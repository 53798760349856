import { ReportCategoryChartConfig } from "../types";
import { getCanonicalBreakdownChart } from "../../../../_common/charts/config/getCanonicalBreakdownChart";
import { getCanonicalBreakdownTrend } from "../../../../_common/charts/config/getCanonicalBreakdownTrend";
import { getIndexabilityCategoryChart } from "../../../../_common/charts/config/getIndexabilityCategoryChart";
import { getIndexabilityCategoryTrend } from "../../../../_common/charts/config/getIndexabilityCategoryTrend";
import { getIndexableDepthChart } from "../../../../_common/charts/config/getIndexableDepthChart";
import { getMobileConfigurationChart } from "../../../../_common/charts/config/getMobileConfigurationChart";
import { getMobileConfigurationTrend } from "../../../../_common/charts/config/getMobileConfigurationTrend";
import { getNonIndexablePagesChart } from "../../../../_common/charts/config/getNonIndexablePagesChart";
import { getNonIndexablePagesTrend } from "../../../../_common/charts/config/getNonIndexablePagesTrend";

export const generateIndexabilityCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getIndexabilityCategoryChart,
      getIndexabilityCategoryTrend,
      getNonIndexablePagesChart,
      getNonIndexablePagesTrend,
      // getPagesBreakdownChart,
      // getPagesBreakdownTrendChart,
      // getNonIndexablePagesChartWithFixedReportOrder,
      getCanonicalBreakdownChart,
      getCanonicalBreakdownTrend,
      getMobileConfigurationChart,
      getMobileConfigurationTrend,
      getIndexableDepthChart,
      // getUncrawledUrlsBreakdownChart,
    ],
  };
