import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SparklineChart } from "./SparklineChart";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Typography } from "../typography/Typography";
import { Link } from "../link/Link";
const useStyles = makeStyles((theme) => ({
    grid: {
        display: "grid",
        height: "100%",
    },
    headerCell: {
        textAlign: "center",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineHeight: theme.typography.pxToRem(24),
    },
    header: {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: theme.typography.pxToRem(15),
        fontWeight: 400,
        textTransform: "uppercase",
        margin: theme.spacing(0, 1),
        verticalAlign: "top",
    },
    link: {
        display: "contents",
        color: "inherit",
        "&:hover $dataCell": {
            backgroundColor: theme.palette.grey[50],
        },
    },
    dataCell: {
        paddingLeft: 8,
        paddingRight: 8,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
    },
    sparklineContainer: {
        height: "32px",
        width: "150px",
        [theme.breakpoints.down(1666)]: {
            width: "100px",
        },
        [theme.breakpoints.down("md")]: {
            width: "300px",
        },
        [theme.breakpoints.down(1100)]: {
            width: "150px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100px",
        },
    },
}));
export function SparklineTableChart(props) {
    const classes = useStyles();
    // In case of new reports, not every series contains the same
    // amount of data points. In this case we want the data points
    // to be aligned on the x axis. This can be achieved by using
    // the minimunm and maximum point as the range for every sparkline.
    const { minX, maxX } = getDataMinMax(props.data);
    return (_jsxs("div", { className: classes.grid, style: {
            gridTemplateColumns: props.columns
                .map((column) => {
                if (column === "name")
                    return "minmax(100px, 4fr)";
                if (column === "trend")
                    return "max-content";
                return column.columnWidth || "1fr";
            })
                .join(" "),
            gridTemplateRows: "min-content auto",
        }, children: [props.columns.map((column, idx) => (_jsx("div", { className: classes.headerCell, children: typeof column !== "string" && column.header ? (_jsx(Typography, { className: classes.header, children: column.header })) : null }, idx))), props.data.map((datum, rowIdx) => {
                return (_jsx(Link, Object.assign({ className: classes.link, to: datum.href }, (props.linkProps && props.linkProps(datum, rowIdx)), { children: props.columns.map((column, idx) => {
                        if (column === "name") {
                            return (_jsx("div", { className: classes.dataCell, children: _jsx(Tooltip, { arrow: true, placement: "bottom-end", title: _jsx(_Fragment, { children: datum.label }), PopperProps: { style: { marginTop: -12 } }, children: _jsx("div", { style: {
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflowX: "hidden",
                                            paddingLeft: 8,
                                        }, children: datum.label }) }, idx) }, idx));
                        }
                        if (column === "trend") {
                            return (_jsx("div", { className: classes.dataCell, children: _jsx(Tooltip, { arrow: true, placement: "bottom", title: _jsx(MinMaxTooltipText, Object.assign({}, getSeriesMinMax(datum.series, props.getMinText, props.getMaxText))), PopperProps: { style: { marginTop: -12 } }, children: _jsx("div", { className: classes.sparklineContainer, children: _jsx(SparklineChart, { series: datum.series, minX: minX, maxX: maxX }) }) }, idx) }, idx));
                        }
                        return (_jsx("div", { className: classes.dataCell, children: _jsx("div", { style: { width: "100%", whiteSpace: "nowrap" }, children: datum.values[column.field] }) }, idx));
                    }) }), datum.id));
            })] }));
}
function getSeriesMinMax(series, getMinText, getMaxText) {
    if (series.length === 0) {
        return {
            min: getMinText(0),
            max: getMaxText(0),
        };
    }
    if (series.length === 1) {
        return {
            min: getMinText(series[0].y),
            max: getMaxText(series[0].y),
        };
    }
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedAsc = series.slice().sort((a, b) => a.y - b.y);
    return {
        min: getMinText(sortedAsc[0].y),
        max: getMaxText(sortedAsc[sortedAsc.length - 1].y),
    };
}
function MinMaxTooltipText(props) {
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "caption", children: props.min }), _jsx("br", {}), _jsx(Typography, { variant: "caption", children: props.max })] }));
}
function getDataMinMax(data) {
    return data.reduce((minMax, data) => {
        const seriesX = data.series.map((value) => value.x);
        const min = Math.min(...seriesX);
        const max = Math.max(...seriesX);
        return {
            minX: Math.min(min, minMax.minX),
            maxX: Math.max(max, minMax.maxX),
        };
    }, { minX: Number.MAX_VALUE, maxX: 0 });
}
