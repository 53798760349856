import React from "react";
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Button,
  Snackbar,
  SwitchField,
  Typography,
  TextField,
  LinkSolid,
} from "@lumar/shared";
import Alert from "@material-ui/lab/Alert";

import { ExpirationDateDropdown } from "./ExpirationDateDropdown";
import { CrawlOption, ShareLinkState } from "./useShareLink";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2.125),
  },
  alert: {
    width: "100%",
    height: 57,
    alignItems: "center",
  },
  alertMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  retryButton: {
    color: "inherit",
    borderColor: "inherit",
  },
  fields: {
    display: "flex",
    flexDirection: "row",
  },
  accessField: {
    marginRight: theme.spacing(1.5),
  },
  description: {
    marginTop: theme.spacing(1.625),
    marginBottom: theme.spacing(1.5),
    whiteSpace: "pre-line",
    minHeight: 35,
  },
  label: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  radio: {
    marginTop: theme.spacing(1.125),
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    marginTop: theme.spacing(2.25),
  },
  copyButton: {
    flexShrink: 0,
    marginLeft: theme.spacing(0.875),
  },
}));

export function CreateShareLinkForm({
  state,
  onChange,
  shareLink,
  loading,
  error,
}: {
  state: ShareLinkState;
  onChange: (state: ShareLinkState) => void;
  shareLink: string;
  loading: boolean;
  error: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("createShareLink");
  const { enqueueSnackbar } = useSnackbar();

  const handleRetry = (): void => {
    onChange(state);
  };

  const handleCopyLink = (): void => {
    if (loading) return;

    navigator.clipboard.writeText(shareLink);
    enqueueSnackbar(
      <Snackbar variant="success" title={t("shareLinkCopied")} />,
    );
  };

  if (error)
    return (
      <Alert
        severity="error"
        classes={{
          root: classes.alert,
          message: classes.alertMessage,
        }}
      >
        {t("failedToGenerateMessage")}
        <Button
          onClick={handleRetry}
          variant="outlined"
          size="large"
          className={classes.retryButton}
        >
          {t("retry")}
        </Button>
      </Alert>
    );

  return (
    <div className={classes.root}>
      <div className={classes.fields}>
        <SwitchField
          checked={!state.isPublic}
          onChange={(_, value) => onChange({ ...state, isPublic: !value })}
          label={t("access")}
          offLabel={t("public")}
          onLabel={t("private")}
          neutral
          className={classes.accessField}
          data-testid="share-link-access"
        />
        <ExpirationDateDropdown
          value={state.expiration}
          onChange={(value) =>
            onChange({
              ...state,
              expiration: value,
            })
          }
          disabled={!state.isPublic}
        />
      </div>
      <Typography variant="subtitle4" className={classes.description}>
        {state.isPublic ? t("descriptionPublic") : t("descriptionPrivate")}
      </Typography>

      <RadioGroup
        value={state.crawl}
        onChange={(e) =>
          onChange({ ...state, crawl: e.target.value as CrawlOption })
        }
      >
        <Typography className={classes.label}>{t("crawl")}</Typography>
        <FormControlLabel
          value={CrawlOption.Current}
          control={<Radio />}
          label={t("currentCrawl")}
          data-testid="share-link-current-crawl"
        />
        <FormControlLabel
          value={CrawlOption.Latest}
          control={<Radio />}
          label={t("latestCrawl")}
          className={classes.radio}
          data-testid="share-link-latest-crawl"
        />
      </RadioGroup>

      <div className={classes.linkContainer}>
        <TextField
          value={shareLink}
          disabled={loading}
          label={t("link")}
          inputProps={{ readOnly: true }}
          data-testid="share-link-text-field"
        />
        <Button
          onClick={handleCopyLink}
          loading={loading}
          startIcon={<LinkSolid />}
          size="large"
          variant="contained"
          color="secondary"
          className={classes.copyButton}
          data-pendo="shared-link-copy-link-button"
        >
          {t("copyLink")}
        </Button>
      </div>
    </div>
  );
}
