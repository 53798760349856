import * as Yup from "yup";
import { ConnectionFilter } from "../types";

function hasOnlyOneKey(value: unknown): boolean {
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 1;
  }
  return false;
}

function firstKeyValueHasOnlyOneKey(value: unknown): boolean {
  if (typeof value === "object" && value !== null) {
    return Object.keys(Object.values(value)[0]).length === 1;
  }
  return false;
}
const datasourceConnectionFilterSchema = Yup.object()
  .shape({
    _or: Yup.array()
      .of(
        Yup.object()
          .shape({
            _and: Yup.array().of(
              Yup.object()
                .test({
                  test: hasOnlyOneKey,
                })
                .test({
                  test: firstKeyValueHasOnlyOneKey,
                })
                .notRequired(),
            ),
          })
          .strict(true)
          .noUnknown(true)
          .required(),
      )
      .required(),
  })
  .strict(true)
  .noUnknown(true)
  .required();

export function validateDatasourceConnectionFilter(
  filter: unknown,
): filter is Required<ConnectionFilter> {
  try {
    datasourceConnectionFilterSchema.validateSync(filter);
    return true;
  } catch {
    return false;
  }
}
