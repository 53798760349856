import { ReportCategoryChartConfig } from "../types";
import { getRedirectBreakdownChart } from "../../../../_common/charts/config/getRedirectBreakdownChart";
import { getRedirectBreakdownTrend } from "../../../../_common/charts/config/getRedirectBreakdownTrend";
import { getRedirectIssuesChart } from "../../../../_common/charts/config/getRedirectIssuesChart";
import { getRedirectIssuesChartTrend } from "../../../../_common/charts/config/getRedirectIssuesChartTrend";

export const generateCrawlabilityRedirectionsCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getRedirectBreakdownChart,
      getRedirectBreakdownTrend,
      getRedirectIssuesChart,
      getRedirectIssuesChartTrend,
    ],
  };
