import { useTheme } from "@material-ui/core";
import { EmptyState, useSession } from "@lumar/shared";
import { useTranslation } from "react-i18next";
import { CrawlType, RoleCode } from "../../../../graphql";
import { RequiredSources } from "../../types/ChartConfigItemBase";
import { useParams } from "react-router";
import { Routes } from "../../../routing/routes";

import AddIcon from "@material-ui/icons/Add";

export function MissingSourceMessage(props: {
  requiredSources?: RequiredSources;
}): JSX.Element {
  const { t } = useTranslation("charts");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const { hasSufficientRole } = useSession();

  return (
    <EmptyState
      icon={<MissingSourceIcon sources={props.requiredSources?.sources} />}
      description={props.requiredSources?.incompleteMessage || ""}
      actions={
        hasSufficientRole(RoleCode.Editor)
          ? [
              {
                type: "internalLink",
                icon: <AddIcon />,
                title: t("configureCrawlSources"),
                to: Routes.Crawls.getUrl({
                  accountId,
                  projectId,
                  tab: "edit",
                  step: 2,
                }),
              },
            ]
          : []
      }
    />
  );
}

function MissingSourceIcon(props: {
  sources?: (CrawlType | "*")[];
}): JSX.Element | null {
  const mainSource = (props.sources || []).filter(
    (source) => source !== "*",
  )[0];
  const theme = useTheme();

  switch (mainSource) {
    case CrawlType.Web:
      return (
        <svg
          width="38"
          height="38"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M4.75 30.875V8.3125C4.75 7.99756 4.87511 7.69551 5.09781 7.47281C5.32051 7.25011 5.62256 7.125 5.9375 7.125H32.0625C32.3774 7.125 32.6795 7.25011 32.9022 7.47281C33.1249 7.69551 33.25 7.99756 33.25 8.3125V30.875L28.5 28.5L23.75 30.875L19 28.5L14.25 30.875L9.5 28.5L4.75 30.875Z"
            fill="#F59E0B"
          />
          <path
            d="M11.2812 15.4375H26.7188"
            stroke="#F59E0B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.2812 20.1875H26.7188"
            stroke="#F59E0B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.75 30.875V8.3125C4.75 7.99756 4.87511 7.69551 5.09781 7.47281C5.32051 7.25011 5.62256 7.125 5.9375 7.125H32.0625C32.3774 7.125 32.6795 7.25011 32.9022 7.47281C33.1249 7.69551 33.25 7.99756 33.25 8.3125V30.875L28.5 28.5L23.75 30.875L19 28.5L14.25 30.875L9.5 28.5L4.75 30.875Z"
            stroke="#F59E0B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case CrawlType.GoogleSearchConsole:
      return (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M14.4995 25.5C20.2985 25.5 24.9995 20.799 24.9995 15C24.9995 9.20101 20.2985 4.5 14.4995 4.5C8.70052 4.5 3.99951 9.20101 3.99951 15C3.99951 20.799 8.70052 25.5 14.4995 25.5Z"
            fill={theme.palette.primary.main}
          />
          <path
            d="M14.4995 25.5C20.2985 25.5 24.9995 20.799 24.9995 15C24.9995 9.20101 20.2985 4.5 14.4995 4.5C8.70052 4.5 3.99951 9.20101 3.99951 15C3.99951 20.799 8.70052 25.5 14.4995 25.5Z"
            stroke={theme.palette.primary.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.9238 22.425L27.9989 28.5001"
            stroke={theme.palette.primary.main}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg viewBox="169.153 63.578 20 20" width="38" height="38">
          <path
            opacity="0.2"
            d="M 178.886 82.667 C 184.027 82.667 188.194 78.434 188.194 73.212 C 188.194 67.991 184.027 63.758 178.886 63.758 C 173.745 63.758 169.577 67.991 169.577 73.212 C 169.577 78.434 173.745 82.667 178.886 82.667 Z"
            fill={theme.palette.primary.main}
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 179.153 65.578 C 174.734 65.578 171.153 69.159 171.153 73.578 C 171.153 77.996 174.734 81.578 179.153 81.578 C 183.571 81.578 187.153 77.996 187.153 73.578 C 187.153 69.159 183.571 65.578 179.153 65.578 Z M 169.153 73.578 C 169.153 68.055 173.63 63.578 179.153 63.578 C 184.675 63.578 189.153 68.055 189.153 73.578 C 189.153 79.101 184.675 83.578 179.153 83.578 C 173.63 83.578 169.153 79.101 169.153 73.578 Z M 178.153 69.578 C 178.153 69.025 178.6 68.578 179.153 68.578 L 179.163 68.578 C 179.715 68.578 180.163 69.025 180.163 69.578 C 180.163 70.13 179.715 70.578 179.163 70.578 L 179.153 70.578 C 178.6 70.578 178.153 70.13 178.153 69.578 Z M 177.153 73.578 C 177.153 73.025 177.6 72.578 178.153 72.578 L 179.153 72.578 C 179.705 72.578 180.153 73.025 180.153 73.578 L 180.153 76.578 C 180.705 76.578 181.153 77.025 181.153 77.578 C 181.153 78.13 180.705 78.578 180.153 78.578 L 179.153 78.578 C 178.6 78.578 178.153 78.13 178.153 77.578 L 178.153 74.578 C 177.6 74.578 177.153 74.13 177.153 73.578 Z"
            fill={theme.palette.primary.main}
          ></path>
        </svg>
      );
  }
}
