import { Metric } from "../data/types";

export function metricCompareFn(a: Metric, b: Metric): number {
  const isCustomMetricA = a.code.startsWith("customMetrics");
  const isCustomMetricB = b.code.startsWith("customMetrics");
  if (isCustomMetricA && !isCustomMetricB) return -1;
  if (isCustomMetricB && !isCustomMetricA) return 1;

  return a.name.localeCompare(b.name);
}
