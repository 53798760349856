import { Chip } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../../../../resource-detail/data/types";
import clsx from "clsx";
import { isString } from "lodash";

const useStyles = makeStyles((theme) => ({
  chip: {
    display: "inline-block",
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      display: "block",
      whiteSpace: "nowrap",
    },
  },
  block: {
    display: "block",
    width: "fit-content",
  },
  wrap: {
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
}));

export function ChangedFromInterpreter(
  props: MetricsValuePresenterProps & {
    isGridView?: boolean;
  },
): JSX.Element {
  const classes = useStyles();

  function getValue(value: unknown): string {
    if (!value) return "-";
    if (typeof value === "string") return value;
    return JSON.stringify(value);
  }

  if (Array.isArray(props.value)) {
    return (
      <div
        style={{
          whiteSpace: props.isGridView ? "nowrap" : "normal",
          display: "grid",
          gap: 8,
        }}
      >
        <div style={{ height: 8 }} />
        {props.value.map((value, idx) => {
          const stringValue = getValue(value);

          const isBlock =
            !props.isGridView && isString(value) && stringValue.length > 100;

          const shouldWrap = !props.isGridView;

          return (
            <Chip
              key={idx}
              label={<s>{stringValue}</s>}
              color="lightgrey"
              className={clsx(classes.chip, {
                [classes.wrap]: shouldWrap,
                [classes.block]: isBlock,
              })}
            />
          );
        })}
        <div style={{ height: 8 }} />
      </div>
    );
  }

  const stringValue = getValue(props.value);
  return (
    <span
      style={{
        whiteSpace: props.isGridView ? "nowrap" : "break-spaces",
        wordBreak: "break-word",
      }}
    >
      {!stringValue ? "-" : <s>{stringValue}</s>}
    </span>
  );
}
