import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FastField, FieldProps, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Button, Grid, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as Yup from "yup";
import { fieldToSelect, Select } from "@lumar/shared";

import { translationNamespace } from "../../../CrawlSettings";

const useStyles = makeStyles(() => ({
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    width: "100%",
  },
}));

export function AddSplunkAccount({
  className,
  afterSave,
  addConnection,
}: {
  className?: string;
  afterSave?: () => void;
  addConnection: (
    url: string,
    username: string,
    password: string,
  ) => Promise<boolean>;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const staticIpValues: string[] = [t("sources.logSummary.splunk.dynamic")];

  const validationSchema = Yup.object().shape({
    url: Yup.string().required(t("validationError.required")),
    username: Yup.string().required(t("validationError.required")),
    password: Yup.string().required(t("validationError.required")),
  });

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) return;

    afterSave?.();
  };

  return (
    <Formik
      initialValues={{
        url: "",
        username: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={({ url, username, password }) =>
        addConnection(url, username, password)
      }
    >
      {({ submitForm }) => (
        <Grid container spacing={1} className={className}>
          <Grid item xs={12} md={6}>
            <FastField
              name="url"
              component={TextField}
              variant="outlined"
              placeholder={t("sources.logSummary.splunk.hostnamePlaceholder")}
              label={t("sources.logSummary.splunk.hostname")}
              className={classes.input}
              data-testid="crawl-settings-add-account-hostname"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              name="username"
              component={TextField}
              variant="outlined"
              label={t("sources.logSummary.splunk.username")}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField
              name="password"
              component={TextField}
              variant="outlined"
              label={t("sources.logSummary.splunk.password")}
              type="password"
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FastField name="splunk.staticIp">
              {(props: FieldProps<number>) => (
                <Select
                  {...fieldToSelect(props)}
                  label={t("sources.logSummary.splunk.staticIP")}
                  fullWidth
                  id="staticIp-selector"
                >
                  {staticIpValues.map((ip: string) => (
                    <MenuItem key={ip} value={ip}>
                      {ip}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FastField>
          </Grid>
          <Grid item xs={12} className={classes.alignRightGrid}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => handleSave(submitForm)}
              data-testid="crawl-settings-add-splunk-account"
            >
              {t("sources.logSummary.splunk.addAccount")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
