import { SettingsIcon, Button, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Routes } from "../../../../_common/routing/routes";
interface RouteParams {
  accountId: string;
  projectId: string;
}

const useStyles = makeStyles(() => ({
  settingsButton: {
    marginTop: 10,
    width: "100%",
    textTransform: "uppercase",
  },
  settingsIcon: { fontSize: 17, marginRight: 5 },
}));

export function AdvancedSettingsButton(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlProgress");
  const { accountId, projectId } = useParams<RouteParams>();

  return (
    <Button
      component={Link}
      to={Routes.Crawls.getUrl({
        accountId,
        projectId,
        tab: "edit",
        step: 4,
      })}
      color="primary"
      className={classes.settingsButton}
      data-testid="advanced-settings-button"
    >
      <SettingsIcon className={classes.settingsIcon} />
      {t("crawlSettings.advancedSettingsLinkText")}
    </Button>
  );
}
