import { camelCase } from "lodash";
import {
  CustomMetric,
  CustomMetricType,
  MetricType,
  ModuleCode,
  Scalars,
} from "../../graphql";
import { CrawlSettings, Datasource, Metrics } from "./types";

interface Props {
  resource: Scalars["JSONObject"]["input"];
  datasource?: Datasource;
  moduleCode?: ModuleCode;
  settings?: CrawlSettings;
  customMetrics?: Pick<CustomMetric, "code" | "name" | "type">[] | null;
}

type MetricWithValueType = [
  string,
  {
    code: string;
    name: string;
    value: unknown;
    data: NonNullable<Props["datasource"]>["metrics"][0];
  },
];

export function getMetrics({
  resource,
  datasource,
  moduleCode,
  settings,
  customMetrics,
}: Props): Metrics {
  const metrics: MetricWithValueType[] = (datasource?.metrics || [])
    .filter((x) => moduleCode && x.supportedModules?.includes(moduleCode))
    .flatMap<MetricWithValueType>((metric) => {
      if (metric.code === "customMetrics") {
        return (customMetrics || []).map<MetricWithValueType>(
          (customMetric) => {
            const customMetricCode = `customMetrics.${customMetric.code}`;
            const customMetricName = `${customMetric.name} *`;

            return [
              customMetricCode,
              {
                code: customMetricCode,
                name: customMetricName,
                value: resource.customMetrics?.[customMetric.code],
                data: {
                  code: customMetricCode,
                  name: customMetric.name,
                  description: "",
                  type: convertCutomMetricTypeToMetricType(customMetric.type),
                  supportedModules: metric.supportedModules,
                },
              },
            ];
          },
        );
      }

      const { name, description } = getNameAndDescription(metric, settings);
      return [
        [
          metric.code,
          {
            code: metric.code,
            name,
            description,
            value: resource[metric.code],
            data: metric,
            datasourceCode: datasource?.datasourceCode,
          },
        ],
      ];
    });

  const metricsWithoutData = Object.entries(resource)
    .filter(([key]) => !metrics.find((x) => x[0] === key))
    .map(([code, value]) => [
      code,
      {
        code,
        name: code,
        value: value,
        datasourceCode: datasource?.datasourceCode,
      },
    ]);

  return Object.fromEntries([...metrics, ...metricsWithoutData]);
}

function getNameAndDescription(
  metric: Datasource["metrics"][number],
  settings: CrawlSettings | undefined,
): { name: string; description?: string } {
  const customExtraction = metric.code.startsWith("customExtraction")
    ? settings?.customExtractions?.find(
        (x) => camelCase(x.reportTemplateCode) === metric.code,
      )
    : undefined;

  if (customExtraction) {
    return {
      name: customExtraction.label,
      description: metric.description?.replace(
        metric.name,
        customExtraction.label,
      ),
    };
  }

  if (metric.metadata?.abbreviation) {
    return {
      name: `${metric.name} (${metric.metadata.abbreviation})`,
      description: metric.description,
    };
  }

  return { name: metric.name, description: metric.description };
}

export function convertCutomMetricTypeToMetricType(
  type: CustomMetricType | null | undefined,
): MetricType {
  switch (type) {
    case CustomMetricType.Boolean:
      return MetricType.Boolean;
    case CustomMetricType.Integer:
      return MetricType.Integer;
    case CustomMetricType.Number:
      return MetricType.Decimal;
    case CustomMetricType.String:
      return MetricType.String;
    case CustomMetricType.IntegerArray:
      return MetricType.Integer;
    case CustomMetricType.NumberArray:
      return MetricType.Decimal;
    case CustomMetricType.StringArray:
      return MetricType.String;
    default:
      return MetricType.Json;
  }
}
