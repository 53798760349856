import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";
import { Chip, Trans } from "@lumar/shared";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
  },
  chipWrap: {
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
}));

export function ContainerExecutionFailuresPresenter({
  value,
  disableWrap,
}: MetricsValuePresenterProps & { disableWrap?: boolean }): JSX.Element {
  const classes = useStyles();

  if (!Array.isArray(value) || !value.length) return <>-</>;

  return (
    <div className={clsx({ [classes.container]: !disableWrap })}>
      {value
        .filter((item) => item.containerId && item.containerName && item.error)
        .map((item, idx) => (
          <Chip
            key={idx}
            label={
              <Trans
                ns="report"
                i18nKey="containerExecutionFailureslabel"
                values={{
                  name: item.containerName,
                  id: item.containerId,
                  error: item.error,
                }}
                components={{ bold: <b /> }}
              />
            }
            color="red"
            className={clsx({
              [classes.chip]: true,
              [classes.chipWrap]: !disableWrap,
            })}
          />
        ))}
    </div>
  );
}
