import React from "react";

import { FieldProps } from "formik";
import { useUpdatableState } from "../../../_common/hooks/useUpdatableState";
import { TextField } from "formik-material-ui";
import { Slider } from "@lumar/shared";

export interface FieldUpdateOnBlurProps extends FieldProps {
  pattern?: RegExp;
}

function FieldUpdateOnBlur<T extends FieldUpdateOnBlurProps>(
  props: T,
  component: (props: T) => JSX.Element,
): JSX.Element {
  const {
    pattern,
    field: { value, onChange, onBlur, ...fieldProps },
  } = props;

  const [innerValue, setInnerValue] = useUpdatableState(value);

  return component({
    ...props,
    field: {
      value: innerValue,
      onChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        if (!pattern || e.target.value.match(pattern)) {
          setInnerValue(e.target.value);
        }
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (innerValue !== value) {
          onChange(e);
          setTimeout(() => {
            onBlur(e);
          }, 0);
        } else {
          onBlur(e);
        }
      },
      ...fieldProps,
    },
  });
}

export function withOnBlurUpdate<T extends FieldUpdateOnBlurProps>(
  component: (props: T) => JSX.Element,
): (props: T) => JSX.Element {
  return (props: T) => FieldUpdateOnBlur(props, component);
}

export const TextFieldWithOnBlurUpdate = withOnBlurUpdate(TextField);

export function SliderWithOnBlurUpdate({
  value,
  onChange,
  ...props
}: Omit<Parameters<typeof Slider>[0], "value" | "onChange"> & {
  value: number;
  onChange: (value: number) => void;
}): JSX.Element {
  const [innerValue, setInnerValue] = useUpdatableState(value);

  return (
    <Slider
      value={innerValue}
      onChange={(_, value) =>
        setInnerValue(Array.isArray(value) ? value[0] : value)
      }
      onBlur={() => onChange?.(innerValue)}
      {...props}
    />
  );
}
