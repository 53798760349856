import { useContext } from "react";
import { useTranslation, ApolloCache } from "@lumar/shared";

import {
  DeleteSplunkQueryMutation,
  GetSplunkConnectionsForSourcesQuery,
  GetSplunkQueriesForSourcesDocument,
  GetSplunkQueriesForSourcesQuery,
  useAddSplunkConnectionMutation,
  useCreateSplunkQueryMutation,
  useDeleteSplunkQueryMutation,
  useGetSplunkConnectionsForSourcesQuery,
  useGetSplunkQueriesForSourcesQuery,
  useUpdateEnabledSplunkQueryMutation,
} from "../../../../../graphql";
import { assert } from "../../../../../_common/assert";
import { useGenericParams } from "../../../../../_common/routing/useGenericParams";
import { translationNamespace } from "../../../CrawlSettings";
import { SourcesContext } from "../../data/useSourcesFormValues";

export interface SplunkConnectionValues {
  id: string;
  url: string;
  username: string;
}

export interface SplunkQueryValues {
  id: string;
  enabled: boolean;
}

export interface SplunkQueriesResult {
  connections: SplunkConnectionValues[];
  queries: SplunkQueryValues[];
  addConnection: (
    url: string,
    username: string,
    password: string,
  ) => Promise<boolean>;
  addQuery: () => Promise<void>;
  setQueryStatus: (id: string, status: boolean) => void;
  deleteQuery: (id: string) => void;
}

export function useSplunkQueries(): SplunkQueriesResult {
  const { projectId } = useGenericParams();
  assert(projectId);

  const { t } = useTranslation(translationNamespace);
  const context = useContext(SourcesContext);

  const { data: connectionData } = useGetSplunkConnectionsForSourcesQuery({
    onError: () => context.onError(t("message.errorSplunkConnections")),
  });

  const { data: queriesData } = useGetSplunkQueriesForSourcesQuery({
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "cache-first",
    onError: () => context.onError(t("message.errorSplunkQueries")),
  });

  const [addConnection] = useAddSplunkConnectionMutation({
    onError: () => context.onError(t("message.errorAddSplunkAccount")),
  });

  const [addQuery] = useCreateSplunkQueryMutation({
    onError: () => context.onError(t("message.errorSplunkCreate")),
  });

  const [setQueryStatus] = useUpdateEnabledSplunkQueryMutation({
    onError: (error) => {
      if (error.message === "Splunk connection is missing.") {
        context.onError(t("sources.logSummary.splunk.noConnection"));
      } else {
        context.onError(t("message.errorSplunkUpdate"));
      }
    },
  });

  const [deleteQuery] = useDeleteSplunkQueryMutation({
    onError: () => context.onError(t("message.errorSplunkDelete")),
  });

  const connections = getConnections(connectionData);
  return {
    connections,
    queries: getQueries(queriesData),
    addConnection: async (url, username, password) => {
      try {
        await addConnection({
          variables: {
            url,
            username,
            password,
          },
          refetchQueries: ["GetSplunkConnectionsForSources"],
          awaitRefetchQueries: true,
        });
      } catch {
        return false;
      }
      return true;
    },
    addQuery: async () => {
      await addQuery({
        variables: {
          splunkConnectionId: connections[0]?.id ?? null,
          projectId: projectId,
          query: "host=*",
        },
        refetchQueries: ["GetSplunkQueriesForSources"],
        awaitRefetchQueries: true,
      });
    },
    setQueryStatus: async (id, status) => {
      await setQueryStatus({
        variables: {
          splunkProjectQueryId: id,
          enabled: status,
        },
        optimisticResponse: {
          updateSplunkProjectQuery: {
            splunkProjectQuery: {
              id: id,
              enabled: status,
              __typename: "SplunkProjectQuery",
            },
          },
        },
      });
    },
    deleteQuery: async (id) => {
      await deleteQuery({
        variables: {
          splunkProjectQueryId: id,
        },
        optimisticResponse: {
          deleteSplunkProjectQuery: {
            splunkProjectQuery: {
              id: id,
              __typename: "SplunkProjectQuery",
            },
          },
        },
        update: (cache, { data }) =>
          updateQueriesAfterDelete(cache, data, projectId),
      });
    },
  };
}

function getConnections(
  data: GetSplunkConnectionsForSourcesQuery | undefined,
): SplunkConnectionValues[] {
  return (
    data?.me?.splunkConnections?.nodes?.map((connection) => ({
      id: connection.id,
      url: connection.url,
      username: connection.username,
    })) ?? []
  );
}

function getQueries(
  data: GetSplunkQueriesForSourcesQuery | undefined,
): SplunkQueryValues[] {
  return (
    data?.getProject?.splunkProjectQueries?.nodes.map((item) => ({
      id: item.id,
      enabled: item.enabled,
    })) ?? []
  );
}

function updateQueriesAfterDelete(
  cache: ApolloCache<DeleteSplunkQueryMutation>,
  data: DeleteSplunkQueryMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetSplunkQueriesForSourcesQuery | null = cache.readQuery({
    query: GetSplunkQueriesForSourcesDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject) return;

  const queries = cachedData.getProject.splunkProjectQueries.nodes;
  const index = queries.findIndex(
    (x) => x.id === data?.deleteSplunkProjectQuery.splunkProjectQuery.id,
  );

  cache.writeQuery({
    query: GetSplunkQueriesForSourcesDocument,
    variables: { projectId: projectId },
    data: {
      getProject: {
        ...cachedData.getProject,
        splunkProjectQueries: {
          nodes: [...queries.slice(0, index), ...queries.slice(index + 1)],
        },
      },
    },
  });
}
