/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesTBT(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedTBTIssues.title"),
    description: () => t("siteSpeedTBTIssues.description"),
    reportTemplateCodesOrder: [
      "bootup_time_failed_audits",
      "dom_size_failed_audits",
      "third_party_summary_failed_audits",
      "viewport_failed_audits",
      "legacy_javascript_failed_audits",
      "mainthread_work_breakdown_failed_audits",
      "third_party_facades_failed_audits",
      "duplicated_javascript_failed_audits",
      "long_tasks_failed_audits",
    ],
    reportStatFilter: (report) => {
      return [
        "bootup_time_failed_audits",
        "dom_size_failed_audits",
        "third_party_summary_failed_audits",
        "viewport_failed_audits",
        "legacy_javascript_failed_audits",
        "mainthread_work_breakdown_failed_audits",
        "third_party_facades_failed_audits",
        "duplicated_javascript_failed_audits",
        "long_tasks_failed_audits",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
