import {
  SparklineTableChart,
  ChartContainer,
  useTranslation,
} from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { ChartPanelSuccessMessage } from "../../../../_common/charts/components/chart-messages/ChartPanelSuccessMessage";
import { Routes } from "../../../../_common/routing/routes";
import {
  useCrawlContextData,
  useCrawlContextHelpers,
} from "../../../CrawlContext";
import { useCrawlOverviewContextData } from "../../../CrawlOverviewContext";
import { useTopErrorsSparklineTableChartData } from "./useTopErrorsSparklineTableChartData";
import { ChartPanelErrorMessage } from "../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";
import { ArchivedCrawlMessage } from "../../../../_common/charts/components/chart-messages/ArchivedCrawlMessage";
import { ChartPanelButton } from "../../../../_common/charts/components/chart-components/ChartPanelButton";

export function TopErrorsChartPanel(): JSX.Element {
  const { t } = useTranslation("charts");

  const { selectedCrawlSegment } = useCrawlContextData();

  return (
    <ChartContainer
      title={t("issues.title")}
      description={t("issues.description")}
      subtitle={selectedCrawlSegment?.segment.name}
      data-testid="top-issues-chart"
      header={<TopErrorsButton />}
    >
      <TopErrorsChart />
    </ChartContainer>
  );
}

export function TopErrorsChart(): JSX.Element {
  const { t } = useTranslation("charts");
  const { data, columns, loading, noErrors, crawlArchived, error } =
    useTopErrorsSparklineTableChartData();

  if (loading) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (noErrors) {
    return (
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <ChartPanelSuccessMessage>
          {t("issues.noIssuesFound")}
        </ChartPanelSuccessMessage>
      </div>
    );
  }

  if (crawlArchived) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArchivedCrawlMessage />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ChartPanelErrorMessage>
          {t("errors.queryError")}
        </ChartPanelErrorMessage>
      </div>
    );
  }

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      <SparklineTableChart
        data={data}
        getMinText={(min) => t("min", { value: min })}
        getMaxText={(max) => t("max", { value: max })}
        linkProps={(_, index) =>
          index === 0 ? { "data-pendo": "top-health-score-error" } : {}
        }
        columns={columns}
      />
    </div>
  );
}

export function TopErrorsButton(): JSX.Element {
  const { t } = useTranslation("charts");
  const helpers = useCrawlContextHelpers();
  const { selectedCrawlSegment } = useCrawlContextData();
  const { crawlId, projectId, accountId } = useParams<{
    crawlId: string;
    accountId: string;
    projectId: string;
  }>();
  const { selectedCategory } = useCrawlOverviewContextData();
  const errorReports = helpers.getCrawlReportCategoryErrorReportsList(
    selectedCategory.code,
  );
  if (!errorReports.length) return <></>;

  return (
    <ChartPanelButton
      link={Routes.CrawlOverview.getUrl({
        accountId,
        crawlId,
        projectId,
        category: selectedCategory.code,
        segmentId: selectedCrawlSegment?.segment?.id,
        type: "errors",
      })}
      label={t("issues.viewAll", { count: errorReports.length })}
    />
  );
}
