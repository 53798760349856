import { useInView } from "react-intersection-observer";
import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { SparklinesMiniChart } from "../../../_common/charts/components/SparklinesMiniChart";
import { alpha, useTheme } from "@material-ui/core";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";
import {
  getCustomReportTemplateId,
  isCustomReportGUID,
} from "../../../_common/report-helpers/customReport";

export function ReportTrendCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  const theme = useTheme();

  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "500px 0px 500px 0px",
    threshold: 0,
    fallbackInView: true,
    triggerOnce: true,
  });

  const isCustomReport = isCustomReportGUID(report.id);

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: getCustomReportTemplateId(report.id),
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
      ref={ref}
    >
      {inView ? (
        <SparklinesMiniChart
          colors={[theme.palette.grey[600]]}
          fillColor={alpha(theme.palette.grey[200], 0.5)}
          height={20}
          width={200}
          data={report.trend?.map((trend) => {
            return {
              y: trend.basic ?? 0,
              x: new Date(trend.createdAt || 0).getTime() || 0,
            };
          })}
        />
      ) : (
        <div style={{ width: 200, height: 20 }} />
      )}
    </ReportLink>
  );
}
