var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { get, omit } from "lodash";
export function fieldToTextField(_a) {
    var { disabled } = _a, _b = _a.field, { onBlur: fieldOnBlur } = _b, field = __rest(_b, ["onBlur"]), { form: { isSubmitting, touched, errors }, onBlur, helperText } = _a, props = __rest(_a, ["disabled", "field", "form", "onBlur", "helperText"]);
    const fieldError = get(errors, field.name);
    const showError = get(touched, field.name) && !!fieldError;
    return Object.assign(Object.assign({ error: showError, helperText: showError ? fieldError : helperText, disabled: disabled !== null && disabled !== void 0 ? disabled : isSubmitting, onBlur: onBlur !== null && onBlur !== void 0 ? onBlur : function (e) {
            fieldOnBlur(e !== null && e !== void 0 ? e : field.name);
        } }, field), omit(props, "meta"));
}
