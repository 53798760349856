const AGGREGATES = ["sum", "avg", "count"];

export function formatMetrics(
  // The value here is unknown because it's a union of all possible values
  // that can be returned from the graphql query.
  // Record, string, number, boolean, null, and undefined are all possible.
  row: Record<string, unknown>,
): Record<string, unknown> {
  return AGGREGATES.reduce(
    (result, aggregate) => ({
      ...result,
      ...Object.fromEntries(
        Object.entries(row?.[aggregate] || {})
          .filter((x) => x[0] !== "__typename")
          .map(([key, value]) => [`${key}-${aggregate}`, value]),
      ),
    }),
    {},
  );
}
