import useLocalStorageState from "use-local-storage-state";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import { createPersistenceKeyFromReportInput } from "../../../Report.helpers";
import { ReportInput } from "../../../Report.types";
import { GridState } from "@mui/x-data-grid-pro";
import {
  ColumnState,
  convertColumnsParamFrom,
  getGridColumnsParam,
  readColumnsState,
  writeColumnsState,
} from "../../../../_common/data-grid/column-persistance/columnsState";
import React from "react";
import { ReportGridColumn } from "./ReportGridColumns.types";

type Props = {
  reportInput: ReportInput;
  columnPersistanceKey?: string;
};

type Result = {
  isGridView: boolean;
  toggleGridView: () => void;
  loadInitialColumnsState: (
    defaultStates: ColumnState[],
    cardMetrics: ReportGridColumn[],
  ) => ColumnState[];
  saveColumnsState: (state: GridState, defaultStates: ColumnState[]) => void;
};

export function useReportGridColumnState({
  reportInput,
  columnPersistanceKey,
}: Props): Result {
  const params = useURLSearchParams();

  const columnsParam = params.get("columns");
  const viewTypeParam = params.get("viewType");

  const persistenceKeyFromInput =
    createPersistenceKeyFromReportInput(reportInput);

  const [viewType, setViewType] = useLocalStorageState<string>(
    `${persistenceKeyFromInput}_view_type`,
  );
  const isGridView = (viewTypeParam ?? viewType) === "grid";
  function toggleGridView(): void {
    if (!viewTypeParam) {
      setViewType((x) => (x !== "grid" ? "grid" : "table"));
    } else {
      params.set("viewType", isGridView ? "table" : "grid");
      params.apply();
    }
  }

  const loadInitialColumnsState = React.useCallback(
    (defaultStates: ColumnState[], cardMetrics: ReportGridColumn[]) => {
      return readColumnsState(
        columnPersistanceKey ?? persistenceKeyFromInput,
        defaultStates,
        getColumnParam(
          cardMetrics.map((x) => x.code),
          isGridView,
        ),
        isGridView,
      );
    },
    [columnPersistanceKey, isGridView, persistenceKeyFromInput],
  );

  function saveColumnsState(
    state: GridState,
    defaultStates: ColumnState[],
  ): void {
    if (!columnsParam) {
      writeColumnsState(
        columnPersistanceKey ?? persistenceKeyFromInput,
        state,
        defaultStates,
        isGridView,
      );
    } else {
      const columns = getGridColumnsParam(state, defaultStates);
      if (columns && columns !== params.get("columns")) {
        params.set("columns", columns);
        params.apply();
      }
    }
  }

  return {
    isGridView,
    toggleGridView,
    loadInitialColumnsState,
    saveColumnsState,
  };
}

function getColumnParam(
  cardMetrics: string[],
  isGridView?: boolean,
): (Partial<ColumnState> | null)[] | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  const columnsParam = urlParams.get("columns");
  if (!columnsParam) return;

  const columns = convertColumnsParamFrom(columnsParam);
  if (!columns) return;

  return isGridView
    ? columns.flatMap((x) =>
        x?.code === "card" ? cardMetrics.map((code) => ({ code })) : [x],
      )
    : columns;
}
