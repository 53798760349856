import { SiteSpeedOverallScore, useTranslation } from "@lumar/shared";
import {
  Metric,
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  Indicator,
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useSiteSpeedDetailsSections } from "./useSiteSpeedDetailsSections";
import {
  PageSpeedPresenter,
  SourcePresenter,
} from "../../metrics-value-presenter";
import { CustomSkeleton } from "../../../_common/CustomSkeleton";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";

export function SiteSpeedDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  const { active, activeParent, loading } = useSiteSpeedDetailsSections(
    data.metrics,
  );

  const config = useSummaryConfig(data.metrics);

  return (
    <ResourceDetailBase
      title={
        active && !activeParent
          ? t("siteSpeed.titleWithSection", { section: active.name })
          : t("siteSpeed.title")
      }
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      {active?.component ? (
        <active.component data={data} />
      ) : loading ? (
        <CustomSkeleton
          height={300}
          variant="rect"
          background="white"
          animation="wave"
        />
      ) : null}
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");
  const { crawlSetting } = useCrawlContextData();

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  const sitespeedScore = metrics["sitespeedScore"]?.value;

  const isMobile = Boolean(
    crawlSetting?.userAgentString
      ? crawlSetting?.userAgentIsMobile
      : crawlSetting?.userAgent?.isMobile,
  );

  return {
    summary: [
      { code: "url" },
      { code: "foundAtUrl" },
      { code: "foundAtSitemap" },
      {
        code: "foundIn",
        name: t("metricFoundIn"),
        value: <SourcePresenter {...getMetricProps("foundIn")} />,
      },
    ],
    header:
      typeof sitespeedScore === "number" ? (
        <SiteSpeedOverallScore
          value={sitespeedScore * 100}
          label={t("siteSpeed.overallScore")}
        />
      ) : undefined,
    cardDetails: [
      {
        code: "sitespeedFcp",
        name: t("siteSpeed.cardFcp"),
        value: (
          <PageSpeedPresenter
            {...getMetricProps("sitespeedFcp")}
            isMobile={isMobile}
          />
        ),
        indicator: getIndicator(metrics["sitespeedFcp"], isMobile),
      },
      {
        code: "sitespeedLcp",
        name: t("siteSpeed.cardLcp"),
        value: (
          <PageSpeedPresenter
            {...getMetricProps("sitespeedLcp")}
            isMobile={isMobile}
          />
        ),
        indicator: getIndicator(metrics["sitespeedLcp"], isMobile),
      },
      {
        code: "sitespeedTbt",
        name: t("siteSpeed.cardTbt"),
        value: (
          <PageSpeedPresenter
            {...getMetricProps("sitespeedTbt")}
            isMobile={isMobile}
          />
        ),
        indicator: getIndicator(metrics["sitespeedTbt"], isMobile),
      },
      {
        code: "sitespeedCls",
        name: t("siteSpeed.cardCls"),
        value: (
          <PageSpeedPresenter
            {...getMetricProps("sitespeedCls")}
            isMobile={isMobile}
          />
        ),
        indicator: getIndicator(metrics["sitespeedCls"], isMobile),
      },
      {
        code: "sitespeedSpeedIndex",
        name: t("siteSpeed.cardSpeedIndex"),
        value: (
          <PageSpeedPresenter
            {...getMetricProps("sitespeedSpeedIndex")}
            isMobile={isMobile}
          />
        ),
        indicator: getIndicator(metrics["sitespeedSpeedIndex"], isMobile),
      },
    ],
  };
}

function getIndicator(
  metric: Metric,
  isMobile?: boolean,
): Indicator | undefined {
  const value = metric.value;
  if (typeof value !== "number") return;

  const siteSpeedScoring = isMobile
    ? metric?.data?.metadata?.siteSpeedScoring?.mobile
    : metric?.data?.metadata?.siteSpeedScoring?.desktop;
  const moderate = siteSpeedScoring?.p10;
  const slow = siteSpeedScoring?.median;

  if (slow === undefined || moderate === undefined) return;

  return value >= slow ? "error" : value >= moderate ? "warning" : "success";
}
