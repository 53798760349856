import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { FilterOrRuleFieldArray } from "../../../_common/connection-filtering/filter-or-rule-field-array/FilterOrRuleFieldArray";
import Button from "@material-ui/core/Button";
import {
  FilterRuleFormValue,
  NarrowMetric,
} from "../../../_common/connection-filtering/types";
import { LocalFilterFormValues } from "./types";
import { useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2, 2, 0, 2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  submitButton: {
    minWidth: 180,
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    marginLeft: "auto",
  },
}));

type FormikFormProps = FormikProps<LocalFilterFormValues>;
interface LocalFilterFormProps {
  resetForm: FormikFormProps["resetForm"];
  onLastRemainingRuleDeleted: () => void;
  values: LocalFilterFormValues;
  metricsWithPredicates: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  onCancel: () => void;
}

export function LocalFilterForm(props: LocalFilterFormProps): JSX.Element {
  const styles = useStyles();
  const { t } = useTranslation(["common", "report"]);
  return (
    <Form>
      <FilterOrRuleFieldArray
        name="filters"
        metrics={props.metricsWithPredicates}
        defaultFilter={props.defaultFilter}
        value={props.values.filters}
        allowDeletingLastEmptyRule
        onLastRemainingRuleDeleted={props.onLastRemainingRuleDeleted}
        autoFocus
      />
      <div className={styles.actions}>
        <Button
          data-testid="clear-all-filters"
          variant="text"
          onClick={() => props.resetForm()}
        >
          {t("report:filterClear")}
        </Button>
        <Button
          onClick={props.onCancel}
          variant="text"
          className={styles.cancelButton}
        >
          {t("common:cancel")}
        </Button>
        <Button
          type="submit"
          data-testid="apply-all-filters"
          variant="contained"
          color="secondary"
          classes={{ root: styles.submitButton }}
        >
          {t("report:filterApply")}
        </Button>
      </div>
    </Form>
  );
}
