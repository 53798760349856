import { ArrowRightUp, Chart, DatabaseSystemV4, FCPIcon, FileboardV3, FlagBugReport, FolderV5, GlobeNetwork, Grid, LCPIcon, NewsGrid, SearchRight, SettingsV2, SettingsV5, Signal, SiteSpeedIcon, StatsV3, TBTIcon, UsabilityA, UsabilityAA, UsabilityAAA, ViewSimple, } from "./index";
import { GalleryGridViewV3 } from "./lumar";
export const categoryIconConfig = new Map([
    ["admin", SettingsV5],
    ["custom", SettingsV2],
    ["summary", Grid],
    ["availability", GlobeNetwork],
    ["indexability", DatabaseSystemV4],
    ["uniqueness", Chart],
    ["crawlability", StatsV3],
    ["rankability", ArrowRightUp],
    ["experience", Signal],
    ["traffic", FlagBugReport],
    ["discoverability", SearchRight],
    ["response_status", FileboardV3],
    ["indexing", FolderV5],
    ["content_serp", NewsGrid],
    ["appearance", ViewSimple],
    ["top", Grid],
    ["accessibility", Grid],
    ["accessibility_bp", FlagBugReport],
    ["accessibility_a", UsabilityA],
    ["accessibility_aa", UsabilityAA],
    ["accessibility_aaa", UsabilityAAA],
    ["site_speed", SiteSpeedIcon],
    ["cumulative_layout_shift", GalleryGridViewV3],
    ["speed_index", ArrowRightUp],
    ["first_contentful_paint", FCPIcon],
    ["largest_contentful_paint", LCPIcon],
    ["total_blocking_time", TBTIcon],
]);
