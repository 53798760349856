import clsx from "clsx";

import { useTranslation, ChipColor, Chip } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ReportImpact } from "./reportImpact";

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: theme.spacing(0.5, 1),
  },
}));

export function ImpactBadge({
  impact,
  className,
  style,
}: {
  impact: ReportImpact;
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const [label, color] = ((): [string, ChipColor] => {
    switch (impact) {
      case ReportImpact.Negative:
        return [t("impactNegative"), "red"];
      case ReportImpact.Neutral:
        return [t("impactNeutral"), "white"];
      case ReportImpact.Positive:
        return [t("impactPositive"), "green"];
    }
  })();

  return (
    <Chip
      label={label}
      color={color}
      className={clsx(classes.badge, className)}
      style={style}
      data-testid="report-info-impact"
    />
  );
}
