import { Typography } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function TitleCell({ value }: GridRenderCellParams): JSX.Element {
  return (
    <Typography variant="subtitle3SemiBold" data-testid="task-title">
      {value}
    </Typography>
  );
}
