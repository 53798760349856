import { Typography, useTranslation } from "@lumar/shared";
import { Box, makeStyles, Switch } from "@material-ui/core";
import { useContext } from "react";
import { GridContext } from "./ReportGrid";

type TableGridToggleProps = {
  disabled?: boolean;
};

export function TableGridToggle({
  disabled,
}: TableGridToggleProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");
  const { isGridView, toggleGridView } = useContext(GridContext);

  return (
    <Box display="flex" alignItems="center" marginLeft={2}>
      <Typography variant="subtitle3Medium" className={classes.switchLabel}>
        {t("tableView")}
      </Typography>
      <Box marginX={1}>
        <Switch
          value={isGridView}
          checked={isGridView}
          onChange={(e, checked) => toggleGridView(checked)}
          disabled={disabled}
        />
      </Box>
      <Typography variant="subtitle3Medium" className={classes.switchLabel}>
        {t("gridView")}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  switchLabel: { color: theme.palette.grey[500] },
}));
