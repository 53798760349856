import React, { useContext, useState } from "react";
import {
  Button,
  ButtonBase,
  FormControlLabel,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import { SearchSolid, Typography, Checkbox } from "@lumar/shared";
import { FixedSizeList } from "react-window";

import { SearchConsoleFilters } from "./SearchConsoleFilters";
import { SourcesContext } from "../data/useSourcesFormValues";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import {
  GoogleSearchConsolePropertyValues,
  useGoogleSearchConsoleProperties,
} from "./useGoogleSearchConsoleProperties";
import { SearchField } from "../../components/SearchField";
import { FormValues } from "../data/types";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent: {
      marginTop: theme.spacing(1),
    },
    searchInput: {
      width: "100%",
    },
    alignRightGrid: {
      display: "flex",
      justifyContent: "flex-end",
    },
    grid: {
      marginTop: theme.spacing(0.25),
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    views: {
      width: "100%",
      height: "250px",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      marginTop: theme.spacing(1),
    },
    notFoundMessage: {
      color: theme.palette.error.main,
    },
    primaryInfo: {
      marginTop: theme.spacing(1),
    },
    secondaryInfo: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightLight,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
);

export const GoogleSearchConsole = React.memo(function GSC({
  initialState,
  dirty,
}: {
  initialState: boolean;
  dirty: boolean;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const contextValues = useContext(SourcesContext);

  const [searchString, setSearchString] = useState(
    contextValues.primaryDomain &&
      new URL(contextValues.primaryDomain).host.replace(/^www\./, ""),
  );
  const formattedSearchString = searchString.toLowerCase();

  const {
    enabledProperties,
    disabledProperties,
    maxProperties,
    setStatus,
    addAccount,
  } = useGoogleSearchConsoleProperties();

  const disabledSites = disabledProperties.filter((p) =>
    p.siteUrl.toLowerCase().includes(formattedSearchString),
  );
  const enabledSites = enabledProperties;

  const getBadges = (values: FormValues): CustomAccordionBadge[] => {
    if (values.dataOnlyCrawlType.googleSearchConsole) {
      return [
        {
          label: t("sources.dataOnly"),
          info: "",
          color: "primary" as const,
        },
      ];
    }
    return [];
  };

  if (contextValues.isPlanLight)
    return (
      <UpgradeMessage
        upgradeType="LightPlusOrCorporate"
        className={classes.indent}
      />
    );

  return (
    <CustomAccordion
      title={t("sources.googleSearchConsole.title")}
      icon={<SearchSolid fontSize="small" style={{ display: "block" }} />}
      tooltip={t("sources.googleSearchConsole.tooltip")}
      initialState={initialState}
      getBadges={getBadges}
      fieldName="crawlType.googleSearchConsole"
      bottomContent={
        <DataOnlySourceSwitch name="dataOnlyCrawlType.googleSearchConsole" />
      }
      data-pendo="google-search-console-header"
      data-testid="google-search-console-section"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <SearchField
            value={searchString}
            onChange={(value) => setSearchString(value)}
            placeholder={t("sources.googleSearchConsole.findWebsites")}
            className={classes.searchInput}
            data-testid="crawl-settings-gsc-filter"
          />
        </Grid>
        <Grid item xs={12} md={8} className={classes.alignRightGrid}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ShowChartIcon />}
            onClick={() => {
              if (
                !dirty ||
                // eslint-disable-next-line no-alert
                window.confirm(t("sources.googleSearchConsole.sureToLeave"))
              ) {
                addAccount();
              }
            }}
          >
            {t("sources.googleSearchConsole.addAccount")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={12}>
              <SearchConsoleFilters />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2Medium">
                {t("sources.googleSearchConsole.availableSites", {
                  count: disabledSites.length,
                })}
              </Typography>
              <div className={classes.views}>
                {disabledSites.length === 0 ? (
                  <Typography
                    variant="body1"
                    className={classes.notFoundMessage}
                  >
                    {searchString.length === 0 ? (
                      t("sources.googleSearchConsole.noData")
                    ) : (
                      <Trans
                        ns="crawlSettings"
                        i18nKey="sources.googleSearchConsole.notFound"
                        values={{
                          searchString: searchString,
                        }}
                        components={{
                          bold: <span className={classes.bold} />,
                        }}
                      />
                    )}
                    <ButtonBase onClick={() => setSearchString("")}>
                      <DeleteIcon color="primary" fontSize="small" />
                      <Typography color="primary">
                        {t("sources.googleSearchConsole.clearFilter")}
                      </Typography>
                    </ButtonBase>
                  </Typography>
                ) : (
                  <SiteList
                    sites={disabledSites}
                    setStatus={setStatus}
                    disabled={enabledSites.length >= maxProperties}
                    disabledMessage={t(
                      "sources.googleSearchConsole.maxProperties",
                      { count: maxProperties },
                    )}
                    data-testid="crawl-settings-gsc-available-sites"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2Medium">
                {t("sources.googleSearchConsole.selectedSites", {
                  count: enabledSites.length,
                })}
              </Typography>
              <div className={classes.views}>
                <SiteList
                  sites={enabledSites}
                  setStatus={setStatus}
                  data-testid="crawl-settings-gsc-selected-sites"
                />
              </div>
            </Grid>
          </Grid>
          {/* <FastField
            name="googleSearchConsole.useSearchConsolePropertyDomainsAsStartUrls"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: t(
                "sources.googleSearchConsole.useSearchConsolePropertyDomainsAsStartUrls"
              ),
            }}
          /> */}
        </Grid>
      </Grid>
    </CustomAccordion>
  );
});

function SiteList({
  sites,
  setStatus,
  disabled,
  disabledMessage,
  "data-testid": dataTestid,
}: {
  sites: GoogleSearchConsolePropertyValues[];
  setStatus: (
    property: GoogleSearchConsolePropertyValues,
    enabled: boolean,
  ) => void;
  disabled?: boolean;
  disabledMessage?: string;
  ["data-testid"]?: string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip title={(disabled && disabledMessage) || ""} placement="top">
      <div>
        <FixedSizeList
          width="100%"
          height={300}
          itemSize={46}
          itemCount={sites.length}
          itemData={{ sites }}
        >
          {({ index, data, style }) => {
            const site = data.sites[index];
            return (
              <FormControlLabel
                key={index}
                style={style}
                checked={site.enabled}
                onChange={() => setStatus(site, !site.enabled)}
                disabled={disabled}
                control={<Checkbox />}
                label={
                  <>
                    <Typography className={classes.primaryInfo}>
                      {site.siteUrl}
                    </Typography>
                    <Typography
                      color="primary"
                      className={classes.secondaryInfo}
                    >
                      {site.connectionName}
                    </Typography>
                  </>
                }
                data-testid={dataTestid}
              />
            );
          }}
        </FixedSizeList>
      </div>
    </Tooltip>
  );
}
