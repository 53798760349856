/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getIndexablePageUniquenessChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("indexablePageUniqueness.title"),
    description: t("indexablePageUniqueness.description"),
    reportTemplateCodes: ["true_uniques", "primary_duplicates"],
  })();
}
