import React, { useMemo, useState } from "react";

import { useSnackbar } from "notistack";
import { Formik } from "formik";
import { truncate } from "lodash";
import {
  Snackbar,
  Typography,
  useTranslation,
  getApiProjectId,
} from "@lumar/shared";

import { useSegmentMutations } from "../useSegmentMutations";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { assert } from "../../_common/assert";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { useCreateSegmentValidationSchema } from "./useCreateSegmentValidationSchema";
import { Crawl, Maybe } from "../../graphql";
import { mapOrFormFiltersToDatasourceConnectionFilter } from "../../_common/connection-filtering/filter-or-rule-field-array/mapOrFormFiltersToDatasourceConnectionFilter";
import { getCrawlStatus } from "../helpers/getCrawlStatus";
import {
  SegmentForm,
  SegmentFormValues,
} from "../segment-dialog-form/SegmentForm";
import { SegmentDialogWithPreview } from "../segment-dialog-form/SegmentDialogWithPreview";
import { SegmentsListData } from "../manage-segment/data/useSegmentsList";
import {
  getDefaultFilterMetric,
  getFormFilter,
  getFormOrFilter,
} from "../../_common/connection-filtering/helpers";

interface CreateSegmentDialogProps {
  metrics: NarrowMetric[];
  groups: string[];
  defaultGroup?: string;
  latestFinishedCrawlData: Maybe<Pick<Crawl, "id" | "archivedAt">> | undefined;
  crawlStatusData: SegmentsListData | undefined;
  closeDialog: () => void;
}

export function CreateSegmentDialog(
  props: CreateSegmentDialogProps,
): JSX.Element {
  const { t } = useTranslation([
    "common",
    "segments",
    "copySegmentDialog",
    "connectionFiltering",
  ]);

  const {
    metrics: filterMetrics,
    groups,
    defaultGroup,
    latestFinishedCrawlData,
    crawlStatusData,
    closeDialog,
  } = props;

  const { createSegmentMutation } = useSegmentMutations();

  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const { projectId, accountId, crawlId } = useGenericParams();

  const { enqueueSnackbar } = useSnackbar();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const defaultFilter = React.useMemo(
    () => getFormFilter(getDefaultFilterMetric(filterMetrics)),
    [filterMetrics],
  );

  const initialValues = useMemo(
    () => ({
      projectDestination: null,
      segments: [
        {
          segmentName: "",
          group: defaultGroup || null,
          rules: [getFormOrFilter(defaultFilter)],
        },
      ],
    }),
    [defaultFilter, defaultGroup],
  );

  const validationSchema = useCreateSegmentValidationSchema(filterMetrics);

  const doesUnarchivedFinishedCrawlExist =
    Boolean(latestFinishedCrawlData) && !latestFinishedCrawlData?.archivedAt;

  const isPreviewDisabled = !doesUnarchivedFinishedCrawlExist;

  // Using async onSubmit handler automatically updates isSubmitting
  const handleSubmit = async (
    values: SegmentFormValues,
    { resetForm }: { resetForm: () => void },
  ): Promise<void> => {
    const truncatedSegmentName = truncate(values.segments[0].segmentName);
    try {
      const createSegmentValues = {
        projectId: getApiProjectId(projectId),
        segmentName: values.segments[0].segmentName,
        group: values.segments[0].group,
        // FIXME: Formik doesn't respect `transform` and `trim` in yup schema.
        // Formik devs will fix it in this pr: https://github.com/jaredpalmer/formik/pull/2255
        // This is why `schema.cast` has to be used.
        rules: mapOrFormFiltersToDatasourceConnectionFilter(
          validationSchema.cast(values)?.segments[0].rules || [],
        ),
      };
      await createSegmentMutation(createSegmentValues);
      if (isPreviewDialogOpen) {
        setIsPreviewDialogOpen(false);
      }
      resetForm();
      closeDialog();
      const crawl = crawlStatusData?.crawl; /* node?.crawls?.nodes[0] */
      const { crawlFinalising, crawlInProgress } = getCrawlStatus(crawl);

      if (crawlInProgress && !crawlFinalising) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("copySegmentDialog:segmentCreated", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          >
            <Typography variant="caption">
              {t("copySegmentDialog:dataForSegmentGeneration")}
            </Typography>
          </Snackbar>,
        );
      } else {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("copySegmentDialog:segmentCreated", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          />,
        );
      }
    } catch {
      if (isPreviewDialogOpen) {
        setIsPreviewDialogOpen(false);
      }

      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("segments:faileToCreate", {
            truncatedSegmentName: truncatedSegmentName,
          })}
        />,
      );
    }
  };

  return (
    <Formik<SegmentFormValues>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        validateForm,
        submitForm,
        setTouched,
        isSubmitting,
      }) => (
        <SegmentDialogWithPreview
          values={values}
          validateForm={validateForm}
          onSubmit={submitForm}
          isSubmitting={isSubmitting}
          setTouched={setTouched}
          closeDialog={closeDialog}
          submitBtnText={t("segments:createSegment")}
          dialogTitle={t("segments:createSegment")}
          previewMessage={(projectName) =>
            t("segments:createSegmentIn", {
              name: values.segments[0].segmentName,
              projectName: projectName,
            })
          }
          isPreviewDisabled={isPreviewDisabled}
          metrics={props.metrics}
          lastFinishedCrawlId={latestFinishedCrawlData?.id}
        >
          <SegmentForm
            values={values}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            metrics={props.metrics}
            defaultFilter={defaultFilter}
            groups={groups}
          />
        </SegmentDialogWithPreview>
      )}
    </Formik>
  );
}
