import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgTBTTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    inputSource: "GqlQuery",
    supportedModules: [ModuleCode.SiteSpeed],
    icon: "lighthouse",
    unitDescription: t("unitDescription.speed"),
    title: () => t("siteSpeedTBTMetricTrend.tile_title"),
    description: () => t("siteSpeedTBTMetricTrend.description"),
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId: crawlContext.selectedCrawlSegment?.segment?.rawID,
            reportTemplateCode: "site_speed_pages:total_blocking_time_avg",
          },
        ],
      };
    },
    testAttributePrefix: "tbt-lighthouse",
  };
}
