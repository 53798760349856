import { makeStyles, Tooltip } from "@material-ui/core";
import { FaviconIcon, Typography } from "@lumar/shared";
import { TFunction } from "i18next";
import { CrawlStatus } from "../../graphql";
import { Routes } from "../../_common/routing/routes";
import { Project, ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    lineHeight: "normal",
    margin: "0 7px",
  },
  container: {
    marginLeft: "20px",
    marginRight: "20px",
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  name: {
    color: theme.palette.grey[800],
  },
  domain: {
    color: theme.palette.grey[600],
    lineHeight: 1.66,
  },
  faviconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  favicon: {
    padding: "2px",
    borderRadius: 4,
  },
}));

export function ProjectCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const classes = useStyles();

  return (
    <div className={classes.root} data-testid="all-projects-project-cell">
      <div className={classes.container}>
        <Tooltip title={row.primaryDomain} placement="bottom" arrow>
          <div className={classes.faviconDiv}>
            <FaviconIcon
              url={row.primaryDomain}
              width={24}
              height={24}
              className={classes.favicon}
            />
          </div>
        </Tooltip>
      </div>

      <div>
        <Typography
          variant="subtitle2SemiBold"
          className={classes.name}
          data-testid="project-name-label"
          component="div"
        >
          {row.name}
        </Typography>
        <Typography
          variant="caption"
          className={classes.domain}
          data-testid="project-domain-label"
          component="div"
        >
          {row.primaryDomain}
        </Typography>
      </div>
    </div>
  );
}

export function getProjectNavigationData(
  project: Project,
  t: TFunction<"projectsList">,
): {
  tooltipTitle: string;
  getHref: (accountId: string) => string;
} {
  const projectId = project.id;

  const noCrawls = project.crawlsTotalCount === 0;
  if (noCrawls) {
    return {
      tooltipTitle: t("goToProjectSettings"),
      getHref: (accountId) =>
        Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "edit",
        }),
    };
  }

  const isLatestCrawlFinished =
    project.lastCrawlStatus === CrawlStatus.Finished;
  if (isLatestCrawlFinished && project.lastFinishedCrawlId) {
    const crawlId = project.lastFinishedCrawlId;
    return {
      tooltipTitle: t("goToTheLatestCrawlOverview"),
      getHref: (accountId) =>
        Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          type: "dashboard",
        }),
    };
  }

  const isLatestCrawlArchived =
    project.lastCrawlStatus === CrawlStatus.Archived;
  if (isLatestCrawlArchived) {
    return {
      tooltipTitle: t("goToPastCrawls"),
      getHref: (accountId) =>
        Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "history",
        }),
    };
  }

  return {
    tooltipTitle: t("goToTheCurrentCrawlProgress"),
    getHref: (accountId) =>
      Routes.Crawls.getUrl({
        accountId,
        projectId,
        tab: "progress",
      }),
  };
}
