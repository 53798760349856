import { Chip } from "@lumar/shared";
import { useCrawlContextData } from "../../crawl-overview/CrawlContext";
import { CircularProgress } from "@material-ui/core";
import { useUnresolvedProjectTasksCountForChipQuery } from "../../graphql";

export function ProjectTasksCountChip(): JSX.Element {
  const { crawlProject } = useCrawlContextData();
  const { data, loading, error } = useUnresolvedProjectTasksCountForChipQuery({
    variables: { projectId: crawlProject.rawID },
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return <CircularProgress size="1.3rem" style={{ display: "block" }} />;
  }

  if (error || !data || !data.getProject) {
    return <></>;
  }

  return (
    <Chip label={data.getProject.unresolvedTasks.totalCount} color="primary" />
  );
}
