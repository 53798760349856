import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Trans } from "react-i18next";
import { Link, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { ViewListOutlined, useTranslation, useBrand } from "@lumar/shared";
import { ManualUploads } from "../manual-uploads/ManualUploads";
import { FormSubmitContext } from "../../components/FormSubmitContext";
import { CrawlType } from "../../../../graphql";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import { translationNamespace } from "../../CrawlSettings";
import { FormValues } from "../data/types";
import { insertIf } from "../../../../_common/insertIf";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  preline: {
    whiteSpace: "pre-line",
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

export const UrlLists = React.memo(function UrlLists({
  initialState,
}: {
  initialState: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(translationNamespace);
  const brand = useBrand();

  const isSupportEmail = brand.supportLink?.includes("@");

  const downloadLink = "/files/ExampleListUpload.txt";

  const getBadges = (
    values: FormValues,
    hasFile: boolean,
  ): CustomAccordionBadge[] => {
    const shouldShowWarning = values.crawlType.urlLists && !hasFile;
    const isDataOnly = values.dataOnlyCrawlType.urlLists;
    return [
      ...insertIf(isDataOnly, {
        label: t("sources.dataOnly"),
        info: "",
        color: "primary" as const,
      }),
      ...insertIf(shouldShowWarning, {
        label: t("sources.urls.warningLabel"),
        info: t("sources.urls.warningInfo"),
        color: "yellow" as const,
      }),
    ];
  };

  return (
    <CustomAccordion
      title={t("sources.urls.title")}
      icon={<ViewListOutlined fontSize="small" style={{ display: "block" }} />}
      tooltip={t("sources.urls.tooltip")}
      initialState={initialState}
      fieldName="crawlType.urlLists"
      getBadges={getBadges}
      bottomContent={<DataOnlySourceSwitch name="dataOnlyCrawlType.urlLists" />}
      data-pendo="url-list-header"
      data-testid="url-list-section"
    >
      <Box className={classes.root}>
        <Typography variant="caption" className={clsx(classes.preline)}>
          <Trans
            ns="crawlSettings"
            i18nKey="sources.urls.description"
            components={{
              exampleLink: (
                <Link
                  href={downloadLink}
                  target="_self"
                  download
                  className={classes.link}
                />
              ),
              supportLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    isSupportEmail
                      ? `mailto:${brand.supportLink}`
                      : brand.supportLink
                  }
                />
              ),
            }}
          />
        </Typography>
        <FormSubmitContext>
          {({ isSubmitting }) => (
            <ManualUploads
              crawlType={CrawlType.List}
              disabled={isSubmitting}
              acceptedFiles={[".txt", ".csv"]}
            />
          )}
        </FormSubmitContext>
      </Box>
    </CustomAccordion>
  );
});
