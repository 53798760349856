import { makeStyles } from "@material-ui/core";

export const useTooltipStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    background: theme.palette.grey[100],
    alignItems: "center",
    boxShadow: theme.shadows[5],
  },
  percentage: {
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontVariantNumeric: "tabular-nums",
  },
  textContainer: {
    background: "#FFF",
    padding: theme.spacing(1, 2),
  },
  urlCountText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));

export const useMultipointTooltipStyles = makeStyles((theme) => ({
  container: {
    background: "white",
    alignItems: "center",
    boxShadow: theme.shadows[5],
    borderRadius: 8,
    padding: theme.spacing(1, 2),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1),
  },
  value: {
    fontVariantNumeric: "tabular-nums",
    justifySelf: "end",
  },
  name: {
    color: theme.palette.blue[600],
    cursor: "pointer",
    marginLeft: 8,
  },
  link: {
    textDecoration: "none",
  },
}));
