import React from "react";
import { ToggleIconButton, ShareSolid, useTranslation } from "@lumar/shared";
import { CreateShareLinkDialog } from "./CreateShareLinkDialog";

export function CreateShareLinkButton({
  getAdditionalParams,
}: {
  getAdditionalParams?: () => string[];
}): JSX.Element {
  const { t } = useTranslation("createShareLink");

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <>
      <ToggleIconButton
        onClick={() => setIsDialogOpen(true)}
        variant="outlined"
        size="large"
        data-testid="create-share-link-btn"
        data-pendo="pendo-create-share-link-btn"
        aria-label={t("shareLinkButtonLabel")}
      >
        <ShareSolid />
      </ToggleIconButton>
      <CreateShareLinkDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        getAdditionalParams={getAdditionalParams}
      />
    </>
  );
}
