import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";
import { useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  conflict: {
    color: theme.palette.red[600],
  },
}));

export function CanonicalUrlConflictPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");

  if (typeof value !== "boolean") return <>-</>;

  return !value ? (
    <span className={classes.conflict}>{t("yes")}</span>
  ) : (
    <span>{t("no")}</span>
  );
}
