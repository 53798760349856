import { Project, UpdateProjectInput } from "../../../../../graphql";
import { StealthModeSettings } from "../types";

type QueryData = Pick<Project, "useStealthMode">;

export function formatStealthModeSettingsFrom(
  data?: QueryData | null,
): StealthModeSettings {
  return {
    enable: data?.useStealthMode ?? false,
  };
}

export function formatStealthModeSettingsTo(
  formValues: StealthModeSettings,
): Pick<UpdateProjectInput, "useStealthMode"> {
  return {
    useStealthMode: formValues.enable,
  };
}
