import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  useTranslation,
  Button,
  EmptyState,
  ExclamationCircleOutlined,
  Typography,
  DocumentDuplicateCopySolid,
  ToggleIconButton,
  SparklesOutlined,
  Close as CloseIcon,
  Snackbar,
} from "@lumar/shared";
import Lottie from "react-lottie-player";
import animation from "../../resource-detail/datasources/issue-details/sections/SuggestedSolutionAnimation.json";
import ReactMarkdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import { TFunction } from "i18next";
import { useSnackbar } from "notistack";
import { useGeneratedDeliveryTicket } from "./useGeneratedDeliveryTicket";

export interface GenerateDeliveryTicketDialogProps {
  taskId: string;
  onClose: () => void;
}

export function GenerateDeliveryTicketDialog({
  taskId,
  onClose,
}: GenerateDeliveryTicketDialogProps): JSX.Element {
  const { t } = useTranslation(["common", "taskManager"]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = React.useState(true);

  const { task, loading, error } = useGeneratedDeliveryTicket(taskId);

  const formattedText = getFormattedText(task?.ticketDetails, t);

  function handleCopy(): void {
    navigator.clipboard.writeText(formattedText);
    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("taskManager:generateDeliveryTicket.copied")}
      />,
    );
  }

  function getContent(): React.ReactNode {
    if (error) {
      return (
        <EmptyState
          icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
          title={t("common:somethingWentWrong")}
          description={error}
          height={416}
          width="100%"
        />
      );
    }

    if (loading) {
      return (
        <EmptyState
          icon={
            <Lottie
              animationData={animation}
              loop
              play
              style={{
                width: "120px",
                height: "120px",
              }}
            />
          }
          title={t("taskManager:generateDeliveryTicket.loadingTitle")}
          description={t(
            "taskManager:generateDeliveryTicket.loadingDescription",
          )}
          height={416}
          width="100%"
        />
      );
    }

    return (
      <>
        <div>
          <ReactMarkdown
            rehypePlugins={[RehypeHighlight]}
            className={classes.markdown}
          >
            {formattedText}
          </ReactMarkdown>
        </div>
        <Tooltip title="Copy">
          <ToggleIconButton
            onClick={handleCopy}
            variant="outlined"
            className={classes.copyButton}
            aria-label="Copy"
            data-pendo="generate-delivery-ticket-copy-button"
          >
            <DocumentDuplicateCopySolid />
          </ToggleIconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () => onClose(),
      }}
      maxWidth="lg"
      fullWidth
      aria-labelledby="generate-delivery-ticket-title"
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography
          component="h2"
          variant="h2"
          id="generate-delivery-ticket-title"
        >
          {t("taskManager:generateDeliveryTicket.title")}
        </Typography>
        <IconButton
          onClick={() => setOpen(false)}
          aria-label={t("taskManager:generateDeliveryTicket.close")}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>{getContent()}</DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.disclaimer}>
          <SparklesOutlined className={classes.disclaimerIcon} />
          <Typography variant="button" className={classes.disclaimerText}>
            {t("taskManager:generateDeliveryTicket.disclaimer")}
          </Typography>
        </div>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="primary"
          data-testid="done-button"
          aria-label={t("taskManager:generateDeliveryTicket.done")}
        >
          {t("taskManager:generateDeliveryTicket.done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function getFormattedText(
  ticketDetails: Record<string, string> | undefined,
  t: TFunction<["common", "taskManager"]>,
): string {
  return `### ${t("taskManager:generateDeliveryTicket.headerTitle")}
${ticketDetails?.title}

### ${t("taskManager:generateDeliveryTicket.headerUserStory")}
${ticketDetails?.userStory}

### ${t("taskManager:generateDeliveryTicket.headerDescription")}
${ticketDetails?.description}

### ${t("taskManager:generateDeliveryTicket.headerHowToReplicate")}
${ticketDetails?.howToReplicate}

### ${t("taskManager:generateDeliveryTicket.headerHowToFix")}
${ticketDetails?.requirementsAndHowToFix}

### ${t("taskManager:generateDeliveryTicket.headerAcceptanceCriteria")}
${ticketDetails?.acceptanceCriteria}`;
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  content: {
    backgroundColor: theme.palette.grey[100],
    borderWidth: "1px 0px",
    borderColor: theme.palette.grey[300],
    borderStyle: "solid",
    position: "relative",
    padding: theme.spacing(0, 1, 2.375, 3.125),
    display: "flex",
    gap: theme.spacing(1),
  },
  actions: {
    justifyContent: "space-between",
  },
  disclaimer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.75),
    marginLeft: theme.spacing(1),
  },
  disclaimerText: {
    color: theme.palette.grey[500],
  },
  disclaimerIcon: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(20),
  },
  copyButton: {
    position: "sticky",
    top: theme.spacing(2.375),
    right: 0,
  },
  markdown: {
    fontSize: theme.typography.pxToRem(14),
    paddingBottom: theme.spacing(2.375),
    "& code": {
      borderRadius: 8,
    },
  },
}));
