import { useTranslation } from "@lumar/shared";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

interface StylisticProps {
  size: "small" | "medium" | "large";
}

interface SelectedProjectInfoProps extends StylisticProps {
  className?: string;
  selectedProjectInfo?: {
    name: string;
    primaryDomain: string;
  };
}

const headerSizes = new Map<StylisticProps["size"], number>([
  ["small", 14],
  ["medium", 16],
  ["large", 22],
]);

const useStyles = makeStyles(() => ({
  header: ({ size }: StylisticProps) => ({
    color: "#0A1124",
    fontSize: headerSizes.get(size),
    fontWeight: 600,
    lineHeight: size === "large" ? "27px" : "21px",
  }),
  subTitle: { color: "#4B5563", fontSize: 13 },
  container: ({ size }: StylisticProps) => ({
    whiteSpace: "nowrap",
    width: "100%",
    minHeight: size === "small" ? 40 : "auto",
    overflow: "hidden",
    marginBottom: size === "large" ? 10 : 0,
  }),
}));

export function SelectedProjectInfo(
  props: SelectedProjectInfoProps,
): JSX.Element {
  const classes = useStyles(props);
  const { t } = useTranslation("sidebar");

  return (
    <div className={clsx(classes.container, props.className)}>
      <Typography className={classes.header}>
        {props.selectedProjectInfo?.name ?? t("projectInfoNameDefault")}
      </Typography>
      <Typography className={classes.subTitle}>
        {props.selectedProjectInfo?.primaryDomain ??
          t("projectInfoDomainDefault")}
      </Typography>
    </div>
  );
}
