import { VariableType } from "json-to-graphql-query";
import { GetCrawlSettingsForSourcesQuery } from "../../../../../graphql";
import { FormValues, MutationData } from "../types";

export function getGoogleSearchConsoleMutationData(
  projectId: string,
  values: FormValues,
  data: GetCrawlSettingsForSourcesQuery | undefined,
): MutationData {
  const gscConfigInput = {
    projectId: projectId,
    country: values.googleSearchConsole.country,
    searchType: values.googleSearchConsole.searchType,
    minClicks: convertNumber(values.googleSearchConsole.minClicks) ?? 1,
    lastNDays: convertNumber(values.googleSearchConsole.lastNDays) ?? 1,
    includeQueries: values.googleSearchConsole.includeQueries,
    excludeQueries: values.googleSearchConsole.excludeQueries,
    useSearchConsolePropertyDomainsAsStartUrls:
      values.googleSearchConsole.useSearchConsolePropertyDomainsAsStartUrls,
  };

  const shouldCreateGSCConfig =
    !data?.getProject?.googleSearchConsoleConfiguration;

  if (shouldCreateGSCConfig) {
    return [
      {
        gscConfigInput: "CreateGoogleSearchConsoleConfigurationInput!",
      },
      {
        createGoogleSearchConsoleConfiguration: {
          __args: {
            input: new VariableType("gscConfigInput"),
          },
          googleSearchConsoleConfiguration: {
            createdAt: true,
          },
        },
      },
      { gscConfigInput },
    ];
  }

  return [
    {
      gscConfigInput: "UpdateGoogleSearchConsoleConfigurationInput!",
    },
    {
      updateGoogleSearchConsoleConfiguration: {
        __args: {
          input: new VariableType("gscConfigInput"),
        },
        googleSearchConsoleConfiguration: {
          createdAt: true,
        },
      },
    },
    { gscConfigInput },
  ];
}

function convertNumber(value: string): number | null {
  if (!value) return null;
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? null : convertedValue;
}
