/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getUrlDesignIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("urlDesignIssues.title"),
    description: () => t("urlDesignIssues.description"),
    reportStatFilter: (report) => {
      return ["malformed_urls", "max_url_length", "double_slash_urls"].includes(
        report.reportTemplateCode,
      );
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "design_issues_chart",
  };
}
