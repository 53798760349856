/* eslint-disable react/display-name */
import React from "react";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ReportStatTrendItem } from "../../../graphql";
import { Routes } from "../../routing/routes";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";
import { Theme } from "@material-ui/core";
import { DateFormatter } from "@lumar/shared";
import { CrawlContextCrawlReportStat } from "../../../crawl-overview/CrawlContext";

export function generateSingleReportTrendChart(reportTemplateCode: string) {
  return function (
    t: TFunction<"charts">,
    theme: Theme,
    formatter: DateFormatter,
  ): ChartConfigItem {
    return {
      inputSource: "CrawlContext",
      visualisationFamily: "MultiDimensionalSeries",
      visualisationType: VisualisationTypes.Area,
      visualisationColors: [theme.palette.singleLineChart.color],
      visualisationFillColors: [theme.palette.singleLineChart.fillColor],
      additionalVisualisationTypes: [
        VisualisationTypes.StackedArea,
        VisualisationTypes.FullStackedArea,
      ],
      title: (reports) =>
        reports[0]?.reportTemplateName ?? t("reportTrend.fallbackTitle"),
      reportStatFilter: (report) => {
        return report.reportTemplateCode === reportTemplateCode;
      },

      series: (
        reports: CrawlContextCrawlReportStat[],
        { accountId, projectId, segmentId },
      ) =>
        reports.map((report) => {
          return {
            name: report.reportTemplateName,
            reportTemplateCode: report.reportTemplateCode,
            data: report.trend
              .filter(
                (
                  trend,
                ): trend is ReportStatTrendItem & {
                  createdAt: string;
                } => !!trend?.createdAt,
              )
              .map((trend) => {
                const createdAt = new Date(trend.createdAt);
                return {
                  x: createdAt,
                  y: trend.basic ?? 0,
                  url: Routes.Report.getUrl({
                    accountId,
                    projectId,
                    crawlId: String(trend.crawlId),
                    segmentId,
                    reportTemplateCode: report.reportTemplateCode,
                    reportTypeCode: "basic",
                  }),
                  reportTemplateCode: report.reportTemplateCode,
                  additionalTooltipLines: [
                    formatter(createdAt, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    }),
                  ],
                };
              }),
          };
        }),

      incompleteSeriesTemplate: (
        <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
      ),
      testAttributePrefix: "report-trend-chart",
    };
  };
}
