import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { EmptyState, SmileySad, useTranslation } from "@lumar/shared";
import LoopIcon from "@material-ui/icons/Loop";

const useStyles = makeStyles((theme) => ({
  sadIcon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.red[400],
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },
}));

export function ChartPanelErrorMessage(props: {
  children: string | ReactNode;
  allowRefresh?: boolean;
}): JSX.Element {
  const { t } = useTranslation("charts");
  const classes = useStyles();

  return (
    <EmptyState
      description={props.children}
      icon={<SmileySad className={classes.sadIcon} />}
      actions={
        props.allowRefresh
          ? [
              {
                type: "button",
                title: t("refreshPage"),
                icon: <LoopIcon />,
                onClick: () => window.location.reload(),
              },
            ]
          : []
      }
    />
  );
}
