import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedSIChart } from "../../../../../_common/charts/config/getSiteSpeedSIChart";
import { getSiteSpeedSITrend } from "../../../../../_common/charts/config/getSiteSpeedSITrend";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAvgSpeedIndexChart } from "../../../../../_common/charts/config/getSiteSpeedAvgSpeedIndexChart";
import { getSiteSpeedAllReportsTable } from "../../../../../_common/charts/config/getSiteSpeedAllReportsTable";

export const generateSiteSpeedSpeedIndexCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    allReportsTable: getSiteSpeedAllReportsTable,
    mainCharts: [
      // getSiteSpeedSIMetricTrend,
      getSiteSpeedAvgSpeedIndexChart,
      getSiteSpeedSIChart,
      getSiteSpeedSITrend,
    ],
  };
