/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getLogSummaryIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    requiredSources: {
      sources: [CrawlType.LogSummary],
      incompleteMessage: t("requiredSourcesMessage.logsSource"),
    },
    title: () => t("logSummaryIssues.title"),
    description: () => t("logSummaryIssues.description"),
    reportTemplateCodesOrder: [
      "error_pages_with_bot_hits",
      "disallowed_pages_with_bot_hits_uncrawled",
      "disallowed_pages_with_bot_hits",
      "non_indexable_pages_with_bot_hits",
      "desktop_pages_with_low_desktop_bot_hits",
      "mobile_alternates_with_low_mobile_bot_hits",
      "indexable_pages_with_no_bot_hits",
      "pages_in_sitemap_with_no_bot_hits",
    ],
    reportStatFilter: (report) => {
      return [
        "error_pages_with_bot_hits",
        "disallowed_pages_with_bot_hits_uncrawled",
        "disallowed_pages_with_bot_hits",
        "non_indexable_pages_with_bot_hits",
        "desktop_pages_with_low_desktop_bot_hits",
        "mobile_alternates_with_low_mobile_bot_hits",
        "indexable_pages_with_no_bot_hits",
        "pages_in_sitemap_with_no_bot_hits",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
