import { useSession } from "@lumar/shared";
import { Redirect, useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";
import { CustomReport } from "./CustomReport";

export const CustomReportContainer = (): JSX.Element => {
  const { hasFeatureFlagEnabled } = useSession();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (!hasFeatureFlagEnabled("custom-reports")) {
    return (
      <Redirect
        to={Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          type: "dashboard",
        })}
      />
    );
  }

  return <CustomReport />;
};
