import React, { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FastField, FastFieldProps, useFormikContext } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ManualUploadsContext } from "./manual-uploads/ManualUploadsWrapper";
import { FormValues } from "./data/types";
import {
  Checkbox,
  Typography,
  Chip,
  InfoOutlined,
  updateIfPropsChanged,
  ChipColor,
  useTranslation,
} from "@lumar/shared";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(1),
    display: "inline-block",
  },
  activeBadge: {
    marginLeft: theme.spacing(1),
  },
  warningBadge: {
    marginLeft: theme.spacing(1),
  },
  tooltipIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(0.5),
    width: "16px",
    height: "16px",
    verticalAlign: "text-bottom",
  },
}));

export interface CustomAccordionProp {
  title: string;
  icon: JSX.Element;
  tooltip: string;
  fieldName: string;
  onChange?: (
    value: boolean,
    props: {
      setFieldValue: (
        field: string,
        value: boolean,
        shouldValidate?: boolean | undefined,
      ) => void;
    },
  ) => void;
  initialState: boolean;
  children?: React.ReactNode;
  bottomContent?: React.ReactNode;
  getBadges?: (values: FormValues, hasFile: boolean) => CustomAccordionBadge[];
  [dataAttrib: `data-${string}`]: string;
}

export interface CustomAccordionBadge {
  label: string;
  info: string;
  color: ChipColor;
}

export const CustomAccordion = React.memo(function CustomAccordion({
  title,
  icon,
  tooltip,
  fieldName,
  onChange,
  initialState,
  children,
  getBadges,
  bottomContent,
  "data-testid": dataTesid,
  ...props
}: CustomAccordionProp): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [expanded, setExpanded] = useState(initialState);

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      style={{ borderRadius: 8 }}
      onChange={(_, expanded) => setExpanded(expanded)}
      data-testid={dataTesid}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FastField
          name={fieldName}
          onChangeCallback={onChange}
          shouldUpdate={updateIfPropsChanged("onChangeCallback")}
        >
          {({
            field: { name, value },
            form: { setFieldValue, isSubmitting },
          }: FastFieldProps<boolean>) => (
            <Grid container alignItems="center" alignContent="flex-end">
              <Grid item>
                <Checkbox
                  onClick={(event) => {
                    event.stopPropagation();
                    setFieldValue(name, !value);
                    onChange?.(!value, { setFieldValue });
                    if (!value) setExpanded(true);
                  }}
                  style={{ display: "inline-block" }}
                  checked={value}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item style={{ marginLeft: "8px" }}>
                {icon}
              </Grid>
              <Grid item>
                <Typography
                  className={classes.title}
                  variant="caption"
                  {...props}
                >
                  {title}
                </Typography>
                <Tooltip title={tooltip} placement="right">
                  <InfoOutlined className={classes.tooltipIcon} />
                </Tooltip>
              </Grid>
              {value === true && (
                <Grid item>
                  <Chip
                    size="small"
                    color="primary"
                    label={t("sources.active")}
                    className={classes.activeBadge}
                  />
                </Grid>
              )}
              <Grid item>
                <CustomAccordionBadges getBadges={getBadges} />
              </Grid>
            </Grid>
          )}
        </FastField>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        <Grid container direction="column">
          <Grid item style={{ paddingBottom: 8 }}>
            <Divider />
          </Grid>
          <Grid item style={{ padding: "0 8px" }}>
            {children}
          </Grid>
          {bottomContent ? (
            <>
              <Grid item style={{ padding: "8px 0px" }}>
                <Divider />
              </Grid>
              <Grid item>{bottomContent}</Grid>
            </>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});

function CustomAccordionBadges({
  getBadges,
}: {
  getBadges?: CustomAccordionProp["getBadges"];
}): JSX.Element {
  const classes = useStyles();

  const formikContext = useFormikContext<FormValues>();
  const manualUploadContext = useContext(ManualUploadsContext);

  const warnings =
    getBadges?.(
      formikContext.values,
      Boolean(manualUploadContext.files.length),
    ) ?? [];

  return (
    <>
      {warnings.map((warning, idx) => (
        <Tooltip key={idx} title={warning.info} placement="top" arrow>
          <Chip
            size="small"
            label={warning.label}
            color={warning.color}
            className={classes.warningBadge}
          />
        </Tooltip>
      ))}
    </>
  );
}
