import React from "react";
import { useTranslation } from "react-i18next";
import { TimeZoneContext } from "../TimeZoneProvider";
export function useDateFormatter() {
    const { i18n } = useTranslation();
    const { timeZone } = React.useContext(TimeZoneContext);
    return React.useCallback((date, options) => {
        return new Intl.DateTimeFormat(i18n.language, Object.assign({ timeZone: timeZone.code }, options)).format(date);
    }, [i18n.language, timeZone.code]);
}
