import React from "react";

interface Result {
  onScroll: React.UIEventHandler<HTMLDivElement>;
}

export function useDataExplorerStickyRow(): Result {
  const stickyRowRef = React.useRef<Element | undefined>();

  const updateStickyRow = React.useCallback((row: Element) => {
    if (row === stickyRowRef.current) return;

    if (stickyRowRef.current) {
      [...stickyRowRef.current.children].forEach((cell) => {
        if (!(cell instanceof HTMLElement)) return;

        cell.style.removeProperty("position");
        cell.style.removeProperty("top");
        cell.style.removeProperty("z-index");
        cell.style.removeProperty("background");
      });
    }

    stickyRowRef.current = row;

    if (stickyRowRef.current) {
      [...stickyRowRef.current.children].forEach((cell) => {
        if (!(cell instanceof HTMLElement)) return;

        // eslint-disable-next-line fp/no-mutation
        cell.style.position = "sticky";
        // eslint-disable-next-line fp/no-mutation
        cell.style.top = "40px";
        // eslint-disable-next-line fp/no-mutation
        cell.style.zIndex = "10";
        // eslint-disable-next-line fp/no-mutation
        cell.style.background = "white";
      });
    }
  }, []);

  const onScroll = React.useCallback<React.UIEventHandler<HTMLDivElement>>(
    (e) => {
      const element = e.target as HTMLDivElement;
      const rows = element?.querySelector("tbody")?.children;
      if (!rows) return;

      const scrollOffset = element.scrollTop;

      const { firstVisibleRow, expandedRows } = [...rows].reduce<{
        firstVisibleRow: HTMLElement | undefined;
        offset: number;
        expandedRows: Record<string, Element>;
      }>(
        (acc, row) => {
          if (acc.offset >= scrollOffset + row.clientHeight) return acc;

          const level = Number((row as HTMLElement)?.dataset?.level);
          const isExpanded = (row as HTMLElement)?.dataset?.expanded === "true";
          const expandedRows =
            isExpanded && !isNaN(level)
              ? { ...acc.expandedRows, [level]: row }
              : acc.expandedRows;

          return {
            firstVisibleRow: row as HTMLElement,
            offset: acc.offset + row.clientHeight,
            expandedRows,
          };
        },
        { firstVisibleRow: undefined, offset: 0, expandedRows: {} },
      );

      const level = Number(firstVisibleRow?.dataset?.level);
      if (!isNaN(level)) updateStickyRow(expandedRows[level - 1]);
    },
    [updateStickyRow],
  );

  return {
    onScroll,
  };
}
