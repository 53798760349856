import {
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
} from "@material-ui/core";
import {
  Typography,
  Button,
  ArrowRightSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import Background from "./marketing-screen-background.svg";
import { RoleCode } from "../../../graphql";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "row",
      borderRadius: "8px",
      minHeight: 653,
      maxWidth: 1314,
      margin: "auto",
      boxSizing: "border-box",
      boxShadow:
        "0px 1px 4px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1)",
      flexFlow: "wrap",
    },
    dashboardPreviewImage: {
      margin: "auto",
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    previewContainer: {
      backgroundImage: `url(${Background})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      flexBasis: 450,
      flexGrow: 3,
      minHeight: 540,
      padding: 64,
    },
    descriptionContainer: {
      display: "flex",
      flexDirection: "column",
      flexBasis: 450,
      paddingTop: 53,
      paddingLeft: 35,
      paddingRight: 35,
      flexGrow: 1,
      marginBottom: 16,
    },
    item: {
      marginTop: 0,
      marginBottom: 20,
      padding: 0,
    },
    itemText: {
      marginTop: 0,
      marginBottom: 0,
      color: "#000000",
    },
    icon: {
      minWidth: 31.5,
      color: theme.palette.navy[500],
      "& svg": {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    title: { marginBottom: 28, display: "inline-block" },
  }),
);

interface MarketingScreenProps {
  title: string;
  bulletpoints: [typeof SvgIcon, string][];
  image: React.ComponentType<{ className?: string }>;
  onRequest: () => void;
}

export function MarketingScreen({
  title,
  bulletpoints,
  image: Image,
  onRequest,
  ...props
}: MarketingScreenProps): JSX.Element {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const session = useSession();
  const canRequest = session.hasSufficientRole(RoleCode.Admin);

  return (
    <Paper classes={{ root: classes.paper }} elevation={0} {...props}>
      <div className={classes.descriptionContainer}>
        <Typography variant="h5SemiBold" className={classes.title}>
          {title}
        </Typography>
        <List disablePadding>
          {bulletpoints.map(([Icon, reason], i) => (
            <ListItem key={i} disableGutters className={classes.item}>
              <ListItemIcon className={classes.icon}>
                <Icon />
              </ListItemIcon>
              <Typography variant="subtitle1">
                <ListItemText
                  disableTypography
                  primary={reason}
                  className={classes.itemText}
                />
              </Typography>
            </ListItem>
          ))}
        </List>
        <div style={{ marginTop: 26 }}>
          {canRequest ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ marginLeft: "auto", display: "block" }}
              onClick={onRequest}
              endIcon={<ArrowRightSolid />}
            >
              {t("components.marketingScreen.interested")}
            </Button>
          ) : (
            <b>{t("components.marketingScreen.userNotAccountAdminInfo")}</b>
          )}
        </div>
      </div>
      <div className={classes.previewContainer}>
        <Image className={classes.dashboardPreviewImage} />
      </div>
    </Paper>
  );
}
