import { makeStyles } from "@material-ui/core";
import {
  DndContext,
  KeyboardCode,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  defaultDropAnimationSideEffects,
  CollisionDetection,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { TasksTable, TasksTableRow } from "./TasksTable";
import { Task } from "../data/types";
import React from "react";
import {
  closestCenter,
  coordinateGetter,
  getPosition,
  handleDragEnd,
  handleDragOver,
  Items,
  useAnnouncements,
} from "./dragAndDropHelpers";
import { createPortal } from "react-dom";
import { useTasksColumns } from "./useTasksColumns";
import { useMemoizedTasksMap } from "./useMemoizedTasksMap";
import { CSS } from "@dnd-kit/utilities";
import { LegacyTaskStatus } from "../../graphql";
import { omit } from "lodash";

export function SortableTasksTables({
  tasks,
  onTaskSelected,
  onTaskMove,
}: {
  tasks: Task[];
  onTaskSelected: (task: Task) => void;
  onTaskMove: (
    taskId: string,
    status: LegacyTaskStatus,
    position: number,
  ) => void;
}): JSX.Element {
  const tasksMap = useMemoizedTasksMap(tasks);

  const [activeId, setActiveId] = React.useState<string | number | null>(null);
  const [clonedItems, setClonedItems] = React.useState<Items | null>(null);

  const lastOverId = React.useRef<string | number | null>(null);
  const recentlyMovedToNewContainer = React.useRef(false);
  React.useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [clonedItems]);

  const items = clonedItems || tasksMap;
  const activeTask = activeId
    ? tasks.find((x) => x.id === activeId)
    : undefined;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { distance: 5 },
    }),
    useSensor(KeyboardSensor, {
      keyboardCodes: {
        start: [KeyboardCode.Space],
        cancel: [KeyboardCode.Esc],
        end: [KeyboardCode.Space],
      },
      coordinateGetter,
    }),
  );

  const announcements = useAnnouncements(items);

  const collisionDetectionStrategy = React.useCallback<CollisionDetection>(
    (args) => {
      // When a draggable item moves to a new container, the layout may shift,
      // causing the 'over' item to change. This shift can trigger a re-render,
      // leading to an infinite loop as the 'over' item keeps updating with each
      // layout change.
      if (recentlyMovedToNewContainer.current) {
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      }

      const result = closestCenter(args);
      lastOverId.current = result[0]?.id;
      return result;
    },
    [],
  );

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      sensors={sensors}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      accessibility={{ announcements: announcements }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(tasksMap);
      }}
      onDragOver={(e) => {
        handleDragOver(e, items, (value) => {
          recentlyMovedToNewContainer.current = true;
          setClonedItems(value);
        });
      }}
      onDragCancel={() => {
        setActiveId(null);
        setClonedItems(null);
      }}
      onDragEnd={(e) => {
        handleDragEnd(e, items, (newItems) => {
          const position = getPosition(newItems, activeId);
          if (position && activeTask) {
            onTaskMove(activeTask.id, position[0], position[1]);
          }
        });

        setActiveId(null);
        setClonedItems(null);
      }}
    >
      <SortableTasksTable
        status={LegacyTaskStatus.Done}
        tasks={items.Done}
        onTaskSelected={onTaskSelected}
      />
      <SortableTasksTable
        status={LegacyTaskStatus.Testing}
        tasks={items.Testing}
        onTaskSelected={onTaskSelected}
      />
      <SortableTasksTable
        status={LegacyTaskStatus.InProgress}
        tasks={items.InProgress}
        onTaskSelected={onTaskSelected}
      />
      <SortableTasksTable
        status={LegacyTaskStatus.ToDo}
        tasks={items.ToDo}
        onTaskSelected={onTaskSelected}
      />
      <SortableTasksTable
        status={LegacyTaskStatus.Backlog}
        tasks={items.Backlog}
        onTaskSelected={onTaskSelected}
      />
      {createPortal(
        <DragOverlay
          adjustScale={false}
          zIndex={1300}
          dropAnimation={{
            sideEffects: defaultDropAnimationSideEffects({
              styles: { active: { opacity: "0.5" } },
            }),
          }}
        >
          {activeTask && <DragedTasksTableRow task={activeTask} />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
}

function SortableTasksTable(
  params: Parameters<typeof TasksTable>[0],
): JSX.Element {
  const { setNodeRef } = useSortable({
    id: params.status,
    data: {
      type: "container",
      children: params.tasks,
    },
  });

  return (
    <SortableContext
      items={params.tasks}
      strategy={verticalListSortingStrategy}
    >
      <TasksTable
        {...params}
        renderRow={SortableTasksTableRow}
        bodyRef={setNodeRef}
      />
    </SortableContext>
  );
}

function SortableTasksTableRow(
  params: Parameters<typeof TasksTableRow>[0],
): JSX.Element {
  const {
    attributes,
    setNodeRef,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: params.task.id,
  });

  return (
    <TasksTableRow
      {...params}
      onClick={(task) => {
        if (isDragging) return;
        params.onClick?.(task);
      }}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
      }}
      {...omit(attributes, "role")}
      {...listeners}
    />
  );
}

function DragedTasksTableRow({ task }: { task: Task }): JSX.Element {
  const columns = useTasksColumns({ status: LegacyTaskStatus.Backlog });
  const classes = useStyles();

  return (
    <table className={classes.dragedTable}>
      <tbody>
        <TasksTableRow task={task} columns={columns} isDragging />
      </tbody>
    </table>
  );
}

const useStyles = makeStyles((theme) => ({
  dragedTable: {
    tableLayout: "fixed",
    width: "100%",
    background: "white",
    borderCollapse: "collapse",
    boxShadow: theme.shadows[2],
    cursor: "grabbing",
    "& tr": {
      boxShadow: `0px 0px 0px 1px  ${theme.palette.grey[500]}`,
    },
    "& td": {
      borderStyle: "none",
    },
  },
}));
