import { useTranslation } from "react-i18next";
import { makeStyles, Tooltip } from "@material-ui/core";
import {
  MonospaceNumber,
  PercentageChangeChip,
  Typography,
} from "@lumar/shared";
import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ReportTypeCode } from "../../../graphql";
import { ReportLink } from "./ReportLink";
import { ReportEntity } from "../../../report/Report.types";
import {
  getCustomReportTemplateId,
  isCustomReportGUID,
} from "../../../_common/report-helpers/customReport";

export function ChangeCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  const { t, i18n } = useTranslation(["crawlOverview", "units"]);

  const classes = useStyles();

  const addedNumber = Number(report.added);
  const removedNumber = Number(report.removed);
  const missingNumber = Number(report.missing);

  const naTranslation = t("crawlOverview:data.na");
  const tooltipChangeAddedTranslation = t(
    "crawlOverview:row.tooltips.change.added",
  );
  const tooltipChangeRemovedTranslation = t(
    "crawlOverview:row.tooltips.change.removed",
  );
  const tooltipChangeMissingTranslation = t(
    "crawlOverview:row.tooltips.change.missing",
  );

  const addedString = isNaN(addedNumber)
    ? naTranslation
    : addedNumber.toLocaleString();
  const removedString = isNaN(removedNumber)
    ? naTranslation
    : removedNumber.toLocaleString();
  const missingString = isNaN(missingNumber)
    ? naTranslation
    : missingNumber.toLocaleString();

  const changesTooltipDescription = `${addedString} ${tooltipChangeAddedTranslation} / ${removedString} ${tooltipChangeRemovedTranslation} / ${missingString} ${tooltipChangeMissingTranslation}`;

  const tooltipPresent =
    (addedNumber || 0) + (removedNumber || 0) + (missingNumber || 0) > 0;

  const tooltipTitle = t("crawlOverview:row.tooltips.change.title");
  const showChangePercentage =
    report.change !== undefined &&
    report.reportTemplateTotalSign !== undefined &&
    report.reportTemplateTotalSign !== null &&
    report.basic !== undefined &&
    report.basic !== null &&
    report.change !== 0 &&
    report.change !== null &&
    report.basic !== 0;

  const changeNum = report.change ?? 0;
  const value = Math.abs(changeNum / ((report.basic ?? 0) - changeNum));

  const percentageChip = (
    <PercentageChangeChip
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sign={report.reportTemplateTotalSign!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      total={value > 10 ? changeNum : report.basic!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      change={report.change!}
      lang={i18n.language}
    />
  );

  const numberToShow = (
    <MonospaceNumber className={classes.change}>
      {report && !!report.change && report.change > 0 && "+"}
      {isNaN(Number(report.change)) || report.change === null
        ? naTranslation
        : t("units:number", { count: report.change ?? undefined })}{" "}
    </MonospaceNumber>
  );

  const isCustomReport = isCustomReportGUID(report.id);

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: getCustomReportTemplateId(report.id),
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
      data-testid="all-reports-table-row-change-percentage"
    >
      <Tooltip
        arrow
        placement="bottom"
        title={
          tooltipPresent ? (
            <>
              <Typography variant="subtitle2Medium">{tooltipTitle}</Typography>
              <Typography variant="caption">
                {changesTooltipDescription}
              </Typography>
            </>
          ) : (
            ""
          )
        }
      >
        <div className={classes.wrapper}>
          {numberToShow}
          <div className={classes.percentageContainer}>
            {showChangePercentage && percentageChip}
          </div>
        </div>
      </Tooltip>
    </ReportLink>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    whiteSpace: "nowrap",
    lineHeight: theme.typography.pxToRem(20),
  },
  percentageContainer: {
    padding: theme.spacing(0, 0, 0, 0.75),
  },
  change: {
    fontWeight: 500,
  },
}));
