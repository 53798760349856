import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LinearProgress, Tooltip, makeStyles } from "@material-ui/core";
import { useNumberFormatter, useTranslation } from "../../i18n";
import { Typography } from "../typography/Typography";
import { InformationCircleOutlined } from "../../icons/ui-friendly/outlined/InformationCircleOutlined";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(1.375, 0),
    },
    container: {
        width: 200,
        height: 8,
        background: "#EBEFF3",
        border: "1px solid #D1D5DB",
        borderRadius: 4,
    },
    indicator: {
        width: 200,
        height: 8,
        margin: -1,
        background: "#EBEFF300",
        borderRadius: 4,
    },
    indicatorGreen: {
        background: theme.palette.green[500],
    },
    indicatorYellow: {
        background: theme.palette.yellow[500],
    },
    indicatorRed: {
        background: theme.palette.red[600],
    },
    valueYellow: {
        color: theme.palette.yellow[500],
    },
    valueRed: {
        color: theme.palette.red[600],
    },
    valueGreen: {
        color: theme.palette.green[700],
    },
    text: {
        color: theme.palette.grey[700],
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: 600,
        marginLeft: theme.spacing(1),
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
    },
    infoIcon: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(18),
        marginLeft: theme.spacing(0.5),
    },
    title: {
        display: "block",
        marginBottom: theme.spacing(1.125),
    },
    link: {
        color: theme.palette.blue[600],
    },
}));
export function UsageLimitIndicator(props) {
    const { usageCount, usageLimit } = props;
    const { t } = useTranslation("usageLimitIndicator");
    const classes = useStyles();
    const formatNumber = useNumberFormatter();
    const usagePercentage = (usageCount / Math.max(usageLimit, 1)) * 100;
    const valueClassName = getValueClassName(usagePercentage);
    const indicatorClassName = getIndicatorClassName(usagePercentage);
    return (_jsxs("div", { className: classes.root, children: [_jsx("div", { className: classes.container, children: _jsx(LinearProgress, { "aria-labelledby": "usage-limit-description", variant: "determinate", value: Math.min(Math.max(usagePercentage, 0), 100), className: classes.indicator, classes: { bar: classes[indicatorClassName] } }) }), _jsxs(Typography, { id: "usage-limit-description", variant: "overline", className: classes.text, children: [_jsx("span", { className: valueClassName ? classes[valueClassName] : undefined, children: formatNumber(usageCount) }), " ", "/ ", formatNumber(usageLimit), " ", props.unit, " ", t("used")] }), usagePercentage >= 100 && props.limitReachedMessage ? (_jsx(Tooltip, { title: props.limitReachedMessage, placement: "top", interactive: true, children: _jsx(InformationCircleOutlined, { className: classes.infoIcon }) })) : null] }));
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getIndicatorClassName(usagePercentage) {
    if (usagePercentage >= 90)
        return "indicatorRed";
    if (usagePercentage >= 80)
        return "indicatorYellow";
    return "indicatorGreen";
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getValueClassName(usagePercentage) {
    if (usagePercentage >= 90)
        return "valueRed";
    if (usagePercentage >= 80)
        return "valueYellow";
    else
        return "valueGreen";
}
