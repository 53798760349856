import { debounce, DebouncedFunc, DebounceSettings } from "lodash";
import { useRef } from "react";

export interface DebounedFunctionResult {
  debounce: (fn: () => void) => void;
  cancel: () => void;
}

export function useDebounedFunction(
  wait?: number,
  options?: DebounceSettings,
): DebounedFunctionResult {
  const functionRef = useRef<DebouncedFunc<() => void> | undefined>(undefined);

  return {
    debounce: (fn) => {
      functionRef.current?.cancel();
      functionRef.current = debounce(fn, wait, options);
      functionRef.current();
    },
    cancel: () => {
      functionRef.current?.cancel();
    },
  };
}
