import { ReportCategoryChartConfig } from "../types";
import { getAnalyticsBreakdownChart } from "../../../../_common/charts/config/getAnalyticsBreakdownChart";
import { getAnalyticsTrafficFunnel } from "../../../../_common/charts/config/getAnalyticsTrafficFunnel";
import { getAnalyticsTrafficFunnelTrend } from "../../../../_common/charts/config/getAnalyticsTrafficFunnelTrend";
import { getCrawlSourceAnalyticsTrend } from "../../../../_common/charts/config/getCrawlSourceAnalyticsTrend";

export const generateTrafficAnalyticsCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAnalyticsTrafficFunnel,
      getAnalyticsTrafficFunnelTrend,
      getAnalyticsBreakdownChart,
      getCrawlSourceAnalyticsTrend,
    ],
  };
