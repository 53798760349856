import { CopyIcon, useTranslation } from "@lumar/shared";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useState } from "react";
import clsx from "clsx";
import React from "react";

interface TruncateProps {
  value: React.ReactNode;
  stringValue?: string;
  tooltipValue?: React.ReactNode;
  tooltipStringValue?: string;
  isCard?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "inline-block",
    "&:hover $button": {
      visibility: "visible",
    },
  },
  text: {
    display: "-webkit-box",
    "-webkit-line-clamp": 10,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    position: "relative",
    paddingRight: theme.spacing(3),
    marginRight: theme.spacing(-2),
  },
  textCard: {
    "-webkit-line-clamp": 3,
  },
  icon: {
    fontSize: 14,
  },
  button: {
    position: "absolute",
    right: -24,
    bottom: -8,
    padding: 5,
    color: theme.palette.grey[500],
    "&:hover": { color: theme.palette.primary.main },
    visibility: "hidden",
  },
  tooltipWide: {
    minWidth: 600,
  },
  endMark: {
    position: "relative",
    display: "inline-block",
  },
  endMarkTruncated: {
    position: "absolute",
    bottom: 4,
    right: 36,
  },
  tooltipContent: {
    whiteSpace: "break-spaces",
    display: "-webkit-box",
    "-webkit-line-clamp": 20,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
}));

export function Truncate(props: TruncateProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");
  const [copied, setCopied] = useState(false);

  const value = props.value;
  const stringValue =
    props.stringValue ?? (typeof value === "string" ? value : "");
  const tooltipValue = props.tooltipValue ?? props.value;
  const tooltipStringValue =
    props.tooltipStringValue ??
    (typeof tooltipValue === "string" ? tooltipValue : stringValue);

  const onClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(tooltipStringValue);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const ref = React.useRef<HTMLSpanElement | null>(null);
  const [truncated, setTruncated] = React.useState(false);
  // Note: Changing the column width triggers a re-render of the cell component.
  // Checking the truncation on every render ensures the correct value is set.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (!ref.current) return;

    const buttonBottomOffset = 4;
    const truncatedNew =
      ref.current.scrollHeight - buttonBottomOffset > ref.current.offsetHeight;
    if (truncatedNew !== truncated) setTruncated(truncatedNew);
  });

  return (
    <div className={classes.container}>
      <Tooltip
        interactive
        title={
          truncated ? (
            <div className={classes.tooltipContent}>{tooltipValue}</div>
          ) : (
            ""
          )
        }
        classes={{
          tooltip: clsx({
            [classes.tooltipWide]: tooltipStringValue.length > 500,
          }),
        }}
      >
        <span
          className={clsx({
            [classes.text]: true,
            [classes.textCard]: props.isCard,
          })}
          ref={ref}
        >
          {value}
          <span
            className={clsx({
              [classes.endMark]: true,
              [classes.endMarkTruncated]: truncated,
            })}
          >
            <Tooltip title={copied ? t("copied") : t("copy")} arrow>
              <IconButton
                color="primary"
                onClick={onClick}
                className={classes.button}
                tabIndex={1}
              >
                {copied ? (
                  <Check className={classes.icon}></Check>
                ) : (
                  <CopyIcon className={classes.icon}></CopyIcon>
                )}
              </IconButton>
            </Tooltip>
          </span>
        </span>
      </Tooltip>
    </div>
  );
}
