/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getLinksBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    hideChartWithoutData: true,
    title: () => t("linksBreakdown.title"),
    description: () => t("linksBreakdown.description"),
    reportTemplateCodesOrder: [
      "unique_internal_links",
      "all_hreflang_links",
      "all_rel_links", // Is Canonical Links
      "mobile_links",
      "rel_links",
      "pagination_links",
    ],
    reportStatFilter: (report) => {
      return [
        "unique_internal_links",
        "all_hreflang_links",
        "all_rel_links", // Is Canonical Links
        "mobile_links",
        "rel_links",
        "pagination_links",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
