import { isEqual } from "lodash";
import { useRef } from "react";

export function useValueChanged<T>(value: T): {
  isChanged: boolean;
  previousValue: T;
} {
  const valueRef = useRef<T>(value);
  const previousValue = valueRef.current;
  valueRef.current = value;

  return {
    isChanged: !isEqual(value, previousValue),
    previousValue: previousValue,
  };
}
