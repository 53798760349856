import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getAnalyticsTrafficFunnel(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("analyticsTrafficFunnel.title"),
    description: t("analyticsTrafficFunnel.description"),
    requiredSources: {
      sources: [CrawlType.GoogleAnalytics],
      incompleteMessage: t("requiredSourcesMessage.analyticsSource"),
    },
    reportTemplateCodes: [
      "primary_pages_with_visits",
      "primary_pages_with_no_visits",
      "duplicate_pages_with_visits",
      "redirecting_pages_with_visits",
      "non_indexable_pages_with_visits",
      "error_pages_with_visits",
    ],
  })();
}
