import React from "react";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Alert,
  Typography,
  useDateFormatter,
  useTranslation,
  ApolloError,
  isNonEmpty,
} from "@lumar/shared";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { PreviewSegmentQuery } from "../../graphql";
import { ReportCountTotals } from "../../_common/report-count-totals/ReportCountTotals";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: 0,
    },
    table: {
      borderRadius: 0,
      borderBottomWidth: 0,
    },
    totalCount: {
      color: theme.palette.grey[700],
    },
  }),
);

interface PreviewSegmentContentProps {
  data: PreviewSegmentQuery | undefined;
  previewLoading: boolean;
  error: ApolloError | undefined;
}

export function PreviewSegmentContent(
  props: PreviewSegmentContentProps,
): JSX.Element {
  const classes = useStyles();
  const dateFormatter = useDateFormatter();
  const { t } = useTranslation(["common", "segments"]);

  const crawlFinishedAt = props.data?.crawl?.finishedAt;
  const totalUrlsInReport = props.data?.crawl?.reportStats[0].rows?.totalCount;
  const urlRows = props.data?.crawl?.reportStats[0].rows?.nodes;

  const errorMessage =
    props.error?.graphQLErrors[0]?.message ??
    props.error?.clientErrors[0]?.message ??
    props.error?.networkError?.message ??
    props.error?.protocolErrors[0]?.message ??
    "";

  return (
    <DialogContent className={classes.content}>
      {props.previewLoading && (
        <Box my={1} display="flex" justifyContent="center">
          <CircularProgress data-testid="loading-spinner" />
        </Box>
      )}
      {props.error && <Alert severity="error">{errorMessage}</Alert>}
      {!props.previewLoading && !props.error && urlRows && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={2.25}
            paddingTop={2}
            paddingBottom={3.5}
          >
            <Typography
              variant="subtitle2SemiBold"
              className={classes.totalCount}
            >
              <ReportCountTotals
                id="preview-segment-total-urls"
                totalUrlsInReport={totalUrlsInReport}
                // TODO: Remove on second iteration of preview segments once we enable pagination
                // and are no longer limited to a sample of 50. Remember to remove the temporarySuffix
                // prop from the ReportCountTotals component once done - Saul.
                temporarySuffix={t("segments:temporarySuffix")}
              />
            </Typography>
            {crawlFinishedAt && (
              <Typography
                variant="subtitle3Medium"
                data-testid="last-crawl-date-time"
              >
                {t("segments:previewData")}{" "}
                {dateFormatter(new Date(crawlFinishedAt), {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </Typography>
            )}
          </Box>
          {urlRows.length > 0 && (
            <TableContainer classes={{ root: classes.table }}>
              <Table
                aria-labelledby="preview-segment-total-urls"
                data-testid="preview-segment-total-urls"
              >
                <TableBody>
                  {urlRows.map((urlRow, index) => (
                    <TableRow key={index} hover>
                      <TableCell>
                        {isNonEmpty(urlRow) ? urlRow.url : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </DialogContent>
  );
}
