/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getMobileConfigurationChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("mobileConfiguration.title"),
    description: () => t("mobileConfiguration.description"),
    reportTemplateCodesOrder: [
      "mobile_desktop_responsive",
      "mobile_desktop_dynamic",
      "mobile_desktop_separate_desktop",
      "mobile_desktop_separate_mobile",
      "mobile_desktop_amphtml",
      "no_mobile_configuration",
    ],
    reportStatFilter: (report) => {
      return [
        "mobile_desktop_responsive",
        "mobile_desktop_dynamic",
        "mobile_desktop_separate_desktop",
        "mobile_desktop_separate_mobile",
        "mobile_desktop_amphtml",
        "no_mobile_configuration",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
