import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox } from "@lumar/shared";

import { getRawLogzioProjectQueryId } from "../../../../../_common/api/getRawLogzioProjectQueryId";
import { LogzioConnectionValues } from "./useLogzioQueries";
import { FormValues, useLogzioFormValues } from "./useLogzioFormValues";
import { useLogzioSchema } from "./useLogzioSchema";
import { LogzioForm } from "./LogzioForm";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    marginTop: theme.spacing(1),
  },
}));

export interface LogzioQueryProps {
  id: string;
  enabled: boolean;
  connections: LogzioConnectionValues[];
  enabledChanged: (enabled: boolean) => void;
  deleteQuery: () => void;
}

export function LogzioQuery({
  id,
  enabled,
  connections: userConnections,
  enabledChanged,
  deleteQuery,
}: LogzioQueryProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const { formValues, loading, connections, getData, update } =
    useLogzioFormValues(id, userConnections);
  const schema = useLogzioSchema();

  const [querryErrors, setQuerryErrors] = React.useState<string[]>([]);
  const [expanded, setExpanded] = React.useState(false);
  const [dataMounted, setDataMounted] = React.useState(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    const errors = await update(values);
    setQuerryErrors(errors);
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={schema}
    >
      {(form) => (
        <Accordion
          expanded={expanded}
          onChange={(_, expanded) => {
            setExpanded(expanded);
            if (expanded && !dataMounted) {
              setDataMounted(true);
              getData();
            }
          }}
          TransitionProps={{ unmountOnExit: dataMounted }}
          data-testid="crawl-settings-logzio-queries"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Checkbox
                value={enabled}
                onClick={(event) => {
                  event.stopPropagation();
                  enabledChanged(!enabled);
                }}
                checked={enabled}
                size="small"
                style={{ marginRight: "8px" }}
              />
              <Typography variant="caption">
                {t("sources.logSummary.logzio.query.queryNr")}
                {getRawLogzioProjectQueryId(id)}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.content}>
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <>
                  <LogzioForm
                    form={form}
                    connections={connections}
                    querryErrors={querryErrors}
                  />
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => deleteQuery()}
                    >
                      {t("sources.logSummary.logzio.query.delete")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={form.submitForm}
                    >
                      {t("sources.logSummary.logzio.query.save")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </Formik>
  );
}
