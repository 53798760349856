import React from "react";
import {
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MapIcon from "@material-ui/icons/CompareArrows";
import { FastField, FastFieldProps, useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { Alert, Select, useNumberFormatter } from "@lumar/shared";

import { ProjectUploadType } from "../../../../graphql";
import { UploadTemplate, FileUpload, FormValues } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 500,
  },
  icon: {
    transform: "translateY(-5%)",
    verticalAlign: "middle",
    fontSize: theme.typography.fontSize,
    marginRight: theme.spacing(1),
  },
  tableContainer: {
    maxHeight: "300px",
  },
  tableCellIndexEditable: {
    background: theme.palette.grey[200],
    cursor: "pointer",
  },
  tableCellHeader: {
    whiteSpace: "nowrap",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
  },
  mapIcon: {
    fontSize: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function ManualUploadsSettingsTable({
  file,
  sample,
}: {
  file: FileUpload;
  sample: string[][];
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const {
    setFieldValue,
    values: formValues,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const metrics = React.useRef(formValues.metrics);
  metrics.current = formValues.metrics;

  const handleRowNumberChanged = React.useCallback(
    (index: number): void => {
      setFieldValue("headerRow", index);
      setFieldValue(
        "metrics",
        metrics.current.map((metric) => ({ ...metric, index: undefined })),
        true,
      );
    },
    [setFieldValue],
  );

  const handleMetriChanged = React.useCallback(
    (code: string, index: number | undefined): void => {
      setFieldValue("uploadType", ProjectUploadType.Custom);
      setFieldValue(
        "metrics",
        metrics.current.map((metric) => ({
          ...metric,
          index:
            metric.code === code
              ? index
              : metric.index === index
                ? undefined
                : metric.index,
        })),
        true,
      );
    },
    [setFieldValue],
  );

  const isCustom = file.uploadType === ProjectUploadType.Custom;

  return (
    <>
      <Alert severity="info" style={{ marginBottom: "15px" }}>
        {isCustom ? (
          <Trans
            ns="crawlSettings"
            i18nKey="sources.manualUpload.description"
            components={{
              icon: <MapIcon classes={{ root: classes.icon }} />,
            }}
          />
        ) : formValues.headerRow !== undefined ? (
          <Trans
            ns="crawlSettings"
            i18nKey="sources.manualUpload.descriptionWithRows"
            values={{
              fileName: file.fileName,
              startRow: (formValues.headerRow || 0) + 1,
            }}
            components={{
              bold: <span className={classes.bold} />,
              icon: <MapIcon classes={{ root: classes.icon }} />,
            }}
          />
        ) : (
          t("sources.manualUpload.descriptionWithoutRows")
        )}
      </Alert>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        elevation={0}
      >
        <Table>
          <SampleTableBody
            sample={sample}
            headerRow={formValues.headerRow}
            enableRowNumberChange={!isSubmitting && isCustom}
            onRowNumberChange={handleRowNumberChanged}
            onMetricChange={handleMetriChanged}
          />
        </Table>
      </TableContainer>
    </>
  );
}

const SampleTableBody = React.memo(function SampleTableBody({
  sample,
  headerRow,
  enableRowNumberChange,
  onRowNumberChange,
  onMetricChange,
}: {
  sample: string[][];
  headerRow?: number;
  enableRowNumberChange: boolean;
  onRowNumberChange: (index: number) => void;
  onMetricChange: (code: string, index: number | undefined) => void;
}): JSX.Element {
  const classes = useStyles();
  const numberFormatter = useNumberFormatter();

  return (
    <TableBody>
      {sample.map((row, idx) => (
        <TableRow
          key={idx}
          data-testid="manual-upload-row"
          style={{ height: "unset" }}
        >
          <TableCell
            onClick={() => enableRowNumberChange && onRowNumberChange(idx)}
            width="7%"
            className={
              enableRowNumberChange ? classes.tableCellIndexEditable : undefined
            }
            data-testid="manual-upload-row-number"
          >
            <Typography variant="caption">
              {numberFormatter(idx + 1)}
            </Typography>
          </TableCell>
          {idx === headerRow ? (
            <SampleTableHeaderCells row={row} onMetricChange={onMetricChange} />
          ) : (
            <SampleTableCells row={row} />
          )}
        </TableRow>
      ))}
    </TableBody>
  );
});

const SampleTableCells = React.memo(function SampleTableCells({
  row,
}: {
  row: string[];
}): JSX.Element {
  return (
    <>
      {row.map((value, idx) => (
        <TableCell key={idx} style={{ height: "unset" }}>
          <Typography variant="caption">{value}</Typography>
        </TableCell>
      ))}
    </>
  );
});

const SampleTableHeaderCells = React.memo(function SampleTableHeaderCells({
  row,
  onMetricChange,
}: {
  row: string[];
  onMetricChange: (code: string, index: number | undefined) => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  return (
    <>
      {row.map((rowValue, idx) => (
        <TableCell key={idx} className={classes.tableCellHeader}>
          <div className={classes.headerBox}>
            <Typography variant="caption" style={{ fontWeight: 500 }}>
              {rowValue}
            </Typography>
            <MapIcon classes={{ root: classes.mapIcon }} />
            <FastField name="metrics">
              {({
                field: { value },
                form: { isSubmitting },
              }: FastFieldProps<UploadTemplate["metrics"]>) => {
                const metric = value.find((x) => x.index === idx)?.code || "";

                return (
                  <Select
                    value={metric}
                    onChange={(e) => {
                      const newValue = e.target.value as string;
                      if (newValue === "None")
                        onMetricChange(metric, undefined);
                      else onMetricChange(e.target.value as string, idx);
                    }}
                    disabled={isSubmitting}
                    displayEmpty
                    data-testid="manual-upload-header"
                  >
                    <MenuItem value="" disabled>
                      {t("sources.manualUpload.selectMetric")}
                    </MenuItem>
                    {value.map((metric) => (
                      <MenuItem
                        key={metric.code}
                        value={metric.code}
                        data-testid="manual-upload-header-option"
                      >
                        {metric.name}
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem value="None">
                      {t("sources.manualUpload.noMetric")}
                    </MenuItem>
                  </Select>
                );
              }}
            </FastField>
          </div>
        </TableCell>
      ))}
    </>
  );
});
