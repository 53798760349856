import { useParams } from "react-router-dom";
import {
  GetTaskReportPageDataQuery,
  useGetTaskReportPageDataQuery,
} from "../../graphql";
import { ApolloError } from "@lumar/shared";

export type TaskReports = {
  loading: boolean;
  error?: ApolloError;
  task?: NonNullable<GetTaskReportPageDataQuery["getLegacyTask"]>;
};

export function useTaskReports(): TaskReports {
  const { taskId } = useParams<{ taskId: string }>();

  const { loading, error, data } = useGetTaskReportPageDataQuery({
    variables: {
      taskId,
    },
  });

  return { loading, error, task: data?.getLegacyTask ?? undefined };
}
