import { makeStyles, SvgIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Button } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  projectSettings: {
    color: theme.palette.primary.main,
    background: "#f0f3f7",
  },
}));

export function ProjectViewerLink({
  disabled,
  label,
  to,
  Icon,
  className,
  ...props
}: {
  disabled?: boolean;
  to: string | (() => void);
  label: string;
  Icon: typeof SvgIcon;
  className?: string;
}): JSX.Element | null {
  const classes = useStyles();

  if (typeof to === "string") {
    return (
      <Button
        variant="outlined"
        size="small"
        component={Link}
        to={to}
        className={clsx(className, classes.projectSettings)}
        {...props}
        startIcon={<Icon />}
        disabled={disabled}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      disableElevation
      disabled={disabled}
      onClick={to}
      className={clsx(className, classes.projectSettings)}
      {...props}
      startIcon={<Icon />}
    >
      {label}
    </Button>
  );
}
