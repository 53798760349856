import { ReportSummaryInfo, ReportAdviceInfo, isReportAdvice } from "./types";
import { SvgIcon } from "@material-ui/core";
import { BookOpen, Warning, Wrench, ShieldCheck } from "@lumar/shared";
import { TFunction } from "i18next";

export function getInfoEntries(
  info: ReportSummaryInfo | ReportAdviceInfo,
  t: TFunction<"report">,
): {
  title: string;
  icon?: typeof SvgIcon;
  content: string;
}[] {
  if (isReportAdvice(info)) {
    return [
      {
        title: t("reportAdviceDefinition", { reportName: info.reportName }),
        icon: BookOpen,
        content: info.definition,
      },
      {
        title: t("reportAdviceEffect"),
        icon: Warning,
        content: info.effect,
      },
      {
        title: t("reportAdviceSolution"),
        icon: Wrench,
        content: info.solutions,
      },
      {
        title: t("reportAdviceBenefit"),
        icon: ShieldCheck,
        content: info.benefit,
      },
    ];
  } else {
    return [
      ...(info.summary
        ? [
            {
              title: t("reportTitleInfoFirst"),
              icon: BookOpen,
              content: info.summary,
            },
          ]
        : []),
      ...(info.description
        ? [
            {
              title: t("reportTitleInfoSecond"),
              icon: Wrench,
              content: info.description,
            },
          ]
        : []),
    ];
  }
}
