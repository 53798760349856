import React from "react";
import { Snackbar, ApolloError, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { addDays, addMonths, addWeeks, addYears } from "date-fns";
import { useCreateShareLinkMutation } from "../../graphql";
import { LATEST_CRAWL_PARAM } from "./useLatestCrawlRedirect";

export interface ShareLinkState {
  isPublic: boolean;
  expiration: ExpirationOption;
  crawl: CrawlOption;
}

export enum ExpirationOption {
  Day = "A Day",
  Week = "A Week",
  Month = "A Month",
  SixMonths = "6 Months",
  Never = "Never",
}

export enum CrawlOption {
  Current = "Current Crawl",
  Latest = "Latest Crawl",
}

interface ShareLinkResult {
  state: ShareLinkState | undefined;
  setState: (state: ShareLinkState) => Promise<void>;
  shareLink: string;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useShareLink({
  getAdditionalParams,
}: {
  getAdditionalParams?: () => string[];
}): ShareLinkResult {
  const { projectId } = useParams<{ projectId?: string }>();
  const { t } = useTranslation("createShareLink");
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = React.useState<ShareLinkState | undefined>(
    undefined,
  );

  const [createShareLinkToken, { data, loading, error }] =
    useCreateShareLinkMutation();
  const token = data?.shareProject.authToken.value;

  const handleStateChange = React.useCallback(
    async (state: ShareLinkState): Promise<void> => {
      const updateToken = async (
        expiration: ExpirationOption,
      ): Promise<void> => {
        if (!projectId) return;

        try {
          await createShareLinkToken({
            variables: {
              projectId,
              expirationDate:
                createDateFromExpirationOption(expiration).toISOString(),
            },
          });
        } catch {
          enqueueSnackbar(
            <Snackbar
              variant="error"
              title={t("expirationDateErrorMessage")}
            />,
          );
        }
      };

      setState((oldState) => {
        if (state.expiration !== oldState?.expiration)
          updateToken(state.expiration);

        return state;
      });
    },
    [createShareLinkToken, enqueueSnackbar, projectId, t],
  );

  return {
    state,
    setState: handleStateChange,
    shareLink: createShareLink(state, token, getAdditionalParams),
    loading,
    error,
  };
}

function createShareLink(
  state: ShareLinkState | undefined,
  token: string | undefined,
  getParams?: () => string[],
): string {
  const params: [string, unknown][] = [
    [`token=${token}`, state?.isPublic && token],
    [`${LATEST_CRAWL_PARAM}=true`, state?.crawl === CrawlOption.Latest],
    ...(getParams?.().map<[string, true]>((x) => [x, true]) || []),
  ];
  const search = params
    .filter((x) => x[1])
    .map((x) => x[0])
    .join("&");

  if (!search.length) return window.location.href;
  return `${window.location.href}${
    window.location.search ? "&" : "?"
  }${search}`;
}

export function createDateFromExpirationOption(
  expirationOption: ExpirationOption,
): Date {
  const now = new Date();

  switch (expirationOption) {
    case ExpirationOption.Day:
      return addDays(now, 1);
    case ExpirationOption.Week:
      return addWeeks(now, 1);
    case ExpirationOption.Month:
      return addMonths(now, 1);
    case ExpirationOption.SixMonths:
      return addMonths(now, 6);
    case ExpirationOption.Never:
      return addYears(now, 100);
    default:
      return now;
  }
}
