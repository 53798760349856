import { Project, UpdateProjectInput } from "../../../../../graphql";
import { LinkRestrictionsSettings } from "../types";

type QueryData = Pick<
  Project,
  | "crawlHyperlinksInternal"
  | "crawlNofollowHyperlinks"
  | "crawlRelCanonicalsInternal"
  | "crawlRelNextPrevInternal"
  | "crawlRelMobileInternal"
  | "crawlRelAmphtmlInternal"
  | "crawlRelHreflangsInternal"
>;

export function formatLinkRestrictionsSettingsFrom(
  data?: QueryData | null,
): LinkRestrictionsSettings {
  return {
    crawlFollowAnchorLinks: data?.crawlHyperlinksInternal ?? false,
    crawlNoFollowAnchorLinks: data?.crawlNofollowHyperlinks ?? false,
    crawlCanonicalLinks: data?.crawlRelCanonicalsInternal ?? false,
    crawlPaginationLinks: data?.crawlRelNextPrevInternal ?? false,
    crawlMobileAlternateLinks: data?.crawlRelMobileInternal ?? false,
    crawlAmphtmlLinks: data?.crawlRelAmphtmlInternal ?? false,
    crawlHreflangLinks: data?.crawlRelHreflangsInternal ?? false,
  };
}

export function formatLinkRestrictionsSettingsTo(
  formValues: LinkRestrictionsSettings,
): Pick<
  UpdateProjectInput,
  | "crawlHyperlinksInternal"
  | "crawlNofollowHyperlinks"
  | "crawlRelCanonicalsInternal"
  | "crawlRelNextPrevInternal"
  | "crawlRelMobileInternal"
  | "crawlRelAmphtmlInternal"
  | "crawlRelHreflangsInternal"
> {
  return {
    crawlHyperlinksInternal: formValues.crawlFollowAnchorLinks,
    crawlNofollowHyperlinks: formValues.crawlNoFollowAnchorLinks,
    crawlRelCanonicalsInternal: formValues.crawlCanonicalLinks,
    crawlRelNextPrevInternal: formValues.crawlPaginationLinks,
    crawlRelMobileInternal: formValues.crawlMobileAlternateLinks,
    crawlRelAmphtmlInternal: formValues.crawlAmphtmlLinks,
    crawlRelHreflangsInternal: formValues.crawlHreflangLinks,
  };
}
