import { isObject } from "lodash";
import { ObjectPresenter } from "./ObjectPresenter";

export function ArrayPresenter(props: {
  value: (string | number | Record<string, string | number>)[];
  chipClassName?: string;
}): JSX.Element {
  const isObjectArray = isObject(props.value[0]);
  return (
    <>
      {isObjectArray
        ? props.value.map((v) => (
            <ObjectPresenter
              chipClassName={props.chipClassName}
              key={JSON.stringify(v)}
              value={v}
            ></ObjectPresenter>
          ))
        : props.value.map((v) => (
            <div className={props.chipClassName} key={v.toString()}>
              {v || "-"}
            </div>
          ))}
    </>
  );
}
