import React from "react";
import { useParams } from "react-router-dom";
import { useGetExampleUrlScreenshotQuery } from "../../../../graphql";
import { Metrics } from "../../../data/types";
import { useTranslation } from "@lumar/shared";

interface Result {
  screenshot?: Blob;
  loading: boolean;
  error?: string;
}

export function useExampleUrlScreenshot(metrics: Metrics): Result {
  const { t } = useTranslation("resourceDetail");
  const { crawlId } = useParams<{
    crawlId: string;
  }>();

  const urlDigest: string | undefined = metrics["exampleUrlDigest"]?.value;

  const [result, setResult] = React.useState<Result>({ loading: true });

  useGetExampleUrlScreenshotQuery({
    variables: {
      crawlId,
      urlDigest: urlDigest || "",
    },
    skip: !urlDigest,
    fetchPolicy: "cache-first",
    onError: (error) => {
      setResult({ loading: false, error: error.message });
    },
    onCompleted: async (data) => {
      const screenshot = data?.report?.crawlUrls?.nodes[0]?.attachments?.find(
        (x) => x.name === "AccessibilityIssues/a11y-full-page-screenshot.png",
      );

      if (!screenshot) {
        setResult({ loading: false, error: t("genericError") });
        return;
      }

      const isExpired =
        new Date(screenshot.expiresAt).getTime() < new Date().getTime();
      if (isExpired) {
        setResult({
          loading: false,
          error: t("accessibilityIssue.screenshotExpired"),
        });
        return;
      }

      try {
        const response = await fetch(screenshot.signedLocation);
        const data = await response.blob();

        setResult({ loading: false, screenshot: data });
      } catch {}
    },
  });

  return result;
}
