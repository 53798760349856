import { ReportCategoryChartConfig } from "../types";
import { getCrawlSourceOverlapSitemapsChart } from "../../../../_common/charts/config/getCrawlSourceOverlapSitemapChart";
import { getCrawlSourceSitemapsTrend } from "../../../../_common/charts/config/getCrawlSourceSitemapsTrend";
import { getPagesinSitemapsBreakdownChart } from "../../../../_common/charts/config/getPagesinSitemapsBreakdownChart";
import { getPagesinSitemapsBreakdownTrend } from "../../../../_common/charts/config/getPagesinSitemapsBreakdownTrend";
import { getSitemapLinksIssuesChart } from "../../../../_common/charts/config/getSitemapLinksIssuesChart";
import { getSitemapsBreakdownChart } from "../../../../_common/charts/config/getSitemapsBreakdownChart";
import { getSitemapsChart } from "../../../../_common/charts/config/getSitemapsChart";
import { getSitemapsIssuesTrend } from "../../../../_common/charts/config/getSitemapsLinksIssuesTrend";
import { getSitemapsTrend } from "../../../../_common/charts/config/getSitemapsTrend";

export const generateCrawlabilitySitemapsCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getSitemapsChart, // CEMM: REP-148 Add Sitemaps with >5 redirects
      getSitemapsTrend,
      getPagesinSitemapsBreakdownChart,
      getPagesinSitemapsBreakdownTrend,
      getSitemapLinksIssuesChart,
      getSitemapsIssuesTrend,
      getSitemapsBreakdownChart,
      getCrawlSourceSitemapsTrend,
      getCrawlSourceOverlapSitemapsChart,
    ],
  };
