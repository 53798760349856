import { FormControl, makeStyles, MenuItem } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { CustomSkeleton } from "../_common/CustomSkeleton";
import {
  CalendarSolid,
  Select,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import { Routes } from "../_common/routing/routes";
import { useCrawlSelectorData } from "./useCrawlSelectorData";

interface CrawlSelectorProps {
  getPath: (crawlId: string) => string;
}

// FIXME: Use blue inputs instead.
const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "row",
    alignItems: "baseline",
    maxWidth: 230,
    minWidth: 230,
    width: 230,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: "9px",
    fontWeight: 500,
    lineHeight: "20px",
    alignSelf: "center",
    color: theme.palette.grey[700],
  },
  container: {
    width: "100%",
  },
  select: {
    paddingLeft: "37px !important",
  },
  icon: {
    fontSize: `${theme.typography.pxToRem(20)} !important`,
    color: theme.palette.grey[500],
  },
  inProgressMenuItem: {
    color: theme.palette.orange[600],
    textDecoration: "none",
    width: "100%",
  },
  menuItem: {
    padding: 0,
    paddingLeft: 8,
  },
}));

export function CrawlSelector(props: CrawlSelectorProps): JSX.Element {
  const { t } = useTranslation("crawlSelector");
  const classes = useStyles();
  const dateFormatter = useDateFormatter();

  const history = useHistory();

  const { projectId, crawlId, accountId } = useParams<{
    projectId: string;
    accountId: string;
    crawlId: string;
  }>();

  const { runningCrawl, lastFinishedCrawl, crawls, loading } =
    useCrawlSelectorData();

  const showLastCrawl =
    lastFinishedCrawl !== undefined &&
    !crawls.find((x) => x.id === lastFinishedCrawl.id);

  const crawlProgressRoute = Routes.Crawls.getUrl({
    accountId,
    projectId,
    tab: "progress",
  });
  const crawlHistoryRoute = Routes.Crawls.getUrl({
    accountId,
    projectId,
    tab: "history",
  });

  return (
    <FormControl hiddenLabel className={classes.formControl}>
      {loading ? (
        <CustomSkeleton
          width="230px"
          height="35.63px"
          variant="rect"
          animation="wave"
        />
      ) : (
        <Select
          aria-label={t("label")}
          data-testid="crawl-selector"
          value={crawlId}
          startIcon={<CalendarSolid className={classes.icon} />}
          className={classes.container}
          classes={{
            select: classes.select,
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "running-crawl") {
              history.push(crawlProgressRoute);
            } else if (value === "view-all") {
              history.push(crawlHistoryRoute);
            } else if (typeof value === "string") {
              history.push(props.getPath(value));
            }
          }}
        >
          {runningCrawl && (
            <MenuItem
              className={classes.inProgressMenuItem}
              data-testid="crawl-selector-option-running-crawl"
              value="running-crawl"
            >
              {t("crawling")}
            </MenuItem>
          )}
          {showLastCrawl && (
            <MenuItem
              value={lastFinishedCrawl.id}
              data-testid="crawl-selector-option"
              className={classes.menuItem}
            >
              {t("viewLatest")}
            </MenuItem>
          )}
          {crawls.map((crawl) => (
            <MenuItem
              key={crawl.id}
              value={crawl.id}
              data-testid="crawl-selector-option"
              className={classes.menuItem}
            >
              {dateFormatter(crawl.createdAt, {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </MenuItem>
          ))}
          <MenuItem
            value="view-all"
            data-testid="crawl-selector-view-all"
            className={classes.menuItem}
          >
            {t("viewAll")}
          </MenuItem>
        </Select>
      )}
    </FormControl>
  );
}
