import React from "react";
import { ApolloError } from "@lumar/shared";
import { useParams } from "react-router-dom";
import {
  Crawl,
  DatasourceCode,
  Maybe,
  ModuleCode,
  useGetMetricsQuery,
} from "../graphql";
import {
  FormattedMetrics,
  useFormattedMetrics,
} from "../_common/report-metrics/useFormattedMetrics";
import { convertCutomMetricTypeToMetricType } from "../resource-detail/data/getMetrics";

interface Result extends FormattedMetrics {
  loading: boolean;
  error?: ApolloError;
  latestFinishedCrawlData: Maybe<Pick<Crawl, "id" | "archivedAt">> | undefined;
}

export function useMetrics(): Result {
  const { projectId, crawlId } = useParams<{
    projectId: string;
    crawlId: string;
  }>();

  const { data, loading, error } = useGetMetricsQuery({
    variables: {
      projectId,
      crawlId,
      datasourceCode: DatasourceCode.CrawlUrls,
    },
    fetchPolicy: "cache-first",
  });

  const moduleCode = data?.getProject?.moduleCode;
  const filter = React.useCallback(
    (metric: FormattedMetrics["metrics"][0]) =>
      metric.code !== "customMetrics" && moduleCode === ModuleCode.Seo
        ? Boolean(metric.supportedModules?.includes(moduleCode))
        : metric.code === "url",
    [moduleCode],
  );

  const metrics = React.useMemo(() => {
    return (data?.datasource?.metrics || []).flatMap((metric) => {
      if (metric.code !== "customMetrics") return [metric];

      const customMetricData = data?.getCrawl?.customMetrics || [];
      return customMetricData.map((x) => ({
        code: `customMetrics.${x.code}`,
        name: `${x.name} *`,
        description: "",
        type: convertCutomMetricTypeToMetricType(x.type),
        connectionPredicates: x.connectionPredicates ?? [],
        supportedModules: metric.supportedModules,
        metadata: metric.metadata,
      }));
    });
  }, [data?.datasource?.metrics, data?.getCrawl?.customMetrics]);

  return {
    ...useFormattedMetrics({
      customExtractionData: data?.getProject?.customExtractions,
      metricsData: metrics,
      filter,
    }),
    loading,
    error,
    latestFinishedCrawlData: data?.getProject?.lastFinishedCrawl,
  };
}
