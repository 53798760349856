import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getUncrawledUrlsBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("uncrawledURLs.title"),
    description: t("uncrawledURLs.description"),
    reportTemplateCodes: [
      "malformed_urls",
      "disallowed_urls",
      "external_urls",
      "other_uncrawled_(invalid)",
      "excluded_urls",
      "not_included_urls",
      "page_group_restricted_urls",
    ],
    testAttributePrefix: "uncrawled-urls-chart",
  })();
}
