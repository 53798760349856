import React from "react";
import {
  Alert,
  Button,
  ToggleIconButton,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Grid, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { FastField, FieldArray } from "formik";
import { AddCircle, Delete } from "@material-ui/icons";
import { uniqBy } from "lodash";

import { FormValues, RequestHeader } from "../data/types";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { CUSTOM_REQUEST_HEADER_MAX } from "../data/useSettingsValidationSchema";
import { SettingControlFactory } from "../useAdvancedControls";

export const getCustomRequestHeadersControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.customRequestHeader.title"),
  path: "customRequestHeaders",
  // eslint-disable-next-line react/display-name
  control: () => <CustomRequestHeaders />,
  testId: "crawl-settings-advanced-custom-request-headers",
  activeValues: ["headers"],
  textForSearch: createTextForSearch([
    t("settings.customRequestHeader.title"),
    t("settings.customRequestHeader.description"),
    t("settings.customRequestHeader.addLabel"),
    t("settings.customRequestHeader.keyField"),
    t("settings.customRequestHeader.valueField"),
  ]),
  fieldNames: [
    ["headers", t("settings.customRequestHeader.headerField")],
    ["key", t("settings.customRequestHeader.keyField")],
    ["value", t("settings.customRequestHeader.valueField")],
  ],
});

const useStyles = makeStyles((theme) => ({
  desciption: {
    whiteSpace: "pre-line",
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  row: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  grid: {
    marginTop: theme.spacing(0),
    alignItems: "flex-start",
  },
  error: {
    marginTop: theme.spacing(1),
  },
}));

const CustomRequestHeaders = React.memo(CustomRequestHeadersInner);

function CustomRequestHeadersInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const createNew = (): RequestHeader => ({
    key: "",
    value: "",
  });

  return (
    <>
      <Typography className={classes.desciption}>
        {t("settings.customRequestHeader.description")}
      </Typography>

      <FieldArray name="spider.customRequestHeaders.headers">
        {({ push, remove, form }) => {
          const values = (form.values as FormValues).spider.customRequestHeaders
            .headers;
          const uniqueKeyCount = uniqBy(values, (x) => x.key).length;

          return (
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={() => push(createNew())}
                disabled={
                  form.isSubmitting ||
                  uniqueKeyCount >= CUSTOM_REQUEST_HEADER_MAX
                }
                className={classes.addButton}
                startIcon={<AddCircle />}
                data-testid="custom-request-header-add"
              >
                {t("settings.customRequestHeader.addLabel")}
              </Button>

              {uniqueKeyCount > CUSTOM_REQUEST_HEADER_MAX && (
                <Alert severity="error" className={classes.error}>
                  {t("settings.validationError.uniqueKey", {
                    max: CUSTOM_REQUEST_HEADER_MAX,
                  })}
                </Alert>
              )}
              {values.map((_, idx) => (
                <Paper key={idx} className={classes.row}>
                  <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`spider.customRequestHeaders.headers[${idx}].key`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder="Name"
                        className={classes.input}
                        data-testid="custom-request-header-key"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`spider.customRequestHeaders.headers[${idx}].value`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder="Value"
                        className={classes.input}
                        data-testid="custom-request-header-value"
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Tooltip
                        title={t("settings.customRequestHeader.removeTooltip")}
                        placement="top"
                      >
                        <ToggleIconButton
                          size="large"
                          variant="outlined"
                          colorVariant="red"
                          onClick={() => remove(idx)}
                          disabled={form.isSubmitting}
                          data-testid="custom-request-header-remove"
                        >
                          <Delete />
                        </ToggleIconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </div>
          );
        }}
      </FieldArray>
    </>
  );
}
