import { uniq } from "lodash";
/**
 * Use it instead of directly reading from `ApolloError.graphQLErrors` property
 * to avoid issues with long error messages and/or when you could expect multiple error messages from your query/mutation.
 *
 * @returns a string containing concatenated and deduplicated GQL error messages.
 */
export function getNormalizedGraphQLErrorMessage(error) {
    if (error.graphQLErrors.length === 0) {
        return undefined;
    }
    return uniq(error.graphQLErrors.map((e) => e.message)).join(", ");
}
