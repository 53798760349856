import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination, {
  TablePaginationProps,
} from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { orderBy } from "lodash";

import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { Order, Header } from "./types";
import { FormatLabelDisplayedRows } from "../pagination/formatLabelDisplayedRows";
import { Checkbox, useTranslation } from "@lumar/shared";

export interface SortModel {
  field: string;
  order: Order;
}

interface Props {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  headers: Header[];
  rows: any[];
  renderRow: (rowItem: any, rowIndex: number) => JSX.Element;
  renderLoadMore?: (rowItem: any) => JSX.Element;
  selected?: string[];
  disabled?: string[];
  setSelected?: (selected: string[]) => void;
  renderToolbarActions?: () => JSX.Element;
  renderToolbarSummary?: () => JSX.Element;
  tableProps?: { "data-testid"?: string };
  sortModel?: SortModel;
  onSortModelChange?: (model: SortModel) => void;
  isLoading?: boolean;
  stickyHeader?: boolean;
  disableSorting?: boolean;
  disableClientSorting?: boolean;
  containerClass?: string;
  paginationProps?: TablePaginationProps;
  customNoRowsMessage?: string | React.ReactNode;
  headerProps?: {
    classes?: {
      header?: string;
      headerRow?: string;
    };
  };
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  getRowDataAttributes?: (
    row: any,
  ) => Record<string, string | undefined> | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      minWidth: "100%",
      borderColor: theme.palette.grey[200],
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: theme.spacing(1),
    },
    table: {
      height: 1,
      minWidth: 750,
    },
    container: {
      background: "none",
      borderWidth: 0,
    },
    paddingCheckboxCell: {
      padding: theme.spacing(0.5, 1.75, 0.5, 3),
    },
    tr: {
      height: "100%",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 400,
    },
    td: {
      height: "100%",
    },
    stickyRow: {
      "& td": {
        background: "white",
        boxShadow: `0 -1px 0 0 ${theme.palette.grey[200]}`,
        position: "sticky",
        top: 40,
        zIndex: 10,
      },
    },
    pagination: {
      borderTop: "solid rgba(0,0,0,0.04) 1px",
    },
    loadMoreCell: {
      padding: theme.spacing(1.25, 2),
    },
  }),
);

/**
 *
 * @deprecated Use `<BlueGrid>` or Material UI Data Grid instead.
 */
export function EnhancedTable(props: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("enhancedTable");

  const {
    headers,
    rows,
    renderRow,
    renderLoadMore,
    selected,
    disabled,
    setSelected,
    renderToolbarActions,
    renderToolbarSummary,
    tableProps,
    sortModel,
    onSortModelChange,
    isLoading = false,
    stickyHeader = false,
    disableSorting = false,
    disableClientSorting = false,
    containerClass,
    paginationProps,
    headerProps,
    onScroll,
    getRowDataAttributes,
  } = props;

  const isSelected = (id: string): boolean =>
    Boolean(selected?.length) && selected?.indexOf(id) !== -1;

  const isDisabled = (id: string): boolean =>
    Boolean(disabled?.length) && disabled?.indexOf(id) !== -1;

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (!setSelected) return;

    if (event.target.checked) {
      const newSelecteds = rows
        .filter((n) => !isDisabled(n.id))
        .map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string): void => {
    if (!selected || !setSelected) return;

    /* eslint-disable fp/no-let  */
    /* eslint-disable fp/no-mutation  */
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ): void => {
    if (!onSortModelChange) return;

    const isCurrentlySortedColumnRequested = sortModel?.field === property;
    const isCurrentlyAscending = sortModel?.order === "asc";

    if (isCurrentlySortedColumnRequested) {
      onSortModelChange({
        field: property,
        order: isCurrentlyAscending ? "desc" : "asc",
      });
      return;
    }

    const header = headers.find((h) => h.id === property);
    const isRequestedColumnValueNumeric = Boolean(header?.numeric);
    const defualtSortOrderIsDesc = header?.firstSortOrder === "desc";

    onSortModelChange({
      field: property,
      order:
        isRequestedColumnValueNumeric || defualtSortOrderIsDesc
          ? "desc"
          : "asc",
    });
  };

  function getSortedRows(): any[] {
    const header = sortModel?.field
      ? headers.find((h) => h.id === sortModel?.field)
      : undefined;
    if (header?.sortComparator) {
      const sign = sortModel?.order === "desc" ? -1 : 1;
      // eslint-disable-next-line fp/no-mutating-methods
      return [...rows].sort(
        (a, b) => (header.sortComparator?.(a, b) ?? 0) * sign,
      );
    }

    if (header?.field) {
      return orderBy(rows, header.field, sortModel?.order);
    }

    return orderBy(rows, sortModel?.field, sortModel?.order);
  }

  const sortedRows = getSortedRows();

  const tableRows = disableSorting || disableClientSorting ? rows : sortedRows;
  const headersLength = headers.reduce(
    (acc, head) => acc + (head.colSpan ?? 1),
    0,
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        {selected && setSelected && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            numDisabled={disabled?.length || 0}
            rowCount={rows.length}
            onSelectAllClick={handleSelectAllClick}
            renderToolbarActions={renderToolbarActions}
            renderToolbarSummary={renderToolbarSummary}
          />
        )}
        <TableContainer
          className={clsx(classes.container, containerClass)}
          onScroll={onScroll}
        >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader={stickyHeader}
            {...tableProps}
          >
            <EnhancedTableHead
              selected={selected}
              headers={headers}
              sortModel={sortModel}
              onRequestSort={handleRequestSort}
              disableSorting={disableSorting}
              {...headerProps}
            />
            <TableBody data-testid="enhanced-table-body">
              {!isLoading &&
                tableRows.map((row, index) => {
                  const isItemSelected = selected && isSelected(row.id);
                  const isItemDisabled = disabled && isDisabled(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  if (row?.pageInfo?.hasNextPage && renderLoadMore) {
                    return (
                      <TableRow
                        key={`load-more-${index}`}
                        style={{ height: "100%" }}
                      >
                        <TableCell
                          colSpan={headersLength}
                          align="center"
                          className={classes.loadMoreCell}
                        >
                          {renderLoadMore(row)}
                        </TableCell>
                      </TableRow>
                    );
                  }

                  return (
                    <TableRow
                      key={row.id || index}
                      hover
                      className={clsx(
                        row.sticky && classes.stickyRow,
                        classes.tr,
                      )}
                      data-testid="enhanced-table-row"
                      {...getRowDataAttributes?.(row)}
                    >
                      {selected && (
                        <TableCell
                          padding="checkbox"
                          classes={{
                            paddingCheckbox: classes.paddingCheckboxCell,
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            disabled={isItemDisabled}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                            data-testid={labelId}
                          />
                        </TableCell>
                      )}
                      {renderRow(row, index)}
                    </TableRow>
                  );
                })}
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={headersLength} align="center">
                    <CircularProgress
                      data-testid="enhanced-table-spinner"
                      color="inherit"
                      size={20}
                    />
                  </TableCell>
                </TableRow>
              )}
              {!sortedRows.length && !isLoading && (
                <TableRow>
                  <TableCell colSpan={headersLength} align="center">
                    {props.customNoRowsMessage
                      ? props.customNoRowsMessage
                      : t("noDataAvailable")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paginationProps && (
          <TablePagination
            component="div"
            count={paginationProps.count}
            page={paginationProps.page}
            rowsPerPageOptions={paginationProps.rowsPerPageOptions}
            rowsPerPage={paginationProps.rowsPerPage}
            onPageChange={paginationProps.onPageChange}
            onRowsPerPageChange={paginationProps.onRowsPerPageChange}
            classes={{ root: classes.pagination }}
            labelDisplayedRows={FormatLabelDisplayedRows}
            SelectProps={{
              id: "pagination-displayed-rows-select",
            }}
          />
        )}
      </Paper>
    </div>
  );
}
