import { useTheme } from "@material-ui/core";

export function SiteSpeedIcon(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: theme.spacing(3.125) }}
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill={theme.palette.ultraviolet[50]}
      />
      <path
        d="M32 9.5C44.426 9.5 54.5 19.574 54.5 32C54.5 35.264 53.816 39.178 52.362 42.053C51.49 43.779 49.688 44.5 48.077 44.5H15.923C14.313 44.5 12.51 43.779 11.638 42.053C10.184 39.178 9.5 35.263 9.5 32C9.5 19.574 19.574 9.5 32 9.5Z"
        fill={theme.palette.ultraviolet[200]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 17.5091C23.659 17.5091 17 24.0561 17 32.0091C17 33.6991 17.299 35.3191 17.847 36.8241C18.0287 37.3225 18.0049 37.8728 17.7809 38.3537C17.5569 38.8346 17.151 39.2069 16.6525 39.3886C16.154 39.5702 15.6038 39.5464 15.1229 39.3224C14.6419 39.0984 14.2697 38.6925 14.088 38.1941C13.3663 36.2116 12.9981 34.1178 13 32.0081C13 21.7351 21.563 13.5081 32 13.5081C42.437 13.5081 51 21.7351 51 32.0081C51 34.1741 50.617 36.2561 49.912 38.1921C49.7305 38.6905 49.3583 39.0965 48.8775 39.3206C48.3966 39.5447 47.8465 39.5686 47.348 39.3871C46.8495 39.2055 46.4436 38.8334 46.2195 38.3526C45.9954 37.8717 45.9715 37.3215 46.153 36.8231C46.7149 35.2807 47.0016 33.6516 47 32.0101C47 24.0571 40.341 17.5101 32 17.5101V17.5091Z"
        fill={theme.palette.ultraviolet[600]}
      />
      <path
        d="M39.073 26.26C39.2446 25.6195 39.1547 24.937 38.8232 24.3628C38.4916 23.7886 37.9455 23.3696 37.305 23.198C36.6645 23.0264 35.9821 23.1162 35.4078 23.4478C34.8336 23.7794 34.4146 24.3255 34.243 24.966L30.853 37.62C29.5267 37.9064 28.353 38.6728 27.5575 39.7719C26.7619 40.8711 26.4005 42.2254 26.5428 43.5748C26.685 44.9242 27.3209 46.1734 28.3282 47.0825C29.3355 47.9916 30.6431 48.4964 32 48.5C33.1121 48.5 34.198 48.163 35.1146 47.5332C36.0312 46.9035 36.7354 46.0108 37.1343 44.9727C37.5331 43.9346 37.608 42.8 37.3489 41.7186C37.0898 40.6371 36.509 39.6596 35.683 38.915L39.073 26.26Z"
        fill={theme.palette.ultraviolet[600]}
      />
    </svg>
  );
}
