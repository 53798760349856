import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import React from "react";
import {
  useCloneProjectMutation,
  useDeleteAccountProjectMutation,
  useDeleteAccountProjectScheduleMutation,
  useDeleteCrawlMutation,
} from "../../graphql";
import { AccountProjectMutations } from "./types";

export function useAccountProjectsMutations(): AccountProjectMutations {
  const { t } = useTranslation("projectsList");
  const { enqueueSnackbar } = useSnackbar();

  const [deleteProjectMutation] = useDeleteAccountProjectMutation();

  const [cloneProjectMutation] = useCloneProjectMutation();

  const [cancelRunningCrawlMutation] = useDeleteCrawlMutation();

  const [deleteProjectScheduleMutation] =
    useDeleteAccountProjectScheduleMutation();

  const deleteProject = React.useCallback(
    async (projectId) => {
      try {
        await deleteProjectMutation({
          variables: { projectId },
          refetchQueries: ["AccountProjects"],
          awaitRefetchQueries: true,
        });
        enqueueSnackbar(
          <Snackbar variant="success" title={t("deletion.success")} />,
        );
        return true;
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(
          <Snackbar variant="error" title={t("deletion.error")}>
            {error.message}
          </Snackbar>,
        );
        return false;
      }
    },
    [deleteProjectMutation, enqueueSnackbar, t],
  );

  const cancelRunningCrawl = React.useCallback(
    async (crawlId) => {
      try {
        await cancelRunningCrawlMutation({
          variables: { crawlId },
          refetchQueries: ["AccountProjects"],
          awaitRefetchQueries: true,
        });
        enqueueSnackbar(
          <Snackbar variant="success" title={t("deletion.cancelSuccess")} />,
        );
        return true;
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(
          <Snackbar variant="error" title={t("deletion.cancelError")}>
            {error.message}
          </Snackbar>,
        );
        return false;
      }
    },
    [cancelRunningCrawlMutation, enqueueSnackbar, t],
  );

  const cloneProject = React.useCallback(
    async (projectId) => {
      try {
        const result = await cloneProjectMutation({
          variables: { projectId: projectId },
        });
        return result.data?.cloneProject.project?.id;
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(
          <Snackbar variant="error" title={t("deletion.cloningError")}>
            {error.message}
          </Snackbar>,
        );
        return undefined;
      }
    },
    [cloneProjectMutation, enqueueSnackbar, t],
  );

  const deleteProjectSchedule = React.useCallback(
    async (scheduleId) => {
      try {
        await deleteProjectScheduleMutation({
          variables: { scheduleId },
          refetchQueries: ["AccountProjects"],
          awaitRefetchQueries: true,
        });
        enqueueSnackbar(
          <Snackbar variant="success" title={t("deletion.scheduleSuccess")} />,
        );
        return true;
      } catch (e) {
        const error = e as Error;
        enqueueSnackbar(
          <Snackbar variant="error" title={t("deletion.scheduleError")}>
            {error.message}
          </Snackbar>,
        );
        return false;
      }
    },
    [deleteProjectScheduleMutation, enqueueSnackbar, t],
  );

  return {
    deleteProject,
    cancelRunningCrawl,
    deleteProjectSchedule,
    cloneProject,
  };
}
