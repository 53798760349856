import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";
import { REGEX_PATTERNS } from "../../../../../_common/constants/regex-patterns.constant";
import { translationNamespace } from "../../../CrawlSettings";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSplunkSchema(): any {
  const { t } = useTranslation(translationNamespace);

  return Yup.object().shape({
    baseUrl: Yup.string().matches(
      REGEX_PATTERNS.url,
      t("sources.logSummary.invalidDomain"),
    ),
  });
}
