import { useNumberFormatter } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function WcagVersionPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const formatNumber = useNumberFormatter();

  const numberValue = Number(value);
  if (value === undefined || value === null || isNaN(numberValue))
    return <>-</>;

  return <>{formatNumber(numberValue, { minimumFractionDigits: 1 })}</>;
}
