import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";
import isEmail from "validator/lib/isEmail";

export function useTaskSchema(): unknown {
  const { t } = useTranslation("taskManager");

  const maxTitle = 255;
  const maxDescription = 65535;
  const maxHowToFix = 1500;

  return Yup.object().shape({
    title: Yup.string()
      .required(t("editDialog.titleRequired"))
      .test(
        "maxTitle",
        t("editDialog.titleMax", { max: maxTitle }),
        (title) => !title || title.length <= maxTitle,
      ),
    description: Yup.string().max(
      maxDescription,
      t("editDialog.descriptionMax", { max: maxDescription }),
    ),
    assignedTo: Yup.array().of(
      Yup.string()
        .test(
          "email",
          t("editDialog.invalidEmail"),
          (email) => !email || isEmail(email),
        )
        .test("unique", t("editDialog.nonUniqueEmail"), (value, context) => {
          const isUnique =
            context.parent.filter((item: string) => item === value).length ===
            1;

          if (!isUnique) {
            throw context.createError({
              path: context.path,
              message: t("editDialog.nonUniqueEmail"),
            });
          }

          return true;
        }),
    ),
    howToFix: Yup.string().max(
      maxHowToFix,
      t("editDialog.howToFixMax", { max: maxHowToFix }),
    ),
  });
}
