import { Trans, useTranslation } from "@lumar/shared";
import { Link, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useAccountsRoutes } from "../../../_common/routing/accountsRoutes";
import { CheckboxWithLabel, CheckboxWithLabelProps } from "./CheckboxWithLabel";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

export interface JsRenderingCheckboxProps extends CheckboxWithLabelProps {
  hasJsRendering: boolean;
}

export function JsRenderingCheckbox({
  hasJsRendering,
  className,
  ...props
}: JsRenderingCheckboxProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const accountsRoutes = useAccountsRoutes();

  const { accountId } = useParams<{ accountId: string }>();

  return (
    <div className={clsx(classes.root, className)}>
      <CheckboxWithLabel
        label={t("jsRender")}
        data-testid="use-javascript-rendering"
        {...props}
        disabled={props.disabled || !hasJsRendering}
      />
      {!hasJsRendering && (
        <Typography variant="caption">
          <Trans
            ns="crawlSettings"
            i18nKey={
              props.checked
                ? "jsRenderDisabledWithValue"
                : "jsRenderDisabledWithoutValue"
            }
            components={{
              enableLink: (
                <Link
                  href={accountsRoutes.Subscription.getUrl({ accountId })}
                  className={classes.link}
                />
              ),
            }}
          />
        </Typography>
      )}
    </div>
  );
}
