import { GenerateChartConfigItemFunction } from "../../../_common/charts/types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export enum PredefinedChart {
  ReportTrend = "reportTrend",
}

export type PredefinedChartConfig = {
  predefinedChartType: `${PredefinedChart}`;
  supportedModules?: ModuleCode[];
};

export type ChartConfigs = (
  | GenerateChartConfigItemFunction
  | PredefinedChartConfig
)[];

export type ReportMap = [string, ChartConfigs][];

export function isPredefinedChartConfig(
  value: unknown,
): value is PredefinedChartConfig {
  return (
    typeof value === "object" &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(PredefinedChart).includes((value as any)?.predefinedChartType)
  );
}
