import React from "react";
import {
  EmptyState,
  ExclamationCircleOutlined,
  Trans,
  useTranslation,
} from "@lumar/shared";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

import {
  DataExplorerTable,
  DataExplorerTableProps,
} from "./table/DataExplorerTable";
import { Routes } from "../_common/routing/routes";
import { useDefaulDimensionData } from "./data/useDefaulDimensionData";
import { usePathData } from "./data/path/usePathData";
import { useSegmentsData } from "./data/useSegmentsData";
import { useBreadcrumbData } from "./data/breadcrumb/useBreadcrumbData";
import { Paper } from "@material-ui/core";

type TableProps = Omit<DataExplorerTableProps, "rows" | "loading" | "error"> & {
  projectId: string;
  accountId: string;
  crawlId: string;
};

export function getDataExplorerDimensionTable(
  dimension: string,
): React.ElementType<TableProps> {
  switch (dimension) {
    case "segment":
      return DataExplorerSegmentsTable;
    case "path":
      return DataExplorerPathTable;
    case "breadcrumb":
      return DataExplorerBreadcrumbTable;
    default:
      return DataExplorerDefaultTable;
  }
}

function DataExplorerDefaultTable({
  crawlId,
  ...props
}: TableProps): JSX.Element {
  const { rows, loading, error } = useDefaulDimensionData({
    crawlId,
    tableConfig: props.tableConfig,
  });

  return (
    <DataExplorerTable rows={rows} loading={loading} error={error} {...props} />
  );
}

function DataExplorerSegmentsTable({
  accountId,
  projectId,
  crawlId,
  ...props
}: TableProps): JSX.Element {
  const { t } = useTranslation("dataExplorer");

  const { rows, loading, error, hasSegments } = useSegmentsData({
    crawlId,
    tableConfig: props.tableConfig,
  });

  if (!hasSegments && !loading && !error) {
    return (
      <Paper>
        <EmptyState
          icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
          title={t("noSegments.title")}
          description={
            <Trans t={t} i18nKey="noSegments.content">
              Switch to a crawl that has segments or&nbsp;
              <Link
                color="inherit"
                component={RouterLink}
                to={Routes.SegmentManager.getUrl({
                  accountId,
                  projectId,
                  crawlId,
                })}
                display="block"
              >
                create segments now
              </Link>
            </Trans>
          }
          height={400}
        />
      </Paper>
    );
  }

  return (
    <DataExplorerTable rows={rows} loading={loading} error={error} {...props} />
  );
}

function DataExplorerPathTable({ crawlId, ...props }: TableProps): JSX.Element {
  const { rows, loading, error } = usePathData({
    crawlId,
    tableConfig: props.tableConfig,
  });

  return (
    <DataExplorerTable
      rows={rows}
      loading={loading}
      error={error}
      dimensionColumnWidth={400}
      disableSorting
      {...props}
    />
  );
}

function DataExplorerBreadcrumbTable({
  crawlId,
  ...props
}: TableProps): JSX.Element {
  const { rows, loading, error } = useBreadcrumbData({
    crawlId,
    tableConfig: props.tableConfig,
  });

  return (
    <DataExplorerTable
      rows={rows}
      loading={loading}
      error={error}
      dimensionColumnWidth={400}
      disableSorting
      {...props}
    />
  );
}
