import { Chip, ChipColor, useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function RedirectStatusCodePresenter({
  value,
  metrics,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("report");
  const statusCode = Number(value ?? 0);

  const isRedirect = metrics["isRedirect"]?.value;
  const redirectExclusionReason = metrics["redirectExclusionReason"]?.value;

  if (!isRedirect) {
    return <>-</>;
  }

  if (isRedirect && redirectExclusionReason) {
    return <Chip label={t("notCrawled")} color="grey" />;
  }

  if (statusCode > 0) {
    return (
      <Chip label={statusCode} color={getStatusCodeChipColor(statusCode)} />
    );
  }

  return <Chip label={t("failed")} color="red" />;
}

export const getStatusCodeChipColor = (statusCode: number): ChipColor => {
  if (statusCode > 199 && statusCode <= 299) return "green";
  if (statusCode > 299 && statusCode <= 399) return "cyan";
  if (statusCode > 399 && statusCode <= 499) return "yellow";
  return "red";
};
