import React from "react";
import { keyBy, uniq, isString } from "lodash";

import { useSegmentsList } from "./data/useSegmentsList";
import { useMetrics } from "../useMetrics";
import { useSegmentMutations } from "../useSegmentMutations";
import { EditSegmentDialog } from "../edit-segment-dialog/EditSegmentDialog";
import { CreateSegmentDialog } from "../create-segment-dialog/CreateSegmentDialog";
import { SegmentGrid } from "./SegmentsGrid";
import { CopySegmentDialog } from "../copy-segment-dialog/CopySegmentDialog";
import { EditGroupDialog } from "./EditGroupDialog";
import { SegmentsLimit } from "./SegmentsLimit";

export type SegmentActions = {
  onCreateSegment: (group?: string) => void;
  onEditSegment: (segmentId: string) => void;
  onCopySegment: (segmentIds: string[]) => void;
  onDeleteSegment: (segmentId: string) => Promise<void>;
  onEditGroup: (group: string) => void;
  onDeleteGroup: (group: string) => Promise<void>;
};

export function ManageSegment(): JSX.Element {
  const {
    updateSegmentMutation,
    deleteSegmentMutation,
    updateSegmentsGroupMutation,
  } = useSegmentMutations();

  const [editSegmentID, setEditSegmentID] = React.useState<
    string | undefined
  >();
  const [copySegmentIDs, setCopySegmentIDs] = React.useState<
    string[] | undefined
  >();
  const [createSegmentDialogOpen, setCreateSegmentDialogOpen] = React.useState<
    { group?: string } | undefined
  >();
  const [editGroup, setEditGroup] = React.useState<string | undefined>();

  const {
    data: segmentsListData,
    loading: segmentsListLoading,
    error: segmentsListError,
  } = useSegmentsList();

  const {
    metrics,
    filterMetrics,
    loading: metricsLoading,
    error: metricsError,
    latestFinishedCrawlData,
  } = useMetrics();

  const error = segmentsListError || metricsError;
  const loading = segmentsListLoading || metricsLoading;

  const crawl = segmentsListData?.crawl;
  const crawlInProgress = Boolean(crawl?.createdAt && !crawl?.finishedAt);

  const segmentsHash = keyBy(segmentsListData.segments, "id");

  const editSegment = editSegmentID && segmentsHash[editSegmentID];

  const copySegments =
    copySegmentIDs && copySegmentIDs.map((id) => segmentsHash[id]);

  const groups = uniq(
    segmentsListData.segments.map((x) => x.group).filter(isString),
  );

  const actions = React.useMemo<SegmentActions>(
    () => ({
      onCreateSegment: (group) => setCreateSegmentDialogOpen({ group }),
      onEditSegment: setEditSegmentID,
      onCopySegment: setCopySegmentIDs,
      onDeleteSegment: async (segmentId) =>
        deleteSegmentMutation(
          segmentId,
          segmentsListData.segments.find((x) => x.id === segmentId)?.name || "",
        ),
      onEditGroup: setEditGroup,
      onDeleteGroup: async (group) =>
        updateSegmentsGroupMutation(
          segmentsListData.segments
            .filter((x) => x.group === group)
            .map((x) => x.id),
          undefined,
        ),
    }),
    [
      segmentsListData.segments,
      deleteSegmentMutation,
      updateSegmentsGroupMutation,
    ],
  );

  return (
    <>
      {!error && (
        <SegmentsLimit
          segmentCount={segmentsListData.segments.length}
          maxSegmentCount={segmentsListData.maxSegmentCount}
          loading={loading}
        />
      )}
      <SegmentGrid
        loading={loading}
        error={error}
        crawl={crawl}
        crawlInProgress={crawlInProgress}
        segments={segmentsListData.segments}
        maxSegmentCount={segmentsListData.maxSegmentCount}
        metrics={metrics}
        actions={actions}
      />
      {Boolean(createSegmentDialogOpen) && (
        <CreateSegmentDialog
          metrics={filterMetrics}
          groups={groups}
          defaultGroup={createSegmentDialogOpen?.group}
          closeDialog={() => setCreateSegmentDialogOpen(undefined)}
          latestFinishedCrawlData={latestFinishedCrawlData}
          crawlStatusData={segmentsListData}
        />
      )}
      {editSegment && (
        <EditSegmentDialog
          editSegment={editSegment}
          closeDialog={() => setEditSegmentID(undefined)}
          updateSegmentMutation={updateSegmentMutation}
          latestFinishedCrawlData={latestFinishedCrawlData}
          metrics={filterMetrics}
          groups={groups}
        />
      )}
      {copySegments && (
        <CopySegmentDialog
          segments={copySegments}
          closeDialog={() => setCopySegmentIDs(undefined)}
          metrics={filterMetrics}
          crawlStatusData={segmentsListData}
          maxSegmentCount={segmentsListData.maxSegmentCount}
          moduleCode={segmentsListData.moduleCode}
        />
      )}
      {editGroup && (
        <EditGroupDialog
          group={editGroup}
          closeDialog={() => setEditGroup(undefined)}
          segments={segmentsListData.segments}
          updateSegmentsGroupMutation={updateSegmentsGroupMutation}
        />
      )}
    </>
  );
}
