import React from "react";
import { Tooltip, useTheme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { InformationCircleOutlined } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  infoCircle: {
    position: "relative",
    top: "7px",
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(0.375),
    color: theme.palette.grey[400],
  },
}));

interface Props {
  count: number;
  min?: number;
  max?: number;
}

export function TextMetricLengthInCharacters(props: Props): JSX.Element {
  const { t } = useTranslation("units");
  const intent = useIntent(props);
  const classes = useStyles();

  return (
    <>
      <span style={{ color: intent?.color }}>
        {t("character", { count: props.count })}
      </span>
      <Tooltip title={intent?.message || ""} placement="top" arrow>
        <InformationCircleOutlined className={classes.infoCircle} />
      </Tooltip>
    </>
  );
}

function useIntent({
  count,
  min,
  max,
}: Props): { message: string; color: string } | undefined {
  const { t } = useTranslation("resourceDetail");
  const theme = useTheme();
  const negativeColor = theme.palette.red[600];
  const positiveColor = theme.palette.green[600];

  if (min !== undefined && max !== undefined) {
    const isWithinLimit = count <= max && count >= min;
    if (isWithinLimit) {
      return {
        message: t("textMetricCharCountWithinLimitMessage", { min, max }),
        color: positiveColor,
      };
    } else {
      return {
        message: t("textMetricCharCountOutsideLimitMessage", { min, max }),
        color: negativeColor,
      };
    }
  }

  if (max !== undefined) {
    const isBelowThreshold = count <= max;
    if (isBelowThreshold) {
      return {
        message: t("textMetricCharCountWithinThreshold", { max }),
        color: positiveColor,
      };
    } else {
      return {
        message: t("textMetricCharCountAboveThreshold", { max }),
        color: negativeColor,
      };
    }
  }

  if (min !== undefined) {
    const isMeetingMinimum = count >= min;
    if (isMeetingMinimum) {
      return {
        message: t("textMetricCharCountMeetsMinimum", { min }),
        color: positiveColor,
      };
    } else {
      return {
        message: t("textMetricCharCountBelowMinimum", { min }),
        color: negativeColor,
      };
    }
  }
}
