/* eslint-disable fp/no-mutation */
import React from "react";
import { useGridSelector, } from "@mui/x-data-grid-pro";
import { buildCSV } from "./buildCsv";
// This function is the modified version of the MUI DataGrid's useGridCsvExport function
// https://github.com/mui/mui-x/blob/v4.0.2/packages/grid/_modules_/grid/hooks/features/export/useGridCsvExport.tsx
// The purpose of this code duplication is to make csv exports work with new line charaters
// and to provide more control over the csv export, compared to the limited export
// function provided by the MUI DataGrid.
export function useGridCsvExport(apiRef) {
    const columns = useGridSelector(apiRef, getAllColumns);
    const visibleColumns = useGridSelector(apiRef, getVisibleColumns);
    const visibleSortedRowIds = useGridSelector(apiRef, getVisibleSortedRowIds);
    return React.useCallback((options) => {
        function getDataAsCsv(options) {
            // eslint-disable-next-line fp/no-let
            let exportedColumns;
            if (options === null || options === void 0 ? void 0 : options.fields) {
                exportedColumns = options.fields
                    .map((field) => columns.find((column) => column.field === field))
                    .filter((column) => !!column);
            }
            else {
                const validColumns = (options === null || options === void 0 ? void 0 : options.allColumns) ? columns : visibleColumns;
                exportedColumns = validColumns.filter((column) => !column.disableExport);
            }
            return buildCSV({
                columns: exportedColumns,
                rowIds: visibleSortedRowIds,
                getCellParams: apiRef.current.getCellParams,
                delimiterCharacter: (options === null || options === void 0 ? void 0 : options.delimiter) || ",",
                includeHeaders: true,
            });
        }
        const csv = getDataAsCsv(options);
        const blob = new Blob([(options === null || options === void 0 ? void 0 : options.utf8WithBom) ? new Uint8Array([0xef, 0xbb, 0xbf]) : "", csv], {
            type: "text/csv",
        });
        exportAs(blob, "csv", options === null || options === void 0 ? void 0 : options.fileName);
    }, [visibleColumns, columns, visibleSortedRowIds, apiRef]);
}
function exportAs(blob, extension = "csv", filename = document.title) {
    const fullName = `${filename}.${extension}`;
    // Test download attribute first
    // https://github.com/eligrey/FileSaver.js/issues/193
    if ("download" in HTMLAnchorElement.prototype) {
        // Create an object URL for the blob object
        const url = URL.createObjectURL(blob);
        // Create a new anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = fullName;
        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        a.click();
        // https://github.com/eligrey/FileSaver.js/issues/205
        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
        return;
    }
    throw new Error("exportAs not supported");
}
function getAllColumns(state) {
    return state.columns.all.map((field) => state.columns.lookup[field]);
}
function getVisibleColumns(state) {
    return getAllColumns(state).filter((field) => field.field != null && !field.hide);
}
function getVisibleSortedRowIds(state) {
    const sortedRows = state.sorting.sortedRows.length
        ? state.sorting.sortedRows
        : state.rows.allRows;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return sortedRows.reduce((rows, rowId) => {
        if (state.visibleRows.visibleRowsLookup[rowId] !== false) {
            return [...rows, rowId];
        }
        return rows;
    }, []);
}
