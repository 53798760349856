import React from "react";
import { Task } from "../../data/types";
import { AssigneeList } from "../../assignee-list/AssigneeList";

export function AssigneeCell({
  task,
  isDragging,
}: {
  task: Task;
  isDragging: boolean;
}): JSX.Element {
  const asignedTo = task.assignedTo || [];

  return <AssigneeList assignees={asignedTo} disabled={isDragging} />;
}
