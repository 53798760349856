import { Typography, useNumberFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  crawled: {
    color: theme.palette.grey[600],
  },
}));

export function CrawledUrlsCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const formatter = useNumberFormatter();
  const totalSteps = row.totalSteps ?? 0;

  return (
    <Typography
      variant="captionSemiBold"
      className={classes.crawled}
      data-testid="all-projects-crawled-urls-cell"
    >
      {formatter(row.runningTotalSteps ?? totalSteps)}
    </Typography>
  );
}
