import { ReportCategoryChartConfig } from "../types";
import { getAnalyticsTrafficFunnel } from "../../../../_common/charts/config/getAnalyticsTrafficFunnel";
import { getAnalyticsTrafficFunnelTrend } from "../../../../_common/charts/config/getAnalyticsTrafficFunnelTrend";
import { getAvgTimeOnPageChart } from "../../../../_common/charts/config/getAvgTimeOnPageChart";
import { getAvgTimeOnPageTrend } from "../../../../_common/charts/config/getAvgTimeOnPageTrend";
import { getBounceRateBreakdownChart } from "../../../../_common/charts/config/getBounceRateBreakdownChart";
import { getBounceRateBreakdownTrend } from "../../../../_common/charts/config/getBounceRateBreakdownTrend";
import { getEngagementBreakdownChart } from "../../../../_common/charts/config/getEngagementBreakdownChart";
import { getEngagementTrend } from "../../../../_common/charts/config/getEngagementBreakdownTrend";
import { getPageViewsPerSessionChart } from "../../../../_common/charts/config/getPageViewsPerSessionChart";
import { getPageViewsPerSessionTrend } from "../../../../_common/charts/config/getPageViewsPerSessionTrend";

export const generateExperienceEngagementCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getEngagementBreakdownChart,
      getEngagementTrend,
      getBounceRateBreakdownChart,
      getBounceRateBreakdownTrend,
      getPageViewsPerSessionChart,
      getPageViewsPerSessionTrend,
      getAvgTimeOnPageChart,
      getAvgTimeOnPageTrend,
      getAnalyticsTrafficFunnel,
      getAnalyticsTrafficFunnelTrend,
      // getAnalyticsBreakdownChart,
      // getCrawlSourceAnalyticsTrend,
    ],
  };
