import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FastField, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as Yup from "yup";

import { translationNamespace } from "../../../CrawlSettings";
import { StyledInputLabel } from "../../../../../_common/forms/StyledInputLabel";

const useStyles = makeStyles(() => ({
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  input: {
    width: "100%",
  },
}));

export function AddLogzioAccount({
  className,
  afterSave,
  addConnection,
}: {
  className?: string;
  afterSave?: () => void;
  addConnection: (label: string, token: string) => Promise<boolean>;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const validationSchema = Yup.object().shape({
    token: Yup.string().required(t("validationError.required")),
  });

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) return;

    afterSave?.();
  };

  return (
    <Formik
      initialValues={{ label: "", token: "" }}
      validationSchema={validationSchema}
      onSubmit={({ label, token }) => addConnection(label, token)}
    >
      {({ submitForm }) => (
        <Grid container spacing={2} className={className}>
          <Grid item xs={12} md={6}>
            <StyledInputLabel>
              {t("sources.logSummary.logzio.label")}
            </StyledInputLabel>
            <FastField
              name="label"
              component={TextField}
              variant="outlined"
              placeholder={t("sources.logSummary.logzio.labelPlaceholder")}
              hiddenLabel
              className={classes.input}
              data-testid="crawl-settings-add-logzio-account-hostname"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledInputLabel>
              {t("sources.logSummary.logzio.token")}
            </StyledInputLabel>
            <FastField
              name="token"
              component={TextField}
              placeholder={t("sources.logSummary.logzio.tokenPlaceholder")}
              variant="outlined"
              hiddenLabel
              className={classes.input}
            />
          </Grid>
          <Grid item xs={12} className={classes.alignRightGrid}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => handleSave(submitForm)}
              data-testid="crawl-settings-add-logzio-account"
            >
              {t("sources.logSummary.logzio.addAccount")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
