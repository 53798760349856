import React from "react";
import {
  Checkbox,
  ChevronDownSolid,
  ChevronRightSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { uniq } from "lodash";

import {
  SegmentGroup,
  SegmentRow,
  SegmentSelectionContext,
} from "../SegmentsGrid";
import { RoleCode } from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    display: "flex",
    alignItems: "center",
  },
  segmentContainer: {
    margin: theme.spacing(1.625, 0, 1.625, 3.625),
    display: "flex",
    alignItems: "center",
  },
  toggleGroupButton: {
    margin: theme.spacing(0, 1.5, 0, 1.5),
    padding: 0,
    borderStyle: "none",
    background: "none",
    display: "flex",
    cursor: "pointer",
    "& svg": {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  segmentName: {
    marginLeft: theme.spacing(1.5),
  },
  unsupported: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(1),
  },
}));

interface Props extends GridRenderCellParams {
  setGroups: React.Dispatch<React.SetStateAction<SegmentGroup[]>>;
}

export const SegmentCell = React.memo(SegmentCellInner);

function SegmentCellInner({
  id,
  row: rowParam,
  getValue,
  setGroups,
}: Props): JSX.Element {
  const row = rowParam as SegmentRow;

  const { t } = useTranslation("segments");
  const classes = useStyles();
  const { hasSufficientRole } = useSession();

  const { selectedRows, setRowSelected } = React.useContext(
    SegmentSelectionContext,
  );

  if (row.isGroup) {
    const ExpandIcon = row.open ? ChevronDownSolid : ChevronRightSolid;
    const segmentSelectedValues: boolean[] = uniq(
      row.segments
        .filter((x) => x.isSupportedFormat)
        .map((x) => Boolean(selectedRows[x.id])),
    );
    const groupSelected =
      segmentSelectedValues.length === 2
        ? undefined
        : (segmentSelectedValues[0] ?? false);
    const disabled = !row.segments.find((x) => x.isSupportedFormat);

    return (
      <div className={classes.groupContainer}>
        {hasSufficientRole(RoleCode.Editor) && (
          <Checkbox
            checked={groupSelected ?? false}
            indeterminate={groupSelected === undefined}
            onChange={(_, value) =>
              setRowSelected(
                row.segments.map((x) => x.id),
                value,
              )
            }
            disabled={disabled}
            data-testid="segment-group-selected"
          />
        )}
        <button
          onClick={() => toggleGroup(setGroups, row.id)}
          className={classes.toggleGroupButton}
          data-testid="segment-group-toggle"
          data-expanded={row.open ? true : undefined}
          aria-label={
            row.open ? t("segmentGroupCollapse") : t("segmentGroupExpand")
          }
        >
          <ExpandIcon />
        </button>
        <Typography variant="subtitle3Medium">
          {row.name || t("defaultGroup")} ({row.segments.length})
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.segmentContainer}>
      {hasSufficientRole(RoleCode.Editor) && (
        <Checkbox
          checked={Boolean(selectedRows[row.id])}
          onChange={(_, value) => setRowSelected(row.id, value)}
          disabled={!row.isSupportedFormat}
          data-testid="segment-selected"
        />
      )}
      <Typography variant="subtitle3Medium" className={classes.segmentName}>
        {getValue(id, "name")}
      </Typography>
      {!row.isSupportedFormat && (
        <Typography variant="subtitle3" className={classes.unsupported}>{`(${t(
          "unsupportedFormat",
        )})`}</Typography>
      )}
    </div>
  );
}

export function SegmentHeader({ colDef }: GridColumnHeaderParams): JSX.Element {
  const { selectedRows, setAllRowsSelected } = React.useContext(
    SegmentSelectionContext,
  );
  const { hasSufficientRole } = useSession();

  const selectedRowsArray = Object.entries(selectedRows);

  const isSelected = selectedRowsArray.reduce<
    boolean | undefined
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  >((res, [_, selected], idx) => {
    if (idx === 0) return selected;
    if (res === undefined) return undefined;
    if (res !== selected) return undefined;

    return res;
  }, false);

  const disabled = selectedRowsArray.length === 0;

  return (
    <>
      {hasSufficientRole(RoleCode.Editor) && (
        <Checkbox
          checked={isSelected ?? false}
          indeterminate={isSelected === undefined}
          onClick={(e) => e.stopPropagation()}
          onChange={(_, value) => setAllRowsSelected(value)}
          disabled={disabled}
        />
      )}
      <span className="MuiDataGrid-columnHeaderTitle" style={{ marginLeft: 8 }}>
        {colDef.headerName}
      </span>
    </>
  );
}

function toggleGroup(
  setGroups: React.Dispatch<React.SetStateAction<SegmentGroup[]>>,
  groupId: string,
): void {
  setGroups((groups) => {
    const foundIndex = groups.findIndex((group) => group.id === groupId);
    if (foundIndex === -1) {
      return groups;
    }

    return [
      ...groups.slice(0, foundIndex),
      { ...groups[foundIndex], open: !groups[foundIndex].open },
      ...groups.slice(foundIndex + 1),
    ];
  });
}
