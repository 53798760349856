import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";

export interface SearchFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
}

export function SearchField({
  value,
  onChange,
  ...props
}: SearchFieldProps): JSX.Element {
  return (
    <TextField
      value={value}
      variant="outlined"
      onChange={(event) => onChange(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment:
          value?.length > 0 ? (
            <InputAdornment position="end">
              <IconButton onClick={() => onChange("")}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      {...props}
    />
  );
}
