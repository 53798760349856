import {
  DocumentReportOutlined,
  EditOutlined,
  PlayOutlined,
  TrashOutlined,
  SessionContextType,
  getRawProjectId,
  DuplicateOutlined,
} from "@lumar/shared";
import { CrawlStatus, RoleCode } from "../../graphql";
import { Routes } from "../../_common/routing/routes";
import { MenuItemProps } from "../cells/ProjectActionsCell";
import { ProjectsGridRenderCellParams } from "../data/types";
import { isCrawlInProgress } from "../projects-filter/helpers";
import { TFunction } from "i18next";

export const accountProjectActions = {
  runCrawl: getRunCrawlAction,
  previousCrawls: getPreviousCrawlsAction,
  editProject: getEditProjectAction,
  removeProject: getRemoveProjectAction,
  cancelCrawl: getCancelCrawlAction,
  editSchedule: getEditScheduleAction,
  removeSchedule: getRemoveScheduleAction,
  cloneProject: getCloneProjectAction,
};

export type AccountProjectsActionTypes = keyof typeof accountProjectActions;

type ActionProps = {
  accountId: string;
  t: TFunction<"projectsList">;
  hasSufficientRole: SessionContextType["hasSufficientRole"];
};

function getRunCrawlAction({
  accountId,
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("runCrawl"),
    icon: PlayOutlined,
    to: ({ row }) =>
      Routes.Crawls.getUrl({
        accountId,
        projectId: row.id,
        tab: "progress",
        start: true,
      }),
    disabled: (params) => crawlIsRunning(params),
    hidden: (params) => params.row.isTestSuite,
    "data-testid": "all-projects-run-crawl",
  };
}

function getPreviousCrawlsAction({
  accountId,
  t,
}: ActionProps): MenuItemProps | undefined {
  return {
    name: t("previousCrawls"),
    icon: DocumentReportOutlined,
    to: ({ row }) =>
      Routes.Crawls.getUrl({
        accountId,
        projectId: row.id,
        tab: "history",
      }),
    disabled: (params) => !params.row.lastFinishedCrawlId,
    "data-testid": "all-projects-previous-crawls",
  };
}

function getEditProjectAction({
  accountId,
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("editProject"),
    icon: EditOutlined,
    to: ({ row }) =>
      Routes.Crawls.getUrl({
        accountId,
        projectId: row.id,
        tab: "edit",
        step: 1,
      }),
    "data-testid": "all-projects-edit",
  };
}

function getRemoveProjectAction({
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("removeProject"),
    icon: TrashOutlined,
    onClick: ({ row }, { deleteProject }) => deleteProject(row.id),
    removeTitle: t("deletion.areYouSure"),
    highlightRed: true,
    removeDescription: t("deletion.cannotBeUndone"),
    "data-testid": "all-projects-remove",
  };
}

function getCancelCrawlAction({
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("cancel"),
    icon: TrashOutlined,
    onClick: async ({ row }, { cancelRunningCrawl }) => {
      if (row.lastCrawlId) return await cancelRunningCrawl(row.lastCrawlId);
      return false;
    },
    removeTitle: t("deletion.cancelConfirmationTitle"),
    highlightRed: true,
    removeDescription: t("deletion.cannotBeUndone"),
    confirmText: t("deletion.ok"),
    hidden: ({ row }) => row.isTestSuite || Boolean(row.lastCrawlFinishedAt),
    "data-testid": "running-projects-cancel",
  };
}

function getEditScheduleAction({
  accountId,
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("editSchedule"),
    icon: EditOutlined,
    to: ({ row }) =>
      Routes.Crawls.getUrl({
        accountId,
        projectId: row.id,
        tab: "edit",
        step: 4,
      }),
    hidden: ({ row }) => !row.schedule,
    "data-testid": "all-projects-edit-schedule",
  };
}

function getCloneProjectAction({
  accountId,
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("cloneProject"),
    icon: DuplicateOutlined,
    onClick: async ({ row }, { cloneProject }) => {
      const result = await cloneProject(row.id);
      if (result) {
        return Routes.Crawls.getUrl({
          accountId,
          projectId: getRawProjectId(result),
          tab: "edit",
          step: 1,
        });
      }
      return false;
    },
    "data-testid": "all-projects-clone-project",
  };
}

function getRemoveScheduleAction({
  t,
  hasSufficientRole,
}: ActionProps): MenuItemProps | undefined {
  if (!hasSufficientRole(RoleCode.Editor)) return;

  return {
    name: t("removeSchedule"),
    icon: TrashOutlined,
    onClick: async ({ row }, { deleteProjectSchedule }) => {
      if (row.schedule) return await deleteProjectSchedule(row.schedule.id);
      return false;
    },
    removeTitle: t("deletion.scheduleTitle"),
    hidden: ({ row }) => !row.schedule,
    "data-testid": "all-projects-remove-schedule",
  };
}

function crawlIsRunning(params: ProjectsGridRenderCellParams): boolean {
  const crawlStatus = params.row.lastCrawlStatus;
  const hasFinishDate = Boolean(params.row.lastCrawlFinishedAt);

  if (crawlStatus === CrawlStatus.Finalizing && hasFinishDate) {
    return false;
  }
  return isCrawlInProgress(crawlStatus);
}
