/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import {
  ArrowCircleRightSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { TableCell } from "@material-ui/core";

import { Routes } from "../../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  cell: {
    "&:hover": {
      background: theme.palette.grey[200],
      "& $viewReportLabel": {
        opacity: 1,
      },
      "& $viewReportIcon": {
        color: theme.palette.primary.main,
      },
    },
  },
  viewReportButton: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  viewReportLabel: {
    color: theme.palette.primary.main,
    opacity: 0,
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  viewReportIcon: {
    color: theme.palette.grey[400],
  },
}));

interface Props {
  row: any;
  children?: React.ReactNode;
  className?: string;
}

export function ViewReportCell({
  row,
  children,
  className,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");

  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const history = useHistory();

  const onViewClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    history.push(
      Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId,
        reportTemplateCode: "all_pages",
        reportTypeCode: "basic",
        filter: row.getFilter?.(),
        segmentId: row.segmentId,
      }),
    );
  };

  return (
    <TableCell className={clsx(className, classes.cell)}>
      <div className={classes.root}>
        {children}
        <div
          onClick={onViewClick}
          className={classes.viewReportButton}
          data-testid="view-report-button"
        >
          <Typography variant="caption" className={classes.viewReportLabel}>
            {t("viewUrls")}
          </Typography>
          <ArrowCircleRightSolid className={classes.viewReportIcon} />
        </div>
      </div>
    </TableCell>
  );
}
