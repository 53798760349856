import React from "react";
import {
  FormControlLabel,
  makeStyles,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { useTranslation } from "react-i18next";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { Checkbox, InfoOutlined, Select, Typography } from "@lumar/shared";
import { LabelInfo } from "../../../../_common/components/LabelInfo";
import { InternalExternalType, ResourceMode } from "../data/types";
import { SettingControlFactory } from "../useAdvancedControls";

export const getResourceRestrictionsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.resourceRestrictions.title"),
  path: "resourceRestriction",
  // eslint-disable-next-line react/display-name
  control: () => <ResourceRestrictions />,
  testId: "crawl-settings-resource-restrictions",
  textForSearch: createTextForSearch([
    t("settings.resourceRestrictions.title"),
    t("settings.resourceRestrictions.description"),
    t("settings.resourceRestrictions.crawlNonHtmlUrls"),
    t("settings.resourceRestrictions.nonHtmlUrlsLabelInfo"),
    t("settings.resourceRestrictions.crawlCssResources"),
    t("settings.resourceRestrictions.cssResourceLabelInfo"),
    t("settings.resourceRestrictions.crawlJsResources"),
    t("settings.resourceRestrictions.jsResourceLabelInfo"),
    t("settings.resourceRestrictions.crawlImageResources"),
    t("settings.resourceRestrictions.imageResourceLabelInfo"),
    t("settings.resourceRestrictions.ignoreInvalidSSL"),
    t("settings.resourceRestrictions.ignoreInvalidSSLLabelInfo"),
  ]),
});

export const ResourceRestrictions = React.memo(ResourceRestrictionsInner);

function ResourceRestrictionsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  return (
    <>
      <Typography variant="caption" style={{ marginBottom: "8px" }}>
        {t("settings.resourceRestrictions.description")}
      </Typography>
      <FastField
        name="scope.resourceRestrictions.crawlNonHtmlUrls"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <LabelInfo
              label={t("settings.resourceRestrictions.crawlNonHtmlUrls")}
              info={t("settings.resourceRestrictions.nonHtmlUrlsLabelInfo")}
            />
          ),
        }}
        data-testid="non-html-urls"
      />
      <InternalExternalComponent
        name="scope.resourceRestrictions.crawlCssResources"
        label={t("settings.resourceRestrictions.crawlCssResources")}
        info={t("settings.resourceRestrictions.cssResourceLabelInfo")}
        id="css-resources"
      />
      <InternalExternalComponent
        name="scope.resourceRestrictions.crawlJsResources"
        label={t("settings.resourceRestrictions.crawlJsResources")}
        info={t("settings.resourceRestrictions.jsResourceLabelInfo")}
        id="js-resources"
      />
      <InternalExternalComponent
        name="scope.resourceRestrictions.crawlImageResources"
        label={t("settings.resourceRestrictions.crawlImageResources")}
        info={t("settings.resourceRestrictions.imageResourceLabelInfo")}
        id="image-resource"
      />
      <FastField
        name="scope.resourceRestrictions.ignoreInvalidSSLCertificate"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <LabelInfo
              label={t("settings.resourceRestrictions.ignoreInvalidSSL")}
              info={t(
                "settings.resourceRestrictions.ignoreInvalidSSLLabelInfo",
              )}
            />
          ),
        }}
        data-testid="invalid-ssl"
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    maxWidth: 400,
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  infoIcon: {
    color: "#374151",
    marginTop: "1px",
    marginLeft: theme.spacing(0.5),
    display: "block",
  },
  labelDiv: {
    display: "flex",
  },
  labelRoot: {
    marginRight: 0,
  },
}));

interface InternalExternalComponentProps {
  name: string;
  label: string;
  info: string;
  id: string;
}

function InternalExternalComponent({
  name,
  label,
  info,
  id,
}: InternalExternalComponentProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const options = [
    {
      value: ResourceMode.Internal,
      label: t("settings.resourceRestrictions.internal"),
    },
    {
      value: ResourceMode.External,
      label: t("settings.resourceRestrictions.external"),
    },
    {
      value: ResourceMode.InternalExternal,
      label: t("settings.resourceRestrictions.internalExternal"),
    },
  ];

  return (
    <FastField name={name}>
      {({
        field: { value },
        form: { setFieldValue },
      }: FastFieldProps<InternalExternalType>) => (
        <>
          <div className={classes.labelDiv}>
            <FormControlLabel
              classes={{ root: classes.labelRoot }}
              control={
                <Checkbox
                  checked={value.enabled}
                  onChange={(_, value) => {
                    setFieldValue(name + ".enabled", value);
                  }}
                  data-testid={id}
                />
              }
              label={label}
              labelPlacement="end"
            />
            <Tooltip title={info} placement="right">
              <InfoOutlined
                color="inherit"
                fontSize="inherit"
                className={classes.infoIcon}
              />
            </Tooltip>
          </div>
          {value.enabled && (
            <Select
              value={value.mode}
              onChange={(e) => {
                setFieldValue(`${name}.mode`, e.target.value);
              }}
              className={classes.select}
            >
              {options.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
    </FastField>
  );
}
