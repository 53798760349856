import React from "react";
import { Menu, MenuItem, SvgIconTypeMap } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ChartIconButton, useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { VisualisationTypes } from "../../types/ChartConfig";

interface Props {
  options?: never;
}

interface PropsWithAdditionalVisualisations<T> {
  selectedOption?: T;
  options?: T[];
  handleOptionChange(option: T): void;
  title: string;
  icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">>;
  isDisabled?: boolean;
  pendoAttributePrefix?: string;
}

export function ChartDropdownMenu<T extends string>(
  props: Props | PropsWithAdditionalVisualisations<T>,
): JSX.Element {
  const { t } = useTranslation("charts");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return !!props?.options ? (
    <>
      <ChartIconButton
        tooltip={props.title}
        aria-controls="simple-menu"
        aria-haspopup="true"
        data-testid="ViewAllButton"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        disabled={props.isDisabled}
        icon={props.icon ?? SettingsIcon}
        data-pendo={
          props.pendoAttributePrefix
            ? `${props.pendoAttributePrefix}`
            : undefined
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        getContentAnchorEl={null}
      >
        {props.options.map((option, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              props.handleOptionChange(option);
              setAnchorEl(null);
            }}
            selected={option === props.selectedOption}
            data-pendo={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-${option}`
                : undefined
            }
          >
            {getTranslation(t, option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <></>
  );
}

function getTranslation(t: TFunction<"charts">, key: string): string {
  switch (key) {
    case "PDF":
      return t("exportTypes.pdf");
    case "PNG":
      return t("exportTypes.png");
    case "CSV":
      return t("exportTypes.csv");
  }

  switch (key) {
    case VisualisationTypes.SparklineList:
      return t("chartTypes.sparklineList");
    case VisualisationTypes.Pie:
      return t("chartTypes.pie");
    case VisualisationTypes.Bar:
      return t("chartTypes.bar");
    case VisualisationTypes.Line:
      return t("chartTypes.line");
    case VisualisationTypes.Area:
      return t("chartTypes.area");
    case VisualisationTypes.StackedColumn:
      return t("chartTypes.stackedColumn");
    case VisualisationTypes.StackedArea:
      return t("chartTypes.stackedArea");
    case VisualisationTypes.FullStackedArea:
      return t("chartTypes.fullStackedArea");
    case VisualisationTypes.FullStackedColumn:
      return t("chartTypes.fullStackedColumn");
    case VisualisationTypes.Table:
      return t("chartTypes.table");
    case VisualisationTypes.Venn:
      return t("chartTypes.venn");
  }

  return key;
}
