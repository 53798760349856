import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

export function Label({ label }: { label: string }): JSX.Element {
  const classes = useStyles();

  return <Typography className={classes.label}>{label}</Typography>;
}

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
}));
