import { ArrowForwardIcon, Chip } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Redirect } from "../../../graphql";
import { MetricsValuePresenterProps } from "../../data/types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  element: {
    marginRight: theme.spacing(0.75),
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
  },
  chipWrap: {
    height: "auto",
    "& > span": {
      paddingTop: 4,
      paddingBottom: 4,
      whiteSpace: "normal",
    },
  },
  statusCode: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    whiteSpace: "nowrap",
    color: theme.palette.red[600],
    minWidth: theme.typography.pxToRem(20),
  },
  statusCodeOrange: {
    color: theme.palette.orange[600],
  },
  statusCodeGreen: {
    color: theme.palette.green[600],
  },
  statusCodeGrey: {
    color: theme.palette.grey[600],
  },
  arrow: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}));

const STATUS_CODE_ARROW_WIDTH = 48;

export function RedirectChainPresenter({
  value,
  containerWidth,
  behaviour = "wrap",
}: MetricsValuePresenterProps & {
  containerWidth?: number;
  behaviour?: "truncate" | "wrap";
}): JSX.Element {
  if (behaviour === "truncate" && !containerWidth) {
    console.error(
      `RedirectChainPresenter: 'containerWidth' is required when 'behaviour' is 'truncate'`,
    );
  }

  const classes = useStyles();

  const chain = (value ?? []) as Redirect[];

  const getStatusCodeColor = (redirect: Redirect): string => {
    const statusCode = Number(redirect.statusCode);

    if (redirect.isMetaRedirect) {
      return "grey";
    }

    if (statusCode === 302 || statusCode === 307) {
      return "orange";
    }

    return "green";
  };

  const getStatusCodeLabel = (redirect: Redirect): string => {
    const statusCode = Number(redirect.statusCode);

    const hasJSRedirect =
      redirect.isJSRedirect !== null && redirect.isJSRedirect !== undefined;

    if (
      statusCode === 301 ||
      statusCode === 302 ||
      statusCode === 307 ||
      statusCode === 308
    ) {
      return `${statusCode}`;
    }

    if (redirect.isMetaRedirect) {
      return "Meta";
    }

    if (!hasJSRedirect || (hasJSRedirect && Boolean(redirect.isJSRedirect))) {
      return "JS";
    }

    return `${statusCode}`;
  };

  return (
    <>
      {chain?.map((redirect) => {
        const statusLabel = getStatusCodeLabel(redirect);
        const statusColor = getStatusCodeColor(redirect);

        if (!redirect.redirectsTo) {
          return <></>;
        }

        return (
          <span key={redirect.redirectsTo} className={classes.element}>
            <span
              className={clsx(classes.statusCode, {
                [classes.statusCodeGreen]: statusColor === "green",
                [classes.statusCodeOrange]: statusColor === "orange",
                [classes.statusCodeGrey]: statusColor === "grey",
              })}
            >
              {statusLabel}
            </span>
            <ArrowForwardIcon className={classes.arrow} />
            <Chip
              label={redirect.redirectsTo}
              color="primary"
              className={clsx(classes.chip, {
                [classes.chipWrap]: behaviour === "wrap",
              })}
              style={{
                ...(containerWidth && behaviour === "truncate"
                  ? /**
                     * We need to substract the size of the status badge and arrow from the container width to
                     * calculate the max width of the chip
                     * @author Alex Sánchez
                     */
                    { maxWidth: containerWidth - STATUS_CODE_ARROW_WIDTH }
                  : {}),
              }}
            />
          </span>
        );
      })}
    </>
  );
}
