import React from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import {
  EmptyState,
  RefreshOutlined,
  SmileySad,
  useTranslation,
} from "@lumar/shared";
import { Task } from "./data/types";
import { TasksTables } from "./tasks-table/TasksTable";
import { useAccountTasks } from "./data/useAccountTasks";
import { useInView } from "react-intersection-observer";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { SearchWithDebounce } from "../_common/searchWithDebounce/SearchWithDebounce";
import { EditTaskDialog } from "./edit-task/EditTaskDialog";

export function AccountTaskManager(): JSX.Element {
  const { t } = useTranslation(["taskManager", "common"]);
  const classes = useStyles();

  const [search, setSearch] = React.useState("");
  const [editingTask, setEditingTask] = React.useState<Task | undefined>();

  const { tasks, loading, error, allTaskCount, hasMore, fetchMore } =
    useAccountTasks({
      search,
    });

  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "500px 0px 500px 0px",
    threshold: 0,
    fallbackInView: true,
  });

  React.useEffect(() => {
    if (loading || !hasMore || !inView) return;
    fetchMore();
  }, [loading, hasMore, inView, fetchMore]);

  const onTaskSelected = React.useCallback(
    (task: Task) => {
      setEditingTask(task);
    },
    [setEditingTask],
  );

  const hasNoTask = !loading && !tasks.length && !allTaskCount;

  return (
    <>
      <TopNavigation
        title={t("taskManager:accountTasksTitle")}
        breadcrumbs={[{ label: t("taskManager:accountTasksBreadcrumb") }]}
      />
      <SearchWithDebounce
        debounceInMs={300}
        value={search}
        valueChangeCallbackFn={setSearch}
        placeholder={t("taskManager:table.searchPlaceholder")}
        style={{ marginBottom: 17 }}
      />
      {error ? (
        <EmptyState
          icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
          title={t("common:genericError")}
          description={t("taskManager:taskLoadError")}
          actions={[
            {
              type: "button",
              title: t("common:reloadPage"),
              onClick: () => window.location.reload(),
              startIcon: <RefreshOutlined />,
            },
          ]}
          height={400}
        />
      ) : loading && !tasks.length ? (
        <div>
          <CircularProgress
            style={{ marginTop: 15 }}
            aria-label={t("table.accountTasksLoading")}
          />
        </div>
      ) : hasNoTask ? (
        <EmptyState
          title={t("taskManager:noTaskTitle")}
          description={t("taskManager:noTaskDescription")}
          height={400}
        />
      ) : (
        <>
          <TasksTables tasks={tasks} onTaskSelected={onTaskSelected} />
          {loading && (
            <CircularProgress
              style={{ marginTop: 15 }}
              aria-label={t("table.accountTasksLoading")}
            />
          )}
          <div ref={ref} style={{ minWidth: 1, minHeight: 1 }} />
          {editingTask && (
            <EditTaskDialog
              taskId={editingTask.id}
              onClose={() => setEditingTask(undefined)}
              accountSearch={search}
            />
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.red[400],
  },
}));
