import React from "react";
import { camelCase } from "lodash";

import {
  ConnectionPredicateMetadata,
  CustomExtractionSetting,
  Metric,
} from "../../graphql";
import { filterReportMetrics } from "./filterReportMetrics";

type CustomExtractionType = Pick<
  CustomExtractionSetting,
  "label" | "reportTemplateCode"
>;

type MetricType = Pick<
  Metric,
  "code" | "name" | "type" | "description" | "supportedModules"
> & {
  connectionPredicates: Pick<
    ConnectionPredicateMetadata,
    "code" | "name" | "type" | "isArray"
  >[];
};

interface Props<TCustomExtractions, TMetrics> {
  customExtractionData: TCustomExtractions[] | null | undefined;
  metricsData: TMetrics[] | null | undefined;
  filter?: (metric: TMetrics) => boolean;
}

export interface FormattedMetrics<T extends MetricType = MetricType> {
  metrics: T[];
  filterMetrics: T[];
}

export function useFormattedMetrics<
  TCustomExtractions extends CustomExtractionType,
  TMetrics extends MetricType,
>({
  customExtractionData,
  metricsData,
  filter,
}: Props<TCustomExtractions, TMetrics>): FormattedMetrics<TMetrics> {
  const { metrics, filterMetrics } = React.useMemo(() => {
    const customExtractions =
      customExtractionData?.map((x) => ({
        ...x,
        reportTemplateCode: camelCase(x.reportTemplateCode),
      })) || [];
    const metrics = (
      metricsData?.map((metric) =>
        replaceCustomExtractionName(metric, customExtractions),
      ) || []
    ).filter((x) => (filter ? filter(x) : true));

    return {
      metrics,
      filterMetrics: filterReportMetrics(
        metrics,
        customExtractions.map((x) => x.reportTemplateCode),
      ),
    };
  }, [customExtractionData, metricsData, filter]);

  return {
    metrics,
    filterMetrics,
  };
}

function replaceCustomExtractionName<T extends MetricType>(
  metric: T,
  customExtractions: CustomExtractionType[],
): T {
  const customExtraction = customExtractions.find(
    (x) => x.reportTemplateCode === metric.code,
  );

  if (customExtraction)
    return {
      ...metric,
      name: customExtraction.label,
      description: metric.description.replace(
        metric.name,
        customExtraction.label,
      ),
    };

  return metric;
}
