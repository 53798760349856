/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, DocumentNode, gql, useQuery } from "@lumar/shared";
import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import React from "react";
import { ReportStat } from "../../graphql";
import { DataExplorerRow, DataExplorerTableConfig } from "../types";
import { formatMetrics } from "./formatMetrics";
import { generateQuery } from "./generateMetricFields";

interface Props {
  crawlId: string;
  tableConfig: DataExplorerTableConfig;
}

interface Result {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
  hasSegments: boolean;
}

export function useSegmentsData({ crawlId, tableConfig }: Props): Result {
  const {
    loading,
    error: queryError,
    data,
  } = useQuery(getDocument(tableConfig), {
    variables: { crawlId },
    fetchPolicy: "cache-first",
    errorPolicy: "all",
    onError: () => null,
  });

  // Note: The fetchMore function does not populate the error
  // field in the useQuery hook.
  const [fetchMoreError, setFetchMoreError] = React.useState<
    ApolloError | undefined
  >();
  React.useEffect(() => {
    if (loading && fetchMoreError) {
      setFetchMoreError(undefined);
    }
  }, [fetchMoreError, loading]);

  const error = queryError || fetchMoreError;

  const rows =
    data?.getCrawl?.reportStats
      ?.filter((x: ReportStat) => Boolean(x.segmentId))
      ?.map((report: ReportStat) => {
        const metrics = report.crawlUrlsAggregates?.nodes[0];
        return {
          segment: report.segment?.name,
          segmentId: report.segment?.id,
          ...(metrics ? formatMetrics(metrics) : {}),
        };
      }) || [];

  return {
    loading: data ? false : !error,
    error,
    rows,
    hasSegments: data?.getCrawl?.project?.segments.totalCount > 0,
  };
}

function getDocument(tableConfig: DataExplorerTableConfig): DocumentNode {
  const metricFields = generateQuery(tableConfig, false);

  const query = {
    query: {
      __name: "getAggregateData",
      __variables: {
        crawlId: "ObjectID!",
      },
      getCrawl: {
        __args: {
          id: new VariableType("crawlId"),
        },
        id: true,
        reportStats: {
          __args: {
            includeAllSegments: true,
            reportTemplateCodes: ["all_pages"],
          },
          crawlId: true,
          projectId: true,
          reportTemplateCode: true,
          segmentId: true,
          crawlUrlsAggregates: {
            nodes: {
              ...metricFields,
            },
          },
          segment: {
            id: true,
            name: true,
          },
        },
        project: {
          id: true,
          segments: {
            totalCount: true,
          },
        },
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}
