import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Domain } from "./domain/Domain";
import { Sources } from "./sources/Sources";
import { Limits } from "./limits/Limits";
import { Settings } from "./settings/Settings";
import { useSearchParam } from "../../_common/routing/useSearchParam";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";
import { Routes } from "../../_common/routing/routes";
import { useSpring, animated } from "react-spring";
import {
  ModuleSelector,
  AddonAvailability,
  useAccountGuard,
  useProjectGuard,
  useSession,
  SeoModuleIcon,
  AccessibilityModuleIcon,
  SiteSpeedModuleIcon,
  BasicModuleIcon,
} from "@lumar/shared";
import {
  ModuleCode,
  RoleCode,
  useGetProjectModuleCodeQuery,
} from "../../graphql";
import { SettingsDialogProvider } from "./components/SettingsDialog";
import { insertIf } from "../../_common/insertIf";
import { Button, SvgIcon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stepper: {
    borderBottom: "1px solid #E5E7EB",
    borderRadius: "8px 8px 0 0",
    paddingLeft: 0,
    paddingRight: "24px",
    paddingTop: "19px",
    paddingBottom: "19px",
    marginLeft: 0,
    flex: 1,
  },
  step: {
    cursor: "pointer",
  },
  paper: {
    borderRadius: 8,
    maxWidth: "1200px",
    border: "1px solid #E5E7EB",
  },
  stepperText: {
    fontSize: "0.8rem",
  },
  button: {
    fontSize: "0.8rem",
    paddingLeft: theme.spacing(2),
    height: 24,
    border: "0px solid transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonWrapper: {
    borderBottom: "1px solid #E5E7EB",
    display: "flex",
    alignItems: "center",
  },
  moduleSelector: {
    borderRadius: 0,
    border: "0px solid transparent",
    boxShadow: "none",
  },
  module: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  icon: {
    fill: "none",
    marginRight: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  iconRight: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  buttonLabel: {
    display: "flex",
    alignItems: "center",
  },
}));

export const translationNamespace = "crawlSettings";

export const CrawlSettings = React.memo(function CrawlSettings(): JSX.Element {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();
  const { account, hasSufficientRole, hasAddon, getAddonAvailability } =
    useSession();

  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const hasEditorRole = hasSufficientRole(RoleCode.Editor);
  const isInEditMode = Boolean(projectId);

  useAccountGuard();
  useProjectGuard({ skip: !isInEditMode });

  const { data } = useGetProjectModuleCodeQuery({
    variables: {
      projectId,
    },
    skip: !projectId,
  });

  const module = useSearchParam("module");

  const searchParams = useURLSearchParams();
  const history = useHistory();

  const stepParam = useSearchParam("step");

  const springProps = useSpring({
    from: { opacity: 0.2, marginTop: 30 },
    to: { opacity: 1, marginTop: 0 },
    config: { duration: 500 },
  });

  if (!hasEditorRole) {
    if (projectId) {
      history.replace(
        Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "history",
        }),
      );
    } else {
      history.replace(Routes.Projects.getUrl({ accountId }));
    }
  }

  const navigateToStep = (step: number, projectId?: string): void => {
    if (projectId) {
      history.push(
        Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "edit",
          step: step,
        }),
      );
    } else {
      searchParams.delete("step");
      searchParams.set("step", step.toString());
      history.push({ search: searchParams.toString() });
    }
  };

  const availableModules = [
    ...insertIf(hasAddon("custom-crawl"), {
      code: ModuleCode.Basic,
      icon: <BasicModuleIcon className={classes.icon} />,
      title: t("moduleSelector.basic.title"),
      description: t("moduleSelector.basic.description"),
      actionLabel: t("moduleSelector.selectModule", {
        module: t("moduleSelector.basic.title"),
      }),
    }),
    ...insertIf(hasAddon("seo"), {
      code: ModuleCode.Seo,
      icon: <SeoModuleIcon className={classes.icon} />,
      title: t("moduleSelector.seo.title"),
      description: t("moduleSelector.seo.description"),
      actionLabel: t("moduleSelector.selectModule", {
        module: t("moduleSelector.seo.title"),
      }),
      // TODO: Uncomment as soon as org cleans up accounts and starts giving the addon for free.
      // detail:
      //   getAddonAvailability("seo") === AddonAvailability.Free
      //     ? t("freeSeoModuleMessage", {
      //         credit: account.primaryAccountPackage.credits,
      //       })
      //     : undefined,
    }),
    ...insertIf(hasAddon("accessibility"), {
      code: ModuleCode.Accessibility,
      icon: <AccessibilityModuleIcon className={classes.icon} />,
      title: t("moduleSelector.accessibility.title"),
      description: t("moduleSelector.accessibility.description"),
      actionLabel: t("moduleSelector.selectModule", {
        module: t("moduleSelector.accessibility.title"),
      }),
      // TODO: Uncomment as soon as org cleans up accounts and starts giving the addon for free.
      // detail:
      //   getAddonAvailability("accessibility") === AddonAvailability.Free
      //     ? t("freeA11yModuleMessage", {
      //         credit: account.primaryAccountPackage.creditsAccessibility,
      //       })
      //     : undefined,
    }),
    ...insertIf(hasAddon("site-speed"), {
      code: ModuleCode.SiteSpeed,
      title: t("moduleSelector.siteSpeed.title"),
      icon: <SiteSpeedModuleIcon className={classes.icon} />,
      description: t("moduleSelector.siteSpeed.description"),
      actionLabel: t("moduleSelector.selectModule", {
        module: t("moduleSelector.siteSpeed.title"),
      }),
      detail:
        getAddonAvailability("site-speed") === AddonAvailability.Free &&
        account.primaryAccountPackage.creditsSiteSpeed !== 0
          ? t("freeSiteSpeedModuleMessage", {
              credit: account.primaryAccountPackage.creditsSiteSpeed,
            })
          : undefined,
    }),
  ];

  const moduleCode =
    isInEditMode && data
      ? data.getProject?.moduleCode
      : getModuleCode(module, availableModules);
  const startPage = (!stepParam && moduleCode) || isInEditMode ? 1 : 0;
  const convertedStep = stepParam ? parseInt(stepParam) : startPage;

  const typeSelector = (
    <ModuleSelector
      classes={{ root: classes.moduleSelector, container: classes.module }}
      modules={availableModules}
      title={t("moduleSelector.title")}
      description={t("moduleSelector.description")}
      onModuleSelected={(module) => {
        if (!projectId) searchParams.set("module", module);
        navigateToStep(1);
      }}
      selected={moduleCode}
      disabled={isInEditMode}
    />
  );

  const steps = [
    {
      title: t("stepDomain"),
      component: (
        <Domain
          next={(projectId) => navigateToStep(2, projectId)}
          moduleCode={!isInEditMode ? moduleCode : undefined}
        />
      ),
      availableInNew: true,
      testId: "crawl-settings-step-domain",
    },
    {
      title: t("stepSources"),
      component: <Sources next={() => navigateToStep(3)} />,
      availableInNew: false,
      testId: "crawl-settings-step-sources",
      "data-pendo": "sources-header",
    },
    {
      title: t("stepLimits"),
      component: <Limits next={() => navigateToStep(4)} />,
      availableInNew: false,
      testId: "crawl-settings-step-limits",
    },
    {
      title: t("stepSettings"),
      component: <Settings />,
      availableInNew: false,
      testId: "crawl-settings-step-settings",
    },
  ];

  const activeStep =
    convertedStep >= 0 && convertedStep <= steps.length
      ? convertedStep
      : startPage;

  const selectedModule = availableModules.find((e) => e.code === moduleCode);
  const buttonProps = !selectedModule
    ? { title: t("projectType") }
    : { title: selectedModule?.title, icon: selectedModule.icon };

  return (
    <SettingsDialogProvider>
      <animated.div style={springProps}>
        <Paper classes={{ root: classes.paper }}>
          <div style={{ display: "flex" }}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                size="small"
                classes={{ label: classes.buttonLabel }}
                endIcon={
                  <ArrowRight
                    height={17}
                    width={10}
                    className={classes.iconRight}
                  />
                }
                onClick={() => {
                  navigateToStep(0);
                }}
              >
                {buttonProps.icon}
                {buttonProps.title}
              </Button>
            </div>
            <Stepper activeStep={activeStep - 1} className={classes.stepper}>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};

                return (
                  <Step
                    key={step.title}
                    {...stepProps}
                    onClick={() => {
                      if (isInEditMode || (step.availableInNew && moduleCode)) {
                        navigateToStep(index + 1);
                      }
                    }}
                    classes={{
                      root: classes.step,
                      completed: classes.step,
                    }}
                    data-testid={step.testId}
                    data-pendo={step["data-pendo"]}
                    disabled={
                      !isInEditMode &&
                      Boolean(availableModules.length) &&
                      !moduleCode
                    }
                  >
                    <StepLabel
                      classes={{
                        root: classes.step,
                        labelContainer: classes.step,
                      }}
                      StepIconProps={{
                        classes: { text: classes.stepperText },
                      }}
                    >
                      {step.title}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {activeStep > 0 ? steps[activeStep - 1].component : typeSelector}
        </Paper>
      </animated.div>
    </SettingsDialogProvider>
  );
});

function getModuleCode(
  value: string | undefined,
  modules: { code: ModuleCode }[],
): ModuleCode | undefined {
  if (modules.length === 1) {
    return modules[0].code;
  }

  const module = modules.find((x) => x.code === value);
  if (module) {
    return module.code;
  }

  return;
}

export function ArrowRight(props: Parameters<typeof SvgIcon>[0]): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 10 18">
      <path
        d="M0.5 0.5L9 9L0.5 17.5"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
