import {
  Project,
  RenderingRobotsCheckMode,
  UpdateProjectInput,
} from "../../../../../graphql";
import { RobotsOverwriteSettings } from "../types";

type QueryData = Pick<
  Project,
  | "robotsOverwrite"
  | "useRobotsOverwrite"
  | "renderingRobotsCheckMode"
  | "ignoreRobotsForNavigationRequests"
>;

export function formatRobotsOverwriteSettingsFrom(
  data?: QueryData | null,
): RobotsOverwriteSettings {
  return {
    robots: data?.robotsOverwrite ?? "",
    enable: data?.useRobotsOverwrite ?? false,
    renderingRobotsEnabled:
      data?.renderingRobotsCheckMode ===
      RenderingRobotsCheckMode.RequestDisallowed,
    ignoreRobotsForNavigationRequests:
      data?.ignoreRobotsForNavigationRequests ?? false,
  };
}

export function formatRobotsOverwriteSettingsTo(
  formValues: RobotsOverwriteSettings,
): Pick<
  UpdateProjectInput,
  | "robotsOverwrite"
  | "useRobotsOverwrite"
  | "renderingRobotsCheckMode"
  | "ignoreRobotsForNavigationRequests"
> {
  return {
    robotsOverwrite: formValues.robots,
    useRobotsOverwrite: formValues.enable,
    renderingRobotsCheckMode: formValues.renderingRobotsEnabled
      ? RenderingRobotsCheckMode.RequestDisallowed
      : RenderingRobotsCheckMode.BlockDisallowed,
    ignoreRobotsForNavigationRequests:
      formValues.ignoreRobotsForNavigationRequests,
  };
}
