import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useTheme, withStyles } from "@material-ui/core";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { SmileySad } from "../../icons";
import { Typography } from "../typography/Typography";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
function BlueGridErrorOverlayInner(incomingProps) {
    var _a;
    const theme = useTheme();
    const { t } = useTranslation("grid");
    const props = Object.assign(Object.assign({}, (_a = theme.props) === null || _a === void 0 ? void 0 : _a.BlueGridErrorOverlay), incomingProps);
    const { title = t("overlay.errorTitle"), detail = t("overlay.errorDetail"), buttonText = t("overlay.buttonText"), hideReloadButton = false, onReload, classes, showIcon = true, } = props;
    const history = useHistory();
    function reloadPage() {
        onReload ? onReload() : history.go(0);
    }
    return (_jsxs(GridOverlay, { className: classes === null || classes === void 0 ? void 0 : classes.root, children: [showIcon ? _jsx(SmileySad, { className: classes === null || classes === void 0 ? void 0 : classes.icon }) : null, _jsx(Typography, { variant: "h2SemiBold", className: classes === null || classes === void 0 ? void 0 : classes.title, children: title }), _jsx(Typography, { variant: "body2", className: classes === null || classes === void 0 ? void 0 : classes.detail, children: detail }), hideReloadButton ? undefined : (_jsx(Button, { onClick: reloadPage, variant: "contained", color: "primary", classes: { root: classes === null || classes === void 0 ? void 0 : classes.button }, children: _jsx(Typography, { variant: "button", className: classes === null || classes === void 0 ? void 0 : classes.buttonText, children: buttonText }) }))] }));
}
export const BlueGridErrorOverlay = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(20),
    },
    icon: {
        color: theme.palette.red[400],
        fontSize: theme.typography.pxToRem(32),
        marginBottom: theme.spacing(2),
    },
    title: {
        color: theme.palette.grey[800],
        marginBottom: theme.spacing(1.5),
    },
    detail: {
        width: 328,
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
    },
    button: {
        padding: theme.spacing(1.2, 2, 1.2, 2),
        zIndex: 1,
    },
    buttonText: {
        lineHeight: theme.typography.pxToRem(17),
    },
}))(BlueGridErrorOverlayInner);
