import { TimeZoneContext, useTranslation } from "@lumar/shared";
import Papa from "papaparse";
import { Task } from "./data/types";
import { Crawl, LegacyTaskStatus, Maybe, Project } from "../graphql";
import { format, addMinutes } from "date-fns";
import React from "react";
import { useTaskStatusName, useTaskPriorityName } from "./data/helpers";

type ExportTasksProps = {
  tasks: Task[];
  project?: Pick<Project, "primaryDomain"> & {
    lastFinishedCrawl?: Maybe<Pick<Crawl, "createdAt">>;
  };
};

export function useExportTasks(): (props: ExportTasksProps) => void {
  const { t } = useTranslation("taskManager");
  const { timeZone } = React.useContext(TimeZoneContext);
  const getStatusName = useTaskStatusName();
  const getPriorityName = useTaskPriorityName();

  return ({ tasks, project }) => {
    if (!project) return;

    const data = [
      [
        t("exportHeaderStatus"),
        t("exportHeaderTitle"),
        t("table.headerDescription"),
        t("table.headerAssignedTo"),
        t("table.headerUrlCount"),
        t("table.headerUnresolvedPercent"),
        t("table.headerPriority"),
      ],
      ...[
        ...tasks.filter((x) => x.status === LegacyTaskStatus.Done),
        ...tasks.filter((x) => x.status === LegacyTaskStatus.Testing),
        ...tasks.filter((x) => x.status === LegacyTaskStatus.InProgress),
        ...tasks.filter((x) => x.status === LegacyTaskStatus.ToDo),
        ...tasks.filter(
          (x) => x.status === LegacyTaskStatus.Backlog || !x.status,
        ),
      ].map((task) => [
        getStatusName(task.status ?? LegacyTaskStatus.Backlog),
        task.title,
        task.description || "",
        task.assignedTo,
        task.remainingResolved,
        task.unresolvedPercentResolved * 100,
        getPriorityName(task.priority),
      ]),
    ];
    const csvData = new Blob(
      ["\ufeff" + Papa.unparse(data, { quotes: true })],
      {
        type: "text/csv",
      },
    );
    const csvUrl = URL.createObjectURL(csvData);

    const fileName = getExportName(project, timeZone.offset);

    const link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };
}

function getExportName(
  project: NonNullable<ExportTasksProps["project"]>,
  timeZoneOffset: number,
): string {
  const domainName = project.primaryDomain
    ?.replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .replace(".", "-")
    .split(/[/?#]/)[0];

  if (project.lastFinishedCrawl?.createdAt) {
    const date = new Date(project.lastFinishedCrawl.createdAt);
    return `${domainName}_${format(
      addMinutes(date, date.getTimezoneOffset() + timeZoneOffset),
      "dd-MM-yyyy",
    )}_Tasks`;
  }

  return `${domainName}_Tasks`;
}
