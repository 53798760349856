import {
  ErrorsChartConfigItem,
  GenerateErrorsChartConfigProps,
} from "../../../crawl-overview/dashboard/config/types";

export function getSiteSpeedErrorsChart({
  theme,
  t,
}: GenerateErrorsChartConfigProps): ErrorsChartConfigItem {
  return {
    columns: [
      "total",
      "name",
      {
        field: "savingsScore",
        hide: true,
        value: {
          siteSpeedAudits: {
            aggregate: "sum",
            metricCode: "savingsScore",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "sum",
            metricCode: "savingsScoreSum",
          },
        },
      },
      {
        field: "timeSavings",
        header: t("siteSpeedErrors.timeSavings"),
        align: "center",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "savingsSecs",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "wastedSecsAvg",
          },
        },
      },
      {
        field: "sizeSavings",
        header: t("siteSpeedErrors.sizeSavings"),
        align: "center",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "savingsKib",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "wastedKibAvg",
          },
        },
      },
      "trend",
    ],
    orderBy: {
      field: "savingsScore",
      direction: "desc",
    },
  };
}
