import { useTheme } from "@material-ui/core";

export function SeoIcon(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      width="64"
      height="64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      style={{
        marginBottom: theme.spacing(3.125),
      }}
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill={theme.palette.ultraviolet[50]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 32 8 C 25.6348 8 19.5303 10.5286 15.0294 15.0294 C 10.5286 19.5303 8 25.6348 8 32 C 8 38.3652 10.5286 44.4697 15.0294 48.9706 C 19.5303 53.4714 25.6348 56 32 56 C 38.3652 56 44.4697 53.4714 48.9706 48.9706 C 53.4714 44.4697 56 38.3652 56 32 C 56 25.6348 53.4714 19.5303 48.9706 15.0294 C 44.4697 10.5286 38.3652 8 32 8 Z"
        fill={theme.palette.ultraviolet[200]}
      />
      <circle
        cx="32.5"
        cy="32.5"
        r="16"
        stroke={theme.palette.ultraviolet[600]}
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 35.7385 16.6492 C 34.4704 16.2819 33.1638 16.064 31.8452 16 C 34.7905 20.6742 36.5952 25.9769 37.1131 31.4791 H 26.887 C 27.414 25.9425 29.2258 20.6345 32.1573 16 C 30.8379 16.0638 29.5305 16.2817 28.2616 16.6492 C 25.7951 21.2407 24.2902 26.2877 23.8389 31.4815 H 15.067 C 14.9777 32.4927 14.9777 33.5098 15.067 34.5209 H 23.8389 C 24.2898 39.7137 25.7939 44.7598 28.2592 49.3508 C 29.5051 49.7107 30.8093 49.9343 32.1548 50 C 29.2083 45.3253 27.4028 40.0217 26.8845 34.5185 H 37.1131 C 36.5948 40.0217 34.7893 45.3253 31.8428 50 C 33.1623 49.9354 34.4697 49.7167 35.7385 49.3484 C 38.2047 44.7576 39.7096 39.7114 40.1611 34.5185 H 48.933 C 49.0223 33.5073 49.0223 32.4902 48.933 31.4791 H 40.1611 C 39.7107 26.2855 38.2066 21.2385 35.7409 16.6468 L 35.7385 16.6492 Z"
        fill={theme.palette.ultraviolet[600]}
      />
    </svg>
  );
}
