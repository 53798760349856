import { withOnBlurUpdate } from "./CustomFields";
import { FieldProps } from "formik";
import { Typography, TextFieldProps } from "@material-ui/core";
import { fieldToTextField, TextField } from "@lumar/shared";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  input: {
    maxWidth: 400,
  },
  label: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[700],
  },
  readonlyValue: {
    marginTop: theme.spacing(1),
  },
}));

export const CustomTextField = withOnBlurUpdate(function CustomTextField({
  readonlyValue,
  ...props
}: FieldProps<string> &
  TextFieldProps & {
    readonlyValue?: string;
    "data-testid"?: string;
  }): JSX.Element {
  const classes = useStyles();
  const filedProps = fieldToTextField(props);
  const label = props.label;

  if (readonlyValue) {
    return (
      <>
        <Typography variant="caption" className={classes.label}>
          {label}
        </Typography>
        <Typography
          variant="caption"
          className={classes.readonlyValue}
          data-testid={props["data-testid"]}
        >
          {readonlyValue}
        </Typography>
      </>
    );
  }

  return (
    <TextField
      {...filedProps}
      value={readonlyValue ?? filedProps.value}
      inputProps={{
        ...filedProps.inputProps,
        readOnly: Boolean(readonlyValue),
      }}
    />
  );
});
