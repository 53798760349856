/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityAriaAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityAriaAChart.title"),
    description: () => t("accessibilityAriaAChart.description"),
    reportTemplateCodesOrder: [
      "aria_allowed_attr_issues",
      "aria_command_name_issues",
      "aria_hidden_body_issues",
      "aria_input_field_name_issues",
      "aria_meter_name_issues",
      "aria_progressbar_name_issues",
      "aria_required_attr_issues",
      "aria_required_children_issues",
      "aria_required_parent_issues",
      "aria_roledescription_issues",
      "aria_roles_issues",
      "aria_toggle_field_name_issues",
      "aria_tooltip_name_issues",
      "aria_valid_attr_value_issues",
      "aria_valid_attr_issues",
    ],
    reportStatsLimit: 15,
    reportStatsOrderBy: {
      field: "basic",
      direction: "desc",
    },
    reportStatFilter: (report) => {
      return [
        "aria_allowed_attr_issues",
        "aria_command_name_issues",
        "aria_hidden_body_issues",
        "aria_input_field_name_issues",
        "aria_meter_name_issues",
        "aria_progressbar_name_issues",
        "aria_required_attr_issues",
        "aria_required_children_issues",
        "aria_required_parent_issues",
        "aria_roledescription_issues",
        "aria_roles_issues",
        "aria_toggle_field_name_issues",
        "aria_tooltip_name_issues",
        "aria_valid_attr_value_issues",
        "aria_valid_attr_issues",
      ].includes(report.reportTemplateCode);
    },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
