import React from "react";
import {
  ApolloError,
  BlueDataGrid,
  DatabaseSystem,
  PlusSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { SegmentsListData } from "./data/useSegmentsList";
import { uniq } from "lodash";
import { insertManyIf } from "../../_common/insertIf";
import { useSegmentGridColumns } from "./useSegmentGridColumns";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { SegmentSearch } from "./SegmentSerchField";
import { SegmentsGeridRightToolbar } from "./SegmentsGeridRightToolbar";
import { RoleCode } from "../../graphql";
import { SegmentActions } from "./ManageSegment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  grid: {
    "& .MuiDataGrid-toolbarContainer": {
      background: "white",
    },
  },
}));

interface Props {
  loading: boolean;
  error?: ApolloError;
  crawl: SegmentsListData["crawl"];
  crawlInProgress: boolean;
  segments: SegmentsListData["segments"];
  maxSegmentCount: number;
  metrics: NarrowMetric[];
  actions: SegmentActions;
}

export interface SegmentGroup {
  id: string;
  isGroup: true;
  name: string | undefined;
  open: boolean;
  segments: (SegmentsListData["segments"][0] & { isGroup?: undefined })[];
}

export type SegmentRow = SegmentGroup | SegmentGroup["segments"][0];

export function SegmentGrid({
  loading,
  error,
  crawlInProgress,
  crawl,
  segments,
  maxSegmentCount,
  actions,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("segments");
  const { hasSufficientRole } = useSession();

  const [groups, setGroups] = React.useState<SegmentGroup[]>([]);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    setGroups((prevGroups) => {
      const groups: SegmentGroup[] = uniq(
        segments.map((x) => x.group || undefined),
      ).map((group) => {
        const id = group || "default";
        const prevGroup = prevGroups.find((x) => x.id === id);

        const groupSegments = segments.filter(
          (segment) => (segment.group || undefined) === group,
        );

        return {
          id,
          isGroup: true,
          name: group,
          open: prevGroup?.open || false,
          count: groupSegments.length,
          selected: false,
          segments: groupSegments,
        };
      });

      // eslint-disable-next-line fp/no-mutating-methods
      return groups.sort(complareSegmentGroups);
    });
  }, [segments, setGroups]);

  const rows = React.useMemo(() => {
    const fomrattedSearch = search.toLocaleLowerCase();
    return groups.flatMap((group) => {
      const segments = group.segments.filter((x) =>
        x.name.toLocaleLowerCase().includes(fomrattedSearch),
      );
      if (!segments.length) {
        return [];
      }

      return [group, ...insertManyIf(group.open, segments)];
    });
  }, [groups, search]);

  const createSegmentDisabled = segments.length >= maxSegmentCount;

  const columns = useSegmentGridColumns({
    crawlInProgress,
    crawlStatus: crawl?.statusEnum,
    createSegmentDisabled,
    setGroups,
    actions,
  });

  return (
    <SegmentSelectionContextProvider segments={segments}>
      <BlueDataGrid
        loading={loading}
        error={error}
        rows={rows}
        totalRowCount={Math.max(rows.length, groups.length)}
        autoHeight
        columns={columns}
        components={{
          ToolbarLeft: SegmentSearch,
          ToolbarRight: SegmentsGeridRightToolbar,
        }}
        componentsProps={{
          toolbarLeft: {
            search,
            setSearch,
            disabled: loading || Boolean(error),
          },
          toolbarRight: {
            onCreateSegment: actions.onCreateSegment,
            onCopySegment: actions.onCopySegment,
            disabled: loading || Boolean(error),
            createSegmentDisabled,
          },
          noRowsOverlay: {
            title: t("noSegments.title"),
            detail: t("noSegments.detail"),
            icon: DatabaseSystem,
            onClick: hasSufficientRole(RoleCode.Editor)
              ? () => actions.onCreateSegment()
              : undefined,
            buttonText: t("createSegment"),
            buttonIcon: PlusSolid,
          },
        }}
        pagination={false}
        autoRowHeight
        className={classes.grid}
      />
    </SegmentSelectionContextProvider>
  );
}

export const SegmentSelectionContext = React.createContext<{
  selectedRows: Record<string, boolean>;
  setRowSelected: (id: string | string[], selected: boolean) => void;
  setAllRowsSelected: (selected: boolean) => void;
}>({
  selectedRows: {},
  setRowSelected: () => null,
  setAllRowsSelected: () => null,
});

function SegmentSelectionContextProvider({
  children,
  segments,
}: {
  children: React.ReactNode;
  segments: SegmentsListData["segments"];
}): JSX.Element {
  const [selectedRows, setSelectedRows] = React.useState<
    Record<string, boolean>
  >({});

  React.useEffect(() => {
    setSelectedRows((prevValue) =>
      Object.fromEntries(
        segments
          .filter((x) => x.isSupportedFormat)
          .map((x) => [x.id, prevValue[x.id] || false]),
      ),
    );
  }, [segments]);

  const setRowSelected = React.useCallback(
    (id: string | string[], selected: boolean) =>
      setSelectedRows((values) => {
        const ids = (Array.isArray(id) ? id : [id]).filter(
          (id) => values[id] !== undefined,
        );
        return {
          ...values,
          ...Object.fromEntries(ids.map((id) => [id, selected])),
        };
      }),
    [setSelectedRows],
  );

  const setAllRowsSelected = React.useCallback(
    (selected: boolean) =>
      setSelectedRows((values) =>
        Object.fromEntries(
          Object.entries(values).map(([key]) => [key, selected]),
        ),
      ),
    [setSelectedRows],
  );

  return (
    <SegmentSelectionContext.Provider
      value={{
        selectedRows,
        setRowSelected,
        setAllRowsSelected,
      }}
    >
      {children}
    </SegmentSelectionContext.Provider>
  );
}

function complareSegmentGroups(a: SegmentGroup, b: SegmentGroup): number {
  if (a.id === "default") return -1;
  if (b.id === "default") return 1;

  const nameA = (a.name || "").toLocaleLowerCase();
  const nameB = (b.name || "").toLocaleLowerCase();
  return nameA.localeCompare(nameB);
}
