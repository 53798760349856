import { Project, UpdateProjectInput } from "../../../../../graphql";
import { ReportSettings } from "../types";
import { convertNumber } from "./helpers";

type QueryData = Pick<
  Project,
  | "maxTitleWidth"
  | "minTitleLength"
  | "minDescriptionLength"
  | "maxDescriptionLength"
  | "maxHtmlSize"
  | "maxLinks"
  | "thinPageThreshold"
  | "emptyPageThreshold"
  | "maxExternalLinks"
  | "maxBodyContentLength"
  | "maxUrlLength"
  | "maxLoadTime"
  | "duplicatePrecision"
  | "maxRedirections"
  | "logSummaryRequestsLow"
  | "logSummaryRequestsHigh"
>;

export function formatReportSettingsFrom(
  data?: QueryData | null,
): ReportSettings {
  return {
    maxTitleWidth: data?.maxTitleWidth?.toString() ?? "",
    minTitleWidth: data?.minTitleLength?.toString() ?? "",
    minDescriptionLength: data?.minDescriptionLength?.toString() ?? "",
    maxDescriptionLength: data?.maxDescriptionLength?.toString() ?? "",
    maxHtmlSize: data?.maxHtmlSize?.toString() ?? "",
    maxLinks: data?.maxLinks?.toString() ?? "",
    thinPageThreshold: data?.thinPageThreshold?.toString() ?? "",
    emptyPageThreshold: data?.emptyPageThreshold?.toString() ?? "",
    maxExternalLinks: data?.maxExternalLinks?.toString() ?? "",
    maxContentSize: data?.maxBodyContentLength?.toString() ?? "",
    maxUrlLength: data?.maxUrlLength?.toString() ?? "",
    maxFetchTime: data?.maxLoadTime?.toString() ?? "",
    duplicatePrecision: data?.duplicatePrecision?.toString() ?? "",
    maxRedirections: data?.maxRedirections?.toString() ?? "",
    lowLogSummaryRequests: data?.logSummaryRequestsLow?.toString() ?? "",
    highLogSummaryRequests: data?.logSummaryRequestsHigh?.toString() ?? "",
  };
}

export function formatReportSettingsTo(
  formValues: ReportSettings,
): Pick<
  UpdateProjectInput,
  | "maxTitleWidth"
  | "minTitleLength"
  | "minDescriptionLength"
  | "maxDescriptionLength"
  | "maxHtmlSize"
  | "maxLinks"
  | "thinPageThreshold"
  | "emptyPageThreshold"
  | "maxFollowedExternalLinks"
  | "maxBodyContentLength"
  | "maxUrlLength"
  | "maxLoadTime"
  | "duplicatePrecision"
  | "maxRedirections"
  | "logSummaryRequestsLow"
  | "logSummaryRequestsHigh"
> {
  return {
    maxTitleWidth: convertNumber(formValues.maxTitleWidth),
    minTitleLength: convertNumber(formValues.minTitleWidth),
    minDescriptionLength: convertNumber(formValues.minDescriptionLength),
    maxDescriptionLength: convertNumber(formValues.maxDescriptionLength),
    maxHtmlSize: convertNumber(formValues.maxHtmlSize),
    maxLinks: convertNumber(formValues.maxLinks),
    thinPageThreshold: convertNumber(formValues.thinPageThreshold),
    emptyPageThreshold: convertNumber(formValues.emptyPageThreshold),
    maxFollowedExternalLinks: convertNumber(formValues.maxExternalLinks),
    maxBodyContentLength: convertNumber(formValues.maxContentSize),
    maxUrlLength: convertNumber(formValues.maxUrlLength),
    maxLoadTime: convertNumber(formValues.maxFetchTime),
    duplicatePrecision: convertNumber(formValues.duplicatePrecision),
    maxRedirections: convertNumber(formValues.maxRedirections),
    logSummaryRequestsLow: convertNumber(formValues.lowLogSummaryRequests),
    logSummaryRequestsHigh: convertNumber(formValues.highLogSummaryRequests),
  };
}
