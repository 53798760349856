import { Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { FieldProps } from "formik";
import { Label } from "../../../../_common/components/Label";
import { FormValues } from "../data/types";
import { ScriptEditor } from "../../components/Editors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  label: {
    margin: theme.spacing(4, 0, 1.25, 0),
  },
}));

export function CustomScripts({
  field,
  form,
}: FieldProps<FormValues["customScripts"]>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  return (
    <div className={classes.root}>
      <Typography>{t("customScriptsDescription")}</Typography>
      <Label label={t("accessibilityScriptsLabel")} className={classes.label} />

      <ScriptEditor
        name="custom-accessibility-javascript"
        value={field.value.accessibility}
        onChange={(value) =>
          form.setFieldValue("customScripts.accessibility", value)
        }
        readOnly={form.isSubmitting}
      />
    </div>
  );
}
