import { ReportCategoryChartConfig } from "../types";
import { getExperienceBrokenChart } from "../../../../_common/charts/config/getExperienceBrokenChart";
import { getExperienceChart } from "../../../../_common/charts/config/getExperienceChart";
import { getExperienceTrend } from "../../../../_common/charts/config/getExperienceTrend";
import { getExternalLinkBreakdownChart } from "../../../../_common/charts/config/getExternalLinkBreakdownChart";
import { getHttpsIssuesChart } from "../../../../_common/charts/config/getHttpsIssuesChart";
import { getPerformanceSlow } from "../../../../_common/charts/config/getPerformanceSlow";
import { getResourceIssuesChart } from "../../../../_common/charts/config/getResourceIssuesChart";

export const generateExperienceCategoryChartConfig: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getExperienceChart,
      getExperienceTrend,
      getPerformanceSlow,
      getExperienceBrokenChart,
      getResourceIssuesChart,
      getHttpsIssuesChart,
      getExternalLinkBreakdownChart,
    ],
  };
