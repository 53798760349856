import { Typography } from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function DescriptionCell({ value }: GridRenderCellParams): JSX.Element {
  const classes = useStyles();

  if (typeof value !== "string") return <></>;

  return (
    <Tooltip title={value} classes={{ tooltip: classes.tooltip }}>
      <Typography variant="caption" className={classes.text}>
        {value}
      </Typography>
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    lineHeight: theme.typography.pxToRem(16),
    whiteSpace: "normal",
    wordBreak: "break-all",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  tooltip: {
    maxWidth: 600,
  },
}));
