import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "../../routing/routes";
import { useSearchParam } from "../../routing/useSearchParam";
import { useColorConfig } from "./useColorConfig";

export function useChartReportColorGetter(): (
  reportTemplateCode?: string,
) => string | undefined {
  const { isExact = false } = useRouteMatch(Routes.CrawlOverview.ROUTE) || {};
  const isDashboard = isExact;
  const category = useSearchParam("category") || "";
  const config = useColorConfig();

  return React.useCallback(
    (reportTemplateCode?: string) => {
      if (!reportTemplateCode) {
        return;
      }
      if (isDashboard) {
        const color =
          config.categoryReportColor[category]?.[reportTemplateCode] ||
          config.globalReportColor[reportTemplateCode];
        return color;
      } else {
        return config.globalReportColor[reportTemplateCode];
      }
    },
    [category, config, isDashboard],
  );
}
