import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SwitchVerticalSolid } from "@lumar/shared";

import { Header, Order } from "./types";

interface Props {
  headers: Header[];
  selected?: string[];
  sortModel?: { field: string; order: Order };
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  disableSorting: boolean;
  classes?: {
    header?: string;
    headerRow?: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(16),
      padding: theme.spacing(0, 2),
      userSelect: "none",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      boxShadow: `-7px 0px 0px -6px ${theme.palette.grey[200]}`,
      background: "white",
      "&:hover .inline-edit-button": {
        opacity: 1,
      },
    },
    sortable: {
      cursor: "pointer",
      padding: theme.spacing(0, 0.75, 0, 2),
    },
    tableSortLabel: {
      width: "100%",
      color: theme.palette.grey[800],
      "&:hover": {
        color: theme.palette.grey[800],
      },
      "&:hover $tableSortIcon": {
        opacity: 1,
      },
    },
    tableSortIcon: {
      color: theme.palette.grey[400],
      opacity: 1,
    },
    headerContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
  }),
);

export function EnhancedTableHead(props: Props): JSX.Element {
  const {
    headers,
    sortModel,
    onRequestSort,
    disableSorting,
    classes: providedClasses,
  } = props;

  const classes = useStyles();

  const renderHeaders = (headers: Header[]): JSX.Element[] =>
    headers.map(
      (
        {
          sortable,
          title,
          id,
          inlineEdit,
          colSpan,
          width,
          numeric,
          center,
        }: Header,
        headerIndex: number,
      ) => {
        const hasSorting = sortable && !disableSorting;

        return (
          <TableCell
            key={`${title}-${headerIndex}`}
            className={clsx(
              classes.header,
              hasSorting && classes.sortable,
              providedClasses?.header,
            )}
            colSpan={colSpan ? colSpan : 1}
            style={{ width: width ?? "auto" }}
            onClick={(event) => {
              if (hasSorting && id) {
                onRequestSort?.(event, id);
              }
            }}
            align={numeric ? "right" : center ? "center" : "left"}
            data-testid={`enhanced-table-header-cell-${id}`}
          >
            <Box display={center ? "inline-block" : "flex"}>
              {hasSorting && id ? (
                <TableSortLabel
                  active={sortModel?.field === id}
                  direction={sortModel?.field === id ? sortModel?.order : "asc"}
                  data-testid={`enhanced-table-header-sort-button-${id}`}
                  IconComponent={
                    sortModel?.field === id
                      ? ArrowDownwardIcon
                      : SwitchVerticalSolid
                  }
                  classes={{
                    root: classes.tableSortLabel,
                    icon: classes.tableSortIcon,
                  }}
                >
                  <div className={classes.headerContent}>
                    {title}
                    {inlineEdit}
                  </div>
                </TableSortLabel>
              ) : (
                <div className={classes.headerContent}>
                  {title}
                  {inlineEdit}
                </div>
              )}
            </Box>
          </TableCell>
        );
      },
    );

  return (
    <TableHead>
      <TableRow className={providedClasses?.headerRow}>
        {renderHeaders(headers)}
      </TableRow>
    </TableHead>
  );
}
