import { BreadcrumbItem } from "@lumar/shared";
import { DatasourceCode } from "../../graphql";
import { ReportImpact } from "../../_common/report-helpers/reportImpact";
import { ReportPriority } from "../../_common/report-helpers/reportPriority";
import { ReportGridTabsMode } from "../report-rows/report-grid/grid-tabs/GridTabs";

export interface ReportPageData {
  loading: boolean;
  error?: Error;
  title: string;
  breadcrumbs: BreadcrumbItem[];
  info?: ReportSummaryInfo | ReportAdviceInfo;
  datasourceCode: DatasourceCode | undefined;
  reportTemplateCode?: string;
  reportTemplateQueryVersion: number;
  lastFinishedCrawlId?: string;
  reportAdviceCollapsed: boolean;
  tabsMode: ReportGridTabsMode;
}

interface ReportInfoBase {
  reportName: string;
  priority: ReportPriority;
  impact: ReportImpact;
}

export interface ReportSummaryInfo extends ReportInfoBase {
  summary?: string;
  description?: string;
}

export interface ReportAdviceInfo extends ReportInfoBase {
  definition: string;
  effect: string;
  solutions: string;
  benefit: string;
}

export function isReportAdvice(
  value?: ReportSummaryInfo | ReportAdviceInfo,
): value is ReportAdviceInfo {
  return Boolean((value as ReportAdviceInfo)?.definition);
}
