/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

import { Trans } from "@lumar/shared";
import { Typography } from "@material-ui/core";

export function getIndexablePagesBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("indexablePagesBreakdown.title"),
    description: () => (
      <Trans
        ns="charts"
        i18nKey="indexablePagesBreakdown.description"
        components={[
          <Typography key="0" paragraph variant="inherit" />,
          <Typography key="1" variant="inherit" />,
        ]}
      />
    ),
    reportTemplateCodesOrder: [
      "unique_pages",
      "duplicate_pages",
      "paginated_pages",
      "mobile_alternates",
    ],
    reportStatFilter: (report) => {
      return [
        "unique_pages",
        "duplicate_pages",
        "paginated_pages",
        "mobile_alternates",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
