import {
  DestructiveMenuActionConfirmation,
  useTranslation,
} from "@lumar/shared";
import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { RunningCrawlQuery } from "../../../../graphql";
import { RunningCrawl } from "../../../useProjectPoller";
import { CrawlControlsListItemProps } from "../crawlControlsConfig";

const useStyles = makeStyles((theme) => ({
  crawlControlButton: {
    backgroundColor: "white",
    border: "1px solid #D1D5DB",
    width: "100%",
    justifyContent: "flex-start",
    marginTop: 7,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: 6,
    color: theme.palette.grey[700],
    paddingLeft: 10,
  },
  buttonLabel: { marginLeft: 5, fontWeight: 600 },
}));

export function CrawlControlsListItem(
  props: CrawlControlsListItemProps & {
    project?: RunningCrawlQuery;
    runningCrawl?: RunningCrawl;
  },
): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlProgress");

  const [isConfirmingAction, setIsConfirmingAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const hidden = props.hidden(props.runningCrawl, props.project);
  if (hidden) return <></>;

  const testId = props.label.toLowerCase().replaceAll(" ", "-");

  return (
    <>
      <Button
        className={classes.crawlControlButton}
        disabled={props.disabled(props.runningCrawl, props.project)}
        onClick={(e) => {
          if (props.needActionConfirmation) {
            setIsConfirmingAction(true);
            setAnchorEl(e.currentTarget);
          } else {
            props.onClick(props.runningCrawl, props.project);
          }
        }}
        data-testid={`${testId}-button`}
      >
        <props.icon style={{ color: props.iconColor }} />
        <Typography variant="body2" className={classes.buttonLabel}>
          {props.label}
        </Typography>
      </Button>
      {isConfirmingAction ? (
        <DestructiveMenuActionConfirmation
          title={props.actionTitle ? props.actionTitle : ""}
          description={t("crawlControls.actionDescription")}
          onConfirm={() => {
            props.onClick(props.runningCrawl, props.project);
            setIsConfirmingAction(false);
          }}
          onCancel={() => {
            setAnchorEl(null);
            setIsConfirmingAction(false);
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "left" }}
          transformOrigin={{ horizontal: "left" }}
        />
      ) : null}
    </>
  );
}
