/* eslint-disable fp/no-mutating-methods */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { startOfDay, endOfDay, addMinutes } from "date-fns";

import {
  CrawlStatus,
  ConnectionPredicate,
  DateTimeRange,
  CrawlType,
} from "../../graphql";
import {
  FilterRuleFormValue,
  PredicateValue,
} from "../../_common/connection-filtering/types";
import { uniq } from "lodash";

export interface FormatFiltersArguments {
  filters: FilterRuleFormValue[];
  metadata: {
    currentBillingPeriod: DateTimeRange;
  };
  timeZoneOffset?: number;
}

export interface FormatFiltersEntry {
  _and?: any[];
  _or?: any[];
}

export interface FormatFiltersValue {
  _and: FormatFiltersEntry[];
}

interface Interval {
  start: string;
  end: string;
}

function getDateInterval(
  predicateValue: PredicateValue,
  timeZoneOffset: number,
): Interval {
  const date = new Date(predicateValue.toString());
  const dayStart = addMinutes(
    startOfDay(addMinutes(date, date.getTimezoneOffset())),
    -date.getTimezoneOffset() - timeZoneOffset,
  ).toISOString();
  const dayEnd = addMinutes(
    endOfDay(addMinutes(date, date.getTimezoneOffset())),
    -date.getTimezoneOffset() - timeZoneOffset,
  ).toISOString();

  return {
    start: dayStart,
    end: dayEnd,
  };
}

function addDateEntriesForEqualsKey(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  andFilters.push({
    _and: [
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Ge]: interval.start,
        },
      },
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Le]: interval.end,
        },
      },
    ],
  });
}

function addDateEntriesForNegations(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  andFilters.push({
    _or: [
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Ge]: interval.end,
        },
      },
      {
        [filter.metricCode]: {
          [ConnectionPredicate.Le]: interval.start,
        },
      },
    ],
  });
}

function addDateEntriesForOtherCases(
  filter: FilterRuleFormValue,
  andFilters: FormatFiltersEntry[],
  interval: Interval,
): void {
  if (
    filter.predicateKey === ConnectionPredicate.Le ||
    filter.predicateKey === ConnectionPredicate.Gt
  ) {
    andFilters.push({
      _and: [
        {
          [filter.metricCode]: {
            [filter.predicateKey]: interval.end,
          },
        },
      ],
    });
  } else if (
    filter.predicateKey === ConnectionPredicate.Ge ||
    filter.predicateKey === ConnectionPredicate.Lt
  ) {
    andFilters.push({
      _and: [
        {
          [filter.metricCode]: {
            [filter.predicateKey]: interval.start,
          },
        },
      ],
    });
  }
}

export const formatFilters = ({
  filters,
  metadata,
  timeZoneOffset,
}: FormatFiltersArguments): {
  filter: FormatFiltersValue | undefined;
  crawlTypeCodes?: CrawlType[];
  crawlTypeCodesExcluded?: CrawlType[];
} => {
  const andFilters: FormatFiltersEntry[] = [];
  const crawlTypeCodes: CrawlType[] = [];
  const crawlTypeCodesExcluded: CrawlType[] = [];

  filters.map((filter) => {
    const includeProject =
      (filter.predicateKey === ConnectionPredicate.Eq &&
        filter.predicateValue === true) ||
      (filter.predicateKey === ConnectionPredicate.Ne &&
        filter.predicateValue === false);

    const keyIsNegation = (
      [
        ConnectionPredicate.Ne,
        ConnectionPredicate.NotContains,
        ConnectionPredicate.NotMatchesRegex,
      ] as string[]
    ).includes(filter.predicateKey);

    const dateMetric = ["createdAt", "lastCrawlCrawlingAt", "finishedAt"];

    const runningStatuses: CrawlStatus[] = [
      CrawlStatus.Crawling,
      CrawlStatus.Finalizing,
      CrawlStatus.Discovering,
      CrawlStatus.Queuing,
      CrawlStatus.Queued,
      CrawlStatus.Paused,
    ];

    if (filter.metricCode === "schedulesTotalCount") {
      if (includeProject) {
        andFilters.push({
          _and: [
            {
              schedulesTotalCount: {
                [ConnectionPredicate.Eq]: 1,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _and: [
            {
              schedulesTotalCount: {
                [ConnectionPredicate.Eq]: 0,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "siteTest") {
      andFilters.push({
        _and: [
          {
            siteTest: {
              [ConnectionPredicate.IsNull]: includeProject ? false : true,
            },
          },
        ],
      });
    } else if (filter.metricCode === "nameOrDomain") {
      if (keyIsNegation) {
        andFilters.push({
          _and: [
            {
              name: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
            {
              sitePrimary: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _or: [
            {
              name: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
            {
              sitePrimary: {
                [filter.predicateKey]: filter.predicateValue,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "running") {
      if (includeProject) {
        andFilters.push({
          _or: runningStatuses.map((status) => ({
            lastCrawlStatus: {
              [ConnectionPredicate.Eq]: status,
            },
          })),
        });
      } else {
        andFilters.push({
          _and: [
            {
              lastCrawlStatus: {
                [ConnectionPredicate.NotIn]: runningStatuses,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "active") {
      const { currentBillingPeriod } = metadata;
      if (includeProject) {
        andFilters.push({
          _and: [
            {
              lastCrawlCrawlingAt: {
                [ConnectionPredicate.Ge]: currentBillingPeriod.start,
              },
            },
            {
              lastCrawlCrawlingAt: {
                [ConnectionPredicate.Le]: currentBillingPeriod.end,
              },
            },
          ],
        });
      } else {
        andFilters.push({
          _or: [
            {
              lastCrawlCrawlingAt: {
                [ConnectionPredicate.Le]: currentBillingPeriod.start,
              },
            },
            {
              lastCrawlCrawlingAt: {
                [ConnectionPredicate.Ge]: currentBillingPeriod.end,
              },
            },
            {
              lastCrawlCrawlingAt: {
                isNull: true,
              },
            },
          ],
        });
      }
    } else if (filter.metricCode === "crawlUrls") {
      andFilters.push({
        _or: [
          {
            crawlUrlsTotal: {
              isNull: false,
              [filter.predicateKey]: filter.predicateValue,
            },
          },
          {
            crawlUrlsTotal: { isNull: true },
            allPagesTotal: { [filter.predicateKey]: filter.predicateValue },
          },
        ],
      });
    } else if (filter.metricCode.startsWith("crawlSource")) {
      const crawlType = filter.metricCode.substring(11);

      if (isCrawlType(crawlType)) {
        if (includeProject) {
          crawlTypeCodes.push(crawlType);
        } else {
          crawlTypeCodesExcluded.push(crawlType);
        }
      }
    } else if (dateMetric.includes(filter.metricCode)) {
      const interval = getDateInterval(
        filter.predicateValue,
        timeZoneOffset ?? 0,
      );
      if (filter.predicateKey === ConnectionPredicate.Eq) {
        addDateEntriesForEqualsKey(filter, andFilters, interval);
      } else if (keyIsNegation) {
        addDateEntriesForNegations(filter, andFilters, interval);
      } else {
        addDateEntriesForOtherCases(filter, andFilters, interval);
      }
    } else {
      andFilters.push({
        _and: [
          {
            [filter.metricCode]: {
              [filter.predicateKey]: filter.predicateValue,
            },
          },
        ],
      });
    }
  });

  return {
    filter: andFilters.length
      ? {
          _and: andFilters,
        }
      : undefined,
    crawlTypeCodes: crawlTypeCodes.length ? uniq(crawlTypeCodes) : undefined,
    crawlTypeCodesExcluded: crawlTypeCodesExcluded.length
      ? uniq(crawlTypeCodesExcluded)
      : undefined,
  };
};

function isCrawlType(value: any): value is CrawlType {
  return Object.values(CrawlType).includes(value);
}
