var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Link as ReactRouterLink } from "react-router-dom";
/*
 * React router's `<Link>` component wrapper which
 * allows to navigate to external URLs when the `to` prop string starts with `http`.
 */
export function Link(props) {
    if (typeof props.to === "string") {
        if (props.to.startsWith("http")) {
            // Making sure to not to pass unknown attributes.
            const { to } = props, rest = __rest(props, ["to"]);
            return _jsx("a", Object.assign({}, rest, { href: to }));
        }
    }
    return _jsx(ReactRouterLink, Object.assign({}, props));
}
