import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCrawlGuardQuery } from "../../graphql";
export function useCrawlGuard(config = {}) {
    const { skip = false, redirectLocation = "/login" } = config;
    const history = useHistory();
    const { crawlId } = useParams();
    if (!crawlId && !skip) {
        console.error("`useCrawlGuard` has been called in a route without `crawlId` param!");
    }
    const { data, loading } = useCrawlGuardQuery({
        variables: { crawlId },
        fetchPolicy: "cache-first",
        errorPolicy: "ignore",
        skip: !crawlId || skip,
    });
    React.useEffect(() => {
        var _a;
        if (!crawlId || skip) {
            return;
        }
        const crawlNotFound = !((_a = data === null || data === void 0 ? void 0 : data.getCrawl) === null || _a === void 0 ? void 0 : _a.id);
        if (!loading && crawlNotFound) {
            history.replace(redirectLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, crawlId]);
}
