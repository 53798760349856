import { Project, UpdateProjectInput } from "../../../../../graphql";
import { DomainScopeSettings } from "../types";

type QueryData = Pick<
  Project,
  | "primaryDomain"
  | "secondaryDomains"
  | "includeSubdomains"
  | "includeHttpAndHttps"
>;

export function formatDomainScopeSettingsFrom(
  data?: QueryData | null,
): DomainScopeSettings {
  return {
    primaryDomain: data?.primaryDomain ?? "",
    secondaryDomains: data?.secondaryDomains ?? [],
    crawlSubDomains: data?.includeSubdomains ?? false,
    crawlHttpAndHttps: data?.includeHttpAndHttps ?? false,
  };
}

export function formatDomainScopeSettingsTo(
  formValues: DomainScopeSettings,
): Pick<
  UpdateProjectInput,
  | "primaryDomain"
  | "secondaryDomains"
  | "includeSubdomains"
  | "includeHttpAndHttps"
> {
  return {
    primaryDomain: formValues.primaryDomain,
    secondaryDomains: formValues.secondaryDomains,
    includeSubdomains: formValues.crawlSubDomains,
    includeHttpAndHttps: formValues.crawlHttpAndHttps,
  };
}
