/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilitySeverityChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilitySeverityChart.title"),
    description: () => t("accessibilitySeverityChart.description"),
    reportTemplateCodesOrder: [
      "critical_issues",
      "serious_issues",
      "moderate_issues",
      "minor_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "critical_issues",
        "serious_issues",
        "moderate_issues",
        "minor_issues",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "a11y-severity-chart",
  };
}
