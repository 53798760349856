import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  monospace: {
    "font-variant-numeric": "tabular-nums",
  },
});

export function MonospaceNumber(props: {
  children: ReactNode;
  className?: string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <span className={clsx(classes.monospace, props.className)}>
      {props.children}
    </span>
  );
}
