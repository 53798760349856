import React from "react";
import {
  BlueDataGrid,
  PlusSolid,
  useAccountGuard,
  useSession,
  useTranslation,
  BlueGridRowSelector,
  TabSpinner,
} from "@lumar/shared";
import { useHistory, useParams } from "react-router-dom";
import { RoleCode } from "../graphql";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { Routes } from "../_common/routing/routes";
import { FiltersButton } from "./projects-filter/FiltersButton";
import { useSpring, animated } from "react-spring";
import { useAccountProjectsVariants } from "./useAccountProjectsVariants";
import { AccountProjectsContext } from "./data/useAccountProjectsContext";
import { Tab, Tabs, makeStyles } from "@material-ui/core";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { getPrisitinePaginationWithCurrentPageSize } from "./getPristinePaginationWithCurrentPageSize";
import { useAccountProjectsPageConfig } from "./useAccountProjectsPageConfig";
import { useAccountProjectsData } from "./data/useAccountProjectsData";
import { insertIf } from "../_common/insertIf";
import { NewProjectButton } from "./NewProjectButton";
import { ColumnSelectorButton } from "../report/report-rows/report-grid/rows/columns-selector-button/ColumnSelectorButton";
import { ColumnSelector } from "../report/report-rows/report-grid/columns/column-selector/ColumnSelector";
import { ProjectSearchField } from "./projects-filter/ProjectSearchField";
import { ProjectStatusFilter } from "./projects-filter/ProjectStatusFilter";
import { useDebounedFunction } from "../_common/useDebounedFunction";
import { NoProjectsIcon } from "./assets/NoProjectsIcon";

export function AccountProjects(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("projectsList");
  const history = useHistory();
  const session = useSession();
  const hasEditorPermissions = session.hasSufficientRole(RoleCode.Editor);
  const params = useURLSearchParams();
  const classes = useStyles();

  useAccountGuard();

  const { active: variant, mainVariants } = useAccountProjectsVariants();

  const {
    getTitle,
    filter: baseFilter,
    defaultSort,
    columns,
    noResultsOverlayProps,
    noRowsOverlayProps,
    hideProjectStatusFilter,
    saveColumnsState,
  } = useAccountProjectsPageConfig({ variant });

  const {
    loading,
    error,
    projects,
    projectCount,
    projectTotalCount,
    projectTotalCountModule,
    pagination,
    ...contextData
  } = useAccountProjectsData({
    baseFilter,
    variant,
    columns,
    defaultSort,
    projectCountModules: mainVariants.map((x) => x.moduleCode ?? "All"),
  });

  const { debounce } = useDebounedFunction(300);

  const title = getTitle(projectTotalCount);

  const springProps = useSpring({
    from: { opacity: 0.2, marginTop: 45 },
    to: { opacity: 1, marginTop: 15 },
    config: { duration: 500 },
  });

  function resetFilters(): void {
    params.delete("filter");
    const pagination = getPrisitinePaginationWithCurrentPageSize(params);
    if (pagination) {
      params.set("pagination", pagination);
    }
    history.push({ search: params.toString() });
  }

  return (
    <AccountProjectsContext.Provider value={contextData}>
      <TopNavigation title={title} loading={loading} />
      {Boolean(mainVariants.length) && (
        <Tabs
          value={variant}
          onChange={(_, value) => {
            const pagination =
              getPrisitinePaginationWithCurrentPageSize(params);
            history.push(
              Routes.Projects.getUrl({
                accountId,
                type: value,
                pagination,
              }),
            );
          }}
          variant="standard"
          indicatorColor="primary"
        >
          {mainVariants.map((tab) => {
            const count = projectTotalCountModule[tab.moduleCode ?? "All"];
            return (
              <Tab
                key={tab.variant}
                label={
                  <>
                    {tab.name}{" "}
                    {count === undefined && loading ? <TabSpinner /> : null}
                    {count !== undefined ? `(${count})` : null}
                  </>
                }
                icon={<tab.icon />}
                value={tab.variant}
                classes={{
                  root: classes.tab,
                  wrapper: classes.tabWrapper,
                  labelIcon: classes.tabLabelIcon,
                }}
              />
            );
          })}
        </Tabs>
      )}
      <animated.div style={springProps} data-testid="all-projects-table">
        <BlueDataGrid
          key={variant}
          error={error}
          loading={loading}
          columns={columns}
          rows={projects}
          rowCount={projectCount}
          totalRowCount={projectTotalCount}
          autoRowHeight
          rowHeight={70}
          rowsPerPageOptions={[10, 20, 25, 50, 100]}
          sortingOrder={["desc", "asc", null]}
          components={{
            ToolbarLeft: [
              ProjectSearchField,
              FiltersButton,
              ...insertIf(!hideProjectStatusFilter, ProjectStatusFilter),
              ColumnSelectorButton,
            ],
            ToolbarRight: [
              BlueGridRowSelector,
              ...insertIf(hasEditorPermissions, NewProjectButton),
            ],
            ColumnsPanel: ColumnSelector,
          }}
          disableColumnsButton
          disableColumnSelector={false}
          sticky
          componentsProps={{
            noResultsOverlay: {
              icon: NoProjectsIcon,
              title: t("noProjectsFound.title"),
              detail: t("noProjectsFound.description"),
              onClick: () => resetFilters(),
              buttonText: t("clearFilterButton"),
              ...noResultsOverlayProps,
            },
            noRowsOverlay: {
              icon: NoProjectsIcon,
              title: t("noProjectsInAccount.title"),
              detail: t("noProjectsInAccount.description"),
              onClick: hasEditorPermissions
                ? () => history.push(Routes.NewProject.getUrl({ accountId }))
                : undefined,
              buttonIcon: PlusSolid,
              buttonText: t("newProjectButton"),
              classes: {
                root: classes.overlay,
              },
              ...noRowsOverlayProps,
            },
          }}
          {...pagination}
          onColumnResize={(p, e, d) =>
            debounce(() => saveColumnsState(d.api.state))
          }
          onColumnOrderChange={(p, e, d) =>
            debounce(() => saveColumnsState(d.api.state))
          }
          onColumnVisibilityChange={(p, e, d) =>
            debounce(() => saveColumnsState(d.api.state))
          }
        />
      </animated.div>
    </AccountProjectsContext.Provider>
  );
}

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  tabWrapper: {
    padding: theme.spacing(0.625, 1),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tabLabelIcon: {
    minHeight: "unset",
    "& $tabWrapper > *:first-child": {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
  overlay: {
    minHeight: 500,
    width: "100%",
    marginTop: 60,
    marginBottom: 0,
  },
}));
