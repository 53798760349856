import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";

import { REGEX_PATTERNS } from "../../../../../_common/constants/regex-patterns.constant";
import { translationNamespace } from "../../../CrawlSettings";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLogzioSchema(): any {
  const { t } = useTranslation(translationNamespace);

  return Yup.object().shape({
    baseUrl: Yup.string().matches(
      REGEX_PATTERNS.url,
      t("sources.logSummary.invalidDomain"),
    ),
    dateRange: Yup.number()
      .typeError(t("validationError.number"))
      .min(1, t("validationError.between", { min: 1, max: 30 }))
      .max(30, t("validationError.between", { min: 1, max: 30 }))
      .required(t("validationError.required")),
    maxRows: Yup.number()
      .typeError(t("validationError.number"))
      .min(1, t("validationError.between", { min: 1, max: 10000000 }))
      .max(10000000, t("validationError.between", { min: 1, max: 10000000 }))
      .required(t("validationError.required")),
    pathFieldName: Yup.string().required(t("validationError.required")),
    userAgentFieldName: Yup.string().required(t("validationError.required")),
    desktopUaRegexp: Yup.string().required(t("validationError.required")),
    mobileUaRegexp: Yup.string().required(t("validationError.required")),
  });
}
