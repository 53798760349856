import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useTheme } from "@material-ui/core";
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarColumnsButton, GridToolbarDensitySelector, } from "@mui/x-data-grid-pro";
import { BlueGridSearchField } from "./BlueGridSearchField";
import { BlueGridToolbarExport, } from "./BlueGridToolbarExport";
export function BlueGridToolbar({ gridName, LeftComponents, RightComponents, enableExport, exportFileName, disabled, defaultColumn, defaultOperator, searchFieldProps, disableColumnsButton, toolbarRight, toolbarLeft, }) {
    const theme = useTheme();
    return (_jsxs(GridToolbarContainer, { children: [_jsxs(Box, { display: "flex", width: "100%", justifyContent: "space-between", flexWrap: "wrap", style: { rowGap: theme.spacing(0.5) }, children: [_jsxs(Box, { display: "flex", flexWrap: "wrap", style: { rowGap: theme.spacing(0.5) }, children: [defaultColumn && (_jsx(BlueGridSearchField, Object.assign({ defaultColumn: defaultColumn, defaultOperator: defaultOperator, disabled: disabled, "data-testid": `${gridName}-search-field` }, searchFieldProps))), LeftComponents &&
                                (Array.isArray(LeftComponents) ? (LeftComponents.map((LeftChild, idx) => (_jsx(LeftChild, Object.assign({ disabled: disabled }, toolbarLeft), idx)))) : (_jsx(LeftComponents, Object.assign({ disabled: disabled }, toolbarLeft))))] }), _jsx(Box, { display: "flex", flexWrap: "wrap", style: { rowGap: theme.spacing(0.5) }, children: RightComponents &&
                            (Array.isArray(RightComponents) ? (RightComponents.map((RightChild, idx) => (_jsx(RightChild, Object.assign({ disabled: disabled }, toolbarRight), idx)))) : (_jsx(RightComponents, Object.assign({ disabled: disabled }, toolbarRight)))) })] }), _jsx(GridToolbarFilterButton, {}), disableColumnsButton ? null : _jsx(GridToolbarColumnsButton, {}), _jsx(GridToolbarDensitySelector, {}), enableExport && (_jsx(BlueGridToolbarExport, { disabled: disabled, "data-testid": `${gridName}-table-export`, exportFileName: exportFileName }))] }));
}
