import {
  Chip,
  fieldToSelect,
  PriorityHighestIcon,
  PriorityHighIcon,
  PriorityLowestIcon,
  PriorityLowIcon,
  PriorityMediumIcon,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import { Label } from "./Label";
import { LegacyTaskPriority } from "../../graphql";
import { PrioritySelect } from "./PrioritySelect";

export function PriorityField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const theme = useTheme();

  function getPriorityChip(priority: LegacyTaskPriority): React.ReactNode {
    switch (priority) {
      case LegacyTaskPriority.Critical:
        return (
          <Chip
            color="red"
            label={t("severity.critical")}
            icon={
              <PriorityHighestIcon
                className={classes.icon}
                style={{ color: theme.palette.red[600] }}
              />
            }
            className={classes.chip}
          />
        );
      case LegacyTaskPriority.High:
        return (
          <Chip
            color="orange"
            label={t("severity.high")}
            icon={
              <PriorityHighIcon
                className={classes.icon}
                style={{ color: theme.palette.red[600] }}
              />
            }
            className={classes.chip}
          />
        );
      case LegacyTaskPriority.Medium:
        return (
          <Chip
            color="yellow"
            label={t("severity.medium")}
            icon={
              <PriorityMediumIcon
                className={classes.icon}
                style={{ color: theme.palette.orange[400] }}
              />
            }
            className={classes.chip}
          />
        );
      case LegacyTaskPriority.Low:
        return (
          <Chip
            color="lightgrey"
            label={t("severity.low")}
            icon={
              <PriorityLowIcon
                className={classes.icon}
                style={{ color: theme.palette.blue[500] }}
              />
            }
            className={classes.chip}
          />
        );
      case LegacyTaskPriority.Note:
      default:
        return (
          <Chip
            color="white"
            label={t("severity.note")}
            icon={
              <PriorityLowestIcon
                className={classes.icon}
                style={{ color: theme.palette.blue[500] }}
              />
            }
            className={classes.chip}
          />
        );
    }
  }

  return (
    <Field name="priority">
      {(props: FieldProps<LegacyTaskPriority>) =>
        !isEditing ? (
          <>
            <Label label={t("editDialog.priority")} />
            {getPriorityChip(props.field.value)}
          </>
        ) : (
          <PrioritySelect
            {...fieldToSelect(props)}
            className={classes.input}
            label={t("editDialog.priority")}
          />
        )
      }
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.75, 0, 1, 0),
    padding: theme.spacing(0, 0.5),
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
  input: {
    width: 150,
    margin: theme.spacing(0, 0, 1, 0),
    "& label": {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
    },
  },
}));
