import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityStructureAChart } from "../../../../../_common/charts/config/getAccessibilityStructureAChart";
import { getAccessibilityStructureATrend } from "../../../../../_common/charts/config/getAccessibilityStructureATrend";

export const generateAccessibilityStructureACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityStructureAChart,
      getAccessibilityStructureATrend,
    ],
  };
