import { FormikProps } from "formik";
import { FormValues } from "./useLogzioFormValues";
import { Grid, Hidden, MenuItem, makeStyles } from "@material-ui/core";
import {
  useTranslation,
  Alert,
  Chip,
  Select,
  TextField,
  TextFieldFormProps,
  Typography,
  formToSelectProps,
  formToTextFieldProps,
} from "@lumar/shared";
import { LogzioConnectionValues } from "./useLogzioQueries";
import { Label } from "../../../../../_common/components/Label";
import React from "react";
import { SourcesContext } from "../../data/useSourcesFormValues";
import { withDisplayValue } from "../../../components/TextFieldInput";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const useStyles = makeStyles((theme) => ({
  container: {
    rowGap: theme.spacing(0.5),
  },
  divider: {
    height: 1,
    background: theme.palette.grey[300],
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  queryLabelContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 1, 1, 1),
    display: "flex",
    flexDirection: "column",
  },
  errorAlert: {
    marginTop: theme.spacing(0.5),
  },
}));

interface Props {
  form: FormikProps<FormValues>;
  connections: LogzioConnectionValues[];
  querryErrors: string[];
}

const integerPattern = /^(\+?\d+)?$/;

export function LogzioForm({
  form,
  connections,
  querryErrors,
}: Props): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const { primaryDomain } = React.useContext(SourcesContext);

  const maxRowsInput = React.useMemo(
    () =>
      withDisplayValue((value) =>
        t("sources.logSummary.logzio.query.maximumUrls", {
          value,
        }),
      ),
    [t],
  );

  const dateRangeInput = React.useMemo(
    () =>
      withDisplayValue((value) =>
        t("sources.logSummary.logzio.query.dateRangeText", {
          value,
        }),
      ),
    [t],
  );

  function formToIntegerTextFieldProps(
    form: FormikProps<FormValues>,
    name: string,
  ): TextFieldFormProps {
    const props = formToTextFieldProps(form, name);
    return {
      ...props,
      onChange: (e) =>
        integerPattern.test(e.target.value) && props.onChange?.(e),
    };
  }

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <Select
          {...formToSelectProps(form, "connectionId")}
          label={t("sources.logSummary.logzio.query.token")}
          fullWidth
        >
          <MenuItem value="" disabled>
            {t("sources.logSummary.logzio.query.accountPlaceholder")}
          </MenuItem>
          {connections.map((connection, idx) => (
            <MenuItem
              key={idx}
              value={connection.id}
              data-testid="crawl-settings-segment"
            >
              {connection.label}
              {connection.user ? ` - ${connection.user}` : ""}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "pathFieldName")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.urlFieldName")}
              info={t("sources.logSummary.logzio.query.urlFieldNameInfo")}
            />
          }
          placeholder={t(
            "sources.logSummary.logzio.query.urlFieldNamePlaceholder",
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "baseUrl")}
          label={t("sources.logSummary.logzio.query.baseUrl")}
          placeholder={primaryDomain}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToIntegerTextFieldProps(form, "maxRows")}
          label={t("sources.logSummary.logzio.query.maxUrls")}
          InputProps={{
            inputComponent: maxRowsInput,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToIntegerTextFieldProps(form, "dateRange")}
          label={t("sources.logSummary.logzio.query.dateRange")}
          InputProps={{
            inputComponent: dateRangeInput,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.divider} />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "userAgentFieldName")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.userAgentFieldName")}
              info={t("sources.logSummary.logzio.query.uaFieldNameInfo")}
            />
          }
          placeholder={t(
            "sources.logSummary.logzio.query.uaFieldNamePlaceholder",
          )}
        />
      </Grid>

      <Hidden only={["xs", "sm"]}>
        <Grid item md={6} />
      </Hidden>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "desktopUaRegexp")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.desktopUaRegexp")}
              info={t("sources.logSummary.logzio.query.desktopUARegexInfo")}
            />
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "desktopUaNotRegexp")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.desktopUaNotRegexp")}
              info={t("sources.logSummary.logzio.query.desktopUANotRegexInfo")}
            />
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "mobileUaRegexp")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.mobileUaRegexp")}
              info={t("sources.logSummary.logzio.query.mobileAgentRegexInfo")}
            />
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          {...formToTextFieldProps(form, "mobileUaNotRegexp")}
          label={
            <Label
              label={t("sources.logSummary.logzio.query.mobileUaNotRegexp")}
              info={t(
                "sources.logSummary.logzio.query.mobileAgentNotRegexInfo",
              )}
            />
          }
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.queryLabelContainer}>
          <Label
            label={t("sources.logSummary.logzio.query.queryFilter")}
            info={t("sources.logSummary.logzio.query.queryFilterInfo")}
          />
          {querryErrors.length === 0 ? (
            <Chip
              label={t("sources.logSummary.logzio.query.valid")}
              color="primary"
              size="small"
              style={{ marginBottom: "4px" }}
            />
          ) : (
            <Chip
              label={t("sources.logSummary.logzio.query.invalid")}
              color="red"
              size="small"
              style={{ marginBottom: "4px" }}
            />
          )}
        </div>
        <AceEditor
          value={form.values.queryFilter}
          onChange={(newValue) => form.setFieldValue("queryFilter", newValue)}
          readOnly={form.isSubmitting}
          placeholder="Query"
          theme="tomorrow"
          name="logzio-query-editor"
          mode="json"
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          editorProps={{
            $blockScrolling: Infinity,
          }}
          height="200px"
          width="100%"
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
          }}
        />
      </Grid>

      <Grid item xs={12} className={classes.error} style={{ padding: 0 }}>
        {querryErrors.length > 0 && (
          <Typography>
            {t("sources.logSummary.logzio.query.queryError", {
              count: querryErrors.length,
            })}
          </Typography>
        )}
        {querryErrors.map((error, idx) => (
          <Alert
            key={idx}
            size="small"
            severity="error"
            className={classes.errorAlert}
          >
            {error}
          </Alert>
        ))}
      </Grid>
    </Grid>
  );
}
