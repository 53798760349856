import { ApolloError, getRawCrawlId, useTranslation } from "@lumar/shared";
import React from "react";
import {
  GetCrawlsForChangedMetricsQuery,
  useGetCrawlsForChangedMetricsQuery,
} from "../../../graphql";
import { QueryReturnValue } from "../../data/types";
import { useResourceDetailParams } from "../../data/useResourceDetailParams";
import { CrawlsListDataData } from "./types";

export function useCrawlsListData({
  currentCrawlDate,
}: {
  currentCrawlDate: Date;
}): QueryReturnValue<CrawlsListDataData> {
  const params = useResourceDetailParams();

  const {
    data: queryData,
    loading,
    error: queryError,
  } = useGetCrawlsForChangedMetricsQuery({
    variables: {
      projectId: params.projectId,
      crawlCreatedAt: currentCrawlDate.toISOString(),
    },
    fetchPolicy: "cache-first",
  });

  const result = useFormattedData({
    data: queryData,
    error: queryError,
  });

  if (loading) return { loading: true };
  if (result.error !== undefined)
    return {
      loading: false,
      error: result.error,
    };

  return {
    loading: false,
    data: result.data,
  };
}

function useFormattedData({
  data,
  error,
}: {
  data?: GetCrawlsForChangedMetricsQuery;
  error?: ApolloError;
}):
  | { data: CrawlsListDataData; error?: undefined }
  | { data?: undefined; error: string } {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo(() => {
    if (error) return { error: error.message };

    if (!data || !data.getProject)
      return { loading: false, error: t("errorResourceNotFound") };

    const crawls = [
      ...data.getProject.newerCrawls.nodes,
      ...data.getProject.olderCrawls.nodes,
    ].map((crawl) => ({
      id: getRawCrawlId(crawl.id),
      createdAt: new Date(crawl.createdAt),
    }));

    return {
      data: {
        crawls,
      },
    };
  }, [data, error, t]);
}
