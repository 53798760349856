import { useTranslation } from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { ResourceDetailData } from "../../data/types";
import { UncrawledUrlsOverview } from "./sections/UncrawledUrlsOverview";

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
};

export function useUncrawledUrlsSections(): {
  sections: (Section & {
    items: Section[];
  })[];
  active: Section;
} {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const sections = [
    {
      code: "overview",
      name: t("overview"),
      component: UncrawledUrlsOverview,
      items: [],
    },
  ];

  const flatSection = sections.flatMap((section) => [
    section,
    ...section.items.map((item) => item),
  ]);
  const foundSection = type
    ? flatSection.find((x) => x.code === type)
    : undefined;

  return {
    sections,
    active: foundSection ?? flatSection[0],
  };
}
