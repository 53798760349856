import {
  Chip,
  fieldToSelect,
  formToTextFieldProps,
  Select,
  TextField,
  Typography,
  updateIfFieldsChanged,
  useNumberFormatter,
} from "@lumar/shared";
import { makeStyles, MenuItem, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { FastField, FieldProps, useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { LabelInfo } from "../../../../_common/components/LabelInfo";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { CustomTextField } from "../../components/CustomTextField";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { getUserAgents } from "../data/getUserAgents";
import { SettingsContext } from "../data/useContextValues";
import { SettingControlFactory } from "../useAdvancedControls";
import { get } from "lodash";

export const getMobileSiteControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.mobileSite.title"),
  path: "mobileSite",
  // eslint-disable-next-line react/display-name
  control: () => <MobileSite />,
  testId: "crawl-settings-advanced-mobile-site",
  activeValues: ["enable"],
  openOnInitValues: ["enable"],
  textForSearch: createTextForSearch([
    t("settings.mobileSite.title"),
    t("settings.mobileSite.description"),
    t("settings.mobileSite.checkbox"),
    t("settings.mobileSite.mobileSite"),
    t("settings.mobileSite.homePage"),
    t("settings.mobileSite.homePageInfo"),
    t("settings.mobileSite.urlPattern"),
    t("settings.mobileSite.urlPatternInfo"),
    t("settings.mobileSite.userAgent"),
    t("settings.mobileSite.customFullLabel"),
    t("settings.mobileSite.customFullPlaceholder"),
    t("settings.mobileSite.customFullTooltip"),
    t("settings.mobileSite.customShortLabel"),
    t("settings.mobileSite.customShortPlaceholder"),
    t("settings.mobileSite.customShortTooltip"),
  ]),
  fieldNames: [
    ["homePage", t("settings.mobileSite.homePage")],
    ["pattern", t("settings.mobileSite.urlPattern")],
    ["customFull", t("settings.mobileSite.customFullLabel")],
    ["customShort", t("settings.mobileSite.customShortLabel")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
  input: {
    maxWidth: 400,
  },
  longInput: {
    maxWidth: 600,
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  viewportField: {
    maxWidth: 250,
    marginTop: theme.spacing(2),
  },
}));

export const MobileSite = React.memo(MobileSiteInner);

function MobileSiteInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const { t: tUnits } = useTranslation("units");
  const classes = useStyles();
  const formatNumber = useNumberFormatter();
  const theme = useTheme();
  const contextValues = React.useContext(SettingsContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik = useFormikContext<any>();

  const defaultUserAgent = formik.values?.spider?.mobileSite?.agent;

  const filteredUserAgents = getUserAgents({
    t,
    userAgents: contextValues.userAgents,
    module: contextValues.module,
    include: [defaultUserAgent],
  }).filter(
    (agent) =>
      agent.code === defaultUserAgent ||
      (!agent.isDeprecated && agent.isPublic),
  );

  const userAgent = filteredUserAgents.find(
    (x) => x.code === get(formik.values, "spider.mobileSite.agent"),
  );

  if (contextValues.isPlanLight) {
    return (
      <>
        <Typography variant="caption" className={classes.description}>
          {t("settings.mobileSite.description")}
        </Typography>
        <UpgradeMessage
          upgradeType="LightPlusOrCorporate"
          className={classes.indent}
        />
      </>
    );
  }

  const suffix = contextValues.module.userAgentSuffixEnabled
    ? contextValues.userAgentSuffix
    : "";

  const isCustomUserAgent = userAgent?.code === "Custom";
  const isCustomUserAgentMobile = !!get(
    formik.values,
    "spider.mobileSite.userAgentIsMobile",
  );
  const viewportWidth = formatNumber(
    isCustomUserAgent
      ? isCustomUserAgentMobile
        ? contextValues.module.viewportWidthMobile
        : contextValues.module.viewportWidthDesktop
      : userAgent?.viewportWidth,
  );

  return (
    <>
      <Typography variant="caption" className={classes.description}>
        {t("settings.mobileSite.description")}
      </Typography>

      <FastField
        name="spider.mobileSite.enable"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("settings.mobileSite.checkbox") }}
        data-testid="use-moblie-site"
      />

      <Typography
        variant="subtitle2Medium"
        className={clsx(classes.indent, classes.title)}
      >
        {t("settings.mobileSite.mobileSite")}
      </Typography>

      <FastField name="spider.mobileSite.homePage">
        {(props: FieldProps<string>) => (
          <CustomTextField
            {...props}
            label={
              <LabelInfo
                label={t("settings.mobileSite.homePage")}
                info={t("settings.mobileSite.homePageInfo")}
              />
            }
            className={clsx(classes.input, classes.indent)}
            data-testid="mobile-domain"
          />
        )}
      </FastField>

      <FastField name="spider.mobileSite.pattern">
        {(props: FieldProps<string>) => (
          <CustomTextField
            {...props}
            label={
              <LabelInfo
                label={t("settings.mobileSite.urlPattern")}
                info={t("settings.mobileSite.urlPatternInfo")}
              />
            }
            className={clsx(classes.input, classes.indent)}
            data-testid="mobile-url-pattern"
          />
        )}
      </FastField>

      <Typography
        variant="subtitle2Medium"
        className={clsx(classes.indent, classes.title)}
      >
        {t("settings.mobileSite.userAgent")}
      </Typography>

      <FastField name="spider.mobileSite.agent">
        {(props: FieldProps<string>) => {
          return (
            <Select
              {...fieldToSelect(props)}
              label={t("settings.mobileSite.userAgent")}
              className={clsx(classes.input)}
              data-testid="select-mobile-agent"
            >
              <MenuItem value="None" disabled>
                {t("settings.mobileSite.selectAgent")}
              </MenuItem>
              {filteredUserAgents.map((agent) => (
                <MenuItem value={agent.code} key={agent.code}>
                  <Typography
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {agent.name}
                    {agent.isDeprecated && (
                      <Chip
                        label={t("userAgentDeprecated")}
                        color="yellow"
                        style={{ marginLeft: "auto" }}
                      />
                    )}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          );
        }}
      </FastField>

      {isCustomUserAgent ? (
        <FastField
          name="spider.mobileSite.userAgentIsMobile"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: t("settings.mobileSite.isMobileUserAgent") }}
          data-testid="mobile-user-agent-is-mobile"
          className={classes.checkbox}
        />
      ) : null}

      <FastField
        name="spider.mobileSite.customFull"
        shouldUpdate={updateIfFieldsChanged("spider.mobileSite.agent")}
      >
        {(props: FieldProps<string>) => (
          <CustomTextField
            {...props}
            readonlyValue={
              userAgent?.string ? `${userAgent?.string} ${suffix}` : undefined
            }
            label={
              <LabelInfo
                label={t("settings.mobileSite.customFullLabel")}
                info={t("settings.mobileSite.customFullTooltip")}
              />
            }
            placeholder={t("settings.mobileSite.customFullPlaceholder")}
            className={clsx(classes.longInput, classes.indent)}
            InputProps={{
              endAdornment: (
                <span style={{ marginLeft: theme.spacing(2) }}>{suffix}</span>
              ),
            }}
            data-testid="custom-mobile-agent-full"
          />
        )}
      </FastField>

      <FastField
        name="spider.mobileSite.customShort"
        shouldUpdate={updateIfFieldsChanged("spider.mobileSite.agent")}
      >
        {(props: FieldProps<string>) => (
          <CustomTextField
            {...props}
            readonlyValue={userAgent?.token || undefined}
            label={
              <LabelInfo
                label={t("settings.mobileSite.customShortLabel")}
                info={t("settings.mobileSite.customShortTooltip")}
              />
            }
            placeholder={t("settings.mobileSite.customShortPlaceholder")}
            className={clsx(classes.longInput, classes.indent)}
            data-testid="custom-mobile-agent-short"
          />
        )}
      </FastField>

      <TextField
        {...formToTextFieldProps(formik, "spider.mobileSite.viewportWidth")}
        type="number"
        label={t("viewportWidth")}
        placeholder={viewportWidth}
        InputProps={{ endAdornment: tUnits("pixelUnit") }}
        className={classes.viewportField}
      />

      <TextField
        {...formToTextFieldProps(formik, "spider.mobileSite.viewportHeight")}
        type="number"
        label={t("viewportHeight")}
        placeholder={formatNumber(userAgent?.viewportHeight)}
        InputProps={{ endAdornment: tUnits("pixelUnit") }}
        className={classes.viewportField}
      />
    </>
  );
}
