/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesCLS(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedCLSIssues.title"),
    description: () => t("siteSpeedCLSIssues.description"),
    reportTemplateCodesOrder: [
      "non_composited_animations_failed_audits",
      "layout_shift_elements_failed_audits",
      "unsized_images_failed_audits",
    ],
    reportStatFilter: (report) => {
      return [
        "non_composited_animations_failed_audits",
        "layout_shift_elements_failed_audits",
        "unsized_images_failed_audits",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
