var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { GridApiContext, useGridApi, useGridSelector, } from "@mui/x-data-grid-pro";
import { ButtonBase, Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import { assert } from "../helpers";
import { ArrowNarrowLeftSolid, ArrowNarrowRightSolid } from "../../icons";
import { useTranslation } from "react-i18next";
function BlueGridPaginationInner(_a) {
    var _b;
    var { apiRef: prividedApiRef, gridName, previousLabel, nextLabel, 
    // Note: The current limit of offset based pagination allowed by the API is 1M
    maxAllowedRows = 1000000, className, classes, disabled, pendoPrefix, hideLastPages, autoHide } = _a, props = __rest(_a, ["apiRef", "gridName", "previousLabel", "nextLabel", "maxAllowedRows", "className", "classes", "disabled", "pendoPrefix", "hideLastPages", "autoHide"]);
    const apiRef = (_b = useContext(GridApiContext)) !== null && _b !== void 0 ? _b : prividedApiRef;
    assert(apiRef);
    const api = useGridApi(apiRef);
    const { t } = useTranslation("grid");
    const { page, pageCount, pageSize } = useGridSelector(apiRef, (state) => state.pagination);
    const maxAllowedPage = maxAllowedRows / pageSize;
    const maxPageCount = pageCount > maxAllowedPage ? maxAllowedPage : pageCount;
    const previousDisabled = page === 0 || disabled;
    const nextDisabled = page === maxPageCount - 1 || disabled;
    const hide = pageCount === 1 && autoHide;
    if (hide)
        return _jsx(_Fragment, {});
    return (_jsxs("div", Object.assign({ className: clsx(className, classes === null || classes === void 0 ? void 0 : classes.root) }, props, { children: [_jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.base }), _jsxs(ButtonBase, { onClick: () => api.setPage(page - 1), disabled: previousDisabled, disableRipple: true, className: clsx(classes === null || classes === void 0 ? void 0 : classes.navigationLeft, previousDisabled ? classes === null || classes === void 0 ? void 0 : classes.navigationDisabled : ""), "data-testid": `${gridName}-pagination-previous`, "data-pendo": pendoPrefix ? `${pendoPrefix}-pagination-previous-page` : undefined, children: [_jsx(ArrowNarrowLeftSolid, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.leftIcon, previousDisabled ? classes === null || classes === void 0 ? void 0 : classes.iconDisabled : "") }), _jsx(Typography, { style: { fontSize: 14 }, children: previousLabel !== null && previousLabel !== void 0 ? previousLabel : t("pagination.previous") })] }), _jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.pages, children: getPages(page, maxPageCount, hideLastPages).map((group, idx) => {
                    return (_jsxs(React.Fragment, { children: [idx !== 0 && _jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.page, children: "..." }), group.map((index) => (_jsx(ButtonBase, { onClick: () => api.setPage(index), disableRipple: true, disabled: index === page || disabled, className: clsx(classes === null || classes === void 0 ? void 0 : classes.page, index === page ? classes === null || classes === void 0 ? void 0 : classes.activePage : ""), "data-testid": `${gridName}--pagination-page`, "data-pendo": pendoPrefix ? `${pendoPrefix}-pagination-page` : undefined, children: index + 1 }, index)))] }, idx));
                }) }), _jsxs(ButtonBase, { onClick: () => api.setPage(page + 1), disabled: nextDisabled, disableRipple: true, className: clsx(classes === null || classes === void 0 ? void 0 : classes.navigationRight, nextDisabled ? classes === null || classes === void 0 ? void 0 : classes.navigationDisabled : ""), "data-testid": `${gridName}-pagination-next`, "data-pendo": pendoPrefix ? `${pendoPrefix}-pagination-next-page` : undefined, children: [_jsx(Typography, { style: { fontSize: 14 }, children: nextLabel !== null && nextLabel !== void 0 ? nextLabel : t("pagination.next") }), _jsx(ArrowNarrowRightSolid, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.rightIcon, nextDisabled ? classes === null || classes === void 0 ? void 0 : classes.iconDisabled : "") })] })] })));
}
export const BlueGridPagination = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        color: theme.palette.grey[600],
        marginTop: theme.spacing(1.5),
        position: "relative",
    },
    base: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: theme.palette.grey[200],
    },
    navigationLeft: {
        display: "flex",
        padding: theme.spacing(2, 0.5, 0, 0.25),
    },
    navigationRight: {
        display: "flex",
        padding: theme.spacing(2, 0.25, 0, 0.5),
    },
    navigationDisabled: {
        color: theme.palette.grey[400],
    },
    leftIcon: {
        marginRight: theme.spacing(1.75),
        color: theme.palette.grey[400],
        fontSize: 20,
    },
    rightIcon: {
        marginLeft: theme.spacing(1.75),
        color: theme.palette.grey[400],
        fontSize: 20,
    },
    iconDisabled: {
        color: theme.palette.grey[300],
    },
    pages: {
        display: "flex",
    },
    page: {
        padding: theme.spacing(2, 2, 0, 2),
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        display: "flex",
        alignItems: "baseline",
        borderRadius: 0,
    },
    activePage: {
        borderTopWidth: "2px",
        borderTopStyle: "solid",
        color: theme.palette.primary.main,
    },
}))(BlueGridPaginationInner);
function getPages(page, pageCount, hideLastPages) {
    if (hideLastPages) {
        const pages = page < 5
            ? formatPages([0, 1, 2, 3, 4, 5, 6], pageCount)
            : formatPages([0, 1, 2, page - 2, page - 1, page, page + 1, page + 2], pageCount);
        const groups = groupPages(pages);
        return pages.length && pages[pages.length - 1] < pageCount - 1
            ? [...groups, []]
            : groups;
    }
    const pages = [
        0,
        1,
        2,
        page - 1,
        page,
        page + 1,
        pageCount - 3,
        pageCount - 2,
        pageCount - 1,
    ];
    return groupPages(formatPages(pages, pageCount));
}
function formatPages(pages, pageCount) {
    // eslint-disable-next-line fp/no-mutating-methods
    return pages
        .reduce(
    // Remove out of range or duplicaded items
    (pages, page) => page >= 0 && page < pageCount && !pages.includes(page)
        ? [...pages, page]
        : pages, [])
        .sort((a, b) => a - b);
}
function groupPages(pages) {
    // Group pages based on the gaps between the page numbers
    return pages.reduce((pageGroups, page, idx) => {
        if (idx === 0 || page > pages[idx - 1] + 1) {
            return [...pageGroups, [page]];
        }
        else {
            return [...pageGroups.slice(0, -1), [...pageGroups.slice(-1)[0], page]];
        }
    }, []);
}
