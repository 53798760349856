import React from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation, Select, SelectProps } from "@lumar/shared";
import { LegacyTaskStatus } from "../../graphql";

export function StatusSelect(props: SelectProps): JSX.Element {
  const classes = useStyles();

  const options = [
    LegacyTaskStatus.Done,
    LegacyTaskStatus.Testing,
    LegacyTaskStatus.InProgress,
    LegacyTaskStatus.ToDo,
    LegacyTaskStatus.Backlog,
  ];

  return (
    <Select
      {...props}
      classes={{ select: classes.select }}
      renderValue={(value) => (
        <StatusValue status={value as LegacyTaskStatus} />
      )}
      data-testid="task-status-select"
    >
      {options.map((status) => (
        <MenuItem key={status} value={status}>
          <StatusValue status={status} />
        </MenuItem>
      ))}
    </Select>
  );
}

function StatusValue({ status }: { status: LegacyTaskStatus }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const { label, className } = (() => {
    switch (status) {
      case LegacyTaskStatus.Done:
        return { label: t("status.done"), className: classes.done };
      case LegacyTaskStatus.Testing:
        return { label: t("status.testing"), className: classes.testing };
      case LegacyTaskStatus.InProgress:
        return { label: t("status.inProgress"), className: classes.inProgress };
      case LegacyTaskStatus.ToDo:
        return { label: t("status.toDo"), className: classes.toDo };
      case LegacyTaskStatus.Backlog:
      default:
        return { label: t("status.backlog"), className: classes.backlog };
    }
  })();

  return (
    <div className={classes.container}>
      <div className={clsx(classes.indicator, className)} />
      {label}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  select: {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "7px",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  indicator: {
    width: "6px",
    height: "24px",
    borderRadius: "4px",
    marginRight: "14px",
    boxSizing: "border-box",
  },
  done: {
    background: theme.palette.green[500],
  },
  testing: {
    background: theme.palette.yellow[300],
  },
  inProgress: {
    background: theme.palette.blue[500],
  },
  toDo: {
    background: theme.palette.orange[400],
  },
  backlog: {
    background: theme.palette.ultraviolet[500],
  },
}));
