import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityCategoriesChart } from "../../../../../_common/charts/config/getAccessibilityCategoriesChart";
import { getAccessibilityCategoriesTrend } from "../../../../../_common/charts/config/getAccessibilityCategoriesTrend";
import { getAccessibilityColorChart } from "../../../../../_common/charts/config/getAccessibilityColorChart";
import { getAccessibilityFormsChart } from "../../../../../_common/charts/config/getAccessibilityFormsChart";
import { getAccessibilityIssuesAndInstancesTrend } from "../../../../../_common/charts/config/getAccessibilityIssuesandInstancesTrend";
import { getAccessibilityLanguageChart } from "../../../../../_common/charts/config/getAccessibilityLanguageChart";
import { getAccessibilityLevelsChart } from "../../../../../_common/charts/config/getAccessibilityLevelsChart";
import { getAccessibilityLevelsTrend } from "../../../../../_common/charts/config/getAccessibilityLevelsTrend";
import { getAccessibilityPagesWithIssuesByLevelTrend } from "../../../../../_common/charts/config/getAccessibilityPagesWithIssuesByLevelTrend";
import { getAccessibilityPagesWithoutIssuesByLevelTrend } from "../../../../../_common/charts/config/getAccessibilityPagesWithoutIssuesByLevelTrend";
import { getAccessibilitySemanticsChart } from "../../../../../_common/charts/config/getAccessibilitySemanticsChart";
import { getAccessibilitySeverityChart } from "../../../../../_common/charts/config/getAccessibilitySeverityChart";
import { getAccessibilitySeverityTrend } from "../../../../../_common/charts/config/getAccessibilitySeverityTrend";
import { getAccessibilityStructureChart } from "../../../../../_common/charts/config/getAccessibilityStructureChart";

export const generateAccessibilityOverviewCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityIssuesAndInstancesTrend,
      getAccessibilityPagesWithIssuesByLevelTrend,
      getAccessibilityPagesWithoutIssuesByLevelTrend,
      getAccessibilityLevelsChart,
      getAccessibilityLevelsTrend,
      getAccessibilitySeverityChart,
      getAccessibilitySeverityTrend,
      getAccessibilityCategoriesChart,
      getAccessibilityCategoriesTrend,
      getAccessibilityColorChart,
      getAccessibilitySemanticsChart,
      getAccessibilityStructureChart,
      getAccessibilityFormsChart,
      getAccessibilityLanguageChart,
    ],
  };
