import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityStructureBPChart } from "../../../../../_common/charts/config/getAccessibilityStructureBPChart";
import { getAccessibilityStructureBPTrend } from "../../../../../_common/charts/config/getAccessibilityStructureBPTrend";

export const generateAccessibilityStructureBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityStructureBPChart,
      getAccessibilityStructureBPTrend,
    ],
  };
