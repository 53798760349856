import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Package, Typography } from "@lumar/shared";
import { ChartPanelMessageWrapper } from "./ChartPanelMessageWrapper";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  message: {
    textAlign: "center",
    display: "inline-block",
    color: theme.palette.grey[700],
  },
  icon: {
    color: theme.palette.yellow[500],
    fontSize: theme.typography.pxToRem(38),
    strokeWidth: "1.7",
  },
}));

export function UnarchivingCrawlMessage(): JSX.Element {
  const { t } = useTranslation("charts");
  const classes = useStyles();

  return (
    <ChartPanelMessageWrapper maxWidth="100%">
      <div className={classes.content}>
        <div style={{ textAlign: "center", marginBottom: "14px" }}>
          <Package className={classes.icon} />
        </div>
        <Typography variant="body2" className={classes.message}>
          {t("unarchivingCrawlTitle")}
        </Typography>
      </div>
    </ChartPanelMessageWrapper>
  );
}
