import {
  ComboBox,
  PlusSmallSolid,
  TextField,
  XCircleSolid,
  useTranslation,
} from "@lumar/shared";
import { IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import { insertIf } from "../../_common/insertIf";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme) => ({
  textField: {
    paddingRight: theme.spacing(0.75),
    "&:hover $closeButton": {
      visibility: "visible",
    },
  },
  closeButton: {
    visibility: "hidden",
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(20),
  },
  comboBoxInput: {
    "& input": {
      padding: "6px 0 7px 0",
    },
  },
  menuItemContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addIcon: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.ultraviolet[600],
  },
}));

interface Props {
  value: string | null;
  groups: string[];
  onChange: (value: string | null) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export function GroupField({
  value,
  groups: groupsParam,
  onChange,
  disabled,
  error,
  helperText,
}: Props): JSX.Element {
  const { t } = useTranslation("segments");
  const classes = useStyles();

  const isCustom = Boolean(value && !groupsParam.includes(value));

  const [inputValue, setInputValue] = React.useState(
    (isCustom ? value : undefined) || "",
  );
  const [isTextInput, setIsTextInput] = React.useState(isCustom);

  const [autoFocus, setAutoFosus] = React.useState(false);
  React.useEffect(() => {
    if (autoFocus) setAutoFosus(false);
  }, [autoFocus, setAutoFosus]);

  const groups: { label: string; isCustom?: boolean }[] = groupsParam.map(
    (x) => ({ label: x }),
  );
  const options = React.useRef<{ label: string; isCustom?: boolean }[]>([]);
  if (!isEqual(options.current, groups)) {
    options.current = groups;
  }

  if (isTextInput) {
    return (
      <TextField
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(e.target.value);
        }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        label={t("segmentGroup")}
        autoFocus={autoFocus}
        InputProps={{
          classes: { root: classes.textField },
          endAdornment: (
            <IconButton
              onClick={() => {
                setIsTextInput(false);
                onChange(null);
                setInputValue("");
                setAutoFosus(true);
              }}
              disabled={disabled}
              size="small"
              className={classes.closeButton}
            >
              <XCircleSolid className={classes.closeIcon} />
            </IconButton>
          ),
        }}
        data-testid="segment-group-input"
      />
    );
  }

  return (
    <ComboBox
      onInputChange={(_, value, reason) =>
        reason === "input" && setInputValue(value)
      }
      value={options.current.find((x) => x.label === value)}
      options={options.current}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <div className={classes.menuItemContent}>
          {option.label}
          {option.isCustom && <PlusSmallSolid className={classes.addIcon} />}
        </div>
      )}
      filterOptions={(options, { inputValue }) => [
        ...options.filter((option) =>
          option.label
            .toLocaleLowerCase()
            .includes(inputValue.toLocaleLowerCase()),
        ),
        ...insertIf(
          inputValue && !options.find((x) => x.label === inputValue),
          {
            label: t("addNewGroupWith", { name: inputValue }),
            isCustom: true,
          },
        ),
        ...insertIf(!inputValue, {
          label: t("addNewGroup"),
          isCustom: true,
        }),
      ]}
      onChange={(_, value) => {
        if (!value.isCustom) {
          onChange(value.label);
        } else {
          setIsTextInput(true);
          setAutoFosus(true);
          onChange(inputValue);
        }
      }}
      disabled={disabled}
      disableClearable
      inputProps={{
        label: t("segmentGroup"),
        className: classes.comboBoxInput,
        autoFocus,
      }}
      data-testid="segment-group-input"
    />
  );
}
