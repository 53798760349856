import {
  useTranslation,
  NestedNavigationMenuChildItemAdornmentProps,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { ResourceDetailData } from "../../data/types";
import { ReportRowsFilter } from "../../../_common/connection-filtering/types";
import { useAssetResourceReports } from "./useAssetResourceReports";
import { AssetResourceReport } from "./sections/AssetResourceReport";
import { AssetResourceOverview } from "./sections/AssetResourceOverview";
import { AssetResourceChangedMetrics } from "./sections/AssetResourceChangedMetrics";
import { insertIf } from "../../../_common/insertIf";
import { ResponseHeader } from "../../metrics-groups/ResponseHeader";
import React from "react";
import { ReportCount } from "../../ReportCount";
import { ModuleCode } from "../../../graphql";

export type ReportConfig = {
  code: string;
  name: string;
  countMetric?: string;
  filter: ReportFilter;
};

export type ReportFilter = {
  reportTemplateCode: string;
  filter: ReportRowsFilter;
};

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  adornment?: React.ComponentType<NestedNavigationMenuChildItemAdornmentProps>;
};

type RetrunType = {
  sections: (Section & {
    items: Section[];
  })[];
  active: Section;
};

export function useAssetResourceSections(
  metrics: ResourceDetailData["metrics"],
  moduleCode: ModuleCode,
): RetrunType {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const reports = useAssetResourceReports(metrics, moduleCode);

  const rawHeader = metrics["rawHeader"]?.value;
  const hasRawHeader =
    typeof rawHeader === "object" && Object.keys(rawHeader).length > 0;

  const sections = React.useMemo<RetrunType["sections"]>(
    () => [
      {
        code: "overview",
        name: t("overview"),
        component: AssetResourceOverview,
        items: [
          {
            code: "changedMetrics",
            name: t("changedMetrics"),
            component: AssetResourceChangedMetrics,
          },
          ...reports.map((report) => {
            function ReportWrapper(): JSX.Element {
              return <AssetResourceReport reportFilter={report.filter} />;
            }

            function ReportCountWrapper(): JSX.Element | null {
              return report.countMetric ? (
                <ReportCount code={report.countMetric} metrics={metrics} />
              ) : null;
            }

            return {
              code: report.code,
              name: report.name,
              component: ReportWrapper,
              adornment: ReportCountWrapper,
            };
          }),
          ...insertIf(hasRawHeader, {
            code: "responseHeader",
            name: t("responseHeader"),
            component: ResponseHeader,
          }),
        ],
      },
    ],
    [metrics, hasRawHeader, reports, t],
  );

  const flatSection = sections.flatMap((section) => [
    section,
    ...section.items,
  ]);

  const foundSection = type
    ? flatSection.find((x) => x.code === type)
    : undefined;

  return {
    sections,
    active: foundSection ?? flatSection[0],
  };
}
