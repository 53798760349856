import { useNumberFormatter } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { getHighestLevel } from "../../crawl-progress/_common/running-crawl-query-utils";

export function DepthCell(props: GridRenderCellParams): JSX.Element {
  const formatNumber = useNumberFormatter();
  return (
    <div data-testid="depth-value-cell">
      {formatNumber(getHighestLevel(props.value as string))}
    </div>
  );
}
