import React from "react";
import { Task } from "../data/types";
import { isEqual } from "lodash";
import { LegacyTaskStatus } from "../../graphql";

export function useMemoizedTasksMap(
  tasks: Task[],
): Record<LegacyTaskStatus, Task[]> {
  const taskMapRef = React.useRef<Record<LegacyTaskStatus, Task[]>>({
    [LegacyTaskStatus.Done]: [],
    [LegacyTaskStatus.Testing]: [],
    [LegacyTaskStatus.InProgress]: [],
    [LegacyTaskStatus.ToDo]: [],
    [LegacyTaskStatus.Backlog]: [],
  });

  return React.useMemo(() => {
    function checkValue(status: LegacyTaskStatus): Task[] {
      const newValue = tasks.filter(
        (x) =>
          x.status === status ||
          (status === LegacyTaskStatus.Backlog && !x.status),
      );

      if (isEqual(newValue, taskMapRef.current[status])) {
        return taskMapRef.current[status];
      }

      taskMapRef.current = {
        ...taskMapRef.current,
        [status]: newValue,
      };
      return newValue;
    }

    return {
      [LegacyTaskStatus.Done]: checkValue(LegacyTaskStatus.Done),
      [LegacyTaskStatus.Testing]: checkValue(LegacyTaskStatus.Testing),
      [LegacyTaskStatus.InProgress]: checkValue(LegacyTaskStatus.InProgress),
      [LegacyTaskStatus.ToDo]: checkValue(LegacyTaskStatus.ToDo),
      [LegacyTaskStatus.Backlog]: checkValue(LegacyTaskStatus.Backlog),
    };
  }, [tasks]);
}
