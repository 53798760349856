/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType } from "../../../graphql";

export function getExperienceChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("requiredSourcesMessage.gscSource"),
    },
    title: () => t("experienceChart.title"),
    description: () => t("experienceChart.description"),
    reportTemplateCodesOrder: [
      "good_ux_pages_with_clicks",
      "poor_ux_pages_with_clicks",
    ],
    reportStatFilter: (report) => {
      return [
        "good_ux_pages_with_clicks",
        "poor_ux_pages_with_clicks",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
