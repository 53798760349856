/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilitySemanticsBPChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilitySemanticsBPChart.title"),
    description: () => t("accessibilitySemanticsBPChart.description"),
    reportTemplateCodesOrder: [
      "heading_order_issues",
      "landmark_banner_is_top_level_issues",
      "landmark_complementary_is_top_level_issues",
      "landmark_contentinfo_is_top_level_issues",
      "landmark_main_is_top_level_issues",
      "landmark_no_duplicate_banner_issues",
      "landmark_no_duplicate_contentinfo_issues",
      "landmark_no_duplicate_main_issues",
      "landmark_one_main_issues",
      "landmark_unique_issues",
      "page_has_heading_one_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "heading_order_issues",
        "landmark_banner_is_top_level_issues",
        "landmark_complementary_is_top_level_issues",
        "landmark_contentinfo_is_top_level_issues",
        "landmark_main_is_top_level_issues",
        "landmark_no_duplicate_banner_issues",
        "landmark_no_duplicate_contentinfo_issues",
        "landmark_no_duplicate_main_issues",
        "landmark_one_main_issues",
        "landmark_unique_issues",
        "page_has_heading_one_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
