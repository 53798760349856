/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getHttpStatusBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("httpStatusBreakdown.title"),
    description: () => t("httpStatusBreakdown.description"),
    reportTemplateCodesOrder: [
      "200_pages",
      "301_redirects",
      "non_301_redirects",
      "unauthorised_pages",
      "uncategorised_http_response_codes",
      "4xx_errors",
      "5xx_errors",
      "failed_urls",
    ],
    reportStatFilter: (report) => {
      return [
        "200_pages",
        "301_redirects",
        "non_301_redirects",
        "unauthorised_pages",
        "uncategorised_http_response_codes",
        "4xx_errors",
        "5xx_errors",
        "failed_urls",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
