import { Typography, useNumberFormatter } from "@lumar/shared";
import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { useTheme } from "@material-ui/core";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";
import {
  getCustomReportTemplateId,
  isCustomReportGUID,
} from "../../../_common/report-helpers/customReport";

export function TotalRowsCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  const formatNumber = useNumberFormatter();
  const theme = useTheme();

  const isCustomReport = isCustomReportGUID(report.id);

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: getCustomReportTemplateId(report.id),
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
    >
      <Typography
        style={{
          color: report.basic
            ? (report.reportTemplateTotalSign ?? 0) === -1
              ? theme.palette.error.main
              : (report.reportTemplateTotalSign ?? 0) === 1
                ? theme.palette.success.main
                : theme.palette.text.primary
            : theme.palette.text.primary,
          fontWeight: 500,
        }}
      >
        {formatNumber(report.basic || 0)}
      </Typography>
    </ReportLink>
  );
}
