import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getSearchImpressionDistributionChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("searchImpressionDistribution.title"),
    reportTemplateCodes: [
      "indexable_pages_in_search",
      "non_indexable_pages_in_serp",
      "mobile_amphtml_pages_in_search",
    ],
    description: t("searchImpressionDistribution.description"),
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("indexablePagesInSearch.requiredSourcesMessage"),
    },
  })();
}
