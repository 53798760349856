import { Typography } from "@lumar/shared";
import { Task } from "../../data/types";
import { makeStyles, Tooltip } from "@material-ui/core";

export function TitleCell({
  task,
  isDragging,
}: {
  task: Task;
  isDragging: boolean;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Tooltip
      title={isDragging ? "" : task.title}
      classes={{ tooltip: classes.tooltip }}
    >
      <Typography
        variant="subtitle3SemiBold"
        className={classes.text}
        data-testid="task-title"
      >
        {task.title}
      </Typography>
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    lineHeight: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tooltip: {
    maxWidth: 600,
  },
}));
