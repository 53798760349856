import { Typography } from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Task } from "../../data/types";

export function DescriptionCell({
  task,
  isDragging,
}: {
  task: Task;
  isDragging: boolean;
}): JSX.Element {
  const classes = useStyles();
  const description = task.description || "";

  return (
    <Tooltip
      title={isDragging ? "" : description}
      classes={{ tooltip: classes.tooltip }}
    >
      <Typography
        variant="caption"
        className={classes.text}
        data-testid="task-description"
      >
        {description}
      </Typography>
    </Tooltip>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    lineHeight: theme.typography.pxToRem(16),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tooltip: {
    maxWidth: 600,
  },
}));
