import React from "react";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { useURLSearchParams } from "../helpers";
export function useDefaultPagination() {
    const history = useHistory();
    const params = useURLSearchParams();
    const [filterModel, setFilterModel] = React.useState(undefined);
    const getSortModel = () => {
        try {
            return JSON.parse(atob(params.get("sorting")));
        }
        catch (err) {
            return undefined;
        }
    };
    const sortModel = getSortModel();
    const page = Number(params.get("page")) || 0;
    const pageSize = Number(params.get("pageSize")) || 0;
    const onStateChange = (state) => {
        setFilterModel(state.filterModel);
        const sortChanged = !isEqual(sortModel, state.sortModel);
        const pageChanged = page !== (state.page || 0);
        const pageSizeChanged = pageSize !== (state.pageSize || 0);
        if (sortChanged)
            params.set("sorting", btoa(JSON.stringify(state.sortModel)));
        if (pageChanged) {
            if (state.page !== undefined) {
                params.set("page", String(state.page));
            }
            else {
                params.delete("page");
            }
        }
        if (pageSizeChanged) {
            if (state.pageSize !== undefined) {
                params.set("pageSize", String(state.pageSize));
            }
            else {
                params.delete("pageSize");
            }
        }
        if (sortChanged || pageChanged) {
            history.push({ search: params.toString() });
        }
    };
    return {
        paginationState: {
            sortModel,
            filterModel,
            page,
        },
        onPaginationStateChange: onStateChange,
    };
}
