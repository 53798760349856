import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getStructuredDataTypesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("structuredDataTypes.title"),
    description: t("structuredDataTypes.description"),
    reportTemplateCodes: [
      "pages_with_product_schema",
      "pages_with_event_schema",
      "pages_with_news_article_schema",
      "pages_with_breadcrumb_schema",
      "pages_with_faqpage_schema",
      "pages_with_howto_schema",
      "pages_with_recipe_schema",
      "pages_with_videoobject_schema",
      "pages_with_qapage_schema",
      "pages_with_review_schema",
    ],
  })();
}
