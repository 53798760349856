/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityTablesAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityTablesAChart.title"),
    description: () => t("accessibilityTablesAChart.description"),
    reportTemplateCodesOrder: [
      "td_headers_attr_issues",
      "th_has_data_cells_issues",
      "table_fake_caption_issues",
      "td_has_header_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "td_headers_attr_issues",
        "th_has_data_cells_issues",
        "table_fake_caption_issues",
        "td_has_header_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
