/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "@lumar/shared";
import { snakeCase } from "lodash";
import Papa from "papaparse";
import { MetricData } from "../data/types";

type ExportData = {
  data: { code: string; values: any[]; metricData?: MetricData }[];
  crawlDates: Date[];
  fileName: string;
};

export function useExportCSV(): (props: ExportData) => void {
  const { t } = useTranslation("resourceDetail");

  return ({ data, crawlDates, fileName }) => {
    const exceptions: Record<string, string> = {
      h1Count: "h1_count",
      h1Length: "h1_length",
      h1Tag: "h1_tag",
      h2Tag: "h2_tag",
      h3Tag: "h3_tag",
    };

    function formatValue(value: any): any {
      if (
        typeof value === "boolean" ||
        typeof value === "number" ||
        typeof value === "string"
      )
        return value;
      if (typeof value === "object") return JSON.stringify(value);

      return "";
    }

    function getCustomMetricName(
      row: ExportData["data"][0],
    ): string | undefined {
      return row.code.startsWith("customMetrics.")
        ? row.metricData?.name
        : undefined;
    }

    const formattedData = [
      [t("exportMetricHeader"), ...crawlDates.map((x) => x.toISOString())],
      ...data.map((row) => [
        exceptions[row.code] || getCustomMetricName(row) || snakeCase(row.code),
        ...row.values.map(formatValue),
      ]),
    ];

    const csvData = new Blob(["\ufeff" + Papa.unparse(formattedData)], {
      type: "text/csv",
    });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };
}
