import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityColorAChart } from "../../../../../_common/charts/config/getAccessibilityColorAChart";
import { getAccessibilityColorATrend } from "../../../../../_common/charts/config/getAccessibilityColorATrend";

export const generateAccessibilityColorACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityColorAChart,
      getAccessibilityColorATrend,
    ],
  };
