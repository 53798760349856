import React from "react";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import {
  InformationCircleOutlined,
  Typography,
  Trans,
  useTranslation,
} from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  moreInfoIcon: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(22),
    marginLeft: theme.spacing(0.375),
    transform: "translateY(4px)",
  },
  list: {
    color: theme.palette.grey[100],
  },
  tooltip: {
    minWidth: 600,
    maxWidth: 600,
  },
}));

export function DataExplorerInfoTooltip(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["dataExplorer", "navigation"]);

  return (
    <Tooltip
      placement="bottom-start"
      interactive
      title={
        <>
          <Typography
            variant="h6Medium"
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            <strong>{t("dataExplorer:explanation.whatIsIt.title")}</strong>
          </Typography>
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            {t("dataExplorer:explanation.whatIsIt.content")}
          </Typography>
          <Divider
            style={{ background: "#4B5563", marginTop: 10, marginBottom: 10 }}
          />
          <Typography
            variant="h6Medium"
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            <strong>{t("dataExplorer:explanation.howDoesItWork.title")}</strong>
          </Typography>
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            {t("dataExplorer:explanation.howDoesItWork.content")}
          </Typography>
          <br />
          <br />
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            {t("dataExplorer:explanation.aggregateFunctions.title")}
          </Typography>
          <ul className={classes.list}>
            <li>
              {t("dataExplorer:explanation.aggregateFunctions.function1")}
            </li>
            <li>
              {t("dataExplorer:explanation.aggregateFunctions.function2")}
            </li>
            <li>
              {t("dataExplorer:explanation.aggregateFunctions.function3")}
            </li>
          </ul>
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            {t("dataExplorer:explanation.aggregateFunctions.additionalInfo")}
          </Typography>
          <Divider
            style={{ background: "#4B5563", marginTop: 10, marginBottom: 10 }}
          />
          <Typography
            variant="h6Medium"
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            <strong>{t("dataExplorer:explanation.howToUse.title")}</strong>
          </Typography>
          <Typography style={{ fontSize: 13, fontWeight: 400 }}>
            <Trans t={t} i18nKey="dataExplorer:explanation.howToUse.content">
              Our team has provided scenarios in our
              <Link
                href={t("navigation:dataExplorerProductGuideLink")}
                color="secondary"
                target="_blank"
                rel="noopener"
              >
                product guides
              </Link>
              on how the Data Explorer can be used to identify common technical
              SEO issues on a website.
            </Trans>
          </Typography>
        </>
      }
      classes={{ tooltip: classes.tooltip }}
    >
      <InformationCircleOutlined
        className={classes.moreInfoIcon}
        data-testid="data-explorer-info-icon"
      />
    </Tooltip>
  );
}
