import {
  EmptyState,
  ExclamationCircleOutlined,
  useTranslation,
} from "@lumar/shared";
import AceEditor from "react-ace";

export function PreviewHTMLContent({
  content,
  "data-testid": dataTestid,
}: {
  content?: string;
  "data-testid": string;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  if (!content) {
    return (
      <EmptyState
        icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
        title={t("previewFailedTitle")}
        description={t("previewFailedDescription")}
        height="100%"
      />
    );
  }

  return (
    <div style={{ width: "100%", height: "100%" }} data-testid={dataTestid}>
      <AceEditor
        value={content}
        readOnly
        mode="html"
        width="100%"
        height="100%"
        fontSize={14}
        theme="tomorrow"
        setOptions={{
          useWorker: false,
          wrap: true,
          printMargin: false,
        }}
      />
    </div>
  );
}
