import {
  Grid,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { InformationCircleOutlined } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 16,
    color: theme.palette.grey[700],
    fill: theme.palette.grey[400],
    strokeWidth: 1.5,
  },
  label: {
    fontSize: 13,
    color: theme.palette.grey[500],
    fontWeight: 600,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  value: {
    fontSize: 19,
    color: theme.palette.grey[900],
    fontWeight: 600,
    minHeight: 28,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tooltip: { fontWeight: 500, fontSize: 14 },
  infoIcon: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(22),
    margin: theme.spacing(0.375),
  },
}));

interface Props {
  label: string;
  icon: typeof SvgIcon;
  value?: string;
  infoText?: string;
}

export function ProgressDashboardKeyNumber(props: Props): JSX.Element {
  const classes = useStyles();
  const formattedLabel = props.label
    ? props.label.toLowerCase().replaceAll(" ", "-")
    : "";
  const testId = `${formattedLabel}-value`;

  return (
    <Grid container alignItems="center" direction="row">
      <Grid
        item
        style={{ width: 40 }}
        container
        justifyContent="flex-start"
        alignItems="center"
      >
        <props.icon className={classes.icon}></props.icon>
      </Grid>
      <Grid item style={{ width: "calc(100% - 80px)" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Typography className={classes.label}>{props.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.value} data-testid={testId}>
              {props.value}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {props.infoText ? (
        <Grid container item style={{ width: 40 }} justifyContent="flex-end">
          <Tooltip
            title={props.infoText}
            arrow
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <InformationCircleOutlined className={classes.infoIcon} />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
}
