import {
  useTranslation,
  NestedNavigationMenuChildItemAdornmentProps,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { ResourceDetailData } from "../../data/types";
import { IssueDetailsAllMetrics } from "./sections/IssueDetailsAllMetrics";
import { IssueDetailsChangedMetrics } from "./sections/IssueDetailsChangedMetrics";
import { IssueDetailsInstances } from "./sections/IssueDetailsInstances";
import { IssueDetailsOverview } from "./sections/IssueDetailsOverview";
import { IssueDetailsSuggestedSolution } from "./sections/IssueDetailsSuggestedSolution";
import { ReportCount } from "../../ReportCount";

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  adornment?: React.ComponentType<NestedNavigationMenuChildItemAdornmentProps>;
};

export function useIssueDetailsSections(
  metrics: ResourceDetailData["metrics"],
): {
  sections: (Section & {
    items: Section[];
  })[];
  active: Section;
} {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const sections = [
    {
      code: "overview",
      name: t("overview"),
      component: IssueDetailsOverview,
      items: [
        {
          code: "allMetrics",
          name: t("allMetrics"),
          component: IssueDetailsAllMetrics,
        },
        {
          code: "changedMetrics",
          name: t("changedMetrics"),
          component: IssueDetailsChangedMetrics,
        },
        {
          code: "issueInstances",
          name: t("issueInstances"),
          adornment: function InstancesCount(): JSX.Element {
            return (
              <ReportCount code="elementSelectorCount" metrics={metrics} />
            );
          },
          component: IssueDetailsInstances,
        },
        {
          code: "suggestedSolution",
          name: t("suggestedSolution.menuItem"),
          component: IssueDetailsSuggestedSolution,
        },
      ],
    },
  ];

  const flatSection = sections.flatMap((section) => [
    section,
    ...section.items.map((item) => item),
  ]);
  const foundSection = type
    ? flatSection.find((x) => x.code === type)
    : undefined;

  return {
    sections,
    active: foundSection ?? flatSection[0],
  };
}
