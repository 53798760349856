import { Grid, NestedNavigationMenu } from "@lumar/shared";
import { Routes } from "../../../_common/routing/routes";
import { useIssueDetailsSections } from "./useIssueDetailsSections";
import { useResourceDetailParams } from "../../data/useResourceDetailParams";
import { ResourceDetailData } from "../../data/types";

export function IssueDetailsSidebar(props: {
  data: ResourceDetailData;
}): JSX.Element {
  const params = useResourceDetailParams();

  const { sections, active } = useIssueDetailsSections(props.data.metrics);

  function getHref(type: string): string {
    return Routes.ResourceDetail.getUrl({
      ...params,
      type,
    });
  }

  return (
    <NestedNavigationMenu
      sections={[
        {
          id: "main",
          items:
            sections.map((section) => ({
              id: section.code,
              name: section.name,
              icon: Grid,
              href: getHref(section.code),
              open: true,
              selected: section.code === active.code,
              children: section.items.map((item) => ({
                id: item.code,
                name: item.name,
                selected: item.code === active.code,
                adornment: item.adornment,
                href: getHref(item.code),
              })),
            })) || [],
        },
      ]}
    />
  );
}
