import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Tab,
  Tabs,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { useProjectPreview } from "./useProjectPreview";
import {
  ArrowLeftSolid,
  Button,
  Chip,
  ChipColor,
  DownloadOutlined,
  EmptyState,
  ExclamationCircleOutlined,
  Snackbar,
  ToggleIconButton,
  Typography,
  XSolid,
  useTranslation,
} from "@lumar/shared";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { ModuleCode } from "../../../graphql";
import { usePreviewTabs } from "./usePreviewTabs";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "100%",
  },
  content: {
    textAlign: "center",
    padding: 0,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actions: {
    justifyContent: "space-between",
    gap: theme.spacing(2.5),
    padding: theme.spacing(1.75, 2.25, 1.875, 2.25),
    borderTopWidth: 1,
    borderTopStyle: "solid",
    borderTopColor: theme.palette.grey[300],
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(1),
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  title: {
    color: theme.palette.grey[800],
    textAlign: "center",
    maxWidth: 360,
  },
  description: {
    color: theme.palette.grey[500],
    letterSpacing: "0.0025em",
    maxWidth: 340,
    marginTop: theme.spacing(2),
  },
  progress: {
    width: "60vw",
    maxWidth: 700,
    marginTop: theme.spacing(4),
  },
  chartIconButton: {
    minWidth: "auto",
    padding: theme.spacing(0.75),
    color: theme.palette.grey[700],
  },
  responseContainer: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  label: {
    display: "block",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.grey[500],
    whiteSpace: "nowrap",
  },
  value: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 500,
  },
  divider: {
    width: 1,
    height: 24,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 2.125, 0, 1.5),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  gridMultiline: {
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1.25),
    "& $label": {
      justifySelf: "end",
      alignSelf: "center",
    },
  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(1.5, 2, 0, 4.5),
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  tabs: {
    marginBottom: -1,
  },
}));

interface Props {
  projectId: string;
  url: string;
  moduleCode: ModuleCode;
  open?: boolean;
  onClose?: () => void;
}

export function ProjectPreviewDialog(props: Props): JSX.Element {
  const classes = useStyles();

  const [openInner, setOpenInner] = React.useState(false);
  React.useEffect(() => setOpenInner(props.open || false), [props.open]);

  return (
    <Dialog
      open={openInner}
      onClose={() => setOpenInner(false)}
      fullWidth
      maxWidth="xl"
      classes={{ paper: classes.dialog }}
      TransitionProps={{
        onExited: () => props.onClose?.(),
      }}
    >
      <ProjectPreviewDialogContent
        {...props}
        onClose={() => setOpenInner(false)}
      />
    </Dialog>
  );
}

function ProjectPreviewDialogContent({
  projectId,
  url,
  moduleCode,
  onClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "crawlSettings"]);
  const { enqueueSnackbar } = useSnackbar();

  const [progress, setProgress] = React.useState(0);
  const avgLoadTime = 25000;

  const [exporting, setExporting] = React.useState(false);

  const [tabIndex, setTabIndex] = React.useState(
    moduleCode === ModuleCode.Seo ? 1 : 0,
  );

  const { loading, errorMessage, data } = useProjectPreview({
    projectId,
    url,
  });

  const tabs = usePreviewTabs(url, data);
  const tab = tabs[tabIndex] ?? tabs[0];

  React.useEffect(() => {
    if (!loading) return;

    // eslint-disable-next-line fp/no-let
    let timeout: NodeJS.Timeout | undefined = undefined;
    const startTime = new Date();

    function loop(): void {
      const delay = Math.round(Math.random() * 2000);
      // eslint-disable-next-line fp/no-mutation
      timeout = setTimeout(() => {
        setProgress((progress) => {
          const elapsed_time = new Date().getTime() - startTime.getTime();
          const target_progress = (elapsed_time / avgLoadTime) * 100;
          const value =
            progress + Math.random() * Math.max(target_progress - progress, 0);
          return Math.min(value, 99);
        });
        loop();
      }, delay);
    }

    loop();
    return () => clearTimeout(timeout);
  }, [setProgress, loading]);

  function getContent(): React.ReactNode {
    if (loading) {
      return (
        <div className={classes.container}>
          <Typography variant="h2SemiBold" className={classes.title}>
            {t("crawlSettings:previewLoadingTitle")}
          </Typography>
          <Typography variant="body2" className={classes.description}>
            {t("crawlSettings:previewLoadingDescription")}
          </Typography>
          <LinearProgress
            value={progress}
            variant="determinate"
            className={classes.progress}
          />
        </div>
      );
    }

    if (errorMessage) {
      return (
        <EmptyState
          icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
          title={t("common:somethingWentWrong")}
          description={errorMessage}
          height="100%"
        />
      );
    }

    return tab?.component;
  }

  const exportDisabled = tab?.exportDisabled ?? true;

  return (
    <>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Tabs
          value={tabIndex}
          onChange={(_, tabIndex) => setTabIndex(tabIndex)}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          {tabs.map((tab, idx) => (
            <Tab key={idx} label={tab.label} data-testid={tab.testId} />
          ))}
        </Tabs>
        <ToggleIconButton
          onClick={onClose}
          size="large"
          variant="outlined"
          aria-label="close"
        >
          <XSolid />
        </ToggleIconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>{getContent()}</DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={onClose}
          startIcon={<ArrowLeftSolid />}
          size="large"
          variant="outlined"
          data-testid="project-preview-back-to-settings"
        >
          {t("crawlSettings:backToSettings")}
        </Button>
        {data !== undefined && (
          <div className={classes.responseContainer}>
            <div
              className={clsx(classes.grid, {
                [classes.gridMultiline]: Boolean(data.redirectUrl),
              })}
            >
              <Typography className={classes.label}>
                {t("crawlSettings:previewUrlLabel")}
              </Typography>
              <Tooltip title={url}>
                <Typography className={classes.value}>{url}</Typography>
              </Tooltip>
              {data.redirectUrl !== undefined && (
                <>
                  <Typography className={classes.label}>
                    {t("crawlSettings:previewRedirectTargetLabel")}
                  </Typography>
                  <Tooltip title={data.redirectUrl}>
                    <Typography className={classes.value}>
                      {data.redirectUrl}
                    </Typography>
                  </Tooltip>
                </>
              )}
            </div>
            <div className={classes.divider} />
            <div>
              <Typography className={classes.label}>
                {t("crawlSettings:previewStatusCodeLabel")}
              </Typography>
              <Chip
                label={`${
                  data.statusCode || t("crawlSettings:previewStatusCodeFailed")
                }`}
                color={getStatusCodeColor(data.statusCode)}
                size="small"
                style={{ padding: "0px 8px" }}
                rounded
              />
            </div>
          </div>
        )}
        <Tooltip
          title={
            !exportDisabled ? t("crawlSettings:previewDownloadTooltip") : ""
          }
        >
          <Button
            onClick={async () => {
              if (exporting) return;

              setExporting(true);
              try {
                tab?.export();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } catch (error: any) {
                if (error?.message) {
                  enqueueSnackbar(
                    <Snackbar title={error.message} variant="error" />,
                  );
                }
              }
              setExporting(false);
            }}
            disabled={exportDisabled}
            loading={exporting}
            variant="outlined"
            className={classes.chartIconButton}
            size="large"
            aria-label={t("crawlSettings:previewDownloadTooltip")}
            data-testid="project-preview-export-button"
          >
            <DownloadOutlined />
          </Button>
        </Tooltip>
      </DialogActions>
    </>
  );
}

function getStatusCodeColor(statusCode?: number): ChipColor {
  if (statusCode && statusCode >= 200 && statusCode < 300) {
    return "green";
  }
  if (statusCode && statusCode >= 300 && statusCode < 400) {
    return "yellow";
  }
  return "red";
}
