import { CustomExtractionLabelsQuery } from "../../graphql";

export function findCustomExtractionLabel(
  data: CustomExtractionLabelsQuery | undefined,
  reportTemplateCode?: string,
): string | undefined {
  return data?.crawl?.crawlSetting?.customExtractions?.find(
    (customExtractionSetting) =>
      customExtractionSetting.reportTemplateCode === reportTemplateCode,
  )?.label;
}
