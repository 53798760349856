import { useTranslation } from "react-i18next";

import { NarrowMetric } from "../../_common/connection-filtering/types";
import {
  MetricType,
  ConnectionPredicate,
  ConnectionPredicateMetadata,
  CrawlType,
} from "../../graphql";
import { TFunction } from "i18next";

export interface ProjectFilter {
  code: string;
  name: string;
  connectionPredicates: Pick<ConnectionPredicateMetadata, "code" | "name">[];
  description: string;
}

function useConnectionPredicates(): Pick<
  ConnectionPredicateMetadata,
  "code" | "name"
>[] {
  const { t } = useTranslation("filters");

  const connectionPredicates = [
    {
      code: ConnectionPredicate.BeginsWith,
      name: t("startsWith"),
    },
    {
      code: ConnectionPredicate.Contains,
      name: t("contains"),
    },
    {
      code: ConnectionPredicate.EndsWith,
      name: t("endsWith"),
    },
    {
      code: ConnectionPredicate.Eq,
      name: t("equals"),
    },
    {
      code: ConnectionPredicate.Ge,
      name: t("greaterThanOrEqualTo"),
    },
    {
      code: ConnectionPredicate.Gt,
      name: t("greaterThan"),
    },
    {
      code: ConnectionPredicate.Le,
      name: t("lessThanOrEqualTo"),
    },
    {
      code: ConnectionPredicate.Lt,
      name: t("lessThan"),
    },
    {
      code: ConnectionPredicate.Ne,
      name: t("notEquals"),
    },
    {
      code: ConnectionPredicate.NotContains,
      name: t("notContains"),
    },
    {
      code: ConnectionPredicate.MatchesRegex,
      name: t("matchesRegex"),
    },
    {
      code: ConnectionPredicate.NotMatchesRegex,
      name: t("doesntMatchRegex"),
    },
  ];

  return connectionPredicates;
}

export function useProjectFilters(): NarrowMetric[] {
  const { t } = useTranslation("projectFilters");
  const { t: tCommon } = useTranslation("common");

  const connectionPredicates = useConnectionPredicates();

  const booleanPredicateCodes = [
    ConnectionPredicate.Eq,
    ConnectionPredicate.Ne,
  ];
  const booleanPredicates = connectionPredicates
    .filter((predicate) => booleanPredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Boolean"],
    }));

  const datePredicateCodes = [
    ConnectionPredicate.Ne,
    ConnectionPredicate.Eq,
    ConnectionPredicate.Lt,
    ConnectionPredicate.Le,
    ConnectionPredicate.Gt,
    ConnectionPredicate.Ge,
  ];
  const datePredicates = connectionPredicates
    .filter((predicate) => datePredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Date"],
    }));

  const stringPredicateCodes = [
    ConnectionPredicate.Eq,
    ConnectionPredicate.Ne,
    ConnectionPredicate.BeginsWith,
    ConnectionPredicate.EndsWith,
    ConnectionPredicate.Contains,
    ConnectionPredicate.NotContains,
    ConnectionPredicate.MatchesRegex,
    ConnectionPredicate.NotMatchesRegex,
  ];
  const stringPredicates = connectionPredicates
    .filter((predicate) => stringPredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["String"],
    }));

  const numberPredicateCodes = [
    ConnectionPredicate.Eq,
    ConnectionPredicate.Ne,
    ConnectionPredicate.Lt,
    ConnectionPredicate.Le,
    ConnectionPredicate.Gt,
    ConnectionPredicate.Ge,
  ];
  const numberPredicates = connectionPredicates
    .filter((predicate) => numberPredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Integer"],
    }));

  const crawlSourcePredicateCodes = [
    ConnectionPredicate.Eq,
    ConnectionPredicate.Ne,
  ];
  const crawlSourcePredicates = connectionPredicates
    .filter((predicate) => crawlSourcePredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Boolean"],
    }));

  return [
    {
      code: "lastCrawlCrawlingAt",
      name: t("lastCrawlDate"),
      connectionPredicates: datePredicates,
      description: t("lastCrawlDateTooltip"),
    },
    {
      code: "finishedAt",
      name: t("dateFinished"),
      connectionPredicates: datePredicates,
      description: t("dateFinishedTooltip"),
    },
    {
      code: "crawlUrls",
      name: t("totalURLs"),
      connectionPredicates: numberPredicates,
      description: t("totalURLsTooltip"),
    },
    {
      code: "crawlsTotalCount",
      name: t("totalCrawls"),
      connectionPredicates: numberPredicates,
      description: t("totalCrawlsTooltip"),
    },
    {
      code: "schedulesTotalCount",
      name: t("isScheduled"),
      connectionPredicates: booleanPredicates,
      description: t("isScheduledTooltip"),
    },
    {
      code: "siteTest",
      name: t("hasTestSite"),
      connectionPredicates: booleanPredicates,
      description: t("hasTestSiteTooltip"),
    },
    {
      code: "sitePrimary",
      name: t("domain"),
      connectionPredicates: stringPredicates,
      description: t("domainTooltip"),
    },
    {
      code: "nameOrDomain",
      name: t("nameOrDomain"),
      connectionPredicates: stringPredicates,
      description: t("nameOrDomainTooltip"),
    },
    {
      code: "name",
      name: t("name"),
      connectionPredicates: stringPredicates,
      description: t("nameTooltip"),
    },
    {
      code: "active",
      name: t("active"),
      connectionPredicates: booleanPredicates,
      description: t("activeTooltip"),
    },
    {
      code: "running",
      name: t("running"),
      connectionPredicates: booleanPredicates,
      description: t("runningTooltip"),
    },
    {
      code: "createdAt",
      name: t("dateCreated"),
      connectionPredicates: datePredicates,
      description: t("dateCreatedTooltip"),
    },
    ...Object.values(CrawlType).map((crawlType) => {
      const crawlTypeName = getCrawlTypeName(crawlType, tCommon);

      return {
        code: `crawlSource${crawlType}`,
        name: t("crawlSource", { crawlType: crawlTypeName }),
        connectionPredicates: crawlSourcePredicates,
        description: t("crawlSourceTooltip", { crawlType: crawlTypeName }),
      };
    }),
  ];
}

function getCrawlTypeName(
  crawlType: CrawlType,
  t: TFunction<"common">,
): string {
  switch (crawlType) {
    case CrawlType.Web:
      return t("crawlType.web");
    case CrawlType.Sitemap:
      return t("crawlType.sitemap");
    case CrawlType.GoogleSearchConsole:
      return t("crawlType.googleSearchConsole");
    case CrawlType.GoogleAnalytics:
      return t("crawlType.googleAnalytics");
    case CrawlType.Backlinks:
      return t("crawlType.backlinks");
    case CrawlType.LogSummary:
      return t("crawlType.logSummary");
    case CrawlType.List:
      return t("crawlType.list");
    case CrawlType.CustomMetricsUpload:
      return t("crawlType.customMetricsUpload");
    default:
      return crawlType;
  }
}
