import { TooltipOptions } from "highcharts";

export const baseTooltipOptions: TooltipOptions = {
  backgroundColor: "#111827",
  borderRadius: 4,
  borderColor: "#111827",
  padding: 10,
  style: {
    color: "white",
    fontFamily: `"Inter", sans-serif;`,
    fontSize: "14px",
    fontWeight: "normal",
  },
};
