import React, { useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { ArrowRightSolid, Typography } from "@lumar/shared";

import { CrawlType } from "../../../graphql";
import { LogSummary } from "./log-summary/LogSummary";
import { Analytics } from "./analytics/Analytics";
import { Backlinks } from "./backlinks/Backlinks";
import { GoogleSearchConsole } from "./google-search-console/GoogleSearchConsole";
import { Sitemaps } from "./sitemaps/Sitemaps";
import { Website } from "./website/Website";
import { UrlLists } from "./urls/UrlLists";
import {
  isCrawlRunning,
  isCrawlUnarchiving,
} from "../../../account-projects/projects-filter/helpers";
import { useSourcesSchema } from "./data/useSourcesSchema";
import { FormValues, ValidationErrors } from "./data/types";
import { ManualUploadsWrapper } from "./manual-uploads/ManualUploadsWrapper";
import {
  SourcesContext,
  useSourcesFormValues,
} from "./data/useSourcesFormValues";
import {
  formatValidationError,
  ValidationErrorDialog,
} from "./ValidationErrorDialog";
import { SettingsErrorOverlay } from "../components/SettingsErrorOverlay";

export interface SourceSettings {
  title: string;
  control?: JSX.Element;
  type: CrawlType;
  fieldName: string;
  icon: JSX.Element;
  tooltip: string;
  hasManualUpload?: boolean;
}

export function Sources({ next }: { next: () => void }): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const { formValues, contextValues, loading, error, updateSources } =
    useSourcesFormValues();
  const schema = useSourcesSchema(contextValues.majesticMaxUrl);

  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors | undefined
  >(undefined);

  if (loading) {
    return (
      <Grid container style={{ height: "208px" }} alignContent="center">
        <Grid item style={{ margin: "auto" }}>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <SettingsErrorOverlay title={t("errorLoadFailed")} description={error} />
    );
  }

  const hasRunningCrawl = isCrawlRunning(contextValues.lastCrawl?.statusEnum);
  const hasUnarchivingCrawl = isCrawlUnarchiving(
    contextValues.lastCrawl?.statusEnum,
    contextValues.lastCrawl?.finishedAt,
  );

  const hasSelectedSource = (values: FormValues): boolean =>
    values.crawlType.sitemaps ||
    values.crawlType.backlinks ||
    values.crawlType.googleSearchConsole ||
    values.crawlType.website ||
    values.crawlType.analytics ||
    values.crawlType.logSummary ||
    values.crawlType.urlLists;

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) {
      if (
        formikRef.current?.errors &&
        Object.keys(formikRef.current.errors).length
      ) {
        setValidationErrors(
          formatValidationError(formikRef.current?.errors, t),
        );
      }
      return;
    }

    next();
  };

  return (
    <SourcesContext.Provider value={contextValues}>
      <ValidationErrorDialog validationErrors={validationErrors} />
      <ManualUploadsWrapper>
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={updateSources}
          validateOnChange={false}
          validationSchema={schema}
          innerRef={(formik) => (formikRef.current = formik)}
        >
          {({ values, submitForm, isSubmitting, dirty }) => (
            <>
              <div style={{ padding: "16px 32px" }}>
                <Typography variant="subtitle3Medium">
                  {t("sources.description")}
                </Typography>
                <Grid
                  item
                  data-testid="crawl-settings-sources"
                  style={{ marginTop: "16px", marginBottom: "8px" }}
                >
                  <Website initialState={formValues.crawlType.website} />
                  <Sitemaps initialState={formValues.crawlType.sitemaps} />
                  <Backlinks initialState={formValues.crawlType.backlinks} />
                  <GoogleSearchConsole
                    initialState={formValues.crawlType.googleSearchConsole}
                    dirty={dirty}
                  />
                  <Analytics
                    initialState={formValues.crawlType.analytics}
                    dirty={dirty}
                  />
                  <LogSummary initialState={formValues.crawlType.logSummary} />
                  <UrlLists initialState={formValues.crawlType.urlLists} />
                </Grid>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(209, 213, 219,0.3)",
                  width: "100%",
                  padding: "12px 16px",
                  textAlign: "right",
                }}
              >
                <Tooltip
                  title={
                    hasSelectedSource(values)
                      ? ""
                      : t("sources.noneSelectedError")
                  }
                >
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() =>
                      handleSave(submitForm as () => Promise<boolean>)
                    }
                    endIcon={<ArrowRightSolid />}
                    data-testid="crawl-settings-sources-save-and-continue"
                    disabled={
                      (hasRunningCrawl && !hasUnarchivingCrawl) ||
                      isSubmitting ||
                      !hasSelectedSource(values)
                    }
                  >
                    {t("saveAndContinue")}
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
        </Formik>
      </ManualUploadsWrapper>
    </SourcesContext.Provider>
  );
}
