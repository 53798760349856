/* eslint-disable react/display-name */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getPerformanceSlow(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    visualisationColors: [theme.palette.red[500]],
    title: () => t("performanceSlow.title"),
    description: () => t("performanceSlow.description"),
    reportTemplateCodesOrder: [
      "slow_server_response",
      "max_load_time",
      "slow_dcl",
      "slow_fcp",
      "slow_lcp",
      "slow_tti",
      "poor_cls",
    ],
    reportStatFilter: (report) => {
      return [
        "slow_server_response",
        "max_load_time",
        "slow_dcl",
        "slow_fcp",
        "slow_lcp",
        "slow_tti",
        "poor_cls",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => {
      const reportTemplateCode = report.reportTemplateCode;
      return reportTemplateCode === "non-200_pages"
        ? t("non200Pages.total")
        : report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },

    testAttributePrefix: "non-200-pages-chart",
  };
}
