import {
  AlertSetting,
  AlertSettingCode,
  Project,
  UpdateProjectInput,
} from "../../../../../graphql";
import { EmailAlertsSettings } from "../types";

type QueryData = Pick<Project, "alertEmails"> & {
  alertSetting: Pick<AlertSetting, "code">;
};

export function formatEmailAlertsSettingsFrom(
  data?: QueryData | null,
): EmailAlertsSettings {
  return {
    settingsCode: data?.alertSetting?.code ?? AlertSettingCode.Always,
    emails: data?.alertEmails ?? [],
  };
}

export function formatEmailAlertsSettingsTo(
  formValues: EmailAlertsSettings,
): Pick<UpdateProjectInput, "alertSettingCode" | "alertEmails"> {
  return {
    alertSettingCode: formValues.settingsCode,
    alertEmails: formValues.emails,
  };
}
