import React from "react";
import { useHistory } from "react-router-dom";

import { RoleCode, useGetProjectNameQuery } from "../../graphql";
import { Routes } from "../../_common/routing/routes";
import { assert } from "../../_common/assert";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { TopNavigation } from "../../_common/top-navigation/TopNavigation";
import {
  useTranslation,
  ArrowLeftSolid,
  Button,
  useSession,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  button: {
    background: "white",
  },
}));

export function SegmentTitle(): JSX.Element {
  const { projectId, accountId, crawlId } = useGenericParams();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const { t } = useTranslation("segments");
  const classes = useStyles();

  const history = useHistory();

  const { data } = useGetProjectNameQuery({
    variables: { projectId: btoa("N007Project" + projectId) },
  });

  const projectName = data?.getProject?.name;

  const goToAdvancedSettings = (): void => {
    history.push(
      Routes.Crawls.getUrl({
        accountId,
        projectId,
        tab: "edit",
        step: 4,
      }),
    );
  };

  const { hasSufficientRole } = useSession();

  return (
    <TopNavigation
      title={t("pageTitle", { projectName: projectName ?? "" })}
      breadcrumbs={[{ label: t("breadcrumb") }]}
      loading={!projectName}
      actions={
        hasSufficientRole(RoleCode.Editor) ? (
          <Button
            key="segment-title-project-settings"
            onClick={goToAdvancedSettings}
            variant="outlined"
            size="medium"
            startIcon={<ArrowLeftSolid />}
            className={classes.button}
            data-testid="segment-title-project-settings"
          >
            {t("projectSettings")}
          </Button>
        ) : undefined
      }
    />
  );
}
