import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getAmpValidationChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("ampValidation.title"),
    reportTemplateCodes: ["valid_amp_pages", "invalid_amp_pages"],
    description: t("ampValidation.description"),
  })();
}
