import React from "react";
import { TextField } from "@lumar/shared";
import { FormikProps, FieldProps, Field } from "formik";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import isUrl from "validator/lib/isURL";

import { FormValues } from "../data/types";
import { fieldToTextField } from "../../../../_common/forms/formikFieldConverters";
import { useDomainCheck } from "../data/useDomainCheck";
import { DomainOptions } from "./DomainOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: "400px",
  },
}));

export function DomainInputs(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const {
    domainVariants,
    loading: checkDomainLoading,
    called: checkDomainCalled,
    checkDomain,
  } = useDomainCheck();

  const domainChanged = React.useRef(false);
  const checkButtonRef = React.useRef<HTMLButtonElement>(null);

  const setDefaultProjectName = (formik: FormikProps<FormValues>): void => {
    if (formik.values.basic.name || !formik.values.basic.primaryDomain) return;

    formik.setFieldValue(
      "basic.name",
      t("domain.defaultProjectName", {
        domain: formik.values.basic.primaryDomain,
      }),
      true,
    );
  };

  const handleCheck = async (
    formik: FormikProps<FormValues>,
  ): Promise<void> => {
    const domain = formik.values.basic.primaryDomain;
    if (domain && isUrl(domain, { protocols: ["http", "https"] })) {
      checkDomain(domain);
      domainChanged.current = false;
    }
  };

  return (
    <div className={classes.root}>
      <Field name="basic.primaryDomain">
        {(props: FieldProps<string>) => (
          <TextField
            {...fieldToTextField(props)}
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === props.field.value) return;

              props.form.setFieldValue(props.field.name, value, true);
              domainChanged.current = true;
            }}
            onBlur={(e) => {
              props.field.onBlur?.(e);
              // Changing a field immediately after the onBlur
              // will not validate the field properly
              setTimeout(() => {
                setDefaultProjectName(props.form);
              }, 0);
            }}
            label={t("domain.domainLabel")}
            placeholder={t("domain.domainPlaceholder")}
            inputProps={{
              "data-testid": "crawl-settings-domain-input",
              "data-pendo": "primary-domain-field",
            }}
            className={classes.input}
            InputProps={{
              onKeyPress: (e) => {
                if (e.key === "Enter") handleCheck(props.form);
              },
              endAdornment: (
                <Button
                  ref={checkButtonRef}
                  color="primary"
                  size="small"
                  variant="text"
                  data-testid="crawl-settings-check-domain"
                  style={{ height: "22px" }}
                  onClick={() => handleCheck(props.form)}
                  disabled={props.form.isSubmitting}
                >
                  {t("domain.checkDomainButton")}
                </Button>
              ),
            }}
          />
        )}
      </Field>
      <DomainOptions
        domainVariants={domainVariants}
        isLoading={checkDomainLoading}
        called={checkDomainCalled}
      />
    </div>
  );
}
