/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ApolloError, DocumentNode, gql, useQuery } from "@lumar/shared";
import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import {
  getCrawlUrlsReportsFragment,
  getCrawlUrlsUnit,
  getCrawlUrlsValue,
} from "./getCrawlUrlsReportsFragment";
import {
  getAuditsReportsFragment,
  getAuditsUnit,
  getAuditsValue,
} from "./getAuditsReportsFragment";
import {
  getOpportunitiesReportsFragment,
  getOpportunityUnit,
  getOpportunityValue,
} from "./getOpportunitiesReportsFragment";
import { AggregateTypes, CustomDataAggregateMetricConfig } from "./types";

interface Props {
  crawlId: string;
  segmentId?: string;
  configs: CustomDataAggregateMetricConfig[];
  skip?: boolean;
}

interface Result {
  data: {
    getValue: (
      reportId: string,
      config: CustomDataAggregateMetricConfig,
    ) => number | null | undefined;
    getUnit: (config: CustomDataAggregateMetricConfig) => string | undefined;
  };
  loading: boolean;
  crawlArchived: boolean;
  error?: ApolloError;
}

export function useReportsAggregateMetricData({
  crawlId,
  segmentId,
  configs,
  skip,
}: Props): Result {
  const {
    data: queryData,
    error,
    loading,
  } = useQuery(generateQuery(configs), {
    errorPolicy: "all",
    fetchPolicy: "cache-first",
    variables: {
      crawlId,
      segmentId,
    },
    skip: skip || !configs.length,
  });

  const data = React.useMemo<Result["data"]>(
    () => ({
      getValue: (reportId, config) => {
        const value =
          getCrawlUrlsValue(queryData, reportId, config) ??
          getAuditsValue(queryData, reportId, config) ??
          getOpportunityValue(queryData, reportId, config);
        return value === null ||
          value === undefined ||
          typeof value === "number"
          ? value
          : undefined;
      },
      getUnit: (config) => {
        const unit =
          getCrawlUrlsUnit(queryData, config) ??
          getAuditsUnit(queryData, config) ??
          getOpportunityUnit(queryData, config);
        return typeof unit === "string" ? unit : undefined;
      },
    }),
    [queryData],
  );

  return {
    data,
    error,
    crawlArchived: Boolean(queryData?.getCrawl?.archivedAt),
    loading,
  };
}

function generateQuery(
  configs: CustomDataAggregateMetricConfig[],
): DocumentNode {
  const crawlUrlsReports = getCrawlUrlsReportsFragment(configs);
  const auditReports = getAuditsReportsFragment(configs);
  const opportunitiesReports = getOpportunitiesReportsFragment(configs);

  const query = {
    query: {
      __name: "ErrorReportsCustomData",
      __variables: {
        crawlId: "ObjectID!",
        segmentId: "ObjectID",
      },
      getCrawl: {
        __args: {
          id: new VariableType("crawlId"),
        },
        id: true,
        archivedAt: true,
        ...(crawlUrlsReports?.reports ?? {}),
        ...(auditReports?.reports ?? {}),
        ...(opportunitiesReports?.reports ?? {}),
      },
      ...(crawlUrlsReports?.datasource ?? {}),
      ...(auditReports?.datasource ?? {}),
      ...(opportunitiesReports?.datasource ?? {}),
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}

export function isAggregateWithUnit(type: AggregateTypes): boolean {
  return type !== "count";
}
