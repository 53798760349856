import { Project, UpdateProjectInput } from "../../../../../graphql";
import { UrlRewritingSettings } from "../types";

type QueryData = Pick<
  Project,
  "urlRewriteRules" | "useUrlRewriteRules" | "urlRewriteStripFragment"
>;

export function formatUrlRewritingSettingsFrom(
  data?: QueryData | null,
): UrlRewritingSettings {
  return {
    rules:
      data?.urlRewriteRules?.map((rule) => ({
        matchFrom: rule.matchFrom,
        matchTo: rule.rewriteTo,
        caseOption: rule.caseOption ?? "None",
      })) ?? [],
    useRewriteRules: data?.useUrlRewriteRules ?? false,
    stripFragments: data?.urlRewriteStripFragment ?? false,
  };
}

export function formatUrlRewritingSettingsTo(
  formValues: UrlRewritingSettings,
): Pick<
  UpdateProjectInput,
  "urlRewriteRules" | "useUrlRewriteRules" | "urlRewriteStripFragment"
> {
  return {
    urlRewriteRules: formValues.rules.map((rule) => ({
      matchFrom: rule.matchFrom,
      rewriteTo: rule.matchTo,
      caseOption:
        rule.caseOption !== "None" && rule.caseOption !== "Default"
          ? rule.caseOption
          : null,
    })),
    useUrlRewriteRules: formValues.useRewriteRules,
    urlRewriteStripFragment: formValues.stripFragments,
  };
}
