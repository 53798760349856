import { useTranslation } from "@lumar/shared";
import { SearchWithDebounce } from "../../_common/searchWithDebounce/SearchWithDebounce";
import { useAccountProjectsFilterParam } from "../useAccountProjectsFilterParam";

export function ProjectSearchField(): JSX.Element {
  const { t } = useTranslation("projectsList");

  const { filter, setSearch } = useAccountProjectsFilterParam();

  return (
    <SearchWithDebounce
      value={filter.search || ""}
      valueChangeCallbackFn={(search) => setSearch(search)}
      debounceInMs={300}
      placeholder={t("projectSearchPlaceholder")}
      style={{ marginRight: 8 }}
    />
  );
}
