import { Chip, fieldToSelect, useTranslation } from "@lumar/shared";
import { LegacyTaskStatus } from "../../graphql";
import { Field, FieldProps } from "formik";
import { Label } from "./Label";
import { makeStyles } from "@material-ui/core";
import { StatusSelect } from "./StatusSelect";

export function StatusField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  function getStatusChip(status: LegacyTaskStatus): React.ReactNode {
    switch (status) {
      case LegacyTaskStatus.Done:
        return (
          <Chip
            color="green"
            label={t("status.done")}
            className={classes.chip}
          />
        );
      case LegacyTaskStatus.Testing:
        return (
          <Chip
            color="yellow"
            label={t("status.testing")}
            className={classes.chip}
          />
        );
      case LegacyTaskStatus.InProgress:
        return (
          <Chip
            color="blue"
            label={t("status.inProgress")}
            className={classes.chip}
          />
        );
      case LegacyTaskStatus.ToDo:
        return (
          <Chip
            color="orange"
            label={t("status.toDo")}
            className={classes.chip}
          />
        );
      default:
        return (
          <Chip
            color="purple"
            label={t("status.backlog")}
            className={classes.chip}
          />
        );
    }
  }

  return (
    <Field name="status">
      {(props: FieldProps<LegacyTaskStatus>) =>
        !isEditing ? (
          <>
            <Label label={t("editDialog.status")} />
            {getStatusChip(props.field.value)}
          </>
        ) : (
          <StatusSelect
            {...fieldToSelect(props)}
            className={classes.input}
            label={t("editDialog.status")}
          />
        )
      }
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.75, 0, 1, 0),
    padding: theme.spacing(0, 0.5),
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
  input: {
    width: 150,
    margin: theme.spacing(0, 0, 1, 0),
    "& label": {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
    },
  },
}));
