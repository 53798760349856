/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getBrokenLinksChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("brokenLinks.title"),
    description: () => t("brokenLinks.description"),
    reportTemplateCodesOrder: [
      "unique_broken_links",
      "all_broken_links",
      "canonical_to_non_200",
      "broken_hreflang_links",
    ],
    reportStatFilter: (report) => {
      return [
        "unique_broken_links",
        "all_broken_links",
        "canonical_to_non_200",
        "broken_hreflang_links",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
