import { ReportCategoryChartConfig } from "../types";
import { getBacklinkIssuesChart } from "../../../../_common/charts/config/getBacklinkIssuesChart";
import { getBacklinksDistribution } from "../../../../_common/charts/config/getBacklinksDistribution";
import { getContentDuplicationChart } from "../../../../_common/charts/config/getContentDuplicationChart";
import { getContentIssuesChart } from "../../../../_common/charts/config/getContentIssuesChart";
import { getMobileContentIssuesChart } from "../../../../_common/charts/config/getMobileContentIssuesChart";
import { getNonPrimaryPagesInSerps } from "../../../../_common/charts/config/getNonPrimaryPagesInSerps";
import { getNonPrimaryPagesInSerpsTrend } from "../../../../_common/charts/config/getNonPrimaryPagesInSerpsTrend";
import { getPagesWithSchemaMarkupChart } from "../../../../_common/charts/config/getPagesWithSchemaMarkupChart";
import { getRankabilityChart } from "../../../../_common/charts/config/getRankabilityChart";
import { getRankabilityTrend } from "../../../../_common/charts/config/getRankabilityTrend";
import { getSocialTagsBreakdownChart } from "../../../../_common/charts/config/getSocialTagsBreakdownChart";
import { getStructuredDataTypesChart } from "../../../../_common/charts/config/getStructuredDataTypesChart";
import { getTitleAndDescriptionIssuesChart } from "../../../../_common/charts/config/getTitleAndDescriptionIssuesChart";

export const generateRankabilityCategoryConfig: ReportCategoryChartConfig = {
  mainCharts: [
    { predefinedChartType: "topChanges" },
    getRankabilityChart,
    getRankabilityTrend,
    getNonPrimaryPagesInSerps,
    getNonPrimaryPagesInSerpsTrend,
    getBacklinksDistribution,
    getBacklinkIssuesChart,
    getContentIssuesChart,
    getMobileContentIssuesChart,
    getContentDuplicationChart,
    getTitleAndDescriptionIssuesChart,
    getPagesWithSchemaMarkupChart,
    getStructuredDataTypesChart,
    getSocialTagsBreakdownChart,
  ],
};
