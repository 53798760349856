import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles({
    monospace: {
        "font-variant-numeric": "tabular-nums",
    },
});
export function MonospaceNumber(props) {
    const classes = useStyles();
    return (_jsx("span", { className: clsx(classes.monospace, props.className), children: props.children }));
}
