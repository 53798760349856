import {
  ArrowNarrowDownSolid,
  ArrowNarrowUpSolid,
  Button,
  formArrayAdd,
  formArrayRemove,
  formToTextFieldProps,
  PlusCircleSolid,
  TextField,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Collapse, makeStyles } from "@material-ui/core";
import { FastField, FieldProps, FormikProps, FormikTouched } from "formik";
import React from "react";
import { CookieSettings, FormValues } from "../data/types";
import { CookiesImport } from "./CookiesImport";
import { SettingControlFactory } from "../useAdvancedControls";

export const getCookiesControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("cookies"),
  path: "cookies",
  // eslint-disable-next-line react/display-name
  control: () => <FastField name="cookies" component={Cookies} />,
  testId: "crawl-settings-advanced-cookies",
  activeValues: ["cookies"],
  textForSearch: createTextForSearch([
    t("cookies"),
    t("cookiesDescription"),
    t("cookiesAdd"),
    t("cookiesImport"),
    t("cookiesImportIntruction"),
    t("cookiesImportIntruction1"),
    t("cookiesImportIntruction2"),
    t("cookiesImportIntruction3"),
    t("cookiesImportIntruction4"),
    t("cookiesImportIntruction5"),
    t("cookiesImportAlert"),
  ]),
  fieldNames: [
    ["cookies", t("cookiesField")],
    ["key", t("cookiesKeyField")],
    ["value", t("cookiesValueField")],
  ],
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2.75),
    marginTop: theme.spacing(2.5),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr auto",
    gap: theme.spacing(1.5),
    margin: theme.spacing(2.625, 0),
  },
  divider: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    margin: theme.spacing(2.5, 0, 2.625, 0),
  },
}));

export function Cookies({
  field,
  form,
}: FieldProps<FormValues["cookies"]>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [importOpen, setImportOpen] = React.useState(false);

  const cookies = field.value.cookies;

  function handleAdd(): void {
    formArrayAdd(form, "cookies.cookies", { key: "", value: "" });
  }

  function handleRemove(idx: number): void {
    formArrayRemove(form, "cookies.cookies", idx);
  }

  function handleImport(importCookies: CookieSettings[]): void {
    formMergeCookies(form, importCookies);
    setImportOpen(false);
  }

  return (
    <div className={classes.root}>
      <Typography>{t("cookiesDescription")}</Typography>
      <div className={classes.content}>
        {Boolean(cookies.length) && (
          <div className={classes.grid}>
            {cookies.map((_, idx) => (
              <React.Fragment key={idx}>
                <TextField
                  {...formToTextFieldProps(form, `cookies.cookies[${idx}].key`)}
                  placeholder={t("cookiesKeyPlaceholder")}
                />
                <TextField
                  {...formToTextFieldProps(
                    form,
                    `cookies.cookies[${idx}].value`,
                  )}
                  placeholder={t("cookiesValuePlaceholder")}
                />
                <ToggleIconButton
                  onClick={() => handleRemove(idx)}
                  disabled={form.isSubmitting}
                  variant="outlined"
                  colorVariant="red"
                  size="large"
                >
                  <TrashSolid />
                </ToggleIconButton>
              </React.Fragment>
            ))}
          </div>
        )}
        <Button
          onClick={() => handleAdd()}
          disabled={form.isSubmitting}
          variant="outlined"
          size="small"
          startIcon={<PlusCircleSolid />}
        >
          {t("cookiesAdd")}
        </Button>

        <div className={classes.divider} />

        <Button
          onClick={() => setImportOpen((x) => !x)}
          variant="outlined"
          size="small"
          startIcon={
            importOpen ? <ArrowNarrowUpSolid /> : <ArrowNarrowDownSolid />
          }
        >
          {t("cookiesImport")}
        </Button>

        <Collapse in={importOpen}>
          <CookiesImport onAdd={handleImport} disabled={form.isSubmitting} />
        </Collapse>
      </div>
    </div>
  );
}

function formMergeCookies(
  form: FormikProps<Pick<FormValues, "cookies">>,
  importCookies: CookieSettings[],
): void {
  const cookies = [...form.values.cookies.cookies];
  const touched: FormikTouched<CookieSettings>[] = Array(cookies.length);

  form.touched.cookies?.cookies?.forEach((value, idx) => {
    // eslint-disable-next-line fp/no-mutation
    touched[idx] = value;
  });

  importCookies.forEach((cookie) => {
    const foundIdx =
      cookie.key !== ""
        ? cookies.findIndex((x) => x.key === cookie.key)
        : cookies.findIndex((x) => x.key === "" && x.value === cookie.value);

    if (foundIdx !== -1) {
      // eslint-disable-next-line fp/no-mutation
      cookies[foundIdx] = cookie;
      // eslint-disable-next-line fp/no-mutation
      touched[foundIdx] = { key: true, value: true };
    } else {
      // eslint-disable-next-line fp/no-mutating-methods
      cookies.push(cookie);
      // eslint-disable-next-line fp/no-mutating-methods
      touched.push({ key: true, value: true });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form.setFieldTouched("cookies.cookies", touched as any);
  form.setFieldValue("cookies.cookies", cookies, true);
}
