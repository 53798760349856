import React from "react";
import {
  useTranslation,
  getProperty,
  TagInput,
  Typography,
} from "@lumar/shared";
import { FastField, FastFieldProps } from "formik";
import { makeStyles } from "@material-ui/core";
import { uniq } from "lodash";

import { SettingControlFactory } from "../useAdvancedControls";
import { SettingsContext } from "../data/useContextValues";

export const getStartUrlsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("startUrlsTitle"),
  path: "startUrls",
  // eslint-disable-next-line react/display-name
  control: () => <StartUrls />,
  testId: "crawl-settings-advanced-start-urls",
  activeValues: ["startUrls"],
  textForSearch: createTextForSearch([
    t("startUrlsTitle"),
    t("startUrlsDescription"),
    t("startUrlsPlaceholder"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
  },
}));

export const StartUrls = React.memo(StartUrlsInner);

function StartUrlsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  const withProtocolAndEncodedSpaces = (url: string): string => {
    const protocol = contextValues?.primaryDomain?.match(/^(.*?):/);
    const urlWithProtocol =
      !(protocol && protocol[0]) || url.match(/^[a-zA-Z]+:\/\//)
        ? url
        : protocol[0] + "//" + url;
    return urlWithProtocol.replaceAll(" ", "%20");
  };

  return (
    <>
      <Typography variant="caption">{t("startUrlsDescription")}</Typography>
      <FastField name="spider.startUrls.startUrls">
        {({
          field: { value, name },
          form: { setFieldValue, isSubmitting, errors },
        }: FastFieldProps<string[]>) => {
          const error: string[] | undefined = getProperty(errors, name);
          return (
            <TagInput
              value={value}
              onChange={(value) =>
                setFieldValue(
                  name,
                  uniq(value.map((x) => withProtocolAndEncodedSpaces(x))),
                  true,
                )
              }
              disabled={isSubmitting}
              showCopyAll
              error={error?.find(Boolean)}
              errors={error?.map((error) => Boolean(error))}
              variant="outlined"
              className={classes.input}
              placeholder={t("startUrlsPlaceholder")}
              data-testid="start-urls-tags"
            />
          );
        }}
      </FastField>
    </>
  );
}
