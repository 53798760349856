import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { CrawlStatus } from "../../graphql";
import { activeStatuses } from "../../project-crawls/constants";
import { useTimeDurationFormatter } from "../../_common/locale/format-functions/useTimeDurationFormatter";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  duration: {
    color: theme.palette.grey[600],
  },
}));

export function CrawlDurationCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element {
  const classes = useStyles();

  const crawlInProgress = row.lastCrawlStatus
    ? activeStatuses.includes(row.lastCrawlStatus)
    : false;

  const [currentTime, setCurrentTime] = React.useState<Date | undefined>(
    new Date(),
  );

  React.useEffect(() => {
    if (!crawlInProgress) return;
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, [crawlInProgress]);

  const isAnarchiving =
    row.lastCrawlFinishedAt && row.lastCrawlStatus === CrawlStatus.Finalizing;

  const lastFinishedCrawlStartTime = row.lastCrawlCreatedAt
    ? new Date(row.lastCrawlCreatedAt)
    : undefined;
  const lastFinishedCrawlEndTime = row.lastCrawlFinishedAt
    ? new Date(row.lastCrawlFinishedAt)
    : undefined;

  const format = useTimeDurationFormatter();

  return (
    <Typography
      variant="caption"
      className={classes.duration}
      data-testid="all-projects-duration-cell"
    >
      {!isAnarchiving
        ? format(lastFinishedCrawlStartTime, currentTime, "long")
        : format(lastFinishedCrawlStartTime, lastFinishedCrawlEndTime, "long")}
    </Typography>
  );
}
