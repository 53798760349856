import { Button, TextField, Typography, useTranslation } from "@lumar/shared";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SegmentsListData } from "./data/useSegmentsList";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  closeButton: {
    marginLeft: "auto",
  },
  content: {
    paddingTop: theme.spacing(1.5),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    gap: theme.spacing(1),
    margin: theme.spacing(1.375, 0, 0.625, 0),
  },
}));

interface Props {
  group: string;
  closeDialog: () => void;
  segments: SegmentsListData["segments"];
  updateSegmentsGroupMutation: (
    segmentIds: string[],
    group: string | undefined,
  ) => Promise<void>;
}

export function EditGroupDialog({
  group,
  closeDialog,
  segments,
  updateSegmentsGroupMutation,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("segments");

  const formik = useFormik<{ group: string }>({
    initialValues: {
      group,
    },
    validationSchema: Yup.object({
      group: Yup.string().required(t("validationErrorEmptyGroup")),
    }),
    onSubmit: async (values) => {
      await updateSegmentsGroupMutation(
        segments.filter((x) => x.group === group).map((x) => x.id),
        values.group,
      );
      closeDialog();
    },
  });

  return (
    <Dialog
      open={true}
      onClose={closeDialog}
      fullWidth
      maxWidth="xs"
      data-testid="edit-segment-group-dialog"
    >
      <DialogTitle disableTypography>
        <div className={classes.titleContainer}>
          <Typography component="h2" variant="h2">
            {t("editGroupDialogTitle")}
          </Typography>
          <IconButton
            onClick={closeDialog}
            aria-label="close"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <TextField
          name="group"
          value={formik.values.group}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.group)}
          helperText={formik.errors.group}
          label={t("groupNameLabel")}
          data-testid="group-name-input"
        />
        <div className={classes.buttonContainer}>
          <Button onClick={closeDialog} variant="outlined" size="large">
            {t("cancel")}
          </Button>
          <Button
            onClick={formik.submitForm}
            loading={formik.isSubmitting}
            variant="contained"
            color="primary"
            size="large"
            data-testid="group-name-update"
          >
            {t("update")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
