import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SegmentRow } from "../SegmentsGrid";
import { CircularProgress, Tooltip, makeStyles } from "@material-ui/core";
import {
  ExclamationSolid,
  Typography,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    textDecoration: "inherit",
    color: "inherit",
  },
}));

interface Props extends GridRenderCellParams {
  crawlInProgress: boolean;
}

export function UrlCountCell({
  value: valueParam,
  row: rowParam,
  crawlInProgress,
}: Props): JSX.Element {
  const row = rowParam as SegmentRow;
  const value = valueParam as number | undefined;

  const classes = useStyles();
  const { t } = useTranslation("segments");
  const formatNumber = useNumberFormatter();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (row.isGroup) {
    return <></>;
  }

  const inProgress = row.isGenerating || crawlInProgress;
  if (inProgress) {
    return (
      <div className={classes.container}>
        <CircularProgress size={24} />
      </div>
    );
  }

  if (row.isFailed) {
    return (
      <Tooltip title={t("failedToGenerate")}>
        <div className={classes.container}>
          <ExclamationSolid color="error" />
        </div>
      </Tooltip>
    );
  }

  return (
    <Link
      to={Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId,
        reportTemplateCodeWithTypeCode: "all_pages_basic",
        segmentId: row.id,
      })}
      className={classes.link}
    >
      <Typography variant="subtitle3Medium">
        {value !== undefined ? formatNumber(value) : ""}
      </Typography>
    </Link>
  );
}
