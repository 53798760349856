import { ReportCategoryChartConfig } from "../types";
import { generateTrafficCategoryConfigItems } from "../TrafficCategoryChartConfig";
import { generateRankabilityCategoryConfig } from "./RankabilityCategoryConfig";
import { generateRankabilityPageContentCategory } from "./RankabilityPageContentCategory";
import { generateRankabilityAuthorityCategory } from "./RankabilityAuthorityCategory";
import { generateRankabilitySocialTagsCategoryConfig } from "./RankabilitySocialTagsCategoryConfig";
import { generateRankabilityStructuredDataCategory } from "./RankabilityStructuredDataCategory";
import { generateRankabilityTitlesDescriptionsCategory } from "./RankabilityTitlesDescriptionsCategory";
import { generateCrawlabilityCategoryChartConfigItems } from "./CrawlabilityCategoryChartConfig";
import { generateCrawlabilityCrawlBudgetCategoryChartConfigItems } from "./CrawlabilityCrawlBudgetCategoryChartConfig";
import { generateCrawlabilityInternalLinksCategoryChartConfigItems } from "./CrawlabilityInternalLinksCategoryChartConfig";
import { generateCrawlabilityInternationalizationCategoryConfig } from "./CrawlabilityInternationalizationCategoryChartConfig";
import { generateCrawlabilityRedirectionsCategoryChartConfigItems } from "./CrawlabilityRedirectionsCategoryChartConfig";
import { generateCrawlabilitySitemapsCategoryChartConfigItems } from "./CrawlabilitySitemapsCategoryChartConfig";
import { generateCustomCategoryChartConfigItems } from "./CustomCategoryChartConfig";
import { generateExperienceCategoryChartConfig } from "./ExperienceCategoryChartConfig";
import { generateExperienceExternalLinksCategoryChartConfigItems } from "./ExperienceExternalLinksCategoryChartConfig";
import { generateExperiencePageSpeedCategoryChartConfigItems } from "./ExperiencePageSpeedCategoryChartConfig";
import { generateExperienceSecurityCategoryChartConfig } from "./ExperienceSecurityCategoryChartConfig";
import { generateExperienceUserExperienceChartItems } from "./ExperienceUserExperienceChartConfig";
import { generateIndexabilityCanonicalizationCategoryItems } from "./IndexabilityCanonicalizationCategory";
import { generateIndexabilityCategoryChartConfigItems } from "./IndexabilityCategoryChartConfig";
import { generateIndexabilityNoindexCategoryItems } from "./IndexabilityNoindexCategory";
import { generateIndexabilityMobileIndexabilityCategoryItems } from "./IndexabilityMobileIndexabilityCategory";
import { generateAvailabilityCategoryItems } from "./AvailabilityCategory";
import { generateSummaryCrawlSummaryChartConfig } from "./SummaryCrawlSummaryChartConfig";
import { generateSummarySourceSummaryChartConfig } from "./SummarySourceSummaryChartConfig";
import { generateTrafficAnalyticsCategoryChartConfigItems } from "./TrafficAnalyticsCategoryChartConfig";
import { generateTrafficSearchConsoleCategoryConfigItems } from "./TrafficSearchConsoleCategoryChartConfig";
import { generateUniquenessCategoryChartConfigItems } from "./UniquenessCategoryChartConfig";
import { generateSummaryCategoryChartConfigItems } from "./SummaryCategoryChartConfig";
import { generateRemovedCategoryItems } from "./RemovedCategory";
import { generateNoCategoryChartConfigItems } from "./NoCategoryChartConfig";
import { generateExperienceEngagementCategoryChartConfigItems } from "./ExperienceEngagementCategoryChartConfig";
import { generateAccessibilityACategoryItems } from "./a11y/AccessibilityACategory";
import { generateAccessibilityKeyboardACategoryItems } from "./a11y/AccessibilityKeyboardACategory";
import { generateAccessibilityAriaACategoryItems } from "./a11y/AccessibilityAriaACategory";
import { generateAccessibilityColorACategoryItems } from "./a11y/AccessibilityColorACategory";
import { generateAccessibilityFormsACategoryItems } from "./a11y/AccessibilityFormsACategory";
import { generateAccessibilityLanguageACategoryItems } from "./a11y/AccessibilityLanguageACategory";
import { generateAccessibilityNameRoleValueACategoryItems } from "./a11y/AccessibilityNameRoleValueACategory";
import { generateAccessibilityColorAAACategoryItems } from "./a11y/AccessibilityColorAAACategory";
import { generateAccessibilityColorAACategoryItems } from "./a11y/AccessibilityColorAACategory";
import { generateAccessibilityFormsAACategoryItems } from "./a11y/AccessibilityFormsAACategory";
import { generateAccessibilityLanguageAACategoryItems } from "./a11y/AccessibilityLanguageAACategory";
import { generateAccessibilityParsingACategoryItems } from "./a11y/AccessibilityParsingACategory";
import { generateAccessibilitySemanticsAAACategoryItems } from "./a11y/AccessibilitySemanticsAAACategory";
import { generateAccessibilitySemanticsACategoryItems } from "./a11y/AccessibilitySemanticsACategory";
import { generateAccessibilityStructureAACategoryItems } from "./a11y/AccessibilityStructureAACategory";
import { generateAccessibilityStructureACategoryItems } from "./a11y/AccessibilityStructureACategory";
import { generateAccessibilityTablesACategoryItems } from "./a11y/AccessibilityTablesACategory";
import { generateAccessibilityTextAlternativesACategoryItems } from "./a11y/AccessibilityTextAlternativesACategory";
import { generateAccessibilityTimeAndMediaACategoryItems } from "./a11y/AccessibilityTimeAndMediaACategory";
import { generateAccessibilityAriaBPCategoryItems } from "./a11y/AccessibilityAriaBPCategory";
import { generateAccessibilityFormsBPCategoryItems } from "./a11y/AccessibilityFormsBPCategory";
import { generateAccessibilityKeyboardBPCategoryItems } from "./a11y/AccessibilityKeyboardBPCategory";
import { generateAccessibilityNameRoleValueBPCategoryItems } from "./a11y/AccessibilityNameRoleValueBPCategory";
import { generateAccessibilitySemanticsBPCategoryItems } from "./a11y/AccessibilitySemanticsBPCategory";
import { generateAccessibilityStructureBPCategoryItems } from "./a11y/AccessibilityStructureBPCategory";
import { generateAccessibilityTablesBPCategoryItems } from "./a11y/AccessibilityTablesBPCategory";
import { generateAccessibilityTextAlternativesBPCategoryItems } from "./a11y/AccessibilityTextAlternativesBPCategory";
import { generateAccessibilitySensoryAndVisualBPCategoryItems } from "./a11y/AccessibilitySensoryAndVisualBPCategory";
import { generateAccessibilityLevelAACategoryItems } from "./a11y/AccessibilityLevelAACategory";
import { generateAccessibilityLevelAAACategoryItems } from "./a11y/AccessibilityLevelAAACategory";
import { generateAccessibilityOverviewCategoryItems } from "./a11y/AccessibilityOverviewCategory";
import { generateAccessibilityLevelACategoryItems } from "./a11y/AccessibilityLevelACategory";
import { generateAccessibilityLevelBPCategoryItems } from "./a11y/AccessibilityLevelBPCategory";
import { generateAccessibilitySensoryAndVisualAACategoryItems } from "./a11y/AccessibilitySensoryAndVisualAACategory";
import { generateSiteSpeedOverviewCategoryItems } from "./site-speed/SiteSpeedOverviewCategory";
import { generateSiteSpeedTotalBlockingTimeCategoryItems } from "./site-speed/SiteSpeedTotalBlockingTimeCategory";
import { generateSiteSpeedLargestContentfulPaintCategoryItems } from "./site-speed/SiteSpeedLargestContentfulPaintCategory";
import { generateSiteSpeedFirstContentfulPaintCategoryItems } from "./site-speed/SiteSpeedFirstContentfulPaintCategory";
import { generateSiteSpeedCumulativeLayoutShiftCategoryItems } from "./site-speed/SiteSpeedCumulativeLayoutShiftCategory";
import { generateSiteSpeedSpeedIndexCategoryItems } from "./site-speed/SiteSpeedSpeedIndexCategory";

export const newCategoriesChartsMap = new Map<
  string,
  ReportCategoryChartConfig
>([
  // TOP
  ["top", generateNoCategoryChartConfigItems],

  // SUMMARY
  ["summary", generateSummaryCategoryChartConfigItems],
  ["crawl_summary", generateSummaryCrawlSummaryChartConfig],
  ["source_summary", generateSummarySourceSummaryChartConfig],

  // AVAILABILITY
  ["availability", generateAvailabilityCategoryItems],

  // INDEXABILITY
  ["indexability", generateIndexabilityCategoryChartConfigItems],
  ["non_indexable", generateIndexabilityNoindexCategoryItems],
  ["canonicalization", generateIndexabilityCanonicalizationCategoryItems],
  ["mobile_indexability", generateIndexabilityMobileIndexabilityCategoryItems],

  // UNIQUENESS
  ["uniqueness", generateUniquenessCategoryChartConfigItems],

  // CRAWLABILITY
  ["discoverability", generateCrawlabilityCategoryChartConfigItems],
  ["crawl_budget", generateCrawlabilityCrawlBudgetCategoryChartConfigItems],
  ["internal_links", generateCrawlabilityInternalLinksCategoryChartConfigItems],
  ["redirections", generateCrawlabilityRedirectionsCategoryChartConfigItems],
  ["sitemaps", generateCrawlabilitySitemapsCategoryChartConfigItems],
  [
    "internationalization",
    generateCrawlabilityInternationalizationCategoryConfig,
  ],

  // RANKABILITY
  ["rankability", generateRankabilityCategoryConfig],
  ["authority", generateRankabilityAuthorityCategory],
  ["page_content", generateRankabilityPageContentCategory],
  // ["mobile_content", generateContentSerpMobileCategoryChartConfigItems],
  ["titles_descriptions", generateRankabilityTitlesDescriptionsCategory],
  ["structured_data", generateRankabilityStructuredDataCategory],
  ["social_tags", generateRankabilitySocialTagsCategoryConfig],

  // EXPERIENCE
  ["experience", generateExperienceCategoryChartConfig],
  ["engagement", generateExperienceEngagementCategoryChartConfigItems],
  ["page_speed", generateExperiencePageSpeedCategoryChartConfigItems],
  ["user_experience", generateExperienceUserExperienceChartItems],
  ["security", generateExperienceSecurityCategoryChartConfig],
  ["external_links", generateExperienceExternalLinksCategoryChartConfigItems],
  // ["rendering", generateExperienceRenderingChartConfigItems],

  // TRAFFIC
  ["traffic", generateTrafficCategoryConfigItems],
  ["analytics", generateTrafficAnalyticsCategoryChartConfigItems],
  ["search_console", generateTrafficSearchConsoleCategoryConfigItems],

  // CUSTOM
  ["custom", generateCustomCategoryChartConfigItems],

  // Accessibility
  ["accessibility_a", generateAccessibilityACategoryItems],
  ["accessibility_aria_a", generateAccessibilityAriaACategoryItems],
  ["accessibility_color_a", generateAccessibilityColorACategoryItems],
  ["accessibility_keyboard_a", generateAccessibilityKeyboardACategoryItems],
  ["accessibility_forms_a", generateAccessibilityFormsACategoryItems],
  ["accessibility_language_a", generateAccessibilityLanguageACategoryItems],
  [
    "accessibility_name_role_value_a",
    generateAccessibilityNameRoleValueACategoryItems,
  ],
  ["accessibility_parsing_a", generateAccessibilityParsingACategoryItems],
  ["accessibility_structure_a", generateAccessibilityStructureACategoryItems],
  ["accessibility_tables_a", generateAccessibilityTablesACategoryItems],
  [
    "accessibility_text_alternatives_a",
    generateAccessibilityTextAlternativesACategoryItems,
  ],
  [
    "accessibility_time_and_media_a",
    generateAccessibilityTimeAndMediaACategoryItems,
  ],
  ["accessibility_semantics_a", generateAccessibilitySemanticsACategoryItems],

  ["accessibility_color_aa", generateAccessibilityColorAACategoryItems],
  ["accessibility_language_aa", generateAccessibilityLanguageAACategoryItems],
  ["accessibility_forms_aa", generateAccessibilityFormsAACategoryItems],
  ["accessibility_structure_aa", generateAccessibilityStructureAACategoryItems],
  [
    "accessibility_sensory_and_visual_aa",
    generateAccessibilitySensoryAndVisualAACategoryItems,
  ],

  ["accessibility_color_aaa", generateAccessibilityColorAAACategoryItems],
  [
    "accessibility_semantics_aaa",
    generateAccessibilitySemanticsAAACategoryItems,
  ],
  ["accessibility_aria_bp", generateAccessibilityAriaBPCategoryItems],
  ["accessibility_keyboard_bp", generateAccessibilityKeyboardBPCategoryItems],
  [
    "accessibility_name_role_value_bp",
    generateAccessibilityNameRoleValueBPCategoryItems,
  ],
  ["accessibility_structure_bp", generateAccessibilityStructureBPCategoryItems],
  [
    "accessibility_text_alternatives_bp",
    generateAccessibilityTextAlternativesBPCategoryItems,
  ],
  ["accessibility_semantics_bp", generateAccessibilitySemanticsBPCategoryItems],
  ["accessibility_forms_bp", generateAccessibilityFormsBPCategoryItems],
  [
    "accessibility_sensory_and_visual_bp",
    generateAccessibilitySensoryAndVisualBPCategoryItems,
  ],
  ["accessibility_tables_bp", generateAccessibilityTablesBPCategoryItems],
  ["accessibility_a", generateAccessibilityLevelACategoryItems],
  ["accessibility_aa", generateAccessibilityLevelAACategoryItems],
  ["accessibility_aaa", generateAccessibilityLevelAAACategoryItems],
  ["accessibility_bp", generateAccessibilityLevelBPCategoryItems],
  ["accessibility", generateAccessibilityOverviewCategoryItems],

  // Admin
  ["removed", generateRemovedCategoryItems],

  // Accessibility
  ["site_speed", generateSiteSpeedOverviewCategoryItems],
  [
    "first_contentful_paint",
    generateSiteSpeedFirstContentfulPaintCategoryItems,
  ],
  [
    "largest_contentful_paint",
    generateSiteSpeedLargestContentfulPaintCategoryItems,
  ],
  ["total_blocking_time", generateSiteSpeedTotalBlockingTimeCategoryItems],
  [
    "cumulative_layout_shift",
    generateSiteSpeedCumulativeLayoutShiftCategoryItems,
  ],
  ["speed_index", generateSiteSpeedSpeedIndexCategoryItems],
]);
