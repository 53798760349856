var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Backdrop, DialogActions, DialogContent, DialogTitle, IconButton, Popover, useTheme, } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import { AppThemeProvider } from "../../theme/AppThemeProvider";
import { Typography } from "../typography/Typography";
import { Button } from "../buttons/Button";
import { XSolid } from "../..";
import { omit } from "lodash";
function DialogInner(_a) {
    var _b;
    var { title, withGreyFooter, onCloseViaIcon, classes, buttons, pendoPrefix, invisibleBackdrop } = _a, dialogProps = __rest(_a, ["title", "withGreyFooter", "onCloseViaIcon", "classes", "buttons", "pendoPrefix", "invisibleBackdrop"]);
    const backdropRef = useRef();
    const theme = useTheme();
    const anchor = dialogProps.anchorEl
        ? dialogProps.anchorEl
        : backdropRef.current;
    const dialogInner = (_jsxs(Popover, Object.assign({ anchorOrigin: {
            vertical: "center",
            horizontal: "center",
        }, transformOrigin: {
            vertical: "center",
            horizontal: "center",
        } }, dialogProps, { anchorEl: anchor, className: clsx(classes === null || classes === void 0 ? void 0 : classes.popover, (_b = dialogProps.className) !== null && _b !== void 0 ? _b : ""), PaperProps: {
            style: {
                display: "flex",
                flexDirection: "column",
                width: dialogProps.width,
                height: dialogProps.height,
            },
        }, BackdropProps: invisibleBackdrop
            ? Object.assign(Object.assign({}, dialogProps.BackdropProps), { style: { backgroundColor: "transparent", boxShadow: "none" } }) : dialogProps.BackdropProps, children: [_jsxs("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.grey[200]}`,
                }, children: [title ? (_jsx(DialogTitle, { className: classes === null || classes === void 0 ? void 0 : classes.title, children: _jsx(Typography, { variant: "subtitle1SemiBold", className: classes === null || classes === void 0 ? void 0 : classes.titleText, children: title }) })) : null, onCloseViaIcon ? (_jsx(IconButton, { "aria-label": "close", className: classes === null || classes === void 0 ? void 0 : classes.closeButton, onClick: onCloseViaIcon, "data-pendo": pendoPrefix ? `${pendoPrefix}-dialog-close` : undefined, children: _jsx(XSolid, { fontSize: "small" }) })) : null] }), _jsx(DialogContent, { className: classes === null || classes === void 0 ? void 0 : classes.dialogContent, children: _jsx(AppThemeProvider, { children: dialogProps.children }) }), _jsx(DialogActions, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.footer, withGreyFooter ? classes === null || classes === void 0 ? void 0 : classes.greyFooter : ""), children: buttons.map((element, index) => {
                    return (_jsx(Button, Object.assign({}, omit(element, "title"), { children: element.title }), index.toString()));
                }) })] })));
    return dialogProps.hideBackdrop || invisibleBackdrop ? (dialogInner) : (_jsx(Backdrop, { open: dialogProps.open, className: classes === null || classes === void 0 ? void 0 : classes.backdrop, ref: backdropRef, children: dialogInner }));
}
export const Dialog = withStyles((theme) => ({
    dialogContent: {
        display: "flex",
        padding: theme.spacing(2, 2, 1, 2),
        "&:first-child": {
            paddingTop: theme.spacing(2),
        },
    },
    title: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    titleText: {
        marginBottom: theme.spacing(1.25),
        color: theme.palette.grey[900],
    },
    footer: {
        padding: theme.spacing(2),
    },
    greyFooter: {
        background: theme.palette.grey[300],
        padding: theme.spacing(1.5, 2),
    },
    closeButton: {
        color: theme.palette.grey[500],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer - 1,
    },
    popover: {
        zIndex: theme.zIndex.drawer,
    },
}))(DialogInner);
