/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {} from "../../../graphql";

export function getDuplicateSetsChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("duplicateSets.title"),
    description: () => t("duplicateSets.description"),
    reportTemplateCodesOrder: [
      "duplicate_pages_2",
      "duplicate_titles_2",
      "duplicate_descriptions_2",
      "duplicate_content_2",
      "duplicate_url_sets",
    ],
    reportStatFilter: (report) => {
      return [
        "duplicate_pages_2",
        "duplicate_titles_2",
        "duplicate_descriptions_2",
        "duplicate_content_2",
        "duplicate_url_sets",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
