import { ReportCategoryChartConfig } from "../types";
import { getCustomExtractionChart } from "../../../../_common/charts/config/getCustomExtractionChart";
import { getExtractReportsChart } from "../../../../_common/charts/config/getExtractReportsChart";

export const generateCustomCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getExtractReportsChart,
      getCustomExtractionChart,
    ],
  };
