import { Link } from "react-router-dom";
// RIP Chester 1976 – 2017
export function getLinkinProps(href) {
    if (href === undefined)
        return {};
    if (href.startsWith("http://") || href.startsWith("https://")) {
        return {
            component: "a",
            href,
        };
    }
    return {
        component: Link,
        to: href,
    };
}
