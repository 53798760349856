/* eslint-disable react/display-name */
import { Trans } from "@lumar/shared";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "@lumar/shared/dist/graphql";

export function getCrawledUrlTypeChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
      ModuleCode.SiteSpeed,
    ],
    title: () => t("crawledUrlTypeChart.title"),
    description: () => (
      <Trans
        ns="charts"
        i18nKey="crawledUrlTypeChart.description"
        components={[
          <Typography key="0" paragraph variant="inherit" />,
          <Typography key="1" variant="inherit" />,
        ]}
      />
    ),
    reportTemplateCodesOrder: [
      "all_pages",
      "javascript_files",
      "css_files",
      "all_images_2",
      "external_urls_crawled",
      "xml_sitemaps",
    ],
    reportStatFilter: (report) => {
      return [
        "all_pages",
        "javascript_files",
        "css_files",
        "all_images_2",
        "external_urls_crawled",
        "xml_sitemaps",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },

    testAttributePrefix: "orphaned-pages-chart",
  };
}
