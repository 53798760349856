type ColorIndex =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32;

type Category = string;
type ReportTemplateCode = string;

export interface ChartReportColorConfig {
  globalReportColor: Record<ReportTemplateCode, string | ColorIndex>;
  categoryReportColor: Record<
    Category,
    Record<ReportTemplateCode, string | ColorIndex>
  >;
}

/*
 * Unifies colors used to represent data points referring to report templates.
 * Each report template can be represented by color string or number which is an index of color defined in the `getChartColors` function.
 * Warning: Empty string or lack of report template code will cause Highcharts to fallback to default color assignment algorithm.
 * Warning: Charts used in the report view refer only to global report colors!
 * Warning: These colors are not applied when `visualisationColors` array exists in the chart config!
 */
export const newChartReportColorConfig: ChartReportColorConfig = {
  globalReportColor: {
    unique_pages: 1,
    non_unique_pages: 11,
    duplicate_pages: 11,
    paginated_pages: 3,
    mobile_alternates: 6,
    non_indexable_pages: 9,
    "non-200_pages": 13,
    "200_pages": 1,
    failed_urls: 14,
    "4xx_errors": 16,
    uncategorised_http_response_codes: 8,
    "5xx_errors": 13,
    unauthorised_pages: 11,
    non_301_redirects: 12,
    "301_redirects": 9,
    disallowed_urls: 11,
    robots_noindexed_urls: 6,
    malformed_urls: 14,
    excluded_urls: 5,
    not_included_urls: 8,
    page_group_restricted_urls: 3,
    noindex_pages: 12,
    canonicalized_pages: 7,
    nofollowed_pages: 5,
    disallowed_pages: 11,
    all_pages: 15,
    https_pages: 2,
    http_pages: 9,
    indexable_pages_in_search: 0,
    indexable_pages_not_in_search: 6,
    indexable_pages: 2,
    primary_duplicates: 3,
    true_uniques: 1,
    max_content_size: 9,
    thin_pages: 12,
    empty_pages: 14,
    javascript_files: 3,
    css_files: 5,
    all_images_2: 6,
    linked_non_html_files: 8,
    pages_with_social_markup: 2,
    pages_without_social_markup: 11,
    missing_titles: 13,
    missing_descriptions: 11,
    short_titles: 9,
    short_descriptions: 8,
    max_title_length: 6,
    max_description_length: 5,
    "no_descriptions_&_no_snippets": 3,
    missing_h1_tags: 11,
    multiple_h1_tag_pages: 9,
    noarchive_pages: 7,
    meta_nofollow_pages: 5,
    max_links: 3,
    broken_images: 14,
    broken_js_css: 16,
    // http_scripts_on_https_pages: 12,
    disallowed_js_css: 10,
    disallowed_js_css_uncrawled: 10,
    unique_redirecting_images: 8,
    missing_image_link_alt_tags: 5,
    self_canonicalized_pages: 1,
    http_pages_with_hsts: 7,
    pages_without_canonical_tag: 9,
    broken_hreflang_links: 13,
    hreflang_link_non_200: 12,
    non_indexable_hreflang_links_in: 10,
    unsupported_hreflang_links: 16,
    all_hreflang_combinations: 7,
    all_hreflang_links: 4,
    first_pages: 2,
    redirection_loop: 13,
    redirect_chains: 10,
    backlink_pages_redirects: 16,
    redirecting_pages_with_traffic: 6,
    internal_redirects_found_in_web: 5,
    excessive_redirects_in: 3,
    //301_redirects: 9,
    meta_refresh_redirect: 11,
    //non_301_redirects 12,
    javascript_redirects: 14,
    canonical_to_non_200: 14,
    conflicting_canonical_tags: 16,
    non_indexable_canonical_links_in: 13,
    canonicalized_noindexed_pages: 12,
    unlinked_canonical_pages: 10,
    //pages_without_canonical_tag: 9,
    high_canonicals_in: 7,
    pages_with_hreflang_tags: 2,
    pages_without_hreflang_tags: 9,
    //malformed_urls: 14,
    max_url_length: 16,
    double_slash_urls: 11,
    broken_head_warning: 13,
    canonical_link_mismatch: 16,
    rendered_word_count_mismatch: 11,
    rendered_link_count_mismatch: 9,
    unique_internal_links: 2,
    //all_hreflang_links: 4,
    all_rel_links: 6,
    all_sitemaps_links: 8,
    unique_broken_links: 12,
    all_broken_links: 16,
    broken_links_external: 14,
    //canonical_to_non_200: 13,
    //broken_sitemap_links: 11,
    external_redirects: 11,
    max_external_links: 9,
    linked_domains: 7,
    unique_external_links: 5,
    xml_sitemaps: 1,
    sitemaps_broken_disallowed: 14,
    empty_sitemaps: 12,
    // sitemaps_non_indexable_links: 16,
    sitemaps_too_many_urls: 10,
    mobile_alternates_in_sitemaps: 7,
    backlink_pages_with_errors: 14,
    backlink_pages_disallowed_uncrawled: 13,
    backlink_pages_disallowed: 13,
    backlink_pages_non_indexable: 12,
    backlink_pages_no_links_out: 12,
    backlink_pages_with_nofollow: 10,
    pages_with_structured_data: 2,
    pages_without_structured_data: 10,
    pages_with_product_schema: 0,
    pages_with_event_schema: 1,
    pages_with_news_article_schema: 2,
    pages_with_breadcrumb_schema: 3,
    pages_with_faqpage_schema: 4,
    pages_with_howto_schema: 5,
    pages_with_recipe_schema: 6,
    pages_with_videoobject_schema: 7,
    pages_with_qapage_schema: 8,
    pages_with_review_schema: 15,
    pages_with_valid_product_schema: 1,
    pages_with_invalid_product_schema: 14,
    pages_with_valid_news_article_schema: 1,
    pages_with_invalid_news_article_schema: 14,
    all_web_crawl_urls: 2,
    pages_not_in_web_crawl: 10,
    all_crawled_urls_in_list: 2,
    pages_not_in_list: 10,
    all_cralwed_urls_with_backlinks: 2,
    missing_in_backlinks_crawl: 10,
    pages_in_log_summary: 2,
    pages_not_in_log_summary: 10,
    all_crawled_urls_in_xml_sitemaps: 2,
    missing_in_sitemaps: 10,
    pages_in_google_search_console: 2,
    pages_not_in_google_search_console: 10,
    all_crawled_organic_landing_pages: 2,
    missing_in_organic_landing_pages: 10,
    primary_pages_with_serp_clicks: 0,
    primary_pages_in_serp_without_clicks: 4,
    primary_pages_not_in_serp: 4,
    duplicate_pages_in_serp: 9,
    redirecting_pages_in_serp: 12,
    non_indexable_pages_in_serp: 16,
    error_pages_in_serp: 14,
    primary_pages_with_visits: 0,
    primary_pages_with_no_visits: 4,
    duplicate_pages_with_visits: 9,
    redirecting_pages_with_visits: 12,
    non_indexable_pages_with_visits: 16,
    error_pages_with_visits: 14,
    valid_amp_pages: 1,
    invalid_amp_pages: 14,
    non_200_separate_mobile_amp: 14,
    mobile_alternates_noindex: 16,
    non_reciprocal_mobile_amp_desktop: 11,
    amphtml_non_rel_alted: 9,
    separate_mobile_wordcount_mismatch: 8,
    separate_mobile_content_mismatch: 6,
    separate_mobile_links_out_mismatch: 5,
    separate_mobile_links_in_mismatch: 3,
    mobile_desktop_responsive: 0,
    mobile_desktop_dynamic: 2,
    mobile_desktop_separate_desktop: 3,
    mobile_desktop_separate_mobile: 5,
    mobile_desktop_amphtml: 7,
    no_mobile_configuration: 12,
    pages_with_high_bot_hits: 0,
    pages_with_medium_bot_hits: 1,
    pages_with_low_bot_hits: 2,
    pages_with_no_bot_hits: 12,
    primary_pages_with_bot_hits: 0,
    primary_pages_with_no_bot_hits: 6,
    duplicate_pages_with_bot_hits: 9,
    redirects_with_bot_hits: 16,
    error_pages_with_bot_hits: 14,
    disallowed_pages_with_bot_hits_uncrawled: 16,
    disallowed_pages_with_bot_hits: 16,
    non_indexable_pages_with_bot_hits: 12,
    desktop_pages_with_low_desktop_bot_hits: 10,
    mobile_alternates_with_low_mobile_bot_hits: 8,
    indexable_pages_with_no_bot_hits: 6,
    pages_in_sitemap_with_no_bot_hits: 4,
    fast_load_time: 1,
    medium_load_time: 11,
    max_load_time: 13,
    fast_server_response: 1,
    medium_server_response: 11,
    slow_server_response: 13,
    fast_fcp: 1,
    average_fcp: 11,
    slow_fcp: 13,
    fast_lcp: 1,
    average_lcp: 11,
    slow_lcp: 13,
    good_cls: 1,
    average_cls: 11,
    poor_cls: 13,
    fast_tti: 1,
    average_tti: 11,
    slow_tti: 13,
    fast_dcl: 1,
    average_dcl: 11,
    slow_dcl: 13,

    cumulative_layout_shift_fast: 1,
    cumulative_layout_shift_moderate: 11,
    cumulative_layout_shift_slow: 13,
    first_contentful_paint_fast: 1,
    first_contentful_paint_moderate: 11,
    first_contentful_paint_slow: 13,
    largest_contentful_paint_fast: 1,
    largest_contentful_paint_moderate: 11,
    largest_contentful_paint_slow: 13,
    total_blocking_time_fast: 1,
    total_blocking_time_moderate: 11,
    total_blocking_time_slow: 13,
    speed_index_fast: 1,
    speed_index_moderate: 11,
    speed_index_slow: 13,
    "site_speed_pages:first_contentful_paint_avg": 15,
    "site_speed_pages:largest_contentful_paint_avg": 15,
    "site_speed_pages:total_blocking_time_avg": 15,
    "site_speed_pages:cumulative_layout_shift_avg": 15,
    "site_speed_pages:speed_index_avg": 15,

    "mainthread_script_evaluation_items:wasted_secs_avg": 14,
    "mainthread_other_items:wasted_secs_avg": 12,
    "mainthread_style_layout_items:wasted_secs_avg": 10,
    "mainthread_script_parsing_items:wasted_secs_avg": 8,
    "mainthread_parse_html_css_items:wasted_secs_avg": 6,
    "mainthread_rendering_items:wasted_secs_avg": 4,
    "mainthread_garbage_collection_items:wasted_secs_avg": 2,

    "crux_domain_summary:crux_fcp_poor_weighted_avg": 13,
    "crux_domain_summary:crux_fcp_moderate_weighted_avg": 11,
    "crux_domain_summary:crux_fcp_good_weighted_avg": 1,
    "crux_domain_summary:crux_lcp_poor_weighted_avg": 13,
    "crux_domain_summary:crux_lcp_moderate_weighted_avg": 11,
    "crux_domain_summary:crux_lcp_good_weighted_avg": 1,
    "crux_domain_summary:crux_itnp_poor_weighted_avg": 13,
    "crux_domain_summary:crux_itnp_moderate_weighted_avg": 11,
    "crux_domain_summary:crux_itnp_good_weighted_avg": 1,
    "crux_domain_summary:crux_cls_poor_weighted_avg": 13,
    "crux_domain_summary:crux_cls_moderate_weighted_avg": 11,
    "crux_domain_summary:crux_cls_good_weighted_avg": 1,

    max_html_size: 12,
    orphaned_organic_landing_pages: 14,
    orphaned_google_search_console_pages: 13,
    orphaned_pages_with_backlinks: 16,
    orphaned_sitemaps_pages: 12,
    orphaned_log_summary_pages: 10,
    orphaned_list_pages: 8,
    indexable_orphaned_web_pages: 6,
    all_orphaned_pages: 4,
    extract_report: 2,
    extract_complement_report: 10,
    primary_pages_not_in_serp_with_no_backlinks: 14,
    primary_pages_not_in_serp_without_followed_links: 16,
    primary_pages_not_in_serp_with_low_deeprank: 12,
    primary_pages_not_in_serp_not_in_sitemaps: 9,
    primary_pages_with_no_backlinks: 6,
    duplicate_pages_with_backlinks: 9,
    poor_ux_pages_with_clicks: 14,
    http_scripts_on_https_pages: 12,
    links_between_protocols: 8,
    http_pages_with_clicks: 13,
    good_engagement: 0,
    moderate_engagement: 9,
    poor_engagement: 12,
    bad_engagement: 14,
    low_bounce_rate: 0,
    moderate_bounce_rate: 9,
    high_bounce_rate: 14,
    high_pageviews_per_session: 0,
    moderate_pageviews_per_session: 9,
    low_pageviews_per_session: 14,
    high_avg_time_on_page: 0,
    moderate_avg_time_on_page: 9,
    low_avg_time_on_page: 14,
    https_pages_with_hsts: 2,
    https_pages_without_hsts: 12,
    http_redirecting_to_https: 0,
    http_non_redirecting_to_https: 14,
    disallowed_css: 10,
    disallowed_css_uncrawled: 10,
    disallowed_js: 11,
    disallowed_js_uncrawled: 11,
    broken_image_urls: 14,
    redirecting_image_urls: 13,
    broken_css: 16,
    broken_js: 12,

    primary_pages_in_sitemaps: 0,
    not_in_sitemaps_primary_indexable: 6,
    duplicate_pages_in_sitemaps: 9,
    redirecting_pages_in_sitemaps: 16,
    sitemaps_non_indexable_links: 12,
    error_pages_in_sitemaps: 14,

    sitemaps_disallowed_malformed_links: 13,
    broken_sitemap_links: 14,
    // redirecting_pages_in_sitemaps: 16,
    // sitemaps_non_indexable_links: 12,

    // <a11y rule-id reports>
    area_alt_issues: 17,
    aria_allowed_attr_issues: 18,
    aria_command_name_issues: 18,
    aria_hidden_body_issues: 17,
    aria_hidden_focus_issues: 18,
    aria_input_field_name_issues: 18,
    aria_meter_name_issues: 18,
    aria_progressbar_name_issues: 18,
    aria_required_attr_issues: 17,
    aria_required_children_issues: 17,
    aria_required_parent_issues: 17,
    aria_roledescription_issues: 18,
    aria_roles_issues: 18,
    aria_toggle_field_name_issues: 18,
    aria_tooltip_name_issues: 18,
    aria_valid_attr_value_issues: 17,
    aria_valid_attr_issues: 17,
    audio_caption_issues: 17,
    blink_issues: 18,
    button_name_issues: 17,
    bypass_issues: 18,
    color_contrast_issues: 22,
    definition_list_issues: 18,
    dlitem_issues: 18,
    document_title_issues: 18,
    duplicate_id_active_issues: 18,
    duplicate_id_aria_issues: 17,
    duplicate_id_issues: 20,
    form_field_multiple_labels_issues: 19,
    frame_focusable_content_issues: 18,
    frame_title_issues: 18,
    html_has_lang_issues: 18,
    html_lang_valid_issues: 18,
    html_xml_lang_mismatch_issues: 19,
    image_alt_issues: 17,
    input_button_name_issues: 17,
    input_image_alt_issues: 17,
    label_issues: 17,
    link_name_issues: 18,
    list_issues: 18,
    listitem_issues: 18,
    marquee_issues: 18,
    meta_refresh_issues: 17,
    nested_interactive_issues: 18,
    object_alt_issues: 18,
    role_img_alt_issues: 18,
    scrollable_region_focusable_issues: 19,
    select_name_issues: 17,
    server_side_image_map_issues: 20,
    svg_img_alt_issues: 18,
    td_headers_attr_issues: 18,
    th_has_data_cells_issues: 18,
    valid_lang_issues: 22,
    video_caption_issues: 17,
    autocomplete_valid_issues: 22,
    avoid_inline_spacing_issues: 22,
    empty_table_header_issues: 32,
    accesskeys_issues: 30,
    aria_allowed_role_issues: 32,
    aria_dialog_name_issues: 30,
    aria_text_issues: 30,
    aria_treeitem_name_issues: 30,
    empty_heading_issues: 32,
    frame_tested_issues: 29,
    frame_title_unique_issues: 30,
    heading_order_issues: 31,
    image_redundant_alt_issues: 32,
    label_title_only_issues: 30,
    landmark_banner_is_top_level_issues: 31,
    landmark_complementary_is_top_level_issues: 31,
    landmark_contentinfo_is_top_level_issues: 31,
    landmark_main_is_top_level_issues: 31,
    landmark_no_duplicate_banner_issues: 31,
    landmark_no_duplicate_contentinfo_issues: 31,
    landmark_no_duplicate_main_issues: 31,
    landmark_one_main_issues: 31,
    landmark_unique_issues: 31,
    meta_viewport_large_issues: 32,
    meta_viewport_issues: 22,
    target_size_issues: 24,
    page_has_heading_one_issues: 31,
    presentation_role_conflict_issues: 32,
    region_issues: 31,
    scope_attr_valid_issues: 29,
    skip_link_issues: 31,
    tabindex_issues: 30,
    table_duplicate_name_issues: 32,
    color_contrast_enhanced_issues: 26,
    identical_links_same_purpose_issues: 28,
    css_orientation_lock_issues: 30,
    focus_order_semantics_issues: 32,
    hidden_content_issues: 32,
    label_content_name_mismatch_issues: 18,
    link_in_text_block_issues: 18,
    no_autoplay_audio_issues: 19,
    p_as_heading_issues: 18,
    table_fake_caption_issues: 18,
    td_has_header_issues: 17,

    // <a11y wcag level reports>
    wcag_a_issue_instances: 19,
    wcag_aa_issue_instances: 23,
    wcag_aaa_issue_instances: 27,
    best_practices_issue_instances: 31,
    wcag_a_issues: 17,
    wcag_aa_issues: 21,
    wcag_aaa_issues: 25,
    best_practices_issues: 29,
    accessibility_issues: 21,
    accessibility_issue_instances: 23,

    // top
    resolved_issues: 29,
    needs_review_issues: 32,

    // <a11y severity reports>
    critical_issues: 17,
    serious_issues: 18,
    moderate_issues: 19,
    minor_issues: 20,

    // <a11y category reports>
    aria_a: 19,
    forms_a: 19,
    keyboard_a: 19,
    language_a: 19,
    name_role_value_a: 19,
    parsing_a: 19,
    structure_a: 19,
    tables_a: 19,
    text_alternatives_a: 19,
    time_and_media_a: 19,
    semantics_a: 19,
    color: 18,
    forms: 22,
    language: 22,
    structure: 22,
    semantics: 26,

    // <a11y crawl_urls reports>
    pages_with_a_issues: 17,
    pages_with_a_or_aa_issues: 19,
    pages_with_a_or_aa_or_aaa_issues: 22,
    pages_without_a_issues: 4,
    pages_without_a_and_aa_issues: 2,
    pages_without_a_and_aa_and_aaa_issues: 0,

    // Changed metrics reports
    pages_with_changed_title: 4,
    pages_with_changed_description: 6,
    pages_with_changed_status_code: 8,
    pages_with_changed_level: 20,
    pages_with_changed_h1_tags: 16,

    // SiteSpeed report colours
    unsized_images_failed_items: 14,
    non_composited_animations_failed_items: 14,
    layout_shift_elements_failed_items: 14,
    non_composited_animations_failed_audits: 12,
    layout_shift_elements_failed_audits: 12,
    unsized_images_failed_audits: 12,

    no_document_write_failed_audits: 12,
    unminified_javascript_failed_audits: 12,
    render_blocking_resources_failed_audits: 12,
    uses_http2_failed_audits: 12,
    uses_long_cache_ttl_failed_audits: 12,
    server_response_time_failed_audits: 12,
    unminified_css_failed_audits: 12,
    uses_text_compression_failed_audits: 12,
    bf_cache_failed_audits: 12,
    font_display_failed_audits: 12,
    uses_rel_preload_failed_audits: 12,
    uses_rel_preconnect_failed_audits: 12,
    critical_request_chains_failed_audits: 12,
    redirects_failed_audits: 12,

    unused_javascript_failed_audits: 12,
    uses_responsive_images_failed_audits: 12,
    efficient_animated_content_failed_audits: 12,
    lcp_lazy_loaded_failed_audits: 12,
    modern_image_formats_failed_audits: 12,
    total_byte_weight_failed_audits: 12,
    prioritize_lcp_image_failed_audits: 12,
    offscreen_images_failed_audits: 12,
    unused_css_rules_failed_audits: 12,
    uses_optimized_images_failed_audits: 12,
    largest_contentful_paint_element_failed_audits: 12,

    bootup_time_failed_audits: 12,
    dom_size_failed_audits: 12,
    third_party_summary_failed_audits: 12,
    viewport_failed_audits: 12,
    legacy_javascript_failed_audits: 12,
    mainthread_work_breakdown_failed_audits: 12,
    third_party_facades_failed_audits: 12,
    duplicated_javascript_failed_audits: 12,
    long_tasks_failed_audits: 12,

    container_execution_failures: 14,
    site_speed_pages: 1,
  },
  categoryReportColor: {
    "": {},
    summary: {
      all_web_crawl_urls: 1,
      all_crawled_urls_in_list: 4,
      all_cralwed_urls_with_backlinks: 6,
      pages_in_log_summary: 8,
      all_crawled_urls_in_xml_sitemaps: 10,
      pages_in_google_search_console: 12,
      all_crawled_organic_landing_pages: 2,
    },
    accessibility_a: {
      aria_a: 17,
      forms_a: 18,
      keyboard_a: 19,
      language_a: 21,
      name_role_value_a: 22,
      parsing_a: 23,
      structure_a: 25,
      tables_a: 26,
      text_alternatives_a: 27,
      time_and_media_a: 29,
      semantics_a: 30,
    },
    source_summary: {
      pages_not_in_web_crawl: 12,
      missing_in_backlinks_crawl: 10,
      missing_in_organic_landing_pages: 8,
      pages_not_in_log_summary: 6,
      missing_in_sitemaps: 5,
      pages_not_in_list: 3,
    },
    user_experience: {
      all_broken_links: 12,
      broken_links_external: 16,
      broken_image_urls: 13,
      empty_pages: 14,
    },
    noindex: {
      non_indexable_pages_with_visits: 16,
      non_indexable_pages_in_serp: 14,
      backlink_pages_non_indexable: 13,
      sitemaps_non_indexable_links: 12,
      non_indexable_pages_with_bot_hits: 10,
    },
    internationalization: {
      broken_hreflang_links: 17,
      hreflang_link_non_200: 23,
      invalid_hreflang_attributes: 19,
      hreflang_and_page_language_conflict: 24,
      non_indexable_hreflang_links_in: 12,
      pages_with_multiple_hreflang_langs_in: 25,
      unsupported_hreflang_links: 28,
    },
    uniqueness: {
      duplicate_pages: 14,
      pages_with_duplicate_titles: 16,
      pages_with_duplicate_descriptions: 9,
      duplicate_body_content: 13,
      duplicate_urls: 12,
      duplicate_pages_2: 14,
      duplicate_titles_2: 16,
      duplicate_descriptions_2: 9,
      duplicate_content_2: 13,
      duplicate_url_sets: 12,
    },
  },
};
