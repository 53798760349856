import {
  InformationCircleOutlined,
  TabSpinner,
  useDateFormatter,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Tab, Tabs, Tooltip } from "@material-ui/core";
import { useURLSearchParams } from "../../../../_common/routing/useURLSearchParams";
import { ReportTypeCode } from "../../../../graphql";
import { ReportInput } from "../../../Report.types";
import { ReportData, ReportDiffs } from "../rows/ReportGridRows.types";
import { mapStringToReportTypeCode } from "../../../../custom-report/_common/CustomReportHelpers";
import { useCrawlContextData } from "../../../../crawl-overview/CrawlContext";

const useStyles = makeStyles((theme) => ({
  conatainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  tabs: { flexGrow: 1, height: 47 },
  label: { display: "flex", flexDirection: "row", alignItems: "center" },
  root: {
    height: 47,
    padding: "6px 20px",
    color: theme.palette.grey[900],
    "&:hover": { color: theme.palette.primary.main },
  },
  totalRows: { fontSize: 12, marginLeft: 4 },
  icon: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(22),
    margin: theme.spacing(0, 0.375, 0.5, 0.5),
  },
  content: {
    flexShrink: 0,
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
    paddingBottom: theme.spacing(0.875),
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
}));

type CustomTabMode = {
  code: string;
  label: string;
  description?: ((comparedToCrawlDate: Date) => string) | string;
  value: number | undefined;
  isSelected?: boolean;
}[];

export type ReportGridTabsMode =
  | "visible"
  | "hidden"
  | "onlyTotalRowsVisible"
  | CustomTabMode;

export function ReportGridTabs(props: {
  mode: ReportGridTabsMode | CustomTabMode;
  diffs: ReportDiffs;
  reportInput: ReportInput;
  totalRows?: number;
  loading?: boolean;
  children?: React.ReactNode;
  onTabChange?: (value: string) => void;
}): JSX.Element | null {
  const searchParams = useURLSearchParams();
  const classes = useStyles();
  const { t } = useTranslation("report");
  const formatter = useNumberFormatter();
  const dateFormatter = useDateFormatter();
  const {
    crawl: { comparedTo },
  } = useCrawlContextData();

  const tab = isCustomTabMode(props.mode)
    ? (props.mode.find((x) => x.isSelected)?.code ?? props.mode[0]?.code)
    : props.reportInput.reportTypeCode.toLowerCase();

  const date = new Date(comparedTo?.createdAt ?? new Date());
  function getDiffReport(code: ReportTypeCode): {
    label?: string;
    description?: string;
    position: number;
  } {
    switch (code) {
      case ReportTypeCode.Basic:
        return {
          label: t("reportGridTabsLabels.all"),
          position: 1,
        };
      case ReportTypeCode.Added:
        return {
          label: t("reportGridTabsLabels.added"),
          description: t("reportGridTabsLabels.addedTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 2,
        };
      case ReportTypeCode.Removed:
        return {
          label: t("reportGridTabsLabels.removed"),
          description: t("reportGridTabsLabels.removeTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 3,
        };
      case ReportTypeCode.Missing:
        return {
          label: t("reportGridTabsLabels.missing"),
          description: t("reportGridTabsLabels.missingTooltipText", {
            date: dateFormatter(date, {
              dateStyle: "medium",
              timeStyle: "short",
            }),
          }),
          position: 4,
        };
      default:
        return {
          position: 5,
        };
    }
  }

  type DiffKeys = keyof ReportData["diffs"];
  const tabs = isCustomTabMode(props.mode)
    ? props.mode.map((item) => ({
        label: item.label,
        description: item.description,
        totalRows: item.value,
        value: item.code,
        loading:
          item.value === undefined || (item.code === tab && props.loading),
      }))
    : Object.keys(props.diffs)
        .map((diffKey) => {
          const tabProps = getDiffReport(mapStringToReportTypeCode(diffKey));
          return {
            label: tabProps.label,
            description: tabProps.description,
            totalRows: props.diffs[diffKey as DiffKeys],
            value: diffKey,
            position: tabProps.position,
            reportTypeCode: mapStringToReportTypeCode(diffKey),
            loading: props.loading,
          };
        })
        .filter(
          (x) =>
            props.mode !== "onlyTotalRowsVisible" ||
            x.reportTypeCode === ReportTypeCode.Basic,
        )
        .toSorted((a, b) => a.position - b.position);

  if (props.mode === "hidden") {
    return null;
  }

  return (
    <div className={classes.conatainer}>
      <Tabs
        value={tab || ""}
        onChange={(_, value) => props.onTabChange?.(value)}
        variant="standard"
        indicatorColor="primary"
        className={classes.tabs}
      >
        {tabs.map(({ label, value, totalRows, description, loading }) => {
          const showTotal = tab === value && searchParams.has("filter");

          return (
            <Tab
              key={value}
              value={value}
              disabled={!tabs.length}
              classes={{ root: classes.root }}
              label={
                <div className={classes.label}>
                  <span>{label}</span>
                  {loading ? (
                    <TabSpinner />
                  ) : (
                    <span className={classes.totalRows}>
                      ({formatter(totalRows === -1 ? 0 : totalRows)}
                      {showTotal && ` of ${formatter(props.totalRows)}`})
                    </span>
                  )}
                  {description ? (
                    <Tooltip title={description} arrow placement="top">
                      <InformationCircleOutlined className={classes.icon} />
                    </Tooltip>
                  ) : null}
                </div>
              }
              data-testid={`report-grid-tab-${value}`}
            />
          );
        })}
      </Tabs>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}

function isCustomTabMode(value: ReportGridTabsMode): value is CustomTabMode {
  return typeof value === "object";
}
