import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  outerWrapper: {
    flexGrow: 1,
    alignSelf: "stretch",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerWrapper: (props: Props) => ({
    maxWidth: props.maxWidth ?? 450,
  }),
});

interface Props {
  children: ReactNode;
  maxWidth?: number | string;
}

export function ChartPanelMessageWrapper(props: Props): JSX.Element {
  const classes = useStyles(props);

  return (
    <div className={classes.outerWrapper} data-testid="error-message">
      <div className={classes.innerWrapper}>{props.children}</div>
    </div>
  );
}
