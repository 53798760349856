export function getReportStatGUID(reportStat: {
  crawlId: string;
  segmentId?: undefined | null | string;
  reportTemplateCode: string;
}): string {
  return `ReportStat_${reportStat.crawlId}:${reportStat.segmentId ?? null}:${reportStat.reportTemplateCode}`;
}

export function isReportStatGUID(input: string): boolean {
  return input.startsWith("ReportStat_");
}
