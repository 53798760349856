import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import arePropsEqual from "react-fast-compare";
import { MetricsValuePresenter } from "../../../../../resource-detail/metrics-value-presenter";
import { StringInterpreter } from "./StringInterpreter";
import { ArrayInterpreter } from "./ArrayInterpreter";
import { RedirectChainInterpreter } from "./RedirectChainInterpreter";
import { AccessibilityIssuesCountByRuleIdInterpreter } from "./AccessibilityIssuesCountByRuleIdInterpreter";
import {
  Metrics,
  MetricsValuePresenterProps,
} from "../../../../../resource-detail/data/types";
import {
  metricPresenters as defaultMetricPresenters,
  typePresenters as defaultTypePresenters,
} from "../../../../../resource-detail/metrics-value-presenter";
import { JSONPresenter } from "../../../../../resource-detail/metrics-value-presenter/default-presenters/JSONPresenter";
import { ChangedFromInterpreter } from "./ChangedFromInterpreter";
import { MetricType } from "../../../../../graphql";

type PresenterResult = {
  metric: string;
  type?: string;
  component: React.ElementType<MetricsValuePresenterProps>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 400,
    whiteSpace: "nowrap",
    maxHeight: "100%",
    maxWidth: "100%",
    width: "100%",
    height: (props: { presenterType?: string }) =>
      props.presenterType === "JSON" ? "100%" : "auto",
  },
}));

export const IndexInterpreter = React.memo(
  IndexInterpreterInner,
  arePropsEqual,
);

function IndexInterpreterInner(
  props: GridRenderCellParams & {
    code: string;
    isGridView: boolean;
    isCard?: boolean;
    containerWidth?: number;
    reportTemplateCode?: string;
  },
): JSX.Element {
  const presenter = getPresenter(props.code, props.row.metrics);
  const classes = useStyles({ presenterType: presenter?.type });

  return (
    <MetricsValuePresenter
      code={props.code}
      metrics={props.row.metrics}
      presenter={presenter}
      componentProps={{
        isGridView: props.isGridView,
        isCard: props.isCard,
        disableWrap: props.isGridView,
        containerWidth: props.containerWidth,
        reportTemplateCode: props.reportTemplateCode,
      }}
      className={classes.root}
      data-testid={`report-grid-cell-${props.code.toLowerCase()}`}
    />
  );
}

function getPresenter(
  code: string,
  metrics: Metrics,
): PresenterResult | undefined {
  const metricPresenters: typeof defaultMetricPresenters = {
    ...defaultMetricPresenters,
    elementSource: null,
    redirectChain: RedirectChainInterpreter,
    accessibilityIssuesCountByRuleId:
      AccessibilityIssuesCountByRuleIdInterpreter,
  };

  const metricPresenter = metricPresenters[code];
  const type = metrics[code]?.data?.type;
  if (metricPresenter) {
    return {
      metric: code,
      type,
      component: metricPresenter,
    };
  }

  const metadataPresenter = getMetadataPresenter(code, metrics);
  if (metadataPresenter) {
    return metadataPresenter;
  }

  const isChangedFromMetric = Boolean(metrics[code]?.data?.changedMetricCode);
  if (isChangedFromMetric) {
    return {
      metric: code,
      type,
      component: ChangedFromInterpreter,
    };
  }

  const typePresenters: typeof defaultTypePresenters = {
    ...defaultTypePresenters,
    Array: ArrayInterpreter,
    String: StringInterpreter,
  };

  const typePresenter = type && typePresenters[type];
  if (typePresenter) {
    return {
      metric: code,
      type,
      component: typePresenter,
    };
  }

  return undefined;
}

export const getMetadataPresenter = (
  code: string,
  metrics: Metrics,
): PresenterResult | undefined => {
  const isJSONList =
    metrics[code]?.data?.type === MetricType.Array &&
    (metrics[code]?.data?.metadata?.items?.type?.toLowerCase() === "json" ||
      metrics[code]?.data?.metadata?.items?.type?.toLowerCase() === "object");
  if (isJSONList) {
    return {
      metric: code,
      type: "JSON",
      component: JSONPresenter,
    };
  }
};
