import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { getReportPriority } from "../../../_common/report-helpers/reportPriority";
import { PriorityBadge } from "../../../_common/report-helpers/ReportPriorityBadge";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";
import {
  getCustomReportTemplateId,
  isCustomReportGUID,
} from "../../../_common/report-helpers/customReport";

export function PriorityCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  const isCustomReport = isCustomReportGUID(report.id);

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: getCustomReportTemplateId(report.id),
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
      data-testid="all-reports-description-name"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PriorityBadge
          priority={getReportPriority(report.reportTemplateTotalWeight)}
          style={{ cursor: "unset" }}
        />
      </div>
    </ReportLink>
  );
}
