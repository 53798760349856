import clsx from "clsx";

import { useTranslation, Chip, ChipColor } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ReportPriority } from "./reportPriority";

const useStyles = makeStyles((theme) => ({
  badge: {
    padding: theme.spacing(0.5, 1),
  },
}));

export function PriorityBadge({
  priority,
  className,
  style,
}: {
  priority: ReportPriority;
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const [label, color] = ((): [string, ChipColor] => {
    switch (priority) {
      case ReportPriority.None:
        return [t("priorityNone"), "white"];
      case ReportPriority.Low:
        return [t("priorityLow"), "grey"];
      case ReportPriority.Medium:
        return [t("priorityMedium"), "yellow"];
      case ReportPriority.High:
        return [t("priorityHigh"), "orange"];
      case ReportPriority.Critical:
        return [t("priorityCritical"), "red"];
    }
  })();

  return (
    <Chip
      label={label}
      color={color}
      className={clsx(classes.badge, className)}
      style={style}
      data-testid="report-info-priority"
    />
  );
}
