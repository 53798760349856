import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNumberFormatter } from "../../../../../i18n";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: 32,
        paddingRight: 32,
        height: "100%",
    },
    line: {
        stroke: theme.palette.grey[400],
    },
    lineHover: {
        stroke: theme.palette.blue[600],
    },
    title: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    titleHover: {
        color: theme.palette.blue[600],
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    count: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginTop: 4,
        color: theme.palette.grey[700],
    },
    countHover: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginTop: 4,
        color: theme.palette.blue[600],
    },
}));
export const PassBarsLegend = ({ elementWidth, isHovered, data, lineMiddlePoint, x, }) => {
    const classes = useStyles();
    const formatter = useNumberFormatter();
    return (_jsxs("g", { transform: `translate(${x}, 420)`, "data-pendo": `url-funnel-${data.passReportTemplateCode}`, children: [_jsx("foreignObject", { width: elementWidth, height: 120, children: _jsxs("div", { className: classes.container, children: [_jsx("span", { className: isHovered ? classes.titleHover : classes.title, children: data.passTitle }), _jsx("span", { className: isHovered ? classes.countHover : classes.count, children: formatter(data.pass, { notation: "compact" }) })] }) }), _jsx("line", { x1: lineMiddlePoint, y1: "0", x2: lineMiddlePoint, y2: "50", strokeDasharray: "4 2", className: isHovered ? classes.lineHover : classes.line }), _jsx("line", { x1: lineMiddlePoint, y1: "50", x2: lineMiddlePoint - 16, y2: "50", strokeDasharray: "4 2", className: isHovered ? classes.lineHover : classes.line })] }));
};
