/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getPageIssuesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("pageIssues.title"),
    description: () => t("pageIssues.description"),
    reportTemplateCodesOrder: [
      "missing_h1_tags",
      "multiple_h1_tag_pages",
      "noarchive_pages",
      "meta_nofollow_pages",
      "max_links",
    ],
    reportStatFilter: (report) => {
      return [
        "missing_h1_tags",
        "multiple_h1_tag_pages",
        "noarchive_pages",
        "meta_nofollow_pages",
        "max_links",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
