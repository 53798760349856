/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  isArray,
  isBoolean,
  isNull,
  isNumber,
  isObject,
  isString,
} from "lodash";
import { ArrayPresenter } from "./ArrayPresenter";
import { BooleanPresenter } from "./BooleanPresenter";
import { NullPresenter } from "./NullPresenter";
import { NumberPresenter } from "./NumberPresenter";
import { ObjectPresenter } from "./ObjectPresenter";
import { StringPresenter } from "./StringPresenter";

type Presenter = (value: any) => JSX.Element;

function usePresenter(value: any): Presenter | undefined {
  switch (true) {
    case isNumber(value):
      return NumberPresenter;
    case isBoolean(value):
      return BooleanPresenter;
    case isArray(value):
      return ArrayPresenter;
    case isString(value):
      return StringPresenter;
    case isObject(value):
      return ObjectPresenter;
    case isNull(value):
      return NullPresenter;
  }
}

export function IndexPresenter(props: {
  value: any;
  chipClassName?: string;
}): JSX.Element {
  const Presenter = usePresenter(props.value);
  return (
    <>
      {Presenter ? (
        <Presenter
          value={props.value}
          chipClassName={props.chipClassName}
        ></Presenter>
      ) : null}
    </>
  );
}
