import { FieldProps } from "formik";
import { FormValues } from "../data/types";
import { MenuItem, makeStyles } from "@material-ui/core";
import {
  Select,
  Typography,
  formToCheckboxProps,
  formToSelectProps,
  useTranslation,
} from "@lumar/shared";
import { CheckboxWithLabel } from "../../components/CheckboxWithLabel";
import { WcagLevel } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  select: {
    maxWidth: "400px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1.625),
    marginBottom: theme.spacing(1),
  },
}));

export function WcagLevelAndVersionSettings({
  form,
}: FieldProps<FormValues["wcagLevelAndVersion"]>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  return (
    <div className={classes.root}>
      <Typography className={classes.description}>
        {t("wcagLevelAndVersionDescription")}
      </Typography>

      <Select
        {...formToSelectProps(form, "wcagLevelAndVersion.wcagLevel")}
        label={t("wcagLevelLabel")}
        className={classes.select}
      >
        <MenuItem value={WcagLevel.A}>{WcagLevel.A}</MenuItem>
        <MenuItem value={WcagLevel.Aa}>{WcagLevel.Aa}</MenuItem>
        <MenuItem value={WcagLevel.Aaa}>
          {t("wcagLevelAndVersionRecommended", { value: WcagLevel.Aaa })}
        </MenuItem>
      </Select>

      <Select
        {...formToSelectProps(form, "wcagLevelAndVersion.wcagVersion")}
        label={t("wcagVersionLabel")}
        className={classes.select}
      >
        <MenuItem value={2.0}>2.0</MenuItem>
        <MenuItem value={2.1}>2.1</MenuItem>
        <MenuItem value={2.2}>
          {t("wcagLevelAndVersionRecommended", { value: 2.2 })}
        </MenuItem>
      </Select>

      <CheckboxWithLabel
        {...formToCheckboxProps(
          form,
          "wcagLevelAndVersion.includeBestPractices",
        )}
        label={t("includeBestPracticeLabel")}
        className={classes.checkbox}
      />
    </div>
  );
}
