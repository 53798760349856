import { ReportCategoryChartConfig } from "../types";
import { getExternalLinkBreakdownChart } from "../../../../_common/charts/config/getExternalLinkBreakdownChart";
import { getExternalLinksTrend } from "../../../../_common/charts/config/getExternalLinksTrend";

export const generateExperienceExternalLinksCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getExternalLinkBreakdownChart,
      getExternalLinksTrend,
    ],
  };
