/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getCrawlabilityChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("crawlabilityChart.title"),
    description: () => t("crawlabilityChart.description"),
    reportTemplateCodesOrder: [
      "primary_pages_in_serp",
      "primary_pages_not_in_serp",
    ],
    reportStatFilter: (report) => {
      return ["primary_pages_in_serp", "primary_pages_not_in_serp"].includes(
        report.reportTemplateCode,
      );
    },
    showPercentageInSeriesTooltip: true,
    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
