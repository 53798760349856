import { moduleIconConfig, useTranslation } from "@lumar/shared";
import { ModuleCode } from "../../graphql";
import { ProjectsGridRenderCellParams } from "../data/types";
import { Tooltip, useTheme } from "@material-ui/core";

export function ProjectTypeCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const theme = useTheme();
  const { t } = useTranslation("projectsList");

  const title = (() => {
    switch (row.moduleCode) {
      case ModuleCode.Basic:
        return t("modules.basic");
      case ModuleCode.Seo:
        return t("modules.seo");
      case ModuleCode.Accessibility:
        return t("modules.accessibility");
      case ModuleCode.SiteSpeed:
        return t("modules.siteSpeed");
      default:
        return "";
    }
  })();

  const Icon = row.moduleCode && moduleIconConfig.get(row.moduleCode);

  if (!Icon) return null;

  return (
    <Tooltip title={title}>
      <div style={{ padding: theme.spacing(1), lineHeight: "normal" }}>
        <Icon
          style={{
            color: theme.palette.grey[700],
            fontSize: theme.typography.pxToRem(20),
          }}
        />
      </div>
    </Tooltip>
  );
}
