import React, { useContext } from "react";
import { MenuItem } from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Select,
  updateIfPropsChanged,
  TimeZoneContext,
  useDateFormatter,
} from "@lumar/shared";

import { translationNamespace } from "../CrawlSettings";
import { SettingsContext } from "./data/useContextValues";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    minWidth: 400,
    marginBottom: theme.spacing(3),
  },
}));

export function SettingsBasic(): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const { timeZone } = React.useContext(TimeZoneContext);
  const dateFormatter = useDateFormatter();

  const classes = useStyles();

  const contextValues = useContext(SettingsContext);

  const compareToOptions = [
    ...contextValues.finishedCrawls.map((crawl, idx) => ({
      value: idx === 0 ? "LastCrawl" : crawl.id,
      label:
        idx === 0
          ? t("settings.compareResultToLast")
          : t("settings.compareResult", {
              crawlDate: dateFormatter(crawl.createdAt, {
                dateStyle: "medium",
                timeStyle: "short",
              }),
            }),
    })),
    {
      value: "None",
      label: t("settings.dontCompare"),
    },
  ];

  const hasCrawls = contextValues.finishedCrawls.length > 0;

  return (
    <>
      {hasCrawls && (
        <FastField
          name="compareToCrawl"
          timeZone={timeZone}
          shouldUpdate={updateIfPropsChanged("timeZone")}
        >
          {({
            field: { name, value },
            form: { setFieldValue, isSubmitting },
          }: FastFieldProps<string>) => (
            <Select
              value={value}
              label={t("settings.compareTo")}
              onChange={(e) => setFieldValue(name, e.target.value)}
              disabled={isSubmitting}
              className={classes.input}
              data-testid="select-compare-crawl"
            >
              {compareToOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </FastField>
      )}
    </>
  );
}
