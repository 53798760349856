import { Project, UpdateProjectInput } from "../../../../../graphql";
import { ApiCallbackSettings } from "../types";
import { convertString } from "./helpers";

type QueryData = Pick<Project, "apiCallbackUrl" | "apiCallbackHeaders">;

export function formatApiCallbackSettingsFrom(
  data?: QueryData | null,
): ApiCallbackSettings {
  return {
    url: data?.apiCallbackUrl ?? "",
    headers: data?.apiCallbackHeaders ?? [],
  };
}

export function formatApiCallbackSettingsTo(
  formValues: ApiCallbackSettings,
): Pick<UpdateProjectInput, "apiCallbackUrl" | "apiCallbackHeaders"> {
  return {
    apiCallbackUrl: convertString(formValues.url),
    apiCallbackHeaders: formValues.headers.map((header) => ({
      key: header.key,
      value: header.value,
    })),
  };
}
