import { jsx as _jsx } from "react/jsx-runtime";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "flex-end",
    },
    percentageContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        paddingTop: 4,
        paddingBottom: 4,
        marginRight: 4,
    },
    percentageText: {
        color: theme.palette.blue[600],
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(13),
        fontFamily: theme.typography.fontFamily,
    },
}));
export const PassPercentage = ({ elementWidth, barWidth, x, y, value, }) => {
    const classes = useStyles();
    return (_jsx("g", { transform: `translate(${x}, ${y > 390 ? 390 : y})`, children: _jsx("foreignObject", { width: elementWidth, height: 40, children: _jsx(Box, { className: classes.container, children: _jsx(Box, { className: classes.percentageContainer, width: barWidth - 8, children: _jsx("span", { className: classes.percentageText, children: value > 1 ? `${value}%` : "<1%" }) }) }) }) }));
};
