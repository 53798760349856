import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedTBTChart } from "../../../../../_common/charts/config/getSiteSpeedTBTChart";
import { getSiteSpeedTBTTrend } from "../../../../../_common/charts/config/getSiteSpeedTBTTrend";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAvgTBTChart } from "../../../../../_common/charts/config/getSiteSpeedAvgTBTChart";
import { getSiteSpeedMainthreadChart } from "../../../../../_common/charts/config/getSiteSpeedMainthreadChart";
import { getSiteSpeedMainthreadTrend } from "../../../../../_common/charts/config/getSiteSpeedMainthreadTrend";
import { getSiteSpeedAllReportsTable } from "../../../../../_common/charts/config/getSiteSpeedAllReportsTable";
import { getSiteSpeedAvgCruxITNPChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxITNPChart";
import { getSiteSpeedCruxITNPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxITNPTrend";

export const generateSiteSpeedTotalBlockingTimeCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    allReportsTable: getSiteSpeedAllReportsTable,
    mainCharts: [
      getSiteSpeedAvgTBTChart,
      getSiteSpeedAvgCruxITNPChart,
      getSiteSpeedTBTTrend,
      getSiteSpeedCruxITNPTrend,
      getSiteSpeedMainthreadChart,
      getSiteSpeedMainthreadTrend,
      getSiteSpeedTBTChart,
    ],
  };
