/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getBotHitsByDeviceChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.LogSummary],
      incompleteMessage: t("requiredSourcesMessage.logsSource"),
    },
    title: () => t("botHitsByDevice.title"),
    description: () => t("botHitsByDevice.description"),
    reportTemplateCodesOrder: [
      "pages_with_desktop_bot_hits",
      "pages_with_mobile_bot_hits",
    ],
    reportStatFilter: (report) => {
      return [
        "pages_with_desktop_bot_hits",
        "pages_with_mobile_bot_hits",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
