import { getProperty, TagInput, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import { Label } from "./Label";
import { AssigneeList } from "../assignee-list/AssigneeList";

export function AssigneeField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  return (
    <Field name="assignedTo">
      {(props: FieldProps<string[]>) =>
        !isEditing ? (
          <>
            <Label label={t("editDialog.assignee")} />
            <div className={classes.assigneeContainer}>
              {props.field.value.length ? (
                <AssigneeList assignees={props.field.value} />
              ) : (
                "-"
              )}
            </div>
          </>
        ) : (
          <TagInput
            id="edit-task-assignee"
            {...fieldToTagInput(props)}
            variant="outlined"
            addOnComma
            addOnSpace
            disabled={props.form.isSubmitting}
            className={classes.input}
            label={t("editDialog.assignee")}
            placeholder={t("editDialog.assigneeEmailPlaceholder")}
            data-testid="edit-task-assignee-email"
            tagProps={{
              "data-testid": "edit-task-assignee-item",
            }}
          />
        )
      }
    </Field>
  );
}

function fieldToTagInput(
  props: FieldProps<string[], unknown>,
): Partial<Parameters<typeof TagInput>[0]> {
  const fieldErrors: string[] | undefined = getProperty(
    props.form.errors,
    props.field.name,
  );

  return {
    value: props.field.value,
    onChange: (value) => props.form.setFieldValue(props.field.name, value),
    error: fieldErrors?.find(Boolean),
    errors: fieldErrors?.map((error) => Boolean(error)),
  };
}

const useStyles = makeStyles((theme) => ({
  assigneeContainer: {
    margin: theme.spacing(0.75, 0, 1, 0),
  },
  input: {
    width: "100%",
    margin: theme.spacing(0, 0, 1, 0),
    "& label": {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
    },
  },
}));
