var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, Grid, useTheme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Check } from "@material-ui/icons";
import { TextField } from "../text-field/TextField";
const useStyles = makeStyles((theme) => ({
    popupIndicator: {
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        borderColor: theme.palette.grey[300],
        borderRadius: 0,
        paddingTop: 6,
        paddingBottom: 6,
        minWidth: 37,
        marginLeft: 8,
        marginRight: -7,
    },
    popupIndicatorOpen: {
        borderLeftWidth: 0,
        borderRightWidth: 1,
        borderRightStyle: "solid",
    },
    endAdornment: (props) => ({
        top: props.freeSolo ? 3 : "unset",
        right: 5,
    }),
}));
const PADDING_DEFAULT = 10;
const PADDING_DROPDOWN = 32;
const PADDING_CLEARABLE = 30;
export function ComboBox(_a) {
    var { inputProps, readOnly, classes: providedClasses } = _a, props = __rest(_a, ["inputProps", "readOnly", "classes"]);
    const classes = useStyles({
        freeSolo: props.freeSolo,
    });
    const theme = useTheme();
    function getInputPaddingRight() {
        if (readOnly)
            return PADDING_DEFAULT;
        return (PADDING_DEFAULT +
            (props.freeSolo ? 0 : PADDING_DROPDOWN) +
            (props.disableClearable ? 0 : PADDING_CLEARABLE));
    }
    return (_jsx(Autocomplete, Object.assign({ renderOption: (option, state) => {
            return (_jsxs(Grid, { container: true, style: { minHeight: 21 }, children: [_jsx(Grid, { item: true, xs: 10, style: {
                            display: "flex",
                            alignItems: "center",
                            lineHeight: "16.8px",
                            color: theme.palette.grey[700],
                        }, children: props.getOptionLabel ? props.getOptionLabel(option) : option }), state.selected ? (_jsx(Grid, { item: true, xs: 2, container: true, justifyContent: "flex-end", alignContent: "center", children: _jsx(Check, { style: { fontSize: 14 } }) })) : null] }));
        }, classes: Object.assign(Object.assign({}, providedClasses), classes), renderInput: (params) => {
            var _a;
            return (_jsx(TextField, Object.assign({}, params, inputProps, { inputProps: Object.assign(Object.assign({ readOnly: readOnly }, params.inputProps), inputProps === null || inputProps === void 0 ? void 0 : inputProps.inputProps), InputProps: Object.assign(Object.assign(Object.assign({}, params.InputProps), { endAdornment: readOnly ? undefined : params.InputProps.endAdornment, style: Object.assign({ paddingRight: getInputPaddingRight() }, (_a = inputProps === null || inputProps === void 0 ? void 0 : inputProps.InputProps) === null || _a === void 0 ? void 0 : _a.style) }), inputProps === null || inputProps === void 0 ? void 0 : inputProps.InputProps), InputLabelProps: Object.assign(Object.assign(Object.assign({}, params.InputLabelProps), inputProps === null || inputProps === void 0 ? void 0 : inputProps.InputLabelProps), { shrink: false }) })));
        } }, props, (readOnly ? { open: false } : undefined))));
}
