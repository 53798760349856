import { Link } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { useCrawlContextData } from "../../CrawlContext";
import React from "react";
import { ReportEntity, ReportInput } from "../../../report/Report.types";

interface ReportLinkProps extends Omit<Parameters<Link>[0], "to"> {
  children: React.ReactNode;
  reportInput: ReportInput;
}

export const ReportLink = React.forwardRef(function ReportLink(
  { children, style, reportInput, ...props }: ReportLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
  const crawlData = useCrawlContextData();

  const reportUrl = (() => {
    switch (reportInput.reportEntity) {
      case ReportEntity.CustomReport: {
        return Routes.CustomReport.getUrl({
          accountId: crawlData.crawlProject.account.rawID,
          projectId: crawlData.crawlProject.rawID,
          crawlId: crawlData.crawl.rawID,
          customReportTemplateId: reportInput.customReportTemplateId,
          segmentId: crawlData.selectedCrawlSegment?.segment.id,
        });
      }
      default: {
        return Routes.Report.getUrl({
          accountId: crawlData.crawlProject.account.rawID,
          projectId: crawlData.crawlProject.rawID,
          crawlId: crawlData.crawl.rawID,
          reportTemplateCode: reportInput.reportTemplateCode,
          reportTypeCode: reportInput.reportTypeCode || "basic",
          segmentId: crawlData.selectedCrawlSegment?.segment.id,
        });
      }
    }
  })();

  return (
    <Link
      to={reportUrl}
      style={{
        textDecoration: "none",
        color: "inherit",
        fontSize: "inherit",
        display: "block",
        width: "100%",
        ...style,
      }}
      {...props}
      ref={ref}
    >
      {children}
    </Link>
  );
});
