import React from "react";
import { Typography } from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { isFunction, isString } from "lodash";
import { useFormikContext } from "formik";

const useStyles = makeStyles(() =>
  createStyles({
    titleDiv: {
      display: "flex",
      alignItems: "center",
    },
    closeButton: {
      marginLeft: "auto",
    },
    dialogActions: {
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "stretch",
    },
  }),
);

export interface SegmentDialogProps {
  dialogTitle: React.ReactNode;
  children: React.ReactNode;
  footer?:
    | React.ReactNode
    | ((props: { isSubmitting: boolean }) => React.ReactNode);
  closeDialog: () => void;
}

export function SegmentDialog(props: SegmentDialogProps): JSX.Element {
  const classes = useStyles();

  const { isSubmitting } = useFormikContext();

  return (
    <Dialog
      open={true}
      onClose={props.closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle disableTypography>
        <div className={classes.titleDiv}>
          {isString(props.dialogTitle) ? (
            <Typography component="h2" variant="h2">
              {props.dialogTitle}
            </Typography>
          ) : (
            props.dialogTitle
          )}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.closeDialog}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent>{props.children}</DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        {isFunction(props.footer)
          ? props.footer({ isSubmitting })
          : props.footer}
      </DialogActions>
    </Dialog>
  );
}
