import * as d3 from "d3";
import { useMemo } from "react";
export const useFunnelModel = (config, dataset) => {
    const yMax = useMemo(() => {
        var _a;
        return (_a = d3.max(dataset, (d) => d["pass"] + d["fail"])) !== null && _a !== void 0 ? _a : 0;
    }, [dataset]);
    const xScale = useMemo(() => {
        return d3
            .scaleBand()
            .domain(d3.range(dataset.length).map((d) => d.toString()))
            .range([0, config.funnelWidth]);
    }, [config.funnelWidth, dataset.length]);
    const hScale = useMemo(() => {
        return d3.scaleLinear().domain([0, yMax]).range([0, config.funnelHeight]);
    }, [config.funnelHeight, yMax]);
    const yScale = useMemo(() => {
        return d3
            .scaleLinear()
            .domain([0, yMax])
            .range([config.svgHeight, config.svgHeight - config.funnelHeight]);
    }, [config.svgHeight, config.funnelHeight, yMax]);
    const elementWidth = config.funnelWidth / dataset.length;
    return {
        xScale,
        hScale,
        yScale,
        elementWidth,
    };
};
