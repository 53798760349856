import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedSIChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    supportedModules: [ModuleCode.SiteSpeed],
    icon: "lighthouse",
    title: t("siteSpeedSIChart.title"),
    reportTemplateCodes: [
      "speed_index_slow",
      "speed_index_moderate",
      "speed_index_fast",
    ],
    description: t("siteSpeedSIChart.description"),
  })();
}
