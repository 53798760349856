import React from "react";
import Highcharts, { PlotAreaOptions } from "highcharts";
import { HighchartsChart } from "./HighchartsChart";
import { Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";

export interface SparklinesMiniChartDataPoint {
  x: number;
  y: number;
}

export interface SparklinesMiniChartProps {
  height: number | string;
  width?: number | string | null;
  colors?: string[];
  fillColor?: PlotAreaOptions["fillColor"];
  data: SparklinesMiniChartDataPoint[] | undefined;
}

export function SparklinesMiniChart(
  props: SparklinesMiniChartProps,
): JSX.Element {
  const { t } = useTranslation("charts");

  const { height, width, colors, data, fillColor } = props;
  // this is required cause highcharts are not sorting data by default. (for a performance reasons)
  // eslint-disable-next-line fp/no-mutating-methods
  const sortedData = data?.sort((a, b) => a.x - b.x);

  const options: Highcharts.Options = {
    chart: {
      type: "area",
      animation: false,
      height,
      width,
      backgroundColor: "transparent",
      margin: [0, 0, 0, 0],
    },
    colors: colors || ["#999999"],
    plotOptions: {
      series: {
        lineWidth: 1,
      },
      area: {
        fillColor: fillColor ?? "#ededed",
      },
    },
    series: [
      {
        marker: {
          radius: 1.5,
          enabled: true,
        },
        data: sortedData,
        enableMouseTracking: false,
        animation: false,
        type: "area",
      },
    ],
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
  };

  const flattenedData = data ? data.map((point) => point.y) : [];
  const maxX = Math.max(...flattenedData);
  const minX = Math.min(...flattenedData);

  return (
    <>
      {data && data.length > 1 && (
        <Tooltip
          arrow
          placement="bottom"
          title={
            <Typography variant="caption">
              {t("min", { value: minX })}
              <br />
              {t("max", { value: maxX })}
            </Typography>
          }
        >
          <span
            style={{ display: "inline" }}
            data-testid="sparkline-mini-chart"
          >
            <HighchartsChart options={options} />
          </span>
        </Tooltip>
      )}
    </>
  );
}
