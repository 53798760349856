/*
 * A cleaner way to conditionally insert an element to an array.
 *
 * Example:
 * ```
 * const array = [
 *  1,
 *  2,
 *  3,
 *  4,
 *  ...insertIf(false, 5),
 *  ...insertIf(true, 6)
 * ];
 *
 * console.log(array); // outputs: [1, 2, 3, 4, 6];
 * ```
 *
 */
export function insertIf<T>(condition: unknown, value: T): T[] {
  if (condition) {
    return [value];
  }
  return [];
}

/*
 * A cleaner way to conditionally insert multiple elements to an array.
 *
 * Example:
 * ```
 * const array = [
 *  1,
 *  2,
 *  3,
 *  4,
 *  ...insertIf(false, [5]),
 *  ...insertIf(true, [6, 7, 8])
 * ];
 *
 * console.log(array); // outputs: [1, 2, 3, 4, 5, 6, 7, 8];
 * ```
 *
 */
export function insertManyIf<T>(condition: unknown, value: T[]): T[] {
  if (condition) {
    return value;
  }
  return [];
}
