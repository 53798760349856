import React from "react";
import { MetricsValuePresenterProps } from "../../data/types";
import { UrlPresenter } from "./UrlPresenter";
import { CopyButton } from "../CopyButton";
import { Theme, makeStyles, useTheme } from "@material-ui/core";
import { Chip } from "@lumar/shared";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  text: {
    paddingRight: 16,
  },
  withCopy: {
    "&:hover $copyButton": {
      visibility: "visible",
    },
  },
  copyButton: {},
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      whiteSpace: "break-spaces",
      padding: theme.spacing(0.25, 2.75),
    },
  },
}));

export function StringPresenter(
  props: MetricsValuePresenterProps & { showCopy?: boolean },
): JSX.Element {
  const isUrl = isUrlMetric(props);
  const theme = useTheme();
  const classes = useStyles();

  if (isUrl) return <UrlPresenter {...props} />;

  if (!props.value) return <>-</>;

  function getValue(value: unknown): React.ReactNode {
    const formattedValue =
      typeof value === "string" ? value : JSON.stringify(value, null, 2);

    return (
      <>
        {formatStringValue(formattedValue, theme)}
        <CopyButton value={formattedValue} className={classes.copyButton} />
      </>
    );
  }

  return (
    <>
      {Array.isArray(props.value) ? (
        props.value.map((value, idx) => (
          <Chip
            key={idx}
            label={getValue(value)}
            color="cyan"
            className={clsx(classes.chip, {
              [classes.withCopy]: props.showCopy,
            })}
          />
        ))
      ) : (
        <span
          className={clsx(classes.text, { [classes.withCopy]: props.showCopy })}
        >
          {getValue(props.value)}
        </span>
      )}
    </>
  );
}

export function isUrlMetric(props: MetricsValuePresenterProps): boolean {
  const hasDigest = Boolean(
    props.metrics[props.code]?.data?.metadata?.digestMetric,
  );
  const type = props.metrics[props.code]?.data?.metadata?.stringType;
  const isUrl = type === "url" || type === "example_url";

  return isUrl || hasDigest;
}

export const linkRegexp = new RegExp(/\[[^\]]*\]\(https?:\/\/[^\)]*\)/g);

function formatStringValue(value: string, theme: Theme): React.ReactNode {
  const links = [...value.matchAll(linkRegexp)];
  if (!links.length) return value;

  return (
    <>
      {value.substring(0, links[0].index)}
      {links.map((link, idx) => {
        const closeIndex = link[0].indexOf("]");
        return (
          <React.Fragment key={idx}>
            <a
              href={link[0].substring(closeIndex + 2, link[0].length - 1)}
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.palette.blue[600] }}
            >
              {link[0].substring(1, closeIndex)}
            </a>
            {value.substring(
              (link.index || 0) + link[0].length,
              links[idx + 1]?.index,
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
