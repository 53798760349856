/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { Routes } from "../../routing/routes";
import { ChartConfigItemMultiSeries } from "../multi-series-chart/ChartConfigItemMultiSeries";
import { VisualisationTypes } from "../types/ChartConfig";

function generateGetPagesBreakdownChart(includeMoreLink = false) {
  return function (t: TFunction<"charts">): ChartConfigItemMultiSeries {
    return {
      inputSource: "CrawlContext",
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Pie,
      additionalVisualisationTypes: [VisualisationTypes.Bar],
      title: () => t("pagesBreakdown.title"),
      description: () => t("pagesBreakdown.description"),
      moreLink: includeMoreLink
        ? {
            title: (_, __, totalUrls) =>
              t("pagesBreakdown.viewAll", {
                totalCount: totalUrls,
              }),
            href: ({ accountId, projectId, crawlId, segmentId }) =>
              Routes.Report.getUrl({
                accountId,
                projectId,
                crawlId,
                reportTemplateCode: "all_pages",
                reportTypeCode: "basic",
                segmentId,
              }),
          }
        : undefined,
      showPercentageInSeriesTooltip: true,
      reportTemplateCodesOrder: [
        "unique_pages",
        "paginated_pages",
        "duplicate_pages",
        "non_indexable_pages",
        "non-200_pages",
        "failed_urls",
      ],
      reportStatFilter: (report) => {
        return [
          "unique_pages",
          "paginated_pages",
          "duplicate_pages",
          "non_indexable_pages",
          "non-200_pages",
          "failed_urls",
        ].includes(report.reportTemplateCode);
      },

      serieName: (report) => {
        return report.reportTemplateName;
      },
      count: (report) => {
        return report.basic ?? 0;
      },

      testAttributePrefix: "pages-breakdown-chart",
    };
  };
}

export const getPagesBreakdownChart = generateGetPagesBreakdownChart();

export const getPagesBreakdownChartWithMoreLink =
  generateGetPagesBreakdownChart(true);
