import { VariableType } from "json-to-graphql-query";
import { GetCrawlSettingsForSourcesQuery } from "../../../../../graphql";
import { FormValues, MutationData } from "../types";

export function getAdobeAnalyticsMutationData(
  projectId: string,
  values: FormValues,
  data: GetCrawlSettingsForSourcesQuery | undefined,
): (MutationData | undefined)[] {
  const persistedReportSuite =
    data?.getProject?.adobeAnalyticsProjectReportSuite;

  const isLegacyAdobeConnection = !!persistedReportSuite?.adobeConnection;

  const persistedConnectionId = isLegacyAdobeConnection
    ? persistedReportSuite?.adobeConnection?.id
    : persistedReportSuite?.adobeJwtConnection?.id;

  const persistedReportSuiteId =
    persistedReportSuite && persistedConnectionId
      ? `${persistedReportSuite.reportSuiteId}:${persistedConnectionId}`
      : undefined;

  const adobeAnalyticsProjectReportSuiteId =
    data?.getProject?.adobeAnalyticsProjectReportSuite?.id;

  const reportSuite = values.analytics.adobe.reportSuiteId;
  const suiteName = values.analytics.adobe.reportSuiteName;
  const [reportSuiteId, adobeConnectionId] = reportSuite.split(":");

  const reportSuiteChanged = persistedReportSuiteId !== reportSuite;

  const input = {
    numberOfDays: convertNumber(values.analytics.adobe.numberOfDays),
    minimumVisits: convertNumber(values.analytics.adobe.minimumVisits),
    maxRows: convertNumber(values.analytics.adobe.maximumUrls),
  };

  function getDelete(): MutationData | undefined {
    const shouldDelete = Boolean(persistedReportSuite) && reportSuiteChanged;
    if (!shouldDelete) return;

    return [
      {
        deleteAdobeReportSuiteInput:
          "DeleteAdobeAnalyticsProjectReportSuiteInput!",
      },
      {
        deleteAdobeAnalyticsProjectReportSuite: {
          __args: {
            input: new VariableType("deleteAdobeReportSuiteInput"),
          },
          adobeAnalyticsProjectReportSuite: {
            id: true,
          },
        },
      },
      { deleteAdobeReportSuiteInput: { adobeAnalyticsProjectReportSuiteId } },
    ];
  }

  function getLegacyCreate(): MutationData | undefined {
    const shouldCreate = Boolean(reportSuite) && reportSuiteChanged;
    if (!shouldCreate) return;

    return [
      {
        adobeReportSuiteInput: "CreateAdobeAnalyticsProjectReportSuiteInput!",
      },
      {
        createAdobeAnalyticsProjectReportSuite: {
          __args: {
            input: new VariableType("adobeReportSuiteInput"),
          },
          adobeAnalyticsProjectReportSuite: {
            id: true,
          },
        },
      },
      {
        adobeReportSuiteInput: {
          projectId,
          reportSuiteId,
          suiteName,
          adobeConnectionId: adobeConnectionId ?? "0",
          ...input,
        },
      },
    ];
  }

  function getNewCreate(): MutationData | undefined {
    const shouldCreate = Boolean(reportSuite) && reportSuiteChanged;
    if (!shouldCreate) return;

    return [
      {
        adobeReportSuiteInput: "CreateAdobeAnalyticsProjectReportSuiteInput!",
      },
      {
        createAdobeAnalyticsProjectReportSuite: {
          __args: {
            input: new VariableType("adobeReportSuiteInput"),
          },
          adobeAnalyticsProjectReportSuite: {
            id: true,
          },
        },
      },
      {
        adobeReportSuiteInput: {
          projectId,
          reportSuiteId,
          suiteName,
          adobeJwtConnectionId: adobeConnectionId ?? "0",
          ...input,
        },
      },
    ];
  }

  const getCreate = isLegacyAdobeConnection ? getLegacyCreate : getNewCreate;

  function getUpdate(): MutationData | undefined {
    const shouldUpdate = Boolean(persistedReportSuite) && !reportSuiteChanged;
    if (!shouldUpdate) return;

    return [
      {
        adobeReportSuiteInput: "UpdateAdobeAnalyticsProjectReportSuiteInput!",
      },
      {
        updateAdobeAnalyticsProjectReportSuite: {
          __args: {
            input: new VariableType("adobeReportSuiteInput"),
          },
          adobeAnalyticsProjectReportSuite: {
            id: true,
          },
        },
      },
      {
        adobeReportSuiteInput: {
          adobeAnalyticsProjectReportSuiteId,
          ...input,
        },
      },
    ];
  }

  return [getDelete(), getCreate(), getUpdate()];
}

function convertNumber(value: string): number | null {
  if (!value) return null;
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? null : convertedValue;
}
