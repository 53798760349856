import { ConnectionFilter, FilterOrRuleFormValue } from "../types";

export function mapOrFormFiltersToDatasourceConnectionFilter(
  filter: FilterOrRuleFormValue[],
): ConnectionFilter {
  return {
    _or: filter.map((or) => {
      return {
        _and: or._and.map((and) => {
          const metricCode = and.metricCode;
          if (metricCode.startsWith("customMetrics.")) {
            const [, customMetricKey] = metricCode.split(".");
            return {
              customMetrics: {
                [customMetricKey]: {
                  [and.predicateKey]: and.predicateValue,
                },
              },
            };
          }
          return {
            [and.metricCode]: { [and.predicateKey]: and.predicateValue },
          };
        }),
      };
    }),
  };
}
