import React from "react";

import { assert } from "../assert";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  text: { fontWeight: 600 },
  highlighted: { color: theme.palette.primary.main },
}));

interface ReportCountTotalsProps {
  id?: string;
  totalUrlsInReport?: number;
  totalFilteredUrlsInReport?: number;
  // FIXME: Using this for the iteration of preview segments (while we are
  // not able to paginate) - Remove this in the second iteration - Saul.
  temporarySuffix?: string;
  classes?: {
    total?: string;
    ofTotal?: string;
  };
  highlighted?: boolean;
}

export function ReportCountTotals(props: ReportCountTotalsProps): JSX.Element {
  const {
    totalUrlsInReport,
    totalFilteredUrlsInReport,
    temporarySuffix,
    highlighted,
  } = props;
  const { t } = useTranslation(["units", "common"]);
  const classes = useStyles();

  assert(typeof totalUrlsInReport === "number");

  const isFilterApplied = typeof totalFilteredUrlsInReport === "number";
  const formattedReportCountTotals = t("units:number", {
    count: totalUrlsInReport,
  });

  const count = isFilterApplied ? totalFilteredUrlsInReport : totalUrlsInReport;

  return (
    <span
      className={clsx(classes.text, { [classes.highlighted]: highlighted })}
      data-testid="total-urls-count"
    >
      {isFilterApplied
        ? t("common:urlCountWithTotal", {
            count,
            total: formattedReportCountTotals,
          })
        : t("common:urlCount", { count })}
      {temporarySuffix && ` ${temporarySuffix}`}
    </span>
  );
}
