import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetNotificationsCountQuery } from "../../../../graphql";
const BASIC_POLLING_RATE = 60000;
export function MonitorAppNotificationCountBadge(props) {
    var _a, _b, _c, _d;
    const { accountId } = useParams();
    const polling = React.useRef(BASIC_POLLING_RATE);
    const { data, stopPolling, startPolling } = useGetNotificationsCountQuery({
        variables: { accountId },
        fetchPolicy: "cache-first",
        pollInterval: BASIC_POLLING_RATE,
        notifyOnNetworkStatusChange: true,
        skip: props.skip,
        onError: (error) => {
            // on network error the polling interval is set to 5 minutes.
            // If error occurs again, polling is stopped totally.
            if (error.networkError) {
                if (polling.current > BASIC_POLLING_RATE)
                    return stopPolling();
                polling.current = BASIC_POLLING_RATE * 5;
                return startPolling(polling.current);
            }
            // for any other error, polling is stopped.
            stopPolling();
        },
        onCompleted: () => {
            // If network problem is solved it turns back to 1 minute
            if (polling.current > BASIC_POLLING_RATE) {
                polling.current = BASIC_POLLING_RATE;
                startPolling(polling.current);
            }
        },
    });
    const totalCount = ((_b = (_a = data === null || data === void 0 ? void 0 : data.getAccount) === null || _a === void 0 ? void 0 : _a.reportNotifications.totalCount) !== null && _b !== void 0 ? _b : 0) +
        ((_d = (_c = data === null || data === void 0 ? void 0 : data.getAccount) === null || _c === void 0 ? void 0 : _c.healthScoreNotifications.totalCount) !== null && _d !== void 0 ? _d : 0);
    if (totalCount === undefined || totalCount === 0)
        return null;
    return (_jsx("div", { style: { minWidth: 10, textAlign: "center" }, children: totalCount > 9 ? "9+" : totalCount }));
}
