import { CrawlType } from "../../../graphql";

export function checkRequiredSources(
  requiredSources: (CrawlType | "*")[],
  sources: CrawlType[],
): boolean {
  if (requiredSources.length === 0) {
    return true;
  }

  const everyNamedSourceExists = requiredSources.every((source) => {
    return source === "*" || sources.includes(source);
  });

  const requiresAnyAdditionalSource = requiredSources.includes("*");
  const containsAdditionalSource = sources.length >= requiredSources.length;

  if (requiresAnyAdditionalSource) {
    return everyNamedSourceExists && containsAdditionalSource;
  } else {
    return everyNamedSourceExists;
  }
}
