import { useTheme } from "@material-ui/core";

export function AccessibilityIcon(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      width="64"
      height="64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      style={{
        marginBottom: theme.spacing(3.125),
      }}
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill={theme.palette.ultraviolet[50]}
      />
      <path
        d="M 8 32 C 8 38.3652 10.5286 44.4697 15.0294 48.9706 C 19.5303 53.4714 25.6348 56 32 56 C 38.3652 56 44.4697 53.4714 48.9706 48.9706 C 53.4714 44.4697 56 38.3652 56 32 C 56 25.6348 53.4714 19.5303 48.9706 15.0294 C 44.4697 10.5286 38.3652 8 32 8 C 25.6348 8 19.5303 10.5286 15.0294 15.0294 C 10.5286 19.5303 8 25.6348 8 32 Z"
        fill={theme.palette.ultraviolet[200]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 31.9999 25.1429 C 32.6753 25.1429 33.3441 25.0099 33.968 24.7514 C 34.592 24.493 35.1589 24.1141 35.6365 23.6366 C 36.1141 23.159 36.4929 22.5921 36.7513 21.9681 C 37.0098 21.3442 37.1428 20.6754 37.1428 20 C 37.1428 19.3247 37.0098 18.6559 36.7513 18.0319 C 36.4929 17.408 36.1141 16.841 35.6365 16.3635 C 35.1589 15.8859 34.592 15.5071 33.968 15.2486 C 33.3441 14.9902 32.6753 14.8572 31.9999 14.8572 C 30.636 14.8572 29.3279 15.399 28.3634 16.3635 C 27.3989 17.328 26.8571 18.6361 26.8571 20 C 26.8571 21.364 27.3989 22.6721 28.3634 23.6366 C 29.3279 24.6011 30.636 25.1429 31.9999 25.1429 Z M 35.9017 47.9772 L 36.9542 39.5737 H 39.4297 C 40.1154 39.5737 40.6639 39.0183 40.6639 38.336 V 35.8572 C 40.6188 33.5887 39.686 31.4284 38.0658 29.8401 C 36.4456 28.2518 34.2671 27.3621 31.9982 27.3621 C 29.7293 27.3621 27.5509 28.2518 25.9307 29.8401 C 24.3105 31.4284 23.3776 33.5887 23.3325 35.8572 V 38.336 C 23.3325 39.0217 23.8879 39.5703 24.5702 39.5703 H 27.0457 L 28.0982 47.9772 C 28.15 48.3917 28.3514 48.7731 28.6646 49.0496 C 28.9777 49.3261 29.381 49.4788 29.7988 49.4789 H 34.2011 C 34.6189 49.4788 35.0222 49.3261 35.3353 49.0496 C 35.6485 48.7731 35.8499 48.3917 35.9017 47.9772 Z"
        fill={theme.palette.ultraviolet[600]}
      />
    </svg>
  );
}
