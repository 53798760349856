import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { InfoOutlined } from "../../icons";
import clsx from "clsx";
import React from "react";
const useStyles = makeStyles((theme) => {
    var _a, _b;
    return ({
        icon: Object.assign({ fill: theme.palette.grey[500] }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.TooltipInfoIcon) === null || _b === void 0 ? void 0 : _b.icon),
    });
});
export const TooltipInfoIcon = React.forwardRef(function InnerTooltipInfoIcon(props, ref) {
    const classes = useStyles();
    return (_jsx(InfoOutlined, Object.assign({}, props, { ref: ref, className: clsx(props.className, classes.icon) })));
});
