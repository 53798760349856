import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  Tooltip,
  RadioGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TextField } from "formik-material-ui";
import { FastField, FastFieldProps } from "formik";
import { Select } from "@lumar/shared";

import { withDisplayValue } from "../components/TextFieldInput";
import { withOnBlurUpdate } from "../components/CustomFields";
import { LimitUrlsMaxValues, SettingsContext } from "./types";

const CustomTextField = withOnBlurUpdate(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent: {
      marginTop: theme.spacing(2),
      width: "400px",
    },
  }),
);

export function LimitsSettings(): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const classes = useStyles();

  const contextValues = useContext(SettingsContext);

  const numberPattern = /^([1-9][0-9]*)?$/;

  const limitOptions = [
    ...LimitUrlsMaxValues.map((limit) => ({
      value: limit,
      name: t("limits.limitSelector", {
        crawlLimit: limit,
      }),
    })),
    {
      value: 0,
      name: t("limits.customLimit"),
    },
  ];

  const autoFinalizeValues = [
    {
      value: "disabled",
      label: t("limits.increaseLimit"),
    },
    {
      value: "enabled",
      label: t("limits.finishAnyway"),
    },
  ];

  return (
    <>
      {contextValues.isWebCrawl && (
        <Tooltip
          title={t("limits.limitLevelsMaxTooltip", {
            min: 1,
            max: contextValues.limitLevelsMax,
          })}
          placement="right"
          arrow
        >
          <Box>
            <FastField
              name="limitLevelsMax"
              component={CustomTextField}
              hiddenLabel
              variant="outlined"
              className={classes.indent}
              InputProps={{
                inputComponent: withDisplayValue((value) =>
                  t("limits.crawlLevels", { level: value }),
                ),
              }}
              pattern={numberPattern}
              data-testid="limit-levels-max"
              data-pendo="maximum-urls-limit"
            />
          </Box>
        </Tooltip>
      )}

      <FastField name="limitUrlsMax">
        {({
          field: { name, value },
          form: { setFieldValue, isSubmitting },
        }: FastFieldProps<number>) => (
          <>
            <Select
              value={value}
              onChange={(e) => setFieldValue(name, e.target.value)}
              disabled={isSubmitting}
              className={classes.indent}
              data-testid="limit-urls-max"
            >
              <MenuItem value={-1} disabled>
                {t("limits.pagesLevelLimit")}
              </MenuItem>
              {limitOptions
                .filter((limit) => limit.value <= contextValues.limitPagesMax)
                .map((limit) => (
                  <MenuItem key={limit.value} value={limit.value}>
                    {limit.name}
                  </MenuItem>
                ))}
            </Select>
            {value === 0 && (
              <Tooltip
                title={t("limits.crawlLimitTooltip", {
                  max: contextValues.limitPagesMax,
                })}
                placement="right"
                arrow
              >
                <Box>
                  <FastField
                    name="customLimitPagesMax"
                    component={CustomTextField}
                    hiddenLabel
                    className={classes.indent}
                    variant="outlined"
                    placeholder={t("limits.customLimitPlaceholder")}
                    InputProps={{
                      inputComponent: withDisplayValue((value) =>
                        t("limits.limitSelector", { crawlLimit: value }),
                      ),
                    }}
                    pattern={numberPattern}
                    data-testid="limit-urls-max-custom"
                  />
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </FastField>
      <FastField name="autoFinalizeOnCrawlLimits">
        {({
          field: { name, value },
          form: { setFieldValue, isSubmitting },
        }: FastFieldProps<string>) => (
          <RadioGroup
            value={value}
            onChange={(e) => setFieldValue(name, e.target.value)}
            className={classes.indent}
          >
            {autoFinalizeValues.map((item, index) => {
              const isFirst = index === 0;
              return (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio />}
                  style={!isFirst ? { marginTop: "7px" } : {}}
                  label={item.label}
                  disabled={isSubmitting}
                  data-testid="auto-finalize-option"
                />
              );
            })}
          </RadioGroup>
        )}
      </FastField>
    </>
  );
}
