import { ResourceDetailData } from "../../../data/types";
import { AllMetricsTable } from "../../../metrics-table/AllMetricsTable";
import { useExportName } from "../../useExportName";
import { shouldShowInAllMetric } from "./metricFilters";

export function SiteSpeedAllMetrics({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const getExportName = useExportName({
    crawlCreatedAt: data.crawl.createdAt,
    suffix: "all_metrics",
  });

  return (
    <AllMetricsTable
      metrics={data.metrics}
      crawlDate={data.crawl.createdAt}
      exportFileName={getExportName}
      filter={(metric) => shouldShowInAllMetric(metric.code)}
    />
  );
}
