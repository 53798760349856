import React from "react";
import {
  Chip,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { alpha, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SettingsIcon from "@material-ui/icons/Settings";
import RemoveIcon from "@material-ui/icons/Delete";
import { Checkbox, ToggleIconButton, useNumberFormatter } from "@lumar/shared";
import { useTranslation } from "react-i18next";

import {
  CrawlType,
  ProjectUploadType,
  UrlFileUploadStatus,
} from "../../../../graphql";
import { useSettingsDialog } from "../../components/SettingsDialog";
import { useManualUploadContext } from "./ManualUploadsWrapper";
import { FileDownloadButton } from "./FileDownloadButton";
import { FileUpload } from "./types";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(2, 0),
  },
  dropzoneTextContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    alignContent: "center",
    alignItems: "center",
  },
  dropzone: {
    minHeight: 0,
    borderWidth: 1,
    padding: 16,
    paddingTop: 8,
  },
  dropzoneText: {
    fontSize: theme.typography.pxToRem(12),
    margin: 0,
  },
  dropzoneIcon: {
    width: 48,
    height: 48,
    color: alpha(theme.palette.primary.light, 0.3),
  },
  fullWidth: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  emptyValue: {
    color: theme.palette.grey[500],
  },
  statusUploading: {
    animation: `$fileUploadAnimation 1000ms infinite`,
    animationTimingFunction: "linear",
    background: `repeating-linear-gradient(
      45deg,
      ${theme.palette.teal[800]},
      ${theme.palette.teal[800]} 10px,
      ${theme.palette.teal[500]} 10px,
      ${theme.palette.teal[500]} 20px)`,
    backgroundSize: "200% 200%",
  },
  "@keyframes fileUploadAnimation": {
    "0%": {
      backgroundPosition: "100px 0px",
    },
    "100%": {
      backgroundPosition: "127px 0px",
    },
  },
  statusSuccess: {
    background: theme.palette.success.main,
  },
  statusError: {
    background: theme.palette.error.main,
  },
  statusDraft: {
    background: theme.palette.grey[300],
  },
}));

const maxFileSize = 100 * 1024 * 1024;

export const ManualUploads = React.memo(function ManualUploads({
  crawlType,
  disabled,
  disableEdit,
  acceptedFiles,
}: {
  crawlType: CrawlType;
  disabled?: boolean;
  disableEdit?: boolean;
  acceptedFiles?: string[] | undefined;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const numberFormatter = useNumberFormatter();

  const {
    loading,
    files,
    uploadTypes,
    uploadFiles,
    setFileStatus,
    deleteFile,
    editFile,
  } = useManualUploadContext(crawlType);

  const { showMessage } = useSettingsDialog();

  const handleUpload = (files: FileObject[]): void => {
    uploadFiles(files.map((f) => f.file));
  };

  const handleEnabledChange = (file: FileUpload, enabled: boolean): void => {
    setFileStatus({ fileId: file.id, status: enabled });
  };

  const handleDelete = (fileId: string): void => {
    showMessage({
      title: t("sources.manualUpload.removeTitle"),
      desctiprion: t("sources.manualUpload.removeDescription"),
      confirmText: t("sources.manualUpload.removeConfirm"),
      cancelText: t("sources.manualUpload.removeCancel"),
      onConfirm: () => deleteFile({ fileId }),
    });
  };

  const getStatus = (
    status: FileUpload["status"],
  ): { label: string; class?: string } => {
    switch (status) {
      case "Uploading":
        return {
          label: t("sources.manualUpload.statusUploading"),
          class: classes.statusUploading,
        };
      case UrlFileUploadStatus.Processed:
        return {
          label: t("sources.manualUpload.statusProcessed"),
          class: classes.statusSuccess,
        };
      case UrlFileUploadStatus.Errored:
        return {
          label: t("sources.manualUpload.statusErrored"),
          class: classes.statusError,
        };
      case UrlFileUploadStatus.Draft:
        return {
          label: t("sources.manualUpload.statusDraft"),
          class: classes.statusDraft,
        };
      default:
        return { label: t("sources.manualUpload.statusProcessing") };
    }
  };

  return (
    <div className={clsx(classes.fullWidth, classes.indent)}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" size={20} />
        </div>
      ) : (
        <div data-testid="manual-file-upload-dropzone">
          <DropzoneAreaBase
            fileObjects={[]}
            filesLimit={Number.POSITIVE_INFINITY}
            acceptedFiles={acceptedFiles}
            onAdd={handleUpload}
            showPreviewsInDropzone={false}
            showAlerts={false}
            maxFileSize={maxFileSize}
            dropzoneText={t("sources.manualUpload.dropzoneText")}
            classes={{
              root: classes.dropzone,
              text: classes.dropzoneText,
              icon: classes.dropzoneIcon,
              textContainer: classes.dropzoneTextContainer,
            }}
            dropzoneProps={{ disabled }}
          />
        </div>
      )}
      {files.length > 0 && (
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.indent}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("sources.manualUpload.active")}</TableCell>
                <TableCell>{t("sources.manualUpload.filename")}</TableCell>
                <TableCell>{t("sources.manualUpload.format")}</TableCell>
                <TableCell align="right">
                  {t("sources.manualUpload.items")}
                </TableCell>
                <TableCell>{t("sources.manualUpload.status")}</TableCell>
                <TableCell align="center">
                  {t("sources.manualUpload.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file) => {
                const uploadType = !file.uploadType
                  ? "..."
                  : file.uploadType === ProjectUploadType.Custom
                    ? t("sources.manualUpload.custom")
                    : uploadTypes.find((type) => type.code === file.uploadType)
                        ?.name;
                const isUploading = file.status === "Uploading";
                const isDraft = file.status === "Draft";
                const isCompleted = (
                  [
                    UrlFileUploadStatus.Processed,
                    UrlFileUploadStatus.Errored,
                  ] as string[]
                ).includes(file.status);

                return (
                  <TableRow
                    key={file.id}
                    data-testid="manual-file-upload"
                    style={{ height: "unset" }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={file.enabled}
                        disabled={disabled || isDraft || isUploading}
                        onChange={(_, checked) =>
                          handleEnabledChange(file, checked)
                        }
                        data-testid="manual-file-upload-enabled"
                      />
                    </TableCell>
                    <TableCell>{file.fileName}</TableCell>
                    <TableCell>{uploadType}</TableCell>
                    <TableCell align="right">
                      {file.totalRows ? (
                        numberFormatter(file.totalRows)
                      ) : (
                        <div className={classes.emptyValue}>
                          {file.status === UrlFileUploadStatus.Processed
                            ? t("sources.manualUpload.noRows")
                            : t("sources.manualUpload.notAvaliable")}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={getStatus(file.status).label}
                        color="primary"
                        size="small"
                        className={getStatus(file.status).class}
                      />
                    </TableCell>
                    <TableCell align="center" width="130px">
                      {!isUploading && (
                        <>
                          {!disableEdit && isCompleted && (
                            <ToggleIconButton
                              onClick={() => editFile({ file })}
                              size="small"
                              color="secondary"
                              disabled={disabled}
                              data-testid="manual-file-upload-settings"
                            >
                              <Tooltip
                                title={t("sources.manualUpload.optionsTooltip")}
                                placement="top"
                                arrow
                              >
                                <SettingsIcon />
                              </Tooltip>
                            </ToggleIconButton>
                          )}
                          <FileDownloadButton
                            file={file}
                            disabled={disabled || isDraft}
                          />
                          <ToggleIconButton
                            onClick={() => handleDelete(file.id)}
                            size="small"
                            colorVariant="red"
                            disabled={disabled}
                            data-testid="manual-file-upload-remove"
                          >
                            <Tooltip
                              title={t("sources.manualUpload.removeTooltip")}
                              placement="top"
                              arrow
                            >
                              <RemoveIcon />
                            </Tooltip>
                          </ToggleIconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
});
