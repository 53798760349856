import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Tooltip } from "@material-ui/core";
import {
  ArrowNarrowRightSolid,
  ExclamationSolid,
  Typography,
  Trans,
  useTranslation,
} from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  missingValue: {
    color: theme.palette.yellow[400],
    cursor: "pointer",
  },
  list: {
    padding: theme.spacing(1, 1.5),
    margin: 0,
  },
  tooltip: {
    padding: theme.spacing(1.75, 2, 2, 2),
    minWidth: "400px",
    maxWidth: "400px",
    "& a": {
      color: theme.palette.blue[500],
    },
  },
  icon: {
    fontSize: "inherit",
    verticalAlign: "middle",
    color: "inherit",
  },
}));

export function MissingValue(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");

  return (
    <Tooltip
      title={
        <>
          <Typography
            variant="subtitle3Medium"
            data-testid="data-explorer-unsupported-crawl-text"
          >
            {t("missingValue.title")}
          </Typography>
          <Typography component="div" variant="caption">
            <ul className={classes.list}>
              <li>{t("missingValue.troubleshoot1")}</li>
              <li>{t("missingValue.troubleshoot2")}</li>
            </ul>
            <Trans t={t} i18nKey="missingValue.contact">
              If the problem still persists, please contact
              <Link href="mailto:support@deepcrawl.com">
                support <ArrowNarrowRightSolid className={classes.icon} />
              </Link>
            </Trans>
          </Typography>
        </>
      }
      interactive
      classes={{ tooltip: classes.tooltip }}
    >
      <ExclamationSolid
        className={classes.missingValue}
        data-testid="data-explorer-unsupported-crawl-type"
      />
    </Tooltip>
  );
}
