var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import { TextField, Chip, Box, IconButton, withStyles, } from "@material-ui/core";
import clsx from "clsx";
import { XSmallIcon } from "../../icons";
import { TrashOutlined } from "../../icons/ui-friendly/outlined/TrashOutlined";
import { useSnackbar } from "notistack";
import { Snackbar } from "../snackbars/Snackbar";
import { DuplicateOutlined } from "../../icons/ui-friendly/outlined/DuplicateOutlined";
import { useTranslation } from "react-i18next";
export function TagInputInner(_a) {
    var { value: valueControlled, onChange, addOnSpace, addOnComma, showCopyAll, disabled, error, errors, placeholder, classes, tagProps, copyAllButtonProps, removeAllButtonProps } = _a, props = __rest(_a, ["value", "onChange", "addOnSpace", "addOnComma", "showCopyAll", "disabled", "error", "errors", "placeholder", "classes", "tagProps", "copyAllButtonProps", "removeAllButtonProps"]);
    const { t } = useTranslation("sharedCommon");
    const [valueUncontrolled, setValueUncontrolled] = useState([]);
    const value = valueControlled !== null && valueControlled !== void 0 ? valueControlled : valueUncontrolled;
    const setValue = onChange
        ? (value) => onChange(value)
        : setValueUncontrolled;
    const [valueInner, setValueInner] = useState("");
    const inputRef = useRef(null);
    const snackbar = useSnackbar();
    const handleAdd = () => {
        if (!valueInner.length)
            return;
        setValue([...value, valueInner]);
        setValueInner("");
    };
    const handleRemove = (index) => {
        setValue([...value.slice(0, index), ...value.slice(index + 1)]);
    };
    const handleRemoveAll = () => {
        setValue([]);
    };
    const handleCopyAll = () => {
        navigator.clipboard.writeText(value.join("\n"));
        snackbar === null || snackbar === void 0 ? void 0 : snackbar.enqueueSnackbar(_jsx(Snackbar, { variant: "success", title: t("copied") }));
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter" ||
            (addOnSpace && event.key === " ") ||
            (addOnComma && event.key === ",")) {
            handleAdd();
            event.preventDefault();
        }
        if (event.key === "Backspace" && value.length && !valueInner.length) {
            handleRemove(value.length - 1);
            setValueInner(value[value.length - 1]);
            event.preventDefault();
        }
    };
    const handleEdit = (event, index) => {
        var _a;
        if (event.detail !== 2 || index < 0 || index >= value.length)
            return;
        handleRemove(index);
        setValueInner(value[index]);
        event.preventDefault();
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const handleBlur = () => {
        handleAdd();
    };
    const handlePaste = (event) => __awaiter(this, void 0, void 0, function* () {
        const text = event.clipboardData.getData("text/plain");
        const pattern = new RegExp("\r\n|\n|\r" + (addOnSpace ? "| " : "") + (addOnComma ? "|," : ""));
        const values = text.split(pattern).filter(Boolean);
        if (values.length > 1) {
            setValue([...value, ...values]);
            setValueInner("");
            event.preventDefault();
        }
    });
    return (_jsx(TextField, Object.assign({}, props, { multiline: true, value: valueInner, onChange: (e) => setValueInner(e.target.value), disabled: disabled, helperText: error !== null && error !== void 0 ? error : placeholder, error: Boolean(error), variant: props.variant || "outlined", InputProps: Object.assign(Object.assign({}, props.InputProps), { startAdornment: value.map((label, index) => (_createElement(Chip, Object.assign({}, tagProps, { key: index, variant: "outlined", tabIndex: undefined, label: label, classes: {
                    root: clsx(classes === null || classes === void 0 ? void 0 : classes.tag, (errors === null || errors === void 0 ? void 0 : errors[index])
                        ? clsx(classes === null || classes === void 0 ? void 0 : classes.errorTag, "TagInput-chip-error")
                        : ""),
                    label: classes === null || classes === void 0 ? void 0 : classes.tagLabel,
                    deleteIcon: clsx(classes === null || classes === void 0 ? void 0 : classes.removeIcon, (errors === null || errors === void 0 ? void 0 : errors[index]) ? classes === null || classes === void 0 ? void 0 : classes.errorRemoveIcon : ""),
                }, onClick: (e) => handleEdit(e, index), deleteIcon: _jsx(XSmallIcon, { viewBox: "1 1 14 14" }), onDelete: () => handleRemove(index), disabled: disabled })))), endAdornment: (_jsx("div", { className: classes === null || classes === void 0 ? void 0 : classes.buttonsDiv, children: _jsx(Box, { className: classes === null || classes === void 0 ? void 0 : classes.buttons, children: !!value.length && (_jsxs(_Fragment, { children: [showCopyAll && (_jsx(IconButton, Object.assign({}, copyAllButtonProps, { size: "small", onClick: () => handleCopyAll(), disabled: disabled, tabIndex: -1, title: t("copyAll"), children: _jsx(DuplicateOutlined, { className: classes === null || classes === void 0 ? void 0 : classes.copyAllIcon }) }))), _jsx(IconButton, Object.assign({}, removeAllButtonProps, { size: "small", onClick: () => handleRemoveAll(), disabled: disabled, tabIndex: -1, title: t("removeAll"), children: _jsx(TrashOutlined, { className: classes === null || classes === void 0 ? void 0 : classes.removeAllIcon }) }))] })) }) })), onKeyDown: handleKeyDown, onPaste: handlePaste, onBlur: handleBlur, classes: {
                root: clsx(classes === null || classes === void 0 ? void 0 : classes.root, showCopyAll ? classes === null || classes === void 0 ? void 0 : classes.copyAllEnabled : ""),
                input: classes === null || classes === void 0 ? void 0 : classes.input,
            } }), inputRef: inputRef })));
}
export const TagInput = withStyles((theme) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return ({
        root: Object.assign({ display: "flex", flexWrap: "wrap", alignItems: "center", padding: theme.spacing(0, 4.875, 0, 1.125), "&.Mui-error fieldset": {
                borderColor: `${theme.palette.grey[300]} !important`,
            }, "&.Mui-error.Mui-focused fieldset": {
                borderColor: `${theme.palette.ultraviolet[500]} !important`,
            }, minHeight: 38 }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.TagInput) === null || _b === void 0 ? void 0 : _b.root),
        copyAllEnabled: {
            paddingRight: theme.spacing(8.125),
        },
        input: {
            width: "auto",
            flexGrow: 1,
            paddingLeft: theme.spacing(0.75),
            paddingRight: theme.spacing(0.75),
            margin: theme.spacing(0.75, 0),
        },
        tag: Object.assign({ background: theme.palette.secondary.main, color: theme.palette.ultraviolet[800], borderRadius: "4px", borderWidth: "0px", fontSize: theme.typography.pxToRem(12), lineHeight: theme.typography.pxToRem(20), fontWeight: 600, height: "auto", padding: theme.spacing(0.25, 0.75, 0.25, 1.25), margin: theme.spacing(0.625, 0.625, 0.625, 0), maxWidth: `calc(100% - ${theme.spacing(0.375)}px)` }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.TagInput) === null || _d === void 0 ? void 0 : _d.tag),
        errorTag: Object.assign({ background: theme.palette.red[200], color: theme.palette.red[800] }, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.TagInput) === null || _f === void 0 ? void 0 : _f.errorTag),
        removeIcon: Object.assign({ color: theme.palette.primary.main, width: "16px", height: "16px", paddingLeft: theme.spacing(0.25), paddingRight: 0, margin: 0, pointerEvents: "auto" }, (_h = (_g = theme.overrides) === null || _g === void 0 ? void 0 : _g.TagInput) === null || _h === void 0 ? void 0 : _h.removeIcon),
        errorRemoveIcon: Object.assign({ color: theme.palette.red[400] }, (_k = (_j = theme.overrides) === null || _j === void 0 ? void 0 : _j.TagInput) === null || _k === void 0 ? void 0 : _k.errorRemoveIcon),
        tagLabel: {
            paddingLeft: 0,
            paddingRight: 0,
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
        },
        buttonsDiv: {
            position: "absolute",
            right: theme.spacing(1.125),
            top: "5px",
            bottom: "5px",
        },
        buttons: {
            position: "sticky",
            top: "32px",
        },
        copyAllIcon: {
            width: "20px",
            height: "20px",
        },
        removeAllIcon: {
            width: "20px",
            height: "20px",
        },
    });
})(TagInputInner);
