import {
  Button,
  ChartBarSolid,
  Globe,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(17.125, 0, 16.5, 0),
    gap: theme.spacing(1.75),
  },
  icon: {
    fontSize: theme.typography.pxToRem(38),
    color: theme.palette.navy[500],
    fill: theme.palette.yellow[500],
  },
  description: {
    color: theme.palette.grey[500],
    textAlign: "center",
    maxWidth: "56%",
  },
}));

export function GoogleAnalytics4NoAccountOverlay({
  onAddAccont,
}: {
  onAddAccont: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  return (
    <div className={classes.root}>
      <Globe className={classes.icon} />
      <Typography variant="h2SemiBold">
        {t("sources.analytics.googleAnalytics4.noAccountTitle")}
      </Typography>
      <Typography className={classes.description}>
        {t("sources.analytics.googleAnalytics4.noAccountDescription")}
      </Typography>
      <Button
        onClick={onAddAccont}
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<ChartBarSolid />}
      >
        {t("sources.analytics.googleAnalytics4.addAccount")}
      </Button>
    </div>
  );
}
