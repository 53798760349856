var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import { GridApiContext, useGridApi, useGridSelector, } from "@mui/x-data-grid-pro";
import { assert } from "../helpers";
import { Typography } from "../typography/Typography";
import { Select } from "../select/Select";
const useStyles = makeStyles((theme) => ({
    prefix: {
        color: theme.palette.grey[500],
    },
}));
export function BlueGridRowSelector(_a) {
    var { 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disabled, prefix = "Rows " } = _a, props = __rest(_a, ["disabled", "prefix"]);
    const classes = useStyles();
    const apiRef = React.useContext(GridApiContext);
    assert(apiRef);
    const api = useGridApi(apiRef);
    const { pageSize } = useGridSelector(apiRef, (state) => state.pagination);
    const { rowsPerPageOptions } = useGridSelector(apiRef, (state) => state.options);
    return (_jsx(Select, Object.assign({ value: pageSize, onChange: (e) => api.setPageSize(e.target.value), dividerRight: true, alignMenu: "right", renderValue: (value) => (_jsxs(Typography, { variant: "button", style: { lineHeight: 1.2 }, children: [_jsx("span", { className: classes.prefix, children: prefix }), value] })) }, props, { children: rowsPerPageOptions === null || rowsPerPageOptions === void 0 ? void 0 : rowsPerPageOptions.map((size) => (_jsx(MenuItem, { value: size, children: size }, size))) })));
}
