// NOTE: The :pageId param of legacy page report route is a url-safe
// base64 encoded, SHA512 hash of "url" value. In Canary API, the
// "urlDigest", "urlToDigest" and "urlFromDigest" values are base 64
// encoded SHA512 hashes of the "url", "urlTo" and "urlFrom" values
// (not url-safe). The :pageId param that the legacy route expects
// and the "urlDigest" value from Canary API don't completely align,
// but are close. We could use the "url" value, get the SHA512 hash
// and url-safe base64 encode on the client side to get to the legacy
// APIs params value but I didn't want to do this on the client-side.
// There are also some quirks between the url-safe encode methods of
// the "base64-url" npm package work vs the how its done in the legacy
// API which mean it's much easier to just use the non-url safe
// "urlDigest" value we already have and manually replace "/"s with
// "_"s and "+" with "-"s on the string here ourselves. It's a bit of
// a hack, but it's the simplest way - Saul.

export function sanitizeDigestForPageId(urlDigest: string): string {
  return urlDigest.replace(/\+/g, "-").replace(/\//g, "_");
}
