import React from "react";
import { AccountProjectsContextData } from "./types";

export const AccountProjectsContext =
  React.createContext<AccountProjectsContextData>({
    crawlTypesMetadata: [],
    deleteProject: () => Promise.resolve(false),
    cancelRunningCrawl: () => Promise.resolve(false),
    deleteProjectSchedule: () => Promise.resolve(false),
    cloneProject: () => Promise.resolve(undefined),
  });

export function useAccountProjectsContext(): AccountProjectsContextData {
  return React.useContext(AccountProjectsContext);
}
