import { useSession, useTranslation } from "@lumar/shared";
import { CrawlType } from "../../../../../graphql";

export function LevelLimitReasons({
  sources,
  crawlHighestLevel,
  finalizationDate,
}: {
  sources?: CrawlType[];
  crawlHighestLevel: number;
  finalizationDate: Date;
}): JSX.Element {
  const { account } = useSession();
  const hasWeb = sources?.includes(CrawlType.Web);
  const webOnly = hasWeb && sources?.length === 1;
  const webAndOther = hasWeb && (sources?.length ?? 0) > 1;
  const accountLimitLevelsMax = account.limitLevelsMax;
  const { t } = useTranslation("crawlProgress");
  return (
    <>
      {crawlHighestLevel < accountLimitLevelsMax ? (
        <span>
          {webOnly
            ? t("pauseReasons.LevelsLimitWebOnly", { value: finalizationDate })
            : null}
          {webAndOther
            ? t("pauseReasons.LevelsLimitWebAndOther", {
                value: finalizationDate,
              })
            : null}
        </span>
      ) : (
        t("pauseReasons.LevelLimitAccountMax", {
          value: finalizationDate,
        })
      )}
    </>
  );
}
