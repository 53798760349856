import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  frequency: {
    color: theme.palette.grey[600],
  },
}));

export function FrequencyCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element {
  const classes = useStyles();

  return (
    <Typography
      variant="captionSemiBold"
      className={classes.frequency}
      data-testid="all-projects-frequency-cell"
    >
      {row.schedule?.scheduleFrequency.name}
    </Typography>
  );
}
