import {
  ClockV2,
  FileDouble,
  GlobeNetwork,
  TrendUp,
  ViewSimple,
  useTranslation,
} from "@lumar/shared";
import { Metrics, ResourceDetailData } from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";
import { MetricsList } from "../../../metrics-groups/MetricsList";
import { MetricsTable } from "../../../metrics-groups/MetricsTable";

export function SiteSpeedSummary({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useConfig(data.metrics);

  return <MetricsGroups config={config} />;
}

function useConfig(metrics: Metrics): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  return [
    {
      name: t("siteSpeed.groupReport"),
      children: (
        <MetricsList
          metrics={metrics}
          config={[
            {
              code: "gaPageviews",
              name: t("siteSpeed.pageviews"),
              icon: ViewSimple,
            },
            {
              code: "savingsKib",
              name: t("siteSpeed.sizeSavings"),
              icon: GlobeNetwork,
            },
            {
              code: "productOfSavingsKibAndPageviews",
              name: t("siteSpeed.sizeSavingsTimesPageviews"),
              icon: GlobeNetwork,
            },
            {
              code: "savingsSecs",
              name: t("siteSpeed.timeSavings"),
              icon: GlobeNetwork,
            },
            {
              code: "productOfSavingsSecsAndPageviews",
              name: t("siteSpeed.timeSavingsTimesPageviews"),
              icon: GlobeNetwork,
            },
          ]}
        />
      ),
    },
    {
      name: t("siteSpeed.groupEngagement"),
      children: (
        <MetricsList
          metrics={metrics}
          config={[
            {
              code: "gaVisits",
              name: t("siteSpeed.visits"),
              icon: GlobeNetwork,
            },
            {
              code: "gaVisitBounceRate",
              name: t("siteSpeed.bounceRate"),
              icon: TrendUp,
            },
            {
              code: "gaAvgTimeOnPage",
              name: t("siteSpeed.avgVisitDuration"),
              icon: ClockV2,
            },
            {
              code: "gaPageviewsPerVisits",
              name: t("siteSpeed.pagesPerVisit"),
              icon: FileDouble,
            },
          ]}
        />
      ),
    },
    {
      name: t("siteSpeed.groupStats"),
      children: (
        <MetricsTable
          metrics={metrics}
          headers={[t("siteSpeed.statsItem"), t("siteSpeed.statsResult")]}
          config={[
            { code: "sitespeedFmp" },
            { code: "sitespeedLcp" },
            { code: "sitespeedTbt" },
            { code: "sitespeedCls" },
            { code: "sitespeedSpeedIndex" },
            { code: "sitespeedTtfb" },
            { code: "sitespeedClsMainFrame" },
            { code: "sitespeedMaxPotentialFid" },
            { code: "sitespeedTti" },
            { code: "lcpLoadStart" },
            { code: "lcpLoadEnd" },
            { code: "sitespeedFcp" },
            { code: "passedAuditsCount" },
            { code: "failedAuditsCount" },
            { code: "warningAuditsCount" },
            { code: "infoAuditsCount" },
            { code: "passedAudits" },
            { code: "failedAudits" },
            { code: "warningAudits" },
            { code: "infoAudits" },
            { code: "savingsSecs" },
            { code: "savingsKib" },
            { code: "savingsScore" },
            { code: "productOfSavingsSecsAndPageviews" },
            { code: "productOfSavingsKibAndPageviews" },
            { code: "sitespeedScore" },
            { code: "sitespeedFcpScore" },
            { code: "sitespeedLcpScore" },
            { code: "sitespeedTbtScore" },
            { code: "sitespeedClsScore" },
            { code: "sitespeedSpeedIndexScore" },
          ]}
        />
      ),
    },
  ];
}
