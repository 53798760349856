import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { assert } from "../../../_common/assert";
import { getReportCodes } from "../../helpers/getReportCodes";
import { ChartConfig } from "../../../_common/charts/types/ChartConfig";
import { generateSingleReportTrendChart } from "../../../_common/charts/utils/generateSingleReportTrendChartConfig";
import { generateTopSegmentsChart } from "../../../_common/charts/config/getTopSegmentsChart";
import { generateSegmentComparisonChart } from "../../../_common/charts/config/getSegmentComparisonChart";
import { reportsChartsMap } from "./reportsChartsMap";
import { useDateFormatter } from "@lumar/shared";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { ModuleCode } from "../../../graphql";
import { PredefinedChart, isPredefinedChartConfig } from "./types";

export function useReportChartConfig(
  displaySegmentationCharts: boolean,
): ChartConfig[] | null {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();

  const { reportTemplateCodeWithTypeCode } = useGenericParams();
  assert(reportTemplateCodeWithTypeCode);
  const { reportTemplateCode } = getReportCodes(reportTemplateCodeWithTypeCode);

  const { selectedCrawlSegment, crawlProject } = useCrawlContextData();
  const isSegmentApplied = Boolean(selectedCrawlSegment);

  const match = reportsChartsMap.get(reportTemplateCode) || [
    { predefinedChartType: "reportTrend" },
  ];

  const segmentConfigItem = displaySegmentationCharts
    ? isSegmentApplied
      ? generateSegmentComparisonChart(reportTemplateCode)
      : generateTopSegmentsChart(reportTemplateCode)
    : undefined;

  return [
    ...(segmentConfigItem ? [segmentConfigItem(t)] : []),
    ...match
      .reduce<ChartConfig[]>((result, configItem) => {
        if (!isPredefinedChartConfig(configItem)) {
          return [...result, configItem(t, theme, dateFormatter)];
        }

        const predefinedConfigItem = (() => {
          switch (configItem.predefinedChartType) {
            case PredefinedChart.ReportTrend:
              return generateSingleReportTrendChart(reportTemplateCode);
          }
        })();

        if (!predefinedConfigItem) return result;

        return [
          ...result,
          {
            ...predefinedConfigItem(t, theme, dateFormatter),
            supportedModules:
              configItem.supportedModules || Object.values(ModuleCode),
          },
        ];
      }, [])
      .filter((chart) =>
        (chart.supportedModules || [ModuleCode.Seo]).includes(
          crawlProject.moduleCode,
        ),
      ),
  ];
}
