import { FilterRuleFormValue } from "../_common/connection-filtering/types";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { getPrisitinePaginationWithCurrentPageSize } from "./getPristinePaginationWithCurrentPageSize";

export type AccountProjectsFilterParam = {
  filters?: FilterRuleFormValue[];
  search?: string;
  scheduled?: boolean;
  draft?: boolean;
  active?: boolean;
};

type Result = {
  filter: AccountProjectsFilterParam;
  resetFilters: () => void;
  applyFilters: (filters: AccountProjectsFilterParam["filters"]) => void;
  clearSearch: () => void;
  setSearch: (search: string) => void;
  clearAllFilters: () => void;
  setScheduled: (value: boolean) => void;
  setDraft: (value: boolean) => void;
  setActive: (value: boolean) => void;
};

export function useAccountProjectsFilterParam(): Result {
  const searchParams = useURLSearchParams();

  const filter = convertFilterParamFrom(searchParams.get("filter") || "");

  function resetPagination(): void {
    const pagination = getPrisitinePaginationWithCurrentPageSize(searchParams);
    if (pagination) {
      searchParams.set("pagination", pagination);
    }
  }

  function updateFilter(filter: string | undefined): void {
    if (!filter) {
      searchParams.delete("filter");
    } else {
      searchParams.set("filter", filter);
    }
  }

  return {
    filter,
    resetFilters: () => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          filters: undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    applyFilters: (filters) => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          filters,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    clearSearch: () => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          search: undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    setSearch: (search) => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          search: search || undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    clearAllFilters: () => {
      searchParams.delete("filter");
      resetPagination();
      searchParams.navigate();
    },
    setScheduled: (value) => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          scheduled: value || undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    setDraft: (value) => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          draft: value || undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
    setActive: (value) => {
      updateFilter(
        convertFilterParamTo({
          ...filter,
          active: value || undefined,
        }),
      );
      resetPagination();
      searchParams.navigate();
    },
  };
}

function convertFilterParamTo(
  filters: AccountProjectsFilterParam,
): string | undefined {
  const encodedFilter = JSON.stringify(filters);
  if (encodedFilter === "{}") return undefined;
  return btoa(encodeURI(encodedFilter));
}

function convertFilterParamFrom(filters: string): AccountProjectsFilterParam {
  try {
    return filters
      ? (JSON.parse(decodeURI(atob(filters))) as AccountProjectsFilterParam)
      : {};
  } catch {
    return {};
  }
}
