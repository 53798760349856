import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityFormsAChart } from "../../../../../_common/charts/config/getAccessibilityFormsAChart";
import { getAccessibilityFormsATrend } from "../../../../../_common/charts/config/getAccessibilityFormsATrend";

export const generateAccessibilityFormsACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityFormsAChart,
      getAccessibilityFormsATrend,
    ],
  };
