import Highcharts from "highcharts";
import { ChartConfigItemMultiSeries } from "./ChartConfigItemMultiSeries";

export type Series = {
  name: string;
  data: SeriesDataItem[];
}[];

export interface SeriesDataItem {
  name: string;
  y?: number;
  reportTemplateCode: string;
}

export type MultiSeriesProps = Omit<ChartConfigItemMultiSeries, "title"> & {
  series: Series;
  title: string;
};

export function isPoint(value: unknown): value is Highcharts.Point {
  return value instanceof Highcharts.Point;
}

interface PointWithLegendItem extends Highcharts.Point {
  legendItem: { label: Highcharts.SVGElement };
}

export function pointContainsLegendItem(
  value: Highcharts.Point,
): value is PointWithLegendItem {
  return (
    (value as Highcharts.Point & { legendItem: { label: unknown } }).legendItem
      .label instanceof Highcharts.SVGElement
  );
}

interface PointWithReportTemplateCode extends Highcharts.Point {
  reportTemplateCode: string;
}

export function pointContainsReportTemplateCode(
  value: Highcharts.Point,
): value is PointWithReportTemplateCode {
  return (
    typeof (
      value as Highcharts.Point & Partial<Record<"reportTemplateCode", unknown>>
    ).reportTemplateCode === "string"
  );
}

interface PointWithReportNameInTooltip extends Highcharts.Point {
  reportNameInTooltip: string;
}

export function pointContainsReportNameInTooltip(
  value: Highcharts.Point,
): value is PointWithReportNameInTooltip {
  return (
    typeof (
      value as Highcharts.Point &
        Partial<Record<"reportNameInTooltip", unknown>>
    ).reportNameInTooltip === "string"
  );
}

interface PointWithSegmentIdKey extends Highcharts.Point {
  segmentId: string | undefined;
}

export function pointContainsSegmentIdKey(
  value: Highcharts.Point,
): value is PointWithSegmentIdKey {
  return (
    value as Highcharts.Point & Partial<Record<"segmentId", unknown>>
  ).hasOwnProperty("segmentId");
}

interface PointWithSegmentNameKey extends Highcharts.Point {
  segmentName: string | undefined;
}

export function pointContainsSegmentNameKey(
  value: Highcharts.Point,
): value is PointWithSegmentNameKey {
  return (
    value as Highcharts.Point & Partial<Record<"segmentName", unknown>>
  ).hasOwnProperty("segmentName");
}
