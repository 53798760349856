import React from "react";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Chip,
  CrawlSourcesIcons,
  useDateFormatter,
} from "@lumar/shared";

import { NarrowAutocompleteProject } from "./types";
import { CrawlStatus, CrawlTypeMetadata } from "../../graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    archivedChip: {
      marginLeft: theme.spacing(1.5),
    },
    lastCrawl: {
      color: theme.palette.grey[600],
    },
  }),
);

interface ProjectAutocompleteOptionProps {
  project: NarrowAutocompleteProject;
  crawlTypesMetadata: Pick<CrawlTypeMetadata, "name" | "code">[];
}

export function ProjectAutocompleteOption(
  props: ProjectAutocompleteOptionProps,
): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "projectAutocomplete"]);
  const dateFormatter = useDateFormatter();

  const { project, crawlTypesMetadata } = props;
  const { lastFinishedCrawl } = project;
  const segmentCount = project.segmentsTotalCount;
  const crawlTypes = project?.crawlTypes;

  const sources = crawlTypes.map((crawlType) => ({
    code: crawlType,
    name: crawlTypesMetadata.find((x) => x.code === crawlType)?.name ?? "",
    active: true,
  }));

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      data-testid="project-autocomplete-option"
    >
      <Box>
        <Box display="flex">
          <Typography variant="captionSemiBold">{project.name}</Typography>
          {lastFinishedCrawl?.statusEnum === CrawlStatus.Archived && (
            <>
              <Chip
                color="lightgrey"
                label={t("common:archived")}
                className={classes.archivedChip}
              />
            </>
          )}
        </Box>
        <Typography variant="caption">{project.primaryDomain} </Typography>
        <Typography variant="caption" className={classes.lastCrawl}>
          {t("projectAutocomplete:lastFinishedCrawl", {
            crawl:
              lastFinishedCrawl && lastFinishedCrawl.createdAt
                ? dateFormatter(new Date(lastFinishedCrawl.createdAt), {
                    dateStyle: "medium",
                    timeStyle: "short",
                  })
                : t("common:notApplicable"),
            count: segmentCount,
          })}
        </Typography>
      </Box>
      <CrawlSourcesIcons sources={sources} hideInactive />
    </Box>
  );
}
