/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import React from "react";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlType,
  GetStatusCodeChartDataDocument,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";

export function getBotHitsbyStatusCode(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "GqlQuery",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.StackedColumn,
    visualisationColors: ["red"],
    additionalVisualisationTypes: [
      VisualisationTypes.Line,
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedColumn,
      VisualisationTypes.FullStackedArea,
    ],
    requiredSources: {
      sources: [CrawlType.LogSummary],
      incompleteMessage: t("requiredSourcesMessage.logsSource"),
    },
    title: () => t("botHitsbyStatusCode.title"),
    description: () => (
      <>
        <Typography paragraph variant="inherit">
          {t("botHitsbyStatusCode.description")}
        </Typography>
      </>
    ),
    gqlDocument: GetStatusCodeChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        crawlId: crawlContext.crawl.rawID,
        segmentId: crawlContext.selectedCrawlSegment?.segment.rawID,
        reportTemplateCode: "pages_in_log_summary",
        httpStatusCodeFilter: { gt: 299 },
      };
    },

    xAxisLabel: "HTTP Status Code",
    series: (reports, linkData) => {
      return reports.map((report) => ({
        name: report.reportTemplateName,
        reportTemplateCode: report.reportTemplateCode,

        data: (report.crawlUrlsAggregates?.nodes ?? []).map(
          (crawlUrlsAggregate) => {
            const depthHttpStatusCode = crawlUrlsAggregate?.httpStatusCode ?? 0;
            const urlCount = crawlUrlsAggregate?.count?.url ?? 0;

            return {
              x: depthHttpStatusCode,
              y: urlCount,
              url: Routes.Report.getUrl({
                ...linkData,
                reportTemplateCode: report.reportTemplateCode,
                reportTypeCode: "basic",
                filter: createConnectionFilter({
                  or: [
                    {
                      and: [
                        {
                          metricCode: "httpStatusCode",
                          predicateKey: ConnectionPredicate.Eq,
                          predicateValue: depthHttpStatusCode,
                        },
                      ],
                    },
                  ],
                }),
              }),
              additionalTooltipLines: [
                t("botHitsbyStatusCode.depthHttpStatusCode", {
                  depthHttpStatusCode,
                }),
              ],
            };
          },
        ),
      }));
    },
  };
}
