import React from "react";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import {
  getFormFilter,
  getFormOrFilter,
  getDefaultFilterMetric,
  cloneFilter,
} from "../../../_common/connection-filtering/helpers";
import { mapOrFormFiltersToDatasourceConnectionFilter } from "../../../_common/connection-filtering/filter-or-rule-field-array/mapOrFormFiltersToDatasourceConnectionFilter";
import { getFilterOrRuleArrayValidationSchema } from "../../../_common/connection-filtering/filter-or-rule-field-array/getFilterOrRuleValidationSchema";
import { LocalFilterFormValues } from "./types";
import { LocalFilterForm } from "./LocalFilterForm";
import { mapDatasourceConnectionFilterToOrFormFilters } from "../../../_common/connection-filtering/filter-or-rule-field-array/mapDatasourceConnectionFilterToOrFormFilters";
import arePropsEqual from "react-fast-compare";
import { isNotEmptyConnectionFilter } from "../../../_common/connection-filtering/isNotEmptyConnectionFilter";
import {
  ConnectionFilter,
  FilterOrRuleFormValue,
  FilterRuleFormValue,
  NarrowMetric,
} from "../../../_common/connection-filtering/types";
import { useTranslation } from "@lumar/shared";
import { GridContext } from "../report-grid/ReportGrid";
import { Box, CircularProgress } from "@material-ui/core";
import { ConnectionFilterWithCustomMetrics } from "@lumar/shared";

interface Props {
  onApply: (arg: ConnectionFilterWithCustomMetrics) => void;
  onReset: () => void;
  onCancel: () => void;
  filters?: ConnectionFilter;
}

const LocalFilterInner = React.forwardRef<HTMLDivElement, Props>(
  function LocalFilterInner(props, ref): JSX.Element {
    const { t } = useTranslation("connectionFiltering");
    const { onApply, onReset, onCancel, filters = {} } = props;

    const { filterMetrics, datasourceCode } = React.useContext(GridContext);

    const validationSchema = React.useMemo(
      () =>
        Yup.object({
          filters: getFilterOrRuleArrayValidationSchema(filterMetrics, t),
        }),
      [filterMetrics, t],
    );

    const defaultFilter = React.useMemo(
      () =>
        getFormFilter(getDefaultFilterMetric(filterMetrics, datasourceCode)),
      [filterMetrics, datasourceCode],
    );

    const formValues = React.useMemo(() => {
      if (!filterMetrics) {
        return { filters: [] };
      }
      if (isNotEmptyConnectionFilter(filters)) {
        return {
          filters: mapDatasourceConnectionFilterToOrFormFilters(filters),
        };
      }
      return { filters: [getFormOrFilter(defaultFilter)] };
    }, [filters, filterMetrics, defaultFilter]);

    const onSubmit = React.useCallback(
      (data: LocalFilterFormValues) => {
        // FIXME: Formik doesn't respect `transform` and `trim` in yup schema.
        // Formik devs will fix it in this pr: https://github.com/jaredpalmer/formik/pull/2255
        // This is why `schema.cast` has to be used.
        onApply(
          mapOrFormFiltersToDatasourceConnectionFilter(
            validationSchema.cast(data)?.filters || [],
          ),
        );
      },
      [onApply, validationSchema],
    );

    if (!filterMetrics.length) {
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress
            style={{ display: "block" }}
            size={24}
            data-testid="local-filter-loading"
          />
        </Box>
      );
    }

    return (
      <div data-testid="local-filter" ref={ref}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={false}
          onSubmit={onSubmit}
          // Note: Not adding `onReset` callback because it is called when new `initialValues` are provided.
          // Example issue: Updating filter through history makes Formik form trigger onReset which usually empties the filter search param after navigating.
          // - Michal
        >
          {(props) => (
            <LocalFilterFormWrapper
              {...props}
              filterMetrics={filterMetrics}
              defaultFilter={defaultFilter}
              onReset={onReset}
              onCancel={onCancel}
            />
          )}
        </Formik>
      </div>
    );
  },
);

interface LocalFilterFormWrapperProps
  extends FormikProps<{
    filters: FilterOrRuleFormValue[];
  }> {
  filterMetrics: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  onReset: () => void;
  onCancel: () => void;
}

function LocalFilterFormWrapper({
  values,
  resetForm,
  defaultFilter,
  filterMetrics,
  onReset,
  onCancel,
}: LocalFilterFormWrapperProps): JSX.Element {
  const onLastRemainingRuleDeleted = React.useCallback(() => {
    resetForm({
      values: {
        filters: [getFormOrFilter(cloneFilter(defaultFilter))],
      },
    });
    onReset();
  }, [defaultFilter, onReset, resetForm]);

  return (
    <LocalFilterForm
      values={values}
      metricsWithPredicates={filterMetrics}
      defaultFilter={defaultFilter}
      // FIXME: Ideally, these two callbacks should be created via `React.useCallback`.
      // Not doing it now because it'll require reworking this component to use `withFormik` HoC. - Michal
      resetForm={() => {
        resetForm();
        onReset();
      }}
      onLastRemainingRuleDeleted={onLastRemainingRuleDeleted}
      onCancel={onCancel}
    />
  );
}

export const LocalFilter = React.memo(LocalFilterInner, arePropsEqual);
