import {
  CustomExtractionSetting,
  Maybe,
  UpdateProjectInput,
} from "../../../../../graphql";
import { CustomExtractionSettings } from "../types";

type QueryData = {
  customExtractions?: Maybe<
    Array<
      Pick<
        CustomExtractionSetting,
        | "label"
        | "regex"
        | "matchNumberTo"
        | "regexFilter"
        | "cleanHtmlTags"
        | "reportTemplateCode"
      >
    >
  >;
};

export function formatCustomExtractionSettingsFrom(
  data?: QueryData | null,
): CustomExtractionSettings {
  return {
    rules:
      data?.customExtractions?.map((extraction) => ({
        label: extraction.label,
        pattern: extraction.regex,
        matchNumber: extraction.matchNumberTo,
        strip: extraction.regexFilter ?? "",
        cleanHtmlTags: extraction.cleanHtmlTags ?? false,
        reportTemplateCode: extraction.reportTemplateCode,
      })) ?? [],
  };
}

export function formatCustomExtractionSettingsTo(
  formValues: CustomExtractionSettings,
): Pick<UpdateProjectInput, "customExtractions"> {
  const availableReportTemplateCodes = Array(30)
    .fill(null)
    .map((_, idx) => `custom_extraction_${idx + 1}`)
    .filter(
      (x) =>
        !formValues.rules.find(
          (extraction) => extraction.reportTemplateCode === x,
        ),
    );

  return {
    customExtractions: formValues.rules.map((extraction) => ({
      label: extraction.label,
      regex: extraction.pattern,
      matchNumberFrom: 0,
      matchNumberTo: extraction.matchNumber,
      regexFilter: extraction.strip,
      cleanHtmlTags: extraction.cleanHtmlTags,
      reportTemplateCode:
        // eslint-disable-next-line fp/no-mutating-methods
        extraction.reportTemplateCode || availableReportTemplateCodes.shift(),
    })),
  };
}
