import React from "react";
import {
  isCrawlInProgress,
  isCrawlUnarchiving,
} from "../../../../account-projects/projects-filter/helpers";
import {
  GetCrawlSettingsForDomainQuery,
  ModuleCode,
} from "../../../../graphql";
import { ContextValues } from "./types";
import { DefaultContextValues } from "../../settings/data/useContextValues";
import { UserAgentType } from "../../settings/types";

export function useContextValues(
  data: GetCrawlSettingsForDomainQuery | undefined,
  moduleCode: ModuleCode | undefined,
): ContextValues {
  return React.useMemo(() => {
    const lastCrawl = data?.getProject?.lastCrawl.nodes[0];

    return {
      hasJsRendering:
        data?.getAccount?.subscription?.jsRenderingAvailable ?? false,
      hasCrawlInProgress: isCrawlInProgress(lastCrawl?.statusEnum),
      hasCrawlUnarchiving: isCrawlUnarchiving(
        lastCrawl?.statusEnum,
        lastCrawl?.finishedAt,
      ),
      industries: data?.getIndustries?.nodes ?? [],
      userAgents: getUserAgents(data),
      userAgentSuffix: data?.getAccount?.userAgentSuffix ?? "",
      module:
        data?.getModules?.find((module) => module.code === moduleCode) ??
        DefaultContextValues.module,
    };
  }, [data, moduleCode]);
}

function getUserAgents(
  data: GetCrawlSettingsForDomainQuery | undefined,
): UserAgentType[] {
  const projectUserAgent = data?.getProject?.userAgent;
  const publicUserAgents = (data?.getUserAgents ?? []).map((agent) => ({
    ...agent,
    isPublic: true,
  }));

  return [
    ...publicUserAgents,
    ...(projectUserAgent &&
    !publicUserAgents.find((x) => x.code === projectUserAgent.code)
      ? [{ ...projectUserAgent, isPublic: false }]
      : []),
  ];
}
