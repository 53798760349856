import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowUpIcon, ArrowDownIcon, TriangleUpIcon, TriangleDownIcon, } from "../../icons";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    icon: {
        width: 13,
        height: 13,
    },
}));
export function PercentageChangeIconArrow({ count, }) {
    const classes = useStyles();
    if (count > 0) {
        return _jsx(ArrowUpIcon, { className: classes.icon });
    }
    else if (count < 0) {
        return _jsx(ArrowDownIcon, { className: classes.icon });
    }
    else {
        return _jsx(_Fragment, {});
    }
}
export function PercentageChangeIconTriangle({ count, }) {
    const classes = useStyles();
    if (count > 0) {
        return _jsx(TriangleUpIcon, { className: classes.icon });
    }
    else if (count < 0) {
        return _jsx(TriangleDownIcon, { className: classes.icon });
    }
    else {
        return _jsx(_Fragment, {});
    }
}
