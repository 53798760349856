import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getSecurityTrafficExperienceChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("securityTrafficExperienceChart.title"),
    description: t("securityTrafficExperienceChart.description"),
    reportTemplateCodes: ["https_pages_with_clicks", "http_pages_with_clicks"],
  })();
}
