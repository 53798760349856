import React from "react";
import { CrawlSourcesIcons } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRowData } from "@mui/x-data-grid-pro";

import { GridContext } from "../../../ReportGrid";
import { FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP } from "../../../../../../_common/constants/found-in-metric-to-crawl-type";
import { CrawlType } from "../../../../../../graphql";
import { ReportGridColumn } from "../../ReportGridColumns.types";

const useStyles = makeStyles((theme) => ({
  sources: { color: theme.palette.grey[500] },
}));

export function FoundInSources(props: {
  foundInSources: ReportGridColumn[];
  row: GridRowData;
}): JSX.Element {
  const classes = useStyles();
  const { crawlTypesMetadata } = React.useContext(GridContext);

  const sources = props.foundInSources.map((source) => {
    const crawlType =
      FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP.get(source.code) ?? CrawlType.Web;
    return {
      code: crawlType,
      name: crawlTypesMetadata.find((x) => x.code === crawlType)?.name ?? "",
      active: props.row[source.code],
    };
  });

  return (
    <CrawlSourcesIcons
      sources={sources}
      size="small"
      hideInactive
      className={classes.sources}
    />
  );
}
