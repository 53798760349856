import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FieldProps } from "formik";
import { Link, MenuItem } from "@material-ui/core";
import {
  formToCheckboxProps,
  formToSelectProps,
  formToTextFieldProps,
  Select,
  TextField,
  Trans,
  useSession,
} from "@lumar/shared";
import { Link as RouterLink, useParams } from "react-router-dom";

import { DomainInputs } from "./DomainInputs";
import { Routes } from "../../../../_common/routing/routes";
import { FormValues } from "../data/types";
import { Label } from "../../../../_common/components/Label";
import { DomainContext } from "../Domain";
import { CheckboxWithLabel } from "../../components/CheckboxWithLabel";
import { JsRenderingCheckbox } from "../../components/JsRenderingCheckbox";
import { ModuleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(2, 4),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  projectName: {
    width: "400px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  industry: {
    width: "400px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  placeholder: {
    color: "#9b9ea4",
  },
}));

export function BasicSettings({
  form,
  field,
}: FieldProps<FormValues["basic"]>): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const { hasAddon } = useSession();

  const { industries, hasJsRendering, module } =
    React.useContext(DomainContext);

  const industryAvailable = hasAddon("industry-benchmarks");

  return (
    <div className={classes.root}>
      <DomainInputs />

      <CheckboxWithLabel
        {...formToCheckboxProps(form, "basic.crawlSubDomains")}
        label={<CrawlSubDomiansLabel />}
        className={classes.checkbox}
        data-testid="crawl-sub-domains"
      />

      <CheckboxWithLabel
        {...formToCheckboxProps(form, "basic.crawlHttpAndHttps")}
        label={t("crawlHttpAndHttpsLabel")}
        className={classes.checkbox}
        data-testid="crawl-http-and-https"
        data-pendo="crawl-both-http-and-https"
      />

      <TextField
        {...formToTextFieldProps(form, "basic.name")}
        label={t("domain.projectNameLabel")}
        placeholder={t("domain.projectNamePlaceholder")}
        className={classes.projectName}
        data-testid="crawl-settings-domain-project-name"
      />

      {industryAvailable && (
        <Select
          {...formToSelectProps(form, "basic.industry")}
          label={t("domain.industryLabel")}
          renderValue={
            !field.value.industry
              ? () => (
                  <span className={classes.placeholder}>
                    {t("domain.industryPlaceholder")}
                  </span>
                )
              : undefined
          }
          displayEmpty
          className={classes.industry}
          data-pendo="industry-dropdown-selector"
        >
          {industries.map((industry) => (
            <MenuItem key={industry.code} value={industry.code}>
              {industry.label}
            </MenuItem>
          ))}
        </Select>
      )}

      {module.code !== ModuleCode.Accessibility && (
        <JsRenderingCheckbox
          {...formToCheckboxProps(form, "basic.useRenderer")}
          hasJsRendering={hasJsRendering}
          className={classes.checkbox}
        />
      )}
    </div>
  );
}

function CrawlSubDomiansLabel(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();

  const advancedSettingsLink =
    projectId &&
    Routes.Crawls.getUrl({
      accountId,
      projectId,
      tab: "edit",
      step: 4,
    }) + "&advanced=true#advanced-settings";

  return (
    <Label
      label={t("crawlSubDomainsLabel")}
      info={
        <Trans
          ns="crawlSettings"
          i18nKey="crawlSubdomainsAdditionalInfo"
          components={{
            seconadryDomain: advancedSettingsLink ? (
              <Link
                color="primary"
                component={RouterLink}
                to={advancedSettingsLink}
              />
            ) : (
              <span />
            ),
          }}
        />
      }
      TooltipProps={{ interactive: true }}
    />
  );
}
