import { ReportTypeCode } from "../../graphql";

type ReportCodes = {
  reportTypeCode: string;
  reportTemplateCode: string;
};

export function getReportCodes(
  reportTemplateCodeWithTypeCode: string,
): ReportCodes {
  const isEmpty = reportTemplateCodeWithTypeCode.length === 0;
  if (isEmpty) {
    return {
      reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
      reportTemplateCode: "all_pages",
    };
  }

  const isComingFromLegacyApi =
    reportTemplateCodeWithTypeCode.split("").filter((char) => char === ":")
      .length === 2;
  if (isComingFromLegacyApi) {
    const reportTemplateCodeList = reportTemplateCodeWithTypeCode.split(":");
    return {
      reportTypeCode: reportTemplateCodeList[2],
      reportTemplateCode: reportTemplateCodeList[1],
    };
  }

  const isLegacyUIParam = reportTemplateCodeWithTypeCode.includes("_");
  if (isLegacyUIParam) {
    const lastUnderscoreIndex = reportTemplateCodeWithTypeCode.lastIndexOf("_");
    return {
      reportTemplateCode: reportTemplateCodeWithTypeCode.substring(
        0,
        lastUnderscoreIndex,
      ),
      reportTypeCode: reportTemplateCodeWithTypeCode.substring(
        lastUnderscoreIndex + 1,
      ),
    };
  }

  return {
    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
    reportTemplateCode: reportTemplateCodeWithTypeCode,
  };
}
