import { uniq } from "lodash";
import { useContext } from "react";
import { GridContext } from "../../ReportGrid";

export function useSelectedFields(): string[] | undefined {
  const { apiRef, cardMetrics, foundInSources } = useContext(GridContext);
  const allMetrics = apiRef.current.state.columns.all;
  const lookup = apiRef.current.state.columns.lookup;

  const result = allMetrics.reduce((selected, metric) => {
    const visible =
      metric === lookup[metric].field && lookup[metric].hide !== true;
    if (!visible) return selected;

    if (metric === "card") {
      const card = [...cardMetrics, ...foundInSources].map((c) => c.code);
      return [...selected, ...card];
    }

    return [...selected, metric];
  }, [] as string[]);

  return uniq(result);
}
