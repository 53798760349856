import { ReportTypeCode } from "../graphql";

export enum ReportEntity {
  LegacyReport = "Report",
  CustomReport = "CustomReport",
  ReportStat = "ReportStat",
}

export type LegacyReportInput = {
  reportEntity: ReportEntity.LegacyReport;
  crawlId: string;
  reportTypeCode: ReportTypeCode;
  reportTemplateCode: string;
  segmentId?: string;
};

export type CustomReportInput = {
  reportEntity: ReportEntity.CustomReport;
  crawlId: string;
  reportTypeCode: ReportTypeCode;
  customReportTemplateId: string;
  reportTemplateCode: string;
  segmentId?: string;
};

export type ReportStatInput = {
  reportEntity: ReportEntity.ReportStat;
  crawlId: string;
  reportTypeCode: ReportTypeCode;
  reportTemplateCode: string;
  segmentId?: string;
};

export type ReportInput =
  | ReportStatInput
  | CustomReportInput
  | LegacyReportInput;

export type MetricsGroupings = Array<{
  metrics: Array<{
    code: string;
  }>;
}>;
