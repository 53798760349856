import { Snackbar, useSession } from "@lumar/shared";
import { useSnackbar } from "notistack";
import React from "react";

import { useUpdatableState } from "../../_common/hooks/useUpdatableState";
import { useSetReportAdviceCollapsedMutation } from "../../graphql";

export function useReportInfoState(
  defaultReportAdviceCollapsed: boolean,
): [boolean, (open: boolean) => void] {
  const { enqueueSnackbar } = useSnackbar();
  const { isUsingSharedLink } = useSession();

  const [isCollapsed, setIsCollapsed] = useUpdatableState(
    isUsingSharedLink ? true : defaultReportAdviceCollapsed,
  );

  const [updateCollapsed] = useSetReportAdviceCollapsedMutation({
    onError: (error) =>
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />),
  });

  const handleCollapsed = React.useCallback(
    (collapsed: boolean) => {
      setIsCollapsed(collapsed);
      updateCollapsed({
        variables: {
          input: collapsed,
        },
      });
    },
    [setIsCollapsed, updateCollapsed],
  );

  if (isUsingSharedLink) return [isCollapsed, setIsCollapsed];
  return [isCollapsed, handleCollapsed];
}
