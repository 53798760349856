var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Dialog, DialogActions, DialogContent, } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ErrorLogo } from "./ErrorLogo";
import clsx from "clsx";
import { WarningLogo } from "./WarningLogo";
import { SuccessLogo } from "./SuccessLogo";
import { InfoLogo } from "./InfoLogo";
import { AppThemeProvider } from "../../theme/AppThemeProvider";
import { Typography } from "../typography/Typography";
import { Button } from "../buttons/Button";
import { useTranslation } from "../../i18n";
function ConfirmationDialogInner(_a) {
    var { variant, title, content, primaryAction, secondaryAction, withGreyFooter, primaryButtonProps: primaryProps, secondaryButtonProps: secondaryProps, classes } = _a, dialogProps = __rest(_a, ["variant", "title", "content", "primaryAction", "secondaryAction", "withGreyFooter", "primaryButtonProps", "secondaryButtonProps", "classes"]);
    const { t } = useTranslation("sharedCommon");
    const getProps = () => {
        switch (variant) {
            case "info":
                return {
                    icon: InfoLogo,
                    iconClass: classes === null || classes === void 0 ? void 0 : classes.infoIcon,
                };
            case "success":
                return {
                    icon: SuccessLogo,
                    iconClass: classes === null || classes === void 0 ? void 0 : classes.successIcon,
                };
            case "warning":
                return {
                    icon: WarningLogo,
                    iconClass: classes === null || classes === void 0 ? void 0 : classes.warningIcon,
                };
            case "error":
                return {
                    icon: ErrorLogo,
                    iconClass: classes === null || classes === void 0 ? void 0 : classes.errorIcon,
                    buttonClass: classes === null || classes === void 0 ? void 0 : classes.errorButton,
                };
            default:
                return {};
        }
    };
    const props = getProps();
    const _b = primaryProps !== null && primaryProps !== void 0 ? primaryProps : {}, { label: primaryActionText = t("ok") } = _b, primaryButtonProps = __rest(_b, ["label"]);
    const _c = secondaryProps !== null && secondaryProps !== void 0 ? secondaryProps : {}, { label: secondaryActionText = t("cancel") } = _c, secondaryButtonProps = __rest(_c, ["label"]);
    return (_jsxs(Dialog, Object.assign({}, dialogProps, { children: [_jsx(DialogContent, { className: classes === null || classes === void 0 ? void 0 : classes.dialogContent, children: _jsxs(AppThemeProvider, { children: [props.icon && (_jsx(props.icon, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.icon, props.iconClass) })), _jsxs(Box, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.content, props.icon ? classes === null || classes === void 0 ? void 0 : classes.contentWithIcon : ""), children: [_jsx(Typography, { variant: "h6SemiBold", className: classes === null || classes === void 0 ? void 0 : classes.title, children: title }), _jsx(Typography, { component: "div", variant: "body2", children: content })] })] }) }), _jsxs(DialogActions, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.footer, withGreyFooter ? classes === null || classes === void 0 ? void 0 : classes.greyFooter : ""), children: [secondaryAction && (_jsx(Button, Object.assign({ onClick: secondaryAction, variant: "outlined" }, secondaryButtonProps, { children: secondaryActionText }))), primaryAction && (_jsx(Button, Object.assign({ autoFocus: true, onClick: primaryAction, variant: "contained", color: "primary", className: clsx(props.buttonClass) }, primaryButtonProps, { children: primaryActionText })))] })] })));
}
export const ConfirmationDialog = withStyles((theme) => ({
    dialogContent: {
        display: "flex",
        padding: theme.spacing(2, 2, 1, 2),
        "&:first-child": {
            paddingTop: theme.spacing(2),
        },
    },
    icon: {
        fontSize: theme.typography.pxToRem(56),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    infoIcon: {
        color: theme.palette.blue[500],
    },
    successIcon: {
        color: theme.palette.green[500],
    },
    warningIcon: {
        color: theme.palette.yellow[400],
    },
    errorIcon: {
        color: theme.palette.red[500],
    },
    content: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "324px",
        color: theme.palette.grey[600],
    },
    contentWithIcon: {
        maxWidth: "360px",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(1.25),
        color: theme.palette.grey[900],
    },
    footer: {
        padding: theme.spacing(2),
    },
    greyFooter: {
        background: theme.palette.grey[50],
        padding: theme.spacing(1.5, 2),
    },
    errorButton: {
        background: theme.palette.red[500],
        "&:hover": {
            background: theme.palette.red[600],
        },
    },
}))(ConfirmationDialogInner);
