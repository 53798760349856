import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";
import { Task } from "../../data/types";

export function UrlCounCell({ task }: { task: Task }): JSX.Element {
  const { t } = useTranslation("units");

  return (
    <Typography variant="body2">
      {t("number", { count: task.remainingResolved })}
    </Typography>
  );
}
