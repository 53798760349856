import { MetricType, ConnectionPredicate, DatasourceCode } from "../../graphql";
import {
  NarrowMetric,
  FilterRuleFormValue,
  FilterOrRuleFormValue,
  Predicate,
} from "./types";
import { v4 as uuid } from "uuid";
import { DateFormatter } from "@lumar/shared";

export function getPredicateType(
  metrics: NarrowMetric[],
  metricCode: string,
  predicateCode: string,
): MetricType | undefined {
  return metrics
    .find((m) => m.code === metricCode)
    ?.connectionPredicates.find((predicate) => predicate.code === predicateCode)
    ?.type;
}

export function getDefaultPredicate(
  metric: NarrowMetric | undefined,
): Predicate | undefined {
  return (
    metric?.connectionPredicates?.find(
      (x) => x.code === ConnectionPredicate.Contains,
    ) ||
    metric?.connectionPredicates?.find(
      (x) => x.code === ConnectionPredicate.Eq,
    ) ||
    metric?.connectionPredicates?.[0]
  );
}

export function getDefaultPredicateValue(
  predicateType: MetricType,
): string | boolean | Date {
  switch (predicateType) {
    case MetricType.Boolean:
    case MetricType.Bit:
      return true;
    case MetricType.Date:
      return new Date();
    default:
      return "";
  }
}

export function getDefaultFilterMetric(
  metrics: NarrowMetric[],
  datasource?: DatasourceCode,
): NarrowMetric {
  const defaultMetrics: Partial<Record<DatasourceCode, string>> = {
    [DatasourceCode.CrawlAccessibilityIssueInstances]: "elementSelector",
    [DatasourceCode.CrawlAccessibilityIssues]: "exampleElementSelector",
  };

  const metric = (datasource && defaultMetrics[datasource]) || "url";

  return metrics.find((x) => x.code === metric) || metrics[0];
}

export function getFormOrFilter(
  filter: FilterRuleFormValue | FilterRuleFormValue[],
): FilterOrRuleFormValue {
  return {
    id: uuid(),
    _and: Array.isArray(filter) ? filter : [filter],
  };
}

export function cloneFilter(filter: FilterRuleFormValue): FilterRuleFormValue {
  return {
    ...filter,
    id: uuid(),
  };
}

export function getFormFilter(
  metric: NarrowMetric | undefined,
): FilterRuleFormValue {
  const defaultPredicate = getDefaultPredicate(metric);

  return {
    id: uuid(),
    metricCode: metric?.code || "",
    predicateKey: defaultPredicate?.code || "",
    predicateValue: defaultPredicate
      ? getDefaultPredicateValue(defaultPredicate.type)
      : "",
  };
}

export function mapFilterRuleFormValuesToNames(
  metrics: NarrowMetric[],
  metricCode: string,
  predicateCode: string,
  predicateValue: string | number | Date | boolean,
  formatDate: DateFormatter,
): {
  metricName: string;
  predicateName: string;
  predicateValue: string;
  predicateType?: string;
} {
  const metric = metrics.find((metric) => metric.code === metricCode);
  const metricName = metric?.name ?? metricCode;

  const predicate = metric?.connectionPredicates.find(
    (predicate) => predicate.code === predicateCode,
  );
  const predicateName = predicate?.name ?? predicateCode;

  const predicateType = getPredicateType(metrics, metricCode, predicateCode);

  const formattedPredicateValue =
    predicateType === MetricType.Date
      ? formatDate(
          predicateValue instanceof Date
            ? predicateValue
            : new Date(String(predicateValue)),
          { dateStyle: "medium" },
        )
      : String(predicateValue);

  return {
    metricName,
    predicateName,
    predicateValue: formattedPredicateValue,
    predicateType,
  };
}
