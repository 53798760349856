import React from "react";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { TextField } from "formik-material-ui";
import { FastField, FieldProps, getIn } from "formik";
import {
  NarrowMetric,
  FilterOrRuleFormValue,
  FilterRuleFormValue,
} from "../../_common/connection-filtering/types";
import { FilterOrRuleFieldArray } from "../../_common/connection-filtering/filter-or-rule-field-array/FilterOrRuleFieldArray";
import {
  InfoOutlined,
  Typography,
  Trans,
  useTranslation,
  updateIfPropsChanged,
} from "@lumar/shared";
import { Grid } from "@material-ui/core";
import { CustomSkeleton } from "../../_common/CustomSkeleton";
import { GroupField } from "./GroupField";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2, 0, 2),
  },
  segmentRow: {
    paddingTop: theme.spacing(3),
  },
  segmentName: {
    width: "100%",
  },
  label: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 500,
    paddingBottom: theme.spacing(0.875),
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    padding: theme.spacing(1.5, 1.5, 2, 1.5),
    minWidth: "490px",
    maxWidth: "490px",
  },
  tooltipIcon: {
    color: theme.palette.grey[500],
    boxSizing: "content-box",
    marginLeft: theme.spacing(0.5),
    width: "18px",
    height: "18px",
  },
  tooltipTitle: {
    paddingTop: theme.spacing(0.875),
  },
}));

interface Props {
  segmentNameFieldName: string;
  segmentGroupFieldName: string;
  segmentRulesOrFieldArrayName: string;
  rulesValue: FilterOrRuleFormValue[];
  metrics: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  groups: string[];
  groupsLoading?: boolean;
}

// TODO: Add tests for tooltip - not tested at integration levels above
// in CreateSegment or CopySegmentDialog where component is used. - Saul.

// IMPROVEMENT: Align labels with input fields. Clicking the "Segment name"
// label does not select the input field - Saul.

export function SegmentFormInputs(props: Props): JSX.Element {
  const {
    segmentNameFieldName,
    segmentGroupFieldName,
    segmentRulesOrFieldArrayName,
    rulesValue,
    metrics,
    defaultFilter,
    groups,
    groupsLoading,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation("segments");

  return (
    <div className={classes.root}>
      <Grid container className={classes.segmentRow} spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.segmentName}>
            <Typography className={classes.label}>
              {t("segmentName")}
            </Typography>
            <FastField
              hiddenLabel
              name={segmentNameFieldName}
              variant="outlined"
              component={TextField}
              placeholder={t("sampleSegment")}
              fullWidth
              inputProps={{ "data-testid": "segment-name-input" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          {groupsLoading ? (
            <>
              <Typography className={classes.label}>
                {t("segmentGroup")}
              </Typography>
              <CustomSkeleton
                width="100%"
                height="36px"
                variant="rect"
                animation="wave"
              />
            </>
          ) : (
            <FastField
              name={segmentGroupFieldName}
              groups={groups}
              shouldUpdate={updateIfPropsChanged(["groups"])}
            >
              {({ field, form }: FieldProps<string | undefined>) => (
                <GroupField
                  value={!field.value ? t("defaultGroup") : field.value}
                  groups={[t("defaultGroup"), ...groups]}
                  onChange={(value) =>
                    form.setFieldValue(
                      segmentGroupFieldName,
                      value === t("defaultGroup") ? null : value,
                    )
                  }
                  disabled={form.isSubmitting}
                  error={Boolean(getIn(form.errors, segmentGroupFieldName))}
                  helperText={getIn(form.errors, segmentGroupFieldName)}
                />
              )}
            </FastField>
          )}
        </Grid>
      </Grid>
      <Box paddingTop={2}>
        <Typography className={classes.label}>
          {t("segmentRules")}{" "}
          <Tooltip
            title={
              <Typography variant="caption">
                <Trans
                  ns="segments"
                  i18nKey="segmentRulesTooltip"
                  components={{
                    label: <Typography variant="subtitle3Medium" />,
                    regexLink: (
                      <Link
                        color="inherit"
                        href="https://www.elastic.co/guide/en/elasticsearch/reference/current/regexp-syntax.html"
                        target="_blank"
                        rel="noopener"
                      />
                    ),
                  }}
                />
              </Typography>
            }
            arrow
            placement="top"
            interactive
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlined className={classes.tooltipIcon} fontSize="small" />
          </Tooltip>
        </Typography>
        <FilterOrRuleFieldArray
          name={segmentRulesOrFieldArrayName}
          metrics={metrics}
          defaultFilter={defaultFilter}
          value={rulesValue}
        />
      </Box>
    </div>
  );
}
