import { useSession } from "@lumar/shared";
import { CustomReportTemplateManager } from "./CustomReportTemplateManager";
import { Redirect } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { assert } from "../../_common/assert";
import { RoleCode } from "../../graphql";
export const CustomReportTemplateManagerContainer = (): JSX.Element => {
  const { accountId, projectId, crawlId } = useGenericParams();
  const { hasSufficientRole, hasFeatureFlagEnabled } = useSession();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  if (!hasFeatureFlagEnabled("custom-reports")) {
    return <Redirect to={Routes.Account.getUrl({ accountId })} />;
  }

  if (!hasSufficientRole(RoleCode.Editor)) {
    return (
      <Redirect
        to={Routes.CustomReports.getUrl({ accountId, projectId, crawlId })}
      />
    );
  }

  return <CustomReportTemplateManager />;
};
