import { makeStyles } from "@material-ui/core";
import success from "./animations/success.json";
import Lottie from "react-lottie-player";
import { EmptyState } from "@lumar/shared";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: "100%",
  },
}));

export function ChartPanelSuccessMessage(props: {
  children: string;
  allowRefresh?: boolean;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <EmptyState
        description={props.children}
        icon={
          <Lottie
            loop={false}
            animationData={success}
            play
            style={{
              width: "88px",
              height: "88px",
            }}
          />
        }
      />
    </div>
  );
}
