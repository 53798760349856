export enum ReportImpact {
  Negative = "Negative",
  Neutral = "Neutral",
  Positive = "Positive",
}

export function getReportImpact(sign?: number | null): ReportImpact {
  if (!sign) return ReportImpact.Neutral;
  if (sign < 0) return ReportImpact.Negative;
  return ReportImpact.Positive;
}
