import { useTranslation } from "@lumar/shared";
import { ResourceDetailData } from "../data/types";
import { MetricsGroups, MetricsGroupsConfig } from "./MetricsGroups";

export function ResponseHeader({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  const config: MetricsGroupsConfig = [
    {
      name: t("groupResponseHeader"),
      items: Object.entries(data.metrics["rawHeader"].value).map(
        ([key, value]) => ({
          code: key,
          name: key,
          value: typeof value === "string" ? value : JSON.stringify(value),
        }),
      ),
    },
  ];

  return <MetricsGroups config={config} />;
}
