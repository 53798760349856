/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Theme, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

function generateNonIndexablePagesChart(fixOrdering = false) {
  return function (t: TFunction<"charts">, theme: Theme): ChartConfigItem {
    return {
      inputSource: "CrawlContext",
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      //visualisationColors: [theme.palette.grey[500]],
      title: () => t("nonIndexablePages.title"),
      description: () => (
        <Trans ns="charts" i18nKey="nonIndexablePages.description">
          <Typography paragraph variant="inherit">
            dfdfdf
          </Typography>
          <Typography variant="inherit">dfdfdf</Typography>
        </Trans>
      ),
      reportStatFilter: (report) => {
        if (fixOrdering) {
          return [
            "non_indexable_pages",
            "mobile_alternates",
            "noindex_pages",
            "canonicalized_pages",
            "nofollowed_pages",
            "disallowed_pages",
            "pages_with_unavailable_after_expired",
          ].includes(report.reportTemplateCode);
        }
        return [
          "mobile_alternates",
          "canonicalized_pages",
          "disallowed_pages",
          "nofollowed_pages",
          "noindex_pages",
          "non_indexable_pages",
          "pages_with_unavailable_after_expired",
        ].includes(report.reportTemplateCode);
      },
      reportStatsOrderBy: fixOrdering
        ? undefined
        : {
            field: "basic",
            direction: "desc",
          },
      reportTemplateCodesOrder: fixOrdering
        ? [
            "non_indexable_pages",
            "mobile_alternates",
            "noindex_pages",
            "canonicalized_pages",
            "nofollowed_pages",
            "disallowed_pages",
            "pages_with_unavailable_after_expired",
          ]
        : [
            "mobile_alternates",
            "canonicalized_pages",
            "disallowed_pages",
            "nofollowed_pages",
            "noindex_pages",
            "non_indexable_pages",
            "pages_with_unavailable_after_expired",
          ],
      serieName: (report) => {
        const reportTemplateCode = report.reportTemplateCode;
        return reportTemplateCode === "non_indexable_pages"
          ? t("non200Pages.total")
          : report.reportTemplateName;
      },
      count: (report) => {
        return report.basic ?? 0;
      },

      testAttributePrefix: "non-indexable-pages-chart",
    };
  };
}

export const getNonIndexablePagesChart = generateNonIndexablePagesChart();

export const getNonIndexablePagesChartWithFixedReportOrder =
  generateNonIndexablePagesChart(true);
