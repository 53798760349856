import { makeStyles, Tooltip } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { ProjectsGridRenderCellParams } from "../data/types";
import { useTranslation } from "@lumar/shared";
import React from "react";
import { useActiveCell } from "./useActiveCell";

const useStyles = makeStyles((theme) => ({
  cell: {
    width: "100%",
    height: "100%",
    color: theme.palette.grey[600],
    textDecoration: "none",
    display: "inline-block",
  },
  linkCell: {
    width: "100%",
    height: "100%",
    color: theme.palette.grey[600],
    textDecoration: "none",
    display: "inline-block",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[600],
      cursor: "pointer",
    },
    "&:hover div": {
      backgroundColor: theme.palette.grey[200],
      cursor: "pointer",
    },
  },
  tooltipPlacement: {
    bottom: 20,
  },
}));

interface RouteParams {
  accountId: string;
}

interface LinkCellProps {
  children: React.ReactNode;
  params: ProjectsGridRenderCellParams;
  disableOnZeroCount?: boolean;
  getHref: (
    cellParams: ProjectsGridRenderCellParams,
    routeParams: RouteParams,
  ) => string;
  tooltipTitle: string;
  "data-testid"?: string;
  "data-pendo"?: string;
}

export function LinkCell(props: LinkCellProps): JSX.Element {
  const { params } = props;
  const { t } = useTranslation("projectsList");
  const classes = useStyles();
  const routeParams = useParams<RouteParams>();
  const href = props.getHref(params, routeParams);
  const shouldDisableLinkOnZeroCount =
    props.disableOnZeroCount && Number(params.value) === 0;

  const { ref } = useActiveCell(
    t("clickableLink", {
      action: props.tooltipTitle,
    }),
  );

  if (!href || shouldDisableLinkOnZeroCount) {
    return <div className={classes.cell}>{props.children}</div>;
  }

  return (
    <Tooltip
      title={props.tooltipTitle}
      arrow
      placement="bottom"
      classes={{ tooltipPlacementBottom: classes.tooltipPlacement }}
    >
      <Link
        to={href}
        tabIndex={-1}
        ref={ref}
        className={classes.linkCell}
        data-testid={props["data-testid"]}
        data-pendo={props["data-pendo"]}
      >
        {props.children}
      </Link>
    </Tooltip>
  );
}
