import { Chip } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { isObject } from "lodash";
import { MetricsValuePresenterProps } from "../../data/types";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
}));

export function ArrayPresenter(props: MetricsValuePresenterProps): JSX.Element {
  const classes = useStyles();

  if (!Array.isArray(props.value) || !props.value.length) return <></>;

  return (
    <>
      {props.value.map((value, idx) => {
        const object = (() => {
          if (isObject(value)) {
            return value;
          }
          try {
            return JSON.parse(value);
          } catch {}
        })();

        const presentedValue = object ? getJsonList(object) : value || "-";

        return (
          <Chip
            key={idx}
            label={presentedValue}
            color="cyan"
            className={classes.chip}
          />
        );
      })}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function getJsonList(value: any): React.ReactNode {
  if (isObject(value))
    return (
      <ul style={{ paddingLeft: 10, margin: 0 }}>
        {Object.entries(value).map(([key, value], i) => (
          <li key={i} style={{ listStyleType: "initial" }}>
            {key}: {getJsonList(value)}
          </li>
        ))}
      </ul>
    );

  return value;
}
