import React from "react";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { Typography } from "@lumar/shared";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { LabelInfo } from "../../../../_common/components/LabelInfo";
import { SettingControlFactory } from "../useAdvancedControls";

export const getRedirectSettingsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.redirectSettings.title"),
  path: "redirectSettings",
  // eslint-disable-next-line react/display-name
  control: () => <RedirectSettings />,
  testId: "crawl-settings-advanced-redirect-settings",
  textForSearch: createTextForSearch([
    t("settings.redirectSettings.title"),
    t("settings.redirectSettings.description"),
    t("settings.redirectSettings.followInternalRedirects"),
    t("settings.redirectSettings.internalRedirectsLabelInfo"),
    t("settings.redirectSettings.followExternalRedirects"),
    t("settings.redirectSettings.externalRedirectsLabelInfo"),
  ]),
});

export const RedirectSettings = React.memo(RedirectSettingsInner);

function RedirectSettingsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  return (
    <>
      <Typography variant="caption" style={{ marginBottom: "8px" }}>
        {t("settings.redirectSettings.description")}
      </Typography>
      <FastField
        name="scope.redirectSettings.followInternalRedirects"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <LabelInfo
              label={t("settings.redirectSettings.followInternalRedirects")}
              info={t("settings.redirectSettings.internalRedirectsLabelInfo")}
            />
          ),
        }}
        data-testid="follow-internal-redirects"
      />

      <FastField
        name="scope.redirectSettings.followExternalRedirects"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <LabelInfo
              label={t("settings.redirectSettings.followExternalRedirects")}
              info={t("settings.redirectSettings.externalRedirectsLabelInfo")}
            />
          ),
        }}
        data-testid="follow-external-redirects"
      />
    </>
  );
}
