import { GridState } from "@mui/x-data-grid-pro";
import { isEqual } from "lodash";

export interface ColumnState {
  code: string;
  hide: boolean;
  width?: number;
}

export function writeColumnsState(
  name: string,
  gridState: GridState,
  defaultStates: ColumnState[],
  isGridView?: boolean,
): void {
  try {
    const key = getPersistenceKey(name, isGridView);

    const changedColumns = getChangedColumns(gridState, defaultStates);
    if (changedColumns !== undefined) {
      localStorage.setItem(key, JSON.stringify(changedColumns));
    } else {
      localStorage.removeItem(key);
    }
  } catch {}
}

export function readColumnsState(
  name: string,
  defaultStates: ColumnState[],
  columnsOverwrite?: (Partial<ColumnState> | null)[],
  isGridView?: boolean,
): ColumnState[] {
  try {
    const storedState = getStoredState(name, isGridView);
    const state = columnsOverwrite ?? storedState;

    if (!state) return defaultStates;

    return mergeColumnsWithDefaultState(defaultStates, state);
  } catch {
    return defaultStates;
  }
}

export function getStoredColumnsParam(
  name: string,
  isGridView?: boolean,
): string | undefined {
  return convertColumnsParamTo(getStoredState(name, isGridView));
}

export function getGridColumnsParam(
  gridState: GridState,
  defaultStates: ColumnState[],
): string | undefined {
  const changedColumns = getChangedColumns(gridState, defaultStates);
  return convertColumnsParamTo(changedColumns);
}

function getPersistenceKey(name: string, isGridView?: boolean): string {
  // This will be the key for the grid view version (the one with all data separated in columns)
  if (isGridView) return `grid_columns_grid_view_${name}`;
  // This will be the key for the table view version (the one with card details)
  return `grid_columns_${name}`;
}

function getStoredState(
  name: string,
  isGridView?: boolean,
): (Partial<ColumnState> | null)[] | undefined {
  try {
    const storedStateValue = localStorage.getItem(
      getPersistenceKey(name, isGridView),
    );

    return storedStateValue ? JSON.parse(storedStateValue) : undefined;
  } catch {
    return;
  }
}

function getChangedColumns(
  gridState: GridState,
  defaultStates: ColumnState[],
): (Partial<ColumnState> | null)[] | undefined {
  const defaultColumnStates = defaultStates.reduce<Partial<ColumnState>[]>(
    (state, column) => {
      if (column.hide) return state;
      return [...state, { code: column.code }];
    },
    [],
  );

  const currectColumnState = gridState.columns.all.reduce<
    Partial<ColumnState>[]
  >((state, code) => {
    const column = gridState.columns.lookup[code];
    if (column.hide) return state;

    const width = (() => {
      const isFlexWidth = column.width !== column.computedWidth;
      if (isFlexWidth) return {};

      const defaultState = defaultStates.find((x) => x.code === code);
      if (!defaultState || column.width === defaultState.width) return {};

      return { width: column.width };
    })();

    return [
      ...state,
      {
        code,
        ...width,
      },
    ];
  }, []);

  const isSameAsDefault = isEqual(defaultColumnStates, currectColumnState);
  return isSameAsDefault ? undefined : currectColumnState;
}

function mergeColumnsWithDefaultState(
  defaultStates: ColumnState[],
  states: (Partial<ColumnState> | null)[],
): ColumnState[] {
  const columns = defaultStates.map((defaultState, idx) => {
    const stateIdx = states.findIndex((x) => x?.code === defaultState.code);
    const state = stateIdx !== -1 ? states[stateIdx] : undefined;

    const width = Number(state?.width ?? defaultState.width);

    return {
      code: defaultState.code,
      index: stateIdx !== -1 ? stateIdx : idx,
      hide: !state,
      width: isNaN(width) || width < 0 ? 0 : width,
    };
  });
  // eslint-disable-next-line fp/no-mutating-methods
  return [...columns].sort((a, b) => a.index - b.index);
}

function convertColumnsParamTo(
  columnState: (Partial<ColumnState> | null)[] | undefined,
): string {
  if (columnState === undefined) return btoa("default");

  const columns: string[] = columnState.map((x) =>
    x?.code && !x.hide ? x.code : "",
  );
  return btoa(columns.join(","));
}

export function convertColumnsParamFrom(
  value: string,
): (Partial<ColumnState> | null)[] | undefined {
  const convertedValue = atob(value);
  if (convertedValue === "default") return;

  const columns = convertedValue.split(",");
  return columns.map((code) => (!code ? null : { code }));
}
