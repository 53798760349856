import { getRawCrawlId } from "@lumar/shared";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGetLastCrawlQuery } from "../../graphql";

export const LATEST_CRAWL_PARAM = "latestCrawl";

export function useLatestCrawlRedirect(): void {
  const { projectId, crawlId } = useParams<{
    projectId?: string;
    crawlId?: string;
  }>();
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const latestCrawl = searchParams.get(LATEST_CRAWL_PARAM);

  useGetLastCrawlQuery({
    variables: { projectId: projectId ?? "" },
    skip: !projectId || !crawlId || !latestCrawl,
    onCompleted: (data) => {
      const apiLastCrawlId = data?.getProject?.lastFinishedCrawl?.id;
      const lastCrawlId = apiLastCrawlId && getRawCrawlId(apiLastCrawlId);

      if (crawlId && lastCrawlId) {
        searchParams.delete(LATEST_CRAWL_PARAM);

        if (crawlId !== lastCrawlId) {
          history.replace({
            pathname: location.pathname.replace(crawlId, lastCrawlId),
            search: searchParams.toString(),
          });
        } else {
          history.replace({
            search: searchParams.toString(),
          });
        }
      }
    },
  });
}
