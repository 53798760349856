import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Field, FieldProps } from "formik";
import debounce from "lodash/debounce";
import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { ComboBox, ComboBoxProps } from "@lumar/shared";
import { useTranslation } from "react-i18next";
import {
  ModuleCode,
  useCrawlTypesMetadataQuery,
  useProjectAutocompleteLazyQuery,
} from "../../graphql";
import { assert } from "../assert";
import { ProjectAutocompleteOption } from "./ProjectAutocompleteOption";
import { ProjectAutocompleteSpinner } from "./ProjectAutocompleteSpinner";
import { NarrowAutocompleteProject } from "./types";

// NOTE: I have assumed that if we call the lazy query multiple times, Apollo
// will be smart enough to take care of ensuring the last request is always
// returned last if for whatever reason there are race-conditionals and the
// requests are returned out of order by the network (request b comes back before
// request a). There wasn't anything in the documentation and haven't dug into
// the implementation - Saul.

interface ProjectAutocompleteProps
  extends Omit<
    ComboBoxProps<NarrowAutocompleteProject | null, false, boolean, false>,
    "value" | "onChange" | "options"
  > {
  accountId: string;
  includeTestSuites?: boolean;
  value: NarrowAutocompleteProject | null;
  onChange?(value: NarrowAutocompleteProject | null): void;
  helperText?: string;
  error?: boolean;
  projectToExclude?: string;
  "data-testid"?: string;
  moduleCode?: ModuleCode;
}

export function ProjectAutocomplete({
  accountId,
  includeTestSuites = false,
  value,
  onChange,
  helperText,
  error,
  projectToExclude,
  moduleCode,
  ...comboBoxProps
}: ProjectAutocompleteProps): JSX.Element {
  const { t } = useTranslation(["common", "projectAutocomplete"]);
  // NOTE: Local state is not used to control the inputs state, but rather to
  // maintain a copy of the TextFields value at this scope, so we can add the
  // text fields value as a depedency to the useEffect which fetch a list of
  // updated options as the value of the TextField changes - Saul.
  const [inputValue, setInputValue] = useState(value?.name || "");
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  const {
    data: crawlTypesMetadataData,
    loading: crawlTypesMetadataLoading,
    error: crawlTypesMetadataError,
  } = useCrawlTypesMetadataQuery({
    fetchPolicy: "cache-first",
    skip: !hasBeenOpened,
  });

  const [
    getProjectsList,
    {
      data: projectListData,
      loading: isProjectListLoading,
      error: projectListError,
    },
  ] = useProjectAutocompleteLazyQuery({
    variables: {
      accountId: accountId,
      includeTestSuites,
    },
    fetchPolicy: "no-cache",
  });

  const [isFetchingDebounced, setIsDebounced] = useState(false);
  const isLoading =
    isProjectListLoading || isFetchingDebounced || crawlTypesMetadataLoading;
  const hasError = Boolean(projectListError || crawlTypesMetadataError);

  const options = [
    ...(value ? [value] : []),
    ...(projectListData?.getAccount?.projects.nodes || []).filter(
      (option) =>
        option.id !== value?.id &&
        !(projectToExclude && option.id === projectToExclude) &&
        (!moduleCode || option.moduleCode === moduleCode),
    ),
  ];

  const searchProjectsByNameAndDomain = useMemo(
    () =>
      debounce((searchValue?: string) => {
        getProjectsList({
          variables: {
            accountId: accountId,
            searchValue: searchValue || undefined,
            includeTestSuites,
          },
        });
      }, 200),
    [accountId, includeTestSuites, getProjectsList],
  );

  useEffect(() => {
    return () => searchProjectsByNameAndDomain.cancel();
  }, [searchProjectsByNameAndDomain]);

  useEffect(() => {
    if (value || hasBeenOpened) {
      setIsDebounced(true);
      searchProjectsByNameAndDomain(inputValue);
    }
  }, [value, inputValue, searchProjectsByNameAndDomain, hasBeenOpened]);

  // NOTE: Ideally, we would call setIsDebounced(false) straight after calling the getProjectsList
  // lazy query. Unfortunately the loading boolean on getProjectList query is not set immediately
  // after the lazy query is called, while setIsDebounced is. This leads to the debounced boolean
  // being set to false before the loading boolean on the query takes over. To avoid a render and
  // a flicker from loading to not to loading states inbetween the debounce and the query loading,
  // we maintain the debounced boolean until the next time the queries loading property is true - Saul.
  useEffect(() => {
    if (isFetchingDebounced && isProjectListLoading) {
      setIsDebounced(false);
    }
  }, [isFetchingDebounced, setIsDebounced, isProjectListLoading]);

  const crawlTypesMetadata = crawlTypesMetadataData?.getCrawlTypesMetadata;
  const props = {
    value: value,
    onChange: (_: unknown, value: NarrowAutocompleteProject | null) => {
      onChange?.(value);
    },
    loading: isLoading,
    loadingText: <ProjectAutocompleteSpinner />,
    // NOTE: If there are options present AND the loading prop is true, the
    // dropdown list will continue to show stale options. To avoid showing
    // stale options while loading, we have to clear the options prop.
    // We also clear the options if there is an error present (from Apollo)
    // so we can re-purpose the no options text to display an error message:
    // see the "noOptionsText" prop for more explanation - Saul.
    options: isLoading || hasError ? [] : options,
    getOptionSelected: (
      option: NarrowAutocompleteProject,
      currentValue: NarrowAutocompleteProject | null,
    ) => option.id === currentValue?.id,
    getOptionLabel: (option: NarrowAutocompleteProject) => option.name,
    filterOptions: createFilterOptions({
      stringify: (option: NarrowAutocompleteProject) =>
        option.name + " " + option.primaryDomain,
    }),
    renderOption: function renderOption(
      option: NarrowAutocompleteProject,
    ): JSX.Element {
      assert(crawlTypesMetadata);
      return (
        <ProjectAutocompleteOption
          project={option}
          crawlTypesMetadata={crawlTypesMetadata}
        />
      );
    },
    inputProps: {
      ...comboBoxProps.inputProps,
      helperText: helperText,
      error: error,
    },
    onInputChange: (_: ChangeEvent<unknown>, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    onOpen: () => setHasBeenOpened(true),
    // NOTE: We are using the noOptions prop to show both network / graphql
    // errors and as a fallback for when there are no options matching the
    // input value. In the options prop, if there is an error, we set options
    // to empty so we can display the no options fallback and in the
    // noOptionsText prop - we show the error message instead of the usual
    // "No matching results found" fallback. If there is no error message, we
    // know that the reason the noOptionsText is being show is because there
    // are no matching options as per usual behaviour - Saul
    noOptionsText: hasError
      ? t("common:genericError")
      : t("projectAutocomplete:noResults"),
  };

  return <ComboBox {...comboBoxProps} {...props} />;
}

interface ProjectAutocompleteFieldProps
  extends Omit<ProjectAutocompleteProps, "value" | "onChange"> {
  name: string;
}

export function ProjectAutocompleteField({
  name,
  ...props
}: ProjectAutocompleteFieldProps): JSX.Element {
  return (
    <Field name={name}>
      {({
        field: { value, name },
        form: { setFieldValue, setFieldTouched, errors, touched, isSubmitting },
      }: FieldProps<NarrowAutocompleteProject | null>) => {
        const error = errors?.[name];
        const errorValue = typeof error === "string" ? error : undefined;
        const isTouched = Boolean(touched?.[name]);

        return (
          <ProjectAutocomplete
            value={value}
            onChange={(value) => {
              setFieldValue(name, value);
            }}
            onBlur={() => {
              setFieldTouched(name, true);
            }}
            {...props}
            error={isTouched && Boolean(errorValue)}
            helperText={isTouched ? errorValue : undefined}
            disabled={isSubmitting}
          />
        );
      }}
    </Field>
  );
}
