import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getBacklinksDistribution(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    requiredSources: {
      sources: [CrawlType.Backlinks],
      incompleteMessage: t("requiredSourcesMessage.backlinksSource"),
    },
    title: t("backlinksDistribution.title"),
    description: t("backlinksDistribution.description"),
    reportTemplateCodes: [
      "primary_pages_with_backlinks",
      "primary_pages_with_no_backlinks",
      "duplicate_pages_with_backlinks",
      "backlink_pages_non_indexable",
      "backlink_pages_redirects",
      "backlink_pages_with_errors",
    ],
  })();
}
