import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    container: {
      minHeight: "100vh",
    },
  }),
);

export function NotFound(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("notFoundPage");

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item xs={3}>
        <Paper className={classes.paper}>
          <Typography variant="h2">{t("notFoundMessage")}</Typography>
          <Typography>
            <Link color="secondary" component={RouterLink} to="/login">
              {t("goBackMessage")}
            </Link>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
