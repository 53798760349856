import { SearchSolid, TextField, useTranslation } from "@lumar/shared";
import { IconButton, makeStyles } from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 290,
    height: 32,
  },
  icon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}));

export function CustomReportTemplateSearch({
  disabled,
  onSearchValueChange,
}: {
  disabled?: boolean;
  onSearchValueChange: (value: string) => void;
}): JSX.Element {
  const { t } = useTranslation("customReports");
  const classes = useStyles();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      onSearchValueChange(search);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [onSearchValueChange, search]);

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      disabled={disabled}
      placeholder={t("customReportTemplateSearchPlaceholder")}
      className={classes.textField}
      InputProps={{
        startAdornment: <SearchSolid className={classes.icon} />,
        endAdornment: search ? (
          <IconButton
            onClick={() => setSearch("")}
            data-testid="search-with-debounce-x"
            size="small"
          >
            <ClearRoundedIcon />
          </IconButton>
        ) : null,
      }}
    />
  );
}
