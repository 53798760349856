import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { FastField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { withDisplayValue } from "../../components/TextFieldInput";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { SettingControlFactory } from "../useAdvancedControls";

export const getReportSettingsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.reportSettings.title"),
  path: "report",
  // eslint-disable-next-line react/display-name
  control: () => <ReportSettings />,
  testId: "crawl-settings-advanced-report-settings",
  textForSearch: createTextForSearch([
    t("settings.reportSettings.title"),
    t("settings.reportSettings.maxTitleWidth"),
    t("settings.reportSettings.minTitleWidth"),
    t("settings.reportSettings.minDescriptionLength"),
    t("settings.reportSettings.maxDescriptionLength"),
    t("settings.reportSettings.maxHtmlSize"),
    t("settings.reportSettings.maxLinks"),
    t("settings.reportSettings.thinPageThreshold"),
    t("settings.reportSettings.emptyPageThreshold"),
    t("settings.reportSettings.maxExternalLinks"),
    t("settings.reportSettings.maxContentSize"),
    t("settings.reportSettings.maxUrlLength"),
    t("settings.reportSettings.maxFetchTime"),
    t("settings.reportSettings.duplicatePrecision"),
    t("settings.reportSettings.maxRedirections"),
    t("settings.reportSettings.lowLogSummaryRequests"),
    t("settings.reportSettings.highLogSummaryRequests"),
    t("settings.reportSettings.pixelPlaceholder"),
    t("settings.reportSettings.charPlaceholder"),
    t("settings.reportSettings.sizePlaceholder"),
    t("settings.reportSettings.linksPlaceholder"),
    t("settings.reportSettings.charNumPlaceholder"),
    t("settings.reportSettings.secondsPlaceholder"),
    t("settings.reportSettings.duplicatePlaceholder"),
    t("settings.reportSettings.duplicateTooltip"),
    t("settings.reportSettings.redirectionPlaceholder"),
    t("settings.reportSettings.requestPlaceholder"),
  ]),
  fieldNames: [
    ["maxTitleWidth", t("settings.reportSettings.maxTitleWidth")],
    ["minTitleWidth", t("settings.reportSettings.minTitleWidth")],
    ["minDescriptionLength", t("settings.reportSettings.minDescriptionLength")],
    ["maxDescriptionLength", t("settings.reportSettings.maxDescriptionLength")],
    ["maxHtmlSize", t("settings.reportSettings.maxHtmlSize")],
    ["maxLinks", t("settings.reportSettings.maxLinks")],
    ["thinPageThreshold", t("settings.reportSettings.thinPageThreshold")],
    ["emptyPageThreshold", t("settings.reportSettings.emptyPageThreshold")],
    ["maxExternalLinks", t("settings.reportSettings.maxExternalLinks")],
    ["maxContentSize", t("settings.reportSettings.maxContentSize")],
    ["maxUrlLength", t("settings.reportSettings.maxUrlLength")],
    ["maxFetchTime", t("settings.reportSettings.maxFetchTime")],
    ["duplicatePrecision", t("settings.reportSettings.duplicatePrecision")],
    ["maxRedirections", t("settings.reportSettings.maxRedirections")],
    [
      "lowLogSummaryRequests",
      t("settings.reportSettings.lowLogSummaryRequests"),
    ],
    [
      "highLogSummaryRequests",
      t("settings.reportSettings.highLogSummaryRequests"),
    ],
  ],
});

const useStyles = makeStyles((theme) => ({
  firstColumn: {
    paddingRight: theme.spacing(2),
  },
  input: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const ReportSettings = React.memo(ReportSettingsInner);

function ReportSettingsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const integerPattern = /^(\+?\d+)?$/;
  const realPattern = /^(\+?\d*\.?\d*)?$/;

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6} className={classes.firstColumn}>
        <FieldEntry
          name="report.report.maxTitleWidth"
          label={t("settings.reportSettings.maxTitleWidth")}
          placeholder={t("settings.reportSettings.pixelPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valuePixel", { value })
          }
          pattern={integerPattern}
          data-testid="max-title-width"
        />
        <FieldEntry
          name="report.report.minTitleWidth"
          label={t("settings.reportSettings.minTitleWidth")}
          placeholder={t("settings.reportSettings.charPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueCharacter", { value })
          }
          pattern={integerPattern}
          data-testid="min-title-width"
        />
        <FieldEntry
          name="report.report.minDescriptionLength"
          label={t("settings.reportSettings.minDescriptionLength")}
          placeholder={t("settings.reportSettings.charPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueCharacter", { value })
          }
          pattern={integerPattern}
          data-testid="min-description-length"
        />
        <FieldEntry
          name="report.report.maxDescriptionLength"
          label={t("settings.reportSettings.maxDescriptionLength")}
          placeholder={t("settings.reportSettings.charPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueCharacter", { value })
          }
          pattern={integerPattern}
          data-testid="max-description-length"
        />
        <FieldEntry
          name="report.report.maxHtmlSize"
          label={t("settings.reportSettings.maxHtmlSize")}
          placeholder={t("settings.reportSettings.sizePlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueByte", { value })
          }
          pattern={integerPattern}
          data-testid="max-html-size"
        />
        <FieldEntry
          name="report.report.maxLinks"
          label={t("settings.reportSettings.maxLinks")}
          placeholder={t("settings.reportSettings.linksPlaceholder")}
          pattern={integerPattern}
          data-testid="max-links"
        />
        <FieldEntry
          name="report.report.thinPageThreshold"
          label={t("settings.reportSettings.thinPageThreshold")}
          placeholder={t("settings.reportSettings.sizePlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueByte", { value })
          }
          pattern={integerPattern}
          data-testid="thin-page-threshold"
        />
        <FieldEntry
          name="report.report.emptyPageThreshold"
          label={t("settings.reportSettings.emptyPageThreshold")}
          placeholder={t("settings.reportSettings.sizePlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueByte", { value })
          }
          pattern={integerPattern}
          data-testid="empty-page-threshold"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FieldEntry
          name="report.report.maxExternalLinks"
          label={t("settings.reportSettings.maxExternalLinks")}
          placeholder={t("settings.reportSettings.linksPlaceholder")}
          pattern={integerPattern}
          data-testid="max-external-links"
        />
        <FieldEntry
          name="report.report.maxContentSize"
          label={t("settings.reportSettings.maxContentSize")}
          placeholder={t("settings.reportSettings.sizePlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueByte", { value })
          }
          pattern={integerPattern}
          data-testid="max-content-size"
        />
        <FieldEntry
          name="report.report.maxUrlLength"
          label={t("settings.reportSettings.maxUrlLength")}
          placeholder={t("settings.reportSettings.charNumPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueCharacter", { value })
          }
          pattern={integerPattern}
          data-testid="max-url-length"
        />
        <FieldEntry
          name="report.report.maxFetchTime"
          label={t("settings.reportSettings.maxFetchTime")}
          placeholder={t("settings.reportSettings.secondsPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueSecond", { value })
          }
          pattern={realPattern}
          data-testid="max-fetch-time"
        />
        <Tooltip
          title={t("settings.reportSettings.duplicateTooltip")}
          placement="top"
          arrow
        >
          <div>
            <FieldEntry
              name="report.report.duplicatePrecision"
              label={t("settings.reportSettings.duplicatePrecision")}
              placeholder={t("settings.reportSettings.duplicatePlaceholder")}
              pattern={realPattern}
              data-testid="duplicate-precision"
            />
          </div>
        </Tooltip>
        <FieldEntry
          name="report.report.maxRedirections"
          label={t("settings.reportSettings.maxRedirections")}
          placeholder={t("settings.reportSettings.redirectionPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueRedirection", { value })
          }
          pattern={integerPattern}
          data-testid="max-redirections"
        />
        <FieldEntry
          name="report.report.lowLogSummaryRequests"
          label={t("settings.reportSettings.lowLogSummaryRequests")}
          placeholder={t("settings.reportSettings.requestPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueRequest", { value })
          }
          pattern={integerPattern}
          data-testid="low-log-summary-request"
        />
        <FieldEntry
          name="report.report.highLogSummaryRequests"
          label={t("settings.reportSettings.highLogSummaryRequests")}
          placeholder={t("settings.reportSettings.requestPlaceholder")}
          valueFormater={(value) =>
            t("settings.reportSettings.valueRequest", { value })
          }
          pattern={integerPattern}
          data-testid="high-log-summary-request"
        />
      </Grid>
    </Grid>
  );
}

function FieldEntry({
  valueFormater,
  ...props
}: Partial<Parameters<typeof TextFieldWithOnBlurUpdate>[0]> & {
  name: string;
  pattern: RegExp;
  valueFormater?: (value: string) => string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <FastField
      {...props}
      component={TextFieldWithOnBlurUpdate}
      hiddenLabel
      variant="outlined"
      className={classes.input}
      InputProps={{
        inputComponent: valueFormater
          ? withDisplayValue(valueFormater)
          : undefined,
      }}
    />
  );
}
