import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getLargestContentfulPaintChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("largestContentfulPaint.title"),
    reportTemplateCodes: ["fast_lcp", "average_lcp", "slow_lcp"],
    description: t("largestContentfulPaint.description"),
  })();
}
