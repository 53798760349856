import { ReportCategoryChartConfig } from "../types";
import { getWebNotInSourceByLevelChart as getWebNotInSourceByLevelChart } from "../../../../_common/charts/config/getWebNotInSourceByLevelChart";
import { getCrawlSourceNotInTrend } from "../../../../_common/charts/config/getCrawlSourceNotInTrend";
import { getCrawlSourceOverlapChart } from "../../../../_common/charts/config/getCrawlSourceOverlapChart";
import { getOrphanedPagesReportChart } from "../../../../_common/charts/config/getOrphanedPagesReportChart";
import { getCrawlSourcesPagesIn } from "../../../../_common/charts/config/getCrawlSourcesPagesIn";

export const generateSummarySourceSummaryChartConfig: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getCrawlSourcesPagesIn,
      getCrawlSourceOverlapChart,
      getCrawlSourceNotInTrend,
      getOrphanedPagesReportChart,
      getWebNotInSourceByLevelChart,
      // getCrawlSourceBacklinksTrend,
      // getCrawlSourceListTrend,
      // getCrawlSourceLogSummaryTrend,
      // getCrawlSourceWebTrend,
      // getCrawlSourceSearchConsoleTrend,
      // getCrawlSourceSitemapsTrend,
      // getCrawlSourceAnalyticsTrend,
    ],
  };
