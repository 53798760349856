import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Warning";
import { FastField, Field, FieldProps, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { fieldToTextField, Select, TextField } from "@lumar/shared";

import { ManualUploadsSettingsTable } from "./ManualUploadsSettingsTable";
import { useFileUploadFormValues } from "./useFileUploadFormValues";
import { useFileUploadSchema } from "./useFileUploadSchema";
import { UrlFileUploadStatus } from "../../../../graphql";
import { FileUpload, FormValues } from "./types";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  labelBox: {
    minWidth: 150,
    maxHeight: 42,
    backgroundColor: theme.palette.grey[600],
    paddingLeft: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  label: {
    color: "white",
    fontWeight: theme.typography.fontWeightBold,
  },
  input: {
    width: "100%",
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    borderRadius: theme.shape.borderRadius,
    width: "fit-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginRight: "auto",
  },
  errorIcon: {
    transform: "translateY(-5%)",
    verticalAlign: "middle",
    color: "white",
    fontSize: theme.typography.fontSize,
    marginRight: theme.spacing(1),
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export interface ManualUploadsSettingsProps {
  file: FileUpload;
  sample?: string[][];
  primaryDomain: string;
  onClose: () => void;
  onSubmit: (values: FormValues, sample: string[][]) => Promise<boolean>;
}

export function ManualUploadsSettings({
  file,
  sample: providedSample,
  primaryDomain,
  onClose,
  onSubmit,
}: ManualUploadsSettingsProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const { loading, formValues, availableUploadTypes, sample } =
    useFileUploadFormValues({ file, sample: providedSample });
  const schema = useFileUploadSchema();

  const [open, setOpen] = useState(false);
  useEffect(() => file && setOpen(true), [file, setOpen]);

  const handelUploadTypeChanged = (
    uploadTypeCode: string,
    setFieldValue: FormikProps<FormValues>["setFieldValue"],
  ): void => {
    const template = availableUploadTypes.find(
      (upload) => upload.code === uploadTypeCode,
    )?.template;
    if (!template) return;

    setFieldValue("uploadType", uploadTypeCode);
    setFieldValue("headerRow", template.headerRow);
    setFieldValue("metrics", template.metrics, true);
  };

  const handleSave = async (submit: () => Promise<boolean>): Promise<void> => {
    const saved = await submit();
    if (saved) setOpen(false);
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={(values) => onSubmit(values, sample)}
      validateOnBlur
      validateOnChange={false}
      validationSchema={schema}
      validateOnMount={!loading && file.status === UrlFileUploadStatus.Draft}
    >
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        data-testid="manual-file-upload-settings-dialog"
        TransitionProps={{ onExited: () => onClose() }}
      >
        <DialogTitle>{file.fileName}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {availableUploadTypes.length > 0 && (
              <Grid item xs={6}>
                <Field name="uploadType">
                  {({
                    field: { value },
                    form: { setFieldValue, isSubmitting },
                  }: FieldProps<string>) => (
                    <Tooltip
                      title={t("sources.manualUpload.uploadTypeTooltip")}
                      placement="top"
                    >
                      <Select
                        value={value}
                        onChange={(e) =>
                          handelUploadTypeChanged(
                            e.target.value as string,
                            setFieldValue,
                          )
                        }
                        disabled={isSubmitting}
                        label={t("sources.manualUpload.uploadType")}
                        fullWidth
                        data-testid="manual-upload-type"
                      >
                        {availableUploadTypes.map((type) => (
                          <MenuItem
                            key={type.code}
                            value={type.code}
                            data-testid="manual-upload-type-option"
                          >
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  )}
                </Field>
              </Grid>
            )}
            <Grid item xs={6}>
              <FastField name="baseDomain">
                {(props: FieldProps<string>) => (
                  <Tooltip
                    title={t("sources.manualUpload.baseDomainTooltip")}
                    placement="top"
                  >
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("sources.manualUpload.baseDomain")}
                      placeholder={primaryDomain}
                      className={classes.input}
                      data-testid="manual-upload-base-domain"
                    />
                  </Tooltip>
                )}
              </FastField>
            </Grid>
            <Grid item xs={12}>
              {!loading ? (
                <ManualUploadsSettingsTable file={file} sample={sample} />
              ) : (
                <div className={classes.loadingContainer}>
                  <CircularProgress color="inherit" size={20} />
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Field name="">
            {({ form }: FieldProps<FormValues>) => (
              <>
                {form.errors.metrics && (
                  <Typography className={classes.error}>
                    <ErrorIcon classes={{ root: classes.errorIcon }} />
                    {form.errors.metrics}
                  </Typography>
                )}
                <Button
                  onClick={() => handleSave(form.submitForm)}
                  color="primary"
                  variant="contained"
                  disabled={!form.isValid || form.isSubmitting}
                  data-testid="manual-upload-save"
                >
                  {t("sources.manualUpload.save")}
                </Button>
                <Button
                  onClick={() => setOpen(false)}
                  variant="outlined"
                  autoFocus
                  disabled={form.isSubmitting}
                  data-testid="manual-upload-close"
                >
                  {t("sources.manualUpload.close")}
                </Button>
              </>
            )}
          </Field>
        </DialogActions>
      </Dialog>
    </Formik>
  );
}
