/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from "react";

import { Formik } from "formik";

import { SegmentEditValues } from "../useSegmentMutations";
import { useEditSegmentValidationSchema } from "./useEditSegmentValidationSchema";
import {
  getDefaultFilterMetric,
  getFormFilter,
  getFormOrFilter,
} from "../../_common/connection-filtering/helpers";
import { useTranslation } from "@lumar/shared";
import { mapDatasourceConnectionFilterToOrFormFilters } from "../../_common/connection-filtering/filter-or-rule-field-array/mapDatasourceConnectionFilterToOrFormFilters";
import { Crawl, CrawlSegment, Maybe } from "../../graphql";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import {
  SegmentForm,
  SegmentFormValues,
} from "../segment-dialog-form/SegmentForm";
import { SegmentDialogWithPreview } from "../segment-dialog-form/SegmentDialogWithPreview";

interface EditSegment {
  id: any;
  name: string;
  group?: string | null; // TODO
  createdAt: string;
  lastGeneratedAt?: Maybe<string> | undefined;
  filter?: any;
  crawlSegments?:
    | Maybe<{
        nodes: Pick<
          CrawlSegment,
          "createdAt" | "generatingAt" | "generatedAt" | "failedAt"
        >[];
      }>
    | undefined;
}

export interface EditSegmentDialogProps {
  editSegment: EditSegment;
  closeDialog: () => void;
  updateSegmentMutation: (values: SegmentEditValues) => void;
  latestFinishedCrawlData: Maybe<Pick<Crawl, "id" | "archivedAt">> | undefined;
  metrics: NarrowMetric[];
  groups: string[];
}

export function EditSegmentDialog({
  editSegment,
  closeDialog,
  updateSegmentMutation,
  metrics,
  groups,
  latestFinishedCrawlData,
}: EditSegmentDialogProps): JSX.Element {
  const { t } = useTranslation(["common", "segments"]);

  const doesUnarchivedFinishedCrawlExist =
    Boolean(latestFinishedCrawlData) && !latestFinishedCrawlData?.archivedAt;

  const isPreviewDisabled = !doesUnarchivedFinishedCrawlExist;

  const validationSchema = useEditSegmentValidationSchema(metrics);

  const defaultFilter = React.useMemo(
    () => getFormFilter(getDefaultFilterMetric(metrics)),
    [metrics],
  );

  const initialValues = useMemo(
    () => ({
      projectDestination: null,
      segments: [
        {
          segmentName: editSegment?.name ?? "",
          group: editSegment?.group ?? null,
          rules: mapDatasourceConnectionFilterToOrFormFilters(
            editSegment?.filter,
          ) ?? [getFormOrFilter(defaultFilter)],
        },
      ],
    }),
    [editSegment, defaultFilter],
  );

  const handleSubmit = async (values: SegmentFormValues): Promise<void> => {
    const editSegmentValues = {
      segmentName: values?.segments[0]?.segmentName,
      group: values?.segments[0]?.group || null,
      rules: validationSchema.cast(values)?.segments[0]?.rules,
      segmentId: editSegment?.id ?? "",
    };
    await updateSegmentMutation(editSegmentValues);
    closeDialog();
  };

  return (
    <Formik<SegmentFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        validateForm,
        submitForm,
        setTouched,
        isSubmitting,
      }) => (
        <SegmentDialogWithPreview
          values={values}
          validateForm={validateForm}
          onSubmit={submitForm}
          isSubmitting={isSubmitting}
          setTouched={setTouched}
          closeDialog={closeDialog}
          submitBtnText={t("segments:updateSegment")}
          dialogTitle={t("segments:editSegment")}
          previewMessage={(projectName) =>
            t("segments:editSegmentIn", {
              name: values.segments[0].segmentName,
              projectName: projectName,
            })
          }
          isPreviewDisabled={isPreviewDisabled}
          metrics={metrics}
          lastFinishedCrawlId={latestFinishedCrawlData?.id}
        >
          <SegmentForm
            errors={errors}
            metrics={metrics}
            defaultFilter={defaultFilter}
            groups={groups}
            touched={touched}
            validateForm={validateForm}
            values={values}
          />
        </SegmentDialogWithPreview>
      )}
    </Formik>
  );
}
