/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, DocumentNode, gql, useQuery } from "@lumar/shared";
import { generateQuery } from "./generateMetricFields";
import React from "react";
import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import { ReportTypeCode } from "../../graphql";
import { formatMetrics } from "./formatMetrics";
import { DataExplorerRow, DataExplorerTableConfig } from "../types";
import { ConnectionFilter } from "../../_common/connection-filtering/types";
import { createConnectionFilter } from "../../_common/connection-filtering/createConnectionFilter";

interface Props {
  crawlId: string;
  tableConfig: DataExplorerTableConfig;
}

interface Result {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
}

export function useDefaulDimensionData({
  crawlId,
  tableConfig,
}: Props): Result {
  const { loading, error, data } = useQuery(getDocument(crawlId, tableConfig), {
    errorPolicy: "all",
    onError: () => null,
    notifyOnNetworkStatusChange: true,
  });

  const dimension = tableConfig.dimension;
  const dimensionRef = React.useRef<string>(dimension);
  const dimensionChanged = dimensionRef.current !== dimension;
  dimensionRef.current = dimension;

  const dataRef = React.useRef<any>();
  if (dimensionChanged) {
    dataRef.current = undefined;
  }

  if (data) {
    dataRef.current = data;
  }

  const aggregateData = dataRef.current;

  const rows =
    aggregateData?.report?.crawlUrlsAggregates?.nodes?.map((row: any) => ({
      [dimension]: row[dimension],
      ...formatMetrics(row),
      getFilter: () => getFilter(dimension, row[dimension]),
    })) || [];

  return {
    loading: aggregateData ? false : loading,
    error,
    rows,
  };
}

function getDocument(
  crawlId: string,
  tableConfig: DataExplorerTableConfig,
): DocumentNode {
  const dimension = tableConfig.dimension;
  const metricFields = generateQuery(tableConfig);

  const query = {
    query: {
      __name: "getAggregateData",
      report: {
        __aliasFor: "getReportStat",
        __args: {
          input: {
            crawlId,
            reportTemplateCode: "all_pages",
          },
        },
        crawlUrlsAggregates: {
          __args: {
            reportType: new EnumType(ReportTypeCode.Basic),
            dimensions: [new EnumType(dimension)],
          },
          nodes: {
            ...metricFields,
          },
        },
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}

function getFilter(
  dimension: string,
  metricValue: any,
): ConnectionFilter | undefined {
  if (!dimension || dimension === "segment" || metricValue === undefined) {
    return;
  }

  if (dimension === "level" && metricValue === null) {
    return createConnectionFilter({
      or: [
        {
          and: [
            {
              metricCode: "foundInWebCrawl",
              predicateKey: "ne",
              predicateValue: true,
            },
          ],
        },
      ],
    });
  }

  return createConnectionFilter({
    or: [
      {
        and: [
          {
            metricCode: dimension,
            predicateKey: metricValue === null ? "isNull" : "eq",
            predicateValue: metricValue === null ? true : metricValue,
          },
        ],
      },
    ],
  });
}
