import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";
import { NumberPresenter } from "../default-presenters";

const useStyles = makeStyles((theme) => ({
  slow: {
    color: theme.palette.red[600],
    "& span": {
      color: theme.palette.red[600],
    },
  },
  medium: {
    color: theme.palette.yellow[600],
    "& span": {
      color: theme.palette.yellow[600],
    },
  },
  fast: {
    color: theme.palette.green[600],
    "& span": {
      color: theme.palette.green[600],
    },
  },
}));

export function NumberWithThresholdPresenter(
  props: MetricsValuePresenterProps & { maximumFractionDigits?: number },
): JSX.Element {
  const classes = useStyles();

  const metadata = props.metrics[props.code]?.data?.metadata;

  const className = (() => {
    if (!metadata?.thresholds) return;

    if (doesValueFitThreshold(props.value, metadata.thresholds.slow))
      return classes.slow;
    if (doesValueFitThreshold(props.value, metadata.thresholds.medium))
      return classes.medium;
    if (doesValueFitThreshold(props.value, metadata.thresholds.fast))
      return classes.fast;

    return undefined;
  })();

  return (
    <span className={className}>
      <NumberPresenter {...props} />
    </span>
  );
}

function doesValueFitThreshold(value: number, threshold: string): boolean {
  if (threshold.startsWith(">")) {
    const isValueGreaterThanThreshold = value > Number(threshold.substring(1));
    return isValueGreaterThanThreshold;
  }

  if (threshold.includes("-")) {
    const [first, second] = threshold.split("-");
    const doesValueFitThreshold =
      value >= Number(first) && value <= Number(second);
    return doesValueFitThreshold;
  }

  if (threshold.startsWith("<")) {
    const isValueLowerThanThreshold = value < Number(threshold.substring(1));
    return isValueLowerThanThreshold;
  }

  return false;
}
