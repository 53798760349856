/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityLevelAAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityLevelAAChart.title"),
    description: () => t("accessibilityLevelAAChart.description"),
    reportTemplateCodesOrder: [
      "color_contrast_issues",
      "autocomplete_valid_issues",
      "valid_lang_issues",
      "avoid_inline_spacing_issues",
      "meta_viewport_issues",
      "target_size_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "color_contrast_issues",
        "autocomplete_valid_issues",
        "valid_lang_issues",
        "avoid_inline_spacing_issues",
        "meta_viewport_issues",
        "target_size_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
