import React from "react";
import { useTranslation } from "@lumar/shared";
import { Metrics } from "../../data/types";
import { ReportConfig } from "./useAccessibilityDetailsSections";
import { ReportRowsFilter } from "../../../_common/connection-filtering/types";

export function useAccessibilityReports(metrics: Metrics): ReportConfig[] {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo<ReportConfig[]>(() => {
    const urlDigest = metrics["urlDigest"].value;
    const issuesCountByRuleId =
      metrics["accessibilityIssuesCountByRuleId"]?.value;

    const reports: ReportConfig = {
      code: "accessibilityReports",
      items: [
        {
          code: "aIssues",
          name: t("reportAIssues"),
          countMetric: "accessibilityAIssuesCount",
          filter: {
            reportTemplateCode: "accessibility_issue_instances",
            filter: {
              _and: [
                { urlDigest: { eq: urlDigest } },
                { wcagLevel: { eq: "a" } },
              ],
            },
          },
        },
        {
          code: "aaIssues",
          name: t("reportAAIssues"),
          countMetric: "accessibilityAaIssuesCount",
          filter: {
            reportTemplateCode: "accessibility_issue_instances",
            filter: {
              _and: [
                { urlDigest: { eq: urlDigest } },
                { wcagLevel: { eq: "aa" } },
              ],
            },
          },
        },
        {
          code: "aaaIssues",
          name: t("reportAAAIssues"),
          countMetric: "accessibilityAaaIssuesCount",
          filter: {
            reportTemplateCode: "accessibility_issue_instances",
            filter: {
              _and: [
                { urlDigest: { eq: urlDigest } },
                { wcagLevel: { eq: "aaa" } },
              ],
            },
          },
        },
        {
          code: "bestPracticeIssues",
          name: t("reportBestPractices"),
          countMetric: "accessibilityBpIssuesCount",
          filter: {
            reportTemplateCode: "accessibility_issue_instances",
            filter: {
              _and: [
                { urlDigest: { eq: urlDigest } },
                { wcagLevel: { eq: "best-practice" } },
              ],
            },
          },
        },
      ],
    };

    return [
      reports,
      ...(hasIssues(issuesCountByRuleId)
        ? [
            {
              code: "all-issues",
              name: t("categoryAllIsuues"),
              // eslint-disable-next-line fp/no-mutating-methods
              items: [...issuesCountByRuleId]
                .sort((a, b) => b.issues_count - a.issues_count)
                .map(({ rule_id, issues_count }) => ({
                  code: rule_id,
                  name: rule_id,
                  count: issues_count,
                  filter: {
                    reportTemplateCode: "accessibility_issue_instances",
                    filter: {
                      _and: [
                        { urlDigest: { eq: urlDigest } },
                        { ruleId: { eq: rule_id } },
                      ],
                    } as ReportRowsFilter,
                  },
                })),
            },
          ]
        : []),
    ];
  }, [t, metrics]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasIssues(value: any): value is {
  rule_id: string;
  issues_count: number;
}[] {
  return Boolean(Array.isArray(value) && value.length);
}
