import { useTranslation, Button, useMutationSnackbar } from "@lumar/shared";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useFormik } from "formik";
import { Prompt, useParams } from "react-router-dom";
import { useUpdateReportTemplateOverrideMutation } from "../../../graphql";
import { useReportAdjustmentFormDialogs } from "./ReportAdjustmentFormDialogsContext";
import { ReportAdjustmentFormFields } from "./ReportAdjustmentFormFields";
import { ReportAdjustment } from "./ReportAdjustmentTypes";
import { useReportAdjustmentFormValidationSchema } from "./useReportAdjustmentFormValidationSchema";
import { useConfirmDialog } from "../../../_common/hooks/useConfirmDialog";

export function EditReportAdjustmentFormDialog(props: {
  reportTemplates: { code: string; name: string }[];
}): JSX.Element {
  const { t } = useTranslation(["common", "reportAdjustment"]);
  const { projectId } = useParams<{ projectId: string }>();
  const [updateOverride] = useUpdateReportTemplateOverrideMutation();
  const mutationHandler = useMutationSnackbar();
  const { reportAdjustmentToEdit, isEditDialogOpen, closeEditDialog } =
    useReportAdjustmentFormDialogs();
  const confirm = useConfirmDialog();

  const reportAdjustmentFormDefaultInitialValues: ReportAdjustment = {
    reportTemplateCode: props.reportTemplates[0]?.code,
    totalWeight: 0,
    totalSign: 0,
  };

  const reportAdjustmentEditFormDefaultValues =
    reportAdjustmentToEdit ?? reportAdjustmentFormDefaultInitialValues;

  const validationSchema = useReportAdjustmentFormValidationSchema();

  const formik = useFormik({
    initialValues: reportAdjustmentEditFormDefaultValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleClose(): void {
    if (formik.dirty) {
      if (confirm()) {
        closeEditDialog();
        formik.resetForm();
      }
    } else {
      closeEditDialog();
      formik.resetForm();
    }
  }

  async function handleSubmit(values: ReportAdjustment): Promise<void> {
    await mutationHandler(
      updateOverride({
        refetchQueries: ["ReportAdjustmentPage"],
        awaitRefetchQueries: true,
        variables: { input: { projectId, ...values } },
      }),
      {
        onSuccess() {
          closeEditDialog();
          formik.resetForm();
        },

        successMessage: t("reportAdjustment:editSuccess"),
        errorMessage: t("reportAdjustment:editError"),
      },
    );
  }

  return (
    <>
      <Prompt
        message={t("common:confirmLeaveDialogText")}
        when={formik.dirty}
      />
      <Dialog
        onClose={handleClose}
        open={isEditDialogOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{t("reportAdjustment:editReportAdjustment")}</DialogTitle>
        <DialogContent>
          <ReportAdjustmentFormFields
            disableReportSelection
            reportTemplates={props.reportTemplates}
            formik={formik}
            // We care about initial total weight during edit
            // because an api might return a different value
            // from predefined ones. We want the form
            // to respect that.
            initialTotalWeight={
              reportAdjustmentEditFormDefaultValues.totalWeight
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={formik.isSubmitting}
            onClick={handleClose}
            data-pendo="edit-report-adjustment-dialog-cancel"
          >
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
            data-pendo="edit-report-adjustment-dialog-save-changes"
          >
            {t("common:saveChanges")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
