import React from "react";
import { useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";

import {
  isCrawlInProgress,
  isCrawlPaused,
  isCrawlUnarchiving,
} from "../../../../account-projects/projects-filter/helpers";
import {
  GetCrawlSettingsForSettingsQuery,
  ModuleCode,
  ScheduleFrequencyCode,
  useGetCrawlSettingsForCopyQuery,
} from "../../../../graphql";
import {
  isPlanLight,
  isPlanLightPlus,
} from "../../../../_common/data-extensions/PlanExtension";
import { getFormValues } from "./getFormValues";
import { ContextValues } from "./types";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";
import { UserAgentType } from "../types";

export const DefaultContextValues: ContextValues = {
  getProjectSettings: () => Promise.resolve(undefined),
  accountId: "",
  projectId: "",
  projectName: "",
  primaryDomain: "",
  finishedCrawls: [],
  lastFinishedCrawlId: "",
  lastCrawl: undefined,
  hasCrawlInProgress: false,
  hasCrawlUnarchiving: false,
  hasPausedCrawl: false,
  scheduleFrequencies: [],
  initialSchedule: undefined,
  accountHasJSRenderingEnabled: false,
  isPlanLight: false,
  isPlanLightPlus: false,
  locations: [],
  userAgents: [],
  alertSettings: [],
  crawlerIpAddresses: [],
  maximumRenderTimeout: 0,
  accessibilityContainer: undefined,
  userAgentSuffix: "https://lumar.io/spdr",
  module: {
    code: ModuleCode.Basic,
    userAgentSuffixEnabled: false,
    viewportHeightDesktop: 0,
    viewportHeightMobile: 0,
    viewportWidthDesktop: 0,
    viewportWidthMobile: 0,
  },
  globalContainers: undefined,
};

export const SettingsContext = React.createContext(DefaultContextValues);

export function useContextValues(
  data: GetCrawlSettingsForSettingsQuery | undefined,
  accountId: string,
  projectId: string,
  accessibilityContainer: { id: string; name: string } | undefined,
  globalContainers:
    | NonNullable<
        GetCrawlSettingsForSettingsQuery["getGlobalCustomMetricContainers"]["nodes"]
      >
    | undefined,
): ContextValues {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  // Note: This query is used as an awaitable lazy query
  const { refetch } = useGetCrawlSettingsForCopyQuery({
    skip: true,
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleInit")}
          error={error}
        />,
      ),
  });

  const screenshotContainerId = globalContainers?.find(
    (e) => e.name === "ScreenshotStoring",
  )?.id;
  const htmlContainerId = globalContainers?.find(
    (e) => e.name === "HtmlStoring",
  )?.id;

  return React.useMemo(() => {
    if (!data) {
      return DefaultContextValues;
    }

    const project = data.getProject;
    const account = data.getAccount;

    const lastCrawl = project?.lastCrawl?.nodes[0];
    return {
      getProjectSettings: async (projectId) => {
        const result = await refetch({ projectId });

        if (!result.data?.getProject) return;
        return getFormValues(
          result.data.getProject,
          accessibilityContainer?.id,
          htmlContainerId,
          screenshotContainerId,
        );
      },
      accountId: accountId,
      projectId: projectId,
      projectName: project?.name ?? "",
      primaryDomain: project?.primaryDomain ?? "",
      finishedCrawls:
        project?.crawls?.nodes?.map((crawl) => ({
          id: crawl.id,
          createdAt: new Date(crawl.createdAt),
        })) ?? [],
      lastFinishedCrawlId: project?.lastFinishedCrawl?.id,
      lastCrawl: lastCrawl,
      hasCrawlInProgress: isCrawlInProgress(lastCrawl?.statusEnum),
      hasCrawlUnarchiving: isCrawlUnarchiving(
        lastCrawl?.statusEnum,
        lastCrawl?.finishedAt,
      ),
      hasPausedCrawl: isCrawlPaused(lastCrawl?.statusEnum),
      scheduleFrequencies:
        // eslint-disable-next-line fp/no-mutating-methods
        [...(data.getScheduleFrequencies ?? [])].sort(
          (item1, item2) =>
            getFrequencyOrder(item1.code) - getFrequencyOrder(item2.code),
        ) ?? [],
      initialSchedule: project?.schedule
        ? {
            id: project.schedule.id,
            frequency: project.schedule.scheduleFrequency.code,
            date: new Date(project.schedule.nextRunTime),
          }
        : undefined,
      accountHasJSRenderingEnabled:
        account?.subscription?.jsRenderingAvailable ?? false,
      isPlanLight: isPlanLight(account?.subscription?.plan?.code),
      isPlanLightPlus: isPlanLightPlus(account?.subscription?.plan?.code),
      locations: account?.locations ?? [],
      userAgents: getUserAgents(data),
      alertSettings: data.getAlertSettings ?? [],
      crawlerIpAddresses: account?.crawlerIpAddresses ?? [],
      maximumRenderTimeout: account?.maximumRenderTimeout ?? 0,
      userAgentSuffix:
        data.getAccount?.userAgentSuffix ??
        DefaultContextValues.userAgentSuffix,
      module:
        data.getModules?.find(
          (module) => module.code === data.getProject?.moduleCode,
        ) ?? DefaultContextValues.module,
      accessibilityContainer,
      globalContainers,
    };
  }, [
    data,
    accountId,
    projectId,
    accessibilityContainer,
    refetch,
    htmlContainerId,
    screenshotContainerId,
    globalContainers,
  ]);
}

function getFrequencyOrder(code: ScheduleFrequencyCode): number {
  switch (code) {
    case ScheduleFrequencyCode.OneTime:
      return 1;
    case ScheduleFrequencyCode.Hourly:
      return 2;
    case ScheduleFrequencyCode.Daily:
      return 3;
    case ScheduleFrequencyCode.MondayFriday:
      return 4;
    case ScheduleFrequencyCode.Weekly:
      return 5;
    case ScheduleFrequencyCode.Fortnightly:
      return 6;
    case ScheduleFrequencyCode.Monthly:
      return 7;
    case ScheduleFrequencyCode.Bimonthly:
      return 8;
    case ScheduleFrequencyCode.Quarterly:
      return 9;
    default:
      return 10;
  }
}

function getUserAgents(
  data: GetCrawlSettingsForSettingsQuery,
): UserAgentType[] {
  const projectUserAgent = data.getProject?.userAgent;
  const projectMobileUserAgent = data.getProject?.mobileUserAgent;

  const publicUserAgents = (data?.getUserAgents ?? []).map((agent) => ({
    ...agent,
    isPublic: true,
  }));

  return [
    ...publicUserAgents,
    ...(projectUserAgent &&
    !publicUserAgents.find((x) => x.code === projectUserAgent.code)
      ? [{ ...projectUserAgent, isPublic: false }]
      : []),
    ...(projectMobileUserAgent &&
    !publicUserAgents.find((x) => x.code === projectMobileUserAgent.code) &&
    projectMobileUserAgent.code !== projectUserAgent?.code
      ? [{ ...projectMobileUserAgent, isPublic: false }]
      : []),
  ];
}
