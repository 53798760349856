import { CrawlUrlAggregateOrderField } from "../graphql";
import { DataExplorerAggregationCalculation } from "./types";

function getMetricAggregateField(
  aggregationId: DataExplorerAggregationCalculation,
  metricCode: string,
): string {
  const metricCapitalized =
    metricCode.charAt(0).toUpperCase() + metricCode.slice(1);
  const aggregationCapitalised =
    aggregationId.charAt(0).toUpperCase() + aggregationId.slice(1);

  return aggregationCapitalised + metricCapitalized;
}

export function doesMetricSupportAggregation(
  aggregationId: DataExplorerAggregationCalculation,
  metricCode: string,
): boolean {
  const metricAggregateField = getMetricAggregateField(
    aggregationId,
    metricCode,
  );

  return metricAggregateField in CrawlUrlAggregateOrderField;
}

const availableAggregations = Object.values(DataExplorerAggregationCalculation);

export function doesMetricSupportAnyAggregation(metricCode: string): boolean {
  return !!availableAggregations.find((aggregationId) =>
    doesMetricSupportAggregation(aggregationId, metricCode),
  );
}
