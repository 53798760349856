import { MetricType } from "../../../../graphql";

export const jsTypes = new Map<
  MetricType,
  "number" | "string" | "array" | "date" | "boolean"
>([
  [MetricType.Integer, "number"],
  [MetricType.Decimal, "number"],
  [MetricType.String, "string"],
  [MetricType.Array, "array"],
  [MetricType.Date, "date"],
  [MetricType.Bit, "boolean"],
  [MetricType.Boolean, "boolean"],
]);
