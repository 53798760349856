import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { kebabCase } from "lodash";
import { Typography } from "../typography/Typography";
import { TooltipInfoIcon } from "../..";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomColor: theme.palette.grey[200],
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        padding: "10px 10px 10px 15px",
        height: 63,
    },
    title: {
        textOverflow: "ellipsis",
        fontSize: "1rem",
    },
    segmentName: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "0.786em",
        textTransform: "uppercase",
    },
    tooltip: {
        padding: theme.spacing(1.5, 1.5, 2, 1.5),
        minWidth: "490px",
        maxWidth: "490px",
    },
    tooltipIcon: {
        color: theme.palette.grey[500],
        boxSizing: "content-box",
        marginLeft: theme.spacing(0.5),
        width: "22px",
        height: "22px",
    },
    tooltipTitle: {
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1),
    },
    tooltipDescription: {
        whiteSpace: "pre-line",
    },
    pendoPlaceholder: {
        marginLeft: theme.spacing(1.5),
    },
}));
// FIXME: Do not expose now. Refactor so it becomes context agnostic first.
// - Michal
export function ChartPanelTitle({ title, description, children, subtitle, pendoActionsAnchorId, }) {
    const classes = useStyles();
    const titleTestAttribute = `${kebabCase(title.toLowerCase())}-title`;
    const segmentTestAttribute = `${kebabCase(title.toLowerCase())}-segment`;
    return (_jsxs(Box, { className: classes.root, children: [_jsxs(Box, { display: "flex", alignItems: "center", overflow: "hidden", children: [_jsx(Typography, { variant: "h6Medium", "data-testid": titleTestAttribute, noWrap: true, color: "textPrimary", className: classes.title, "data-pendo": pendoActionsAnchorId, children: subtitle ? (_jsxs(_Fragment, { children: [title, _jsxs("span", { className: classes.segmentName, "data-testid": segmentTestAttribute, children: [" ", "(", subtitle, ")"] })] })) : (title) }), Boolean(description) && (_jsx(Tooltip, { title: _jsxs(_Fragment, { children: [_jsx(Typography, { component: "h6", variant: "subtitle3Medium", className: classes.tooltipTitle, children: title }), _jsx(Typography, { variant: "caption", className: classes.tooltipDescription, children: description })] }), arrow: true, placement: "top", interactive: true, classes: { tooltip: classes.tooltip }, children: _jsx(TooltipInfoIcon, { className: classes.tooltipIcon, fontSize: "small" }) }))] }), _jsx(Box, { display: "flex", alignItems: "center", children: children })] }));
}
