import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  metricName: {
    color: theme.palette.grey[500],
  },
}));

interface Props {
  label: string;
}

export function ChangedMetricLabel({ label }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Typography variant="inherit" className={classes.metricName}>
      {label}
    </Typography>
  );
}
