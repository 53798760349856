import { useContext } from "react";
import { useTranslation } from "@lumar/shared";

import {
  useAddAdobeConnectionMutation,
  useGetAdobeAnalyticsReportSuitsQuery,
} from "../../../../graphql";
import { translationNamespace } from "../../CrawlSettings";
import { SourcesContext } from "../data/useSourcesFormValues";

export interface AnalyticsValuesResult {
  adobeAnalyticsReportSuites: AdobeAnalyticsReportSuites[];
  addAdobeConnection: (
    clientId: string,
    clientSecret: string,
    orgId: string,
    privateKey: string,
    technicalAccountId: string,
  ) => Promise<string>;
}

export interface AdobeAnalyticsReportSuites {
  id: string;
  reportSuiteId: string;
  connectionId: string;
  suiteName: string;
  companyName: string;
}

export function useAnalyticsValues(): AnalyticsValuesResult {
  const { t } = useTranslation(translationNamespace);
  const context = useContext(SourcesContext);

  const { data } = useGetAdobeAnalyticsReportSuitsQuery({
    onError: () => context.onError(t("message.apiErrorTitleInit")),
  });

  const [addConnection] = useAddAdobeConnectionMutation();

  return {
    adobeAnalyticsReportSuites: !data
      ? []
      : [
          // FIXME: Remove `adobeConnections` when API stops supporting them.
          ...data.me.adobeConnections.nodes.flatMap((connection) =>
            connection.availableAdobeAnalyticsReportSuites.map(
              (reportSuite) => ({
                id: `${reportSuite.reportSuiteId}:${connection.id}`,
                reportSuiteId: reportSuite.reportSuiteId,
                connectionId: connection.id,
                suiteName: reportSuite.suiteName,
                companyName: connection.company || "",
              }),
            ),
          ),
          ...data.me.adobeJwtConnections.nodes.flatMap((connection) =>
            connection.availableAdobeAnalyticsReportSuites.map(
              (reportSuite) => ({
                id: `${reportSuite.reportSuiteId}:${connection.id}`,
                reportSuiteId: reportSuite.reportSuiteId,
                connectionId: connection.id,
                suiteName: reportSuite.suiteName,
                companyName: connection.orgId,
              }),
            ),
          ),
        ],
    addAdobeConnection: async (
      clientId,
      clientSecret,
      orgId,
      privateKey,
      technicalAccountId,
    ) => {
      try {
        await addConnection({
          variables: {
            clientId,
            clientSecret,
            orgId,
            privateKey,
            technicalAccountId,
          },
          refetchQueries: ["GetAdobeAnalyticsReportSuits"],
          awaitRefetchQueries: true,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        return error?.message ?? "Error";
      }

      return "";
    },
  };
}
