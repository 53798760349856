import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";

const useStyles = makeStyles(() => ({
  container: {
    background: "#EBEFF3",
    border: "1px solid #D1D5DB",
    borderRadius: 8,
    padding: "10px 15px 15px 15px",
    marginBottom: 17,
  },
  header: { fontSize: 14, fontWeight: 600, marginBottom: 15 },
}));

export function ProgressControlsContainer(props: {
  children?: ReactNode;
  header?: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography variant="body1" className={classes.header}>
        {props.header}
      </Typography>
      {props.children}
    </Box>
  );
}
