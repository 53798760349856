import {
  XCircle,
  StopCircle,
  PauseCircle,
  Snackbar,
  SnackbarProps,
  PlayCircle,
  ArrowCircleRight,
  useTranslation,
  ApolloCache,
  getApiProjectId,
} from "@lumar/shared";
import { SvgIcon } from "@material-ui/core";
import {
  RunningCrawlQuery,
  useCancelCrawlingMutation,
  useDeleteCrawlMutation,
  usePauseCrawlingMutation,
  useResumeCrawlingMutation,
  useStartCrawlingMutation,
} from "../../../graphql";
import { SnackbarKey, useSnackbar } from "notistack";
import React from "react";
import {
  hasWeb,
  isCrawlRunning,
  isFinalising,
  isLevelPausedWithoutLevelUpdate,
  isPaused,
  isQeued,
} from "../_common/running-crawl-query-utils";
import { useURLSearchParams } from "../../../_common/routing/useURLSearchParams";
import { useHistory, useParams } from "react-router-dom";
import { RunningCrawl } from "../../useProjectPoller";

export interface CrawlControlsListItemProps {
  label: string;
  icon: typeof SvgIcon;
  iconColor: string;
  onClick: (runningCrawl?: RunningCrawl, project?: RunningCrawlQuery) => void;
  disabled: (
    runningCrawl?: RunningCrawl,
    project?: RunningCrawlQuery,
  ) => boolean;
  hidden: (runningCrawl?: RunningCrawl, project?: RunningCrawlQuery) => boolean;
  needActionConfirmation?: boolean;
  actionTitle?: string;
}

export function useCrawlControlsListItems(
  project?: RunningCrawlQuery,
  runningCrawl?: RunningCrawl,
): CrawlControlsListItemProps[] {
  const { t } = useTranslation("crawlProgress");
  const searchParams = useURLSearchParams();
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();

  const updateList = {
    refetchQueries: ["CrawlsListTable", "RunningCrawl", "ProjectSummary"],
    awaitRefetchQueries: true,
    update: (cache: ApolloCache<unknown>) => {
      cache.modify({
        id: cache.identify({
          __typename: "Project",
          id: getApiProjectId(projectId),
        }),
        fields: {
          crawls: (_, details) => details.DELETE,
        },
      });
    },
  };
  const [start] = useStartCrawlingMutation(updateList);
  const [pause] = usePauseCrawlingMutation(updateList);
  const [finalise] = useCancelCrawlingMutation(updateList);
  const [cancel] = useDeleteCrawlMutation(updateList);
  const [resume] = useResumeCrawlingMutation(updateList);

  const { enqueueSnackbar } = useSnackbar();
  const showSnack = (
    t: React.ReactNode,
    variant?: SnackbarProps["variant"],
    shouldPersist?: boolean,
  ): SnackbarKey =>
    enqueueSnackbar(<Snackbar variant={variant ?? "success"} title={t} />, {
      persist: shouldPersist,
    });

  const startCrawl = async (): Promise<void> => {
    try {
      await start({
        variables: { projectId },
      });
      showSnack(t("crawlControls.startMessage"));
    } catch (e) {
      showSnack((e as Error).message, "error", true);
    }
  };

  const startCrawlConfig: CrawlControlsListItemProps = {
    label: t("crawlControls.start"),
    icon: PlayCircle,
    iconColor: "#059669",
    disabled: (crawl) => isCrawlRunning(crawl),
    onClick: () => startCrawl(),
    hidden: (crawl) => isCrawlRunning(crawl),
  };

  if (searchParams.has("start")) {
    searchParams.delete("start");
    history.replace({ search: searchParams.toString() });

    if (!startCrawlConfig.disabled(runningCrawl, project)) startCrawl();
  }

  const configs: CrawlControlsListItemProps[] = [
    startCrawlConfig,
    {
      label: t("crawlControls.pause"),
      icon: PauseCircle,
      iconColor: "#F97316",
      disabled: (crawl) =>
        !isCrawlRunning(crawl) || isFinalising(crawl) || isQeued(crawl),
      onClick: async (crawl) => {
        try {
          await pause({ variables: { crawlId: crawl?.id } });
          showSnack(t("crawlControls.pausedMessage"));
        } catch (e) {
          showSnack((e as Error).message, "error");
        }
      },
      hidden: (crawl) => isPaused(crawl) || !isCrawlRunning(crawl),
    },
    {
      label: t("crawlControls.unpause"),
      icon: PlayCircle,
      iconColor: "#059669",
      disabled: (crawl) =>
        !isCrawlRunning(crawl) ||
        isLevelPausedWithoutLevelUpdate(project, crawl),
      onClick: async (crawl) => {
        try {
          await resume({ variables: { crawlId: crawl?.id } });
          showSnack(t("crawlControls.unpausedMessage"));
        } catch (e) {
          showSnack((e as Error).message, "error");
        }
      },
      hidden: (crawl) => !isPaused(crawl),
    },
    {
      label: t("crawlControls.otherSources"),
      icon: ArrowCircleRight,
      iconColor: "#0284C7",
      disabled: (crawl) => isQeued(crawl) || isFinalising(crawl),
      onClick: async (crawl) => {
        try {
          await resume({ variables: { crawlId: crawl?.id } });
          showSnack(t("crawlControls.unpausedMessage"));
        } catch (e) {
          showSnack((e as Error).message, "error");
        }
      },
      hidden: (crawl, p) =>
        (p?.getProject?.crawlTypes?.length ?? 1) <= 1 ||
        !hasWeb(crawl) ||
        !isPaused(crawl) ||
        !isLevelPausedWithoutLevelUpdate(project, crawl),
    },
    {
      label: t("crawlControls.finalize"),
      icon: StopCircle,
      iconColor: "#4B5563",
      disabled: (crawl) =>
        !isCrawlRunning(crawl) || isQeued(crawl) || isFinalising(crawl),
      onClick: async (crawl) => {
        try {
          await finalise({ variables: { crawlId: crawl?.id } });
          showSnack(t("crawlControls.finalizeMessage"));
        } catch (e) {
          showSnack((e as Error).message, "error");
        }
      },
      hidden: () => false,
      needActionConfirmation: true,
      actionTitle: t("crawlControls.finaliseConfirm"),
    },
    {
      label: t("crawlControls.cancel"),
      icon: XCircle,
      iconColor: "#DC2626",
      disabled: (crawl) => !isCrawlRunning(crawl),
      onClick: async (crawl) => {
        try {
          await cancel({
            variables: { crawlId: crawl?.id },
          });
          showSnack(t("crawlControls.cancelMessage"));
        } catch (e) {
          showSnack((e as Error).message, "error");
        }
      },
      hidden: (_, p) => p?.getProject?.isTestSuite || false,
      needActionConfirmation: true,
      actionTitle: t("crawlControls.cancelConfirm"),
    },
  ];

  return configs;
}
