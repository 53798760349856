import { MetricsValuePresenterProps } from "../../data/types";
import xmlFormat from "xml-formatter";
import { CopyButton } from "../CopyButton";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  text: {
    paddingRight: 16,
    "&:hover $copyButton": {
      visibility: "visible",
    },
  },
  copyButton: {},
}));

export function ElementSourcePresenter({
  value,
  showCopy,
}: MetricsValuePresenterProps & { showCopy?: boolean }): JSX.Element {
  const classes = useStyles();

  if (!value) return <>-</>;

  const formattedValue = xmlFormat(value, {
    throwOnFailure: false,
  });

  return (
    <span className={clsx({ [classes.text]: showCopy })}>
      {formattedValue}
      <CopyButton value={formattedValue} className={classes.copyButton} />
    </span>
  );
}
