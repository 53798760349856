import { ListItem, Grid, Box, makeStyles } from "@material-ui/core";
import {
  CrawlSourcesIcons,
  FaviconIcon,
  ArrowForwardIcon,
  useTranslation,
} from "@lumar/shared";
import { SelectedProjectInfo } from "../../selected-project-info/SelectedProjectInfo";
import { ProjectSelectorProject } from "./Project.type";
import { CrawlTypeMetadata } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  faded: { color: "#8B909B", fontSize: 11 },
  projectItem: {
    borderBottom: "solid 1px #D1D5DB",
    whiteSpace: "nowrap",
    padding: "10px 15px",
    borderRadius: 0,
  },
  projectItemText: {
    overflow: "hidden",
  },
  rightArrow: { color: theme.palette.primary.main, fontSize: 19 },
  sources: {
    color: theme.palette.grey[500],
    width: "100%",
    padding: theme.spacing(0.375, 0),
  },
  favicon: {
    padding: "2px",
    borderRadius: 4,
  },
  faviconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface ProjectSelectorItemProps {
  project: ProjectSelectorProject;
  crawlTypesMetadata: Pick<CrawlTypeMetadata, "id" | "name" | "code">[];
  onClick: () => void;
}

export function ProjectSelectorItem({
  project,
  crawlTypesMetadata,
  onClick,
}: ProjectSelectorItemProps): JSX.Element {
  const { t } = useTranslation("units");
  const classes = useStyles();

  const sources = project.lastFinishedCrawl?.crawlTypes.map((crawlType) => ({
    code: crawlType,
    name: crawlTypesMetadata.find((x) => x.code === crawlType)?.name ?? "",
    active: true,
  }));

  return (
    <ListItem
      className={classes.projectItem}
      button
      disableRipple
      data-testid="project-selector-item"
      onClick={onClick}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        direction="row"
      >
        <Grid item xs={1} container justifyContent="flex-start">
          <div className={classes.faviconDiv}>
            <FaviconIcon
              url={project.primaryDomain}
              width={24}
              height={24}
              className={classes.favicon}
            />
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={8} className={classes.projectItemText}>
              <SelectedProjectInfo
                size="medium"
                selectedProjectInfo={{
                  name: project.name,
                  primaryDomain: project.primaryDomain,
                }}
              ></SelectedProjectInfo>
            </Grid>
            <Grid
              container
              item
              xs={4}
              className={classes.faded}
              justifyContent="flex-end"
            >
              <Box style={{ textTransform: "uppercase" }}>
                {project.finishedAt
                  ? t("timeAgo", { date: new Date(project.finishedAt) })
                  : null}
              </Box>
              <CrawlSourcesIcons
                sources={sources}
                size="small"
                align="right"
                hideInactive
                className={classes.sources}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          <ArrowForwardIcon className={classes.rightArrow}></ArrowForwardIcon>
        </Grid>
      </Grid>
    </ListItem>
  );
}
