import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Checkbox } from "@lumar/shared";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: theme.spacing(1, 1, 1, 3),
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      minHeight: 0,
      borderBottomColor: theme.palette.grey[300],
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
    },
    selectAllCheckbox: {
      marginRight: theme.spacing(1.75),
    },
  }),
);

interface Props {
  numSelected: number;
  numDisabled: number;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderToolbarActions?: () => JSX.Element;
  renderToolbarSummary?: () => JSX.Element;
}

export const EnhancedTableToolbar = (props: Props): JSX.Element => {
  const classes = useStyles();
  const {
    renderToolbarActions,
    numSelected,
    numDisabled,
    rowCount,
    onSelectAllClick,
    renderToolbarSummary,
  } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <Checkbox
        className={classes.selectAllCheckbox}
        indeterminate={numSelected > 0 && numSelected < rowCount - numDisabled}
        checked={rowCount > 0 && numSelected === rowCount - numDisabled}
        onChange={onSelectAllClick}
      />

      {renderToolbarSummary && renderToolbarSummary()}

      {renderToolbarActions && renderToolbarActions()}
    </Toolbar>
  );
};
