import React from "react";
import clsx from "clsx";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { usePopupState, bindPopover } from "material-ui-popup-state/hooks";
import { sortBy } from "lodash";
import {
  Button,
  ComboBox,
  InfoOutlined,
  Select,
  useTranslation,
} from "@lumar/shared";
import {
  DataExplorerTableConfig,
  Metric,
  Column,
  DataExplorerAggregationCalculation,
} from "../types";
import { useAggregationCalculations } from "../data/useAggregationCalculations";
import { doesMetricSupportAggregation } from "../doesMetricSupportAggregation";

const useStyles = makeStyles((theme) => ({
  headerButton: {
    opacity: 0,
    fontSize: theme.typography.pxToRem(12),
    maxHeight: 30,
    height: 30,
    padding: theme.spacing(1, 1.5, 1, 1.5),
    minWidth: 30,
    marginLeft: theme.spacing(1),
    boxShadow: "none",
  },
  editOpen: {
    opacity: 1,
  },
  metricSelect: {
    minWidth: 260,
    marginRight: theme.spacing(2),
  },
  aggregationCalculationSelect: {
    width: 180,
  },
  option: {
    display: "flex",
    width: "100%",
  },
  menuItem: {
    "&:hover $infoIcon": {
      opacity: 1,
    },
  },
  input: {
    marginTop: 0,
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    alignSelf: "center",
    opacity: 0,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1.75, 2),
    borderTopColor: theme.palette.grey[200],
    borderTopStyle: "solid",
    borderTopWidth: "1px",
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  container: {
    display: "flex",
    margin: theme.spacing(2),
  },
  aggegateMenuItem: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  aggegateMenuItemName: {
    flexGrow: 1,
  },
}));

interface Props {
  columnIndex: number;
  availableMetrics: Array<Metric>;
  tableConfig: DataExplorerTableConfig;
  setTableConfig: React.Dispatch<React.SetStateAction<DataExplorerTableConfig>>;
}

export function InlineEdit({
  columnIndex,
  availableMetrics,
  tableConfig,
  setTableConfig,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");

  const popupState = usePopupState({
    variant: "popover",
    popupId: `${columnIndex}`,
  });

  const metricOptions = sortBy(availableMetrics, (metric) =>
    metric.name.toLowerCase(),
  );

  return (
    <>
      <Button
        onClick={(event: React.MouseEvent<unknown>) => {
          event.stopPropagation();
          popupState.open(event);
        }}
        size="small"
        className={clsx(
          classes.headerButton,
          "inline-edit-button",
          popupState.isOpen && classes.editOpen,
        )}
        data-testid={`metric-edit-icon-${columnIndex + 1}`}
        aria-label="Edit column"
      >
        {t("editColumn")}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        onClose={(event: React.MouseEvent<unknown>) => {
          event.stopPropagation();
          popupState.close();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -9,
          horizontal: "center",
        }}
        onClick={(event: React.MouseEvent<unknown>) => {
          event.stopPropagation();
        }}
      >
        <EditForm
          column={tableConfig.columns[columnIndex]}
          columnIndex={columnIndex}
          metricOptions={metricOptions}
          setTableConfig={setTableConfig}
          onClose={() => popupState.close()}
        />
      </Popover>
    </>
  );
}

function EditForm({
  column,
  columnIndex,
  metricOptions,
  setTableConfig,
  onClose,
}: {
  column: Column;
  columnIndex: number;
  metricOptions: Metric[];
  setTableConfig: React.Dispatch<React.SetStateAction<DataExplorerTableConfig>>;
  onClose: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");

  const aggregationCalculations = useAggregationCalculations();

  const [metric, setMetric] = React.useState(
    metricOptions.find((x) => x.code === column.metric.code) ||
      metricOptions[0],
  );
  const [aggregate, setAggregate] = React.useState(
    column.aggregationCalculation,
  );

  const aggregationOptions = aggregationCalculations.map((aggregation) => ({
    ...aggregation,
    enabled: doesMetricSupportAggregation(aggregation.id, metric?.code || ""),
  }));

  return (
    <div>
      <div className={classes.container}>
        <ComboBox
          value={metric}
          onChange={(_, value) => {
            setMetric(value);

            if (!doesMetricSupportAggregation(aggregate, value.code)) {
              const supportedAggregations = aggregationCalculations
                .map((aggregation) => ({
                  ...aggregation,
                  enabled: doesMetricSupportAggregation(
                    aggregation.id,
                    value.code,
                  ),
                }))
                .filter((aggregation) => aggregation.enabled);

              if (supportedAggregations[0]) {
                setAggregate(supportedAggregations[0].id);
              }
            }
          }}
          options={metricOptions}
          getOptionLabel={(option) => option.name}
          renderOption={(option) => (
            <div
              className={classes.option}
              data-testid={`data-explorer-metric-option-${option.code}`}
            >
              {option.name}
              {option.description && (
                <Tooltip title={option.description} placement="right">
                  <InfoOutlined
                    className={classes.infoIcon}
                    fontSize="small"
                    data-testid="metric-description-icon"
                  />
                </Tooltip>
              )}
            </div>
          )}
          disableClearable
          className={classes.metricSelect}
          classes={{ option: classes.menuItem }}
          inputProps={{
            inputProps: {
              "data-testid": "data-explorer-metric-select",
            },
          }}
        />
        <Select
          value={aggregate}
          onChange={(e) =>
            setAggregate(e.target.value as DataExplorerAggregationCalculation)
          }
          data-testid="data-explorer-aggregation-select"
          className={classes.aggregationCalculationSelect}
        >
          {aggregationOptions.map((aggregation) => {
            if (!aggregation.enabled) {
              return (
                <Tooltip
                  key={aggregation.id}
                  title={t("unsupportedAggregationType")}
                >
                  <MenuItem value={aggregation.id} disabled>
                    {aggregation.name}
                  </MenuItem>
                </Tooltip>
              );
            }

            return (
              <MenuItem
                key={aggregation.id}
                value={aggregation.id}
                className={classes.menuItem}
              >
                <div
                  className={classes.aggegateMenuItem}
                  data-testid={`data-explorer-aggregation-option-${aggregation.id}`}
                >
                  <div className={classes.aggegateMenuItemName}>
                    {aggregation.name}
                  </div>
                  <Tooltip title={aggregation.info} placement="right">
                    <InfoOutlined
                      className={classes.infoIcon}
                      fontSize="small"
                      data-testid={`data-explorer-aggregation-info-${aggregation.id}`}
                    />
                  </Tooltip>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="outlined"
          className={classes.cancelButton}
          onClick={onClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setTableConfig((config) => ({
              ...config,
              columns: [
                ...config.columns.slice(0, columnIndex),
                {
                  aggregationCalculation: aggregate,
                  metric: { code: metric.code },
                },
                ...config.columns.slice(columnIndex + 1),
              ],
            }));

            onClose();
          }}
          data-testid="data-explorer-edit-metric-save"
        >
          {t("update")}
        </Button>
      </div>
    </div>
  );
}
