import React from "react";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Snackbar, ToggleIconButton } from "@lumar/shared";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FileUpload } from "./types";

const useStyles = makeStyles(() => ({
  actionInProgress: {
    width: "24px",
    height: "24px",
    padding: "2px",
    display: "inline-flex",
    verticalAlign: "middle",
  },
}));

export function FileDownloadButton({
  file,
  disabled,
}: {
  file: FileUpload;
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  const [downloading, setDownloading] = React.useState(false);

  const handleDownload = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) return;
    e.preventDefault();

    setDownloading(true);

    fetch(file.fileLink)
      .then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", file.fileName);
          link.click();
          link.remove();

          setDownloading(false);
        });
      })
      .catch((error) => {
        if (error?.message)
          enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
        setDownloading(false);
      });
  };

  if (downloading)
    return (
      <div className={classes.actionInProgress}>
        <CircularProgress size={20} />
      </div>
    );

  return (
    <ToggleIconButton
      href={file.fileLink}
      onClick={handleDownload}
      size="small"
      disabled={disabled}
      data-testid="manual-file-upload-download"
    >
      <Tooltip
        title={t("sources.manualUpload.downloadTooltip")}
        placement="top"
        arrow
      >
        <DownloadIcon />
      </Tooltip>
    </ToggleIconButton>
  );
}
