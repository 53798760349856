import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Task } from "../data/types";
import { Typography, useNumberFormatter, useTranslation } from "@lumar/shared";
import clsx from "clsx";
import { ColumnDefinition, useTasksColumns } from "./useTasksColumns";
import React from "react";
import { useMemoizedTasksMap } from "./useMemoizedTasksMap";
import { LegacyTaskStatus } from "../../graphql";
import { kebabCase } from "lodash";
import { TFunction } from "i18next";

interface TasksTablesProps {
  tasks: Task[];
  onTaskSelected?: (task: Task) => void;
}

export function TasksTables({
  tasks,
  onTaskSelected,
}: TasksTablesProps): JSX.Element {
  const tasksMap = useMemoizedTasksMap(tasks);

  return (
    <>
      <TasksTable
        status={LegacyTaskStatus.Done}
        tasks={tasksMap.Done}
        onTaskSelected={onTaskSelected}
      />
      <TasksTable
        status={LegacyTaskStatus.Testing}
        tasks={tasksMap.Testing}
        onTaskSelected={onTaskSelected}
      />
      <TasksTable
        status={LegacyTaskStatus.InProgress}
        tasks={tasksMap.InProgress}
        onTaskSelected={onTaskSelected}
      />
      <TasksTable
        status={LegacyTaskStatus.ToDo}
        tasks={tasksMap.ToDo}
        onTaskSelected={onTaskSelected}
      />
      <TasksTable
        status={LegacyTaskStatus.Backlog}
        tasks={tasksMap.Backlog}
        onTaskSelected={onTaskSelected}
      />
    </>
  );
}

interface TasksTableProps {
  status: LegacyTaskStatus;
  tasks: Task[];
  onTaskSelected?: (task: Task) => void;
  renderRow?: React.ElementType<TasksTableRowProps>;
  bodyRef?: React.Ref<HTMLTableSectionElement>;
}

const TasksTableInner = React.forwardRef(function TasksTableInner(
  { status, tasks, onTaskSelected, renderRow, bodyRef }: TasksTableProps,
  ref: React.Ref<HTMLDivElement>,
): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const formatNumber = useNumberFormatter();

  const columns = useTasksColumns({ status });

  const ResolvedTasksTableRow = renderRow ?? TasksTableRow;

  return (
    <div className={classes.root} ref={ref}>
      <Table
        className={classes.table}
        aria-label={getAriaLabel(t, status)}
        data-testid={`task-manager-table-${kebabCase(status)}`}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => (
              <TableCell
                key={column.header}
                width={column.width}
                className={clsx({
                  [classes.headerCell]: true,
                  [classes.emptyTableCell]: tasks.length === 0,
                })}
              >
                <div className={classes.headerContainer}>
                  {column.color && (
                    <div
                      className={classes.indicator}
                      style={{ backgroundColor: column.color }}
                    />
                  )}
                  <Typography
                    variant="captionSemiBold"
                    className={classes.headerValue}
                  >
                    {column.header}
                    {idx === 0 ? ` ${formatNumber(tasks.length)}` : ""}
                  </Typography>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody ref={bodyRef}>
          {tasks.map((task) => (
            <ResolvedTasksTableRow
              key={task.id}
              task={task}
              columns={columns}
              onClick={onTaskSelected}
            />
          ))}
        </TableBody>
      </Table>
      {status === LegacyTaskStatus.Backlog && (
        <div className={classes.visuallyHidden}>
          <Typography id="task-row-text">{t("table.editTaskText")}</Typography>
        </div>
      )}
    </div>
  );
});

export const TasksTable = React.memo(TasksTableInner);

interface TasksTableRowProps {
  task: Task;
  columns: ColumnDefinition[];
  isDragging?: boolean;
  onClick?: (task: Task) => void;
  style?: React.CSSProperties;
}

export const TasksTableRow = React.forwardRef(function TasksTableRow(
  { task, columns, isDragging, onClick, style, ...props }: TasksTableRowProps,
  ref: React.Ref<HTMLTableRowElement>,
): JSX.Element {
  const classes = useStyles();

  return (
    <TableRow
      ref={ref}
      className={classes.dataRow}
      tabIndex={0}
      onClick={() => onClick?.(task)}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          onClick?.(task);
        }
      }}
      aria-describedby="task-row-text"
      aria-label={task.title}
      data-testid="task-row"
      style={{ ...style, cursor: onClick ? "pointer" : undefined }}
      {...props}
    >
      <TasksTableRowInner
        task={task}
        columns={columns}
        isDragging={isDragging}
      />
    </TableRow>
  );
});

const TasksTableRowInner = React.memo(function TasksTableRowInner({
  task,
  columns,
  isDragging,
}: TasksTableRowProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {columns.map((column) => (
        <TableCell
          key={column.header}
          className={classes.dataCell}
          width={column.width}
        >
          <div
            className={classes.dataContainer}
            style={{ justifyContent: column.align }}
          >
            {column.renderCell({ task, isDragging: isDragging || false })}
          </div>
        </TableCell>
      ))}
    </>
  );
});

function getAriaLabel(
  t: TFunction<"taskManager">,
  status: LegacyTaskStatus,
): string | undefined {
  switch (status) {
    case LegacyTaskStatus.Done:
      return t("table.ariaLabelDone");
    case LegacyTaskStatus.Testing:
      return t("table.ariaLabelTesting");
    case LegacyTaskStatus.InProgress:
      return t("table.ariaLabelInProgress");
    case LegacyTaskStatus.ToDo:
      return t("table.ariaLabelToDo");
    case LegacyTaskStatus.Backlog:
      return t("table.ariaLabelBacklog");
    default:
      return undefined;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "auto",
    overflowY: "clip",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 800,
    tableLayout: "fixed",
    background: "white",
    borderRadius: theme.spacing(1),
    "& thead tr": {
      height: 40,
    },
    "& th:first-child $headerContainer": {
      borderStyle: "none",
    },
    borderCollapse: "separate",
  },
  headerCell: {
    padding: 0,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    height: theme.spacing(3.5),
    borderLeft: `solid 1px ${theme.palette.grey[200]}`,
  },
  headerValue: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "none",
  },
  indicator: {
    width: 9,
    height: 9,
    marginRight: theme.spacing(1.375),
  },
  dataContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    minHeight: 52,
    maxHeight: 52,
  },
  dataRow: {
    height: "54px",
    boxShadow: `0px 1px 0px ${theme.palette.grey[200]}`,
    "& td": {
      borderTop: "1px solid transparent",
      borderBottom: "1px solid transparent",
    },
    "& td:first-child": {
      borderLeft: "1px solid transparent",
    },
    "& td:last-child": {
      borderRight: "1px solid transparent",
    },
    "&:focus td": {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    "&:focus td:first-child": {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
    "&:focus td:last-child": {
      borderRight: `1px solid ${theme.palette.primary.main}`,
    },
  },
  dataCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  emptyTableCell: {
    borderStyle: "none",
  },
  visuallyHidden: {
    border: "0 !important",
    clip: "rect(0, 0, 0, 0) !important",
    height: "1px !important",
    margin: "-1px !important",
    overflow: "hidden !important",
    padding: "0 !important",
    whiteSpace: "nowrap",
    width: "1px !important",
    position: "absolute",
  },
}));
