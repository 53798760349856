import React from "react";
import { makeStyles } from "@material-ui/core";
import { ArrowNarrowRightSolid, ChartButton } from "@lumar/shared";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    lineHeight: theme.typography.pxToRem(17),
    padding: theme.spacing(0.8, 1.75, 0.8, 1.5),
    height: 32,
    whiteSpace: "nowrap",
    ...theme.overrides?.ChartPanelButton?.button,
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  endIcon: {
    alignItems: "center",
    display: "flex",
  },
  icon: {
    width: "16px",
    height: "16px",
  },
}));

interface Props {
  link?: string;
  label?: string;
}

export function ChartPanelButton({
  link,
  label,
  ...props
}: Props): JSX.Element | null {
  const classes = useStyles();

  if (!link) return null;

  return (
    <ChartButton
      component={RouterLink}
      to={link}
      endIcon={<ArrowNarrowRightSolid className={classes.icon} />}
      className={classes.button}
      classes={{ endIcon: classes.endIcon }}
      data-testid="more-url-button"
      {...props}
    >
      <span className={classes.label}>{label}</span>
    </ChartButton>
  );
}
