import { useParams } from "react-router-dom";
import { useGetTaggedTaskReportsRowCountQuery } from "../../graphql";
import { ApolloError } from "@lumar/shared";
import { useConvertFilter } from "../../report/report-rows/report-grid/rows/useReportGridRows";

type TaggedTaskReportsRowCount = {
  loading: boolean;
  error?: ApolloError;
  rowCounts: {
    all?: number;
    unresolved?: number;
    resolved?: number;
    new?: number;
  };
  reportsNotFound: boolean;
};

export function useTaggedTaskReportsRowCount({
  allReportTemplateId,
  unresolvedReportTemplateId,
  resolvedReportTemplateId,
  newReportTemplateId,
}: {
  allReportTemplateId?: string;
  unresolvedReportTemplateId?: string;
  resolvedReportTemplateId?: string;
  newReportTemplateId?: string;
}): TaggedTaskReportsRowCount {
  const { crawlId } = useParams<{ crawlId: string }>();

  const filter = useConvertFilter(undefined);

  const { loading, error, data } = useGetTaggedTaskReportsRowCountQuery({
    variables: {
      crawlId,
      allReportTemplateId: allReportTemplateId || "",
      unresolvedReportTemplateId: unresolvedReportTemplateId || "",
      resolvedReportTemplateId: resolvedReportTemplateId || "",
      newReportTemplateId: newReportTemplateId || "",
      filter,
    },
    skip:
      !allReportTemplateId ||
      !unresolvedReportTemplateId ||
      !resolvedReportTemplateId ||
      !newReportTemplateId,
    fetchPolicy: "cache-first",
  });

  const reportsNotFound =
    !loading &&
    !error &&
    !data?.all &&
    !data?.unresolved &&
    !data?.resolved &&
    !data?.new;

  return {
    loading,
    error,
    reportsNotFound,
    rowCounts: loading
      ? {}
      : {
          all: data?.all?.rowsWithAllMetrics?.totalCount ?? 0,
          unresolved: data?.unresolved?.rowsWithAllMetrics?.totalCount ?? 0,
          resolved: data?.resolved?.rowsWithAllMetrics?.totalCount ?? 0,
          new: data?.new?.rowsWithAllMetrics?.totalCount ?? 0,
        },
  };
}
