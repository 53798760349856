import { ConnectionFilter } from "./types";

interface Input {
  or: {
    and: {
      metricCode: string;
      predicateKey: string;
      predicateValue: string | number | boolean;
    }[];
  }[];
}

export function createConnectionFilter(
  input: Input,
): Required<ConnectionFilter> {
  return {
    _or: input.or.map((orBlock) => {
      return {
        _and: orBlock.and.map((andBlock) => {
          return {
            [andBlock.metricCode]: {
              [andBlock.predicateKey]: andBlock.predicateValue,
            },
          };
        }),
      };
    }),
  };
}
