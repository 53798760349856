/* eslint-disable react/display-name */
import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { MenuItemProps, ProjectActionsCell } from "../cells/ProjectActionsCell";
import { ProjectCell, getProjectNavigationData } from "../cells/ProjectCell";
import { StatusCell } from "../cells/StatusCell";
import { LastCrawlCell } from "../cells/LastCrawlCell";
import { CountCell } from "../cells/CountCell";
import { PieChartSolid, ClipboardCheckSolid } from "@lumar/shared";
import { TFunction } from "i18next";
import { Routes } from "../../_common/routing/routes";
import { CrawlSourcesCell } from "../cells/CrawlSourcesCell";
import { FrequencyCell } from "../cells/FrequencyCell";
import { DateCell } from "../cells/DateCell";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";
import { LinkCell } from "../cells/LinkCell";
import { ChippedCountCell } from "../cells/ChippedCountCell";
import { CrawlDurationCell } from "../cells/CrawlDurationCell";
import { CrawlSpeedCell } from "../cells/CrawlSpeedCell";
import { CrawledUrlsCell } from "../cells/CrawledUrlsCell";
import { ProjectTypeCell } from "../cells/ProjectTypeCell";

export const useColumnsStyles = makeStyles(() => ({
  clickableCell: {
    padding: "0px !important",
  },
}));

export const accountProjectColumns = {
  name: getNameCol,
  status: getStatusCol,
  lastCrawl: getLastCrawlCol,
  tasks: getTasksCol,
  segments: getSegmentsCol,
  urlCount: getUrlCountCol,
  crawlCount: getCrawlsCountCol,
  createdAt: getCreatedCol,
  frequency: getFrequencyCol,
  nextCrawl: getNextCrawlCol,
  source: getSourceCol,
  crawled: getCrawledCol,
  speed: getSpeedCol,
  crawlDuration: getCrawlDurationCol,
  actions: getActionsCol,
  projectType: getProjectTypeCol,
};

export type AccountProjectsColumnTypes = keyof typeof accountProjectColumns;

type ColumProps = {
  t: TFunction<"projectsList">;
  classes: ReturnType<typeof useColumnsStyles>;
};

type ColumnDefinition = Omit<GridColDef, "renderCell"> & {
  renderCell?: (params: ProjectsGridRenderCellParams) => React.ReactNode;
};

function getNameCol({ t, classes }: ColumProps): ColumnDefinition {
  return {
    field: "name",
    headerName: t("tableHeaderProject"),
    renderCell: (params) => {
      const { getHref, tooltipTitle } = getProjectNavigationData(params.row, t);
      return (
        <LinkCell
          params={params}
          tooltipTitle={tooltipTitle}
          getHref={(_, { accountId }) => getHref(accountId)}
          data-testid="all-projects-project-link"
          data-pendo="a-project"
        >
          <ProjectCell {...params} />
        </LinkCell>
      );
    },
    cellClassName: classes.clickableCell,
    flex: 4,
    disableColumnMenu: true,
  };
}

function getStatusCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "lastCrawlStatus",
    headerName: t("tableHeaderStatus"),
    renderCell: (params) => {
      return <StatusCell {...params} />;
    },
    flex: 1,
    align: "center",
    disableColumnMenu: true,
  };
}

function getLastCrawlCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "finishedAt",
    headerName: t("tableHeaderLastCrawl"),
    renderCell: (params) => {
      return <LastCrawlCell {...params} />;
    },
    flex: 1,
    disableColumnMenu: true,
  };
}

function getTasksCol({ t, classes }: ColumProps): ColumnDefinition {
  return {
    field: "legacyTasksTotalCount",
    headerName: t("tableHeaderTasks"),
    valueGetter: (params) => params.row.taskTotalCount,
    renderCell: (params) => {
      return (
        <LinkCell
          data-testid="all-projects-task-count-cell"
          params={params}
          disableOnZeroCount
          tooltipTitle={t("goToTasks")}
          getHref={({ row }, { accountId }) =>
            Routes.Tasks.getUrl({
              accountId,
              projectId: row.id,
              crawlId: row.lastFinishedCrawlId || "0",
            })
          }
        >
          <ChippedCountCell params={params} icon={ClipboardCheckSolid} />
        </LinkCell>
      );
    },
    cellClassName: classes.clickableCell,
    flex: 1,
    align: "right",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getSegmentsCol({ t, classes }: ColumProps): ColumnDefinition {
  return {
    field: "segmentsTotalCount",
    headerName: t("tableHeaderSegments"),
    renderCell: (params) => {
      return (
        <LinkCell
          data-testid="all-projects-segments-cell"
          data-pendo="segments"
          params={params}
          tooltipTitle={t("goToSegments")}
          getHref={({ row }, { accountId }) =>
            Routes.SegmentManager.getUrl({
              accountId,
              projectId: row.id,
              crawlId: row.lastFinishedCrawlId || "0",
            })
          }
        >
          <ChippedCountCell params={params} icon={PieChartSolid} />
        </LinkCell>
      );
    },
    cellClassName: classes.clickableCell,
    flex: 1,
    align: "right",
    disableColumnMenu: true,
  };
}

function getUrlCountCol({ t, classes }: ColumProps): ColumnDefinition {
  return {
    field: "crawlUrls",
    headerName: t("tableHeaderURLs"),
    renderCell: (params) => {
      return (
        <LinkCell
          data-testid="all-projects-urls-count-cell"
          params={params}
          tooltipTitle={t("goToTheLatestCrawlOverview")}
          getHref={({ row }, { accountId }) =>
            row.lastFinishedCrawlId
              ? Routes.CrawlOverview.getUrl({
                  accountId,
                  projectId: row.id,
                  crawlId: row.lastFinishedCrawlId,
                  type: "dashboard",
                })
              : ""
          }
        >
          <CountCell
            params={params}
            data-testid="all-projects-urls-count-cell"
          />
        </LinkCell>
      );
    },
    cellClassName: classes.clickableCell,
    align: "right",
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  };
}

function getCrawlsCountCol({ t, classes }: ColumProps): ColumnDefinition {
  return {
    field: "crawlsTotalCount",
    headerName: t("tableHeaderCrawls"),
    renderCell: (params) => {
      return (
        <LinkCell
          data-testid="all-projects-crawls-count-cell"
          params={params}
          tooltipTitle={t("goToCrawls")}
          disableOnZeroCount
          getHref={(params, { accountId }) => {
            const projectId = params.row.id as string;
            return Routes.Crawls.getUrl({
              accountId,
              projectId,
              tab: "history",
            });
          }}
        >
          <CountCell
            params={params}
            data-testid="all-projects-crawls-count-cell"
          />
        </LinkCell>
      );
    },
    cellClassName: classes.clickableCell,
    align: "right",
    flex: 1,
    disableColumnMenu: true,
  };
}

function getCreatedCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "createdAt",
    headerName: t("tableHeaderCreated"),
    flex: 2,
    renderCell: (params) => {
      return (
        <DateCell params={params} data-testid="all-projects-created-cell" />
      );
    },
    disableColumnMenu: true,
  };
}

function getFrequencyCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "frequency",
    headerName: t("tableHeaderFrequency"),
    renderCell: (params) => {
      return <FrequencyCell {...params} />;
    },
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  };
}

function getNextCrawlCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "nextCrawl",
    headerName: t("tableHeaderNextCrawl"),
    valueGetter: ({ row }) => row.schedule?.nextRunTime,
    renderCell: (params) => {
      return (
        <DateCell params={params} data-testid="all-projects-next-crawl-cell" />
      );
    },
    flex: 2,
    hide: true,
    disableColumnMenu: true,
    sortable: false,
  };
}

function getSourceCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "crawlTypes",
    headerName: t("tableHeaderCrawlSources"),
    renderCell: (params) => <CrawlSourcesCell {...params} />,
    width: 180,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getCrawledCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "totalSteps",
    headerName: t("crawledUrls"),
    renderCell: (params) => <CrawledUrlsCell {...params} />,
    width: 150,
    headerAlign: "left",
    align: "right",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getSpeedCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "crawledPerSecond",
    headerName: t("crawlSpeed"),
    renderCell: (params) => <CrawlSpeedCell {...params} />,
    width: 150,
    headerAlign: "left",
    align: "left",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getCrawlDurationCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "duration",
    headerName: t("duration"),
    renderCell: (params) => <CrawlDurationCell {...params} />,
    width: 170,
    headerAlign: "left",
    align: "left",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getActionsCol({
  t,
  menuItems,
}: ColumProps & {
  menuItems: MenuItemProps[];
}): ColumnDefinition {
  return {
    field: "actions",
    headerName: t("tableHeaderActions"),
    renderCell: (params) => {
      return <ProjectActionsCell {...params} menuItems={menuItems} />;
    },
    width: 100,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    sortable: false,
  };
}

function getProjectTypeCol({ t }: ColumProps): ColumnDefinition {
  return {
    field: "projectType",
    headerName: t("tableHeaderProjectType"),
    renderCell: (params) => {
      return <ProjectTypeCell {...params} />;
    },
    width: 150,
    disableColumnMenu: true,
    sortable: false,
  };
}
