import {
  ArrowNarrowRightSolid,
  Button,
  getRawCrawlId,
  getRawProjectId,
  getRawTaskId,
  Typography,
  useNumberFormatter,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { CreateTaskFormValuesResult } from "../data/useEditTaskFormValues";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Routes } from "../../_common/routing/routes";
import { isNotEmptyConnectionFilter } from "../../_common/connection-filtering/isNotEmptyConnectionFilter";
import { ReportTypeCode } from "../../graphql";

export function TaskSummaryCard({
  task,
}: {
  task: CreateTaskFormValuesResult["task"];
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["taskManager", "units"]);
  const formatNumber = useNumberFormatter();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { hasFeatureFlagEnabled } = useSession();

  const taskReportScreenEnabled = hasFeatureFlagEnabled("task-report-screen");

  const originalTaskLink = getOriginalTaskLink(
    task,
    accountId,
    taskReportScreenEnabled,
  );
  const unresolvedTaskLink = getUnresolvedTaskLink(
    task,
    accountId,
    taskReportScreenEnabled,
  );

  return (
    <div className={classes.urlContainer}>
      {originalTaskLink ? (
        <Button
          component={Link}
          to={originalTaskLink}
          variant="contained"
          className={classes.urlButton}
        >
          <span className={classes.urlItem}>
            <Typography variant="subtitle1SemiBold">
              {formatNumber(task?.identified)}
              <ArrowNarrowRightSolid className={classes.arrowIcon} />
            </Typography>
            <Typography variant="subtitle4Medium">
              {t("taskManager:editDialog.originalUrlCount")}
            </Typography>
          </span>
        </Button>
      ) : (
        <span className={classes.urlItem}>
          <Typography variant="subtitle1SemiBold">
            {formatNumber(task?.identified)}
          </Typography>
          <Typography variant="subtitle4Medium">
            {t("taskManager:editDialog.originalUrlCount")}
          </Typography>
        </span>
      )}
      {unresolvedTaskLink ? (
        <Button
          component={Link}
          to={unresolvedTaskLink}
          variant="contained"
          className={classes.urlButton}
        >
          <span className={classes.urlItem}>
            <Typography variant="subtitle1SemiBold">
              {formatNumber(task?.remainingResolved)}
              <ArrowNarrowRightSolid className={classes.arrowIcon} />
            </Typography>
            <Typography variant="subtitle4Medium">
              {t("taskManager:editDialog.unresolvedUrlCount")}
            </Typography>
          </span>
        </Button>
      ) : (
        <span className={classes.urlItem}>
          <Typography variant="subtitle1SemiBold">
            {formatNumber(task?.remainingResolved)}
          </Typography>
          <Typography variant="subtitle4Medium">
            {t("taskManager:editDialog.unresolvedUrlCount")}
          </Typography>
        </span>
      )}
      <span className={classes.urlItem}>
        <Typography variant="subtitle1SemiBold">
          {t("units:percent", { count: task?.unresolvedPercentResolved })}
        </Typography>
        <Typography variant="subtitle4Medium">
          {t("taskManager:editDialog.percentUnresolved")}
        </Typography>
      </span>
    </div>
  );
}

function getOriginalTaskLink(
  task: CreateTaskFormValuesResult["task"],
  accountId: string,
  taskReportScreenEnabled: boolean,
): string | undefined {
  if (!taskReportScreenEnabled) return;

  const lastCrawlId = task?.project.lastFinishedCrawl?.id;

  if (!task || !lastCrawlId || !task.customReportTemplates.nodes.length) {
    return;
  }

  return Routes.TaskReport.getUrl({
    accountId,
    projectId: getRawProjectId(task.project.id),
    crawlId: getRawCrawlId(lastCrawlId),
    taskId: getRawTaskId(task.id),
  });
}

function getUnresolvedTaskLink(
  task: CreateTaskFormValuesResult["task"],
  accountId: string,
  taskReportScreenEnabled: boolean,
): string | undefined {
  const lastCrawlId = task?.project.lastFinishedCrawl?.id;

  if (!task || !task.reportTemplate || !lastCrawlId) {
    return;
  }

  if (taskReportScreenEnabled) {
    return Routes.TaskReport.getUrl({
      accountId,
      projectId: getRawProjectId(task.project.id),
      crawlId: getRawCrawlId(lastCrawlId),
      taskId: getRawTaskId(task.id),
      type: "unresolved",
    });
  }

  const isValidFilter =
    task.connectionFilter && !isNotEmptyConnectionFilter(task.connectionFilter);

  return Routes.Report.getUrl({
    accountId,
    projectId: getRawProjectId(task.project.id),
    crawlId: getRawCrawlId(lastCrawlId),
    reportTemplateCode: task.reportTemplate.code,
    reportTypeCode: (
      task.reportType?.code ?? ReportTypeCode.Basic
    ).toLowerCase(),
    segmentId: task.segment?.id,
    filter: isValidFilter
      ? { _or: [task.connectionFilter] }
      : task.connectionFilter,
  });
}

const useStyles = makeStyles((theme) => ({
  urlContainer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    display: "flex",
    marginBottom: theme.spacing(3.625),
    overflow: "hidden",
    "& > *:nth-child(2n)": {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  urlItem: {
    flexBasis: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.625),
    padding: theme.spacing(1.375, 0, 1, 0),
    color: theme.palette.grey[800],
    "& > *:first-child": {
      lineHeight: theme.typography.pxToRem(19),
    },
    "& > *:last-child": {
      lineHeight: theme.typography.pxToRem(15),
    },
  },
  urlButton: {
    flexBasis: 0,
    flexGrow: 1,
    borderRadius: 0,
    padding: 0,
    background: "white",
    "&:hover": {
      background: theme.palette.primary.light,
    },
  },
  arrowIcon: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    verticalAlign: "top",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-2),
  },
}));
