import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { useFormikContext } from "formik";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { DomainCheckVariant } from "../../../../graphql";
import { ButtonBase, CircularProgress } from "@material-ui/core";
import { FormValues } from "../data/types";
import { Typography } from "@lumar/shared";
import { DomainVariant } from "../data/useDomainCheck";
import { useValueChanged } from "../../../../_common/useValueChanged";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      width: 100,
      height: 36,
    },
    domainOption: {
      cursor: "pointer",
      background: theme.palette.grey[100],
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: theme.palette.grey[200],
      },
    },
    icon: {
      fontSize: 14,
      marginRight: theme.spacing(0.5),
    },
    goodOption: {
      color: theme.palette.success.main,
    },
    redirectOption: {
      color: theme.palette.warning.main,
    },
    active: {
      background: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    tooltip: { wordBreak: "break-all" },
    indent: {
      marginTop: theme.spacing(2),
    },
    loading: {
      color: theme.palette.success.dark,
    },
    loadingIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
  domainVariants: DomainVariant[];
  isLoading: boolean;
  called: boolean;
}

export function DomainOptions({
  domainVariants,
  isLoading,
  called,
}: Props): JSX.Element | null {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const theme = useTheme();

  const { values, setFieldValue, isSubmitting } =
    useFormikContext<FormValues>();

  const { isChanged: variantsChanged } = useValueChanged(domainVariants);

  React.useEffect(() => {
    if (!variantsChanged) return;
    const goodOptions = domainVariants.filter(
      (variant) => variant.statusCode === 200,
    );
    if (!goodOptions.length) return;

    const variant =
      goodOptions.find((x) =>
        x.domain.startsWith(values.basic.primaryDomain),
      ) ?? goodOptions[0];
    setFieldValue("basic.primaryDomain", variant.domain, true);
  }, [
    variantsChanged,
    domainVariants,
    values.basic.primaryDomain,
    setFieldValue,
  ]);

  const onDomainClick = (domain?: string): void => {
    if (domain) {
      setFieldValue("basic.primaryDomain", domain, true);
    }
  };

  const httpWithWWW = domainVariants.find(
    (variant) =>
      variant.domain.includes("www") && !variant.domain.includes("https"),
  );
  const httpsWithWWW = domainVariants.find(
    (variant) =>
      variant.domain.includes("www") && variant.domain.includes("https"),
  );
  const http = domainVariants.find(
    (variant) =>
      !variant.domain.includes("https") && !variant.domain.includes("www"),
  );
  const https = domainVariants.find(
    (variant) =>
      variant.domain.includes("https") && !variant.domain.includes("www"),
  );

  const renderIcon = (status: number): JSX.Element => {
    if (status === 200) {
      return <ThumbUpAltRoundedIcon className={classes.icon} />;
    } else {
      return <ErrorRoundedIcon className={classes.icon} />;
    }
  };

  const getClasses = (domainVariant?: DomainCheckVariant): string => {
    return clsx(
      classes.cell,
      classes.domainOption,
      domainVariant?.statusCode === 200 && classes.goodOption,
      domainVariant?.statusCode &&
        domainVariant?.statusCode !== 200 &&
        classes.redirectOption,
      values.basic.primaryDomain === domainVariant?.domain && classes.active,
    );
  };

  const isDisabled = isLoading || isSubmitting;

  if (!called) return null;

  return (
    <>
      <Box marginBottom="7px" marginTop="15px">
        {isLoading ? (
          <Typography component="div" variant="captionSemiBold">
            <CircularProgress className={classes.loadingIcon} size="0.6rem" />
            {t("domain.domainOptionLoading")}
          </Typography>
        ) : (
          <Typography variant="captionSemiBold">
            {t("domain.domainSelect")}
          </Typography>
        )}
      </Box>
      <div
        style={{
          borderRadius: 4,
          padding: 12,
          width: "400px",
          border: `1px solid ${theme.palette.grey[200]}`,
          boxShadow:
            "0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%)",
        }}
      >
        <Box display="flex">
          <Box className={classes.cell}></Box>
          <Box
            className={classes.cell}
            style={{
              borderRight: "dashed 1px #cccccc",
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1),
            }}
          >
            {t("domain.withWWW")}
          </Box>
          <Box
            className={classes.cell}
            style={{
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1),
            }}
          >
            {t("domain.withoutWWW")}
          </Box>
        </Box>
        <Box display="flex">
          <Box
            className={classes.cell}
            style={{
              borderBottom: "dashed 1px #cccccc",
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1),
            }}
          >
            {t("domain.https")}
          </Box>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              httpsWithWWW?.redirectsTo
                ? t("domain.redirectsTo", {
                    domain: httpsWithWWW?.redirectsTo,
                  })
                : ""
            }
          >
            <ButtonBase
              onClick={() => onDomainClick(httpsWithWWW?.domain)}
              disabled={isDisabled}
              className={getClasses(httpsWithWWW)}
              data-testid="crawl-settings-httpsWithWWW"
              style={{
                borderTopLeftRadius: 50,
                borderBottom: "dashed 1px #cccccc",
                borderRight: "dashed 1px #cccccc",
              }}
            >
              {httpsWithWWW?.statusCode && renderIcon(httpsWithWWW?.statusCode)}
              {httpsWithWWW?.statusCode || t("domain.NA")}
            </ButtonBase>
          </Tooltip>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              https?.redirectsTo
                ? t("domain.redirectsTo", {
                    domain: https?.redirectsTo,
                  })
                : ""
            }
          >
            <ButtonBase
              onClick={() => onDomainClick(https?.domain)}
              disabled={isDisabled}
              data-testid="crawl-settings-https"
              className={getClasses(https)}
              style={{
                borderTopRightRadius: 50,
                borderBottom: "dashed 1px #cccccc",
              }}
            >
              {https?.statusCode && renderIcon(https?.statusCode)}
              {https?.statusCode || t("domain.NA")}
            </ButtonBase>
          </Tooltip>
        </Box>
        <Box display="flex">
          <Box
            className={classes.cell}
            style={{
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(1),
            }}
          >
            {t("domain.http")}
          </Box>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              httpWithWWW?.redirectsTo
                ? t("domain.redirectsTo", {
                    domain: httpWithWWW?.redirectsTo,
                  })
                : ""
            }
          >
            <ButtonBase
              onClick={() => onDomainClick(httpWithWWW?.domain)}
              disabled={isDisabled}
              data-testid="crawl-settings-httpWithWWW"
              className={getClasses(httpWithWWW)}
              style={{
                borderBottomLeftRadius: 50,
                borderRight: "dashed 1px #cccccc",
              }}
            >
              {httpWithWWW?.statusCode && renderIcon(httpWithWWW?.statusCode)}
              {httpWithWWW?.statusCode || t("domain.NA")}
            </ButtonBase>
          </Tooltip>
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              httpWithWWW?.redirectsTo
                ? t("domain.redirectsTo", {
                    domain: httpWithWWW.redirectsTo,
                  })
                : ""
            }
          >
            <ButtonBase
              onClick={() => onDomainClick(http?.domain)}
              disabled={isDisabled}
              data-testid="crawl-settings-http"
              className={getClasses(http)}
              style={{
                borderBottomRightRadius: 50,
              }}
            >
              {http?.statusCode && renderIcon(http?.statusCode)}
              {http?.statusCode || t("domain.NA")}
            </ButtonBase>
          </Tooltip>
        </Box>
      </div>
    </>
  );
}
