import React, { useState } from "react";
import { useTranslation, Clock } from "@lumar/shared";
import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import { ProgressDashboardKeyNumber } from "../_common/progress-dashboard-key-number/ProgressDashboardKeyNumber";
import { RunningCrawl } from "../../../useProjectPoller";
import { RunningCrawlQuery } from "../../../../graphql";
import { activeStatuses } from "../../../constants";
import { useTimeDurationFormatter } from "../../../../_common/locale/format-functions/useTimeDurationFormatter";

export function CrawlDurationCard({
  project,
  runningCrawl,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation(["crawlProgress", "common"]);

  const crawlInProgress = runningCrawl?.status
    ? activeStatuses.includes(runningCrawl?.status)
    : false;

  const [currentTime, setCurrentTime] = useState<Date | undefined>(new Date());

  React.useEffect(() => {
    if (!crawlInProgress) return;
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, [crawlInProgress]);

  const lastFinishedCrawlCreatedAt =
    project?.getProject?.lastFinishedCrawl?.createdAt;
  const lastFinishedCrawlFinishedAt =
    project?.getProject?.lastFinishedCrawl?.finishedAt;

  const startTime = runningCrawl?.createdAt
    ? new Date(runningCrawl?.createdAt)
    : undefined;

  const lastFinishedCrawlStartTime = lastFinishedCrawlCreatedAt
    ? new Date(lastFinishedCrawlCreatedAt)
    : undefined;

  const lastFinishedCrawlEndTime = lastFinishedCrawlFinishedAt
    ? new Date(lastFinishedCrawlFinishedAt)
    : undefined;

  const format = useTimeDurationFormatter();

  return (
    <ProgressDashboardContainer>
      <ProgressDashboardKeyNumber
        icon={Clock}
        label={t("crawlProgress:progressDashboard.crawlDurationCardTitle")}
        value={
          startTime
            ? format(startTime, currentTime, "short")
            : format(
                lastFinishedCrawlStartTime,
                lastFinishedCrawlEndTime,
                "short",
              )
        }
        infoText={t("crawlProgress:progressDashboard.crawlDurationCardTooltip")}
      />
    </ProgressDashboardContainer>
  );
}
