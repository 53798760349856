import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  ListItem,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { ExclamationSolid, ApolloError } from "@lumar/shared";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { translationNamespace } from "../CrawlSettings";
import { getFirstLetterCapitalised } from "../../../_common/string-manipulation/getFirstLetterCapitalised";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "600px",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    color: theme.palette.red[500],
    fontSize: theme.typography.pxToRem(15),
    marginRight: 10,
    marginTop: 1,
  },
}));

export interface APIError {
  apiError: ApolloError;
  title: React.ReactNode;
  errorMessage?: string[];
}

export interface ConfirmMessage {
  title: React.ReactNode;
  desctiprion: React.ReactNode;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
}

export type MessageType = APIError | ConfirmMessage | undefined;

export interface SettingsDialogProps {
  message: MessageType;
}

export function SettingsDialog({ message }: SettingsDialogProps): JSX.Element {
  const { t } = useTranslation(translationNamespace);

  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (message) {
      setShowDialog(true);
    }
  }, [message]);

  const apiError =
    (message as APIError)?.apiError !== undefined
      ? (message as APIError)
      : undefined;

  const confirmMessage =
    (message as ConfirmMessage)?.onConfirm !== undefined
      ? (message as ConfirmMessage)
      : undefined;

  const getTitle = (): React.ReactNode => {
    if (apiError) return apiError.title;
    if (confirmMessage) return confirmMessage.title;
    return "";
  };

  const getDetailedDescription = (): string[] | undefined => {
    if (apiError) {
      return apiError.errorMessage;
    }
    return [];
  };

  const getDescription = (): React.ReactNode => {
    if (apiError) return apiError.apiError.message;
    if (confirmMessage) return confirmMessage?.desctiprion;
    return "";
  };

  return (
    <Dialog
      open={showDialog}
      maxWidth="md"
      classes={{ paper: classes.content }}
      data-testid="settings-message-dialog"
    >
      <DialogTitle disableTypography>
        <Typography variant="h2" color="primary">
          {getTitle()}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {getDescription()}
          {getDetailedDescription()?.map((description, index) => (
            <ListItem key={index}>
              <ExclamationSolid className={classes.icon} />
              {getFirstLetterCapitalised(description)}
            </ListItem>
          ))}
        </Typography>
      </DialogContent>
      <DialogActions>
        {confirmMessage ? (
          <>
            <Button
              onClick={() => {
                confirmMessage.onConfirm();
                setShowDialog(false);
              }}
              variant="contained"
              color="primary"
              data-testid="settings-message-dialog-confirm"
            >
              {confirmMessage.confirmText}
            </Button>
            <Button
              onClick={() => setShowDialog(false)}
              autoFocus
              variant="outlined"
            >
              {confirmMessage.cancelText}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setShowDialog(false)}
            autoFocus
            variant="outlined"
          >
            {t("message.close")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface SettingsDialogContextType {
  showMessage: (message: MessageType) => void;
}

const SettingsDialogContext = React.createContext<SettingsDialogContextType>({
  showMessage: () => null,
});

export function SettingsDialogProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [message, setMessage] = useState<MessageType>(undefined);

  const value = React.useMemo<SettingsDialogContextType>(
    () => ({
      showMessage: setMessage,
    }),
    [setMessage],
  );

  return (
    <SettingsDialogContext.Provider value={value}>
      {children}
      <SettingsDialog message={message} />
    </SettingsDialogContext.Provider>
  );
}

export function useSettingsDialog(): SettingsDialogContextType {
  return React.useContext(SettingsDialogContext);
}

export function getValidationErrors(error: ApolloError): string[] {
  return error.graphQLErrors
    .filter((x) => x.extensions?.code === "ARGUMENT_VALIDATION_ERROR")
    .flatMap((x) =>
      (
        x?.extensions?.validationErrors ??
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x?.extensions?.exception as any)?.validationErrors
      )?.flatMap((error: APIValidationError) => getErrorMessages(error)),
    );
}

interface APIValidationError {
  children: APIValidationError[];
  property: string;
  constraints?: Record<string, string>;
}

function getErrorMessages(x: APIValidationError): string[] {
  if (x.constraints)
    return Object.entries(x.constraints).map(
      (error) => `${x.property}: ${error[1]}`,
    );

  return (
    x.children
      ?.flatMap((child) => getErrorMessages(child))
      .map((error) => `${x.property}.${error}`) ?? []
  );
}
