import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getHreflangIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("hreflangConfig.title"),
    description: t("hreflangConfig.description"),
    reportTemplateCodes: [
      "broken_hreflang_links",
      "hreflang_link_non_200",
      "invalid_hreflang_attributes",
      "hreflang_and_page_language_conflict",
      "non_indexable_hreflang_links_in",
      "pages_with_multiple_hreflang_langs_in",
      "unsupported_hreflang_links",
    ],
  })();
}
