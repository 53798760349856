import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { GoogleAnalytics4ConversionEventType } from "../../../../graphql";
import {
  Alert,
  Checkbox,
  ChevronDownSolid,
  ChevronUpSolid,
  ExclamationSolid,
  TextField,
  Trans,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Paper as MuiPaper } from "@material-ui/core";
import React from "react";
import { CustomSkeleton } from "../../../../_common/CustomSkeleton";
import { ConversionEvent } from "../data/types";

type Props = {
  loading: boolean;
  error?: string;
  options: string[];
  value: ConversionEvent[];
  onChange: (value: ConversionEvent[]) => void;
  disabled?: boolean;
};

export function ConversionEventsFields({
  loading,
  error,
  options,
  value,
  onChange,
  disabled,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const primaryValues = value.filter(
    (x) => x.eventType === GoogleAnalytics4ConversionEventType.Primary,
  );
  const primaryValuesMap: Record<string, boolean> = Object.fromEntries(
    primaryValues.map((x) => [x.eventName, true]),
  );

  const additionalValues = value.filter(
    (x) => x.eventType === GoogleAnalytics4ConversionEventType.Additional,
  );
  const additionalValuesMap: Record<string, boolean> = Object.fromEntries(
    additionalValues.map((x) => [x.eventName, true]),
  );

  function onPrimaryValueChanged(value: string[]): void {
    onChange([
      ...additionalValues,
      ...value
        .filter((x) => !additionalValues.find((y) => y.eventName === x))
        .map((x) => ({
          eventName: x,
          eventType: GoogleAnalytics4ConversionEventType.Primary,
        })),
    ]);
  }

  function onAdditionalValueChanged(value: string[]): void {
    onChange([
      ...primaryValues,
      ...value
        .filter((x) => !primaryValues.find((y) => y.eventName === x))
        .map((x) => ({
          eventName: x,
          eventType: GoogleAnalytics4ConversionEventType.Additional,
        })),
    ]);
  }

  const allOptions = [
    ...value.map((x) => x.eventName),
    ...options.filter((o) => !value.find((x) => x.eventName === o)),
  ].toSorted();

  const inactiveCount = value.filter(
    (x) => !options.find((o) => x.eventName === o),
  ).length;

  return (
    <>
      {error ? (
        <Alert severity="error" className={classes.alert}>
          <Trans
            ns="crawlSettings"
            i18nKey="sources.analytics.googleAnalytics4.conversionEventLoadError"
            values={{ error }}
          />
        </Alert>
      ) : (
        inactiveCount > 0 && (
          <Alert severity="warning" className={classes.alert}>
            {t("sources.analytics.googleAnalytics4.inactiveConversionsAlert", {
              count: inactiveCount,
            })}
          </Alert>
        )
      )}
      <ConversionEventsSelect
        id="primary-conversions"
        loading={loading}
        value={primaryValues.map((x) => x.eventName)}
        onChange={onPrimaryValueChanged}
        options={allOptions}
        activeOptions={options}
        getOptionSelected={(option) =>
          Boolean(primaryValuesMap[option] || additionalValuesMap[option])
        }
        getOptionDisabled={(option) => Boolean(additionalValuesMap[option])}
        label={t("sources.analytics.googleAnalytics4.primaryConversions")}
        detail={t(
          "sources.analytics.googleAnalytics4.primaryConversionsDetail",
        )}
        disabled={disabled}
      />
      <ConversionEventsSelect
        id="additional-conversions"
        loading={loading}
        value={additionalValues.map((x) => x.eventName)}
        onChange={onAdditionalValueChanged}
        options={allOptions}
        activeOptions={options}
        getOptionSelected={(option) =>
          Boolean(primaryValuesMap[option] || additionalValuesMap[option])
        }
        getOptionDisabled={(option) => Boolean(primaryValuesMap[option])}
        label={t("sources.analytics.googleAnalytics4.additionalConversions")}
        detail={t(
          "sources.analytics.googleAnalytics4.additionalConversionsDetail",
        )}
        disabled={disabled}
      />
      <Typography variant="caption" className={classes.desctiption}>
        <Trans
          ns="crawlSettings"
          i18nKey="sources.analytics.googleAnalytics4.conversionsEventsDescription"
          components={{
            readMoreLink: (
              <a
                href="https://help.lumar.io/hc/en-us/articles/28796148613905-Conversion-Metrics"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              />
            ),
          }}
        />
      </Typography>
    </>
  );
}

type Option = string | { value: string; label: string };
const filter = createFilterOptions<Option>();

function getValue(option: Option): string {
  return typeof option === "string" ? option : option.value;
}

function ConversionEventsSelect({
  id,
  loading,
  value,
  onChange,
  options,
  activeOptions,
  getOptionSelected,
  getOptionDisabled,
  label,
  detail,
  disabled,
}: {
  id: string;
  loading: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  options: string[];
  activeOptions: string[];
  getOptionSelected: (option: string) => boolean;
  getOptionDisabled: (option: string) => boolean;
  label: string;
  detail: string;
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [open, setOpen] = React.useState(false);

  const hasInactive = Boolean(
    value.find((x) => !activeOptions.find((o) => o === x)),
  );

  return (
    <>
      <Typography
        variant="subtitle3Medium"
        component="label"
        className={classes.label}
        id={`${id}-label`}
        htmlFor={id}
      >
        {label}
      </Typography>
      <Typography variant="caption" className={classes.detail}>
        {detail}
      </Typography>
      {loading ? (
        <CustomSkeleton animation="wave" height="36px" variant="rect" />
      ) : (
        <Autocomplete<Option, true, false, true>
          id={id}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          onChange={(_, value) => onChange(value.map(getValue))}
          options={options}
          getOptionDisabled={(option) =>
            typeof option === "string" ? getOptionDisabled(option) : false
          }
          disabled={disabled}
          multiple
          freeSolo
          disableCloseOnSelect
          clearOnBlur
          filterOptions={(filterOptions, params) => {
            const filtered = filter(filterOptions, params);

            if (
              params.inputValue !== "" &&
              !options.find((x) => x === params.inputValue)
            ) {
              return [
                ...filtered,
                {
                  label: t(
                    "sources.analytics.googleAnalytics4.addConversionEventOption",
                    { value: params.inputValue },
                  ),
                  value: params.inputValue,
                },
              ];
            }

            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div className={classes.endAdornment}>
                    {params.InputProps.endAdornment}
                    {hasInactive && (
                      <ExclamationSolid className={classes.inactiveIcon} />
                    )}
                    <button
                      onClick={() => setOpen((x) => !x)}
                      className={classes.popupIndicator}
                      disabled={disabled}
                    >
                      {open ? <ChevronUpSolid /> : <ChevronDownSolid />}
                    </button>
                  </div>
                ),
                classes: { root: classes.input },
              }}
            />
          )}
          renderOption={(option) => {
            if (typeof option !== "string") {
              return <div>{option.label}</div>;
            }

            const isSelected = getOptionSelected(option);
            const disabled = getOptionDisabled(option);
            const inactive = !activeOptions.find((x) => x === option);

            return (
              <div className={classes.optionContainer}>
                <Checkbox checked={isSelected} disabled={disabled} />
                <span className={classes.optionText}>{option}</span>
                {inactive && !disabled && (
                  <ExclamationSolid className={classes.inactiveIcon} />
                )}
              </div>
            );
          }}
          renderTags={(value) => (
            <span className={classes.tags}>{`${value.length} selected`}</span>
          )}
          PaperComponent={Paper}
          classes={{ option: classes.option, focused: classes.focused }}
        />
      )}
    </>
  );
}

function Paper(props: React.ComponentProps<typeof MuiPaper>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const noOptions =
    Array.isArray(props.children) && !props.children.find((x) => x);
  if (noOptions) {
    return (
      <MuiPaper className={props.className}>
        <Typography className={classes.noOption}>
          {t("sources.analytics.googleAnalytics4.noConversionEventOption")}
        </Typography>
      </MuiPaper>
    );
  }

  return <MuiPaper {...props} />;
}

const useStyles = makeStyles((theme) => ({
  option: {
    "&[aria-selected='true']": {
      backgroundColor: "transparent",
      color: theme.palette.grey[900],
    },
    "&[aria-selected='true']:hover": {
      backgroundColor: theme.palette.grey[200],
    },
    "&[aria-disabled='true']": {
      opacity: 1,
    },
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  optionText: {
    wordBreak: "break-all",
    flexGrow: 1,
  },
  inactiveIcon: {
    color: theme.palette.yellow[400],
    fontSize: theme.typography.pxToRem(22),
    marginRight: theme.spacing(0.5),
  },
  alert: {
    marginBottom: theme.spacing(0.625),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  focused: {
    "& $tags": {
      display: "none",
    },
  },
  tags: {
    whiteSpace: "nowrap",
  },
  label: {
    display: "block",
    color: theme.palette.grey[700],
    lineHeight: theme.typography.pxToRem(17),
    marginTop: theme.spacing(1),
  },
  detail: {
    display: "block",
    color: theme.palette.grey[700],
    opacity: 0.8,
    lineHeight: theme.typography.pxToRem(18),
    margin: theme.spacing(0.25, 0, 0.625, 0),
  },
  desctiption: {
    display: "block",
    color: theme.palette.grey[700],
    opacity: 0.8,
    lineHeight: theme.typography.pxToRem(18),
    marginTop: theme.spacing(1),
  },
  input: {
    paddingRight: 0,
  },
  endAdornment: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    "& > *": {
      position: "initial",
      height: "auto",
    },
  },
  noOption: {
    display: "block",
    margin: theme.spacing(1.5, 0),
    color: theme.palette.grey[500],
  },
  link: {
    textDecoration: "none",
  },
  popupIndicator: {
    background: "transparent",
    borderWidth: "0px 0px 0px 1px",
    borderColor: theme.palette.grey[300],
    borderRadius: "0px 6px 6px 0px",
    height: "100%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 0.75),
    "&:hover": {
      background: "#F5F5F5",
    },
    "&:active, &:focus": {
      borderColor: theme.palette.grey[300],
      borderStyle: "solid",
    },
    "& svg": {
      fontSize: theme.typography.pxToRem(24),
      color: theme.palette.grey[500],
    },
    "&:disabled": {
      cursor: "initial",
      "& svg": {
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
  },
}));
