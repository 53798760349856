/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getMobileIssuesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("mobileIssues.title"),
    description: () => t("mobileIssues.description"),
    reportTemplateCodesOrder: [
      "non_200_separate_mobile_amp",
      "mobile_alternates_noindex",
      "non_reciprocal_mobile_amp_desktop",
      "amphtml_non_rel_alted",
    ],
    reportStatFilter: (report) => {
      return [
        "non_200_separate_mobile_amp",
        "mobile_alternates_noindex",
        "non_reciprocal_mobile_amp_desktop",
        "amphtml_non_rel_alted",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
