import { CrawlType } from "../../graphql";

export const FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP = new Map([
  ["foundInWebCrawl", CrawlType.Web],
  ["foundInList", CrawlType.List],
  ["foundInSitemap", CrawlType.Sitemap],
  ["foundInGoogleAnalytics", CrawlType.GoogleAnalytics],
  ["foundInBacklinks", CrawlType.Backlinks],
  ["foundInGoogleSearchConsole", CrawlType.GoogleSearchConsole],
  ["foundInLogSummary", CrawlType.LogSummary],
]);
