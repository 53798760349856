import React from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Button } from "@lumar/shared";

import {
  CrawlOption,
  ExpirationOption,
  ShareLinkState,
  useShareLink,
} from "./useShareLink";
import { CreateShareLinkForm } from "./CreateShareLinkForm";

const useStyles = makeStyles((theme) => ({
  close: {
    marginRight: theme.spacing(1.25),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const defaultState: ShareLinkState = {
  isPublic: true,
  expiration: ExpirationOption.Never,
  crawl: CrawlOption.Current,
};

export function CreateShareLinkDialog(props: {
  open: boolean;
  onClose: () => void;
  getAdditionalParams?: () => string[];
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "createShareLink"]);

  const { state, setState, shareLink, loading, error } = useShareLink({
    getAdditionalParams: props.getAdditionalParams,
  });

  React.useEffect(() => {
    if (props.open && state === undefined) {
      setState(defaultState);
    }
  }, [props.open, setState, state]);

  return (
    <Dialog {...props} aria-labelledby="share-link-dialog-title" fullWidth>
      <DialogTitle id="share-link-dialog-title">
        {t("createShareLink:shareReports")}
      </DialogTitle>
      <DialogContent>
        <CreateShareLinkForm
          state={state || defaultState}
          onChange={setState}
          shareLink={shareLink}
          loading={loading}
          error={!!error}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.onClose()}
          variant="outlined"
          className={classes.close}
        >
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
