import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 22,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);",
    marginLeft: 17,
  },
  contentRoot: {
    padding: "12px 16px",
    position: "relative",
    minHeight: 71,
    "&:last-child": {
      paddingBottom: 12,
    },
  },
  header: {
    borderBottom: "solid 1px #E5E7EB",
    color: theme.palette.grey[800],
    fontWeight: 500,
  },
  title: { fontSize: "19px" },
}));

export function ProgressDashboardContainer(props: {
  children?: ReactNode;
  header?: string;
}): JSX.Element {
  const classes = useStyles();
  return (
    <Card className={classes.container} elevation={0}>
      {props.header ? (
        <CardHeader
          title={props.header}
          classes={{ title: classes.title }}
          className={classes.header}
        ></CardHeader>
      ) : null}
      <CardContent classes={{ root: classes.contentRoot }}>
        {props.children}
      </CardContent>
    </Card>
  );
}
