import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityColorAAAChart } from "../../../../../_common/charts/config/getAccessibilityColorAAAChart";
import { getAccessibilityColorAAATrend } from "../../../../../_common/charts/config/getAccessibilityColorAAATrend";

export const generateAccessibilityColorAAACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityColorAAAChart,
      getAccessibilityColorAAATrend,
    ],
  };
