import {
  ReportsTableConfigItem,
  GenerateReportsTableConfigProps,
} from "../../../crawl-overview/dashboard/config/types";

export function getSiteSpeedAllReportsTable({}: GenerateReportsTableConfigProps): ReportsTableConfigItem {
  return {
    columns: [
      { predefinedColumn: "categoryIcon" },
      { predefinedColumn: "categoryName" },
      { predefinedColumn: "impact" },
      { predefinedColumn: "name" },
      { predefinedColumn: "total" },
      { predefinedColumn: "weightedTotal" },
      { predefinedColumn: "trend" },
      { predefinedColumn: "change" },
      { predefinedColumn: "weightedChange" },
      { predefinedColumn: "added" },
      { predefinedColumn: "moved" },
      { predefinedColumn: "missing" },
    ],
    orderBy: {
      field: "total",
      direction: "desc",
    },
  };
}
