import {
  useTranslation,
  NestedNavigationMenuChildItemAdornmentProps,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { ResourceDetailData } from "../../data/types";
import { SitemapDetailsAllMetrics } from "./sections/SitemapDetailsAllMetrics";
import { SitemapDetailsChangedMetrics } from "./sections/SitemapDetailsChangedMetrics";
import { UrlsInSitemap } from "./sections/UrlsInSitemap";

type Section = {
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  adornment?: React.ComponentType<NestedNavigationMenuChildItemAdornmentProps>;
};

export function useSitemapDetailsSections(): {
  sections: (Section & {
    items: Section[];
  })[];
  active: Section;
} {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const sections = [
    {
      code: "allMetrics",
      name: t("allMetrics"),
      component: SitemapDetailsAllMetrics,
      items: [
        {
          code: "changedMetrics",
          name: t("changedMetrics"),
          component: SitemapDetailsChangedMetrics,
        },
        {
          code: "urlsInSitemap",
          name: t("urlsInSitemap"),
          component: UrlsInSitemap,
        },
      ],
    },
  ];

  const flatSection = sections.flatMap((section) => [
    section,
    ...section.items.map((item) => item),
  ]);
  const foundSection = type
    ? flatSection.find((x) => x.code === type)
    : undefined;

  return {
    sections,
    active: foundSection ?? flatSection[0],
  };
}
