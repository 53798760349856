import { Link, Typography, TypographyTypeMap } from "@material-ui/core";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAccountsRoutes } from "../../../_common/routing/accountsRoutes";

export type UpgradeMessageProps = DefaultComponentProps<TypographyTypeMap> & {
  upgradeType: "Corporate" | "LightPlusOrCorporate";
};

export function UpgradeMessage({
  upgradeType,
  ...props
}: UpgradeMessageProps): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const accountsRoutes = useAccountsRoutes();

  const subscriptionsLink = (
    <Link
      href={accountsRoutes.Subscription.getUrl({
        accountId,
      })}
    />
  );

  return (
    <Typography variant="caption" {...props}>
      {upgradeType === "Corporate" && (
        <Trans
          ns="crawlSettings"
          i18nKey="upgradeMessageCorporate"
          components={{
            upgradeLink: subscriptionsLink,
          }}
        />
      )}
      {upgradeType === "LightPlusOrCorporate" && (
        <Trans
          ns="crawlSettings"
          i18nKey="upgradeMessageLightPlusCorporate"
          components={{
            upgradeLink: subscriptionsLink,
          }}
        />
      )}
    </Typography>
  );
}
