import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getGscTrafficLevel(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("requiredSourcesMessage.gscSource"),
    },
    title: t("gscTrafficLevel.title"),
    description: t("gscTrafficLevel.description"),
    reportTemplateCodes: [
      "primary_pages_with_1_to_10_clicks",
      "primary_pages_with_10_to_100_clicks",
      "primary_pages_with_100_to_1000_clicks",
      "primary_pages_with_1000_to_10000_clicks",
      "primary_pages_with_10000_plus_clicks",
    ],
  })();
}
