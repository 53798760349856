import React from "react";
import {
  Alert,
  Button,
  ChevronDownSolid,
  ChevronUpSolid,
  Slider,
  TagInput,
  TextField,
  ToggleIconButton,
  Typography,
  formArrayAdd,
  formArrayMove,
  formArrayRemove,
  formToTextFieldProps,
  useTranslation,
} from "@lumar/shared";
import { Grid, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { FastField, FieldProps } from "formik";
import { uniq } from "lodash";
import { AddCircle, Delete } from "@material-ui/icons";

import { FormValues, PageGroupingSettings } from "../data/types";
import { SettingControlFactory } from "../useAdvancedControls";

export const getUrlScopeControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("urlScopeTitle"),
  path: "urlScope",
  // eslint-disable-next-line react/display-name
  control: () => <FastField name="scope.urlScope" component={UrlScope} />,
  testId: "crawl-settings-advanced-url-scope",
  activeValues: ["includedUrls", "excludedUrls", "groups"],
  textForSearch: createTextForSearch([
    t("urlScopeTitle"),
    t("includedUrlsLabel"),
    t("includedUrlsDescription"),
    t("includedUrlsExample"),
    t("includedUrlsPlaceholder"),
    t("excludedUrlsLabel"),
    t("excludedUrlsDescription"),
    t("excludedUrlsExample"),
    t("excludedUrlsNotes"),
    t("excludedUrlsPlaceholder"),
    t("regexUrlsNotes"),
    t("pageGroupingLabel"),
    t("pageGroupingDescription"),
    t("pageGroupingNotes"),
    t("pageGroupingRegexNote"),
    t("pageGroupingExample"),
    t("pageGroupingNew"),
    t("pageGroupingRemove"),
    t("pageGroupingMoveUp"),
    t("pageGroupingMoveDown"),
    t("pageGroupingNamePlaceholder"),
    t("pageGroupingUrlPlaceholder"),
  ]),
  fieldNames: [
    ["groups", t("pageGroupingField")],
    ["name", t("pageGroupingNameField")],
    ["urlMatch", t("pageGroupingUrlField")],
  ],
});

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(1),
  },
  example: {
    fontSize: theme.typography.pxToRem(12),
  },
  groupsExample: {
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "pre-line",
    marginTop: theme.spacing(2),
  },
  tagInput: {
    marginTop: theme.spacing(2),
  },
  button: {
    width: "fit-content",
    marginTop: theme.spacing(2),
  },
  grid: {
    alignItems: "flex-start",
    marginTop: theme.spacing(2),
  },
  input: {
    width: "100%",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "column-reverse",
    margin: theme.spacing(-0.5, 1, 0, 1),
  },
  slider: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  moveButton: {
    marginLeft: theme.spacing(1.5),
    "& svg": {
      fontSize: theme.typography.pxToRem(24),
    },
  },
}));

export function UrlScope({
  field,
  form,
}: FieldProps<FormValues["scope"]["urlScope"]>): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const { t: tUnits } = useTranslation("units");
  const classes = useStyles();

  const createNewGroup = (): PageGroupingSettings => ({
    name: "",
    urlMatch: "",
    crawlSample: 1.0,
  });

  return (
    <>
      <Typography variant="subtitle2Medium">
        {t("includedUrlsLabel")}
      </Typography>
      <Typography variant="caption">{t("includedUrlsDescription")}</Typography>
      <code className={classes.example}>{t("includedUrlsExample")}</code>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("regexUrlsNotes")}
      </Alert>
      <TagInput
        value={field.value.includedUrls}
        onChange={(value) =>
          form.setFieldValue("scope.urlScope.includedUrls", uniq(value))
        }
        disabled={form.isSubmitting}
        showCopyAll
        className={classes.tagInput}
        placeholder={t("includedUrlsPlaceholder")}
        data-testid="include-urls-tags"
      />

      <Typography variant="subtitle2Medium" className={classes.subtitle}>
        {t("excludedUrlsLabel")}
      </Typography>
      <Typography variant="caption">{t("excludedUrlsDescription")}</Typography>
      <code className={classes.example}>{t("excludedUrlsExample")}</code>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("excludedUrlsNotes")}
      </Alert>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("regexUrlsNotes")}
      </Alert>
      <TagInput
        value={field.value.excludedUrls}
        onChange={(value) =>
          form.setFieldValue("scope.urlScope.excludedUrls", uniq(value))
        }
        disabled={form.isSubmitting}
        showCopyAll
        className={classes.tagInput}
        placeholder={t("excludedUrlsPlaceholder")}
        data-testid="exclude-urls-tags"
      />

      <Typography variant="subtitle2Medium" className={classes.subtitle}>
        {t("pageGroupingLabel")}
      </Typography>
      <Typography variant="caption">{t("pageGroupingDescription")}</Typography>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("pageGroupingRegexNote")}
      </Alert>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("pageGroupingNotes")}
      </Alert>
      <code className={classes.groupsExample}>{t("pageGroupingExample")}</code>
      <Button
        onClick={() =>
          formArrayAdd(form, "scope.urlScope.groups", createNewGroup())
        }
        disabled={form.isSubmitting}
        color="primary"
        variant="contained"
        size="small"
        startIcon={<AddCircle />}
        className={classes.button}
        data-testid="page-grouping-add"
      >
        {t("pageGroupingNew")}
      </Button>
      {field.value.groups.map((group, idx) => (
        <Grid
          key={idx}
          container
          spacing={2}
          component={Paper}
          alignItems="center"
          className={classes.grid}
          data-testid="page-grouping-row"
        >
          <Grid item xs={12} md={3}>
            <TextField
              {...formToTextFieldProps(
                form,
                `scope.urlScope.groups[${idx}].name`,
              )}
              placeholder={t("pageGroupingNamePlaceholder")}
              className={classes.input}
              data-testid="page-grouping-name"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              {...formToTextFieldProps(
                form,
                `scope.urlScope.groups[${idx}].urlMatch`,
              )}
              placeholder={t("pageGroupingUrlPlaceholder")}
              className={classes.input}
              data-testid="page-grouping-pattern"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.sliderContainer}>
              <Slider
                value={group.crawlSample}
                onChange={(_, value) =>
                  form.setFieldValue(
                    `scope.urlScope.groups[${idx}].crawlSample`,
                    Array.isArray(value) ? value[0] : value,
                    false,
                  )
                }
                leftLabel={(values) =>
                  tUnits("percent", {
                    count: values[0],
                  })
                }
                disabled={form.isSubmitting}
                min={0.01}
                max={1.0}
                step={0.01}
                classes={{ root: classes.slider }}
                data-testid="page-grouping-sample"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <IconButtonWithTooltip
              label={t("pageGroupingRemove")}
              variant="outlined"
              colorVariant="red"
              onClick={() =>
                formArrayRemove(form, "scope.urlScope.groups", idx)
              }
              disabled={form.isSubmitting}
              data-testid="page-grouping-remove"
            >
              <Delete fontSize="small" />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              label={t("pageGroupingMoveUp")}
              variant="outlined"
              onClick={() =>
                formArrayMove(form, "scope.urlScope.groups", idx, idx - 1)
              }
              disabled={form.isSubmitting || idx === 0}
              className={classes.moveButton}
              data-testid="page-grouping-move-up"
            >
              <ChevronUpSolid />
            </IconButtonWithTooltip>
            <IconButtonWithTooltip
              label={t("pageGroupingMoveDown")}
              variant="outlined"
              onClick={() =>
                formArrayMove(form, "scope.urlScope.groups", idx, idx + 1)
              }
              disabled={
                form.isSubmitting || idx === field.value.groups.length - 1
              }
              className={classes.moveButton}
              data-testid="page-grouping-move-down"
            >
              <ChevronDownSolid />
            </IconButtonWithTooltip>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

function IconButtonWithTooltip({
  label,
  ...props
}: Parameters<typeof ToggleIconButton>[0] & { label: string }): JSX.Element {
  return (
    <Tooltip title={props.disabled ? "" : label} placement="top">
      <ToggleIconButton {...props} aria-label={label} />
    </Tooltip>
  );
}
