import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getUrlFetchTimeChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("urlFetchTime.title"),
    reportTemplateCodes: [
      "fast_load_time",
      "medium_load_time",
      "max_load_time",
    ],
    description: t("urlFetchTime.description"),
  })();
}
