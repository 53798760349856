import { ReportCategoryChartConfig } from "../types";
import { getCanonicalBreakdownChart } from "../../../../_common/charts/config/getCanonicalBreakdownChart";
import { getCanonicalBreakdownTrend } from "../../../../_common/charts/config/getCanonicalBreakdownTrend";
import { getCanonicalIssuesChart } from "../../../../_common/charts/config/getCanonicalIssuesChart";
import { getCanonicalIssuesTrend } from "../../../../_common/charts/config/getCanonicalIssuesTrend";

export const generateIndexabilityCanonicalizationCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getCanonicalBreakdownChart,
      getCanonicalBreakdownTrend,
      getCanonicalIssuesChart,
      getCanonicalIssuesTrend,
    ],
  };
