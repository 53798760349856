import { Crawl, CrawlStatus } from "../../graphql";

export const isCrawlUnarchiving = (
  crawlStatus?: Crawl["statusEnum"] | null,
  crawlFinishedAt?: Crawl["finishedAt"] | null,
): boolean => {
  return crawlFinishedAt !== null && crawlStatus === CrawlStatus.Finalizing;
};

export const isCrawlInProgress = (
  crawlStatus?: Crawl["statusEnum"] | null,
): boolean => {
  return (
    crawlStatus === CrawlStatus.Crawling ||
    crawlStatus === CrawlStatus.Finalizing ||
    crawlStatus === CrawlStatus.Discovering ||
    crawlStatus === CrawlStatus.Paused ||
    crawlStatus === CrawlStatus.Queued
  );
};

export const isCrawlRunning = (
  crawlStatus?: Crawl["statusEnum"] | null,
): boolean => {
  return (
    crawlStatus === CrawlStatus.Crawling ||
    crawlStatus === CrawlStatus.Finalizing ||
    crawlStatus === CrawlStatus.Discovering ||
    crawlStatus === CrawlStatus.Queued
  );
};

export const isCrawlPaused = (
  crawlStatus?: Crawl["statusEnum"] | null,
): boolean => {
  return crawlStatus === CrawlStatus.Paused;
};
