import React from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  MenuItem,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { FastField, FieldArray, FieldProps } from "formik";
import clsx from "clsx";
import { AddCircle, Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { fieldToSelect, Select, ToggleIconButton } from "@lumar/shared";

import { FormValues, UrlRewriteRule } from "../data/types";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { SettingControlFactory } from "../useAdvancedControls";

export const getUrlRewritingControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.urlRewriting.title"),
  path: "urlRewriting",
  // eslint-disable-next-line react/display-name
  control: (formik) => (
    <UrlRewriting
      rewritingRules={formik.values.test.urlRewriting.rules}
      setFormValues={formik.setFieldValue}
    />
  ),
  testId: "crawl-settings-advanced-url-rewriting",
  activeValues: ["rules"],
  textForSearch: createTextForSearch([
    t("settings.urlRewriting.title"),
    t("settings.urlRewriting.instruction"),
    t("settings.urlRewriting.example"),
    t("settings.urlRewriting.matchFromPlaceholder"),
    t("settings.urlRewriting.matchToPlaceholder"),
    t("settings.urlRewriting.useRewriteRules"),
    t("settings.urlRewriting.stripFragments"),
  ]),
  fieldNames: [
    ["rules", t("settings.urlRewriting.rulesField")],
    ["matchFrom", t("settings.urlRewriting.matchFromPlaceholder")],
    ["matchTo", t("settings.urlRewriting.matchToPlaceholder")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  smallIndent: {
    marginTop: theme.spacing(1),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  button: {
    width: "fit-content",
  },
  grid: {
    marginTop: theme.spacing(0),
    alignItems: "flex-start",
  },
  removeButton: {
    height: 49,
  },
}));

export const UrlRewriting = React.memo(UrlRewritingInner);

function UrlRewritingInner({
  rewritingRules,
  setFormValues,
}: {
  rewritingRules: UrlRewriteRule[];
  setFormValues: (field: string, value: boolean) => void;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const caseOptions = [
    {
      value: "None",
      label: t("settings.urlRewriting.caseOptionNoChange"),
    },
    {
      value: "RewriteToUppercase",
      label: t("settings.urlRewriting.caseOptionUpper"),
    },
    {
      value: "RewriteToLowercase",
      label: t("settings.urlRewriting.caseOptionLower"),
    },
  ];

  const previousRules = React.useRef(rewritingRules);
  React.useEffect(() => {
    if (rewritingRules !== previousRules.current) {
      if (rewritingRules.find((rule) => rule.matchFrom && rule.matchTo)) {
        setFormValues("test.urlRewriting.useRewriteRules", true);
      }

      previousRules.current = rewritingRules;
    }
  }, [rewritingRules, setFormValues]);

  const createNewRule = (): UrlRewriteRule => ({
    matchFrom: "",
    matchTo: "",
    caseOption: "None",
  });

  return (
    <>
      <Typography variant="caption">
        {t("settings.urlRewriting.instruction")}
      </Typography>
      <code className={clsx(classes.example, classes.indent)}>
        {t("settings.urlRewriting.example")}
      </code>
      <FieldArray
        name="test.urlRewriting.rules"
        render={({ form, push, remove }) => {
          const values = (form.values as FormValues).test.urlRewriting.rules;
          return (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => push(createNewRule())}
                disabled={form.isSubmitting}
                startIcon={<AddCircle />}
                className={clsx(classes.button, classes.indent)}
                data-testid="url-rewriting-add"
              >
                {t("settings.urlRewriting.addLabel")}
              </Button>
              {values.map((_: unknown, idx: number) => (
                <Paper key={idx} style={{ padding: "8px", marginTop: "8px" }}>
                  <Grid container spacing={1} className={classes.grid}>
                    <Grid item>
                      <FastField
                        name={`test.urlRewriting.rules[${idx}].matchFrom`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t(
                          "settings.urlRewriting.matchFromPlaceholder",
                        )}
                        data-testid="url-rewriting-match-from"
                      />
                    </Grid>
                    <Grid item>
                      <FastField
                        name={`test.urlRewriting.rules[${idx}].matchTo`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t(
                          "settings.urlRewriting.matchToPlaceholder",
                        )}
                        data-testid="url-rewriting-match-to"
                      />
                    </Grid>
                    <Grid item>
                      <FastField
                        name={`test.urlRewriting.rules[${idx}].caseOption`}
                      >
                        {(props: FieldProps<string>) => (
                          <Select
                            {...fieldToSelect(props)}
                            data-testid="url-rewriting-case"
                          >
                            <MenuItem value="" disabled>
                              {t("settings.urlRewriting.caseOptionDefault")}
                            </MenuItem>
                            {caseOptions.map((option, idx) => (
                              <MenuItem key={idx} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </FastField>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={t("settings.urlRewriting.removeTooltip")}
                        placement="top"
                      >
                        <ToggleIconButton
                          size="large"
                          variant="outlined"
                          colorVariant="red"
                          onClick={() => remove(idx)}
                          disabled={form.isSubmitting}
                          data-testid="url-rewriting-remove"
                        >
                          <Delete />
                        </ToggleIconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          );
        }}
      />

      <FastField
        name="test.urlRewriting.useRewriteRules"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("settings.urlRewriting.useRewriteRules") }}
        className={classes.indent}
        data-testid="use-url-rewriting"
      />
      <FastField
        name="test.urlRewriting.stripFragments"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("settings.urlRewriting.stripFragments") }}
        data-testid="strip-fragments"
      />
    </>
  );
}
