import { useTranslation } from "@lumar/shared";

export function useTimeDurationFormatter(): (
  startTime?: Date,
  endTime?: Date,
  format?: "long" | "short",
) => string {
  const { t } = useTranslation("common");
  return (
    startTime?: Date,
    endTime?: Date,
    format?: "long" | "short",
  ): string => {
    if (!startTime || !endTime) return "";
    const time = endTime.getTime() - startTime.getTime();

    const days = Math.floor(time / 86400000);
    const hours = Math.floor((time % 86400000) / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const formatDay = (): string => t("time.days", { count: days });

    const formatHrs = (): string =>
      format === "short"
        ? t("time.hrs", { count: hours })
        : t("time.hours", { count: hours });

    const formatMin = (): string =>
      format === "short"
        ? t("time.mins", { count: minutes })
        : t("time.minutes", { count: minutes });

    const formatSec = (): string =>
      format === "short"
        ? t("time.sec", { count: seconds })
        : t("time.seconds", { count: seconds });

    if (days > 0)
      return hours > 0 ? `${formatDay()} ${formatHrs()}` : formatDay();

    if (hours > 0)
      return minutes > 0 ? `${formatHrs()} ${formatMin()}` : formatHrs();

    if (minutes > 0)
      return seconds > 0 ? `${formatMin()} ${formatSec()}` : formatMin();

    if (seconds > 0) return formatSec();

    return "";
  };
}
