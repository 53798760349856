/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from "@lumar/shared";
import React from "react";
import { Maybe, MetricMetadata } from "../../../../graphql";
import {
  CrawlContextCrawl,
  CrawlContextCrawlProject,
  CrawlContextCrawlReportStat,
} from "../../../../crawl-overview/CrawlContext";
import {
  ChartConfigGenericReportStat,
  ChartConfigReportStat,
} from "../../types/ChartConfigItemBase";

export interface ChartDataUsingCrawlContextInputSource {
  inputSource: "CrawlContext";
  reportStats: CrawlContextCrawlReportStat[];
  getReportUnit: (report?: CrawlContextCrawlReportStat) => string | undefined;
  getAggregatedMetric: (
    report?: CrawlContextCrawlReportStat,
  ) => ChartDataMetric | undefined;
}

export interface ChartDataUsingGqlQueryInputSource {
  inputSource: "GqlQuery";
  reportStats: ChartConfigGenericReportStat[];
  getReportUnit: (report?: ChartConfigGenericReportStat) => string | undefined;
  getAggregatedMetric: (
    report?: ChartConfigGenericReportStat,
  ) => ChartDataMetric | undefined;
}

export interface ChartDataContextBase {
  loading: boolean;
  segmentName?: string;
  error?: ApolloError;
  isChartUnavailable: boolean;
  crawl?: CrawlContextCrawl;
  project?: CrawlContextCrawlProject;
  totalUrls?: number;
  getReportUnit: (report?: ChartConfigReportStat) => string | undefined;
  getAggregatedMetric: (
    report?: ChartConfigReportStat,
  ) => ChartDataMetric | undefined;
}

export type ChartDataContext = ChartDataContextBase &
  (ChartDataUsingCrawlContextInputSource | ChartDataUsingGqlQueryInputSource);

export type ChartDataMetric = {
  code: string;
  name: string;
  aggregate: string;
  metadata?: Maybe<Pick<MetricMetadata, "unit" | "siteSpeedScoring">>;
};

export const ChartDataContext = React.createContext<ChartDataContext | null>(
  null,
);

export function useChartDataContext(): ChartDataContext {
  const context = React.useContext(ChartDataContext);

  if (!context) {
    throw new Error(
      "useChartDataContext must be used within a ChartDataContext provider",
    );
  }

  return context;
}
