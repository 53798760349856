import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ComboBox, InfoOutlined, useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { InputBaseComponentProps, Tooltip } from "@material-ui/core";

import { NarrowMetric } from "../types";

interface FilterMetricSelectProps {
  metrics: NarrowMetric[];
  value: NarrowMetric | undefined;
  onChange: (value: NarrowMetric) => void;
  disabled?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  option: {
    display: "flex",
    width: "100%",
  },
  menuItem: {
    "&:hover svg": {
      opacity: 1,
    },
  },
  infoIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    alignSelf: "center",
    opacity: 0,
  },
  unit: {
    alignSelf: "center",
    color: theme.palette.grey[400],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    minWidth: 22,
    textAlign: "end",
    flexShrink: 0,
  },
}));

export function FilterMetricSelect({
  metrics,
  value,
  onChange,
  disabled,
  className,
}: FilterMetricSelectProps): JSX.Element {
  const classes = useStyles();

  return (
    <ComboBox
      value={value}
      onChange={(_, value) => onChange(value)}
      options={metrics}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, selected) => option.code === selected?.code}
      renderOption={(option) => (
        <div
          className={classes.option}
          data-testid={getFilterMetricOptionTestId(option.code)}
        >
          {option.name}
          {option.description && (
            <Tooltip title={option.description} arrow placement="right">
              <InfoOutlined
                className={classes.infoIcon}
                fontSize="small"
                data-testid="metric-description-icon"
              />
            </Tooltip>
          )}
        </div>
      )}
      disableClearable
      disabled={disabled}
      className={className}
      classes={{ option: classes.menuItem }}
      inputProps={{
        inputProps: {
          "data-testid": "filter-rule-metric-input",
          metric: value,
        },
        InputProps: {
          inputComponent: MetricInput,
        },
      }}
    />
  );
}

function MetricInput({
  inputRef,
  metric,
  ...props
}: InputBaseComponentProps & {
  metric?: NarrowMetric;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("units");

  const unit = getUnit(metric?.metadata?.unit, t);

  return (
    <>
      <input {...props} ref={inputRef} />
      {Boolean(unit) && <span className={classes.unit}>{unit}</span>}
    </>
  );
}

export function getFilterMetricOptionTestId(metricCode: string): string {
  return `filter-metric-option-${metricCode}`;
}

function getUnit(
  unit: string | undefined | null,
  t: TFunction<"units">,
): string | undefined {
  switch (unit) {
    case "word":
      return t("word_plural");
    case "second":
      return t("second_plural");
    case "pixel":
      return t("pixelUnit");
    case "character":
      return t("characterUnit_plural");
    case "percent":
      return t("percentUnit");
    case "byte":
      return t("byte_plural");
  }
}
