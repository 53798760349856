import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { FastField, FastFieldProps } from "formik";
import { TextField as FormikTextField } from "formik-material-ui";
import { Typography, updateIfPropsChanged } from "@lumar/shared";
import { FixedSizeList } from "react-window";

import { DeepcrawlTooltip } from "../../../../_common/DeepcrawlTooltip";
import { withOnBlurUpdate } from "../../components/CustomFields";
import { withDisplayValue } from "../../components/TextFieldInput";
import { AddAdobeAccount } from "./AddAdobeAccount";
import { TextFieldWithOptions } from "../../components/TextFieldWithOptions";
import {
  AdobeAnalyticsReportSuites,
  AnalyticsValuesResult,
} from "./useAnalyticsValues";
import { FormValues } from "../data/types";
import { SearchField } from "../../components/SearchField";
import { StyledInputLabel } from "../../../../_common/forms/StyledInputLabel";

const CustomTextFieldWithOptions = withOnBlurUpdate(TextFieldWithOptions);
const CustomTextField = withOnBlurUpdate(FormikTextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent: {
      marginTop: theme.spacing(2),
    },
    root: {
      marginTop: theme.spacing(3),
    },
    alignRightGrid: {
      display: "flex",
      justifyContent: "flex-end",
    },
    description: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    details: {
      backgroundColor: "#F0F3F7",
      minHeight: "300px",
      border: "1px solid #EBEFF3",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    list: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      paddingRight: theme.spacing(2.625),
    },
    fullWidth: {
      width: "100%",
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    small: {
      fontSize: "0.714rem",
    },
    divider: {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.grey[200],
    },
  }),
);

export function AdobeAnalytics({
  reportSuites: providedReportSuites,
  addConnection,
}: {
  reportSuites: AdobeAnalyticsReportSuites[];
  addConnection: AnalyticsValuesResult["addAdobeConnection"];
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [searchString, setSearchString] = useState("");
  const formattedSearchString = searchString.toLowerCase();

  const lastNDaysValues = ["7", "14", "30", "60", "90", "180", "365"];
  const integerPattern = /^(\+?\d+)?$/;

  const reportSuites: AdobeAnalyticsReportSuites[] = [
    {
      id: "",
      reportSuiteId: t("sources.analytics.adobeAnalytics.noneSelected"),
      connectionId: "",
      suiteName: t("sources.analytics.adobeAnalytics.none"),
      companyName: "",
    },
    ...providedReportSuites,
  ];
  const filteredReportSuites = reportSuites.filter(
    (t) =>
      t.reportSuiteId.toLowerCase().includes(formattedSearchString) ||
      t.suiteName.toLowerCase().includes(formattedSearchString),
  );

  const getDisplayName = (
    element: AdobeAnalyticsReportSuites | undefined,
  ): string => {
    return element?.companyName?.length === 0
      ? (element?.suiteName ?? "")
      : `${element?.suiteName ?? ""} (${element?.companyName ?? ""})`;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={4}>
        <SearchField
          value={searchString}
          onChange={(value) => setSearchString(value)}
          placeholder={t("sources.analytics.adobeAnalytics.findReports")}
          className={classes.fullWidth}
          data-testid="crawl-settings-adobe-analytics-filter"
        />
      </Grid>
      <Grid item xs={12} md={8} className={classes.alignRightGrid}>
        <AddAdobeAccount addConnection={addConnection} />
      </Grid>
      <Grid item xs={12} className={classes.description}>
        <Typography variant="caption">
          {t("sources.analytics.adobeAnalytics.description")}
        </Typography>
      </Grid>
      <FastField
        name="analytics.adobe.reportSuiteId"
        searchString={searchString}
        reportSuites={reportSuites}
        shouldUpdate={updateIfPropsChanged(["searchString", "reportSuites"])}
      >
        {({
          field: { value },
          form: { setValues, isSubmitting },
        }: FastFieldProps<string>) => {
          const selectedItem = reportSuites.find(
            (t) => `${t.reportSuiteId}:${t.connectionId}` === value,
          );
          const hasSelection = Boolean(selectedItem?.reportSuiteId);
          const setFieldsValue = setValues as unknown as (
            values: React.SetStateAction<FormValues>,
            shouldValidate?: boolean,
          ) => void;

          return (
            <>
              <Grid item xs={12} md={8} className={classes.list}>
                <RadioGroup
                  value={value}
                  onChange={(_, value) => {
                    const reportSuiteName =
                      reportSuites.find((r) => r.id === value)?.suiteName ?? "";
                    if (value) {
                      setFieldsValue(
                        (values) => ({
                          ...values,
                          analytics: {
                            ...values.analytics,
                            adobe: {
                              ...values.analytics.adobe,
                              reportSuiteId: value,
                              reportSuiteName: reportSuiteName,
                            },
                          },
                        }),
                        true,
                      );
                    } else {
                      setFieldsValue(
                        (values) => ({
                          ...values,
                          analytics: {
                            ...values.analytics,
                            adobe: {
                              reportSuiteId: value,
                              reportSuiteName: reportSuiteName,
                              numberOfDays: "",
                              minimumVisits: "",
                              maximumUrls: "",
                            },
                          },
                        }),
                        true,
                      );
                    }
                  }}
                >
                  <FixedSizeList
                    width="100%"
                    height={300}
                    itemSize={46}
                    itemCount={filteredReportSuites.length}
                    itemData={{ reportSuites: filteredReportSuites }}
                  >
                    {({ index, data, style }) => {
                      const reportSuite = data.reportSuites[index];
                      return (
                        <FormControlLabel
                          key={reportSuite.reportSuiteId}
                          style={style}
                          value={reportSuite.id}
                          control={<Radio />}
                          disabled={isSubmitting}
                          label={
                            <div style={{ padding: "4px 0" }}>
                              <Typography
                                variant="captionSemiBold"
                                data-testid="crawl-settings-ga-display-name"
                              >
                                {getDisplayName(reportSuite)}
                              </Typography>
                              <Typography
                                className={classes.small}
                                variant="caption"
                                data-testid="crawl-settings-ga-websiteUrl"
                                component="div"
                              >
                                {(reportSuite.reportSuiteId
                                  ? reportSuite.reportSuiteId
                                  : t(
                                      "sources.analytics.adobeAnalytics.noneSelected",
                                    )
                                ).toUpperCase()}
                              </Typography>
                            </div>
                          }
                          data-testid="crawl-settings-aa-report-suites"
                          className={classes.divider}
                        />
                      );
                    }}
                  </FixedSizeList>
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={4} className={classes.details}>
                <Typography className={classes.bold} component="div">
                  {t("sources.analytics.adobeAnalytics.selectedReportSuite")}
                </Typography>
                {hasSelection ? (
                  <>
                    <Typography component="div">
                      {t("sources.analytics.adobeAnalytics.reportSuiteName", {
                        name: selectedItem?.suiteName,
                        company: selectedItem?.companyName,
                      })}
                    </Typography>
                    <Typography component="div" className={classes.small}>
                      {selectedItem?.reportSuiteId.toUpperCase()}
                    </Typography>
                  </>
                ) : (
                  <Typography component="div">
                    {t("sources.analytics.adobeAnalytics.noneSelected")}
                  </Typography>
                )}
                <div className={classes.indent}>
                  <StyledInputLabel>
                    {t("sources.analytics.adobeAnalytics.rangeAndVisits")}
                  </StyledInputLabel>
                </div>
                <FastField
                  name="analytics.adobe.numberOfDays"
                  component={CustomTextFieldWithOptions}
                  pattern={integerPattern}
                  options={lastNDaysValues}
                  disabled={!hasSelection}
                  shouldUpdate={updateIfPropsChanged("disabled")}
                  renderOption={(range: string) =>
                    t("sources.analytics.adobeAnalytics.dateRangeText", {
                      value: range,
                    })
                  }
                  variant="outlined"
                  placeholder={t(
                    "sources.analytics.adobeAnalytics.dateRangePlaceholder",
                  )}
                  InputProps={{
                    inputComponent: withDisplayValue((value) =>
                      t("sources.analytics.adobeAnalytics.dateRangeText", {
                        value,
                      }),
                    ),
                  }}
                />
                <DeepcrawlTooltip
                  width={300}
                  content={t(
                    "sources.analytics.adobeAnalytics.minimumVisitsInfo",
                  )}
                >
                  <Box paddingTop={1}>
                    <FastField
                      name="analytics.adobe.minimumVisits"
                      component={CustomTextField}
                      pattern={integerPattern}
                      disabled={!hasSelection || isSubmitting}
                      shouldUpdate={updateIfPropsChanged("disabled")}
                      hiddenLabel
                      placeholder={t(
                        "sources.analytics.adobeAnalytics.minimumVisitsPlaceholder",
                      )}
                      variant="outlined"
                      className={classes.fullWidth}
                      InputProps={{
                        inputComponent: withDisplayValue((value) =>
                          t("sources.analytics.adobeAnalytics.minimumVisits", {
                            value,
                          }),
                        ),
                      }}
                    />
                  </Box>
                </DeepcrawlTooltip>
                <DeepcrawlTooltip
                  width={300}
                  content={t(
                    "sources.analytics.adobeAnalytics.maximumUrlsInfo",
                  )}
                >
                  <Box paddingTop={1}>
                    <FastField
                      name="analytics.adobe.maximumUrls"
                      component={CustomTextField}
                      pattern={integerPattern}
                      disabled={!hasSelection || isSubmitting}
                      shouldUpdate={updateIfPropsChanged("disabled")}
                      hiddenLabel
                      placeholder={t(
                        "sources.analytics.adobeAnalytics.maximumUrlsPlaceholder",
                      )}
                      variant="outlined"
                      className={classes.fullWidth}
                      InputProps={{
                        inputComponent: withDisplayValue((value) =>
                          t("sources.analytics.adobeAnalytics.maximumUrls", {
                            value,
                          }),
                        ),
                      }}
                    />
                  </Box>
                </DeepcrawlTooltip>
              </Grid>
            </>
          );
        }}
      </FastField>
    </Grid>
  );
}
