import React from "react";
import { useTranslation } from "@lumar/shared";
import { ProjectViewer } from "./project-viewer/ProjectViewer";
import { SearchSideBar } from "./search-side-bar/SearchSideBar";
import { SearchBar } from "./search-bar/SearchBar";
import { CrawlSidebarMenu } from "./crawl-report-categories-menu/CrawlSidebarMenu";

export function CrawlSidebarContent(): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState("");

  const { t } = useTranslation("sidebar");

  return (
    <div>
      <ProjectViewer />
      <SearchBar
        onSearch={setSearchTerm}
        searchTerm={searchTerm}
        placeholder={t("searchReports")}
      />
      <div>
        {!searchTerm ? (
          <CrawlSidebarMenu />
        ) : (
          <SearchSideBar searchTerm={searchTerm} />
        )}
      </div>
    </div>
  );
}
