var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSnackbar } from "notistack";
import { getNormalizedGraphQLErrorMessage } from "./getNormalizedGraphQLErrorMessage";
import { Snackbar } from "../components/snackbars/Snackbar";
import { ApolloError } from "@apollo/client";
/**
 * Generic way of handling mutation in the UI that shows a success or error snackbar depending
 * on the response. Use it when you want to show snackbars as a result of the mutation.
 */
export function useMutationSnackbar() {
    const { enqueueSnackbar } = useSnackbar();
    return function mutationSnackbar(mutation, config) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                const response = yield mutation;
                enqueueSnackbar(_jsx(Snackbar, { variant: "success", title: config.successMessage }));
                (_a = config.onSuccess) === null || _a === void 0 ? void 0 : _a.call(config, response);
            }
            catch (error) {
                const gqlErrorMessage = error instanceof ApolloError
                    ? getNormalizedGraphQLErrorMessage(error)
                    : undefined;
                if (gqlErrorMessage) {
                    enqueueSnackbar(_jsx(Snackbar, { variant: "error", title: `${config.errorMessage} (${gqlErrorMessage})` }));
                }
                else {
                    enqueueSnackbar(_jsx(Snackbar, { variant: "error", title: config.errorMessage }));
                    (_b = config.onFailure) === null || _b === void 0 ? void 0 : _b.call(config, error);
                }
            }
            finally {
                (_c = config.onFinally) === null || _c === void 0 ? void 0 : _c.call(config);
            }
        });
    };
}
