/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getResourceIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("resourceIssues.title"),
    description: () => t("resourceIssues.description"),
    reportTemplateCodesOrder: [
      "broken_image_urls",
      "broken_js",
      "broken_css",
      "http_scripts_on_https_pages",
      "disallowed_js",
      "disallowed_css",
      "disallowed_js_uncrawled",
      "disallowed_css_uncrawled",
    ],
    reportStatFilter: (report) => {
      return [
        "broken_image_urls",
        "broken_js",
        "broken_css",
        "http_scripts_on_https_pages",
        "disallowed_js",
        "disallowed_css",
        "disallowed_js_uncrawled",
        "disallowed_css_uncrawled",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
