import {
  CrawlType,
  GetCrawlSettingsForSourcesQuery,
  GoogleAnalytics4SourceType,
  GoogleSearchConsoleSearchType,
} from "../../../../graphql";
import { ContextValues, FormValues } from "./types";

export function getFormValues(
  data: GetCrawlSettingsForSourcesQuery | undefined,
  contextValues: ContextValues,
): FormValues {
  const project = data?.getProject;

  const hasCrawlType = (type: CrawlType): boolean =>
    Boolean(project?.crawlTypes.find((t) => t === type));

  const hasDataOnlyCrawlType = (type: CrawlType): boolean =>
    Boolean(project?.dataOnlyCrawlTypes?.find((t) => t === type));

  const googleAnalytics4Property = project?.googleAnalytics4ProjectProperty;

  const reportSuitId = project?.adobeAnalyticsProjectReportSuite?.reportSuiteId;
  const adobeConnectionId = (
    project?.adobeAnalyticsProjectReportSuite?.adobeConnection ??
    project?.adobeAnalyticsProjectReportSuite?.adobeJwtConnection
  )?.id;

  return {
    crawlType: {
      website: hasCrawlType(CrawlType.Web),
      sitemaps: hasCrawlType(CrawlType.Sitemap),
      backlinks: hasCrawlType(CrawlType.Backlinks),
      googleSearchConsole: hasCrawlType(CrawlType.GoogleSearchConsole),
      analytics: hasCrawlType(CrawlType.GoogleAnalytics),
      logSummary: hasCrawlType(CrawlType.LogSummary),
      urlLists: hasCrawlType(CrawlType.List),
    },
    dataOnlyCrawlType: {
      sitemaps: hasDataOnlyCrawlType(CrawlType.Sitemap),
      backlinks: hasDataOnlyCrawlType(CrawlType.Backlinks),
      googleSearchConsole: hasDataOnlyCrawlType(CrawlType.GoogleSearchConsole),
      analytics: hasDataOnlyCrawlType(CrawlType.GoogleAnalytics),
      logSummary: hasDataOnlyCrawlType(CrawlType.LogSummary),
      urlLists: hasDataOnlyCrawlType(CrawlType.List),
    },
    website: {
      startUrls: project?.startUrls ?? [],
    },
    sitemaps: {
      discoverSitemapsInRobotsTxt:
        project?.discoverSitemapsInRobotsTxt ?? false,
    },
    backlinks: {
      majestic: contextValues.majesticAvailable
        ? {
            enabled: project?.majesticConfiguration?.enabled ?? false,
            maxRows:
              project?.majesticConfiguration?.maxRows?.toString() ??
              contextValues.majesticMaxUrl.toString(),
            useHistoricData:
              project?.majesticConfiguration?.useHistoricData === true
                ? "true"
                : "false",
            useRootDomain:
              project?.majesticConfiguration?.useRootDomain === true
                ? "true"
                : "false",
          }
        : undefined,
    },
    googleSearchConsole: {
      country:
        project?.googleSearchConsoleConfiguration?.country ?? "worldwide",
      searchType:
        project?.googleSearchConsoleConfiguration?.searchType ??
        GoogleSearchConsoleSearchType.Web,
      minClicks:
        project?.googleSearchConsoleConfiguration?.minClicks?.toString() ?? "0",
      lastNDays:
        project?.googleSearchConsoleConfiguration?.lastNDays?.toString() ??
        "100",
      includeQueries:
        project?.googleSearchConsoleConfiguration?.includeQueries ?? [],
      excludeQueries:
        project?.googleSearchConsoleConfiguration?.excludeQueries ?? [],
      useSearchConsolePropertyDomainsAsStartUrls:
        project?.googleSearchConsoleConfiguration
          ?.useSearchConsolePropertyDomainsAsStartUrls ?? false,
    },
    analytics: {
      googleAnalytics4: {
        propertyId: googleAnalytics4Property
          ? `${googleAnalytics4Property.googleConnection.id}:${googleAnalytics4Property.propertyName}`
          : undefined,
        lastNDays: googleAnalytics4Property?.lastNDays?.toString() || "",
        minimumSessions:
          googleAnalytics4Property?.minimumSessions?.toString() || "",
        minimumViews: googleAnalytics4Property?.minimumViews?.toString() || "",
        source:
          googleAnalytics4Property?.source ||
          GoogleAnalytics4SourceType.OrganicSearchLandingPages,
        conversionEvents:
          googleAnalytics4Property?.conversionEvents?.map((x) => ({
            eventName: x.eventName,
            eventType: x.eventType,
          })) ?? [],
      },
      adobe: {
        reportSuiteId:
          reportSuitId && adobeConnectionId
            ? `${reportSuitId}:${adobeConnectionId}`
            : "",
        reportSuiteName:
          project?.adobeAnalyticsProjectReportSuite?.suiteName ?? "",
        numberOfDays:
          project?.adobeAnalyticsProjectReportSuite?.numberOfDays?.toString() ??
          "",
        minimumVisits:
          project?.adobeAnalyticsProjectReportSuite?.minimumVisits?.toString() ??
          "",
        maximumUrls:
          project?.adobeAnalyticsProjectReportSuite?.maxRows?.toString() ?? "",
      },
    },
  };
}
