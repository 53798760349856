import { generatePath } from "react-router-dom";
import { getApiAccountId } from "@lumar/shared";
import queryString, { StringifyOptions, UrlObject } from "query-string";
import { removeTrailingSlash } from "./helpers";

const automateDomain = process.env.REACT_APP_AUTOMATE_APP_URL
  ? removeTrailingSlash(process.env.REACT_APP_AUTOMATE_APP_URL)
  : "";

function createAutomateURL(
  object: UrlObject,
  options?: StringifyOptions,
): string {
  if (!automateDomain) return "#";

  return `${automateDomain}${queryString.stringifyUrl(object, options)}`;
}

export const AutomateRoutes = {
  Domain: automateDomain,
  Dashboard: {
    ROUTE: "/accounts/:accountId/dashboard",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(AutomateRoutes.Dashboard.ROUTE, {
        accountId: getApiAccountId(accountId),
      });

      return createAutomateURL({ url, query: {} });
    },
  },
};
