import React from "react";
import { DynamicTrendChart } from "../../dynamic-trend-chart/DynamicTrendChart";
import { MultiDimensionalSeriesChart } from "../../multi-dimensional-series-chart/MultiDimensionalSeriesChart";
import { MultiSeriesChart } from "../../multi-series-chart/MultiSeriesChart";
import { SparklineList } from "../../sparkline-list/SparklineList";
import { ChartConfigItem } from "../../types/ChartConfig";
import { VennDiagram } from "../../venn-diagram-chart/VennDiagram";

export const ChartComponent = (props: ChartComponentProps): JSX.Element => {
  if (props.visualisationFamily === "Sparkline") {
    return <SparklineList {...props} />;
  } else if (props.visualisationFamily === "MultiDimensionalSeries") {
    return <MultiDimensionalSeriesChart {...props} />;
  } else if (props.visualisationFamily === "MultiSeries") {
    return <MultiSeriesChart {...props} />;
  } else if (props.visualisationFamily === "Venn") {
    return <VennDiagram {...props} />;
  } else if (props.visualisationFamily === "Dynamic") {
    return <DynamicTrendChart {...props} />;
  }
  return <></>;
};

export type ChartComponentProps = ChartConfigItem & {
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
};
