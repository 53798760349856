import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getSitemapsChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("sitemapsChart.title"),
    reportTemplateCodes: [
      "xml_sitemaps",
      "sitemaps_broken_disallowed",
      "sitemaps_too_many_urls",
      "empty_sitemaps",
      // CEMM: REP-148 Add Sitemaps with >5 redirects
    ],
    description: t("sitemapsChart.description"),
    requiredSources: {
      sources: [CrawlType.Sitemap],
      incompleteMessage: t("requiredSourcesMessage.sitemapsSource"),
    },
  })();
}
