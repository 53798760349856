import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getRedirectBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("redirectBreakdown.title"),
    description: t("redirectBreakdown.description"),
    reportTemplateCodes: [
      "301_redirects",
      "meta_refresh_redirect",
      "non_301_redirects",
      "javascript_redirects",
    ],
  })();
}
