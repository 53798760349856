var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ClickAwayListener, Popper as MuiPopper, } from "@material-ui/core";
import React from "react";
export const Popper = React.forwardRef(function CustomPopper(_a, ref) {
    var { onClose, children } = _a, props = __rest(_a, ["onClose", "children"]);
    const popperRef = React.useRef(null);
    React.useEffect(() => {
        if (!props.open || !onClose)
            return;
        function handler(e) {
            if (e.target instanceof Element &&
                popperRef.current &&
                !popperRef.current.contains(e.target) &&
                !e.target.closest("[role=presentation]")) {
                onClose === null || onClose === void 0 ? void 0 : onClose();
            }
        }
        window.addEventListener("focus", handler, true);
        () => window.removeEventListener("focus", handler);
    }, [onClose, props.open]);
    return (_jsx(MuiPopper, Object.assign({}, props, { ref: (el) => {
            popperRef.current = el;
            if (typeof ref === "function") {
                ref(el);
            }
            else if (ref != null) {
                ref.current = el;
            }
        }, onKeyDown: (e) => e.key === "Escape" && (onClose === null || onClose === void 0 ? void 0 : onClose()), children: _jsx(ClickAwayListener, { onClickAway: () => onClose === null || onClose === void 0 ? void 0 : onClose(), children: children }) })));
});
