import { ToggleIconButton } from "@lumar/shared";
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";
import clsx from "clsx";
import { FastField, FieldArray } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { CustomDNS, FormValues } from "../data/types";
import { SettingControlFactory } from "../useAdvancedControls";

export const getCustomDnsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.customDns.title"),
  path: "customDNS",
  // eslint-disable-next-line react/display-name
  control: () => <CustomDns />,
  testId: "crawl-settings-advanced-custom-dns",
  activeValues: ["customDns"],
  textForSearch: createTextForSearch([
    t("settings.customDns.title"),
    t("settings.customDns.description"),
    t("settings.customDns.instruction"),
    t("settings.customDns.example"),
    t("settings.customDns.addLabel"),
    t("settings.customDns.hostNamePlaceholder"),
    t("settings.customDns.addressPlaceholder"),
  ]),
  fieldNames: [
    ["customDns", t("settings.customDns.title")],
    ["hostName", t("settings.customDns.hostNameField")],
    ["ipAddress", t("settings.customDns.addressField")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  input: {
    width: "100%",
  },
  button: {
    width: "fit-content",
  },
  grid: {
    marginTop: theme.spacing(0),
    alignItems: "flex-start",
  },
}));

export const CustomDns = React.memo(CustomDnsInner);

function CustomDnsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const createNewDns = (): CustomDNS => ({
    hostName: "",
    ipAddress: "",
  });

  return (
    <>
      <Typography variant="caption">
        {t("settings.customDns.description")}
      </Typography>
      <Typography variant="caption" className={classes.indent}>
        {t("settings.customDns.instruction")}
      </Typography>
      <code className={clsx(classes.example, classes.indent)}>
        {t("settings.customDns.example")}
      </code>
      <FieldArray
        name="test.customDNS.customDns"
        render={({ form, push, remove }) => {
          const values = (form.values as FormValues).test.customDNS.customDns;
          return (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => push(createNewDns())}
                disabled={form.isSubmitting}
                className={clsx(classes.button, classes.indent)}
                startIcon={<AddCircle />}
                data-testid="custom-dns-add"
              >
                {t("settings.customDns.addLabel")}
              </Button>
              {values.map((_: unknown, idx: number) => (
                <Paper key={idx} style={{ padding: "8px", marginTop: "8px" }}>
                  <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`test.customDNS.customDns[${idx}].hostName`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t(
                          "settings.customDns.hostNamePlaceholder",
                        )}
                        className={classes.input}
                        data-testid="custom-dns-name"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`test.customDNS.customDns[${idx}].ipAddress`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t("settings.customDns.addressPlaceholder")}
                        className={classes.input}
                        data-testid="custom-dns-address"
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Tooltip
                        title={t("settings.customDns.removeTooltip")}
                        placement="top"
                        arrow
                      >
                        <ToggleIconButton
                          size="large"
                          variant="outlined"
                          colorVariant="red"
                          onClick={() => remove(idx)}
                          disabled={form.isSubmitting}
                          data-testid="custom-dns-remove"
                        >
                          <Delete />
                        </ToggleIconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          );
        }}
      />
    </>
  );
}
