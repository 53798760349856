import React from "react";
import {
  Alert,
  ArrowRightSolid,
  Button,
  getRawAccountId,
  useSession,
  Trans,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { addDays } from "date-fns";
import { RoleCode, useGetSubscriptionDataQuery } from "../../graphql";
import { useAccountsRoutes } from "../routing/accountsRoutes";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(0.75),
    paddingRight: theme.spacing(1.5),
  },
  content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  message: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  button: {
    marginLeft: "auto",
    flexShrink: 0,
  },
}));

const notificationPeriod = 90;

export function SubscriptionAlert(): JSX.Element {
  const classes = useStyles();
  const { account } = useSession();
  const { t } = useTranslation("navigation");
  const formatDate = useDateFormatter();
  const accountsRoutes = useAccountsRoutes();

  const expirationDate = useSubscriptionExpirationDate();

  const notificationStartDate =
    expirationDate && addDays(expirationDate, -notificationPeriod);

  if (
    !expirationDate ||
    (notificationStartDate &&
      notificationStartDate.getTime() > new Date().getTime())
  )
    return <></>;

  if (expirationDate.getTime() > new Date().getTime()) {
    return (
      <Alert
        severity="warning"
        classes={{ root: classes.alert, message: classes.content }}
      >
        <span className={classes.message}>
          <Trans
            ns="navigation"
            i18nKey="subscriptionExpiring"
            values={{
              expirationDate: formatDate(expirationDate, {
                dateStyle: "medium",
              }),
            }}
          />
        </span>
        <Button
          component="a"
          href={accountsRoutes.Subscription.getUrl({
            accountId: getRawAccountId(account.id),
          })}
          variant="outlined"
          size="small"
          className={classes.button}
          startIcon={<ArrowRightSolid />}
        >
          {t("subscriptionExpiringLabel")}
        </Button>
      </Alert>
    );
  } else {
    return (
      <Alert
        severity="error"
        classes={{ root: classes.alert, message: classes.content }}
      >
        <span className={classes.message}>
          <Trans ns="navigation" i18nKey="subscriptionExpired" />
        </span>
        <Button
          component="a"
          href={accountsRoutes.Subscription.getUrl({
            accountId: getRawAccountId(account.id),
          })}
          variant="outlined"
          size="small"
          className={classes.button}
          startIcon={<ArrowRightSolid />}
        >
          {t("subscriptionExpiredLabel")}
        </Button>
      </Alert>
    );
  }
}

function useSubscriptionExpirationDate(): Date | undefined {
  const { account, hasSufficientRole, hasFeatureFlagEnabled } = useSession();

  const isConductor = hasFeatureFlagEnabled("conductor-long-tail");

  const { data } = useGetSubscriptionDataQuery({
    variables: { accountId: account.id },
    onError: () => null,
    skip: !isConductor || !hasSufficientRole(RoleCode.Editor),
    fetchPolicy: "cache-first",
  });

  const isChargebee = data?.getAccount?.packageAccount?.chargebee;
  if (isChargebee !== false) return;

  const expirationDate = data?.getAccount?.conductorExpiry
    ? new Date(data.getAccount.conductorExpiry)
    : undefined;

  return expirationDate;
}
