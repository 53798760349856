/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getRankabilityChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("rankabilityChart.title"),
    description: () => t("rankabilityChart.description"),
    reportTemplateCodesOrder: [
      "primary_pages_with_serp_clicks",
      "primary_pages_in_serp_without_clicks",
    ],
    reportStatFilter: (report) => {
      return [
        "primary_pages_with_serp_clicks",
        "primary_pages_in_serp_without_clicks",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
