import React from "react";
import { Button, TimeZoneContext, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { addMinutes } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1),
  },
  closeButton: {
    marginLeft: theme.spacing(1),
  },
  removeButton: {
    marginLeft: "auto",
  },
}));

export function DateSelector({
  date,
  onChange,
  onClose,
  onRemove,
}: {
  date: Date | undefined;
  onChange: (value: Date) => void;
  onClose: () => void;
  onRemove: () => void;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const { timeZone } = React.useContext(TimeZoneContext);
  const now = new Date();
  const timeZoneRelativeOffset = now.getTimezoneOffset() + timeZone.offset;

  const value = date && addMinutes(date, timeZoneRelativeOffset);

  const getMinValue = (): Date => {
    return addMinutes(new Date(), timeZoneRelativeOffset + 60);
  };

  const handleChange = (value?: Date | null): void => {
    if (!value) return;
    onChange(addMinutes(value, -timeZoneRelativeOffset));
  };

  return (
    <div className={classes.root} data-testid="crawl-schedule-date-selector">
      <DateTimePicker
        value={value}
        onChange={(value) => handleChange(value)}
        disableToolbar
        variant="static"
        inputVariant="outlined"
        autoOk
        views={["date"]}
        minDate={getMinValue()}
      />
      <div className={classes.buttons}>
        <Button
          onClick={() => handleChange(getMinValue())}
          variant="contained"
          color="primary"
          size="small"
          data-testid="crawl-schedule-time-select-today"
        >
          {t("settings.today")}
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          size="small"
          className={classes.closeButton}
          data-testid="crawl-schedule-time-select-close"
        >
          {t("settings.closeSchedule")}
        </Button>
        <Button
          onClick={onRemove}
          variant="outlined"
          size="small"
          className={classes.removeButton}
          data-testid="crawl-schedule-time-select-remove"
        >
          {t("settings.removeSchedule")}
        </Button>
      </div>
    </div>
  );
}
