import { MetricsValuePresenterProps } from "../../data/types";
import { NumberPresenter } from "./NumberPresenter";

export function GoogleAnalyticsBounceRatePresenter({
  value,
  ...props
}: MetricsValuePresenterProps): JSX.Element {
  return (
    <NumberPresenter {...props} value={value / 100} maximumFractionDigits={1} />
  );
}
