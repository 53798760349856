import { ToggleIconButton, ViewBoardsSolid } from "@lumar/shared";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import {
  GridApiContext,
  GridPreferencePanelsValue,
} from "@mui/x-data-grid-pro";
import { useContext, useState } from "react";

import { assert } from "../../../../../_common/assert";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 8,
    "& svg": {
      fontSize: theme.typography.pxToRem(20),
    },
  },
}));

export function ColumnSelectorButton({
  disabled,
}: {
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const [showPreferences, setShowPreferences] = useState(false);

  const apiRef = useContext(GridApiContext);
  assert(apiRef);

  const openPreferencesDialog = (): void =>
    apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
  const hidePreferencesDialog = (): void => apiRef.current.hidePreferences();
  return (
    <ClickAwayListener onClickAway={() => setShowPreferences(false)}>
      <div>
        <ToggleIconButton
          data-pendo="edit-columns-button"
          data-testid="report-grid-edit-columns-button"
          size="large"
          variant="outlined"
          className={classes.button}
          onClick={() => {
            if (!showPreferences) {
              openPreferencesDialog();
            } else {
              hidePreferencesDialog();
            }
            setShowPreferences(!showPreferences);
          }}
          disabled={disabled}
        >
          <ViewBoardsSolid />
        </ToggleIconButton>
      </div>
    </ClickAwayListener>
  );
}
