import { ApolloError, useSession, useTranslation } from "@lumar/shared";
import { makeStyles, Button } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";
import { RoleCode } from "../../../../../../graphql";

const useStyles = makeStyles(() => ({
  button: {
    background: "white",
    border: "solid 1px #D1D5DB",
    marginLeft: 10,
    "&:hover": { background: "#eee" },
  },
}));

interface Params {
  accountId: string;
  projectId: string;
}

export function ComparedToCrawlArchived(props: {
  error?: ApolloError;
}): JSX.Element {
  const classes = useStyles();
  const { hasSufficientRole } = useSession();
  const { accountId, projectId } = useParams<Params>();
  const { t } = useTranslation("report");
  const message = props?.error?.message;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const crawlId = (props.error?.graphQLErrors[0]?.extensions?.crawl as any)
    .crawlCompareToId;
  const params = new URLSearchParams({ unarchive: crawlId, tab: "history" });
  const href = `/accounts/${accountId}/projects/${projectId}/crawls?${params}`;
  const hasEditorPermission = hasSufficientRole(RoleCode.Editor);

  return (
    <>
      {message ? message : null}
      {hasEditorPermission ? (
        <Button
          className={classes.button}
          component={Link}
          to={href}
          size="small"
        >
          {t("unarchiveComparedCrawl")}
        </Button>
      ) : null}
    </>
  );
}
