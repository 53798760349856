var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { GridApiContext } from "@mui/x-data-grid-pro";
import { DownloadSolid } from "../../icons";
import { isFunction } from "lodash";
import { useGridCsvExport } from "./export/useGridCsvExport";
import { assert } from "../helpers";
// FIXME: Don't use CSS overrides.
// Icon should inherit the size by default.
// Maybe the shared comp needs changes. - Michal
const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: theme.typography.pxToRem(20),
        padding: "8px 12.5px",
        backgroundColor: "white",
        minWidth: "45px",
        "& svg": {
            fontSize: "inherit",
        },
    },
}));
export function BlueGridToolbarExport(_a) {
    var { exportFileName } = _a, props = __rest(_a, ["exportFileName"]);
    const classes = useStyles();
    const apiRef = React.useContext(GridApiContext);
    assert(apiRef);
    const exportDataAsCsv = useGridCsvExport(apiRef);
    function exportCsv() {
        const api = apiRef === null || apiRef === void 0 ? void 0 : apiRef.current;
        if (!api) {
            return;
        }
        const filterVisibleAndAdditionalFields = (col) => {
            // The idea is to export a column if a developer *explicitly states* that they want to export it.
            // - Michal
            if (col.disableExport !== undefined)
                return !col.disableExport;
            return !col.hide;
        };
        const fieldsToExport = api
            .getAllColumns()
            .filter(filterVisibleAndAdditionalFields)
            .map((column) => column.field);
        exportDataAsCsv({
            fields: fieldsToExport,
            fileName: isFunction(exportFileName) ? exportFileName() : exportFileName,
            utf8WithBom: true,
        });
    }
    return (_jsx(Button, Object.assign({ onClick: () => exportCsv(), variant: "outlined", color: "default", size: "small", className: classes.button }, props, { children: _jsx(DownloadSolid, {}) })));
}
