import React from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import {
  useTranslation,
  TabSpinner,
  Typography,
  useNumberFormatter,
  useSession,
  EmptyState,
  SmileySad,
  ArrowCircleRightSolid,
  RefreshOutlined,
  Button,
  getRawCrawlId,
  DownloadSolid,
} from "@lumar/shared";
import {
  ProjectTasksData,
  UpdateTaskPosition,
  useProjectTasks,
} from "./data/useProjectTasks";
import {
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  useTheme,
} from "@material-ui/core";
import { ArchivedTasksGrid } from "./archived-tasks/ArchivedTasksGrid";
import { SortableTasksTables } from "./tasks-table/SortableTasksTables";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { Task } from "./data/types";
import { SearchWithDebounce } from "../_common/searchWithDebounce/SearchWithDebounce";
import { EditTaskDialog } from "./edit-task/EditTaskDialog";
import { RoleCode } from "../graphql";
import { TasksTables } from "./tasks-table/TasksTable";
import { Routes } from "../_common/routing/routes";
import { useParams } from "react-router-dom";
import { useExportTasks } from "./useExportTasks";

export function ProjectTaskManager(): JSX.Element {
  const { t } = useTranslation("taskManager");
  const searchParams = useURLSearchParams();

  const tab = searchParams.get("tab") || "all";

  const { loading, error, tasks, project, moveTask, updateTasksOrder } =
    useProjectTasks();

  return (
    <>
      <TopNavigation
        title={t("title")}
        breadcrumbs={[{ label: t("breadcrumb") }]}
        availableForShare
      />
      <Tabs
        value={tab}
        onChange={(_, value) => {
          searchParams.set("tab", value);
          searchParams.delete("pagination");
          searchParams.navigate();
        }}
        indicatorColor="primary"
      >
        <Tab
          value="all"
          label={
            <TabLabel
              label={t("allTasksTab")}
              loading={loading}
              value={error ? undefined : tasks.length}
            />
          }
          data-testid="task-manager-all-tasks"
        />
        <Tab
          value="archived"
          label={
            <TabLabel
              label={t("archivedTasksTab")}
              loading={loading}
              value={
                error ? undefined : (project?.archivedTasks.totalCount ?? 0)
              }
            />
          }
          data-testid="task-manager-archived-tasks"
        />
      </Tabs>
      {tab === "all" && (
        <ProjectTasksTable
          loading={loading}
          error={error}
          tasks={tasks}
          project={project}
          moveTask={moveTask}
          updateTasksOrder={updateTasksOrder}
        />
      )}
      {tab === "archived" && <ArchivedTasksGrid />}
    </>
  );
}

function TabLabel({
  label,
  loading,
  value,
}: {
  label: string;
  loading: boolean;
  value?: number;
}): JSX.Element {
  const formatNumber = useNumberFormatter();

  return (
    <Typography variant="inherit">
      {label}{" "}
      {loading ? (
        <TabSpinner />
      ) : value !== undefined ? (
        `(${formatNumber(value)})`
      ) : null}
    </Typography>
  );
}

export function ProjectTasksTable({
  loading,
  error,
  tasks,
  project,
  moveTask,
  updateTasksOrder,
}: {
  loading: boolean;
  error: boolean | undefined;
  tasks: Task[];
  project: ProjectTasksData["project"];
  moveTask: UpdateTaskPosition;
  updateTasksOrder: UpdateTaskPosition;
}): JSX.Element {
  const { t } = useTranslation(["common", "taskManager"]);
  const { hasSufficientRole } = useSession();
  const theme = useTheme();
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const exportTasks = useExportTasks();
  const classes = useStyles();
  const params = useURLSearchParams();

  const taskParam = params.get("task");
  const selectedTask = tasks.find((x) => x.id === taskParam);
  const setSelectedTask = React.useCallback(
    (task?: Task) => {
      if (task) {
        params.set("task", task.id);
        params.navigate();
      } else {
        params.delete("task");
        params.navigate();
      }
    },
    [params],
  );

  const [search, setSearch] = React.useState("");

  const formattedSearch = search.toLocaleLowerCase();
  const filteredTasks = React.useMemo(
    () =>
      tasks.filter((task) =>
        task.title.toLocaleLowerCase().includes(formattedSearch),
      ),
    [tasks, formattedSearch],
  );

  const canEdit = hasSufficientRole(RoleCode.Editor);
  const lastCrawlLink =
    project?.lastFinishedCrawl?.id &&
    Routes.Report.getUrl({
      accountId,
      projectId,
      crawlId: getRawCrawlId(project.lastFinishedCrawl.id),
      reportTemplateCode: "all_pages",
      reportTypeCode: "basic",
    });

  return (
    <div style={{ marginTop: 12 }}>
      <div className={classes.container}>
        <SearchWithDebounce
          debounceInMs={300}
          value={search}
          valueChangeCallbackFn={setSearch}
          placeholder={t("taskManager:table.searchPlaceholder")}
        />
        <Button
          onClick={() => exportTasks({ tasks: filteredTasks, project })}
          disabled={!filteredTasks.length}
          variant="outlined"
          size="small"
          className={classes.export}
          aria-label={t("taskManager:exportLabel")}
        >
          <DownloadSolid />
        </Button>
      </div>
      {error ? (
        <EmptyState
          title={t("common:genericError")}
          description={t("taskManager:taskLoadError")}
          icon={<SmileySad style={{ color: theme.palette.red[400] }} />}
          actions={[
            {
              type: "button",
              title: t("common:reloadPage"),
              onClick: () => window.location.reload(),
              startIcon: <RefreshOutlined />,
            },
          ]}
          height={400}
        />
      ) : loading ? (
        <div>
          <CircularProgress
            aria-label={t("taskManager:table.projectTasksLoading")}
            style={{ marginTop: theme.spacing(2) }}
          />
        </div>
      ) : !tasks.length ? (
        <EmptyState
          title={t("taskManager:noTaskTitle")}
          description={t("taskManager:noTaskDescription")}
          actions={
            lastCrawlLink
              ? [
                  {
                    type: "internalLink",
                    title: t("taskManager:goToLatestCrawl"),
                    to: lastCrawlLink,
                    endIcon: <ArrowCircleRightSolid />,
                  },
                ]
              : []
          }
          height={400}
        />
      ) : (
        <>
          {canEdit ? (
            <SortableTasksTables
              tasks={filteredTasks}
              onTaskSelected={setSelectedTask}
              onTaskMove={moveTask}
            />
          ) : (
            <TasksTables
              tasks={filteredTasks}
              onTaskSelected={setSelectedTask}
            />
          )}
        </>
      )}
      {selectedTask && (
        <EditTaskDialog
          taskId={selectedTask.id}
          onClose={() => setSelectedTask(undefined)}
          onStatusUpdated={(status) =>
            updateTasksOrder(selectedTask.id, status, 1)
          }
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 17,
  },
  export: {
    fontSize: theme.typography.pxToRem(20),
    padding: "8px 12.5px",
    backgroundColor: "white",
    minWidth: "45px",
    "& svg": {
      fontSize: "inherit",
    },
  },
}));
