import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getServerResponseTimeChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("serverResponseTime.title"),
    reportTemplateCodes: [
      "fast_server_response",
      "medium_server_response",
      "slow_server_response",
    ],
    description: t("serverResponseTime.description"),
  })();
}
