import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getSitemapLinksIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("sitemapLinksIssues.title"),
    requiredSources: {
      sources: [CrawlType.Sitemap],
      incompleteMessage: t("requiredSourcesMessage.sitemapsSource"),
    },
    reportTemplateCodes: [
      "broken_sitemap_links",
      "sitemaps_disallowed_malformed_links",
      "sitemaps_non_indexable_links",
      "mobile_alternates_in_sitemaps",
    ],
    description: t("sitemapLinksIssues.description"),
  })();
}
