import { ComparedToCrawlArchived } from "./ComparedToCrawlArchived";
import { ComparedToCrawlMissing } from "./ComparedToCrawlMissing";
import { ComparedToCrawlNotFinished } from "./ComparedToCrawlNotFinished";
import { ComparedToCrawlUnArchiving } from "./ComparedToCrawlUnArchiving";
import { CrawlArchived } from "./CrawlArchived";
import { CrawlNotFinished } from "./CrawlNotFinished";
import { CrawlUnArchiving } from "./CrawlUnArchiving";

export const crawlNoDataErrors = new Map([
  [
    "REPORT_DATA_UNAVAILABLE_COMPARE_TO_CRAWL_ARCHIVED",
    ComparedToCrawlArchived,
  ],
  ["REPORT_DATA_UNAVAILABLE_COMPARE_TO_CRAWL_MISSING", ComparedToCrawlMissing],
  [
    "REPORT_DATA_UNAVAILABLE_COMPARE_TO_CRAWL_NOT_FINISHED",
    ComparedToCrawlNotFinished,
  ],
  [
    "REPORT_DATA_UNAVAILABLE_COMPARE_TO_CRAWL_UNARCHIVING",
    ComparedToCrawlUnArchiving,
  ],
  ["REPORT_DATA_UNAVAILABLE_CRAWL_ARCHIVED", CrawlArchived],
  ["REPORT_DATA_UNAVAILABLE_CRAWL_NOT_FINISHED", CrawlNotFinished],
  ["REPORT_DATA_UNAVAILABLE_CRAWL_UNARCHIVING", CrawlUnArchiving],
]);
