/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getNon200PagesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    //visualisationColors: [theme.palette.red[500]],
    title: () => t("non200Pages.title"),
    description: () => t("non200Pages.description"),
    reportTemplateCodesOrder: [
      "301_redirects",
      "non_301_redirects",
      "unauthorised_pages",
      "uncategorised_http_response_codes",
      "4xx_errors",
      "5xx_errors",
      "failed_urls",
    ],
    reportStatFilter: (report) => {
      return [
        "301_redirects",
        "non_301_redirects",
        "unauthorised_pages",
        "uncategorised_http_response_codes",
        "4xx_errors",
        "5xx_errors",
        "failed_urls",
      ].includes(report.reportTemplateCode);
    },
    serieName: (report) => {
      const reportTemplateCode = report.reportTemplateCode;
      return reportTemplateCode === "non-200_pages"
        ? t("non200Pages.total")
        : report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "non-200-pages-chart",
    pendoAttributePrefix: "non-200-pages-chart",
  };
}
