import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import { ChangeEvent, KeyboardEvent } from "react";
import { SearchIcon, ClearIcon } from "@lumar/shared";

const useStyles = makeStyles(() => ({
  searchInput: (props: { size: SearchBarProps["size"] }) => ({
    width: "100%",
    background: "rgba(255, 255, 255, 0.55)",
    border: "1px solid #D1D5DB",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    height: props.size === "small" ? 36 : 48,
    fontSize: props.size === "small" ? 14 : 16,
    "& input": { paddingLeft: "5px" },
  }),
  // Adding useless props here since CSS loses priority of focus class
  searchInputFocused: {
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #6366F1",
    borderColor: "transparent",
    background: "white",
  },
  inputIcons: (props: { size: SearchBarProps["size"] }) => ({
    fontSize: props.size === "small" ? 20 : 24,
    color: "#6B7280",
  }),
  clearIcon: { cursor: "pointer", right: 0 },
}));

interface SearchBarProps {
  onSearch: (term: string) => void;
  searchTerm: string;
  placeholder: string;
  size?: "small" | "large";
  maxLenth?: number;
}

export function SearchBar(props: SearchBarProps): JSX.Element {
  const classes = useStyles({ size: props.size ?? "small" });

  const onSearchTermChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const term = e.target.value;
    props.onSearch(term);
  };

  const onClearSearch = (): void => props.onSearch("");

  const onSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>): void | null =>
    e.key === "Escape" ? onClearSearch() : null;

  return (
    <Input
      data-pendo="report-search-bar"
      disableUnderline={true}
      placeholder={props.placeholder}
      value={props.searchTerm}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={onSearchKeyDown}
      onChange={onSearchTermChange}
      inputProps={{
        "data-testid": "side-bar-common-search-input",
        maxLength: props.maxLenth,
      }}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon className={classes.inputIcons}></SearchIcon>
        </InputAdornment>
      }
      endAdornment={
        !!props.searchTerm ? (
          <InputAdornment position="start">
            <ClearIcon
              className={`${classes.inputIcons} ${classes.clearIcon}`}
              onClick={onClearSearch}
            ></ClearIcon>
          </InputAdornment>
        ) : null
      }
      classes={{ focused: classes.searchInputFocused }}
      className={classes.searchInput}
    />
  );
}
