import React from "react";
import useLocalStorageState from "use-local-storage-state";
import { DataExplorerDimension, DataExplorerTableConfig } from "./types";

interface Props {
  key: string;
  defaultConfig: DataExplorerTableConfig;
  dimensions: DataExplorerDimension[];
  primaryDimensions: DataExplorerDimension[];
}

interface Result {
  tableConfig: DataExplorerTableConfig;
  setTableConfig: React.Dispatch<React.SetStateAction<DataExplorerTableConfig>>;
}

export function useTableConfig({
  key,
  defaultConfig,
  dimensions,
  primaryDimensions,
}: Props): Result {
  const [tableConfig, setTableConfig] =
    useLocalStorageState<DataExplorerTableConfig>(key, {
      defaultValue: defaultConfig,
    });

  const formattedTableConfig = React.useMemo(() => {
    const allDimensions = [...primaryDimensions, ...dimensions];
    const dimension =
      [tableConfig.dimension, defaultConfig?.dimension].find((dimension) =>
        allDimensions.find((x) => x.code === dimension),
      ) ?? allDimensions[0].code;

    return {
      ...tableConfig,
      dimension,
      orderByColumn: tableConfig.orderByColumn || "col-1",
      orderDirection: tableConfig.orderDirection || "desc",
    };
  }, [tableConfig, defaultConfig, dimensions, primaryDimensions]);

  return {
    tableConfig: formattedTableConfig,
    setTableConfig,
  };
}
