import { createUrlPagination, getUrlPagination } from "@lumar/shared";
import { ExtendedURLSearchParams } from "../_common/routing/useURLSearchParams";

export function getPrisitinePaginationWithCurrentPageSize(
  params: ExtendedURLSearchParams,
): string | undefined {
  const urlPagination = getUrlPagination(
    params as unknown as URLSearchParams,
    "pagination",
  );
  const page = urlPagination.page;
  if (page) {
    const pageWithKeptPageSize = {
      index: 0,
      size: page.size,
      first: page.first,
    };
    return createUrlPagination({ page: pageWithKeptPageSize });
  }
}
