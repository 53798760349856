import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.red[600],
  },
}));

export function FailedReasonPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const classes = useStyles();

  if (!value) return <>-</>;

  return <span className={classes.error}>{value.replace(/_/g, " ")}</span>;
}
