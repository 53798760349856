import { Typography, useTranslation } from "@lumar/shared";
import { Tooltip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { ImpactBadge } from "../../../_common/report-helpers/ReportImpactBadge";
import { getReportImpact } from "../../../_common/report-helpers/reportImpact";
import { ReportTypeCode } from "../../../graphql";
import { ReportEntity } from "../../../report/Report.types";
import {
  CrawlContextCrawlCustomReport,
  CrawlContextCrawlReportStat,
} from "../../CrawlContext";
import { ReportLink } from "./ReportLink";

export function ReportNameCell({
  report,
}: {
  report: CrawlContextCrawlReportStat | CrawlContextCrawlCustomReport;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlOverview");

  const sign = report.reportTemplateTotalSign || 0;
  const customReportTemplate =
    "customReportTemplate" in report ? report.customReportTemplate : null;

  return (
    <ReportLink
      reportInput={
        customReportTemplate
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: customReportTemplate.id,
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              crawlId: report.crawlId,
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
      data-testid="all-reports-description-name"
    >
      <div className={classes.container}>
        <Tooltip
          arrow
          placement="bottom-start"
          title={
            <div className={classes.tooltipContent}>
              <Typography className={classes.impact}>{t("impact")}</Typography>
              <ImpactBadge
                impact={getReportImpact(report.reportTemplateTotalSign)}
                style={{ cursor: "unset" }}
              />
            </div>
          }
        >
          <div className={classes.indicatorContainer}>
            <div
              className={clsx({
                [classes.indicator]: true,
                [classes.indicatorPositive]: sign > 0,
                [classes.indicatorNegative]: sign < 0,
              })}
            />
          </div>
        </Tooltip>
        <Tooltip
          arrow
          placement="bottom-start"
          title={
            <>
              <Typography variant="subtitle2Medium">
                {customReportTemplate?.name ?? report.reportTemplate.name}
              </Typography>
              <Typography variant="caption">
                {customReportTemplate?.description ??
                  report.reportTemplate.description}
              </Typography>
            </>
          }
        >
          <Typography className={classes.name}>
            {customReportTemplate?.name ?? report.reportTemplate.name}
          </Typography>
        </Tooltip>
      </div>
    </ReportLink>
  );
}

const useStyles = makeStyles((theme) => ({
  container: { display: "flex", alignItems: "center" },
  indicatorContainer: {
    marginRight: theme.spacing(0.875),
  },
  indicator: {
    minWidth: theme.typography.pxToRem(9),
    maxWidth: theme.typography.pxToRem(9),
    minHeight: theme.typography.pxToRem(9),
    maxHeight: theme.typography.pxToRem(9),
    margin: theme.spacing(0.75),
    backgroundColor: theme.palette.grey[300],
  },
  indicatorPositive: {
    backgroundColor: theme.palette.green[500],
  },
  indicatorNegative: {
    backgroundColor: theme.palette.red[500],
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  impact: {
    textTransform: "uppercase",
    marginRight: theme.spacing(1.5),
  },
  tooltipContent: {
    display: "flex",
    alignItems: "center",
  },
}));
