import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getGoogleSearchConsoleBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("requiredSourcesMessage.gscSource"),
    },
    title: t("googleSearchConsoleBreakdown.title"),
    reportTemplateCodes: [
      "pages_in_google_search_console",
      "pages_not_in_google_search_console",
    ],
    description: t("googleSearchConsoleBreakdown.description"),
  })();
}
