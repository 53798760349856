import ace from "ace-builds/src-noconflict/ace";
import AceEditor from "react-ace";
import { makeStyles } from "@material-ui/core";

import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

ace.config.setModuleUrl(
  "ace/mode/javascript_worker",
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  require("file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js"),
);

// eslint-disable-next-line fp/no-mutation
ace.Range = ace.acequire("ace/range").Range;

const useStyles = makeStyles((theme) => ({
  errorHighlight: {
    backgroundColor: theme.palette.red[700],
    position: "absolute",
    zIndex: 2,
  },
}));

type Props = ConstructorParameters<typeof AceEditor>[0];

export function ScriptEditor(props: Props): JSX.Element {
  return (
    <AceEditor
      mode="javascript"
      editorProps={{ $blockScrolling: Infinity }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        firstLineNumber: 0,
      }}
      theme="tomorrow"
      wrapEnabled
      showGutter
      maxLines={12}
      minLines={12}
      width="100%"
      fontSize={14}
      {...props}
    />
  );
}

export function ScriptRejectionEditor({
  maxLines,
  ...props
}: Props & { maxLines: number }): JSX.Element {
  const classes = useStyles();

  const getLineCount = (): number => {
    const value = props.value || "";
    const lines = value.split("\n").length;
    return lines > maxLines ? lines : maxLines;
  };

  return (
    <AceEditor
      mode="text"
      editorProps={{ $blockScrolling: Infinity }}
      setOptions={{
        useWorker: false,
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
      }}
      theme="tomorrow"
      wrapEnabled
      showGutter
      showPrintMargin={false}
      maxLines={12}
      minLines={12}
      width="100%"
      fontSize={14}
      markers={[
        {
          type: "fullLine",
          className: classes.errorHighlight,
          startRow: maxLines,
          endRow: getLineCount(),
          startCol: 0,
          endCol: 0,
        },
      ]}
      {...props}
    />
  );
}

export function TextEditor(props: Props): JSX.Element {
  return (
    <AceEditor
      mode="text"
      editorProps={{ $blockScrolling: Infinity }}
      setOptions={{
        useWorker: false,
        enableSnippets: true,
        showLineNumbers: true,
        firstLineNumber: 1,
      }}
      theme="tomorrow"
      wrapEnabled
      showGutter
      maxLines={12}
      minLines={12}
      width="100%"
      fontSize={14}
      {...props}
    />
  );
}
