/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Trans } from "@lumar/shared";
import { TFunction } from "i18next";
import { Theme, Typography } from "@material-ui/core";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType } from "../../../graphql";

function generateOrphanedPagesReportChart(fixOrdering = false) {
  return function (t: TFunction<"charts">, theme: Theme): ChartConfigItem {
    return {
      inputSource: "CrawlContext",
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      requiredSources: {
        sources: [CrawlType.Web],
        incompleteMessage: t("requiredSourcesMessage.webSource"),
      },
      //visualisationColors: [theme.palette.grey[500]],
      title: () => t("orphanedPagesReport.title"),
      description: () => (
        <Trans ns="charts" i18nKey="orphanedPagesReport.description">
          <Typography paragraph variant="inherit">
            dfdfdf
          </Typography>
          <Typography variant="inherit">dfdfdf</Typography>
        </Trans>
      ),
      reportTemplateCodesOrder: [
        "all_orphaned_pages",
        "orphaned_organic_landing_pages",
        "orphaned_google_search_console_pages",
        "orphaned_pages_with_backlinks",
        "orphaned_sitemaps_pages",
        "orphaned_log_summary_pages",
        "orphaned_list_pages",
        "indexable_orphaned_web_pages",
      ],
      reportStatFilter: (report) => {
        return [
          "all_orphaned_pages",
          "orphaned_organic_landing_pages",
          "orphaned_google_search_console_pages",
          "orphaned_pages_with_backlinks",
          "orphaned_sitemaps_pages",
          "orphaned_log_summary_pages",
          "orphaned_list_pages",
          "indexable_orphaned_web_pages",
        ].includes(report.reportTemplateCode);
      },
      reportStatsOrderBy: fixOrdering
        ? undefined
        : {
            field: "basic",
            direction: "desc",
          },
      serieName: (report) => {
        const reportTemplateCode = report.reportTemplateCode;
        return reportTemplateCode === "all_orphaned_pages"
          ? t("non200Pages.total")
          : report.reportTemplateName;
      },
      count: (report) => {
        return report.basic ?? 0;
      },

      testAttributePrefix: "orphaned-pages-chart",
    };
  };
}

export const getOrphanedPagesReportChart = generateOrphanedPagesReportChart();
