import React from "react";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import { useSnackbar } from "notistack";
import { isNonEmpty } from "@lumar/shared";

import {
  ProjectUploadType,
  useGetManualUploadSampleForSourcesQuery,
} from "../../../../graphql";
import { FileUpload, FormValues, UploadType } from "./types";
import {
  isUploadTypeMatch,
  useManualUploadContext,
} from "./ManualUploadsWrapper";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";

export interface FormValuesResult {
  loading: boolean;
  formValues: FormValues;
  sample: string[][];
  availableUploadTypes: UploadType[];
}

interface Props {
  file: FileUpload;
  sample?: string[][];
}

export function useFileUploadFormValues({
  file,
  sample: providedSample,
}: Props): FormValuesResult {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();
  const { uploadTypes, metrics } = useManualUploadContext(file.crawlType);

  const [sample, setSample] = React.useState<string[][]>(providedSample || []);

  const { loading } = useGetManualUploadSampleForSourcesQuery({
    variables: { id: file.id },
    skip: Boolean(providedSample),
    onCompleted: (data) =>
      setSample(
        formatSample(isNonEmpty(data.node) ? data.node?.sample || "" : ""),
      ),
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileSample")}
          error={error}
        />,
      ),
  });

  const availableUploadTypes: UploadType[] = [
    ...uploadTypes.filter(
      (uploadType) =>
        uploadType.code === file.uploadType ||
        isUploadTypeMatch(sample, uploadType),
    ),
    ...(file.isCustomizable
      ? [
          {
            code: ProjectUploadType.Custom,
            name: t("sources.manualUpload.custom"),
            crawlType: file.crawlType,
            template: file.customTemplate || {
              headerRow: guessDataStartRow(sample),
              metrics: metrics,
            },
          },
        ]
      : []),
  ];

  return {
    loading,
    formValues: getFormValues(file, availableUploadTypes),
    availableUploadTypes,
    sample,
  };
}

function getFormValues(
  file: FileUpload,
  uploadTypes: UploadType[],
): FormValues {
  const template = uploadTypes.find(
    (uploadType) => uploadType.code === file.uploadType,
  )?.template;

  return {
    uploadType: file.uploadType ?? ProjectUploadType.Custom,
    baseDomain: file.baseDomain ?? "",
    headerRow: template?.headerRow,
    metrics: template?.metrics || [],
  };
}

function formatSample(sample: string): string[][] {
  function removeByteOrderMarks(value: string): string {
    return value.replace(/^\uFEFF/, "").replace(/^\u00BB\u00BF/, "");
  }

  return Papa.parse(removeByteOrderMarks(sample)).data as string[][];
}

function guessDataStartRow(data: string[][]): number {
  // eslint-disable-next-line no-var
  var longesRow = { max: 0, index: 0 };
  data.forEach((row, idx) => {
    const rowLength = row.filter(Boolean).length;
    if (rowLength > longesRow.max) {
      // eslint-disable-next-line fp/no-mutation
      longesRow = { max: rowLength, index: idx };
    }
  });

  const hasNumber = data[longesRow.index].filter((x) => !isNaN(+x));

  return hasNumber.length > 1 && longesRow.index > 0
    ? longesRow.index - 1
    : longesRow.index;
}
