import { groupBy } from "lodash";
import { Column, DataExplorerTableConfig } from "../types";

export const generateQuery = (
  tableConfig: DataExplorerTableConfig,
  includeDimension = true,
): Record<string, unknown> => {
  const { dimension, columns } = tableConfig;

  const groupedMetrics = groupBy(columns, "aggregationCalculation");

  const returnMetricCode = (group: Column[]): Record<string, true> =>
    group
      .map((column) => column.metric)
      .reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.code]: true,
        }),
        {} as Record<string, true>,
      );

  const query = {
    ...(includeDimension && {
      [dimension]: true,
    }),
    ...(groupedMetrics.count && {
      count: returnMetricCode(groupedMetrics.count),
    }),
    ...(groupedMetrics.avg && {
      avg: returnMetricCode(groupedMetrics.avg),
    }),
    ...(groupedMetrics.sum && {
      sum: returnMetricCode(groupedMetrics.sum),
    }),
  };

  return query;
};
