import {
  EmptyState,
  ExclamationCircleOutlined,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "minmax(150px, auto) minmax(150px, 1fr)",
    columnGap: theme.spacing(2.375),
    textAlign: "start",
    margin: theme.spacing(2, 5),
  },
  cell: {
    margin: theme.spacing(1),
    wordBreak: "break-word",
  },
}));

export function PreviewResponseHeaders({
  headers,
}: {
  headers?: Record<string, string>;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  if (!headers) {
    return (
      <EmptyState
        icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
        title={t("previewFailedTitle")}
        description={t("previewFailedDescription")}
        height="100%"
      />
    );
  }

  return (
    <div
      className={classes.grid}
      data-testid="project-preview-response-headers"
    >
      {Object.entries(headers).map(([key, value]) => (
        <React.Fragment key={key}>
          <div className={classes.cell}>{key}</div>
          <div className={classes.cell}>{value}</div>
        </React.Fragment>
      ))}
    </div>
  );
}
