import React from "react";
import { BlueDataGrid, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useProjectArchivedTasks } from "../data/useProjectArchivedTasks";
import { useArchivedTasksColumns } from "./useArchivedTasksColumns";
import { SearchWithDebounce } from "../../_common/searchWithDebounce/SearchWithDebounce";
import { ArchivedTask } from "../data/types";
import { EditTaskDialog } from "../edit-task/EditTaskDialog";

export function ArchivedTasksGrid(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const [search, setSearch] = React.useState("");
  const [editingTask, setEditingTask] = React.useState<
    ArchivedTask | undefined
  >();

  const { loading, error, tasks, taskCount, totalTaskCount, pagination } =
    useProjectArchivedTasks({ search });

  const columns = useArchivedTasksColumns({
    onTaskSelected: (task) => setEditingTask(task),
  });

  return (
    <div className={classes.root} data-testid="task-manager-archived-table">
      <BlueDataGrid
        name="archived-tasks"
        loading={loading}
        error={error}
        rows={tasks}
        columns={columns}
        rowCount={taskCount}
        totalRowCount={totalTaskCount}
        autoRowHeight
        rowHeight={72}
        sortingOrder={["desc", "asc", null]}
        sticky
        paginationMode="server"
        {...pagination}
        components={{
          ToolbarLeft: SearchWithDebounce,
        }}
        componentsProps={{
          toolbarLeft: {
            debounceInMs: 300,
            placeholder: t("archivedTasks.searchPlaceholder"),
            value: search,
            valueChangeCallbackFn: setSearch,
            disabled: Boolean(error),
          },
        }}
        className={classes.grid}
      />
      {editingTask && (
        <EditTaskDialog
          taskId={editingTask.id}
          onClose={() => setEditingTask(undefined)}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.875),
  },
  grid: {
    "& .MuiDataGrid-cell": {
      padding: 0,
    },
  },
}));
