import {
  makeStyles,
  List,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useState } from "react";
import {
  useParams,
  useHistory,
  useLocation,
  matchPath,
} from "react-router-dom";
import { ProjectViewerLink } from "../../project-viewer-link/ProjectViewerLink";
import { SearchBar } from "../../search-bar/SearchBar";
import {
  Scrollbar,
  SettingsIcon,
  getRawCrawlId,
  getRawProjectId,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { SelectedProjectInfo } from "../selected-project-info/SelectedProjectInfo";
import { ProjectSelectorItem } from "./project-selector-item/ProjectSelectorItem";
import { ViewAllLink } from "./view-all-link/ViewAllLink";
import {
  ProjectConnectionFilterInput,
  RoleCode,
  useProjectSelectorQuery,
} from "../../../graphql";
import { SidebarX } from "../../icons";
import { Routes } from "../../../_common/routing/routes";
import { ProjectSelectorProject } from "./project-selector-item/Project.type";

const containerWidth = 567;

const useStyles = makeStyles((theme) => ({
  projectSettings: {
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: "20px 25px 50px 25px",
    backgroundColor: "#EBEFF3",
    borderRight: "solid 1px #D1D5DB",
    overflowY: "hidden",
    overflowX: "hidden",
    width: containerWidth,
    backfaceVisibility: "hidden",
  },
  projectsList: { paddingTop: 0 },
  closeButton: {
    cursor: "pointer",
  },
  clearIcon: { fontSize: 16, top: -1, position: "relative" },
  projectTitle: {
    WebkitMaskImage:
      "linear-gradient(to right, rgba(0,0,0,1) 72%, rgba(0,0,0,0))",
  },
  circularProgress: {
    color: theme.palette.primary.main,
  },
}));

const getProjectsFilter = (
  term: string,
): ProjectConnectionFilterInput | undefined => {
  if (term) {
    return {
      _or: [{ name: { contains: term } }, { sitePrimary: { contains: term } }],
    };
  }
};

export function ProjectSelector(props: {
  selectedProjectInfo?: { name: string; primaryDomain: string };
}): JSX.Element {
  const { t } = useTranslation("sidebar");
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const history = useHistory();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();

  const { hasSufficientRole } = useSession();

  const { data, loading } = useProjectSelectorQuery({
    variables: {
      accountId,
      ...(!!searchTerm && { filter: getProjectsFilter(searchTerm) }),
    },
    fetchPolicy: "cache-first",
  });
  const projects = data?.getAccount?.projects.nodes;
  const crawlTypesMetadata = data?.getCrawlTypesMetadata ?? [];

  const onProjectItemClick = (project: ProjectSelectorProject): void => {
    setTimeout(() =>
      history.push(
        projectSearchItemHref({
          accountId,
          project,
          path: location.pathname,
          params: new URLSearchParams(location.search),
        }),
      ),
    );
  };

  return (
    <Box width={containerWidth} height="100%">
      <Scrollbar>
        <Box className={classes.container}>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={11}>
              <SelectedProjectInfo
                className={classes.projectTitle}
                selectedProjectInfo={props.selectedProjectInfo}
                size="large"
              ></SelectedProjectInfo>
            </Grid>
            <Grid item xs={1} container justifyContent="flex-end">
              <Box className={classes.closeButton}>
                <SidebarX style={{ fill: "#D1D5DB" }} />
              </Box>
            </Grid>
          </Grid>
          {hasSufficientRole(RoleCode.Editor) ? (
            <ProjectViewerLink
              Icon={SettingsIcon}
              to={Routes.Crawls.getUrl({
                accountId,
                projectId,
                tab: "edit",
              })}
              label={t("projectSettings")}
              className={classes.projectSettings}
            />
          ) : null}
          <SearchBar
            onSearch={setSearchTerm}
            searchTerm={searchTerm}
            size="large"
            placeholder={t("searchProjects")}
          ></SearchBar>
          {loading ? (
            <Grid container justifyContent="center" direction="row">
              <Grid item>
                <CircularProgress className={classes.circularProgress} />
              </Grid>
            </Grid>
          ) : (
            <List className={classes.projectsList}>
              {projects?.map((project) => (
                <ProjectSelectorItem
                  key={project.id}
                  project={project}
                  crawlTypesMetadata={crawlTypesMetadata}
                  onClick={() => onProjectItemClick(project)}
                />
              ))}
            </List>
          )}
        </Box>
      </Scrollbar>
      <ViewAllLink />
    </Box>
  );
}

function projectSearchItemHref({
  accountId,
  path,
  params,
  project,
}: {
  accountId: string;
  path: string;
  params: URLSearchParams;
  project: ProjectSelectorProject;
}): string {
  const projectId = getRawProjectId(project.id);
  const crawlId =
    project.lastFinishedCrawl?.id &&
    getRawCrawlId(project.lastFinishedCrawl.id);

  if (!crawlId) {
    return Routes.Crawls.getUrl({
      accountId,
      projectId,
      tab: "edit",
      step: 4,
    });
  }

  if (matchPath(path, { path: Routes.DataExplorer.ROUTE, exact: true })) {
    return Routes.DataExplorer.getUrl({ accountId, projectId, crawlId });
  }

  if (matchPath(path, { path: Routes.Tasks.ROUTE, exact: true })) {
    return Routes.Tasks.getUrl({ accountId, projectId, crawlId });
  }

  if (matchPath(path, { path: Routes.CrawlOverview.ROUTE, exact: true })) {
    return Routes.CrawlOverview.getUrl({
      accountId,
      projectId,
      crawlId,
      category: params.get("category"),
      type: params.get("type"),
      resolvePath: true,
    });
  }

  const reportMatch = matchPath<{ reportTemplateCodeWithTypeCode: string }>(
    path,
    { path: Routes.Report.ROUTE, exact: true },
  );
  if (reportMatch) {
    return Routes.Report.getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCodeWithTypeCode:
        reportMatch.params.reportTemplateCodeWithTypeCode,
      resolvePath: true,
      segmentId: null,
    });
  }

  const resourceDetailMatch = matchPath<{
    reportTemplateCodeWithTypeCode: string;
  }>(path, { path: Routes.ResourceDetail.ROUTE, exact: true });
  if (resourceDetailMatch) {
    return Routes.Report.getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCodeWithTypeCode:
        resourceDetailMatch.params.reportTemplateCodeWithTypeCode,
      resolvePath: true,
      segmentId: null,
    });
  }

  return Routes.CrawlOverview.getUrl({
    accountId,
    projectId,
    crawlId,
  });
}
