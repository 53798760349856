import { useTranslation } from "@lumar/shared";
import { insertIf } from "../../../../_common/insertIf";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";
import { BoundingBoxWithScreenshotPresenter } from "../../../metrics-value-presenter";
import { useExampleUrlScreenshot } from "../data/useExampleUrlScreenshot";

export function IssueDetailsOverview({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useMetricsConfig(data.metrics);

  return <MetricsGroups metrics={data.metrics} config={config} />;
}

function useMetricsConfig(metrics: Metrics): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  const ruleId = metrics["ruleId"]?.value;
  const showColorContrast = colorContrastRules.includes(ruleId);

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  const { loading, error, screenshot } = useExampleUrlScreenshot(metrics);

  return [
    {
      name: t("accessibilityMetrics"),
      items: [
        { code: "elementSelectorCount", name: t("issueInstanceCount") },
        { code: "needsManualReview" },
        { code: "legal" },
        { code: "category" },
      ],
    },
    ...insertIf(showColorContrast, {
      name: t("issueColorContrast"),
      items: [
        { code: "fontSize" },
        { code: "fontWeight" },
        { code: "isFontLarge" },
        { code: "fontColor" },
        { code: "contrastRatio" },
        { code: "bgMinColor" },
        { code: "contrastRatioAverage" },
        { code: "bgAverage" },
        { code: "bgMaxColor" },
        { code: "passProbability" },
        ...insertIf(metrics["boundingBox"]?.value, {
          code: "screenshot",
          name: t("accessibilityIssue.screenshot"),
          value: (
            <BoundingBoxWithScreenshotPresenter
              {...getMetricProps("boundingBox")}
              loading={loading}
              error={error}
              screenshot={screenshot}
            />
          ),
        }),
      ],
    }),
  ];
}

const colorContrastRules = [
  "color-contrast",
  "color-contrast-enhanced",
  "link-in-text-block",
];
