import {
  MenuNavigationGrid,
  ObjectConnectionV7,
  StatsV9,
  CloudUp,
  useTranslation,
} from "@lumar/shared";
import { useParams } from "react-router-dom";
import { MarketingScreen } from "../../_common/components/marketing-screen/MarketingScreen";
import SegmentationUpgradePreview from "./segmentation-upgrade-preview.svg";
import { useAccountsRoutes } from "../../_common/routing/accountsRoutes";

export function UpgradeToSegmentationMessage(): JSX.Element {
  const { t } = useTranslation("segments");
  const { accountId } = useParams<{ accountId: string }>();
  const accountsRoutes = useAccountsRoutes();

  return (
    <MarketingScreen
      title={t("upgrade.title")}
      bulletpoints={[
        [MenuNavigationGrid, t("upgrade.reason1")],
        [ObjectConnectionV7, t("upgrade.reason2")],
        [StatsV9, t("upgrade.reason3")],
        [CloudUp, t("upgrade.reason4")],
      ]}
      image={(props) => (
        <img
          src={SegmentationUpgradePreview}
          alt={t("upgrade.previewImageAltDescription")}
          title={t("upgrade.previewImageAltDescription")}
          {...props}
        />
      )}
      onRequest={() =>
        (window.location.href = accountsRoutes.Subscription.getUrl({
          accountId,
        }))
      }
      data-testid="upgrade-to-segmentation-message"
    />
  );
}
