import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedCLSChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    supportedModules: [ModuleCode.SiteSpeed],
    icon: "lighthouse",
    title: t("siteSpeedCLSChart.title"),
    reportTemplateCodes: [
      "cumulative_layout_shift_slow",
      "cumulative_layout_shift_moderate",
      "cumulative_layout_shift_fast",
    ],
    description: t("siteSpeedCLSChart.description"),
  })();
}
