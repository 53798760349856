var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonGroup, makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { Button } from "../buttons/Button";
const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.grey[500],
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[200]}`,
        "&:hover": {
            color: theme.palette.grey[600],
            background: theme.palette.grey[100],
        },
        flexGrow: 1,
    },
    startIcon: {
        marginRight: 4,
    },
    selected: ({ colorVariant }) => ({
        color: theme.palette.grey[600],
        background: colorVariant === "bold"
            ? theme.palette.ultraviolet[50]
            : theme.palette.secondary.main,
        boxShadow: `0 0 0 1px ${colorVariant === "bold"
            ? theme.palette.ultraviolet[400]
            : theme.palette.grey[300]} inset`,
        zIndex: 1,
    }),
    sizeSmall: {
        lineHeight: "17px",
        paddingTop: 6,
        paddingBottom: 6,
    },
    hideElement: {
        border: 0,
        clip: "rect(1px, 1px, 1px, 1px)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: 1,
    },
}));
export function SelectableButtonGroup(_a) {
    var { value, onValueChange, options, size = "large", disabled, className, colorVariant = "bold", dataTestId, classes: classesProp } = _a, props = __rest(_a, ["value", "onValueChange", "options", "size", "disabled", "className", "colorVariant", "dataTestId", "classes"]);
    const classes = useStyles({ colorVariant });
    return (_jsx(ButtonGroup, Object.assign({ "data-testid": dataTestId, "data-value": value, disabled: disabled, className: className }, props, { children: options.map((option, i) => {
            var _a, _b;
            const isSelected = value === option.value;
            const button = (_jsx(Button, { className: option.className, classes: {
                    root: clsx(classes.root, classesProp === null || classesProp === void 0 ? void 0 : classesProp.option, isSelected && classes.selected, isSelected && (classesProp === null || classesProp === void 0 ? void 0 : classesProp.optionSelected)),
                    sizeSmall: classes.sizeSmall,
                    startIcon: classes.startIcon,
                }, startIcon: (_a = option.startIcon) === null || _a === void 0 ? void 0 : _a.call(option, isSelected), endIcon: (_b = option.endIcon) === null || _b === void 0 ? void 0 : _b.call(option, isSelected), onClick: () => onValueChange(option.value), size: size, disabled: option.disabled, "data-pendo": option["data-pendo"], "data-testid": option["data-testid"], "aria-label": option.label, role: "option", "aria-selected": isSelected, children: option.hideLabel ? null : option.label }, i));
            if (option.tooltipTitle) {
                return (_jsx(Tooltip, { title: option.tooltipTitle, arrow: false, children: button }, i));
            }
            return button;
        }) })));
}
