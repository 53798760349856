/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getMobileContentIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("mobileContentIssues.title"),
    description: () => t("mobileContentIssues.description"),
    reportTemplateCodesOrder: [
      "separate_mobile_wordcount_mismatch",
      "separate_mobile_content_mismatch",
      "separate_mobile_links_out_mismatch",
      "separate_mobile_links_in_mismatch",
    ],
    reportStatFilter: (report) => {
      return [
        "separate_mobile_wordcount_mismatch",
        "separate_mobile_content_mismatch",
        "separate_mobile_links_out_mismatch",
        "separate_mobile_links_in_mismatch",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
