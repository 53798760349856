import { isEqual } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { useURLSearchParams } from "../../helpers/routing/useURLSearchParams";
import { isNotEmptyConnectionFilter } from "./isNotEmptyConnectionFilter";
import { validateConnectionFilter } from "./validateConnectionFilter";
export function useConnectionFilterParam({ onFilterChange, replace = false, } = {}) {
    const searchParams = useURLSearchParams();
    const history = useHistory();
    const filterParam = searchParams.get("filter");
    const filter = React.useMemo(() => {
        if (filterParam) {
            try {
                const decodedFilterParam = decodeConnectionFilterSearchParam(filterParam);
                const filter = isAndFilter(decodedFilterParam)
                    ? { _or: [decodedFilterParam] }
                    : decodedFilterParam;
                validateConnectionFilter(filter);
                return filter;
            }
            catch (e) {
                return {};
            }
        }
        return {};
    }, [filterParam]);
    function setFilter(incomingFilter) {
        if (isEqual(incomingFilter, filter)) {
            return;
        }
        if (isNotEmptyConnectionFilter(incomingFilter)) {
            searchParams.set("filter", encodeConnectionFilterSearchParam(incomingFilter));
            if (onFilterChange) {
                onFilterChange(searchParams);
            }
            if (replace) {
                history.replace({ search: searchParams.toString() });
            }
            else {
                history.push({ search: searchParams.toString() });
            }
        }
        else {
            searchParams.delete("filter");
            if (onFilterChange) {
                onFilterChange(searchParams);
            }
            if (replace) {
                history.replace({ search: searchParams.toString() });
            }
            else {
                history.push({ search: searchParams.toString() });
            }
        }
    }
    return [filter, setFilter];
}
export function decodeConnectionFilterSearchParam(filter) {
    return JSON.parse(unescape(atob(filter)));
}
export function encodeConnectionFilterSearchParam(filter) {
    return btoa(escape(JSON.stringify(filter)));
}
function isAndFilter(value) {
    return (Boolean(value) &&
        value._or === undefined &&
        value._and !== undefined);
}
