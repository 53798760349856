import React from "react";
import { SearchSolid, TextField, useTranslation } from "@lumar/shared";
import { IconButton, makeStyles } from "@material-ui/core";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 290,
    height: 32,
  },
  icon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}));

export function SegmentSearch({
  search,
  disabled,
  setSearch,
}: {
  search: string;
  disabled?: boolean;
  setSearch: (values: string) => void;
}): JSX.Element {
  const { t } = useTranslation("segments");
  const classes = useStyles();

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      disabled={disabled}
      placeholder={t("segmentSearchPlaceholder")}
      className={classes.textField}
      InputProps={{
        startAdornment: <SearchSolid className={classes.icon} />,
        endAdornment: search ? (
          <IconButton
            onClick={() => setSearch("")}
            data-testid="search-with-debounce-x"
            size="small"
          >
            <ClearRoundedIcon />
          </IconButton>
        ) : null,
      }}
    />
  );
}
