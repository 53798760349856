import React from "react";
import {
  TimeZoneInfo,
  useSession,
  TimeZoneProvider,
  useTranslation,
  GlobeSimple,
  Snackbar,
  Typography,
} from "@lumar/shared";
import { useSnackbar } from "notistack";

export function AppTimeZoneProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { t } = useTranslation("timezone");
  const { enqueueSnackbar } = useSnackbar();

  const { timeZone: apiTimeZone, setTimeZone } = useSession();

  const localStorageTimeZone = useUpdateTimeZoneFromLocalStorage();
  const timeZone = localStorageTimeZone ?? apiTimeZone;

  const onChange = React.useCallback(
    (props: {
      timeZone: string | undefined;
      lastActiveTimeZone: string | undefined;
    }) => {
      setTimeZone({
        selected: props.timeZone,
        lastActive: props.lastActiveTimeZone,
      }).catch(() => console.error("Faild to set time zone!"));
    },
    [setTimeZone],
  );

  return (
    <TimeZoneProvider
      timeZoneCode={timeZone?.selected}
      lastActiveTimeZone={timeZone?.lastActive}
      onChange={onChange}
      onNewTimeZoneDetected={(timeZone, setTimeZone) => {
        enqueueSnackbar(
          <Snackbar
            variant="info"
            title={t("notificationTitile", { timezone: timeZone.longName })}
            actions={{
              primaryButtonLabel: t("accept"),
              secondaryButtonLabel: t("cancel"),
              onPrimaryButtonClick: () => setTimeZone(timeZone.code),
            }}
            icon={GlobeSimple}
          >
            <Typography variant="caption">
              {t("notificationDescription")}
            </Typography>
          </Snackbar>,
          { persist: true },
        );
      }}
    >
      {children}
    </TimeZoneProvider>
  );
}

// Note: The previous state from the local storage should be saved on
// the api side and should be used for the first load to prevent breaking
// any functionalities during the update.
function useUpdateTimeZoneFromLocalStorage(): TimeZoneInfo | undefined {
  const { timeZone, setTimeZone } = useSession();

  const localStorageState = getLocalStorageState();

  const localStorageTimeZoneAvailable =
    Boolean(localStorageState?.savedTimeZone) ||
    Boolean(localStorageState?.lastTimeZone);
  const apiTimeZoneNotSet = !timeZone.selected && !timeZone.lastActive;
  const shouldUpdateFromLocalStorage =
    localStorageTimeZoneAvailable && apiTimeZoneNotSet;

  React.useLayoutEffect(() => {
    if (shouldUpdateFromLocalStorage) {
      setTimeZone({
        selected: localStorageState?.savedTimeZone,
        lastActive: localStorageState?.lastTimeZone,
      });
    }
  }, [setTimeZone, shouldUpdateFromLocalStorage, localStorageState, timeZone]);

  if (shouldUpdateFromLocalStorage)
    return {
      selected: localStorageState?.savedTimeZone,
      lastActive: localStorageState?.lastTimeZone,
    };
}

const TIME_ZONE_KEY = "user_timezone";

function getLocalStorageState():
  | {
      savedTimeZone?: string;
      lastTimeZone?: string;
    }
  | undefined {
  try {
    const value = localStorage.getItem(TIME_ZONE_KEY);
    localStorage.removeItem(TIME_ZONE_KEY);

    return value ? JSON.parse(value) : undefined;
  } catch {}
}
