import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedFailuresChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],

    title: t("siteSpeedFailuresChart.title"),
    reportTemplateCodes: ["container_execution_failures", "site_speed_pages"],
    description: t("siteSpeedFailuresChart.description"),
  })();
}
