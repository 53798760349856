import React from "react";
import { useParams } from "react-router-dom";
import {
  CrawlType,
  CrawlUrlAggregateDimension,
  ModuleCode,
  useGetDataExplorerMetricsQuery,
} from "../../graphql";
import {
  DataExplorerDimension,
  Metric,
  DataExplorerTableConfig,
} from "../types";
import { ApolloError, useTranslation } from "@lumar/shared";
import { dataExplorerModuleConfigs } from "./dataExplorerModuleConfigs";
import { insertIf } from "../../_common/insertIf";
import { doesMetricSupportAnyAggregation } from "../doesMetricSupportAggregation";

export interface DataExplorerMetricsData {
  aggregableMetrics: Metric[];
  secondaryDimensions: DataExplorerDimension[];
  primaryDimensions: DataExplorerDimension[];
  tableConfigKey: string;
  defaultTableConfig: DataExplorerTableConfig;
  crawlTypes: CrawlType[];
  isCrawlIncomplete: boolean;
}

type Result =
  | { loading: true; error?: undefined; data?: undefined }
  | { loading: false; error: string; data?: undefined }
  | { loading: false; error?: undefined; data: DataExplorerMetricsData | null };

export function useDataExplorerMetrics(): Result {
  const { crawlId } = useParams<{
    crawlId: string;
  }>();
  const additionalDimensions = useAdditionalDimensions();

  const {
    loading,
    error,
    data: queryData,
  } = useGetDataExplorerMetricsQuery({
    variables: {
      crawlId,
    },
  });

  const data = React.useMemo<DataExplorerMetricsData | null>(() => {
    const moduleCode =
      queryData?.report?.project.moduleCode ?? ModuleCode.Basic;
    const config = dataExplorerModuleConfigs.find(
      (cfg) => cfg.moduleCode === moduleCode,
    );

    if (!config) {
      return null;
    }

    const exclusiveDimensionsCodes = config.exclusiveDimensionsCodes ?? [];
    const excludedDimensionsCodes = config.excludedDimensionsCodes ?? [];
    const primaryDimensionsCodes = config.primaryDimensionsCodes ?? [];
    const tableTypeVersion =
      queryData?.report?.reportTemplate.tableTypeVersion || 0;

    const compatibleMetrics = (
      queryData?.report?.reportTemplate.datasource.metrics || []
    ).filter(
      (metric) =>
        tableTypeVersion >=
        (metric?.metadata?.availableFromTableTypeVersion || 0),
    );

    const moduleMetrics = compatibleMetrics.filter((m) =>
      m.supportedModules?.includes(config.moduleCode),
    );

    const crawlUrlAggregateDimensionsCodes = Object.values(
      CrawlUrlAggregateDimension,
    );

    const moduleDimensionsCodes = [
      ...crawlUrlAggregateDimensionsCodes.filter((dimensionCode) =>
        moduleMetrics.find((module) => module.code === dimensionCode),
      ),
    ];

    const additionalDimensionsCodes = [
      "segment" as const,
      ...insertIf(
        moduleDimensionsCodes.find((code) => code === "path0"),
        "path" as const,
      ),
      ...insertIf(
        moduleDimensionsCodes.find((code) => code === "breadcrumb01"),
        "breadcrumb" as const,
      ),
    ];

    const allDimensionsCodes = [
      ...moduleDimensionsCodes,
      ...additionalDimensionsCodes,
    ];

    const availableDimensionsCodes = allDimensionsCodes
      .filter((dimensionCode) => {
        if (exclusiveDimensionsCodes.length > 0) {
          return exclusiveDimensionsCodes.find(
            (exclusiveDimensionCode) =>
              exclusiveDimensionCode === dimensionCode,
          );
        }
        return true;
      })
      .filter(
        (dimensionCode) =>
          !excludedDimensionsCodes.find(
            (excludedDimensionCode) => excludedDimensionCode === dimensionCode,
          ),
      );

    const availableDimensions: DataExplorerDimension[] =
      availableDimensionsCodes.map((dimensionCode) => {
        const metric =
          moduleMetrics.find((module) => module.code === dimensionCode) ??
          additionalDimensions.find(
            (additionalDimension) => additionalDimension.code === dimensionCode,
          );
        return {
          code: dimensionCode,
          name: metric?.name || dimensionCode,
        };
      });

    const secondaryDimensions = availableDimensions.filter(
      (availableDimension) =>
        !primaryDimensionsCodes.find(
          (primaryDimensionCode) =>
            primaryDimensionCode === availableDimension.code,
        ),
    );

    const primaryDimensions = availableDimensions.filter((availableDimension) =>
      primaryDimensionsCodes.find(
        (primaryDimensionCode) =>
          primaryDimensionCode === availableDimension.code,
      ),
    );

    const aggregableMetrics = moduleMetrics.filter((metric) =>
      doesMetricSupportAnyAggregation(metric.code),
    );

    const crawlTypes = queryData?.report?.crawl.crawlTypes || [];
    const isCrawlIncomplete = Boolean(queryData?.report?.crawl?.incomplete);

    return {
      crawlTypes,
      aggregableMetrics,
      secondaryDimensions,
      primaryDimensions,
      tableConfigKey: getModuleTableConfigLocalStorageKey(moduleCode),
      defaultTableConfig: config.defaultTableConfig,
      isCrawlIncomplete,
    };
  }, [
    additionalDimensions,
    queryData?.report?.crawl.crawlTypes,
    queryData?.report?.crawl?.incomplete,
    queryData?.report?.project.moduleCode,
    queryData?.report?.reportTemplate.datasource.metrics,
    queryData?.report?.reportTemplate.tableTypeVersion,
  ]);

  if (loading) {
    return { loading };
  }

  if (error) {
    return {
      loading: false,
      error: getErrorMessage(error),
    };
  }

  return {
    loading: false,
    data,
  };
}

function getErrorMessage(error: ApolloError): string {
  if (error.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error.protocolErrors?.[0]) return error.protocolErrors[0].message;
  return error.message;
}

function useAdditionalDimensions(): DataExplorerDimension[] {
  const { t } = useTranslation("dataExplorer");
  return [
    { name: t("dimensions.segment"), code: "segment" as const },
    { name: t("dimensions.path"), code: "path" as const },
    { name: t("dimensions.breadcrumb"), code: "breadcrumb" as const },
  ];
}

// This function maintains compatibility with keys definied in the past.
function getModuleTableConfigLocalStorageKey(moduleCode: ModuleCode): string {
  if (moduleCode === ModuleCode.Seo) {
    return `data-explorer-table-config`;
  }

  if (moduleCode === ModuleCode.Accessibility) {
    return `data-explorer-table-config-a11y`;
  }

  return `data-explorer-table-config-${moduleCode}`;
}
