import { useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const LINE_HEIGHT = 20;
const LINE_COUNT = 2;

const useStyles = makeStyles((theme) => ({
  truncatedClosed: {
    display: "-webkit-box",
    "-webkit-line-clamp": LINE_COUNT,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    marginBottom: theme.spacing(0.5),
  },
  truncatedOpen: {
    marginBottom: theme.spacing(0.5),
  },
  conatiner: {
    display: "flex",
    justifyContent: "center",
  },
  expandCollapse: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

export function TruncateText({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("common");

  const [truncated, setTruncated] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const observerRef = React.useRef<ResizeObserver | undefined>();
  React.useEffect(() => () => observerRef.current?.disconnect(), []);

  function setUpObserver(element: HTMLParagraphElement | null): void {
    observerRef.current?.disconnect();
    observerRef.current = undefined;

    if (element) {
      observerRef.current = new ResizeObserver(() => {
        const truncated = element.scrollHeight > LINE_COUNT * LINE_HEIGHT;
        // Note: Fixes the "ResizeObserver loop limit exceeded" error on load.
        setTimeout(() => {
          setTruncated(truncated);
        }, 0);
      });
      observerRef.current.observe(element);
    }
  }

  return (
    <>
      <p
        className={clsx(className, {
          [classes.truncatedClosed]: truncated && !open,
          [classes.truncatedOpen]: truncated && open,
        })}
        ref={(el) => setUpObserver(el)}
      >
        {children}
      </p>
      {truncated && (
        <div className={classes.conatiner}>
          <a
            onClick={() => setOpen((open) => !open)}
            className={classes.expandCollapse}
          >
            {open ? t("collapse") : t("expand")}
          </a>
        </div>
      )}
    </>
  );
}
