import { CreateProjectInput, Project } from "../../../../../graphql";
import { CookiesSettings } from "../types";

type QueryData = Pick<Project, "rendererCookies">;

export function formatCookiesSettingsFrom(
  data?: QueryData | null,
): CookiesSettings {
  return {
    cookies:
      data?.rendererCookies?.map((x) => ({
        key: x.name,
        value: x.value,
      })) ?? [],
  };
}

export function formatCookiesSettingsTo(
  formValues: CookiesSettings,
): Pick<CreateProjectInput, "rendererCookies"> {
  return {
    rendererCookies: formValues.cookies.map((x) => ({
      name: x.key,
      value: x.value,
    })),
  };
}
