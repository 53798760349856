var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useGetAllHealthScoresLazyQuery, useGetAllHealthScoresSegmentLazyQuery, } from "../../../../graphql";
import { isString, snakeCase, uniq } from "lodash";
import Papa from "papaparse";
export function useAllHealthScoresExport() {
    const [fetch] = useGetAllHealthScoresLazyQuery({
        fetchPolicy: "cache-first",
    });
    const [fetchWithSegment] = useGetAllHealthScoresSegmentLazyQuery({
        fetchPolicy: "cache-first",
    });
    function fetchData(crawlId, segmentId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (segmentId) {
                return (_a = (yield fetchAllHealthScoresWithSegments(fetchWithSegment, crawlId, segmentId))) === null || _a === void 0 ? void 0 : _a.getHealthScoreTrendForCrawlSegment;
            }
            return (_b = (yield fetchAllHealthScores(fetch, crawlId))) === null || _b === void 0 ? void 0 : _b.getHealthScoreTrendForCrawl;
        });
    }
    return (props) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const data = yield fetchData(props.crawlId, (_a = props.segment) === null || _a === void 0 ? void 0 : _a.id);
        data && exportCSV(data, props);
    });
}
function fetchAllHealthScores(fetch, crawlId) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield fetch({ variables: { crawlId } });
        yield (function fetchAllPages(data) {
            return __awaiter(this, void 0, void 0, function* () {
                const pageInfo = data === null || data === void 0 ? void 0 : data.getHealthScoreTrendForCrawl.pageInfo;
                if ((pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) && pageInfo.endCursor) {
                    const fetchMoreResult = yield result.fetchMore({
                        variables: { cursor: pageInfo.endCursor },
                    });
                    return fetchAllPages(fetchMoreResult.data);
                }
                return data;
            });
        })(result.data);
        return (_a = result.observable.getCurrentResult()) === null || _a === void 0 ? void 0 : _a.data;
    });
}
function fetchAllHealthScoresWithSegments(fetch, crawlId, segmentId) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield fetch({ variables: { crawlId, segmentId } });
        yield (function fetchAllPages(data) {
            return __awaiter(this, void 0, void 0, function* () {
                const pageInfo = data === null || data === void 0 ? void 0 : data.getHealthScoreTrendForCrawlSegment.pageInfo;
                if ((pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage) && pageInfo.endCursor) {
                    const fetchMoreResult = yield result.fetchMore({
                        variables: { cursor: pageInfo.endCursor },
                    });
                    return fetchAllPages(fetchMoreResult.data);
                }
                return data;
            });
        })(result.data);
        return (_a = result.observable.getCurrentResult()) === null || _a === void 0 ? void 0 : _a.data;
    });
}
function exportCSV(data, props) {
    var _a;
    const filteredData = props.categories.reduce((results, category) => {
        var _a, _b;
        const healthScores = (_b = (_a = data.edges.find((x) => x.node.reportCategoryCode === category.code)) === null || _a === void 0 ? void 0 : _a.node) === null || _b === void 0 ? void 0 : _b.healthScores;
        return healthScores
            ? [...results, { name: category.name, healthScores }]
            : results;
    }, []);
    // eslint-disable-next-line fp/no-mutating-methods
    const sortedDates = uniq(filteredData.flatMap((x) => x.healthScores).map((x) => x === null || x === void 0 ? void 0 : x.createdAt))
        .filter(isString)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    const exportData = [
        ["DateTime", ...filteredData.map((x) => x.name)],
        ...sortedDates.map((date) => [
            date,
            ...filteredData.map((x) => {
                var _a, _b;
                return getFormattedHealthScore((_b = (_a = x.healthScores) === null || _a === void 0 ? void 0 : _a.find((y) => y.createdAt === date)) === null || _b === void 0 ? void 0 : _b.healthScore);
            }),
        ]),
    ];
    const csvData = new Blob(["\ufeff" + Papa.unparse(exportData)], {
        type: "text/csv",
    });
    const csvUrl = URL.createObjectURL(csvData);
    const exportFileName = getFileName(props.projectId, props.crawlId, props.title, (_a = props.segment) === null || _a === void 0 ? void 0 : _a.name) + ".csv";
    const link = document.createElement("a");
    link.setAttribute("href", csvUrl);
    link.setAttribute("download", exportFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
function getFormattedHealthScore(value) {
    return value !== undefined ? Math.floor(value * 100) : undefined;
}
function getFileName(projectId, crawlId, title, segmentName) {
    const fileName = `${projectId}_${crawlId}_${snakeCase(title)}`;
    return segmentName ? `${fileName}_${snakeCase(segmentName)}` : fileName;
}
