import {
  CrawlLevel,
  CrawlStatus,
  CrawlType,
  Maybe,
  PauseReason,
  RunningCrawlQuery,
} from "../../../graphql";
import { activeStatuses } from "../../constants";
import { RunningCrawl } from "../../useProjectPoller";

export const isCrawlRunning = (crawl?: RunningCrawl): boolean => {
  return crawl !== undefined && activeStatuses.includes(crawl.status);
};

export const isQeued = (crawl?: RunningCrawl): boolean => {
  if (!crawl) return false;
  return (
    crawl.status === CrawlStatus.Queued || crawl.status === CrawlStatus.Queuing
  );
};

export const isCrawling = (crawl?: RunningCrawl): boolean => {
  return crawl?.status === CrawlStatus.Crawling;
};

export const isFinalising = (crawl?: RunningCrawl): boolean => {
  return crawl?.status === CrawlStatus.Finalizing;
};

export const isPaused = (crawl?: RunningCrawl): boolean => {
  return crawl?.status === CrawlStatus.Paused;
};

export const isLevelPausedWithoutLevelUpdate = (
  project?: RunningCrawlQuery,
  crawl?: RunningCrawl,
): boolean => {
  const isLevelPaused = crawl?.pauseReason === PauseReason.LevelLimit;
  const projectLimit = project?.getProject?.limitLevelsMax;
  const highestLevel = getHighestLevel(crawl?.crawlStat);
  return isLevelPaused && projectLimit === highestLevel;
};

type CrawlLevelType = Maybe<{
  crawlLevels: Array<Pick<CrawlLevel, "level" | "stepsProcessed">>;
}>;

export const getHighestLevel = (progress?: CrawlLevelType | string): number => {
  if (typeof progress === "string") {
    const levels = progress ? Object.keys(JSON.parse(progress)) : null;
    const valids = levels ? levels?.filter((l) => l !== "") : null;

    if (valids) {
      const level = Number(valids[valids.length - 1]);
      if (level) {
        return level;
      }
    }

    return 0;
  }

  const levels = progress?.crawlLevels
    ?.map((p) => p.level)
    .filter((l): l is number => l !== undefined);

  return levels ? Math.max(...levels) : 0;
};

export const getProjectId = (project?: RunningCrawlQuery): string => {
  return project?.getProject?.id;
};

export const hasWeb = (crawl?: RunningCrawl): boolean => {
  return crawl?.sources?.includes(CrawlType.Web) ?? false;
};
