import { FailSolid, PassSolid, Typography, WarningSolid } from "@lumar/shared";
import { makeStyles, SvgIcon } from "@material-ui/core";
import React from "react";
import { CustomSkeleton } from "../_common/CustomSkeleton";
import { Metrics } from "./data/types";
import { MetricsValuePresenter } from "./metrics-value-presenter/MetricsValuePresenter";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3.5),
  },
  mainPanel: {
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(1.875, 2.625, 1.875, 2.625),
    display: "flex",
    justifyContent: "space-between",
    columnGap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      rowGap: theme.spacing(3),
    },
  },
  grid: {
    display: "grid",
    columnGap: theme.spacing(2.375),
    rowGap: theme.spacing(1.375),
    gridTemplateColumns: "auto minmax(50%, 1fr)",
    minWidth: 330,
    flexGrow: 10000,
  },
  headerContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  summaryName: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(10.72),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 500,
    textTransform: "uppercase",
  },
  detailIcon: {
    marginRight: theme.spacing(1.5),
  },
  detailName: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    color: theme.palette.grey[500],
    textTransform: "uppercase",
    letterSpacing: "0.015rem",
  },
  metricValue: {
    overflow: "hidden",
    wordBreak: "break-word",
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(16),
  },
  childContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    margin: theme.spacing(1.5, 0, 1.5, 0),
    width: "100%",
  },
  childPalne: {
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "6px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    flex: 1,
    padding: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    position: "relative",
    minHeight: 74,
  },
  valueContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(0.5),
    width: "100%",
  },
  childValue: {
    fontSize: theme.typography.pxToRem(19),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 600,
    color: theme.palette.grey[900],
    letterSpacing: "0.015rem",
    minWidth: 100,
  },
  indicator: {
    fontSize: theme.typography.pxToRem(20),
    position: "absolute",
    right: theme.spacing(1.125),
    top: theme.spacing(1.125),
  },
  indicatorPass: {
    color: theme.palette.green[500],
  },
  indicatorWarning: {
    color: theme.palette.yellow[500],
  },
  indicatorFail: {
    color: theme.palette.red[600],
  },
}));

export type ResourceDetailSummaryConfig = {
  summary: { code: string; name?: string; value?: React.ReactChild }[];
  header?: React.ReactChild;
  cardDetails: {
    code: string;
    name?: string;
    value?: React.ReactChild;
    icon?: typeof SvgIcon;
    indicator?: Indicator;
  }[];
};

export type Indicator = "success" | "warning" | "error";

export function ResourceDetailSummary({
  metrics,
  config,
}: {
  metrics: Metrics;
  config: ResourceDetailSummaryConfig;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainPanel} data-testid="resource-detail-summary">
        <div className={classes.grid}>
          {config.summary.map(({ code, name, value }, idx) => (
            <React.Fragment key={idx}>
              <Typography className={classes.summaryName}>
                {name || metrics[code]?.name || code}
              </Typography>
              <MetricsValuePresenter
                metrics={metrics}
                code={code}
                value={value}
                className={classes.metricValue}
                componentProps={{
                  showCopy: true,
                }}
              />
            </React.Fragment>
          ))}
        </div>
        <div className={classes.headerContainer}>{config.header}</div>
      </div>
      <div className={classes.childContainer}>
        {config.cardDetails.map((detail) => (
          <div
            className={classes.childPalne}
            key={detail.code}
            data-testid="resource-detail-card-detail"
          >
            {detail.icon && <detail.icon className={classes.detailIcon} />}
            <div className={classes.valueContainer}>
              <Typography className={classes.detailName}>
                {detail.name || metrics[detail.code]?.name || detail.code}
              </Typography>
              <MetricsValuePresenter
                metrics={metrics}
                code={detail.code}
                value={detail.value}
                className={classes.childValue}
              />
              {detail.indicator === "success" && (
                <PassSolid
                  className={clsx(classes.indicator, classes.indicatorPass)}
                />
              )}
              {detail.indicator === "warning" && (
                <WarningSolid
                  className={clsx(classes.indicator, classes.indicatorWarning)}
                />
              )}
              {detail.indicator === "error" && (
                <FailSolid
                  className={clsx(classes.indicator, classes.indicatorFail)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function ResourceDetailSummarySkeleton(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomSkeleton
        height={128}
        variant="rect"
        background="white"
        animation="wave"
      />
      <div className={classes.childContainer}>
        {Array(6)
          .fill(null)
          .map((_, idx) => {
            return (
              <CustomSkeleton
                key={idx}
                variant="rect"
                background="white"
                animation="wave"
                height={71}
                style={{ flex: 1 }}
              />
            );
          })}
      </div>
      <CustomSkeleton
        height={300}
        variant="rect"
        background="white"
        animation="wave"
      />
    </div>
  );
}
