/* eslint-disable fp/no-this */
import React, { RefObject } from "react";
import { renderToString } from "react-dom/server";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsVenn from "highcharts/modules/venn";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";
import HighchartsAccesibility from "highcharts/modules/accessibility";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
import { makeStyles, useTheme } from "@material-ui/core";

import { useGenericParams } from "../../routing/useGenericParams";
import { snakeCase } from "lodash";
import { useBrand, useDateFormatter } from "@lumar/shared";

// Needed in order to intialise the additional highcharts modules
highchartsVenn(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsAccesibility(Highcharts);
HighchartsPatternFill(Highcharts);

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    "& .highcharts-empty-series": {
      fill: theme.palette.grey[200],
    },
  },
}));

interface Props {
  options: Highcharts.Options;
  title?: string;
  projectName?: string;
  icon?: string;
  crawlCreatedAt?: string | null;
  segmentName?: string;
  primaryDomain?: string;
}

export type ChartRef = {
  chart: Highcharts.Chart;
  container: RefObject<HTMLDivElement>;
} | null;

function getFileName(
  projectId?: string,
  crawlId?: string,
  title?: string,
  primaryDomain?: string,
  segmentName?: string,
  icon?: string,
): string {
  if (!title || !primaryDomain) return "chart-download";
  // Note: here we are removing `http(s)://` and trailing `/` from domain because we cannot use `:` or `/` in the file name
  const formattedDomain = primaryDomain.replace(/(^https?:\/\/|\/$)/g, "");

  const fileName = [projectId, crawlId, icon, snakeCase(title), formattedDomain]
    .filter(Boolean)
    .join("_");

  return segmentName ? `${fileName}_${snakeCase(segmentName)}` : fileName;
}

export const HighchartsChart = React.forwardRef<ChartRef, Props>(function Chart(
  {
    options: optionsParam,
    title,
    icon,
    segmentName,
    projectName,
    primaryDomain,
    crawlCreatedAt,
  },
  ref,
): JSX.Element | null {
  const classes = useStyles();
  const theme = useTheme();
  const formatDate = useDateFormatter();
  const { projectId, crawlId } = useGenericParams();
  const colors = theme.palette.chart as unknown as string[];
  const brand = useBrand();

  const filename = getFileName(
    projectId,
    crawlId,
    title,
    primaryDomain,
    segmentName,
    icon,
  );

  Highcharts.setOptions({
    exporting: {
      enabled: false,
      chartOptions: {
        chart: {
          events: {
            load: function () {
              this.renderer
                .image(
                  brand.chartExportLogo.encodedImage,
                  brand.chartExportLogo.x,
                  this.chartHeight - brand.chartExportLogo.y,
                  brand.chartExportLogo.width,
                  brand.chartExportLogo.height,
                )
                .add();
            },
          },
          spacing: [20, 20, 20, 20],
        },
      },
    },
    chart: {
      shadow: false,
      spacing: [0, 0, 0, 0],
      style: {
        fontSize: "1em",
        fontFamily: theme.typography.fontFamily,
      },
    },
    plotOptions: {
      area: {
        fillOpacity: 0.5,
      },
    },
    legend: {
      enabled: true,
      useHTML: true,
      itemStyle: {
        fontSize: "13px",
        fontWeight: "400",
        color: theme.palette.text.primary,
      },
    },
    colors,
    credits: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      lineColor: theme.palette.grey[100],
      gridLineColor: theme.palette.grey[100],
      dateTimeLabelFormats: {
        month: "%b %e",
        day: "%b %e",
        hour: "%b %e",
        minute: "%b %e",
        week: "%b %e",
        millisecond: "%b %e",
        second: "%b %e",
      },
      labels: {
        style: {
          fontSize: "13px",
          fontWeight: "400",
          color: theme.palette.text.primary,
        },
      },
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      lineColor: theme.palette.grey[100],
      gridLineColor: theme.palette.grey[100],
      labels: {
        style: {
          fontSize: "13px",
          fontWeight: "400",
          color: theme.palette.text.primary,
        },
      },
    },
    tooltip: {
      useHTML: true,
      shape: "rect",
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      style: {
        fontSize: "1em",
        lineHeight: "1.5em",
        color: theme.palette.text.primary,
      },
    },
  });

  const options: Highcharts.Options = {
    ...optionsParam,
    exporting: {
      ...optionsParam.exporting,
      filename,
      chartOptions: {
        ...optionsParam.exporting?.chartOptions,
        title: {
          text: segmentName ? `${title} (${segmentName})` : `${title}`,
        },
        subtitle: {
          text: renderToString(
            <div>
              <p style={{ fontSize: 10 }}>
                {projectName} ({primaryDomain})
              </p>
              {crawlCreatedAt ? (
                <span style={{ fontSize: 10 }}>{`, ${formatDate(
                  new Date(crawlCreatedAt),
                  { dateStyle: "medium", timeStyle: "short" },
                )}`}</span>
              ) : null}
            </div>,
          ),
          useHTML: true,
        },
      },
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        className: classes.container,
      }}
      options={options}
      ref={ref}
    />
  );
});
