import { useTranslation } from "@lumar/shared";
import { useMemo } from "react";
import { DataExplorerAggregationCalculation } from "../types";

export function useAggregationCalculations(): {
  id: DataExplorerAggregationCalculation;
  name: string;
  info: string;
}[] {
  const { t } = useTranslation("dataExplorer");
  return useMemo(() => {
    return [
      {
        id: DataExplorerAggregationCalculation.Avg,
        name: t("aggregationCalculations.average.name"),
        info: t("aggregationCalculations.average.info"),
      },
      {
        id: DataExplorerAggregationCalculation.Sum,
        name: t("aggregationCalculations.sum.name"),
        info: t("aggregationCalculations.sum.info"),
      },
      {
        id: DataExplorerAggregationCalculation.Count,
        name: t("aggregationCalculations.count.name"),
        info: t("aggregationCalculations.count.info"),
      },
    ];
  }, [t]);
}
