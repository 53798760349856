import { useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function StatusCodeSimplePresenter({
  value,
  metrics,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("report");
  const statusCode = Number(value ?? 0);

  const isS3Upload = metrics["url"].value?.startsWith("s3://");

  function getValue(): string | number {
    if (statusCode > 0) return statusCode;
    if (isS3Upload) return t("manualUpload");
    return t("failed");
  }

  return <>{getValue()}</>;
}
