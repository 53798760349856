import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAccountGuardQuery } from "../../graphql";
export function useAccountGuard(config = {}) {
    const { skip = false, redirectLocation = "/login" } = config;
    const history = useHistory();
    const { accountId } = useParams();
    if (!accountId && !skip) {
        console.error("`useAccountGuard` has been called in a route without `accountId` param!");
    }
    const { data, loading } = useAccountGuardQuery({
        variables: { accountId },
        fetchPolicy: "cache-first",
        errorPolicy: "ignore",
        skip: !accountId || skip,
    });
    React.useEffect(() => {
        var _a;
        if (!accountId || skip) {
            return;
        }
        const accountNotFound = !((_a = data === null || data === void 0 ? void 0 : data.getAccount) === null || _a === void 0 ? void 0 : _a.id);
        if (!loading && accountNotFound) {
            history.replace(redirectLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading]);
}
