import { AccountSubscriptionPlan } from "../../graphql";

export const isPlanLight = (
  code: AccountSubscriptionPlan["code"] | undefined,
): boolean => {
  return code === "light" || code === "light-annual";
};

export const isPlanLightPlus = (
  code: AccountSubscriptionPlan["code"] | undefined,
): boolean => {
  return code === "lightplus" || code === "lightplus-annual";
};
