import { Project, UpdateProjectInput } from "../../../../../graphql";
import { UrlScopeSettings } from "../types";

type QueryData = Pick<
  Project,
  "includeUrlPatterns" | "excludeUrlPatterns" | "urlSampling"
>;

export function formatUrlScopeSettingsFrom(
  data?: QueryData | null,
): UrlScopeSettings {
  return {
    includedUrls: data?.includeUrlPatterns ?? [],
    excludedUrls: data?.excludeUrlPatterns ?? [],
    groups:
      data?.urlSampling?.map((sampling) => ({
        name: sampling.name,
        urlMatch: sampling.regexPattern,
        crawlSample: sampling.samplePercentage,
      })) ?? [],
  };
}

export function formatUrlScopeSettingsTo(
  formValues: UrlScopeSettings,
): Pick<
  UpdateProjectInput,
  "includeUrlPatterns" | "excludeUrlPatterns" | "urlSampling"
> {
  return {
    includeUrlPatterns: formValues.includedUrls,
    excludeUrlPatterns: formValues.excludedUrls,
    urlSampling: formValues.groups.map((group) => ({
      name: group.name,
      regexPattern: group.urlMatch,
      samplePercentage: group.crawlSample,
    })),
  };
}
