import { ResourceDetailData } from "../../../data/types";
import { ChangedMetricsTable } from "../../../metrics-table/ChangedMetricsTable";
import { useExportName } from "../../useExportName";

export function AssetResourceChangedMetrics({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const getExportName = useExportName({
    crawlCreatedAt: data.crawl.createdAt,
    suffix: "changed_metrics",
  });

  return (
    <ChangedMetricsTable
      metrics={data.metrics}
      sourceTemplate={data.sourceTemplate}
      currentCrawlDate={data.crawl.createdAt}
      exportFileName={getExportName}
      filter={(metric) => shouldShowMetric(metric.code)}
    />
  );
}

const metricsToExclude = ["crawlDatetime", "rawHeader"];

export function shouldShowMetric(code: string): boolean {
  return !metricsToExclude.includes(code) && !code.endsWith("ChangedFrom");
}
