/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityCategoriesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityCategoriesChart.title"),
    description: () => t("accessibilityCategoriesChart.description"),
    reportTemplateCodesOrder: [
      "aria_a",
      "color",
      "forms",
      "keyboard_a",
      "language",
      "name_role_value_a",
      "parsing_a",
      "structure",
      "tables_a",
      "text_alternatives_a",
      "time_and_media_a",
      "semantics",
    ],
    reportStatFilter: (report) => {
      return [
        "aria_a",
        "color",
        "forms",
        "keyboard_a",
        "language",
        "name_role_value_a",
        "parsing_a",
        "structure",
        "tables_a",
        "text_alternatives_a",
        "time_and_media_a",
        "semantics",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "a11y-categories-chart",
  };
}
