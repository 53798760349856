import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityFormsAAChart } from "../../../../../_common/charts/config/getAccessibilityFormsAAChart";
import { getAccessibilityFormsAATrend } from "../../../../../_common/charts/config/getAccessibilityFormsAATrend";

export const generateAccessibilityFormsAACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityFormsAAChart,
      getAccessibilityFormsAATrend,
    ],
  };
