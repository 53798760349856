import {
  CreateProjectInput,
  Maybe,
  ModuleCode,
  Project,
  UserAgent,
} from "../../../../../graphql";
import { UserAgentSettings } from "../types";

type QueryData = Pick<
  Project,
  | "userAgentString"
  | "userAgentToken"
  | "viewportWidth"
  | "viewportHeight"
  | "userAgentIsMobile"
> & {
  userAgent?: Maybe<Pick<UserAgent, "code">>;
  moduleCode: ModuleCode;
};

export function formatUserAgentSettingsFrom(
  data: QueryData | undefined | null,
  moduleCode: ModuleCode | undefined,
): UserAgentSettings {
  const isCustom = data?.userAgentString || data?.userAgentToken;
  // FIXME: Redundant logic when we introduce separate project type creation views
  const defaultUserAgent =
    (moduleCode && moduleDefaultUserAgents[moduleCode]) ||
    "googlebot-smartphone";

  return {
    code: isCustom ? "Custom" : (data?.userAgent?.code ?? defaultUserAgent),
    userAgentIsMobile: data?.userAgentIsMobile ?? false,
    string: data?.userAgentString ?? "",
    token: data?.userAgentToken ?? "",
    viewportWidth: data?.viewportWidth ?? undefined,
    viewportHeight: data?.viewportHeight ?? undefined,
  };
}

export function formatUserAgentSettingsTo(
  formValues: UserAgentSettings,
  skipViewport?: true,
): Pick<
  CreateProjectInput,
  | "userAgentCode"
  | "userAgentString"
  | "userAgentToken"
  | "viewportWidth"
  | "viewportHeight"
  | "userAgentIsMobile"
> {
  const isCustom = formValues.code === "Custom";

  return {
    userAgentCode: isCustom ? undefined : formValues.code,
    userAgentString: isCustom ? formValues.string : null,
    userAgentToken: isCustom ? formValues.token : null,
    userAgentIsMobile: isCustom ? formValues.userAgentIsMobile : null,
    ...(!skipViewport
      ? {
          viewportWidth: formatViewport(formValues.viewportWidth),
          viewportHeight: formatViewport(formValues.viewportHeight),
        }
      : {}),
  };
}

function formatViewport(value: number | string | undefined): number | null {
  if (typeof value === "string") {
    const result = parseInt(value);
    return Number.isNaN(result) ? null : result;
  }

  return value ?? null;
}

const moduleDefaultUserAgents: Partial<Record<ModuleCode, string>> = {
  [ModuleCode.Seo]: "googlebot-smartphone",
  [ModuleCode.Accessibility]: "chrome-a11y",
  [ModuleCode.SiteSpeed]: "lighthouse-mobile",
};
