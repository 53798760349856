import React from "react";
import { Button, Grid, Typography, Tooltip, Paper } from "@material-ui/core";
import { FastField, FieldArray } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { AddCircle, Delete } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

import { CallbackHeader, FormValues } from "../data/types";
import { ToggleIconButton } from "@lumar/shared";
import { SettingControlFactory } from "../useAdvancedControls";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";

export const getApiCallbackControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.apiCallback.title"),
  path: "apiCallback",
  // eslint-disable-next-line react/display-name
  control: () => <ApiCallback />,
  testId: "crawl-settings-advanced-api-callback",
  activeValues: ["url"],
  textForSearch: createTextForSearch([
    t("settings.apiCallback.title"),
    t("settings.apiCallback.description"),
    t("settings.apiCallback.example"),
    t("settings.apiCallback.payloadLabel"),
    t("settings.apiCallback.payload"),
    t("settings.apiCallback.callbackPlaceholder"),
    t("settings.apiCallback.headerDescription"),
    t("settings.apiCallback.keyPlaceholder"),
    t("settings.apiCallback.valuePlaceholder"),
  ]),
  fieldNames: [
    ["url", t("settings.apiCallback.callbackUrlField")],
    ["headers", t("settings.apiCallback.headersField")],
    ["key", t("settings.apiCallback.keyField")],
    ["value", t("settings.apiCallback.valueField")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
  payload: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
  },
  button: {
    width: "fit-content",
  },
  input: {
    width: "100%",
  },
  grid: {
    marginTop: theme.spacing(0),
    alignItems: "flex-start",
  },
  removeButton: {
    height: 49,
  },
}));

export const ApiCallback = React.memo(ApiCallbackInner);

function ApiCallbackInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const createNewHeader = (): CallbackHeader => ({
    key: "",
    value: "",
  });

  return (
    <>
      <Typography>{t("settings.apiCallback.description")}</Typography>
      <code className={clsx(classes.example, classes.indent)}>
        {t("settings.apiCallback.example")}
      </code>
      <Typography className={clsx(classes.label, classes.indent)}>
        {t("settings.apiCallback.payloadLabel")}
      </Typography>
      <Paper className={clsx(classes.payload, classes.indent)} elevation={0}>
        <code>{t("settings.apiCallback.payload")}</code>
      </Paper>
      <FastField
        name="report.apiCallback.url"
        component={TextFieldWithOnBlurUpdate}
        hiddenLabel
        variant="outlined"
        placeholder={t("settings.apiCallback.callbackPlaceholder")}
        className={clsx(classes.input, classes.indent)}
        data-testid="api-callback-url"
      />

      <Typography variant="caption" className={classes.indent}>
        {t("settings.apiCallback.headerDescription")}
      </Typography>
      <FieldArray
        name="report.apiCallback.headers"
        render={({ form, push, remove }) => {
          const values = (form.values as FormValues).report.apiCallback.headers;
          return (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => push(createNewHeader())}
                disabled={form.isSubmitting}
                startIcon={<AddCircle />}
                className={clsx(classes.button, classes.indent)}
                data-testid="api-callback-header-add"
              >
                {t("settings.apiCallback.addLabel")}
              </Button>
              {values.map((_: unknown, idx: number) => (
                <Paper key={idx} style={{ padding: 8, marginTop: 8 }}>
                  <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`report.apiCallback.headers[${idx}].key`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t("settings.apiCallback.keyPlaceholder")}
                        className={classes.input}
                        data-testid="api-callback-header-key"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FastField
                        name={`report.apiCallback.headers[${idx}].value`}
                        component={TextFieldWithOnBlurUpdate}
                        hiddenLabel
                        variant="outlined"
                        placeholder={t("settings.apiCallback.valuePlaceholder")}
                        className={classes.input}
                        data-testid="api-callback-header-value"
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Tooltip
                        title={t("settings.apiCallback.removeTooltip")}
                        placement="top"
                        arrow
                      >
                        <ToggleIconButton
                          size="large"
                          variant="outlined"
                          colorVariant="red"
                          onClick={() => remove(idx)}
                          disabled={form.isSubmitting}
                          data-testid="api-callback-header-remove"
                        >
                          <Delete />
                        </ToggleIconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          );
        }}
      />
    </>
  );
}
