import { useTranslation, Typography, useSession } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import React from "react";
import { Cookies } from "../../settings/spider-settings/Cookies";
import { CustomScripts } from "./CustomScripts";
import { UserAgent } from "../../settings/spider-settings/UserAgent";
import { DomainContext } from "../Domain";
import { WcagLevelAndVersionSettings } from "./WcagLevelAndVersionSettings";
import { SettingsAccordion } from "../SettingsAccordion";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(2, 4),
  },
  description: {
    margin: theme.spacing(1, 0, 2.625, 0),
  },
  content: {
    width: "100%",
  },
}));

export const AccessibilitySettings = React.memo(AccessibilitySettingsInner);

function AccessibilitySettingsInner(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const { hasFeatureFlagEnabled } = useSession();

  const { userAgents, module, userAgentSuffix } =
    React.useContext(DomainContext);

  return (
    <div className={classes.root}>
      <Typography variant="h2SemiBold">{t("accessibilitySettings")}</Typography>
      <Typography variant="body1" className={classes.description}>
        {t("accessibilityDescription")}
      </Typography>

      <div className={classes.content}>
        {hasFeatureFlagEnabled("wcag-level-option") && (
          <SettingsAccordion title={t("wcagLevelAndVersion")}>
            <FastField
              name="wcagLevelAndVersion"
              component={WcagLevelAndVersionSettings}
            />
          </SettingsAccordion>
        )}

        <SettingsAccordion title={t("userAgent")}>
          <FastField
            name="userAgent"
            component={UserAgent}
            userAgents={userAgents}
            module={module}
            userAgentSuffix={userAgentSuffix}
          />
        </SettingsAccordion>

        <SettingsAccordion title={t("cookies")}>
          <FastField name="cookies" component={Cookies} />
        </SettingsAccordion>

        <SettingsAccordion title={t("customScripts")}>
          <FastField name="customScripts" component={CustomScripts} />
        </SettingsAccordion>
      </div>
    </div>
  );
}
