import React from "react";
import {
  getProperty,
  TagInput,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { uniq } from "lodash";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { SettingsContext } from "../data/useContextValues";
import { SettingControlFactory } from "../useAdvancedControls";

export const getDomainScopeControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("domainScopeTitle"),
  path: "domainScope",
  // eslint-disable-next-line react/display-name
  control: () => <DomainScope />,
  testId: "crawl-settings-advanced-domain-scope",
  activeValues: ["secondaryDomains"],
  textForSearch: createTextForSearch([
    t("domainScopeTitle"),
    t("primaryDomainLabel"),
    t("crawlSubDomainsLabel"),
    t("crawlHttpAndHttpsLabel"),
    t("secondaryDomainsLabel"),
    t("secondaryDomainsDescription"),
    t("secondaryDomainsExample"),
    t("secondaryDomainsPlaceholder"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginTop: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  code: {
    fontSize: "12px",
    marginBottom: "8px",
  },
  indent: {
    marginTop: theme.spacing(2),
  },
}));

export const DomainScope = React.memo(DomainScopeInner);

function DomainScopeInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  const withProtocol = (url: string): string => {
    const protocol = contextValues?.primaryDomain?.match(/^(.*?):/);
    return !(protocol && protocol[0]) || url.match(/^[a-zA-Z]+:\/\//)
      ? url
      : protocol[0] + "//" + url;
  };

  return (
    <>
      <Typography variant="subtitle2">{t("primaryDomainLabel")}</Typography>
      <FastField
        name="scope.domainScope.primaryDomain"
        component={TextFieldWithOnBlurUpdate}
        hiddenLabel
        variant="outlined"
        data-testid="primary-domain"
      />

      <FastField
        name="scope.domainScope.crawlSubDomains"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("crawlSubDomainsLabel") }}
        className={classes.checkBox}
        data-testid="crawl-sub-domains"
      />

      <FastField
        name="scope.domainScope.crawlHttpAndHttps"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("crawlHttpAndHttpsLabel") }}
        className={classes.checkBox}
        data-testid="crawl-http-and-https"
        data-pendo="crawl-both-http-and-https"
      />

      <Typography variant="subtitle2" className={classes.subtitle}>
        {t("secondaryDomainsLabel")}
      </Typography>
      <Typography variant="caption">
        {t("secondaryDomainsDescription")}
      </Typography>
      <code className={classes.code}>{t("secondaryDomainsExample")}</code>
      <FastField name="scope.domainScope.secondaryDomains">
        {({
          field: { value, name },
          form: { setFieldValue, isSubmitting, errors },
        }: FastFieldProps<string[]>) => {
          const error: string[] | undefined = getProperty(errors, name);
          return (
            <TagInput
              value={value}
              onChange={(value) =>
                setFieldValue(
                  name,
                  uniq(value.map((x) => withProtocol(x))),
                  true,
                )
              }
              disabled={isSubmitting}
              showCopyAll
              error={error?.find(Boolean)}
              errors={error?.map((error) => Boolean(error))}
              className={classes.indent}
              placeholder={t("secondaryDomainsPlaceholder")}
              data-testid="secondary-urls-tags"
            />
          );
        }}
      </FastField>
    </>
  );
}
