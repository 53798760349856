import { Button, PlusSolid, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Routes } from "../_common/routing/routes";

export function NewProjectButton(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("projectsList");
  return (
    <Button
      data-pendo="new-project-button"
      data-testid="all-projects-create-new"
      to={Routes.NewProject.getUrl({
        accountId,
      })}
      component={Link}
      variant="contained"
      color="primary"
      size="large"
      startIcon={<PlusSolid />}
      style={{ marginLeft: 7 }}
    >
      {t("newProjectButton")}
    </Button>
  );
}
