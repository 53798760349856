import {
  Button,
  Checkbox,
  ChevronDownSolid,
  ChevronUpSolid,
  Chip,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Menu, MenuItem, makeStyles } from "@material-ui/core";
import { useAccountProjectsFilterParam } from "../useAccountProjectsFilterParam";
import React from "react";

export function ProjectStatusFilter(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("projectsList");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const { filter, setScheduled, setDraft, setActive } =
    useAccountProjectsFilterParam();

  const scheduled = Boolean(filter.scheduled);
  const draft = Boolean(filter.draft);
  const active = Boolean(filter.active);

  const count = [scheduled, draft, active].filter((x) => x).length;

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="outlined"
        className={classes.button}
        endIcon={
          Boolean(anchorEl) ? (
            <ChevronUpSolid className={classes.icon} />
          ) : (
            <ChevronDownSolid className={classes.icon} />
          )
        }
      >
        {t("projectStatus.label")}
        {count > 0 && (
          <Chip
            label={count}
            color="primary"
            size="small"
            rounded
            className={classes.chip}
          />
        )}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <MenuItem onClick={() => setScheduled(!scheduled)}>
          <Checkbox checked={scheduled} className={classes.checkbox} />
          <Typography variant="body2" className={classes.label}>
            {t("projectStatus.scheduled")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setDraft(!draft)}>
          <Checkbox checked={draft} className={classes.checkbox} />
          <Typography variant="body2" className={classes.label}>
            {t("projectStatus.draft")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setActive(!active)}>
          <Checkbox checked={active} className={classes.checkbox} />
          <Typography variant="body2" className={classes.label}>
            {t("projectStatus.active")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    minWidth: 20,
    minHeight: 20,
  },
  checkbox: {
    marginRight: theme.spacing(1.125),
    pointerEvents: "none",
  },
  label: {
    marginRight: theme.spacing(5),
  },
}));
