/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getExternalLinkBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("externalLinkBreakdown.title"),
    description: () => t("externalLinkBreakdown.description"),
    reportTemplateCodesOrder: [
      "external_redirects",
      "max_external_links",
      "linked_domains",
      "unique_external_links",
    ],
    reportStatFilter: (report) => {
      return [
        "external_redirects",
        "max_external_links",
        "linked_domains",
        "unique_external_links",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
