import { Tooltip, makeStyles } from "@material-ui/core";
import { ResourceDetailData } from "../../../data/types";
import {
  EmptyState,
  InfoOutlined,
  SmileySad,
  useTranslation,
} from "@lumar/shared";
import { CustomSkeleton } from "../../../../_common/CustomSkeleton";
import React from "react";
import { useSiteSpeedReport } from "../data/useSiteSpeedReport";
import { MetricsGroups } from "../../../metrics-groups/MetricsGroups";

export function SiteSpeedScreenshot({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();

  const { screenshot, loading, error, expiresAt } = useSiteSpeedReport(
    data.metrics,
  );

  if (loading) {
    return (
      <CustomSkeleton
        height={300}
        variant="rect"
        background="white"
        animation="wave"
      />
    );
  }

  if (error) {
    return (
      <div className={classes.panel}>
        <EmptyState
          icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
          title={t("siteSpeed.reportErrorTitle")}
          description={error}
          className={classes.error}
        />
      </div>
    );
  }

  return (
    <MetricsGroups
      config={[
        {
          name: (
            <>
              {t("siteSpeed.screenshot")}
              <Tooltip title={t("siteSpeed.screenshotTooltip", { expiresAt })}>
                <InfoOutlined className={classes.infoIcon} />
              </Tooltip>
            </>
          ),
          children: (
            <div style={{ display: "flex" }}>
              <img
                className={classes.img}
                src={screenshot}
                alt={t("siteSpeed.screenshot")}
              />
            </div>
          ),
        },
      ]}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(1.25, 0, 3, 2.25),
  },
  error: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
  },
  infoIcon: {
    width: 16,
    height: 16,
    marginLeft: theme.spacing(0.5),
  },
}));
