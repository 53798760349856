import {
  Alert,
  Chip,
  formToSelectProps,
  formToTextFieldProps,
  Select,
  TextField,
  Typography,
  useNumberFormatter,
} from "@lumar/shared";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FastField, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { Label } from "../../../../_common/components/Label";
import { getUserAgents } from "../data/getUserAgents";
import { ContextValues, FormValues } from "../data/types";
import { SettingControlFactory } from "../useAdvancedControls";
import { UserAgentType } from "../types";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { get } from "lodash";

export const getUserAgentControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("userAgent"),
  path: "userAgent",
  // eslint-disable-next-line react/display-name
  control: (_, { userAgents, module, userAgentSuffix }) => (
    <FastField
      name="userAgent"
      component={UserAgent}
      userAgents={userAgents}
      module={module}
      userAgentSuffix={userAgentSuffix}
    />
  ),
  testId: "crawl-settings-advanced-user-agent",
  disabled: (formik) => formik.values.spider.stealthMode.enable,
  disabledTooltip: t("settings.stealtModeTooltip"),
  textForSearch: createTextForSearch([
    t("userAgent"),
    t("userAgentDescription"),
    t("userAgentExample"),
    t("userAgentString"),
    t("userAgentStringInfo"),
    t("userAgentStringPlaceholder"),
    t("userAgentToken"),
    t("userAgentTokenInfo"),
    t("userAgentTokenPlaceholder"),
    t("viewportWidth"),
    t("viewportHeight"),
  ]),
  fieldNames: [
    ["string", t("userAgentStringPlaceholder")],
    ["token", t("userAgentTokenPlaceholder")],
  ],
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  alert: {
    margin: theme.spacing(2.25, 0),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  select: {
    maxWidth: 400,
    marginBottom: theme.spacing(2),
  },
  checkbox: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    maxWidth: 600,
    marginBottom: theme.spacing(1.75),
  },
  viewportField: {
    maxWidth: 250,
    marginBottom: theme.spacing(1.75),
  },
  readonlyValue: {
    marginBottom: theme.spacing(1.75),
    color: theme.palette.grey[700],
  },
  suffix: {
    marginLeft: theme.spacing(2),
  },
}));

export function UserAgent({
  field,
  form,
  userAgents,
  module,
  userAgentSuffix,
  hideViewport,
}: FieldProps<FormValues["userAgent"]> & {
  userAgents: UserAgentType[];
  module: ContextValues["module"];
  userAgentSuffix: string;
  hideViewport?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");
  const { t: tUnits } = useTranslation("units");
  const formatNumber = useNumberFormatter();

  const agentCode = field.value.code;

  const filteredUserAgents = getUserAgents({
    t,
    userAgents,
    module,
    include: [agentCode],
  }).filter(
    (agent) =>
      agent.code === agentCode || (!agent.isDeprecated && agent.isPublic),
  );

  const userAgent = filteredUserAgents.find((x) => x.code === agentCode);

  const suffix = module.userAgentSuffixEnabled ? userAgentSuffix : "";

  const isCustomUserAgent = userAgent?.code === "Custom";
  const isCustomUserAgentMobile = !!get(
    form.values,
    "userAgent.userAgentIsMobile",
  );

  const viewportWidth = formatNumber(
    isCustomUserAgent
      ? isCustomUserAgentMobile
        ? module.viewportWidthMobile
        : module.viewportWidthDesktop
      : userAgent?.viewportWidth,
  );

  const viewportHeight = formatNumber(
    isCustomUserAgent
      ? isCustomUserAgentMobile
        ? module.viewportHeightMobile
        : module.viewportHeightDesktop
      : userAgent?.viewportHeight,
  );

  return (
    <div className={classes.root}>
      <Typography>{t("userAgentDescription")}</Typography>
      <Alert severity="info" size="small" className={classes.alert}>
        {t("userAgentExample")}
      </Alert>

      <Select
        {...formToSelectProps(form, "userAgent.code")}
        className={classes.select}
        data-testid="select-agent"
      >
        {filteredUserAgents.map((agent) => (
          <MenuItem key={agent.code} value={agent.code}>
            <Typography
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              {agent.name}
              {agent.isDeprecated && (
                <Chip
                  label={t("userAgentDeprecated")}
                  color="yellow"
                  style={{ marginLeft: "auto" }}
                />
              )}
            </Typography>
          </MenuItem>
        ))}
      </Select>

      {isCustomUserAgent ? (
        <FastField
          name="userAgent.userAgentIsMobile"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: t("settings.userAgent.isMobileUserAgent") }}
          data-testid="user-agent-is-mobile"
          className={classes.checkbox}
        />
      ) : null}

      {!isCustomUserAgent ? (
        <>
          <Label
            label={t("userAgentString")}
            info={t("userAgentStringInfo")}
            className={classes.label}
          />
          <Typography
            variant="body2"
            className={classes.readonlyValue}
            data-testid="user-agent-string"
          >
            {userAgent ? `${userAgent.string} ${suffix}` : "-"}
          </Typography>
        </>
      ) : (
        <TextField
          {...formToTextFieldProps(form, "userAgent.string")}
          label={
            <Label
              label={t("userAgentString")}
              info={t("userAgentStringInfo")}
            />
          }
          placeholder={t("userAgentStringPlaceholder")}
          className={classes.textField}
          InputProps={{
            endAdornment: <span className={classes.suffix}>{suffix}</span>,
          }}
          data-testid="custom-user-agent-string"
        />
      )}

      {!isCustomUserAgent ? (
        <>
          <Label
            label={t("userAgentToken")}
            info={t("userAgentTokenInfo")}
            className={classes.label}
          />
          <Typography
            variant="body2"
            className={classes.readonlyValue}
            data-testid="user-agent-token"
          >
            {userAgent?.token || "-"}
          </Typography>
        </>
      ) : (
        <TextField
          {...formToTextFieldProps(form, "userAgent.token")}
          label={
            <Label label={t("userAgentToken")} info={t("userAgentTokenInfo")} />
          }
          placeholder={t("userAgentTokenPlaceholder")}
          className={classes.textField}
          data-testid="custom-user-agent-token"
        />
      )}

      {!hideViewport && (
        <TextField
          {...formToTextFieldProps(form, "userAgent.viewportWidth")}
          type="number"
          label={t("viewportWidth")}
          placeholder={viewportWidth}
          InputProps={{ endAdornment: tUnits("pixelUnit") }}
          className={classes.viewportField}
        />
      )}

      {!hideViewport && (
        <TextField
          {...formToTextFieldProps(form, "userAgent.viewportHeight")}
          type="number"
          label={t("viewportHeight")}
          placeholder={viewportHeight}
          InputProps={{ endAdornment: tUnits("pixelUnit") }}
          className={classes.viewportField}
        />
      )}
    </div>
  );
}
