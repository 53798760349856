import {
  ArrowLeftSolid,
  useSession,
  Button,
  BlueDataGrid,
  useTranslation,
  PlusOutlined,
  BlueGridSearchField,
  Warning,
  Alert,
  Trans,
} from "@lumar/shared";
import { TopNavigation } from "../../../_common/top-navigation/TopNavigation";
import {
  ModuleCode,
  ReportTemplateConnectionFilterInput,
  RoleCode,
  useReportAdjustmentPageQuery,
} from "../../../graphql";
import { Link as RouterLink, useParams } from "react-router-dom";
import { insertIf } from "../../../_common/insertIf";
import {
  GridColDef,
  GridRowData,
  GridSortDirection,
} from "@mui/x-data-grid-pro";
import { getReportPriority } from "../../../_common/report-helpers/reportPriority";
import { getReportImpact } from "../../../_common/report-helpers/reportImpact";
import { PriorityBadge } from "../../../_common/report-helpers/ReportPriorityBadge";
import { ImpactBadge } from "../../../_common/report-helpers/ReportImpactBadge";
import adjustAnimation from "./adjustAnimation.json";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { Routes } from "../../../_common/routing/routes";
import {
  ReportAdjustmentFormDialogsContextProvider,
  useReportAdjustmentFormDialogs,
} from "./ReportAdjustmentFormDialogsContext";
import { CreateReportAdjustmentFormDialog } from "./CreateReportAdjustmentFormDialog";
import { EditReportAdjustmentFormDialog } from "./EditReportAdjustmentFormDialog";
import { ReportAdjustmentActionItems } from "./ReportAdjustmentActionItems";
import { makeStyles } from "@material-ui/core";

const defaultPaginationState = {
  sortModel: [
    { field: "reportTemplateName", sort: "asc" as GridSortDirection },
  ],
};

const useStyles = makeStyles((theme) => ({
  unknownReportAdjustmentRow: {
    background: theme.palette.warning.light,
  },
  alert: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export function ReportAdjustmentPage(): JSX.Element {
  const classes = useStyles();
  const session = useSession();
  const { projectId, accountId } = useParams<{
    projectId: string;
    accountId: string;
  }>();
  const { t, i18n } = useTranslation("reportAdjustment");

  const { data, loading, error } = useReportAdjustmentPageQuery({
    variables: {
      projectId,
      reportTemplateFilter: session.isDeepCrawlAdmin
        ? // We don't want to show admin reports in this feature.
          ({ admin: { eq: false } } as ReportTemplateConnectionFilterInput)
        : undefined,
    },
  });

  const allTemplates =
    // eslint-disable-next-line fp/no-mutating-methods
    data?.reportTemplates.nodes
      .map((node) => {
        if (node.code.startsWith("custom_extraction_")) {
          return {
            ...node,
            name:
              data?.project?.customExtractions?.find(
                (ce) => ce.reportTemplateCode === node.code,
              )?.label ?? node.code,
          };
        }
        return node;
      })
      .sort((a, b) => a.name.localeCompare(b.name, i18n.language))
      .filter((node) => !node.name.startsWith("custom_extraction_")) ?? [];

  const rows =
    data?.project?.reportTemplateOverrides.nodes.map((node) => {
      const template = allTemplates.find(
        (tpl) => tpl.code === node.reportTemplateCode,
      );
      return {
        ...node,
        isUnknown: !template,
        reportTemplateName: template?.name ?? node.reportTemplateCode,
      };
    }) ?? [];

  const unusedTemplates = allTemplates.filter(
    (tpl) => !rows.find((row) => row.reportTemplateCode === tpl.code),
  );

  const module = data?.project?.moduleCode ?? ModuleCode.Basic;
  const availableTemplates = unusedTemplates.filter((tpl) =>
    tpl.supportedModules.includes(module),
  );

  const columns: GridColDef[] = [
    {
      field: "reportTemplateName",
      headerName: t("reportName"),
      flex: 0.4,
      renderCell: (params) => {
        if (params.row.isUnknown) {
          return (
            <>
              <Warning
                color="action"
                fontSize="small"
                style={{ marginRight: 7 }}
              />
              {t("unknownReport", { name: params.row.reportTemplateName })}
            </>
          );
        }
        return <>{params.row.reportTemplateName}</>;
      },
    },
    {
      field: "totalWeight",
      headerName: t("priority"),
      flex: 0.3,
      renderCell: (params) => {
        return (
          <PriorityBadge priority={getReportPriority(params.row.totalWeight)} />
        );
      },
    },
    {
      field: "totalSign",
      headerName: t("impact"),
      flex: 0.3,
      renderCell: (params) => {
        return <ImpactBadge impact={getReportImpact(params.row.totalSign)} />;
      },
    },
    ...insertIf<GridColDef>(session.hasSufficientRole(RoleCode.Editor), {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        const { reportTemplateCode, totalWeight, totalSign, isUnknown } =
          params.row;
        return (
          <ReportAdjustmentActionItems
            disableEdit={isUnknown}
            reportAdjustment={{ reportTemplateCode, totalWeight, totalSign }}
          />
        );
      },
    }),
  ];

  return (
    <>
      <TopNavigation
        title={t("title")}
        breadcrumbs={[{ label: t("title") }]}
        actions={
          session.hasSufficientRole(RoleCode.Editor) ? (
            <Button
              key="project-settings"
              component={RouterLink}
              to={Routes.Crawls.getUrl({
                accountId,
                projectId,
                tab: "edit",
                step: 4,
              })}
              variant="outlined"
              size="medium"
              startIcon={<ArrowLeftSolid />}
            >
              {t("projectSettings")}
            </Button>
          ) : undefined
        }
      />
      {data?.project?.moduleCode === ModuleCode.SiteSpeed ? (
        <Alert severity="info" className={classes.alert}>
          <Trans
            ns="reportAdjustment"
            i18nKey="siteSpeedHealthScoreUnaffectedAlert"
          >
            Report adjustments <b>do not</b> affect health scores in Site Speed
            Audit projects - like the one you are currently configuring.
          </Trans>
        </Alert>
      ) : null}
      <ReportAdjustmentFormDialogsContextProvider>
        <div
          style={{
            marginTop: 7,
          }}
        >
          <BlueDataGrid
            getRowClassName={(params) => {
              if (params.row.isUnknown) {
                return classes.unknownReportAdjustmentRow;
              }
              return "";
            }}
            components={{
              ToolbarLeft: BlueGridSearchField,
              ToolbarRight: ToolbarRight,
            }}
            componentsProps={{
              toolbarLeft: {
                disabled: rows.length === 0,
              },
              toolbarRight: {
                disabled: loading,
              },
              noRowsOverlay: {
                icon: () => (
                  <Lottie
                    animationData={adjustAnimation}
                    loop={false}
                    play
                    style={{
                      width: "120px",
                      height: "120px",
                      margin: "auto",
                    }}
                  />
                ),
                title: t("noRows.title"),
                detail: t("noRows.detail"),
              },
            }}
            loading={loading}
            error={error}
            getRowId={getReportAdjustmentRowId}
            columns={columns}
            rows={rows}
            rowCount={rows.length}
            totalRowCount={rows.length}
            pageSize={100}
            defaultPaginationState={defaultPaginationState}
          />
        </div>
        <CreateReportAdjustmentFormDialog
          reportTemplates={availableTemplates}
        />
        <EditReportAdjustmentFormDialog reportTemplates={allTemplates} />
      </ReportAdjustmentFormDialogsContextProvider>
    </>
  );
}

function getReportAdjustmentRowId(row: GridRowData): string {
  return row.reportTemplateCode;
}

function ToolbarRight(props: { disabled?: boolean }): JSX.Element | null {
  const { openCreateDialog } = useReportAdjustmentFormDialogs();
  const { t } = useTranslation("reportAdjustment");
  const { hasSufficientRole } = useSession();

  if (hasSufficientRole(RoleCode.Editor)) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={openCreateDialog}
        startIcon={<PlusOutlined />}
        disabled={props.disabled}
        data-testid="create-report-adjustment-button"
      >
        {t("createReportAdjustment")}
      </Button>
    );
  }

  return null;
}
