import { ModuleCode } from "../../../graphql";
import { ContextValues, ScriptRenderingSettings } from "./data/types";

export const getScriptRenderingDefaultValues = (
  contextValues: ContextValues,
): ScriptRenderingSettings => {
  if (contextValues.module.code === ModuleCode.Accessibility) {
    return {
      enable: true,
      blockAdScripts: false,
      blockAnalyticsScripts: true,
      customRejections: "",
      customScript: "",
      customAccessibilityScript: "",
      externalResources: [],
      flattenShadowDom: false,
      flattenIframes: false,
      renderTimeout: 15,
      block3rdPartyCookies: false,
    };
  }

  if (contextValues.module.code === ModuleCode.SiteSpeed) {
    return {
      enable: true,
      blockAdScripts: false,
      blockAnalyticsScripts: true,
      customRejections: "",
      customScript: "",
      customAccessibilityScript: "",
      externalResources: [],
      flattenShadowDom: false,
      flattenIframes: false,
      renderTimeout: 15,
      block3rdPartyCookies: false,
    };
  }

  return {
    enable: true,
    blockAdScripts: true,
    blockAnalyticsScripts: true,
    customRejections: "",
    customScript: "",
    customAccessibilityScript: "",
    externalResources: [],
    flattenShadowDom: false,
    flattenIframes: false,
    renderTimeout: 15,
    block3rdPartyCookies: false,
  };
};
