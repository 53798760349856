import { ConfirmationDialog, Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { FormikErrors } from "formik";
import { get, isString } from "lodash";
import React from "react";
import { ValidationErrors } from "./data/types";
import { SettingControl, SettingsGroup } from "./useAdvancedControls";

const useStyles = makeStyles(() => ({
  errorGroup: {
    fontWeight: 600,
  },
  error: {
    display: "block",
  },
}));

interface ValidationErrorDialogProps {
  validationErrors?: ValidationErrors;
}

export function ValidationErrorDialog({
  validationErrors,
}: ValidationErrorDialogProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => setOpen(Boolean(validationErrors)), [validationErrors]);

  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      variant="error"
      title={t("settings.validationErros")}
      content={<ValidationError errors={validationErrors} />}
      primaryAction={() => setOpen(false)}
      withGreyFooter
    />
  );
}

function ValidationError({
  errors,
}: {
  errors?: ValidationErrors;
}): JSX.Element | null {
  const classes = useStyles();

  if (!errors) return null;
  return (
    <div>
      {errors.groups.map((group, idx) => (
        <React.Fragment key={idx}>
          <Typography variant="caption" className={classes.errorGroup}>
            {group.name}
          </Typography>
          <div style={{ paddingLeft: 8 }}>
            {group.settings.map((settings, idx) => (
              <React.Fragment key={idx}>
                <Typography variant="caption" className={classes.errorGroup}>
                  {settings?.name}
                </Typography>
                <div style={{ paddingLeft: 8 }}>
                  {settings.errors.map((error, idx) => (
                    <Typography key={idx} className={classes.error}>
                      {error}
                    </Typography>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export function formatValidationError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: FormikErrors<any> | undefined,
  advancedSettings: SettingsGroup[],
): ValidationErrors {
  const arraysToSingle = [
    "test.removeUrlParams.params",
    "report.emailAlerts.emails",
  ];
  function getLabel(settings?: SettingControl, field?: string): string {
    return settings?.fieldNames?.find(([x]) => x === field)?.[1] ?? "";
  }

  function getErrors(
    errors: Record<string, unknown>,
    settings?: SettingControl,
    groupPath?: string,
  ): string[] {
    return Object.entries(errors).flatMap(([field, error]) => {
      const fieldName = getLabel(settings, field);
      if (Array.isArray(error)) {
        const showFirst = arraysToSingle.includes(
          `${groupPath}.${settings?.path}.${field}`,
        );
        const errorMessages = error
          .flatMap((error, idx) => {
            if (error === undefined) return;
            if (isString(error))
              return showFirst
                ? `${fieldName}: ${error}`
                : `${fieldName} ${idx + 1}: ${error}`;
            return Object.entries(error).map(
              ([field, error]) =>
                `${fieldName} ${idx + 1} - ${getLabel(
                  settings,
                  field,
                )}: ${error}`,
            );
          })
          .filter(isString);
        return showFirst ? errorMessages?.[0] : errorMessages;
      }

      return `${fieldName}: ${error}`;
    });
  }

  return {
    groups: advancedSettings.reduce<ValidationErrors["groups"]>(
      (groups, group) => {
        const settings = group.controls.reduce<
          ValidationErrors["groups"][number]["settings"]
        >((settings, control) => {
          const errors =
            get(error, control.path) ||
            get(error, `${group.path}.${control.path}`);

          if (!errors) return settings;
          return [
            ...settings,
            {
              name: control.title,
              errors: getErrors(
                errors as Record<string, unknown>,
                control,
                group.path,
              ),
            },
          ];
        }, []);

        if (!settings.length) return groups;
        return [...groups, { name: group.title, settings }];
      },
      [],
    ),
  };
}
