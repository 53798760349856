import React from "react";
import { ReportAdjustment } from "./ReportAdjustmentTypes";

export interface ReportAdjustmentFormDialogsContextValue {
  isCreateDialogOpen: boolean;
  isEditDialogOpen: boolean;
  reportAdjustmentToEdit?: ReportAdjustment;
  openCreateDialog(): void;
  closeCreateDialog(): void;
  openEditDialog(reportAdjustment: ReportAdjustment): void;
  closeEditDialog(): void;
}

const ReportAdjustmentFormDialogsContext =
  React.createContext<ReportAdjustmentFormDialogsContextValue>({
    isCreateDialogOpen: false,
    isEditDialogOpen: false,
    reportAdjustmentToEdit: undefined,
    openCreateDialog() {
      return;
    },
    closeCreateDialog() {
      return;
    },
    openEditDialog() {
      return;
    },
    closeEditDialog() {
      return;
    },
  });

export function ReportAdjustmentFormDialogsContextProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [reportAdjustmentToEdit, setReportAdjustmentToEdit] = React.useState<
    ReportAdjustment | undefined
  >(undefined);

  const value: ReportAdjustmentFormDialogsContextValue = React.useMemo(() => {
    return {
      isCreateDialogOpen,
      isEditDialogOpen,
      reportAdjustmentToEdit,
      openCreateDialog() {
        setIsCreateDialogOpen(true);
      },
      closeCreateDialog() {
        setIsCreateDialogOpen(false);
      },
      openEditDialog(reportAdjustment) {
        setReportAdjustmentToEdit(reportAdjustment);
        setIsEditDialogOpen(true);
      },
      closeEditDialog() {
        setIsEditDialogOpen(false);
        // Not calling `setReportAdjustmentToEdit` to avoid the form changing values when the dialog fades out.
      },
    };
  }, [isCreateDialogOpen, reportAdjustmentToEdit, isEditDialogOpen]);

  return (
    <ReportAdjustmentFormDialogsContext.Provider value={value}>
      {props.children}
    </ReportAdjustmentFormDialogsContext.Provider>
  );
}

export function useReportAdjustmentFormDialogs(): ReportAdjustmentFormDialogsContextValue {
  return React.useContext(ReportAdjustmentFormDialogsContext);
}
