import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from "@material-ui/core";
import { SentimentDissatisfiedSharp } from "@material-ui/icons";
import { EmptyState } from "../../empty-state/EmptyState";
import { BackgroundBoxes } from "./components/BackgroundBoxes";
import { BezierCurves } from "./components/BezierCurves";
import { ColumnsTitles } from "./components/ColumnsTitles";
import { FailBars } from "./components/FailBars/FailBars";
import { PassBars } from "./components/PassBars/PassBars";
import { ShadowFilters } from "./components/ShadowFilters";
import { stackedSeries } from "./helpers";
import { MissingSourcesMessage } from "./MissingSourcesMessage";
import { useFunnelModel } from "./URLFunnelModel";
export const funnelConfig = {
    svgWidth: 1400,
    svgHeight: 420,
    funnelWidth: 1400,
    funnelHeight: 200,
    barWidth: 46,
    minimumBarHeight: 1,
};
export const URLFunnelChart = ({ showProportionalBars, data, loading, text, getReportUrl, editCrawlUrl, isMissingSources, hasEditorPermission, }) => {
    const staticDataset = data.reduce((accumulator, current) => {
        return {
            previousPass: accumulator.previousPass * 0.7,
            data: [
                ...accumulator.data,
                Object.assign(Object.assign({}, current), { pass: accumulator.previousPass * 0.7, fail: accumulator.previousPass * 0.3 }),
            ],
        };
    }, { previousPass: 1000, data: [] }).data;
    const stackSeries = stackedSeries(showProportionalBars ? data : staticDataset);
    const controller = useFunnelModel(funnelConfig, showProportionalBars ? data : staticDataset);
    const { xScale, hScale, yScale, elementWidth } = controller;
    if (loading) {
        return (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "420px", children: _jsx(CircularProgress, {}) }));
    }
    if (isMissingSources) {
        return (_jsx(MissingSourcesMessage, { editCrawlUrl: editCrawlUrl, text: {
                description: text.missingSources.description,
                action: text.missingSources.action,
            }, hasEditorPermission: hasEditorPermission }));
    }
    if (data.length === 0) {
        return (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "420px", children: _jsx(EmptyState, { icon: _jsx(SentimentDissatisfiedSharp, { fontSize: "large" }), title: text.emptyState.title, description: text.emptyState.description, height: 200 }) }));
    }
    return (_jsxs("svg", { viewBox: `0 0 ${funnelConfig.svgWidth} ${funnelConfig.svgHeight}`, children: [_jsx(ShadowFilters, {}), _jsx(ColumnsTitles, { elementWidth: elementWidth, xScale: xScale, dataset: showProportionalBars ? data : staticDataset }), _jsxs("g", { transform: "translate(0, -120)", children: [_jsx(BackgroundBoxes, { hScale: hScale, xScale: xScale, yScale: yScale, elementWidth: elementWidth, dataset: showProportionalBars ? data : staticDataset }), _jsx(BezierCurves, { xScale: xScale, yScale: yScale, funnelWidth: funnelConfig.funnelWidth, svgHeight: funnelConfig.svgHeight, dataset: showProportionalBars ? data : staticDataset }), _jsx(FailBars, { xScale: xScale, funnelWidth: funnelConfig.funnelWidth, barWidth: funnelConfig.barWidth, hScale: hScale, yScale: yScale, elementWidth: elementWidth, dynamicDataset: data, stackedSeries: stackSeries, getReportUrl: getReportUrl }), _jsx(PassBars, { xScale: xScale, funnelWidth: funnelConfig.funnelWidth, barWidth: funnelConfig.barWidth, hScale: hScale, yScale: yScale, elementWidth: elementWidth, dynamicDataset: data, stackedSeries: stackSeries, getReportUrl: getReportUrl })] })] }));
};
