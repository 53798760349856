import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable fp/no-let */
/* eslint-disable fp/no-mutating-methods */
import { makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { ArrowDownOutlined, ArrowUpOutlined, SwitchVerticalOutlined, } from "../../icons";
const useStyles = makeStyles((theme) => ({
    table: {
        marginLeft: -1,
        width: "100%",
        borderCollapse: "collapse",
        fontFamily: theme.typography.fontFamily,
        "& tbody": {
            zIndex: 10,
            position: "relative",
        },
        "& thead": {
            position: "sticky",
            top: 0,
            zIndex: 100,
        },
        "& th": {
            cursor: "pointer",
            backgroundColor: "#7E46DE",
            color: theme.palette.common.white,
            textAlign: "center",
            border: "none",
            padding: "4px",
            fontSize: 12,
            "& span": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            },
        },
        "& td": {
            border: "1px solid #dddddd",
            textAlign: "left",
            padding: "8px",
            color: theme.palette.grey[800],
            maxWidth: "200px",
            minWidth: "100px",
            whiteSpace: "pre-line",
            wordBreak: "break-all",
            fontSize: 12,
            verticalAlign: "top",
        },
        "& tr:first-child td": {
            borderTop: "none",
        },
        "& tr:last-child td": {
            borderBottom: "none",
        },
        "& tr td:first-child": {
            borderLeft: "none",
        },
        "& tr td:last-child": {
            borderRight: "none",
        },
    },
    sortIcon: {
        width: 16,
        height: 16,
        marginLeft: 4,
    },
}));
export const JSONTable = ({ data, presenters, }) => {
    const classes = useStyles();
    const columns = useMemo(() => {
        if (data.length === 0)
            return [];
        return Object.keys(data[0]).map((key) => ({
            key,
            header: key,
        }));
    }, [data]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "none" });
    const sortableData = useMemo(() => {
        const sortableData = [...data];
        if (sortConfig.key !== null && sortConfig.direction !== "none") {
            sortableData.sort((a, b) => {
                if (sortConfig.key === null) {
                    return 0;
                }
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);
    const requestSort = (key) => {
        if (!sortConfig.key || sortConfig.key !== key) {
            setSortConfig({ key, direction: "descending" });
            return;
        }
        switch (sortConfig.direction) {
            case "descending":
                setSortConfig({ key, direction: "ascending" });
                break;
            case "ascending":
                setSortConfig({ key: null, direction: "none" });
                break;
            default:
                throw new Error("Invalid sort direction");
        }
    };
    return (_jsx("div", { className: "LumarJSONTable", children: _jsxs("table", { className: classes.table, children: [_jsx("thead", { children: _jsx("tr", { children: columns.map((column) => (_jsx("th", { onClick: () => requestSort(column.key), children: _jsxs("span", { children: [column.header, column.key === sortConfig.key ? (sortConfig.direction === "descending" ? (_jsx(ArrowDownOutlined, { className: classes.sortIcon })) : (_jsx(ArrowUpOutlined, { className: classes.sortIcon }))) : (_jsx(SwitchVerticalOutlined, { className: classes.sortIcon }))] }) }, String(column.key)))) }) }), _jsx("tbody", { children: sortableData.map((item, index) => (_jsx("tr", { children: columns.map((column) => {
                            const value = item[column.key];
                            return (_jsx("td", { children: presenters[getPresenterKey(value)](value) }, String(column.key)));
                        }) }, index))) })] }) }));
};
const getPresenterKey = (value) => {
    if (value === null || value === undefined)
        return "null";
    if (typeof value === "number")
        return "number";
    if (typeof value === "boolean")
        return "boolean";
    return "string";
};
