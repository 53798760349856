import React, { useState } from "react";
import {
  DotsHorizontalOutlined,
  DuplicateOutlined,
  ToggleIconButton,
  TrashOutlined,
  Typography,
  DestructiveMenuActionConfirmation,
  EditOutlined,
  useTranslation,
  DocumentReportOutlined,
  insertIf,
  PlusCircleOutlined,
} from "@lumar/shared";
import { makeStyles, Menu, MenuItem, SvgIcon } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SegmentRow } from "../SegmentsGrid";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { SegmentActions } from "../ManageSegment";
import { insertManyIf } from "../../../_common/insertIf";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  icon: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(20),
  },
  confirmIcon: {
    color: theme.palette.red[600],
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(20),
  },
}));

interface Props extends GridRenderCellParams {
  actions: SegmentActions;
  createSegmentDisabled: boolean;
}

interface ActionItem {
  label: string;
  icon: typeof SvgIcon;
  onClick: (() => void) | (() => Promise<void>);
  disabled?: boolean;
  dataTestid: string;
  confirm?: {
    title: string;
    description: string;
    confirmText: string;
    cancelText: string;
  };
}

export const ActionsCell = React.memo(ActionsCellInner);

function ActionsCellInner({
  row: rowParam,
  actions,
  createSegmentDisabled,
}: Props): JSX.Element {
  const row = rowParam as SegmentRow;

  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const classes = useStyles();
  const { t } = useTranslation("segments");
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [confirmAction, setConfirmAction] = useState<ActionItem | undefined>();

  const crawlSegment = row.isGroup ? undefined : row.crawlSegments?.nodes[0];

  const actionItems: ActionItem[] = row.isGroup
    ? [
        {
          label: t("createSegmentInGroup"),
          icon: PlusCircleOutlined,
          onClick: () => actions.onCreateSegment(row.name),
          disabled: createSegmentDisabled,
          dataTestid: `create-segment-in-group-button-${row.id}`,
        },
        ...insertManyIf(row.name, [
          {
            label: t("editSegmentGroup"),
            icon: EditOutlined,
            onClick: () => actions.onEditGroup(row.name || ""),
            dataTestid: `edit-segment-group-button-${row.id}`,
          },
          {
            label: "Delete",
            icon: TrashOutlined,
            onClick: () => actions.onDeleteGroup(row.name || ""),
            confirm: {
              title: t("deleteGroupConfirmTitle"),
              description: t("deleteGroupConfirmDescription"),
              confirmText: t("delete"),
              cancelText: t("cancel"),
            },
            dataTestid: `segment-group-delete-button-${row.id}`,
          },
        ]),
      ]
    : [
        ...insertIf<ActionItem>(crawlSegment, {
          label: t("viewSegment"),
          icon: DocumentReportOutlined,
          onClick: () =>
            history.push(
              Routes.Report.getUrl({
                accountId,
                projectId,
                crawlId,
                reportTemplateCodeWithTypeCode: "all_pages_basic",
                segmentId: row.id,
              }),
            ),
          disabled: !crawlSegment?.generatedAt,
          dataTestid: `view-segment-button-${row.id}`,
        }),
        {
          label: t("editSegment"),
          icon: EditOutlined,
          onClick: () => actions.onEditSegment(row.id),
          disabled: !row.isSupportedFormat,
          dataTestid: `edit-segment-button-${row.id}`,
        },
        {
          label: t("copy"),
          icon: DuplicateOutlined,
          onClick: () => actions.onCopySegment([row.id]),
          disabled: !row.isSupportedFormat,
          dataTestid: `copy-segment-button-${row.id}`,
        },
        {
          label: t("remove"),
          icon: TrashOutlined,
          onClick: () => actions.onDeleteSegment(row.id),
          confirm: {
            title: t("removeConfirmTitle"),
            description: row.hasActiveTask
              ? t("removeConfirmDescriptionActive")
              : t("removeConfirmDescription"),
            confirmText: t("removeConfirmOk"),
            cancelText: t("removeConfirmCancel"),
          },
          dataTestid: `segment-delete-button-${row.id}`,
        },
      ];

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-testid="segments-actions"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={Boolean(anchorEl) && !confirmAction}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="segments-actions-menu"
      >
        {actionItems.map((actionItem, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              if (actionItem.confirm !== undefined) {
                setConfirmAction(actionItem);
              } else {
                actionItem.onClick();
                setAnchorEl(null);
              }
            }}
            disabled={actionItem.disabled}
            data-testid={actionItem.dataTestid}
          >
            <actionItem.icon
              className={
                actionItem.confirm ? classes.confirmIcon : classes.icon
              }
            />
            <Typography variant="body2">{actionItem.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
      {confirmAction !== undefined && (
        <DestructiveMenuActionConfirmation
          title={confirmAction.confirm?.title || ""}
          description={confirmAction.confirm?.description}
          confirmText={confirmAction.confirm?.confirmText}
          cancelText={confirmAction.confirm?.cancelText}
          anchorEl={anchorEl}
          onConfirm={confirmAction.onClick}
          onCancel={() => {
            setAnchorEl(null);
            setConfirmAction(undefined);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        />
      )}
    </>
  );
}
