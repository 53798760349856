import * as Yup from "yup";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { getFilterOrRuleArrayValidationSchema } from "../../_common/connection-filtering/filter-or-rule-field-array/getFilterOrRuleValidationSchema";
import { useTranslation } from "@lumar/shared";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useCopySegmentDialogValidationSchema(
  metrics: NarrowMetric[],
  segmentLimit: number,
) {
  const { t } = useTranslation(["copySegmentDialog", "segments"]);
  const { t: tConnectionFiltering } = useTranslation("connectionFiltering");

  return Yup.object({
    projectDestination: Yup.mixed()
      .required(t("copySegmentDialog:projectDestination"))
      .test(
        "segmentLimit",
        t("copySegmentDialog:validation.copySegmentLimit"),
        (value, context) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const segmentsTotalCount = (value as any)?.segmentsTotalCount;

          const projectSegmentCount =
            typeof segmentsTotalCount === "number" ? segmentsTotalCount : 0;
          const segmentCount =
            typeof context.parent.segments?.length === "number"
              ? context.parent.segments.length
              : 0;

          return projectSegmentCount + segmentCount <= segmentLimit;
        },
      ),
    segments: Yup.array()
      .of(
        Yup.object({
          segmentName: Yup.string()
            .min(3, t("copySegmentDialog:validation.minNameLength"))
            .max(255, t("copySegmentDialog:validation.maxNameLength"))
            .required(t("copySegmentDialog:validation.required")),
          group: Yup.string()
            .nullable()
            .test(
              "emptyGroupName",
              t("segments:validationErrorEmptyGroup"),
              (value) => value !== undefined,
            ),
          rules: getFilterOrRuleArrayValidationSchema(
            metrics,
            tConnectionFiltering,
          ),
        }).required(),
      )
      .required(),
  });
}
