var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Typography } from "../typography/Typography";
const SIZE = 101;
export function SiteSpeedOverallScore(_a) {
    var { value, label, classes: outerClasses } = _a, props = __rest(_a, ["value", "label", "classes"]);
    const classes = useStyles();
    const colorClass = value < 50 ? classes.red : value > 89 ? classes.green : classes.orange;
    return (_jsxs("div", Object.assign({ style: {
            position: "relative",
            display: "inline-flex",
        }, className: outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.root }, props, { children: [_jsx("div", { className: clsx(classes.background, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.background), style: { width: SIZE, height: SIZE }, children: _jsx("svg", { className: classes.backgroundSvg, viewBox: "22 22 44 44", children: _jsx("circle", { cx: "44", cy: "44", r: "19.5", fill: "none", stroke: "currentColor", strokeWidth: "5", strokeDasharray: "122.522", strokeDashoffset: "0" }) }) }), _jsx(CircularProgress, { variant: "determinate", value: value, className: clsx(classes.progress, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.progress), style: { width: SIZE, height: SIZE }, thickness: 5, classes: {
                    svg: clsx(classes.svg, colorClass),
                } }), _jsxs("div", { style: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }, children: [_jsx(Typography, { className: clsx(classes.percentage, colorClass, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.percentage), variant: "caption", component: "div", color: "textSecondary", children: `${Math.round(value)}%` }), (label === null || label === void 0 ? void 0 : label.length) ? (_jsx(Typography, { className: clsx(classes.label, colorClass, outerClasses === null || outerClasses === void 0 ? void 0 : outerClasses.label), variant: "caption", component: "div", color: "textSecondary", children: label })) : undefined] })] })));
}
const useStyles = makeStyles((theme) => ({
    progress: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
    },
    background: {
        transform: "rotate(-90deg)",
        display: "inline-block",
    },
    backgroundSvg: {
        color: theme.palette.grey[200],
        display: "block",
    },
    svg: {
        strokeLinecap: "round",
    },
    percentage: {
        fontSize: theme.typography.pxToRem(21),
        fontWeight: 700,
        lineHeight: "25px",
        letterSpacing: 0,
        textAlign: "center",
    },
    red: {
        color: theme.palette.red[400],
    },
    green: {
        color: theme.palette.green[400],
    },
    orange: {
        color: theme.palette.orange[400],
    },
    label: {
        fontSize: theme.typography.pxToRem(8),
        fontWeight: 700,
        textAlign: "center",
        wordSpacing: 9999,
        color: theme.palette.grey[500],
    },
}));
