/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityKeyboardBPChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    //visualisationColors: [theme.palette.yellow[300]],
    title: () => t("accessibilityKeyboardBPChart.title"),
    description: () => t("accessibilityKeyboardBPChart.description"),
    reportTemplateCodesOrder: [
      "accesskeys_issues",
      "region_issues",
      "skip_link_issues",
      "tabindex_issues",
      "focus_order_semantics_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "accesskeys_issues",
        "region_issues",
        "skip_link_issues",
        "tabindex_issues",
        "focus_order_semantics_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
