import { DatasourceCode, ModuleCode } from "../../graphql";
import { DatasourceView } from "../data/types";
import { AccessibilityDetails } from "./accessibility-details/AccessibilityDetails";
import { AccessibilityDetailsSidebar } from "./accessibility-details/AccessibilityDetailsSidebar";
import { AssetResource } from "./asset-resource/AssetResource";
import { AssetResourceSidebar } from "./asset-resource/AssetResourceSidebar";
import { IssueDetails } from "./issue-details/IssueDetails";
import { IssueDetailsSidebar } from "./issue-details/IssueDetailsSidebar";
import { SeoDetails } from "./seo-details/SeoDetails";
import { SeoDetailsSidebar } from "./seo-details/SeoDetailsSidebar";
import { SiteSpeedDetails } from "./site-speed/SiteSpeedDetails";
import { SiteSpeedDetailsSidebar } from "./site-speed/SiteSpeedDetailsSidebar";
import { SitemapDetails } from "./sitemap-details/SitemapDetails";
import { SitemapDetailsSidebar } from "./sitemap-details/SitemapDetailsSidebar";
import { UncrawledUrls } from "./uncrawled-urls/UncrawledUrls";
import { UncrawledUrlsSidebar } from "./uncrawled-urls/UncrawledUrlsSidebar";
import { OpportunityDetails } from "./opportunity-details/OpportunityDetails";
import { OpportunityDetailsSidebar } from "./opportunity-details/OpportunityDetailsSidebar";

export function getResourceDatasources(): {
  datasource: DatasourceCode;
  templateCode: string;
}[] {
  return [
    {
      datasource: DatasourceCode.CrawlAccessibilityIssues,
      templateCode: "accessibility_issues",
    },
    {
      datasource: DatasourceCode.CrawlUrls,
      templateCode: "aaa_crawl_urls_ordered",
    },
    {
      datasource: DatasourceCode.CrawlUncrawledUrls,
      templateCode: "aaa_crawl_uncrawled_urls",
    },
    {
      datasource: DatasourceCode.CrawlSitemaps,
      templateCode: "aaa_crawl_sitemaps",
    },
  ];
}

interface Props {
  datasource: DatasourceCode;
  moduleCode: ModuleCode;
  resource: Record<string, unknown>;
}

export function getDatasourceView({
  datasource,
  moduleCode,
  resource,
}: Props): DatasourceView | undefined {
  switch (datasource) {
    case DatasourceCode.CrawlAccessibilityIssues:
      return {
        view: IssueDetails,
        sidebar: IssueDetailsSidebar,
      };
    case DatasourceCode.CrawlSiteSpeedAuditOpportunities:
      return {
        view: OpportunityDetails,
        sidebar: OpportunityDetailsSidebar,
      };
    case DatasourceCode.CrawlUrls:
      if (!isHtmlResource(resource)) {
        return {
          view: AssetResource,
          sidebar: AssetResourceSidebar,
        };
      }

      switch (moduleCode) {
        case ModuleCode.Seo:
          return {
            view: SeoDetails,
            sidebar: SeoDetailsSidebar,
          };
        case ModuleCode.Accessibility:
          return {
            view: AccessibilityDetails,
            sidebar: AccessibilityDetailsSidebar,
          };
        case ModuleCode.SiteSpeed:
          return {
            view: SiteSpeedDetails,
            sidebar: SiteSpeedDetailsSidebar,
          };
      }

      return;
    case DatasourceCode.CrawlUncrawledUrls:
      return {
        view: UncrawledUrls,
        sidebar: UncrawledUrlsSidebar,
      };
    case DatasourceCode.CrawlSitemaps:
      return {
        view: SitemapDetails,
        sidebar: SitemapDetailsSidebar,
      };
  }
}

function isHtmlResource(resource: Record<string, unknown>): boolean {
  return !(resource.js || resource.css || resource.isImage);
}
