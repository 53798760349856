/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";
import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  GetAggregateChartDataDocument,
  ModuleCode,
  ReportStatTrendItem,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { DateFormatter, getRawCrawlId } from "@lumar/shared";

export function getSiteSpeedAvgCruxITNPChart(
  t: TFunction<"charts">,
  theme: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Column,
    icon: "crux",
    title: () => t("siteSpeedCruxITNPMetricTrend.title"),
    description: () => t("siteSpeedCruxITNPMetricTrend.description"),
    descriptionTitle: () => t("siteSpeedCruxITNPMetricTrend.descriptionTitle"),
    legendEnabled: false,
    showXAxisLabel: false,
    showYAxisLabel: false,
    showPercentageInSeriesTooltip: false,
    presentLastValue: true,
    showBorderAtColumnTop: true,
    pointPadding: 0,
    categoryCsvColumnHeaderName: "DateTime",
    inputSource: "GqlQuery",
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId: crawlContext.selectedCrawlSegment?.segment?.rawID,
            reportTemplateCode: "crux_domain_summary:crux_itnp_weighted_avg",
          },
        ],
      };
    },
    seriesCategories: (series) => {
      return (
        series[0]?.data.map((data) => String(data.metadata?.createdAt)) || []
      );
    },
    series: (reports, { accountId, projectId, segmentId }: LinkData) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: (report.trend ?? [])
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .sort((a, b) => a.crawlId - b.crawlId)
            .map((trend, index) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: index,
                y: trend.basic ?? 0,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode:
                    report.reportTemplate?.code ?? report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
                metadata: {
                  createdAt: trend.createdAt,
                },
              };
            }),
        };
      }),
  };
}
