import {
  Button,
  PieChartSolid,
  PlusSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { SegmentSelectionContext } from "./SegmentsGrid";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { RoleCode } from "@lumar/shared/dist/graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  button: {
    paddingTop: 5.5,
    paddingBottom: 5.5,
  },
}));

export function SegmentsGeridRightToolbar({
  disabled,
  createSegmentDisabled,
  onCreateSegment,
  onCopySegment,
}: {
  disabled?: boolean;
  createSegmentDisabled?: boolean;
  onCreateSegment: () => void;
  onCopySegment: (segmentIds: string[]) => void;
}): JSX.Element {
  const { t } = useTranslation("segments");
  const classes = useStyles();
  const { hasSufficientRole } = useSession();

  const { selectedRows } = React.useContext(SegmentSelectionContext);

  if (!hasSufficientRole(RoleCode.Editor)) {
    return <></>;
  }

  const selectedIds = Object.entries(selectedRows)
    .filter((x) => x[1])
    .map((x) => x[0]);

  return (
    <div className={classes.root}>
      <Button
        onClick={() => onCopySegment(selectedIds)}
        disabled={disabled || selectedIds.length === 0}
        variant="outlined"
        startIcon={<PieChartSolid />}
        className={classes.button}
        data-testid="bulk-copy-project-button"
      >
        {t("bulkCopyIntoProject")}
      </Button>
      <Button
        onClick={() => onCreateSegment()}
        disabled={disabled || createSegmentDisabled}
        variant="contained"
        color="primary"
        startIcon={<PlusSolid />}
        className={classes.button}
        data-testid="create-segment-project-button"
      >
        {t("createSegment")}
      </Button>
    </div>
  );
}
