import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getTimeToInteractiveChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("timeToInteractive.title"),
    reportTemplateCodes: ["fast_tti", "average_tti", "slow_tti"],
    description: t("timeToInteractive.description"),
  })();
}
