import { ReportCategoryChartConfig } from "../types";
import { getNewsArticleStructuredDataValidationBreakdownChart } from "../../../../_common/charts/config/getNewsArticleStructuredDataValidationBreakdownChart";
import { getPagesWithSchemaMarkupChart } from "../../../../_common/charts/config/getPagesWithSchemaMarkupChart";
import { getPagesWithSchemaMarkupTrend } from "../../../../_common/charts/config/getPagesWithSchemaMarkupTrend";
import { getProductStructuredDataValidationBreakdownChart } from "../../../../_common/charts/config/getProductStructuredDataValidationBreakdownChart";
import { getStructuredDataTypesChart } from "../../../../_common/charts/config/getStructuredDataTypesChart";
import { getStructuredDataTypesTrend } from "../../../../_common/charts/config/getStructuredDataTypesTrend";

export const generateRankabilityStructuredDataCategory: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getPagesWithSchemaMarkupChart,
      getPagesWithSchemaMarkupTrend,
      getStructuredDataTypesChart,
      getStructuredDataTypesTrend,
      getProductStructuredDataValidationBreakdownChart,
      getNewsArticleStructuredDataValidationBreakdownChart,
    ],
  };
