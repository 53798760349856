import {
  Code,
  FileText,
  FolderNotchOpen,
  Note,
  useTranslation,
} from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { PlainValuePresenter } from "../../metrics-value-presenter";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useIssueDetailsSections } from "./useIssueDetailsSections";

export function IssueDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const config = useSummaryConfig(data.metrics);

  const { active } = useIssueDetailsSections(data.metrics);

  return (
    <ResourceDetailBase
      title={t("issueTitle")}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      {
        code: "issueDigest",
        name: t("issueId"),
      },
      {
        code: "elementSource",
      },
      {
        code: "exampleUrl",
      },
      {
        code: "exampleElementSelector",
      },
      {
        code: "issueDescription",
      },
    ],
    cardDetails: [
      {
        code: "ruleId",
        icon: FileText,
      },
      {
        code: "issueType",
        icon: FileText,
      },
      {
        code: "issueSeverity",
        icon: Code,
        value: <PlainValuePresenter {...getMetricProps("issueSeverity")} />,
      },
      {
        code: "wcagLevel",
        icon: FolderNotchOpen,
      },
      {
        code: "urlCount",
        icon: Note,
      },
      {
        code: "wcagSuccessCriteria",
        icon: Note,
      },
    ],
  };
}
