import React from "react";
import { useTranslation } from "@lumar/shared";
import { Metrics } from "../../data/types";
import { ReportConfig } from "./useAssetResourceSections";
import { ModuleCode } from "../../../graphql";

export function useAssetResourceReports(
  metrics: Metrics,
  moduleCode: ModuleCode,
): ReportConfig[] {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo(() => {
    const urlDigest = metrics["urlDigest"].value;

    if (moduleCode !== ModuleCode.Seo) return [];

    const result: ReportConfig[] = [
      {
        code: "resourceLinksIn",
        name: t("reportResourceLinksIn"),
        countMetric: "resourceLinksInCount",
        filter: {
          reportTemplateCode: "aaa_crawl_links",
          filter: {
            _and: [
              { urlToDigest: { eq: urlDigest } },
              {
                _or: [
                  { attrRel: { eq: "stylesheet" } },
                  { linkType: { eq: "script" } },
                  { linkType: { eq: "img" } },
                ],
              },
            ],
          },
        },
      },
      {
        code: "anchorLinksIn",
        name: t("reportAnchorLinksIn"),
        countMetric: "uniqueLinksInCount",
        filter: {
          reportTemplateCode: "aaa_crawl_unique_links",
          filter: {
            _and: [
              { urlToDigest: { eq: urlDigest } },
              { linkType: { eq: "a" } },
            ],
          },
        },
      },
    ];
    return result;
  }, [t, metrics, moduleCode]);
}
