/* eslint-disable fp/no-mutation */
import { Divider, Fade, Paper, makeStyles } from "@material-ui/core";
import {
  Badge,
  Button,
  EmptyState,
  ExclamationCircleOutlined,
  PaperClipSolid,
  Typography,
  ApolloError,
  useTranslation,
  useSession,
  EmptyStateProps,
} from "@lumar/shared";
import ReactMarkdown from "react-markdown";
import RehypeHighlight from "rehype-highlight";
import {
  RoleCode,
  useCreateAccessibilityIssueSolutionSuggestionMutation,
  useGetAccessibilityIssueSolutionSuggestionLazyQuery,
} from "../../../../graphql";
import { useParams } from "react-router-dom";
import React from "react";
import "@highlightjs/cdn-assets/styles/github-dark.min.css";
import clsx from "clsx";
import Lottie from "react-lottie-player";
import animation from "./SuggestedSolutionAnimation.json";
import { useRollbar } from "@rollbar/react";
import { useAccountsRoutes } from "../../../../_common/routing/accountsRoutes";
import { insertIf } from "../../../../_common/insertIf";

const useStyles = makeStyles({
  head: {
    padding: 16,
  },
  badge: {
    display: "inline-block",
    marginLeft: 8,
  },
});

export function IssueDetailsSuggestedSolution(): JSX.Element {
  const { t } = useTranslation(["common", "resourceDetail"]);
  const classes = useStyles();
  const { crawlId, resourceId } = useParams<{
    crawlId: string;
    resourceId: string;
  }>();
  const { account } = useSession();

  const { loading, errorMessage, solution } = usePrepareSuggestion({
    crawlId,
    issueDigest: resourceId,
    skip: !account.aiFeaturesEnabled,
  });

  return (
    <Paper>
      <Typography
        component="div"
        className={classes.head}
        variant="subtitle1SemiBold"
      >
        {t("resourceDetail:suggestedSolution.title")}
        <Badge
          variant="medium"
          label={t("common:beta")}
          className={classes.badge}
        />
      </Typography>
      <Divider />
      <Solution
        key={resourceId}
        solution={solution}
        errorMessage={errorMessage}
        loading={loading}
      />
    </Paper>
  );
}

const useSolutionStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 16,
    paddingRight: 151 + 128,
    paddingBottom: 16,
    position: "relative",
    [theme.breakpoints.up(1921)]: {
      maxWidth: 1280,
      margin: "auto",
      paddingRight: 16 + 128,
    },
    [theme.breakpoints.down(1281)]: {
      paddingRight: 128 + 16,
    },
    minHeight: 400,
  },
  buttonContainer: {
    position: "absolute",
    top: 0,
    right: 16,
  },
  code: { textWrap: "balance", borderRadius: 8 },
  ol: {
    listStyle: "none",
    padding: 0,
    "& ~ pre": {
      marginLeft: 39,
      "& ~ p": {
        marginLeft: 39,
      },
    },
    "& > li": {
      counterIncrement: "ai-suggestion-counter",
      fontWeight: "500",
      marginBottom: 8,
      marginLeft: 39,
      "& > ul": {
        paddingLeft: 17,
      },
      "& > p": {
        margin: 0,
        display: "inline",
      },
      "& > code": {
        display: "contents",
      },
      "&::before": {
        width: 23,
        height: 23,
        borderRadius: 23,
        marginLeft: -39,
        marginRight: 16,
        display: "inline-block",
        textAlign: "center",
        background: theme.palette.yellow[400],
        fontSize: theme.typography.pxToRem(13),
        content: "counter(ai-suggestion-counter)",
        paddingTop: theme.typography.pxToRem(2),
        fontWeight: "600",
      },
    },
  },
}));

function Solution(props: {
  solution: string;
  loading: boolean;
  errorMessage?: string;
}): JSX.Element {
  const classes = useSolutionStyles();
  const { t } = useTranslation(["resourceDetail", "common"]);
  const [shouldFade, setShouldFade] = React.useState(false);
  const { account, hasSufficientRole } = useSession();
  const accountsRoutes = useAccountsRoutes();
  const { accountId } = useParams<{
    accountId: string;
  }>();

  // If response is near instantenous (e.g. while fetching from cache), the fade effect looks jarring.
  React.useEffect(() => {
    if (props.loading) {
      const timeout = setTimeout(() => setShouldFade(true), 100);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.loading]);

  if (!account.aiFeaturesEnabled) {
    return (
      <EmptyState
        icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
        title={t("resourceDetail:suggestedSolution.disabledTitle")}
        description={t("resourceDetail:suggestedSolution.disabledDesciption")}
        actions={[
          ...insertIf<NonNullable<EmptyStateProps["actions"]>[0]>(
            hasSufficientRole(RoleCode.Admin),
            {
              type: "externalLink",
              title: t("resourceDetail:suggestedSolution.disabledAction"),
              href: accountsRoutes.Account.getUrl({ accountId }),
            },
          ),
        ]}
        height={416}
      />
    );
  }

  if (props.loading) {
    return (
      <EmptyState
        icon={
          <Lottie
            animationData={animation}
            loop
            play
            style={{
              width: "120px",
              height: "120px",
            }}
          />
        }
        title={t("resourceDetail:suggestedSolution.loadingTitle")}
        description={t("resourceDetail:suggestedSolution.loadingDescription")}
        height={416}
      />
    );
  }

  if (props.errorMessage) {
    return (
      <EmptyState
        icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
        title={t("common:somethingWentWrong")}
        description={props.errorMessage}
        height={416}
        actions={[
          {
            type: "button",
            title: t("common:reloadPage"),
            onClick: () => {
              window.location.reload();
            },
          },
        ]}
      />
    );
  }

  return (
    <Fade in timeout={shouldFade ? 200 : 0}>
      <div className={classes.container}>
        <ReactMarkdown
          rehypePlugins={[RehypeHighlight]}
          components={{
            code: (props) => (
              <code
                {...props}
                className={clsx(props.className, classes.code)}
              />
            ),
            ol: (props) => {
              const counter = (props.start ?? 1) - 1;
              return (
                <ol
                  {...props}
                  style={{
                    counterReset: `ai-suggestion-counter ${counter}`,
                  }}
                  className={clsx(props.className, classes.ol)}
                />
              );
            },
          }}
        >
          {props.solution}
        </ReactMarkdown>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={() => navigator.clipboard.writeText(props.solution)}
            startIcon={<PaperClipSolid />}
          >
            {t("resourceDetail:suggestedSolution.copyText")}
          </Button>
          <div
            id="issue-details-suggested-solution-placeholder"
            style={{ marginTop: 16, width: "100%", height: 2 }}
          />
        </div>
      </div>
    </Fade>
  );
}

interface PrepareSuggestionProps {
  crawlId: string;
  issueDigest: string;
  skip?: boolean;
}

function usePrepareSuggestion({
  crawlId,
  issueDigest,
  skip,
}: PrepareSuggestionProps): {
  solution: string;
  loading: boolean;
  errorMessage?: string;
} {
  const rollbar = useRollbar();
  const { t } = useTranslation(["resourceDetail", "common"]);
  const [getSuggestion] = useGetAccessibilityIssueSolutionSuggestionLazyQuery();
  const [createSuggestion] =
    useCreateAccessibilityIssueSolutionSuggestionMutation();

  const prepareSuggestion = React.useCallback(async () => {
    const options = {
      variables: { crawlId, issueDigest },
    };

    try {
      let solution = ""; // eslint-disable-line fp/no-let

      const { data: getResponse } = await getSuggestion({
        ...options,
        fetchPolicy: "cache-first",
      });

      solution = getResponse?.suggestion?.solution ?? "";

      if (!solution) {
        try {
          const { data: createResponse } = await createSuggestion(options);
          solution = createResponse?.create?.suggestion.solution ?? "";
        } catch (error) {
          // During local development, the cache might partially disappear during hot-reload
          // triggering an unecessary creation. Also, this covers
          // an edge case where more than 1 user open the same page at the same time.
          const wasSolutionAlreadyCreated =
            error instanceof ApolloError &&
            error.graphQLErrors.find((e) => e.extensions?.code === "CONFLICT");
          if (wasSolutionAlreadyCreated) {
            // Fetching again but without asking for cache.
            const { data: getResponse } = await getSuggestion(options);
            solution = getResponse?.suggestion?.solution ?? "";
          } else {
            throw error;
          }
        }
      }

      if (solution) {
        return { solution };
      }

      rollbar.error("Got empty AI suggestion response.", {
        crawlId,
        issueDigest,
      });

      return {
        errorMessage: t(
          "resourceDetail:suggestedSolution.emptyCreationResponseError",
        ),
      };
    } catch (error) {
      if (error instanceof ApolloError) {
        return {
          errorMessage: error.graphQLErrors[0]?.message ?? error.message,
        };
      }
      return { errorMessage: t("common:somethingWentWrong") };
    }
  }, [crawlId, issueDigest, getSuggestion, rollbar, t, createSuggestion]);

  const [loading, setLoading] = React.useState<boolean>(skip ? false : true);
  const [solution, setSolution] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (skip) return;

    setLoading(true);
    (async () => {
      const { solution, errorMessage } = await prepareSuggestion();
      setSolution(solution ?? "");
      setErrorMessage(errorMessage);
      setLoading(false);
    })();
  }, [skip, prepareSuggestion]);

  return { solution, loading, errorMessage };
}
