import { Task } from "../data/types";
import { TitleCell } from "./cells/TitleCell";
import { DescriptionCell } from "./cells/DescriptionCell";
import { AssigneeCell } from "./cells/AssigneeCell";
import { UrlCounCell } from "./cells/UrlCounCell";
import { UnresolvedPercentCell } from "./cells/UnresolvedPercentCell";
import { TrendCell } from "./cells/TrendCell";
import { PriorityCell } from "./cells/PriorityCell";
import { Theme, useTheme } from "@material-ui/core";
import React from "react";
import { LegacyTaskStatus } from "../../graphql";
import { insertIf, useBrand, useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";

export interface ColumnDefinition {
  header: string;
  color?: string;
  width?: string | number;
  align?: "start" | "end" | "center";
  renderCell: (params: { task: Task; isDragging: boolean }) => React.ReactChild;
}

export function useTasksColumns({
  status,
}: {
  status: LegacyTaskStatus;
}): ColumnDefinition[] {
  const { t } = useTranslation("taskManager");
  const theme = useTheme();
  const brand = useBrand();

  const { header, color } = getHeaderProps(t, theme, status);

  return React.useMemo(
    () => [
      {
        header: header.toLocaleUpperCase(),
        color,
        renderCell: (params) => <TitleCell {...params} />,
        width: "18%",
      },
      {
        header: t("table.headerDescription"),
        width: "25%",
        renderCell: (params) => <DescriptionCell {...params} />,
      },
      ...insertIf<ColumnDefinition>(
        brand.featureAvailability.taskManagerEmailAssignment,
        {
          header: t("table.headerAssignedTo"),
          width: "15%",
          renderCell: (params) => <AssigneeCell {...params} />,
        },
      ),
      {
        header: t("table.headerUrlCount"),
        width: "10%",
        align: "end",
        renderCell: (params) => <UrlCounCell {...params} />,
      },
      {
        header: t("table.headerUnresolvedPercent"),
        width: "10%",
        align: "end",
        renderCell: (params) => <UnresolvedPercentCell {...params} />,
      },
      {
        header: t("table.headerUnresolvedTrend"),
        width: "15%",
        renderCell: (params) => <TrendCell {...params} />,
      },
      {
        header: t("table.headerPriority"),
        width: "7%",
        renderCell: (params) => <PriorityCell {...params} />,
      },
    ],
    [brand.featureAvailability.taskManagerEmailAssignment, color, header, t],
  );
}

function getHeaderProps(
  t: TFunction<"taskManager">,
  theme: Theme,
  status: LegacyTaskStatus,
): { header: string; color: string } {
  switch (status) {
    case LegacyTaskStatus.Done:
      return {
        header: t("status.done"),
        color: theme.palette.green[500],
      };
    case LegacyTaskStatus.Testing:
      return {
        header: t("status.testing"),
        color: theme.palette.yellow[300],
      };
    case LegacyTaskStatus.InProgress:
      return {
        header: t("status.inProgress"),
        color: theme.palette.blue[500],
      };
    case LegacyTaskStatus.ToDo:
      return {
        header: t("status.toDo"),
        color: theme.palette.orange[400],
      };
    case LegacyTaskStatus.Backlog:
      return {
        header: t("status.backlog"),
        color: theme.palette.ultraviolet[500],
      };
    default:
      return { header: status, color: theme.palette.grey[500] };
  }
}
