import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { HealthScoreChange } from "./HealthScoreChange";
import { HealthScoreResult } from "./HealthScoreResult";
import { LargeHealthScoreTrend } from "./LargeHealthScoreTrend";
import clsx from "clsx";
const useStyles = makeStyles({
    wrapper: { position: "relative", height: "100%" },
    container: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        zIndex: 1,
    },
    hidden: {
        visibility: "hidden",
    },
});
export function LargeHealthScoreChart(props) {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.wrapper, children: [_jsxs("div", { className: classes.container, children: [_jsx("div", { children: _jsx(HealthScoreResult, { healthScore: props.healthScore, industryBenchmark: props.industryBenchmark, size: "large" }) }), _jsx("div", { className: clsx({
                            [classes.hidden]: props.healthScoreChangePercentage === null,
                        }), children: _jsx(HealthScoreChange, { changePercentage: props.healthScoreChangePercentage || 0 }) })] }), props.healthScoreTrend && props.healthScoreTrend.length > 0 ? (_jsx(LargeHealthScoreTrend, { getIndustryBenchmarkLegendText: props.getIndustryBenchmarkLegendText, getScoreCategoryText: props.getScoreCategoryText, getTooltipScoreText: props.getTooltipScoreText, getTrendDataPointUrl: props.getTrendDataPointUrl, categoryName: props.categoryName, healthScoreTrend: props.healthScoreTrend, industryCode: props.industryCode, industryBenchmark: props.industryBenchmark })) : null] }));
}
