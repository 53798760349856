import React from "react";
import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "@lumar/shared";

export function CrawlRequestRateChart({
  requestRateChartOptions,
}: {
  requestRateChartOptions: Options;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");

  return (
    <ProgressDashboardContainer
      header={t("progressDashboard.crawlRequestRateChartTitle")}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={requestRateChartOptions}
        containerProps={{ "data-testid": "crawl-request-rate-chart" }}
      />
    </ProgressDashboardContainer>
  );
}
