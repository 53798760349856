import React from "react";
import {
  FilterRuleFormValue,
  FilterButton,
  useTranslation,
  Popper,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import { ConnectionPredicate } from "../../graphql";
import { ProjectsFilter } from "./ProjectsFilter";
import { useProjectFilters } from "./useProjectFilters";
import { getFilterRuleArrayValidationSchema } from "../../_common/connection-filtering/filter-rule-field-array/getFilterRuleArrayValidationSchema";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";
import PopperJs from "popper.js";
import * as Yup from "yup";
import { useAccountProjectsFilterParam } from "../useAccountProjectsFilterParam";

const useStyles = makeStyles((theme) => ({
  filterPopper: {
    zIndex: theme.zIndex.drawer,
  },
  filterBackdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const defaultFilter = {
  id: uuid(),
  metricCode: "nameOrDomain",
  predicateKey: ConnectionPredicate.Contains,
  predicateValue: "",
};

export function FiltersButton(): JSX.Element {
  const { t } = useTranslation("projectsList");
  const { t: tFilter } = useTranslation("connectionFiltering");
  const classes = useStyles();

  const { filter, resetFilters, applyFilters } =
    useAccountProjectsFilterParam();

  const [localFilterAnchor, setLocalFilterAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const localFilterVisible = Boolean(localFilterAnchor);
  const localFilterPopperId = localFilterVisible
    ? "local-filter-popper"
    : undefined;
  const localFilterPopperRef = React.useRef<PopperJs | null>(null);

  const initialValues: {
    filters: FilterRuleFormValue[];
  } = {
    filters: filter.filters ? filter.filters : [defaultFilter],
  };

  const getFilterCount = (filters: unknown[]): number =>
    filters.length === 1 && isEqual(filters[0], defaultFilter)
      ? 0
      : filters.length;

  const filterCount = filter.filters ? getFilterCount(filter.filters) : 0;

  const projectMetricsWithPredicates = useProjectFilters();

  const validationSchema = React.useMemo(
    () =>
      projectMetricsWithPredicates
        ? Yup.object({
            filters: getFilterRuleArrayValidationSchema(
              projectMetricsWithPredicates,
              tFilter,
            ),
          })
        : undefined,
    [projectMetricsWithPredicates, tFilter],
  );

  const onCancel = React.useCallback(
    () => setLocalFilterAnchor(null),
    [setLocalFilterAnchor],
  );

  return (
    <>
      <FilterButton
        label={t("filtersButton")}
        data-testid="filter-toggle"
        onClick={(event) => setLocalFilterAnchor(event.currentTarget)}
        chipLabel={filterCount}
        onClear={
          filterCount
            ? (e) => {
                e.stopPropagation();
                resetFilters();
              }
            : undefined
        }
        className={classes.button}
      />
      <Popper
        id={localFilterPopperId}
        open={localFilterVisible}
        onClose={onCancel}
        anchorEl={localFilterAnchor}
        placement="bottom-start"
        modifiers={{ flip: { enabled: false } }}
        popperRef={localFilterPopperRef}
        className={classes.filterPopper}
      >
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={async (values) => {
              const validatedValues = await validationSchema?.validate(values);
              setLocalFilterAnchor(null);
              applyFilters(validatedValues?.filters ?? values.filters);
            }}
          >
            {({ submitForm, resetForm, isValid, dirty }) => (
              <ProjectsFilter
                isValid={isValid}
                dirty={dirty}
                submitForm={submitForm}
                resetForm={resetForm}
                resetFilters={resetFilters}
                onCancel={onCancel}
              />
            )}
          </Formik>
        </div>
      </Popper>
    </>
  );
}
