import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Metrics } from "../data/types";
import { Typography } from "@lumar/shared";
import { MetricsValuePresenter } from "../metrics-value-presenter";

interface Props {
  metrics: Metrics;
  headers: [string, string];
  config: {
    code: string;
    name?: string;
    value?: React.ReactNode;
  }[];
}

export function MetricsTable({ metrics, headers, config }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width="30%" className={classes.header}>
              <Typography variant="captionSemiBold">{headers[0]}</Typography>
            </TableCell>
            <TableCell width="70%" className={classes.header}>
              <Typography variant="captionSemiBold">{headers[1]}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {config.map(({ code, name, value }) => (
            <TableRow key={code} className={classes.row}>
              <TableCell className={classes.nameCell}>
                <Typography variant="inherit">
                  {name || metrics?.[code]?.name || code}
                </Typography>
              </TableCell>
              <TableCell className={classes.valueCell}>
                <MetricsValuePresenter
                  metrics={metrics}
                  code={code}
                  value={value}
                  componentProps={{
                    showCopy: true,
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.625),
    minWidth: 540,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: { maxWidth: 290 },
  icon: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(1),
    minHeight: 373,
  },
  header: {
    background: theme.palette.grey[100],
    height: theme.spacing(6),
  },
  row: {
    height: "auto",
  },
  nameCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
  },
  valueCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
  },
}));
