import { ChartIconButton, Select, Snackbar, ZoomOutMap } from "@lumar/shared";
import { Box, MenuItem } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CrawlType } from "../../../graphql";
import { assert } from "../../assert";
import { useGenericParams } from "../../routing/useGenericParams";
import {
  ChartDataContextBase,
  ChartDataUsingGqlQueryInputSource,
  useChartDataContext,
} from "../components/chart-components/ChartDataContext";
import { ChartDropdownMenu } from "../components/chart-components/ChartDropdownMenu";
import { ChartPanelContent } from "../components/chart-components/ChartPanelContent";
import { ChartPanelTitle } from "../components/chart-components/ChartPanelTitle";
import { ChartWrapper } from "../components/chart-components/ChartWrapper";
import { ChartPanelErrorMessage } from "../components/chart-messages/ChartPanelErrorMessage";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { FullscreenDialog } from "../components/FullscreenDialog";
import { ChartRef, HighchartsChart } from "../components/HighchartsChart";
import { ExportType, ExportTypeMap } from "../types/ExportTypes";
import { ChartConfigItemVennDiagram } from "./ChartConfigItemVennDiagram";
import { useVennHighchartsOptions } from "./useVennHighchartsOptions";
import { getCrawlTypeName, sortCrawlTypesByPriority } from "./utils";
import { ChartConfigReportStatArray } from "../types/ChartConfigItemBase";
import { getIconForChart } from "../../../crawl-overview/dashboard/data-visualization/charts/getIconForChart";

export type VennDiagramProps = ChartConfigItemVennDiagram;

export function VennDiagram(props: VennDiagramProps): JSX.Element | null {
  const chartDataContext = useChartDataContext();

  // Both should be the same.
  // Just being extra careful here.
  if (
    props.inputSource !== "GqlQuery" ||
    chartDataContext.inputSource !== "GqlQuery"
  ) {
    return null;
  }

  return <VennDiagramInner {...props} chartDataContext={chartDataContext} />;
}

type VennDiagramInnerProps = VennDiagramProps & {
  chartDataContext: ChartDataContextBase & ChartDataUsingGqlQueryInputSource;
};

export function VennDiagramInner(props: VennDiagramInnerProps): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { accountId, projectId, crawlId } = useGenericParams();
  const { t } = useTranslation("charts");
  const { segmentName, crawl, project, reportStats, isChartUnavailable } =
    props.chartDataContext;

  const chartRef = React.createRef<ChartRef>();
  const { enqueueSnackbar } = useSnackbar();

  const crawlTypesSortedByPriority = React.useMemo(
    () =>
      sortCrawlTypesByPriority(
        reportStats[0]?.crawl?.crawlTypeCounts ?? [],
        reportStats[0]?.crawl?.crawlTypes ?? [],
      ),
    [reportStats],
  );

  const defaultCrawlType =
    props.defaultSelectedCrawlType || crawlTypesSortedByPriority[0];

  const [currentCrawlType, setCurrentCrawlType] =
    React.useState(defaultCrawlType);

  const title = props.title(reportStats as ChartConfigReportStatArray);

  const { options, seriesData, missingMultipleSources } =
    useVennHighchartsOptions(props, currentCrawlType);

  function handleExportOptionChange(exportType: ExportType): void {
    if (exportType === "CSV") {
      chartRef.current?.chart.downloadCSV();
    } else {
      chartRef.current?.chart.exportChartLocal(
        { type: ExportTypeMap.get(exportType) },
        {},
      );
    }
    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("chartDownloadMessage", { exportType })}
      />,
    );
  }

  React.useEffect(() => {
    // This ensures that we re-render the chart if defaults are updated
    const defaultCrawlType =
      props.defaultSelectedCrawlType || crawlTypesSortedByPriority[0];
    setCurrentCrawlType(defaultCrawlType);
  }, [props.defaultSelectedCrawlType, crawlTypesSortedByPriority]);

  React.useEffect(() => {
    // This ensures that we set the largest crawl type once the data has finished loading
    if (!currentCrawlType) {
      setCurrentCrawlType(defaultCrawlType);
    }
  }, [defaultCrawlType, currentCrawlType]);

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const isDataEmpty = seriesData.every(
    (d) => !d.value || !d.sets.includes(currentCrawlType),
  );

  const shouldDisableChartOptions =
    isChartUnavailable || isDataEmpty || missingMultipleSources;

  return (
    <>
      <ChartWrapper
        data-testid={props.testAttributePrefix}
        data-pendo={props.pendoAttributePrefix}
      >
        <ChartPanelTitle
          title={title}
          icon={getIconForChart(props.icon)}
          description={
            props.description
              ? props.description(
                  { accountId, projectId, crawlId },
                  reportStats as ChartConfigReportStatArray,
                )
              : undefined
          }
          descriptionTitle={props.descriptionTitle?.(
            reportStats as ChartConfigReportStatArray,
          )}
          segmentName={segmentName}
        >
          {crawlTypesSortedByPriority.length > 0 ? (
            <Select
              value={currentCrawlType}
              onChange={(e) => setCurrentCrawlType(e.target.value as CrawlType)}
              alignMenu="right"
              variant="filled"
            >
              {crawlTypesSortedByPriority.map((crawlType) => (
                <MenuItem
                  key={crawlType}
                  value={crawlType}
                  data-pendo={
                    props.pendoAttributePrefix
                      ? `${props.pendoAttributePrefix}-crawl-type-${crawlType}`
                      : undefined
                  }
                >
                  {t("vennDiagram.primarySourceOption", {
                    sourceName: getCrawlTypeName(t, crawlType),
                  })}
                </MenuItem>
              ))}
            </Select>
          ) : null}
          {!props.disableFullscreen && (
            <ChartIconButton
              tooltip={t("openFullscreen")}
              onClick={() => setModalIsOpen(true)}
              icon={ZoomOutMap}
              data-pendo={
                props.pendoAttributePrefix
                  ? `${props.pendoAttributePrefix}-open-fullscreen`
                  : undefined
              }
            />
          )}
          <ChartDropdownMenu<ExportType>
            title={t("exportOptions")}
            options={["PDF", "PNG", "CSV"]}
            handleOptionChange={handleExportOptionChange}
            icon={GetAppIcon}
            isDisabled={shouldDisableChartOptions}
            pendoAttributePrefix={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-open-fullscreen`
                : undefined
            }
          />
        </ChartPanelTitle>
        <ChartPanelContent requiredSources={props.requiredSources}>
          {missingMultipleSources ? (
            <ChartPanelMessageWrapper>
              <ChartPanelErrorMessage>
                {t("vennDiagram.missingSources")}
              </ChartPanelErrorMessage>
            </ChartPanelMessageWrapper>
          ) : isDataEmpty ? (
            <ChartPanelMessageWrapper>
              <ChartPanelErrorMessage>
                {t("vennDiagram.noData")}
              </ChartPanelErrorMessage>
            </ChartPanelMessageWrapper>
          ) : (
            <Box flexGrow={1} style={{ width: "100%", height: "100%" }}>
              <HighchartsChart
                options={options}
                ref={chartRef}
                title={title}
                segmentName={segmentName}
                projectName={project?.name}
                primaryDomain={project?.primaryDomain}
                crawlCreatedAt={crawl?.createdAt}
              />
            </Box>
          )}
        </ChartPanelContent>
      </ChartWrapper>
      {!props.disableFullscreen && (
        <FullscreenDialog
          title={title}
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          content={
            <ChartPanelContent requiredSources={props.requiredSources}>
              {missingMultipleSources ? (
                <ChartPanelMessageWrapper>
                  <ChartPanelErrorMessage>
                    {t("vennDiagram.missingSources")}
                  </ChartPanelErrorMessage>
                </ChartPanelMessageWrapper>
              ) : isDataEmpty ? (
                <ChartPanelMessageWrapper>
                  <ChartPanelErrorMessage>
                    {t("vennDiagram.noData")}
                  </ChartPanelErrorMessage>
                </ChartPanelMessageWrapper>
              ) : (
                <Box flexGrow={1} style={{ width: "100%" }}>
                  <HighchartsChart
                    options={options}
                    title={title}
                    segmentName={segmentName}
                    projectName={project?.name}
                    primaryDomain={project?.primaryDomain}
                    crawlCreatedAt={crawl?.createdAt}
                  />
                </Box>
              )}
            </ChartPanelContent>
          }
        />
      )}
    </>
  );
}
