import React from "react";
import { GridColumns } from "@mui/x-data-grid-pro";
import { useSession, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";
import { MenuItemProps } from "../cells/ProjectActionsCell";
import {
  AccountProjectsColumnTypes,
  accountProjectColumns,
  useColumnsStyles,
} from "./getColumn";
import { AccountProjectsActionTypes, accountProjectActions } from "./getAction";

type ColumnsGetter = (props: {
  columns: Exclude<AccountProjectsColumnTypes, "actions">[];
  actions?: AccountProjectsActionTypes[];
}) => GridColumns;

export function useAllProjectsColumns(): ColumnsGetter {
  const classes = useColumnsStyles();
  const { t } = useTranslation("projectsList");
  const { accountId } = useParams<{ accountId: string }>();
  const { hasSufficientRole } = useSession();

  return React.useCallback<ColumnsGetter>(
    ({ columns, actions }) => {
      const columnsDefinitons = columns.map((column) =>
        accountProjectColumns[column]({
          t,
          classes,
        }),
      );
      const menuItems = (actions || []).reduce<MenuItemProps[]>(
        (result, action) => {
          const menuItem = accountProjectActions[action]({
            t,
            accountId,
            hasSufficientRole,
          });
          return menuItem ? [...result, menuItem] : result;
        },
        [],
      );

      return (
        menuItems.length
          ? [
              ...columnsDefinitons,
              accountProjectColumns.actions({
                t,
                classes,
                menuItems,
              }),
            ]
          : columnsDefinitons
      ) as GridColumns;
    },
    [t, classes, accountId, hasSufficientRole],
  );
}
