/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getSitemapsBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.Sitemap],
      incompleteMessage: t("requiredSourcesMessage.sitemapsSource"),
    },
    title: () => t("sitemapsBreakdown.title"),
    description: () => t("sitemapsBreakdown.description"),
    reportTemplateCodesOrder: [
      "all_crawled_urls_in_xml_sitemaps",
      "missing_in_sitemaps",
    ],
    reportStatFilter: (report) => {
      return [
        "all_crawled_urls_in_xml_sitemaps",
        "missing_in_sitemaps",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
