// Note: The "admin" filter is part of the admin schema and only affects the result if the
// user is admin. Because of the typecasting, it can be used with non admin queries as well.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAdminFilter<T extends object = any>(
  isDeepCrawlAdminEnabled: boolean | undefined,
): T | undefined {
  if (isDeepCrawlAdminEnabled === false) return { admin: { eq: false } } as T;

  return;
}
