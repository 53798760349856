import { ReportCategoryChartConfig } from "../types";
import { getAllPagesbyStatusCode } from "../../../../_common/charts/config/getAllPagesbyStatusCode";
import { getCrawledUrlTypeChart } from "../../../../_common/charts/config/getCrawledUrlTypeChart";
import { getCrawlSourcesPagesIn } from "../../../../_common/charts/config/getCrawlSourcesPagesIn";
import { getHttpAndHttpsChart } from "../../../../_common/charts/config/getHttpAndHttpsChart";
import { getLinksBreakdownChart } from "../../../../_common/charts/config/getLinksBreakdownChart";
import { getNonHtmlFilesChart } from "../../../../_common/charts/config/getNonHtmlFilesChart";
import { getUncrawledUrlsBreakdownChart } from "../../../../_common/charts/config/getUncrawledUrlsBreakdownChart";

export const generateSummaryCrawlSummaryChartConfig: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getCrawledUrlTypeChart,
      getAllPagesbyStatusCode,
      getCrawlSourcesPagesIn,
      getUncrawledUrlsBreakdownChart,
      getHttpAndHttpsChart,
      getNonHtmlFilesChart,
      getLinksBreakdownChart,
      // CEMM: ANLC-893 - Add SegmentBreakdone chart https://deepcrawl.atlassian.net/browse/ANLC-893
      // CEMM: ANLC-892 - Add hostnames breakdown using Data Explorer https://deepcrawl.atlassian.net/browse/ANLC-892
    ],
  };
