import React, { useContext, useState } from "react";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { addMinutes } from "date-fns";
import { HorizontalSlider, Select } from "@lumar/shared";

import { SettingsContext } from "../data/useContextValues";
import { ScheduleFrequencyCode } from "../../../../graphql";
import { useScheduleMutations } from "./useScheduleMutations";
import { useSchduleFrequencyOptions } from "./useSchduleFrequencyOptions";
import { ActiveSchedulePanel } from "./ActiveSchedulePanel";
import { TimeSelector } from "./TimeSelector";
import { DateSelector } from "./DateSelector";
import { Schedule } from "../data/types";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(3),
  },
  input: {
    minWidth: 400,
  },
}));

interface EditSchedule {
  id?: string;
  step: number;
  frequency?: ScheduleFrequencyCode;
  date?: Date;
}

export interface ScheduleSettingsProps {
  disabled?: boolean;
}

export function ScheduleSettings({
  disabled,
}: ScheduleSettingsProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = useContext(SettingsContext);

  const [schedule, setSchedule] = useState<Schedule | undefined>(
    contextValues.initialSchedule,
  );
  const [editSchedule, setEditSchedule] = useState<EditSchedule | undefined>(
    undefined,
  );

  const frequencyOptions = useSchduleFrequencyOptions();

  const { inProgress, createOrUpdateSchedule, deleteSchedule } =
    useScheduleMutations();

  async function handleSave(
    schedule: Omit<EditSchedule, "step">,
  ): Promise<void> {
    if (!schedule.frequency || !schedule.date) return;

    const scheduleId = await createOrUpdateSchedule(
      schedule.frequency,
      schedule.date,
      schedule.id,
    );
    if (scheduleId) {
      setSchedule({
        id: scheduleId,
        frequency: schedule.frequency,
        date: schedule.date,
      });
    }
  }

  async function handleDelete(): Promise<void> {
    if (!schedule) return;

    const scheduleId = await deleteSchedule(schedule.id);
    if (scheduleId) {
      setSchedule(undefined);
    }
  }

  function handleOpen(schedule: EditSchedule): void {
    setEditSchedule(schedule);
  }

  function handleClose(): void {
    setEditSchedule(undefined);
  }

  function handleChange(step?: number): void {
    const date = schedule?.date ?? addMinutes(new Date(), 60);
    handleOpen({
      id: schedule?.id,
      frequency: schedule?.frequency,
      date,
      step: step ?? 0,
    });
  }

  function handleRemove(): void {
    if (schedule?.id) handleDelete();
    handleClose();
  }

  function handleFrequencySelect(
    frequency: ScheduleFrequencyCode | "None",
  ): void {
    if (frequency === "None") {
      handleRemove();
    } else {
      setEditSchedule((schedule) => ({
        ...schedule,
        frequency,
        step: 1,
      }));
    }
  }

  function handleDateSelect(date: Date): void {
    const minValue = addMinutes(new Date(), 60);
    setEditSchedule((schedule) => {
      return {
        ...schedule,
        date: date < minValue ? minValue : date,
        step: 2,
      };
    });
  }

  function selectTime(date: Date): void {
    handleSave({
      ...editSchedule,
      date,
    });
    handleClose();
  }

  return (
    <>
      <Select
        open={Boolean(editSchedule)}
        onOpen={() => handleChange()}
        onClose={() => handleClose()}
        value={schedule?.frequency ?? "None"}
        label={t("settings.schedule.selectLabel")}
        disabled={disabled || inProgress}
        className={classes.input}
        data-testid="select-schedule-frequency"
        data-pendo="schedule-crawl-selector"
      >
        {frequencyOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ display: "none" }}
          >
            {option.label}
          </MenuItem>
        ))}
        <HorizontalSlider
          step={editSchedule?.step || 0}
          steps={[
            <React.Fragment key="select-frequency">
              {frequencyOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  selected={editSchedule?.frequency === option.value}
                  onClick={() => handleFrequencySelect(option.value)}
                  data-testid="select-schedule-frequency-option"
                >
                  {option.label}
                </MenuItem>
              ))}
            </React.Fragment>,
            <DateSelector
              key="select-date"
              date={editSchedule?.date}
              onChange={handleDateSelect}
              onClose={handleClose}
              onRemove={handleRemove}
            />,
            <TimeSelector
              key="select-time"
              date={editSchedule?.date}
              onChange={selectTime}
              onClose={handleClose}
              onRemove={handleRemove}
            />,
          ]}
        />
      </Select>
      {schedule !== undefined && (
        <ActiveSchedulePanel
          scheduleTime={schedule.date}
          disabled={disabled || inProgress}
          onChange={() => handleChange(1)}
          onRemove={handleDelete}
          className={classes.indent}
        />
      )}
    </>
  );
}
