import { getRawAccountId } from "@lumar/shared";
import {
  GetCrawlSettingsForSourcesQuery,
  GoogleAnalytics4Property as ApiGoogleAnalytics4Property,
} from "../../../../graphql";
import {
  isPlanLight,
  isPlanLightPlus,
} from "../../../../_common/data-extensions/PlanExtension";
import {
  ContextValues,
  GoogleAnalytics4Property,
  GoogleConnection,
} from "./types";

export const DefaultContextValues: ContextValues = {
  onError: () => {
    //
  },
  addGoogleConnection: () => {
    //
  },
  projectAccountId: "",
  primaryDomain: "",
  lastCrawl: undefined,
  isPlanLight: false,
  isPlanLightPlus: false,
  majesticAvailable: false,
  majesticMaxUrl: 1,
  googleConnections: [],
  googleAnalytics4Properties: [],
};

export function getContextValues(
  onError: (message: string) => void,
  addGoogleConnection: () => void,
  data: GetCrawlSettingsForSourcesQuery | undefined,
): ContextValues {
  if (!data) return DefaultContextValues;

  const planLight = isPlanLight(data.getAccount?.subscription?.plan?.code);
  const planLightPlus = isPlanLightPlus(
    data.getAccount?.subscription?.plan?.code,
  );

  return {
    onError,
    addGoogleConnection,
    projectAccountId: getRawAccountId(data.getProject?.account?.id ?? ""),
    primaryDomain: data.getProject?.primaryDomain ?? "",
    lastCrawl: data.getProject?.lastCrawl?.nodes[0],
    isPlanLight: planLight,
    isPlanLightPlus: planLightPlus,
    majesticAvailable: !planLight && !planLightPlus,
    majesticMaxUrl: Math.min(
      data?.getAccount?.accountSettings?.find(
        (settings) => settings.code === "majestic",
      )?.limit ?? 1,
      50000,
    ),
    googleConnections: getGoogleConnections(data),
    googleAnalytics4Properties: getGoogleAnalytics4Properties(data),
    googleAnalytics4ProjectProperty: data.getProject
      ?.googleAnalytics4ProjectProperty
      ? {
          googleConnectionId:
            data.getProject.googleAnalytics4ProjectProperty.googleConnection.id,
          propertyName:
            data.getProject.googleAnalytics4ProjectProperty.propertyName,
          conversionEventNames:
            data.getProject.googleAnalytics4ProjectProperty
              .conversionEventNames || [],
        }
      : undefined,
  };
}

function getGoogleConnections(
  data: GetCrawlSettingsForSourcesQuery,
): GoogleConnection[] {
  return data.me.googleConnections.nodes.map((x) => ({
    id: x.id,
    name: x.name,
  }));
}

function getGoogleAnalytics4Properties(
  data: GetCrawlSettingsForSourcesQuery,
): GoogleAnalytics4Property[] {
  function getName(
    property: Pick<ApiGoogleAnalytics4Property, "propertyName" | "displayName">,
  ): string {
    return `${property.displayName} (${property.propertyName?.replace(
      /^properties\//,
      "",
    )})`;
  }

  const properties = data.me.googleConnections.nodes.flatMap(
    (connection) =>
      connection.analytics4Properties
        ?.filter((property) => property.propertyName)
        .map<GoogleAnalytics4Property>((property) => ({
          propertyId: `${connection.id}:${property.propertyName}`,
          propertyName: property.propertyName || "",
          connectionId: connection.id,
          name: getName(property),
          accountName: connection.name,
          available: true,
        })) ?? [],
  );

  const additionalProperty = data?.getProject?.googleAnalytics4ProjectProperty;
  const additionalPropertyId = `${additionalProperty?.googleConnection.id}:${additionalProperty?.propertyName}`;
  if (
    additionalProperty &&
    !properties.find((x) => x.propertyId === additionalPropertyId)
  ) {
    // eslint-disable-next-line fp/no-mutating-methods
    properties.push({
      propertyId: additionalPropertyId,
      propertyName: additionalProperty.propertyName,
      connectionId: additionalProperty.googleConnection.id,
      name: getName(additionalProperty),
      accountName: additionalProperty.googleConnection.name,
      available: false,
    });
  }

  return [...properties];
}
