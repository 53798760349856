/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getIndexabilityCategoryChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("indexabilityCategoryChart.title"),
    description: () => t("indexabilityCategoryChart.description"),
    reportStatFilter: (report) => {
      return ["indexable_pages", "non_indexable_pages"].includes(
        report.reportTemplateCode,
      );
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
