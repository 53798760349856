import { GlobeAltSolid, Select, useTranslation } from "@lumar/shared";
import { Divider, FormControl, makeStyles, MenuItem } from "@material-ui/core";
import React from "react";
import {
  DataExplorerDimension,
  DataExplorerTableConfig,
  DataExplorerDimensionCode,
  DataExplorerAggregationCalculation,
} from "./types";
import { CustomSkeleton } from "../_common/CustomSkeleton";

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexDirection: "row",
    alignItems: "baseline",
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: "9px",
    fontWeight: 500,
    lineHeight: "20px",
    alignSelf: "center",
    color: theme.palette.grey[700],
  },
  container: {
    maxWidth: 230,
    minWidth: 230,
  },
  select: {
    paddingLeft: "37px !important",
  },
  icon: {
    fontSize: `${theme.typography.pxToRem(20)} !important`,
    color: theme.palette.grey[500],
  },
}));

export interface DimensionSelectProps {
  primaryDimensions: DataExplorerDimension[];
  secondaryDimensions: DataExplorerDimension[];
  tableConfig: DataExplorerTableConfig;
  setTableConfig: React.Dispatch<React.SetStateAction<DataExplorerTableConfig>>;
  loading?: boolean;
}

export function DimensionSelect({
  primaryDimensions,
  secondaryDimensions,
  tableConfig,
  setTableConfig,
  loading,
}: DimensionSelectProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");

  return (
    <FormControl hiddenLabel classes={{ root: classes.formControl }}>
      <label className={classes.label} htmlFor="data-explorer-dimension-select">
        {t("primaryDimension")}
      </label>
      {loading ? (
        <CustomSkeleton
          animation="wave"
          height="36px"
          variant="rect"
          className={classes.container}
        />
      ) : (
        <Select
          value={tableConfig.dimension}
          onChange={(e) =>
            setTableConfig((tableConfig) => {
              const dimension = e.target.value as DataExplorerDimensionCode;
              if (dimension === "path") {
                // Resets first column to URL (count) when viewing Paths
                return {
                  ...tableConfig,
                  dimension,
                  columns: [
                    {
                      metric: { code: "url" },
                      aggregationCalculation:
                        DataExplorerAggregationCalculation.Count,
                    },
                    ...tableConfig.columns.slice(1),
                  ],
                };
              } else {
                return {
                  ...tableConfig,
                  dimension,
                };
              }
            })
          }
          className={classes.container}
          classes={{
            select: classes.select,
          }}
          data-testid="data-explorer-dimension-select"
          inputProps={{
            id: "data-explorer-dimension-select",
          }}
          startIcon={<GlobeAltSolid className={classes.icon} />}
        >
          {primaryDimensions.map(({ name, code }) => (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          ))}
          {Boolean(primaryDimensions.length) && <Divider />}
          {secondaryDimensions.map(({ name, code }) => (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}
