import React from "react";
import { useTheme } from "@material-ui/core";

import { CustomSkeleton } from "../../../CustomSkeleton";

export function ChartSkeleton(): JSX.Element {
  const theme = useTheme();

  return (
    <CustomSkeleton
      background={theme.palette.common.white}
      variant="rect"
      height="100%"
      width="100%"
      animation="wave"
    />
  );
}
