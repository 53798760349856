import { Dialog, useTranslation } from "@lumar/shared";
import { Box, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";

type Props = {
  content: React.ReactNode;
  title: string;
  open: boolean;
  onClose: () => void;
};

export const FullscreenDialog = (props: Props): JSX.Element => {
  const { t } = useTranslation(["charts"]);
  const [shouldRender, setShouldRender] = useState(false);

  return (
    <Dialog
      title={props.title}
      open={props.open}
      width="100%"
      height="100%"
      TransitionProps={{
        onExited: () => {
          setShouldRender(false);
        },
        onEntered: () => {
          setShouldRender(true);
        },
      }}
      onClose={props.onClose}
      buttons={[
        {
          variant: "contained",
          color: "primary",
          title: t("charts:close"),
          onClick: props.onClose,
        },
      ]}
    >
      {shouldRender ? (
        props.content
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
