import { CircularProgress } from "@material-ui/core";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { TaskReports, useTaskReports } from "./data/useTaskReports";
import { Alert, useTranslation } from "@lumar/shared";
import { TaskBanner } from "./TaskBanner";
import { ReportGrid } from "../report/report-rows/report-grid/ReportGrid";
import { ReportEntity } from "../report/Report.types";
import { useParams } from "react-router-dom";
import { useCrawlContext } from "../crawl-overview/CrawlContext";
import { CrawlSelector } from "../crawl-selector/CrawlSelector";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { ReportTypeCode } from "../graphql";
import { useTaggedTaskReportsRowCount } from "./data/useTaggedTaskReportsRowCount";

export function TaskReport(): JSX.Element {
  const { t } = useTranslation("taskReport");
  const { accountId, projectId, taskId } = useParams<{
    accountId: string;
    projectId: string;
    taskId: string;
  }>();

  const { loading, error, task } = useTaskReports();
  const { loading: crawlContextLoading } = useCrawlContext();

  if (loading || crawlContextLoading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  if (error) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{error.message}</Alert>
        </div>
      </>
    );
  }

  if (!task) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="warning">{t("taskNotFound")}</Alert>
        </div>
      </>
    );
  }

  const isTaggedTask = Boolean(task.customReportTemplates.nodes.length);

  return (
    <>
      <TaskBanner
        description={task.description || ""}
        priority={task.priority}
        status={task.status}
      />
      <TopNavigation
        title={task?.title}
        breadcrumbs={[{ label: task.title }]}
        availableForShare
        actions={[
          <CrawlSelector
            key="crawl-select"
            getPath={(crawlId) =>
              Routes.TaskReport.getUrl({
                accountId,
                projectId,
                crawlId,
                taskId,
              })
            }
          />,
        ]}
      />
      {isTaggedTask ? (
        <TaggedTaskReport task={task} />
      ) : (
        <UntaggedTaskReport task={task} />
      )}
    </>
  );
}

function TaggedTaskReport({
  task,
}: {
  task: Required<TaskReports>["task"];
}): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();
  const { t } = useTranslation("taskReport");
  const params = useURLSearchParams();

  const allReportTemplate = task.customReportTemplates.nodes.find((x) =>
    x.code.startsWith("task_all_"),
  );
  const unresolvedReportTemplate = task.customReportTemplates.nodes.find((x) =>
    x.code.startsWith("task_unresolved_"),
  );
  const resolvedReportTemplate = task.customReportTemplates.nodes.find((x) =>
    x.code.startsWith("task_resolved_"),
  );
  const newReportTemplate = task.customReportTemplates.nodes.find((x) =>
    x.code.startsWith("task_new_"),
  );

  const type = params.get("type");

  const { error, reportsNotFound, rowCounts } = useTaggedTaskReportsRowCount({
    allReportTemplateId: allReportTemplate?.id,
    unresolvedReportTemplateId: unresolvedReportTemplate?.id,
    resolvedReportTemplateId: resolvedReportTemplate?.id,
    newReportTemplateId: newReportTemplate?.id,
  });

  if (
    !allReportTemplate ||
    !unresolvedReportTemplate ||
    !resolvedReportTemplate ||
    !newReportTemplate
  ) {
    return (
      <Alert severity="warning" style={{ marginTop: 8 }}>
        {t("noReportTemplate")}
      </Alert>
    );
  }

  if (reportsNotFound) {
    return (
      <Alert severity="warning" style={{ marginTop: 8 }}>
        {t("taggedTaskReportNotAvailable")}
      </Alert>
    );
  }

  const reportTemplate =
    type === "unresolved"
      ? unresolvedReportTemplate
      : type === "resolved"
        ? resolvedReportTemplate
        : type === "new"
          ? newReportTemplate
          : allReportTemplate;

  return (
    <ReportGrid
      reportInput={{
        reportEntity: ReportEntity.CustomReport,
        crawlId,
        customReportTemplateId: reportTemplate.id,
        reportTemplateCode: reportTemplate.code,
        reportTypeCode: ReportTypeCode.Basic,
      }}
      hideTaskMenu
      hideCreateCustomReport
      tabsMode={[
        {
          code: "all",
          label: t("reportAllLabel"),
          value: rowCounts.all,
          isSelected: type === "all",
        },
        {
          code: "unresolved",
          label: t("reportUnresolvedLabel"),
          value: rowCounts.unresolved,
          isSelected: type === "unresolved",
        },
        {
          code: "resolved",
          label: t("reportResolvedLabel"),
          value: rowCounts.resolved,
          isSelected: type === "resolved",
        },
        {
          code: "new",
          label: t("reportNewLabel"),
          value: rowCounts.new,
          isSelected: type === "new",
        },
      ]}
      onTabChange={(tab) => {
        params.set("type", tab);
        params.navigate();
      }}
      error={error}
    />
  );
}

function UntaggedTaskReport({
  task,
}: {
  task: Required<TaskReports>["task"];
}): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();
  const { t } = useTranslation("taskReport");
  const params = useURLSearchParams();

  const reportTemplateCode = task.reportTemplate?.code;
  const reportTypeCode =
    getReportTypeCode(params.get("type")) ?? task.reportType?.code;

  if (!reportTemplateCode || !reportTypeCode) {
    return <Alert severity="warning">{t("noReportTemplate")}</Alert>;
  }

  return (
    <ReportGrid
      reportInput={{
        reportEntity: ReportEntity.ReportStat,
        crawlId,
        reportTemplateCode,
        reportTypeCode,
        segmentId: task.segment?.id,
      }}
      baseFilter={task.connectionFilter}
      hideTaskMenu
      hideCreateCustomReport
      onTabChange={(tab) => {
        params.set("type", tab);
        params.navigate();
      }}
    />
  );
}

function getReportTypeCode(value: string | null): ReportTypeCode | undefined {
  return Object.values(ReportTypeCode).find((x) => x.toLowerCase() === value);
}
