import { ReportEntity, ReportInput } from "./Report.types";

export const createPersistenceKeyFromReportInput = (
  reportInput: ReportInput,
): string => {
  switch (reportInput.reportEntity) {
    case ReportEntity.ReportStat:
      return reportInput.reportTemplateCode;
    case ReportEntity.CustomReport:
      return `custom_report_${reportInput.customReportTemplateId}`;
    default:
      console.error("Unimplemented report entity id creation");
      return "";
  }
};
