import {
  Alert,
  Chip,
  formToCheckboxProps,
  ToggleIconButton,
  Typography,
} from "@lumar/shared";
import { Button, Grid, Link, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { FastField, FastFieldProps, Field, FieldArray } from "formik";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Label } from "../../../../_common/components/Label";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { ScriptEditor, ScriptRejectionEditor } from "../../components/Editors";
import { JsRenderingCheckbox } from "../../components/JsRenderingCheckbox";
import { withDisplayValue } from "../../components/TextFieldInput";
import { FormValues } from "../data/types";
import { SettingsContext } from "../data/useContextValues";
import {
  CUSTOM_REJECTION_MAX,
  SCRIPT_RESOURCE_MAX,
} from "../data/useSettingsValidationSchema";
import { SettingControlFactory } from "../useAdvancedControls";
import { ModuleCode } from "../../../../graphql";

export const getJavaScriptRenderingControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.javaScriptRendering.title"),
  path: "scriptRendering",
  // eslint-disable-next-line react/display-name
  control: () => <JavaScriptRendering />,
  testId: "crawl-settings-advanced-js-rendering",
  isNew: false,
  activeValues: ["enable"],
  openOnInitValues: ["enable"],
  textForSearch: createTextForSearch([
    t("settings.javaScriptRendering.title"),
    t("settings.javaScriptRendering.description"),
    t("jsRender"),
    t("settings.javaScriptRendering.blockAdScriptsLabel"),
    t("settings.javaScriptRendering.blockAdScriptsInfo"),
    t("settings.javaScriptRendering.blockAnalyticsScriptsLabel"),
    t("settings.javaScriptRendering.blockAnalyticsScriptsInfo"),
    t("settings.javaScriptRendering.rejectionsLabel"),
    t("settings.javaScriptRendering.rejectionsInfo"),
    t("settings.javaScriptRendering.rejectionsExapmle"),
    t("settings.javaScriptRendering.rejectionsNote"),
    t("settings.javaScriptRendering.customLabel"),
    t("settings.javaScriptRendering.experimental"),
    t("settings.javaScriptRendering.customInfo"),
    t("settings.javaScriptRendering.external"),
    t("settings.javaScriptRendering.externalPlaceholder"),
    t("renderingAccessibilityScript"),
    t("settings.javaScriptRendering.flattenShadowDomInfo"),
    t("settings.javaScriptRendering.flattenShadowDomLabel"),
    t("settings.javaScriptRendering.flattenIframesInfo"),
    t("settings.javaScriptRendering.flattenIframesLabel"),
    t("settings.javaScriptRendering.renderTimeoutInfo"),
    t("settings.javaScriptRendering.renderTimeoutLabel"),
    t("settings.javaScriptRendering.block3rdPartyCookiesLabel"),
    t("settings.javaScriptRendering.block3rdPartyCookiesInfo"),
  ]),
  fieldNames: [
    ["externalResources", t("settings.javaScriptRendering.resource")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  fieldIndent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  example: {
    fontSize: "12px",
  },
  input: {
    width: "400px",
  },
  narrowerInput: {
    width: "250px",
  },
  button: {
    width: "fit-content",
  },
  link: {
    color: theme.palette.blue[600],
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  label: {
    width: "fit-content",
  },
}));

export const JavaScriptRendering = React.memo(JavaScriptRenderingInner);

function JavaScriptRenderingInner(): JSX.Element {
  const { t } = useTranslation(["crawlSettings", "navigation"]);
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  return (
    <>
      <Typography variant="caption" style={{ marginBottom: "8px" }}>
        <Trans
          ns="crawlSettings"
          i18nKey="settings.javaScriptRendering.description"
          values={{
            crawlerIpAddresses: contextValues.crawlerIpAddresses.join(", "),
          }}
          components={{
            infoLink: (
              <Link
                href={t("navigation:javascriptRenderingProductGuideLink")}
                target="_blank"
                className={classes.link}
              />
            ),
          }}
        />
      </Typography>

      {contextValues.module.code !== ModuleCode.Accessibility && (
        <FastField name="spider.scriptRendering.enable">
          {({ form, field }: FastFieldProps) => (
            <JsRenderingCheckbox
              {...formToCheckboxProps(form, field.name)}
              hasJsRendering={contextValues.accountHasJSRenderingEnabled}
            />
          )}
        </FastField>
      )}

      <FastField
        name="spider.scriptRendering.flattenShadowDom"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.javaScriptRendering.flattenShadowDomLabel",
              )}
              info={t(
                "crawlSettings:settings.javaScriptRendering.flattenShadowDomInfo",
              )}
            />
          ),
        }}
        data-testid="flatten-shadow-dom"
      />

      {/**
       * This one needs to be a Field instead of FastField because it needs to update
       * if it's disabled or not depending on the form values, and FastField doesn't
       * listen for this value change on other fields from the form
       */}
      <Field
        name="spider.scriptRendering.flattenIframes"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.javaScriptRendering.flattenIframesLabel",
              )}
              info={t(
                "crawlSettings:settings.javaScriptRendering.flattenIframesInfo",
              )}
            />
          ),
        }}
        data-testid="flatten-iframes"
      />

      <FastField
        name="spider.scriptRendering.blockAdScripts"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.javaScriptRendering.blockAdScriptsLabel",
              )}
              info={t(
                "crawlSettings:settings.javaScriptRendering.blockAdScriptsInfo",
              )}
            />
          ),
        }}
        data-testid="block-ad-scripts"
      />

      <FastField
        name="spider.scriptRendering.blockAnalyticsScripts"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.javaScriptRendering.blockAnalyticsScriptsLabel",
              )}
              info={t(
                "crawlSettings:settings.javaScriptRendering.blockAnalyticsScriptsInfo",
              )}
            />
          ),
        }}
        data-testid="block-analytics-scripts"
      />

      <FastField
        name="spider.scriptRendering.block3rdPartyCookies"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.javaScriptRendering.block3rdPartyCookiesLabel",
              )}
              info={t(
                "crawlSettings:settings.javaScriptRendering.block3rdPartyCookiesInfo",
              )}
            />
          ),
        }}
        data-testid="block-3rd-party-cookies"
      />

      <Label
        label={t(
          "crawlSettings:settings.javaScriptRendering.renderTimeoutLabel",
        )}
        info={t("crawlSettings:settings.javaScriptRendering.renderTimeoutInfo")}
        className={classes.fieldIndent}
        htmlFor="renderTimeout"
      />
      <FieldEntry
        id="renderTimeout"
        name="spider.scriptRendering.renderTimeout"
        type="number"
        valueFormater={(value) =>
          t("crawlSettings:settings.reportSettings.valueSecond", { value })
        }
        pattern={/^(\+?\d+)?$/}
        data-testid="render-timeout"
      />

      <Label
        label={t("crawlSettings:settings.javaScriptRendering.rejectionsLabel")}
        info={t("crawlSettings:settings.javaScriptRendering.rejectionsInfo")}
        className={classes.indent}
      />
      <code className={clsx(classes.example)} style={{ marginBottom: "8px" }}>
        {t("crawlSettings:settings.javaScriptRendering.rejectionsExapmle")}
      </code>
      <Alert severity="info" size="small" style={{ marginTop: "8px 0" }}>
        {t("crawlSettings:settings.javaScriptRendering.rejectionsNote")}
      </Alert>

      <FastField name="spider.scriptRendering.customRejections">
        {(props: FastFieldProps<string>) => (
          <ScriptRejectionEditor
            name="custom-rejections"
            value={props.field.value}
            onChange={(newValue) =>
              props.form.setFieldValue(props.field.name, newValue)
            }
            readOnly={props.form.isSubmitting}
            debounceChangePeriod={300}
            className={classes.indent}
            maxLines={CUSTOM_REJECTION_MAX}
          />
        )}
      </FastField>

      <div className={classes.row}>
        <Label
          label={t("crawlSettings:settings.javaScriptRendering.customLabel")}
          info={t("crawlSettings:settings.javaScriptRendering.customInfo")}
        />
        <Chip
          label={t("crawlSettings:settings.javaScriptRendering.experimental")}
          size="small"
          color="primary"
          style={{ marginBottom: "4px" }}
        />
      </div>

      <FastField name="spider.scriptRendering.customScript">
        {(props: FastFieldProps<string>) => (
          <ScriptEditor
            name="custom-javascript"
            value={props.field.value}
            onChange={(newValue) =>
              props.form.setFieldValue(props.field.name, newValue)
            }
            readOnly={props.form.isSubmitting}
            debounceChangePeriod={300}
            className={classes.indent}
          />
        )}
      </FastField>

      {contextValues.module.code === ModuleCode.Accessibility && (
        <>
          <Label
            label={t("crawlSettings:renderingAccessibilityScript")}
            className={classes.indent}
          />
          <FastField name="spider.scriptRendering.customAccessibilityScript">
            {(props: FastFieldProps<string>) => (
              <ScriptEditor
                name="custom-accessibility-javascript"
                value={props.field.value}
                onChange={(newValue) =>
                  props.form.setFieldValue(props.field.name, newValue)
                }
                readOnly={props.form.isSubmitting}
                debounceChangePeriod={300}
                className={classes.indent}
              />
            )}
          </FastField>
        </>
      )}

      <Typography variant="subtitle2Medium" className={classes.indent}>
        {t("crawlSettings:settings.javaScriptRendering.external")}
      </Typography>
      <FieldArray
        name="spider.scriptRendering.externalResources"
        render={({ form, push, remove }) => {
          const values = (form.values as FormValues).spider.scriptRendering
            .externalResources;
          return (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => push("")}
                disabled={
                  form.isSubmitting || values.length >= SCRIPT_RESOURCE_MAX
                }
                className={clsx(classes.button, classes.indent)}
                startIcon={<AddIcon />}
                data-testid="external-resource-add"
              >
                {t("crawlSettings:settings.javaScriptRendering.addResource")}
              </Button>
              {values.map((_: unknown, idx: number) => (
                <Grid
                  container
                  key={idx}
                  alignItems="flex-start"
                  style={{ marginTop: "8px" }}
                >
                  <Grid item>
                    <FastField
                      name={`spider.scriptRendering.externalResources[${idx}]`}
                      component={TextFieldWithOnBlurUpdate}
                      hiddenLabel
                      variant="outlined"
                      placeholder={t(
                        "crawlSettings:settings.javaScriptRendering.externalPlaceholder",
                      )}
                      className={classes.input}
                      data-testid="external-resource"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={t(
                        "crawlSettings:settings.javaScriptRendering.externalRemove",
                      )}
                      placement="top"
                      arrow
                    >
                      <ToggleIconButton
                        size="large"
                        variant="outlined"
                        colorVariant="red"
                        onClick={() => remove(idx)}
                        disabled={form.isSubmitting}
                        data-testid="external-resource-remove"
                        style={{ marginLeft: "8px" }}
                      >
                        <DeleteIcon />
                      </ToggleIconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
            </>
          );
        }}
      />
    </>
  );
}

function FieldEntry({
  valueFormater,
  ...props
}: Partial<Parameters<typeof TextFieldWithOnBlurUpdate>[0]> & {
  name: string;
  pattern: RegExp;
  valueFormater?: (value: string) => string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <FastField
      {...props}
      component={TextFieldWithOnBlurUpdate}
      hiddenLabel
      variant="outlined"
      className={classes.narrowerInput}
      InputProps={{
        inputComponent: valueFormater
          ? withDisplayValue(valueFormater)
          : undefined,
      }}
    />
  );
}
