import { makeStyles } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "@lumar/shared";
import { Routes } from "../../../../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  viewAllLink: {
    backgroundColor: "#EBEFF3",
    color: theme.palette.primary.main,
    fontSize: 13,
    textTransform: "uppercase",
    textDecoration: "none",
    position: "absolute",
    borderTop: "solid 1px #D1D5DB",
    width: "100%",
    height: 55,
    padding: 19,
    bottom: 0,
    left: 0,
  },
}));

function viewAllProjectsHref({ accountId }: { accountId?: string }): string {
  if (accountId) {
    return Routes.Projects.getUrl({ accountId });
  }
  return "#";
}

export function ViewAllLink(): JSX.Element {
  const { t } = useTranslation("sidebar");
  const classes = useStyles();
  const params = useParams<{ accountId?: string; projectId?: string }>();
  return (
    <Link className={classes.viewAllLink} to={viewAllProjectsHref(params)}>
      {t("viewAll")}
    </Link>
  );
}
