import {
  DestructiveMenuActionConfirmation,
  DotsHorizontalOutlined,
  Snackbar,
  SnackbarProps,
  ToggleIconButton,
  TrashOutlined,
  UnarchiveIcon,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useSnackbar, SnackbarKey } from "notistack";
import React, { useState } from "react";
import {
  CrawlStatus,
  useDeleteCrawlMutation,
  useUnArchiveCrawlMutation,
} from "../../../graphql";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  defaultIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(20),
  },
  removeIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.red[600],
    fontSize: theme.typography.pxToRem(20),
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export function ActionsItems(props: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlsList");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setIsConfirmingDeletion(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const showSnack = (
    t?: string,
    variant?: SnackbarProps["variant"],
  ): SnackbarKey => {
    return enqueueSnackbar(<Snackbar variant={variant} title={t} />);
  };

  const updateList = {
    refetchQueries: ["CrawlsListTable"],
    awaitRefetchQueries: true,
  };
  const [remove] = useDeleteCrawlMutation(updateList);
  const [unarchive] = useUnArchiveCrawlMutation(updateList);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div ref={containerRef}>
      <ToggleIconButton onClick={handleClick} data-testid="actions-button">
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={!!anchorEl && !isConfirmingDeletion}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
        // Explanation why it's `null` here: https://github.com/mui-org/material-ui/issues/7961
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        classes={{ paper: classes.menu }}
      >
        {props.row.statusEnum === CrawlStatus.Archived ? (
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              handleClose();
              try {
                await unarchive({ variables: { crawlId: props.id } });
                showSnack(t("tableColumns.unarchiveMessage"));
              } catch (e) {
                showSnack((e as Error).message, "error");
              }
            }}
            data-testid="unarchive-option"
          >
            <UnarchiveIcon className={classes.defaultIcon}></UnarchiveIcon>
            <Typography variant="body2">
              {t("tableColumns.unarchive")}
            </Typography>
          </MenuItem>
        ) : null}
        <MenuItem
          className={classes.menuItem}
          onClick={() => setIsConfirmingDeletion(true)}
          data-testid="remove-option"
        >
          <TrashOutlined className={classes.removeIcon} />
          <Typography variant="body2">{t("tableColumns.remove")}</Typography>
        </MenuItem>
      </Menu>
      {isConfirmingDeletion ? (
        <DestructiveMenuActionConfirmation
          title={t("actions.deleteTitle")}
          description={t("actions.deleteDescription")}
          confirmText={t("actions.deleteConfirm")}
          cancelText={t("actions.deleteCancel")}
          anchorEl={anchorEl}
          onConfirm={async () => {
            try {
              await remove({
                variables: { crawlId: props.id },
                refetchQueries: ["CrawlsListTable", "ProjectSummary"],
                awaitRefetchQueries: true,
              });
              showSnack(t("tableColumns.deleteMessage"));
            } catch (e) {
              showSnack((e as Error).message, "error");
            } finally {
              if (containerRef.current) {
                setIsConfirmingDeletion(false);
                setAnchorEl(null);
              }
            }
          }}
          onCancel={handleClose}
        />
      ) : null}
    </div>
  );
}
