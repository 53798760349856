import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { ChartPanelTitle } from "./ChartPanelTitle";
const useStyles = makeStyles((theme) => ({
    doublePanel: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            background: theme.palette.background.paper,
            borderRadius: "8px",
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
        },
        [theme.breakpoints.down("md")]: {
            gap: 15,
        },
    },
    panel: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        height: "430px",
        [theme.breakpoints.down("md")]: {
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
            background: theme.palette.background.paper,
            borderRadius: "8px",
            width: "100%",
        },
    },
    titleItem: {
        display: "flex",
        alignItems: "center",
        height: "63px",
    },
    chartItem: { padding: 15, height: "100%" },
}));
export function DoubleChartContainer(props) {
    const classes = useStyles();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: classes.doublePanel, children: [_jsxs("div", { className: classes.panel, "data-pendo": "healthscore-main-panel", "data-testid": "healthscore-main-panel", children: [_jsx("div", { className: classes.titleItem, children: _jsx(ChartPanelTitle, { title: props.leftColumn.title, subtitle: props.leftColumn.subtitle, description: props.leftColumn.description, pendoActionsAnchorId: "an-health-scores-heading", children: props.leftColumn.header }) }), _jsx("div", { className: classes.chartItem, children: props.leftColumn.content })] }), _jsxs("div", { className: classes.panel, "data-pendo": "healthscore-errors-panel", "data-testid": "healthscore-errors-panel", children: [_jsx("div", { className: classes.titleItem, children: _jsx(ChartPanelTitle, { title: props.rightColumn.title, subtitle: props.rightColumn.subtitle, description: props.rightColumn.description, children: props.rightColumn.header }) }), _jsx("div", { className: classes.chartItem, children: props.rightColumn.content })] })] }) }));
}
