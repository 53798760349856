import { useTranslation } from "@lumar/shared";

export function BooleanPresenter(props: { value: boolean }): JSX.Element {
  const { t } = useTranslation("common");
  const valueType = typeof props.value;

  if (valueType === "undefined") {
    return <>-</>;
  } else {
    return <>{props.value ? t("yes") : t("no")}</>;
  }
}
