import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  ChartConfig,
  TileChartConfigItem,
} from "../../../_common/charts/types/ChartConfig";
import { useDateFormatter, useSession } from "@lumar/shared";
import { newCategoriesChartsMap } from "./new/newCategoriesChartsMap";
import { useCrawlOverviewContextData } from "../../CrawlOverviewContext";
import { ModuleCode } from "../../../graphql";
import { PredefinedChartConfig, isPredefinedChartConfig } from "./types";
import { ChartConfigItemBaseShared } from "../../../_common/charts/types/ChartConfigItemBase";

export function useReportCategoryChartConfig(): {
  tileCharts: TileChartConfigItem[][];
  mainCharts: (ChartConfig | PredefinedChartConfig)[];
} {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();
  const { hasFeatureFlagEnabled } = useSession();

  const { selectedCategory, moduleCode } = useCrawlOverviewContextData();

  const predefinedCharts = newCategoriesChartsMap.get(selectedCategory.code);

  if (!predefinedCharts) return { tileCharts: [], mainCharts: [] };

  function moduleFilter(chart: { supportedModules?: ModuleCode[] }): boolean {
    return (chart.supportedModules || [ModuleCode.Seo]).includes(moduleCode);
  }

  // FIXME: remove after feature is public
  function cruxFilter(chart: ChartConfigItemBaseShared): boolean {
    if (chart.icon !== "crux") return true;
    return hasFeatureFlagEnabled("crux-charts");
  }

  const tileChartConfigs = predefinedCharts.tileCharts || [];

  return {
    tileCharts: (isNestedArray(tileChartConfigs)
      ? tileChartConfigs
      : [tileChartConfigs]
    )
      .map((charts) =>
        charts
          .map((configItem) => configItem(t, theme, dateFormatter))
          .filter(moduleFilter)
          .filter(cruxFilter),
      )
      .filter((x) => x.length),
    mainCharts: predefinedCharts.mainCharts
      .map((configItem) =>
        isPredefinedChartConfig(configItem)
          ? {
              ...configItem,
              supportedModules:
                configItem.supportedModules || Object.values(ModuleCode),
            }
          : configItem(t, theme, dateFormatter),
      )
      .filter(moduleFilter)
      .filter(cruxFilter),
  };
}

function isNestedArray<T>(value: T[] | T[][]): value is T[][] {
  return Boolean(value.length && Array.isArray(value[0]));
}
