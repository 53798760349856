import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityTablesAChart } from "../../../../../_common/charts/config/getAccessibilityTablesAChart";
import { getAccessibilityTablesATrend } from "../../../../../_common/charts/config/getAccessibilityTablesATrend";

export const generateAccessibilityTablesACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityTablesAChart,
      getAccessibilityTablesATrend,
    ],
  };
