/* eslint-disable i18next/no-literal-string */
import { useDateFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { mapFilterRuleFormValuesToNames } from "../_common/connection-filtering/helpers";
import {
  FilterOrRuleFormValue,
  NarrowMetric,
} from "../_common/connection-filtering/types";
import { TruncateText } from "../_common/truncate-text/TruncateText";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: theme.palette.yellow[300],
    font: "Courier New",
  },
}));

export function SegmentRulesVisualizer(props: {
  metrics: NarrowMetric[];
  rules: FilterOrRuleFormValue[];
}): JSX.Element {
  const dateFormatter = useDateFormatter();

  const styles = useStyles();
  const shouldUseParenthesis =
    props.rules.length > 1 && props.rules.find((a) => a._and.length > 1);

  return (
    <TruncateText>
      {shouldUseParenthesis ? <>(</> : null}
      {props.rules.map((or, i) => {
        return (
          <React.Fragment key={i}>
            {or._and.map((and, j) => {
              const {
                metricName,
                predicateName,
                predicateValue,
                predicateType,
              } = mapFilterRuleFormValuesToNames(
                props.metrics,
                and.metricCode,
                and.predicateKey,
                and.predicateValue,
                dateFormatter,
              );

              const isString = predicateType === "String";

              return (
                <React.Fragment
                  key={`${predicateType}-${predicateName}-${predicateValue}`}
                >
                  {metricName} {predicateName}{" "}
                  <span className={isString ? styles.highlight : undefined}>
                    {isString
                      ? predicateValue.replace(/\s/g, "⎵")
                      : predicateValue}
                  </span>
                  {j !== or._and.length - 1 ? " AND " : ""}
                </React.Fragment>
              );
            })}
            {i !== props.rules.length - 1 ? (
              shouldUseParenthesis ? (
                <>) OR (</>
              ) : (
                <> OR </>
              )
            ) : null}
          </React.Fragment>
        );
      })}
      {shouldUseParenthesis ? <>)</> : null}
    </TruncateText>
  );
}
