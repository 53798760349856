import { TFunction } from "i18next";
import { CrawlType, CrawlTypeCount } from "../../../graphql";

const crawlTypePriority: Partial<Record<CrawlType, number>> = {
  Web: 1,
  Sitemap: 2,
  GoogleSearchConsole: 3,
  GoogleAnalytics: 4,
  Backlinks: 5,
  LogSummary: 6,
  List: 7,
  CustomMetricsUpload: 8,
};

export function sortCrawlTypesByPriority(
  crawlTypeCounts: CrawlTypeCount[],
  providedCrawlTypes: CrawlType[],
): CrawlType[] {
  const supportedCrawlTypeCounts =
    crawlTypeCounts.filter(
      ({ crawlTypes }) =>
        crawlTypes.length === 1 && providedCrawlTypes.includes(crawlTypes[0]),
    ) ?? [];

  // eslint-disable-next-line fp/no-mutating-methods
  return [...supportedCrawlTypeCounts]
    .filter(({ count }) => count > 0)
    .sort(
      (a, b) =>
        (crawlTypePriority[a.crawlTypes[0]] ?? 100) -
        (crawlTypePriority[b.crawlTypes[0]] ?? 100),
    )
    .map((crawlTypeNode) => crawlTypeNode.crawlTypes[0]);
}

export function getCrawlTypeName(
  t: TFunction<"charts">,
  crawlType: CrawlType,
): string {
  switch (crawlType) {
    case CrawlType.Web:
      return t("vennDiagram.crawlType.Web");
    case CrawlType.Sitemap:
      return t("vennDiagram.crawlType.Sitemap");
    case CrawlType.GoogleSearchConsole:
      return t("vennDiagram.crawlType.GoogleSearchConsole");
    case CrawlType.GoogleAnalytics:
      return t("vennDiagram.crawlType.GoogleAnalytics");
    case CrawlType.Backlinks:
      return t("vennDiagram.crawlType.Backlinks");
    case CrawlType.LogSummary:
      return t("vennDiagram.crawlType.LogSummary");
    case CrawlType.List:
      return t("vennDiagram.crawlType.List");
    case CrawlType.CustomMetricsUpload:
      return t("vennDiagram.crawlType.CustomMetricsUpload");
    default:
      return crawlType;
  }
}

export const crawlTypeOrder = [
  CrawlType.Web,
  CrawlType.Sitemap,
  CrawlType.GoogleSearchConsole,
  CrawlType.GoogleAnalytics,
  CrawlType.Backlinks,
  CrawlType.LogSummary,
  CrawlType.List,
];
