var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, makeStyles, Tooltip } from "@material-ui/core";
import { CrawlType } from "../../graphql";
import { GlobeSolid, ViewListOutlined, TreeSolid, GoogleAnalyticsSolid, LinkSolid, SearchSolid, DocumentTextSolid, } from "../../icons";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    list: (props) => ({
        display: "flex",
        flexWrap: "nowrap",
        width: "auto",
        flexDirection: props.direction === "vertical" ? "column" : "row",
        justifyContent: props.align === "left" ? "flex-start" : "flex-end",
        color: theme.palette.grey[700],
    }),
    listItem: {
        lineHeight: 0,
        marginRight: theme.spacing(0.5),
        "&:last-child": {
            marginRight: 0,
        },
    },
    icon: (props) => ({
        fontSize: theme.typography.pxToRem(props.size === "medium" ? 18 : 15),
        lineHeight: 0,
    }),
    iconInactive: {
        color: theme.palette.grey[200],
    },
}));
export function CrawlSourcesIcons(_a) {
    var { sources, hideInactive, className, size = "medium", direction = "horizontal", align = "left" } = _a, props = __rest(_a, ["sources", "hideInactive", "className", "size", "direction", "align"]);
    const classes = useStyles({ size, direction, align });
    return (_jsx(Grid, Object.assign({ container: true, className: clsx(classes.list, className) }, props, { children: crawlTypes.map(([crawlType, Icon]) => {
            const source = sources === null || sources === void 0 ? void 0 : sources.find((source) => source.code === crawlType);
            const active = Boolean(source === null || source === void 0 ? void 0 : source.active);
            if (!source)
                return null;
            if (hideInactive && !active)
                return null;
            return (_jsx(Grid, { item: true, className: classes.listItem, children: _jsx(Tooltip, { title: source.name, placement: "bottom", arrow: true, children: _jsx(Icon, { className: clsx(classes.icon, {
                            [classes.iconInactive]: !active,
                        }), "data-testid": getGetTestId(crawlType) }) }) }, crawlType));
        }) })));
}
const crawlTypes = [
    [CrawlType.Web, GlobeSolid],
    [CrawlType.List, ViewListOutlined],
    [CrawlType.Sitemap, TreeSolid],
    [CrawlType.GoogleAnalytics, GoogleAnalyticsSolid],
    [CrawlType.Backlinks, LinkSolid],
    [CrawlType.GoogleSearchConsole, SearchSolid],
    [CrawlType.LogSummary, DocumentTextSolid],
];
function getGetTestId(crawlType) {
    return (crawlType.replace(/[A-Z]/g, (match, idx) => (idx > 0 ? "-" : "") + match.toLowerCase()) + "-icon");
}
