import { Tooltip, useTheme, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { InformationCircleOutlined } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  infoCircle: {
    position: "relative",
    top: "7px",
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(0.375),
    color: theme.palette.grey[400],
  },
}));

export function TextMetricLengthInPixels(props: {
  px: number;
  max?: number;
}): JSX.Element {
  const theme = useTheme();
  const negativeColor = theme.palette.red[600];
  const positiveColor = theme.palette.green[600];
  const { t } = useTranslation(["units", "resourceDetail"]);
  const classes = useStyles();

  if (props.max) {
    if (props.px > props.max) {
      return (
        <>
          <span style={{ color: negativeColor }}>
            {t("units:pixel", { count: props.px })}
          </span>
          <Tooltip
            title={t("resourceDetail:textMetricPixelsAboveThreshold", {
              max: props.max,
            })}
            placement="top"
            arrow
          >
            <InformationCircleOutlined className={classes.infoCircle} />
          </Tooltip>
        </>
      );
    }
    return (
      <>
        <span style={{ color: positiveColor }}>
          {t("units:pixel", { count: props.px })}
        </span>
        <Tooltip
          title={t("resourceDetail:textMetricPixelsWithinThreshold", {
            max: props.max,
          })}
          placement="top"
          arrow
        >
          <InformationCircleOutlined className={classes.infoCircle} />
        </Tooltip>
      </>
    );
  }

  return (
    <span style={{ color: theme.palette.grey[500] }}>
      {t("units:pixel", { count: props.px })}
    </span>
  );
}
