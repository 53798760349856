import { Select, TextField, useTranslation } from "@lumar/shared";
import {
  makeStyles,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";
import { ReportAdjustment } from "./ReportAdjustmentTypes";
import {
  ReportPriority,
  getReportPriority,
} from "../../../_common/report-helpers/reportPriority";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: 14,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 0,
    },
  },
  reportInput: {
    flex: 0.5,
  },
  priorityInput: {
    flex: 0.25,
  },
  impactInput: {
    flex: 0.25,
  },
}));

export function ReportAdjustmentFormFields(props: {
  formik: ReturnType<typeof useFormik<ReportAdjustment>>;
  reportTemplates: {
    code: string;
    name: string;
    totalSign?: number | null | undefined;
    totalWeight?: number | null | undefined;
  }[];
  initialTotalWeight?: number; // Used in edit context to respect a total weight returned by the api that might differ from predefined ones.
  disableReportSelection?: boolean;
}): JSX.Element {
  const { formik, reportTemplates } = props;
  const classes = useStyles();
  const { t } = useTranslation(["reportAdjustment", "report"]);
  const selectedReportTemplate = reportTemplates.find(
    (tpl) => formik.values.reportTemplateCode === tpl.code,
  );

  return (
    <div className={classes.container}>
      <FormControl
        error={!!formik.errors.reportTemplateCode}
        className={classes.reportInput}
      >
        <Autocomplete
          id="report-to-adjust"
          disabled={props.disableReportSelection}
          options={reportTemplates}
          getOptionLabel={(tpl) => tpl.name}
          getOptionSelected={(option, value) => option.code === value?.code}
          onChange={(_, option) => {
            formik.setFieldValue("totalSign", option?.totalSign ?? 0);
            formik.setFieldValue("totalWeight", option?.totalWeight ?? 0);
            formik.setFieldValue("reportTemplateCode", option?.code);
          }}
          value={selectedReportTemplate ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("reportAdjustment:report")}
              error={!!formik.errors.reportTemplateCode}
            />
          )}
        />
        <FormHelperText>{formik.errors.reportTemplateCode}</FormHelperText>
      </FormControl>
      <FormControl
        error={!!formik.errors.totalWeight}
        className={classes.priorityInput}
      >
        <Select
          label={t("reportAdjustment:priority")}
          name="totalWeight"
          id="report-to-adjust-priority"
          onChange={formik.handleChange}
          value={formik.values.totalWeight}
          error={!!formik.errors.totalWeight}
        >
          {getPriorityValues(
            selectedReportTemplate?.totalWeight ?? 0,
            props.initialTotalWeight ?? 0,
          ).map(([key, value]) => {
            return (
              <MenuItem key={key + String(value)} value={value}>
                {t(`report:${key}`)}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{formik.errors.totalWeight}</FormHelperText>
      </FormControl>
      <FormControl
        error={!!formik.errors.totalSign}
        className={classes.impactInput}
      >
        <Select
          label={t("reportAdjustment:impact")}
          name="totalSign"
          id="report-to-adjust-impact"
          onChange={formik.handleChange}
          value={formik.values.totalSign}
          error={!!formik.errors.totalSign}
        >
          <MenuItem value={-1}>{t("report:impactNegative")}</MenuItem>
          <MenuItem value={0}>{t("report:impactNeutral")}</MenuItem>
          <MenuItem value={1}>{t("report:impactPositive")}</MenuItem>
        </Select>
        <FormHelperText>{formik.errors.totalSign}</FormHelperText>
      </FormControl>
    </div>
  );
}

const predefinedPriorityValues = {
  priorityNone: 0,
  priorityLow: 0.1,
  priorityMedium: 0.3,
  priorityHigh: 0.6,
  priorityCritical: 1,
};

type PredefinedPriorityKey = keyof typeof predefinedPriorityValues;

function getDerivedPriorityKeyFromTotalWeight(
  totalWeight: number,
): PredefinedPriorityKey {
  const priority = getReportPriority(totalWeight);
  switch (priority) {
    case ReportPriority.None:
      return "priorityNone";
    case ReportPriority.Low:
      return "priorityLow";
    case ReportPriority.Medium:
      return "priorityMedium";
    case ReportPriority.High:
      return "priorityHigh";
    case ReportPriority.Critical:
      return "priorityCritical";
    default:
      return "priorityCritical";
  }
}

// If the report template or report adjustment in the api
// has a value different from predefined ones, we want to hide this fact from the user
// and map values to their nearestest priorities and replace priority values
// making weights consistent with what the api returned when not explicitly changed.
function getPriorityValues(
  reportTemplateTotalWeight: number,
  initialTotalWeight: number,
): [keyof typeof predefinedPriorityValues, number][] {
  const reportTemplateRelatedKeyToReplace =
    getDerivedPriorityKeyFromTotalWeight(reportTemplateTotalWeight);
  const initialValueRelatedKeyToReplace =
    getDerivedPriorityKeyFromTotalWeight(initialTotalWeight);

  return Object.entries({
    ...predefinedPriorityValues,
    [reportTemplateRelatedKeyToReplace]: reportTemplateTotalWeight,
    [initialValueRelatedKeyToReplace]: initialTotalWeight,
  }) as [keyof typeof predefinedPriorityValues, number][];
}
