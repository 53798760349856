import useLocalStorageState from "use-local-storage-state";
import React from "react";

export type ExpandedRow = {
  value: string;
  pageCount: number;
  children: ExpandedRow[];
};

interface Props {
  localSotrageKey: string;
  columnConfig?: string;
}

type Result = {
  root: ExpandedRow;
  addRow: (values: string[]) => void;
  loadMoreRow: (values: string[]) => void;
  removeRow: (values: string[]) => void;
};

const rootRow = { value: "", pageCount: 1, children: [] };

export function useExpandedRows({
  localSotrageKey,
  columnConfig,
}: Props): Result {
  const [expandedRows, setExpandedRows] = useLocalStorageState<{
    root: ExpandedRow;
    columnConfig?: string;
  }>(localSotrageKey, {
    defaultValue: { root: rootRow },
  });

  // Note: Since cursors cannot be reused when the column configuration changes,
  // it is necessary to reset all page count to prevent API errors.
  React.useEffect(() => {
    if (columnConfig === expandedRows.columnConfig) return;

    setExpandedRows((expandedRows) => ({
      root: resetPageCountRecursively(expandedRows.root || rootRow),
      columnConfig,
    }));
  }, [columnConfig, expandedRows.columnConfig, setExpandedRows]);

  const addRow = React.useCallback<Result["addRow"]>(
    (values) =>
      setExpandedRows((expandedRows) => ({
        root: addRowRecursively(expandedRows.root || rootRow, values),
        columnConfig: expandedRows.columnConfig,
      })),
    [setExpandedRows],
  );

  const loadMoreRow = React.useCallback<Result["loadMoreRow"]>(
    (values) =>
      setExpandedRows((expandedRows) => ({
        root: addRowRecursively(expandedRows.root || rootRow, values, true),
        columnConfig: expandedRows.columnConfig,
      })),
    [setExpandedRows],
  );

  const removeRow = React.useCallback<Result["removeRow"]>(
    (values) =>
      setExpandedRows((expandedRows) => ({
        root: removeRowRecursively(expandedRows.root || rootRow, values),
        columnConfig: expandedRows.columnConfig,
      })),
    [setExpandedRows],
  );

  const root = expandedRows.root || rootRow;

  return {
    root:
      columnConfig !== expandedRows.columnConfig
        ? resetPageCountRecursively(root)
        : root,
    addRow,
    loadMoreRow,
    removeRow,
  };
}

function addRowRecursively(
  row: ExpandedRow,
  values: string[],
  increasePageCount?: boolean,
): ExpandedRow {
  if (!values.length) {
    return {
      ...row,
      pageCount: row.pageCount + (increasePageCount ? 1 : 0),
    };
  }

  const idx = row.children.findIndex((x) => x.value === values[0]);

  if (idx !== -1) {
    return {
      ...row,
      children: [
        ...row.children.slice(0, idx),
        addRowRecursively(
          row.children[idx],
          values.slice(1),
          increasePageCount,
        ),
        ...row.children.slice(idx + 1),
      ],
    };
  } else {
    return {
      ...row,
      children: [
        ...row.children,
        addRowRecursively(
          { value: values[0], pageCount: 1, children: [] },
          values.slice(1),
          increasePageCount,
        ),
      ],
    };
  }
}

function removeRowRecursively(row: ExpandedRow, values: string[]): ExpandedRow {
  if (values.length <= 1) {
    return {
      ...row,
      children: row.children.filter(
        (x) => values.length && x.value !== values[0],
      ),
    };
  }

  const idx = row.children.findIndex((x) => x.value === values[0]);

  if (idx !== -1) {
    return {
      ...row,
      children: [
        ...row.children.slice(0, idx),
        removeRowRecursively(row.children[idx], values.slice(1)),
        ...row.children.slice(idx + 1),
      ],
    };
  } else {
    return row;
  }
}

function resetPageCountRecursively(row: ExpandedRow): ExpandedRow {
  return {
    ...row,
    pageCount: 1,
    children: row.children.map((x) => resetPageCountRecursively(x)),
  };
}
