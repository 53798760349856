import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { MultiDimensionalSeriesChart } from "../multi-dimensional-series-chart/MultiDimensionalSeriesChart";
import { MultiSeriesChart } from "../multi-series-chart/MultiSeriesChart";
import { ChartConfigItemDynamic } from "./ChartConfigItemDynamic";

export function DynamicTrendChart(props: ChartConfigItemDynamic): JSX.Element {
  const { reportStats } = useChartDataContext();

  if (reportStats[0].trend && reportStats[0].trend.length < 2) {
    return <MultiSeriesChart {...props.singleReportConfig} />;
  }

  return <MultiDimensionalSeriesChart {...props.trendReportConfig} />;
}
