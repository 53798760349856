import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    minWidth: "100%",
    alignItems: "flex-start",
  },
});

interface Props {
  children: React.ReactNode;
  ["data-testid"]?: string;
}

export function ChartWrapper(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-testid={props["data-testid"]}>
      {props.children}
    </Box>
  );
}
