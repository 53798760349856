import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, PlusCircleSolid, useTranslation } from "@lumar/shared";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  orSeparator: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    padding: theme.spacing(0.94, 2, 0.94, 2),
    margin: theme.spacing(2),
  },
  addOrStatement: {
    display: "block",
    margin: theme.spacing(2),
  },
}));

interface AddOrStatementButtonProps {
  isLastOrStatement: boolean;
  onAddOrStatement: () => void;
  disabled?: boolean;
}

export function AddOrStatementButton({
  isLastOrStatement,
  onAddOrStatement,
  disabled,
}: AddOrStatementButtonProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectionFiltering");

  if (!isLastOrStatement)
    return (
      <Typography
        className={classes.orSeparator}
        data-testid="add-or-statement"
      >
        {t("orStatement")}
      </Typography>
    );

  return (
    <Button
      onClick={onAddOrStatement}
      disabled={disabled}
      variant="outlined"
      size="small"
      startIcon={<PlusCircleSolid />}
      className={classes.addOrStatement}
      data-testid="add-or-statement"
    >
      {t("orStatement")}
    </Button>
  );
}
