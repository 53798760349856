import { Typography } from "@lumar/shared";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

export function SettingsAccordion({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles(() => ({
  accordion: {
    width: "100%",
  },
}));
