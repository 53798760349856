import React from "react";
import {
  ChartBarHorizontal,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";

import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import { ProgressDashboardKeyNumber } from "../_common/progress-dashboard-key-number/ProgressDashboardKeyNumber";
import { RunningCrawlQuery } from "../../../../graphql";
import { RunningCrawl } from "../../../useProjectPoller";

export function CrawledUrlsCard({
  project,
  runningCrawl,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const formatter = useNumberFormatter();
  const lastTotal = project?.getProject?.lastFinishedCrawl?.totalSteps ?? 0;

  return (
    <ProgressDashboardContainer>
      <ProgressDashboardKeyNumber
        icon={ChartBarHorizontal}
        label={t("progressDashboard.crawledUrlsCardTitle")}
        value={formatter(runningCrawl?.runningTotal ?? lastTotal)}
        infoText={t("progressDashboard.crawledUrlsCardTooltip")}
      />
    </ProgressDashboardContainer>
  );
}
