import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TaskTrend } from "../../task-trend/TaskTrend";

export function TrendCell(params: GridRenderCellParams): JSX.Element {
  return (
    <TaskTrend
      trend={params.row.trendResolved}
      identified={params.row.identified}
    />
  );
}
