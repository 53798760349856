/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, isObject } from "lodash";

import { GridColDef } from "@mui/x-data-grid-pro";
import { MetricType } from "../../../../graphql";
import { STRING_INTERPRETER_MAX_LENGTH } from "../columns/interpreters/StringInterpreter";
import { ReportGridColumnsResult } from "../columns/ReportGridColumns.types";
import { MetricData } from "../ReportGrid.types";

// NOTE: The grid currently doesn't support dynamic row height out of the box. In order to set a row height that high
// enough for every visible cell the function calculates the heighest cell based on the visible columns and rows.
// FIXME: In the near future, all of our metrics will be dynamic (and even defined by the user!) so we cannot afford to have
// hardcoded logic in the medium/long-term. Find a better solution which works with virtualization.
// Example with dynamic row height: https://github.com/mui-org/material-ui-x/issues/417#issuecomment-1009464126. - Tamas

const avgCharWidth = 10;
const cellMinWidth = 120;
const lineHeight = 20;
const chipLineHeight = 20;
const chipMarginV = 8;
const chipMarginH = 26;

const MAX_ROW_HEIGHT = 500;

type HeightFn = (row: Record<string, unknown>, width: number) => number;

type Props = {
  cardMetrics: ReportGridColumnsResult["cardMetrics"];
  hasFounInMetric: boolean;
  rows: any[] | undefined;
  visibleColumns: string[];
  definitions: GridColDef[];
  metricsData: MetricData[];
  isGridView: boolean;
};

export function getRowHeight(props: Props): number | undefined {
  const columns = new Set<string>();

  if (props.visibleColumns.length) {
    props.visibleColumns.forEach((column) => {
      columns.add(column);
    });

    if (!props.isGridView) {
      columns.add("card");
    }
  } else {
    columns.add("card");
  }

  const maxRowHeight = Array.from(columns).reduce((max, code) => {
    const heightFn = getHeightFn(code, props);
    const width =
      (props.definitions?.find((x) => x.field === code)?.minWidth ||
        cellMinWidth) - 40;

    const maxCellHeight =
      props.rows?.reduce((max, row) => {
        try {
          const cellHeight = heightFn(row, width);
          return Math.max(max, cellHeight);
        } catch {}
        return max;
      }, 0) || 0;

    return Math.max(max, maxCellHeight);
  }, 0);

  const paddingTopAndBottom = 12 * 2;
  return maxRowHeight ? maxRowHeight + paddingTopAndBottom : undefined;
}

export function getHeightFn(code: string, props: Props): HeightFn {
  if (code === "card") {
    return (row, width) => getCardHeight(row, width, props);
  }

  if (code === "accessibilityIssuesCountByRuleId") {
    return (row) => getAccessibilityIssuesCountByRuleId(row[code]);
  }

  if (code === "containerExecutionFailures") {
    return (row, width) => getContainerExecutionFailures(row[code], width);
  }

  if (code.startsWith("customExtraction"))
    return (row, width) => getCustomExtractionHeight(row[code], width);

  const metric = props.metricsData.find((x) => x.code === code);

  if (metric?.type === MetricType.Array) {
    return (row, width) => getArrayValueHeight(row[code], width);
  }

  if (metric?.type === MetricType.String) {
    return (row, width) => getStringValueHeight(get(row, code), width);
  }

  return () => 24;
}

function getStringValueHeight(value: any, width: number): number {
  function getHeight(value: any): number {
    const characters = Math.min(
      (typeof value === "string"
        ? value.length
        : JSON.stringify(value, null, 4)?.length) || 0,
      STRING_INTERPRETER_MAX_LENGTH,
    );

    return (
      Math.max(
        1,
        Math.ceil((characters * avgCharWidth) / (width - chipMarginH)),
      ) * lineHeight
    );
  }

  const rowHeight = Array.isArray(value)
    ? value.reduce((sum, value) => sum + getHeight(value), 0)
    : getHeight(value);

  return rowHeight > MAX_ROW_HEIGHT ? MAX_ROW_HEIGHT : rowHeight;
}

function getArrayValueHeight(value: any, minWidth: number): number {
  const value_ = value as string[] | undefined;

  return (
    value_?.reduce<number>((result, value) => {
      const height =
        Math.ceil((value.length * avgCharWidth) / minWidth) * chipLineHeight;
      return result + height + 8;
    }, 0) || 0
  );
}

function getCardHeight(row: any, width: number, props: Props): number {
  const rowGap = 5;

  const labelWidth =
    Math.max(...props.cardMetrics.map((x) => x.name.length)) * avgCharWidth;
  const valueWidth = Math.max(width - labelWidth - 16, 200);

  const metricsHeight =
    props.cardMetrics?.reduce((sum, metric) => {
      try {
        const heightFn = getHeightFn(metric.code, props);
        const metricHeight = heightFn(row, valueWidth);

        return sum + metricHeight + rowGap;
      } catch {}
      return sum;
    }, 0) || 0;

  return metricsHeight + (props.hasFounInMetric ? 20 : 0);
}

function getCustomExtractionHeight(row: any, width: number): number {
  const value = (row || []) as string[];

  function getJSON(value: any): any | undefined {
    try {
      return JSON.parse(value);
    } catch {}
  }

  function getJSONRowCount(value: any, padding: number): number {
    if (isObject(value))
      return Object.entries(value).reduce((sum, x) => {
        const keyLineCount = isObject(x[1])
          ? Math.ceil(((String(x[0]).length + padding) * avgCharWidth) / width)
          : 0;

        return (
          sum +
          keyLineCount +
          getJSONRowCount(x[1], String(x[0]).length + padding + 2)
        );
      }, 0);

    return Math.ceil(((String(value).length + padding) * avgCharWidth) / width);
  }

  const rowHeight = value.reduce((height, value) => {
    const json = getJSON(value);
    if (json) {
      return height + getJSONRowCount(json, 0) * chipLineHeight + chipMarginV;
    }

    const lines = value.split("\n");

    const rowCount = lines.reduce(
      (rowCount, line) =>
        rowCount + Math.ceil((line.length * avgCharWidth) / width),
      0,
    );
    return height + rowCount * chipLineHeight + chipMarginV;
  }, 0);

  return rowHeight > MAX_ROW_HEIGHT ? MAX_ROW_HEIGHT : rowHeight;
}

function getAccessibilityIssuesCountByRuleId(row: any): number {
  return lineHeight * (Array.isArray(row) ? row.length : 0);
}

function getContainerExecutionFailures(row: any, width: number): number {
  if (!Array.isArray(row)) return 0;

  return row.reduce((height, value) => {
    const characters =
      (value.containerName?.length || 0) +
      (value.containerId?.length || 0) +
      (value.error?.length || 0);

    return (
      height +
      Math.max(1, Math.ceil((characters * avgCharWidth) / width)) * lineHeight
    );
  }, 0);
}
