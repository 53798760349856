import { v4 as uuid } from "uuid";
import {
  ConnectionFilter,
  FilterOrRuleFormValue,
  PredicateValue,
} from "../types";

export function mapDatasourceConnectionFilterToOrFormFilters(
  filter: Required<ConnectionFilter>,
): FilterOrRuleFormValue[] {
  return filter._or.map((or) => {
    return {
      id: uuid(),
      _and: or._and.map((and) => {
        if (and.customMetrics) {
          const customMetricName = Object.keys(and.customMetrics)[0];
          const metricCode = `customMetrics.${customMetricName}`;
          const predicateKey = Object.keys(
            and.customMetrics[customMetricName],
          )[0];
          const predicateValue = (
            and.customMetrics[customMetricName] as Record<
              string,
              PredicateValue
            >
          )[predicateKey];
          return {
            id: uuid(),
            metricCode,
            predicateKey,
            predicateValue,
          };
        }

        const predicate = and as Record<string, Record<string, PredicateValue>>;
        const metricCode = Object.keys(predicate)[0];
        const predicateKey = Object.keys(predicate[metricCode])[0];
        const predicateValue =
          predicate[Object.keys(predicate)[0]][predicateKey];
        return {
          id: uuid(),
          metricCode,
          predicateKey,
          predicateValue,
        };
      }),
    };
  });
}
