import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Disabling it because "/ 100" should be universal.
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "../../../i18n";
const useStyles = makeStyles((theme) => ({
    notApplicable: {
        color: theme.palette.grey[400],
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(24),
    },
    score: {
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(24),
    },
    scoreLarge: {
        fontSize: theme.typography.pxToRem(26),
    },
    scoreSmall: {
        verticalAlign: "text-bottom",
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(20),
    },
    positive: {
        color: theme.palette.green[600],
    },
    negative: {
        color: theme.palette.red[600],
    },
    outOf: {
        color: theme.palette.grey[500],
        fontWeight: 400,
        fontSize: theme.typography.pxToRem(16),
        verticalAlign: "text-bottom",
        lineHeight: theme.typography.pxToRem(20),
    },
}));
export function HealthScoreResult(props) {
    const { t } = useTranslation("sharedCommon");
    const roundedScore = Math.floor((props.healthScore || 0) * 100);
    const roundedBenchmark = props.industryBenchmark
        ? Math.floor(props.industryBenchmark * 100)
        : undefined;
    const isPositiveResult = roundedBenchmark
        ? (roundedBenchmark === 100 && roundedScore === roundedBenchmark) ||
            roundedScore > roundedBenchmark
        : undefined;
    const classes = useStyles();
    if (props.healthScore === null) {
        return (_jsx("span", { "data-testid": "health-score-not-applicable", className: classes.notApplicable, children: t("na") }));
    }
    return (_jsxs("div", { style: {
            width: "fit-content",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            gap: 4,
        }, children: [_jsx("span", { "data-testid": "health-score-value", className: clsx(classes.score, {
                    [classes.scoreLarge]: props.size === "large",
                    [classes.scoreSmall]: props.size === "small",
                    [classes.positive]: isPositiveResult === true,
                    [classes.negative]: isPositiveResult === false,
                }), children: roundedScore }), " ", _jsx("span", { "data-testid": "health-score-out-of", className: classes.outOf, children: t("perHundred") })] }));
}
