import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilitySemanticsBPChart } from "../../../../../_common/charts/config/getAccessibilitySemanticsBPChart";
import { getAccessibilitySemanticsBPTrend } from "../../../../../_common/charts/config/getAccessibilitySemanticsBPTrend";

export const generateAccessibilitySemanticsBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilitySemanticsBPChart,
      getAccessibilitySemanticsBPTrend,
    ],
  };
