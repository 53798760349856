import { ModuleCode } from "../graphql";
import { GlobeAltSolid } from "./ui-friendly/solid";
import { AccessibilityCircle, SiteSpeedV2Icon } from "./uncategorised";
import { BasicIcon } from "./uncategorised/BasicIcon";
export const moduleIconConfig = new Map([
    [ModuleCode.Basic, BasicIcon],
    [ModuleCode.Seo, GlobeAltSolid],
    [ModuleCode.Accessibility, AccessibilityCircle],
    [ModuleCode.SiteSpeed, SiteSpeedV2Icon],
]);
