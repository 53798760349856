export enum ReportPriority {
  None = "None",
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Critical = "Critical",
}

export function getReportPriority(weight?: number | null): ReportPriority {
  if (!weight) return ReportPriority.None;
  if (weight <= 0.25) return ReportPriority.Low;
  if (weight <= 0.5) return ReportPriority.Medium;
  if (weight <= 0.75) return ReportPriority.High;
  return ReportPriority.Critical;
}
