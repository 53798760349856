/* eslint-disable react/display-name */
import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ReportStatTrendItem } from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter } from "@lumar/shared";

import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getCanonicalIssuesTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Line,
    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("canonicalIssuesTrend.title"),
    description: () => (
      <>
        <Typography paragraph variant="inherit">
          {t("canonicalIssuesTrend.description")}
        </Typography>
      </>
    ),
    reportTemplateCodesOrder: [
      "canonical_to_non_200",
      "conflicting_canonical_tags",
      "non_indexable_canonical_links_in",
      "canonicalized_noindexed_pages",
      "unlinked_canonical_pages",
      "pages_without_canonical_tag",
      "high_canonicals_in",
    ],
    reportStatFilter: (report) => {
      return (
        [
          "canonical_to_non_200",
          "conflicting_canonical_tags",
          "non_indexable_canonical_links_in",
          "canonicalized_noindexed_pages",
          "unlinked_canonical_pages",
          "pages_without_canonical_tag",
          "high_canonicals_in",
        ].includes(report.reportTemplateCode) &&
        Number(report.reportTemplateTotalSign) < 0
      );
    },
    series: (reports, { accountId, projectId, segmentId }) =>
      reports.map((report) => {
        return {
          name: report.reportTemplateName,
          reportTemplateCode: report.reportTemplateCode,
          data: report.trend
            .filter(
              (
                trend,
              ): trend is ReportStatTrendItem & {
                createdAt: string;
              } => !!trend?.createdAt,
            )
            .map((trend) => {
              const createdAt = new Date(trend.createdAt);
              return {
                x: createdAt,
                y: trend.basic ?? 0,
                url: Routes.Report.getUrl({
                  accountId,
                  projectId,
                  crawlId: getRawCrawlId(String(trend.crawlId)),
                  segmentId,
                  reportTemplateCode: report.reportTemplateCode,
                  reportTypeCode: "basic",
                }),
                additionalTooltipLines: [
                  formatter(createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                  }),
                ],
              };
            }),
        };
      }),

    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
  };
}
