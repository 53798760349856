import { useDateFormatter } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function DatePresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const dateFormatter = useDateFormatter();

  if (!value) return <>-</>;
  return (
    <>
      {dateFormatter(new Date(value), {
        dateStyle: "medium",
        timeStyle: "medium",
      })}
    </>
  );
}
