import { useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function BooleanPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("common");

  if (value === undefined) return <>-</>;

  return <>{value ? t("yes") : t("no")}</>;
}
