import {
  Trans,
  Typography,
  UsageLimitIndicator,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { CustomSkeleton } from "../../_common/CustomSkeleton";
import { useAccountsRoutes } from "../../_common/routing/accountsRoutes";
import { RoleCode } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1.375, 0),
  },
  title: {
    display: "block",
    marginBottom: theme.spacing(1.125),
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

interface Props {
  segmentCount: number;
  maxSegmentCount: number;
  loading: boolean;
}

export function SegmentsLimit({
  segmentCount,
  maxSegmentCount,
  loading,
}: Props): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("segments");
  const classes = useStyles();
  const accountsRoutes = useAccountsRoutes();
  const { hasSufficientRole } = useSession();

  if (loading) {
    return (
      <div className={classes.root}>
        <CustomSkeleton
          width="300px"
          height="18px"
          variant="rect"
          animation="wave"
        />
      </div>
    );
  }

  return (
    <UsageLimitIndicator
      usageCount={segmentCount}
      usageLimit={maxSegmentCount}
      unit={"segments"}
      limitReachedMessage={
        <>
          <Typography variant="subtitle3Medium" className={classes.title}>
            {t("segmentLimitInfoTitle")}
          </Typography>
          <Typography>
            <Trans
              ns="segments"
              i18nKey={
                hasSufficientRole(RoleCode.Admin)
                  ? "segmentLimitInfoDescription"
                  : "segmentLimitInfoDescriptionEditor"
              }
              components={{
                accountsLink: (
                  <a
                    href={accountsRoutes.Subscription.getUrl({ accountId })}
                    className={classes.link}
                  />
                ),
              }}
            />
          </Typography>
        </>
      }
    />
  );
}
