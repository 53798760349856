import { useTranslation, Button, useMutationSnackbar } from "@lumar/shared";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useFormik } from "formik";
import { Prompt, useParams } from "react-router-dom";
import { useCreateReportTemplateOverrideMutation } from "../../../graphql";
import { useReportAdjustmentFormDialogs } from "./ReportAdjustmentFormDialogsContext";
import { ReportAdjustmentFormFields } from "./ReportAdjustmentFormFields";
import { ReportAdjustment } from "./ReportAdjustmentTypes";
import { useReportAdjustmentFormValidationSchema } from "./useReportAdjustmentFormValidationSchema";
import { useConfirmDialog } from "../../../_common/hooks/useConfirmDialog";

export function CreateReportAdjustmentFormDialog(props: {
  reportTemplates: {
    code: string;
    name: string;
    totalSign?: number | null | undefined;
    totalWeight?: number | null | undefined;
  }[];
}): JSX.Element {
  const { t } = useTranslation(["common", "reportAdjustment"]);
  const { projectId } = useParams<{ projectId: string }>();
  const [createOverride] = useCreateReportTemplateOverrideMutation();
  const mutationHandler = useMutationSnackbar();
  const { isCreateDialogOpen, closeCreateDialog } =
    useReportAdjustmentFormDialogs();
  const confirm = useConfirmDialog();

  const reportAdjustmentFormDefaultInitialValues: ReportAdjustment = {
    reportTemplateCode: props.reportTemplates[0]?.code ?? "all_pages",
    totalWeight: props.reportTemplates[0]?.totalWeight ?? 0,
    totalSign: props.reportTemplates[0]?.totalSign ?? 0,
  };

  const validationSchema = useReportAdjustmentFormValidationSchema();

  const formik = useFormik({
    initialValues: reportAdjustmentFormDefaultInitialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  function handleClose(): void {
    if (formik.dirty) {
      if (confirm()) {
        closeCreateDialog();
        formik.resetForm();
      }
    } else {
      closeCreateDialog();
      formik.resetForm();
    }
  }

  async function handleSubmit(values: ReportAdjustment): Promise<void> {
    await mutationHandler(
      createOverride({
        refetchQueries: ["ReportAdjustmentPage"],
        awaitRefetchQueries: true,
        variables: { input: { projectId, ...values } },
      }),
      {
        onSuccess() {
          closeCreateDialog();
          formik.resetForm();
        },
        successMessage: t("reportAdjustment:createSuccess"),
        errorMessage: t("reportAdjustment:createError"),
      },
    );
  }

  return (
    <>
      <Prompt
        message={t("common:confirmLeaveDialogText")}
        when={formik.dirty}
      />
      <Dialog
        onClose={handleClose}
        open={isCreateDialogOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {t("reportAdjustment:createReportAdjustment")}
        </DialogTitle>
        <DialogContent>
          <ReportAdjustmentFormFields
            reportTemplates={props.reportTemplates}
            formik={formik}
            // When creating a report adjustment, we don't care
            // about the initial total weight, we only care about
            // the report template total weight so it is respected
            // if it differs from predefined total weight values.
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            disabled={formik.isSubmitting}
            onClick={handleClose}
            data-pendo="create-report-adjustment-dialog-close"
          >
            {t("common:cancel")}
          </Button>
          <Button
            type="submit"
            loading={formik.isSubmitting}
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
            data-pendo="create-report-adjustment-dialog-create"
          >
            {t("common:create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
