import React from "react";
import {
  NarrowMetric,
  FilterOrRuleFormValue,
  FilterRuleFormValue,
} from "../types";
import { FieldArray } from "formik";
import { FilterOrRuleFieldContainer } from "./FilterOrRuleFieldContainer";
import { sortMetricsAlphabetically } from "./sortMetricsAlphabetically";

interface FilterOrRuleFieldArrayProps {
  name: string;
  metrics: NarrowMetric[];
  value: FilterOrRuleFormValue[];
  defaultFilter: FilterRuleFormValue;
  allowDeletingLastEmptyRule?: boolean;
  onLastRemainingRuleDeleted?: () => void;
  autoFocus?: boolean;
}

export function FilterOrRuleFieldArray(
  props: FilterOrRuleFieldArrayProps,
): JSX.Element {
  const sortedMetrics = React.useMemo(
    () => sortMetricsAlphabetically(props.metrics),
    [props.metrics],
  );
  return (
    <FieldArray name={props.name}>
      {({ replace, remove, push, form: { isSubmitting } }) => (
        <FilterOrRuleFieldContainer
          replace={replace}
          remove={remove}
          push={push}
          name={props.name}
          value={props.value}
          metrics={sortedMetrics}
          defaultFilter={props.defaultFilter}
          allowDeletingLastEmptyRule={props.allowDeletingLastEmptyRule}
          onLastRemainingRuleDeleted={props.onLastRemainingRuleDeleted}
          autoFocus={props.autoFocus}
          disabled={isSubmitting}
        />
      )}
    </FieldArray>
  );
}
