import {
  ChartBar,
  Cube,
  HourglassLow,
  PaintBrushBroad,
  Stack,
  useTranslation,
} from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { NumberPresenter } from "../../metrics-value-presenter";
import { useOpportunityDetailsSections } from "./useOpportunityDetailsSections";
import { useTheme } from "@material-ui/core";
import { TimePresenter } from "../../metrics-value-presenter/custom-presenters/TimePresenter";

export function OpportunityDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useSummaryConfig(data.metrics);

  const { active } = useOpportunityDetailsSections(data.metrics);

  return (
    <ResourceDetailBase
      title={active.title}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");
  const theme = useTheme();

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      {
        code: "title",
        name: t("opportunity.auditName"),
      },
      {
        code: "opportunityDigest",
        name: t("opportunity.id"),
      },
      {
        code: "element",
        name: t("opportunity.item"),
      },
      {
        code: "exampleUrl",
      },
      {
        code: "description",
      },
    ],
    cardDetails: [
      {
        code: "auditId",
        icon: PaintBrushBroad,
      },
      {
        code: "urlCount",
        icon: Cube,
      },
      {
        code: "gaPageviewsSum",
        icon: HourglassLow,
      },
      {
        code: "wastedKibSum",
        name: t("opportunity.wastedKiB"),
        icon: Stack,
        value: (
          <NumberPresenter
            color={theme.palette.red[600]}
            {...getMetricProps("wastedKibSum")}
          />
        ),
      },
      {
        code: "wastedSecsSum",
        name: t("opportunity.wastedHRS"),
        icon: ChartBar,
        value: (
          <TimePresenter
            color={theme.palette.red[600]}
            {...getMetricProps("wastedSecsSum")}
          />
        ),
      },
    ],
  };
}
