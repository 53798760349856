import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "block",
  },
});

export function ProjectAutocompleteSpinner(): JSX.Element {
  const classes = useStyles();

  return (
    <CircularProgress
      data-testid="project-autocomplete-loading-spinner"
      size={20}
      classes={{ root: classes.root }}
    />
  );
}
