import { SwitchWithIcon, useTranslation } from "@lumar/shared";
import { Grid, FormControlLabel, Tooltip, useTheme } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { FastField, FastFieldProps } from "formik";
import { translationNamespace } from "../CrawlSettings";

export function DataOnlySourceSwitch(props: { name: string }): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const theme = useTheme();
  return (
    <Grid container>
      <Grid
        item
        style={{
          marginLeft: "auto",
          border: "1px solid #E5E7EB",
          borderRadius: 8,
          padding: 4,
        }}
      >
        <Grid container alignItems="center">
          <Grid item>
            <FastField name={props.name}>
              {({
                field: { value, name },
                form: { setFieldValue, isSubmitting },
              }: FastFieldProps<boolean>) => (
                <FormControlLabel
                  style={{ marginRight: 8 }}
                  control={
                    <SwitchWithIcon
                      checked={value}
                      onChange={() => {
                        setFieldValue(name, !value);
                      }}
                      disabled={isSubmitting}
                    />
                  }
                  label={t("sources.dataOnly")}
                />
              )}
            </FastField>
          </Grid>
          <Grid item>
            <Tooltip title={t("sources.dataOnlyInfo")} placement="right">
              <InfoOutlined
                style={{
                  display: "block",
                  color: theme.palette.grey[500],
                  fontSize: 21,
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
