import React from "react";
import { Button, TimeZoneContext, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { addMinutes } from "date-fns";
import { TimePicker } from "./TimePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1),
  },
  closeButton: {
    marginLeft: theme.spacing(1),
  },
  removeButton: {
    marginLeft: "auto",
  },
}));

export function TimeSelector({
  date,
  onChange,
  onClose,
  onRemove,
}: {
  date: Date | undefined;
  onChange: (value: Date) => void;
  onClose: () => void;
  onRemove: () => void;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const { timeZone } = React.useContext(TimeZoneContext);

  const now = new Date();
  const timeZoneRelativeOffset =
    now.getTimezoneOffset() + (timeZone?.offset || 0);

  const value = date && addMinutes(date, timeZoneRelativeOffset);

  const minValue = addMinutes(now, timeZoneRelativeOffset + 1);

  return (
    <div className={classes.root} data-testid="crawl-schedule-time-selector">
      <TimePicker
        value={value}
        onChange={(value: Date) => {
          const date = new Date(value);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);

          onChange(addMinutes(date, -timeZoneRelativeOffset));
        }}
        noMinutes
        minValue={minValue}
      />
      <div className={classes.buttons}>
        <Button
          onClick={onClose}
          variant="outlined"
          size="small"
          className={classes.closeButton}
          data-testid="crawl-schedule-time-select-close"
        >
          {t("settings.closeSchedule")}
        </Button>
        <Button
          onClick={onRemove}
          variant="outlined"
          size="small"
          className={classes.removeButton}
          data-testid="crawl-schedule-time-select-remove"
        >
          {t("settings.removeSchedule")}
        </Button>
      </div>
    </div>
  );
}
