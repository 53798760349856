import React, { ReactNode } from "react";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1),
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    height: 390,
    display: "flex",
  },
}));

export function ChartPanel(props: { children: ReactNode }): JSX.Element {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      {props.children}
    </Paper>
  );
}
