import {
  ErrorsChartConfigItem,
  GenerateErrorsChartConfigProps,
} from "../../../crawl-overview/dashboard/config/types";

export function getSiteSpeedCLSErrorsChart({
  theme,
  t,
}: GenerateErrorsChartConfigProps): ErrorsChartConfigItem {
  return {
    columns: [
      "total",
      "name",
      {
        field: "layoutShift",
        header: t("siteSpeedErrors.layoutShift"),
        align: "center",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "metricSavingsCls",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "layoutShiftImpactAvg",
          },
        },
      },
      "trend",
    ],
    orderBy: {
      field: "layoutShift",
      direction: "desc",
    },
  };
}
