import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function SideBarArrowRight(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="2.5 2 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="transparent"
      />
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="#0A1124"
        strokeWidth="0"
        strokeMiterlimit="10"
      />
      <path
        d="M14.5 11.5L19.5 16L14.5 20.5"
        stroke="#4B5563" // grey 600
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
export function SidebarX(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="4 4 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill="#0A1124"
      />
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="#0A1124"
        strokeWidth="0"
        strokeMiterlimit="10"
      />
      <path
        d="M20 12L12 20"
        stroke="#4B5563"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 20L12 12"
        stroke="#4B5563"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
