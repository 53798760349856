import { TextField, Button, useTranslation } from "@lumar/shared";
import {
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Switch,
  useTheme,
} from "@material-ui/core";
import { GridApiContext } from "@mui/x-data-grid-pro";
import { useContext, useState } from "react";
import { FixedSizeList } from "react-window";
import { assert } from "../../../../../_common/assert";
import React from "react";

export function ColumnSelector({
  defaultColumns,
  hideColumns,
  insertIndex,
}: {
  defaultColumns?: string[];
  hideColumns?: string[];
  insertIndex?: number;
}): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation("report");

  const [filter, setFilter] = useState("");
  const apiRef = useContext(GridApiContext);
  assert(apiRef);

  // The intention is to always display visible columns first
  // and to make sure that they don't change their position after
  // changing their visibility.
  const [initialColumnOrder] = React.useState(() => {
    const initiallyVisibleColumns = apiRef.current.getVisibleColumns();
    return [
      ...initiallyVisibleColumns,
      // eslint-disable-next-line fp/no-mutating-methods
      ...apiRef.current
        .getAllColumns()
        .filter(
          (column) =>
            !initiallyVisibleColumns.find((v) => v.field === column.field),
        )
        .sort((a, b) => {
          const aIsCustomMetric = a.field.startsWith("customMetrics.");
          const bIsCustomMetric = b.field.startsWith("customMetrics.");

          if (aIsCustomMetric && !bIsCustomMetric) return -1;
          if (bIsCustomMetric && !aIsCustomMetric) return 1;

          return (a.headerName || "").localeCompare(b.headerName || "");
        }),
    ].map((x) => x.field);
  });

  // eslint-disable-next-line fp/no-mutating-methods
  const allColumns = [...apiRef.current.getAllColumns()].sort(
    (a, b) =>
      initialColumnOrder.indexOf(a.field) - initialColumnOrder.indexOf(b.field),
  );

  const allowedColumns = allColumns.filter(
    (col) => !hideColumns?.includes(col.field),
  );

  const filteredAllowedColumns =
    filter === ""
      ? allowedColumns
      : allowedColumns.filter((col) =>
          col.headerName?.toLowerCase().includes(filter.toLowerCase()),
        );

  const setAllColumnsVisibility = (shouldMakeVisible: boolean): void => {
    filteredAllowedColumns.forEach((column) => {
      if (column.field !== "card") {
        apiRef.current.setColumnVisibility(column.field, shouldMakeVisible);
      }
    });
  };

  const setDefaultMetrics = (): void => {
    filteredAllowedColumns.forEach((column) => {
      apiRef.current.setColumnVisibility(
        column.field,
        Boolean(defaultColumns?.includes(column.field)),
      );
    });
  };

  return (
    <div
      style={{
        width: "400px",
        position: "relative",
      }}
      data-testid="column-selector"
    >
      <div
        style={{
          padding: 10,
          position: "absolute",
          background: "white",
          // Note: Setting 400px here so Safari doesn't apply 100% width. - Michal
          width: "400px",
          top: 0,
          zIndex: 10,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: theme.palette.grey[100],
        }}
      >
        <TextField
          placeholder="Find column"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          data-testid="columns-selector-search"
        ></TextField>
      </div>
      <List style={{ marginTop: 49, marginBottom: 49 }}>
        <FixedSizeList
          height={320}
          width={400}
          itemSize={40}
          itemCount={filteredAllowedColumns.length}
          itemData={{ columns: filteredAllowedColumns, apiRef: apiRef }}
        >
          {({ index, data, style }) => {
            const { headerName, field, hide } = data.columns[index];
            return (
              <>
                <ListItem
                  key={field}
                  style={style}
                  data-testid="report-grid-edit-column"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        id={`toggle-${field}`}
                        checked={!hide}
                        onChange={(_e, visible) => {
                          if (visible) {
                            data.apiRef.current.setColumnIndex(
                              data.columns[index].field,
                              insertIndex ?? 0,
                            );
                          }
                          data.apiRef.current.setColumnVisibility(
                            data.columns[index].field,
                            visible,
                          );
                        }}
                        disabled={field === "card"}
                      />
                    }
                    label={headerName}
                  />
                </ListItem>
              </>
            );
          }}
        </FixedSizeList>
      </List>
      <div
        style={{
          padding: 10,
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderTopColor: theme.palette.grey[100],
          background: "white",
          position: "absolute",
          width: "100%",
          bottom: 0,
          zIndex: 10,
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              disabled={filteredAllowedColumns.length === 0}
              onClick={() => setAllColumnsVisibility(true)}
              data-testid="report-grid-edit-columns-select-all"
              style={{ whiteSpace: "nowrap" }}
            >
              {filter === "" ? t("selectAll") : t("selectFiltered")}
            </Button>
          </Grid>
          <Grid item xs={9} container justifyContent="flex-end">
            {Boolean(defaultColumns) && (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setDefaultMetrics()}
                style={{ marginRight: 10 }}
                data-testid="report-grid-edit-columns-use-default"
              >
                {t("useDefault")}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAllColumnsVisibility(false)}
              data-testid="report-grid-edit-columns-remove-all"
            >
              {t("removeAll")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
