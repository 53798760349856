import { Alert } from "@lumar/shared";
import { makeStyles, Typography } from "@material-ui/core";
import { FastField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { SettingsContext } from "../data/useContextValues";
import { SettingControlFactory } from "../useAdvancedControls";

export const getStealthModeControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.stealthMode.title"),
  path: "stealthMode",
  // eslint-disable-next-line react/display-name
  control: () => <StealthMode />,
  testId: "crawl-settings-advanced-stealth-mode",
  activeValues: ["enable"],
  openOnInitValues: ["enable"],
  textForSearch: createTextForSearch([
    t("settings.stealthMode.title"),
    t("settings.stealthMode.description"),
    t("settings.stealthMode.warning"),
    t("settings.stealthMode.info"),
    t("settings.stealthMode.infoWithRenderer"),
    t("settings.stealthMode.infoWithoutRenderer"),
    t("settings.stealthMode.checkbox"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
}));

export const StealthMode = React.memo(StealthModeInner);

function StealthModeInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  return (
    <>
      <Typography variant="caption">
        {t("settings.stealthMode.description")}
      </Typography>
      <Alert severity="info" size="small" style={{ marginTop: "8px" }}>
        {t("settings.stealthMode.info")}
      </Alert>
      <Alert severity="info" size="small" style={{ marginTop: "8px" }}>
        {t("settings.stealthMode.infoWithoutRenderer")}
      </Alert>

      {contextValues.isPlanLight ? (
        <UpgradeMessage
          upgradeType="LightPlusOrCorporate"
          className={classes.indent}
        />
      ) : (
        <FastField
          name="spider.stealthMode.enable"
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: t("settings.stealthMode.checkbox") }}
          className={classes.indent}
          data-testid="use-stealth-mode"
        />
      )}
    </>
  );
}
