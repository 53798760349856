import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useAccountGuard, useProjectGuard, useSession } from "@lumar/shared";

import { SegmentTitle } from "./segment-title/SegmentTitle";
import { ManageSegment } from "./manage-segment/ManageSegment";
import { UpgradeToSegmentationMessage } from "./upgrade-to-segmentation-message/UpgradeToSegmentationMessage";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
      },
    },
  }),
);

export function Segments(): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();

  const classes = useStyles();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  useAccountGuard();
  useProjectGuard();

  if (segmentationAvailable) {
    return (
      <div className={classes.container}>
        <SegmentTitle />
        <div style={{ paddingTop: 2 }}>
          <ManageSegment key={projectId} />
        </div>
      </div>
    );
  }

  return (
    <>
      <TopNavigation />
      <UpgradeToSegmentationMessage />
    </>
  );
}
