import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { AccountsRoutes } from "../routes/accountsRoutes";
import { getRawAccountId, useSession } from "../session";
export function InactiveAccountRedirect(props) {
    const session = useSession();
    const isActiveAccount = session === null || session === void 0 ? void 0 : session.account.active;
    const shouldRedirectUser = session && !isActiveAccount && !(session === null || session === void 0 ? void 0 : session.isUsingSharedLink);
    if (shouldRedirectUser) {
        window.location.href = AccountsRoutes.Subscription.getUrl({
            accountId: getRawAccountId(session.account.id),
        });
    }
    return _jsx(_Fragment, { children: props.children });
}
