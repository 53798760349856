/* eslint-disable fp/no-mutation */
import { generatePath } from "react-router-dom";
import queryString from "query-string";
const ACCOUNTS_ROUTE_MATCH = "/accounts/:accountId";
function createAccountsURL(object, options) {
    const params = new URLSearchParams(window.location.search);
    const shareLinkToken = params.get("token");
    const preferredLanguage = localStorage.getItem("i18nextLng");
    if (shareLinkToken) {
        object.query.token = shareLinkToken;
    }
    object.query.referral = window.location.href;
    object.query.lng = preferredLanguage !== null && preferredLanguage !== void 0 ? preferredLanguage : "en";
    return `${process.env.REACT_APP_ACCOUNTS_APP_URL}${queryString.stringifyUrl(object, options)}`;
}
export const AccountsRoutes = {
    Subscription: {
        ROUTE: ACCOUNTS_ROUTE_MATCH + "/subscription",
        getUrl({ accountId }) {
            const url = generatePath(AccountsRoutes.Subscription.ROUTE, {
                accountId,
            });
            return createAccountsURL({ url, query: {} });
        },
    },
};
