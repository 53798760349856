import React from "react";
import { TFunction } from "i18next";
import {
  CrawlContextCrawlReportStat,
  CrawlReportCategoryListNode,
} from "../CrawlContext";
import { GridApi, GridColDef } from "@mui/x-data-grid-pro";
import { CategoryIconCell } from "./cells/CategoryIconCell";
import { ReportNameCell } from "./cells/ReportNameCell";
import { PriorityCell } from "./cells/PriorityCell";
import { TotalRowsCell } from "./cells/TotalRowsCell";
import { ReportTrendCell } from "./cells/ReportTrendCell";
import { AddedCell } from "./cells/AddedCell";
import { RemovedCell } from "./cells/RemovedCell";
import { MissingCell } from "./cells/MissingCell";
import { ChangeCell } from "./cells/ChangeCell";
import {
  ReportPriority,
  getReportPriority,
} from "../../_common/report-helpers/reportPriority";
import { ReportCodeCell } from "./cells/ReportCodeCell";
import { CategoryNameCell } from "./cells/CategoryNameCell";
import { WeightedChangeCell } from "./cells/WeightedChangeCell";
import { WeightedTotalCell } from "./cells/WeightedTotalCell";
import { ReportImpactCell } from "./cells/ReportImpactCell";
import {
  getReportImpact,
  ReportImpact,
} from "../../_common/report-helpers/reportImpact";

export const reportsTableDefaultColumns = {
  categoryIcon: getCategoryIconColummn,
  categoryName: getCategoryNameColummn,
  impact: getImpactColumn,
  name: getNameColumn,
  priority: getPriorityColumn,
  total: getTotalColumn,
  trend: getTrendColumn,
  change: getChangeColumn,
  added: getAddedColumn,
  moved: getRemovedColumn,
  missing: getMissingColumn,
  reportCode: getReportCode,
  weightedChange: getWeightedChangeColumn,
  weightedTotal: getWeightedTotalColumn,
};

export type ReportsDefaultColumnTypes = keyof typeof reportsTableDefaultColumns;

export type ReportsColumnBuilderProps = {
  t: TFunction<"crawlOverview">;
  tReport: TFunction<"report">;
};

function getCategoryIconColummn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "categoryIcon",
    headerName: t("table.header.categoryIcon"),
    description: t("table.tooltips.categoryIcon"),
    width: 110,
    align: "center",
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.parentCategory?.name,
    renderCell: ({ row }) => (
      <CategoryIconCell
        category={row.parentCategory as CrawlReportCategoryListNode | undefined}
      />
    ),
  };
}

function getCategoryNameColummn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "categoryName",
    headerName: t("table.header.categoryName"),
    description: t("table.tooltips.categoryName"),
    width: 200,
    minWidth: 150,
    hide: true,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.parentCategory?.name,
    renderCell: ({ row }) => (
      <CategoryNameCell
        category={row.parentCategory as CrawlReportCategoryListNode | undefined}
      />
    ),
  };
}

function getNameColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "name",
    headerName: t("table.header.name"),
    description: t("table.tooltips.name"),
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.reportTemplate.name,
    renderCell: ({ row }) => (
      <ReportNameCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getImpactColumn({
  t,
  tReport,
}: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "impact",
    headerName: t("table.header.impact"),
    description: t("table.tooltips.impact"),
    minWidth: 125,
    disableColumnMenu: true,
    align: "center",
    hide: true,
    valueGetter: ({ row }) => {
      const impact = getReportImpact(row.reportTemplateTotalSign);
      switch (impact) {
        case ReportImpact.Negative:
          return tReport("impactNegative");
        case ReportImpact.Neutral:
          return tReport("impactNeutral");
        case ReportImpact.Positive:
          return tReport("impactPositive");
      }
    },
    renderCell: ({ row }) => (
      <ReportImpactCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getPriorityColumn({
  t,
  tReport,
}: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "priority",
    headerName: t("table.header.priority"),
    description: t("table.tooltips.priority"),
    width: 125,
    align: "center",
    disableColumnMenu: true,
    hide: true,
    valueGetter: ({ row }) => row.reportTemplateTotalWeight,
    valueFormatter: ({ row }) => {
      switch (getReportPriority(row.reportTemplateTotalWeight)) {
        case ReportPriority.None:
          return tReport("priorityNone");
        case ReportPriority.Low:
          return tReport("priorityLow");
        case ReportPriority.Medium:
          return tReport("priorityMedium");
        case ReportPriority.High:
          return tReport("priorityHigh");
        case ReportPriority.Critical:
          return tReport("priorityCritical");
      }
    },
    renderCell: ({ row }) => (
      <PriorityCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getTotalColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "total",
    headerName: t("table.header.all"),
    description: t("table.tooltips.all"),
    align: "right",
    width: 90,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.basic ?? 0,
    renderCell: ({ row }) => (
      <TotalRowsCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getTrendColumn(props: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "trend",
    headerName: props.t("table.header.trend"),
    description: props.t("table.tooltips.trend"),
    minWidth: 232,
    renderCell: ({ row }) => (
      <ReportTrendCell report={row as CrawlContextCrawlReportStat} />
    ),
    disableExport: true,
    sortable: false,
    disableColumnMenu: true,
  };
}

function getChangeColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "change",
    headerName: t("table.header.change"),
    description: t("table.tooltips.change"),
    width: 125,
    align: "right",
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.change,
    sortComparator: (v1, v2, cell1, cell2) => {
      const row1 = (cell1.api as GridApi).getRow(
        cell1.id,
      ) as CrawlContextCrawlReportStat | null;
      const row2 = (cell2.api as GridApi).getRow(
        cell2.id,
      ) as CrawlContextCrawlReportStat | null;

      if (!row1 || !row2) return 0;
      return Math.abs(row1.change ?? 0) - Math.abs(row2.change ?? 0);
    },
    renderCell: ({ row }) => (
      <ChangeCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getAddedColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "added",
    headerName: t("table.header.added"),
    description: t("table.tooltips.added"),
    width: 125,
    align: "right",
    disableColumnMenu: true,
    valueGetter: ({ row }) => (row as CrawlContextCrawlReportStat).added,
    renderCell: ({ row, value }) => (
      <AddedCell
        report={row as CrawlContextCrawlReportStat}
        value={value as number | undefined}
      />
    ),
  };
}

function getRemovedColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "removed",
    headerName: t("table.header.removed"),
    description: t("table.tooltips.removed"),
    width: 125,
    align: "right",
    disableColumnMenu: true,
    valueGetter: ({ row }) => (row as CrawlContextCrawlReportStat).removed,
    renderCell: ({ row, value }) => (
      <RemovedCell
        report={row as CrawlContextCrawlReportStat}
        value={value as number | undefined}
      />
    ),
  };
}

function getMissingColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "missing",
    headerName: t("table.header.missing"),
    description: t("table.tooltips.missing"),
    width: 125,
    align: "right",
    disableColumnMenu: true,
    valueGetter: ({ row }) => (row as CrawlContextCrawlReportStat).missing,
    renderCell: ({ row, value }) => (
      <MissingCell
        report={row as CrawlContextCrawlReportStat}
        value={value as number | undefined}
      />
    ),
  };
}

function getReportCode({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "reportCode",
    headerName: t("table.header.reportCode"),
    width: 200,
    hide: true,
    disableColumnMenu: true,
    valueGetter: ({ row }) =>
      (row as CrawlContextCrawlReportStat).reportTemplate.code,
    renderCell: ({ row }) => (
      <ReportCodeCell report={row as CrawlContextCrawlReportStat} />
    ),
  };
}

function getWeightedChangeColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "weightedChange",
    headerName: t("table.header.weightedChange"),
    description: t("table.tooltips.weightedChange"),
    align: "right",
    width: 160,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.weightedChange,
    renderCell: ({ row, value }) => (
      <WeightedChangeCell
        report={row as CrawlContextCrawlReportStat}
        value={value as number | undefined}
      />
    ),
  };
}

function getWeightedTotalColumn({ t }: ReportsColumnBuilderProps): GridColDef {
  return {
    field: "weightedTotal",
    headerName: t("table.header.weightedTotal"),
    description: t("table.tooltips.weightedTotal"),
    align: "right",
    width: 150,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.weightedTotal,
    renderCell: ({ row, value }) => (
      <WeightedTotalCell
        report={row as CrawlContextCrawlReportStat}
        value={value as number | undefined}
      />
    ),
  };
}
