import React from "react";
import { ApolloCache, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";

import {
  CrawlType,
  DeleteFileForSourcesMutation,
  GetManualUploadsForSourcesDocument,
  GetManualUploadsForSourcesQuery,
  ProjectUploadType,
  UploadFileForSourcesMutation,
  useDeleteFileForSourcesMutation,
  useUpdateUploadFileEnabledForSourcesMutation,
  useUpdateUploadFileForSourcesMutation,
  useUploadFileForSourcesMutation,
} from "../../../../graphql";
import { UploadTemplate } from "./types";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";

interface CustomUploadTemplateData {
  sample: string[][];
  headerRow: number;
  metrics: UploadTemplate["metrics"];
}

export interface ManualUploadMutations {
  createFile: (props: {
    crawlType: CrawlType;
    fileName: string;
    baseDomain?: string;
    uploadType: ProjectUploadType;
    customTemplate?: CustomUploadTemplateData;
  }) => Promise<{ fileId?: string; uploadLink?: string }>;
  updateFile: (props: {
    fileId: string;
    baseDomain?: string;
    uploadType?: ProjectUploadType;
    customTemplate?: CustomUploadTemplateData;
  }) => Promise<boolean>;
  setFileStatus: (props: { fileId: string; status: boolean }) => void;
  deleteFile: (props: { fileId: string }) => void;
}

export function useManualUploadMutations({
  projectId,
}: {
  projectId: string;
}): ManualUploadMutations {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  const [uploadFile] = useUploadFileForSourcesMutation({
    context: {
      includeInBatch: true,
    },
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileUpload")}
          error={error}
        />,
      ),
  });

  const [updateEnabled] = useUpdateUploadFileEnabledForSourcesMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileSave")}
          error={error}
        />,
      ),
  });

  const [deleteFile] = useDeleteFileForSourcesMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileDelete")}
          error={error}
        />,
      ),
  });

  const [updateFile] = useUpdateUploadFileForSourcesMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileSave")}
          error={error}
        />,
      ),
  });

  return {
    createFile: async ({
      crawlType,
      fileName,
      baseDomain,
      uploadType,
      customTemplate,
    }) => {
      const isCustom = uploadType
        ? uploadType === ProjectUploadType.Custom
        : undefined;

      const mutationResult = await uploadFile({
        variables: {
          projectId,
          crawlTypeCode: crawlType,
          enabled: true,
          fileName: fileName,
          uploadBaseDomain: formatString(baseDomain),
          projectUploadType: uploadType,
          uploadTemplate:
            isCustom && customTemplate
              ? getCustomUploadTemplate(customTemplate)
              : undefined,
        },
        update: (cache, { data }) => addFile(cache, data, projectId),
      });

      return {
        fileId: mutationResult.data?.createSignedUrlFileUpload.urlFileUpload.id,
        uploadLink:
          mutationResult.data?.createSignedUrlFileUpload.signedS3UploadUrl,
      };
    },
    updateFile: async ({ fileId, baseDomain, uploadType, customTemplate }) => {
      const isCustom = uploadType
        ? uploadType === ProjectUploadType.Custom
        : undefined;

      const result = await updateFile({
        variables: {
          urlFileUploadId: fileId,
          uploadBaseDomain: formatString(baseDomain),
          projectUploadType: uploadType,
          customUploadTemplate:
            isCustom && customTemplate
              ? getCustomUploadTemplate(customTemplate)
              : undefined,
        },
      });
      return !!result.data;
    },
    setFileStatus: ({ fileId, status }) => {
      updateEnabled({
        variables: {
          urlFileUploadId: fileId,
          enabled: status,
        },
        optimisticResponse: {
          updateUrlFileUpload: {
            urlFileUpload: {
              id: fileId,
              enabled: status,
              __typename: "UrlFileUpload",
            },
          },
        },
      });
    },
    deleteFile: ({ fileId }) => {
      deleteFile({
        variables: {
          urlFileUploadId: fileId,
        },
        optimisticResponse: {
          deleteUrlFileUpload: {
            urlFileUpload: {
              id: fileId,
              __typename: "UrlFileUpload",
            },
          },
        },
        update: (cache, { data }) => removeFile(cache, data, projectId),
      });
    },
  };
}

function formatString(value: string | undefined): string | null {
  return value && value.trim().length > 0 ? value : null;
}

function getCustomUploadTemplate({
  sample,
  headerRow,
  metrics,
}: CustomUploadTemplateData): unknown {
  const headers = sample[headerRow];

  function getHeader(index?: number): string | undefined {
    return index !== undefined ? headers?.[index] : undefined;
  }

  return {
    data_start_row: headerRow + 2,
    has_header: true,
    header_column_names: headers,
    metrics: Object.fromEntries(
      metrics.map((metric) => [
        metric.code,
        {
          header: getHeader(metric.index) ?? null,
          column_position: metric.index !== undefined ? metric.index + 1 : null,
          type: metric.type,
        },
      ]),
    ),
  };
}

function addFile(
  cache: ApolloCache<UploadFileForSourcesMutation>,
  data: UploadFileForSourcesMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetManualUploadsForSourcesQuery | null = cache.readQuery({
    query: GetManualUploadsForSourcesDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject) return;

  cache.writeQuery({
    query: GetManualUploadsForSourcesDocument,
    variables: { projectId },
    data: {
      ...cachedData,
      getProject: {
        ...cachedData.getProject,
        urlFileUploads: {
          ...cachedData.getProject.urlFileUploads,
          edges: [
            ...cachedData.getProject.urlFileUploads.edges,
            {
              node: data?.createSignedUrlFileUpload.urlFileUpload,
            },
          ],
        },
      },
    },
  });
}

function removeFile(
  cache: ApolloCache<DeleteFileForSourcesMutation>,
  data: DeleteFileForSourcesMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetManualUploadsForSourcesQuery | null = cache.readQuery({
    query: GetManualUploadsForSourcesDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject) return;

  const fileId = data?.deleteUrlFileUpload.urlFileUpload.id;

  cache.writeQuery({
    query: GetManualUploadsForSourcesDocument,
    variables: { projectId },
    data: {
      ...cachedData,
      getProject: {
        ...cachedData.getProject,
        urlFileUploads: {
          ...cachedData.getProject.urlFileUploads,
          edges: cachedData.getProject.urlFileUploads.edges.filter(
            (x) => x.node.id !== fileId,
          ),
        },
      },
    },
  });
}
