import { get } from "lodash";
import { DatasourceCode, Scalars } from "../../../../graphql";
import { Metrics } from "../../../../resource-detail/data/types";
import { MetricData } from "../ReportGrid.types";

export function getMetrics(
  resource: Scalars["JSONObject"]["input"],
  metricsData: MetricData[],
  datasourceCode: DatasourceCode | undefined,
): Metrics {
  const metrics = metricsData.map((metric) => [
    metric.code,
    {
      code: metric.code,
      name: metric.name,
      value: get(resource, metric.code),
      data: metric,
      datasourceCode,
    },
  ]);

  const metricsWithoutData = Object.entries(resource)
    .filter(([key]) => !metrics.find((x) => x[0] === key))
    .map(([code, value]) => [
      code,
      {
        code,
        name: code,
        value: value,
        datasourceCode,
      },
    ]);

  return Object.fromEntries([...metrics, ...metricsWithoutData]);
}
