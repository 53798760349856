import { ApolloError } from "@lumar/shared";
import {
  LegacyTaskPriority,
  LegacyTaskStatus,
  ReportTypeCode,
  useCreateReportTaskMutation,
} from "../../graphql";
import { ConnectionFilter } from "../../_common/connection-filtering/types";

export interface FormValues {
  priority: LegacyTaskPriority;
  deadline: Date | null;
  title: string;
  description: string | undefined;
  assignedTo: string[];
  howToFix: string | undefined;
}

interface CreateTaskFormValuesProps {
  onSaveError?: (error: ApolloError) => void;
}

interface CreateTaskFormValuesResult {
  values: FormValues;
  createReportTask: (
    values: FormValues,
    crawlId: string,
    reportTemplateCode: string,
    reportTypeCode: ReportTypeCode,
    segmentId: string | undefined,
    identified: number,
    filters?: ConnectionFilter,
  ) => Promise<boolean>;
}

export function useCreateTaskFormValues({
  onSaveError,
}: CreateTaskFormValuesProps): CreateTaskFormValuesResult {
  const [createReportTask] = useCreateReportTaskMutation({
    onError: onSaveError,
    refetchQueries: ["ReportTasks", "UnresolvedProjectTasksCountForChip"],
    awaitRefetchQueries: true,
  });

  return {
    values: {
      priority: LegacyTaskPriority.Note,
      deadline: null,
      title: "",
      description: undefined,
      assignedTo: [],
      howToFix: undefined,
    },
    createReportTask: async (
      values: FormValues,
      crawlId: string,
      reportTemplateCode: string,
      reportTypeCode: ReportTypeCode,
      segmentId: string | undefined,
      identified: number,
      filters?: ConnectionFilter,
    ) => {
      const result = await createReportTask({
        variables: {
          input: {
            crawlId,
            reportTemplateCode,
            reportTypeCode,
            segmentId,
            identified,
            filters,
            priority: values.priority,
            deadlineAt: values.deadline ? values.deadline?.toISOString() : null,
            title: values.title,
            description: values.description,
            assignedTo: values.assignedTo,
            howToFix: values.howToFix,
            status: LegacyTaskStatus.Backlog,
          },
        },
      });

      return Boolean(result.data);
    },
  };
}
