import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityAriaBPChart } from "../../../../../_common/charts/config/getAccessibilityAriaBPChart";
import { getAccessibilityAriaBPTrend } from "../../../../../_common/charts/config/getAccessibilityAriaBPTrend";

export const generateAccessibilityAriaBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityAriaBPChart,
      getAccessibilityAriaBPTrend,
    ],
  };
