var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useHistory } from "react-router-dom";
import { useURLSearchParams } from "../helpers";
import { getUrlPagination } from "./getUrlPagination";
export function useUrlServerPagination(defaultPageSize, defaultOrderByField, defaultOrderByDirection, name) {
    var _a, _b, _c, _d;
    const history = useHistory();
    const params = useURLSearchParams();
    const paginationName = name !== null && name !== void 0 ? name : "pagination";
    const { sort: sortModel, filter: filterModel, page: pageData, } = getUrlPagination(params, paginationName);
    const _e = pageData !== null && pageData !== void 0 ? pageData : {}, { index: page } = _e, pageInfo = __rest(_e, ["index"]);
    const pageSize = (_a = pageInfo.size) !== null && _a !== void 0 ? _a : defaultPageSize;
    const onStateChange = (state, options) => {
        var _a;
        const pageSize = (_a = state.pageSize) !== null && _a !== void 0 ? _a : defaultPageSize;
        const cursor = state.page
            ? btoa((state.page * pageSize).toString())
            : undefined;
        const urlParams = {
            sort: state.sortModel,
            filter: state.filterModel,
            page: {
                index: state.page,
                size: state.pageSize,
                after: cursor,
                first: pageSize,
            },
        };
        params.set(paginationName, btoa(JSON.stringify(urlParams)));
        if (options === null || options === void 0 ? void 0 : options.replaceHistory) {
            history.replace({ search: params.toString() });
        }
        else {
            history.push({ search: params.toString() });
        }
    };
    const sortData = sortModel === null || sortModel === void 0 ? void 0 : sortModel[0];
    const field = (_b = sortData === null || sortData === void 0 ? void 0 : sortData.field) !== null && _b !== void 0 ? _b : defaultOrderByField;
    const direction = (_d = (_c = sortData === null || sortData === void 0 ? void 0 : sortData.sort) === null || _c === void 0 ? void 0 : _c.toUpperCase()) !== null && _d !== void 0 ? _d : defaultOrderByDirection;
    return {
        paginationState: {
            sortModel: sortModel !== null && sortModel !== void 0 ? sortModel : [],
            filterModel,
            page: page !== null && page !== void 0 ? page : 0,
            pageSize: pageSize,
        },
        pageInfo: Object.assign(Object.assign({ first: pageInfo.last === undefined ? pageSize : undefined }, pageInfo), { orderBy: field ? [{ field, direction }] : null }),
        onPaginationStateChange: onStateChange,
    };
}
