export { ArrayPresenter } from "./ArrayPresenter";
export { BooleanPresenter } from "./BooleanPresenter";
export { DatePresenter } from "./DatePresenter";
export { NumberPresenter } from "./NumberPresenter";
export { StringPresenter } from "./StringPresenter";
export { UrlPresenter } from "./UrlPresenter";

export { AccessibilityIssuesCountByRuleIdPresenter } from "./AccessibilityIssuesCountByRuleIdPresenter";
export { ChangedFromPresenter } from "./ChangedFromPresenter";
export { ColorPresenter } from "./ColorPresenter";
export { ContainerExecutionFailuresPresenter } from "./ContainerExecutionFailuresPresenter";
export { ElementSourcePresenter } from "./ElementSourcePresenter";
export { GoogleAnalyticsBounceRatePresenter } from "./GoogleAnalyticsBounceRatePresenter";
export { IssueSeverityPresenter } from "./IssueSeverityPresenter";
export { RedirectChainPresenter } from "./RedirectChainPresenter";
export { RedirectStatusCodePresenter } from "./RedirectStatusCodePresenter";
export {
  StatusCodePresenter,
  StatusCodeChangedFromPresenter,
} from "./StatusCodePresenter";
export { WcagVersionPresenter } from "./WcagVersionPresenter";

import { MetricType } from "../../../graphql";
import { MetricsValuePresenterProps } from "../../data/types";
import { AccessibilityIssuesCountByRuleIdPresenter } from "./AccessibilityIssuesCountByRuleIdPresenter";
import { ArrayPresenter } from "./ArrayPresenter";
import { BooleanPresenter } from "./BooleanPresenter";
import { BoundingBoxPresenter } from "./BoundingBoxPresenter";
import { ColorPresenter } from "./ColorPresenter";
import { ContainerExecutionFailuresPresenter } from "./ContainerExecutionFailuresPresenter";
import { DatePresenter } from "./DatePresenter";
import { ElementSourcePresenter } from "./ElementSourcePresenter";
import { GoogleAnalyticsBounceRatePresenter } from "./GoogleAnalyticsBounceRatePresenter";
import { IssueSeverityPresenter } from "./IssueSeverityPresenter";
import { JSONPresenter } from "./JSONPresenter";
import { NumberPresenter } from "./NumberPresenter";
import { RedirectChainPresenter } from "./RedirectChainPresenter";
import { RedirectStatusCodePresenter } from "./RedirectStatusCodePresenter";
import {
  StatusCodeChangedFromPresenter,
  StatusCodePresenter,
} from "./StatusCodePresenter";
import { StringPresenter } from "./StringPresenter";
import { WcagVersionPresenter } from "./WcagVersionPresenter";

export type TypePresenters = Partial<
  Record<
    MetricType,
    React.ElementType<MetricsValuePresenterProps> | undefined | null
  >
>;

export const typePresenters: TypePresenters = {
  [MetricType.Integer]: NumberPresenter,
  [MetricType.Decimal]: NumberPresenter,
  [MetricType.String]: StringPresenter,
  [MetricType.Array]: ArrayPresenter,
  [MetricType.Date]: DatePresenter,
  [MetricType.Bit]: BooleanPresenter,
  [MetricType.Boolean]: BooleanPresenter,
  [MetricType.Json]: JSONPresenter,
};

export type MetricPresenters = Record<
  string,
  React.ElementType<MetricsValuePresenterProps> | undefined | null
>;

export const metricPresenters: MetricPresenters = {
  httpStatusCode: StatusCodePresenter,
  httpStatusCodeChangedFrom: StatusCodeChangedFromPresenter,
  redirectedToStatusCode: RedirectStatusCodePresenter,
  redirectChain: RedirectChainPresenter,
  gaVisitBounceRate: GoogleAnalyticsBounceRatePresenter,
  issueSeverity: IssueSeverityPresenter,
  elementSource: ElementSourcePresenter,
  bgAverage: ColorPresenter,
  bgMaxColor: ColorPresenter,
  bgMinColor: ColorPresenter,
  bgStdv: ColorPresenter,
  fontColor: ColorPresenter,
  accessibilityIssuesCountByRuleId: AccessibilityIssuesCountByRuleIdPresenter,
  wcagVersion: WcagVersionPresenter,
  containerExecutionFailures: ContainerExecutionFailuresPresenter,
  primaryUrlFromStatusCode: StatusCodePresenter,
  urlToStatusCode: StatusCodePresenter,
  urlFromStatusCode: StatusCodePresenter,
  boundingBox: BoundingBoxPresenter,
};
