import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getFirstContentfulPaintChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("firstContentfulPaint.title"),
    reportTemplateCodes: ["fast_fcp", "average_fcp", "slow_fcp"],
    description: t("firstContentfulPaint.description"),
  })();
}
