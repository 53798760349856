/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  useTranslation,
  Button,
  Alert,
  ApolloError,
  EmptyState,
  SmileySad,
  useSession,
} from "@lumar/shared";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress, Paper } from "@material-ui/core";
import { RoleCode } from "@lumar/shared/dist/graphql";

import { DataExplorerTableConfig, Metric, DataExplorerRow } from "../types";
import { Routes } from "../../_common/routing/routes";
import { CrawlType } from "../../graphql";
import { EnhancedTable } from "../../_common/enhanced-table/EnhancedTable";
import { Header } from "../../_common/enhanced-table/types";
import { InlineEdit } from "./InlineEdit";
import { DataTableCell } from "./DataTableCell";
import { useDataExplorerStickyRow } from "./useDataExplorerStickyRow";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "calc(100vh - 260px)",
    minHeight: 96,
  },
  aggregationCalculation: {
    paddingLeft: theme.spacing(0.5),
    textTransform: "uppercase",
  },
  header: {
    boxShadow: `1px 0px 0px 0px ${theme.palette.grey[200]} inset`,
  },
  headerRow: {
    "& th:first-child": {
      boxShadow: "none",
    },
  },
  loadingRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
  },
  loadMoreCell: {
    padding: theme.spacing(1.25, 2),
  },
  alert: {
    marginBottom: theme.spacing(1.5),
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
}));

export interface DataExplorerTableProps {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
  tableConfig: DataExplorerTableConfig;
  setTableConfig: React.Dispatch<React.SetStateAction<DataExplorerTableConfig>>;
  aggregableMetrics: Metric[];
  crawlTypes: CrawlType[];
  dimensionColumnName: string;
  dimensionColumnWidth?: number;
  disableSorting?: boolean;
}

export function DataExplorerTable({
  rows,
  loading,
  error,
  tableConfig,
  setTableConfig,
  aggregableMetrics,
  crawlTypes,
  dimensionColumnName,
  dimensionColumnWidth = 260,
  disableSorting,
}: DataExplorerTableProps): JSX.Element {
  const { projectId, accountId, crawlId } = useParams<{
    projectId: string;
    accountId: string;
    crawlId: string;
  }>();

  const classes = useStyles();
  const { t } = useTranslation("dataExplorer");
  const { hasSufficientRole } = useSession();
  const history = useHistory();

  const { onScroll } = useDataExplorerStickyRow();

  const buildHeaders = (tableConfig: DataExplorerTableConfig): Header[] => {
    const { columns } = tableConfig;

    const availableMetrics = aggregableMetrics.map((metric) => ({
      code: metric.code,
      name: metric.name,
      description: metric?.description,
      metadata: {
        unit: metric?.metadata?.unit,
        crawlType: metric?.metadata?.crawlType,
        rendererOnly: metric?.metadata?.rendererOnly,
      },
    }));

    const aggregationId = "data-explorer-table-aggregation";

    const metricHeaders = columns.map((column, columnIndex) => {
      const metric = aggregableMetrics?.find(
        (x) => x.code === column.metric.code,
      );

      return {
        id: `col-${columnIndex + 1}`,
        field: `${column.metric.code}-${column.aggregationCalculation}`,
        title: (
          <span>
            {metric?.name}
            <span
              data-testid={`${aggregationId}-${column.aggregationCalculation}-${
                columnIndex + 1
              }`}
              className={classes.aggregationCalculation}
            >
              {column.aggregationCalculation}
            </span>
          </span>
        ),
        sortable: true,
        inlineEdit: (
          <InlineEdit
            columnIndex={columnIndex}
            availableMetrics={availableMetrics}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
          />
        ),
      };
    });

    return [
      {
        id: "col-0",
        field: tableConfig.dimension,
        title: dimensionColumnName,
        sortable: true,
        width: dimensionColumnWidth,
      },
      ...metricHeaders,
    ];
  };

  const renderRow = (row: any): JSX.Element => {
    if (row.isLoading)
      return (
        <TableCell colSpan={tableConfig.columns.length + 1}>
          <div className={classes.loadingRow}>
            <CircularProgress color="inherit" size={18} />
          </div>
        </TableCell>
      );

    if (row.loadMore) {
      return (
        <TableCell
          colSpan={tableConfig.columns.length + 1}
          align="center"
          className={classes.loadMoreCell}
        >
          <Button
            onClick={row.loadMore}
            variant="contained"
            color="secondary"
            loading={row.isMoreLoading}
            data-testid="load-more"
          >
            {t("loadMoreEntries")}
          </Button>
        </TableCell>
      );
    }

    return (
      <>
        {[null, ...tableConfig.columns].map((column, columnIndex) => {
          const metric = aggregableMetrics.find(
            (x) => x.code === column?.metric?.code,
          );

          const metricName = column
            ? `${column.metric.code}-${column.aggregationCalculation}`
            : tableConfig.dimension;

          return (
            <DataTableCell
              key={columnIndex}
              metricValue={row[metricName]}
              metricUnit={metric?.metadata?.unit}
              metricCrawlType={metric?.metadata?.crawlType}
              isMetricColumn={Boolean(column)}
              columnIndex={columnIndex}
              tableConfig={tableConfig}
              aggregationCalculation={column?.aggregationCalculation}
              crawlTypes={crawlTypes}
              row={row}
              error={Boolean(error)}
            />
          );
        })}
      </>
    );
  };

  if (
    error &&
    error.graphQLErrors.find(
      (x) => x.extensions?.code === "REPORT_DATA_UNAVAILABLE_CRAWL_ARCHIVED",
    )
  ) {
    return (
      <Paper>
        <EmptyState
          icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
          title={t("errorTitleArchivedCrawl")}
          description={t("errorDescriptionArchivedCrawl")}
          actions={
            hasSufficientRole(RoleCode.Editor)
              ? [
                  {
                    type: "button",
                    title: t("unarchiveCrawl"),
                    onClick: () => {
                      history.push(
                        Routes.Crawls.getUrl({
                          accountId,
                          projectId,
                          tab: "history",
                          unarchive: crawlId,
                        }),
                      );
                    },
                  },
                ]
              : undefined
          }
          height={400}
        />
      </Paper>
    );
  }

  if (error && !rows?.length) {
    return (
      <Paper>
        <EmptyState
          icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
          title={t("errorTitle")}
          description={getErrorMessage(error)}
          height={400}
        />
      </Paper>
    );
  }

  return (
    <>
      {error && (
        <Alert severity="error" className={classes.alert}>
          <strong>{t("errorTitleIncompleteData")}</strong>&nbsp;
          {getErrorMessage(error)}
        </Alert>
      )}
      <EnhancedTable
        isLoading={loading}
        rows={rows}
        headers={buildHeaders(tableConfig)}
        renderRow={(row) => renderRow(row)}
        getRowDataAttributes={(row) => {
          return {
            ["data-expanded"]: row.isExpanded ? "true" : undefined,
            ["data-level"]: row.expansionLevel,
          };
        }}
        disableSorting={disableSorting}
        sortModel={{
          field: tableConfig.orderByColumn,
          order: tableConfig.orderDirection,
        }}
        onSortModelChange={(sortModel) =>
          setTableConfig((config) => ({
            ...config,
            orderByColumn: sortModel.field,
            orderDirection: sortModel.order,
          }))
        }
        stickyHeader
        containerClass={classes.tableContainer}
        headerProps={{
          classes: {
            header: classes.header,
            headerRow: classes.headerRow,
          },
        }}
        tableProps={{ "data-testid": "data-explorer-table" }}
        onScroll={onScroll}
      />
    </>
  );
}

function getErrorMessage(error: ApolloError): string {
  if (error.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error.protocolErrors?.[0]) return error.protocolErrors[0].message;
  return error.message;
}
