import { Snackbar, useTranslation } from "@lumar/shared";
import {
  CrawlUrlConnectionFilterInput,
  GetSegmentsListDocument,
  GetSegmentsListQuery,
  useCreateSegmentMutation,
  useDeleteSegmentMutation,
  useUpdateSegmentMutation,
  useUpdateSegmentsMutation,
} from "../graphql";
import { CreateSegmentFormValues } from "./create-segment-dialog/types";
import { GraphQLError } from "graphql";
import { useSnackbar } from "notistack";
import { mapOrFormFiltersToDatasourceConnectionFilter } from "../_common/connection-filtering/filter-or-rule-field-array/mapOrFormFiltersToDatasourceConnectionFilter";
import React from "react";
import { useParams } from "react-router-dom";

export interface CreateSegmentValues {
  projectId: string;
  segmentName: string;
  group: string | null;
  rules: CrawlUrlConnectionFilterInput;
}

export interface SegmentEditValues extends CreateSegmentFormValues {
  segmentId: string;
}

export interface SegmentMutations {
  updateSegmentMutation: (segment: SegmentEditValues) => Promise<void>;
  createSegmentMutation: (values: CreateSegmentValues) => Promise<void>;
  deleteSegmentMutation: (
    segmentId: string,
    segmentName: string,
  ) => Promise<void>;
  updateSegmentsGroupMutation: (
    segmentIds: string[],
    group: string | undefined,
  ) => Promise<void>;
}

export function useSegmentMutations(): SegmentMutations {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation("segments");
  const { enqueueSnackbar } = useSnackbar();

  const [updateSegment] = useUpdateSegmentMutation();
  const [createSegment] = useCreateSegmentMutation({
    fetchPolicy: "no-cache",
  });
  const [deleteSegment] = useDeleteSegmentMutation({
    fetchPolicy: "no-cache",
  });
  const [updateSegments] = useUpdateSegmentsMutation();

  const deleteSegmentMutation = React.useCallback(
    async function deleteSegmentMutation(
      segmentId: string,
      truncatedSegmentName: string,
    ): Promise<void> {
      try {
        await deleteSegment({
          variables: {
            segmentId: segmentId,
          },
          refetchQueries: ["ProjectSummary"],
          awaitRefetchQueries: true,
          update: (cache, { data }) => {
            const cachedData: GetSegmentsListQuery | null = cache.readQuery({
              query: GetSegmentsListDocument,
              variables: { projectId },
            });
            if (!cachedData?.getProject?.segments) return;

            const removedId = data?.deleteSegment.segment.id;
            cache.writeQuery({
              query: GetSegmentsListDocument,
              variables: {},
              data: {
                ...cachedData,
                getProject: {
                  ...cachedData.getProject,
                  segments: {
                    ...cachedData.getProject.segments,
                    edges: cachedData.getProject.segments.edges.filter(
                      (x) => x.node.id !== removedId,
                    ),
                  },
                },
              },
            });
          },
        });
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("deleteSuccess", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          />,
        );
      } catch {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("failedToDelete", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          />,
        );
      }
    },
    [projectId, deleteSegment, enqueueSnackbar, t],
  );

  const createSegmentMutation = React.useCallback(
    async function createSegmentMutation(
      values: CreateSegmentValues,
    ): Promise<void> {
      await createSegment({
        variables: {
          input: {
            projectId: values.projectId,
            name: values.segmentName,
            crawlUrlFilter: values.rules,
            group: values.group,
          },
        },
        refetchQueries: ["getSegmentsList", "ProjectSummary"],
        awaitRefetchQueries: true,
      });
    },
    [createSegment],
  );

  const updateSegmentMutation = React.useCallback(
    async function updateSegmentMutation(
      segment: SegmentEditValues,
    ): Promise<void> {
      try {
        await updateSegment({
          variables: {
            input: {
              segmentId: segment.segmentId,
              name: segment.segmentName,
              group: segment.group,
              crawlUrlFilter: mapOrFormFiltersToDatasourceConnectionFilter(
                segment.rules ?? [],
              ),
            },
          },
        });
        enqueueSnackbar(
          <Snackbar variant="success" title={t("segmentSuccessUpdate")} />,
        );
      } catch (error) {
        enqueueSnackbar(
          <Snackbar variant="error" title={(error as GraphQLError).message} />,
        );
      }
    },
    [enqueueSnackbar, t, updateSegment],
  );

  const updateSegmentsGroupMutation = React.useCallback(
    async function updateSegmentsGroupMutation(
      segmentIds: string[],
      group: string | undefined,
    ): Promise<void> {
      try {
        await updateSegments({
          variables: {
            inputs: segmentIds.map((segmentId) => ({
              segmentId,
              group: group || null,
            })),
          },
        });
      } catch (error) {
        enqueueSnackbar(
          <Snackbar variant="error" title={(error as GraphQLError).message} />,
        );
      }
    },
    [enqueueSnackbar, updateSegments],
  );

  return {
    updateSegmentMutation,
    createSegmentMutation,
    deleteSegmentMutation,
    updateSegmentsGroupMutation,
  };
}
