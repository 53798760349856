import React from "react";
import { Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "@lumar/shared";

export function UrlCountCell({ value }: GridRenderCellParams): JSX.Element {
  const { t } = useTranslation("units");

  return (
    <Typography variant="body2">
      {t("number", { count: value as number })}
    </Typography>
  );
}
