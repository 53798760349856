var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";
import { DataGridPro, LicenseInfo, } from "@mui/x-data-grid-pro";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";
import { LoadingOverlayClass } from "./BlueGridLoadingOverlay";
import { useBlueGridPaginationState } from "./useBlueGridPaginationState";
import { useBlueGridComponents } from "./useBlueGridComponents";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");
function BlueDataGridInner(props) {
    var _a;
    const { name = "blue-grid", paginationMode = "client", paginationState: state, defaultPaginationState: defaultState, onPaginationStateChange: onStateChange, resetPageOnSortChange = true, resetPageOnFilterChange = true, searchColumn, searchOperator, components, componentsProps, totalRowCount, autoHeight = true, headerHeight = 40, pagination = true, disableSelectionOnClick = true, disableDensitySelector = true, disableColumnSelector = true, disableColumnFilter = true, hideFooterRowCount = true, disableColumnsButton = false, hideFooterSelectedRowCount = true, enableExport, autoRowHeight, hideFooter: providedHideFooter, error } = props, _b = props.classes, _c = _b === void 0 ? {} : _b, { errorState } = _c, classes = __rest(_c, ["errorState"]), rest = __rest(props, ["name", "paginationMode", "paginationState", "defaultPaginationState", "onPaginationStateChange", "resetPageOnSortChange", "resetPageOnFilterChange", "searchColumn", "searchOperator", "components", "componentsProps", "totalRowCount", "autoHeight", "headerHeight", "pagination", "disableSelectionOnClick", "disableDensitySelector", "disableColumnSelector", "disableColumnFilter", "hideFooterRowCount", "disableColumnsButton", "hideFooterSelectedRowCount", "enableExport", "autoRowHeight", "hideFooter", "error", "classes"]);
    const tableRef = useRef(null);
    const paginationState = useBlueGridPaginationState({
        paginationMode,
        state,
        defaultState,
        onStateChange,
        resetPageOnFilterChange,
        resetPageOnSortChange,
        tableRef,
        columns: props.columns,
    });
    const rowCount = props.rowCount !== undefined ? props.rowCount : props.rows.length;
    const hideFooter = providedHideFooter !== undefined
        ? providedHideFooter
        : (rowCount !== null && rowCount !== void 0 ? rowCount : 0) <= ((_a = props.pageSize) !== null && _a !== void 0 ? _a : 0);
    const hasNoColumns = props.visibleColumnsCount !== undefined && props.visibleColumnsCount === 0;
    const hasFilteredOutRows = totalRowCount !== undefined && totalRowCount !== rowCount;
    const gridComponents = useBlueGridComponents({
        components,
        componentsProps,
        name,
        enableExport,
        disableColumnsButton,
        error,
        hasFilteredOutRows,
        hasNoColumns,
        searchColumn,
        searchOperator,
        loading: props.loading,
    });
    function getErrorStatePropsOverwrite() {
        if (!error)
            return undefined;
        return {
            rows: [],
            rowCount: undefined,
            hideFooter: true,
            classes: Object.assign(Object.assign({}, classes), { root: clsx(classes.root, errorState) }),
        };
    }
    function getNoColumnsPropsOverwrite() {
        if (!hasNoColumns)
            return undefined;
        return {
            rows: [],
            rowCount: undefined,
            hideFooter: true,
        };
    }
    return (_jsx(DataGridPro, Object.assign({}, gridComponents, paginationState, rest, { autoHeight: autoHeight, headerHeight: headerHeight, pagination: pagination, disableSelectionOnClick: disableSelectionOnClick, disableDensitySelector: disableDensitySelector, disableColumnSelector: disableColumnSelector, disableColumnFilter: disableColumnFilter, hideFooterRowCount: hideFooterRowCount, hideFooterSelectedRowCount: hideFooterSelectedRowCount, hideFooter: hideFooter, ref: tableRef, disableVirtualization: autoRowHeight || rest.disableVirtualization, classes: classes }, getErrorStatePropsOverwrite(), getNoColumnsPropsOverwrite())));
}
export const BlueDataGrid = withStyles((theme) => ({
    root: ({ autoRowHeight, rowHeight = 52, sticky, headerHeight = 40, }) => {
        const autoRowHeightStyles = autoRowHeight
            ? getAutoRowHeightStyle(rowHeight)
            : {};
        const stickyHeaderStyles = getStickyHeaderStyle(theme, headerHeight, sticky);
        return Object.assign(Object.assign(Object.assign({}, autoRowHeightStyles), stickyHeaderStyles), { background: "transparent", borderStyle: "none", "& .MuiDataGrid-main": {
                background: theme.palette.background.paper,
                borderRadius: "8px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[200],
                boxShadow: theme.shadows[1],
            }, "& .MuiDataGrid-toolbarContainer": {
                backgroundColor: "#EBEFF3",
                padding: "9px 10px 17px 10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                marginBottom: "-8px",
            }, "& .MuiDataGrid-overlay": {
                position: "initial",
                background: "transparent",
                [`&.${LoadingOverlayClass}`]: {
                    position: "absolute",
                },
            }, "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: theme.typography.pxToRem(13),
                fontWeight: 500,
            }, "& .MuiDataGrid-cell": Object.assign(Object.assign({ padding: "0px 8px 0px 16px" }, autoRowHeightStyles["& .MuiDataGrid-cell"]), { scrollbarGutter: "stable", overflow: "auto", "&::-webkit-scrollbar": {
                    backgroundColor: "rgba(0,0,0,0)",
                    width: 8,
                    height: 8,
                    zIndex: 999999,
                }, "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0)",
                }, "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0)",
                    borderRadius: 8,
                    border: "0px solid #fff",
                }, "&::-webkit-scrollbar-button": {
                    display: "none",
                }, "&:hover::-webkit-scrollbar-thumb": {
                    backgroundColor: "#a0a0a5",
                }, "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#a0a0a5",
                } }), "& .MuiDataGrid-iconButtonContainer": {
                "& svg": {
                    width: 16,
                    height: 16,
                    color: theme.palette.grey[400],
                },
                "& button": {
                    "&:hover": {
                        backgroundColor: "unset",
                    },
                },
            } });
    },
    columnHeader: {},
    row: {},
    cell: {},
    errorState: {
        "& .MuiDataGrid-columnsContainer": {
            display: "none",
        },
        "& .MuiDataGrid-windowContainer": {
            display: "none",
        },
    },
}))(BlueDataGridInner);
function getStickyHeaderStyle(theme, headerHeight, sticky) {
    if (!sticky)
        return {};
    return {
        "& .MuiDataGrid-columnsContainer": Object.assign(Object.assign({ position: "sticky", top: 0 }, (typeof sticky === "object" ? sticky : {})), { backgroundColor: theme.palette.background.paper, marginBottom: -headerHeight, zIndex: 1 }),
        "& .MuiDataGrid-overlay + .MuiDataGrid-columnsContainer": {
            position: "absolute",
        },
    };
}
function getAutoRowHeightStyle(minRowHeight) {
    return {
        "& .MuiDataGrid-windowContainer": {
            height: "auto !important",
        },
        "& .MuiDataGrid-window": {
            position: "initial",
            marginTop: 40,
        },
        "& .MuiDataGrid-dataContainer": {
            minHeight: "0 !important",
        },
        "& .MuiDataGrid-viewport": {
            maxHeight: "none !important",
        },
        "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important",
            minHeight: `${minRowHeight}px !important`,
        },
        "& .MuiDataGrid-cell": {
            maxHeight: "none !important",
            minHeight: `${minRowHeight}px !important`,
        },
        "& .MuiDataGrid-row": {
            maxHeight: "none !important",
            minHeight: `${minRowHeight}px !important`,
        },
    };
}
