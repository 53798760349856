import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { getProperty, TagInput } from "@lumar/shared";
import { uniq } from "lodash";

import { SettingControlFactory } from "../useAdvancedControls";

export const getRemoveUrlParametersControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.removeUrlParams.title"),
  path: "removeUrlParams",
  // eslint-disable-next-line react/display-name
  control: () => <RemoveUrlParameters />,
  testId: "crawl-settings-advanced-remove-params",
  activeValues: ["params"],
  textForSearch: createTextForSearch([
    t("settings.removeUrlParams.title"),
    t("settings.removeUrlParams.description"),
    t("settings.removeUrlParams.exapmle"),
    t("settings.removeUrlParams.placeholder"),
  ]),
  fieldNames: [["params", t("settings.removeUrlParams.paramField")]],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const RemoveUrlParameters = React.memo(RemoveUrlParametersInner);

function RemoveUrlParametersInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        {t("settings.removeUrlParams.description")}
      </Typography>
      <code className={clsx(classes.example, classes.indent)}>
        <Trans
          ns="crawlSettings"
          i18nKey="settings.removeUrlParams.exapmle"
          components={{
            bold: <span className={classes.bold} />,
          }}
        />
      </code>
      <FastField name="test.removeUrlParams.params">
        {({
          field: { value, name },
          form: { setFieldValue, isSubmitting, errors },
        }: FastFieldProps<string[]>) => {
          const error: string[] | undefined = getProperty(errors, name);
          return (
            <TagInput
              value={value}
              onChange={(value) => setFieldValue(name, uniq(value), true)}
              disabled={isSubmitting}
              addOnSpace
              addOnComma
              showCopyAll
              error={error?.find(Boolean)}
              errors={error?.map((error) => Boolean(error))}
              variant="outlined"
              className={classes.indent}
              placeholder={t("settings.removeUrlParams.placeholder")}
              data-testid="remove-url-parameters"
            />
          );
        }}
      </FastField>
    </>
  );
}
