import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityKeyboardAChart } from "../../../../../_common/charts/config/getAccessibilityKeyboardAChart";
import { getAccessibilityKeyboardATrend } from "../../../../../_common/charts/config/getAccessibilityKeyboardATrend";

export const generateAccessibilityKeyboardACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityKeyboardAChart,
      getAccessibilityKeyboardATrend,
    ],
  };
