import React, { useCallback } from "react";
import {
  FilterOrRuleFormValue,
  FilterRuleFormValue,
  NarrowMetric,
} from "../types";
import { FieldArrayRenderProps } from "formik";
import { OrStatementBlock } from "./OrStatementBlock";
import { AddOrStatementButton } from "./AddOrStatementButton";
import { cloneFilter, getFormOrFilter } from "../helpers";

interface FilterOrRuleFieldContainerProps {
  name: string;
  value: FilterOrRuleFormValue[];
  metrics: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  replace: FieldArrayRenderProps["replace"];
  remove: FieldArrayRenderProps["remove"];
  push: FieldArrayRenderProps["push"];
  allowDeletingLastEmptyRule?: boolean;
  onLastRemainingRuleDeleted?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
}

export function FilterOrRuleFieldContainer(
  props: FilterOrRuleFieldContainerProps,
): JSX.Element {
  const isOneOrBlockLeft = props.value.length === 1;
  const { replace, remove, defaultFilter, onLastRemainingRuleDeleted } = props;
  const onLastRemainingRuleDeletedInOrBlock = useCallback(
    (index: number) => {
      if (isOneOrBlockLeft) {
        replace(index, getFormOrFilter(cloneFilter(defaultFilter)));
        if (onLastRemainingRuleDeleted) {
          onLastRemainingRuleDeleted();
        }
      } else {
        remove(index);
      }
    },
    [
      replace,
      remove,
      defaultFilter,
      isOneOrBlockLeft,
      onLastRemainingRuleDeleted,
    ],
  );
  return (
    <>
      {props.value.map((value, index) => {
        const isLast = index === props.value.length - 1;
        return (
          <React.Fragment key={value.id}>
            <OrStatementBlock
              name={props.name}
              index={index}
              metrics={props.metrics}
              defaultFilter={props.defaultFilter}
              autoFocus={props.autoFocus}
              onLastRemainingRuleDeletedInOrBlock={
                onLastRemainingRuleDeletedInOrBlock
              }
              allowDeletingLastEmptyRule={
                !isOneOrBlockLeft || props.allowDeletingLastEmptyRule
              }
            />
            <AddOrStatementButton
              isLastOrStatement={isLast}
              onAddOrStatement={() =>
                // eslint-disable-next-line fp/no-mutating-methods
                props.push(getFormOrFilter(cloneFilter(defaultFilter)))
              }
              disabled={props.disabled}
            />
          </React.Fragment>
        );
      })}
    </>
  );
}
