import { Project, UpdateProjectInput } from "../../../../../graphql";
import { RedirectSettings } from "../types";

type QueryData = Pick<
  Project,
  "crawlRedirectsInternal" | "crawlRedirectsExternal"
>;

export function formatRedirectSettingsForm(
  data?: QueryData | null,
): RedirectSettings {
  return {
    followInternalRedirects: data?.crawlRedirectsInternal ?? false,
    followExternalRedirects: data?.crawlRedirectsExternal ?? false,
  };
}

export function formatRedirectSettingsTo(
  formValues: RedirectSettings,
): Pick<
  UpdateProjectInput,
  "crawlRedirectsInternal" | "crawlRedirectsExternal"
> {
  return {
    crawlRedirectsInternal: formValues.followInternalRedirects,
    crawlRedirectsExternal: formValues.followExternalRedirects,
  };
}
