import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles } from "@material-ui/core";
import { EmptyState } from "../../..";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(10),
    },
    message: {
        textAlign: "center",
        display: "inline-block",
        color: theme.palette.grey[700],
    },
    icon: {
        display: "flex",
    },
}));
export function MissingSourcesMessage({ editCrawlUrl, text, hasEditorPermission, }) {
    const classes = useStyles();
    const isExternalLink = editCrawlUrl.startsWith("http");
    return (_jsx("div", { className: classes.container, children: _jsx(Box, { mb: 3, maxWidth: 460, children: _jsx(EmptyState, { description: text.description, icon: _jsx(MissingSourcesIcon, {}), actions: hasEditorPermission
                    ? [
                        Object.assign({ title: text.action }, (isExternalLink
                            ? { type: "externalLink", href: editCrawlUrl }
                            : { type: "internalLink", to: editCrawlUrl })),
                    ]
                    : [] }) }) }));
}
function MissingSourcesIcon() {
    return (_jsxs("svg", { width: "32", height: "33", viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { opacity: "0.2", d: "M14.4995 25.5C20.2985 25.5 24.9995 20.799 24.9995 15C24.9995 9.20101 20.2985 4.5 14.4995 4.5C8.70052 4.5 3.99951 9.20101 3.99951 15C3.99951 20.799 8.70052 25.5 14.4995 25.5Z", fill: "#2563EB" }), _jsx("path", { d: "M14.4995 25.5C20.2985 25.5 24.9995 20.799 24.9995 15C24.9995 9.20101 20.2985 4.5 14.4995 4.5C8.70052 4.5 3.99951 9.20101 3.99951 15C3.99951 20.799 8.70052 25.5 14.4995 25.5Z", stroke: "#2563EB", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M21.9238 22.425L27.9989 28.5001", stroke: "#2563EB", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }));
}
