import {
  ChartBar,
  Code,
  FileText,
  FolderNotchOpen,
  Note,
  Shield,
  useTranslation,
} from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  Indicator,
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useSeoDetailsSections } from "./useSeoDetailsSections";
import {
  CrawlUrlPageTypePresenter,
  DuplicatePresenter,
  IndexabilityPresenter,
  SourcePresenter,
  StatusCodeSimplePresenter,
} from "../../metrics-value-presenter";

export function SeoDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  const { active } = useSeoDetailsSections(data.metrics);

  const config = useSummaryConfig(data.metrics);

  return (
    <ResourceDetailBase
      title={t("defaultTitle")}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      { code: "url" },
      { code: "foundAtUrl" },
      { code: "foundAtSitemap" },
      {
        code: "foundIn",
        name: t("metricFoundIn"),
        value: <SourcePresenter {...getMetricProps("foundIn")} />,
      },
    ],
    cardDetails: [
      {
        code: "issueType",
        name: t("pageType"),
        icon: FileText,
        value: <CrawlUrlPageTypePresenter {...getMetricProps("issueType")} />,
      },
      {
        code: "httpStatusCode",
        icon: Code,
        value: (
          <StatusCodeSimplePresenter {...getMetricProps("httpStatusCode")} />
        ),
        indicator: getHttpStatusCodeIndicator(
          String(metrics["httpStatusCode"]?.value),
        ),
      },
      {
        code: "indexable",
        icon: FolderNotchOpen,
        value: <IndexabilityPresenter {...getMetricProps("indexable")} />,
        indicator: getIndexableIndicator(metrics["indexable"]?.value),
      },
      {
        code: "duplicatePage",
        icon: Note,
        value: <DuplicatePresenter {...getMetricProps("duplicatePage")} />,
        indicator: getDuplicateIndicator(metrics["duplicatePage"]?.value),
      },
      {
        code: "level",
        icon: ChartBar,
      },
      {
        code: "deeprank",
        icon: Shield,
      },
    ],
  };
}

function getHttpStatusCodeIndicator(status: string): Indicator {
  if (status.startsWith("2")) return "success";
  if (status.startsWith("3")) return "warning";
  return "error";
}

function getIndexableIndicator(value: unknown): Indicator {
  return value ? "success" : "error";
}

function getDuplicateIndicator(value: unknown): Indicator {
  return value ? "error" : "success";
}
