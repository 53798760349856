import React, { useCallback, memo } from "react";
import Box from "@material-ui/core/Box";
import { FilterRuleFormValue, NarrowMetric } from "../types";
import { FilterRuleFieldArray } from "../filter-rule-field-array/FilterRuleFieldArray";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    borderColor: theme.palette.grey[50],
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(2),
    width: "100%",
  },
}));

interface OrStatementBlockProps {
  name: string;
  index: number;
  metrics: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  onLastRemainingRuleDeletedInOrBlock: (index: number) => void;
  allowDeletingLastEmptyRule?: boolean;
  autoFocus?: boolean;
}

function OrStatementBlockInner(props: OrStatementBlockProps): JSX.Element {
  const classes = useStyles();

  const { onLastRemainingRuleDeletedInOrBlock, index } = props;

  const onLastRemainingRuleDeleted = useCallback(
    () => onLastRemainingRuleDeletedInOrBlock(index),
    [onLastRemainingRuleDeletedInOrBlock, index],
  );

  return (
    <Box className={classes.root} data-testid="or-statement-block">
      <FilterRuleFieldArray
        name={`${props.name}.${props.index}._and`}
        metrics={props.metrics}
        defaultFilter={props.defaultFilter}
        allowDeletingLastEmptyRule={props.allowDeletingLastEmptyRule}
        onLastRemainingRuleDeleted={onLastRemainingRuleDeleted}
        autoFocus={props.autoFocus}
      />
    </Box>
  );
}

export const OrStatementBlock = memo(OrStatementBlockInner, arePropsEqual);
