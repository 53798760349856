import {
  LocationCode,
  Location,
  UpdateProjectInput,
} from "../../../../../graphql";
import { CrawlerIpSettings } from "../types";

type QueryData = { location: Pick<Location, "code"> };

export function formatCrawlerIpSettingsFrom(
  data?: QueryData | null,
): CrawlerIpSettings {
  return {
    location: data?.location?.code ?? LocationCode.Default,
  };
}

export function formatCrawlerIpSettingsTo(
  formValues: CrawlerIpSettings,
): Pick<UpdateProjectInput, "locationCode"> {
  return {
    locationCode: formValues.location,
  };
}
