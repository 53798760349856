/* eslint-disable prefer-const */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable fp/no-let */
import React from "react";
import { ConfirmationDialog, Typography, useTranslation } from "@lumar/shared";
import { FormValues, ValidationErrors } from "./data/types";
import { makeStyles } from "@material-ui/core";
import { FormikErrors } from "formik";
import { isString } from "lodash";
import { TFunction } from "i18next";

interface ValidationErrorDialogProps {
  validationErrors?: ValidationErrors;
}

export function ValidationErrorDialog({
  validationErrors,
}: ValidationErrorDialogProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => setOpen(Boolean(validationErrors)), [validationErrors]);

  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      variant="error"
      title={t("settings.validationErros")}
      content={<ValidationError errors={validationErrors} />}
      primaryAction={() => setOpen(false)}
      withGreyFooter
    />
  );
}

const useStyles = makeStyles(() => ({
  errorGroup: {
    fontWeight: 600,
  },
  error: {
    display: "block",
  },
}));

function ValidationError({
  errors,
}: {
  errors?: ValidationErrors;
}): JSX.Element | null {
  const classes = useStyles();

  if (!errors) return null;
  return (
    <div>
      {errors.groups.map((group, idx) => (
        <React.Fragment key={idx}>
          <Typography variant="caption" className={classes.errorGroup}>
            {group.name}
          </Typography>
          <div style={{ paddingLeft: 8 }}>
            {group.subGroup.map((group, idx) => (
              <React.Fragment key={idx}>
                <Typography variant="caption" className={classes.errorGroup}>
                  {group.name}
                </Typography>
                <div style={{ paddingLeft: 8 }}>
                  {group.errors.map((error, idx) => (
                    <Typography key={idx} className={classes.error}>
                      {error}
                    </Typography>
                  ))}
                </div>
              </React.Fragment>
            ))}
            {group.errors.map((error, idx) => (
              <Typography key={idx} className={classes.error}>
                {error}
              </Typography>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export function formatValidationError(
  error: FormikErrors<FormValues> | undefined,
  t: TFunction<"crawlSettings">,
): ValidationErrors {
  function getLabel(path: string): string {
    switch (path) {
      case "backlinks":
        return t("sources.backlinks.title");
      case "backlinks.majestic":
        return t("sources.backlinks.majestic.title");
      case "backlinks.majestic.maxRows":
        return t("sources.backlinks.majestic.limitsField");
      case "googleSearchConsole":
        return t("sources.googleSearchConsole.title");
      case "googleSearchConsole.minClicks":
        return t("sources.googleSearchConsole.filters.minimumClicks");
      case "googleSearchConsole.lastNDays":
        return t("sources.googleSearchConsole.filters.dateRange");
      case "analytics":
        return t("sources.analytics.title");
      case "analytics.adobe":
        return t("sources.analytics.adobeAnalytics.title");
      case "analytics.adobe.numberOfDays":
        return t("sources.analytics.adobeAnalytics.rangeField");
      case "analytics.adobe.maximumUrls":
        return t("sources.analytics.adobeAnalytics.maximumUrlsField");
    }
    return path;
  }

  function getErrors(
    errors: Record<string, unknown>,
    group: string,
  ): [string[], [string, string[]][]] {
    let errorMessages: string[] = [];
    let subGroups: [string, string[]][] = [];

    Object.entries(errors).map(([field, error]) => {
      if (isString(error)) {
        errorMessages.push(`${getLabel(`${group}.${field}`)}: ${error}`);
      } else {
        subGroups.push([
          getLabel(`${group}.${field}`),
          Object.entries(error as Record<string, string>).map(
            ([subField, error]) =>
              `${getLabel(`${group}.${field}.${subField}`)}: ${error}`,
          ),
        ]);
      }
    });

    return [errorMessages, subGroups];
  }

  return {
    groups: Object.entries(error ?? {}).map(([groupKey, error]) => {
      const [errorMessages, subGroups] = getErrors(error, groupKey);
      return {
        name: getLabel(groupKey),
        errors: errorMessages,
        subGroup: subGroups.map((x) => ({ name: x[0], errors: x[1] })),
      };
    }),
  };
}
