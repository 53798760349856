import { CopyIcon, getEntityRawId, useTranslation } from "@lumar/shared";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Check } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  icon: { fontSize: 16 },
  button: {
    marginLeft: 10,
  },
}));

export function IdCell(props: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlsList");
  const crawlId = getEntityRawId(`${props.value}`) ?? "";
  const [copied, setCopied] = useState(false);

  const onClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(crawlId);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <>
      {crawlId}
      <Tooltip title={copied ? t("copied") : t("copy")} placement="top">
        <IconButton
          color="primary"
          onClick={onClick}
          className={classes.button}
        >
          {copied ? (
            <Check className={classes.icon}></Check>
          ) : (
            <CopyIcon className={classes.icon}></CopyIcon>
          )}
        </IconButton>
      </Tooltip>
    </>
  );
}
