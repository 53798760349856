import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  FormControlLabel,
  Hidden,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { translationNamespace } from "../../CrawlSettings";
import {
  InputLabelInfo,
  LabelInfo,
} from "../../../../_common/components/LabelInfo";
import { withDisplayValue } from "../../components/TextFieldInput";
import { withOnBlurUpdate } from "../../components/CustomFields";
import { StyledInputLabel } from "../../../../_common/forms/StyledInputLabel";

const CustomTextField = withOnBlurUpdate(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: "100%",
  },
  limitsLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "block",
  },
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export function MajesticAdvanced(): JSX.Element {
  const { t } = useTranslation(translationNamespace);

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const integerPattern = /^(\+?\d+)?$/;

  const retrievalOptions = [
    {
      value: "false",
      label: t("sources.backlinks.majestic.retrievalPrimary"),
      info: t("sources.backlinks.majestic.retrievalPrimaryDesc"),
    },
    {
      value: "true",
      label: t("sources.backlinks.majestic.retrievalRoot"),
      info: t("sources.backlinks.majestic.retrievalRootDesc"),
    },
  ];

  const indexOptions = [
    {
      value: "false",
      label: t("sources.backlinks.majestic.indexFresh"),
      info: t("sources.backlinks.majestic.indexFreshDesc"),
    },
    {
      value: "true",
      label: t("sources.backlinks.majestic.indexHistoric"),
      info: t("sources.backlinks.majestic.indexHistoricDesc"),
    },
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      elevation={1}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        data-testid="crawl-settings-show-advanced-majestic"
      >
        <Grid container alignItems="center">
          <SettingsIcon
            className={classes.heading}
            style={{ marginBottom: "2px" }}
          />
          <Typography
            className={classes.heading}
            component="div"
            style={{ marginLeft: "8px" }}
          >
            {t("sources.backlinks.majestic.advancedLabel")}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabelInfo
              label={t("sources.backlinks.majestic.limitsLabel")}
              info={t("sources.backlinks.majestic.limitsDescription")}
            />
            <FastField
              name="backlinks.majestic.maxRows"
              component={CustomTextField}
              pattern={integerPattern}
              hiddenLabel
              variant="outlined"
              placeholder={t("sources.backlinks.majestic.limitsPlaceholder")}
              className={classes.input}
              data-testid="crawl-settings-max-rows"
              InputProps={{
                inputComponent: withDisplayValue((value) =>
                  t("sources.backlinks.majestic.limitsFormat", {
                    limit: value,
                  }),
                ),
              }}
            />
          </Grid>
          <Hidden only={["xs", "sm"]}>
            <Grid item md={6} />
          </Hidden>
          <Grid item xs={12} md={6}>
            <StyledInputLabel>
              {t("sources.backlinks.majestic.retrevalTitle")}
            </StyledInputLabel>
            <FastField name="backlinks.majestic.useRootDomain">
              {({
                field: { name, value },
                form: { setFieldValue, isSubmitting },
              }: FastFieldProps<string>) => (
                <RadioGroup
                  value={value}
                  onChange={(e) => setFieldValue(name, e.target.value)}
                  style={{ marginTop: "4px" }}
                >
                  {retrievalOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <LabelInfo label={option.label} info={option.info} />
                      }
                      style={{ marginBottom: "4px" }}
                      disabled={isSubmitting}
                      data-testid="crawl-settings-domain-option"
                    />
                  ))}
                </RadioGroup>
              )}
            </FastField>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledInputLabel>
              {t("sources.backlinks.majestic.indexTitle")}
            </StyledInputLabel>
            <FastField name="backlinks.majestic.useHistoricData">
              {({
                field: { name, value },
                form: { setFieldValue, isSubmitting },
              }: FastFieldProps<string>) => (
                <RadioGroup
                  value={value}
                  onChange={(e) => setFieldValue(name, e.target.value)}
                  style={{ marginTop: "4px" }}
                >
                  {indexOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={
                        <LabelInfo label={option.label} info={option.info} />
                      }
                      style={{ marginBottom: "4px" }}
                      disabled={isSubmitting}
                      data-testid="crawl-settings-historic-option"
                    />
                  ))}
                </RadioGroup>
              )}
            </FastField>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
