import { makeStyles, useTheme } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";

const useStyles = makeStyles((theme) => ({
  indicator: {
    display: "inline-block",
    width: 5,
    height: 24,
    borderRadius: 4,
    marginRight: theme.spacing(1.125),
    verticalAlign: "middle",
  },
  value: {
    verticalAlign: "middle",
  },
}));

export function ColorPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  if (!value) return <>-</>;

  const lightness = getColorLightness(value);

  return (
    <>
      <span
        className={classes.indicator}
        style={{
          background: value,
          border:
            lightness > 95 ? `1px solid ${theme.palette.grey[300]}` : undefined,
        }}
      />
      <span className={classes.value}>{value}</span>
    </>
  );
}

function getColorLightness(color: string): number {
  const [red, green, blue] = (() => {
    if (color.length === 4 || color.length == 5)
      return [
        Number("0x" + color[1] + color[1]) / 255,
        Number("0x" + color[2] + color[2]) / 255,
        Number("0x" + color[3] + color[3]) / 255,
      ];
    if (color.length === 7 || color.length === 9)
      return [
        Number("0x" + color[1] + color[2]) / 255,
        Number("0x" + color[3] + color[4]) / 255,
        Number("0x" + color[5] + color[6]) / 255,
      ];

    return [0, 0, 0];
  })();

  const lum = Math.max(red, green, blue);
  const sat = lum - Math.min(red, green, blue);

  return (100 * (2 * lum - sat)) / 2;
}
