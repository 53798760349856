/* eslint-disable fp/no-mutation */
/* eslint-disable i18next/no-literal-string */
import {
  BreadcrumbItem,
  useAuth,
  useSession,
  useTranslation,
} from "@lumar/shared";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import { RoleCode } from "../../graphql";
import { CreateShareLinkButton } from "../create-share-link-button/CreateShareLinkButton";
import { useLatestCrawlRedirect } from "../create-share-link-button/useLatestCrawlRedirect";
import { AccountMenu } from "./AccountMenu";
import { PageBreadcrumbs } from "./PageBreadcrumbs";
import { PageTitle } from "./PageTitle";
import { ProjectSummary } from "./ProjectSummary";
import { SubscriptionAlert } from "./SubscriptionAlert";
import { useTopNavigationData } from "./useTopNavigationData";

const TOP_NAVIGATION_DOM_ELEMENT_ID = "top-bar";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: "unset",
    },
    toolbar: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "transparent",
      minHeight: "0px",
      padding: "0px",
    },
    boxItems: {
      marginLeft: "auto",
    },
    appControlsContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 70,
      width: "100%",
    },
    titleContainer: {
      marginBottom: 5,
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
      flexWrap: "wrap",
      gap: theme.spacing(1),
    },
    actionContainer: {
      marginLeft: theme.spacing(1),
    },
    title: {
      overflow: "hidden",
      display: "flex",
      alignItems: "baseline",
      minWidth: 80,
      marginBottom: theme.spacing(0.5),
    },
    newExperienceSwitch: {
      borderRadius: "61px",
      height: "36px",
      backgroundColor: "white",
      borderWidth: "1.5px",
      borderStyle: "solid",
      borderColor: theme.palette.grey[300],
      boxShadow: theme.shadows[1],
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(0.6),
      paddingRight: theme.spacing(0.9),
      marginRight: theme.spacing(1.5),
    },
    actionsContainer: {
      marginLeft: "auto",
      display: "flex",
      justifyContent: "end",
      flexWrap: "nowrap",
      flexShrink: 1,
      flexGrow: 1,
      gap: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start",
      },
    },
  }),
);

export interface TopNavigationProps {
  title?: string;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  breadcrumbs?: BreadcrumbItem[];
  crawlId?: string;
  loading?: boolean;
  capitalizeTitle?: boolean;
  availableForShare?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
  getShareLinkAdditionalParams?: () => string[];
}

export function TopNavigation({
  title,
  leftComponent,
  rightComponent,
  breadcrumbs,
  crawlId,
  loading: providedLoading,
  availableForShare,
  capitalizeTitle,
  actions,
  getShareLinkAdditionalParams,
}: TopNavigationProps): JSX.Element {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId?: string }>();

  const { data, loading: dataLoading } = useTopNavigationData();
  const loading = providedLoading || dataLoading;

  useLatestCrawlRedirect();

  const actionComponents: React.ReactNode[] = [
    ...(actions ? (Array.isArray(actions) ? actions : [actions]) : []),
    <ShareLink
      key={"share_" + projectId}
      availableForShare={availableForShare}
      getAdditionalParams={getShareLinkAdditionalParams}
    />,
  ];

  const showProjectSummary = Boolean(projectId);
  const shouldShowBottomContent = title || actionComponents.length > 0;

  return (
    <AppBar
      id={TOP_NAVIGATION_DOM_ELEMENT_ID}
      className={classes.appBar}
      position="relative"
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        <Box className={classes.appControlsContainer}>
          <PageBreadcrumbs
            breadcrumbs={breadcrumbs}
            loading={loading}
            projectName={data.projectName}
            moduleCode={data.moduleCode}
            crawlId={crawlId}
          />
          <div className={classes.boxItems}>
            <Grid container wrap="nowrap">
              {showProjectSummary ? (
                <Grid item style={{ marginRight: "8px" }}>
                  <ProjectSummary />
                </Grid>
              ) : null}
              <Grid item>
                <AccountMenu />
              </Grid>
            </Grid>
          </div>
        </Box>
        {shouldShowBottomContent ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box className={classes.titleContainer}>
              <div className={classes.title}>
                <PageTitle
                  title={title}
                  leftComponent={leftComponent}
                  rightComponent={rightComponent}
                  capitalize={capitalizeTitle}
                  projectName={data.projectName}
                  loading={loading}
                />
              </div>
              <div className={classes.actionsContainer}>
                {actionComponents.filter((x) => x !== null)}
              </div>
            </Box>
          </div>
        ) : null}
      </Toolbar>
      <SubscriptionAlert />
    </AppBar>
  );
}

function ShareLink({
  availableForShare,
  getAdditionalParams,
}: {
  availableForShare?: boolean;
  getAdditionalParams?: () => string[];
}): JSX.Element | null {
  const { hasSufficientRole, isUsingSharedLink } = useSession();

  if (isUsingSharedLink) {
    return <StopUsingShareLinkButton />;
  }

  if (availableForShare && hasSufficientRole(RoleCode.Editor)) {
    return <CreateShareLinkButton getAdditionalParams={getAdditionalParams} />;
  }

  return null;
}

function StopUsingShareLinkButton(): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation("createShareLink");
  return (
    <Button
      size="large"
      variant="outlined"
      onClick={() => auth.logout()}
      style={{
        backgroundColor: "#FEF2F2",
        color: "#0A1124",
        height: "35.5px",
        flexShrink: 0,
      }}
      startIcon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47176 18.364 5.63604C17.5282 4.80031 16.5361 4.13737 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3ZM14.25 14.25H9.75V9.75H14.25V14.25Z"
            fill="#EF4444"
          />
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke="#EF4444"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M14.25 9.75H9.75V14.25H14.25V9.75Z"
            stroke="#EF4444"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      data-testid="stop-using-share-link-button"
    >
      {t("stop")}
    </Button>
  );
}
