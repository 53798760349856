import { JSONTable } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";
import { BooleanPresenter } from "./BooleanPresenter";
import { NumberPresenter } from "./NumberPresenter";
import { StringPresenter } from "./StringPresenter";
import { isObject } from "lodash";

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: -16,
    marginRight: -8,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "stretch",
  },
  string: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "stretch",
    paddingRight: 16,
    width: "fit-content",
  },
}));
export const JSONPresenter = (
  props: MetricsValuePresenterProps & { isGridView?: boolean },
): JSX.Element => {
  const classes = useStyles();
  const data =
    typeof props.value === "string" ? JSON.parse(props.value) : props.value;

  if (Array.isArray(data)) {
    if (props.isGridView) {
      const data =
        Array.isArray(props.value) || isObject(props.value)
          ? JSON.stringify(props.value).replaceAll('\\"', '"')
          : props.value;

      return <div className={classes.string}>{data}</div>;
    } else
      return (
        <div className={classes.root}>
          <JSONTable
            data={data}
            presenters={{
              boolean: (value) => <BooleanPresenter {...props} value={value} />,
              number: (value) => <NumberPresenter {...props} value={value} />,
              string: (value) => <StringPresenter {...props} value={value} />,
              null: () => <>-</>,
            }}
          />
        </div>
      );
  }

  return <div>-</div>;
};
