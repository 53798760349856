import { useNumberFormatter } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function ChangesCell(props: GridRenderCellParams): JSX.Element {
  const formatNumber = useNumberFormatter();
  return (
    <div data-testid="changes-value-cell">
      {typeof props.value === "number" ? formatNumber(props.value) : "-"}
    </div>
  );
}
