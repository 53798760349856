import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, useTheme, withStyles } from "@material-ui/core";
import { GridOverlay } from "@mui/x-data-grid-pro";
import { SmileyMeh } from "../../icons";
import { Typography } from "../typography/Typography";
import { useTranslation } from "react-i18next";
function BlueGridNoColumnsOverlayInner(incomingProps) {
    var _a;
    const theme = useTheme();
    const { t } = useTranslation("grid");
    const props = Object.assign(Object.assign({}, (_a = theme.props) === null || _a === void 0 ? void 0 : _a.BlueGridNoRowsOverlay), incomingProps);
    const { icon: Icon = SmileyMeh, title = t("noColumns.errorTitle"), detail = t("noColumns.errorDetail"), onClick, buttonIcon: ButtonIcon, buttonText, classes, showIcon = true, } = props;
    return (_jsxs(GridOverlay, { className: classes === null || classes === void 0 ? void 0 : classes.root, "data-testid": "no-columns-overlay", children: [showIcon ? _jsx(Icon, { className: classes === null || classes === void 0 ? void 0 : classes.icon }) : null, _jsx(Typography, { variant: "h2SemiBold", className: classes === null || classes === void 0 ? void 0 : classes.title, children: title }), _jsx(Typography, { variant: "body2", className: classes === null || classes === void 0 ? void 0 : classes.detail, children: detail }), onClick && (_jsx(Button, { onClick: onClick, variant: "contained", color: "primary", classes: { root: classes === null || classes === void 0 ? void 0 : classes.button }, size: "large", startIcon: ButtonIcon ? _jsx(ButtonIcon, { className: classes === null || classes === void 0 ? void 0 : classes.buttonIcon }) : null, children: buttonText }))] }));
}
export const BlueGridNoColumnsOverlay = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(6),
    },
    icon: {
        color: theme.palette.yellow[500],
        fontSize: theme.typography.pxToRem(32),
        marginBottom: theme.spacing(2),
    },
    title: {
        color: theme.palette.grey[800],
        marginBottom: theme.spacing(1.5),
    },
    detail: {
        width: 328,
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
    },
}))(BlueGridNoColumnsOverlayInner);
