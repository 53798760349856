var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FormControl, Switch, Typography, withStyles, } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
export function SwitchFieldInner(_a) {
    var _b;
    var { className, classes, offLabel, onLabel, label, neutral } = _a, props = __rest(_a, ["className", "classes", "offLabel", "onLabel", "label", "neutral"]);
    const { t } = useTranslation("sharedCommon");
    const [checkedUncontrolled, setCheckedUncontrolled] = useState(false);
    const checked = (_b = props.checked) !== null && _b !== void 0 ? _b : checkedUncontrolled;
    const [focused, setFocused] = useState(false);
    return (_jsxs(FormControl, { className: className, children: [!!label && _jsx("label", { className: classes === null || classes === void 0 ? void 0 : classes.label, children: label }), _jsxs("div", { className: clsx(classes === null || classes === void 0 ? void 0 : classes.root, focused ? classes === null || classes === void 0 ? void 0 : classes.focused : "", props.disabled ? classes === null || classes === void 0 ? void 0 : classes.disabled : ""), children: [_jsx(Typography, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.valueLabel, !checked ? classes === null || classes === void 0 ? void 0 : classes.activeLabel : "", props.disabled ? classes === null || classes === void 0 ? void 0 : classes.disabledLabel : ""), children: offLabel !== null && offLabel !== void 0 ? offLabel : t("off") }), _jsx(Switch, Object.assign({ checked: checkedUncontrolled, onChange: () => setCheckedUncontrolled(!checkedUncontrolled) }, props, { classes: {
                            root: classes === null || classes === void 0 ? void 0 : classes.switch,
                            input: classes === null || classes === void 0 ? void 0 : classes.input,
                            track: neutral ? classes === null || classes === void 0 ? void 0 : classes.switchTrackNeutral : "",
                        }, onFocus: () => setFocused(true), onBlur: () => setFocused(false) })), _jsx(Typography, { className: clsx(classes === null || classes === void 0 ? void 0 : classes.valueLabel, checked ? classes === null || classes === void 0 ? void 0 : classes.activeLabel : "", props.disabled ? classes === null || classes === void 0 ? void 0 : classes.disabledLabel : ""), children: onLabel !== null && onLabel !== void 0 ? onLabel : t("on") })] })] }));
}
export const SwitchField = withStyles((theme) => ({
    root: {
        borderWidth: "1px",
        borderRadius: "6px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[300],
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: theme.spacing(0.625, 1.25),
        overflow: "hidden",
        "&:hover": {
            "&:not($focused)": {
                borderColor: theme.palette.grey[500],
            },
        },
    },
    focused: {
        borderColor: theme.palette.primary.main,
        borderWidth: "2px",
        padding: theme.spacing(0.5, 1.125),
    },
    disabled: {
        cursor: "default",
    },
    label: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(17),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginBottom: theme.spacing(0.875),
    },
    valueLabel: {
        color: theme.palette.grey[700],
        fontSize: theme.typography.pxToRem(14),
    },
    activeLabel: {
        fontWeight: 600,
    },
    disabledLabel: {
        color: theme.palette.grey[400],
    },
    switch: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        overflow: "visible",
    },
    switchTrackNeutral: {
        backgroundColor: `${theme.palette.grey[200]} !important`,
    },
    input: {
        // Note: This is the simplest solution to allow the whole component to be responsive
        // It may need to be adjusted to fill wider components
        width: "500px",
        left: "-250px",
        height: "50px",
        top: "-25px",
    },
}))(SwitchFieldInner);
