import { DocumentNode, gql } from "@lumar/shared";
import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import { generateQuery } from "../generateMetricFields";
import { OrderDirection, ReportTypeCode } from "../../../graphql";
import { DataExplorerTableConfig } from "../../types";
import { BREADCRUMB_ALIAS } from "./useBreadcrumbData";

export function getBreadcrumDocument(
  crawlId: string,
  tableConfig: DataExplorerTableConfig,
  fragments: { values: string[]; cursor?: string }[],
): DocumentNode {
  const firstColumn = tableConfig.columns[0];
  function capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const orderByColumn = `${firstColumn.aggregationCalculation}${capitalize(
    firstColumn.metric.code,
  )}`;

  const query = {
    query: {
      __name: "getAggregateData",
      report: {
        __aliasFor: "getReportStat",
        __args: {
          input: {
            crawlId,
            reportTemplateCode: "all_pages",
          },
        },
        ...Object.fromEntries(
          fragments.map((fragment, idx) => {
            const dimension = `breadcrumb0${fragment.values.length + 1}`;
            return [
              `${BREADCRUMB_ALIAS}${idx}`,
              {
                __aliasFor: "crawlUrlsAggregates",
                __args: {
                  reportType: new EnumType(ReportTypeCode.Basic),
                  dimensions: [new EnumType(dimension)],
                  filter: {
                    ...Object.fromEntries(
                      fragment.values.map((breadcrumb, idx) => [
                        `breadcrumb0${idx + 1}`,
                        { eq: breadcrumb },
                      ]),
                    ),
                    [dimension]: { isEmpty: false },
                  },
                  orderBy: [
                    {
                      field: new EnumType(orderByColumn),
                      direction: new EnumType("DESC"),
                    },
                    {
                      field: new EnumType(dimension),
                      direction: new EnumType(OrderDirection.Asc),
                    },
                  ],
                  first: 100,
                  ...(fragment.cursor ? { after: fragment.cursor } : {}),
                },
                nodes: {
                  [dimension]: true,
                  ...generateQuery(tableConfig, false),
                  max: {
                    breadcrumbCount: true,
                  },
                },
                pageInfo: {
                  endCursor: true,
                  hasNextPage: true,
                },
              },
            ];
          }),
        ),
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}
