import { ApolloError, useSession, Typography } from "@lumar/shared";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { RoleCode } from "../../graphql";
import { assert } from "../assert";
import { Routes } from "../routing/routes";
import { useGenericParams } from "../routing/useGenericParams";
import { useAccountsRoutes } from "../routing/accountsRoutes";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  actionButton: {
    minWidth: 0,
  },
}));

interface RunCrawlErrorDialogProps {
  error: ApolloError;
  onClose?: () => void;
  projectId: string;
  crawlId?: string;
}

export function RunNewCrawlErrorDialog(
  props: RunCrawlErrorDialogProps,
): JSX.Element {
  const { error, onClose, projectId, crawlId } = props;
  const classes = useStyles();
  const { accountId } = useGenericParams();
  const { t } = useTranslation(["common", "runNewCrawlErrorDialog"]);
  const session = useSession();
  const accountsRoutes = useAccountsRoutes();

  assert(accountId);
  assert(projectId);

  const errorCode = error?.graphQLErrors[0]?.extensions?.code;
  const hasNoCreditError = errorCode === "NO_CREDITS";
  const hasCrawlInProgressError = errorCode === "CONFLICT";

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-messsage"
    >
      <DialogTitle disableTypography>
        <Typography variant="h6Medium" classes={{ root: classes.title }}>
          {t("runNewCrawlErrorDialog:title")}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent id="alert-dialog-message">
        {hasCrawlInProgressError && (
          <Typography>{t("runNewCrawlErrorDialog:crawlInProgress")}</Typography>
        )}
        {hasNoCreditError && (
          <Typography>
            {t("runNewCrawlErrorDialog:notEnoughCredits")}
          </Typography>
        )}
        {!hasCrawlInProgressError && !hasNoCreditError && error.message}
      </DialogContent>
      <Divider />
      <DialogActions>
        {hasCrawlInProgressError && crawlId && (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<EditIcon />}
            component={RouterLink}
            to={Routes.Crawls.getUrl({
              accountId,
              projectId,
              tab: "edit",
              step: 1,
            })}
            classes={{ root: classes.actionButton }}
          >
            {t("common:editProject")}
          </Button>
        )}
        {hasNoCreditError && session.hasSufficientRole(RoleCode.Admin) && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              window.location.href = accountsRoutes.Subscription.getUrl({
                accountId,
              });
            }}
            classes={{ root: classes.actionButton }}
          >
            {t("common:upgrade")}
          </Button>
        )}
        <Button onClick={onClose} variant="outlined" autoFocus>
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
