import {
  DestructiveMenuActionConfirmation,
  DotsHorizontalOutlined,
  EditOutlined,
  Snackbar,
  ToggleIconButton,
  TrashOutlined,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useDeleteCustomReportTemplateMutation } from "../../graphql";
import { UpdateCustomReportTemplateFormValues } from "../_common/CustomReportTypes";
import { UpdateCustomReportDialog } from "./update-custom-report/UpdateCustomReportDialog";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  editIcon: {
    color: theme.palette.grey[400],
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(20),
  },
  deleteIcon: {
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.red[600],
  },
}));

type Props = {
  accountId: string;
  projectId: string;
  crawlId: string;
  segmentId?: string;
  templateDefaultValues: UpdateCustomReportTemplateFormValues;
  hasGeneratedReport: boolean;
  crawlIsArchived: boolean;
};

export const CustomReportTemplateActions = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation("customReports");
  const [openedModal, setOpenedModal] = useState<
    "deleteTemplate" | "updateTemplate" | undefined
  >();

  const [deleteCustomReportTemplate] = useDeleteCustomReportTemplateMutation({
    onCompleted: () => {
      const title = t("customReportTemplateDeleted");
      enqueueSnackbar(<Snackbar title={title} variant="success" />);
      closeDialogAndReset();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar title={error.message} variant="error" />);
    },
    refetchQueries: ["CustomReportTemplatesManager"],
  });

  const closeDialogAndReset = (): void => {
    setOpenedModal(undefined);
  };

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-testid="custom-report-template-actions"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="custom-report-template-actions-menu"
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (props.crawlIsArchived) {
              enqueueSnackbar(
                <Snackbar
                  title={t("customReportTemplateCrawlArchived")}
                  variant="error"
                />,
              );
            } else {
              setOpenedModal("updateTemplate");
            }
          }}
        >
          <EditOutlined className={classes.editIcon} />
          <Typography variant="body2">
            {t("editCustomReportTemplate")}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (props.crawlIsArchived) {
              enqueueSnackbar(
                <Snackbar
                  title={t("customReportTemplateCrawlArchived")}
                  variant="error"
                />,
              );
            } else {
              setOpenedModal("deleteTemplate");
            }
          }}
        >
          <TrashOutlined className={classes.deleteIcon} />
          <Typography variant="body2">
            {t("deleteCustomReportTemplate")}
          </Typography>
        </MenuItem>
      </Menu>
      <UpdateCustomReportDialog
        isOpen={openedModal === "updateTemplate"}
        onClose={() => {
          setOpenedModal(undefined);
        }}
        defaultValues={props.templateDefaultValues}
      />
      {openedModal === "deleteTemplate" && (
        <DestructiveMenuActionConfirmation
          title={t("deleteCustomReportTemplateTitle")}
          description={t("deleteCustomReportTemplateDescription")}
          cancelText={t("cancelButton")}
          confirmText={t("confirmButton")}
          anchorEl={anchorEl}
          onConfirm={async () => {
            await deleteCustomReportTemplate({
              variables: {
                input: {
                  customReportTemplateId:
                    props.templateDefaultValues.customReportTemplateId,
                },
              },
            });
            setAnchorEl(null);
          }}
          onCancel={() => closeDialogAndReset()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        />
      )}
    </>
  );
};
