import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTimeZones as getTimeZonesEntries, } from "@vvo/tzdb";
const timeZoneFallbackValue = {
    code: "UTC",
    name: "UTC",
    longName: "UTC",
    group: [],
    offset: 0,
};
export const TimeZoneContext = React.createContext({
    timeZones: [],
    timeZone: timeZoneFallbackValue,
    setTimeZone: () => {
        //
    },
});
export function TimeZoneProvider({ timeZoneCode, lastActiveTimeZone, onChange, onNewTimeZoneDetected, children, }) {
    var _a, _b, _c;
    const { i18n } = useTranslation();
    const timeZones = React.useMemo(() => getTimeZones(i18n.language), [i18n.language]);
    const detectedTimeZoneCode = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const detectedTimeZone = timeZones.find((x) => x.code === detectedTimeZoneCode);
    const selectedTimeZone = (_b = (_a = timeZones.find((x) => x.code === timeZoneCode)) !== null && _a !== void 0 ? _a : detectedTimeZone) !== null && _b !== void 0 ? _b : timeZoneFallbackValue;
    const shouldUpdateLastActiveTimeZone = detectedTimeZone !== undefined &&
        (lastActiveTimeZone === undefined ||
            lastActiveTimeZone !== detectedTimeZone.code);
    const shouldCallNewTimeZoneDetected = detectedTimeZone !== undefined &&
        lastActiveTimeZone !== undefined &&
        detectedTimeZone.code !== lastActiveTimeZone &&
        detectedTimeZone.code !== timeZoneCode;
    const resolvedLastActiveTimeZone = (_c = detectedTimeZone === null || detectedTimeZone === void 0 ? void 0 : detectedTimeZone.code) !== null && _c !== void 0 ? _c : lastActiveTimeZone;
    const setTimeZone = React.useCallback((selected) => {
        onChange({
            timeZone: selected,
            lastActiveTimeZone: resolvedLastActiveTimeZone,
        });
    }, [onChange, resolvedLastActiveTimeZone]);
    React.useEffect(() => {
        if (shouldUpdateLastActiveTimeZone) {
            onChange({
                timeZone: timeZoneCode,
                lastActiveTimeZone: detectedTimeZone.code,
            });
        }
        if (shouldCallNewTimeZoneDetected) {
            onNewTimeZoneDetected === null || onNewTimeZoneDetected === void 0 ? void 0 : onNewTimeZoneDetected(detectedTimeZone, setTimeZone);
        }
    }, [
        shouldUpdateLastActiveTimeZone,
        shouldCallNewTimeZoneDetected,
        timeZoneCode,
        detectedTimeZone,
        onChange,
        onNewTimeZoneDetected,
        setTimeZone,
    ]);
    return (_jsx(TimeZoneContext.Provider, { value: {
            timeZones,
            timeZone: selectedTimeZone,
            setTimeZone,
        }, children: children }));
}
function getTimeZones(language) {
    return getTimeZonesEntries().map((timezone) => {
        const abbreviation = `${timezone.abbreviation.replace(/[0-9+-]/g, "")}${formatOffset(timezone, language)}`;
        const city = timezone.mainCities[0];
        return {
            code: timezone.name,
            group: timezone.group,
            name: `(${abbreviation}) ${city}`,
            longName: `${abbreviation} (${city}, ${timezone.countryCode})`,
            offset: timezone.currentTimeOffsetInMinutes,
        };
    });
}
function formatOffset(timezone, language) {
    if (timezone.currentTimeOffsetInMinutes === 0)
        return "";
    return ` ${timezone.currentTimeOffsetInMinutes > 0 ? "+" : "-"}${new Intl.DateTimeFormat(language, {
        timeStyle: "short",
        hourCycle: "h24",
        timeZone: "GMT",
    }).format(Math.abs(timezone.currentTimeOffsetInMinutes * 60 * 1000))}`;
}
