import {
  AccessibilityCircle,
  FolderSolid,
  GlobeAltSolid,
  SiteSpeedV2Icon,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { AccountProjectsVariant } from "../_common/routing/routes";
import { useSearchParam } from "../_common/routing/useSearchParam";
import { SvgIcon } from "@material-ui/core";
import { ModuleCode } from "../graphql";

const AccountProjectsVatiants = Object.values(AccountProjectsVariant);

interface Result {
  active: AccountProjectsVariant;
  mainVariants: {
    variant: AccountProjectsVariant;
    name: string;
    icon: typeof SvgIcon;
    moduleCode?: ModuleCode;
  }[];
}

export function useAccountProjectsVariants(): Result {
  const { t } = useTranslation("projectsList");
  const type = useSearchParam("type");

  const { account, hasAddon } = useSession();

  const mainVariants: Result["mainVariants"] = [
    {
      variant: AccountProjectsVariant.All,
      name: t("allProjects"),
      icon: FolderSolid,
    },
    {
      variant: AccountProjectsVariant.SEO,
      name: t("seoProjects"),
      icon: GlobeAltSolid,
      moduleCode: ModuleCode.Seo,
    },
    {
      variant: AccountProjectsVariant.SiteSpeed,
      name: t("siteSpeedProjects"),
      icon: SiteSpeedV2Icon,
      moduleCode: ModuleCode.SiteSpeed,
    },
    {
      variant: AccountProjectsVariant.Accessibility,
      name: t("accessiblityProjects"),
      icon: AccessibilityCircle,
      moduleCode: ModuleCode.Accessibility,
    },
  ];

  const availableVariants = AccountProjectsVatiants.filter((variant) => {
    switch (variant) {
      case AccountProjectsVariant.TestSuite:
        return account.availableApps.automateAvailable;
      case AccountProjectsVariant.SEO:
        return hasAddon("seo");
      case AccountProjectsVariant.Accessibility:
        return hasAddon("accessibility");
      case AccountProjectsVariant.SiteSpeed:
        return hasAddon("site-speed");
      default:
        return true;
    }
  });

  const filteredMainVariants = mainVariants.filter((x) =>
    availableVariants.includes(x.variant),
  );

  const active =
    availableVariants.find((x) => x === type) ?? AccountProjectsVariant.All;

  const isInMain = Boolean(mainVariants.find((x) => x.variant === active));

  return {
    active,
    mainVariants: !isInMain
      ? []
      : filteredMainVariants.length == 2
        ? [mainVariants[0]]
        : filteredMainVariants,
  };
}
