import React from "react";

import { Typography } from "@lumar/shared";
import { NarrowAutocompleteProject } from "../../_common/project-autocomplete/types";
import {
  FilterOrRuleFormValue,
  FilterRuleFormValue,
  NarrowMetric,
} from "../../_common/connection-filtering/types";
import { SegmentFormInputs } from "./SegmentFormInputs";
import { ProjectDestinationSelect } from "../copy-segment-dialog/ProjectDestinationSelect";

import { Box, Divider } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { ModuleCode } from "../../graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    dialogActions: {
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "stretch",
    },
    segmentIndex: {
      fontWeight: theme.typography.fontWeightBold,
    },
  }),
);

export interface SegmentFormValues {
  projectDestination: NarrowAutocompleteProject | null;
  segments: {
    segmentName: string;
    group: string | null;
    rules: FilterOrRuleFormValue[];
  }[];
}

export interface SegmentFormProps {
  values: SegmentFormValues;
  touched: FormikProps<SegmentFormValues>["touched"];
  errors: FormikProps<SegmentFormValues>["errors"];
  validateForm: FormikProps<SegmentFormValues>["validateForm"];
  showProjectField?: boolean;
  metrics: NarrowMetric[];
  defaultFilter: FilterRuleFormValue;
  groups: string[];
  groupsLoading?: boolean;
  moduleCode?: ModuleCode;
}

export function SegmentForm({
  moduleCode,
  ...props
}: SegmentFormProps): JSX.Element {
  const classes = useStyles();

  const segmentsLength = props.values.segments.length;

  return (
    <Form data-testid="segment-form">
      {props.showProjectField && (
        <div>
          <ProjectDestinationSelect
            name="projectDestination"
            value={props.values.projectDestination}
            touched={props.touched.projectDestination}
            error={props.errors.projectDestination}
            moduleCode={moduleCode}
          />
        </div>
      )}
      <Box paddingBottom={2}>
        {props.values.segments.map((segment, segmentIndex) => {
          const isLastSegment = segmentIndex === segmentsLength - 1;
          return (
            <Box key={segmentIndex}>
              <Box display="flex">
                {segmentsLength > 1 && (
                  <Box paddingTop={3}>
                    <Typography className={classes.segmentIndex}>
                      {segmentIndex + 1}.
                    </Typography>
                  </Box>
                )}
                <Box>
                  <SegmentFormInputs
                    segmentNameFieldName={`segments.${segmentIndex}.segmentName`}
                    segmentGroupFieldName={`segments.${segmentIndex}.group`}
                    segmentRulesOrFieldArrayName={`segments.${segmentIndex}.rules`}
                    rulesValue={props.values.segments[segmentIndex].rules}
                    metrics={props.metrics}
                    defaultFilter={props.defaultFilter}
                    groups={props.groups}
                    groupsLoading={props.groupsLoading}
                  />
                </Box>
              </Box>
              {!isLastSegment && (
                <Box paddingTop={3}>
                  <Divider />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Form>
  );
}
