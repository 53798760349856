import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@material-ui/core";
import { Button } from "../buttons/Button";
/*
 * Some themes expect different button variant for chart buttons.
 * This component ensures the correct variant.
 */
export const ChartButton = function ChartButton(props) {
    var _a, _b, _c;
    const theme = useTheme();
    const variant = (_c = (_b = (_a = theme.props) === null || _a === void 0 ? void 0 : _a.ChartButton) === null || _b === void 0 ? void 0 : _b.variant) !== null && _c !== void 0 ? _c : "outlined";
    return _jsx(Button, Object.assign({ variant: variant }, props));
};
