import {
  CrawlSiteSpeedAuditAvgCalculation,
  CrawlSiteSpeedAuditCountCalculation,
  CrawlSiteSpeedAuditMaxCalculation,
  CrawlSiteSpeedAuditMinCalculation,
  CrawlSiteSpeedAuditOpportunityAvgCalculation,
  CrawlSiteSpeedAuditOpportunityCountCalculation,
  CrawlSiteSpeedAuditOpportunityMaxCalculation,
  CrawlSiteSpeedAuditOpportunityMinCalculation,
  CrawlSiteSpeedAuditOpportunitySumCalculation,
  CrawlSiteSpeedAuditSumCalculation,
  CrawlUrlAvgCalculation,
  CrawlUrlCountCalculation,
  CrawlUrlMaxCalculation,
  CrawlUrlMinCalculation,
  CrawlUrlSumCalculation,
} from "../../../../graphql";
import { CrawlContextCrawlReportUnion } from "../../../CrawlContext";

export type AggregateTypes = "avg" | "count" | "max" | "min" | "sum";

export type CustomDataAggregateMetricConfig = {
  crawlUrls?:
    | {
        aggregate: "avg";
        metricCode: keyof CrawlUrlAvgCalculation;
      }
    | {
        aggregate: "min";
        metricCode: keyof CrawlUrlMinCalculation;
      }
    | {
        aggregate: "max";
        metricCode: keyof CrawlUrlMaxCalculation;
      }
    | {
        aggregate: "sum";
        metricCode: keyof CrawlUrlSumCalculation;
      }
    | {
        aggregate: "count";
        metricCode: keyof CrawlUrlCountCalculation;
      };
  siteSpeedAudits?:
    | {
        aggregate: "avg";
        metricCode: keyof CrawlSiteSpeedAuditAvgCalculation;
      }
    | {
        aggregate: "min";
        metricCode: keyof CrawlSiteSpeedAuditMinCalculation;
      }
    | {
        aggregate: "max";
        metricCode: keyof CrawlSiteSpeedAuditMaxCalculation;
      }
    | {
        aggregate: "sum";
        metricCode: keyof CrawlSiteSpeedAuditSumCalculation;
      }
    | {
        aggregate: "count";
        metricCode: keyof CrawlSiteSpeedAuditCountCalculation;
      };
  siteSpeedAuditOpportunities?:
    | {
        aggregate: "avg";
        metricCode: keyof CrawlSiteSpeedAuditOpportunityAvgCalculation;
      }
    | {
        aggregate: "min";
        metricCode: keyof CrawlSiteSpeedAuditOpportunityMinCalculation;
      }
    | {
        aggregate: "max";
        metricCode: keyof CrawlSiteSpeedAuditOpportunityMaxCalculation;
      }
    | {
        aggregate: "sum";
        metricCode: keyof CrawlSiteSpeedAuditOpportunitySumCalculation;
      }
    | {
        aggregate: "count";
        metricCode: keyof CrawlSiteSpeedAuditOpportunityCountCalculation;
      };
};

export type CustomDataValueGetterConfig = (props: {
  report: CrawlContextCrawlReportUnion;
  reports: CrawlContextCrawlReportUnion[];
  maxTotalRows: number;
}) => number | null | undefined;

export type CustomDataConfig =
  | CustomDataAggregateMetricConfig
  | CustomDataValueGetterConfig;

export type ReportWithCustomValues = CrawlContextCrawlReportUnion & {
  customValues: Record<
    string,
    { value?: number | null; unit?: string } | undefined
  >;
};

export function isCustomDataAggregateMetricConfig(
  value?: CustomDataConfig,
): value is CustomDataAggregateMetricConfig {
  return Boolean(value) && typeof value !== "function";
}
