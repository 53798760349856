import React, { useRef } from "react";
import {
  Button,
  CogSolid,
  CrawlSourcesIcons,
  moduleIconConfig,
  PencilSolid,
  RefreshSolid,
  useSession,
  useTranslation,
} from "@lumar/shared";
import {
  ClickAwayListener,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ModuleCode, RoleCode, useProjectSummaryQuery } from "../../graphql";
import { Link, useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { Routes } from "../routing/routes";
import { isCrawlInProgress } from "../../account-projects/projects-filter/helpers";
import { assert } from "../assert";
import { CrawlContext } from "../../crawl-overview/CrawlContext";

export function ProjectSummary(): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation("projectSummary");
  const { projectId, accountId, crawlId } = useParams<{
    projectId?: string;
    accountId: string;
    crawlId: string;
  }>();

  assert(projectId);
  const history = useHistory();
  const {
    hasSufficientRole,
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();
  const hasEditorPermission = hasSufficientRole(RoleCode.Editor);

  const crawlContext = React.useContext(CrawlContext);

  const { data } = useProjectSummaryQuery({
    variables: { id: projectId, segmentationAvailable: segmentationAvailable },
    fetchPolicy: "cache-first",
  });

  const [isOpen, setIsOpen] = React.useState(false);

  const project = data?.project;
  const crawlStatus = project?.lastCrawlStatus;
  const isInProgress = isCrawlInProgress(crawlStatus);
  const isTestSuite = project?.isTestSuite;
  const segmentTotalCount = project?.segments?.totalCount;

  const popupItemRef = useRef<HTMLAnchorElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const sources = project?.crawlTypes.map((crawlType) => ({
    code: crawlType,
    name:
      data?.getCrawlTypesMetadata.find((x) => x.code === crawlType)?.name ?? "",
    active: true,
  }));

  const disabled = !project || crawlContext?.loading === true;

  const ModuleIcon = project
    ? moduleIconConfig.get(project.moduleCode)
    : undefined;
  const moduleName = (() => {
    switch (project?.moduleCode) {
      case ModuleCode.Basic:
        return t("projectTypeBasic");
      case ModuleCode.Seo:
        return t("projectTypeSEO");
      case ModuleCode.Accessibility:
        return t("projectTypeAccessibility");
      case ModuleCode.SiteSpeed:
        return t("projectTypeSiteSpeed");
    }
  })();

  const crawlSettings = crawlContext?.data?.crawlSetting;
  const isMobileUserAgent = Boolean(
    crawlSettings?.userAgentString
      ? crawlSettings?.userAgentIsMobile
      : crawlSettings?.userAgent?.isMobile,
  );

  const dialog = (
    <div>
      <Typography
        variant="body1"
        className={classes.projectName}
        data-testid="project-context-name"
      >
        {project?.name}
      </Typography>
      <Typography
        component="a"
        href={project?.primaryDomain ?? ""}
        target="_blank"
        variant="body2"
        className={classes.link}
        data-testid="project-context-primary-site"
        ref={popupItemRef}
      >
        {project?.primaryDomain}
      </Typography>
      <Divider className={classes.groupDivider} />
      <Grid container className={classes.grid}>
        {ModuleIcon !== undefined && moduleName !== undefined && (
          <Grid item xs={6}>
            <Typography variant="overline" className={classes.label}>
              {t("projectType")}
            </Typography>
            <div style={{ display: "flex" }}>
              <ModuleIcon className={classes.moduleIcon} />
              <Typography className={classes.valuesBold}>
                {moduleName}
              </Typography>
            </div>
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography variant="overline" className={classes.label}>
            {t("crawlTypes")}
          </Typography>
          <CrawlSourcesIcons sources={sources} hideInactive />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="overline" className={classes.label}>
            {t("maxUrlLimit")}
          </Typography>
          <div
            className={classes.valuesBold}
            data-testid="project-context-max-url-limit"
          >
            {project?.limitUrlsMax?.toLocaleString("en-US")}
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="overline" className={classes.label}>
            {t("maxClickLevels")}
          </Typography>
          <div
            className={classes.valuesBold}
            data-testid="project-context-max-click-levels"
          >
            {project?.limitLevelsMax}
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="overline" className={classes.label}>
            {t("crawls")}
          </Typography>
          <div className={classes.valuesBlue}>
            <Link
              to={Routes.Crawls.getUrl({
                accountId,
                projectId,
                tab: "history",
              })}
              data-testid="project-context-crawls"
              aria-label={t("crawlsLabel", {
                value: project?.crawls.totalCount,
              })}
            >
              {project?.crawls.totalCount}
            </Link>
          </div>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="overline" className={classes.label}>
            {t("segments")}
          </Typography>
          <div className={classes.valuesBlue}>
            {segmentTotalCount || segmentTotalCount === 0 ? (
              <Link
                to={Routes.SegmentManager.getUrl({
                  accountId,
                  projectId,
                  crawlId: crawlId ?? "0",
                })}
                data-testid="project-context-segment-count"
                aria-label={t("segmentsLabel", {
                  value: project?.segments?.totalCount,
                })}
              >
                {project?.segments?.totalCount}
              </Link>
            ) : (
              "-"
            )}
          </div>
        </Grid>

        {crawlContext !== null && (
          <Grid item xs={12}>
            <Typography variant="overline" className={classes.label}>
              {t("userAgentType")}
            </Typography>
            <Typography className={classes.valuesBold}>
              {isMobileUserAgent
                ? t("userAgentTypeMobile")
                : t("userAgentTypeDesktop")}
            </Typography>
          </Grid>
        )}
      </Grid>
      {hasEditorPermission ? (
        <div className={classes.actionContainer}>
          <Button
            variant="outlined"
            className={classes.editBtn}
            data-testid="project-context-edit"
            startIcon={<PencilSolid className={classes.pencilIcon} />}
            size="large"
            onClick={() => {
              history.push(
                Routes.Crawls.getUrl({
                  accountId,
                  projectId,
                  tab: "edit",
                  step: 1,
                }),
              );
              setIsOpen(false);
            }}
          >
            {t("edit")}
          </Button>
          {!isTestSuite ? (
            <>
              {!isInProgress ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.runCrawlBtn}
                  data-testid="project-context-run-crawl"
                  startIcon={<RefreshSolid className={classes.refreshIcon} />}
                  onClick={() => {
                    history.push(
                      Routes.Crawls.getUrl({
                        accountId,
                        projectId,
                        tab: "progress",
                        start: true,
                      }),
                    );
                    setIsOpen(false);
                  }}
                >
                  {t("runCrawl")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.currentCrawlBtn}
                  onClick={() => {
                    history.push(
                      Routes.Crawls.getUrl({
                        accountId,
                        projectId,
                        tab: "progress",
                      }),
                    );
                    setIsOpen(false);
                  }}
                >
                  {t("currentCrawl")}
                </Button>
              )}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <Tooltip
          title={dialog}
          interactive
          arrow={false}
          placement="bottom-end"
          classes={{ tooltip: classes.tooltip }}
          open={isOpen}
          TransitionProps={{
            onEnter: () => popupItemRef.current?.focus(),
          }}
          PopperProps={{
            onKeyDown: (e) => {
              if (e.key === "Escape") {
                setIsOpen(false);
                btnRef?.current?.focus();
              }
            },
          }}
        >
          <div>
            <Button
              ref={btnRef}
              focusRipple
              onClick={() => setIsOpen(!isOpen)}
              disabled={disabled}
              classes={{
                root: classes.button,
                disabled: classes.buttonDisabled,
              }}
              data-testid="project-context-toggle"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsOpen(true);
                }
              }}
              aria-label={t("buttonLabel")}
            >
              <CogSolid
                className={clsx(
                  classes.cogIcon,
                  isOpen ? classes.clickedCogIcon : "",
                )}
              />
            </Button>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginTop: theme.spacing(1.5),
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: "white",
    padding: theme.spacing(1.5, 2.25, 2, 2.25),
    borderColor: theme.palette.grey[200],
    borderWidth: "1px",
    borderStyle: "solid",
    boxShadow:
      "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    borderRadius: 6,
    maxWidth: 340,
    minWidth: 340,
    "& a": {
      color: theme.palette.primary.main,
    },
    "& $link": {
      color: theme.palette.blue[600],
    },
  },
  button: {
    minWidth: 0,
    padding: theme.spacing(1),
    borderWidth: "1.5px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    boxShadow: theme.shadows[1],
    borderRadius: "50%",
    background: "white",
    marginLeft: "auto",
    height: 36,
  },
  buttonDisabled: {
    background: theme.palette.grey[100],
    "& $cogIcon": {
      color: theme.palette.grey[400],
    },
  },
  cogIcon: {
    fontSize: 19,
    color: "#4A5568",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  clickedCogIcon: {
    color: theme.palette.primary.main,
  },
  projectName: {
    color: theme.palette.grey[800],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(0.5),
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  link: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
  },
  groupDivider: {
    marginTop: theme.spacing(1),
  },
  refreshIcon: { color: "white", fontSize: theme.typography.pxToRem(12) },
  pencilIcon: {
    fontSize: theme.typography.pxToRem(12),
  },
  label: {
    color: theme.palette.grey[700],
    lineHeight: theme.typography.pxToRem(15),
  },
  valuesBlue: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: "none",
  },
  valuesBold: {
    color: theme.palette.grey[800],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
  },
  editBtn: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3.25),
    paddingRight: theme.spacing(3.25),
  },
  runCrawlBtn: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4.3),
    paddingRight: theme.spacing(4.3),
  },
  currentCrawlBtn: {
    flexGrow: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  grid: {
    rowGap: 15,
    padding: theme.spacing(2, 0),
  },
  moduleIcon: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5),
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    marginTop: 10,
    marginBottom: 6,
    justifyContent: "space-between",
  },
}));
