import { Typography } from "@lumar/shared";
import { Grid, makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { LabelInfo } from "../../../../_common/components/LabelInfo";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { SettingControlFactory } from "../useAdvancedControls";

export const getLinkRestrictionsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.linkRestrictions.title"),
  path: "linkRestritions",
  // eslint-disable-next-line react/display-name
  control: () => <LinkRestrictions />,
  testId: "crawl-settings-advanced-link-restrictions",
  textForSearch: createTextForSearch([
    t("settings.linkRestrictions.title"),
    t("settings.linkRestrictions.description"),
    t("settings.linkRestrictions.crawlFollowAnchorLinks"),
    t("settings.linkRestrictions.followLinksLabelInfo"),
    t("settings.linkRestrictions.crawlNoFollowAnchorLinks"),
    t("settings.linkRestrictions.noFollowLinksLabelInfo"),
    t("settings.linkRestrictions.crawlCanonicalLinks"),
    t("settings.linkRestrictions.canonicalLinksLabelInfo"),
    t("settings.linkRestrictions.crawlPaginationLinks"),
    t("settings.linkRestrictions.paginationlinksLabelInfo"),
    t("settings.linkRestrictions.crawlMobileAlternateLinks"),
    t("settings.linkRestrictions.mobileAltLinksLabelInfo"),
    t("settings.linkRestrictions.crawlAmphtmlLinks"),
    t("settings.linkRestrictions.ampHtmlLinksLabelInfo"),
    t("settings.linkRestrictions.crawlHreflangLinks"),
    t("settings.linkRestrictions.hreflangLabelInfo"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const LinkRestrictions = React.memo(LinkRestrictionsInner);

function LinkRestrictionsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        {t("settings.linkRestrictions.description")}
      </Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={12} md={6} className={classes.column}>
          <FastField
            name="scope.linkRestrictions.crawlFollowAnchorLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkRestrictions.crawlFollowAnchorLinks")}
                  info={t("settings.linkRestrictions.followLinksLabelInfo")}
                />
              ),
            }}
            data-testid="follow-anchor-links"
          />

          <FastField
            name="scope.linkRestrictions.crawlNoFollowAnchorLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t(
                    "settings.linkRestrictions.crawlNoFollowAnchorLinks",
                  )}
                  info={t("settings.linkRestrictions.noFollowLinksLabelInfo")}
                />
              ),
            }}
            data-testid="no-follow-links"
          />
          <FastField
            name="scope.linkRestrictions.crawlCanonicalLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkRestrictions.crawlCanonicalLinks")}
                  info={t("settings.linkRestrictions.canonicalLinksLabelInfo")}
                />
              ),
            }}
            data-testid="canonical-links"
          />
          <FastField
            name="scope.linkRestrictions.crawlPaginationLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkRestrictions.crawlPaginationLinks")}
                  info={t("settings.linkRestrictions.paginationlinksLabelInfo")}
                />
              ),
            }}
            data-testid="pagination-links"
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.column}>
          <FastField
            name="scope.linkRestrictions.crawlMobileAlternateLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t(
                    "settings.linkRestrictions.crawlMobileAlternateLinks",
                  )}
                  info={t("settings.linkRestrictions.mobileAltLinksLabelInfo")}
                />
              ),
            }}
            data-testid="mobile-alternate-links"
          />
          <FastField
            name="scope.linkRestrictions.crawlAmphtmlLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkRestrictions.crawlAmphtmlLinks")}
                  info={t("settings.linkRestrictions.ampHtmlLinksLabelInfo")}
                />
              ),
            }}
            data-testid="amphtml-links"
          />
          <FastField
            name="scope.linkRestrictions.crawlHreflangLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkRestrictions.crawlHreflangLinks")}
                  info={t("settings.linkRestrictions.hreflangLabelInfo")}
                />
              ),
            }}
            data-testid="hreflang-links"
          />
        </Grid>
      </Grid>
    </>
  );
}
