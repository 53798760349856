export function assert(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  condition: any,
  errorMessage = "",
): asserts condition {
  if (!condition) {
    throw new Error(
      errorMessage ? `Assertion Error: ${errorMessage}` : "Assertion Error",
    );
  }
}
