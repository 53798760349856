var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button, makeStyles, Tooltip, useTheme, } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => {
    var _a, _b;
    return ({
        root: Object.assign({ minWidth: "auto", padding: theme.spacing(0.75, 1.25), marginLeft: theme.spacing(1), color: theme.palette.grey[700] }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.ChartIconButton) === null || _b === void 0 ? void 0 : _b.root),
    });
});
export function ChartIconButton(props) {
    var _a, _b;
    const theme = useTheme();
    const classes = useStyles();
    const { size = "small", variant = ((_b = (_a = theme.props) === null || _a === void 0 ? void 0 : _a.ChartIconButton) === null || _b === void 0 ? void 0 : _b.variant) || "outlined", classes: providedClasses } = props, rest = __rest(props, ["size", "variant", "classes"]);
    return (_jsx(Tooltip, { title: props.tooltip, children: _jsx("span", { children: _jsx(Button, Object.assign({ size: size, variant: variant, classes: Object.assign({ root: clsx(classes.root, providedClasses === null || providedClasses === void 0 ? void 0 : providedClasses.root) }, providedClasses) }, rest, { children: _jsx(props.icon, { fontSize: "small" }) })) }) }));
}
