import {
  ApolloCache,
  ApolloError,
  ExcludeEmpty,
  Snackbar,
  useTranslation,
} from "@lumar/shared";
import {
  AccountTasksDocument,
  AccountTasksQuery,
  GetTaskForEditQuery,
  LegacyTaskPriority,
  LegacyTaskStatus,
  useDeleteTaskMutation,
  useGetTaskForEditQuery,
  useUpdateTaskMutation,
} from "../../graphql";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { getTaskResolvedData, TaskResolvedData } from "./helpers";

export interface FormValues {
  title: string;
  description: string | undefined;
  howToFix: string | undefined;
  assignedTo: string[];
  priority: LegacyTaskPriority;
  status: LegacyTaskStatus;
  deadline: Date | null;
}

export interface CreateTaskFormValuesProps {
  taskId: string;
  accountSearch?: string;
  onStatusUpdated?: (status: LegacyTaskStatus) => void;
}

export interface CreateTaskFormValuesResult {
  loading: boolean;
  error?: string;
  values: FormValues;
  task?: ExcludeEmpty<GetTaskForEditQuery["getLegacyTask"]> & TaskResolvedData;
  updateTask: (values: FormValues) => Promise<boolean>;
  deleteTask: () => Promise<boolean>;
  archiveTask: () => Promise<boolean>;
}

export function useEditTaskFormValues({
  taskId,
  accountSearch,
  onStatusUpdated,
}: CreateTaskFormValuesProps): CreateTaskFormValuesResult {
  const { t } = useTranslation("taskManager");
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useParams<{ accountId: string }>();

  const { loading, error, data } = useGetTaskForEditQuery({
    variables: {
      taskId,
    },
    fetchPolicy: "cache-first",
  });

  const [updateTask] = useUpdateTaskMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("editDialog.saveError", { message: error.message })}
        />,
      ),
  });

  const [deleteTask] = useDeleteTaskMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("actionsMenu.removeError", { message: error.message })}
        />,
      ),
  });

  const initialValues = getValues(data);

  return {
    loading,
    error: getErrorMessage(error),
    values: initialValues,
    task: data?.getLegacyTask
      ? {
          ...data.getLegacyTask,
          ...getTaskResolvedData(data.getLegacyTask),
        }
      : undefined,
    updateTask: async (values) => {
      const statusUpdated = initialValues.status !== values.status;

      const result = await updateTask({
        variables: {
          input: {
            legacyTaskId: taskId,
            title: values.title,
            description: values.description,
            howToFix: values.howToFix,
            assignedTo: values.assignedTo,
            priority: values.priority,
            status: values.status,
            deadlineAt: values.deadline ? values.deadline.toISOString() : null,
            ...(statusUpdated ? { position: 1 } : {}),
          },
        },
      });

      if (statusUpdated && result.data) {
        onStatusUpdated?.(values.status);
      }

      return Boolean(result.data);
    },
    deleteTask: async () => {
      const result = await deleteTask({
        variables: {
          taskId,
        },
        refetchQueries: [
          "ProjectTasks",
          "ProjectArchivedTasks",
          "UnresolvedProjectTasksCountForChip",
        ],
        awaitRefetchQueries: true,
        update: (cache) =>
          removeTaskFromAccountTasks(cache, accountId, accountSearch, taskId),
      });

      return Boolean(result.data);
    },
    archiveTask: async () => {
      const result = await updateTask({
        variables: {
          input: {
            legacyTaskId: taskId,
            fixedAt: new Date().toISOString(),
            status: LegacyTaskStatus.Done,
          },
        },
        refetchQueries: ["ProjectTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
        update: (cache) =>
          removeTaskFromAccountTasks(cache, accountId, accountSearch, taskId),
      });

      return Boolean(result.data);
    },
  };
}

function getValues(data?: GetTaskForEditQuery): FormValues {
  const task = data?.getLegacyTask ?? undefined;

  return {
    title: task?.title ?? "",
    description: task?.description ?? undefined,
    howToFix: task?.howToFix ?? "",
    assignedTo: task?.assignedTo ?? [],
    priority: task?.priority ?? LegacyTaskPriority.Note,
    status: task?.status ?? LegacyTaskStatus.Backlog,
    deadline: task?.deadlineAt ? new Date(task.deadlineAt) : null,
  };
}

function getErrorMessage(error?: ApolloError): string | undefined {
  if (error?.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error?.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error?.protocolErrors?.[0]) return error.protocolErrors[0].message;
  return error?.message;
}

function removeTaskFromAccountTasks(
  cache: ApolloCache<unknown>,
  accountId: string,
  search: string | undefined,
  taskId: string,
): void {
  const cachedData: AccountTasksQuery | null = cache.readQuery({
    query: AccountTasksDocument,
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
  });

  if (!cachedData?.getAccount) return;

  const taskFound = Boolean(
    cachedData.getAccount.legacyTasks.edges.find((x) => x.node.id === taskId),
  );

  cache.writeQuery({
    query: AccountTasksDocument,
    variables: {
      accountId,
      filter: {
        fixedAt: { isNull: true },
        ...(search ? { title: { contains: search } } : {}),
      },
    },
    data: {
      ...cachedData,
      getAccount: cachedData && {
        ...cachedData.getAccount,
        legacyTasks: cachedData.getAccount.legacyTasks && {
          ...cachedData.getAccount.legacyTasks,
          edges:
            cachedData.getAccount.legacyTasks.edges &&
            cachedData.getAccount.legacyTasks.edges.filter(
              (x) => x.node.id !== taskId,
            ),
        },
        allTasks: cachedData.getAccount.allTasks && {
          ...cachedData.getAccount.allTasks,
          totalCount:
            cachedData.getAccount.allTasks.totalCount - (taskFound ? 1 : 0),
        },
      },
    },
  });
}
