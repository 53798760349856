import { ReportCategoryChartConfig } from "../types";
import { getContentDuplicationChart } from "../../../../_common/charts/config/getContentDuplicationChart";
import { getContentDuplicationTrend } from "../../../../_common/charts/config/getContentDuplicationTrend";
import { getDuplicateSetsChart } from "../../../../_common/charts/config/getDuplicateSetsChart";
import { getNonPrimaryBreakdown } from "../../../../_common/charts/config/getNonPrimaryBreakdown";
import { getNonPrimaryBreakdownTrend } from "../../../../_common/charts/config/getNonPrimaryBreakdownTrend";
import { getUniquenessChart } from "../../../../_common/charts/config/getUniquenessChart";
import { getUniquenessTrend } from "../../../../_common/charts/config/getUniquenessTrend";

export const generateUniquenessCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getUniquenessChart,
      getUniquenessTrend,
      getNonPrimaryBreakdown,
      getNonPrimaryBreakdownTrend,
      getContentDuplicationChart,
      getContentDuplicationTrend,
      getDuplicateSetsChart,
    ],
  };
