import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Metrics } from "../data/types";
import { MetricsValuePresenter } from "../metrics-value-presenter/MetricsValuePresenter";
import clsx from "clsx";

export type MetricsGroupsConfig = {
  name: React.ReactNode;
  items?: {
    code: string;
    name?: React.ReactChild;
    value?: React.ReactChild;
  }[];
  children?: React.ReactNode;
}[];

export function MetricsGroups({
  metrics,
  config,
  className,
}: {
  metrics?: Metrics;
  config: MetricsGroupsConfig;
  className?: string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} data-testid="metrics-list">
      {config.map(({ name, items, children }, idx) => (
        <React.Fragment key={idx}>
          <Typography className={classes.category}>{name}</Typography>
          <div className={classes.childCell}>{children}</div>
          {items?.map(({ code, name, value }) => (
            <React.Fragment key={code}>
              <Typography
                variant="body2"
                className={classes.metricName}
                data-metric={code}
              >
                {name || metrics?.[code]?.name || code}
              </Typography>
              <MetricsValuePresenter
                metrics={metrics || {}}
                code={code}
                value={value}
                className={classes.metricValue}
                componentProps={{
                  showCopy: true,
                }}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(1.25, 0, 3, 2.25),
    marginBottom: theme.spacing(3),
    display: "grid",
    columnGap: theme.spacing(2.375),
    gridTemplateColumns: "minmax(30%, auto) minmax(100px, 1fr)",
  },
  category: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 600,
    gridColumn: "1 / -1",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(1.25, 0.25, 1.25, 0.25),
    marginBottom: theme.spacing(2),
  },
  metricName: {
    padding: theme.spacing(1, 0, 1, 0.75),
    color: theme.palette.grey[700],
    lineHeight: theme.typography.pxToRem(17),
  },
  metricValue: {
    padding: theme.spacing(1, 0.75, 1, 0),
    overflow: "hidden",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },
  childCell: {
    gridColumn: "1 / -1",
    paddingRight: theme.spacing(2.25),
  },
}));
