import { TFunction } from "i18next";
import {
  DatasourceCode,
  ReportDownloadOutputType,
} from "../../../../../graphql";

export interface GenerationOption {
  type: ReportDownloadOutputType;
  label: string;
  regenerateLabel: string;
  id: string;
  fromSelection: boolean;
  limitedToDatasources?: DatasourceCode[];
}

export type GenerationOptionFactory = (
  t: TFunction<"report">,
) => GenerationOption[];

export const generationOptions: GenerationOptionFactory = (
  t: TFunction<"report">,
) => [
  {
    type: ReportDownloadOutputType.CsvZip,
    label: t("generateCsv"),
    regenerateLabel: t("regenerateCsv"),
    id: "selectedColumnsCSV",
    fromSelection: true, // can send fields
  },
  {
    type: ReportDownloadOutputType.XmlZip,
    label: t("generateXml"),
    regenerateLabel: t("regenerateXml"),
    id: "xmlSitemaps",
    limitedToDatasources: [DatasourceCode.CrawlUrls], // only this data source
    fromSelection: false,
  },
];
