/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getIndexabilityChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("indexability.title"),
    description: t("indexability.description"),
    reportTemplateCodes: [
      "non_indexable_pages",
      "indexable_pages",
      "mobile_alternates",
    ],
  })();
}
