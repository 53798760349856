import { DocumentNode } from "graphql";
import {
  CustomReportColumnsMetadataDocument,
  CustomReportColumnsMetadataQueryVariables,
  ReportStatColumnsMetadataDocument,
  ReportStatColumnsMetadataQueryVariables,
} from "../../../../graphql";
import { ReportEntity, ReportInput } from "../../../Report.types";

export const getReportGridColumnsMetadataQuery = (args: {
  reportInput: ReportInput;
}): {
  document: DocumentNode;
  variables:
    | ReportStatColumnsMetadataQueryVariables
    | CustomReportColumnsMetadataQueryVariables;
} => {
  const { reportInput } = args;

  switch (reportInput.reportEntity) {
    case ReportEntity.ReportStat:
      return {
        document: ReportStatColumnsMetadataDocument,
        variables: {
          input: {
            crawlId: reportInput.crawlId,
            reportTemplateCode: reportInput.reportTemplateCode,
            segmentId: reportInput.segmentId,
          },
          crawlId: reportInput.crawlId,
        },
      };
    case ReportEntity.CustomReport:
      return {
        document: CustomReportColumnsMetadataDocument,
        variables: {
          input: {
            crawlId: reportInput.crawlId,
            customReportTemplateId: reportInput.customReportTemplateId,
            segmentId: reportInput.segmentId,
          },
          crawlId: reportInput.crawlId,
        },
      };
    default:
      throw new Error("Unimplemented report entity query");
  }
};
