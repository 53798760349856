import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgCruxITNPTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    icon: "crux",
    unitDescription: t("unitDescription.speed"),
    title: () => t("siteSpeedCruxITNPMetricTrend.tile_title"),
    description: () => t("siteSpeedCruxITNPMetricTrend.description"),
    descriptionTitle: () =>
      t("siteSpeedCruxITNPMetricTrend.tile_descriptionTitle"),
    noDataMessage: t("noCruxData"),
    inputSource: "GqlQuery",
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId: crawlContext.selectedCrawlSegment?.segment?.rawID,
            reportTemplateCode: "crux_domain_summary:crux_itnp_weighted_avg",
          },
        ],
      };
    },
  };
}
