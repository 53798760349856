import React, { useCallback } from "react";
import { useSnackbar } from "notistack";
import {
  ApolloError,
  getRawCrawlId,
  useTranslation,
  Snackbar,
} from "@lumar/shared";
import { useParams } from "react-router-dom";

import {
  CrawlSelectorQuery,
  useCrawlSelectorCurrentCrawlQuery,
  useCrawlSelectorLazyQuery,
} from "../graphql";

export interface CrawlSelectorDataResult {
  runningCrawl: RunningCrawl | undefined;
  lastFinishedCrawl: Crawl | undefined;
  crawls: Crawl[];
  loading: boolean;
}

export interface Crawl {
  id: string;
  createdAt: Date;
}

export interface RunningCrawl {
  id: string;
}

export function useCrawlSelectorData(): CrawlSelectorDataResult {
  const { t } = useTranslation("crawlSelector");
  const { enqueueSnackbar } = useSnackbar();

  const { projectId, crawlId } = useParams<{
    projectId: string;
    crawlId: string;
  }>();

  const showErrorSnackbar = useCallback(
    (error: ApolloError) =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("queryError")}>
          {error.message}
        </Snackbar>,
      ),
    [enqueueSnackbar, t],
  );

  const [getCrawls, { data, loading: crawlListLoading, error }] =
    useCrawlSelectorLazyQuery({
      fetchPolicy: "cache-first",
      onError: showErrorSnackbar,
    });

  const { loading: currentCrawlLoading, error: currentCrawlError } =
    useCrawlSelectorCurrentCrawlQuery({
      variables: { crawlId },
      fetchPolicy: "cache-first",
      onError: showErrorSnackbar,
      onCompleted: (data) => {
        const currentCrawlCreatedAt = data?.getCrawl?.createdAt;
        if (currentCrawlCreatedAt) {
          getCrawls({
            variables: { projectId, crawlCreatedAt: currentCrawlCreatedAt },
          });
        }
      },
    });

  const loading =
    currentCrawlLoading ||
    crawlListLoading ||
    (data === undefined &&
      error === undefined &&
      currentCrawlError === undefined);

  return {
    runningCrawl: getRunningCrawl(data),
    lastFinishedCrawl: getLastFinishedCrawl(data),
    crawls: [...getPreviousCrawls(data), ...getFollowingCrawls(data)],
    loading,
  };
}

function getRunningCrawl(
  data: CrawlSelectorQuery | undefined,
): RunningCrawl | undefined {
  const crawl = data?.getProject?.runningCrawl.nodes[0];
  if (!crawl) return;

  return {
    id: getRawCrawlId(crawl.id),
  };
}

function getLastFinishedCrawl(
  data: CrawlSelectorQuery | undefined,
): Crawl | undefined {
  const crawl = data?.getProject?.lastFinishedCrawl;
  if (!crawl) return;

  return {
    id: getRawCrawlId(crawl.id),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createdAt: new Date(crawl.createdAt!),
  };
}

function getPreviousCrawls(data: CrawlSelectorQuery | undefined): Crawl[] {
  const crawlsData = data?.getProject?.previousCrawls.nodes;
  if (!crawlsData) return [];

  // eslint-disable-next-line fp/no-mutating-methods
  const crawls = [...crawlsData].reverse();

  return crawls.map((crawl) => {
    return {
      id: getRawCrawlId(crawl.id),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createdAt: new Date(crawl.createdAt!),
    };
  });
}

function getFollowingCrawls(data: CrawlSelectorQuery | undefined): Crawl[] {
  const crawls = data?.getProject?.crawls.nodes;
  if (!crawls) return [];

  return crawls.map((crawl) => {
    return {
      id: getRawCrawlId(crawl.id),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createdAt: new Date(crawl.createdAt!),
    };
  });
}
