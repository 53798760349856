import { useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  ModuleCode,
  useCreateAccessibilityDomainMutation,
  useCreateBasicDomainMutation,
  useCreateDomainMutation,
  useCreateSiteSpeedDomainMutation,
  useUpdateAccessibilityDomainContainerProjectMutation,
  useUpdateDomainMutation,
} from "../../../../graphql";
import { FormValues } from "./types";
import { FormValuesResult } from "./useFormValues";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";
import {
  formatCookiesSettingsTo,
  formatUserAgentSettingsTo,
} from "../../settings/data/formatters/formattes";

export function useDomainMutation({
  moduleCode,
  initialValues,
  accessibilityContainerId,
}: {
  moduleCode: ModuleCode;
  initialValues: FormValues;
  accessibilityContainerId?: string;
}): FormValuesResult["createOrUpdate"] {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();

  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  const isInEditMode = projectId !== undefined;

  const [updateDomain] = useUpdateDomainMutation({
    refetchQueries: ["RunningCrawl", "GetTopNavigationData", "ProjectSummary"],
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.updateFailed")} error={error} />,
      ),
  });

  const [createSEODomain] = useCreateDomainMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.createFailed")} error={error} />,
      ),
  });

  const [createBasicDomain] = useCreateBasicDomainMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.createFailed")} error={error} />,
      ),
  });

  const [createAccessibilityDomain] = useCreateAccessibilityDomainMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.createFailed")} error={error} />,
      ),
  });

  const [updateAccessibilityDomainContainerProject] =
    useUpdateAccessibilityDomainContainerProjectMutation({
      onError: (error) =>
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("domain.accessibilityFailed")}
            error={error}
          />,
        ),
    });

  const [createSiteSpeedDomain] = useCreateSiteSpeedDomainMutation({
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.createFailed")} error={error} />,
      ),
  });

  return async (values) => {
    if (isInEditMode) {
      const accessibilityScriptChanged =
        values.customScripts.accessibility !==
        initialValues.customScripts.accessibility;

      const shouldUpdateAccessibility =
        moduleCode === ModuleCode.Accessibility &&
        Boolean(accessibilityContainerId) &&
        accessibilityScriptChanged;

      const response = await updateDomain({
        variables: {
          input: {
            projectId,
            ...getBaseValues(values),
            ...(moduleCode === ModuleCode.Seo ? getSEOValues(values) : {}),
            ...(moduleCode === ModuleCode.Accessibility
              ? getAccessibilityValues(values)
              : {}),
            ...(moduleCode === ModuleCode.SiteSpeed
              ? getSiteSpeedValues(values)
              : {}),
          },
          updateAccessibility: shouldUpdateAccessibility,
          updateAccessibilityInput: {
            projectId,
            customMetricContainerId: accessibilityContainerId || "0",
            customJsScripts: [values.customScripts.accessibility],
          },
        },
      });

      return response.data?.updateProject.project.rawID;
    }

    if (moduleCode === ModuleCode.Basic) {
      const response = await createBasicDomain({
        variables: {
          input: {
            accountId,
            ...getBaseValues(values),
          },
        },
      });
      return response.data?.createBasicProject.project.rawID;
    }

    if (moduleCode === ModuleCode.Seo) {
      const response = await createSEODomain({
        variables: {
          input: {
            accountId,
            ...getBaseValues(values),
            ...getSEOValues(values),
          },
        },
      });

      return response.data?.createSEOProject.project.rawID;
    }

    if (moduleCode === ModuleCode.Accessibility) {
      const response = await createAccessibilityDomain({
        variables: {
          input: {
            accountId,
            ...getBaseValues(values),
            ...getAccessibilityValues(values),
          },
        },
      });

      await updateAccessibilityDomainContainerProject({
        variables: {
          updateLinkInput: {
            projectId: response.data?.createAccessibilityProject.project.rawID,
            customMetricContainerId: accessibilityContainerId || "0",
            customJsScripts: [values.customScripts.accessibility],
          },
        },
      });

      return response.data?.createAccessibilityProject.project.rawID;
    }

    if (moduleCode === ModuleCode.SiteSpeed) {
      const response = await createSiteSpeedDomain({
        variables: {
          input: {
            accountId,
            ...getBaseValues(values),
            ...getSiteSpeedValues(values),
          },
        },
      });

      return response.data?.createSiteSpeedProject.project.rawID;
    }
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getBaseValues(values: FormValues) {
  function getDomain(url: string): string {
    const domain = (() => {
      try {
        return new URL(url).origin;
      } catch {
        return url;
      }
    })();

    return domain.endsWith("/") ? domain : domain + "/";
  }

  return {
    primaryDomain: getDomain(values.basic.primaryDomain),
    name: values.basic.name,
    includeSubdomains: values.basic.crawlSubDomains,
    includeHttpAndHttps: values.basic.crawlHttpAndHttps,
    industryCode: values.basic.industry ? values.basic.industry : null,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getSEOValues(values: FormValues) {
  return {
    useRenderer: values.basic.useRenderer,
    ...formatUserAgentSettingsTo(values.userAgent, true),
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getAccessibilityValues(values: FormValues) {
  return {
    selectedWcagLevel: values.wcagLevelAndVersion.wcagLevel,
    selectedWcagVersion: values.wcagLevelAndVersion.wcagVersion,
    includeBestPractices: values.wcagLevelAndVersion.includeBestPractices,
    ...formatUserAgentSettingsTo(values.userAgent),
    ...formatCookiesSettingsTo(values.cookies),
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getSiteSpeedValues(values: FormValues) {
  return {
    useRenderer: values.basic.useRenderer,
    ...formatUserAgentSettingsTo(values.userAgent, true),
  };
}
