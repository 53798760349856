import { useContext } from "react";
import { useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";

import { translationNamespace } from "../../CrawlSettings";
import { SettingsContext } from "../data/useContextValues";
import {
  ScheduleFrequencyCode,
  useCreateScheduleForSettingsMutation,
  useDeleteScheduleForSettingsMutation,
  useUpdateScheduleForSettingsMutation,
} from "../../../../graphql";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";

export interface ScheduleMutations {
  inProgress: boolean;
  createOrUpdateSchedule: (
    frequency: ScheduleFrequencyCode,
    date: Date,
    id?: string,
  ) => Promise<string>;
  deleteSchedule: (id: string) => Promise<string>;
}

export function useScheduleMutations(): ScheduleMutations {
  const { t } = useTranslation(translationNamespace);
  const { enqueueSnackbar } = useSnackbar();

  const contextValues = useContext(SettingsContext);

  const [createScheduleMutation, { loading: creating }] =
    useCreateScheduleForSettingsMutation({
      onError: (error) =>
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("message.apiErrorTitleScheduleSave")}
            error={error}
          />,
        ),
    });
  const [updateScheduleMutation, { loading: updating }] =
    useUpdateScheduleForSettingsMutation({
      onError: (error) =>
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("message.apiErrorTitleScheduleSave")}
            error={error}
          />,
        ),
    });
  const [removeScheduleMutation, { loading: removing }] =
    useDeleteScheduleForSettingsMutation({
      onError: (error) =>
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("message.apiErrorTitleScheduleDelete")}
            error={error}
          />,
        ),
    });

  return {
    inProgress: creating || updating || removing,
    createOrUpdateSchedule: async (frequency, date, id) => {
      if (!id) {
        const result = await createScheduleMutation({
          variables: {
            projectId: contextValues.projectId,
            scheduleFrequency: frequency,
            nextRunTime: date.toISOString(),
          },
        });
        return result?.data?.createSchedule?.schedule?.id;
      } else {
        const result = await updateScheduleMutation({
          variables: {
            scheduleId: id,
            scheduleFrequency: frequency,
            nextRunTime: date.toISOString(),
          },
        });
        return result?.data?.updateSchedule?.schedule?.id;
      }
    },
    deleteSchedule: async (id) => {
      const result = await removeScheduleMutation({
        variables: {
          scheduleId: id,
        },
      });
      return result.data?.deleteSchedule.schedule.id;
    },
  };
}
