/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType } from "../../../graphql";

export function getEngagementBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.GoogleAnalytics],
      incompleteMessage: t("requiredSourcesMessage.analyticsSource"),
    },
    title: () => t("engagementChart.title"),
    description: () => t("engagementChart.description"),
    reportTemplateCodesOrder: [
      "good_engagement",
      "moderate_engagement",
      "poor_engagement",
      "bad_engagement",
    ],
    reportStatFilter: (report) => {
      return [
        "good_engagement",
        "moderate_engagement",
        "poor_engagement",
        "bad_engagement",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
