import React from "react";
import { ApolloError } from "@lumar/shared";
import { useGetProjectSegmentGroupsQuery } from "../../../graphql";
import { isString } from "lodash";

interface Props {
  projectId: string | undefined;
}

interface Result {
  groups: string[];
  loading: boolean;
  error?: ApolloError;
}

export function useProjectSegmentGroups({ projectId }: Props): Result {
  const { loading, error, data, fetchMore } = useGetProjectSegmentGroupsQuery({
    variables: { projectId: projectId || "" },
    fetchPolicy: "cache-first",
    skip: !projectId,
  });

  React.useEffect(() => {
    if (data?.getProject?.segments?.pageInfo.hasNextPage) {
      fetchMore({
        variables: { cursor: data.getProject.segments.pageInfo.endCursor },
      });
    }
  }, [data, fetchMore]);

  return {
    loading:
      loading ||
      Boolean(data?.getProject?.segments?.pageInfo.hasNextPage && !error),
    error,
    groups:
      data?.getProject?.segments?.edges
        .map((x) => x.node.group)
        .filter(isString) || [],
  };
}
