/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getBacklinksBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.Backlinks],
      incompleteMessage: t("requiredSourcesMessage.backlinksSource"),
    },
    title: () => t("backlinksBreakdown.title"),
    description: () => t("backlinksBreakdown.description"),
    reportTemplateCodesOrder: [
      "all_cralwed_urls_with_backlinks",
      "missing_in_backlinks_crawl",
    ],
    reportStatFilter: (report) => {
      return [
        "all_cralwed_urls_with_backlinks",
        "missing_in_backlinks_crawl",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
