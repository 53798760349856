import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Tooltip,
  TooltipProps,
} from "@material-ui/core";
import clsx from "clsx";
import { CustomSkeleton } from "../CustomSkeleton";
import { Typography, useTranslation } from "@lumar/shared";
import { getCapitalisedString } from "../string-manipulation/getCapitalisedString";

const useStyles = makeStyles((theme) =>
  createStyles({
    capitalize: {
      textTransform: "capitalize",
    },
    title: {
      color: theme.palette.grey[900],
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      minHeight: 34,
      display: "block",
      lineHeight: theme.typography.pxToRem(34),
    },
    skeleton: {
      transform: "none",
      width: "40%",
      height: theme.typography.pxToRem(34),
      background: theme.palette.grey[200],
    },
    titleContainer: {
      display: "flex",
    },
  }),
);

export interface PageTitleProps {
  title?: string;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  projectName?: string;
  capitalize?: boolean;
  loading?: boolean;
}

export function PageTitle({
  title = "",
  leftComponent,
  rightComponent,
  projectName,
  loading,
  capitalize,
}: PageTitleProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("navigation");

  const defaultTitle = t("title");

  useEffect(() => {
    const titles = [
      capitalize ? getCapitalisedString(title) : title,
      projectName && !title.includes(projectName) ? projectName : undefined,
      defaultTitle,
    ];
    // eslint-disable-next-line fp/no-mutation
    document.title = titles.filter((x) => x?.length).join(" | ");
    return () => {
      // eslint-disable-next-line fp/no-mutation
      document.title = defaultTitle;
    };
  }, [title, capitalize, projectName, defaultTitle]);

  if (loading)
    return (
      <CustomSkeleton
        data-testid="top-nav-page-title-loading"
        animation="wave"
        className={classes.skeleton}
      />
    );

  return (
    <div className={classes.titleContainer}>
      {leftComponent}
      <TruncatedTooltip title={title} placement="top">
        <Typography
          className={clsx({
            [classes.title]: true,
            [classes.capitalize]: capitalize,
          })}
          component="h1"
          variant="h5SemiBold"
          data-testid="top-nav-page-title"
        >
          {title}
        </Typography>
      </TruncatedTooltip>
      {rightComponent}
    </div>
  );
}

function TruncatedTooltip({
  children,
  ...tooltipProps
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
} & TooltipProps): JSX.Element {
  const ref = useRef<HTMLElement | null>(null);
  const [truncated, setTruncated] = useState(false);

  useLayoutEffect(() => {
    if (!ref.current) return;

    try {
      const observer = new ResizeObserver(() => {
        const truncated =
          (ref.current?.offsetWidth ?? 0) < (ref.current?.scrollWidth ?? 0);
        // Note: Fixes the "ResizeObserver loop limit exceeded" error.
        setTimeout(() => {
          setTruncated(truncated);
        }, 0);
      });
      observer.observe(ref.current);

      return () => observer.disconnect();
    } catch {
      setTruncated(true);
    }
  }, [truncated]);

  const child = React.cloneElement(children, { ...children.props, ref: ref });

  if (!truncated) return <>{child}</>;
  return <Tooltip {...tooltipProps}>{child}</Tooltip>;
}
