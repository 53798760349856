import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityColorAAChart } from "../../../../../_common/charts/config/getAccessibilityColorAAChart";
import { getAccessibilityColorAATrend } from "../../../../../_common/charts/config/getAccessibilityColorAATrend";

export const generateAccessibilityColorAACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityColorAAChart,
      getAccessibilityColorAATrend,
    ],
  };
