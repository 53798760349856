import * as Yup from "yup";
import { useTranslation } from "@lumar/shared";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { getFilterOrRuleArrayValidationSchema } from "../../_common/connection-filtering/filter-or-rule-field-array/getFilterOrRuleValidationSchema";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useCreateSegmentValidationSchema(metrics: NarrowMetric[]) {
  const { t: tSegments } = useTranslation("segments");
  const { t: tConnectionFiltering } = useTranslation("connectionFiltering");
  return Yup.object({
    segments: Yup.array()
      .of(
        Yup.object({
          segmentName: Yup.string()
            .min(3, tSegments("validation.minNameLength"))
            .max(255, tSegments("validation.maxNameLength"))
            .required(tSegments("validation.required")),
          group: Yup.string()
            .nullable()
            .test(
              "emptyGroupName",
              tSegments("validationErrorEmptyGroup"),
              (value) => value !== undefined,
            ),
          rules: getFilterOrRuleArrayValidationSchema(
            metrics,
            tConnectionFiltering,
          ),
        }).required(),
      )
      .required(),
  });
}
