import {
  Button,
  Chip,
  PlusSolid,
  Select,
  useConnectionFilterParam,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, MenuItem, Tooltip } from "@material-ui/core";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import {
  LegacyTaskOrderField,
  OrderDirection,
  ReportTasksQuery,
  useReportTasksQuery,
} from "../../../../../graphql";
import { CreateTaskDialog } from "../../../../../task-manager/create-task/CreateTaskDialog";
import { LegacyReportInput } from "../../../../Report.types";
import { TaskItem } from "./task-item/TaskItem";
import clsx from "clsx";
import { TFunction } from "i18next";

const useStyles = makeStyles((theme) => ({
  createLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    "&$createLinkDisabled": {
      pointerEvents: "auto",
    },
  },
  createLinkDisabled: {},
  menu: {
    border: 0,
    width: 260,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
    minWidth: 260,
  },
  chip: {
    marginLeft: "auto",
    pointerEvents: "none",
    height: 18,
    "& > span": {
      padding: theme.spacing(0, 1.25),
    },
  },
}));

export function TasksMenu({
  totalCount,
  reportInput,
}: {
  totalCount: number;
  reportInput: LegacyReportInput;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [filters] = useConnectionFilterParam();

  const { crawlId, reportTemplateCode, reportTypeCode, segmentId } =
    reportInput;

  const { data, loading } = useReportTasksQuery({
    variables: {
      input: {
        crawlId,
        reportTemplateCode,
        reportTypeCode,
        segmentId,
      },
      tasksOrderBy: [
        {
          field: LegacyTaskOrderField.CreatedAt,
          direction: OrderDirection.Desc,
        },
      ],
    },
  });

  const report = data?.report;
  const tasks = report?.legacyTasks;

  const { disabled, reason } = isTaskCreationDisabled(data, t);

  return (
    <>
      {!tasks?.totalCount ? (
        <Tooltip title={reason || ""} placement="top">
          <div>
            <Button
              onClick={(event) => {
                event.preventDefault();
                setTaskModalOpen(true);
              }}
              variant="outlined"
              size="large"
              startIcon={<PlusSolid />}
              disabled={loading || disabled}
              data-pendo="create-task-button"
              data-testid="create-task-button"
            >
              {t("createTask")}
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Select
          value={tasks?.totalCount}
          dividerRight
          alignMenu="right"
          renderValue={() => (
            <span style={{ display: "flex" }}>
              <span style={{ marginRight: 8 }}>{t("tasks")}</span>
              <Chip
                label={tasks?.totalCount}
                color="primary"
                rounded
                size="small"
                className={classes.chip}
              />
            </span>
          )}
          MenuProps={{ classes: { paper: classes.menu } }}
          data-pendo="tasks-dropdown"
          data-testid="report-grid-tasks-button"
        >
          <Tooltip title={reason || ""} placement="top">
            <MenuItem
              component={Link}
              to=""
              className={clsx({
                [classes.createLink]: true,
                [classes.createLinkDisabled]: disabled,
              })}
              onClick={(event: MouseEvent) => {
                event.preventDefault();

                if (disabled) return;
                setTaskModalOpen(true);
              }}
              disabled={disabled}
              data-testid="report-grid-add-task"
            >
              <PlusSolid style={{ fontSize: 15, marginRight: 5 }} />{" "}
              {t("addTask")}
            </MenuItem>
          </Tooltip>
          {tasks?.nodes.map((task) => (
            <TaskItem task={task} key={task.id}></TaskItem>
          ))}
        </Select>
      )}
      <CreateTaskDialog
        onClose={() => setTaskModalOpen(false)}
        open={taskModalOpen}
        crawlId={crawlId}
        reportTemplateCode={reportTemplateCode}
        reportTypeCode={reportTypeCode}
        segmentId={segmentId}
        identified={totalCount}
        filters={filters}
        clearOnClose
      />
    </>
  );
}

function isTaskCreationDisabled(
  data: ReportTasksQuery | undefined,
  t: TFunction<"report">,
): {
  disabled: boolean;
  reason: string | undefined;
} {
  const project = data?.report?.project ?? undefined;

  const taskLimitReached =
    project !== undefined &&
    project.tasks.totalCount >= project.account.maxLegacyTasksPerProject;
  const activeTaskLimitReached =
    project !== undefined &&
    project.activeTasks.totalCount >=
      project.account.maxActiveLegacyTasksPerProject;

  return {
    disabled: taskLimitReached || activeTaskLimitReached,
    reason: taskLimitReached
      ? t("taskLimitReached")
      : activeTaskLimitReached
        ? t("activeTaskLimitReached")
        : undefined,
  };
}
