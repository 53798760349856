import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useProjectGuardQuery } from "../../graphql";
export function useProjectGuard(config = {}) {
    const { skip = false, redirectLocation = "/login" } = config;
    const history = useHistory();
    const { projectId } = useParams();
    if (!projectId && !skip) {
        console.error("`useProjectGuard` has been called in a route without `projectId` param!");
    }
    const { data, loading } = useProjectGuardQuery({
        variables: { projectId },
        fetchPolicy: "cache-first",
        errorPolicy: "ignore",
        skip: !projectId || skip,
    });
    React.useEffect(() => {
        var _a;
        if (!projectId || skip) {
            return;
        }
        const projectNotFound = !((_a = data === null || data === void 0 ? void 0 : data.getProject) === null || _a === void 0 ? void 0 : _a.id);
        if (!loading && projectNotFound) {
            history.replace(redirectLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, projectId]);
}
