import {
  Button,
  DocumentNode,
  KeyboardArrowDown,
  useQuery,
  useTranslation,
} from "@lumar/shared";
import { ListSubheader, makeStyles, Menu, MenuProps } from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import React, { useContext, useEffect, useState } from "react";
import {
  CustomReportExportedRowsDocument,
  DatasourceCode,
  GetCustomReportInput,
  GetReportStatInput,
  ReportDownloadConnection,
  ReportDownloadStatus,
  ReportRowsWithAllMetricsArgs,
  ReportStatExportedRowsDocument,
  ReportTypeCode,
} from "../../../../../graphql";
import { ReportEntity, ReportInput } from "../../../../Report.types";
import { GridContext } from "../../ReportGrid";
import { useSelectedFields } from "../_common/useSelectedFields";
import { ExportedRowsGenerationItem } from "./export-rows-generation-item/ExportedRowsGenerationItem";
import {
  ExportedRows,
  ExportRowsItem,
} from "./export-rows-item/ExportRowsItem";
import { generationOptions } from "./generationOptions";
import { useExportDownload } from "./useExportDownload";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "auto",
    color: theme.palette.grey[600],
    padding: "7.5px 16px",
    backgroundColor: "white",
    borderColor: theme.palette.grey[300],
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
    borderRadius: theme.shape.borderRadius,
    "& span": {
      fontWeight: "500",
    },
    "&:hover": {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
    },
    "&:focus": {
      backgroundColor: theme.palette.grey[100],
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[400],
      boxShadow: "none",
      backgroundColor: theme.palette.grey[100],
      borderColor: "unset",
    },
  },
  listHeader: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.grey[700],
    background: "white",
    paddingLeft: 10,
    lineHeight: "30px",
  },
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
    minWidth: 260,
  },
  arrows: {
    width: 20,
    height: 20,
  },
  badge: {
    backgroundColor: theme.palette.green[400],
    color: "white",
    padding: theme.spacing(0, 1.25),
    borderRadius: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    marginLeft: theme.spacing(0.625),
  },
}));

const getExportedRowsQueryDocument = (
  reportInput: ReportInput,
): DocumentNode => {
  switch (reportInput.reportEntity) {
    case ReportEntity.CustomReport:
      return CustomReportExportedRowsDocument;
    default:
      return ReportStatExportedRowsDocument;
  }
};

const getExportedRowsQueryVariables = (
  reportInput: ReportInput,
): { input: GetCustomReportInput | GetReportStatInput } & {
  reportTypeCode: ReportTypeCode;
} => {
  const { crawlId, segmentId, reportEntity, reportTypeCode } = reportInput;

  switch (reportEntity) {
    case ReportEntity.CustomReport:
      return {
        input: {
          crawlId,
          customReportTemplateId: reportInput.customReportTemplateId,
          segmentId,
        },
        reportTypeCode,
      };
    default:
      return {
        input: {
          crawlId,
          reportTemplateCode: reportInput.reportTemplateCode,
          segmentId,
        },
        reportTypeCode,
      };
  }
};

export const ExportRows = React.memo(function ExportRows(): JSX.Element {
  const { datasourceCode, loading, filter, reportInput } =
    useContext(GridContext);
  const { t } = useTranslation("report");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasActive, setHasActive] = useState(false);

  const { reportTypeCode } = reportInput;

  const { data, startPolling, stopPolling } = useQuery(
    getExportedRowsQueryDocument(reportInput),
    {
      variables: getExportedRowsQueryVariables(reportInput),
      context: { headers: { "x-graph-locale": "en" } },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const reportDownloads = data.report?.reportDownloads as
          | ReportDownloadConnection
          | undefined;
        const download = reportDownloads?.nodes[0];
        const actives = reportDownloads?.nodes.filter(
          (d) =>
            d.status === ReportDownloadStatus.Generating ||
            d.status === ReportDownloadStatus.Draft,
        );
        if (actives?.length) {
          setHasActive(true);
          startPolling(1000);
        } else {
          stopPolling();
          if (hasActive && download) {
            setHasActive(false);
            downloadFile(download);
          }
        }
      },
      onError: () => {
        stopPolling();
      },
    },
  );

  const downloadFile = useExportDownload({ data, reportTypeCode });

  const downloads = data?.report?.reportDownloads?.nodes;

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        disabled={loading}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        data-pendo="export-dropdown"
        data-testid="export-button"
        style={{ marginLeft: 8 }}
        endIcon={
          anchorEl === null ? (
            <KeyboardArrowDown className={classes.arrows} />
          ) : (
            <KeyboardArrowUp className={classes.arrows} />
          )
        }
      >
        {t("export")}
        <div>
          {(downloads?.length ?? 0) > 0 ? (
            <span className={classes.badge}>{downloads?.length}</span>
          ) : null}
        </div>
      </Button>
      {Boolean(anchorEl) && (
        <ExportRowsMenu
          reportInput={reportInput}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          dataSource={datasourceCode}
          downloads={downloads}
          downloadFile={downloadFile}
          filter={filter}
        />
      )}
    </>
  );
});

function ExportRowsMenu({
  reportInput,
  anchorEl,
  onClose,
  filter,
  dataSource,
  downloads,
  downloadFile,
}: {
  reportInput: ReportInput;
  anchorEl: MenuProps["anchorEl"];
  onClose: () => void;
  filter: ReportRowsWithAllMetricsArgs["filter"];
  dataSource?: DatasourceCode;
  downloads?: ExportedRows;
  downloadFile: (download: ExportedRows[number]) => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");

  // The menu component puts tabIndex prop on it's children.
  // This ListSubHeader to be focusable.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tabIndex: number = null;

  const selectedMetrics = useSelectedFields();

  const [open, setOpen] = useState(Boolean(anchorEl));
  useEffect(() => setOpen(Boolean(anchorEl)), [anchorEl]);

  const generationItems = generationOptions(t).filter((option) => {
    const limitedTo = option.limitedToDatasources !== undefined;
    return limitedTo && dataSource
      ? option.limitedToDatasources?.includes(dataSource)
      : true;
  });

  return (
    <Menu
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      anchorEl={anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      classes={{ paper: classes.menu }}
    >
      {downloads?.length ? (
        <div tabIndex={tabIndex}>
          <ListSubheader className={classes.listHeader}>
            {t("availableFiles")}
          </ListSubheader>
        </div>
      ) : null}

      {downloads?.length
        ? downloads.map((file) => (
            <ExportRowsItem
              key={file.id}
              download={file}
              downloadFile={downloadFile}
              reportInput={reportInput}
            />
          ))
        : null}

      <div tabIndex={tabIndex}>
        <ListSubheader className={classes.listHeader}>
          {t("generateExports")}
        </ListSubheader>
      </div>
      {generationItems.map((option, index) => (
        <ExportedRowsGenerationItem
          reportInput={reportInput}
          option={option}
          key={option.id}
          generatedReports={downloads}
          selectedMetrics={selectedMetrics}
          autoFocus={!downloads?.length ? true : false}
          index={index.valueOf()}
          filter={filter}
        />
      ))}
    </Menu>
  );
}
