import { Typography, useDateFormatter, useTranslation } from "@lumar/shared";
import { Field, FieldProps } from "formik";
import { Label } from "./Label";
import { DeadlineSelect } from "./DeadlineSelect";
import { makeStyles } from "@material-ui/core";

export function DeadlineField({
  isEditing,
}: {
  isEditing: boolean;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const formatDate = useDateFormatter();

  return (
    <Field name="deadline">
      {(props: FieldProps<Date | null>) =>
        !isEditing ? (
          <>
            <Label label={t("editDialog.deadline")} />
            <Typography
              variant="subtitle3Medium"
              className={classes.deadline}
              data-testid="task-dialog-deadline"
            >
              {props.field.value
                ? formatDate(props.field.value, { dateStyle: "medium" })
                : "-"}
            </Typography>
          </>
        ) : (
          <>
            <Label label={t("editDialog.deadline")} />
            <DeadlineSelect
              value={props.field.value}
              onChange={(value: Date | null) =>
                props.form.setFieldValue(props.field.name, value)
              }
              disabled={props.form.isSubmitting}
              className={classes.input}
              data-testid="edit-task-deadline"
            />
          </>
        )
      }
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  deadline: {
    display: "block",
    margin: theme.spacing(0.75, 0, 1, 0),
  },
  input: {
    margin: theme.spacing(0.75, 0, 1, 0),
  },
}));
