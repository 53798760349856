import { GridApi, GridColDef } from "@mui/x-data-grid-pro";
import { SegmentCell, SegmentHeader } from "./cells/SegmentCell";
import { insertIf, useSession, useTranslation } from "@lumar/shared";
import { CrawlStatus, RoleCode } from "../../graphql";
import { ActionsCell } from "./cells/ActionsCell";
import React from "react";
import { SegmentGroup, SegmentRow } from "./SegmentsGrid";
import { UrlCountCell } from "./cells/UrlCountCell";
import { TrendCell } from "./cells/TrendCell";
import { isNumber } from "lodash";
import { SegmentActions } from "./ManageSegment";

interface Props {
  crawlInProgress: boolean;
  crawlStatus: CrawlStatus | undefined;
  createSegmentDisabled: boolean;
  setGroups: React.Dispatch<React.SetStateAction<SegmentGroup[]>>;
  actions: SegmentActions;
}

export function useSegmentGridColumns({
  crawlInProgress,
  crawlStatus,
  createSegmentDisabled,
  setGroups,
  actions,
}: Props): GridColDef[] {
  const { t } = useTranslation("segments");
  const { hasSufficientRole } = useSession();

  const hasEditPermission = hasSufficientRole(RoleCode.Editor);

  return React.useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        headerName: t("segmentName"),
        renderCell: (params) => (
          <SegmentCell {...params} setGroups={setGroups} />
        ),
        renderHeader: (params) => <SegmentHeader {...params} />,
        flex: 1.5,
        minWidth: 180,
        disableColumnMenu: true,
        sortComparator: (value1, value2, cell1, cell2) => {
          const row1 = (cell1.api as GridApi).getRow(
            cell1.id,
          ) as SegmentRow | null;
          const row2 = (cell2.api as GridApi).getRow(
            cell2.id,
          ) as SegmentRow | null;

          const group1 =
            (row1?.isGroup ? row1.name : row1?.group) || t("defaultGroup");
          const group2 =
            (row2?.isGroup ? row2.name : row2?.group) || t("defaultGroup");
          const groupCompare = group1
            .toLocaleLowerCase()
            .localeCompare(group2.toLocaleLowerCase());
          if (groupCompare !== 0) return groupCompare;

          if (row1?.isGroup || row2?.isGroup) return 0;

          const name1 = value1?.toString().toLocaleLowerCase() || "";
          const name2 = value2?.toString().toLocaleLowerCase() || "";
          return name1.localeCompare(name2);
        },
      },
      {
        field: "trend",
        headerName: t("trend"),
        renderCell: (params) => (
          <TrendCell
            {...params}
            crawlInProgress={crawlInProgress}
            crawlStatus={crawlStatus}
          />
        ),
        minWidth: 189,
        sortable: false,
      },
      {
        field: "urlCount",
        headerName: t("urlCount"),
        valueGetter: ({ row }) =>
          isNumber(row.report?.totalRows) ? row.report.totalRows : undefined,
        renderCell: (params) => (
          <UrlCountCell {...params} crawlInProgress={crawlInProgress} />
        ),
        minWidth: 150,
        sortComparator: (value1, value2, cell1, cell2) => {
          const row1 = (cell1.api as GridApi).getRow(
            cell1.id,
          ) as SegmentRow | null;
          const row2 = (cell2.api as GridApi).getRow(
            cell2.id,
          ) as SegmentRow | null;

          if (row1?.isGroup || row2?.isGroup || row1?.group !== row2?.group) {
            return 0;
          }

          return (
            ((value1 as number | undefined) || 0) -
            ((value2 as number | undefined) || 0)
          );
        },
      },
      ...insertIf<GridColDef>(hasEditPermission, {
        field: "actions",
        headerName: t("actions"),
        disableColumnMenu: true,
        renderCell: (params) => (
          <ActionsCell
            {...params}
            actions={actions}
            createSegmentDisabled={createSegmentDisabled}
          />
        ),
        width: 100,
        sortable: false,
        align: "center",
        headerAlign: "center",
      }),
    ],
    [
      t,
      hasEditPermission,
      setGroups,
      crawlInProgress,
      crawlStatus,
      createSegmentDisabled,
      actions,
    ],
  );
}
