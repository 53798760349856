import React from "react";
import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import { ProgressDashboardKeyNumber } from "../_common/progress-dashboard-key-number/ProgressDashboardKeyNumber";
import {
  ShieldCheck,
  Package,
  GlobeSimple,
  Timer,
  PauseCircle,
  Compass,
  Draft,
  WarningOctagon,
  useTranslation,
} from "@lumar/shared";
import { TFunction } from "i18next";
import { CrawlStatus, RunningCrawlQuery } from "../../../../graphql";
import { FinalisationProgress } from "./finalisation-progress/FinalisationProgress";
import { RunningCrawl } from "../../../useProjectPoller";
import { SvgIcon } from "@material-ui/core";
import { isFinalising } from "../../_common/running-crawl-query-utils";

export function CrawlStatusCard({
  project,
  runningCrawl,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const { t: tCommon } = useTranslation("common");
  const lastFinishedCrawl = project?.getProject?.lastFinishedCrawl;
  const status = runningCrawl?.status ?? lastFinishedCrawl?.statusEnum;
  const { icon, info } = getCrawlStatusProps(status, t);

  const statusKey = status?.toLowerCase() as
    | Lowercase<keyof typeof CrawlStatus>
    | undefined;
  const crawlStatusName = statusKey
    ? tCommon(`crawlStatus.${statusKey}`)
    : undefined;

  return (
    <ProgressDashboardContainer>
      <ProgressDashboardKeyNumber
        icon={icon}
        label={t("progressDashboard.crawlStatusCardTitle")}
        value={crawlStatusName}
        infoText={info}
      />
      {isFinalising(runningCrawl) && <FinalisationProgress />}
    </ProgressDashboardContainer>
  );
}

function getCrawlStatusProps(
  status: CrawlStatus | undefined,
  t: TFunction<"crawlProgress">,
): { info: string; icon: typeof SvgIcon } {
  switch (status) {
    case CrawlStatus.Draft:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: Draft,
      };
    case CrawlStatus.Queuing:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: Timer,
      };
    case CrawlStatus.Queued:
      return {
        info: t("progressDashboard.queued"),
        icon: Timer,
      };
    case CrawlStatus.Discovering:
      return {
        info: t("progressDashboard.discovering"),
        icon: Compass,
      };
    case CrawlStatus.Crawling:
      return {
        info: t("progressDashboard.crawling"),
        icon: GlobeSimple,
      };
    case CrawlStatus.Finalizing:
      return {
        info: t("progressDashboard.finalizing"),
        icon: GlobeSimple,
      };
    case CrawlStatus.Paused:
      return {
        info: t("progressDashboard.paused"),
        icon: PauseCircle,
      };
    case CrawlStatus.Finished:
      return {
        info: t("progressDashboard.finished"),
        icon: ShieldCheck,
      };
    case CrawlStatus.Errored:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: WarningOctagon,
      };
    case CrawlStatus.Archiving:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: Package,
      };
    case CrawlStatus.Archived:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: Package,
      };
    default:
      return {
        info: t("progressDashboard.defaultStatus"),
        icon: ShieldCheck,
      };
  }
}
