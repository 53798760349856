import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button, Typography } from "../..";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    body: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        marginBottom: theme.spacing(1),
    },
    titleText: {
        color: theme.palette.grey[800],
        textAlign: "center",
        maxWidth: 360,
        wordBreak: "break-word",
        marginBottom: theme.spacing(2),
    },
    descriptionSlot: {
        color: theme.palette.grey[500],
        letterSpacing: "0.0025em",
        maxWidth: 340,
        textAlign: "center",
        marginBottom: theme.spacing(2),
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
    },
}));
export function EmptyState(incomingProps) {
    var _a;
    const classes = useStyles();
    const theme = useTheme();
    const props = Object.assign(Object.assign({}, (_a = theme.props) === null || _a === void 0 ? void 0 : _a.EmptyState), incomingProps);
    const { title, description, actions, width, height, icon, className, showIcon = true, } = props;
    return (_jsxs("div", { style: {
            padding: theme.spacing(2),
            height,
            width,
        }, className: clsx(classes.body, className), "data-testid": "empty-state", children: [icon && showIcon ? _jsx("div", { className: classes.icon, children: icon }) : null, (title === null || title === void 0 ? void 0 : title.length) ? (_jsx(Typography, { variant: "h2SemiBold", className: classes.titleText, children: title })) : undefined, description ? (_jsx("div", { className: clsx(classes.descriptionSlot, "EmptyState-box-description"), children: typeof description === "string" ? (_jsx(Typography, { variant: "body2", children: description })) : (description) })) : null, actions && (actions === null || actions === void 0 ? void 0 : actions.length) > 0 && (_jsx("div", { className: classes.actions, children: actions.map((itemProps, i) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                    switch (itemProps.type) {
                        case "button":
                            return (_jsx(Button, { variant: (_a = itemProps.variant) !== null && _a !== void 0 ? _a : "contained", color: (_b = itemProps.color) !== null && _b !== void 0 ? _b : "primary", size: "large", onClick: itemProps.onClick, startIcon: (_c = itemProps.startIcon) !== null && _c !== void 0 ? _c : itemProps.icon, endIcon: itemProps.endIcon, "data-testid": itemProps["data-testid"], "data-pendo": itemProps["data-pendo"], disabled: itemProps.disabled, children: itemProps.title }, i));
                        case "internalLink":
                            return (_jsx(Button, { component: Link, variant: (_d = itemProps.variant) !== null && _d !== void 0 ? _d : "contained", color: (_e = itemProps.color) !== null && _e !== void 0 ? _e : "primary", size: "large", startIcon: (_f = itemProps.startIcon) !== null && _f !== void 0 ? _f : itemProps.icon, endIcon: itemProps.endIcon, "data-testid": itemProps["data-testid"], "data-pendo": itemProps["data-pendo"], disabled: itemProps.disabled, to: itemProps.to, children: itemProps.title }, i));
                        case "externalLink":
                            return (_jsx(Button, { component: "a", variant: (_g = itemProps.variant) !== null && _g !== void 0 ? _g : "contained", color: (_h = itemProps.color) !== null && _h !== void 0 ? _h : "primary", size: "large", startIcon: (_j = itemProps.startIcon) !== null && _j !== void 0 ? _j : itemProps.icon, endIcon: itemProps.endIcon, "data-testid": itemProps["data-testid"], "data-pendo": itemProps["data-pendo"], disabled: itemProps.disabled, href: itemProps.href, children: itemProps.title }, i));
                    }
                }) }))] }));
}
