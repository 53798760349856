import {
  Box,
  createStyles,
  FormControlLabel,
  Link,
  makeStyles,
} from "@material-ui/core";
import { useContext } from "react";

import {
  Checkbox,
  Typography,
  getRawAccountId,
  useSession,
} from "@lumar/shared";
import { FastField, FastFieldProps } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RoleCode } from "../../../../graphql";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { translationNamespace } from "../../CrawlSettings";
import { SourcesContext } from "../data/useSourcesFormValues";
import { MajesticAdvanced } from "./MajesticAdvanced";
import { useAccountsRoutes } from "../../../../_common/routing/accountsRoutes";

const useStyles = makeStyles((theme) =>
  createStyles({
    indent: {
      marginTop: theme.spacing(1),
    },
    hidden: {
      display: "none",
    },
    link: {
      color: theme.palette.blue[600],
    },
  }),
);

export function Majestic(): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const { accountId } = useParams<{ accountId: string }>();
  const accountsRoutes = useAccountsRoutes();

  const classes = useStyles();

  const session = useSession();

  const contextValues = useContext(SourcesContext);

  if (!contextValues.majesticAvailable)
    return (
      <UpgradeMessage upgradeType="Corporate" className={classes.indent} />
    );

  const urlCountLimit = 50000;

  const projectAccount = session.allRelationships.find(
    (x) => getRawAccountId(x.account.id) === contextValues.projectAccountId,
  );

  const showUpgradeLink =
    projectAccount?.role.code === RoleCode.Admin &&
    contextValues.majesticMaxUrl < urlCountLimit;

  return (
    <Box className={classes.indent} flexDirection="column">
      <Typography variant="caption">
        <Trans
          ns="crawlSettings"
          i18nKey="sources.backlinks.majestic.description"
          values={{ max: contextValues.majesticMaxUrl }}
          components={{
            upgradeLink: (
              <Link
                href={accountsRoutes.Subscription.getUrl({
                  accountId,
                })}
                className={showUpgradeLink ? classes.link : classes.hidden}
              />
            ),
          }}
        />
      </Typography>
      <FastField name="backlinks.majestic.enabled">
        {({
          field: { value, name },
          form: { setFieldValue, isSubmitting },
        }: FastFieldProps<boolean>) => (
          <div style={{ marginTop: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={() => {
                    setFieldValue(name, !value);
                    if (!value) {
                      setFieldValue("crawlType.backlinks", true);
                    }
                  }}
                  disabled={isSubmitting}
                  data-testid="crawl-settings-majestic-enabled"
                />
              }
              label={t("sources.backlinks.majestic.enableLabel")}
            />
            {value && (
              <Box marginTop="16px">
                <MajesticAdvanced />
              </Box>
            )}
          </div>
        )}
      </FastField>
    </Box>
  );
}
