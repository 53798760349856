import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNumberFormatter } from "../../../../../i18n";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingLeft: 32,
        paddingRight: 32,
        height: "100%",
    },
    line: {
        stroke: theme.palette.grey[400],
    },
    lineHover: {
        stroke: theme.palette.blue[600],
    },
    title: {
        color: theme.palette.grey[500],
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    titleHover: {
        color: theme.palette.blue[600],
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        textAlign: "center",
    },
    count: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginTop: 4,
        color: theme.palette.grey[700],
    },
    countHover: {
        fontSize: theme.typography.pxToRem(14),
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        marginTop: 4,
        color: theme.palette.blue[600],
    },
}));
export const FailBarsLegend = ({ elementWidth, isHovered, data, lineMiddlePoint, x, y, }) => {
    const classes = useStyles();
    const formatter = useNumberFormatter();
    return (_jsxs("g", { transform: `translate(${x}, ${y})`, "data-pendo": `url-funnel-${data.failReportTemplateCode}`, children: [_jsx("foreignObject", { width: elementWidth, height: 120, children: _jsxs("div", { className: classes.container, children: [_jsx("span", { className: isHovered ? classes.titleHover : classes.title, children: data.failTitle }), _jsx("span", { className: isHovered ? classes.countHover : classes.count, children: formatter(data.fail, { notation: "compact" }) })] }) }), _jsx("path", { fill: "none", strokeDasharray: "4 2", className: isHovered ? classes.lineHover : classes.line, d: `M${lineMiddlePoint - 16},50 L${lineMiddlePoint - 2}, 50 C${lineMiddlePoint},50 ${lineMiddlePoint},50 ${lineMiddlePoint},52 L${lineMiddlePoint},100` })] }));
};
