import { MetricsValuePresenterProps } from "../../data/types";
import { Alert, TabSpinner } from "@lumar/shared";

interface Props extends MetricsValuePresenterProps {
  loading?: boolean;
  error?: string;
  screenshot?: Blob;
}

export function BoundingBoxWithScreenshotPresenter({
  value,
  loading,
  error,
  screenshot,
}: Props): JSX.Element {
  const boundingBox = parseBoundingBox(value);

  if (!boundingBox) {
    return <>-</>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (loading || !screenshot) {
    return <TabSpinner />;
  }

  return (
    <div
      style={{
        display: "block",
        backgroundImage: `url(${URL.createObjectURL(screenshot)})`,
        backgroundPosition: `-${boundingBox.x}px -${boundingBox.y}px`,
        width: boundingBox.width,
        height: boundingBox.height,
      }}
    />
  );
}

function parseBoundingBox(value: string | undefined):
  | {
      x?: number;
      y?: number;
      width?: number;
      height?: number;
    }
  | undefined {
  try {
    return value && JSON.parse(value);
  } catch {
    return;
  }
}
