import { Typography } from "@lumar/shared";
import { SvgIcon, makeStyles } from "@material-ui/core";
import { MetricsValuePresenter } from "../metrics-value-presenter";
import { Metrics } from "../data/types";

interface Props {
  metrics: Metrics;
  config: {
    icon: typeof SvgIcon;
    name: string;
    code: string;
    value?: React.ReactNode;
  }[];
}

export function MetricsList({ config, metrics }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.list}>
      {config.map((config, idx) => (
        <div key={idx} className={classes.element}>
          <div className={classes.header}>
            <config.icon className={classes.icon} />
            <MetricsValuePresenter
              metrics={metrics}
              code={config.code}
              value={config.value}
              className={classes.value}
            />
          </div>
          <Typography className={classes.name}>{config.name}</Typography>
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(6),
    margin: theme.spacing(0.5, 1.5, 2, 1.5),
  },
  element: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.grey[400],
    marginRight: theme.spacing(0.25),
    marginLeft: -theme.spacing(0.21),
  },
  name: {
    textTransform: "uppercase",
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(15),
    fontWeight: 700,
    marginTop: theme.spacing(0.5),
  },
  value: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    fontWeight: 600,
  },
}));
