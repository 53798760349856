import React from "react";
import { ApolloError } from "@lumar/shared";
import { useGetSiteSpeedAuditsQuery } from "../../../../graphql";
import { useParams } from "react-router-dom";
import { desanitizeDigestFromPageId } from "../../../../_common/routing/desanitizeDigestFromPageId";
import { Metrics } from "../../../data/types";
import { getMetrics } from "../../../data/getMetrics";

export type AuditData = {
  id: string;
  name: string;
  metrics: Metrics;
};

interface Result {
  audits: AuditData[];
  loading: boolean;
  error?: ApolloError;
}

export function useSiteSpeedAudits(): Result {
  const { crawlId, resourceId } = useParams<{
    crawlId: string;
    resourceId: string;
  }>();

  const urlDigest = desanitizeDigestFromPageId(resourceId);

  const [audits, setAudits] = React.useState<Result["audits"]>([]);

  const [cursor, setCursor] = React.useState<string | undefined>();
  const { data, loading, error } = useGetSiteSpeedAuditsQuery({
    variables: { crawlId, urlDigest, cursor },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setAudits((audits) => [
        ...audits,
        ...(data.report?.rowsWithAllMetrics?.nodes.map((audit) => ({
          id: audit.auditId,
          name: audit.title,
          metrics: getMetrics({
            resource: audit,
            datasource: data.report?.reportTemplate.datasource,
            moduleCode: data.report?.project.moduleCode,
          }),
        })) || []),
      ]);

      const pageInfo = data?.report?.rowsWithAllMetrics?.pageInfo;
      if (pageInfo?.hasNextPage) setCursor(pageInfo.endCursor || undefined);
    },
  });

  const pageInfo = data?.report?.rowsWithAllMetrics?.pageInfo;

  return {
    audits,
    loading: loading || Boolean(pageInfo?.hasNextPage && !error),
    error,
  };
}
