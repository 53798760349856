import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getNonHtmlFilesChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("nonHtmlFilesChart.title"),
    reportTemplateCodes: ["non_html_pages", "linked_non_html_files"],
    description: t("nonHtmlFilesChart.description"),
  })();
}
