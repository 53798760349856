import { CrawlStatus as ApiCrawlStatus, Crawl } from "../../graphql";

type NarrowCrawl =
  | Pick<
      Crawl,
      "id" | "createdAt" | "finishedAt" | "processingAt" | "statusEnum"
    >
  | undefined;

type CrawlStatus = {
  crawlFinalising: boolean;
  crawlInProgress: boolean;
};

export function getCrawlStatus(crawl: NarrowCrawl): CrawlStatus {
  const crawlFinalising = crawl?.statusEnum === ApiCrawlStatus.Finalizing;
  const crawlInProgress = Boolean(
    crawl?.createdAt && !crawl?.finishedAt && !crawlFinalising,
  );

  return {
    crawlFinalising,
    crawlInProgress,
  };
}
