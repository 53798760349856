import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";

import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { SettingControlFactory } from "../useAdvancedControls";

export const getAuthenticationControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.authentication.title"),
  path: "authentication",
  // eslint-disable-next-line react/display-name
  control: () => <Authentication />,
  testId: "crawl-settings-advanced-authentication",
  activeValues: ["userName"],
  textForSearch: createTextForSearch([
    t("settings.authentication.title"),
    t("settings.authentication.description"),
    t("settings.authentication.userNamePlaceholder"),
    t("settings.authentication.passwordPlaceholder"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
}));

export const Authentication = React.memo(AuthenticationInner);

function AuthenticationInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        {t("settings.authentication.description")}
      </Typography>
      <Grid container spacing={2} className={classes.indent}>
        <Grid item xs={12} md={3}>
          <FastField
            name="test.authentication.userName"
            component={TextFieldWithOnBlurUpdate}
            hiddenLabel
            variant="outlined"
            placeholder={t("settings.authentication.userNamePlaceholder")}
            className={classes.input}
            inputProps={{
              autoComplete: "username",
              form: {
                autoComplete: "off",
              },
            }}
            data-testid="test-site-username"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FastField
            name="test.authentication.password"
            component={TextFieldWithOnBlurUpdate}
            type="password"
            hiddenLabel
            variant="outlined"
            placeholder={t("settings.authentication.passwordPlaceholder")}
            className={classes.input}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            data-testid="test-site-password"
          />
        </Grid>
      </Grid>
    </>
  );
}
