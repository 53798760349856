import {
  useTranslation,
  NestedNavigationMenuChildItemAdornmentProps,
  Chip,
  ImportantMessageV2,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { ResourceDetailData } from "../../data/types";
import { IssueDetailsChangedMetrics } from "../issue-details/sections/IssueDetailsChangedMetrics";
import { OpportunityDetailsOverview } from "./section/OpportunityDetailsOverview";
import { OpportunityDetailsAllMetrics } from "./section/OpportunityDetailsAllMetrics";
import {
  OpportunityInstances,
  OpportunityInstancesProps,
} from "./section/OpportunityInstances";
import { SvgIcon } from "@material-ui/core";

type Section = {
  title: string;
  code: string;
  name: string;
  component: React.ElementType<{ data: ResourceDetailData }>;
  adornment?: React.ComponentType<NestedNavigationMenuChildItemAdornmentProps>;
};

export function useOpportunityDetailsSections(
  metrics: ResourceDetailData["metrics"],
): {
  sections: (Section & {
    items: Section[];
    icon?: typeof SvgIcon;
  })[];
  active: Section;
} {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");

  const sections = [
    {
      title: t("opportunity.opportunityDetails"),
      code: "overview",
      name: t("overview"),
      component: OpportunityDetailsOverview,
      items: [
        {
          title: t("opportunity.opportunityDetails"),
          code: "allMetrics",
          name: t("allMetrics"),
          component: OpportunityDetailsAllMetrics,
        },
        {
          title: t("opportunity.opportunityDetails"),
          code: "changedMetrics",
          name: t("changedMetrics"),
          component: IssueDetailsChangedMetrics,
        },
      ],
    },
    {
      title: t("opportunity.opportunityInstances"),
      code: "all-instances",
      name: t("opportunity.opportunityInstances"),
      component: getSiteSpeedAuditsComponent("all"),
      icon: ImportantMessageV2,
      items: [
        {
          title: t("opportunity.opportunityInstances"),
          code: "failed-instances",
          name: t("opportunity.failedInstances"),
          component: getSiteSpeedAuditsComponent("failed"),
          adornment: function InstancesCount(): JSX.Element {
            return (
              <Chip
                label={metrics["failedAuditsCount"]?.value}
                color="purple"
              />
            );
          },
        },
        {
          title: t("opportunity.opportunityInstances"),
          code: "warning-instances",
          name: t("opportunity.warningInstances"),
          component: getSiteSpeedAuditsComponent("warning"),
          adornment: function InstancesCount(): JSX.Element {
            return (
              <Chip
                label={metrics["warningAuditsCount"]?.value}
                color="purple"
              />
            );
          },
        },
        {
          title: t("opportunity.opportunityInstances"),
          code: "info-instances",
          name: t("opportunity.infoInstances"),
          component: getSiteSpeedAuditsComponent("info"),
          adornment: function InstancesCount(): JSX.Element {
            return (
              <Chip label={metrics["infoAuditsCount"]?.value} color="purple" />
            );
          },
        },
        {
          title: t("opportunity.opportunityInstances"),
          code: "passed-instances",
          name: t("opportunity.passedInstances"),
          component: getSiteSpeedAuditsComponent("passed"),
          adornment: function InstancesCount(): JSX.Element {
            return (
              <Chip
                label={metrics["passedAuditsCount"]?.value}
                color="purple"
              />
            );
          },
        },
      ],
    },
  ];

  const flatSection = sections.flatMap((section) => [
    section,
    ...section.items.map((item) => item),
  ]);
  const foundSection = type
    ? flatSection.find((x) => x.code === type)
    : undefined;

  return {
    sections,
    active: foundSection ?? flatSection[0],
  };
}

function getSiteSpeedAuditsComponent(
  variant: OpportunityInstancesProps["variant"],
): React.ElementType<{
  data: ResourceDetailData;
}> {
  function OpportunityInstancesWrapper(props: {
    data: ResourceDetailData;
  }): JSX.Element {
    return <OpportunityInstances {...props} variant={variant} />;
  }

  return OpportunityInstancesWrapper;
}
