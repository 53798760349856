/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "@lumar/shared";
import { isEqual, isNumber, isObject, isString } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import {
  CrawlType,
  GetManualUploadsForSourcesQuery,
  UrlFileUploadStatus,
  useGetManualUploadsForSourcesQuery,
  useGetProcessingManualUploadsQuery,
} from "../../../../graphql";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";
import {
  FileUpload,
  ManualUploadsContextValues,
  UploadTemplate,
  UploadType,
} from "./types";

export interface ManualUploadFilesResult {
  files: FileUpload[];
  loading: boolean;
  uploadTypes: ManualUploadsContextValues["uploadTypes"];
  primaryDomain: string;
}

interface Props {
  projectId: string;
  uploadingFiles: FileUpload[];
}

export function useManualUploadFiles({
  projectId,
  uploadingFiles,
}: Props): ManualUploadFilesResult {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    loading: queryLoading,
    fetchMore,
  } = useGetManualUploadsForSourcesQuery({
    variables: { projectId },
    fetchPolicy: "cache-first",
    onError: (error) =>
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleFileInit")}
          error={error}
        />,
      ),
  });

  const pageInfo = data?.getProject?.urlFileUploads?.pageInfo;
  React.useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({ variables: { projectId, cursor: pageInfo.endCursor } });
    }
  }, [pageInfo, fetchMore, projectId]);

  const loading = Boolean(queryLoading || pageInfo?.hasNextPage);
  const files = !loading ? getFileUploads(data, uploadingFiles) : [];

  const processingFiles = files
    .filter(
      (file) =>
        file.status === UrlFileUploadStatus.Processing ||
        file.status === UrlFileUploadStatus.Draft ||
        file.status === "Uploading",
    )
    .map((file) => file.fileName);

  // This query is used for updating processing files for the GetManualUploadsForSources query
  const { startPolling, stopPolling, error } =
    useGetProcessingManualUploadsQuery({
      variables: { projectId, fileNames: processingFiles },
      fetchPolicy: "cache-first",
      skip: !processingFiles.length,
      onError: (error) => {
        stopPolling();
        enqueueSnackbar(
          <ApolloErrorSnackbar
            title={t("message.apiErrorTitleFileInit")}
            error={error}
          />,
        );
      },
    });

  React.useEffect(() => {
    if (processingFiles.length && !error) startPolling(1000);
    return () => stopPolling();
  }, [processingFiles, startPolling, stopPolling, error]);

  return {
    files,
    loading,
    uploadTypes: getUploadTypes(data),
    primaryDomain: data?.getProject?.primaryDomain || "",
  };
}

function getFileUploads(
  data: GetManualUploadsForSourcesQuery | undefined,
  uploadingFiles: FileUpload[],
): FileUpload[] {
  const files: FileUpload[] =
    data?.getProject?.urlFileUploads?.edges?.map(({ node: file }) => {
      const isUploading = Boolean(uploadingFiles.find((x) => x.id === file.id));

      return {
        id: file.id,
        crawlType: file.crawlType.code,
        fileName: file.fileName,
        enabled: file.enabled,
        fileLink: file.fileUrl,
        status: isUploading ? "Uploading" : file.status,
        totalRows: file.totalRows ?? undefined,
        baseDomain: file.uploadBaseDomain ?? undefined,
        isCustomizable: file.isCustomizable,
        uploadType: file.urlFileUploadType?.code,
        customTemplate: getUploadTemplate(file.customUploadTemplate),
      };
    }) ?? [];

  return [
    ...files,
    ...uploadingFiles.filter((file) => !files.find((x) => x.id === file.id)),
  ];
}

function getUploadTypes(
  data?: GetManualUploadsForSourcesQuery,
): ManualUploadsContextValues["uploadTypes"] {
  const allUploadTypes = data?.getUrlFileUploadTypes || [];

  const crawlTypeMetrics: Partial<Record<CrawlType, Record<string, string>>> = {
    [CrawlType.Backlinks]: {
      url: "string",
      backlinks: "integer",
      linking_source_domains: "integer",
    },
    [CrawlType.GoogleAnalytics]: {
      url: "string",
      sessions: "integer",
      avg_page_load_time: "decimal",
      bounce_rate: "percent",
      avg_time_on_page: "time",
      pageviews_per_session: "decimal",
      pageviews: "integer",
    },
    [CrawlType.LogSummary]: {
      url: "string",
      log_requests_desktop: "integer",
      log_requests_mobile: "integer",
    },
  };

  return Object.fromEntries(
    Object.values(CrawlType).map((crawType) => {
      const uploadTypes: UploadType[] = allUploadTypes
        .filter((x) => x.crawlType.code === crawType)
        .map((x) => ({
          code: x.code,
          name: x.name,
          crawlType: x.crawlType.code,
          template: getUploadTemplate(x.uploadTemplate),
        }));

      const metrics: UploadTemplate["metrics"] = Object.entries(
        crawlTypeMetrics[crawType] ?? {},
      ).map(([metric, type]) => ({
        code: metric,
        name: metric.replaceAll("_", " "),
        type,
      }));

      return [crawType, { uploadTypes, metrics }];
    }),
  );
}

function getUploadTemplate(template: any): UploadTemplate | undefined {
  if (!template || isEqual(template, {})) return;

  const headerRow =
    isNumber(template.data_start_row) && template.data_start_row > 1
      ? template.data_start_row - 2
      : undefined;

  return {
    headerRow,
    metrics: isObject(template.metrics)
      ? Object.entries(template.metrics).map(([key, value]) => ({
          code: key,
          header: (value as any).header,
          name: key.replaceAll("_", " "),
          type: isString((value as any).type) ? (value as any).type : undefined,
          index: isNumber((value as any).column_position)
            ? (value as any).column_position - 1
            : undefined,
        }))
      : [],
  };
}
