import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "@lumar/shared";

import { activeStatuses } from "../../constants";
import { CrawlStatus } from "../../../graphql";

const useStyles = makeStyles((theme) => {
  const colors = [
    { statuses: activeStatuses, color: theme.palette.orange[600] },
    { statuses: [CrawlStatus.Finished], color: theme.palette.green[500] },
    { statuses: [CrawlStatus.Archived], color: theme.palette.grey[500] },
  ];

  return {
    text: (props: GridRenderCellParams) => {
      const mapping = colors.find(
        ({ statuses }) => statuses.filter((s) => s === props.value).length > 0,
      );
      return {
        textTransform: "capitalize",
        color: mapping?.color ?? theme.palette.grey[500],
        fontSize: 14,
      };
    },
  };
});

export function StatusCell(props: GridRenderCellParams): JSX.Element {
  const classes = useStyles(props);
  const { t } = useTranslation("common");

  const statusKey = (props.value as CrawlStatus)?.toLowerCase() as Lowercase<
    keyof typeof CrawlStatus
  >;
  const crawlStatusName = t(`crawlStatus.${statusKey}`);

  return (
    <Typography className={classes.text} data-testid="status-value">
      {crawlStatusName}
    </Typography>
  );
}
