import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getDomContentLoadChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("domContentLoad.title"),
    reportTemplateCodes: ["fast_dcl", "average_dcl", "slow_dcl"],
    description: t("domContentLoad.description"),
  })();
}
