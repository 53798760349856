import { useParams } from "react-router-dom";
import { desanitizeDigestFromPageId } from "../../../../_common/routing/desanitizeDigestFromPageId";
import { useGetSiteSpeedAttachmentsQuery } from "../../../../graphql";
import React from "react";
import { Metrics } from "../../../data/types";
import { useTranslation } from "@lumar/shared";
import { Result as Report } from "lighthouse/dist/report/bundle.esm";
import { LIGHTHOUSE_ATTACHMENT } from "../useSiteSpeedDetailsSections";

interface Result {
  report?: Report;
  screenshot?: string;
  loading: boolean;
  error?: string;
  expiresAt?: Date;
}

export function useSiteSpeedReport(metrics: Metrics): Result {
  const { t } = useTranslation("resourceDetail");
  const { crawlId, resourceId } = useParams<{
    crawlId: string;
    resourceId: string;
  }>();

  const urlDigest = desanitizeDigestFromPageId(resourceId);

  const [result, setResult] = React.useState<Result>({ loading: true });

  const expiresAt = metrics["attachments"]?.value?.find(
    (x: { name?: string }) => x?.name?.endsWith(LIGHTHOUSE_ATTACHMENT),
  )?.expiresAt;

  const isExpired =
    (typeof expiresAt === "string"
      ? new Date(expiresAt)
      : new Date()
    ).getTime() < new Date().getTime();

  React.useEffect(() => {
    if (!isExpired) return;
    setResult({ loading: false, error: t("siteSpeed.reportErrorExpired") });
  }, [isExpired, t]);

  useGetSiteSpeedAttachmentsQuery({
    variables: {
      crawlId,
      urlDigest,
    },
    fetchPolicy: "cache-first",
    skip: isExpired,
    onCompleted: async (data) => {
      const url = data.report?.crawlUrls?.nodes[0]?.attachments?.find((x) =>
        x.name.endsWith(LIGHTHOUSE_ATTACHMENT),
      )?.signedLocation;
      if (!url) {
        setResult({
          loading: false,
          error: t("siteSpeed.reportErrorInvalid"),
        });
        return;
      }

      try {
        const response = await fetch(url);
        const data = await response.json();

        const screenshot =
          data?.artifacts?.FullPageScreenshot?.screenshot?.data ?? undefined;

        const reportString = data?.report;
        const report = JSON.parse(reportString);

        setResult({
          loading: false,
          report,
          screenshot,
          expiresAt: new Date(expiresAt),
        });
      } catch {
        setResult({
          loading: false,
          error: t("siteSpeed.reportErrorInvalid"),
        });
      }
    },
  });

  return result;
}
