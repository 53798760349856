var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles, Switch, Typography, } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    label: ({ labelDistance }) => ({
        color: "#374151",
        fontSize: 14,
        cursor: "pointer",
        marginLeft: labelDistance ? labelDistance : 5,
        marginBottom: 0,
    }),
    track: {
        backgroundColor: "#D1D5DB",
    },
}));
export function Toggle(props) {
    const { labelDistance } = props, swtichProps = __rest(props, ["labelDistance"]);
    const classes = useStyles({ labelDistance });
    return (_jsxs(Box, { className: classes.container, children: [_jsx(Switch, Object.assign({}, swtichProps, { color: "default", id: props.id, classes: { track: classes.track } })), props.label ? (_jsx("label", { htmlFor: props.id, className: classes.label, children: _jsx(Typography, { variant: "body2", component: "span", children: props.label }) })) : null] }));
}
