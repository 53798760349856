import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation, Button, Snackbar } from "@lumar/shared";

import { CreateTaskForm } from "./CreateTaskForm";
import { useCreateTaskFormValues } from "../data/useCreateTaskFormValues";
import { ConnectionFilter } from "../../_common/connection-filtering/types";
import { useSnackbar } from "notistack";
import { ReportTypeCode } from "../../graphql";

export interface CreateTaskDialogProps {
  open?: boolean;
  onClose: () => void;
  clearOnClose?: boolean;
  filters?: ConnectionFilter;
  segmentId?: string;
  crawlId: string;
  reportTemplateCode: string;
  reportTypeCode: ReportTypeCode;
  identified: number;
}

export function CreateTaskDialog({
  open,
  onClose,
  clearOnClose,
  filters,
  ...props
}: CreateTaskDialogProps): JSX.Element {
  const { t } = useTranslation("taskManager");
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const { values, createReportTask } = useCreateTaskFormValues({
    onSaveError: (error) =>
      setError({ title: t("createTask.saveError"), message: error.message }),
  });

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) return;

    enqueueSnackbar(
      <Snackbar variant="success" title={t("createTask.successMessage")} />,
    );
    onClose();
  };

  return (
    <CreateTaskForm
      values={values}
      error={error}
      onSubmit={async (values) => {
        return await createReportTask(
          values,
          props.crawlId,
          props.reportTemplateCode,
          props.reportTypeCode,
          props.segmentId,
          props.identified,
          filters,
        );
      }}
    >
      {(form, { isSubmitting, isValid, submitForm, resetForm }) => (
        <Dialog
          open={open ?? false}
          onClose={onClose}
          TransitionProps={{
            onExited: () => {
              if (clearOnClose) {
                resetForm();
                setError(undefined);
              }
            },
          }}
          maxWidth="sm"
          fullWidth
          aria-labelledby="create-task-dialog"
        >
          <DialogTitle id="create-task-dialog">
            {t("createTask.dialogTitle")}
          </DialogTitle>
          <DialogContent>{form}</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => onClose()}
              disabled={isSubmitting}
              data-testid="create-task-cancel"
            >
              {t("createTask.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (isSubmitting) return;
                setError(undefined);
                handleSave(submitForm as () => Promise<boolean>);
              }}
              disabled={!isValid}
              loading={isSubmitting}
              data-testid="create-task-update"
            >
              {t("createTask.create")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </CreateTaskForm>
  );
}
