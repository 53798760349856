import React from "react";
import { useFormikContext } from "formik";

export interface FormSubmitProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitForm: () => Promise<any>;
  isSubmitting: boolean;
  isValid: boolean;
}

export interface FormSubmitContextProps {
  children: (props: FormSubmitProps) => React.ReactElement;
}

export function FormSubmitContext({
  children,
}: FormSubmitContextProps): JSX.Element {
  const { submitForm, isSubmitting, isValid } = useFormikContext();

  const child = React.useMemo(
    () => children({ submitForm, isSubmitting, isValid }),
    [submitForm, isSubmitting, isValid, children],
  );

  return child;
}
