import { getReportImpact } from "../../_common/report-helpers/reportImpact";
import { getReportPriority } from "../../_common/report-helpers/reportPriority";
import { ReportTemplate } from "../../graphql";
import { ReportAdviceInfo, ReportSummaryInfo } from "./types";

export function getReportInfo(
  report:
    | {
        reportTemplateTotalSign?: number | null;
        reportTemplateTotalWeight?: number | null;
      }
    | undefined
    | null,
  reportTemplate:
    | Pick<
        ReportTemplate,
        | "name"
        | "description"
        | "summary"
        | "definition"
        | "effect"
        | "solutions"
        | "impact"
        | "totalSign"
        | "totalWeight"
      >
    | undefined,
  customExtractionLabel: string | undefined,
): ReportSummaryInfo | ReportAdviceInfo | undefined {
  const reportName = (customExtractionLabel ?? reportTemplate?.name) || "";
  const priority = getReportPriority(report?.reportTemplateTotalWeight);
  const impact = getReportImpact(report?.reportTemplateTotalSign);

  if (
    reportTemplate?.definition &&
    reportTemplate?.effect &&
    reportTemplate?.solutions &&
    reportTemplate?.impact
  ) {
    return {
      reportName,
      priority,
      impact,
      definition: reportTemplate.definition,
      effect: reportTemplate.effect,
      solutions: reportTemplate.solutions,
      benefit: reportTemplate.impact,
    };
  }

  if (reportTemplate?.description || reportTemplate?.summary) {
    const summary = customExtractionLabel
      ? reportTemplate?.summary?.replace(
          reportTemplate?.name,
          customExtractionLabel,
        )
      : reportTemplate?.summary;

    return {
      reportName,
      priority,
      impact,
      summary: summary || undefined,
      description: reportTemplate?.description || undefined,
    };
  }

  return undefined;
}
