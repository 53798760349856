var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getIn } from "formik";
export function fieldToSelect(_a) {
    var 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _b = _a.field, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { onBlur: fieldOnBlur } = _b, field = __rest(_b, ["onBlur"]), { form: { isSubmitting, touched, errors }, disabled } = _a;
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;
    return Object.assign({ disabled: disabled !== null && disabled !== void 0 ? disabled : isSubmitting, error: showError, onBlur: function (e) {
            fieldOnBlur(e !== null && e !== void 0 ? e : field.name);
        } }, field);
}
