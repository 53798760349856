/* eslint-disable fp/no-mutation */
import React from "react";
import useScrollbarSize from "react-scrollbar-size";

interface ScrollElements {
  overlay: HTMLDivElement;
  scroll: HTMLDivElement;
  fakeContent: HTMLDivElement;
}

const GRID_HEADER_HEIGHT = 40;

export function DataGridStickyScroll({
  children,
  ...props
}: {
  children: React.ReactNode;
}): JSX.Element {
  const elements = React.useRef<ScrollElements | null>(null);
  const container = React.useRef<HTMLDivElement | null>(null);

  const lastScroll = React.useRef(0);

  const { height: scrollBarHeight } = useScrollbarSize();

  const observeRoot = useMutationObserver((root) => {
    const windowContainer = root.querySelector(
      ".MuiDataGrid-windowContainer",
    ) as HTMLDivElement;
    if (windowContainer && !elements.current) {
      const scrollElements = createScrollOverlay(scrollBarHeight);
      elements.current = scrollElements;
      windowContainer.appendChild(scrollElements.overlay);
      observeScroll(scrollElements.scroll);
    }

    container.current = root.querySelector(
      ".MuiDataGrid-window",
    ) as HTMLDivElement;
    if (container.current) {
      observeTargetScroll(container.current);
    }

    const content = root.querySelector(
      ".MuiDataGrid-dataContainer",
    ) as HTMLDivElement;
    if (content) {
      observeContent(content);
    }

    return Boolean(windowContainer && container.current && content);
  });

  const observeContent = useResizeObserver((element) => {
    if (elements.current === null) return;
    elements.current.fakeContent.style.width = `${element.offsetWidth}px`;
  });

  function updateScroll(source: HTMLDivElement, target: HTMLDivElement): void {
    if (source.scrollLeft === lastScroll.current) return;
    target.scrollLeft = source.scrollLeft;
    lastScroll.current = source.scrollLeft;
  }

  const observeScroll = useScrollObserver((element) => {
    if (container.current === null) return;
    updateScroll(element, container.current);
  });

  const observeTargetScroll = useScrollObserver((element) => {
    if (elements.current === null) return;
    updateScroll(element, elements.current.scroll);
  });

  return (
    <div
      {...props}
      ref={(root) => {
        if (!root) return;
        observeRoot(root);
      }}
    >
      {children}
    </div>
  );
}

function createScrollOverlay(scrollBarHeight: number): ScrollElements {
  const overlay = document.createElement("div");
  overlay.style.position = "absolute";
  overlay.style.top = `${GRID_HEADER_HEIGHT}px`;
  overlay.style.bottom = "0px";
  overlay.style.left = "0px";
  overlay.style.right = "0px";
  overlay.style.pointerEvents = "none";

  const scroll = document.createElement("div");
  scroll.style.overflowX = "auto";
  scroll.style.position = "sticky";
  scroll.style.top = `calc(100vh - ${scrollBarHeight}px)`;
  scroll.style.pointerEvents = "auto";

  const fakeContent = document.createElement("div");
  fakeContent.style.height = "1px";

  scroll.appendChild(fakeContent);
  overlay.appendChild(scroll);
  return { overlay, scroll, fakeContent };
}

function useMutationObserver(
  callback: (element: HTMLDivElement) => boolean,
): (element: HTMLDivElement | null) => void {
  const observerRef = React.useRef<MutationObserver | undefined>();

  const cleanup = React.useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      observerRef.current = new MutationObserver(() => {
        const result = callback(element);
        if (result) cleanup();
      });
      observerRef.current.observe(element, {
        childList: true,
        subtree: true,
      });
    }
  };
}

function useResizeObserver(
  callback: (element: HTMLDivElement) => void,
): (element: HTMLDivElement | null) => void {
  const observerRef = React.useRef<ResizeObserver | undefined>();

  const cleanup = React.useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      observerRef.current = new ResizeObserver(() => callback(element));
      observerRef.current.observe(element);
      callback(element);
    }
  };
}

function useScrollObserver(
  callback: (element: HTMLDivElement) => void,
): (element: HTMLDivElement | null) => void {
  const ref = React.useRef<
    | {
        element: HTMLDivElement;
        listener: (this: HTMLDivElement, ev: Event) => void;
      }
    | undefined
  >(undefined);

  const cleanup = React.useCallback(() => {
    if (ref.current) {
      ref.current.element.removeEventListener("scroll", ref.current.listener);
      ref.current = undefined;
    }
  }, []);

  React.useEffect(() => () => cleanup(), [cleanup]);

  return (element) => {
    cleanup();

    if (element) {
      const listener = (): void => callback(element);
      element.addEventListener("scroll", listener);
      ref.current = { element, listener };
    }
  };
}
