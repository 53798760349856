import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCLSTrend";
import { getSiteSpeedFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedFCPTrend";
import { getSiteSpeedLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedLCPTrend";
import { getSiteSpeedSITrend } from "../../../../../_common/charts/config/getSiteSpeedSITrend";
import { getSiteSpeedTBTTrend } from "../../../../../_common/charts/config/getSiteSpeedTBTTrend";
import { getSiteSpeedAvgFCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgFCPTileChart";
import { getSiteSpeedAvgLCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgLCPTileChart";
import { getSiteSpeedAvgTBTTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgTBTTileChart";
import { getSiteSpeedAvgCLSTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCLSTileChart";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAvgCruxFCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxFCPTileChart";
import { getSiteSpeedAvgCruxLCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxLCPTileChart";
import { getSiteSpeedAvgCruxITNPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxITNPTileChart";
import { getSiteSpeedAvgCruxCLSTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxCLSTileChart";
import { getSiteSpeedCruxFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxFCPTrend";
import { getSiteSpeedCruxLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxLCPTrend";
import { getSiteSpeedCruxITNPTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxITNPTrend";
import { getSiteSpeedCruxCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxCLSTrend";

export const generateSiteSpeedOverviewCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    // allReportsTable: getSiteSpeedAllReportsTable,
    tileCharts: [
      [
        getSiteSpeedAvgFCPTileChart,
        getSiteSpeedAvgLCPTileChart,
        getSiteSpeedAvgTBTTileChart,
        getSiteSpeedAvgCLSTileChart,
      ],
      [
        getSiteSpeedAvgCruxFCPTileChart,
        getSiteSpeedAvgCruxLCPTileChart,
        getSiteSpeedAvgCruxITNPTileChart,
        getSiteSpeedAvgCruxCLSTileChart,
      ],
    ],
    mainCharts: [
      // { predefinedChartType: "topChanges" },
      // getSiteSpeedMainKPIsChart,
      // getSiteSpeedMainKPIsTrend,
      getSiteSpeedFCPTrend,
      getSiteSpeedCruxFCPTrend,
      getSiteSpeedLCPTrend,
      getSiteSpeedCruxLCPTrend,
      getSiteSpeedTBTTrend,
      getSiteSpeedCruxITNPTrend,
      getSiteSpeedCLSTrend,
      getSiteSpeedCruxCLSTrend,
      getSiteSpeedSITrend,
    ],
  };
