var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { GridApiContext, useGridApi, useGridSelector, } from "@mui/x-data-grid-pro";
import { assert } from "../helpers";
import { SearchIcon, XOutlined } from "../../icons";
import { debounce } from "lodash";
const useStyles = makeStyles(() => ({
    input: {
        width: 290,
    },
    clear: {
        cursor: "pointer",
        minHeight: 8,
    },
}));
export function BlueGridSearchField(_a) {
    var _b;
    var { defaultColumn, defaultOperator, searchDebounce } = _a, props = __rest(_a, ["defaultColumn", "defaultOperator", "searchDebounce"]);
    const classes = useStyles();
    const apiRef = useContext(GridApiContext);
    assert(apiRef);
    const api = useGridApi(apiRef);
    const filters = useGridSelector(apiRef, (state) => state.filter);
    const filterId = defaultOperator !== null && defaultOperator !== void 0 ? defaultOperator : `${defaultColumn}:contains`;
    const filterOperator = defaultOperator !== null && defaultOperator !== void 0 ? defaultOperator : "contains";
    const filter = filters.items.find((x) => x.id === filterId);
    const [innerValue, setInnerValue] = useDebounceValue({
        value: (_b = filter === null || filter === void 0 ? void 0 : filter.value) !== null && _b !== void 0 ? _b : "",
        setValue: (value) => api.upsertFilter({
            id: filterId,
            operatorValue: filterOperator,
            columnField: defaultColumn,
            value: value,
        }),
        wait: searchDebounce,
    });
    return (_jsx(TextField, Object.assign({ value: innerValue, onChange: (e) => setInnerValue(e.target.value), className: classes.input, variant: "outlined", placeholder: "Search", InputProps: {
            startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, {}) })),
            endAdornment: innerValue ? (_jsx(InputAdornment, { className: classes.clear, onClick: () => setInnerValue(""), position: "start", "data-testid": "search-remove-icon", children: _jsx(XOutlined, {}) })) : null,
        } }, props)));
}
function useDebounceValue({ value, setValue, wait, }) {
    var _a;
    const [debounceValue, setDebounceValue] = React.useState();
    const onValueChanged = (value) => {
        if (!wait) {
            setValue(value);
            return;
        }
        setDebounceValue((prevValue) => {
            prevValue === null || prevValue === void 0 ? void 0 : prevValue.fn.cancel();
            if (value === "") {
                setValue(value);
                return undefined;
            }
            const newValue = {
                fn: debounce(() => {
                    setValue(value);
                    setDebounceValue(undefined);
                }, wait),
                value,
            };
            newValue.fn();
            return newValue;
        });
    };
    return [(_a = debounceValue === null || debounceValue === void 0 ? void 0 : debounceValue.value) !== null && _a !== void 0 ? _a : value, onValueChanged];
}
