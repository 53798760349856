import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getPrimaryPagesNotInSerps(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("primaryPagesNotInSerps.title"),
    reportTemplateCodes: [
      "primary_pages_not_in_serp_with_no_backlinks",
      "primary_pages_not_in_serp_without_followed_links",
      "primary_pages_not_in_serp_with_low_deeprank",
      "primary_pages_not_in_serp_not_in_sitemaps",
    ],
    description: t("primaryPagesNotInSerps.description"),
  })();
}
