import { SvgIcon } from "@material-ui/core";
import { ChartIconNames } from "../../../../_common/charts/types/ChartConfigItemBase";
import { CruxIcon, LighthouseIcon } from "@lumar/shared";

export function getIconForChart(
  type: ChartIconNames | null | undefined,
): typeof SvgIcon | undefined {
  switch (type) {
    case "crux":
      return CruxIcon;
    case "lighthouse":
      return LighthouseIcon;
    default:
      return undefined;
  }
}
