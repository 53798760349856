import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";
import isUrl from "validator/lib/isURL";
import {
  getCookiesSchema,
  getUserAgentSchema,
} from "../../settings/data/useSettingsValidationSchema";
import { ModuleCode } from "../../../../graphql";

export function useDomainSchema(moduleCode: ModuleCode): unknown {
  const { t } = useTranslation("crawlSettings");

  return Yup.object().shape({
    basic: Yup.object().shape({
      primaryDomain: Yup.string()
        .test(
          "isURL",
          t("domain.invalidDomain"),
          (value) =>
            value !== undefined &&
            isUrl(value, {
              protocols: ["http", "https"],
              require_protocol: true,
            }),
        )
        .required(t("domain.invalidDomain")),
      name: Yup.string().required(t("domain.invalidProjectName")),
    }),
    ...(moduleCode === ModuleCode.Seo
      ? {
          userAgent: getUserAgentSchema(t, true),
        }
      : {}),
    ...(moduleCode === ModuleCode.Accessibility
      ? {
          userAgent: getUserAgentSchema(t),
          cookies: getCookiesSchema(t),
        }
      : {}),
    ...(moduleCode === ModuleCode.SiteSpeed
      ? {
          userAgent: getUserAgentSchema(t, true),
        }
      : {}),
  });
}
