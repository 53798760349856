import { ReportCategoryChartConfig } from "../types";
import { getAllPagesbyStatusCode } from "../../../../_common/charts/config/getAllPagesbyStatusCode";
import { getCrawlSourcesPagesIn } from "../../../../_common/charts/config/getCrawlSourcesPagesIn";
import { getCrawlSourcesPagesInTrend } from "../../../../_common/charts/config/getCrawlSourcesPagesInTrend";
import { getCrawledUrlTypeChart } from "../../../../_common/charts/config/getCrawledUrlTypeChart";
import { getHttpAndHttpsChart } from "../../../../_common/charts/config/getHttpAndHttpsChart";
import { getLinksBreakdownChart } from "../../../../_common/charts/config/getLinksBreakdownChart";
import { getUncrawledUrlsBreakdownChart } from "../../../../_common/charts/config/getUncrawledUrlsBreakdownChart";
import { getWebCrawlByLevel } from "../../../../_common/charts/config/getWebCrawlByLevel";
import { getSiteSpeedFailuresChart } from "../../../../_common/charts/config/getSiteSpeedFailuresChart";
import { getSiteSpeedFailuresTrend } from "../../../../_common/charts/config/getSiteSpeedFailuresTrend";

// category=summary
export const generateSummaryCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getSiteSpeedFailuresChart,
      getSiteSpeedFailuresTrend,
      getCrawledUrlTypeChart,
      getAllPagesbyStatusCode,
      getUncrawledUrlsBreakdownChart,
      getCrawlSourcesPagesIn,
      getCrawlSourcesPagesInTrend,
      getHttpAndHttpsChart,
      getLinksBreakdownChart,
      getWebCrawlByLevel,
    ],
  };
