import React from "react";
import { useTranslation, CopyIcon } from "@lumar/shared";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";

interface Props {
  value: string;
  className?: string;
}

export function CopyButton({ value, className }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const [copied, setCopied] = React.useState(false);

  const onClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <span className={classes.endMark}>
      <Tooltip title={copied ? t("copied") : t("copy")} arrow>
        <IconButton
          color="primary"
          onClick={onClick}
          className={clsx(classes.copyButton, className)}
        >
          {copied ? (
            <Check className={classes.copyIcon}></Check>
          ) : (
            <CopyIcon className={classes.copyIcon}></CopyIcon>
          )}
        </IconButton>
      </Tooltip>
    </span>
  );
}

const useStyles = makeStyles((theme) => ({
  copyButton: {
    position: "absolute",
    right: -24,
    bottom: -4,
    padding: 5,
    color: theme.palette.grey[500],
    "&:hover": { color: theme.palette.primary.main },
    visibility: "hidden",
  },
  copyIcon: {
    fontSize: 14,
  },
  endMark: {
    position: "relative",
  },
}));
