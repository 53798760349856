import { useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ResourceDetailData } from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";
import { AuditData } from "../data/useSiteSpeedAudits";
import { auditItemsMetricsGroupings } from "./auditItemsMetricsGroupings";
import { useParams } from "react-router-dom";
import { ReportEntity } from "../../../../report/Report.types";

export interface SiteSpeedAuditProps {
  data: ResourceDetailData;
  audit: AuditData;
}

export function SiteSpeedAudit({
  data,
  audit,
}: SiteSpeedAuditProps): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();
  const urlDigest = data.metrics["urlDigest"]?.value;
  const hasItems = Boolean(audit.metrics["exampleItems"]?.value);

  const reportTemplateCode = "crawl_site_speed_audit_items";

  const metricsGroupings = auditItemsMetricsGroupings[audit.id];

  return (
    <>
      <AuditDetails data={data} audit={audit} />
      {Boolean(urlDigest && hasItems) && (
        <ReportGrid
          reportInput={{
            crawlId,
            reportEntity: ReportEntity.ReportStat,
            reportTemplateCode,
            reportTypeCode: ReportTypeCode.Basic,
          }}
          baseFilter={{
            auditId: { eq: audit.id },
            urlDigest: { eq: urlDigest },
          }}
          tabsMode="onlyTotalRowsVisible"
          hideTaskMenu
          overwrites={{
            metricsGroupings,
          }}
          columnPersistanceKey={`${reportTemplateCode}-${audit.id}`}
        />
      )}
    </>
  );
}

function AuditDetails({ audit }: SiteSpeedAuditProps): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();

  const dispalyValueClass = (() => {
    switch (audit.metrics["auditResult"]?.value) {
      case "Failed":
        return classes.failed;
      case "Warning":
        return classes.warning;
      case "Needs Review":
        return classes.needsReview;
      default:
        return classes.passed;
    }
  })();

  const displayValue = audit.metrics["displayValue"]?.value;

  const config: MetricsGroupsConfig = [
    {
      name: (
        <>
          {t("siteSpeed.auditSummary", { auditTitle: audit.name })}
          {Boolean(displayValue) && (
            <span className={dispalyValueClass}>{` — ${displayValue}`}</span>
          )}
        </>
      ),
      items: [
        { code: "auditId" },
        { code: "description" },
        { code: "auditResult" },
        { code: "savingsScore" },
        { code: "savingsSecs" },
        { code: "savingsKib" },
        { code: "metricSavingsCls" },
        { code: "gscClicks" },
        { code: "pageviews" },
        { code: "productOfSavingsSecsAndPageviews" },
        { code: "productOfSavingsKibAndPageviews" },
      ],
    },
  ];

  return (
    <MetricsGroups
      metrics={audit.metrics}
      config={config}
      className={classes.summary}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  summary: {
    marginBottom: theme.spacing(2.25),
  },
  failed: {
    color: theme.palette.red[700],
    fontWeight: 400,
  },
  warning: {
    color: theme.palette.orange[700],
    fontWeight: 400,
  },
  needsReview: {
    color: theme.palette.grey[500],
    fontWeight: 400,
  },
  passed: {
    display: "none",
  },
}));
