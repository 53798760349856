import { Code, FileText, useTranslation } from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { SourcePresenter } from "../../metrics-value-presenter";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useUncrawledUrlsSections } from "./useUncrawledUrlsSections";

export function UncrawledUrls({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const config = useSummaryConfig(data.metrics);

  const { active } = useUncrawledUrlsSections();

  return (
    <ResourceDetailBase
      title={t("uncrawledUrlTitle")}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      {
        code: "url",
      },
      {
        code: "foundAtUrl",
      },
      {
        code: "foundAtSitemap",
      },
      {
        code: "rewriteChain",
      },
      {
        code: "foundIn",
        name: t("metricFoundIn"),
        value: <SourcePresenter {...getMetricProps("foundIn")} />,
      },
    ],
    cardDetails: [
      {
        code: "fileExtension",
        icon: FileText,
      },
      {
        code: "level",
        icon: Code,
      },
      {
        code: "restrictedReason",
        icon: Code,
      },
    ],
  };
}
