import { Project, UpdateProjectInput } from "../../../../../graphql";
import { RemoveUrlParamsSettings } from "../types";

type QueryData = Pick<Project, "urlRewriteQueryParameters">;

export function formatRemoveUrlParamsSettingsFrom(
  data?: QueryData | null,
): RemoveUrlParamsSettings {
  return {
    params: data?.urlRewriteQueryParameters ?? [],
  };
}

export function formatRemoveUrlParamsSettingsTo(
  formValues: RemoveUrlParamsSettings,
): Pick<UpdateProjectInput, "urlRewriteQueryParameters"> {
  return {
    urlRewriteQueryParameters: formValues.params,
  };
}
