import React from "react";

// Note: This workaround enables normal keyboard navigation within the grid
// while also enabling link activation when the cell is focused.
export function useActiveCell(label: string): {
  ref: (element: HTMLElement | null) => void;
} {
  const parentRef = React.useRef<
    { element: HTMLElement; handler: (e: KeyboardEvent) => void } | undefined
  >();
  function setParentAttributes(element: HTMLElement | null): void {
    if (parentRef.current) {
      parentRef.current.element.removeEventListener(
        "keydown",
        parentRef.current.handler,
      );
      parentRef.current = undefined;
    }

    if (!element || !element.parentElement) return;

    // eslint-disable-next-line fp/no-mutation
    element.parentElement.ariaRoleDescription = label;
    function handler(e: KeyboardEvent): void {
      if (e.key !== "Enter" && e.key !== " ") return;

      e.preventDefault();
      element?.click();
    }

    element.parentElement.addEventListener("keydown", handler);
    parentRef.current = { element: element.parentElement, handler };
  }

  return { ref: setParentAttributes };
}
