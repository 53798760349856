import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getSecurityBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("securityBreakdownChart.title"),
    description: t("securityBreakdownChart.description"),
    reportTemplateCodes: [
      "http_non_redirecting_to_https",
      "http_redirecting_to_https",
      "https_pages_without_hsts",
      "https_pages_with_hsts",
    ],
  })();
}
