import { MetricGrouping } from "../../../../report/report-rows/report-grid/ReportGrid.types";

export const auditItemsMetricsGroupings: Record<string, MetricGrouping[]> = {
  "bf-cache": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "failureType" },
        { code: "subItems" },
        { code: "subItemsCount" },
      ],
    },
  ],
  "no-document-write": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "sourceColumn" },
        { code: "sourceLine" },
        { code: "auditResult" },
      ],
    },
  ],
  "font-display": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
  ],
  "render-blocking-resources": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedSecs" }] },
  ],
  "uses-rel-preconnect": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
  ],
  "unminified-css": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "unminified-javascript": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "uses-text-compression": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "resourceUrl" },
        { code: "nodeNodeLabel" },
        { code: "totalBytes" },
        { code: "wastedKib" },
      ],
    },
  ],
  "uses-http2": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "protocol" }] },
  ],
  "uses-long-cache-ttl": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedKib" }] },
    {
      metrics: [
        { code: "cacheLifetimeMs" },
        { code: "totalBytes" },
        { code: "cacheHitProbability" },
      ],
    },
  ],
  "efficient-animated-content": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "modern-image-formats": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "offscreen-images": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "resourceUrl" }] },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "unused-css-rules": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "unused-javascript": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "uses-optimized-images": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "resourceUrl" },
        { code: "totalBytes" },
        { code: "wastedKib" },
      ],
    },
  ],
  "uses-responsive-images": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "resourceUrl" }] },
    { metrics: [{ code: "totalBytes" }, { code: "wastedKib" }] },
  ],
  "total-byte-weight": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "totalBytes" }] },
  ],
  "prioritize-lcp-image": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
    {
      metrics: [
        { code: "resourceUrl" },
        { code: "nodeNodeLabel" },
        { code: "wastedSecs" },
      ],
    },
  ],
  "lcp-lazy-loaded": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "resourceUrl" }, { code: "nodeNodeLabel" }] },
  ],
  "uses-passive-event-listeners": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "sourceColumn" },
        { code: "sourceLine" },
        { code: "auditResult" },
      ],
    },
  ],
  "mainthread-work-breakdown": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "durationMs" }] },
  ],
  "legacy-javascript": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "subItems" }, { code: "wastedKib" }] },
  ],
  "duplicated-javascript": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "transferSize" }, { code: "wastedKib" }] },
  ],
  "third-party-summary": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
    {
      metrics: [
        { code: "blockingTimeSecs" },
        { code: "transferSize" },
        { code: "tbtImpact" },
      ],
    },
  ],
  "dom-size": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    {
      metrics: [
        { code: "nodeNodeLabel" },
        { code: "statistic" },
        { code: "statisticValue" },
      ],
    },
  ],
  "bootup-time": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
    {
      metrics: [
        { code: "totalCpuTimeMs" },
        { code: "scriptEvaluationTimeMs" },
        { code: "scriptParseTimeMs" },
      ],
    },
  ],
  "long-tasks": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "wastedSecs" }] },
    { metrics: [{ code: "requestStartTime" }, { code: "durationMs" }] },
  ],
  "third-party-facades": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "transferSize" }, { code: "wastedSecs" }] },
  ],
  "layout-shift-elements": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "nodeNodeLabel" }, { code: "layoutShiftImpact" }] },
  ],
  "unsized-images": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "url" }] },
    { metrics: [{ code: "subItems" }, { code: "subItemsCount" }] },
  ],
  "non-composited-animations": [
    {
      metrics: [
        { code: "opportunityDigest" },
        { code: "element" },
        { code: "title" },
        { code: "url" },
      ],
    },
    { metrics: [{ code: "nodeNodeLabel" }] },
    { metrics: [{ code: "subItems" }, { code: "subItemsCount" }] },
  ],
};
