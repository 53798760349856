import {
  CrawlUrlAggregateDimension,
  GetDataExplorerMetricsQuery,
  ModuleCode,
} from "../graphql";
import { ConnectionFilter } from "../_common/connection-filtering/types";

export type Metric = NonNullable<
  GetDataExplorerMetricsQuery["report"]
>["reportTemplate"]["datasource"]["metrics"][0];

export enum DataExplorerAggregationCalculation {
  Avg = "avg",
  Sum = "sum",
  Count = "count",
}

export interface Column {
  metric: { code: string };
  aggregationCalculation: DataExplorerAggregationCalculation;
}

export type DataExplorerTableConfig = {
  dimension: DataExplorerDimensionCode;
  columns: Column[];
  orderByColumn: string;
  orderDirection: "asc" | "desc";
};

export type DataExplorerSimpleRow = Record<string, unknown> & {
  segmentId?: string;
  getFilter?: () => ConnectionFilter | undefined;
};

export type DataExplorerExpandableRow = DataExplorerSimpleRow & {
  level: number;
  hasChildren: boolean;
  isExpanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

export type DataExplorerExpandableLoadMoreRow = {
  loadMore: () => void;
  isMoreLoading: boolean;
};

export type DataExplorerRow =
  | DataExplorerSimpleRow
  | DataExplorerExpandableRow
  | DataExplorerExpandableLoadMoreRow
  | { isLoading?: boolean };

export type DataExplorerDimension = {
  name: string;
  code: DataExplorerDimensionCode;
};

export type DataExplorerDimensionCode =
  | CrawlUrlAggregateDimension
  | "segment"
  | "path"
  | "breadcrumb";

export interface DataExplorerModuleConfig {
  moduleCode: ModuleCode;
  primaryDimensionsCodes: DataExplorerDimensionCode[];
  excludedDimensionsCodes?: DataExplorerDimensionCode[];
  exclusiveDimensionsCodes?: DataExplorerDimensionCode[];
  defaultTableConfig: DataExplorerTableConfig;
}
