import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../data/types";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 400,
    verticalAlign: "middle",
  },
  fullWidth: {
    width: "100%",
  },
}));

interface Props extends MetricsValuePresenterProps {
  fullWidth?: boolean;
}

export function ImagePresenter({ value, fullWidth }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <img
      className={clsx({
        [classes.image]: true,
        [classes.fullWidth]: fullWidth,
      })}
      src={value}
    />
  );
}
