import { ReportCategoryChartConfig } from "../types";
import { getChangedMetricsContentTrend } from "../../../../_common/charts/config/getChangedMetricsContentTrend";
import { getContentIssuesChart } from "../../../../_common/charts/config/getContentIssuesChart";
import { getContentIssuesChartTrend } from "../../../../_common/charts/config/getContentIssuesChartTrend";
import { getContentTypeBreakdownChart } from "../../../../_common/charts/config/getContentTypeBreakdownChart";
import { getContentTypeBreakdownTrend } from "../../../../_common/charts/config/getContentTypeBreakdownTrend";
import { getMobileContentIssuesChart } from "../../../../_common/charts/config/getMobileContentIssuesChart";
import { getMobileContentIssuesTrend } from "../../../../_common/charts/config/getMobileContentIssuesTrend";
import { getRenderingIssuesChart } from "../../../../_common/charts/config/getRenderingIssuesChart";
import { getRenderingIssuesTrend } from "../../../../_common/charts/config/getRenderingIssuesTrend";

export const generateRankabilityPageContentCategory: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getContentIssuesChart,
      getContentIssuesChartTrend,
      getChangedMetricsContentTrend,
      getMobileContentIssuesChart,
      getMobileContentIssuesTrend,
      getRenderingIssuesChart,
      getRenderingIssuesTrend,
      getContentTypeBreakdownChart,
      getContentTypeBreakdownTrend,
    ],
  };
