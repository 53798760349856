import React from "react";

import Link from "@material-ui/core/Link";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Formik } from "formik";
import { isString, truncate, uniq } from "lodash";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

import {
  getApiProjectId,
  getRawCrawlId,
  getRawProjectId,
  Snackbar,
  Trans,
  Typography as BlueTypography,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { ModuleCode, useCreateSegmentMutation } from "../../graphql";
import { assert } from "../../_common/assert";
import { mapDatasourceConnectionFilterToOrFormFilters } from "../../_common/connection-filtering/filter-or-rule-field-array/mapDatasourceConnectionFilterToOrFormFilters";
import { mapOrFormFiltersToDatasourceConnectionFilter } from "../../_common/connection-filtering/filter-or-rule-field-array/mapOrFormFiltersToDatasourceConnectionFilter";
import {
  getDefaultFilterMetric,
  getFormFilter,
} from "../../_common/connection-filtering/helpers";
import { NarrowMetric } from "../../_common/connection-filtering/types";
import { Routes } from "../../_common/routing/routes";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { getCrawlStatus } from "../helpers/getCrawlStatus";
import { SegmentsListData } from "../manage-segment/data/useSegmentsList";
import { SegmentDialog } from "../segment-dialog-form/SegmentDialog";
import {
  SegmentForm,
  SegmentFormProps,
  SegmentFormValues,
} from "../segment-dialog-form/SegmentForm";
import { useCopySegmentDialogValidationSchema } from "./useCopySegmentDialogValidationSchema";
import { useProjectSegmentGroups } from "../manage-segment/data/useProjectSegmentGroups";
import { SegmentDialogFooter } from "../segment-dialog-form/SegmentDialogFooter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summary: {
      color: theme.palette.grey[800],
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  }),
);
export interface CopySegmentDialogProps {
  closeDialog: () => void;
  segments: SegmentsListData["segments"];
  metrics: NarrowMetric[];
  crawlStatusData: SegmentsListData | undefined;
  maxSegmentCount: number;
  moduleCode: ModuleCode;
}

export function CopySegmentDialog(props: CopySegmentDialogProps): JSX.Element {
  const classes = useStyles();
  const {
    segments,
    closeDialog,
    crawlStatusData,
    metrics,
    maxSegmentCount,
    moduleCode,
  } = props;
  const history = useHistory();
  const { accountId, projectId, crawlId } = useGenericParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation("copySegmentDialog");

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const base64ProjectId = isNaN(Number(projectId))
    ? projectId
    : getApiProjectId(projectId);

  const [createSegment] = useCreateSegmentMutation({
    fetchPolicy: "no-cache",
    context: {
      includeInBatch: true,
    },
  });

  const validationSchema = useCopySegmentDialogValidationSchema(
    metrics,
    maxSegmentCount,
  );

  const handleSubmit = async (values: SegmentFormValues): Promise<void> => {
    const { projectDestination, segments } = values;

    // NOTE: Validation should not allow for null value to submit and get here - Saul.
    assert(projectDestination);

    await Promise.all(
      segments.map(({ segmentName, group }, index) =>
        createSegment({
          variables: {
            input: {
              projectId: projectDestination.id,
              name: segmentName,
              group,
              // FIXME: Formik doesn't respect `transform` and `trim` in yup schema.
              // Formik devs will fix it in this pr: https://github.com/jaredpalmer/formik/pull/2255
              // This is why `schema.cast` has to be used.
              crawlUrlFilter: mapOrFormFiltersToDatasourceConnectionFilter(
                validationSchema.cast(values)?.segments[index].rules || [],
              ),
            },
          },
          update: (cache) =>
            cache.modify({
              id: cache.identify({
                __typename: "Project",
                id: getApiProjectId(projectDestination.id),
              }),
              fields: {
                segments: (_, details) => details.DELETE,
              },
            }),
        }),
      ),
    );

    const crawl = crawlStatusData?.crawl;
    const { crawlFinalising, crawlInProgress } = getCrawlStatus(crawl);

    const handleProjectLinkClick = (): void => {
      closeSnackbar();

      const { lastFinishedCrawl } = projectDestination;

      history.push(
        Routes.SegmentManager.getUrl({
          accountId,
          projectId: getRawProjectId(projectDestination.id),
          crawlId: lastFinishedCrawl
            ? getRawCrawlId(lastFinishedCrawl.id)
            : "0",
        }),
      );
    };

    const copyToCurrentProject = projectDestination.id === base64ProjectId;
    const copySingleSegment = segments.length === 1;

    const truncatedSegmentName = truncate(segments[0].segmentName);

    // FIXME: We currently show the snackbar regardless of whether segment
    // creation was successful, before segment creation mutation has resolved.
    // We should change these to only show the correct snackbar once segment
    // creation has definately succeeded - Saul.
    if (copyToCurrentProject && copySingleSegment) {
      if (crawlInProgress && !crawlFinalising) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("segmentCreated", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          >
            <BlueTypography variant="caption">
              {t("dataForSegmentGeneration")}
            </BlueTypography>
          </Snackbar>,
        );
      } else {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("segmentCreated", {
              truncatedSegmentName: truncatedSegmentName,
            })}
          />,
        );
      }
    }

    if (copyToCurrentProject && !copySingleSegment) {
      if (crawlInProgress && !crawlFinalising) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("createdSegmentsNumber", {
              count: segments.length,
            })}
          >
            <BlueTypography variant="caption">
              {t("dataForSegmentGeneration_plural")}
            </BlueTypography>
          </Snackbar>,
        );
      } else {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("createdSegmentsNumber", {
              count: segments.length,
            })}
          />,
        );
      }
    }

    if (!copyToCurrentProject) {
      const truncatedProjectName = truncate(projectDestination.name, {
        length: 60,
      });

      if (copySingleSegment) {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={
              <Trans
                ns="copySegmentDialog"
                i18nKey="truncatedSegmentsCreatedIn"
                components={{
                  truncatedSegmentName: truncatedSegmentName,
                  truncatedProjectName: truncatedProjectName,
                  projectLink: (
                    <Link onClick={() => handleProjectLinkClick()} />
                  ),
                }}
              ></Trans>
            }
          />,
        );
      } else {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={
              <Trans
                ns="copySegmentDialog"
                i18nKey="segmentsCreatedIn"
                components={{
                  segmentsLength: segments.length,
                  truncatedProjectName: truncatedProjectName,
                  projectLink: (
                    <Link onClick={() => handleProjectLinkClick()} />
                  ),
                }}
              ></Trans>
            }
          />,
        );
      }
    }
    closeDialog();
  };

  const segmentsCount = props.segments ? props.segments.length : 0;

  const groups = segments.map((x) => x.group).filter(isString);

  const defaultFilter = React.useMemo(
    () => getFormFilter(getDefaultFilterMetric(props.metrics)),
    [props.metrics],
  );

  return (
    <Formik<SegmentFormValues>
      initialValues={{
        projectDestination: null,
        segments: segments.map(({ name, group, filter }) => {
          return {
            segmentName: name,
            group: group || null,
            rules: mapDatasourceConnectionFilterToOrFormFilters(filter),
          };
        }),
      }}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, validateForm, submitForm }) => (
        <SegmentDialog
          dialogTitle={t("dialogTitle", { count: segmentsCount })}
          closeDialog={closeDialog}
          footer={() => (
            <SegmentDialogFooter
              onCancel={closeDialog}
              onSubmit={submitForm}
              submitBtnText={t("createSegment", { count: segmentsCount })}
            >
              <Typography variant="subtitle3Medium" className={classes.summary}>
                {t("createSegmentsIn", {
                  count: values.segments.length,
                  projectDestinationName: values.projectDestination?.name,
                })}
              </Typography>
            </SegmentDialogFooter>
          )}
        >
          <SegmentFormInner
            showProjectField
            values={values}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            metrics={props.metrics}
            defaultFilter={defaultFilter}
            groups={groups}
            moduleCode={moduleCode}
          />
        </SegmentDialog>
      )}
    </Formik>
  );
}

function SegmentFormInner(props: SegmentFormProps): JSX.Element {
  const projectId = props.values.projectDestination?.id;

  const { loading, groups } = useProjectSegmentGroups({ projectId });
  const allGroups = uniq([...props.groups, ...groups]);

  return <SegmentForm {...props} groups={allGroups} groupsLoading={loading} />;
}
