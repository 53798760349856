import { useParams } from "react-router-dom";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ReportEntity } from "../../../../report/Report.types";

export function IssueDetailsInstances(): JSX.Element {
  const { resourceId, crawlId } = useParams<{
    crawlId: string;
    resourceId: string;
  }>();

  return (
    <ReportGrid
      reportInput={{
        crawlId,
        reportEntity: ReportEntity.ReportStat,
        reportTemplateCode: "accessibility_issue_instances",
        reportTypeCode: ReportTypeCode.Basic,
      }}
      baseFilter={{ issueDigest: { eq: resourceId } }}
      tabsMode="onlyTotalRowsVisible"
      hideTaskMenu
    />
  );
}
