import { Snackbar, SnackbarProps, useTranslation } from "@lumar/shared";
import { SnackbarKey, useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useUnArchiveCrawlMutation } from "../graphql";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";

export function useAutoUnArchive(): void {
  const { t } = useTranslation("crawlsList");
  const searchParams = useURLSearchParams();
  const history = useHistory();

  const [unarchive] = useUnArchiveCrawlMutation({
    refetchQueries: ["CrawlsListTable"],
    awaitRefetchQueries: true,
  });

  const { enqueueSnackbar } = useSnackbar();
  const showSnack = (
    t?: string,
    variant?: SnackbarProps["variant"],
  ): SnackbarKey => {
    return enqueueSnackbar(<Snackbar variant={variant} title={t} />);
  };

  if (searchParams.has("unarchive")) {
    const crawlId = searchParams.get("unarchive");
    searchParams.set("tab", "history");
    searchParams.delete("unarchive");
    history.replace({ search: searchParams.toString() });

    (async () => {
      try {
        await unarchive({ variables: { crawlId } });
        showSnack(t("tableColumns.unarchiveMessage"));
      } catch (e) {
        showSnack((e as Error).message, "error");
      }
    })();
  }
}
