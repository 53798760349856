import { MetricsValuePresenterProps } from "../../../../../resource-detail/data/types";
import { AccessibilityIssuesCountByRuleIdPresenter } from "../../../../../resource-detail/metrics-value-presenter";

export function AccessibilityIssuesCountByRuleIdInterpreter(
  props: MetricsValuePresenterProps & {
    isGridView?: boolean;
  },
): JSX.Element {
  if (props.isGridView)
    return (
      <>
        {Array.isArray(props.value)
          ? props.value.map((x) => `${x.rule_id}: ${x.issues_count}`).join(", ")
          : "-"}
      </>
    );

  return <AccessibilityIssuesCountByRuleIdPresenter {...props} />;
}
