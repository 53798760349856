import { useTranslation } from "@lumar/shared";
import React from "react";
import { RunningCrawlQuery } from "../../../graphql";
import { RunningCrawl } from "../../useProjectPoller";
import { ProgressControlsContainer } from "../_common/progress-controls-container/ProgressControlsContainer";
import { CrawlControlsListItem } from "./crawl-controls-list-item/CrawlControlsListItem";
import { useCrawlControlsListItems } from "./crawlControlsConfig";

export function CrawlControlsList({
  project,
  runningCrawl,
}: {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");

  return (
    <ProgressControlsContainer header={t("crawlControls.title")}>
      {useCrawlControlsListItems(project, runningCrawl).map((item) => (
        <CrawlControlsListItem
          key={item.label}
          project={project}
          runningCrawl={runningCrawl}
          {...item}
        />
      ))}
    </ProgressControlsContainer>
  );
}
