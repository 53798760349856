import React from "react";
import {
  EmptyState,
  SmileySad,
  useAccountGuard,
  useCrawlGuard,
  useProjectGuard,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Redirect, useParams } from "react-router-dom";

import { UpgradeToDataExplorerMessage } from "./upgrade-to-data-explorer-message/UpgradeToDataExplorerMessage";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { CrawlSelector } from "../crawl-selector/CrawlSelector";
import { Routes } from "../_common/routing/routes";
import { DimensionSelect } from "./DimensionSelect";
import { CrawlSettingsComparison } from "../report/report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { DataExplorerInfoTooltip } from "./DataExplorerInfoTooltip";
import { Paper, makeStyles } from "@material-ui/core";
import { CustomSkeleton } from "../_common/CustomSkeleton";
import {
  DataExplorerMetricsData,
  useDataExplorerMetrics,
} from "./data/useDataExplorerMetrics";
import { useTableConfig } from "./useTableConfig";
import { getDataExplorerDimensionTable } from "./DataExplorerDimensions";

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(2.5) },
  loading: {
    background: theme.palette.common.white,
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
}));

export function DataExplorer(): JSX.Element {
  const { projectId, accountId } = useParams<{
    projectId: string;
    accountId: string;
  }>();

  useAccountGuard();
  useProjectGuard();
  useCrawlGuard();

  const { t } = useTranslation("dataExplorer");
  const classes = useStyles();

  const { hasAddon } = useSession();

  const { loading, error, data } = useDataExplorerMetrics();

  const crawlSelectorRoute = (crawlId: string): string =>
    Routes.DataExplorer.getUrl({
      accountId,
      projectId,
      crawlId,
    });

  if (data?.isCrawlIncomplete) {
    return (
      <Redirect
        exact
        to={Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "progress",
        })}
      />
    );
  }

  if (!hasAddon("data-explorer")) {
    return (
      <>
        <TopNavigation />
        <UpgradeToDataExplorerMessage />
      </>
    );
  }

  if (loading) {
    return (
      <>
        <TopNavigation
          title={t("pageTitle")}
          rightComponent={<DataExplorerInfoTooltip />}
          breadcrumbs={[{ label: t("pageTitle") }]}
          actions={[
            <CustomSkeleton
              key="dimensionSelectLoading"
              animation="wave"
              width="230px"
              height="36px"
              variant="rect"
            />,
            <CrawlSelector
              key="crawlSelector"
              getPath={(crawlId) => crawlSelectorRoute(crawlId)}
            />,
            <CrawlSettingsComparison key="crawl-settings-comparison" />,
          ]}
          availableForShare
        />
        <div className={classes.root}>
          <CustomSkeleton
            data-testid="global-filter-skeleton"
            variant="rect"
            height="121px"
            animation="wave"
            className={classes.loading}
          />
        </div>
      </>
    );
  }

  if (error !== undefined) {
    return (
      <>
        <TopNavigation
          title={t("pageTitle")}
          rightComponent={<DataExplorerInfoTooltip />}
          breadcrumbs={[{ label: t("pageTitle") }]}
          actions={[
            <CrawlSelector
              key="crawlSelector"
              getPath={(crawlId) => crawlSelectorRoute(crawlId)}
            />,
            <CrawlSettingsComparison key="crawl-settings-comparison" />,
          ]}
          availableForShare
        />
        <div className={classes.root}>
          <Paper>
            <EmptyState
              icon={
                <SmileySad fontSize="large" className={classes.errorIcon} />
              }
              title={t("errorTitle")}
              description={error}
              height={400}
            />
          </Paper>
        </div>
      </>
    );
  }

  if (data === null) {
    return (
      <>
        <TopNavigation
          title={t("pageTitle")}
          rightComponent={<DataExplorerInfoTooltip />}
          breadcrumbs={[{ label: t("pageTitle") }]}
          actions={[
            <CrawlSelector
              key="crawlSelector"
              getPath={(crawlId) => crawlSelectorRoute(crawlId)}
            />,
            <CrawlSettingsComparison key="crawl-settings-comparison" />,
          ]}
          availableForShare
        />
        <div className={classes.root}>
          <Paper>
            <EmptyState
              icon={
                <SmileySad fontSize="large" className={classes.errorIcon} />
              }
              title={t("errorTitle")}
              description={t("moduleNotFound")}
              height={400}
            />
          </Paper>
        </div>
      </>
    );
  }

  return (
    <DataExplorerInner data={data} crawlSelectorRoute={crawlSelectorRoute} />
  );
}

function DataExplorerInner({
  data,
  crawlSelectorRoute,
}: {
  data: DataExplorerMetricsData;
  crawlSelectorRoute: (crawlId: string) => string;
}): JSX.Element {
  const { projectId, accountId, crawlId } = useParams<{
    projectId: string;
    accountId: string;
    crawlId: string;
  }>();
  const { t } = useTranslation("dataExplorer");
  const classes = useStyles();

  const { tableConfig, setTableConfig } = useTableConfig({
    key: data.tableConfigKey,
    defaultConfig: data.defaultTableConfig,
    dimensions: data.secondaryDimensions,
    primaryDimensions: data.primaryDimensions,
  });

  const Table = getDataExplorerDimensionTable(tableConfig.dimension);

  const dimensionName =
    data.secondaryDimensions.find((x) => x.code === tableConfig.dimension)
      ?.name || tableConfig.dimension;

  return (
    <>
      <TopNavigation
        title={t("pageTitle")}
        rightComponent={<DataExplorerInfoTooltip />}
        breadcrumbs={[{ label: t("pageTitle") }]}
        actions={[
          <DimensionSelect
            key="dimensionSelect"
            primaryDimensions={data.primaryDimensions}
            secondaryDimensions={data.secondaryDimensions}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
          />,
          <CrawlSelector
            key="crawlSelector"
            getPath={(crawlId) => crawlSelectorRoute(crawlId)}
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        availableForShare
      />
      <div className={classes.root}>
        <Table
          accountId={accountId}
          projectId={projectId}
          crawlId={crawlId}
          tableConfig={tableConfig}
          setTableConfig={setTableConfig}
          aggregableMetrics={data.aggregableMetrics}
          crawlTypes={data.crawlTypes}
          dimensionColumnName={dimensionName}
        />
      </div>
    </>
  );
}
