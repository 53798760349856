import React, { useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Typography } from "@lumar/shared";

import { activeStatuses } from "../../constants";
import { useTimeDurationFormatter } from "../../../_common/locale/format-functions/useTimeDurationFormatter";

export function DurationCell(props: GridRenderCellParams): JSX.Element {
  const crawlInProgress = activeStatuses.includes(props.row.statusEnum);

  const formatTime = useTimeDurationFormatter();

  const [currentTime, setCurrentTime] = useState<Date | undefined>(new Date());
  React.useEffect(() => {
    if (!crawlInProgress) return;

    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, [crawlInProgress]);

  const startTime = props.row.createdAt
    ? new Date(props.row.createdAt)
    : undefined;
  const endTime = props.row.finishedAt
    ? new Date(props.row.finishedAt)
    : crawlInProgress
      ? currentTime
      : undefined;

  return (
    <Typography data-testid="duration-value">
      {formatTime(startTime, endTime)}
    </Typography>
  );
}
