import { GridColumns } from "@mui/x-data-grid-pro";
import { ColumnState } from "./columnsState";

export function applyColumnStates(
  columns: GridColumns,
  storedStates: ColumnState[],
): GridColumns {
  // eslint-disable-next-line fp/no-mutating-methods
  return columns
    .map((column) => {
      const storedState = storedStates.find((x) => x.code === column.field);
      if (!storedState) return column;

      return {
        ...column,
        hide: storedState.hide,
        width: storedState.width ?? column.width,
      };
    })
    .sort(
      (a, b) =>
        storedStates.findIndex((x) => x.code === a.field) -
        storedStates.findIndex((x) => x.code === b.field),
    );
}
