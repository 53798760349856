/* eslint-disable fp/no-mutating-methods */
import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import {
  BreadcrumbItem,
  Breadcrumbs,
  getRawAccountId,
  moduleIconConfig,
  useSession,
} from "@lumar/shared";

import { CustomSkeleton } from "../CustomSkeleton";
import { Routes } from "../routing/routes";
import { useGenericParams } from "../routing/useGenericParams";
import { ModuleCode } from "../../graphql";

const useStyles = makeStyles((theme) =>
  createStyles({
    skeleton: {
      transform: "none",
      width: "20%",
      height: "26px",
      background: theme.palette.grey[200],
    },
    breadcrumbs: {
      overflow: "hidden",
    },
  }),
);

export interface PageBreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[] | undefined;
  projectName?: string;
  moduleCode?: ModuleCode;
  crawlId?: string;
  loading?: boolean;
}

export function PageBreadcrumbs({
  breadcrumbs = [],
  projectName,
  moduleCode,
  crawlId: providedCrawlId,
  loading,
}: PageBreadcrumbsProps): JSX.Element {
  const { accountId, projectId, crawlId: urlCrawlId } = useGenericParams();
  const classes = useStyles();

  const {
    account: { id: sessionAccountId },
  } = useSession();

  const crawlId = providedCrawlId ?? urlCrawlId;

  if (loading) {
    return (
      <CustomSkeleton
        data-testid="top-nav-breadcrumbs-loading"
        animation="wave"
        className={classes.skeleton}
      />
    );
  }

  const homeLink = Routes.Projects.getUrl({
    accountId: getRawAccountId(sessionAccountId),
  });

  const crawlOverviewLink =
    idIsValid(accountId) && idIsValid(projectId) && idIsValid(crawlId)
      ? Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          /**
           * We add the default type to dashboard to avoid UI freezing.
           * The UI freezes when the user navigates to the crawl overview
           * without a type, as it first try to load the reports section
           * and then the dashboard section.
           * @author Alex Sánchez
           */
          type: "dashboard",
        })
      : undefined;

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      label: "",
      link: homeLink,
      "data-testid": "top-nav-breadcrumb-home",
    },
  ];

  if (projectName) {
    breadcrumbItems.push({
      label: projectName,
      icon: moduleCode && moduleIconConfig.get(moduleCode),
      link: crawlOverviewLink,
      "data-testid": "project-overview",
    });
  }

  return (
    <Breadcrumbs
      breadcrumbItems={[...breadcrumbItems, ...breadcrumbs]}
      data-testid="top-nav-breadcrumbs"
      className={classes.breadcrumbs}
    />
  );
}

function idIsValid(value: string | undefined): value is string {
  return Boolean(value && Number(value) > 0);
}
