/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getExperienceBrokenChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("experienceBrokenChart.title"),
    description: () => t("experienceBrokenChart.description"),
    reportTemplateCodesOrder: [
      "empty_pages",
      "broken_images",
      "all_broken_links",
      "broken_links_external",
    ],
    reportStatFilter: (report) => {
      return [
        "empty_pages",
        "broken_images",
        "all_broken_links",
        "broken_links_external",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
