import { useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function CrawlUrlPageTypePresenter({
  metrics,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  if (metrics["js"]?.value === true) return <>{t("pageTypeJs")}</>;
  if (metrics["css"]?.value === true) return <>{t("pageTypeCss")}</>;
  if (metrics["isImage"]?.value === true) return <>{t("pageTypeImg")}</>;
  return <>{t("pageTypeHtml")}</>;
}
