import { Code, FileText, FolderNotchOpen, useTranslation } from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../data/types";
import { StatusCodeSimplePresenter } from "../../metrics-value-presenter";
import { ResourceDetailBase } from "../../ResourceDetail";
import {
  Indicator,
  ResourceDetailSummary,
  ResourceDetailSummaryConfig,
} from "../../ResourceDetailSummary";
import { useSitemapDetailsSections } from "./useSitemapDetailsSections";

export function SitemapDetails({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const config = useSummaryConfig(data.metrics);

  const { active } = useSitemapDetailsSections();

  return (
    <ResourceDetailBase
      title={t("sitemapTitle")}
      navigationReportTemplate={data.navigationReportTemplate}
    >
      <ResourceDetailSummary metrics={data.metrics} config={config} />
      <active.component data={data} />
    </ResourceDetailBase>
  );
}

function useSummaryConfig(metrics: Metrics): ResourceDetailSummaryConfig {
  const { t } = useTranslation("resourceDetail");

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  return {
    summary: [
      {
        code: "url",
      },
      {
        code: "restrictedReason",
      },
    ],
    cardDetails: [
      {
        code: "pageType",
        name: t("pageType"),
        value: t("pageTypeSitemap"),
        icon: FileText,
      },
      {
        code: "httpStatusCode",
        icon: Code,
        value: (
          <StatusCodeSimplePresenter {...getMetricProps("httpStatusCode")} />
        ),
        indicator: getHttpStatusCodeIndicator(
          String(metrics["httpStatusCode"]?.value),
        ),
      },
      {
        code: "urlCount",
        icon: Code,
      },
      {
        code: "sitemapType",
        icon: FolderNotchOpen,
      },
    ],
  };
}

function getHttpStatusCodeIndicator(status: string): Indicator {
  if (status.startsWith("2")) return "success";
  if (status.startsWith("3")) return "warning";
  return "error";
}
