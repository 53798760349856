import { Project, UpdateProjectInput } from "../../../../../graphql";
import {
  InternalExternalType,
  ResourceMode,
  ResourceRestrictionsSettings,
} from "../types";

type QueryData = Pick<
  Project,
  | "crawlNonHtml"
  | "crawlStylesheetsInternal"
  | "crawlStylesheetsExternal"
  | "crawlScriptsInternal"
  | "crawlScriptsExternal"
  | "crawlImagesInternal"
  | "crawlImagesExternal"
  | "ignoreInvalidSSLCertificate"
>;

export function formatResourceRestrictionsSettingsForm(
  data?: QueryData | null,
): ResourceRestrictionsSettings {
  function getMode(internal?: boolean, external?: boolean): ResourceMode {
    if (internal && !external) return ResourceMode.Internal;
    if (!internal && external) return ResourceMode.External;
    if (internal && external) return ResourceMode.InternalExternal;

    return ResourceMode.InternalExternal;
  }

  function getInternalExternalType(
    internal?: boolean,
    external?: boolean,
  ): InternalExternalType {
    return {
      enabled: Boolean(internal || external),
      mode: getMode(internal, external),
    };
  }

  return {
    crawlNonHtmlUrls: data?.crawlNonHtml ?? false,
    crawlCssResources: getInternalExternalType(
      data?.crawlStylesheetsInternal,
      data?.crawlStylesheetsExternal,
    ),
    crawlJsResources: getInternalExternalType(
      data?.crawlScriptsInternal,
      data?.crawlScriptsExternal,
    ),
    crawlImageResources: getInternalExternalType(
      data?.crawlImagesInternal,
      data?.crawlImagesExternal,
    ),
    ignoreInvalidSSLCertificate: data?.ignoreInvalidSSLCertificate ?? false,
  };
}

export function formatResourceRestrictionsSettingsTo(
  formValues: ResourceRestrictionsSettings,
): Pick<
  UpdateProjectInput,
  | "crawlNonHtml"
  | "crawlStylesheetsInternal"
  | "crawlStylesheetsExternal"
  | "crawlScriptsInternal"
  | "crawlScriptsExternal"
  | "crawlImagesInternal"
  | "crawlImagesExternal"
  | "ignoreInvalidSSLCertificate"
> {
  return {
    crawlNonHtml: formValues.crawlNonHtmlUrls,

    crawlStylesheetsInternal:
      formValues.crawlCssResources.enabled &&
      (formValues.crawlCssResources.mode === ResourceMode.Internal ||
        formValues.crawlCssResources.mode === ResourceMode.InternalExternal),
    crawlStylesheetsExternal:
      formValues.crawlCssResources.enabled &&
      (formValues.crawlCssResources.mode === ResourceMode.External ||
        formValues.crawlCssResources.mode === ResourceMode.InternalExternal),

    crawlScriptsInternal:
      formValues.crawlJsResources.enabled &&
      (formValues.crawlJsResources.mode === ResourceMode.Internal ||
        formValues.crawlJsResources.mode === ResourceMode.InternalExternal),
    crawlScriptsExternal:
      formValues.crawlJsResources.enabled &&
      (formValues.crawlJsResources.mode === ResourceMode.External ||
        formValues.crawlJsResources.mode === ResourceMode.InternalExternal),

    crawlImagesInternal:
      formValues.crawlImageResources.enabled &&
      (formValues.crawlImageResources.mode === ResourceMode.Internal ||
        formValues.crawlImageResources.mode === ResourceMode.InternalExternal),
    crawlImagesExternal:
      formValues.crawlImageResources.enabled &&
      (formValues.crawlImageResources.mode === ResourceMode.External ||
        formValues.crawlImageResources.mode === ResourceMode.InternalExternal),

    ignoreInvalidSSLCertificate: formValues.ignoreInvalidSSLCertificate,
  };
}
