import { Typography, useDateFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function DateResolvedCell({ value }: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const dateFormatter = useDateFormatter();

  const date = typeof value === "string" ? new Date(value) : undefined;
  const dateValue = !date || isNaN(date.getTime()) ? undefined : date;

  return (
    <Typography variant="caption" className={classes.text}>
      {dateValue ? dateFormatter(dateValue, { dateStyle: "medium" }) : ""}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[500],
  },
}));
