import { Project, UpdateProjectInput } from "../../../../../graphql";
import { LinkValidationSettings } from "../types";

type QueryData = Pick<
  Project,
  | "crawlDisallowedUrls1stLevel"
  | "crawlNotIncluded1stLevel"
  | "crawlHyperlinksExternal"
  | "crawlRelCanonicalsExternal"
  | "crawlRelNextPrevExternal"
  | "crawlRelMobileExternal"
  | "crawlRelAmphtmlExternal"
  | "crawlRelHreflangsExternal"
>;

export function formatLinkValidationSettingsForm(
  data?: QueryData | null,
): LinkValidationSettings {
  return {
    disallowedLinks: data?.crawlDisallowedUrls1stLevel ?? false,
    excludedLinks: data?.crawlNotIncluded1stLevel ?? false,
    externalAnchorLinks: data?.crawlHyperlinksExternal ?? false,
    externalCanonicalLinks: data?.crawlRelCanonicalsExternal ?? false,
    externalPaginationLinks: data?.crawlRelNextPrevExternal ?? false,
    externalMobileAltLinks: data?.crawlRelMobileExternal ?? false,
    externalAmphtmlLinks: data?.crawlRelAmphtmlExternal ?? false,
    externalHreflangLinks: data?.crawlRelHreflangsExternal ?? false,
  };
}

export function formatLinkValidationSettingsTo(
  formValues: LinkValidationSettings,
): Pick<
  UpdateProjectInput,
  | "crawlDisallowedUrls1stLevel"
  | "crawlNotIncluded1stLevel"
  | "crawlHyperlinksExternal"
  | "crawlRelCanonicalsExternal"
  | "crawlRelNextPrevExternal"
  | "crawlRelMobileExternal"
  | "crawlRelAmphtmlExternal"
  | "crawlRelHreflangsExternal"
> {
  return {
    crawlDisallowedUrls1stLevel: formValues.disallowedLinks,
    crawlNotIncluded1stLevel: formValues.excludedLinks,
    crawlHyperlinksExternal: formValues.externalAnchorLinks,
    crawlRelCanonicalsExternal: formValues.externalCanonicalLinks,
    crawlRelNextPrevExternal: formValues.externalPaginationLinks,
    crawlRelMobileExternal: formValues.externalMobileAltLinks,
    crawlRelAmphtmlExternal: formValues.externalAmphtmlLinks,
    crawlRelHreflangsExternal: formValues.externalHreflangLinks,
  };
}
