import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { GetAggregateChartDataDocument, ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgCruxLCPTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    inputSource: "GqlQuery",
    supportedModules: [ModuleCode.SiteSpeed],
    icon: "crux",
    unitDescription: t("unitDescription.speed"),
    title: () => t("siteSpeedCruxLCPMetricTrend.tile_title"),
    description: () => t("siteSpeedCruxLCPMetricTrend.description"),
    descriptionTitle: () =>
      t("siteSpeedCruxLCPMetricTrend.tile_descriptionTitle"),
    noDataMessage: t("noCruxData"),
    gqlDocument: GetAggregateChartDataDocument,
    gqlVariables: (crawlContext) => {
      return {
        inputs: [
          {
            crawlId: crawlContext.crawl.rawID,
            segmentId: crawlContext.selectedCrawlSegment?.segment?.rawID,
            reportTemplateCode: "crux_domain_summary:crux_lcp_weighted_avg",
          },
        ],
      };
    },
  };
}
