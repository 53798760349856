import { Project, UpdateProjectInput } from "../../../../../graphql";
import { CustomDNSSettings } from "../types";

type QueryData = Pick<Project, "customDns">;

export function formatCustomDnsSettingsFrom(
  data?: QueryData | null,
): CustomDNSSettings {
  return {
    customDns:
      data?.customDns?.map((dns) => ({
        hostName: dns.hostname,
        ipAddress: dns.ipAddress,
      })) ?? [],
  };
}

export function formatCustomDnsSettingsTo(
  formValues: CustomDNSSettings,
): Pick<UpdateProjectInput, "customDns"> {
  return {
    customDns: formValues.customDns.map((custom) => ({
      hostname: custom.hostName,
      ipAddress: custom.ipAddress,
    })),
  };
}
