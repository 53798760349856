import React, { useState } from "react";

import { ChartIconButton, Snackbar, ZoomOutMap } from "@lumar/shared";
import GetAppIcon from "@material-ui/icons/GetApp";
import { escape } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { assert } from "../../assert";
import { useGenericParams } from "../../routing/useGenericParams";
import {
  ChartDataMetric,
  useChartDataContext,
} from "../components/chart-components/ChartDataContext";
import { ChartDropdownMenu } from "../components/chart-components/ChartDropdownMenu";
import { ChartPanelContent } from "../components/chart-components/ChartPanelContent";
import { ChartPanelTitle } from "../components/chart-components/ChartPanelTitle";
import { ChartWrapper } from "../components/chart-components/ChartWrapper";
import { ChartPanelErrorMessage } from "../components/chart-messages/ChartPanelErrorMessage";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { FullscreenDialog } from "../components/FullscreenDialog";
import { ChartRef } from "../components/HighchartsChart";
import { VisualisationTypes } from "../types/ChartConfig";
import { ExportType, ExportTypeMap } from "../types/ExportTypes";
import {
  ChartConfigItemMultiDimensionalSeries,
  MultiDimensionalSeries,
  MultiDimensionalSeriesVisualisationTypes,
} from "./ChartConfigItemMultiDimensionalSeries";
import { ColumnChart } from "./ColumnChart";
import { LineOrAreaChart } from "./LineOrAreaChart";
import { TableChart } from "./TableChart";
import {
  CrawlContextCrawlSetting,
  useCrawlContextData,
} from "../../../crawl-overview/CrawlContext";
import { Theme, useTheme } from "@material-ui/core";
import { ChartConfigReportStatArray } from "../types/ChartConfigItemBase";
import { getIconForChart } from "../../../crawl-overview/dashboard/data-visualization/charts/getIconForChart";

const VisualisationTypeToChartMap = {
  [VisualisationTypes.Line]: LineOrAreaChart,
  [VisualisationTypes.Area]: LineOrAreaChart,
  [VisualisationTypes.StackedArea]: LineOrAreaChart,
  [VisualisationTypes.StackedColumn]: ColumnChart,
  [VisualisationTypes.Column]: ColumnChart,
  [VisualisationTypes.FullStackedColumn]: ColumnChart,
  [VisualisationTypes.FullStackedArea]: LineOrAreaChart,
  [VisualisationTypes.Table]: TableChart,
};

export type MultiDimensionalSeriesChartProps = Omit<
  ChartConfigItemMultiDimensionalSeries,
  "title"
> & {
  categories?: string[];
  multiDimensionalSeries: MultiDimensionalSeries;
  title: string;
  thresholds?: { value: number; color: string; textColor: string }[];
};

type Props = ChartConfigItemMultiDimensionalSeries;

export function MultiDimensionalSeriesChart(props: Props): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    reportStats,
    segmentName,
    isChartUnavailable,
    getReportUnit,
    getAggregatedMetric,
  } = useChartDataContext();
  const { accountId, projectId, crawlId } = useGenericParams();
  const { selectedCrawlSegment, crawlSetting } = useCrawlContextData();
  const { t } = useTranslation(["common", "charts"]);
  const theme = useTheme();

  const chartRef = React.createRef<ChartRef>();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedVisualisationType, setSelectedVisualisationType] =
    React.useState(props.visualisationType);

  function handleExportOptionChange(exportType: ExportType): void {
    if (exportType === "CSV") {
      chartRef.current?.chart.downloadCSV();
    } else {
      chartRef.current?.chart.exportChartLocal(
        { type: ExportTypeMap.get(exportType) },
        {},
      );
    }
    enqueueSnackbar(
      <Snackbar
        variant="success"
        title={t("charts:chartDownloadMessage", { exportType })}
      />,
    );
  }

  const Component = VisualisationTypeToChartMap[selectedVisualisationType];

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const series = props
    .series(reportStats as ChartConfigReportStatArray, {
      accountId,
      projectId,
      crawlId,
      segmentId: selectedCrawlSegment?.segment?.id,
    })
    .map((serie) => ({
      ...serie,
      unit: getReportUnit(
        reportStats.find(
          (x) =>
            (x.reportTemplateCode || x.reportTemplateCode) ===
            serie.reportTemplateCode,
        ),
      ),
      name: escape(serie.name),
    }));

  const categories = props.seriesCategories
    ? props.seriesCategories(series)
    : undefined;

  const hasNoData = series.every((serie) => !serie.data.length);

  const title = props.title(reportStats as ChartConfigReportStatArray);

  const visualisationOptions = props.additionalVisualisationTypes
    ? [props.visualisationType, ...props.additionalVisualisationTypes]
    : undefined;

  const isTableChart = props.visualisationType === VisualisationTypes.Table;

  const shouldDisableChartOptions = isChartUnavailable || hasNoData;
  const shouldShowIncompleteSeriesTemplate =
    props.incompleteSeriesTemplate &&
    series.every(({ data }) => data.length < 2);
  const shouldShowChart = !hasNoData && !shouldShowIncompleteSeriesTemplate;

  const metric =
    reportStats.length === 1 ? getAggregatedMetric(reportStats[0]) : undefined;
  const thresholds = getThresholds({ metric, crawlSetting, theme });
  return (
    <>
      <ChartWrapper
        data-testid={props.testAttributePrefix}
        data-pendo={props.pendoAttributePrefix}
      >
        <ChartPanelTitle
          title={title}
          icon={getIconForChart(props.icon)}
          description={
            props.description
              ? props.description(
                  { accountId, projectId, crawlId },
                  reportStats as ChartConfigReportStatArray,
                )
              : undefined
          }
          descriptionTitle={props.descriptionTitle?.(
            reportStats as ChartConfigReportStatArray,
          )}
          segmentName={segmentName}
        >
          {!props.disableFullscreen && (
            <ChartIconButton
              tooltip={t("charts:openFullscreen")}
              onClick={() => setModalIsOpen(true)}
              icon={ZoomOutMap}
              data-pendo={
                props.pendoAttributePrefix
                  ? `${props.pendoAttributePrefix}-open-fullscreen`
                  : undefined
              }
            />
          )}
          <ChartDropdownMenu<MultiDimensionalSeriesVisualisationTypes>
            title={t("charts:chooseChartType")}
            selectedOption={selectedVisualisationType}
            options={visualisationOptions}
            handleOptionChange={setSelectedVisualisationType}
            isDisabled={shouldDisableChartOptions}
            pendoAttributePrefix={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-chart-type`
                : undefined
            }
          />
          {!isTableChart && (
            <ChartDropdownMenu<ExportType>
              title={t("charts:exportOptions")}
              options={["PDF", "PNG", "CSV"]}
              handleOptionChange={handleExportOptionChange}
              icon={GetAppIcon}
              isDisabled={shouldDisableChartOptions}
              pendoAttributePrefix={
                props.pendoAttributePrefix
                  ? `${props.pendoAttributePrefix}-export`
                  : undefined
              }
            />
          )}
        </ChartPanelTitle>
        <ChartPanelContent requiredSources={props.requiredSources}>
          {hasNoData && !shouldShowIncompleteSeriesTemplate && (
            <ChartPanelMessageWrapper>
              <ChartPanelErrorMessage>
                {Boolean(selectedCrawlSegment)
                  ? t("charts:noDataInSegment", { segmentName })
                  : t("charts:noData")}
              </ChartPanelErrorMessage>
            </ChartPanelMessageWrapper>
          )}
          {shouldShowIncompleteSeriesTemplate && (
            <ChartPanelMessageWrapper>
              {props.incompleteSeriesTemplate}
            </ChartPanelMessageWrapper>
          )}
          {shouldShowChart && (
            <Component
              {...props}
              title={title}
              icon={props.icon}
              visualisationType={selectedVisualisationType}
              categories={categories}
              multiDimensionalSeries={series}
              thresholds={thresholds}
              ref={!isTableChart ? chartRef : undefined}
            />
          )}
        </ChartPanelContent>
      </ChartWrapper>
      {!props.disableFullscreen && (
        <FullscreenDialog
          title={title}
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          content={
            <ChartPanelContent requiredSources={props.requiredSources}>
              {hasNoData && !shouldShowIncompleteSeriesTemplate && (
                <ChartPanelMessageWrapper>
                  <ChartPanelErrorMessage>
                    {Boolean(selectedCrawlSegment)
                      ? t("charts:noDataInSegment", { segmentName })
                      : t("charts:noData")}
                  </ChartPanelErrorMessage>
                </ChartPanelMessageWrapper>
              )}
              {shouldShowIncompleteSeriesTemplate && (
                <ChartPanelMessageWrapper>
                  {props.incompleteSeriesTemplate}
                </ChartPanelMessageWrapper>
              )}
              {shouldShowChart && (
                <Component
                  {...props}
                  title={title}
                  visualisationType={selectedVisualisationType}
                  categories={categories}
                  multiDimensionalSeries={series}
                  thresholds={thresholds}
                />
              )}
            </ChartPanelContent>
          }
        />
      )}
    </>
  );
}

function getThresholds({
  metric,
  crawlSetting,
  theme,
}: {
  metric: ChartDataMetric | undefined;
  crawlSetting: CrawlContextCrawlSetting | undefined;
  theme: Theme;
}): { value: number; color: string; textColor: string }[] | undefined {
  const siteSpeedScoring = metric?.metadata?.siteSpeedScoring;
  if (!siteSpeedScoring) return;

  const isMobile = Boolean(
    crawlSetting?.userAgentString
      ? crawlSetting?.userAgentIsMobile
      : crawlSetting?.userAgent?.isMobile,
  );

  return metric?.metadata?.siteSpeedScoring
    ? [
        {
          value: 0,
          color: theme.palette.green[400],
          textColor: theme.palette.green[600],
        },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.p10
            : siteSpeedScoring.desktop.p10,
          color: theme.palette.yellow[400],
          textColor: theme.palette.yellow[400],
        },
        {
          value: isMobile
            ? siteSpeedScoring.mobile.median
            : siteSpeedScoring.desktop.median,
          color: theme.palette.red[400],
          textColor: theme.palette.red[600],
        },
      ]
    : undefined;
}
