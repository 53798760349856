import { TimeZoneContext } from "@lumar/shared";
import { addMinutes, format } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";

export function useExportName({
  crawlCreatedAt,
  suffix,
}: {
  crawlCreatedAt: Date;
  suffix: string;
}): () => string {
  const { timeZone } = React.useContext(TimeZoneContext);
  const { resourceId } = useParams<{ resourceId: string }>();

  return () => {
    const date = format(
      addMinutes(
        crawlCreatedAt,
        crawlCreatedAt.getTimezoneOffset() + timeZone.offset,
      ),
      "dd-MM-yyyy",
    );
    return `${resourceId}_${date}_${suffix}`;
  };
}
