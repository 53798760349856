import { Maybe } from "../../graphql";

/**
 * @param report Accepts `ReportStat` or `CustomReport`
 * @returns
 */
export function getReportChange(report: {
  added?: Maybe<number> | undefined;
  missing?: Maybe<number> | undefined;
  removed?: Maybe<number> | undefined;
}): number | null {
  if (
    typeof report.added === "number" &&
    typeof report.missing === "number" &&
    typeof report.removed === "number"
  ) {
    return report.added - report.missing - report.removed;
  }
  return null;
}
