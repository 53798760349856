import { useParams } from "react-router-dom";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ResourceDetailData } from "../../../data/types";
import { auditItemsMetricsGroupings } from "../../site-speed/sections/auditItemsMetricsGroupings";
import { ReportEntity } from "../../../../report/Report.types";

export interface OpportunityInstancesProps {
  data: ResourceDetailData;
  variant: "all" | "failed" | "warning" | "info" | "passed";
}

export function OpportunityInstances({
  variant,
  data,
}: OpportunityInstancesProps): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();
  const opportunityDigest = data.metrics["opportunityDigest"]?.value;
  const auditFilter = getAuditFilter(variant);

  const reportTemplateCode = "crawl_site_speed_audit_items";

  const auditId = data.metrics["auditId"]?.value;
  const metricsGroupings = auditItemsMetricsGroupings[auditId];

  return (
    <ReportGrid
      reportInput={{
        crawlId,
        reportEntity: ReportEntity.ReportStat,
        reportTemplateCode,
        reportTypeCode: ReportTypeCode.Basic,
      }}
      baseFilter={{
        opportunityDigest: { eq: opportunityDigest },
        ...(auditFilter ? { auditResult: { eq: auditFilter } } : {}),
      }}
      tabsMode="onlyTotalRowsVisible"
      hideTaskMenu
      overwrites={{ metricsGroupings }}
      columnPersistanceKey={auditId && `${reportTemplateCode}-${auditId}`}
    />
  );
}

function getAuditFilter(
  variant: OpportunityInstancesProps["variant"],
): string | undefined {
  switch (variant) {
    case "all":
      return;
    case "failed":
      return "Failed";
    case "warning":
      return "Warning";
    case "info":
      return "Needs Review";
    case "passed":
      return "Passed";
    default:
      return;
  }
}
