import { Metrics } from "./data/types";

export function ReportCount({
  code,
  count,
  offset,
  metrics,
}: {
  code?: string;
  count?: number;
  offset?: number;
  metrics: Metrics;
}): JSX.Element | null {
  const value = count ?? metrics[code || ""]?.value;

  if (typeof value !== "number") {
    return null;
  }

  const countValue = Math.max(value + (offset || 0), 0);
  if (countValue > 1000) {
    return <>1000+</>;
  }

  const isApproximation = Boolean(metrics[`${code}IsApproximation`]?.value);
  if (isApproximation) {
    return <>≈{countValue}</>;
  }

  return <>{countValue}</>;
}
