/* eslint-disable react/display-name */
import {
  GridColDef,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import React, { useMemo } from "react";
import { ActionsItems } from "./ActionsItems";
import { DurationCell } from "./DurationCell";
import { useTranslation, getRawCrawlId, useSession } from "@lumar/shared";
import { StatusCell } from "./StatusCell";
import { IdCell } from "./IdCell";
import { ComparedToCrawlIdCell } from "./ComparedToCrawlIdCell";
import { CrawledCell } from "./CrawledCell";
import { CrawlTypeCell } from "./CrawlTypeCell";
import { makeStyles } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { activeStatuses } from "../../constants";
import { ChangesCell } from "./ChangesCell";
import { DepthCell } from "./DepthCell";
import { DateSinceCell } from "./DateSinceCell";
import { insertIf } from "../../../_common/insertIf";
import { RoleCode } from "../../../graphql";

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "inherit",
    width: "100%",
  },
}));

export function useCrawlsListColumns(isTestSuite?: boolean): GridColumns {
  const { t } = useTranslation("crawlsList");
  const { isDeepCrawlAdminEnabled, hasSufficientRole, isUsingSharedLink } =
    useSession();
  const hasEditPermission = hasSufficientRole(RoleCode.Editor);

  return useMemo<GridColumns>(
    () => [
      {
        field: "createdAt",
        type: "date",
        headerName: t("tableColumns.started"),
        flex: 1.5,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <DateSinceCell params={params} data-testid="created-at-value" />
          </CrawlLink>
        ),
      },
      {
        field: "finishedAt",
        type: "date",
        headerName: t("tableColumns.completed"),
        flex: 1.5,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <DateSinceCell
              params={params}
              showTestSiteBadge
              data-testid="finished-at-value"
            />
          </CrawlLink>
        ),
      },
      {
        field: "duration",
        type: "string",
        headerName: t("tableColumns.duration"),
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <DurationCell {...params} />
          </CrawlLink>
        ),
      },
      {
        field: "statusEnum",
        type: "string",
        headerName: t("tableColumns.status"),
        flex: 0.7,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <StatusCell {...params} />
          </CrawlLink>
        ),
      },
      {
        field: "crawlUrls",
        type: "number",
        headerName: t("tableColumns.crawled"),
        align: "right",
        flex: 0.8,
        sortable: false,
        disableColumnMenu: true,
        // Note: Crawls prior to GA-1133 do not have a crawlUrlsTotal value.
        valueGetter: ({ row: { crawlUrlsTotal, totalSteps } }) =>
          crawlUrlsTotal ?? totalSteps ?? undefined,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <CrawledCell {...params} />
          </CrawlLink>
        ),
      },
      {
        field: "crawlLevels",
        type: "number",
        headerName: t("tableColumns.depth"),
        align: "right",
        flex: 0.7,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <CrawlLink crawlId={params.row.id} params={params}>
              <DepthCell {...params} />
            </CrawlLink>
          );
        },
      },
      {
        field: "crawlChanges",
        type: "number",
        headerName: t("tableColumns.changes"),
        align: "right",
        flex: 0.7,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <CrawlLink crawlId={params.row.id} params={params} changes={"open"}>
              <ChangesCell {...params} />
            </CrawlLink>
          );
        },
      },
      ...insertIf<GridColDef>(!isUsingSharedLink, {
        field: "username",
        type: "string",
        headerName: t("tableColumns.startedBy"),
        flex: 1.2,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: ({ row: { createdByUser } }) =>
          createdByUser?.email || createdByUser?.id,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <CrawlLink crawlId={params.row.id} params={params}>
              {params.value}
            </CrawlLink>
          );
        },
      }),
      ...insertIf<GridColDef>(isDeepCrawlAdminEnabled, {
        field: "crawlerType",
        type: "string",
        headerName: t("tableColumns.crawlType"),
        flex: 0.8,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <CrawlTypeCell {...params} />
          </CrawlLink>
        ),
      }),
      ...insertIf<GridColDef>(isDeepCrawlAdminEnabled, {
        field: "id",
        type: "string",
        headerName: t("tableColumns.id"),
        flex: 0.8,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <IdCell {...params} />
          </CrawlLink>
        ),
      }),
      ...insertIf<GridColDef>(isDeepCrawlAdminEnabled, {
        field: "comparedToCrawlId",
        type: "string",
        headerName: t("tableColumns.compareId"),
        flex: 0.8,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <CrawlLink crawlId={params.row.id} params={params}>
            <ComparedToCrawlIdCell {...params} />
          </CrawlLink>
        ),
      }),
      ...insertIf<GridColDef>(hasEditPermission, {
        field: "actions",
        headerName: t("tableColumns.actions"),
        width: 100,
        sortable: false,
        align: "center",
        headerAlign: "center",
        hide: isTestSuite,
        disableColumnMenu: true,
        renderCell: (params) => <ActionsItems {...params} />,
      }),
    ],
    [
      t,
      isDeepCrawlAdminEnabled,
      hasEditPermission,
      isTestSuite,
      isUsingSharedLink,
    ],
  );
}

function CrawlLink({
  crawlId,
  params,
  children,
  changes,
}: {
  crawlId: string;
  params: GridRenderCellParams;
  children: React.ReactNode;
  changes?: string | undefined;
}): JSX.Element {
  const { projectId, accountId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const classes = useStyles();
  const { statusEnum, finishedAt } = params.row;
  const isActive = activeStatuses.includes(statusEnum);
  const isNotArchived = finishedAt === null;

  const getHref = (id: string, changes: string | undefined): string => {
    const crawlId = getRawCrawlId(id) || "0";
    if (isActive && isNotArchived) {
      return Routes.Crawls.getUrl({ accountId, projectId, tab: "progress" });
    }
    return Routes.CrawlOverview.getUrl({
      accountId,
      projectId,
      crawlId,
      changes: changes ?? undefined,
    });
  };

  return (
    <Link to={getHref(crawlId, changes)} className={classes.link}>
      {children}
    </Link>
  );
}
