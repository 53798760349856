import { useTranslation } from "@lumar/shared";
import React from "react";
import { ScheduleFrequencyCode } from "../../../../graphql";
import { SettingsContext } from "../data/useContextValues";

const PLAN_LIGHT_FREQUENCIES = [
  ScheduleFrequencyCode.Monthly,
  ScheduleFrequencyCode.Quarterly,
];

const PLAN_LIGHT_PLUS_FREQUENCIES = [
  ScheduleFrequencyCode.Weekly,
  ScheduleFrequencyCode.Monthly,
  ScheduleFrequencyCode.Quarterly,
];

export function useSchduleFrequencyOptions(): {
  value: ScheduleFrequencyCode | "None";
  label: string;
}[] {
  const { t } = useTranslation("crawlSettings");
  const contextValues = React.useContext(SettingsContext);

  function filterFrequencies(frequency: ScheduleFrequencyCode): boolean {
    if (contextValues.isPlanLight)
      return PLAN_LIGHT_FREQUENCIES.includes(frequency);

    if (contextValues.isPlanLightPlus)
      return PLAN_LIGHT_PLUS_FREQUENCIES.includes(frequency);

    return true;
  }

  return [
    {
      value: "None",
      label: t("settings.schedule.label"),
    },
    ...contextValues.scheduleFrequencies
      .filter((frequency) => filterFrequencies(frequency.code))
      .map((frequency) => ({
        value: frequency.code,
        label: frequency.name,
      })),
  ];
}
