import { EmptyState, SmileySad } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.red[400],
  },
}));

interface Props {
  title: string;
  description: string;
}

export function SettingsErrorOverlay({
  title,
  description,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <EmptyState
      icon={<SmileySad fontSize="large" className={classes.icon} />}
      title={title}
      description={description}
      height={400}
    />
  );
}
