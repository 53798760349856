import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TypographyProps, Tooltip, Grid } from "@material-ui/core";
import { InfoOutlined } from "@lumar/shared";
import { StyledInputLabel } from "../forms/StyledInputLabel";

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    marginTop: "1px",
    marginLeft: theme.spacing(0.5),
    display: "block",
  },
  label: {
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.0625rem",
  },
}));

export interface LabelInfoProp extends TypographyProps {
  label: string;
  info: NonNullable<React.ReactNode>;
  interactive?: boolean;
}

export function LabelInfo({
  label,
  info,
  interactive,
}: LabelInfoProp): JSX.Element {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" alignContent="center" direction="row">
      <div className={classes.label}>{label}</div>
      <Tooltip title={info} placement="right" arrow interactive={interactive}>
        <InfoOutlined
          color="inherit"
          fontSize="inherit"
          className={classes.infoIcon}
        />
      </Tooltip>
    </Grid>
  );
}

export function InputLabelInfo({
  label,
  info,
  interactive,
}: LabelInfoProp): JSX.Element {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      direction="row"
      style={{ marginBottom: 8 }}
    >
      <div>
        <StyledInputLabel style={{ marginBottom: 0 }}>{label}</StyledInputLabel>
      </div>
      <Tooltip title={info} placement="right" arrow interactive={interactive}>
        <InfoOutlined
          color="inherit"
          fontSize="inherit"
          className={classes.infoIcon}
        />
      </Tooltip>
    </Grid>
  );
}
