import { fieldToTextField, TextField, Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Field, FieldProps } from "formik";

export function TitleField({ isEditing }: { isEditing: boolean }): JSX.Element {
  const classes = useStyles();

  return (
    <Field name="title">
      {(props: FieldProps<string[]>) =>
        !isEditing ? (
          <Typography
            variant="h6SemiBold"
            className={classes.title}
            data-testid="task-dialog-title"
          >
            {props.field.value}
          </Typography>
        ) : (
          <TextField
            {...fieldToTextField(props)}
            id="edit-task-title"
            className={classes.input}
            disabled={props.form.isSubmitting}
            data-testid="edit-task-title"
          />
        )
      }
    </Field>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "36px",
  },
  input: {
    marginRight: theme.spacing(3),
  },
}));
