import {
  CustomReportTemplate,
  CustomReportTrendItem,
  LegacyTask,
  Maybe,
  LegacyTaskPriority,
  LegacyTaskStatus,
} from "../../graphql";
import { useTranslation } from "@lumar/shared";

type TaskData = Pick<LegacyTask, "identified" | "trend" | "remaining"> & {
  customReports?: Maybe<
    Array<{
      customReportTemplate: Pick<CustomReportTemplate, "code">;
      trend: Array<
        Pick<CustomReportTrendItem, "basic" | "createdAt" | "crawlId">
      >;
    }>
  >;
};

export type TaskResolvedData = {
  trendResolved: { value: number; createdAt: Date; crawlId?: string }[];
  remainingResolved: number;
  unresolvedPercentResolved: number;
};

export function getTaskResolvedData(task: TaskData): TaskResolvedData {
  const isTaggedTask = Boolean(task.customReports?.length);

  const { trendResolved, remainingResolved } = isTaggedTask
    ? getTaggedTaskData(task)
    : getUntaggedTaskData(task);

  return {
    trendResolved,
    remainingResolved,
    unresolvedPercentResolved: Boolean(task.identified)
      ? Math.ceil((remainingResolved / (task.identified || 0)) * 100) / 100
      : 0,
  };
}

function getUntaggedTaskData(
  task: TaskData,
): Pick<TaskResolvedData, "trendResolved" | "remainingResolved"> {
  return {
    trendResolved:
      task.trend
        ?.filter((x) => typeof x.identified === "number")
        .map((x) => ({
          value: x.identified || 0,
          createdAt: new Date(x.crawlFinishedAt),
          crawlId: x.crawlId || undefined,
        }))
        .toSorted((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .slice(-30) || [],
    remainingResolved: task.remaining || 0,
  };
}

function getTaggedTaskData(
  task: TaskData,
): Pick<TaskResolvedData, "trendResolved" | "remainingResolved"> {
  const unresolvedReport = task.customReports?.find((x) =>
    x.customReportTemplate.code.startsWith("task_unresolved_"),
  );
  const trendResolved =
    unresolvedReport?.trend
      .filter(
        (x) => typeof x.basic === "number" || typeof x.createdAt === "string",
      )
      .map((x) => ({
        value: x.basic || 0,
        createdAt: new Date(x.createdAt || ""),
        crawlId: x.crawlId.toString(),
      }))
      .toSorted((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
      .slice(-30) || [];

  const remaining = trendResolved.at(-1)?.value;

  return {
    trendResolved,
    remainingResolved: remaining || 0,
  };
}

export function useTaskStatusName(): (
  status?: LegacyTaskStatus,
) => string | undefined {
  const { t } = useTranslation("taskManager");

  return (status) => {
    switch (status) {
      case LegacyTaskStatus.Done:
        return t("status.done");
      case LegacyTaskStatus.Testing:
        return t("status.testing");
      case LegacyTaskStatus.InProgress:
        return t("status.inProgress");
      case LegacyTaskStatus.ToDo:
        return t("status.toDo");
      case LegacyTaskStatus.Backlog:
        return t("status.backlog");
      default:
        return status;
    }
  };
}

export function useTaskPriorityName(): (
  priority?: LegacyTaskPriority,
) => string | undefined {
  const { t } = useTranslation("taskManager");

  return (priority) => {
    switch (priority) {
      case LegacyTaskPriority.Critical:
        return t("severity.critical");
      case LegacyTaskPriority.High:
        return t("severity.high");
      case LegacyTaskPriority.Medium:
        return t("severity.medium");
      case LegacyTaskPriority.Low:
        return t("severity.low");
      case LegacyTaskPriority.Note:
        return t("severity.note");
      default:
        return priority;
    }
  };
}
