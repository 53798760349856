import { ApolloError } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { crawlNoDataErrors } from "./no-data-errors/mappings";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: 15,
    color: theme.palette.yellow[900],
    backgroundColor: theme.palette.yellow[100],
    alignItems: "center",
  },
  alertIcon: { color: theme.palette.yellow[500] },
  alertLink: { color: theme.palette.blue[600] },
}));

export function ReportGridAlerts(props: {
  error?: ApolloError;
  children?: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const code = props.error?.graphQLErrors[0]?.extensions?.code as
    | string
    | undefined;
  const GridError = code ? crawlNoDataErrors.get(code) : undefined;
  return (
    <>
      {GridError ? (
        <Alert
          className={classes.alert}
          severity="warning"
          classes={{ icon: classes.alertIcon }}
        >
          <GridError error={props.error}></GridError>
        </Alert>
      ) : (
        props.children
      )}
    </>
  );
}
