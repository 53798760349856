import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControlLabel,
  Grid,
  Popover,
  Box,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { bindPopover } from "material-ui-popup-state/core";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Checkbox, Typography } from "@lumar/shared";
import { translationNamespace } from "../../../CrawlSettings";
import { AddLogzioAccount } from "./AddLogzioAccount";
import { LogzioQuery } from "./LogzioQuery";
import { assert } from "../../../../../_common/assert";
import { useGenericParams } from "../../../../../_common/routing/useGenericParams";
import { getRawLogzioProjectQueryId } from "../../../../../_common/api/getRawLogzioProjectQueryId";
import { LogzioConnectionValues, LogzioQueryValues } from "./useLogzioQueries";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
  },
  alignRightGrid: {
    display: "flex",
    justifyContent: "flex-end",
  },
  query: {},
  loginPopup: {
    maxWidth: "600px",
    margin: theme.spacing(1),
  },
}));

export interface LogzioProps {
  connections: LogzioConnectionValues[];
  queries: LogzioQueryValues[];
  addConnection: (label: string, token: string) => Promise<boolean>;
  addQuery: () => Promise<void>;
  setQueryStatus: (id: string, status: boolean) => void;
  deleteQuery: (id: string) => void;
}

export function Logzio({
  connections,
  queries,
  addConnection,
  addQuery,
  setQueryStatus,
  deleteQuery,
}: LogzioProps): JSX.Element {
  const { projectId } = useGenericParams();
  assert(projectId);

  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const addAccountPopupState = usePopupState({
    variant: "popover",
    popupId: `addLogzioAccount`,
  });

  const allSelected: boolean =
    queries.filter((t) => t.enabled === false).length === 0;

  const updateAllEnabled = (state: boolean): void => {
    queries.map((item) => {
      if (item.enabled !== state) setQueryStatus(item.id, state);
    });
  };
  // eslint-disable-next-line fp/no-mutating-methods
  const sortedQueryIds = queries.sort((a, b) => {
    return (
      (getRawLogzioProjectQueryId(b.id) as unknown as number) -
      (getRawLogzioProjectQueryId(a.id) as unknown as number)
    );
  });

  if (!connections.length && !queries.length)
    return (
      <Box className={classes.root}>
        <Typography variant="caption">
          {t("sources.logSummary.logzio.infoText")}
        </Typography>
        <AddLogzioAccount
          className={classes.indent}
          addConnection={addConnection}
        />
      </Box>
    );

  return (
    <Grid container spacing={2} className={classes.indent}>
      <Grid item xs={12} md={6}>
        <Button
          onClick={() => addQuery()}
          color="primary"
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          data-testid="crawl-settings-add-logzio-query"
        >
          {t("sources.logSummary.logzio.addQuery")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} className={classes.alignRightGrid}>
        <Popover
          {...bindPopover(addAccountPopupState)}
          onClose={(event: React.MouseEvent<unknown>) => {
            event.stopPropagation();
            addAccountPopupState.close();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <AddLogzioAccount
            className={classes.loginPopup}
            afterSave={() => addAccountPopupState.close()}
            addConnection={addConnection}
          />
        </Popover>
        <Button
          onClick={(event) => {
            addAccountPopupState.open(event);
          }}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          data-testid="crawl-settings-add-logzio-connection"
        >
          {t("sources.logSummary.logzio.addAccount")}
        </Button>
      </Grid>
      {queries.length === 0 ? (
        <Grid item xs={12}>
          <Typography variant="subtitle2Medium">
            {t("sources.logSummary.logzio.noQueriesTitle")}
          </Typography>
          <Typography variant="caption">
            {t("sources.logSummary.logzio.noQueriesDescription")}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                onChange={() => {
                  updateAllEnabled(!allSelected);
                }}
                data-testid="crawl-settings-logzio-select-deselect-all"
              />
            }
            label={t("sources.sitemaps.selectDeselectAll")}
          />
          <div style={{ marginTop: "15px" }}>
            {sortedQueryIds.map((item) => (
              <LogzioQuery
                key={item.id}
                id={item.id}
                enabled={item.enabled}
                connections={connections}
                enabledChanged={(enabled) => setQueryStatus(item.id, enabled)}
                deleteQuery={() => deleteQuery(item.id)}
              />
            ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
}
