var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
function removePathFromURL(url) {
    return url.endsWith("/") || url.endsWith("\\") ? url.slice(0, -1) : url;
}
export function FaviconIcon(_a) {
    var { url } = _a, imgProps = __rest(_a, ["url"]);
    const cleanURL = removePathFromURL(url);
    const faviconUrl = `https://www.google.com/s2/favicons?sz=128&domain_url=${cleanURL}`;
    return (_jsx("img", Object.assign({ src: faviconUrl, alt: "Favicon for primary domain" }, imgProps)));
}
