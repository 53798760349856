import { useTranslation } from "@lumar/shared";
import * as Yup from "yup";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useReportAdjustmentFormValidationSchema() {
  const { t } = useTranslation("reportAdjustment");
  return Yup.object({
    reportTemplateCode: Yup.string().required(t("validation.reportRequired")),
    totalWeight: Yup.number()
      .min(0)
      .max(1)
      .required(t("validation.priorityRequired")),
    totalSign: Yup.number()
      .equals([-1, 0, 1])
      .required(t("validation.impactRequired")),
  });
}
