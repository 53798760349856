/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable fp/no-mutating-methods */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, isEmpty, isEqual, isObject, omit, unionBy } from "lodash";
import {
  Maybe,
  ModuleCode,
  RenderingRobotsCheckMode,
  SettingsComparisonFragment,
} from "../../graphql";
import { getFirstLetterCapitalised } from "../string-manipulation/getFirstLetterCapitalised";
import { useTranslation, useSession } from "@lumar/shared";
import { TFunction } from "i18next";
import { useCallback } from "react";
import { insertIf } from "../insertIf";

export interface DiffSetting {
  name: string;
  currentValue: any;
  comparedToValue: any;
}

export interface ComparisonResults {
  diffs: DiffSetting[];
  noDiffs: [string, any][];
  isCompared: boolean;
}

export function useCompareSettingsResults(): (
  crawl?: Maybe<SettingsComparisonFragment>,
  accessibilityContainerId?: string,
  moduleCode?: ModuleCode,
) => ComparisonResults {
  const { t } = useTranslation("crawlSettingsComparison");
  const { isDeepCrawlAdminEnabled } = useSession();

  return useCallback(
    (
      crawl?: Maybe<SettingsComparisonFragment>,
      accessibilityContainerId?: string,
      moduleCode?: ModuleCode,
    ) =>
      compareSettingsResults(
        t,
        !!isDeepCrawlAdminEnabled,
        crawl,
        accessibilityContainerId,
        moduleCode,
      ),
    [t, isDeepCrawlAdminEnabled],
  );
}

function compareSettingsResults(
  t: TFunction<"crawlSettingsComparison">,
  isSuperAdmin: boolean,
  crawl?: Maybe<SettingsComparisonFragment>,
  accessibilityContainerId?: string,
  moduleCode?: ModuleCode,
): ComparisonResults {
  const comparedTo = crawl?.comparedTo;
  const isCompared = Boolean(comparedTo);

  const currentSettings = getCrawlSettings(
    t,
    isSuperAdmin,
    crawl,
    accessibilityContainerId,
  );
  const comparedToSettings = getCrawlSettings(
    t,
    isSuperAdmin,
    comparedTo,
    accessibilityContainerId,
  );

  const fullSettings = unionBy(currentSettings, comparedToSettings, (a) => a[0])
    .sort((a, b) => a[1].localeCompare(b[1]))
    .map((e) => e[0]);

  const moduleSpecificSettings: [ModuleCode, string[]][] = [
    [
      ModuleCode.Accessibility,
      ["selectedWcagLevel", "selectedWcagVersion", "includeBestPractices"],
    ],
  ];

  function shouldShowSettings(settingsKey: string): boolean {
    const settingsModules = moduleSpecificSettings.filter(([_, settings]) =>
      settings.includes(settingsKey),
    );

    const settingsBelongToModule = Boolean(settingsModules.length);
    if (!settingsBelongToModule) return true;

    return Boolean(settingsModules.find(([module]) => module === moduleCode));
  }

  const diffs: DiffSetting[] = [];
  const noDiffs: [string, any][] = [];

  fullSettings?.forEach((key) => {
    if (!shouldShowSettings(key)) return;

    const currentValue = currentSettings.find(
      ([comparedToKey]) => key === comparedToKey,
    );
    const previousValue = comparedToSettings.find(
      ([comparedToKey]) => key === comparedToKey,
    );
    if (
      isCompared &&
      // previousValue &&
      !isEqual(
        convert(currentValue ? currentValue[2] : null),
        convert(previousValue ? previousValue[2] : null),
      )
    ) {
      diffs.push({
        name: currentValue?.[1] ?? previousValue?.[1] ?? "",
        currentValue: currentValue ? currentValue[2] : null,
        comparedToValue: previousValue ? previousValue[2] : null,
      });
    } else {
      noDiffs.push([
        currentValue?.[1] ?? previousValue?.[1] ?? "",
        currentValue ? currentValue[2] : null,
      ]);
    }
  });

  return {
    diffs,
    noDiffs,
    isCompared,
  };
}

function getCrawlSettings(
  t: TFunction<"crawlSettingsComparison">,
  isSuperAdmin: boolean,
  crawl?: Maybe<SettingsComparisonFragment>,
  accessibilityContainerId?: string,
): [string, string, any][] {
  const isCustomUserAgent =
    Boolean(crawl?.crawlSetting?.userAgentString) ||
    Boolean(crawl?.crawlSetting?.userAgentToken);
  const isMobileCustomUserAgent =
    Boolean(crawl?.crawlSetting?.userAgentTokenMobile) ||
    Boolean(crawl?.crawlSetting?.userAgentStringMobile);

  const valueTransformerList: [string, null | ((e: any) => any)][] = [
    ["id", null],
    ["__typename", null],
    ["renderingRobotsCheckMode", null],
    ["googleAnalytics4LastNDays", null],
    ["googleAnalytics4Source", null],
    ["googleAnalytics4MinimumSessions", null],
    ["googleAnalytics4MinimumViews", null],
    ["userAgent", isCustomUserAgent ? null : getCustomUserAgent],
    ["userAgentString", isCustomUserAgent ? getCustomUserAgent : null],
    ["userAgentToken", isCustomUserAgent ? getCustomUserAgent : null],
    ["mobileUserAgent", isMobileCustomUserAgent ? null : getCustomUserAgent],
    [
      "userAgentStringMobile",
      isMobileCustomUserAgent ? getCustomUserAgent : null,
    ],
    [
      "userAgentTokenMobile",
      isMobileCustomUserAgent ? getCustomUserAgent : null,
    ],
  ];

  function getCustomUserAgent(value: any): any {
    return value;
  }

  const settings: [string, string, any][] = Object.entries(
    crawl?.crawlSetting || {},
  ).reduce<[string, string, any][]>((list, [key, value]) => {
    const valueTransformer = valueTransformerList.find(
      (e) => e[0] === key,
    )?.[1];
    if (valueTransformer === null) return list;

    return [
      ...list,
      [
        key,
        t(`settings.${key}`),
        valueTransformer ? valueTransformer(value) : value,
      ],
    ];
  }, []);

  const accessibilityContainer =
    crawl?.customMetricContainerVersionCrawls?.nodes.find(
      (x) =>
        x.customMetricContainerVersion.customMetricContainer.id ===
        accessibilityContainerId,
    );

  const additionalSettings: [string, string, any][] = [
    ["crawlTypes", t("additionalSettings.crawlTypes"), crawl?.crawlTypes],
    ...insertIf<[string, string, any]>(isSuperAdmin, [
      "crawlerType",
      t("additionalSettings.crawlerType"),
      crawl?.crawlerType,
    ]),
    [
      "accessibilityTestsEnabled",
      t("additionalSettings.accessibilityTestsEnabled"),
      Boolean(accessibilityContainer),
    ],
    [
      "a11yIgnoreRobotsTxtForResources",
      t("additionalSettings.ignoreRobotsTxtForResources"),
      crawl?.crawlSetting?.renderingRobotsCheckMode ===
        RenderingRobotsCheckMode.RequestDisallowed,
    ],
    [
      "a11yCustomJs",
      t("additionalSettings.a11yCustomJs"),
      accessibilityContainer?.customJsScripts?.[0] || "",
    ],
    [
      "ga4Filter",
      t("additionalSettings.ga4Filter"),
      crawl?.crawlSetting?.googleAnalytics4Source,
    ],
    [
      "ga4DateRange",
      t("additionalSettings.ga4DateRange"),
      crawl?.crawlSetting?.googleAnalytics4LastNDays
        ? t("additionalSettings.ga4LastNDays", {
            count: crawl?.crawlSetting?.googleAnalytics4LastNDays,
          })
        : undefined,
    ],
    [
      "ga4MinimumSessions",
      t("additionalSettings.ga4MinimumSessions"),
      crawl?.crawlSetting?.googleAnalytics4MinimumSessions,
    ],
    [
      "ga4MinimumViews",
      t("additionalSettings.ga4MinimumViews"),
      crawl?.crawlSetting?.googleAnalytics4MinimumViews,
    ],
  ];

  return [...settings, ...additionalSettings].map((x) => {
    if (get(x[2], "id")) {
      const { id, ...rest } = x[2];
      return [x[0], x[1], recursiveOmit(rest, "__typename")];
    }
    return [x[0], x[1], recursiveOmit(x[2], "__typename")];
  });
}

export function addSpaceBeforeCapitalLetter(name: string): string {
  const capitalizedName = getFirstLetterCapitalised(name);
  return capitalizedName.replace(/([A-Z])/g, " $1").trim();
}

function convert(value: unknown): unknown {
  if (Array.isArray(value) && !value.length) {
    return null;
  }
  if (isObject(value) && isEmpty(value)) {
    return null;
  } else if (typeof value === "string" && !value.length) {
    return null;
  }
  return value;
}

function recursiveOmit(obj: unknown, field: string): unknown {
  if (Array.isArray(obj)) {
    return obj.map((e) => recursiveOmit(e, field));
  }
  return typeof obj === "object" && obj !== null ? omit(obj, field) : obj;
}
