/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getTitleAndDescriptionIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    //CEMM: ANLC-276 - PROD: potentially replace with multidimentional graph, where titles and descriptions issues are grouped into a bar show missing, short, OK and too long
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("titleAndDescriptionIssues.title"),
    description: () => t("titleAndDescriptionIssues.description"),
    reportTemplateCodesOrder: [
      "missing_titles",
      "missing_descriptions",
      "short_titles",
      "short_descriptions",
      "max_title_length",
      "max_description_length",
      "no_descriptions_&_no_snippets",
    ],
    reportStatFilter: (report) => {
      return [
        "missing_titles",
        "missing_descriptions",
        "short_titles",
        "short_descriptions",
        "max_title_length",
        "max_description_length",
        "no_descriptions_&_no_snippets",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
