import { CrawlStatus } from "../graphql";

export const activeStatuses = [
  CrawlStatus.Crawling,
  CrawlStatus.Discovering,
  CrawlStatus.Finalizing,
  CrawlStatus.Paused,
  CrawlStatus.Queued,
  CrawlStatus.Queuing,
];
