import React from "react";
import { useTheme } from "@material-ui/core";
import { OverviewType } from "../types";
import { useTranslation, ApolloError, useSession } from "@lumar/shared";
import { reportsTableDefaultColumns } from "./reportsTableDefaultColumns";
import { useReportsAggregateMetricData } from "../dashboard/data-visualization/report-custom-data/useReportsAggregateMetricData";
import { useCrawlOverviewContextData } from "../CrawlOverviewContext";
import { newCategoriesChartsMap } from "../dashboard/config/new/newCategoriesChartsMap";
import {
  CustomDataAggregateMetricConfig,
  CustomDataConfig,
  ReportWithCustomValues,
  isCustomDataAggregateMetricConfig,
} from "../dashboard/data-visualization/report-custom-data/types";
import { useNumberMetricFormatter } from "../../resource-detail/metrics-value-presenter/default-presenters/NumberPresenter";
import {
  GridColumns,
  GridState,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { insertIf } from "../../_common/insertIf";
import { CrawlContextCrawlReportUnion } from "../CrawlContext";
import { orderBy as orderByFn } from "lodash";
import {
  ColumnState,
  readColumnsState,
  writeColumnsState,
} from "../../_common/data-grid/column-persistance/columnsState";
import { applyColumnStates } from "../../_common/data-grid/column-persistance/applyColumnStates";
import { getReportsWithCustomValues } from "../dashboard/data-visualization/report-custom-data/getReportsWithCustomValues";
import { getCustomColumn } from "./getCustomColumn";
import {
  isReportsTableColumnCustomDefinition,
  ReportsTableColumnDefinition,
  ReportsTableConfigItem,
} from "../dashboard/config/types";

export type ReportsTableColumnCustomDefinition = {
  field: string;
  header: string;
  description?: string;
  value: CustomDataConfig;
  maxFractionDigits?: number;
  minFractionDigits?: number;
  align?: "left" | "center" | "right";
  bold?: boolean;
  color?:
    | string
    | ((props: {
        value: number | null | undefined;
        report: CrawlContextCrawlReportUnion;
      }) => string | undefined);
  exclude?: boolean;
  hide?: boolean;
};

interface Props {
  crawlId: string;
  type: OverviewType;
  reports: CrawlContextCrawlReportUnion[];
}

interface Result {
  loading: boolean;
  error?: ApolloError;
  crawlArchived?: boolean;
  columns: GridColumns;
  defaultColumns: string[];
  reports: ReportWithCustomValues[];
  defaultOrderBy?: {
    field: string;
    sort: "asc" | "desc";
  };
  saveColumnsState: (state: GridState) => void;
}

export function useReportsTableColumns({
  crawlId,
  type,
  reports,
}: Props): Result {
  const theme = useTheme();
  const { t } = useTranslation("crawlOverview");
  const { t: tReport } = useTranslation("report");
  const { t: tCharts } = useTranslation("charts");
  const { selectedCategory } = useCrawlOverviewContextData();
  const { isDeepCrawlAdminEnabled } = useSession();

  const formatMetric = useNumberMetricFormatter();

  const { columnDefinitions, orderBy } = React.useMemo(() => {
    const categoryConfig =
      (() => {
        const config = newCategoriesChartsMap.get(selectedCategory.code);
        switch (type) {
          case OverviewType.All:
            return config?.allReportsTable?.({ t: tCharts, theme });
          case OverviewType.Errors:
            return config?.errorsTable?.({ t: tCharts, theme });
        }
      })() || defaultReportsTableConfigs[type];

    return {
      columnDefinitions: [
        ...(categoryConfig?.columns || []),
        ...insertIf<ReportsTableColumnDefinition>(isDeepCrawlAdminEnabled, {
          predefinedColumn: "reportCode",
          hide: true,
        }),
      ],
      orderBy: categoryConfig?.orderBy,
    };
  }, [isDeepCrawlAdminEnabled, selectedCategory.code, tCharts, theme, type]);

  const { data, loading, error, crawlArchived } = useReportsAggregateMetricData(
    {
      crawlId,
      configs: columnDefinitions.reduce<CustomDataAggregateMetricConfig[]>(
        (res, conf) =>
          isReportsTableColumnCustomDefinition(conf) &&
          isCustomDataAggregateMetricConfig(conf.value)
            ? [...res, conf.value]
            : res,
        [],
      ),
    },
  );

  const columnValues = React.useMemo(() => {
    const { columns, defaultColumns, defaultStates } = columnDefinitions.reduce<
      Pick<Result, "columns" | "defaultColumns"> & {
        defaultStates: ColumnState[];
      }
    >(
      ({ columns, defaultColumns, defaultStates }, columnDefinition) => {
        const column = isReportsTableColumnCustomDefinition(columnDefinition)
          ? !columnDefinition.exclude
            ? getCustomColumn(columnDefinition, formatMetric)
            : undefined
          : {
              ...reportsTableDefaultColumns[columnDefinition.predefinedColumn]({
                t,
                tReport,
              }),
              ...(columnDefinition.hide !== undefined
                ? { hide: columnDefinition.hide }
                : {}),
            };

        if (!column) {
          return { columns, defaultColumns, defaultStates };
        }

        return {
          columns: [...columns, column],
          defaultColumns: !column.hide
            ? [...defaultColumns, column.field]
            : defaultColumns,
          defaultStates: [
            ...defaultStates,
            {
              code: column.field,
              hide: Boolean(column.hide),
              width: column.width,
            },
          ],
        };
      },
      { columns: [], defaultColumns: [], defaultStates: [] },
    );

    return {
      columns: applyColumnStates(
        columns,
        readColumnsState(
          `category_${selectedCategory.code}_${type}`,
          defaultStates,
        ),
      ),
      defaultColumns,
      defaultStates,
    };
  }, [
    columnDefinitions,
    selectedCategory.code,
    type,
    formatMetric,
    t,
    tReport,
  ]);

  const reportsWithCustomValues = React.useMemo(() => {
    const columnsWithCustomValue =
      columnDefinitions.filter(isReportsTableColumnCustomDefinition) || [];

    const reportsWithCustomValues = getReportsWithCustomValues({
      reports,
      aggregateMetricData: data,
      fields: columnsWithCustomValue,
    });

    if (!orderBy) return reportsWithCustomValues;

    const customColumnDefinition = columnDefinitions.find(
      (x): x is ReportsTableColumnCustomDefinition =>
        isReportsTableColumnCustomDefinition(x) && x.field === orderBy.field,
    );
    const column = columnValues.columns.find((c) => c.field === orderBy.field);
    return orderByFn(
      reportsWithCustomValues,
      (report) => {
        // If the value is undefined, negative infinity is used, ensuring
        // that the sort order matches the MuiDataGrid's sorting.
        if (customColumnDefinition) {
          return (
            report.customValues[orderBy.field]?.value ??
            Number.NEGATIVE_INFINITY
          );
        }

        if (column) {
          return column.sortable !== false && column.valueGetter
            ? column.valueGetter?.({
                row: report,
              } as unknown as GridValueGetterParams)
            : undefined;
        }
      },
      orderBy.direction,
    );
  }, [columnDefinitions, reports, data, orderBy, columnValues.columns]);

  return {
    loading,
    error,
    crawlArchived,
    reports: reportsWithCustomValues,
    defaultOrderBy: orderBy && {
      field: orderBy.field,
      sort: orderBy.direction,
    },
    ...columnValues,
    saveColumnsState: (state) =>
      writeColumnsState(
        `category_${selectedCategory.code}_${type}`,
        state,
        columnValues.defaultStates,
      ),
  };
}

const defaultReportsTableConfigs: Partial<
  Record<OverviewType, ReportsTableConfigItem>
> = {
  [OverviewType.All]: {
    columns: [
      { predefinedColumn: "categoryIcon" },
      { predefinedColumn: "categoryName" },
      { predefinedColumn: "impact" },
      { predefinedColumn: "name" },
      { predefinedColumn: "total" },
      { predefinedColumn: "weightedTotal" },
      { predefinedColumn: "trend" },
      { predefinedColumn: "change" },
      { predefinedColumn: "weightedChange" },
      { predefinedColumn: "added" },
      { predefinedColumn: "moved" },
      { predefinedColumn: "missing" },
      { predefinedColumn: "priority" },
    ],
  },
  [OverviewType.Errors]: {
    columns: [
      { predefinedColumn: "categoryIcon" },
      { predefinedColumn: "categoryName" },
      { predefinedColumn: "impact" },
      { predefinedColumn: "name" },
      { predefinedColumn: "total" },
      { predefinedColumn: "weightedTotal" },
      { predefinedColumn: "trend" },
      { predefinedColumn: "change" },
      { predefinedColumn: "weightedChange", hide: true },
      { predefinedColumn: "added" },
      { predefinedColumn: "moved" },
      { predefinedColumn: "missing" },
      { predefinedColumn: "priority" },
    ],
    orderBy: { field: "weightedTotal", direction: "desc" },
  },
};
