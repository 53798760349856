import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityNameRoleValueAChart } from "../../../../../_common/charts/config/getAccessibilityNameRoleValueAChart";
import { getAccessibilityNameRoleValueATrend } from "../../../../../_common/charts/config/getAccessibilityNameRoleValueATrend";

export const generateAccessibilityNameRoleValueACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityNameRoleValueAChart,
      getAccessibilityNameRoleValueATrend,
    ],
  };
