import { Tab, Tabs } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OverviewType } from "./types";
import { TabSpinner } from "@lumar/shared";
import { useCrawlContextHelpers } from "./CrawlContext";
import { useCrawlOverviewContextData } from "./CrawlOverviewContext";

export interface CrawlOverviewTypeTabsProps {
  selectedType: OverviewType;
  onTypeSelect: (type: OverviewType) => void;
}

export function CrawlOverviewTypeTabs(
  props: CrawlOverviewTypeTabsProps,
): JSX.Element {
  const { t } = useTranslation("crawlOverview");
  const helpers = useCrawlContextHelpers();
  const { selectedCategory } = useCrawlOverviewContextData();

  const errorReports = helpers.getCrawlReportCategoryErrorReportsList(
    selectedCategory.code,
  );
  const allReports = helpers.getCrawlReportCategoryReportsList(
    selectedCategory.code,
  );

  return (
    <Tabs
      value={props.selectedType}
      indicatorColor="primary"
      onChange={(_, type) => {
        if (type === props.selectedType) {
          return;
        }
        props.onTypeSelect(type);
      }}
    >
      <Tab
        label={t("tabs.dashboard")}
        value={OverviewType.Dashboard}
        data-testid="crawl-overview-dashboard-tab"
      />
      <Tab
        label={<TabLabel name={t("tabs.all")} count={allReports.length} />}
        value={OverviewType.All}
        data-testid="crawl-overview-all-reports-tab"
        data-pendo="all-reports-tab"
      />
      <Tab
        label={<TabLabel name={t("tabs.errors")} count={errorReports.length} />}
        value={OverviewType.Errors}
        disabled={errorReports.length === 0}
        data-testid="crawl-overview-issue-reports-tab"
      />
    </Tabs>
  );
}

function TabLabel({
  name,
  count,
  loading,
}: {
  name: string;
  count: number;
  loading?: boolean;
}): JSX.Element {
  const { t } = useTranslation("units");
  if (loading)
    return (
      <span>
        {name} <TabSpinner />
      </span>
    );

  return (
    <span>
      {name}{" "}
      <span style={{ fontSize: "12px", lineHeight: "14.52px" }}>
        ({t("number", { count })})
      </span>
    </span>
  );
}
