import { SvgIcon } from "@material-ui/core";

export function GoogleAnalyticsLogo(props: {
  className?: string;
}): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 192 192">
      <path
        d="M130 29v132c0 14.77 10.2 23 21 23 10 0 21-7 21-23V30c0-13.54-10-22-21-22s-21 9.33-21 21z"
        fill="#f9ab00"
      />
      <path
        d="M75 96v65c0 14.77 10.2 23 21 23 10 0 21-7 21-23V97c0-13.54-10-22-21-22s-21 9.33-21 21z"
        fill="#e37400"
      />
      <circle cx="41" cy="163" r="21" fill="#e37400" />
    </SvgIcon>
  );
}
