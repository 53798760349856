import { ModuleCode, useGetTopNavigationDataQuery } from "../../graphql";
import { useGenericParams } from "../routing/useGenericParams";

export interface TopNavigationDataResult {
  data: {
    projectName?: string;
    moduleCode?: ModuleCode;
  };
  loading: boolean;
}

export function useTopNavigationData(): TopNavigationDataResult {
  const { projectId } = useGenericParams();

  const { data, loading } = useGetTopNavigationDataQuery({
    variables: {
      projectId: projectId,
    },
    skip: !projectId,
    fetchPolicy: "cache-first",
  });

  return {
    data: {
      projectName: data?.getProject?.name,
      moduleCode: data?.getProject?.moduleCode,
    },
    loading,
  };
}
