import React, { useEffect, useState } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { DownloadSolid, Snackbar } from "@lumar/shared";
import Papa from "papaparse";

export interface CSVGenerationInput {
  header: string[];
  rows: string[][];
}

export interface CSVDownloadButtonProps {
  data: CSVGenerationInput;
  dataReady: boolean;
  fileName: string;
  afterDownloadMessage?: string;
  delayedDownloadMessage?: string;
  tooltip: string;
  disabled?: boolean;
}

// FIXME: Don't use CSS overrides.
// Icon should inherit the size by default.
// Maybe the shared comp needs changes. - Michal
const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: theme.typography.pxToRem(20),
    padding: "8px 12.5px",
    backgroundColor: "white",
    minWidth: "45px",
    "& svg": {
      fontSize: "inherit",
    },
  },
}));

// Note: IMO this should become a hook which returns
// a function making the browser download a csv file.
// I'm not sure if queueing a download is a good idea.
// I think it's better block download interaction instead of queueing. - Michal
export function CSVDownloadButton(props: CSVDownloadButtonProps): JSX.Element {
  const classes = useStyles();
  const {
    dataReady,
    data,
    fileName,
    delayedDownloadMessage,
    afterDownloadMessage,
    tooltip,
    disabled,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [downloadCSVAfterDataLoads, setDownloadCSVAfterDataLoads] =
    useState<boolean>(false);

  function spawnWaitingMessage(): void {
    if (delayedDownloadMessage)
      enqueueSnackbar(<Snackbar title={delayedDownloadMessage} />);
  }

  function spawnSuccessMessage(): void {
    if (afterDownloadMessage)
      enqueueSnackbar(
        <Snackbar variant="success" title={afterDownloadMessage} />,
      );
  }

  function tryDownloadCSV(): void {
    if (dataReady) {
      generateCSVReport();
      spawnSuccessMessage();
    } else {
      setDownloadCSVAfterDataLoads(true);
      spawnWaitingMessage();
    }
  }

  useEffect(() => {
    if (dataReady && downloadCSVAfterDataLoads) {
      generateCSVReport();
      setDownloadCSVAfterDataLoads(false);
      spawnSuccessMessage();
    }
    // eslint-disable-next-line
  }, [dataReady, downloadCSVAfterDataLoads]);

  function generateCSVReport(): void {
    const csv = Papa.unparse([data.header, ...data.rows]);
    const csvData = new Blob(["\ufeff" + csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvData);

    const hiddenElement = document.createElement("a");
    // eslint-disable-next-line fp/no-mutation
    hiddenElement.href = csvUrl;
    // eslint-disable-next-line fp/no-mutation
    hiddenElement.target = "_blank";
    // eslint-disable-next-line fp/no-mutation
    hiddenElement.download = fileName;
    // eslint-disable-next-line fp/no-mutation
    hiddenElement.click();
    hiddenElement.remove();
  }

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Button
        variant="outlined"
        className={classes.button}
        data-testid="csv-download-button"
        onClick={() => tryDownloadCSV()}
        disabled={disabled}
      >
        <DownloadSolid />
      </Button>
    </Tooltip>
  );
}
