import {
  DownloadSolid,
  SearchOutlined,
  TextField,
  ToggleIconButton,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Metric, Metrics } from "../data/types";
import { MetricsValuePresenter } from "../metrics-value-presenter/MetricsValuePresenter";
import { useExportCSV } from "./useExportCSV";
import { MetricTableOverlay } from "./MetricTableOverlay";
import { metricCompareFn } from "./metricCompareFn";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.625),
    minWidth: 540,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: { maxWidth: 290 },
  icon: {
    fontSize: theme.typography.pxToRem(22),
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(1),
    minHeight: 373,
    overflowX: "clip",
  },
  header: {
    background: "#EAEDF0",
    height: theme.spacing(6),
  },
  row: {
    height: "auto",
    "& .LumarJSONTable": {
      maxHeight: 400,
      overflowY: "auto",
    },
  },
  nameCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: 500,
    verticalAlign: "top",
  },
  valueCell: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(20),
  },
}));

export function AllMetricsTable({
  metrics,
  crawlDate,
  exportFileName,
  filter,
}: {
  metrics: Metrics;
  crawlDate: Date;
  exportFileName?: () => string;
  filter?: (metric: Metric) => boolean;
}): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();
  const formatDate = useDateFormatter();

  const exportCSV = useExportCSV();

  const [search, setSearch] = React.useState("");

  const formattedSearch = search.toLocaleLowerCase();
  // eslint-disable-next-line fp/no-mutating-methods
  const filteredMetrics = Object.values(metrics)
    .filter((metric) => metric.data && (!filter || filter(metric)))
    .filter(
      ({ code, name }) =>
        code.toLocaleLowerCase().includes(formattedSearch) ||
        name.toLocaleLowerCase().includes(formattedSearch),
    )
    .sort(metricCompareFn);

  function exportData(): void {
    if (!exportFileName) return;
    exportCSV({
      data: filteredMetrics.map((metric) => ({
        code: metric.code,
        values: [metric.value],
        metricData: metric.data,
      })),
      crawlDates: [crawlDate],
      fileName: exportFileName(),
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <TextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t("searchMetricsPlaceholder")}
          className={classes.search}
          InputProps={{
            startAdornment: <SearchOutlined className={classes.icon} />,
          }}
        />
        {exportFileName && (
          <ToggleIconButton
            size="large"
            variant="outlined"
            onClick={exportData}
            disabled={!filteredMetrics.length}
          >
            <DownloadSolid />
          </ToggleIconButton>
        )}
      </div>
      <TableContainer className={classes.table}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="30%" className={classes.header}>
                <Typography>{t("metric")}</Typography>
              </TableCell>
              <TableCell width="70%" className={classes.header}>
                {formatDate(crawlDate, {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!filteredMetrics.length ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <MetricTableOverlay
                    variant="warning"
                    title={t("noMetricFoundTitle")}
                    description={t("noMetricFoundDescription")}
                  />
                </TableCell>
              </TableRow>
            ) : (
              filteredMetrics.map(({ code, name, description }) => (
                <TableRow key={code} className={classes.row}>
                  <TableCell className={classes.nameCell}>
                    <Tooltip title={description || ""}>
                      <Typography variant="inherit">{name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.valueCell}>
                    <MetricsValuePresenter
                      metrics={metrics}
                      code={code}
                      componentProps={{
                        showCopy: true,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
