import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { RequiredSources } from "../../types/ChartConfigItemBase";
import { ChartErrorGuard } from "./ChartErrorGuard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "100%",
    width: "100%",
    padding: theme.spacing(2.875, 2.5),
    overflow: "hidden",
  },
}));

interface Props {
  children: React.ReactNode;
  requiredSources?: RequiredSources;
}

export function ChartPanelContent({
  children,
  requiredSources,
  ...props
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...props}>
      <ChartErrorGuard requiredSources={requiredSources}>
        {children}
      </ChartErrorGuard>
    </Box>
  );
}
