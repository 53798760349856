import { useTranslation } from "@lumar/shared";
import React from "react";
import { useSizeFormatter } from "../../_common/locale/format-size/useSizeFormatter";

export function useMetricValueUnitGetter(): (
  unit: string,
  count: number,
) => { count: number; unit: string } {
  const { t } = useTranslation("units");
  const formatSize = useSizeFormatter();

  return React.useCallback(
    (unit: string, count: number) => {
      switch (unit) {
        case "percent":
          return { count, unit: t("percentUnit", { count }) };
        case "byte":
          const size = formatSize(count);
          return { count: size.value, unit: size.unit };
        case "character":
          return { count, unit: t("characterUnit", { count }) };
        case "pixel":
          return { count, unit: t("pixelUnit", { count }) };
        case "word":
          return { count, unit: t("word", { count }) };
        case "second":
          return { count, unit: t("second", { count }) };
        default:
          return { count, unit };
      }
    },
    [t, formatSize],
  );
}
