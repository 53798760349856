import React from "react";
import Papa from "papaparse";
import { useTranslation } from "@lumar/shared";

import { PreviewScreenshot } from "./PreviewScreenshot";
import { PreviewResponseHeaders } from "./PreviewResponseHeaders";
import { PreviewHTMLContent } from "./PreviewHTMLContent";
import { ProjectPreviewResult } from "./useProjectPreview";

type Result = {
  label: string;
  component: JSX.Element;
  export: () => Promise<void>;
  exportDisabled: boolean;
  testId: string;
}[];

export function usePreviewTabs(
  url: string,
  data: ProjectPreviewResult["data"],
): Result {
  const { t } = useTranslation("crawlSettings");

  return [
    {
      label: t("previewTabScreenshot"),
      component: <PreviewScreenshot screenshot={data?.screenshot} />,
      export: async () => {
        if (!data?.screenshot) return;

        const objectUrl = window.URL.createObjectURL(data.screenshot);
        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);
        link.setAttribute(
          "download",
          getPreviewExportName(url) + "_Screenshot.jpg",
        );
        link.click();
        link.remove();
      },
      exportDisabled: !data?.screenshot,
      testId: "project-preview-tab-screenshot",
    },
    {
      label: t("previewTabRenderedHTML"),
      component: (
        <PreviewHTMLContent
          content={data?.renderedHTML}
          key="rendered"
          data-testid="project-preview-rendered-html"
        />
      ),
      export: async () => {
        if (!data?.renderedHTML) return;

        const objectURL = URL.createObjectURL(
          new Blob([data.renderedHTML], { type: "plain/text" }),
        );

        const link = document.createElement("a");
        link.setAttribute("href", objectURL);
        link.setAttribute(
          "download",
          getPreviewExportName(url) + "_RenderedHTML.html",
        );
        link.click();
        link.remove();
      },
      exportDisabled: !data?.renderedHTML,
      testId: "project-preview-tab-rendered-html",
    },
    {
      label: t("previewTabStaticHTML"),
      component: (
        <PreviewHTMLContent
          content={data?.staticHTML}
          key="static"
          data-testid="project-preview-static-html"
        />
      ),
      export: async () => {
        if (!data?.staticHTML) return;

        const objectURL = URL.createObjectURL(
          new Blob([data.staticHTML], { type: "plain/text" }),
        );

        const link = document.createElement("a");
        link.setAttribute("href", objectURL);
        link.setAttribute(
          "download",
          getPreviewExportName(url) + "_StaticHTML.html",
        );
        link.click();
        link.remove();
      },
      exportDisabled: !data?.staticHTML,
      testId: "project-preview-tab-static-html",
    },
    {
      label: t("previewTabResponseHeaders"),
      component: <PreviewResponseHeaders headers={data?.responseHeaders} />,
      export: async () => {
        if (!data?.responseHeaders) return;

        const objectURL = URL.createObjectURL(
          new Blob(
            [
              "\ufeff" +
                Papa.unparse([
                  ["Header Name", "Header Value"],
                  ...Object.entries(data.responseHeaders),
                ]),
            ],
            {
              type: "text/csv",
            },
          ),
        );

        const link = document.createElement("a");
        link.setAttribute("href", objectURL);
        link.setAttribute(
          "download",
          getPreviewExportName(url) + "_ResponseHeaders.csv",
        );
        link.click();
        link.remove();
      },
      exportDisabled: !data?.responseHeaders,
      testId: "project-preview-tab-response-headers",
    },
  ];
}

function getPreviewExportName(primaryDomain: string): string {
  const domainName = primaryDomain
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .replace(".", "-")
    .split(/[/?#]/)[0];

  return `${domainName}`;
}
