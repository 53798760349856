import {
  ArrowLeftSolid,
  Button,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";
import { useSearchParam } from "../../_common/routing/useSearchParam";
import { TopNavigation } from "../../_common/top-navigation/TopNavigation";
import { RoleCode, useGetProjectNameQuery } from "../../graphql";

const useStyles = makeStyles(() => ({
  button: {
    background: "white",
  },
}));

export function CustomReportTemplateManagerHeader(): JSX.Element {
  const { projectId, accountId, crawlId } = useParams<{
    crawlId: string;
    projectId: string;
    accountId: string;
  }>();
  const fromProjectSettings = useSearchParam("from") === "projectSettings";

  const { t } = useTranslation("customReports");
  const classes = useStyles();
  const history = useHistory();
  const { hasSufficientRole } = useSession();

  const { data } = useGetProjectNameQuery({
    variables: { projectId: btoa("N007Project" + projectId) },
  });

  const projectName = data?.getProject?.name;

  const goBack = (): void => {
    if (fromProjectSettings) {
      history.push(
        Routes.Crawls.getUrl({
          accountId,
          projectId,
          tab: "edit",
          step: 4,
        }),
      );
    } else {
      history.push(
        Routes.CustomReports.getUrl({ accountId, projectId, crawlId }),
      );
    }
  };

  return (
    <TopNavigation
      title={t("customReportTemplatesHeader")}
      breadcrumbs={[{ label: t("customReportTemplatesHeader") }]}
      loading={!projectName}
      actions={
        hasSufficientRole(RoleCode.Editor) ? (
          <Button
            key="custom-report-manager-back"
            onClick={goBack}
            variant="outlined"
            size="medium"
            startIcon={<ArrowLeftSolid />}
            className={classes.button}
            data-testid="custom-report-manager-back"
          >
            {fromProjectSettings ? t("projectSettings") : t("customReports")}
          </Button>
        ) : undefined
      }
    />
  );
}
