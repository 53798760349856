import { categoryIconConfig } from "@lumar/shared";
import { CrawlReportCategoryListNode } from "../../CrawlContext";
import { Tooltip, useTheme } from "@material-ui/core";

export function CategoryIconCell({
  category,
}: {
  category?: CrawlReportCategoryListNode;
}): JSX.Element | null {
  const theme = useTheme();

  const CategoryIcon =
    categoryIconConfig.get(category?.code || "") ??
    categoryIconConfig.get(category?.parentCode || "");

  if (!CategoryIcon) return null;

  return (
    <Tooltip title={category?.name || ""}>
      <CategoryIcon
        fontSize="small"
        style={{ color: theme.palette.grey[700] }}
      />
    </Tooltip>
  );
}
