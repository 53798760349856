// This function is the modified version of the MUI DataGrid's buildCSV function
// https://github.com/mui/mui-x/blob/v4.0.2/packages/grid/_modules_/grid/hooks/features/export/serializers/csvSerializer.ts
// The main difference comapred to the base code is that this function always uses
// double quotation marks for string values. This allows us to use new line characters
// for cell values and also makes csv exports more consistent with API exports.
export function buildCSV({ columns, rowIds, getCellParams, delimiterCharacter, includeHeaders, }) {
    const CSVBody = rowIds
        .reduce((acc, id) => `${acc}${serialiseRow(id, columns, getCellParams).join(delimiterCharacter)}\r\n`, "")
        .trim();
    if (!includeHeaders) {
        return CSVBody;
    }
    const CSVHead = `${columns
        .filter((column) => column.field !== "__check__")
        .map((column) => serialiseCellValue(column.headerName || column.field))
        .join(delimiterCharacter)}\r\n`;
    return `${CSVHead}${CSVBody}`.trim();
}
function serialiseRow(id, columns, getCellParams) {
    return columns.map((column) => serialiseCellValue(getCellParams(id, column.field).formattedValue));
}
function serialiseCellValue(value) {
    if (typeof value === "string") {
        const formattedValue = value.replace(/"/g, '""');
        return `"${formattedValue}"`;
    }
    return value;
}
