/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { addSpaceBeforeCapitalLetter } from "../../../../../../_common/crawl-settings-comparison/compareSettingsResults";

export function ObjectPresenter(props: {
  value: any;
  chipClassName?: string;
}): JSX.Element {
  const { __typename, ...rest } = props.value;

  const entries = Object.entries(rest);

  if (entries.length === 0) {
    return <></>;
  }

  return (
    <div className={props.chipClassName}>
      {entries.map(([key, value]) => (
        <div key={key}>
          {addSpaceBeforeCapitalLetter(key)}:&nbsp;
          <span style={{ fontWeight: 400 }}>{value as any}</span>
        </div>
      ))}
    </div>
  );
}
