import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import { animated, useSpring } from "react-spring";
import { easeCubicInOut } from "d3-ease";
import { useTranslation } from "@lumar/shared";
import { translationNamespace } from "../../CrawlSettings";

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  arrowButton: {
    color: "#6B7280",
    padding: 12,
    borderStyle: "none",
    borderRadius: "50%",
    background: "transparent",
    cursor: "pointer",
    zIndex: 1,
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  arrowButtonDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "unset",
    "&:hover": {
      background: "transparent",
    },
  },
  arrow: {
    width: 24,
    height: 24,
    fill: "currentColor",
  },
  headerLabel: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "0.8125rem",
    lineHeight: "1.66",
    marginLeft: 26,
  },
  conatiner: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  button: {
    color: "#111827",
    width: 36,
    height: 36,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    fontWeight: 400,
    background: "transparent",
    borderStyle: "none",
    borderRadius: "50%",
    marginLeft: "2px",
    marginRight: "2px",
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  buttonActive: {
    color: "white",
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  buttonDisabled: {
    color: "rgba(0, 0, 0, 0.38)",
    cursor: "unset",
    "&:hover": {
      background: "transparent",
      "&$buttonActive": {
        background: theme.palette.primary.main,
      },
    },
  },
}));

export function TimePicker({
  value,
  onChange,
  noMinutes,
  minValue,
}: {
  value?: Date;
  onChange: (value: Date) => void;
  noMinutes?: boolean;
  minValue?: Date;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(translationNamespace);

  const valueResolved = value ?? new Date();

  const activeHours = valueResolved?.getHours();
  const activeMinutes = valueResolved?.getMinutes();

  const [am, setAm] = React.useState(activeHours < 12);

  const amStyle = useSpring(
    am
      ? {
          to: { transform: "translate(0%)", opacity: 1 },
          config: { duration: 350, easing: easeCubicInOut },
        }
      : {
          to: { transform: "translate(-100%)", opacity: 0 },
          config: { duration: 350, easing: easeCubicInOut },
        },
  );
  const pmStyle = useSpring(
    am
      ? {
          to: { transform: "translate(100%)", opacity: 0 },
          config: { duration: 350, easing: easeCubicInOut },
        }
      : {
          to: { transform: "translate(0%)", opacity: 1 },
          config: { duration: 350, easing: easeCubicInOut },
        },
  );

  function formatTime(value: number): string {
    return value.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  return (
    <div style={{ width: 270, minHeight: 216, overflow: "hidden" }}>
      <div className={classes.header}>
        <button
          onClick={() => setAm(true)}
          disabled={am}
          className={clsx(classes.arrowButton, {
            [classes.arrowButtonDisabled]: am,
          })}
        >
          <svg className={classes.arrow}>
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
          </svg>
        </button>
        <animated.div style={amStyle} className={classes.headerLabel}>
          {t("timePicker.am")}
        </animated.div>
        <animated.div style={pmStyle} className={classes.headerLabel}>
          {t("timePicker.pm")}
        </animated.div>
        <button
          onClick={() => setAm(false)}
          disabled={!am}
          className={clsx(classes.arrowButton, {
            [classes.arrowButtonDisabled]: !am,
          })}
        >
          <svg className={classes.arrow}>
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </button>
      </div>
      <span className={classes.label}>{t("timePicker.hours")}</span>
      <animated.div
        style={{ height: 0, ...amStyle }}
        className={classes.conatiner}
      >
        {Array.from(Array(12).keys()).map((x) => {
          const hours = x;
          const value = new Date(valueResolved);
          value.setHours(hours);

          const active = hours === activeHours;
          const disabled = minValue ? value < minValue : false;

          return (
            <button
              key={hours}
              onClick={() => onChange(value)}
              disabled={disabled}
              className={clsx(classes.button, {
                [classes.buttonActive]: active,
                [classes.buttonDisabled]: disabled,
              })}
            >
              {formatTime(x === 0 ? 12 : x)}
            </button>
          );
        })}
      </animated.div>
      <animated.div style={{ ...pmStyle }} className={classes.conatiner}>
        {Array.from(Array(12).keys()).map((x) => {
          const hours = x + 12;
          const value = new Date(valueResolved);
          value.setHours(hours);

          const active = hours === activeHours;
          const disabled = minValue ? value < minValue : false;

          return (
            <button
              key={hours}
              onClick={() => onChange(value)}
              disabled={disabled}
              className={clsx(classes.button, {
                [classes.buttonActive]: active,
                [classes.buttonDisabled]: disabled,
              })}
            >
              {formatTime(x === 0 ? 12 : x)}
            </button>
          );
        })}
      </animated.div>
      {!noMinutes && (
        <>
          <span className={classes.label}>{t("timePicker.minutes")}</span>
          <div className={classes.conatiner}>
            {Array.from(Array(12).keys()).map((x) => {
              const minutes = x * 5;
              const value = new Date(valueResolved);
              value.setMinutes(minutes);

              const active = minutes === activeMinutes;
              const disabled = minValue ? value < minValue : false;

              return (
                <button
                  key={minutes}
                  onClick={() => onChange(value)}
                  disabled={disabled}
                  className={clsx(classes.button, {
                    [classes.buttonActive]: active,
                    [classes.buttonDisabled]: disabled,
                  })}
                >
                  {formatTime(minutes)}
                </button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
