import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { Alert } from "@lumar/shared";

import { SettingControlFactory } from "../useAdvancedControls";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { Label } from "../../../../_common/components/Label";
import { SettingsContext } from "../data/useContextValues";

export const getSaveHTMLAndScreenshotsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.saveHTMLAndScreenshots.title"),
  path: "saveHTMLAndScreenshots",
  // eslint-disable-next-line react/display-name
  control: () => <SaveHTMLAndScreenshots />,
  testId: "crawl-settings-advanced-email-alerts",
  textForSearch: createTextForSearch([
    t("settings.saveHTMLAndScreenshots.title"),
    t("settings.saveHTMLAndScreenshots.description"),
    t("settings.saveHTMLAndScreenshots.alert"),
    t("settings.saveHTMLAndScreenshots.saveHTMLLabel"),
    t("settings.saveHTMLAndScreenshots.saveScreenshotLabel"),
  ]),
  activeValues: ["saveHTML", "saveScreenshot"],
});

const useStyles = makeStyles((theme) => ({
  label: {
    width: "fit-content",
  },
  description: {
    width: "100%",
  },
  saveHTML: {
    marginTop: theme.spacing(2),
  },
}));

export const SaveHTMLAndScreenshots = React.memo(SaveHTMLAndScreenshotsInner);

function SaveHTMLAndScreenshotsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const { globalContainers, module } = React.useContext(SettingsContext);
  const classes = useStyles();

  const screenshotContainer = globalContainers?.find(
    (e) => e.name === "ScreenshotStoring",
  );
  const screenshotPrice =
    screenshotContainer?.costs?.find((e) => e.moduleCode === module.code)
      ?.cost ?? 0;
  const htmlContainer = globalContainers?.find((e) => e.name === "HtmlStoring");
  const htmlPrice =
    htmlContainer?.costs?.find((e) => e.moduleCode === module.code)?.cost ?? 0;

  return (
    <>
      <Typography variant="caption" className={classes.description}>
        {t("settings.saveHTMLAndScreenshots.description")}
      </Typography>
      <Alert severity="info" size="small">
        {t("settings.saveHTMLAndScreenshots.alert")}
      </Alert>
      <Field
        name="report.saveHTMLAndScreenshots.saveHTML"
        component={CheckboxWithLabel}
        type="checkbox"
        disabled={!Boolean(htmlContainer)}
        className={classes.saveHTML}
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.saveHTMLAndScreenshots.saveHTMLLabel",
                {
                  price: t(
                    "crawlSettings:settings.saveHTMLAndScreenshots.credit",
                    { count: htmlPrice },
                  ),
                },
              )}
            />
          ),
        }}
        data-testid="rendering-robots-enabled"
      />
      <Field
        name="report.saveHTMLAndScreenshots.saveScreenshot"
        component={CheckboxWithLabel}
        type="checkbox"
        disabled={!Boolean(screenshotContainer)}
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.saveHTMLAndScreenshots.saveScreenshotLabel",
                {
                  price: t(
                    "crawlSettings:settings.saveHTMLAndScreenshots.credit",
                    { count: screenshotPrice },
                  ),
                },
              )}
            />
          ),
        }}
        data-testid="rendering-robots-enabled"
      />
    </>
  );
}
