import { useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function DuplicatePresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("resourceDetail");

  return <>{value ? t("duplicate") : t("unique")}</>;
}
