import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ClockSolid,
  Select,
  DateFormatter,
  useDateFormatter,
} from "@lumar/shared";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
  ExpirationOption,
  createDateFromExpirationOption,
} from "./useShareLink";

const useStyles = makeStyles((theme) => ({
  select: {
    width: 200,
  },
  icon: {
    color: theme.palette.grey[400],
  },
}));

interface ExpirationDateDropdownProps {
  onChange: (expiration: ExpirationOption) => void;
  value: ExpirationOption;
  disabled?: boolean;
}

export function ExpirationDateDropdown({
  value,
  onChange,
  disabled,
}: ExpirationDateDropdownProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("createShareLink");
  const dateFormatter = useDateFormatter();

  const [hover, setHover] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Tooltip
      title={getTooltip(t, value, dateFormatter)}
      open={hover && !disabled}
      disableHoverListener
    >
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value as ExpirationOption)}
        onOpen={() => setHover(false)}
        onMouseEnter={() => {
          // Note: The menu element triggers a mouseenter event on close,
          // even if the mouse is outside of the element.
          if (!menuRef.current) setHover(true);
        }}
        onMouseLeave={() => setHover(false)}
        MenuProps={{ ref: menuRef }}
        disabled={disabled}
        label={t("setExpiration")}
        startIcon={<ClockSolid className={classes.icon} />}
        dividerLeft
        className={classes.select}
        data-testid="set-expiration-date-dropdown"
      >
        {Object.values(ExpirationOption).map((expiration) => (
          <MenuItem key={expiration} value={expiration}>
            {getTranslation(t, expiration)}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
}

function getTranslation(
  t: TFunction<"createShareLink">,
  expiration: ExpirationOption,
): string {
  switch (expiration) {
    case ExpirationOption.Day:
      return t("expiresInADay");
    case ExpirationOption.Month:
      return t("expiresInAMonth");
    case ExpirationOption.Never:
      return t("neverExpires");
    case ExpirationOption.SixMonths:
      return t("expiresInSixMonths");
    case ExpirationOption.Week:
      return t("expiresInAWeek");
    default:
      return "";
  }
}

function getTooltip(
  t: TFunction<"createShareLink">,
  expiration: ExpirationOption,
  dateFormatter: DateFormatter,
): string {
  if (expiration === ExpirationOption.Never) return t("neverExpires");

  return t("expiresAtMessage", {
    date: dateFormatter(createDateFromExpirationOption(expiration), {
      dateStyle: "medium",
      timeStyle: "short",
    }),
  });
}
