/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getRedirectIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("redirectIssuesChart.title"),
    description: () => t("redirectIssuesChart.description"),
    reportTemplateCodesOrder: [
      "redirection_loop",
      "redirect_chains",
      "backlink_pages_redirects",
      "redirecting_pages_in_serp",
      "redirecting_pages_with_visits",
      "internal_redirects_found_in_web",
      "excessive_redirects_in",
    ],
    reportStatFilter: (report) => {
      return [
        "redirection_loop",
        "redirect_chains",
        "backlink_pages_redirects",
        "redirecting_pages_in_serp",
        "redirecting_pages_with_visits",
        "internal_redirects_found_in_web",
        "excessive_redirects_in",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
