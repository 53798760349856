import {
  DocumentNode,
  MutationHookOptions,
  OperationVariables,
  gql,
  useApolloClient,
} from "@lumar/shared";
import { VariableType, jsonToGraphQLQuery } from "json-to-graphql-query";
import {
  CrawlType,
  GetCrawlSettingsForSourcesQuery,
} from "../../../../graphql";
import { FormValues, MutationData } from "./types";
import { insertIf } from "../../../../_common/insertIf";
import { getGoogleAnalytics4MutationData } from "./mutation-data/getGoogleAnalytics4MutationData";
import { getMajesticMutationData } from "./mutation-data/getMajesticMutationData";
import { getGoogleSearchConsoleMutationData } from "./mutation-data/getGoogleSearchConsoleMutationData";
import { getAdobeAnalyticsMutationData } from "./mutation-data/getAdobeAnalyticsMutationData";

interface Variables {
  projectId: string;
  values: FormValues;
  initialValues: FormValues;
  data: GetCrawlSettingsForSourcesQuery | undefined;
  updateConversionEvents: boolean;
}

export function useUpdateSourcesMutation(): (
  props: Omit<MutationHookOptions, "variables"> & { variables: Variables },
) => Promise<unknown> {
  const client = useApolloClient();

  return ({ variables, ...props }) => {
    const [document, documentVariables] = getDocument(variables);

    return client.mutate({
      ...props,
      mutation: document,
      variables: documentVariables,
    });
  };
}

function getDocument({
  projectId,
  values,
  initialValues,
  data,
  updateConversionEvents,
}: Variables): [DocumentNode, OperationVariables] {
  const [variableDefinitions, documents, variables] = merge([
    getMajesticMutationData(projectId, values, data),
    getGoogleSearchConsoleMutationData(projectId, values, data),
    ...getGoogleAnalytics4MutationData(
      projectId,
      values,
      initialValues,
      updateConversionEvents,
    ),
    ...getAdobeAnalyticsMutationData(projectId, values, data),
  ]);

  const mutation = {
    mutation: {
      __name: "UpdateSources",
      __variables: {
        projectId: "ObjectID!",
        crawlTypes: "[CrawlType!]!",
        dataOnlyCrawlTypes: "[CrawlType!]",
        startUrls: "[String!]!",
        discoverSitemapsInRobotsTxt: "Boolean!",
        ...variableDefinitions,
      },
      updateProject: {
        __args: {
          input: {
            projectId: new VariableType("projectId"),
            crawlTypes: new VariableType("crawlTypes"),
            dataOnlyCrawlTypes: new VariableType("dataOnlyCrawlTypes"),
            startUrls: new VariableType("startUrls"),
            discoverSitemapsInRobotsTxt: new VariableType(
              "discoverSitemapsInRobotsTxt",
            ),
          },
        },
        project: {
          id: true,
        },
      },
      ...documents,
    },
  };

  const document = gql`
    ${jsonToGraphQLQuery(mutation, {
      pretty: true,
    })}
  `;

  return [
    document,
    {
      projectId: projectId,
      crawlTypes: getCrawlTypes(values),
      dataOnlyCrawlTypes: getDataOnlyCrawlTypes(values),
      startUrls: values.website.startUrls,
      discoverSitemapsInRobotsTxt: values.sitemaps.discoverSitemapsInRobotsTxt,
      ...variables,
    },
  ];
}

function getCrawlTypes(values: FormValues): CrawlType[] {
  return [
    ...insertIf(values.crawlType.website, CrawlType.Web),
    ...insertIf(values.crawlType.sitemaps, CrawlType.Sitemap),
    ...insertIf(values.crawlType.backlinks, CrawlType.Backlinks),
    ...insertIf(
      values.crawlType.googleSearchConsole,
      CrawlType.GoogleSearchConsole,
    ),
    ...insertIf(values.crawlType.analytics, CrawlType.GoogleAnalytics),
    ...insertIf(values.crawlType.logSummary, CrawlType.LogSummary),
    ...insertIf(values.crawlType.urlLists, CrawlType.List),
  ];
}

function getDataOnlyCrawlTypes(values: FormValues): CrawlType[] | null {
  const dataOnlyCrawlTypes = [
    ...insertIf(values.dataOnlyCrawlType.sitemaps, CrawlType.Sitemap),
    ...insertIf(values.dataOnlyCrawlType.backlinks, CrawlType.Backlinks),
    ...insertIf(
      values.dataOnlyCrawlType.googleSearchConsole,
      CrawlType.GoogleSearchConsole,
    ),
    ...insertIf(values.dataOnlyCrawlType.analytics, CrawlType.GoogleAnalytics),
    ...insertIf(values.dataOnlyCrawlType.logSummary, CrawlType.LogSummary),
    ...insertIf(values.dataOnlyCrawlType.urlLists, CrawlType.List),
  ];

  if (dataOnlyCrawlTypes.length) {
    return dataOnlyCrawlTypes;
  }

  return null;
}

function merge(data: (MutationData | undefined)[]): MutationData {
  return data.reduce<MutationData>(
    (prev, current) => [
      { ...prev[0], ...current?.[0] },
      { ...prev[1], ...current?.[1] },
      { ...prev[2], ...current?.[2] },
    ],
    [{}, {}, {}],
  );
}
