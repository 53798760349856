import { ExternalLinkSolid, useTranslation } from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";
import { camelCase } from "lodash";
import {
  NavLink,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { sanitizeDigestForPageId } from "../../../_common/routing/sanitizeDigestForPageId";
import { DatasourceCode } from "../../../graphql";
import { Metrics, MetricsValuePresenterProps } from "../../data/types";

const useStyles = makeStyles((theme) => ({
  link: { color: theme.palette.blue[600], whiteSpace: "normal" },
  externalLink: {
    marginLeft: 4,
    position: "relative",
    color: theme.palette.blue[600],
  },
  icon: {
    fontSize: 14,
    verticalAlign: "sub",
  },
}));

export function UrlPresenter(
  props: MetricsValuePresenterProps,
): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation("report");

  const { resourceDetailUrl, externalUrl } = useResourceDetailUrls(props);

  if (!props.value) return <>-</>;

  return (
    <>
      {resourceDetailUrl ? (
        <NavLink to={resourceDetailUrl} className={classes.link}>
          {props.value}
        </NavLink>
      ) : (
        <>{props.value}</>
      )}
      {externalUrl && (
        <Tooltip title={t("openNewTab")} arrow>
          <a
            href={externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.externalLink}
          >
            <ExternalLinkSolid className={classes.icon} />
          </a>
        </Tooltip>
      )}
    </>
  );
}

export function useResourceDetailUrls({
  value,
  code,
  metrics,
  crawlId: crawlIdOverwrite,
  reportTemplateCode,
}: MetricsValuePresenterProps & {
  crawlId?: string;
  reportTemplateCode?: string;
}): {
  resourceDetailUrl: string | undefined;
  externalUrl: string | undefined;
} {
  const {
    accountId,
    projectId,
    crawlId: crawlIdParam,
    reportTemplateCodeWithTypeCode: reportTemplateCodeWithTypeCodeParam,
    customReportTemplateId,
  } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
    reportTemplateCodeWithTypeCode?: string;
    customReportTemplateId?: string;
  }>();
  const location = useLocation();
  const crawlId = crawlIdOverwrite ?? crawlIdParam;

  const search = new URLSearchParams(location.search);
  const reportTypeQuery = search.get("reportType");
  const reportTemplateCodeQuery = search.get("reportTemplateCode");

  const reportTemplateCodeWithTypeCode =
    reportTemplateCodeWithTypeCodeParam ??
    [reportTemplateCodeQuery, reportTypeQuery].join("_");

  const isReportStatPage = Boolean(
    useRouteMatch({ path: Routes.Report.ROUTE, exact: true }),
  );
  const isCustomReportPage = Boolean(
    useRouteMatch({ path: Routes.CustomReport.ROUTE, exact: true }),
  );

  function getResourceDetailUrl(): string | undefined {
    const { resourceId, sourceReportTemplateCode, datasourceCode } =
      getResourceProps(metrics, code);

    if (!resourceId) return;

    return Routes.ResourceDetail.getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCodeWithTypeCode,
      resourceId: sanitizeDigestForPageId(resourceId),
      sourceReportTemplateCode,
      type:
        isReportStatPage || isCustomReportPage
          ? getDetailType(metrics, code, datasourceCode, reportTemplateCode)
          : undefined,
      customReportTemplateId,
    });
  }

  const type = metrics[code]?.data?.metadata?.stringType;
  const isUrl = type === "url" || type === "example_url";

  return {
    resourceDetailUrl: getResourceDetailUrl(),
    externalUrl: isUrl ? value : undefined,
  };
}

function getResourceProps(
  metrics: Metrics,
  code: string,
): {
  resourceId?: string;
  sourceReportTemplateCode?: string;
  datasourceCode?: DatasourceCode;
} {
  const digestMetric =
    metrics[camelCase(metrics[code].data?.metadata?.digestMetric || "")];
  const reportTemplateCode = metrics[code].data?.metadata?.reportTemplateCode;
  if (digestMetric?.value) {
    return {
      resourceId: digestMetric.value,
      sourceReportTemplateCode: reportTemplateCode ?? undefined,
      datasourceCode: digestMetric.datasourceCode,
    };
  }

  const digest = metrics[`${code}Digest`];
  if (digest?.value) {
    return {
      resourceId: digest.value,
      datasourceCode: digest.datasourceCode,
    };
  }

  return {};
}

function getDetailType(
  metrics: Metrics,
  code: string,
  datasourceCode: DatasourceCode | undefined,
  reportTemplateCode: string | undefined,
): string | undefined {
  const auditId = metrics["auditId"]?.value;

  if (
    datasourceCode === DatasourceCode.CrawlSiteSpeedAudits &&
    code === "url"
  ) {
    return reportTemplateCode === "crawl_site_speed_audits"
      ? "site-speed-all-audits"
      : auditId;
  }

  if (
    datasourceCode === DatasourceCode.CrawlSiteSpeedAuditOpportunities &&
    code === "exampleUrl"
  ) {
    return reportTemplateCode === "crawl_site_speed_audit_opportunities"
      ? "site-speed-all-audits"
      : auditId;
  }

  if (
    datasourceCode === DatasourceCode.CrawlSiteSpeedAuditItems &&
    code === "url"
  ) {
    return reportTemplateCode === "crawl_site_speed_audit_items"
      ? "site-speed-all-audits"
      : auditId;
  }
}
