import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    text: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
        fontFamily: theme.typography.fontFamily,
    },
}));
export const ColumnsTitles = ({ elementWidth, xScale, dataset, }) => {
    const classes = useStyles();
    return (_jsx("g", { transform: "translate(0, 24)", children: dataset.map((d, i) => {
            var _a;
            return (_jsx("text", { width: elementWidth, height: 16, x: ((_a = xScale(i.toString())) !== null && _a !== void 0 ? _a : 0) + elementWidth / 2, textAnchor: "middle", className: classes.text, children: d.title }, d.title));
        }) }));
};
