import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedCLSChart } from "../../../../../_common/charts/config/getSiteSpeedCLSChart";
import { getSiteSpeedCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCLSTrend";
import { getSiteSpeedCLSErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedCLSErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAllReportsTable } from "../../../../../_common/charts/config/getSiteSpeedAllReportsTable";
import { getSiteSpeedAvgCLSChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCLSChart";
import { getSiteSpeedAvgCruxCLSChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCruxCLSChart";
import { getSiteSpeedCruxCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCruxCLSTrend";

export const generateSiteSpeedCumulativeLayoutShiftCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedCLSErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    allReportsTable: getSiteSpeedAllReportsTable,
    mainCharts: [
      // getSiteSpeedCLSMetricTrend,
      getSiteSpeedAvgCLSChart,
      getSiteSpeedAvgCruxCLSChart,
      getSiteSpeedCLSTrend,
      getSiteSpeedCruxCLSTrend,
      getSiteSpeedCLSChart,
    ],
  };
