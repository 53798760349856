/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getBacklinkIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    requiredSources: {
      sources: [CrawlType.Backlinks],
      incompleteMessage: t("requiredSourcesMessage.backlinksSource"),
    },
    title: () => t("backlinkIssues.title"),
    description: () => t("backlinkIssues.description"),
    reportTemplateCodesOrder: [
      "backlink_pages_with_errors",
      "backlink_pages_disallowed_uncrawled",
      "backlink_pages_disallowed",
      "backlink_pages_non_indexable",
      "backlink_pages_no_links_out",
      "backlink_pages_with_nofollow",
      "backlink_pages_redirects",
    ],
    reportStatFilter: (report) => {
      return [
        "backlink_pages_with_errors",
        "backlink_pages_disallowed_uncrawled",
        "backlink_pages_disallowed",
        "backlink_pages_non_indexable",
        "backlink_pages_no_links_out",
        "backlink_pages_with_nofollow",
        "backlink_pages_redirects",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
