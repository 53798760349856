import { VariableType } from "json-to-graphql-query";
import { GetCrawlSettingsForSourcesQuery } from "../../../../../graphql";
import { FormValues, MutationData } from "../types";

export function getMajesticMutationData(
  projectId: string,
  values: FormValues,
  data: GetCrawlSettingsForSourcesQuery | undefined,
): MutationData | undefined {
  const majesticValues = values.backlinks.majestic;
  if (!majesticValues) return;

  const majesticInput = {
    projectId,
    enabled: majesticValues.enabled,
    maxRows: convertNumber(majesticValues.maxRows) ?? 1,
    useRootDomain: majesticValues.useRootDomain === "true",
    useHistoricData: majesticValues.useHistoricData === "true",
  };

  const hasMajesticConfig = Boolean(data?.getProject?.majesticConfiguration);

  const shouldCreateMajesticConfig =
    !hasMajesticConfig && majesticValues.enabled;
  if (shouldCreateMajesticConfig) {
    return [
      { majesticInput: "CreateMajesticConfigurationInput!" },
      {
        createMajesticConfiguration: {
          __args: {
            input: new VariableType("majesticInput"),
          },
          majesticConfiguration: {
            enabled: true,
          },
        },
      },
      { majesticInput },
    ];
  }

  const shouldUpdateMajesticConfig = hasMajesticConfig;
  if (shouldUpdateMajesticConfig) {
    return [
      { majesticInput: "UpdateMajesticConfigurationInput!" },
      {
        updateMajesticConfiguration: {
          __args: {
            input: new VariableType("majesticInput"),
          },
          majesticConfiguration: {
            enabled: true,
          },
        },
      },
      {
        majesticInput: majesticValues.enabled
          ? majesticInput
          : { projectId, enabled: false },
      },
    ];
  }
}

function convertNumber(value: string): number | null {
  if (!value) return null;
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? null : convertedValue;
}
