import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityTablesBPChart } from "../../../../../_common/charts/config/getAccessibilityTablesBPChart";
import { getAccessibilityTablesBPTrend } from "../../../../../_common/charts/config/getAccessibilityTablesBPTrend";

export const generateAccessibilityTablesBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityTablesBPChart,
      getAccessibilityTablesBPTrend,
    ],
  };
