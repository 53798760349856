import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import { Trans } from "react-i18next";

import { ManualUploads } from "../manual-uploads/ManualUploads";
import { FormSubmitContext } from "../../components/FormSubmitContext";
import { CrawlType } from "../../../../graphql";
import { useBrand, useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  preline: {
    whiteSpace: "pre-line",
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

export function LogSummaryManualUploads(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("navigation");
  const brand = useBrand();

  const isSupportEmail = brand.supportLink?.includes("@");

  const downloadLink = "/files/ExampleLogSummaryUpload.csv";
  const supportedFileTypesLink = t("supportedUploadFileTypesProductGuideLink");

  return (
    <Box className={classes.root}>
      <Typography
        variant="caption"
        className={clsx(classes.preline, classes.indent)}
      >
        <Trans
          ns="crawlSettings"
          i18nKey="sources.logSummary.manualUploads.description"
          components={{
            supportedFileTypes: (
              <Link
                target="_blank"
                href={supportedFileTypesLink}
                className={classes.link}
              />
            ),
            exampleUpload: (
              <Link
                href={downloadLink}
                target="_self"
                download
                className={classes.link}
              />
            ),
            supportLink: (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  isSupportEmail
                    ? `mailto:${brand.supportLink}`
                    : brand.supportLink
                }
              />
            ),
          }}
        />
      </Typography>
      <FormSubmitContext>
        {({ isSubmitting }) => (
          <ManualUploads
            crawlType={CrawlType.LogSummary}
            disabled={isSubmitting}
            acceptedFiles={[".csv"]}
          />
        )}
      </FormSubmitContext>
    </Box>
  );
}
