import React from "react";
import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { getProperty, TagInput } from "@lumar/shared";
import { uniq } from "lodash";

import { SettingsContext } from "../data/useContextValues";
import { SettingControlFactory } from "../useAdvancedControls";

export const getEmailAlertsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.emailAlerts.title"),
  path: "emailAlerts",
  // eslint-disable-next-line react/display-name
  control: () => <EmailAlerts />,
  testId: "crawl-settings-advanced-email-alerts",
  textForSearch: createTextForSearch([
    t("settings.emailAlerts.title"),
    t("settings.emailAlerts.description"),
    t("settings.emailAlerts.placeholder"),
  ]),
  fieldNames: [["emails", t("settings.emailAlerts.emailsField")]],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
}));

export const EmailAlerts = React.memo(EmailAlertsInner);

function EmailAlertsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  return (
    <>
      <Typography variant="caption">
        {t("settings.emailAlerts.description")}
      </Typography>
      <FastField name="report.emailAlerts.settingsCode">
        {({
          field: { name, value },
          form: { setFieldValue, isSubmitting },
        }: FastFieldProps<string>) => (
          <RadioGroup
            value={value}
            onChange={(e) => setFieldValue(name, e.target.value)}
            className={classes.indent}
          >
            {contextValues.alertSettings.map((alert) => (
              <FormControlLabel
                key={alert.code}
                value={alert.code}
                control={<Radio />}
                label={alert.name}
                disabled={isSubmitting}
                data-testid="email-alert-mode"
              />
            ))}
          </RadioGroup>
        )}
      </FastField>
      <FastField name="report.emailAlerts.emails">
        {({
          field: { value, name },
          form: { setFieldValue, isSubmitting, errors },
        }: FastFieldProps<string[]>) => {
          const error: string[] | undefined = getProperty(errors, name);
          return (
            <TagInput
              value={value}
              onChange={(value) => setFieldValue(name, uniq(value), true)}
              disabled={isSubmitting}
              addOnSpace
              addOnComma
              showCopyAll
              error={error?.find(Boolean)}
              errors={error?.map((error) => Boolean(error))}
              variant="outlined"
              className={classes.indent}
              placeholder={t("settings.emailAlerts.placeholder")}
              data-testid="email-alerts"
            />
          );
        }}
      </FastField>
    </>
  );
}
