var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { withStyles } from "@material-ui/core";
import clsx from "clsx";
function BadgeInner(_a, ref) {
    var { label, variant, classes, className } = _a, props = __rest(_a, ["label", "variant", "classes", "className"]);
    const variantClass = classes[variant || "none"];
    return (_jsx("div", Object.assign({ ref: ref }, props, { className: clsx(classes.root, variantClass, className), children: label })));
}
export const Badge = withStyles((theme) => ({
    root: {
        display: "inline-block",
        borderRadius: theme.spacing(0.5),
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 500,
        padding: theme.spacing(0.25, 1.5),
    },
    none: {
        background: theme.palette.grey[50],
        color: theme.palette.grey[800],
        border: `1px solid ${theme.palette.grey[300]}`,
    },
    low: {
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],
    },
    medium: {
        background: theme.palette.yellow[400],
        color: theme.palette.grey[800],
    },
    high: {
        background: theme.palette.orange[500],
        color: "white",
    },
    critical: {
        background: theme.palette.red[500],
        color: "white",
    },
}))(React.forwardRef(BadgeInner));
