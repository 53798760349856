import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@material-ui/core";
import { useCallback } from "react";
export const BezierCurves = ({ xScale, yScale, funnelWidth, svgHeight, dataset, }) => {
    const theme = useTheme();
    const paintBezierCurve = useCallback((d, i) => {
        var _a;
        const startX = xScale(i.toString());
        const startY = yScale(d["pass"] + d["fail"]);
        const endX = (_a = xScale((i + 1).toString())) !== null && _a !== void 0 ? _a : funnelWidth;
        const endY = yScale(d["pass"]);
        const middlePoint = endX - (endX - (startX !== null && startX !== void 0 ? startX : 0)) / 2;
        return `M${startX},${startY}
                C${middlePoint},${startY}
                ${middlePoint},${endY} 
                ${endX},${endY}
                L${endX}, ${endY}
                L${endX}, ${svgHeight}
                L${startX}, ${svgHeight}`;
    }, [funnelWidth, svgHeight, xScale, yScale]);
    return (_jsx("g", { children: dataset.map((d, i) => (_jsx("path", { d: paintBezierCurve(d, i), fill: theme.palette.blue[200] }, d.title))) }));
};
