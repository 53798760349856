import { isFunction } from "lodash";
import React, { Dispatch, SetStateAction } from "react";

// This hooks works same way as the React.useState, the only difference is that,
// it updates it's state when the value provided as parameter changes.
export function useUpdatableState<T>(
  value: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [innerValue, setInnerValue] = React.useState<T>(value);

  const previousValue = React.useRef(value);
  const currentValue = React.useRef(value);
  currentValue.current = value;

  const valueChanged = value !== previousValue.current;

  // Note: In order to avoid unnecessary rerender, the setInnerValue function
  // is not called when the provided value changes. The new value is being
  // returned until the inner value changes. - Csaba
  return [
    valueChanged ? value : innerValue,
    React.useCallback(
      (setValue: SetStateAction<T>) => {
        const valueChanged = previousValue.current !== currentValue.current;

        setInnerValue((oldValue) =>
          isFunction(setValue)
            ? setValue(valueChanged ? currentValue.current : oldValue)
            : setValue,
        );
        previousValue.current = currentValue.current;
      },
      [setInnerValue],
    ),
  ];
}
