import { useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Theme,
  Button,
  Chip,
  Popover,
} from "@material-ui/core";
import { translationNamespace } from "../../CrawlSettings";
import { useTranslation } from "react-i18next";
import AceEditor from "react-ace";
import AddIcon from "@material-ui/icons/Add";
import * as Yup from "yup";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";
import { REGEX_PATTERNS } from "../../../../_common/constants/regex-patterns.constant";
import { Typography } from "@lumar/shared";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    width: "400px",
    margin: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  marginRightBtn: {
    marginRight: theme.spacing(1),
    marginLeft: "auto",
  },
  chip: {
    pointerEvents: "none",
    backgroundColor: theme.palette.error.main,
  },
  chipSmall: {
    height: 20,
  },
  labelSmall: {
    color: "white",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function ManualSitemaps({
  onAddSitemap,
  disabled,
}: {
  onAddSitemap: (sitemap: string[]) => Promise<boolean>;
  disabled: boolean;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const schema = Yup.array().of(Yup.string().matches(REGEX_PATTERNS.url));

  const validateUrlString = async (): Promise<void> => {
    const urls = input
      .split(/\r\n|\n|\r/)
      .map((line) => line.trim())
      .filter((line) => line.length);

    if (!schema.isValidSync(urls)) {
      setInputError(true);
    } else {
      await onAddSitemap(urls).then(() => {
        setIsOpen(false);
        setInput("");
      });
    }
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setIsOpen(true)}
          disabled={disabled}
          ref={buttonRef}
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          data-testid="crawl-settings-manually-add"
        >
          {t("sources.sitemaps.addSitemaps")}
        </Button>
      </div>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box className={classes.root}>
          <Typography variant="subtitle3Medium">
            {t("sources.sitemaps.manualSitemaps.title")}
          </Typography>
          <Typography variant="caption" style={{ marginBottom: "8px" }}>
            {t("sources.sitemaps.manualSitemaps.description")}
          </Typography>
          <AceEditor
            placeholder="URLs"
            theme="tomorrow"
            name="crawl-settings-add-manual-sitemaps"
            mode="text"
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={input}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            onChange={(e) => {
              setInputError(false);
              setInput(e);
            }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
            }}
            height="200px"
            width="100%"
          />
          {inputError && (
            <Box paddingTop={1}>
              <Chip
                size="small"
                classes={{
                  root: classes.chip,
                  sizeSmall: classes.chipSmall,
                  label: classes.labelSmall,
                }}
                label={t("sources.sitemaps.manualSitemaps.invalidUrls")}
              />
            </Box>
          )}
          <Box paddingTop={2} className={classes.marginRightBtn}>
            <Button
              variant="outlined"
              onClick={validateUrlString}
              data-testid="crawl-settings-add-sitemaps-button"
            >
              {t("sources.sitemaps.manualSitemaps.addBtn")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
