import { ReportCategoryChartConfig } from "../types";
import { getBotHitsBreakdownChart } from "../../../../_common/charts/config/getBotHitsBreakdownChart";
import { getBotHitsBreakdownDepthChart } from "../../../../_common/charts/config/getBotHitsBreakdownDepthChart";
import { getBotHitsBreakdownTrend } from "../../../../_common/charts/config/getBotHitsBreakdownTrend";
import { getBotHitsbyStatusCode } from "../../../../_common/charts/config/getBotHitsbyStatusCode";
import { getBotHitsCrawlBudget } from "../../../../_common/charts/config/getBotHitsCrawlBudget";
import { getBotHitsCrawlBudgetTrend } from "../../../../_common/charts/config/getBotHitsCrawlBudgetTrend";
import { getLogSummaryIssuesChart } from "../../../../_common/charts/config/getLogSummaryIssuesChart";

export const generateCrawlabilityCrawlBudgetCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getBotHitsCrawlBudget,
      getBotHitsCrawlBudgetTrend,
      getBotHitsBreakdownChart,
      getBotHitsBreakdownTrend,
      getLogSummaryIssuesChart,
      getBotHitsbyStatusCode,
      getBotHitsBreakdownDepthChart,
    ],
  };
