import { TotalSign, TotalWeight } from "./CustomReportTypes";
import { ReportTypeCode } from "../../graphql";
import { MetricsGroupings } from "../../report/Report.types";
import { GridApiRef } from "@mui/x-data-grid-pro";
import { ReportGridColumn } from "../../report/report-rows/report-grid/columns/ReportGridColumns.types";

export const mapTotalSignToInt = (totalSign: TotalSign): number => {
  switch (totalSign) {
    case "negative":
      return -1;
    case "neutral":
      return 0;
    case "positive":
      return 1;
  }
};

export const mapIntToTotalSign = (n: number): TotalSign => {
  switch (n) {
    case -1:
      return "negative";
    case 0:
      return "neutral";
    case 1:
      return "positive";
    default:
      return "neutral";
  }
};

export const mapTotalWeightToFloat = (totalWeight: TotalWeight): number => {
  switch (totalWeight) {
    case "none":
      return 0;
    case "low":
      return 0.25;
    case "medium":
      return 0.5;
    case "high":
      return 0.75;
    case "critical":
      return 1;
  }
};

export const mapFloatToTotalWeight = (f: number): TotalWeight => {
  switch (f) {
    case 0:
      return "none";
    case 0.25:
      return "low";
    case 0.5:
      return "medium";
    case 0.75:
      return "high";
    case 1:
      return "critical";
    default:
      return "none";
  }
};

export const mapReportTypeCodeToString = (
  reportTypeCode: ReportTypeCode,
): string => {
  switch (reportTypeCode) {
    case ReportTypeCode.Basic:
      return "basic";
    case ReportTypeCode.Added:
      return "added";
    case ReportTypeCode.Removed:
      return "removed";
    case ReportTypeCode.Missing:
      return "missing";
  }
};

export const mapStringToReportTypeCode = (
  reportTypeString: string,
): ReportTypeCode => {
  switch (reportTypeString) {
    case "basic":
      return ReportTypeCode.Basic;
    case "added":
      return ReportTypeCode.Added;
    case "removed":
      return ReportTypeCode.Removed;
    case "missing":
      return ReportTypeCode.Missing;
    default:
      return ReportTypeCode.Basic;
  }
};

export const mapCustomReportMetricsGroupings = (
  metricsGroupings: string[][],
): MetricsGroupings => {
  return metricsGroupings.map((group) => ({
    metrics: group.map((code) => ({ code })),
  }));
};

export const getMetricsGroupingsFromGrid = (args: {
  cardMetrics: ReportGridColumn[];
  gridApiRef: GridApiRef;
}): Array<{ metrics: Array<string> }> => {
  // We need to consume the columns from the gridApiRef to get the metrics
  // to be able to react to columns order changes as right now these changes
  // are not reactive.
  const { gridApiRef } = args;
  const columns = gridApiRef.current?.state?.columns;

  if (!columns) {
    return [{ metrics: args.cardMetrics.map((m) => m.code) }];
  }

  return [
    { metrics: args.cardMetrics.map((m) => m.code) },
    {
      metrics: Array.from(
        (columns.all ?? []).reduce((acc, curr) => {
          const definition = columns.lookup[curr];
          if (definition.hide || definition.field === "card") {
            return acc;
          } else {
            return acc.add(definition.field);
          }
        }, new Set<string>()),
      ),
    },
  ].filter((grouping) => grouping.metrics.length > 0);
};
