import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "@lumar/shared";
import { lowerFirst } from "lodash";

import { CrawlerType } from "../../../graphql";

export function CrawlTypeCell(props: GridRenderCellParams): JSX.Element {
  const { t } = useTranslation("common");

  const typeKey = lowerFirst(props.value as CrawlerType) as Uncapitalize<
    keyof typeof CrawlerType
  >;
  const crawlerTypeName = t(`crawlerType.${typeKey}`);

  return <>{crawlerTypeName}</>;
}
