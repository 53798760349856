import { MetricsValuePresenterProps } from "../../data/types";
import { Badge, BadgeVariant } from "@lumar/shared";

export function IssueSeverityPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  if (!value) return <>-</>;

  const badgeVariant = getBadgeVariant(value);

  return <Badge variant={badgeVariant} label={value} />;
}

function getBadgeVariant(value?: string): BadgeVariant | undefined {
  const code = value?.replace(/^\d+-/, "");
  switch (code) {
    case "minor":
      return "low";
    case "moderate":
      return "medium";
    case "serious":
      return "high";
    case "critical":
      return "critical";
  }
}
