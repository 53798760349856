import {
  Alert,
  Button,
  ExclamationCircleSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { TextEditor } from "../../components/Editors";
import { CookieSettings } from "../data/types";
import {
  API_COOKIE_KEY_REGEXP,
  API_COOKIE_VALUE_REGEXP,
} from "../data/useSettingsValidationSchema";

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(2.625),
  },
  accordionSummary: {
    minHeight: 42,
    padding: theme.spacing(0, 3),
    "&$accordionSummaryExpanded": {
      minHeight: 42,
    },
  },
  accordionSummaryExpanded: {},
  accordionSummaryContent: {
    margin: theme.spacing(0.875, 0),
    display: "flex",
    justifyContent: "space-between",
    "&$accordionSummaryExpanded": {
      margin: theme.spacing(0.875, 0),
    },
  },
  action: {
    color: theme.palette.primary.main,
  },
  instructionContainer: {
    margin: theme.spacing(1.25, 0, 0, 1),
  },
  instruction: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: theme.spacing(1.125),
  },
  number: {
    display: "inline-block",
    width: 23,
    height: 23,
    background: theme.palette.yellow[400],
    borderRadius: "50%",
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(23),
    fontWeight: 600,
    textAlign: "center",
    marginRight: theme.spacing(1.125),
    flexShrink: 0,
  },
  input: {
    margin: theme.spacing(1.375, 0, 1, 0),
  },
  footer: {
    display: "flex",
  },
  error: {
    color: theme.palette.red[600],
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(0.5),
    },
  },
  button: {
    marginLeft: "auto",
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
  },
  example: {
    display: "block",
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(1),
  },
}));

interface Props {
  disabled?: boolean;
  onAdd: (values: CookieSettings[]) => void;
}

export function CookiesImport({ disabled, onAdd }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlSettings");

  const [instructionOpen, setInstructionOpen] = React.useState(false);

  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");

  const instructions = [
    t("cookiesImportIntruction1"),
    t("cookiesImportIntruction2"),
    t("cookiesImportIntruction3"),
    t("cookiesImportIntruction4"),
    t("cookiesImportIntruction5"),
  ];

  function handleImport(): void {
    if (!value) return;

    const cookies = importCookies(value);
    if (cookies.length) {
      onAdd(cookies);
      setValue("");
      setError("");
      setInstructionOpen(false);
    } else {
      setError(t("cookiesImportError"));
    }
  }

  return (
    <div>
      <div className={classes.accordion}>
        <Accordion
          expanded={instructionOpen}
          onChange={(_, value) => setInstructionOpen(value)}
        >
          <AccordionSummary
            classes={{
              root: classes.accordionSummary,
              expanded: classes.accordionSummaryExpanded,
              content: classes.accordionSummaryContent,
            }}
          >
            <Typography variant="captionSemiBold">
              {t("cookiesImportIntruction")}
            </Typography>
            <Typography variant="captionSemiBold" className={classes.action}>
              {!instructionOpen
                ? t("cookiesImportIntructionShow")
                : t("cookiesImportIntructionHide")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.instructionContainer}>
              {instructions.map((instruction, idx) => (
                <div className={classes.instruction} key={idx}>
                  <div className={classes.number}>{idx + 1}</div>
                  <Typography>{instruction}</Typography>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <Alert severity="info" size="small">
        {t("cookiesImportAlert")}
      </Alert>

      <code className={classes.example}>{t("cookiesImportExample")}</code>

      <TextEditor
        name="cookie-import"
        value={value}
        onChange={(value) => setValue(value)}
        className={classes.input}
      />

      <div className={classes.footer}>
        {error && (
          <div className={classes.error}>
            <ExclamationCircleSolid />
            <Typography>{error}</Typography>
          </div>
        )}
        <Button
          onClick={handleImport}
          disabled={disabled}
          variant="outlined"
          size="large"
          className={classes.button}
        >
          {t("cookiesImportAction")}
        </Button>
      </div>
    </div>
  );
}

export function importCookies(value: string): CookieSettings[] {
  try {
    return formatCookieString(value);
  } catch {}
  return [];
}

function formatCookieString(value: string): CookieSettings[] {
  const trimmedValue = value.trim();
  const formattedValue = trimmedValue.match(/^"[^"]*"$/)
    ? trimmedValue.substring(1, trimmedValue.length - 1)
    : trimmedValue;

  function encode(key: string, regExp: RegExp): string {
    return key
      .split("")
      .map((x) => (regExp.test(x) ? x : encodeURIComponent(x)))
      .join("");
  }

  return formattedValue
    .split(";")
    .filter((row) => row.length > 0)
    .map((row) => {
      const idx = row.indexOf("=");
      if (idx === -1) {
        return {
          key: "",
          value: encode(row.trim(), API_COOKIE_VALUE_REGEXP),
        };
      }

      return {
        key: encode(row.substring(0, idx).trim(), API_COOKIE_KEY_REGEXP),
        value: encode(row.substring(idx + 1).trim(), API_COOKIE_VALUE_REGEXP),
      };
    });
}
