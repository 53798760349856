import {
  CrawlAccessibilityIssueInstanceOrderField,
  CrawlAccessibilityIssueOrderField,
  CrawlDuplicateUrlOrderField,
  CrawlHreflangsOrderField,
  CrawlLinkedDomainOrderField,
  CrawlLinkOrderField,
  CrawlSitemapOrderField,
  CrawlSiteSpeedAuditItemOrderField,
  CrawlSiteSpeedAuditOpportunityOrderField,
  CrawlSiteSpeedAuditOrderField,
  CrawlUncrawledUrlOrderField,
  CrawlUniqueLinkOrderField,
  CrawlUrlOrderField,
  CrawlWebCrawlDepthOrderField,
  DatasourceCode,
} from "../../../../graphql";

export const sortableMetrics: Record<string, Map<string, boolean>> = {
  [DatasourceCode.CrawlDuplicateUrls]: getSortableFields(
    CrawlDuplicateUrlOrderField,
  ),
  [DatasourceCode.CrawlHreflangs]: getSortableFields(CrawlHreflangsOrderField),
  [DatasourceCode.CrawlLinkedDomains]: getSortableFields(
    CrawlLinkedDomainOrderField,
  ),
  [DatasourceCode.CrawlLinks]: getSortableFields(CrawlLinkOrderField),
  [DatasourceCode.CrawlSitemaps]: getSortableFields(CrawlSitemapOrderField),
  [DatasourceCode.CrawlUncrawledUrls]: getSortableFields(
    CrawlUncrawledUrlOrderField,
  ),
  [DatasourceCode.CrawlUniqueLinks]: getSortableFields(
    CrawlUniqueLinkOrderField,
  ),
  [DatasourceCode.CrawlUrls]: getSortableFields(CrawlUrlOrderField),
  [DatasourceCode.CrawlWebCrawlDepths]: getSortableFields(
    CrawlWebCrawlDepthOrderField,
  ),
  [DatasourceCode.CrawlAccessibilityIssueInstances]: getSortableFields(
    CrawlAccessibilityIssueInstanceOrderField,
  ),
  [DatasourceCode.CrawlAccessibilityIssues]: getSortableFields(
    CrawlAccessibilityIssueOrderField,
  ),
  [DatasourceCode.CrawlSiteSpeedAuditItems]: getSortableFields(
    CrawlSiteSpeedAuditItemOrderField,
  ),
  [DatasourceCode.CrawlSiteSpeedAudits]: getSortableFields(
    CrawlSiteSpeedAuditOrderField,
  ),
  [DatasourceCode.CrawlSiteSpeedAuditOpportunities]: getSortableFields(
    CrawlSiteSpeedAuditOpportunityOrderField,
  ),
};

function getSortableFields(
  value: Record<string, string>,
): Map<string, boolean> {
  return new Map<string, true>(Object.values(value).map((x) => [x, true]));
}
