import { Typography } from "@lumar/shared";
import { CrawlReportCategoryListNode } from "../../CrawlContext";
import { Tooltip } from "@material-ui/core";

export function CategoryNameCell({
  category,
}: {
  category?: CrawlReportCategoryListNode;
}): JSX.Element | null {
  return (
    <Tooltip title={category?.name || ""}>
      <div
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
          }}
        >
          {category?.name}
        </Typography>
      </div>
    </Tooltip>
  );
}
