/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getRenderingIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("renderingIssues.title"),
    description: () => t("renderingIssues.description"),
    reportTemplateCodesOrder: [
      "broken_head_warning",
      "canonical_link_mismatch",
      "rendered_word_count_mismatch",
      "rendered_link_count_mismatch",
    ],
    reportStatFilter: (report) => {
      return [
        "broken_head_warning",
        "canonical_link_mismatch",
        "rendered_word_count_mismatch",
        "rendered_link_count_mismatch",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
