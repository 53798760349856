import {
  GetCrawlSettingsForSettingsQuery,
  SetCrawlSettingsForSettingsMutationVariables,
  UpdateProjectInput,
} from "../../../../graphql";
import { FormValues } from "./types";
import * as formatters from "./formatters/formattes";

interface Props {
  data: GetCrawlSettingsForSettingsQuery | undefined;
  projectId: string;
  values: FormValues;
  initialValues: FormValues;
  accessibilityContainerId?: string;
  htmlContainerId: string | undefined;
  screenshotContainerId: string | undefined;
}

export function getMutationData({
  data,
  projectId,
  values,
  initialValues,
  accessibilityContainerId,
  htmlContainerId,
  screenshotContainerId,
}: Props): SetCrawlSettingsForSettingsMutationVariables {
  const accessibilityValuesChanged =
    initialValues.spider.scriptRendering.customAccessibilityScript !==
    values.spider.scriptRendering.customAccessibilityScript;
  const shouldUpdateAccessibility =
    Boolean(accessibilityContainerId) && accessibilityValuesChanged;

  const HTMLLink = data?.getProject?.customMetricContainerProjects.nodes.find(
    (e) => e.customMetricContainer.id === htmlContainerId,
  );
  const ScreenshotLink =
    data?.getProject?.customMetricContainerProjects.nodes.find(
      (e) => e.customMetricContainer.id === screenshotContainerId,
    );

  return {
    updateProjectInput: getProjectInput(projectId, values),
    updateAccessibility: shouldUpdateAccessibility,
    updateAccessibilityInput: {
      projectId,
      customMetricContainerId: accessibilityContainerId || "0",
      customJsScripts: [
        values.spider.scriptRendering.customAccessibilityScript,
      ],
    },
    linkHTML:
      !HTMLLink &&
      Boolean(htmlContainerId) &&
      values.report.saveHTMLAndScreenshots.saveHTML !==
        initialValues.report.saveHTMLAndScreenshots.saveHTML,
    updateHTML:
      !!HTMLLink &&
      values.report.saveHTMLAndScreenshots.saveHTML !==
        initialValues.report.saveHTMLAndScreenshots.saveHTML,
    linkScreenshot:
      !ScreenshotLink &&
      Boolean(screenshotContainerId) &&
      values.report.saveHTMLAndScreenshots.saveScreenshot !==
        initialValues.report.saveHTMLAndScreenshots.saveScreenshot,
    updateScreenshot:
      !!ScreenshotLink &&
      values.report.saveHTMLAndScreenshots.saveScreenshot !==
        initialValues.report.saveHTMLAndScreenshots.saveScreenshot,
    linkHTMLInput: {
      projectId,
      customMetricContainerId: htmlContainerId || "0",
      enabled: values.report.saveHTMLAndScreenshots.saveHTML,
    },
    linkScreenshotInput: {
      projectId,
      customMetricContainerId: screenshotContainerId || "0",
      enabled: values.report.saveHTMLAndScreenshots.saveScreenshot,
    },
    updateHTMLInput: {
      projectId,
      customMetricContainerId: htmlContainerId || "0",
      enabled: values.report.saveHTMLAndScreenshots.saveHTML,
    },
    updateScreenshotInput: {
      projectId,
      customMetricContainerId: screenshotContainerId || "0",
      enabled: values.report.saveHTMLAndScreenshots.saveScreenshot,
    },
  };
}

function getProjectInput(
  projectId: string,
  values: FormValues,
): UpdateProjectInput {
  return {
    projectId: projectId,
    compareToCrawl: values.compareToCrawl,
    ...formatters.formatDomainScopeSettingsTo(values.scope.domainScope),
    ...formatters.formatUrlScopeSettingsTo(values.scope.urlScope),
    ...formatters.formatResourceRestrictionsSettingsTo(
      values.scope.resourceRestrictions,
    ),
    ...formatters.formatLinkValidationSettingsTo(values.scope.linkValidation),
    ...formatters.formatRedirectSettingsTo(values.scope.redirectSettings),
    ...formatters.formatLinkRestrictionsSettingsTo(
      values.scope.linkRestrictions,
    ),
    ...formatters.formatStartUrlsSettingsTo(values.spider.startUrls),
    ...formatters.formatScriptRenderingSettingsTo(
      values.spider.scriptRendering,
    ),
    ...formatters.formatCrawlerIpSettingsTo(values.spider.crawlerIp),
    ...formatters.formatUserAgentSettingsTo(values.userAgent),
    ...formatters.formatMobileSiteSettingsTo(values.spider.mobileSite),
    ...formatters.formatStealthModeSettingsTo(values.spider.stealthMode),
    ...formatters.formatCustomRequestHeadersSettingsTo(
      values.spider.customRequestHeaders,
    ),
    ...formatters.formatCookiesSettingsTo(values.cookies),
    ...formatters.formatCustomExtractionSettingsTo(
      values.extraction.customExtraction,
    ),
    ...formatters.formatRobotsOverwriteSettingsTo(
      values.spider.robotsOverwrite,
    ),
    ...formatters.formatTestSiteDomainSettingsTo(values.test.testSiteDomain),
    ...formatters.formatCustomDnsSettingsTo(values.test.customDNS),
    ...formatters.formatAuthenticationSettingsTo(values.test.authentication),
    ...formatters.formatRemoveUrlParamsSettingsTo(values.test.removeUrlParams),
    ...formatters.formatUrlRewritingSettingsTo(values.test.urlRewriting),
    ...formatters.formatApiCallbackSettingsTo(values.report.apiCallback),
    ...formatters.formatEmailAlertsSettingsTo(values.report.emailAlerts),
    ...formatters.formatReportSettingsTo(values.report.report),
  };
}
