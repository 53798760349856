/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {} from "../../../graphql";

export function getNonIndexablePagesBySourceChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    //visualisationColors: [theme.palette.yellow[300]],
    title: () => t("nonIndexablePagesBySource.title"),
    description: () => t("nonIndexablePagesBySource.description"),
    reportTemplateCodesOrder: [
      "non_indexable_pages_with_visits",
      "non_indexable_pages_in_serp",
      "backlink_pages_non_indexable",
      "sitemaps_non_indexable_links",
      "non_indexable_pages_with_bot_hits",
    ],
    reportStatFilter: (report) => {
      return [
        "non_indexable_pages_with_visits",
        "non_indexable_pages_in_serp",
        "backlink_pages_non_indexable",
        "sitemaps_non_indexable_links",
        "non_indexable_pages_with_bot_hits",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
