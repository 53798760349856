export var ReportSignColors;
(function (ReportSignColors) {
    ReportSignColors["grey"] = "grey";
    ReportSignColors["red"] = "red";
    ReportSignColors["green"] = "green";
})(ReportSignColors || (ReportSignColors = {}));
const colorMapping = new Map([
    [0, ReportSignColors.grey],
    [1, ReportSignColors.green],
    [-1, ReportSignColors.red],
]);
export function getReportSignColor(sign) {
    var _a;
    return (_a = colorMapping.get(sign)) !== null && _a !== void 0 ? _a : ReportSignColors.grey;
}
export function getReportChangeDetails({ sign, total, change, }) {
    const changeNum = change !== null && change !== void 0 ? change : 0;
    const changeSign = Math.sign(changeNum);
    return {
        color: getReportSignColor(changeSign * sign),
        changePercentage: changeNum / ((total !== null && total !== void 0 ? total : 0) - changeNum),
    };
}
