import * as Yup from "yup";
const connectionFilterValidator = Yup.object({
    _or: Yup.array()
        .notRequired()
        .min(1)
        .of(Yup.object({
        _and: Yup.array().required().min(1).of(Yup.object().required()),
    }).required()),
});
export function validateConnectionFilter(input) {
    return !!connectionFilterValidator.validateSync(input);
}
