import { Chip, useNumberFormatter, useTranslation } from "@lumar/shared";
import { makeStyles, useTheme } from "@material-ui/core";
import { useSizeFormatter } from "../../../_common/locale/format-size/useSizeFormatter";
import { MetricsValuePresenterProps } from "../../data/types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  unit: {
    fontWeight: 500,
  },
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
}));

interface Props extends MetricsValuePresenterProps {
  maximumFractionDigits?: number;
  /**
   * Value and unit color. If value is an array, this color is ignored.
   */
  color?: string;
}

export function NumberPresenter({
  value,
  metrics,
  code,
  maximumFractionDigits,
  color,
}: Props): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const formatMetric = useNumberMetricFormatter();

  const unit = metrics[code]?.data?.metadata?.unit;

  function getValue(value: unknown): React.ReactElement {
    const numberValue = Number(value);
    if (value === undefined || value === null || isNaN(numberValue))
      return <>-</>;

    const formattedValue = formatMetric(numberValue, unit || undefined, {
      maximumFractionDigits,
    });
    return (
      <span style={{ color }}>
        {formattedValue.value}
        {Boolean(formattedValue.unit) && (
          <>
            &nbsp;
            <span
              className={classes.unit}
              style={{ color: color || theme.palette.grey[500] }}
            >
              {formattedValue.unit}
            </span>
          </>
        )}
      </span>
    );
  }

  return Array.isArray(value) ? (
    <>
      {value.map((value, idx) => {
        return (
          <Chip
            key={idx}
            label={getValue(value)}
            color="cyan"
            className={classes.chip}
          />
        );
      })}
    </>
  ) : (
    getValue(value)
  );
}

export function useNumberMetricFormatter(): (
  numberValue: number,
  unit?: string,
  options?: Intl.NumberFormatOptions,
) => {
  value: string | number;
  unit: string | undefined;
} {
  const { t } = useTranslation("units");
  const formatNumber = useNumberFormatter();
  const formatSize = useSizeFormatter();

  return React.useCallback(
    (numberValue, unit, options) => {
      switch (unit) {
        case "word":
          return {
            value: formatNumber(numberValue, options),
            unit: t("word", { count: numberValue }),
          };
        case "second":
          return {
            value: formatNumber(numberValue, options),
            unit: t("second", { count: numberValue }),
          };
        case "ms":
          return {
            value: formatNumber(numberValue, options),
            unit: t("msUnit"),
          };
        case "pixel":
          return {
            value: formatNumber(numberValue, options),
            unit: t("pixelUnit", { count: numberValue }),
          };
        case "character":
          return {
            value: formatNumber(numberValue, options),
            unit: t("characterUnit", { count: numberValue }),
          };
        case "percent":
          return {
            value: formatNumber(numberValue * 100, options),
            unit: t("percentUnit", { count: numberValue }),
          };
        case "byte":
          const { unit, value: formattedValue } = formatSize(numberValue ?? 0);
          return {
            value: formattedValue,
            unit,
          };
        case "KiB":
          return {
            value: formatNumber(numberValue, options),
            unit: t("kibUnit"),
          };
        case "point":
          return {
            value: formatNumber(numberValue, options),
            unit: t("pointUnit", { count: numberValue }),
          };
        case "secs":
          return {
            value: formatNumber(numberValue, options),
            unit: t("secsUnit"),
          };
        case "pageLoadsPercent":
          return {
            value: formatNumber(numberValue * 100, options),
            unit: t("pageLoadsPercentUnit", { count: numberValue }),
          };
        default:
          return {
            value: formatNumber(numberValue, options),
            unit: undefined,
          };
      }
    },
    [formatNumber, formatSize, t],
  );
}
