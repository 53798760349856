import {
  useTranslation,
  ToggleIconButton,
  DotsHorizontalOutlined,
  TrashOutlined,
  DestructiveMenuActionConfirmation,
  useMutationSnackbar,
} from "@lumar/shared";
import { Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import React from "react";
import { useParams } from "react-router-dom";
import { useDeleteReportTemplateOverrideMutation } from "../../../graphql";
import { useReportAdjustmentFormDialogs } from "./ReportAdjustmentFormDialogsContext";
import { ReportAdjustment } from "./ReportAdjustmentTypes";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  defaultIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(20),
  },
  removeIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.red[600],
    fontSize: theme.typography.pxToRem(20),
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export function ReportAdjustmentActionItems(props: {
  reportAdjustment: ReportAdjustment;
  disableEdit?: boolean;
}): JSX.Element {
  const { projectId } = useParams<{ projectId: string }>();
  const classes = useStyles();
  const toggleRef = React.useRef<SVGSVGElement | null>(null);
  const deleteItemRef = React.useRef<HTMLLIElement | null>(null);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = React.useState(false);
  const [removeOverride] = useDeleteReportTemplateOverrideMutation();
  const mutationSnackbar = useMutationSnackbar();
  const { openEditDialog } = useReportAdjustmentFormDialogs();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { t } = useTranslation(["common", "reportAdjustment"]);

  return (
    <>
      <ToggleIconButton
        onClick={() => setIsMenuOpen(true)}
        data-testid="actions-button"
      >
        <DotsHorizontalOutlined ref={toggleRef} />
      </ToggleIconButton>
      <Menu
        open={isMenuOpen}
        id="report-adjustment-actions"
        onClose={() => setIsMenuOpen(false)}
        anchorEl={toggleRef.current}
        keepMounted
        getContentAnchorEl={null} // Explanation: https://github.com/mui-org/material-ui/issues/7961
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        classes={{ paper: classes.menu }}
      >
        <MenuItem
          className={classes.menuItem}
          ref={deleteItemRef}
          disabled={props.disableEdit}
          onClick={() => {
            openEditDialog(props.reportAdjustment);
            setIsMenuOpen(false);
          }}
        >
          <EditOutlined className={classes.defaultIcon} />
          <Typography variant="body2">{t("common:edit")}</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => setIsConfirmingDeletion(true)}
        >
          <TrashOutlined className={classes.removeIcon} />
          <Typography variant="body2">{t("common:delete")}</Typography>
        </MenuItem>
      </Menu>
      {isConfirmingDeletion ? (
        <DestructiveMenuActionConfirmation
          title={t("reportAdjustment:deleteTitle")}
          description={t("reportAdjustment:deleteDescription")}
          confirmText={t("common:confirm")}
          cancelText={t("common:cancel")}
          anchorEl={deleteItemRef.current}
          onCancel={() => setIsConfirmingDeletion(false)}
          onConfirm={async () => {
            await mutationSnackbar(
              removeOverride({
                variables: {
                  input: {
                    projectId,
                    reportTemplateCode:
                      props.reportAdjustment.reportTemplateCode,
                  },
                },
                refetchQueries: ["ReportAdjustmentPage"],
                awaitRefetchQueries: true,
              }),
              {
                successMessage: t("reportAdjustment:deleteSuccess"),
                errorMessage: t("reportAdjustment:deleteError"),
                onFinally: () => {
                  setIsConfirmingDeletion(true);
                  setIsMenuOpen(false);
                },
              },
            );
          }}
        />
      ) : null}
    </>
  );
}
