import React from "react";
import { CrawlSourcesIcons } from "@lumar/shared";
import { ProjectsGridRenderCellParams } from "../data/types";
import { useAccountProjectsContext } from "../data/useAccountProjectsContext";

export function CrawlSourcesCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const { crawlTypesMetadata } = useAccountProjectsContext();

  const sources = crawlTypesMetadata.map((crawlType) => ({
    code: crawlType.code,
    name: crawlType.name,
    active: row.crawlTypes.includes(crawlType.code),
  }));

  return (
    <CrawlSourcesIcons
      sources={sources}
      data-testid="all-projects-crawl-types-cell"
      data-pendo="crawl-sources"
    />
  );
}
