import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getHttpAndHttpsChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("httpAndHttps.title"),
    description: t("httpAndHttps.description"),
    reportTemplateCodes: ["https_pages", "http_pages"],
    testAttributePrefix: "http-and-https-pages-chart",
  })();
}
