/* eslint-disable fp/no-mutation */
import { generatePath } from "react-router-dom";
import queryString, { StringifyOptions, UrlObject } from "query-string";
import { useBrand } from "@lumar/shared";

export interface RouteParams {
  accountId: string;
}

const ACCOUNTS_ROUTE_MATCH = "/accounts/:accountId";

function createAccountsURL(
  appUrl: string,
  object: Omit<UrlObject, "query"> & Required<Pick<UrlObject, "query">>,
  options?: StringifyOptions,
): string {
  const params = new URLSearchParams(window.location.search);
  const shareLinkToken = params.get("token");
  const preferredLanguage = localStorage.getItem("i18nextLng");

  if (shareLinkToken) {
    object.query.token = shareLinkToken;
  }

  object.query.referral = window.location.href;

  object.query.lng = preferredLanguage ?? "en";

  return `${appUrl}${queryString.stringifyUrl(object, options)}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAccountsRoutes() {
  const brand = useBrand();

  const AccountsRoutes = {
    Login: {
      ROUTE: "/login",
      getUrl(): string {
        return createAccountsURL(brand.appsURLs.accounts, {
          url: AccountsRoutes.Login.ROUTE,
          query: {},
        });
      },
    },
    ConnectedApps: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/connected-apps",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.ConnectedApps.ROUTE, {
          accountId,
        });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    Account: {
      ROUTE: ACCOUNTS_ROUTE_MATCH,
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.Account.ROUTE, { accountId });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    User: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/user",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.User.ROUTE, { accountId });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    Team: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/team",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.Team.ROUTE, { accountId });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    Subscription: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/subscription",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.Subscription.ROUTE, {
          accountId,
        });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    CreditUsage: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/credit-usage",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.CreditUsage.ROUTE, {
          accountId,
        });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
    ApiAccess: {
      ROUTE: ACCOUNTS_ROUTE_MATCH + "/api-access",
      getUrl({ accountId }: RouteParams): string {
        const url = generatePath(AccountsRoutes.ApiAccess.ROUTE, { accountId });
        return createAccountsURL(brand.appsURLs.accounts, { url, query: {} });
      },
    },
  };

  return AccountsRoutes;
}
