import { useTranslation } from "@lumar/shared";
import React from "react";

type SizeFomratter = (bytes: number) => { value: number; unit: string };

export function useSizeFormatter(): SizeFomratter {
  const { t } = useTranslation("units");

  return React.useCallback(
    (bytes) => {
      const sizes: string[] = [
        t("byte", { count: bytes }),
        t("kilobyteUnit"),
        t("megabyteUnit"),
        t("gigabyteUnit"),
        t("terabyteUnit"),
      ];

      if (bytes === 0) {
        return { value: bytes, unit: sizes[0] };
      }
      const i: number = parseInt(
        Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
      );
      if (i === 0) {
        return { value: bytes, unit: sizes[i] };
      }
      return {
        value: Number((bytes / Math.pow(1024, i)).toFixed(1)),
        unit: sizes[i],
      };
    },
    [t],
  );
}
