import { useTheme } from "@material-ui/core";

export function NoProjectsIcon(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: theme.spacing(3.125) }}
    >
      <rect
        width="64"
        height="64"
        rx="32"
        fill={theme.palette.ultraviolet[50]}
      />
      <g clipPath="url(#clip0_25041_24119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.9072 32.0325C41.6697 31.6173 41.3267 31.2723 40.913 31.0324C40.4993 30.7924 40.0295 30.666 39.5512 30.666C39.0729 30.666 38.6031 30.7924 38.1894 31.0324C37.7757 31.2723 37.4327 31.6173 37.1952 32.0325L28.675 46.9393C28.4393 47.3521 28.316 47.8195 28.3176 48.2948C28.3192 48.7701 28.4456 49.2367 28.6842 49.6478C28.9227 50.0589 29.2651 50.4002 29.6769 50.6375C30.0888 50.8748 30.5557 50.9998 31.031 50.9999H48.0686C48.5442 51.0002 49.0115 50.8756 49.4237 50.6385C49.8359 50.4014 50.1786 50.0602 50.4175 49.649C50.6564 49.2378 50.783 48.771 50.7847 48.2955C50.7864 47.8199 50.6632 47.3523 50.4273 46.9393L41.9072 32.0325Z"
          fill={theme.palette.ultraviolet[600]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.0682 13.0007C27.9945 12.9786 28.9007 13.2732 29.6371 13.8358C30.3734 14.3984 30.8957 15.1953 31.1179 16.095L31.126 16.1221L31.6472 18.4292H46.8472C47.927 18.4292 48.9626 18.8582 49.7261 19.6217C50.4896 20.3853 50.9186 21.4209 50.9186 22.5007V42.4208L44.2143 30.6842C42.1297 27.0389 36.8694 27.0389 34.7876 30.6842L26.2674 45.5938C26.0269 46.013 25.8426 46.462 25.7191 46.9292H17.0714C15.9916 46.9292 14.956 46.5003 14.1925 45.7367C13.429 44.9732 13 43.9376 13 42.8578V17.0721C13 15.9923 13.429 14.9567 14.1925 14.1932C14.956 13.4296 15.9916 13.0007 17.0714 13.0007H27.0682Z"
          fill={theme.palette.ultraviolet[300]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2476 38.7855C41.2476 38.3356 41.0689 37.9041 40.7507 37.586C40.4326 37.2678 40.0011 37.0891 39.5512 37.0891C39.1012 37.0891 38.6698 37.2678 38.3516 37.586C38.0335 37.9041 37.8547 38.3356 37.8547 38.7855V41.4998C37.8547 41.7226 37.8986 41.9432 37.9839 42.149C38.0691 42.3548 38.1941 42.5419 38.3516 42.6994C38.5091 42.8569 38.6962 42.9819 38.902 43.0671C39.1078 43.1524 39.3284 43.1963 39.5512 43.1963C39.7739 43.1963 39.9945 43.1524 40.2004 43.0671C40.4062 42.9819 40.5932 42.8569 40.7507 42.6994C40.9082 42.5419 41.0332 42.3548 41.1185 42.149C41.2037 41.9432 41.2476 41.7226 41.2476 41.4998V38.7855ZM39.5512 48.6248C40.0011 48.6248 40.4326 48.4461 40.7507 48.128C41.0689 47.8098 41.2476 47.3783 41.2476 46.9284C41.2476 46.4785 41.0689 46.047 40.7507 45.7288C40.4326 45.4107 40.0011 45.232 39.5512 45.232C39.1012 45.232 38.6698 45.4107 38.3516 45.7288C38.0335 46.047 37.8547 46.4785 37.8547 46.9284C37.8547 47.3783 38.0335 47.8098 38.3516 48.128C38.6698 48.4461 39.1012 48.6248 39.5512 48.6248Z"
          fill={theme.palette.ultraviolet[300]}
        />
      </g>
      <defs>
        <clipPath id="clip0_25041_24119">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
