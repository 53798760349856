import { useNumberFormatter, useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";
import { useTheme } from "@material-ui/core";

interface Props extends MetricsValuePresenterProps {
  isMobile?: boolean;
}

export function PageSpeedPresenter({
  value,
  metrics,
  code,
  isMobile,
}: Props): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  const format = useNumberFormatter();
  const theme = useTheme();

  if (typeof value !== "number") return <>-</>;

  const siteSpeedScoring = isMobile
    ? metrics[code]?.data?.metadata?.siteSpeedScoring?.mobile
    : metrics[code]?.data?.metadata?.siteSpeedScoring?.desktop;
  const moderate = siteSpeedScoring?.p10;
  const slow = siteSpeedScoring?.median;

  function getColor(): string | undefined {
    if (slow === undefined || moderate === undefined) return;

    if (value >= slow) return theme.palette.red[600];
    if (value >= moderate) return theme.palette.yellow[600];
    return theme.palette.green[600];
  }

  const unit = metrics[code]?.data?.metadata?.unit;

  return (
    <span style={{ color: getColor() }}>
      {unit === "secs"
        ? t("units.seconds", { value: value })
        : format(value, { maximumFractionDigits: 1 })}
    </span>
  );
}
