import { Typography, useNumberFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  text: {
    verticalAlign: "middle",
    display: "inline-block",
    color: theme.palette.grey[800],
    marginRight: theme.spacing(2),
  },
}));

interface CountCellProps {
  params: ProjectsGridRenderCellParams;
  "data-testid"?: string;
}

export function CountCell(props: CountCellProps): JSX.Element {
  const { params } = props;
  const classes = useStyles();
  const paramsValue = params.value as string | null;
  const count = parseInt(paramsValue || "") || 0;
  const formatNumber = useNumberFormatter();

  return (
    <Typography
      variant="captionSemiBold"
      className={classes.text}
      data-testid={props["data-testid"]}
    >
      {formatNumber(count)}
    </Typography>
  );
}
