import { LinearProgress, makeStyles } from "@material-ui/core";
import { useState, useRef, useEffect } from "react";

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    width: "100%",
    left: 0,
    top: 0,
    height: 3,
  },
}));

export function FinalisationProgress(): JSX.Element {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const progressRef = useRef(() => {});

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <LinearProgress
      classes={{ root: classes.root }}
      color="primary"
      variant="buffer"
      value={progress}
      valueBuffer={buffer}
    />
  );
}
