import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsAccessibility from "highcharts/modules/accessibility";
HighchartsAccessibility(Highcharts);
export function SparklineChart(props) {
    var _a, _b;
    const theme = useTheme();
    // We want to show the data in a single point format, only when the
    // data contains only one point and, if the x axis range is specified,
    // the range is 0.
    const isSingleDataPoint = props.series.length === 1;
    const isMinMaxXDefined = props.minX !== undefined && props.maxX !== undefined;
    const isMinMaxXSinglePointValue = props.minX === props.maxX;
    const shouldShowSingleDot = isMinMaxXDefined
        ? isMinMaxXSinglePointValue
        : isSingleDataPoint;
    const options = {
        title: undefined,
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        chart: {
            type: "area",
            animation: false,
            backgroundColor: "transparent",
            // Preventing line and marker cut-offs.
            // In Safari and Firefox highchart aligns the chart to the next
            // whole number (relative to the window's origin), because of this
            // it needs an additional 1px margin.
            margin: [5, 3, 5, 3],
        },
        plotOptions: {
            series: {
                lineWidth: 1,
            },
            area: {
                fillColor: theme.palette.grey[100],
            },
        },
        series: [],
        xAxis: {
            visible: false,
            min: props.minX,
            max: props.maxX,
        },
        yAxis: {
            visible: false,
        },
        legend: {
            enabled: false,
        },
    };
    // If a trend has only one data point, we want to show a line on the same
    // level as the marker. This can be achieved by adding additional
    // serie to the array that has fake data points. One is before
    // the real data point, and the other one is after.
    // This way also makes it easy to modify interaction with the line.
    if (shouldShowSingleDot) {
        // eslint-disable-next-line fp/no-mutating-methods
        (_a = options.series) === null || _a === void 0 ? void 0 : _a.push({
            type: "area",
            dataLabels: { enabled: false },
            lineWidth: 2,
            color: theme.palette.grey[600],
            fillColor: theme.palette.grey[100],
            marker: { enabled: false },
            label: {
                enabled: false,
            },
            showInLegend: false,
            enableMouseTracking: false,
            clip: false,
            data: [
                {
                    x: props.series[0].x - 1,
                    y: props.series[0].y,
                },
                {
                    x: props.series[0].x + 1,
                    y: props.series[0].y,
                },
            ],
        });
    }
    // eslint-disable-next-line fp/no-mutating-methods
    (_b = options.series) === null || _b === void 0 ? void 0 : _b.push({
        marker: {
            symbol: "circle",
            radius: shouldShowSingleDot ? 4 : 1.5,
            enabled: true,
        },
        color: theme.palette.grey[600],
        data: props.series,
        enableMouseTracking: false,
        animation: false,
        type: "area",
        clip: false,
    });
    return (_jsx(HighchartsReact, { containerProps: { style: { height: "100%", width: "100%" } }, highcharts: Highcharts, options: options }));
}
