import React from "react";
import {
  ChartReportColorConfig,
  newChartReportColorConfig,
} from "./newChartReportColorConfig";
import { useTheme } from "@material-ui/core";

type Category = string;
type ReportTemplateCode = string;
export interface MappedChartReportColorConfig {
  globalReportColor: Record<ReportTemplateCode, string>;
  categoryReportColor: Record<Category, Record<ReportTemplateCode, string>>;
}

export function useColorConfig(): MappedChartReportColorConfig {
  const theme = useTheme();
  return React.useMemo(
    () => ({
      globalReportColor: mapReportColor(
        theme.palette.chart,
        newChartReportColorConfig.globalReportColor,
      ),
      categoryReportColor: mapCategoryReportColor(
        theme.palette.chart,
        newChartReportColorConfig.categoryReportColor,
      ),
    }),
    [theme],
  );
}

function mapCategoryReportColor(
  colors: readonly string[],
  categoryReportColor: ChartReportColorConfig["categoryReportColor"],
): MappedChartReportColorConfig["categoryReportColor"] {
  return Object.fromEntries(
    Object.entries(categoryReportColor).map(([category, reportColor]) => {
      return [category, mapReportColor(colors, reportColor)];
    }),
  );
}

function mapReportColor(
  colors: readonly string[],
  reportColor: ChartReportColorConfig["globalReportColor"],
): MappedChartReportColorConfig["globalReportColor"] {
  return Object.fromEntries(
    Object.entries(reportColor).map(([code, color]) => {
      if (typeof color === "number") {
        return [code, colors[color]];
      }
      return [code, color];
    }),
  );
}
