import {
  FilterRuleFormValue,
  useSession,
  Trans,
  useTranslation,
  AddonAvailability,
  useBrand,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

import { AccountProjectsVariant } from "../_common/routing/routes";
import { ModuleCode, OrderDirection, ProjectOrderField } from "../graphql";
import { AutomateRoutes } from "../_common/routing/automateRoutes";
import { GridColumns, GridState } from "@mui/x-data-grid-pro";
import { AccountProjectsColumnTypes } from "./columns/getColumn";
import { AccountProjectsActionTypes } from "./columns/getAction";
import { useAllProjectsColumns } from "./columns/useAllProjectsColumns";
import React from "react";
import { applyColumnStates } from "../_common/data-grid/column-persistance/applyColumnStates";
import {
  readColumnsState,
  writeColumnsState,
} from "../_common/data-grid/column-persistance/columnsState";
import { SiteSpeedIcon } from "./assets/SiteSpeedIcon";
import siteSpeedBackground from "./assets/Lumar_Illustrations.png";
import { SeoIcon } from "./assets/SeoIcon";
import { AccessibilityIcon } from "./assets/AccessibilityIcon";

export interface AccountProjectsPageConfig {
  getTitle: (count: number) => string;
  filter?: FilterRuleFormValue;
  defaultSort?: { field: ProjectOrderField; order: OrderDirection };
  columns: GridColumns;
  noRowsOverlayProps?: Record<string, unknown>;
  noResultsOverlayProps?: Record<string, unknown>;
  hideProjectStatusFilter?: boolean;
  saveColumnsState: (state: GridState) => void;
}

export function useAccountProjectsPageConfig({
  variant,
}: {
  variant: AccountProjectsVariant;
}): AccountProjectsPageConfig {
  const classes = useStyles();
  const { t } = useTranslation("projectsList");
  const { account, getAddonAvailability } = useSession();
  const brand = useBrand();

  const getColumns = useAllProjectsColumns();

  const isSiteSpeedFree =
    getAddonAvailability("site-speed") === AddonAvailability.Free;

  return React.useMemo<AccountProjectsPageConfig>(() => {
    const defaultColumns: Exclude<AccountProjectsColumnTypes, "actions">[] = [
      "name",
      "status",
      "lastCrawl",
      "frequency",
      "nextCrawl",
      "tasks",
      "segments",
      "urlCount",
      "crawlCount",
      "source",
    ];

    const defaultActions: AccountProjectsActionTypes[] = [
      "runCrawl",
      "previousCrawls",
      "editSchedule",
      "removeSchedule",
      "editProject",
      "cloneProject",
      "removeProject",
    ];

    const {
      columns: columnNames,
      actions,
      ...config
    } = ((): Omit<AccountProjectsPageConfig, "columns" | "saveColumnsState"> & {
      columns: typeof defaultColumns;
      actions: typeof defaultActions;
    } => {
      switch (variant) {
        case AccountProjectsVariant.All:
          return {
            getTitle: () => t("allPageTitle"),
            columns: [
              "name",
              "projectType",
              "status",
              "lastCrawl",
              "frequency",
              "nextCrawl",
              "tasks",
              "segments",
              "urlCount",
              "crawlCount",
              "source",
            ],
            actions: defaultActions,
          };
        case AccountProjectsVariant.SEO:
          return {
            getTitle: () => t("allPageTitle"),
            filter: {
              id: "860c61af-d523-4bd1-8dac-b90e6becd5ce",
              metricCode: "moduleCode",
              predicateKey: "eq",
              predicateValue: ModuleCode.Seo,
            },
            columns: defaultColumns,
            actions: defaultActions,
            noRowsOverlayProps: {
              title: t("noProjectsInAccount.titleSeo"),
              icon: SeoIcon,
            },
          };
        case AccountProjectsVariant.Accessibility:
          return {
            getTitle: () => t("allPageTitle"),
            filter: {
              id: "c548ba90-4ea9-411c-9e9a-5d9ec14b3938",
              metricCode: "moduleCode",
              predicateKey: "eq",
              predicateValue: ModuleCode.Accessibility,
            },
            columns: defaultColumns,
            actions: defaultActions,
            noRowsOverlayProps: {
              title: t("noProjectsInAccount.titleAccessibility"),
              icon: AccessibilityIcon,
            },
          };
        case AccountProjectsVariant.SiteSpeed:
          return {
            getTitle: () => t("allPageTitle"),
            filter: {
              id: "06084fcf-bc73-418e-a932-abd5fb1b30d1",
              metricCode: "moduleCode",
              predicateKey: "eq",
              predicateValue: ModuleCode.SiteSpeed,
            },
            columns: defaultColumns,
            actions: defaultActions,
            noRowsOverlayProps: {
              icon: SiteSpeedIcon,
              title: isSiteSpeedFree
                ? t("noProjectsFound.siteSpeedFreeTitle")
                : t("noProjectsFound.siteSpeedTitle"),
              detail: isSiteSpeedFree
                ? t("noProjectsFound.siteSpeedFreeDescription", {
                    credit: account.primaryAccountPackage.creditsSiteSpeed,
                  })
                : t("noProjectsFound.siteSpeedDetail"),
              children:
                brand.brandId === "lumar" ? <SiteSpeedBackground /> : undefined,
            },
          };
        case AccountProjectsVariant.Running:
          return {
            getTitle: (count) => t("runningPageTitle", { count }),
            filter: {
              id: "6ae558ba-d585-406d-b78a-c1a86b827a84",
              metricCode: "running",
              predicateKey: "eq",
              predicateValue: true,
            },
            columns: [
              "name",
              "status",
              "frequency",
              "nextCrawl",
              "crawled",
              "speed",
              "crawlDuration",
              "source",
            ],
            actions: [
              "runCrawl",
              "previousCrawls",
              "editProject",
              "cancelCrawl",
            ],
          };
        case AccountProjectsVariant.TestSuite:
          return {
            getTitle: (count) => t("testSuitesTitle", { count }),
            filter: {
              id: "288951b5-6269-4df8-a469-8dbf16d421fc",
              metricCode: "isTestSuite",
              predicateKey: "eq",
              predicateValue: true,
            },
            columns: [
              "name",
              "status",
              "lastCrawl",
              "urlCount",
              "crawlCount",
              "source",
            ],
            actions: ["previousCrawls", "editProject"],
            hideProjectStatusFilter: true,
            noRowsOverlayProps: {
              title: t("noProjectsInAccount.testSuiteTitle"),
              detail: (
                <Trans
                  ns="projectsList"
                  i18nKey="noProjectsInAccount.testSuiteDescription"
                  components={{
                    automationLink: (
                      <a
                        href={AutomateRoutes.Dashboard.getUrl({
                          accountId: account.id,
                        })}
                        className={classes.link}
                        data-testid="test-suites-automation-hub-link"
                      />
                    ),
                  }}
                />
              ),
              onClick: undefined,
            },
            noResultsOverlayProps: {
              title: t("noProjectsFound.testSuiteTitle"),
              detail: t("noProjectsFound.testSuiteDescription"),
            },
          };
        default:
          return {
            getTitle: (count) => t("allPageTitle", { count }),
            columns: defaultColumns,
            actions: defaultActions,
          };
      }
    })();

    const columns = getColumns({ columns: columnNames, actions });
    const defaultStates = columns.map((column) => ({
      code: column.field,
      hide: Boolean(column.hide),
      width: column.width,
    }));
    const persistanceKey = `account_projects_${variant}`;

    return {
      ...config,
      columns: applyColumnStates(
        columns,
        readColumnsState(persistanceKey, defaultStates),
      ),
      saveColumnsState: (state) =>
        writeColumnsState(persistanceKey, state, defaultStates),
    };
  }, [
    account.id,
    account.primaryAccountPackage.creditsSiteSpeed,
    brand.brandId,
    classes.link,
    getColumns,
    isSiteSpeedFree,
    t,
    variant,
  ]);
}

function SiteSpeedBackground(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <img
        src={siteSpeedBackground}
        style={{
          height: "100%",
          transform: "scaleX(-1) translate(50%, 0px)",
          width: "50%",
          objectFit: "contain",
        }}
      />
      <img
        src={siteSpeedBackground}
        style={{
          height: "100%",
          transform: "scaleX(-1) translate(-50%, 0px)",
          width: "50%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.blue[600],
  },
  wrapper: {
    position: "absolute",
    inset: 0,
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
