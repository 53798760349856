/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {} from "../../../graphql";

export function getContentDuplicationChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("contentDuplication.title"),
    description: () => t("contentDuplication.description"),
    reportTemplateCodesOrder: [
      "duplicate_pages",
      "pages_with_duplicate_titles",
      "pages_with_duplicate_descriptions",
      "duplicate_body_content",
      "duplicate_urls",
    ],
    reportStatFilter: (report) => {
      return [
        "duplicate_pages",
        "pages_with_duplicate_titles",
        "pages_with_duplicate_descriptions",
        "duplicate_body_content",
        "duplicate_urls",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
