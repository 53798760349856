import { ComboBox } from "@lumar/shared";
import { Predicate } from "../types";

interface FilterPredicateSelectProps {
  predicates: Predicate[];
  value: Predicate | undefined;
  onChange: (value: Predicate) => void;
  disabled?: boolean;
  className?: string;
}

export function FilterPredicateSelect({
  predicates,
  value,
  onChange,
  disabled,
  className,
}: FilterPredicateSelectProps): JSX.Element {
  return (
    <ComboBox
      value={value}
      onChange={(_, value) => onChange(value)}
      options={predicates}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, selected) => option.code === selected?.code}
      renderOption={(option) => (
        <div data-testid={getFilterPredicateOptionTestId(option.code)}>
          {option.name}
        </div>
      )}
      disableClearable
      disabled={disabled}
      className={className}
      inputProps={{
        inputProps: {
          "data-testid": "filter-rule-predicate-type-select",
        },
      }}
    />
  );
}

function getFilterPredicateOptionTestId(predicateCode: string): string {
  return `filter-predicate-option-${predicateCode}`;
}
