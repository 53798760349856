import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { ArrowRightSolid } from "@lumar/shared";

import {
  useGetCrawlSettingsForLimitsQuery,
  useUpdateLimitsMutation,
} from "../../../graphql";
import { LimitsRestrictions } from "./LimitsRestrictions";
import {
  BuildContextData,
  BuildFormData,
  BuildMutationData,
  FormValues,
  SettingsContext,
  useValidationSchema,
} from "./types";
import { LimitsSettings } from "./LimitsSettings";
import { FormSubmitContext } from "../components/FormSubmitContext";
import { SettingsErrorOverlay } from "../components/SettingsErrorOverlay";
import { ApolloErrorSnackbar } from "../components/ApolloErrorSnackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent: {
      marginTop: theme.spacing(2),
    },
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      padding: theme.spacing(2, 4),
    },
  }),
);

export function Limits({ next }: { next: () => void }): JSX.Element {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data, error, loading } = useGetCrawlSettingsForLimitsQuery({
    variables: {
      accountId,
      projectId,
    },
  });

  const [updateLimits] = useUpdateLimitsMutation({
    onError: (error) => {
      enqueueSnackbar(
        <ApolloErrorSnackbar
          title={t("message.apiErrorTitleSave")}
          error={error}
        />,
      );
    },
  });

  const initialValues = BuildFormData(data);
  const contextValues = BuildContextData(data);

  const validationSchema = useValidationSchema(
    contextValues.limitLevelsMax,
    contextValues.limitPagesMax,
    contextValues.maxCrawlRate,
  );

  if (loading) {
    return (
      <Grid container style={{ height: "208px" }} alignContent="center">
        <Grid item style={{ margin: "auto" }}>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <SettingsErrorOverlay
        title={t("errorLoadFailed")}
        description={error.message}
      />
    );
  }

  const handleSubmit = async (values: FormValues): Promise<boolean> => {
    const result = await updateLimits({
      variables: BuildMutationData(projectId, values),
      refetchQueries: ["ProjectSummary", "RunningCrawl"],
      awaitRefetchQueries: true,
    });
    return !!result;
  };

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) return;

    next();
  };

  return (
    <SettingsContext.Provider value={contextValues}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        <>
          <div className={classes.root}>
            <LimitsRestrictions />
            <LimitsSettings />
          </div>
          <FormSubmitContext>
            {({ submitForm, isSubmitting }) => (
              <div
                style={{
                  backgroundColor: "rgba(209, 213, 219,0.3)",
                  width: "100%",
                  padding: "12px 16px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<ArrowRightSolid />}
                  onClick={() =>
                    handleSave(submitForm as () => Promise<boolean>)
                  }
                  disabled={isSubmitting}
                  data-testid="limits-save-and-continue"
                >
                  {t("saveAndContinue")}
                </Button>
              </div>
            )}
          </FormSubmitContext>
        </>
      </Formik>
    </SettingsContext.Provider>
  );
}
