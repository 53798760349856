import { ReportCategoryChartConfig } from "../types";
import { getSocialTagsBreakdownChart } from "../../../../_common/charts/config/getSocialTagsBreakdownChart";
import { getSocialTagsBreakdownTrend } from "../../../../_common/charts/config/getSocialTagsBreakdownTrend";

export const generateRankabilitySocialTagsCategoryConfig: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getSocialTagsBreakdownChart,
      getSocialTagsBreakdownTrend,
    ],
  };
