import React, { useState, useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import CopyIcon from "@material-ui/icons/FileCopy";
import { Trans, useTranslation } from "react-i18next";
import {
  getApiProjectId,
  getRawProjectId,
  ConfirmationDialog,
  Snackbar,
} from "@lumar/shared";

import { translationNamespace } from "../CrawlSettings";
import { ProjectAutocomplete } from "../../../_common/project-autocomplete/ProjectAutocomplete";
import { NarrowAutocompleteProject } from "../../../_common/project-autocomplete/types";
import { SettingsContext } from "./data/useContextValues";
import { useSnackbar } from "notistack";
import { FormikState, useFormikContext } from "formik";
import { FormValues } from "./data/types";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  field: {
    width: "100%",
    minWidth: 350,
    float: "right",
  },
  fieldIcon: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0.5),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function CopySettingsField(): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const contextValues = useContext(SettingsContext);
  const formContext = useFormikContext<FormValues>();

  const [copyProject, setCopyProject] =
    useState<NarrowAutocompleteProject | null>(null);
  const [copying, setCopying] = useState(false);
  const [showCopyProjectDialog, setShowCopyProjectDialog] = useState(false);

  async function updateFormValues(projectId: string): Promise<boolean> {
    const copyValues = await contextValues.getProjectSettings(projectId);
    if (!copyValues) return false;

    formContext.setFormikState((prevState: FormikState<FormValues>) => {
      const prevValues = prevState.values;
      const values: FormValues = {
        ...prevValues,
        ...copyValues,
        compareToCrawl: prevValues.compareToCrawl,
        scope: {
          ...copyValues.scope,
          domainScope: {
            ...copyValues.scope.domainScope,
            primaryDomain: prevValues.scope.domainScope.primaryDomain,
          },
        },
      };

      return {
        ...prevState,
        values,
      };
    });
    return true;
  }

  const handleCopy = async (): Promise<void> => {
    if (!copyProject || copying) return;

    setCopying(true);
    const result = await updateFormValues(getRawProjectId(copyProject.id));
    setCopying(false);

    setShowCopyProjectDialog(false);
    setCopyProject(null);

    if (result)
      enqueueSnackbar(
        <Snackbar variant="success" title={t("message.copySettingsTitle")}>
          <Trans
            ns="crawlSettings"
            i18nKey="message.copySettingsDescription"
            values={{
              project: copyProject?.name,
            }}
            components={{
              bold: <span className={classes.bold} />,
            }}
          />
        </Snackbar>,
      );
  };

  const handleConfirmationCancel = (): void => {
    if (copying) return;

    setShowCopyProjectDialog(false);
    setCopyProject(null);
  };

  return (
    <>
      <ProjectAutocomplete
        value={copyProject}
        onChange={(project) => {
          if (project) {
            setCopyProject(project);
            setShowCopyProjectDialog(true);
          }
        }}
        projectToExclude={getApiProjectId(contextValues.projectId)}
        accountId={contextValues.accountId}
        inputProps={{
          placeholder: t("settings.copyProjectPlaceholder"),
          className: clsx(classes.field, classes.indent),
          InputProps: {
            startAdornment: <CopyIcon className={classes.fieldIcon} />,
          },
        }}
      />
      <ConfirmationDialog
        open={showCopyProjectDialog}
        onClose={handleConfirmationCancel}
        title={t("settings.copySettingsTitle")}
        content={
          <Trans
            ns="crawlSettings"
            i18nKey="settings.copySettingsDescription"
            values={{
              projectFrom: copyProject?.name ?? "",
              projectTo: contextValues.projectName,
            }}
            components={{
              bold: <span className={classes.bold} />,
            }}
          />
        }
        primaryAction={handleCopy}
        primaryButtonProps={{
          loading: copying,
        }}
        secondaryAction={handleConfirmationCancel}
        secondaryButtonProps={{
          disabled: copying,
        }}
        withGreyFooter
      />
    </>
  );
}
