import React from "react";
import { ApolloError } from "@lumar/shared";
import { PageInfo } from "../../../graphql";

export type FragmentData = {
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: Record<string, any>[];
  pageInfo?: Pick<PageInfo, "hasNextPage" | "endCursor">;
};

interface ExpandedDataState {
  loading: boolean;
  error?: ApolloError;
  data: Record<string, FragmentData>;
}

export function useExpandedDataState(
  crawlId: string,
): [
  ExpandedDataState,
  (props: {
    loading: boolean;
    error?: ApolloError;
    data: [string, Partial<FragmentData>][];
  }) => void,
] {
  const [state, setState] = React.useState<ExpandedDataState>({
    loading: false,
    error: undefined,
    data: {},
  });

  const updateState = React.useCallback(
    ({
      loading,
      error,
      data,
    }: {
      loading: boolean;
      error?: ApolloError;
      data: [string, Partial<FragmentData>][];
    }) => {
      setState((prevState) => ({
        loading,
        error,
        data: {
          ...prevState.data,
          ...Object.fromEntries(
            data.map(([fragmentId, data]) => {
              const prevData = prevState.data[fragmentId] || { nodes: [] };
              return [
                fragmentId,
                {
                  ...prevData,
                  ...data,
                },
              ];
            }),
          ),
        },
      }));
    },
    [setState],
  );

  React.useEffect(() => {
    setState({ loading: false, error: undefined, data: {} });
  }, [crawlId, setState]);

  return [state, updateState];
}
