import { useContext } from "react";
import { useTranslation, isNonEmpty } from "@lumar/shared";

import {
  GetSplunkProjectQueryForSourcesQuery,
  UpdateSplunkQueryMutationVariables,
  useGetSplunkProjectQueryForSourcesLazyQuery,
  useUpdateSplunkQueryMutation,
} from "../../../../../graphql";
import { assert } from "../../../../../_common/assert";
import { useGenericParams } from "../../../../../_common/routing/useGenericParams";
import { translationNamespace } from "../../../CrawlSettings";
import { SplunkConnectionValues } from "./useSplunkQueries";
import { SourcesContext } from "../../data/useSourcesFormValues";

export interface FormValues {
  id: string;
  baseUrl: string;
  account: string;
  dateRange: string;
  query: string;
}

export interface SplunkFormValuesResult {
  formValues: FormValues;
  loading: boolean;
  getData: () => void;
  update: (values: FormValues) => Promise<string[]>;
}

export function useSplunkFormValues(
  id: string,
  connections: SplunkConnectionValues[],
): SplunkFormValuesResult {
  const { projectId } = useGenericParams();
  assert(projectId);

  const { t } = useTranslation(translationNamespace);
  const context = useContext(SourcesContext);

  const [getData, { data, loading }] =
    useGetSplunkProjectQueryForSourcesLazyQuery({
      variables: {
        queryID: id,
      },
      onError: () => context.onError(t("message.errorSplunkQuery")),
    });

  const [updateQuery] = useUpdateSplunkQueryMutation({
    onError: () => context.onError(t("message.errorSplunkUpdate")),
  });

  return {
    formValues: getFormValues(data),
    loading,
    getData,
    update: async (values) => {
      await updateQuery({
        variables: buildSplunkQueryMutationData(id, values, connections),
      });
      return [];
    },
  };
}

function getFormValues(
  data: GetSplunkProjectQueryForSourcesQuery | undefined,
): FormValues {
  if (!data?.node || !isNonEmpty(data.node))
    return {
      id: "",
      baseUrl: "",
      account: "",
      dateRange: "7",
      query: "",
    };

  return {
    id: data.node.id,
    baseUrl: data.node.baseUrl ?? "",
    account:
      data.node.splunkConnection?.url +
      " - " +
      data.node.splunkConnection?.username,
    dateRange: data.node.useLastCrawlDate
      ? "lastCrawl"
      : (data.node.dateRange.toString() ?? "7"),
    query: data.node.query ?? "",
  };
}

function buildSplunkQueryMutationData(
  id: string,
  values: FormValues,
  connections: SplunkConnectionValues[],
): UpdateSplunkQueryMutationVariables {
  const [connectionUrl, connectionUsername] = values.account.split(" - ");
  const connection = connections.find(
    (c) => c.url === connectionUrl && c.username === connectionUsername,
  );

  return {
    splunkProjectQueryId: id,
    splunkConnectionId: connection?.id,
    baseUrl: values.baseUrl === "" ? null : values.baseUrl,
    dateRange: convertNumber(values.dateRange) ?? 7,
    useLastCrawlDate: values.dateRange === "lastCrawl",
    query: values.query,
  };
}

function convertNumber(value: string): number | null {
  if (!value) return null;
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? null : convertedValue;
}
