import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { funnelConfig } from "../../URLFunnelChart";
import { ReportLink } from "../ReportLink";
import { FailBarsLegend } from "./FailBarsLegend";
const useStyles = makeStyles(() => ({
    bar: {
        cursor: "pointer",
    },
}));
export const FailBars = ({ xScale, funnelWidth, barWidth, hScale, yScale, elementWidth, dynamicDataset, stackedSeries, getReportUrl, }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [hovered, setHovered] = React.useState(undefined);
    const calculateBarX = (d, i) => {
        var _a;
        const nextBarX = (_a = xScale((i + 1).toString())) !== null && _a !== void 0 ? _a : funnelWidth;
        return nextBarX - barWidth;
    };
    return (_jsx(_Fragment, { children: stackedSeries[1].map((d, i) => {
            var _a;
            const height = d[1] === 0 ? funnelConfig.minimumBarHeight : hScale(d[1] - d[0]);
            const y = () => {
                // This line referst to the PassBar data, as the position of the FailBar
                // is calculated based on its PassBar height
                if (stackedSeries[0][i][1] === 0) {
                    if (d[1] === 0) {
                        return funnelConfig.svgHeight - funnelConfig.minimumBarHeight * 2;
                    }
                    else {
                        return yScale(d[1]) - funnelConfig.minimumBarHeight;
                    }
                }
                else {
                    return yScale(d[1]);
                }
            };
            return (_jsxs(ReportLink, { className: classes.bar, onMouseEnter: () => setHovered(i), onMouseLeave: () => setHovered(undefined), to: getReportUrl(dynamicDataset[i].failReportTemplateCode), children: [_jsx(FailBarsLegend, { y: yScale(d[1]) - 100, isHovered: hovered === i, elementWidth: elementWidth, x: (_a = xScale(i.toString())) !== null && _a !== void 0 ? _a : 0, lineMiddlePoint: elementWidth - barWidth / 2, data: dynamicDataset[i] }), _jsx("rect", { fill: hovered === i
                            ? theme.palette.lightBlue[400]
                            : theme.palette.lightBlue[300], width: barWidth, height: height, x: calculateBarX(d, i), y: y(), filter: hovered === i ? "url(#shadow)" : "", rx: "8", stroke: theme.palette.lightBlue[400], strokeWidth: 1 }, i)] }, i));
        }) }));
};
