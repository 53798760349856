import React, { useContext, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { FastField, FastFieldProps, FieldProps, Formik } from "formik";
import { TextField } from "formik-material-ui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, Chip, Select, fieldToSelect } from "@lumar/shared";
import { translationNamespace } from "../../../CrawlSettings";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

import { getRawSplunkProjectQueryId } from "../../../../../_common/api/getRawSplunkProjectQueryId";
import { SplunkConnectionValues } from "./useSplunkQueries";
import { FormValues, useSplunkFormValues } from "./useSplunkFormValues";
import { useSplunkSchema } from "./useSplunkSchema";
import { SourcesContext } from "../../data/useSourcesFormValues";
import { StyledInputLabel } from "../../../../../_common/forms/StyledInputLabel";

const useStyles = makeStyles((theme: Theme) => ({
  columnBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(1),
  },
  fullWidth: {
    display: "flex",
    width: "100%",
  },
  right: {
    display: "flex",
    justifyContent: "end",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  errorBadge: {
    background: theme.palette.error.main,
    width: "fit-content",
    marginBottom: theme.spacing(1),
  },
  query: {},
  queryLabel: {
    marginRight: theme.spacing(1),
  },
  smallCheckbox: {},
}));

export interface SplunkQueryProps {
  id: string;
  enabled: boolean;
  connections: SplunkConnectionValues[];
  enabledChanged: (enabled: boolean) => void;
  deleteQuery: () => void;
}

export function SplunkQuery({
  id,
  enabled,
  connections,
  enabledChanged,
  deleteQuery,
}: SplunkQueryProps): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();
  const context = useContext(SourcesContext);

  const { formValues, loading, getData, update } = useSplunkFormValues(
    id,
    connections,
  );
  const schema = useSplunkSchema();

  const [querryErrors, setQuerryErrors] = useState<string[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [dataMounted, setDataMounted] = useState(false);

  const possibleDayValues = ["7", "12", "30", "60", "90", "180", "365"];
  const dateRanges = [
    {
      value: "lastCrawl",
      name: t("sources.logSummary.splunk.query.lastDate"),
    },
    ...possibleDayValues.map((day) => ({
      value: day,
      name: t("sources.logSummary.splunk.query.nrDays", { nrDays: day }),
    })),
  ];

  const handleSubmit = async (values: FormValues): Promise<void> => {
    const errors = await update(values);
    setQuerryErrors(errors);
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={schema}
    >
      {({ submitForm }) => (
        <Accordion
          expanded={expanded}
          onChange={(_, expanded) => {
            setExpanded(expanded);
            if (expanded && !dataMounted) {
              setDataMounted(true);
              getData();
            }
          }}
          TransitionProps={{ unmountOnExit: dataMounted }}
          className={classes.query}
          data-testid="crawl-settings-splunk-queries"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container alignItems="center">
              <Checkbox
                value={enabled}
                onClick={(event) => {
                  event.stopPropagation();
                  enabledChanged(!enabled);
                }}
                checked={enabled}
                size="small"
                style={{ marginRight: "8px" }}
              />
              <Typography variant="caption">
                {t("sources.logSummary.splunk.query.queryNr")}
                {getRawSplunkProjectQueryId(id)}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {loading ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <FastField
                    name="baseUrl"
                    component={TextField}
                    variant="outlined"
                    placeholder={context.primaryDomain}
                    label={t("sources.logSummary.splunk.query.baseUrl")}
                    className={classes.fullWidth}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FastField name="account">
                    {(props: FieldProps<string>) => (
                      <Select
                        {...fieldToSelect(props)}
                        label={t("sources.logSummary.splunk.query.account")}
                        fullWidth
                        data-testid="crawl-settings-splunkquery=account"
                      >
                        <MenuItem value="" disabled>
                          {t(
                            "sources.logSummary.splunk.query.accountPlaceholder",
                          )}
                        </MenuItem>
                        {connections.map((connection, idx) => {
                          const val =
                            connection.url + " - " + connection.username;
                          return (
                            <MenuItem key={idx} value={val}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  </FastField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FastField name="dateRange">
                    {(props: FieldProps<string>) => (
                      <Select
                        {...fieldToSelect(props)}
                        label={t("sources.logSummary.splunk.query.dateRange")}
                        fullWidth
                      >
                        {dateRanges.map(({ name, value }) => (
                          <MenuItem key={name} value={value}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FastField>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.columnBox}>
                    <Box className={classes.fullWidth}>
                      <Grid
                        container
                        direction="row"
                        alignContent="center"
                        alignItems="center"
                      >
                        <StyledInputLabel style={{ marginRight: "8px" }}>
                          {t("sources.logSummary.splunk.query.query")}
                        </StyledInputLabel>
                        {querryErrors.length === 0 ? (
                          <Chip
                            color="primary"
                            label={t("sources.logSummary.splunk.query.valid")}
                            size="small"
                            style={{ marginBottom: "8px" }}
                          />
                        ) : (
                          <Chip
                            label={t("sources.logSummary.splunk.query.invalid")}
                            size="small"
                            color="red"
                          />
                        )}
                      </Grid>
                    </Box>
                    <FastField name="query">
                      {({
                        field: { name, value },
                        form: { setFieldValue, isSubmitting },
                      }: FastFieldProps<string | undefined>) => (
                        <AceEditor
                          value={value}
                          onChange={(value) => setFieldValue(name, value)}
                          readOnly={isSubmitting}
                          placeholder="Query"
                          theme="tomorrow"
                          name="crawl-settings-splunk-query-editor"
                          mode="text"
                          fontSize={14}
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          editorProps={{
                            $blockScrolling: Infinity,
                          }}
                          height="200px"
                          width="100%"
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: true,
                          }}
                        />
                      )}
                    </FastField>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8} className={classes.error}>
                  {querryErrors.length > 0 && (
                    <Typography variant="caption">
                      <Box fontWeight="fontWeightBold" m={1}>
                        {t("sources.logSummary.splunk.query.queryError", {
                          count: querryErrors.length,
                        })}
                      </Box>
                    </Typography>
                  )}
                  {querryErrors.map((error, idx) => (
                    <Chip
                      key={idx}
                      size="small"
                      color="red"
                      label={error}
                      icon={<ErrorIcon />}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} className={classes.right}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className={classes.button}
                    onClick={submitForm}
                  >
                    {t("sources.logSummary.splunk.query.save")}
                  </Button>
                  <Button variant="outlined" onClick={deleteQuery} size="small">
                    {t("sources.logSummary.splunk.query.delete")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Formik>
  );
}
