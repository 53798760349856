import { Typography } from "@lumar/shared";
import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { Tooltip } from "@material-ui/core";

export function ReportCodeCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  return (
    <Tooltip title={report.reportTemplate.code}>
      <Typography
        style={{
          display: "block",
          fontWeight: 500,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {report.reportTemplate.code}
      </Typography>
    </Tooltip>
  );
}
