import { TFunction } from "i18next";
import * as Yup from "yup";
import { getFilterRuleArrayValidationSchema } from "../filter-rule-field-array/getFilterRuleArrayValidationSchema";
import { NarrowMetric } from "../types";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function getFilterOrRuleArrayValidationSchema(
  metrics: NarrowMetric[],
  t: TFunction<"connectionFiltering">,
) {
  return Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.string().required(),
          _and: getFilterRuleArrayValidationSchema(metrics, t),
        })
        .required(),
    )
    .required();
}
