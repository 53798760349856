import { Typography } from "@lumar/shared";
import { useMultipointTooltipStyles } from "../utils/useTooltipStyles";
import React from "react";
import { TFunction } from "i18next";

type Props = {
  formatter: Highcharts.TooltipFormatterContextObject;
  classes: ReturnType<typeof useMultipointTooltipStyles>;
  formatTitle?: (context: Highcharts.TooltipFormatterContextObject) => string;
  t: TFunction<"units">;
};

export const MultipointTooltip = ({
  formatter,
  classes,
  formatTitle,
  t,
}: Props): JSX.Element => (
  <div className={classes.container}>
    {formatTitle !== undefined && (
      <Typography style={{ fontWeight: 700 }}>
        {formatTitle(formatter)}
      </Typography>
    )}
    <div className={classes.grid}>
      {formatter.points?.map(({ key, series, y, point }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const url = (point as any).url as string;
        return (
          <React.Fragment key={key}>
            <Typography className={classes.value}>
              {t("compactUrl", { count: y ?? undefined })}
            </Typography>
            <a href={url} className={classes.link}>
              <Typography className={classes.name}>{series.name}</Typography>
            </a>
          </React.Fragment>
        );
      })}
    </div>
  </div>
);
