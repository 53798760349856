import React from "react";
import { makeStyles } from "@material-ui/core";
import { ComboBox, TextField, useTranslation } from "@lumar/shared";
import clsx from "clsx";
import { DatePicker } from "@material-ui/pickers";
import { addMinutes, startOfDay } from "date-fns";
import { isDate, isString } from "lodash";

import { MetricType } from "../../../graphql";
import { PredicateValue } from "../types";

const useStyles = makeStyles((theme) => ({
  input: {
    // FIXME: Inputs should be consistent in the first place.
    "& > [class*='MuiInputBase-focused'] fieldset": {
      borderColor: theme.palette.indigo?.[500],
    },
  },
}));

interface FilterPredicateInputProps {
  type: MetricType | undefined;
  value: PredicateValue;
  onChange: (value: PredicateValue) => void;
  onBlur: () => void;
  error?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export function FilterPredicateInput({
  type,
  value,
  onChange,
  onBlur,
  error,
  autoFocus,
  disabled,
  className,
}: FilterPredicateInputProps): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation("connectionFiltering");

  if (type === MetricType.Boolean || type === MetricType.Bit) {
    const option = [
      {
        value: "true",
        label: t("true"),
      },
      {
        value: "false",
        label: t("false"),
      },
    ];

    return (
      <ComboBox
        value={option.find((x) => x.value === value.toString())}
        onChange={(_, value) => onChange(value.value === "true")}
        onClose={onBlur}
        options={option}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, selected) =>
          option.value === selected?.value
        }
        renderOption={(option) => (
          <div data-testid={`boolean-value-${option.value}`}>
            {option.label}
          </div>
        )}
        disableClearable
        disabled={disabled}
        className={clsx(className, classes.input)}
        inputProps={{
          inputProps: {
            "data-testid": "filter-rule-predicate-value-input",
          },
        }}
      />
    );
  }

  if (type === MetricType.Integer || type === MetricType.Decimal) {
    return (
      <TextField
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        inputProps={{
          "data-testid": "filter-rule-predicate-value-input",
        }}
        autoFocus={autoFocus}
        className={clsx(className, classes.input)}
      />
    );
  }

  if (type === MetricType.String) {
    return (
      <TextField
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        inputProps={{
          type: "text",
          "data-testid": "filter-rule-predicate-value-input",
        }}
        autoFocus={autoFocus}
        className={clsx(className, classes.input)}
      />
    );
  }

  if (type === MetricType.Date) {
    function getValue(): Date {
      if (isDate(value)) return value;
      if (isString(value)) return new Date(value);
      return new Date();
    }

    return (
      <DatePicker
        value={getValue()}
        onChange={(value) => {
          // Note: For consistency the selected date UTC format must be at midnight.
          if (!value) return;
          const date = addMinutes(
            startOfDay(value),
            -value.getTimezoneOffset(),
          );

          onChange(date);
        }}
        onClose={onBlur}
        autoOk
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        autoFocus={autoFocus}
        className={clsx(className, classes.input)}
        disableToolbar
        format="dd MMMM yyyy"
        variant="inline"
        inputVariant="outlined"
        inputProps={{
          "data-testid": "filter-rule-predicate-value-input",
        }}
        hiddenLabel
      />
    );
  }

  return null;
}
