import {
  getConnectionFilterCount,
  useConnectionFilterParam,
  FilterButton,
  useTranslation,
  Popper,
} from "@lumar/shared";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import PopperJs from "popper.js";
import { LocalFilter } from "../../../local-filter/LocalFilter";
import React from "react";

const useFilterStyles = makeStyles((theme) => ({
  filterPopper: {
    zIndex: theme.zIndex.drawer,
  },
}));

//FIXME: We need a button that opens a popper as a pattern

export function RowsFilters(): JSX.Element {
  const [filters, setFilters] = useConnectionFilterParam();
  const [localFilterAnchor, setLocalFilterAnchor] =
    useState<HTMLButtonElement | null>(null);
  const classes = useFilterStyles();
  const { t } = useTranslation("report");
  const filterCount = getConnectionFilterCount(filters);
  const localFilterPopperRef = useRef<PopperJs | null>(null);
  const localFilterRefCallback = useCallback(() => {
    localFilterPopperRef.current?.update();
  }, []);
  const localFilterVisible = Boolean(localFilterAnchor);
  const localFilterPopperId = localFilterVisible
    ? "local-filter-popper"
    : undefined;

  const onCancel = React.useCallback(
    () => setLocalFilterAnchor(null),
    [setLocalFilterAnchor],
  );

  return (
    <>
      <FilterButton
        onClick={(event) => setLocalFilterAnchor(event.currentTarget)}
        label={t("filters")}
        chipLabel={filterCount}
        data-testid="filter-toggle"
        onClear={
          filterCount
            ? (e) => {
                e.stopPropagation();
                setFilters({});
              }
            : undefined
        }
        ClearButtonProps={{
          "data-testid": "clear-filter",
        }}
      />
      <Popper
        id={localFilterPopperId}
        open={localFilterVisible}
        anchorEl={localFilterAnchor}
        placement="bottom-start"
        modifiers={{ flip: { enabled: false } }}
        popperRef={localFilterPopperRef}
        className={classes.filterPopper}
        onClose={onCancel}
      >
        <Paper elevation={4}>
          <Box padding={2} mt={1} mb={1}>
            <LocalFilter
              ref={localFilterRefCallback}
              onReset={() => {
                setFilters({});
              }}
              onApply={(filters) => {
                setFilters(filters);
                setLocalFilterAnchor(null);
              }}
              onCancel={onCancel}
              filters={filters}
            />
          </Box>
        </Paper>
      </Popper>
    </>
  );
}
