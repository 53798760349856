import React from "react";
import { useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";

import { useCheckDomainMutation } from "../../../../graphql";
import { ApolloErrorSnackbar } from "../../components/ApolloErrorSnackbar";

export interface DomainCheckResult {
  domainVariants: DomainVariant[];
  loading: boolean;
  called: boolean;
  checkDomain: (url: string) => Promise<void>;
}

export interface DomainVariant {
  domain: string;
  redirectsTo?: string;
  statusCode?: number;
}

export function useDomainCheck(): DomainCheckResult {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();

  const [checkDomain, { data, loading, called }] = useCheckDomainMutation({
    onError: (error) => {
      enqueueSnackbar(
        <ApolloErrorSnackbar title={t("domain.variantFailed")} error={error} />,
      );
    },
  });

  return {
    domainVariants:
      data?.checkDomain.variants.map((x) => ({
        domain: x.domain,
        redirectsTo: x.redirectsTo ?? undefined,
        statusCode: x.statusCode ?? undefined,
      })) ?? [],
    loading,
    called,
    checkDomain: async (url: string) => {
      await checkDomain({
        variables: {
          url,
        },
      });
    },
  };
}
