/* eslint-disable react/display-name */
import { Trans } from "@lumar/shared";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "@lumar/shared/dist/graphql";

export function getCrawlSourcesPagesIn(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
      ModuleCode.SiteSpeed,
    ],
    title: () => t("crawlSourcesPagesIn.title"),
    description: () => (
      <Trans
        ns="charts"
        i18nKey="crawlSourcesPagesIn.description"
        components={[
          <Typography key="0" paragraph variant="inherit" />,
          <Typography key="1" variant="inherit" />,
        ]}
      />
    ),
    reportTemplateCodesOrder: [
      "all_web_crawl_urls",
      "all_crawled_urls_in_xml_sitemaps",
      "pages_in_google_search_console",
      "all_crawled_organic_landing_pages",
      "all_cralwed_urls_with_backlinks",
      "pages_in_log_summary",
      "all_crawled_urls_in_list",
    ],
    reportStatFilter: (report) => {
      return [
        "all_web_crawl_urls",
        "all_crawled_urls_in_xml_sitemaps",
        "pages_in_google_search_console",
        "all_crawled_organic_landing_pages",
        "all_cralwed_urls_with_backlinks",
        "pages_in_log_summary",
        "all_crawled_urls_in_list",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },

    testAttributePrefix: "orphaned-pages-chart",
  };
}
