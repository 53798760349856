import React from "react";
import { TextField, StandardTextFieldProps } from "@material-ui/core";
import { FieldProps } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getProperty } from "@lumar/shared";

export interface TextFieldWithOptionsProps
  extends FieldProps<string>,
    StandardTextFieldProps {
  options?: string[];
  renderOption?: (value: string) => React.ReactNode;
}

export function TextFieldWithOptions({
  field: { name, value, onChange, onBlur },
  form: { setFieldValue, errors, isSubmitting },
  options,
  renderOption,
  disabled,
  ...props
}: TextFieldWithOptionsProps): JSX.Element {
  return (
    <Autocomplete
      inputValue={value}
      onChange={(_, newValue) => setFieldValue(name, newValue, true)}
      onBlur={onBlur}
      disabled={isSubmitting || disabled}
      options={options ?? []}
      filterOptions={(items) => items}
      renderOption={renderOption}
      freeSolo
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          ref={params.InputProps.ref}
          {...props}
          name={name}
          onChange={onChange}
          error={getProperty(errors, name)}
          helperText={getProperty(errors, name)}
        />
      )}
    />
  );
}
