/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesFCP(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedFCPIssues.title"),
    description: () => t("siteSpeedFCPIssues.description"),
    reportTemplateCodesOrder: [
      "no_document_write_failed_audits",
      "unminified_javascript_failed_audits",
      "render_blocking_resources_failed_audits",
      "uses_http2_failed_audits",
      "uses_long_cache_ttl_failed_audits",
      "server_response_time_failed_audits",
      "unminified_css_failed_audits",
      "uses_text_compression_failed_audits",
      "bf_cache_failed_audits",
      "font_display_failed_audits",
      "uses_rel_preload_failed_audits",
      "uses_rel_preconnect_failed_audits",
      "critical_request_chains_failed_audits",
      "redirects_failed_audits",
    ],
    reportStatFilter: (report) => {
      return [
        "no_document_write_failed_audits",
        "unminified_javascript_failed_audits",
        "render_blocking_resources_failed_audits",
        "uses_http2_failed_audits",
        "uses_long_cache_ttl_failed_audits",
        "server_response_time_failed_audits",
        "unminified_css_failed_audits",
        "uses_text_compression_failed_audits",
        "bf_cache_failed_audits",
        "font_display_failed_audits",
        "uses_rel_preload_failed_audits",
        "uses_rel_preconnect_failed_audits",
        "critical_request_chains_failed_audits",
        "redirects_failed_audits",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
