import React from "react";
import { ResourceDetailData } from "../../../data/types";
import { useSiteSpeedReport } from "../data/useSiteSpeedReport";
import { renderReport } from "lighthouse/dist/report/bundle.esm";
import { makeStyles } from "@material-ui/core";
import { CustomSkeleton } from "../../../../_common/CustomSkeleton";
import { EmptyState, SmileySad, useTranslation } from "@lumar/shared";

export function SiteSpeedReport({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();

  const { report, loading, error } = useSiteSpeedReport(data.metrics);

  const [renderedReport, setRenderedReport] = React.useState<HTMLElement>();
  const isRendering = Boolean(report && !renderedReport);

  React.useEffect(() => {
    if (loading || error || !report) return;

    setRenderedReport(
      renderReport(report, {
        disableDarkMode: true,
        disableFireworks: true,
        omitTopbar: true,
      }),
    );
  }, [error, loading, report]);

  React.useLayoutEffect(() => {
    if (!ref.current || !renderedReport) return;

    const root = ref.current;
    root.appendChild(renderedReport);
    return () => {
      root.removeChild(renderedReport);
    };
  });

  if (loading || isRendering) {
    return (
      <CustomSkeleton
        height={300}
        variant="rect"
        background="white"
        animation="wave"
      />
    );
  }

  return (
    <div className={classes.root}>
      {error && (
        <EmptyState
          icon={<SmileySad fontSize="large" className={classes.errorIcon} />}
          title={t("siteSpeed.reportErrorTitle")}
          description={error}
          className={classes.error}
        />
      )}
      <div className={classes.report} ref={ref} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: "8px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    padding: theme.spacing(1.25, 0, 3, 2.25),
  },
  error: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  errorIcon: {
    color: theme.palette.red[400],
  },
  report: {
    "& .lh-vars": {
      "--report-font-family-monospace": theme.typography.fontFamily,
      "--report-font-family": theme.typography.fontFamily,
      "--report-content-max-width": "calc(80* var(--report-font-size))",
      "--color-green": theme.palette.green[400],
      "--color-green-700": theme.palette.green[700],
      "--color-orange": theme.palette.orange[400],
      "--color-orange-700": theme.palette.orange[700],
      "--color-red": theme.palette.red[400],
      "--color-red-700": theme.palette.red[700],
      "--color-gray-50": theme.palette.grey[50],
      "--color-gray-100": theme.palette.grey[100],
      "--color-gray-200": theme.palette.grey[200],
      "--color-gray-300": theme.palette.grey[300],
      "--color-gray-400": theme.palette.grey[400],
      "--color-gray-500": theme.palette.grey[500],
      "--color-gray-600": theme.palette.grey[600],
      "--color-gray-700": theme.palette.grey[700],
      "--color-gray-800": theme.palette.grey[800],
      "--color-gray-900": theme.palette.grey[900],
    },
    "& .lh-audit-group__title": {
      fontWeight: 600,
    },
    "& .lh-generated": {
      display: "none",
    },
    "& .lh-audit--informative .lh-audit__display-text": {
      color: "var(--color-gray-500)",
    },
  },
}));
