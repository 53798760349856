import {
  EmptyState,
  ExclamationCircleOutlined,
  useTranslation,
} from "@lumar/shared";
import React from "react";

export function PreviewScreenshot({
  screenshot,
}: {
  screenshot?: Blob;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const objectURL = React.useMemo(() => {
    return screenshot && URL.createObjectURL(screenshot);
  }, [screenshot]);

  React.useEffect(
    () => () => {
      if (objectURL) URL.revokeObjectURL(objectURL);
    },
    [objectURL],
  );

  if (!objectURL) {
    return (
      <EmptyState
        icon={<ExclamationCircleOutlined color="error" fontSize="large" />}
        title={t("previewFailedTitle")}
        description={t("previewFailedDescription")}
        height="100%"
      />
    );
  }

  return (
    <img
      src={objectURL}
      style={{ maxWidth: "100%" }}
      alt="Project preview"
      data-testid="project-preview-screenshot"
    />
  );
}
