import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { Typography } from "../../typography/Typography";
import { HealthScoreChange } from "./HealthScoreChange";
import { HealthScoreResult } from "./HealthScoreResult";
import { SmallHealthScoreTrend } from "./SmallHealthScoreTrend";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { categoryIconConfig } from "../../../icons/categoryIconConfig";
import { CruxIcon, LighthouseIcon } from "../../../icons";
const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        margin: theme.spacing(0, 0.75),
    },
    titleWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "start",
    },
    titleHolder: {
        flexGrow: 1,
        flexBasis: 0,
        overflow: "hidden",
    },
    iconHolder: {
        alignSelf: "center",
    },
    title: {
        lineHeight: theme.typography.pxToRem(20),
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        margin: theme.spacing(0, 0, 0, 0),
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    healthScoreContainer: {
        margin: theme.spacing(0, 0.75, 0, 0.75),
    },
    percentageContainer: {
        margin: theme.spacing(0, 0.75, 0.5, 0.75),
    },
    hidden: {
        visibility: "hidden",
    },
    unit: {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(15),
        color: theme.palette.grey[500],
    },
    values: {
        display: "flex",
        flexFlow: "column",
        alignItems: "end",
    },
    icon: {
        width: 18,
        height: 18,
        marginRight: theme.spacing(0.75),
    },
}));
export function SmallHealthScoreChart(props) {
    var _a;
    const classes = useStyles();
    const Icon = getIconForChart(props.icon, props.id);
    return (_jsxs("div", { className: classes.container, "data-pendo": `health-score-${props.id}`, "data-testid": `health-score-${props.id}`, children: [_jsxs("div", { className: classes.titleWrapper, children: [_jsx("div", { className: classes.iconHolder, children: Icon && _jsx(Icon, { className: classes.icon }) }), _jsxs("div", { className: classes.titleHolder, children: [props.link ? (_jsx(Link, { to: props.link, style: {
                                    color: "inherit",
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                }, children: _jsx(Typography, { component: "div", className: classes.title, children: props.getTitleText(props.name) }) })) : (_jsx(Typography, { component: "div", className: classes.title, children: props.getTitleText(props.name) })), _jsx(Typography, { variant: "h6Medium", noWrap: true, color: "textPrimary", className: classes.unit, children: (_a = props.unitDescription) !== null && _a !== void 0 ? _a : "" })] }), _jsxs("div", { className: classes.values, children: [_jsx("div", { className: classes.healthScoreContainer, children: _jsx(HealthScoreResult, { healthScore: props.healthScore, size: "small", industryBenchmark: props.industryBenchmark }) }), _jsx("div", { className: clsx(classes.percentageContainer, {
                                    [classes.hidden]: props.healthScoreChangePercentage === null,
                                }), children: _jsx(HealthScoreChange, { changePercentage: props.healthScoreChangePercentage || 0 }) })] })] }), props.healthScoreTrend && props.healthScoreTrend.length > 0 ? (_jsx(SmallHealthScoreTrend, { healthScoreTrend: props.healthScoreTrend, industryBenchmark: props.industryBenchmark })) : null] }));
}
export function getIconForChart(type, categoryCode) {
    switch (type) {
        case "crux":
            return CruxIcon;
        case "lighthouse":
            return LighthouseIcon;
        case "category":
            return categoryIconConfig.get(categoryCode);
        default:
            return undefined;
    }
}
