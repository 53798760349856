import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { funnelConfig } from "../../URLFunnelChart";
import { ReportLink } from "../ReportLink";
import { PassBarsLegend } from "./PassBarsLegend";
import { PassPercentage } from "./PassPercentage";
const useStyles = makeStyles(() => ({
    bar: {
        cursor: "pointer",
    },
}));
const PERCENTAGE_FONT_SIZE = 14;
export const PassBars = ({ xScale, funnelWidth, barWidth, hScale, yScale, elementWidth, dynamicDataset, stackedSeries, getReportUrl, }) => {
    const theme = useTheme();
    const classes = useStyles();
    const [hovered, setHovered] = React.useState(undefined);
    const calculateBarX = (d, i) => {
        var _a;
        const nextBarX = (_a = xScale((i + 1).toString())) !== null && _a !== void 0 ? _a : funnelWidth;
        return nextBarX - barWidth;
    };
    const calculatePercentageX = (d, i) => {
        var _a;
        const nextBarX = (_a = xScale(i.toString())) !== null && _a !== void 0 ? _a : funnelWidth;
        return nextBarX;
    };
    return (_jsx(_Fragment, { children: stackedSeries[0].map((d, i) => {
            var _a;
            const height = d[1] === 0 ? funnelConfig.minimumBarHeight : hScale(d[1]);
            const y = d[1] === 0
                ? funnelConfig.svgHeight - funnelConfig.minimumBarHeight
                : yScale(d[1]);
            return (_jsxs(ReportLink, { className: classes.bar, onMouseEnter: () => setHovered(i), onMouseLeave: () => setHovered(undefined), to: getReportUrl(dynamicDataset[i].passReportTemplateCode), children: [_jsx("rect", { width: barWidth, height: height, fill: hovered === i
                            ? theme.palette.blue[700]
                            : theme.palette.blue[600], filter: hovered === i ? "url(#shadow)" : "", rx: "8", x: calculateBarX(d, i), y: y }, i), d[1] > 0 ? (_jsx(PassPercentage, { elementWidth: elementWidth, barWidth: barWidth, x: calculatePercentageX(d, i), y: yScale(d[1]) + hScale(d[1]) / 2 - PERCENTAGE_FONT_SIZE, value: Math.floor((dynamicDataset[i].pass /
                            (dynamicDataset[i].pass + dynamicDataset[i].fail)) *
                            100) })) : null, _jsx(PassBarsLegend, { isHovered: hovered === i, elementWidth: elementWidth, data: dynamicDataset[i], x: (_a = xScale(i.toString())) !== null && _a !== void 0 ? _a : 0, lineMiddlePoint: elementWidth - barWidth / 2 })] }, i));
        }) }));
};
