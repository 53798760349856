/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityNameRoleValueAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityNameRoleValueAChart.title"),
    description: () => t("accessibilityNameRoleValueAChart.description"),
    reportTemplateCodesOrder: [
      "aria_hidden_focus_issues",
      "button_name_issues",
      "input_button_name_issues",
      "link_name_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "aria_hidden_focus_issues",
        "button_name_issues",
        "input_button_name_issues",
        "link_name_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
