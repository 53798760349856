import { ConnectionPredicate } from "../../graphql";

function isUndefined(value: unknown): value is undefined {
  return typeof value === "undefined";
}

function isNil(value: unknown): value is null | undefined {
  return value === null || isUndefined(value);
}

function isNonNullableObject<TData>(
  obj: TData,
): obj is Exclude<TData, null | undefined> & object {
  return obj !== null && typeof obj === "object";
}

export function countFilters<TData>(obj: TData): number {
  if (!isNonNullableObject(obj)) return 0;

  const connectionPredicates = Object.values(ConnectionPredicate);

  const matcher = Array.isArray(connectionPredicates)
    ? (key: string) =>
        connectionPredicates.length
          ? connectionPredicates.includes(key as ConnectionPredicate)
          : true
    : connectionPredicates;

  return Object.entries(obj).reduce((acc, [k, v]) => {
    const doesKeyMatch = matcher(k);
    const isValueNil = isNil(v);
    const shouldCount = doesKeyMatch && !isValueNil;
    const currentKeyCount = shouldCount ? 1 : 0;
    const nestedCount = isNonNullableObject(v) ? countFilters(v) : 0;
    return acc + currentKeyCount + nestedCount;
  }, 0);
}
