import { MetricsValuePresenterProps } from "../../data/types";

export function AccessibilityIssuesCountByRuleIdPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  if (!Array.isArray(value)) return <>-</>;

  // eslint-disable-next-line fp/no-mutating-methods
  const issues = (
    [...value] as {
      rule_id: string;
      issues_count: number;
    }[]
  ).sort((a, b) => {
    const diffByIssueCount = b.issues_count - a.issues_count;
    if (diffByIssueCount !== 0) return diffByIssueCount;
    return a.rule_id.localeCompare(b.rule_id);
  });

  return (
    <>
      {issues.map((x) => (
        <div key={x.rule_id}>
          {x.rule_id}: {x.issues_count}
        </div>
      ))}
    </>
  );
}
