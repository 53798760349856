import { useTranslation } from "@lumar/shared";
import { ResourceDetailData } from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";

export function OpportunityDetailsOverview({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useMetricsConfig();

  return <MetricsGroups metrics={data.metrics} config={config} />;
}

function useMetricsConfig(): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  return [
    {
      name: t("opportunity.summary"),
      items: [
        { code: "failedAuditsCount" },
        { code: "warningAuditsCount" },
        { code: "infoAuditsCount" },
        { code: "passedAuditsCount" },
        { code: "totalSizeKibSum" },
        { code: "totalSizeKibAvg" },
        { code: "wastedKibSum" },
        { code: "wastedSecsSum" },
        { code: "gaPageviewsSum" },
        { code: "gscClicksSum" },
        { code: "productOfWastedSecsAndPageviewsSum" },
        { code: "productOfWastedKibAndPageviewsSum" },
      ],
    },
  ];
}
