import { groupBy, isString } from "lodash";
import { Project, UpdateProjectInput } from "../../../../../graphql";
import { CustomRequestHeaders } from "../types";

// Note: The API only allows unique request header keys. For better UX the values are divided
// into separate rows and concatenated back into a single value on save. - Csaba

const separator = ";";

type QueryData = Pick<Project, "customRequestHeaders">;

export function formatCustomRequestHeadersSettingsFrom(
  data?: QueryData | null,
): CustomRequestHeaders {
  const customRequestHeaders = data?.customRequestHeaders;

  return {
    headers: customRequestHeaders
      ? Object.entries(customRequestHeaders).flatMap(([key, value]) => {
          if (!isString(value)) return [];
          return value
            .split(separator)
            .filter((value) => value.length)
            .map((value) => ({ key, value }));
        })
      : [],
  };
}

export function formatCustomRequestHeadersSettingsTo(
  formValues: CustomRequestHeaders,
): Pick<UpdateProjectInput, "customRequestHeaders"> {
  return {
    customRequestHeaders: Object.entries(
      groupBy(formValues.headers, (x) => x.key),
    ).map(([key, values]) => ({
      key,
      value: values.map((v) => v.value).join(separator),
    })),
  };
}
