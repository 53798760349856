/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getCanonicalIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("canonicalIssues.title"),
    description: () => t("canonicalIssues.description"),
    reportTemplateCodesOrder: [
      "canonical_to_non_200",
      "conflicting_canonical_tags",
      "non_indexable_canonical_links_in",
      "canonicalized_noindexed_pages",
      "unlinked_canonical_pages",
      "pages_without_canonical_tag",
      "high_canonicals_in",
    ],
    reportStatFilter: (report) => {
      return [
        "canonical_to_non_200",
        "conflicting_canonical_tags",
        "non_indexable_canonical_links_in",
        "canonicalized_noindexed_pages",
        "unlinked_canonical_pages",
        "pages_without_canonical_tag",
        "high_canonicals_in",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
