import React from "react";
import { SourcesContext } from "./useSourcesFormValues";
import { useGetGa4PropertyConversionEventNamesQuery } from "../../../../graphql";
import { isNonEmpty } from "@lumar/shared";

export function useConversionEventNames({
  googleConnectionId,
  propertyName,
  skip,
}: {
  googleConnectionId?: string;
  propertyName?: string;
  skip?: boolean;
}): {
  loading: boolean;
  error?: string;
  conversionEventNames: string[];
} {
  const { googleAnalytics4ProjectProperty } = React.useContext(SourcesContext);

  const shouldFetch =
    Boolean(googleConnectionId) &&
    Boolean(propertyName) &&
    !(
      googleConnectionId ===
        googleAnalytics4ProjectProperty?.googleConnectionId &&
      propertyName === googleAnalytics4ProjectProperty?.propertyName
    );

  const { loading, error, data } = useGetGa4PropertyConversionEventNamesQuery({
    variables: {
      googleConnectionId: googleConnectionId || "",
      propertyName: propertyName || "",
    },
    skip: !shouldFetch || skip,
    fetchPolicy: "cache-first",
  });

  if (
    googleConnectionId ===
      googleAnalytics4ProjectProperty?.googleConnectionId &&
    propertyName === googleAnalytics4ProjectProperty?.propertyName
  ) {
    return {
      loading: false,
      conversionEventNames:
        googleAnalytics4ProjectProperty?.conversionEventNames || [],
    };
  }

  return {
    loading,
    error: error?.message,
    conversionEventNames:
      (data?.node && isNonEmpty(data.node)
        ? data.node.conversionEventNames
        : undefined) || [],
  };
}
