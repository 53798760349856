/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getHreflangBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("hreflangBreakdown.title"),
    description: () => t("hreflangBreakdown.description"),
    reportTemplateCodesOrder: [
      "pages_with_hreflang_tags",
      "pages_without_hreflang_tags",
    ],
    reportStatFilter: (report) => {
      return [
        "pages_with_hreflang_tags",
        "pages_without_hreflang_tags",
      ].includes(report.reportTemplateCode);
    },

    showPercentageInSeriesTooltip: true,

    serieName: (report) => {
      return report.reportTemplateName;
    },
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
