import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ImpactBadge } from "../../../_common/report-helpers/ReportImpactBadge";
import { getReportImpact } from "../../../_common/report-helpers/reportImpact";

export function ReportImpactCell({
  report,
}: {
  report: CrawlContextCrawlReportStat;
}): JSX.Element {
  return (
    <ImpactBadge impact={getReportImpact(report.reportTemplateTotalSign)} />
  );
}
