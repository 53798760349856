import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Metrics, MetricsValuePresenterProps } from "../data/types";
import {
  ChangedFromPresenter,
  metricPresenters,
  typePresenters,
} from "./default-presenters";
import { JSONPresenter } from "./default-presenters/JSONPresenter";
import { MetricType } from "../../graphql";

type PresenterResult = {
  metric: string;
  type?: string;
  component: React.ElementType<MetricsValuePresenterProps>;
};

type Props = Omit<React.ComponentPropsWithRef<"div">, "children"> & {
  metrics: Metrics;
  code: string;
  value?: React.ReactNode;
  presenter?: PresenterResult | undefined;
  componentProps?: Record<string, unknown>;
};

export const MetricsValuePresenter = React.forwardRef(
  MetricsValuePresenterInner,
);

function MetricsValuePresenterInner(
  {
    metrics,
    code,
    value,
    presenter = getDefaultPresenter(code, metrics),
    componentProps,
    className,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLParagraphElement>,
): JSX.Element {
  const classes = useStyles({ presenterType: presenter?.type });

  function getChildren(): React.ReactNode {
    if (value !== undefined) return value;

    if (presenter) {
      const { component: Component } = presenter;

      return (
        <Component
          metrics={metrics}
          code={code}
          value={metrics[code]?.value}
          {...componentProps}
        />
      );
    }

    const metricValue = metrics[code]?.value;
    return metricValue === undefined ? "-" : JSON.stringify(metricValue);
  }

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...props}>
      {getChildren()}
    </div>
  );
}

function getDefaultPresenter(
  code: string,
  metrics: Metrics,
): PresenterResult | undefined {
  const metricPresenter = metricPresenters[code];
  const type = metrics[code]?.data?.type;
  if (metricPresenter) {
    return {
      metric: code,
      type,
      component: metricPresenter,
    };
  }

  const metadataPresenter = getMetadataPresenter(code, metrics);
  if (metadataPresenter) {
    return metadataPresenter;
  }

  const isChangedFromMetric = Boolean(metrics[code]?.data?.changedMetricCode);
  if (isChangedFromMetric) {
    return {
      metric: code,
      type,
      component: ChangedFromPresenter,
    };
  }

  const typePresenter = type && typePresenters[type];
  if (typePresenter) {
    return {
      metric: code,
      type,
      component: typePresenter,
    };
  }

  return undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[800],
    whiteSpace: "pre-wrap",
    overflowWrap: "anywhere",
    height: (props: { presenterType?: string }) =>
      props.presenterType === "JSON" ? "100%" : "auto",
  },
}));

export const getMetadataPresenter = (
  code: string,
  metrics: Metrics,
): PresenterResult | undefined => {
  const isJSONList =
    metrics[code]?.data?.type === MetricType.Array &&
    (metrics[code]?.data?.metadata?.items?.type?.toLowerCase() === "json" ||
      metrics[code]?.data?.metadata?.items?.type?.toLowerCase() === "object");

  if (isJSONList) {
    return {
      metric: code,
      type: "JSON",
      component: JSONPresenter,
    };
  }
};
