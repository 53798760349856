import { Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  speed: {
    color: theme.palette.grey[600],
  },
}));

export function CrawlSpeedCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("projectsList");

  return (
    <Typography
      variant="captionSemiBold"
      className={classes.speed}
      data-testid="all-projects-crawl-speed-cell"
    >
      {t("crawlSpeedCellValue", {
        value: row.crawlSpeed,
      })}
    </Typography>
  );
}
