import { BreadcrumbItem, ApolloError } from "@lumar/shared";
import React from "react";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import {
  CrawlContextValue,
  CrawlReportCategoryListNode,
  useCrawlContext,
} from "./CrawlContext";
import { OverviewType } from "./types";
import { ModuleCode } from "../graphql";
import { Redirect, useParams } from "react-router-dom";
import { ReportEntity } from "../report/Report.types";

export interface CrawlOverviewContextValue {
  crawlContext: CrawlContextValue;
  loading: boolean;
  categoryNotFound: boolean;
  errors?: ApolloError[];
  helpers?: {
    selectCategory: (categoryCode: string) => void;
    selectOverviewType: (type: OverviewType) => void;
  };
  data?: {
    selectedOverviewType: OverviewType;
    selectedCategory: CrawlReportCategoryListNode;
    moduleCode: ModuleCode;
    breadcrumbs: BreadcrumbItem[];
  };
}

const CrawlOverviewContext =
  React.createContext<CrawlOverviewContextValue | null>(null);

export function useCrawlOverviewContext(): CrawlOverviewContextValue {
  const ctx = React.useContext(CrawlOverviewContext);

  if (ctx) {
    return ctx;
  }

  throw new Error(
    "`useCrawlOverviewContext` has been called without `CrawlOverviewContextProvider` in the tree!",
  );
}

export function useCrawlOverviewContextData(): NonNullable<
  CrawlOverviewContextValue["data"]
> {
  const ctx = React.useContext(CrawlOverviewContext);

  if (ctx === null) {
    throw new Error(
      "`useCrawlOverviewContextData` has been called without `CrawlOverviewContextProvider` in the tree!",
    );
  }

  if (!ctx.data) {
    throw new Error(
      "`useCrawlOverviewContextData` has been called while data wasn't loaded!",
    );
  }

  return ctx.data;
}

export function CrawlOverviewContextProvider(props: {
  reportEntity: ReportEntity;
  children: React.ReactNode;
}): JSX.Element {
  const crawlContext = useCrawlContext();
  const { data, loading, errors } = crawlContext;
  const searchParams = useURLSearchParams();

  React.useEffect(() => window.scrollTo(0, 0), [searchParams]);

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (!searchParams.get("category")) {
      const defaultCategory = getDefaultTopReportCategory(data);

      searchParams.set("category", defaultCategory);
      searchParams.apply();
    }

    if (!searchParams.get("type")) {
      switch (props.reportEntity) {
        case ReportEntity.CustomReport: {
          searchParams.set("type", "all");
          searchParams.apply();
          break;
        }
        default:
          searchParams.set("type", "dashboard");
          searchParams.apply();
          break;
      }
    }
  }, [searchParams, data, props.reportEntity]);

  const value = React.useMemo(() => {
    if (loading) {
      return {
        crawlContext,
        loading: true,
        categoryNotFound: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    if (errors) {
      return {
        crawlContext,
        loading: false,
        categoryNotFound: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    if (!data) {
      return {
        crawlContext,
        categoryNotFound: false,
        loading: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    const selectedCategory = data.crawlReportCategoriesList.find(
      (crawlReportCategory) =>
        crawlReportCategory.code === searchParams.get("category"),
    );

    if (!selectedCategory) {
      return {
        crawlContext,
        categoryNotFound: true,
        loading: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    // Always guaranteed.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const selectedOverviewType = searchParams.get("type") as OverviewType;

    return {
      crawlContext,
      loading: false,
      categoryNotFound: false,
      errors: undefined,
      helpers: {
        selectCategory(categoryCode: string): void {
          searchParams.set("category", categoryCode);
          searchParams.apply();
        },
        selectOverviewType(type: OverviewType): void {
          searchParams.set("type", type);
          searchParams.delete("sorting");
          searchParams.delete("page");
          searchParams.apply();
        },
      },
      data: {
        selectedOverviewType,
        selectedCategory,
        moduleCode: data.crawlProject.moduleCode,
        breadcrumbs: createBreadcrumbs(
          data,
          selectedOverviewType,
          selectedCategory,
        ),
      },
    };
  }, [loading, errors, data, crawlContext, searchParams]);

  const resolvedPath = useResolvePath();
  if (resolvedPath) {
    return <Redirect to={resolvedPath} />;
  }

  return (
    <CrawlOverviewContext.Provider value={value}>
      {props.children}
    </CrawlOverviewContext.Provider>
  );
}

function createBreadcrumbs(
  crawlContextData: NonNullable<CrawlContextValue["data"]>,
  selectedOverviewType: string | null,
  selectedCategory?: CrawlReportCategoryListNode,
): BreadcrumbItem[] {
  if (!selectedCategory) {
    return [];
  }

  const categoryLineage: CrawlReportCategoryListNode[] = [selectedCategory];

  // eslint-disable-next-line fp/no-loops
  while (categoryLineage[0]?.parentCode) {
    const parent = crawlContextData.crawlReportCategoriesList.find(
      (crawlReportCategory) =>
        crawlReportCategory.code === categoryLineage[0].parentCode,
    );
    if (parent) {
      // eslint-disable-next-line fp/no-mutating-methods
      categoryLineage.unshift(parent);
    } else {
      break;
    }
  }

  return categoryLineage.map((crawlReportCategory) => {
    return {
      label: crawlReportCategory.name,
      link: Routes.CrawlOverview.getUrl({
        accountId: crawlContextData.crawlProject.account.rawID,
        crawlId: crawlContextData.crawl.rawID,
        projectId: crawlContextData.crawlProject.rawID,
        category: crawlReportCategory.code,
        segmentId: crawlContextData.selectedCrawlSegment?.segment.id,
        type: selectedOverviewType,
      }),
    };
  });
}

const prioritizedDefaultTopReportCategoryCodes = [
  "top", // top category for SEO crawls
  "accessibility", // top category for A11Y crawls
  "site_speed", // top category for SiteSpeed crawls
];

function getDefaultTopReportCategory(
  data: NonNullable<CrawlContextValue["data"]>,
): string {
  return (
    prioritizedDefaultTopReportCategoryCodes.find((code) =>
      data?.crawlReportCategoriesTree.find(
        (topReportCategory) => topReportCategory.code === code,
      ),
    ) ?? "summary"
  );
}

function useResolvePath(): string | undefined {
  const params = useURLSearchParams();
  const { data } = useCrawlContext();
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (!data || !params.get("resolvePath")) return;

  const categoryCode = params.get("category");
  const category = data.crawlReportCategoriesList.find(
    (x) => x.code === categoryCode,
  );

  if (category) {
    params.delete("resolvePath");
    params.apply();
  } else {
    return Routes.CrawlOverview.getUrl({
      accountId,
      projectId,
      crawlId,
    });
  }
}
