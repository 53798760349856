import {
  makeStyles,
  Grid,
  Drawer,
  CircularProgress,
  ButtonGroup,
} from "@material-ui/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ProjectSelector } from "./project-selector/ProjectSelector";
import { SelectedProjectInfo } from "./selected-project-info/SelectedProjectInfo";
import { RoleCode, useProjectViewerQuery } from "../../graphql";
import { ProjectViewerLink } from "../project-viewer-link/ProjectViewerLink";
import {
  CogSolid,
  SwitchHorizontalOutlined,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Routes } from "../../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  drawerAnchorLeft: { left: "64px" },
  childrenContainer: {
    borderBottom: "solid 1px",
    borderBottomColor: "#D1D5DB",
    marginBottom: 10,
  },
  paper: { backgroundColor: "#EBEFF3", borderRadius: 0 },
  projectButton: {
    width: "100%",
    textTransform: "none",
    textAlign: "left",
    padding: 5,
    marginBottom: 5,
  },
  projectTitle: {
    color: "#4B5563",
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
    WebkitMaskImage:
      "linear-gradient(to right, rgba(0,0,0,1) 72%, rgba(0,0,0,0))",
  },
  bold: { fontWeight: 600 },
  buttonGroup: {
    marginBottom: theme.spacing(1.75),
  },
}));

export function ProjectViewer(): JSX.Element {
  const { projectId, accountId } = useParams<{
    projectId: string;
    accountId: string;
  }>();

  const classes = useStyles();

  const { t } = useTranslation("sidebar");

  const { hasSufficientRole } = useSession();

  const [open, setOpen] = useState(false);
  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  const { data, loading } = useProjectViewerQuery({
    variables: { id: projectId },
    skip: !projectId,
    fetchPolicy: "cache-first",
  });

  const selectedProjectInfo = data?.getProject?.name
    ? { ...data?.getProject }
    : undefined;

  if (loading) {
    return (
      <div
        className={classes.childrenContainer}
        style={{ minHeight: 86, maxHeight: 86, paddingTop: 18 }}
        data-pendo="project-switcher"
      >
        <CircularProgress style={{ display: "block", margin: "auto" }} />
      </div>
    );
  }

  return (
    <div className={classes.childrenContainer} data-pendo="project-switcher">
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        className={classes.projectButton}
      >
        <Grid item xs={10}>
          <SelectedProjectInfo
            className={classes.projectTitle}
            size="small"
            selectedProjectInfo={selectedProjectInfo}
          ></SelectedProjectInfo>
        </Grid>
      </Grid>
      <ButtonGroup
        variant="outlined"
        size="small"
        fullWidth={hasSufficientRole(RoleCode.Editor)}
        className={classes.buttonGroup}
      >
        {hasSufficientRole(RoleCode.Editor) ? (
          <ProjectViewerLink
            Icon={CogSolid}
            disabled={!selectedProjectInfo}
            label={t("projectSettings")}
            to={Routes.Crawls.getUrl({
              accountId,
              projectId,
              tab: "edit",
            })}
            data-pendo="sidebar-project-settings-button"
          />
        ) : null}
        <ProjectViewerLink
          Icon={SwitchHorizontalOutlined}
          to={toggleDrawer}
          label={t("changeProject")}
          data-testid="switch-project"
          data-pendo="sidebar-switch-project-button"
        />
      </ButtonGroup>
      <Drawer
        transitionDuration={{ enter: 150, exit: 200 }}
        classes={{
          paperAnchorLeft: classes.drawerAnchorLeft,
          paper: classes.paper,
        }}
        elevation={0}
        disablePortal={true}
        open={open}
        hideBackdrop={true}
        onClick={toggleDrawer}
      >
        <ProjectSelector
          selectedProjectInfo={selectedProjectInfo}
        ></ProjectSelector>
      </Drawer>
    </div>
  );
}
