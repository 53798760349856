import React, { useRef, useState } from "react";
import {
  Button,
  Chip,
  Grid,
  makeStyles,
  Popover,
  Tooltip,
} from "@material-ui/core";
import { Field, FieldProps, Formik } from "formik";
import {
  useTranslation,
  fieldToTextField,
  TextField,
  InfoOutlined,
  Typography,
} from "@lumar/shared";
import AddIcon from "@material-ui/icons/Add";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import * as Yup from "yup";

import { translationNamespace } from "../../CrawlSettings";
import { AnalyticsValuesResult } from "./useAnalyticsValues";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    maxWidth: "600px",
  },
  input: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  error: {
    pointerEvents: "none",
    background: theme.palette.error.main,
    color: "white",
    height: 20,
    marginRight: "auto",
  },
}));

interface AdobeAccount {
  clientId: string;
  clientSecret: string;
  orgId: string;
  privateKey: string;
  technicalAccountId: string;
}

export function AddAdobeAccount({
  addConnection,
}: {
  addConnection: AnalyticsValuesResult["addAdobeConnection"];
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(translationNamespace);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (account: AdobeAccount): Promise<void> => {
    setError("");
    const error = await addConnection(
      account.clientId,
      account.clientSecret,
      account.orgId,
      account.privateKey,
      account.technicalAccountId,
    );

    if (error) {
      setError(error);
    } else {
      setIsOpen(false);
    }
  };

  const schema = Yup.object().shape({
    clientId: Yup.string().required(t("validationError.required")),
    clientSecret: Yup.string().required(t("validationError.required")),
    orgId: Yup.string().required(t("validationError.required")),
    privateKey: Yup.string().required(t("validationError.required")),
    technicalAccountId: Yup.string().required(t("validationError.required")),
  });

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        ref={buttonRef}
        variant="outlined"
        size="small"
        startIcon={<ShowChartIcon />}
      >
        {t("sources.analytics.adobeAnalytics.addAccount")}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={buttonRef.current}
        onClose={() => {
          setIsOpen(false);
          setError("");
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Formik
          initialValues={{
            clientId: "",
            clientSecret: "",
            orgId: "",
            privateKey: "",
            technicalAccountId: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ submitForm, isValid, isSubmitting }) => (
            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={6}>
                <Field name="clientId">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("sources.analytics.adobeAnalytics.clientId")}
                      data-testid="adobe-edit-client-id"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="orgId">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("sources.analytics.adobeAnalytics.orgId")}
                      data-testid="adobe-edit-org-id"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="technicalAccountId">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t(
                        "sources.analytics.adobeAnalytics.technicalAccountId",
                      )}
                      data-testid="adobe-edit-technical-account-id"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="clientSecret">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("sources.analytics.adobeAnalytics.clientSecret")}
                      data-testid="adobe-edit-client-secret"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="privateKey">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      multiline
                      minRows={10}
                      label={
                        <>
                          {t("sources.analytics.adobeAnalytics.privateKey")}{" "}
                          <Tooltip
                            title={
                              <Typography
                                variant="caption"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {t(
                                  "sources.analytics.adobeAnalytics.privateKeyTooltip",
                                )}
                              </Typography>
                            }
                            placement="right"
                            style={{
                              verticalAlign: "middle",
                            }}
                          >
                            <InfoOutlined color="inherit" fontSize="inherit" />
                          </Tooltip>
                        </>
                      }
                      data-testid="adobe-edit-private-key"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                {error && (
                  <Chip
                    size="small"
                    classes={{
                      root: classes.error,
                    }}
                    label={error}
                  />
                )}
                <Button
                  onClick={submitForm}
                  disabled={!isValid || isSubmitting}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  {t("sources.analytics.adobeAnalytics.addAccount")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Popover>
    </>
  );
}
