import { Typography, useNumberFormatter, useTranslation } from "@lumar/shared";
import { CrawlContextCrawlReportStat } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "@lumar/shared/dist/graphql";
import {
  getCustomReportTemplateId,
  isCustomReportGUID,
} from "../../../_common/report-helpers/customReport";

export function MissingCell({
  report,
  value,
}: {
  report: CrawlContextCrawlReportStat;
  value?: number;
}): JSX.Element {
  const { t } = useTranslation("crawlOverview");
  const formatNumber = useNumberFormatter();

  const isCustomReport = isCustomReportGUID(report.id);

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: getCustomReportTemplateId(report.id),
              reportTypeCode: ReportTypeCode.Missing,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              crawlId: report.crawlId,
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              reportTypeCode: ReportTypeCode.Missing,
            }
      }
    >
      <Typography style={{ fontWeight: 500 }}>
        {typeof value !== "number" || Number.isNaN(value)
          ? t("data.na")
          : formatNumber(value)}
      </Typography>
    </ReportLink>
  );
}
