import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getBotHitsCrawlBudget(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    requiredSources: {
      sources: [CrawlType.LogSummary],
      incompleteMessage: t("requiredSourcesMessage.logsSource"),
    },
    title: t("botHitsCrawlBudget.title"),
    description: t("botHitsCrawlBudget.description"),
    reportTemplateCodes: [
      "primary_pages_with_bot_hits",
      "primary_pages_with_no_bot_hits",
      "duplicate_pages_with_bot_hits",
      "non_indexable_pages_with_bot_hits",
      "redirects_with_bot_hits",
      "error_pages_with_bot_hits",
    ],
  })();
}
