import React, { useState } from "react";
import { Box, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DocumentTextSolid, TabSpinner } from "@lumar/shared";
import { translationNamespace } from "../../CrawlSettings";
import { LogSummaryManualUploads } from "./LogSummaryManualUploads";
import { Splunk } from "./splunk/Splunk";
import { Logzio } from "./logzio/Logzio";
import { ReactComponent as SplunkLogo } from "../../../../_common/assets/vendor-logos/splunk.svg";
import { ReactComponent as LogzioLogo } from "../../../../_common/assets/vendor-logos/logzio.svg";
import { ManualUploadsContext } from "../manual-uploads/ManualUploadsWrapper";
import { CrawlType } from "../../../../graphql";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import { FormValues } from "../data/types";
import { useSplunkQueries } from "./splunk/useSplunkQueries";
import { useLogzioQueries } from "./logzio/useLogzioQueries";
import { AddonAccess } from "../../components/AddonAccess";
import { insertIf } from "../../../../_common/insertIf";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  iconLabelWrapper: {
    padding: theme.spacing(1),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "80px",
    height: "32px",
  },
  labelIcon: {
    minHeight: "unset",
  },
}));

export const LogSummary = React.memo(function LogSummary({
  initialState,
}: {
  initialState: boolean;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(0);

  const {
    connections: splunkConnections,
    queries: splunkQueries,
    addConnection: addSplunkConnection,
    addQuery: addSplunkQuery,
    setQueryStatus: setSplunkQueryStatus,
    deleteQuery: deleteSplunkQuery,
  } = useSplunkQueries();

  const {
    connections: logzioConnections,
    queries: logzioQueries,
    addConnection: addLogzioConnection,
    addQuery: addLogzioQuery,
    setQueryStatus: setLogzioQueryStatus,
    deleteQuery: deleteLogzioQuery,
  } = useLogzioQueries();

  const getBadges = (
    values: FormValues,
    hasFile: boolean,
  ): CustomAccordionBadge[] => {
    const shouldShowWarning =
      values.crawlType.logSummary &&
      !hasFile &&
      !splunkQueries.length &&
      !logzioQueries.length;
    const isDataOnly = values.dataOnlyCrawlType.logSummary;
    return [
      ...insertIf(isDataOnly, {
        label: t("sources.dataOnly"),
        info: "",
        color: "primary" as const,
      }),
      ...insertIf(shouldShowWarning, {
        label: t("sources.logSummary.warningLabel"),
        info: t("sources.logSummary.warningInfo"),
        color: "yellow" as const,
      }),
    ];
  };

  return (
    <CustomAccordion
      title={t("sources.logSummary.title")}
      icon={
        <DocumentTextSolid
          fontSize="small"
          style={{ display: "block", marginBottom: "1px" }}
        />
      }
      tooltip={t("sources.logSummary.tooltip")}
      initialState={initialState}
      fieldName="crawlType.logSummary"
      getBadges={getBadges}
      bottomContent={
        <DataOnlySourceSwitch name="dataOnlyCrawlType.logSummary" />
      }
      data-pendo="log-files-header"
      data-testid="log-files-section"
    >
      <Box className={classes.fullWidth}>
        <Tabs
          value={selectedOption}
          onChange={(_, value) => setSelectedOption(value)}
        >
          <Tab
            icon={<LogzioLogo className={classes.icon} />}
            label={
              <>
                {t("sources.logSummary.tabDescription")}&nbsp;
                <span style={{ fontSize: "12px" }}>
                  ({logzioQueries.length})
                </span>
              </>
            }
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
          />
          <Tab
            icon={<SplunkLogo className={classes.icon} />}
            label={
              <>
                {t("sources.logSummary.tabDescription")}&nbsp;
                <span style={{ fontSize: "12px" }}>
                  ({splunkQueries.length})
                </span>
              </>
            }
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
          />
          <Tab
            label={
              <ManualUploadsContext.Consumer>
                {({ getFileCount, loading }) => (
                  <>
                    {t("sources.logSummary.manualUploads.title")}&nbsp;
                    {loading ? (
                      <TabSpinner />
                    ) : (
                      <span style={{ fontSize: "12px" }}>
                        ({getFileCount(CrawlType.LogSummary)})
                      </span>
                    )}
                  </>
                )}
              </ManualUploadsContext.Consumer>
            }
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
          />
        </Tabs>
        {selectedOption === 0 && (
          <AddonAccess
            addonCode="logzio"
            addonName={t("addonAccess.logzioName")}
            message={t("addonAccess.logzioDescription")}
            feature="Logz.io Integration"
          >
            <Logzio
              connections={logzioConnections}
              queries={logzioQueries}
              addConnection={addLogzioConnection}
              addQuery={addLogzioQuery}
              setQueryStatus={setLogzioQueryStatus}
              deleteQuery={deleteLogzioQuery}
            />
          </AddonAccess>
        )}
        {selectedOption === 1 && (
          <AddonAccess
            addonCode="splunk"
            addonName={t("addonAccess.splunkName")}
            message={t("addonAccess.splunkDescription")}
            feature="Splunk Integration"
          >
            <Splunk
              connections={splunkConnections}
              queries={splunkQueries}
              addConnection={addSplunkConnection}
              addQuery={addSplunkQuery}
              setQueryStatus={setSplunkQueryStatus}
              deleteQuery={deleteSplunkQuery}
            />
          </AddonAccess>
        )}
        {selectedOption === 2 && <LogSummaryManualUploads />}
      </Box>
    </CustomAccordion>
  );
});
