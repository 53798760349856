/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ReportStatTrendItem } from "../../../graphql";
import { Routes } from "../../routing/routes";

import { ChartPanelSuccessMessage } from "../components/chart-messages/ChartPanelSuccessMessage";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { OverviewType } from "../../../crawl-overview/types";
import { isNumber } from "lodash";

export function getIssuesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "Sparkline",
    visualisationType: VisualisationTypes.SparklineList,
    title: () => t("issues.title"),
    description: () => t("issues.description"),
    moreLink: {
      title: (reportCount) => t("issues.viewAll", { count: reportCount }),
      href: ({ accountId, projectId, crawlId, segmentId, category }) =>
        Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          segmentId,
          type: OverviewType.Errors,
          category,
        }),
    },
    reportStatFilter: (report, viewedCategoryCode) => {
      return (
        report.totalWeight < 0 &&
        Number(report.reportTemplateTotalSign) < 0 &&
        Boolean(
          report.reportTemplate.reportCategories.find(
            (reportCategory) => reportCategory.code === viewedCategoryCode,
          ),
        )
      );
    },
    reportStatsOrderBy: {
      field: "totalWeight",
      direction: "asc",
    },
    noReportsTemplate: (
      <ChartPanelMessageWrapper>
        <ChartPanelSuccessMessage>
          {t("issues.noIssuesFound")}
        </ChartPanelSuccessMessage>
      </ChartPanelMessageWrapper>
    ),

    count: (report) => {
      return report.basic ?? 0;
    },
    sign: () => -1,
    change: (report) => {
      if (isNumber(report?.change)) return report.change;
      if (report.trend) {
        return report.trend[0]?.basic && report.trend[1]?.basic
          ? report.trend[0].basic - report.trend[1].basic
          : 0;
      }
      return 0;
    },
    trendValues: (report) =>
      report.trend
        .filter(
          (
            trend,
          ): trend is ReportStatTrendItem & {
            createdAt: string;
          } => !!trend?.createdAt,
        )
        .map((report) => {
          const totalRows = report?.basic ?? 0;
          const createdAt = report?.createdAt;
          return { y: totalRows || 0, x: new Date(createdAt) };
        }),

    showChangePercentage: true,
    testAttributePrefix: "top-issues-chart",
  };
}
