import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { FastField } from "formik";
import { CircularProgress } from "@material-ui/core";
import { ArrowRightSolid, Button, EyeSolid } from "@lumar/shared";

import { useFormValues } from "./data/useFormValues";
import { useDomainSchema } from "./data/useDomainSchema";
import { ContextValues } from "./data/types";
import { AccessibilitySettings } from "./accessibility/AccessibilitySettings";
import { BasicSettings } from "./basic/BasicSettings";
import { SettingsErrorOverlay } from "../components/SettingsErrorOverlay";
import { ProjectPreviewDialog } from "../project-preview/ProjectPreviewDialog";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../_common/routing/routes";
import { ModuleCode } from "../../../graphql";
import { SeoSettings } from "./seo/SeoSettings";
import { SiteSpeedSettings } from "./site-speed/SiteSpeedSettings";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: 208,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    backgroundColor: "rgba(209, 213, 219,0.3)",
    width: "100%",
    padding: theme.spacing(1.5, 2),
    display: "flex",
  },
}));

export const DomainContextDefaultValues: ContextValues = {
  hasCrawlInProgress: false,
  hasCrawlUnarchiving: false,
  hasJsRendering: false,
  industries: [],
  userAgents: [],
  userAgentSuffix: "",
  module: {
    code: ModuleCode.Basic,
    userAgentSuffixEnabled: false,
    viewportHeightDesktop: 0,
    viewportHeightMobile: 0,
    viewportWidthDesktop: 0,
    viewportWidthMobile: 0,
  },
};

export const DomainContext = React.createContext<ContextValues>(
  DomainContextDefaultValues,
);

interface Props {
  next: (projectId?: string) => void;
  moduleCode?: ModuleCode;
}

export function Domain({ next, moduleCode }: Props): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();
  const { accountId, projectId: routeProjectId } = useParams<{
    accountId: string;
    projectId?: string;
  }>();
  const history = useHistory();

  const [previewData, setPreviewData] = React.useState<
    { projectId: string; url: string } | undefined
  >();

  const { formValues, contextValues, loading, error, createOrUpdate } =
    useFormValues(moduleCode);

  const schema = useDomainSchema(contextValues.module.code);

  async function handleSaveAndPreview(
    submitForm: () => Promise<string | undefined>,
    primaryDomain: string,
  ): Promise<void> {
    const projectId = await submitForm();
    if (!projectId) return;

    if (!routeProjectId) {
      history.replace(
        Routes.Crawls.getUrl({ accountId, projectId, tab: "edit", step: 1 }),
      );
    }

    setPreviewData({
      projectId,
      url: primaryDomain,
    });
  }

  async function handleSaveAndContinue(
    submitForm: () => Promise<string | undefined>,
  ): Promise<void> {
    const projectId = await submitForm();
    if (!projectId) return;

    next(projectId);
  }

  if (loading)
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color="primary" />
      </div>
    );

  if (error) {
    return (
      <SettingsErrorOverlay title={t("errorLoadFailed")} description={error} />
    );
  }

  const ModuleSettings = moduleSettings[contextValues.module.code];

  return (
    <DomainContext.Provider value={contextValues}>
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={(values) => createOrUpdate(values)}
        validateOnBlur
        validateOnChange={false}
        validationSchema={schema}
      >
        {({ submitForm, isSubmitting, values }) => {
          const saveDisabled =
            isSubmitting ||
            (contextValues.hasCrawlInProgress &&
              !contextValues.hasCrawlUnarchiving) ||
            !values.basic.primaryDomain ||
            !values.basic.name;

          return (
            <form>
              <FastField name="basic" component={BasicSettings} />

              {ModuleSettings && <ModuleSettings />}

              <div className={classes.buttonContainer}>
                <Button
                  onClick={() =>
                    handleSaveAndPreview(submitForm, values.basic.primaryDomain)
                  }
                  disabled={saveDisabled}
                  variant="outlined"
                  size="large"
                  startIcon={<EyeSolid />}
                  data-testid="crawl-settings-save-and-preview"
                >
                  {t("saveAndPreview")}
                </Button>
                <Button
                  onClick={() => handleSaveAndContinue(submitForm)}
                  disabled={saveDisabled}
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<ArrowRightSolid />}
                  style={{ marginLeft: "auto" }}
                  data-testid="crawl-settings-domain-save-and-continue"
                >
                  {t("saveAndContinue")}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
      <ProjectPreviewDialog
        open={Boolean(previewData)}
        onClose={() => setPreviewData(undefined)}
        projectId={previewData?.projectId || ""}
        url={previewData?.url || ""}
        moduleCode={contextValues.module.code}
      />
    </DomainContext.Provider>
  );
}

const moduleSettings: Partial<Record<ModuleCode, React.ElementType>> = {
  [ModuleCode.Seo]: SeoSettings,
  [ModuleCode.Accessibility]: AccessibilitySettings,
  [ModuleCode.SiteSpeed]: SiteSpeedSettings,
};
