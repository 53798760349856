var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, ButtonBase, Typography, makeStyles, IconButton, } from "@material-ui/core";
import clsx from "clsx";
import { AdjustmentsOutlined } from "../../icons/ui-friendly/outlined/AdjustmentsOutlined";
import { ChevronDownSolid, XCircleOutlined } from "../../icons";
import { Chip } from "../chip/Chip";
const useStyles = makeStyles((theme) => ({
    buttonBase: {
        backgroundColor: "white",
        border: `1px solid ${theme.palette.grey[300]}`,
        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.grey[50],
        },
        "&:focus": {
            backgroundColor: theme.palette.grey[100],
        },
        "&.Mui-disabled": {
            color: theme.palette.grey[400],
            boxShadow: "none",
            backgroundColor: theme.palette.grey[100],
        },
    },
    startIconWrapper: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        display: "flex",
        alignItems: "center",
        padding: "8px",
        marginRight: 10,
    },
    startIcon: { fontSize: 18, color: theme.palette.grey[800] },
    label: {
        alignSelf: "center",
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(17),
        color: theme.palette.grey[700],
    },
    chipRoot: {
        height: "auto",
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(16),
        marginLeft: 10,
        borderRadius: 16,
    },
    clearButton: {
        marginLeft: 3,
        marginRight: -4,
    },
    clearIcon: {
        fontSize: theme.typography.pxToRem(22),
        color: theme.palette.grey[400],
    },
    endIcon: {
        fontSize: 20,
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
export function FilterButton(props) {
    const classes = useStyles();
    const { chipLabel, enableEndIcon, label, className, onClear, ClearButtonProps } = props, others = __rest(props, ["chipLabel", "enableEndIcon", "label", "className", "onClear", "ClearButtonProps"]);
    return (_jsx(ButtonBase, Object.assign({ className: clsx(classes.buttonBase, className) }, others, { children: _jsxs(Box, { display: "flex", alignItems: "stretch", justifyContent: "space-between", width: "100%", children: [_jsxs(Box, { display: "flex", alignItems: "stretch", children: [_jsx(Box, { className: classes.startIconWrapper, children: _jsx(AdjustmentsOutlined, { className: classes.startIcon }) }), _jsx(Typography, { className: classes.label, children: label })] }), _jsxs(Box, { display: "flex", alignItems: "center", pr: 1, children: [chipLabel ? (_jsx(Chip, { label: chipLabel, "data-testid": "filters-applied", color: "primary", className: classes.chipRoot })) : null, onClear ? (_jsx(IconButton, Object.assign({ component: "div", size: "small", className: classes.clearButton, onClick: onClear }, ClearButtonProps, { children: _jsx(XCircleOutlined, { className: classes.clearIcon }) }))) : null, enableEndIcon ? (_jsx(ChevronDownSolid, { className: classes.endIcon })) : (_jsx(_Fragment, {}))] })] }) })));
}
