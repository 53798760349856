var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, } from "@material-ui/core";
import { Fragment } from "react";
import { Typography } from "../typography/Typography";
import { getButtonProps } from "./utils/getButtonProps";
import { getLinkinProps } from "./utils/getLinkinProps";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
const useStyles = makeStyles((theme) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return ({
        list: {},
        listSubheader: {
            paddingTop: 0,
        },
        nestedList: {
            paddingLeft: 16,
        },
        subheader: Object.assign({ color: theme.palette.grey[900], margin: 0, fontWeight: 600, fontSize: theme.typography.pxToRem(14), lineHeight: "30px" }, (_b = (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.NestedNavigationMenu) === null || _b === void 0 ? void 0 : _b.subheader),
        listItem: Object.assign({ color: theme.palette.grey[900], paddingLeft: 6, paddingRight: 3, paddingBottom: 3, paddingTop: 3, borderRadius: 6, marginBottom: 3, marginTop: 3, borderStyle: "none", "&:hover": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[900],
            }, "&:focus-visible": {
                outline: "none",
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[900],
            }, "button&": {
                cursor: "pointer",
                lineHeight: "inherit",
                backgroundColor: "initial",
            } }, (_d = (_c = theme.overrides) === null || _c === void 0 ? void 0 : _c.NestedNavigationMenu) === null || _d === void 0 ? void 0 : _d.listItem),
        listItemText: Object.assign({}, (_f = (_e = theme.overrides) === null || _e === void 0 ? void 0 : _e.NestedNavigationMenu) === null || _f === void 0 ? void 0 : _f.listItemText),
        nestedListItem: Object.assign(Object.assign({ color: theme.palette.grey[900], backgroundColor: "unset", padding: "0px 0px 5px 24px", minHeight: 30, borderStyle: "none", borderLeftStyle: "solid", borderLeftWidth: 1, borderLeftColor: theme.palette.grey[400], borderRadius: 0 }, (_h = (_g = theme.overrides) === null || _g === void 0 ? void 0 : _g.NestedNavigationMenu) === null || _h === void 0 ? void 0 : _h.nestedListItem), { "button&": {
                cursor: "pointer",
                lineHeight: "inherit",
                backgroundColor: "initial",
            } }),
        nestedListItemClickable: {
            "&:hover": {
                color: theme.palette.primary.main,
                borderLeftColor: theme.palette.primary.main,
                backgroundColor: "transparent",
                borderLeftWidth: 2,
                paddingLeft: "23px",
            },
            "&:focus-visible": {
                outline: "none",
                color: theme.palette.primary.main,
                borderLeftColor: theme.palette.primary.main,
                backgroundColor: "transparent",
                borderLeftWidth: 2,
                paddingLeft: "23px",
            },
        },
        nestedListItemDisabled: {
            color: theme.palette.grey[500],
        },
        groupHeaderContainer: {
            marginLeft: 16,
        },
        nestedListItemGroup: {
            paddingLeft: 14,
            paddingRight: 0,
        },
        nestedListItemGroupText: {
            flexGrow: 0,
        },
        nestedListItemSelected: {
            color: `${theme.palette.primary.main} !important`,
            borderLeftColor: theme.palette.primary.main,
            backgroundColor: "unset !important",
            borderLeftWidth: "3px !important",
            paddingLeft: "22px !important",
        },
        nestedListItemText: Object.assign({ whiteSpace: "break-spaces" }, (_k = (_j = theme.overrides) === null || _j === void 0 ? void 0 : _j.NestedNavigationMenu) === null || _k === void 0 ? void 0 : _k.nestedListItemText),
        childDivider: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            flex: 1,
            marginLeft: 12,
        },
        listItemIcon: {
            minWidth: "30px",
            color: theme.palette.grey[600],
        },
        adornmentContainer: {
            paddingRight: 4,
        },
    });
});
export function NestedNavigationMenu(props) {
    const classes = useStyles();
    const history = useHistory();
    return (_jsx(_Fragment, { children: props.sections
            .filter((section) => section.items.length > 0)
            .map((section, index, filteredSections) => {
            return (_jsxs(Fragment, { children: [section.name ? (_jsx(ListSubheader, { component: "span", disableGutters: true, className: classes.subheader, id: `${section.id}-header`, children: section.name })) : undefined, _jsx(List, { id: section.id, "aria-labelledby": `${section.id}-header`, className: clsx({
                            [classes.list]: true,
                            [classes.listSubheader]: Boolean(section.name),
                        }), children: section.items.map((parent) => {
                            const { id, name, icon: Icon, adornment: Adornment, href, onClick, selected, open, children = [] } = parent, props = __rest(parent, ["id", "name", "icon", "adornment", "href", "onClick", "selected", "open", "children"]);
                            return (_jsxs("li", { children: [_jsxs(ListItem, Object.assign({ id: id, "data-pendo": id ? `sidebar-menu-item-${id}` : undefined, className: classes.listItem, selected: isNestedNavigationMenuParentItemSelected(parent, history), component: "div" }, getButtonProps(onClick), getLinkinProps(href), props, { children: [Icon ? (_jsx(ListItemIcon, { className: classes.listItemIcon, children: _jsx(Icon, { fontSize: "small" }) })) : null, _jsx(ListItemText, { disableTypography: true, primary: _jsx(Typography, { variant: "body2", className: classes.listItemText, children: name }) }), Adornment ? (_jsx("div", { className: classes.adornmentContainer, children: _jsx(Adornment, { selected: isNestedNavigationMenuParentItemSelected(parent, history), open: !!isNestedNavigationMenuParentItemOpen(parent, history) }) })) : null] })), children.length > 0 && (_jsx(Collapse, { in: isNestedNavigationMenuParentItemOpen(parent, history), timeout: "auto", unmountOnExit: true, children: _jsx(ChildList, { parent: parent }) }))] }, id));
                        }) }), shouldShowDivider(section, index, filteredSections) ? (_jsx(Divider, {})) : null] }, index));
        }) }));
}
function ChildList({ parent, }) {
    const classes = useStyles();
    const groups = (parent.children || []).reduce((res, child) => {
        if (isNestedNavigationMenuChildItemGroup(child)) {
            return [...res, child];
        }
        const last = res.at(-1);
        if (last && Array.isArray(last)) {
            return [...res.slice(0, -1), [...last, child]];
        }
        else {
            return [...res, [child]];
        }
    }, []);
    return (_jsx(_Fragment, { children: groups.map((group, idx) => {
            if (Array.isArray(group)) {
                return (_jsx(List, { disablePadding: true, className: classes.nestedList, children: group.map((child) => (_jsx(ChildListItem, { child: child, parent: parent }, child.id))) }, idx));
            }
            return (_jsxs(React.Fragment, { children: [_jsx("div", { className: classes.groupHeaderContainer, children: _jsxs(ListItem, { id: `${group.id}-header`, className: clsx(classes.nestedListItem, classes.nestedListItemGroup), component: "div", children: [_jsx(ListItemText, { disableTypography: true, primary: _jsx(Typography, { variant: "body2", children: group.name }), className: classes.nestedListItemGroupText }), _jsx("div", { className: classes.childDivider })] }) }), _jsx(List, { disablePadding: true, className: classes.nestedList, "aria-labelledby": `${group.id}-header`, children: group.children.map((child) => (_jsx(ChildListItem, { child: child, parent: parent }, child.id))) })] }, idx));
        }) }));
}
function ChildListItem({ child, parent, }) {
    const classes = useStyles();
    const history = useHistory();
    const selected = isNestedNavigationMenuChildItemSelected(child, history, parent);
    return (_jsx("li", { children: _jsxs(ListItem, Object.assign({ id: child.id, selected: selected, component: "div" }, (!child.disabled
            ? Object.assign(Object.assign({}, getButtonProps(child.onClick)), getLinkinProps(child.href)) : {}), { className: clsx({
                [classes.nestedListItem]: true,
                [classes.nestedListItemClickable]: !child.disabled,
                [classes.nestedListItemDisabled]: child.disabled,
            }), classes: {
                selected: classes.nestedListItemSelected,
            }, "data-pendo": child.id ? `sidebar-menu-child-item-${child.id}` : undefined, children: [_jsx(ListItemText, { disableTypography: true, className: classes.nestedListItemText, primary: _jsx(Typography, { component: "span", variant: "body2", children: child.name }) }), child.adornment ? (_jsx("div", { className: classes.adornmentContainer, children: _jsx(child.adornment, { selected: selected }) })) : null] })) }));
}
export function isNestedNavigationMenuParentItemOpen(item, history) {
    if (typeof item.open === "function") {
        return item.open(item, history);
    }
    return !!item.open;
}
export function isNestedNavigationMenuParentItemSelected(item, history) {
    if (typeof item.selected === "function") {
        return item.selected(item, history);
    }
    return !!item.selected;
}
export function isNestedNavigationMenuChildItemSelected(item, history, parent) {
    if (typeof item.selected === "function") {
        return item.selected(item, history, parent);
    }
    return !!item.selected;
}
function shouldShowDivider(section, index, allSections) {
    var _a;
    if (section.showDivider) {
        return !!section.showDivider;
    }
    const isLast = index === allSections.length - 1;
    const isNameInNextSectionDefined = Boolean((_a = allSections[index + 1]) === null || _a === void 0 ? void 0 : _a.name);
    return !isLast && !isNameInNextSectionDefined;
}
export function isNestedNavigationMenuChildItemGroup(value) {
    return value === null || value === void 0 ? void 0 : value.hasOwnProperty("children");
}
