import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { alpha, Chip, makeStyles, Typography, useTheme, } from "@material-ui/core";
import { PercentageChangeIconArrow, PercentageChangeIconTriangle, } from "./PercentageChangeIcon";
import { MonospaceNumber } from "../monospace-number/MonospaceNumber";
import { getReportChangeDetails, ReportSignColors, } from "./logic/getReportChangeDetails";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    chip: {
        pointerEvents: "none",
    },
    chipWithBackground: {
        paddingLeft: theme.spacing(0.7),
        paddingRight: theme.spacing(1),
    },
    chipSmall: {
        height: 20,
    },
    label: {
        padding: theme.spacing(0, 0, 0, 0.3),
    },
    labelPadding: {
        padding: theme.spacing(0, 0, 0, 0),
    },
}));
export function PercentageChangeChip(props) {
    var _a, _b, _c;
    const { total, sign, change, disableBackground, variant, label, notShowZeroPercent, children, compact, maximumFractionDigits, } = props;
    const theme = useTheme();
    const { color, changePercentage } = getReportChangeDetails({
        sign,
        total,
        change,
    });
    const customColorMap = {
        [ReportSignColors.red]: theme.palette.red[400],
        [ReportSignColors.green]: theme.palette.green[500],
        [ReportSignColors.grey]: theme.palette.grey[500],
    };
    const customColor = customColorMap[color];
    const classes = useStyles();
    if (isNaN(changePercentage) ||
        (changePercentage === 0 && notShowZeroPercent)) {
        return label ? (_jsxs(_Fragment, { children: [_jsx("div", { className: (_a = props === null || props === void 0 ? void 0 : props.classes) === null || _a === void 0 ? void 0 : _a.avatar, style: { marginRight: 8 }, children: label }), getVariant(variant)] })) : (_jsx(_Fragment, {}));
    }
    const value = Math.abs(changePercentage);
    function getVariant(variant) {
        return variant === "triangle" ? (_jsx(PercentageChangeIconTriangle, { count: change })) : (_jsx(PercentageChangeIconArrow, { count: change }));
    }
    return (_jsx(Chip, { size: "small", avatar: label ? (_jsxs(_Fragment, { children: [_jsx(Typography, { className: (_b = props === null || props === void 0 ? void 0 : props.classes) === null || _b === void 0 ? void 0 : _b.avatar, style: { marginRight: 8 }, children: label }), getVariant(variant)] })) : undefined, classes: {
            root: disableBackground
                ? classes.chip
                : clsx(classes.chip, classes.chipWithBackground),
            sizeSmall: classes.chipSmall,
            label: clsx(Boolean(label) ? classes.labelPadding : classes.label, (_c = props === null || props === void 0 ? void 0 : props.classes) === null || _c === void 0 ? void 0 : _c.label),
        }, style: {
            color: customColor,
            background: alpha(customColor, disableBackground ? 0 : 0.1),
        }, label: _jsxs(MonospaceNumber, { children: [formatPercentage(value, props.lang, Boolean(compact), maximumFractionDigits), children] }), icon: label ? undefined : variant === "triangle" ? (_jsx(PercentageChangeIconTriangle, { count: change })) : (_jsx(PercentageChangeIconArrow, { count: change })) }));
}
function formatPercentage(value, lang, compact, maximumFractionDigits) {
    if (typeof maximumFractionDigits !== "undefined")
        return new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            maximumFractionDigits,
            notation: compact ? "compact" : undefined,
        }).format(value);
    if (value >= 1) {
        return new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            maximumFractionDigits: 0,
            notation: compact ? "compact" : undefined,
        }).format(value);
    }
    if (value >= 0.1) {
        return new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            maximumFractionDigits: 1,
            notation: compact ? "compact" : undefined,
        }).format(value);
    }
    if (value >= 0.0001) {
        return new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            maximumFractionDigits: 2,
            notation: compact ? "compact" : undefined,
        }).format(value);
    }
    if (value === 0) {
        return new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            maximumFractionDigits: 0,
            notation: compact ? "compact" : undefined,
        }).format(value);
    }
    return ("< " +
        new Intl.NumberFormat(lang, {
            style: "percent",
            compactDisplay: compact ? "short" : undefined,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: compact ? "compact" : undefined,
        }).format(0.0001));
}
