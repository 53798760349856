import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { FastField, Field, FieldProps } from "formik";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { SettingControlFactory } from "../useAdvancedControls";
import { updateIfPropsChanged } from "@lumar/shared";
import { Label } from "../../../../_common/components/Label";
import { FormValues } from "../data/types";

export const getRobotsOverwriteControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.robotsOverwrite.title"),
  path: "robotsOverwrite",
  // eslint-disable-next-line react/display-name
  control: () => (
    <FastField name="spider.robotsOverwrite" component={RobotsOverwrite} />
  ),
  testId: "crawl-settings-advanced-robots-overwrite",
  activeValues: ["robots", "enable"],
  openOnInitValues: ["enable"],
  textForSearch: createTextForSearch([
    t("settings.robotsOverwrite.title"),
    t("settings.robotsOverwrite.description"),
    t("settings.robotsOverwrite.instruction"),
    t("settings.robotsOverwrite.example"),
    t("settings.robotsOverwrite.checkbox"),
    t("settings.robotsOverwrite.renderingRobotsEnabledLabel"),
    t("settings.robotsOverwrite.renderingRobotsEnabledInfo"),
    t("settings.robotsOverwrite.ignoreRobotsForNavigationRequestsLabel"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  robotsOverwriteField: {
    zIndex: 0,
  },
  robotsOverwriteCheckbox: {
    zIndex: 1,
    marginBottom: theme.spacing(1),
  },
  label: {
    width: "fit-content",
  },
}));

export const RobotsOverwrite = React.memo(RobotsOverwriteInner);

function RobotsOverwriteInner({
  field,
  form,
}: FieldProps<FormValues["spider"]["robotsOverwrite"]>): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Field
        name="spider.robotsOverwrite.ignoreRobotsForNavigationRequests"
        component={CheckboxWithLabel}
        type="checkbox"
        disabled={form.isSubmitting}
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.robotsOverwrite.ignoreRobotsForNavigationRequestsLabel",
              )}
              info={t(
                "crawlSettings:settings.robotsOverwrite.ignoreRobotsForNavigationRequestsInfo",
              )}
            />
          ),
        }}
        data-testid="rendering-robots-enabled"
      />
      <Field
        name="spider.robotsOverwrite.renderingRobotsEnabled"
        component={CheckboxWithLabel}
        type="checkbox"
        disabled={form.isSubmitting}
        Label={{
          label: (
            <Label
              className={classes.label}
              label={t(
                "crawlSettings:settings.robotsOverwrite.renderingRobotsEnabledLabel",
              )}
              info={t(
                "crawlSettings:settings.robotsOverwrite.renderingRobotsEnabledInfo",
              )}
            />
          ),
        }}
        data-testid="rendering-robots-enabled"
      />

      <Field
        name="spider.robotsOverwrite.enable"
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{ label: t("settings.robotsOverwrite.checkbox") }}
        disabled={form.isSubmitting || !field.value.robots.length}
        data-testid="use-robots-overwrite"
        shouldUpdate={updateIfPropsChanged("disabled")}
        className={classes.robotsOverwriteCheckbox}
      />

      <Typography variant="caption">
        <Trans
          ns="crawlSettings"
          i18nKey="settings.robotsOverwrite.description"
          components={{
            bold: <span className={classes.bold} />,
          }}
        />
      </Typography>

      <Typography variant="caption" className={classes.indent}>
        {t("settings.robotsOverwrite.instruction")}
      </Typography>
      <code className={clsx(classes.example, classes.indent)}>
        {t("settings.robotsOverwrite.example")}
      </code>

      <AceEditor
        name="robots-overwrite"
        mode="text"
        value={field.value.robots}
        onChange={(newValue) => {
          form.setFieldValue("spider.robotsOverwrite.robots", newValue);
          form.setFieldValue(
            "spider.robotsOverwrite.enable",
            newValue.length > 0,
          );
        }}
        readOnly={form.isSubmitting}
        debounceChangePeriod={300}
        editorProps={{ $blockScrolling: Infinity }}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
        }}
        theme="tomorrow"
        wrapEnabled
        showGutter
        showPrintMargin={false}
        maxLines={12}
        minLines={12}
        width="100%"
        fontSize={14}
        className={clsx(classes.indent, classes.robotsOverwriteField)}
      />
    </>
  );
}
