import {
  ScriptV2,
  StatsV3,
  ClockV2,
  NotificationBellV2,
  useTranslation,
} from "@lumar/shared";
import { useParams } from "react-router-dom";
import { MarketingScreen } from "../../_common/components/marketing-screen/MarketingScreen";
import DataExplorerPreview from "./data-explorer-upgrade-preview.svg";
import { useAccountsRoutes } from "../../_common/routing/accountsRoutes";

export function UpgradeToDataExplorerMessage(): JSX.Element {
  const { t } = useTranslation("dataExplorer");

  const { accountId } = useParams<{ accountId: string }>();
  const accountsRoutes = useAccountsRoutes();

  return (
    <MarketingScreen
      title={t("upgrade.addDataExplorerToYourAccount")}
      bulletpoints={[
        [ScriptV2, t("upgrade.reason1")],
        [StatsV3, t("upgrade.reason2")],
        [ClockV2, t("upgrade.reason3")],
        [NotificationBellV2, t("upgrade.reason4")],
      ]}
      image={(props) => (
        <img
          src={DataExplorerPreview}
          alt={t("upgrade.imageAlt")}
          title={t("upgrade.imageAlt")}
          {...props}
        />
      )}
      onRequest={() =>
        (window.location.href = accountsRoutes.Subscription.getUrl({
          accountId,
        }))
      }
      data-testid="upgrade-data-explorer-message"
    />
  );
}
