import { Package, SmileyMeh, useTranslation, useSession } from "@lumar/shared";
import { isString } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";
import { GetCompareCrawlError } from "./data/types";
import { MetricTableOverlay } from "./MetricTableOverlay";
import { RoleCode } from "../../graphql";

export function useChangedMetricsOverlay({
  error,
  noCrawls,
  noChanges,
  noMetrics,
  crawlId,
}: {
  error?: string | GetCompareCrawlError;
  noCrawls?: boolean;
  noChanges?: boolean;
  noMetrics?: boolean;
  crawlId?: string;
}): Parameters<typeof MetricTableOverlay>[0] | undefined {
  const { t } = useTranslation("resourceDetail");
  const history = useHistory();
  const session = useSession();

  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  if (noCrawls)
    return {
      variant: "warning",
      title: t("noCompareCrawlTitle"),
      description: t("noCompareCrawlDescription"),
    };

  if (isString(error))
    return {
      variant: "error",
      title: t("genericError"),
      description: error,
    };

  if (error) {
    if (error.isArchived) {
      const showUnarchiveButton =
        crawlId && session.hasSufficientRole(RoleCode.Editor);
      return {
        variant: "warning",
        icon: Package,
        title: t("resourceUnvailableTitle"),
        description: t("archivedCrawlDescription"),
        action: showUnarchiveButton
          ? {
              title: t("archivedCrawlAction"),
              callback: () =>
                history.push(
                  Routes.Crawls.getUrl({
                    accountId,
                    projectId,
                    tab: "history",
                    unarchive: crawlId,
                  }),
                ),
            }
          : undefined,
      };
    }

    if (error.isUnarchivig)
      return {
        variant: "warning",
        icon: Package,
        title: t("resourceUnvailableTitle"),
        description: t("unarchivingCrawlDescription"),
      };

    if (error.isDatasourceChanged)
      return {
        variant: "warning",
        icon: SmileyMeh,
        title: t("errorCompareDatasourceChanged"),
        description: error.message,
      };

    if (error.noPriorCrawl)
      return {
        variant: "warning",
        icon: SmileyMeh,
        title: t("noChangedMetricsTitle"),
        description: error.message,
      };

    return {
      variant: "error",
      title: t("errorResourceNotFound"),
      description: error.message,
    };
  }

  if (noChanges)
    return {
      variant: "warning",
      title: t("noChangedMetricsTitle"),
      description: t("noChangedMetricsDescription"),
    };

  if (noMetrics)
    return {
      variant: "warning",
      title: t("noMetricFoundTitle"),
      description: t("noMetricFoundDescription"),
    };
}
