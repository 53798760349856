import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityTextAlternativesBPChart } from "../../../../../_common/charts/config/getAccessibilityTextAlternativesBPChart";
import { getAccessibilityTextAlternativesBPTrend } from "../../../../../_common/charts/config/getAccessibilityTextAlternativesBPTrend";

export const generateAccessibilityTextAlternativesBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityTextAlternativesBPChart,
      getAccessibilityTextAlternativesBPTrend,
    ],
  };
