import React from "react";
import { useDefaultPagination } from "./useDefaultPagination";
import { isEqual } from "lodash";
export function useBlueGridPaginationState({ paginationMode, state, defaultState, onStateChange, resetPageOnFilterChange, resetPageOnSortChange, tableRef, columns, }) {
    const { paginationState: stateDefault, onPaginationStateChange: onStateChangeDefault, } = useDefaultPagination();
    const pageInfo = {
        state: Object.assign(Object.assign({}, stateDefault), state),
        onStateChange: onStateChange || onStateChangeDefault,
    };
    const filterModel = pageInfo.state.filterModel !== undefined
        ? pageInfo.state.filterModel
        : defaultState === null || defaultState === void 0 ? void 0 : defaultState.filterModel;
    const handleFilterChange = (model) => {
        pageInfo.onStateChange(Object.assign(Object.assign(Object.assign({}, pageInfo.state), { filterModel: model }), (resetPageOnFilterChange ? { page: undefined } : {})));
    };
    const sortModel = pageInfo.state.sortModel !== undefined
        ? pageInfo.state.sortModel
        : defaultState === null || defaultState === void 0 ? void 0 : defaultState.sortModel;
    const filteredSortModel = sortModel &&
        sortModel.filter(({ field }) => columns.find((column) => column.field === field));
    const handleSortChange = (model) => {
        if (isEqual(model, filteredSortModel))
            return;
        pageInfo.onStateChange(Object.assign(Object.assign(Object.assign({}, pageInfo.state), { sortModel: model }), (resetPageOnSortChange ? { page: undefined } : {})));
    };
    // Note: Changing the page size, generates a page change event, which overwrites the page
    // index set by the page size change event handler. In order to avoid an unwanted page
    // change the page change event is ignored if the page size change event has been fired. - Csaba
    const pageSizeChanged = React.useRef(false);
    pageSizeChanged.current = false;
    const page = pageInfo.state.page !== undefined
        ? pageInfo.state.page
        : defaultState === null || defaultState === void 0 ? void 0 : defaultState.page;
    const handlePageChange = (page) => {
        var _a;
        if (pageSizeChanged.current)
            return;
        pageInfo.onStateChange(Object.assign(Object.assign({}, pageInfo.state), { page: page }));
        (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView();
    };
    const pageSize = pageInfo.state.pageSize !== undefined
        ? pageInfo.state.pageSize
        : defaultState === null || defaultState === void 0 ? void 0 : defaultState.pageSize;
    const handlePageSizeChange = (pageSize) => {
        pageSizeChanged.current = true;
        pageInfo.onStateChange(Object.assign(Object.assign({}, pageInfo.state), { pageSize: pageSize, page: undefined }));
    };
    return {
        filterMode: paginationMode,
        sortingMode: paginationMode,
        paginationMode: paginationMode,
        filterModel,
        sortModel: filteredSortModel,
        page,
        pageSize,
        onFilterModelChange: handleFilterChange,
        onSortModelChange: handleSortChange,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
    };
}
