import { useContext } from "react";
import { useTranslation } from "@lumar/shared";

import {
  GetLogzioProjectQueryForSourcesQuery,
  UpdateLogzioProjectQueryInput,
  useGetLogzioProjectQueryForSourcesLazyQuery,
  useUpdateLogzioQueryMutation,
} from "../../../../../graphql";
import { assert } from "../../../../../_common/assert";
import { useGenericParams } from "../../../../../_common/routing/useGenericParams";
import { translationNamespace } from "../../../CrawlSettings";
import { SourcesContext } from "../../data/useSourcesFormValues";
import { LogzioConnectionValues } from "./useLogzioQueries";

export interface FormValues {
  id: string;
  baseUrl: string;
  connectionId: string;
  dateRange: string;
  desktopUaRegexp: string;
  desktopUaNotRegexp: string;
  maxRows: string;
  mobileUaRegexp: string;
  mobileUaNotRegexp: string;
  pathFieldName: string;
  userAgentFieldName: string;
  queryFilter: string;
}

export interface LogzioQueriesResult {
  formValues: FormValues;
  loading: boolean;
  connections: LogzioConnectionValues[];
  getData: () => void;
  update: (values: FormValues) => Promise<string[]>;
}

export function useLogzioFormValues(
  id: string,
  userConnections: LogzioConnectionValues[],
): LogzioQueriesResult {
  const { projectId } = useGenericParams();
  assert(projectId);

  const { t } = useTranslation(translationNamespace);
  const context = useContext(SourcesContext);

  const [getData, { data, loading }] =
    useGetLogzioProjectQueryForSourcesLazyQuery({
      variables: {
        queryID: id,
      },
      fetchPolicy: "cache-first",
      onError: () => context.onError(t("message.errorLogzioQuery")),
    });

  const [updateQuery] = useUpdateLogzioQueryMutation();

  const query = isNonEmpty(data?.node) ? data?.node : undefined;

  const connections: LogzioConnectionValues[] =
    !query?.logzioConnection ||
    userConnections.find((x) => x.id === query.logzioConnection?.id)
      ? userConnections
      : [
          ...userConnections,
          {
            id: query.logzioConnection.id,
            label: query.logzioConnection.label || "",
            token: query.logzioConnection.token,
            user: query.logzioConnection.createdByUser.email || undefined,
          },
        ];

  const formValues = getFormValues(data);

  return {
    formValues,
    loading,
    connections,
    getData,
    update: async (values) => {
      try {
        const formatedQuery = values.queryFilter?.replace(/(\r\n|\n|\r)/gm, "");
        if (formatedQuery && formatedQuery.length > 0)
          JSON.parse(formatedQuery);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (ex: any) {
        return [ex.message];
      }

      try {
        await updateQuery({
          variables: {
            input: buildLogzioQueryMutationData(id, values, formValues),
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const hasQueryError =
          error?.graphQLErrors?.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (e: any) => e.extensions?.code === "INVALID_LOGZIO_QUERY_FILTER",
          ).length > 0;
        if (hasQueryError) return [error.message];
        context.onError(t("message.errorLogzioUpdate"));
      }

      return [];
    },
  };
}

function getFormValues(
  data: GetLogzioProjectQueryForSourcesQuery | undefined,
): FormValues {
  if (!data?.node || !isNonEmpty(data.node))
    return {
      id: "",
      baseUrl: "",
      connectionId: "",
      dateRange: "7",
      desktopUaRegexp: "",
      desktopUaNotRegexp: "",
      maxRows: "0",
      mobileUaRegexp: "",
      mobileUaNotRegexp: "",
      pathFieldName: "",
      userAgentFieldName: "",
      queryFilter: "",
    };

  return {
    id: data.node.id,
    baseUrl: data.node.baseUrl ?? "",
    connectionId: data.node.logzioConnection?.id ?? "",
    dateRange: data.node.dateRange.toString(),
    desktopUaRegexp: data.node.desktopUaRegexp,
    desktopUaNotRegexp: data.node.desktopUaNotRegexp || "",
    maxRows: data.node.maxRows.toString(),
    mobileUaRegexp: data.node.mobileUaRegexp,
    mobileUaNotRegexp: data.node.mobileUaNotRegexp || "",
    pathFieldName: data.node.pathFieldName,
    userAgentFieldName: data.node.userAgentFieldName,
    queryFilter: data.node.queryFilter
      ? JSON.stringify(data.node.queryFilter, null, "\t")
      : "",
  };
}

function buildLogzioQueryMutationData(
  id: string,
  values: FormValues,
  initialValues: FormValues,
): UpdateLogzioProjectQueryInput {
  const formatedQuery = values.queryFilter?.replace(/(\r\n|\n|\r)/gm, "");

  const connectionIdChanged =
    initialValues.connectionId !== values.connectionId;

  return {
    logzioProjectQueryId: id,
    baseUrl: values.baseUrl === "" ? null : values.baseUrl,
    ...(connectionIdChanged ? { logzioConnectionId: values.connectionId } : {}),
    desktopUaRegexp: values.desktopUaRegexp,
    desktopUaNotRegexp: values.desktopUaNotRegexp,
    dateRange: convertNumber(values.dateRange),
    maxRows: convertNumber(values.maxRows),
    mobileUaRegexp: values.mobileUaRegexp,
    mobileUaNotRegexp: values.mobileUaNotRegexp,
    pathFieldName: values.pathFieldName,
    queryFilter: formatedQuery ? JSON.parse(formatedQuery) : null,
    userAgentFieldName: values.userAgentFieldName,
  };
}

function convertNumber(value: string): number {
  const convertedValue: number = +value;
  return convertedValue;
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

type ExcludeEmpty<T> = T extends AtLeastOne<T> ? T : never;

function isNonEmpty(
  value: GetLogzioProjectQueryForSourcesQuery["node"],
): value is ExcludeEmpty<GetLogzioProjectQueryForSourcesQuery["node"]> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Boolean((value as any)?.id);
}
