import { useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

interface TimePresenterProps extends MetricsValuePresenterProps {
  color?: string;
}

export function TimePresenter({
  value,
  color,
}: TimePresenterProps): JSX.Element {
  const { t } = useTranslation("resourceDetail");
  if (!value) return <span style={{ color }}>0</span>;

  function formatTime(value: number): string {
    const days = value / 86400;
    const hours = value / 3600;
    const minutes = value / 60;
    if (days >= 1)
      return t("timeFormatter.day", {
        count: days,
      });
    if (hours >= 1)
      return t("timeFormatter.hour", {
        count: hours,
      });
    if (minutes >= 1)
      return t("timeFormatter.minute", {
        count: minutes,
      });
    return t("timeFormatter.second", {
      count: value,
    });
  }
  return <span style={{ color }}>{formatTime(value)}</span>;
}
