import { TotalSign, TotalWeight } from "./CustomReportTypes";

export const TOTAL_SIGN_OPTIONS: TotalSign[] = [
  "negative",
  "neutral",
  "positive",
];
export const TOTAL_WEIGHT_OPTIONS: TotalWeight[] = [
  "none",
  "low",
  "medium",
  "high",
  "critical",
];
