import { ReportCategoryChartConfig } from "../types";
import { getCumulativeLayoutShiftTrend } from "../../../../_common/charts/config/getCumulativeLayoutShiftTrend";
import { getDomContentLoadTrend } from "../../../../_common/charts/config/getDomContentLoadTrend";
import { getFirstContentfulPaintTrend } from "../../../../_common/charts/config/getFirstContentfulPaintTrend";
import { getLargestContentfulPaintTrend } from "../../../../_common/charts/config/getLargestContentfulPaintTrend";
// import { getPerformanceBreakdownTrend } from "../../../../_common/charts/config/getPerformanceBreakdownTrend";
// import { getPerformanceAverage } from "../../../../_common/charts/config/getPerformanceAverage";
// import { getPerformanceFast } from "../../../../_common/charts/config/getPerformanceFast";
import { getPerformanceSlow } from "../../../../_common/charts/config/getPerformanceSlow";
// import { getPerformanceSlowTrend } from "../../../../_common/charts/config/getPerformanceSlowTrend";
import { getServerResponseTimeTrend } from "../../../../_common/charts/config/getServerResponseTimeTrend";
import { getTimeToInteractiveTrend } from "../../../../_common/charts/config/getTimeToInteractiveTrend";
import { getUrlFetchTimeTrend } from "../../../../_common/charts/config/getUrlFetchTimeTrend";

export const generateExperiencePageSpeedCategoryChartConfigItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getPerformanceSlow,
      // getPerformanceSlowTrend,
      // getPerformanceAverage,
      // getPerformanceFast,
      getServerResponseTimeTrend,
      getUrlFetchTimeTrend,
      getDomContentLoadTrend,
      getFirstContentfulPaintTrend,
      getLargestContentfulPaintTrend,
      getTimeToInteractiveTrend,
      getCumulativeLayoutShiftTrend,
    ],
  };
