import React, { useState } from "react";
import { Box, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { translationNamespace } from "../../CrawlSettings";
import { Majestic } from "./Majestic";
import { BacklinkManualUploads } from "./BacklinkManualUploads";
import { ReactComponent as MajesticLogo } from "../../../../_common/assets/vendor-logos/majestic.svg";
import {
  ManualUploadsContext,
  useManualUploadContext,
} from "../manual-uploads/ManualUploadsWrapper";
import { CrawlType } from "../../../../graphql";
import { CustomAccordion, CustomAccordionBadge } from "../CustomAccordion";
import { FormValues } from "../data/types";
import { LinkSolid, TabSpinner } from "@lumar/shared";
import { insertIf } from "../../../../_common/insertIf";
import { DataOnlySourceSwitch } from "../DataOnlySourceSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  root: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    flexDirection: "column",
  },
  iconLabelWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  labelIcon: {
    minHeight: "unset",
  },
  icon: {
    width: "40px",
    height: "30px",
    marginRight: "8px",
  },
  labelContainer: {
    padding: 0,
  },
}));

export const Backlinks = React.memo(function Backlinks({
  initialState,
}: {
  initialState: boolean;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const [selectedBacklink, setSelectedBacklink] = useState(0);
  const { files } = useManualUploadContext(CrawlType.Backlinks);
  const hasManualUploads = Boolean(files.length);

  const getBadges = (
    values: FormValues,
    hasFile: boolean,
  ): CustomAccordionBadge[] => {
    const shouldShowWarning =
      values.crawlType.backlinks &&
      !hasFile &&
      !values.backlinks.majestic?.enabled;
    const isDataOnly = values.dataOnlyCrawlType.backlinks;
    return [
      ...insertIf(isDataOnly, {
        label: t("sources.dataOnly"),
        info: "",
        color: "primary" as const,
      }),
      ...insertIf(shouldShowWarning, {
        label: t("sources.backlinks.warningLabel"),
        info: t("sources.backlinks.warningInfo"),
        color: "yellow" as const,
      }),
    ];
  };

  return (
    <CustomAccordion
      title={t("sources.backlinks.title")}
      icon={<LinkSolid fontSize="small" style={{ display: "block" }} />}
      tooltip={t("sources.backlinks.tooltip")}
      initialState={initialState}
      fieldName="crawlType.backlinks"
      onChange={React.useCallback(
        (value, { setFieldValue }) => {
          if (value && !hasManualUploads)
            setFieldValue("backlinks.majestic.enabled", true);
        },
        [hasManualUploads],
      )}
      getBadges={getBadges}
      bottomContent={
        <DataOnlySourceSwitch name="dataOnlyCrawlType.backlinks" />
      }
      data-pendo="backlinks-header"
      data-testid="backlinks-section"
    >
      <Box style={{ width: "100%" }}>
        <Tabs
          value={selectedBacklink}
          onChange={(_, value) => setSelectedBacklink(value)}
        >
          <Tab
            label={t("sources.backlinks.majestic.title")}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
            icon={<MajesticLogo className={classes.icon} />}
          />
          <Tab
            label={
              <ManualUploadsContext.Consumer>
                {({ getFileCount, loading }) => (
                  <>
                    {t("sources.backlinks.manualUpload.title")}&nbsp;
                    {loading ? (
                      <TabSpinner />
                    ) : (
                      <span style={{ fontSize: "12px" }}>
                        ({getFileCount(CrawlType.Backlinks)})
                      </span>
                    )}
                  </>
                )}
              </ManualUploadsContext.Consumer>
            }
            classes={{
              wrapper: classes.iconLabelWrapper,
            }}
          />
        </Tabs>
        {selectedBacklink === 0 && <Majestic />}
        {selectedBacklink === 1 && <BacklinkManualUploads />}
      </Box>
    </CustomAccordion>
  );
});
