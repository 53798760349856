/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual, merge } from "lodash";
import { isAggregateWithUnit } from "./useReportsAggregateMetricData";
import { EnumType, VariableType } from "json-to-graphql-query";
import { DatasourceCode, ReportTypeCode } from "../../../../graphql";
import { CustomDataAggregateMetricConfig } from "./types";
import { getReportStatGUID } from "../../../../_common/report-helpers/reportStat";

export function getOpportunitiesReportsFragment(
  configs: CustomDataAggregateMetricConfig[],
):
  | { reports: Record<string, unknown>; datasource: Record<string, unknown> }
  | undefined {
  const aggregates = configs.reduce<Record<string, unknown>>(
    (result, config) =>
      merge(
        result,
        config.siteSpeedAuditOpportunities
          ? {
              crawlSiteSpeedAuditOpportunitiesAggregates: {
                nodes: {
                  [config.siteSpeedAuditOpportunities.aggregate]: {
                    [config.siteSpeedAuditOpportunities.metricCode]: true,
                  },
                },
              },
            }
          : {},
      ),
    {},
  );

  if (isEqual(aggregates, {})) return;

  return {
    reports: {
      opportunitiesReports: {
        __aliasFor: "reports",
        __args: {
          filter: {
            reportTypeCodeEnum: { eq: new EnumType(ReportTypeCode.Basic) },
            totalRows: { gt: 0 },
            segmentId: { eq: new VariableType("segmentId") },
            datasourceCodeEnum: {
              eq: new EnumType(DatasourceCode.CrawlSiteSpeedAuditOpportunities),
            },
          },
          reportTemplateFilter: {
            totalSign: { lt: 0 },
          },
          first: 1000,
        },
        totalCount: true,
        edges: {
          node: {
            id: true,
            crawlId: true,
            segmentId: true,
            reportTemplateCode: true,
            ...aggregates,
          },
        },
      },
    },
    datasource: {
      opportunitiesDatasource: {
        __aliasFor: "datasource",
        __args: {
          datasourceCode: new EnumType(
            DatasourceCode.CrawlSiteSpeedAuditOpportunities,
          ),
        },
        datasourceCode: true,
        metrics: {
          code: true,
          metadata: {
            unit: true,
          },
        },
      },
    },
  };
}

export function getOpportunityValue(
  data: any,
  reportId: string,
  config: CustomDataAggregateMetricConfig,
): number | null | undefined {
  if (!config.siteSpeedAuditOpportunities) return;

  const report = data?.getCrawl?.opportunitiesReports?.edges?.find(
    // FIXME: HACK: This allowed to match ReportStat and Report entities.
    // This feature should use ReportStat exclusively. For now, this works
    (x: any) => getReportStatGUID(x.node) === reportId,
  )?.node;
  if (!report) return;

  return report?.crawlSiteSpeedAuditOpportunitiesAggregates?.nodes?.[0]?.[
    config.siteSpeedAuditOpportunities.aggregate
  ]?.[config.siteSpeedAuditOpportunities.metricCode];
}

export function getOpportunityUnit(
  data: any,
  config: CustomDataAggregateMetricConfig,
): string | undefined {
  if (
    !config.siteSpeedAuditOpportunities ||
    !isAggregateWithUnit(config.siteSpeedAuditOpportunities.aggregate)
  ) {
    return;
  }

  return data?.opportunitiesDatasource?.metrics?.find(
    (x: any) => x.code === config.siteSpeedAuditOpportunities?.metricCode,
  )?.metadata?.unit;
}
