import { AdvancedSettingsFragment } from "../../../../graphql";
import { FormValues } from "./types";
import * as formatters from "./formatters/formattes";

export function getFormValues(
  data: AdvancedSettingsFragment | undefined | null,
  accessibilityContainerId: string | undefined,
  htmlContainerId: string | undefined,
  screenshotContainerId: string | undefined,
): FormValues {
  return {
    compareToCrawl: data?.compareToCrawl ?? "None",
    ...getAdvancedSettings(
      data,
      accessibilityContainerId,
      htmlContainerId,
      screenshotContainerId,
    ),
  };
}

function getAdvancedSettings(
  data: AdvancedSettingsFragment | undefined | null,
  accessibilityContainerId: string | undefined,
  htmlContainerId: string | undefined,
  screenshotContainerId: string | undefined,
): Omit<FormValues, "compareToCrawl"> {
  return {
    scope: {
      domainScope: formatters.formatDomainScopeSettingsFrom(data),
      urlScope: formatters.formatUrlScopeSettingsFrom(data),
      resourceRestrictions:
        formatters.formatResourceRestrictionsSettingsForm(data),
      linkRestrictions: formatters.formatLinkRestrictionsSettingsFrom(data),
      redirectSettings: formatters.formatRedirectSettingsForm(data),
      linkValidation: formatters.formatLinkValidationSettingsForm(data),
    },
    spider: {
      startUrls: formatters.formatStartUrlsSettingsFrom(data),
      scriptRendering: formatters.formatScriptRenderingSettingsFrom(
        data,
        accessibilityContainerId,
      ),
      crawlerIp: formatters.formatCrawlerIpSettingsFrom(data),
      mobileSite: formatters.formatMobileSiteSettingsFrom(data),
      robotsOverwrite: formatters.formatRobotsOverwriteSettingsFrom(data),
      stealthMode: formatters.formatStealthModeSettingsFrom(data),
      customRequestHeaders:
        formatters.formatCustomRequestHeadersSettingsFrom(data),
    },
    userAgent: formatters.formatUserAgentSettingsFrom(data, data?.moduleCode),
    cookies: formatters.formatCookiesSettingsFrom(data),
    extraction: {
      customExtraction: formatters.formatCustomExtractionSettingsFrom(data),
    },
    test: {
      testSiteDomain: formatters.formatTestSiteDomainSettingsFrom(data),
      customDNS: formatters.formatCustomDnsSettingsFrom(data),
      authentication: formatters.formatAuthenticationSettingsFrom(data),
      removeUrlParams: formatters.formatRemoveUrlParamsSettingsFrom(data),
      urlRewriting: formatters.formatUrlRewritingSettingsFrom(data),
    },
    report: {
      saveHTMLAndScreenshots:
        formatters.formatSaveHTMLAndScreenshotsSettingsFrom(
          data,
          htmlContainerId,
          screenshotContainerId,
        ),
      apiCallback: formatters.formatApiCallbackSettingsFrom(data),
      emailAlerts: formatters.formatEmailAlertsSettingsFrom(data),
      report: formatters.formatReportSettingsFrom(data),
    },
  };
}
