import { Project, UpdateProjectInput } from "../../../../../graphql";
import { TestSiteDomainSettings } from "../types";
import { convertString } from "./helpers";

type QueryData = Pick<Project, "testSiteDomain" | "crawlTestSite">;

export function formatTestSiteDomainSettingsFrom(
  data?: QueryData | null,
): TestSiteDomainSettings {
  return {
    domain: data?.testSiteDomain ?? "",
    enable: data?.crawlTestSite ?? false,
  };
}

export function formatTestSiteDomainSettingsTo(
  formValues: TestSiteDomainSettings,
): Pick<UpdateProjectInput, "testSiteDomain" | "crawlTestSite"> {
  return {
    testSiteDomain: convertString(formValues.domain),
    crawlTestSite: formValues.enable,
  };
}
