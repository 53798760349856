import { addMinutes, endOfDay, startOfDay } from "date-fns";
import { ConnectionPredicate } from "../../graphql";
import { ConnectionFilter, FilterType, ReportRowsApiFilter } from "./types";

export function formatFilters(
  filter: ConnectionFilter,
  timeZoneOffset: number,
  dateMetric?: string[],
): ReportRowsApiFilter {
  return {
    _or: filter._or?.map((x) => ({
      _and: x._and.map((filter) =>
        formatFilter(filter, timeZoneOffset, dateMetric),
      ),
    })),
  };
}

const CUSTOM_METRICS_PREFIX = "customMetrics.";

export function formatFilter(
  filter: FilterType,
  timeZoneOffset: number,
  dateMetric?: string[],
): ReportRowsApiFilter {
  function getAsDate(
    value: unknown,
    metric: string,
    dateMetric?: string[],
  ): Date | undefined {
    if (!dateMetric?.includes(metric)) return;
    if (typeof value !== "string") return;

    const dateValue = new Date(value);
    return !isNaN(dateValue.getTime()) ? dateValue : undefined;
  }

  const [metric, filterPredicate] = Object.entries(filter)[0];
  const [predicate, value] = Object.entries(filterPredicate)[0];

  const dateValue = getAsDate(value, metric, dateMetric);
  if (dateValue) {
    return getDateFilter(metric, predicate, dateValue, timeZoneOffset) ?? {};
  }

  if (metric.startsWith(CUSTOM_METRICS_PREFIX)) {
    return {
      customMetrics: {
        [metric.slice(CUSTOM_METRICS_PREFIX.length)]: {
          [predicate]: value,
        },
      },
    };
  }

  return filter;
}

function getDateFilter(
  metric: string,
  predicate: string,
  time: Date,
  timeZoneOffset: number,
): ReportRowsApiFilter | undefined {
  const { start, end } = getDateInterval(time, timeZoneOffset);

  if (predicate === ConnectionPredicate.Eq) {
    return {
      _and: [
        {
          [metric]: {
            [ConnectionPredicate.Ge]: start,
          },
        },
        {
          [metric]: {
            [ConnectionPredicate.Le]: end,
          },
        },
      ],
    };
  }

  if (predicate === ConnectionPredicate.Ne) {
    return {
      _or: [
        {
          [metric]: {
            [ConnectionPredicate.Ge]: end,
          },
        },
        {
          [metric]: {
            [ConnectionPredicate.Le]: start,
          },
        },
      ],
    };
  }

  if (
    predicate === ConnectionPredicate.Le ||
    predicate === ConnectionPredicate.Gt
  ) {
    return {
      [metric]: {
        [predicate]: end,
      },
    };
  }

  if (
    predicate === ConnectionPredicate.Ge ||
    predicate === ConnectionPredicate.Lt
  ) {
    return {
      [metric]: {
        [predicate]: start,
      },
    };
  }
}

function getDateInterval(
  date: Date,
  timeZoneOffset: number,
): { start: string; end: string } {
  const dayStart = addMinutes(
    startOfDay(addMinutes(date, date.getTimezoneOffset())),
    -date.getTimezoneOffset() - timeZoneOffset,
  ).toISOString();
  const dayEnd = addMinutes(
    endOfDay(addMinutes(date, date.getTimezoneOffset())),
    -date.getTimezoneOffset() - timeZoneOffset,
  ).toISOString();

  return {
    start: dayStart,
    end: dayEnd,
  };
}
