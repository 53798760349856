import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { PopperProps } from "@material-ui/core/Popper";

interface DeepcrawlTooltipProps {
  content: string | JSX.Element;
  children: React.ReactElement;
  placement?: TooltipProps["placement"];
  width?: number | string;
  maxWidth?: number;
  PopperProps?: Partial<PopperProps>;
}

interface StyleProps {
  width?: number | string;
  maxWidth?: number;
}

const useTooltipStyles = makeStyles<Theme, StyleProps>((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    fontSize: "1em",
    color: theme.palette.text.secondary,
    boxShadow: "1px 2px 7px 3px rgba(215,215,215,0.5)",
    padding: theme.spacing(2),
    width: ({ width }) => width || 550,
    maxWidth: ({ maxWidth }) => maxWidth || "1000px",
    userSelect: "all",
  },
  arrow: {
    color: theme.palette.background.paper,
  },
}));

export function DeepcrawlTooltip(props: DeepcrawlTooltipProps): JSX.Element {
  const tooltipClasses = useTooltipStyles(props);

  return (
    <Tooltip
      classes={tooltipClasses}
      arrow
      interactive
      title={props.content}
      PopperProps={props.PopperProps}
      data-testid="tooltip"
      placement={props.placement || "top"}
    >
      {props.children}
    </Tooltip>
  );
}
