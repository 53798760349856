export enum ReportSignColors {
  grey = "grey",
  red = "red",
  green = "green",
}

const colorMapping = new Map<number, ReportSignColors>([
  [0, ReportSignColors.grey],
  [1, ReportSignColors.green],
  [-1, ReportSignColors.red],
]);

export function getReportSignColor(sign: number): ReportSignColors {
  return colorMapping.get(sign) ?? ReportSignColors.grey;
}
