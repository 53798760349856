import { ReportCategoryChartConfig } from "../types";
import { getExperienceBrokenChart } from "../../../../_common/charts/config/getExperienceBrokenChart";
import { getExperienceBrokenTrend } from "../../../../_common/charts/config/getExperienceBrokenTrend";
import { getResourceIssuesChart } from "../../../../_common/charts/config/getResourceIssuesChart";
import { getResourceIssuesTrend } from "../../../../_common/charts/config/getResourceIssuesTrend";

export const generateExperienceUserExperienceChartItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getExperienceBrokenChart,
      getExperienceBrokenTrend,
      getResourceIssuesChart,
      getResourceIssuesTrend,
    ],
  };
