import React from "react";
import {
  useTranslation,
  Grid,
  FileTextData,
  StatsV9,
  Stats,
  ImportantMessageV2,
  FlagBugReportV2,
  WindowPlusV2,
  FileDone,
  ApolloError,
  LighthouseIcon,
  useSession,
  FileImage,
  CodeSolid,
} from "@lumar/shared";
import { useSearchParam } from "../../../_common/routing/useSearchParam";
import { Metrics, ResourceDetailData } from "../../data/types";
import { SvgIcon } from "@material-ui/core";
import { SiteSpeedAllMetrics } from "./sections/SiteSpeedAllMetrics";
import { SiteSpeedSummary } from "./sections/SiteSpeedSummary";
import { insertIf } from "../../../_common/insertIf";
import { SiteSpeedAudits } from "./sections/SiteSpeedAudits";
import { SiteSpeedAudit } from "./sections/SiteSpeedAudit";
import { SiteSpeedChangedMetrics } from "./sections/SiteSpeedChangedMetrics";
import { AuditData, useSiteSpeedAudits } from "./data/useSiteSpeedAudits";
import { SiteSpeedReport } from "./sections/SiteSpeedReport";
import { SiteSpeedScreenshot } from "./sections/SiteSpeedScreenshot";
import { ResponseHeader } from "../../metrics-groups/ResponseHeader";
import { useAttachmentSections } from "../../attachments/useAttachmentSections";

type Section = {
  code: string;
  name: string;
} & (
  | { expandable: true; component?: undefined }
  | {
      expandable?: undefined;
      component: React.ElementType<{ data: ResourceDetailData }>;
    }
);

export type SiteSpeedDetailsSections = Section & {
  icon: typeof SvgIcon;
  count?: number;
  items?: Section[];
};

type Result = {
  sections: SiteSpeedDetailsSections[];
  active?: Section;
  activeParent?: Section;
  loading: boolean;
  error?: string;
};

export const LIGHTHOUSE_ATTACHMENT = "/lighthouse.json";

export function useSiteSpeedDetailsSections(metrics: Metrics): Result {
  const { t } = useTranslation("resourceDetail");
  const type = useSearchParam("type");
  const auditFilter = useSearchParam("auditFilter") || "all";
  const { hasFeatureFlagEnabled } = useSession();

  const attachmentSections = useAttachmentSections(metrics);

  const hasLighthouseAttachment = Boolean(
    metrics["attachments"]?.value?.find((x: { name?: string }) =>
      x.name?.endsWith(LIGHTHOUSE_ATTACHMENT),
    ),
  );

  const rawHeader = metrics["rawHeader"]?.value;
  const hasRawHeader =
    typeof rawHeader === "object" && Object.keys(rawHeader).length > 0;

  const mainSections = React.useMemo<SiteSpeedDetailsSections[]>(() => {
    return [
      {
        code: "site-speed-summary",
        name: t("siteSpeed.summary"),
        icon: Grid,
        component: SiteSpeedSummary,
      },
      ...attachmentSections,
      ...insertIf(hasLighthouseAttachment, {
        code: "site-speed-screenshot",
        name: t("siteSpeed.screenshot"),
        icon: FileImage,
        component: SiteSpeedScreenshot,
      }),
      ...insertIf(
        hasLighthouseAttachment && hasFeatureFlagEnabled("lighthouse-viewer"),
        {
          code: "site-speed-report",
          name: t("siteSpeed.report"),
          icon: LighthouseIcon,
          component: SiteSpeedReport,
        },
      ),
      {
        code: "site-speed-all-metrics",
        name: t("siteSpeed.allMetrics"),
        icon: StatsV9,
        component: SiteSpeedAllMetrics,
      },
      {
        code: "site-speed-changed-metrics",
        name: t("siteSpeed.changedMetrics"),
        icon: Stats,
        component: SiteSpeedChangedMetrics,
      },
      ...insertIf(hasRawHeader, {
        code: "responseHeader",
        name: t("responseHeader"),
        icon: CodeSolid,
        component: ResponseHeader,
      }),
    ];
  }, [
    t,
    attachmentSections,
    hasLighthouseAttachment,
    hasFeatureFlagEnabled,
    hasRawHeader,
  ]);

  const { audits, loading, error } = useSiteSpeedAudits();

  const auditSections = React.useMemo<SiteSpeedDetailsSections[]>(() => {
    if (!audits.length || loading || error) return [];

    const filteredAuditCodes = getFilteredAuditCodes({ auditFilter, metrics });

    const failedAudits: string[] = (
      metrics["failedAudits"]?.value || []
    ).filter(
      (x: string) => !filteredAuditCodes || filteredAuditCodes.includes(x),
    );
    const warningAudits: string[] = (
      metrics["warningAudits"]?.value || []
    ).filter(
      (x: string) => !filteredAuditCodes || filteredAuditCodes.includes(x),
    );
    const infoAudits: string[] = (metrics["infoAudits"]?.value || []).filter(
      (x: string) => !filteredAuditCodes || filteredAuditCodes.includes(x),
    );
    const passedAudits: string[] = (
      metrics["passedAudits"]?.value || []
    ).filter(
      (x: string) => !filteredAuditCodes || filteredAuditCodes.includes(x),
    );

    const auditCount =
      failedAudits.length +
      warningAudits.length +
      infoAudits.length +
      passedAudits.length;

    function getAuditSection(auditId: string): Section {
      const audit = audits.find((x) => x.id === auditId) || {
        id: auditId,
        name: auditId,
        metrics: {},
      };

      return {
        code: auditId,
        name: audit?.name || auditId,
        component: getSiteSpeedAuditComponent(audit),
      };
    }

    return [
      {
        code: "site-speed-all-audits",
        name: t("siteSpeed.allAudits"),
        icon: FileTextData,
        count: auditCount,
        component: function SiteSpeedAuditsWrapper(props: {
          data: ResourceDetailData;
        }): JSX.Element {
          return <SiteSpeedAudits {...props} auditCodes={filteredAuditCodes} />;
        },
      },
      ...insertIf(failedAudits.length, {
        code: "site-speed-audits-failed",
        name: t("siteSpeed.failedAudits"),
        icon: ImportantMessageV2,
        count: failedAudits.length,
        expandable: true,
        // eslint-disable-next-line fp/no-mutating-methods
        items: failedAudits
          .map(getAuditSection)
          .sort((a, b) => a.name.localeCompare(b.name)),
      }),
      ...insertIf(warningAudits.length, {
        code: "site-speed-audits-warning",
        name: t("siteSpeed.warningAudits"),
        icon: FlagBugReportV2,
        count: warningAudits.length,
        expandable: true,
        // eslint-disable-next-line fp/no-mutating-methods
        items: warningAudits
          .map(getAuditSection)
          .sort((a, b) => a.name.localeCompare(b.name)),
      }),
      ...insertIf(infoAudits.length, {
        code: "site-speed-audits-needs-review",
        name: t("siteSpeed.infoAudits"),
        icon: WindowPlusV2,
        count: infoAudits.length,
        expandable: true,
        // eslint-disable-next-line fp/no-mutating-methods
        items: infoAudits
          .map(getAuditSection)
          .sort((a, b) => a.name.localeCompare(b.name)),
      }),
      ...insertIf(passedAudits.length, {
        code: "site-speed-audits-passed",
        name: t("siteSpeed.passedAudits"),
        icon: FileDone,
        count: passedAudits.length,
        expandable: true,
        // eslint-disable-next-line fp/no-mutating-methods
        items: passedAudits
          .map(getAuditSection)
          .sort((a, b) => a.name.localeCompare(b.name)),
      }),
    ] as SiteSpeedDetailsSections[];
  }, [t, auditFilter, audits, loading, error, metrics]);

  const sections = [...mainSections, ...auditSections];

  return {
    sections,
    ...findActive(sections, type),
    loading,
    error: getErrorMessage(error),
  };
}

function findActive(
  sections: SiteSpeedDetailsSections[],
  type?: string,
): { active?: Section; activeParent?: Section } {
  const children: { section: Section; parent?: Section }[] = sections.flatMap(
    (section) => [
      { section },
      ...(section.items?.map((item) => ({ section: item, parent: section })) ||
        []),
    ],
  );

  const typeFinal = type || children[0].section.code;
  const active = children.find((x) => x.section.code === typeFinal);

  return { active: active?.section, activeParent: active?.parent };
}

function getErrorMessage(error?: ApolloError): string | undefined {
  return (
    error?.graphQLErrors[0]?.message ||
    error?.clientErrors[0]?.message ||
    error?.protocolErrors[0]?.message ||
    error?.networkError?.message
  );
}

function getSiteSpeedAuditComponent(audit: AuditData): React.ElementType<{
  data: ResourceDetailData;
}> {
  function SiteSpeedAuditWrapper(props: {
    data: ResourceDetailData;
  }): JSX.Element {
    return <SiteSpeedAudit {...props} audit={audit} />;
  }

  return SiteSpeedAuditWrapper;
}

function getFilteredAuditCodes({
  auditFilter,
  metrics,
}: {
  auditFilter: string;
  metrics: Metrics;
}): string[] | undefined {
  const metric = (() => {
    switch (auditFilter) {
      case "fcp":
        return metrics["sitespeedFcpAuditResults"]?.value;
      case "lcp":
        return metrics["sitespeedLcpAuditResults"]?.value;
      case "tbt":
        return metrics["sitespeedTbtAuditResults"]?.value;
      case "cls":
        return metrics["sitespeedClsAuditResults"]?.value;
    }
  })();

  if (!metric) return;

  return metric.flatMap((x: { audits: string }) => {
    try {
      const audits = JSON.parse(x.audits);
      return Array.isArray(audits) ? audits : [];
    } catch {
      return [];
    }
  });
}
