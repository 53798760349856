import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityFormsBPChart } from "../../../../../_common/charts/config/getAccessibilityFormsBPChart";
import { getAccessibilityFormsBPTrend } from "../../../../../_common/charts/config/getAccessibilityFormsBPTrend";

export const generateAccessibilityFormsBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityFormsBPChart,
      getAccessibilityFormsBPTrend,
    ],
  };
