import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityIssuesandInstancesBPTrend } from "../../../../../_common/charts/config/getAccessibilityIssuesandInstancesBPTrend";

export const generateAccessibilityLevelBPCategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityIssuesandInstancesBPTrend,
    ],
  };
