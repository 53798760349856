/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, updateIfPropsChanged } from "@lumar/shared";
import {
  Divider,
  FormControlLabel,
  makeStyles,
  Radio,
} from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { RadioGroup } from "formik-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { UpgradeMessage } from "../../components/UpgradeMessage";
import { SettingsContext } from "../data/useContextValues";
import { SettingControlFactory } from "../useAdvancedControls";

export const getCrawlerIpSettingsControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.crawlerIpSettings.title"),
  path: "crawlerIp",
  // eslint-disable-next-line react/display-name
  control: () => <CrawlerIpSettings />,
  testId: "crawl-settings-advanced-crawler-ip",
  disabledTooltip: t("settings.stealtModeTooltip"),
  textForSearch: createTextForSearch([
    t("settings.crawlerIpSettings.title"),
    t("settings.crawlerIpSettings.description"),
    t("settings.crawlerIpSettings.warning"),
    t("settings.crawlerIpSettings.regional"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
    borderRadius: theme.shape.borderRadius,
    width: "fit-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  warningIcon: {
    transform: "translateY(-5%)",
    verticalAlign: "middle",
    color: "white",
    fontSize: theme.typography.fontSize,
    marginRight: theme.spacing(1),
  },
  errorIcon: {
    transform: "translateY(-5%)",
    verticalAlign: "middle",
    color: theme.palette.error.main,
    fontSize: theme.typography.fontSize,
    marginRight: theme.spacing(1),
  },
}));

export const CrawlerIpSettings = React.memo(CrawlerIpSettingsInner);

function CrawlerIpSettingsInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  const isPlanLight =
    contextValues.isPlanLight || contextValues.isPlanLightPlus;

  // eslint-disable-next-line fp/no-mutating-methods
  const locations = contextValues.locations
    .filter(
      (location) =>
        location.type !== "Country" &&
        location.type !== "Global" &&
        location.type !== "StaticIP",
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  // eslint-disable-next-line fp/no-mutating-methods
  const regionalLocations = contextValues.locations
    .filter((location) => location.type === "Country")
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Typography
        variant="caption"
        style={{ marginBottom: 12, display: "inline-block" }}
      >
        {t("settings.crawlerIpSettings.description", {
          crawlerIpAddresses: contextValues.crawlerIpAddresses.join(", "),
        })}
      </Typography>

      <FastField
        name="spider.crawlerIp.location"
        shouldUpdate={updateIfPropsChanged("disabled")}
      >
        {(props: FastFieldProps<any>) => (
          <>
            <RadioGroup {...props}>
              {locations.map((location, idx) => (
                <FormControlLabel
                  key={idx}
                  value={location.code}
                  control={<Radio />}
                  label={location.name}
                  disabled={props.form.isSubmitting || !location.enabled}
                  data-testid="ip-location"
                />
              ))}
            </RadioGroup>
            <Divider className={classes.indent} />
            {isPlanLight && (
              <UpgradeMessage
                upgradeType="Corporate"
                className={classes.indent}
              />
            )}
            <Typography variant="subtitle2Medium" className={classes.indent}>
              {t("settings.crawlerIpSettings.regional")}
            </Typography>
            <RadioGroup {...props}>
              {regionalLocations.map((location, idx) => (
                <FormControlLabel
                  key={idx}
                  value={location.code}
                  control={<Radio />}
                  label={location.name}
                  disabled={
                    props.form.isSubmitting || isPlanLight || !location.enabled
                  }
                  data-testid="ip-country"
                />
              ))}
            </RadioGroup>
          </>
        )}
      </FastField>
    </>
  );
}
