import { ConnectionPredicate } from "@deepcrawl/connection-filters";
import { Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { TFunction } from "i18next";
import React, { useContext } from "react";
import { GridContext } from "../report/report-rows/report-grid/ReportGrid";
import { MetricData } from "../report/report-rows/report-grid/ReportGrid.types";

const useStyles = makeStyles((theme) => ({
  operation: {
    fontWeight: 500,
    lineHeight: theme.typography.pxToRem(20),
  },
  condition: {
    display: "block",
    lineHeight: theme.typography.pxToRem(24),
  },
}));

interface FilterCondition {
  conditions: (string | FilterCondition)[];
  operation: string;
}

function formatName(name?: string): string | undefined {
  return name?.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
}

function getFilters(
  filters: Record<string, unknown>,
  operation: string,
  t: TFunction<"report">,
  filterMetrics: MetricData[],
): FilterCondition {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function formatCondition(predicate?: string, value?: any): string {
    switch (predicate) {
      case ConnectionPredicate.eq:
        return t("equals", { value: value?.toString() });
      case ConnectionPredicate.ne:
        return t("notEquals", { value: value?.toString() });
      case ConnectionPredicate.ge:
        return t("greaterThanOrEqualTo", { value: value?.toString() });
      case ConnectionPredicate.gt:
        return t("greaterThan", { value: value?.toString() });
      case ConnectionPredicate.le:
        return t("lessThanOrEqualTo", { value: value?.toString() });
      case ConnectionPredicate.lt:
        return t("lessThan", { value: value?.toString() });
      case ConnectionPredicate.isEmpty:
        return value ? t("isEmpty") : t("isNotEmpty");
      case ConnectionPredicate.isNull:
        return value ? t("isNull") : t("isNotNull");
    }

    return formatName(predicate) + " " + value?.toString();
  }

  return {
    conditions: (Array.isArray(filters) ? filters : [filters]).flatMap(
      (filter) => {
        return Object.entries(filter).map(([key, value]) => {
          if (key === "_or")
            return getFilters(
              value as Record<string, unknown>,
              t("or"),
              t,
              filterMetrics,
            );
          if (key === "_and")
            return getFilters(
              value as Record<string, unknown>,
              t("and"),
              t,
              filterMetrics,
            );

          if (key === "customMetrics") {
            const [field, filter] = Object.entries(
              value as Record<string, Record<string, unknown>>,
            )?.[0];

            const name = filterMetrics.find(
              (x) => x.code === `customMetrics.${field}`,
            )?.name;
            const condition = Object.entries(filter)?.[0];
            return `${name} ${formatCondition(condition?.[0], condition?.[1])}`;
          }

          const condition = Object.entries(
            value as Record<string, unknown>,
          )?.[0];
          return {
            conditions: [
              `${formatName(key)} ${formatCondition(
                condition?.[0],
                condition?.[1],
              )}`,
            ],
            operation: "AND",
          };
        });
      },
    ),
    operation,
  };
}

export function CustomReportFilterViewer({
  filter,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any;
}): JSX.Element {
  const { t } = useTranslation("report");
  const { filterMetrics } = useContext(GridContext);

  const filterCondition: FilterCondition = getFilters(
    filter,
    t("and"),
    t,
    filterMetrics,
  );

  return <ConditionGroup filterCondition={filterCondition} />;
}

export function ConditionGroup({
  filterCondition,
}: {
  filterCondition: FilterCondition;
}): JSX.Element | null {
  const classes = useStyles();
  const groups: FilterCondition[] =
    filterCondition.conditions.filter(isFilterCondition);

  if (groups.length > 0) {
    const hasMultiple = groups.length > 1;
    return (
      <>
        {groups.map((group, idx) => (
          <React.Fragment key={idx}>
            {idx !== 0 && (
              <div className={classes.operation}>
                {filterCondition.operation}
              </div>
            )}
            <div style={{ paddingLeft: hasMultiple ? 15 : 0 }}>
              <ConditionGroup filterCondition={group} />
            </div>
          </React.Fragment>
        ))}
      </>
    );
  }

  const count = filterCondition.conditions.length;
  return (
    <>
      {filterCondition.conditions.map((condition, idx) => (
        <Typography key={idx} className={classes.condition}>
          {condition}{" "}
          {idx !== count - 1 && (
            <span className={classes.operation}>
              {filterCondition.operation}
            </span>
          )}
        </Typography>
      ))}
    </>
  );
}

function isFilterCondition(value?: unknown): value is FilterCondition {
  return (value as Record<string, unknown>)?.conditions !== undefined;
}
