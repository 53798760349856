import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityParsingAChart } from "../../../../../_common/charts/config/getAccessibilityParsingAChart";
import { getAccessibilityParsingATrend } from "../../../../../_common/charts/config/getAccessibilityParsingATrend";

export const generateAccessibilityParsingACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityParsingAChart,
      getAccessibilityParsingATrend,
    ],
  };
