import { useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useAccountsRoutes } from "../../../../../_common/routing/accountsRoutes";

const useStyles = makeStyles((theme) => ({
  link: { color: theme.palette.blue[600] },
}));

export function CreditLimitReasons(props: {
  isAdmin: boolean;
  finalizationDate: Date;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");
  const { accountId } = useParams<{ accountId: string }>();
  const classes = useStyles();
  const accountsRoutes = useAccountsRoutes();
  const link = accountsRoutes.Subscription.getUrl({
    accountId,
  });

  return (
    <>
      {props.isAdmin ? (
        <span>
          {t("pauseReasons.CreditLimitAdminPart1", {
            value: props.finalizationDate,
          })}{" "}
          <a href={link} className={classes.link}>
            {t("pauseReasons.CreditLimitAdminAnchorText")}
          </a>{" "}
          {t("pauseReasons.CreditLimitAdminPart2")}
        </span>
      ) : (
        t("pauseReasons.CreditLimitNonAdmin", {
          value: props.finalizationDate,
        })
      )}
    </>
  );
}
