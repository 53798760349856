import {
  NestedNavigationMenu,
  NestedNavigationMenuChildItem,
  NestedNavigationMenuChildItemGroup,
} from "@lumar/shared";
import { Routes } from "../../../_common/routing/routes";
import { useAccessibilityDetailsSections } from "./useAccessibilityDetailsSections";
import { ResourceDetailData } from "../../data/types";
import { useResourceDetailParams } from "../../data/useResourceDetailParams";

export function AccessibilityDetailsSidebar({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const params = useResourceDetailParams();
  const { section, active } = useAccessibilityDetailsSections(data.metrics);

  function getHref(type: string): string {
    return Routes.ResourceDetail.getUrl({
      ...params,
      type,
    });
  }

  return (
    <NestedNavigationMenu
      sections={[
        {
          id: "crawlUrl",
          items: [
            {
              id: section.code,
              name: section.name,
              icon: section.icon,
              href: getHref(section.code),
              open: true,
              selected: section.code === active.code,
              children: [
                ...section.main.map((item) => ({
                  id: item.code,
                  name: item.name,
                  selected: item.code === active.code,
                  href: getHref(item.code),
                })),
                ...section.reports.flatMap(
                  (
                    group,
                  ): (
                    | NestedNavigationMenuChildItem
                    | NestedNavigationMenuChildItemGroup
                  )[] => {
                    const items = group.items.map((item) => ({
                      id: item.code,
                      name: item.name,
                      selected: item.code === active.code,
                      href: getHref(item.code),
                      adornment: item.adornment,
                    }));
                    if (!group.name) return items;
                    return [
                      {
                        id: group.code,
                        name: group.name,
                        children: items,
                      },
                    ];
                  },
                ),
              ],
            },
          ],
        },
      ]}
    />
  );
}
