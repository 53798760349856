import {
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { getDjb2Hash } from "../../_common/hashUtils";

export function AssigneeList({
  assignees,
  disabled,
  className,
}: {
  assignees: string[];
  disabled?: boolean;
  className?: string;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Tooltip
      title={
        !disabled ? (
          <div className={classes.tooltipContainer}>
            {assignees.map((email) => (
              <div key={email} className={classes.tooltipItem}>
                <div
                  className={classes.item}
                  style={{ background: getColor(email, theme) }}
                >
                  {getName(email)}
                </div>
                <Typography variant="inherit">{email}</Typography>
              </div>
            ))}
          </div>
        ) : (
          ""
        )
      }
      placement="right"
    >
      <div
        className={clsx(className, classes.container)}
        data-testid="assignee-list"
      >
        {assignees.map((email) => (
          <div key={email} className={classes.itemContainer}>
            <div
              className={classes.item}
              style={{ background: getColor(email, theme) }}
              data-testid="assignee-list-item"
              data-value={email}
            >
              {getName(email)}
            </div>
          </div>
        ))}
      </div>
    </Tooltip>
  );
}

function getName(email: string): string {
  const name = email.split("@")[0];

  const words = name.split(/[.\-_]+/);
  if (words.length > 1 && words[0].length && words[1].length) {
    return words[0][0] + words[1][0];
  }

  if (words.length > 1 && words[0].length) {
    return words[0][0].substring(0, 2);
  }

  return name.substring(0, 2);
}

function getColor(email: string, theme: Theme): string {
  const colors = theme.palette.assigneeColorMapping;
  return colors[getDjb2Hash(email) % colors.length];
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "max-content",
    maxWidth: "100%",
  },
  itemContainer: {
    maxWidth: theme.spacing(2.5),
    minWidth: theme.spacing(0.25),
    flexShrink: 1,
    "&:last-child": {
      maxWidth: theme.spacing(3.75),
      minWidth: theme.spacing(3.75),
      flexShrink: 0,
    },
  },
  item: {
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    width: theme.spacing(3.75),
    height: theme.spacing(3.75),
    borderRadius: theme.spacing(3.75),
    border: "1px solid white",
    textTransform: "uppercase",
    cursor: "default",
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0.5, 0),
    gap: theme.spacing(0.75),
    "& $item": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      borderStyle: "none",
      fontSize: theme.typography.pxToRem(8.57),
    },
  },
  tooltipItem: {
    display: "flex",
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(13),
  },
}));
