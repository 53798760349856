import { ApolloError, getRawCrawlId, useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { uniq } from "lodash";
import { insertManyIf } from "../../../_common/insertIf";
import {
  DatasourceCode,
  GetCompareCrawlQuery,
  GetCompareCrawlReportStatsQuery,
  useGetCompareCrawlQuery,
  useGetCompareCrawlReportStatsQuery,
} from "../../../graphql";
import { getMetrics } from "../../data/getMetrics";
import { QueryReturnValue, ResourceDetailData } from "../../data/types";
import { useResourceDetailParams } from "../../data/useResourceDetailParams";
import { getResourceDatasources } from "../../datasources/getDatasourceView";
import { CompareCrawlData, GetCompareCrawlError } from "./types";

const datasourceTransition = [
  DatasourceCode.CrawlUrls,
  DatasourceCode.CrawlUncrawledUrls,
];

export function useGetCompareCrawlData({
  currentCrawlDate,
  sourceTemplate,
  crawlId,
}: {
  currentCrawlDate: Date;
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  crawlId?: string;
}): QueryReturnValue<CompareCrawlData, GetCompareCrawlError> {
  const { t } = useTranslation("resourceDetail");
  const params = useResourceDetailParams();

  const crawlFilter = crawlId
    ? { id: { eq: crawlId } }
    : { createdAt: { lt: currentCrawlDate.toISOString() } };

  const reportTemplateCodes = uniq([
    sourceTemplate.code,
    ...insertManyIf(
      datasourceTransition.includes(sourceTemplate.datasource.datasourceCode),
      getResourceDatasources()
        .filter((x) => datasourceTransition.includes(x.datasource))
        .map((x) => x.templateCode),
    ),
  ]);

  const {
    data: crawlData,
    loading: crawlLoading,
    error: crawlError,
  } = useGetCompareCrawlQuery({
    variables: {
      projectId: params.projectId,
      crawlFilter,
    },
    fetchPolicy: "cache-first",
  });

  const crawl = crawlData?.getProject?.crawls.nodes[0];

  const {
    data: reportStatsData,
    loading: reportStatsLoading,
    error: reportStatsError,
  } = useGetCompareCrawlReportStatsQuery({
    variables: {
      reportStatsInputs: reportTemplateCodes.map((code) => ({
        crawlId: crawl?.id,
        reportTemplateCode: code,
      })),
      resourceId: params.resourceId,
    },
    fetchPolicy: "cache-first",
    skip: !crawl,
  });

  const result = formatData({
    crawlData,
    reportStatsData,
    error: crawlError ?? reportStatsError,
    sourceTemplate,
    t,
  });

  if (crawlLoading || reportStatsLoading) return { loading: true };

  if (result.error !== undefined)
    return {
      loading: false,
      error: result.error,
    };

  return {
    loading: false,
    data: result.data,
  };
}

export const formatData = (args: {
  crawlData?: GetCompareCrawlQuery;
  reportStatsData?: GetCompareCrawlReportStatsQuery;
  error?: ApolloError;
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  t: TFunction<"resourceDetail">;
}):
  | { data: CompareCrawlData; error?: undefined }
  | { data?: undefined; error: GetCompareCrawlError } => {
  const { reportStatsData, error, sourceTemplate, t, crawlData } = args;

  const project = crawlData?.getProject;
  const crawl = crawlData?.getProject?.crawls.nodes[0];

  if (error) {
    return {
      error: {
        message: error.message,
        isArchived: checkErrorCode(
          error,
          "REPORT_DATA_UNAVAILABLE_CRAWL_ARCHIVED",
        ),
        isUnarchivig: checkErrorCode(
          error,
          "REPORT_DATA_UNAVAILABLE_CRAWL_UNARCHIVING",
        ),
      },
    };
  }

  const resource = reportStatsData?.getReportStats?.find(
    (x) => x.reportTemplate.code === sourceTemplate.code,
  )?.getResource;

  if (!project || !crawl || !resource) {
    if (
      datasourceChangedFromCrawledToUncrawled({
        reportStatsData,
        sourceTemplate,
      })
    ) {
      return {
        error: {
          message: t("errorCompareDatasourceChangedCrawledUncrawled"),
          isDatasourceChanged: true,
        },
      };
    }

    if (
      datasourceChangedFromUncrawledToCrawled({
        reportStatsData,
        sourceTemplate,
      })
    ) {
      return {
        error: {
          message: t("errorCompareDatasourceChangedUncrawledCrawled"),
          isDatasourceChanged: true,
        },
      };
    }

    if (!crawlData && !resource) {
      return {
        error: {
          message: t("noPriorCrawl"),
          noPriorCrawl: true,
        },
      };
    }

    return { error: { message: t("compareResourceNotFound") } };
  }

  const metrics = getMetrics({
    resource,
    datasource: sourceTemplate.datasource,
    moduleCode: project.moduleCode,
    customMetrics: crawl.customMetrics,
  });

  return {
    data: {
      metrics,
      crawlId: getRawCrawlId(crawl.id),
    },
  };
};

function checkErrorCode(error: ApolloError, code: string): boolean {
  return Boolean(error.graphQLErrors.find((x) => x.extensions?.code === code));
}

function datasourceChangedFromUncrawledToCrawled(args: {
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  reportStatsData?: GetCompareCrawlReportStatsQuery;
}): boolean {
  const { sourceTemplate, reportStatsData } = args;

  const isCrawledDatasource =
    sourceTemplate.datasource.datasourceCode === DatasourceCode.CrawlUrls;
  const uncrawledResource = reportStatsData?.getReportStats?.find(
    (x) =>
      x.reportTemplate.datasource.datasourceCode ===
        DatasourceCode.CrawlUncrawledUrls && x.getResource,
  )?.getResource;

  return isCrawledDatasource && Boolean(uncrawledResource);
}

function datasourceChangedFromCrawledToUncrawled(args: {
  sourceTemplate: ResourceDetailData["sourceTemplate"];
  reportStatsData?: GetCompareCrawlReportStatsQuery;
}): boolean {
  const { reportStatsData, sourceTemplate } = args;

  const isUncrawledDatasource =
    sourceTemplate.datasource.datasourceCode ===
    DatasourceCode.CrawlUncrawledUrls;
  const crawledResource = reportStatsData?.getReportStats?.find(
    (x) =>
      x.reportTemplate.datasource.datasourceCode === DatasourceCode.CrawlUrls &&
      x.getResource,
  )?.getResource;

  return isUncrawledDatasource && Boolean(crawledResource);
}
