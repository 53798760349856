import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getNonPrimaryPagesInSerps(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("nonPrimaryPagesInSerps.title"),
    description: t("nonPrimaryPagesInSerps.description"),
    reportTemplateCodes: [
      "duplicate_pages_in_serp",
      "redirecting_pages_in_serp",
      "non_indexable_pages_in_serp",
      "error_pages_in_serp",
    ],
  })();
}
