import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { EmptyState, Package, UnarchiveIcon, useSession } from "@lumar/shared";
import { assert } from "../../../assert";
import { Routes } from "../../../routing/routes";
import { useGenericParams } from "../../../routing/useGenericParams";
import { ChartPanelMessageWrapper } from "./ChartPanelMessageWrapper";
import { RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.yellow[500],
    fontSize: theme.typography.pxToRem(38),
    strokeWidth: "1.7",
  },
}));

export function ArchivedCrawlMessage(): JSX.Element {
  const { accountId, projectId, crawlId } = useGenericParams();
  const { t } = useTranslation("charts");
  const { hasSufficientRole } = useSession();
  const classes = useStyles();
  const hasEditorPermission = hasSufficientRole(RoleCode.Editor);

  assert(accountId);
  assert(projectId);

  return (
    <ChartPanelMessageWrapper maxWidth="100%">
      <EmptyState
        description={t("archivedCrawlTitle")}
        icon={<Package className={classes.icon} />}
        actions={
          hasEditorPermission
            ? [
                {
                  type: "internalLink",
                  title: t("archivedCrawlMessage"),
                  icon: <UnarchiveIcon />,
                  to: Routes.Crawls.getUrl({
                    accountId,
                    projectId,
                    tab: "history",
                    unarchive: crawlId,
                  }),
                },
              ]
            : []
        }
      />
    </ChartPanelMessageWrapper>
  );
}
