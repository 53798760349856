import React, { useState } from "react";
import {
  Typography,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { FastField, FastFieldProps, FieldProps } from "formik";
import { TextField } from "formik-material-ui";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import { fieldToSelect, Select, TagInput } from "@lumar/shared";
import { uniq } from "lodash";

import { translationNamespace } from "../../CrawlSettings";
import { COUNTRIES_ALPHA3 } from "../../../../_common/constants/countries-alpha3.constant";
import { TextFieldWithOptions } from "../../components/TextFieldWithOptions";
import { withOnBlurUpdate } from "../../components/CustomFields";
import { withDisplayValue } from "../../components/TextFieldInput";
import { InputLabelInfo } from "../../../../_common/components/LabelInfo";

const CustomTextField = withOnBlurUpdate(TextField);
const CustomTextFieldWithOptions = withOnBlurUpdate(TextFieldWithOptions);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    accordion: {
      display: "block",
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    fullWidth: { width: "100%" },
    gridItem1: {
      order: 1,
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
    },
    gridItem2: {
      order: 2,
      [theme.breakpoints.up("md")]: {
        order: 3,
      },
    },
    gridItem3: {
      order: 3,
      [theme.breakpoints.up("md")]: {
        order: 5,
      },
    },
    gridItem4: {
      order: 4,
      [theme.breakpoints.up("md")]: {
        order: 2,
      },
    },
    gridItem5: {
      order: 5,
      [theme.breakpoints.up("md")]: {
        order: 4,
      },
    },
    gridItem6: {
      order: 6,
      [theme.breakpoints.up("md")]: {
        order: 6,
      },
    },
    gridItem7: {
      order: 7,
      display: "flex",
      justifyContent: "flex-end",
    },
  }),
);

export const SearchConsoleFilters = React.memo(
  function SearchConsoleFilters(): JSX.Element {
    const classes = useStyles();

    const { t } = useTranslation(translationNamespace);
    const { t: tCommon } = useTranslation("common");

    const [expanded, setExpanded] = useState(false);

    const lastNDaysValues = ["10", "15", "30", "60", "100"];

    const integerPattern = /^(\+?\d+)?$/;

    const countryOption = [
      {
        code: "worldwide",
        name: t("sources.googleSearchConsole.filters.worldwide"),
      },
      ...COUNTRIES_ALPHA3.map((country) => ({
        code: country["alpha-3"],
        name: tCommon("country", { code: country["alpha-3"] }),
      })),
    ];

    const searchTypes = [
      {
        name: t("sources.googleSearchConsole.filters.searchTypeWeb"),
        code: "Web",
      },
      {
        name: t("sources.googleSearchConsole.filters.searchTypeImage"),
        code: "Image",
      },
      {
        name: t("sources.googleSearchConsole.filters.searchTypeVideo"),
        code: "Video",
      },
    ];

    return (
      <Accordion
        expanded={expanded}
        onChange={(_, expanded) => setExpanded(expanded)}
        className={classes.accordion}
        elevation={1}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <SettingsIcon
              className={classes.heading}
              style={{ marginBottom: "2px" }}
            />
            <Typography
              className={classes.heading}
              component="div"
              style={{ marginLeft: "8px" }}
            >
              {t("sources.googleSearchConsole.filters.title")}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className={classes.gridItem1}>
              <FastField name="googleSearchConsole.country">
                {(props: FieldProps<string>) => (
                  <Select
                    {...fieldToSelect(props)}
                    label={t("sources.googleSearchConsole.filters.country")}
                    className={classes.fullWidth}
                  >
                    {countryOption.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FastField>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem2}>
              <FastField name="googleSearchConsole.searchType">
                {(props: FieldProps<string>) => (
                  <Select
                    {...fieldToSelect(props)}
                    label={t("sources.googleSearchConsole.filters.searchType")}
                    className={classes.fullWidth}
                  >
                    {searchTypes.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FastField>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem3}>
              <InputLabelInfo
                label={t("sources.googleSearchConsole.filters.minimumClicks")}
                info={t(
                  "sources.googleSearchConsole.filters.minimumClicksInfo",
                )}
              />
              <FastField
                name="googleSearchConsole.minClicks"
                component={CustomTextField}
                pattern={integerPattern}
                variant="outlined"
                className={classes.fullWidth}
                placeholder={t(
                  "sources.googleSearchConsole.filters.minimumClicksPlaceholder",
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem4}>
              <InputLabelInfo
                label={t("sources.googleSearchConsole.filters.dateRange")}
                info={t("sources.googleSearchConsole.filters.dateRangeInfo")}
              />
              <FastField
                name="googleSearchConsole.lastNDays"
                component={CustomTextFieldWithOptions}
                pattern={integerPattern}
                options={lastNDaysValues}
                renderOption={(range: string) =>
                  t("sources.googleSearchConsole.filters.dateRangeSelector", {
                    value: range,
                  })
                }
                variant="outlined"
                placeholder={t(
                  "sources.googleSearchConsole.filters.dateRangePlaceholder",
                )}
                InputProps={{
                  inputComponent: withDisplayValue((value) =>
                    t("sources.googleSearchConsole.filters.dateRangeSelector", {
                      value,
                    }),
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem5}>
              <InputLabelInfo
                label={t("sources.googleSearchConsole.filters.includeQueries")}
                info={t(
                  "sources.googleSearchConsole.filters.includeQueriesInfo",
                )}
              />
              <FastField name="googleSearchConsole.includeQueries">
                {({
                  field: { value, name },
                  form: { setFieldValue, isSubmitting },
                }: FastFieldProps<string[]>) => (
                  <TagInput
                    value={value}
                    onChange={(value) => setFieldValue(name, uniq(value))}
                    disabled={isSubmitting}
                    addOnComma
                    variant="outlined"
                    className={classes.fullWidth}
                    InputProps={{
                      placeholder: t(
                        "sources.googleSearchConsole.filters.includeQueriesPlaceholder",
                      ),
                    }}
                    data-testid="crawl-settings-include-queries"
                  />
                )}
              </FastField>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridItem6}>
              <InputLabelInfo
                label={t("sources.googleSearchConsole.filters.excludeQueries")}
                info={t(
                  "sources.googleSearchConsole.filters.excludeQueriesInfo",
                )}
              />
              <FastField name="googleSearchConsole.excludeQueries">
                {({
                  field: { value, name },
                  form: { setFieldValue, isSubmitting },
                }: FastFieldProps<string[]>) => (
                  <TagInput
                    value={value}
                    onChange={(value) => setFieldValue(name, uniq(value))}
                    disabled={isSubmitting}
                    addOnComma
                    variant="outlined"
                    className={classes.fullWidth}
                    InputProps={{
                      placeholder: t(
                        "sources.googleSearchConsole.filters.excludeQueriesPlaceholder",
                      ),
                    }}
                    data-testid="crawl-settings-exclude-queries"
                  />
                )}
              </FastField>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
);
