import { Checkbox } from "@lumar/shared";
import { CheckboxProps, FormControlLabel } from "@material-ui/core";

export interface CheckboxWithLabelProps extends CheckboxProps {
  label?: React.ReactNode;
}

export function CheckboxWithLabel({
  label,
  className,
  ...props
}: CheckboxWithLabelProps): JSX.Element {
  return (
    <FormControlLabel
      control={<Checkbox {...props} />}
      className={className}
      label={label}
    />
  );
}
