import { CrawlType } from "../../../graphql";
import { RequiredSources } from "../types/ChartConfigItemBase";
import { checkRequiredSources } from "../utils/checkRequiredSources";

export function isChartMissingRequiredSource(
  requiredSources?: RequiredSources,
  crawlTypes?: CrawlType[],
): boolean {
  return (
    !!requiredSources &&
    !checkRequiredSources(requiredSources.sources, crawlTypes ?? [])
  );
}
