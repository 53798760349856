var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles, Popover, Typography, } from "@material-ui/core";
import React from "react";
import { Button } from "../../buttons/Button";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
    paper: {
        border: 0,
        width: 350,
        padding: theme.spacing(1.75),
        marginTop: theme.spacing(1),
        boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    },
    title: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 600,
        color: theme.palette.grey[800],
        marginBottom: "7px",
    },
    description: {
        fontSize: theme.typography.pxToRem(13),
        fontWeight: 400,
        color: theme.palette.grey[800],
        marginBottom: "15px",
        whiteSpace: "pre-line",
    },
    cancelButton: {
        width: "87px",
        marginRight: "7px",
        height: "36px",
    },
    removeButton: {
        background: theme.palette.red[600],
        "&:hover": {
            background: theme.palette.red[700],
        },
        color: "white",
        width: "108px",
        height: "36px",
    },
}));
export function DestructiveMenuActionConfirmation(_a) {
    var { anchorOrigin, transformOrigin } = _a, props = __rest(_a, ["anchorOrigin", "transformOrigin"]);
    const [isConfirming, setIsConfirming] = React.useState(false);
    const classes = useStyles();
    const { t } = useTranslation("sharedCommon");
    return (_jsxs(Popover, { open: true, disablePortal: props.disablePortal, anchorEl: props.anchorEl, onClose: props.onCancel, anchorOrigin: Object.assign({ vertical: "bottom", horizontal: "right" }, anchorOrigin), transformOrigin: Object.assign({ vertical: "top", horizontal: "right" }, transformOrigin), classes: { paper: classes.paper }, style: props.style, children: [_jsx(Typography, { className: classes.title, children: props.title }), props.description ? (_jsx(Typography, { className: classes.description, children: props.description })) : null, _jsxs(Box, { display: "flex", justifyContent: "flex-end", children: [_jsx(Button, { variant: "outlined", onClick: props.onCancel, className: classes.cancelButton, "data-testid": "action-remove-cancel", "data-pendo": props.pendoPrefix
                            ? `${props.pendoPrefix}-destructive-menu-action-cancel`
                            : undefined, children: props.cancelText ? props.cancelText : t("cancel") }), _jsx(Button, { onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                            setIsConfirming(true);
                            Promise.resolve(props.onConfirm(e)).finally(() => setIsConfirming(false));
                        }), variant: "contained", className: classes.removeButton, "data-testid": "action-remove-confirm", loading: isConfirming, "data-pendo": props.pendoPrefix
                            ? `${props.pendoPrefix}-destructive-menu-action-confirm`
                            : undefined, children: props.confirmText ? props.confirmText : t("confirm") })] })] }));
}
