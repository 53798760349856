import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";

export function getLogSummaryBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    title: t("logSummaryBreakdownChart.title"),
    reportTemplateCodes: ["pages_in_log_summary", "pages_not_in_log_summary"],
    description: t("logSummaryBreakdownChart.description"),
  })();
}
