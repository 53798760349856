import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FastField, FastFieldProps } from "formik";
import { getProperty, GlobeSolid, TagInput, Typography } from "@lumar/shared";
import { uniq } from "lodash";

import { translationNamespace } from "../../CrawlSettings";
import { CustomAccordion } from "../CustomAccordion";
import { SourcesContext } from "../data/useSourcesFormValues";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    paddingBottom: theme.spacing(1),
    flexDirection: "column",
    width: "100%",
  },
  input: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
}));

export const Website = React.memo(function Website({
  initialState,
}: {
  initialState: boolean;
}): JSX.Element {
  const { t } = useTranslation(translationNamespace);
  const classes = useStyles();

  const contextValues = React.useContext(SourcesContext);

  const withProtocolAndEncodedSpaces = (url: string): string => {
    const protocol = contextValues?.primaryDomain?.match(/^(.*?):/);
    const urlWithProtocol =
      !(protocol && protocol[0]) || url.match(/^[a-zA-Z]+:\/\//)
        ? url
        : protocol[0] + "//" + url;
    return urlWithProtocol.replaceAll(" ", "%20");
  };

  return (
    <CustomAccordion
      title={t("sources.website.title")}
      icon={
        <GlobeSolid
          fontSize="small"
          style={{ display: "block", marginBottom: "1px" }}
        />
      }
      tooltip={t("sources.website.tooltip")}
      initialState={initialState}
      fieldName="crawlType.website"
      data-testid="website-section"
    >
      <div className={classes.root}>
        <Typography variant="subtitle2" component="h6">
          {t("startUrlsTitle")}
        </Typography>
        <Typography variant="caption">{t("startUrlsDescription")}</Typography>
        <FastField name="website.startUrls">
          {({
            field: { value, name },
            form: { setFieldValue, isSubmitting, errors },
          }: FastFieldProps<string[]>) => {
            const error: string[] | undefined = getProperty(errors, name);
            return (
              <TagInput
                value={value}
                onChange={(value) =>
                  setFieldValue(
                    name,
                    uniq(value.map((x) => withProtocolAndEncodedSpaces(x))),
                    true,
                  )
                }
                disabled={isSubmitting}
                showCopyAll
                error={error?.find(Boolean)}
                errors={error?.map((error) => Boolean(error))}
                variant="outlined"
                className={classes.input}
                placeholder={t("startUrlsPlaceholder")}
                data-testid="start-urls-tags"
              />
            );
          }}
        </FastField>
      </div>
    </CustomAccordion>
  );
});
