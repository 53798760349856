import { Project, UpdateProjectInput } from "../../../../../graphql";
import { StartUrlsSettings } from "../types";

type QueryData = Pick<Project, "startUrls">;

export function formatStartUrlsSettingsFrom(
  data?: QueryData | null,
): StartUrlsSettings {
  return {
    startUrls: data?.startUrls ?? [],
  };
}

export function formatStartUrlsSettingsTo(
  formValues: StartUrlsSettings,
): Pick<UpdateProjectInput, "startUrls"> {
  return {
    startUrls: formValues.startUrls,
  };
}
