import { useParams } from "react-router-dom";
import { ReportTypeCode } from "../../../../graphql";
import { ReportGrid } from "../../../../report/report-rows/report-grid/ReportGrid";
import { ReportFilter } from "../useSeoDetailsSections";
import { ReportEntity } from "../../../../report/Report.types";

export function SeoReport({
  reportFilter,
}: {
  reportFilter: ReportFilter;
}): JSX.Element {
  const { crawlId } = useParams<{ crawlId: string }>();

  return (
    <ReportGrid
      reportInput={{
        crawlId,
        reportEntity: ReportEntity.ReportStat,
        reportTemplateCode: reportFilter.reportTemplateCode,
        reportTypeCode: ReportTypeCode.Basic,
      }}
      baseFilter={reportFilter.filter}
      tabsMode="onlyTotalRowsVisible"
      hideTaskMenu
    />
  );
}
