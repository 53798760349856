import React from "react";
import { useTranslation } from "@lumar/shared";
import { Metrics } from "../../data/types";
import { ReportConfig } from "./useSeoDetailsSections";
import { insertIf } from "../../../_common/insertIf";

export function useSeoReports(metrics: Metrics): ReportConfig[] {
  const { t } = useTranslation("resourceDetail");

  return React.useMemo(() => {
    const urlDigest = metrics["urlDigest"].value;
    const primaryUrlDigest = metrics["primaryUrlDigest"]?.value || "";
    const duplicateTitlePrimaryUrlDigest =
      metrics["duplicateTitlePrimaryUrlDigest"]?.value || "";
    const duplicateDescriptionPrimaryUrlDigest =
      metrics["duplicateDescriptionPrimaryUrlDigest"]?.value || "";
    const duplicateBodyPrimaryUrlDigest =
      metrics["duplicateBodyPrimaryUrlDigest"]?.value || "";

    const result: ReportConfig[] = [
      {
        code: "duplicates",
        name: t("categoryDuplicates"),
        items: [
          {
            code: "duplicatePages",
            name: t("reportDuplicatePages"),
            countMetric: "duplicatePageCount",
            filter: {
              reportTemplateCode: "duplicate_pages_including_primary",
              filter: { primaryUrlDigest: { eq: primaryUrlDigest } },
            },
          },
          {
            code: "duplicateURLs",
            name: t("reportDuplicateURLs"),
            countMetric: "duplicateUrlCount",
            // Note: The report does not contain the primary URL
            countMetricOffset: -1,
            filter: {
              reportTemplateCode: "duplicate_urls",
              filter: {
                duplicateUrlPrimaryUrlDigest: {
                  eq: duplicateTitlePrimaryUrlDigest,
                },
              },
            },
          },
          {
            code: "duplicateTitles",
            name: t("reportDuplicateTitles"),
            countMetric: "duplicateTitleCount",
            // Note: The report does not contain the primary URL
            countMetricOffset: -1,
            filter: {
              reportTemplateCode: "pages_with_duplicate_titles",
              filter: {
                duplicateTitlePrimaryUrlDigest: {
                  eq: duplicateTitlePrimaryUrlDigest,
                },
              },
            },
          },
          {
            code: "duplicateDescriptions",
            name: t("reportDuplicateDescriptions"),
            countMetric: "duplicateDescriptionCount",
            // Note: The report does not contain the primary URL
            countMetricOffset: -1,
            filter: {
              reportTemplateCode: "pages_with_duplicate_descriptions",
              filter: {
                duplicateDescriptionPrimaryUrlDigest: {
                  eq: duplicateDescriptionPrimaryUrlDigest,
                },
              },
            },
          },
          {
            code: "duplicateBody",
            name: t("reportDuplicateBody"),
            countMetric: "duplicateBodyCount",
            // Note: The report does not contain the primary URL
            countMetricOffset: -1,
            filter: {
              reportTemplateCode: "duplicate_body_content",
              filter: {
                duplicateBodyPrimaryUrlDigest: {
                  eq: duplicateBodyPrimaryUrlDigest,
                },
              },
            },
          },
        ],
      },
      {
        code: "foundAt",
        name: t("categoryFoundAt"),
        items: [
          {
            code: "uniqueLinksIn",
            name: t("reportUniqueLinksIn"),
            countMetric: "uniqueLinksInCount",
            filter: {
              reportTemplateCode: "unique_internal_links",
              filter: { urlToDigest: { eq: urlDigest } },
            },
          },
          {
            code: "sitemapsIn",
            name: t("reportSitemapsIn"),
            countMetric: "sitemapsInCount",
            filter: {
              reportTemplateCode: "all_sitemaps_links",
              filter: { urlToDigest: { eq: urlDigest } },
            },
          },
          {
            code: "allCanonicalLinks",
            name: t("reportAllCanonicalLinks"),
            countMetric: "canonicalLinksInCount",
            filter: {
              reportTemplateCode: "all_rel_links",
              filter: {
                _and: [
                  { urlToDigest: { eq: urlDigest } },
                  { urlFromDigest: { ne: urlDigest } },
                ],
              },
            },
          },
          {
            code: "redirectsIn",
            name: t("reportRedirectsIn"),
            countMetric: "redirectsInCount",
            filter: {
              reportTemplateCode: "all_redirects",
              filter: { redirectedToUrlDigest: { eq: urlDigest } },
            },
          },
          {
            code: "relLinksIn",
            name: t("reportRelLinkIn"),
            countMetric: "relLinksInCount",
            filter: {
              reportTemplateCode: "rel_links",
              filter: { urlToDigest: { eq: urlDigest } },
            },
          },
          {
            code: "mobileLinks",
            name: t("reportMobileLinks"),
            countMetric: "mobileLinksInCount",
            filter: {
              reportTemplateCode: "rel_links",
              filter: {
                _and: [
                  { urlToDigest: { eq: urlDigest } },
                  { mobile: { eq: true } },
                ],
              },
            },
          },
          {
            code: "paginationLinks",
            name: t("reportPaginationLinks"),
            countMetric: "paginatedLinksInCount",
            filter: {
              reportTemplateCode: "rel_links",
              filter: {
                _and: [
                  { urlToDigest: { eq: urlDigest } },
                  {
                    _or: [
                      { attrRel: { eq: "next" } },
                      { attrRel: { eq: "prev" } },
                    ],
                  },
                ],
              },
            },
          },
          ...insertIf(metrics["resourceLinksInCount"].value, {
            code: "resourceLinksIn",
            name: t("resourceLinksIn"),
            countMetric: "resourceLinksInCount",
            filter: {
              reportTemplateCode: "aaa_crawl_links",
              filter: { urlToDigest: { eq: urlDigest } },
            },
          }),
        ],
      },
      {
        code: "hreflang",
        name: t("categoryHreflang"),
        items: [
          {
            code: "hreflangsIn",
            name: t("reportHreflangsIn"),
            countMetric: "hreflangLinksInCount",
            filter: {
              reportTemplateCode: "all_hreflang_links",
              filter: { urlToDigest: { eq: urlDigest } },
            },
          },
          {
            code: "reciprocalHreflangs",
            name: t("reciprocalHreflangs"),
            countMetric: "reciprocateHreflangOutCount",
            filter: {
              reportTemplateCode: "all_hreflang_links",
              filter: {
                relUrlFromDigest: { eq: urlDigest },
                hreflangReciprocate: { eq: true },
              },
            },
          },
          {
            code: "nonReciprocalHreflangs",
            name: t("nonReciprocalHreflangs"),
            countMetric: "nonReciprocateHreflangOutCount",
            filter: {
              reportTemplateCode: "all_hreflang_links",
              filter: {
                relUrlFromDigest: { eq: urlDigest },
                hreflangReciprocate: { eq: false },
              },
            },
          },
        ],
      },
      {
        code: "resources",
        name: t("categoryResources"),
        items: [
          {
            code: "cssResources",
            name: t("reportCssResources"),
            filter: {
              reportTemplateCode: "aaa_crawl_links",
              filter: {
                _and: [
                  { urlFromDigest: { eq: urlDigest } },
                  { attrRel: { eq: "stylesheet" } },
                ],
              },
            },
          },
          {
            code: "jsResources",
            name: t("reportJsResources"),
            filter: {
              reportTemplateCode: "aaa_crawl_links",
              filter: {
                _and: [
                  { urlFromDigest: { eq: urlDigest } },
                  { linkType: { eq: "script" } },
                ],
              },
            },
          },
          {
            code: "imgResources",
            name: t("reportImgResources"),
            filter: {
              reportTemplateCode: "aaa_crawl_links",
              filter: {
                _and: [
                  { urlFromDigest: { eq: urlDigest } },
                  { linkType: { eq: "img" } },
                ],
              },
            },
          },
        ],
      },
    ];
    return result;
  }, [t, metrics]);
}
