/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import {
  ChevronRightSolid,
  Chip,
  ChipColor,
  FolderNotch,
  FolderNotchOpen,
  TabSpinner,
  Typography,
  useNumberFormatter,
} from "@lumar/shared";

import { MissingValue } from "./MissingValue";
import { DataExplorerTableConfig } from "../types";
import { ViewReportCell } from "./ViewReportCell";
import { MonospaceNumber } from "../../_common/monospace-number/MonospaceNumber";
import { useMetricValueUnitGetter } from "../data/useMetricValueUnitGetter";
import { CrawlUrlAggregateDimension } from "../../graphql";

export interface DataTableCellProps {
  metricUnit?: string | null;
  metricValue: any;
  metricCrawlType?: string | null;
  columnIndex: number;
  isMetricColumn: boolean;
  tableConfig: DataExplorerTableConfig;
  aggregationCalculation?: string;
  crawlTypes: Array<string>;
  row: any;
  error: boolean;
}

const useStyles = makeStyles((theme) => ({
  cell: {
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.grey[200],
  },
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  valueContainer: {
    display: "flex",
    width: "100%",
  },
  numericValue: {
    display: "flex",
    justifyContent: "flex-end",
  },
  unit: {
    textTransform: "uppercase",
    fontSize: 10,
  },
  arrow: {
    opacity: 0,
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(0.5),
    marginLeft: -theme.spacing(1),
  },
  arrowHasChildren: {
    opacity: 1,
    color: theme.palette.grey[600],
  },
  openArrow: {
    transition: ".2s",
    transform: "rotate(90deg)",
  },
  folderIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  value: {
    whiteSpace: "nowrap",
  },
  link: {
    cursor: "pointer",
  },
  httpStatus: {
    fontSize: theme.typography.pxToRem(13),
  },
}));

export function DataTableCell(props: DataTableCellProps): JSX.Element {
  const classes = useStyles();
  const formatNumber = useNumberFormatter();

  const getMetricValueUnit = useMetricValueUnitGetter();

  const {
    metricUnit,
    metricValue,
    metricCrawlType,
    isMetricColumn,
    columnIndex,
    tableConfig,
    aggregationCalculation,
    crawlTypes,
    row,
    error,
  } = props;

  const getParsedMetric = (
    metricValue: number | string | null,
    columnIndex: number,
  ): string | number | JSX.Element => {
    const testId = `data-explorer-table-cell-${columnIndex}`;

    const getUnit = (count = 1): { count: number; unit: string } => {
      if (!metricUnit || aggregationCalculation === "count") {
        return { count, unit: "" };
      }

      return getMetricValueUnit(metricUnit, count);
    };

    const getValue = (
      metricValue: number | string | boolean | null | undefined,
    ): string | number | JSX.Element => {
      if (columnIndex !== 0 && metricValue === undefined) {
        if (error) {
          return <div className={classes.container}>-</div>;
        } else {
          return (
            <div className={classes.container}>
              <TabSpinner />
            </div>
          );
        }
      }

      if (
        columnIndex !== 0 &&
        metricCrawlType &&
        !crawlTypes.includes(metricCrawlType)
      ) {
        return (
          <div className={classes.container}>
            <MissingValue />
          </div>
        );
      }

      if (
        columnIndex === 0 &&
        tableConfig.dimension === CrawlUrlAggregateDimension.HttpStatusCode
      ) {
        const value = metricValue ?? 0;
        const getColor = (): ChipColor => {
          if (value === 0) return "lightgrey";
          if (+value >= 200 && +value < 300) return "green";
          if (+value >= 300 && +value < 400) return "yellow";
          return "red";
        };
        return (
          <Chip
            label={value}
            color={getColor()}
            rounded
            className={classes.httpStatus}
          />
        );
      }

      if (typeof metricValue === "boolean") {
        return (
          <span className={clsx(classes.value, "value-container")}>
            {metricValue === true ? "Yes" : "No"}
          </span>
        );
      }

      if (typeof metricValue === "number") {
        const { count, unit } = getUnit(Number(metricValue));
        const formattedNumber = formatNumber(count, {
          maximumFractionDigits: 2,
        });

        return (
          <>
            <MonospaceNumber>{formattedNumber}</MonospaceNumber>
            &nbsp;
            <span className={classes.unit}>{unit}</span>
          </>
        );
      }

      if (typeof metricValue === "string") {
        return (
          <span className={clsx(classes.value, "value-container")}>
            {metricValue} {getUnit().unit}
          </span>
        );
      }

      return "-";
    };

    return (
      <Typography
        variant="captionSemiBold"
        component="div"
        className={clsx(
          classes.valueContainer,
          typeof metricValue !== "string" &&
            isMetricColumn &&
            classes.numericValue,
        )}
        data-testid={testId}
      >
        {getValue(metricValue)}
      </Typography>
    );
  };

  const getExpandableCell = (
    value: string | number | JSX.Element,
  ): JSX.Element => {
    function getFolderProps(): Record<string, unknown> | undefined {
      if (!row.hasChildren) return;
      return {
        className: classes.link,
        ["data-testid"]: `data-explorer-value-${metricValue}`,
        onClick: () => row.setExpanded(!row.isExpanded),
      };
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft:
            row.expansionLevel && row.expansionLevel !== 1
              ? row.expansionLevel * 16
              : 0,
        }}
        {...getFolderProps()}
      >
        <ChevronRightSolid
          className={clsx(
            classes.arrow,
            row.hasChildren && classes.arrowHasChildren,
            row.isExpanded && classes.openArrow,
          )}
        />
        {row.isExpanded ? (
          <FolderNotchOpen className={classes.folderIcon} />
        ) : (
          <FolderNotch className={classes.folderIcon} />
        )}
        <Typography variant="captionMedium" component="div">
          {value}
        </Typography>
      </div>
    );
  };

  const parsedMetricValue = getParsedMetric(metricValue, columnIndex);
  const cellContent =
    typeof row.expansionLevel === "number" && columnIndex === 0
      ? getExpandableCell(parsedMetricValue)
      : parsedMetricValue;

  return columnIndex === 0 ? (
    <ViewReportCell row={row}>{cellContent}</ViewReportCell>
  ) : (
    <TableCell className={classes.cell}>{cellContent}</TableCell>
  );
}
