import {
  ArrowRight,
  Chip,
  ExpandMore,
  InfoOutlined,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { useReportInfoState } from "../../report/data/useReportInfoState";
import { ReportImpact } from "../report-helpers/reportImpact";
import { ImpactBadge } from "../report-helpers/ReportImpactBadge";
import { ReportPriority } from "../report-helpers/reportPriority";
import { PriorityBadge } from "../report-helpers/ReportPriorityBadge";

type HelpAccordionEntry = {
  title: string;
  content: string | JSX.Element;
  icon?: typeof SvgIcon;
};

type HelpAccordionProps = {
  entries: HelpAccordionEntry[];
  priority: ReportPriority;
  impact: ReportImpact;
  customReport?: {
    templateId: string;
    baseReportName: string;
    baseReportLink: string;
  };
  defaultCollapsed: boolean;
};

export const HelpAccordion = (props: HelpAccordionProps): JSX.Element => {
  const classes = useStyles();
  const [helpSectionCollapsed, setHelpSectionCollapsed] = useReportInfoState(
    props.defaultCollapsed,
  );
  const { t } = useTranslation("customReports");
  const half = Math.ceil(props.entries.length / 2);

  return (
    <Accordion
      expanded={!helpSectionCollapsed}
      onChange={(e, expanded) => {
        setHelpSectionCollapsed(!expanded);
      }}
      className={classes.accordion}
    >
      <AccordionSummary
        data-testid="report-info-toggle"
        expandIcon={<ExpandMore />}
        className={classes.collapsibleSummary}
      >
        <div className={classes.collapsibleHeader}>
          <div className={classes.collapsibleHeaderLeft}>
            <div className={classes.chipContainer}>
              <Typography variant="subtitle3Medium">
                {t("helpAccordion.priority")}
              </Typography>
              <div className={classes.badgeContainer}>
                <PriorityBadge priority={props.priority} />
              </div>
            </div>
            <div className={classes.chipContainer}>
              <Typography variant="subtitle3Medium">
                {t("helpAccordion.impact")}
              </Typography>
              <span className={classes.badgeContainer}>
                <ImpactBadge impact={props.impact} />
              </span>
            </div>
            {props.customReport ? (
              <div className={classes.chipContainer}>
                <Typography variant="subtitle3Medium">
                  {t("helpAccordion.baseReport")}
                </Typography>
                <Link
                  to={props.customReport.baseReportLink}
                  className={classes.chip}
                >
                  <span>{props.customReport.baseReportName}</span>
                  <ArrowRight className={classes.chipIcon} />
                </Link>
              </div>
            ) : null}
          </div>
          <div className={classes.collapsibleHeaderRight}>
            {props.customReport?.templateId ? (
              <div className={classes.chipContainer}>
                <Typography variant="subtitle3Medium">
                  {t("helpAccordion.reportTemplateId")}
                </Typography>
                <span className={classes.badgeContainer}>
                  <Chip
                    color="primary"
                    label={`#${props.customReport.templateId}`}
                  />
                </span>
                <Tooltip title={t("reportTemplateIdTooltip")} placement="top">
                  <span tabIndex={0}>
                    <InfoOutlined
                      color="inherit"
                      fontSize="inherit"
                      className={classes.tooltipIcon}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails data-testid="report-info-panel">
        <div className={classes.accordionContent}>
          <div className={classes.accordionSection}>
            {props.entries.slice(0, half).map((entry) => {
              const Icon = entry.icon;
              return (
                <div key={entry.title}>
                  <div className={classes.accordionSectionTitle}>
                    {Icon ? <Icon className={classes.sectionIcon} /> : null}
                    <Typography variant="h2SemiBold">{entry.title}</Typography>
                  </div>
                  {typeof entry.content === "string" ? (
                    <div
                      className={classes.sectionDescription}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(entry.content),
                      }}
                    />
                  ) : (
                    entry.content
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.accordionSection}>
            {props.entries.slice(half).map((entry) => {
              const Icon = entry.icon;
              return (
                <div key={entry.title}>
                  <div className={classes.accordionSectionTitle}>
                    {Icon ? <Icon className={classes.sectionIcon} /> : null}
                    <Typography variant="h2SemiBold">{entry.title}</Typography>
                  </div>
                  {typeof entry.content === "string" ? (
                    <div
                      className={classes.sectionDescription}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(entry.content),
                      }}
                    />
                  ) : (
                    entry.content
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  collapsibleSummary: {
    padding: theme.spacing(0, 3.75),
  },
  collapsibleHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  collapsibleHeaderLeft: {
    display: "flex",
  },
  collapsibleHeaderRight: {
    display: "flex",
  },
  chipContainer: {
    display: "flex",
    alignItems: "center",
  },
  chip: {
    display: "flex",
    alignItems: "center",
    marginLeft: 7,
    marginRight: 12,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.purple[800],
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  badgeContainer: {
    marginLeft: 7,
    marginRight: 12,
  },
  chipIcon: {
    marginLeft: 4,
    height: 16,
    width: 16,
  },
  accordion: {
    borderRadius: 8,
    marginBottom: 24,
    marginTop: 16,
    boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.01)",
    "&:before": {
      display: "none",
    },
  },
  accordionContent: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  accordionSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: theme.spacing(0, 1.75),
    gap: theme.spacing(4),
  },
  accordionSectionTitle: {
    display: "flex",
    gap: 8,
    marginBottom: theme.spacing(1),
  },
  tooltipIcon: {
    width: 20,
    height: 20,
    color: theme.palette.grey[400],
    verticalAlign: "middle",
  },
  sectionIcon: {
    fontSize: theme.typography.pxToRem(22),
    strokeWidth: 1.5,
  },
  sectionDescription: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(22.5),
    whiteSpace: "pre-line",
    "& ol, ul": {
      paddingLeft: theme.spacing(2.75),
      margin: 0,
    },
    "& a": {
      color: theme.palette.blue[600],
      textDecoration: "none",
    },
    "& strong, b": {
      fontWeight: 600,
    },
  },
  filterContainer: {
    marginTop: theme.spacing(1.5),
  },
}));
