import React from "react";
import { LabelDisplayedRowsArgs } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";

// Note: When introducing support for other languages, replace this with modified locale
// https://material-ui.com/guides/localization/#localization
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/locale/index.ts
export function FormatLabelDisplayedRows({
  from,
  to,
  count,
}: LabelDisplayedRowsArgs): JSX.Element {
  const { t } = useTranslation("common");

  return (
    <span data-testid="pagination-label-displayed-rows">
      {count !== -1
        ? t("components.paginationWithTotal", { from, to, total: count })
        : t("components.pagination", { from, to })}
    </span>
  );
}
