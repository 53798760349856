import React from "react";
import {
  TimeZoneContext,
  useDateFormatter,
  useTranslation,
  Button,
  Typography,
} from "@lumar/shared";
import { Paper } from "@material-ui/core";

export function ActiveSchedulePanel({
  scheduleTime,
  disabled,
  onChange,
  onRemove,
  className,
}: {
  scheduleTime: Date;
  disabled: boolean;
  onChange: () => void;
  onRemove: () => void;
  className?: string;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");

  const { timeZone } = React.useContext(TimeZoneContext);
  const dateFormatter = useDateFormatter();

  return (
    <Paper className={className} style={{ padding: "8px", width: "400px" }}>
      <Typography variant="subtitle1">{t("settings.nextCrawl")}</Typography>
      <Typography variant="subtitle2Medium" data-testid="crawl-schedule-time">
        {`${dateFormatter(scheduleTime, {
          dateStyle: "medium",
          timeStyle: "short",
        })} ${timeZone?.name || ""}`}
      </Typography>
      <div style={{ display: "flex", marginTop: "8px", marginLeft: "auto" }}>
        <Button
          style={{ marginLeft: "auto", marginRight: "8px" }}
          onClick={onChange}
          disabled={disabled}
          data-testid="crawl-schedule-time-change"
          variant="outlined"
          size="small"
        >
          {t("settings.changeSchedule")}
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={onRemove}
          disabled={disabled}
          data-testid="crawl-schedule-time-remove"
        >
          {t("settings.removeSchedule")}
        </Button>
      </div>
    </Paper>
  );
}
