import Alert from "@material-ui/lab/Alert";
import { Collapse, makeStyles } from "@material-ui/core";
import { useSession, useTranslation } from "@lumar/shared";
import { LevelLimitReasons } from "./reasons/LevelLimitReasons";
import { CreditLimitReasons } from "./reasons/CreditLimitReasons";
import { getHighestLevel } from "../../_common/running-crawl-query-utils";
import { RunningCrawl } from "../../../useProjectPoller";
import { addHours } from "date-fns";
import { PauseReason, RoleCode } from "../../../../graphql";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: 15,
    color: theme.palette.yellow[900],
    backgroundColor: theme.palette.yellow[100],
    alignItems: "center",
  },
  icon: { color: theme.palette.yellow[500] },
}));

export function PauseReasons({
  runningCrawl,
}: {
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const classes = useStyles();
  const { hasSufficientRole } = useSession();
  const isAdmin = hasSufficientRole(RoleCode.Admin);
  const reason = runningCrawl?.pauseReason;
  const sources = runningCrawl?.sources;
  const crawlHighestLevel = getHighestLevel(runningCrawl?.crawlStat);
  const { t } = useTranslation("crawlProgress");

  const finalizationDate = addHours(
    new Date(runningCrawl?.updatedAt ?? ""),
    72,
  );

  function getMessage(): React.ReactNode {
    switch (reason) {
      case PauseReason.CreditLimit:
        return (
          <CreditLimitReasons
            isAdmin={isAdmin}
            finalizationDate={finalizationDate}
          />
        );
      case PauseReason.LevelLimit:
        return (
          <LevelLimitReasons
            sources={sources}
            crawlHighestLevel={crawlHighestLevel}
            finalizationDate={finalizationDate}
          />
        );
      case PauseReason.UrlLimit:
        return t("pauseReasons.UrlLimit", {
          value: finalizationDate,
        });
      case PauseReason.Manual:
        return t("pauseReasons.Manual", {
          value: finalizationDate,
        });
      default:
        return t("pauseReasons.generic", {
          reason,
          value: finalizationDate,
        });
    }
  }

  return (
    <Collapse in={!!reason}>
      {reason ? (
        <Alert
          className={classes.alert}
          severity="warning"
          classes={{ icon: classes.icon }}
        >
          {getMessage()}
        </Alert>
      ) : null}
    </Collapse>
  );
}
