import {
  makeStyles,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  listItems: (props: { totalRows?: number | null }) => ({
    padding: "4px 3px 4px 6px",
    color: props.totalRows === 0 ? "#949494" : "inherit",
  }),
}));

export function SearchSideBarItem(props: {
  name: string;
  href: string;
  totalRows: number;
}): JSX.Element {
  const classes = useStyles({ totalRows: props.totalRows });
  return (
    <Tooltip
      placement="right-end"
      arrow
      title={props.totalRows ? `${props.name}: ${props.totalRows}` : ""}
    >
      <ListItem
        button
        className={classes.listItems}
        component={Link}
        data-testid="report-search-side-bar-item"
        to={props.href}
      >
        <ListItemText>
          <Typography variant="body2">{props.name}</Typography>
        </ListItemText>
      </ListItem>
    </Tooltip>
  );
}
