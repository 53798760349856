import { useSession, useTranslation, Typography } from "@lumar/shared";
import { Box, Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { useParams } from "react-router-dom";
import { RoleCode } from "../../../graphql";
import { translationNamespace } from "../CrawlSettings";
import { useAccountsRoutes } from "../../../_common/routing/accountsRoutes";

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface AddonAccessProps {
  addonCode: string;
  addonName: string;
  message: string;
  feature?: string;
  children: React.ReactNode;
}

export function AddonAccess({
  addonCode,
  addonName,
  message,
  children,
}: AddonAccessProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(translationNamespace);
  const session = useSession();
  const { accountId } = useParams<{ accountId: string }>();
  const accountsRoutes = useAccountsRoutes();

  if (!session.hasAddon(addonCode))
    return (
      <Box mt={2}>
        <Typography variant="subtitle2Medium" className={classes.indent}>
          {t("addonAccess.title", { addonName })}
        </Typography>
        <Typography variant="caption" className={classes.indent}>
          {message}
        </Typography>
        <br />
        {session.hasSufficientRole(RoleCode.Admin) ? (
          <Button
            onClick={() => {
              window.location.href = accountsRoutes.Subscription.getUrl({
                accountId,
              });
            }}
            size="small"
            color="primary"
            variant="contained"
            className={classes.indent}
          >
            {t("addonAccess.request", { addonName })}
          </Button>
        ) : (
          <Typography className={clsx(classes.indent, classes.bold)}>
            {t("addonAccess.description")}
          </Typography>
        )}
      </Box>
    );

  return <>{children}</>;
}
