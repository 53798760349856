import { DocumentNode, gql } from "@lumar/shared";
import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import { generateQuery } from "../generateMetricFields";
import { OrderDirection, ReportTypeCode } from "../../../graphql";
import { DataExplorerTableConfig } from "../../types";
import { FragmentParams } from "../expanded-data/getExpandedRows";
import { PATH_ALIAS, getPathComponents } from "./usePathData";

export function getPathDocument(
  crawlId: string,
  tableConfig: DataExplorerTableConfig,
  fragments: FragmentParams[],
): DocumentNode {
  const firstColumn = tableConfig.columns[0];
  function capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  const metricFields = {
    ...generateQuery(tableConfig, false),
    max: {
      folderCount: true,
    },
  };

  const orderByColumn = `${firstColumn.aggregationCalculation}${capitalize(
    firstColumn.metric.code,
  )}`;

  const query = {
    query: {
      __name: "getAggregateData",
      report: {
        __aliasFor: "getReportStat",
        __args: {
          input: {
            crawlId,
            reportTemplateCode: "all_pages",
          },
        },
        ...Object.fromEntries(
          fragments.map((fragment, idx) => {
            if (!fragment.values.length) {
              return [
                `${PATH_ALIAS}${idx}`,
                {
                  __aliasFor: "crawlUrlsAggregates",
                  __args: {
                    reportType: new EnumType(ReportTypeCode.Basic),
                    dimensions: [new EnumType("path0"), new EnumType("path1")],
                    orderBy: {
                      field: new EnumType(orderByColumn),
                      direction: new EnumType("DESC"),
                    },
                  },
                  nodes: {
                    path0: true,
                    path1: true,
                    ...metricFields,
                  },
                },
              ];
            }

            const dimension = `path${fragment.values.length + 1}`;
            return [
              `${PATH_ALIAS}${idx}`,
              {
                __aliasFor: "crawlUrlsAggregates",
                __args: {
                  reportType: new EnumType(ReportTypeCode.Basic),
                  dimensions: [new EnumType(dimension)],
                  filter: {
                    ...Object.fromEntries(
                      getPathComponents(fragment.values).map((path, idx) => [
                        `path${idx}`,
                        { eq: path },
                      ]),
                    ),
                    [dimension]: { isEmpty: false },
                  },
                  orderBy: [
                    {
                      field: new EnumType(orderByColumn),
                      direction: new EnumType("DESC"),
                    },
                    {
                      field: new EnumType(dimension),
                      direction: new EnumType(OrderDirection.Asc),
                    },
                  ],
                  first: 100,
                  ...(fragment.cursor ? { after: fragment.cursor } : {}),
                },
                nodes: {
                  [dimension]: true,
                  ...metricFields,
                },
                pageInfo: {
                  endCursor: true,
                  hasNextPage: true,
                },
              },
            ];
          }),
        ),
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}
