import {
  ReportsTableConfigItem,
  GenerateReportsTableConfigProps,
} from "../../../crawl-overview/dashboard/config/types";

export function getSiteSpeedErrorsTable({
  theme,
  t,
}: GenerateReportsTableConfigProps): ReportsTableConfigItem {
  return {
    columns: [
      { predefinedColumn: "categoryIcon" },
      { predefinedColumn: "categoryName" },
      { predefinedColumn: "impact" },
      { predefinedColumn: "name" },
      { predefinedColumn: "total" },
      { predefinedColumn: "trend" },
      { predefinedColumn: "change" },
      {
        field: "savingsScore",
        header: t("siteSpeedErrors.savingsScore"),
        align: "right",
        maxFractionDigits: 0,
        exclude: true,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "sum",
            metricCode: "savingsScore",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "sum",
            metricCode: "savingsScoreSum",
          },
        },
      },
      {
        field: "urlCount",
        header: t("siteSpeedErrors.urlCount"),
        align: "right",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "count",
            metricCode: "itemsCount",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "sum",
            metricCode: "urlCount",
          },
        },
      },
      {
        field: "timeSavings",
        header: t("siteSpeedErrors.timeSavings"),
        align: "right",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "savingsSecs",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "wastedSecsAvg",
          },
        },
      },
      {
        field: "sizeSavings",
        header: t("siteSpeedErrors.sizeSavings"),
        align: "right",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "savingsKib",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "wastedKibAvg",
          },
        },
      },
      {
        field: "layoutShift",
        header: t("siteSpeedErrors.layoutShift"),
        align: "center",
        maxFractionDigits: 2,
        bold: true,
        color: theme.palette.green[600],
        value: {
          siteSpeedAudits: {
            aggregate: "avg",
            metricCode: "metricSavingsCls",
          },
          siteSpeedAuditOpportunities: {
            aggregate: "avg",
            metricCode: "layoutShiftImpactAvg",
          },
        },
      },
      // {
      //   field: "pageviews",
      //   header: t("siteSpeedErrors.pageviews"),
      //   align: "center",
      //   maxFractionDigits: 2,
      //   bold: true,
      //   color: theme.palette.green[600],
      //   value: {
      //     siteSpeedAudits: {
      //       aggregate: "avg",
      //       metricCode: "pageviews",
      //     },
      //     siteSpeedAuditOpportunities: {
      //       aggregate: "avg",
      //       metricCode: "gaPageviewsSum",
      //     },
      //   },
      // },
      // {
      //   field: "gscClicks",
      //   header: t("siteSpeedErrors.gscClicks"),
      //   align: "center",
      //   maxFractionDigits: 2,
      //   bold: true,
      //   color: theme.palette.green[600],
      //   value: {
      //     siteSpeedAudits: {
      //       aggregate: "avg",
      //       metricCode: "gscClicks",
      //     },
      //     siteSpeedAuditOpportunities: {
      //       aggregate: "avg",
      //       metricCode: "gscClicksSum",
      //     },
      //   },
      // },
      { predefinedColumn: "weightedTotal", hide: true },
      { predefinedColumn: "weightedChange", hide: true },
    ],
    orderBy: {
      field: "savingsScore",
      direction: "desc",
    },
  };
}
