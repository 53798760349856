import React from "react";
import { useHistory, useLocation } from "react-router";

export interface ExtendedURLSearchParams {
  get(name: string): string | null;
  set(name: string, value: string): void;
  delete(name: string): void;
  toString(): string;
  has(name: string): boolean;
  navigate(): void;
  apply(): void;
}

export function useURLSearchParams(): ExtendedURLSearchParams {
  const location = useLocation();
  const history = useHistory();
  return React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    return {
      get(name: string) {
        return urlSearchParams.get(name);
      },
      set(name: string, value: string) {
        return urlSearchParams.set(name, value);
      },
      delete(name: string) {
        return urlSearchParams.delete(name);
      },
      has(name: string) {
        return urlSearchParams.has(name);
      },
      toString() {
        return urlSearchParams.toString();
      },
      navigate() {
        history.push({
          search: urlSearchParams.toString(),
        });
      },
      apply() {
        history.replace({
          search: urlSearchParams.toString(),
        });
      },
    };
  }, [location.search, history]);
}
