/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityLevelsChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityLevelsChart.title"),
    description: () => t("accessibilityLevelsChart.description"),
    reportTemplateCodesOrder: [
      "wcag_a_issues",
      "wcag_aa_issues",
      "wcag_aaa_issues",
      "best_practices_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "wcag_a_issues",
        "wcag_aa_issues",
        "wcag_aaa_issues",
        "best_practices_issues",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
    testAttributePrefix: "a11y-levels-chart",
  };
}
