import { ReportCategoryChartConfig } from "../../types";
import { getAccessibilityAriaAChart } from "../../../../../_common/charts/config/getAccessibilityAriaAChart";
import { getAccessibilityAriaATrend } from "../../../../../_common/charts/config/getAccessibilityAriaATrend";

export const generateAccessibilityAriaACategoryItems: ReportCategoryChartConfig =
  {
    mainCharts: [
      { predefinedChartType: "topChanges" },
      getAccessibilityAriaAChart,
      getAccessibilityAriaATrend,
    ],
  };
