/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedCLSLayoutShiftElements(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedCLSLayoutShiftElements.title"),
    description: () => t("siteSpeedCLSLayoutShiftElements.description"),
    reportTemplateCodesOrder: [
      "layout_shift_elements_failed_items",
      "layout_shift_elements_failed_audits",
    ],
    reportStatFilter: (report) => {
      return [
        "layout_shift_elements_failed_items",
        "layout_shift_elements_failed_audits",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
