import { MetricsValuePresenterProps } from "../../data/types";
import { TextMetricLengthInCharacters } from "./text-measurement/TextMetricLengthInCharacters";
import { TextMetricLengthInPixels } from "./text-measurement/TextMetricLengthInPixels";

interface Props extends MetricsValuePresenterProps {
  length?: number;
  minLength?: number;
  maxLength?: number;
  width?: number;
  maxWidth?: number;
}

export function TextLimitPresenter({
  value,
  length,
  minLength,
  maxLength,
  width,
  maxWidth,
}: Props): JSX.Element {
  if (!value) return <>-</>;

  return (
    <>
      {value}
      <br />
      {length !== undefined && (
        <TextMetricLengthInCharacters
          count={length}
          min={minLength}
          max={maxLength}
        />
      )}
      {width !== undefined && (
        <TextMetricLengthInPixels px={width} max={maxWidth} />
      )}
    </>
  );
}
