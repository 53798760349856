import { Typography, useDateFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { isDate } from "lodash";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  crawlDate: {
    color: theme.palette.grey[600],
  },
}));

interface DateCellProps {
  params: ProjectsGridRenderCellParams;
  "data-testid"?: string;
}

export function DateCell({
  params: { value },
  "data-testid": dataTestId,
}: DateCellProps): JSX.Element {
  const classes = useStyles();
  const dateFormatter = useDateFormatter();

  return (
    <Typography className={classes.crawlDate} data-testid={dataTestId}>
      {isDate(value) &&
        dateFormatter(value, {
          dateStyle: "medium",
          timeStyle: "medium",
        })}
    </Typography>
  );
}
