import { CrawlSourcesIcons } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";
import { FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP } from "../../../_common/constants/found-in-metric-to-crawl-type";
import { CrawlType } from "../../../graphql";

export function SourcePresenter({
  metrics,
  metricCodes,
}: MetricsValuePresenterProps & { metricCodes?: string[] }): JSX.Element {
  const crawlTypes: [string, CrawlType][] = (
    metricCodes || [...FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP.keys()]
  ).map((metricCode) => [
    metricCode,
    FOUND_IN_METRIC_CODE_TO_CRAWL_TYPE_MAP.get(metricCode) || CrawlType.Web,
  ]);

  const sources: Parameters<typeof CrawlSourcesIcons>[0]["sources"] = crawlTypes
    .filter(([code]) => metrics[code])
    .map(([code, type]) => ({
      code: type,
      name: metrics[code].name,
      active: Boolean(metrics[code].value),
    }));

  return <CrawlSourcesIcons sources={sources} />;
}
