import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ProjectUploadType } from "../../../../graphql";
import { REGEX_PATTERNS } from "../../../../_common/constants/regex-patterns.constant";

export function useFileUploadSchema(): unknown {
  const { t } = useTranslation("crawlSettings");

  return Yup.object().shape({
    baseDomain: Yup.string().matches(
      new RegExp(REGEX_PATTERNS.url),
      t("sources.manualUpload.baseDomainValidation"),
    ),
    metrics: Yup.array()
      .of(Yup.object())
      .when("uploadType", {
        is: (uploadType: ProjectUploadType) =>
          uploadType === ProjectUploadType.Custom,
        then: (schema) =>
          schema.test(
            "URL metric",
            t("sources.manualUpload.urlError"),
            (value) =>
              Boolean(
                value?.find(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (x: any) => x.code === "url" && x.index !== undefined,
                ),
              ),
          ),
      }),
  });
}
