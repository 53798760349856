import { ApolloError, useApolloClient } from "@lumar/shared";
import React from "react";
import { DataExplorerRow, DataExplorerTableConfig } from "../../types";
import { useExpandedRows } from "../expanded-data/useExpandedRows";
import {
  ExpandedRowsProps,
  ExpandedRowsResult,
  FragmentData,
  getExpandedRows,
} from "../expanded-data/getExpandedRows";
import { createConnectionFilter } from "../../../_common/connection-filtering/createConnectionFilter";
import { formatMetrics } from "../formatMetrics";
import { getPathDocument } from "./getPathDocument";
import { useExpandedDataState } from "../expanded-data/useExpandedDataState";

export const PATH_ALIAS = "expandedPaths";
const FETCH_LIMIT = 30;
const PATH_DEPTH_LIMIT = 10;

interface Props {
  crawlId: string;
  tableConfig: DataExplorerTableConfig;
}

interface Result {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
}

export function usePathData({ crawlId, tableConfig }: Props): Result {
  const client = useApolloClient();

  const [{ loading, error, data }, updateState] = useExpandedDataState(crawlId);

  const {
    root: expandedRows,
    addRow,
    loadMoreRow,
    removeRow,
  } = useExpandedRows({
    localSotrageKey: "data-explorer-expanded-paths",
    columnConfig: JSON.stringify(tableConfig.columns),
  });

  const { rows, fragmentsToFetch: allFragmentsToFetch } = React.useMemo(() => {
    return getPathRows({
      data,
      expandedRows: {
        root: expandedRows,
        addRow,
        loadMoreRow,
        removeRow,
      },
      tableConfig,
    });
  }, [data, expandedRows, tableConfig, addRow, loadMoreRow, removeRow]);

  React.useEffect(() => {
    if (loading || error) return;

    const fragmentsToFetch = allFragmentsToFetch
      .filter((x) => x.values.length < PATH_DEPTH_LIMIT - 1)
      .slice(0, FETCH_LIMIT);

    if (!fragmentsToFetch.length) return;

    updateState({
      loading: true,
      data: fragmentsToFetch.map(({ values, cursor }) => [
        getFragmentId(values, cursor),
        { loading: true },
      ]),
    });

    (async function fetchPaths() {
      try {
        const sessionQuery = client.watchQuery({
          query: getPathDocument(crawlId, tableConfig, fragmentsToFetch),
          errorPolicy: "all",
        });
        await sessionQuery.result();
        const result = sessionQuery.getCurrentResult();

        updateState({
          loading: false,
          error: result.errors?.length
            ? new ApolloError({ graphQLErrors: result.errors })
            : undefined,
          data: Object.entries(result.data.report)
            .filter(([key]) => key.startsWith(PATH_ALIAS))
            .map(([key, data]) => {
              const idx = Number(key.slice(PATH_ALIAS.length));
              const pathId = getFragmentId(
                fragmentsToFetch[idx].values,
                fragmentsToFetch[idx].cursor,
              );

              return [pathId, { ...(data as FragmentData), loading: false }];
            }),
        });
      } catch (error) {
        updateState({
          loading: false,
          error: error as ApolloError,
          data: fragmentsToFetch.map(({ values, cursor }) => [
            getFragmentId(values, cursor),
            { loading: false },
          ]),
        });
      }
    })();
  }, [
    crawlId,
    tableConfig,
    loading,
    error,
    client,
    data,
    expandedRows,
    allFragmentsToFetch,
    updateState,
  ]);

  return {
    loading: loading && !error && !data[""].nodes.length,
    error,
    rows,
  };
}

function getFragmentId(values: string[], cursor?: string): string {
  const path = values.join("") || "";
  return cursor ? `${path}:${cursor}` : path;
}

export function getPathComponents(paths?: string[]): string[] {
  return (
    paths?.flatMap((path, idx) => {
      const index = idx === 0 ? path.indexOf("//") : -1;
      if (index === -1) {
        return [path];
      }

      const protocol = path.substring(0, index + 2);
      const domain = path.substring(index + 2);
      return [protocol, domain];
    }) || []
  );
}

function getPathRows(
  props: Pick<ExpandedRowsProps, "data" | "expandedRows"> & {
    tableConfig: DataExplorerTableConfig;
  },
): ExpandedRowsResult {
  const metrics = props.tableConfig.columns.map((x) => [
    x.aggregationCalculation,
    x.metric.code,
  ]);

  return getExpandedRows({
    ...props,
    getFragmentId,
    shouldRefetchFragment: (data) =>
      Boolean(
        data[0] &&
          metrics.find(([aggregate, metric]) => {
            const breadcrumbRow = data[0];
            return breadcrumbRow?.[aggregate]?.[metric] === undefined;
          }),
      ),
    getNodeValue: (data) => {
      function getValue(): string {
        if (data.path1) return `${data.path0}${data.path1}`;
        return Object.entries(data).find((x) =>
          x[0].startsWith("path"),
        )?.[1] as string;
      }
      return {
        value: getValue(),
        maxDepth: Math.min(data.max?.folderCount || 0, PATH_DEPTH_LIMIT - 1),
      };
    },
    formatMetrics: ({ data, value, values }) => ({
      path: value,
      getFilter: () =>
        createConnectionFilter({
          or: [
            {
              and: getPathComponents(values).map((path, idx) => ({
                metricCode: `path${idx}`,
                predicateKey: "eq",
                predicateValue: path,
              })),
            },
          ],
        }),
      ...formatMetrics(data),
    }),
  });
}
