import { Chip } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
  text: {
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
  },
  grayedOut: {
    color: theme.palette.grey[500],
  },
}));

export function ChangedFromPresenter({
  value,
  grayedOut,
}: MetricsValuePresenterProps & { grayedOut?: boolean }): JSX.Element {
  const classes = useStyles();

  function getValue(value: unknown): string {
    if (!value) return "-";
    if (typeof value === "string") return value;
    return JSON.stringify(value);
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((x, idx) => (
          <Chip
            key={idx}
            label={<s>{getValue(x)}</s>}
            color="lightgrey"
            className={classes.chip}
          />
        ))}
      </>
    );
  }

  const stringValue = getValue(value);
  return (
    <span
      className={clsx({ [classes.text]: true, [classes.grayedOut]: grayedOut })}
    >
      {!stringValue ? "-" : <s>{stringValue}</s>}
    </span>
  );
}
