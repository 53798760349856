import React from "react";
import { ResourceDetailData } from "../../data/types";
import {
  Camera,
  Clock,
  EmptyState,
  Typography,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    padding: 16,
    minHeight: 400,
  },
  head: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingBottom: 8,
    marginBottom: 13,
  },
}));

export function StoredScreenshot({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element | null {
  const classes = useStyles();
  const formatDate = useDateFormatter();
  const { t } = useTranslation("resourceDetail");

  // Making it extension agnostic because I suspect that they might move away from jpeg
  // format to something lighter like webp.
  const screenshotAttachment = data.attachments.find((a) =>
    a.name.startsWith("ScreenshotStoring/screenshot."),
  );

  if (!screenshotAttachment) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedScreenshot.title")}
          </Typography>
        </div>
        <div className={classes.container}>
          <EmptyState
            height={400}
            icon={<Camera />}
            title={t("storedScreenshot.disabledTitle")}
            description={t("storedScreenshot.disabledDescription")}
          />
        </div>
      </div>
    );
  }

  const expiryTimestamp = new Date(screenshotAttachment.expiresAt).getTime();
  const currentTimestamp = Date.now();
  const timeLeft = expiryTimestamp - currentTimestamp;

  if (timeLeft <= 0) {
    return (
      <div>
        <div className={classes.head}>
          <Typography variant="h2SemiBold" component="div">
            {t("storedScreenshot.title")}
          </Typography>
        </div>
        <div className={classes.container}>
          <EmptyState
            height={400}
            icon={<Clock />}
            title={t("storedScreenshot.expiredTitle")}
            description={t("storedScreenshot.expiredDescription")}
          />
        </div>
      </div>
    );
  }

  const expiryDateText = formatDate(expiryTimestamp, {
    timeStyle: "short",
    dateStyle: "medium",
  });

  return (
    <div>
      <div className={classes.head}>
        <Typography variant="h2SemiBold" component="div">
          {t("storedScreenshot.title")}
        </Typography>
        <Typography
          variant="caption"
          style={{ marginLeft: "auto" }}
          component="div"
        >
          {t("storedScreenshot.expiryText")}{" "}
          <Typography component="span" variant="captionBold">
            {expiryDateText}
          </Typography>
        </Typography>
      </div>
      <div className={classes.container}>
        <img
          style={{ margin: "auto", display: "block" }}
          src={screenshotAttachment?.signedLocation}
          alt={t("storedScreenshot.alt")}
        />
      </div>
    </div>
  );
}
