import React from "react";
import { makeStyles } from "@material-ui/core";

import { assert } from "../../_common/assert";
import { useGenericParams } from "../../_common/routing/useGenericParams";
import { ProjectAutocompleteField } from "../../_common/project-autocomplete/ProjectAutocomplete";
import { NarrowAutocompleteProject } from "../../_common/project-autocomplete/types";
import { useTranslation } from "react-i18next";
import { ModuleCode } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "360px",
    marginTop: theme.spacing(1),
  },
}));

interface ProjectDestinationSelectProps {
  name: string;
  value: NarrowAutocompleteProject | null;
  touched?: boolean;
  error?: string;
  moduleCode?: ModuleCode;
}

export function ProjectDestinationSelect({
  name,
  moduleCode,
}: ProjectDestinationSelectProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("copySegmentDialog");
  const { accountId } = useGenericParams();
  assert(accountId);

  return (
    <ProjectAutocompleteField
      name={name}
      accountId={accountId}
      includeTestSuites
      className={classes.input}
      moduleCode={moduleCode}
      inputProps={{
        label: t("project"),
        placeholder: t("projectPlaceholder"),
        inputProps: {
          id: "project-destination",
          "data-testid": "project-destination-input",
        },
      }}
      data-testid="project-destination-select"
    />
  );
}
