/* eslint-disable fp/no-mutating-methods */
import { DataExplorerRow } from "../../types";
import { ExpandedRow } from "./useExpandedRows";
import { PageInfo } from "../../../graphql";

export interface FragmentParams {
  values: string[];
  cursor?: string;
}

export interface FragmentData {
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: Record<string, any>[];
  pageInfo?: Pick<PageInfo, "hasNextPage" | "endCursor">;
}

export interface ExpandedRowsProps {
  data: Record<string, FragmentData>;
  expandedRows: {
    root: ExpandedRow;
    addRow: (values: string[]) => void;
    loadMoreRow: (values: string[]) => void;
    removeRow: (values: string[]) => void;
  };
  getFragmentId: (values: string[], cursor?: string) => string;
  shouldRefetchFragment: (data: FragmentData["nodes"]) => boolean;
  getNodeValue: (data: FragmentData["nodes"][0]) => {
    value: string;
    maxDepth: number;
  };
  formatMetrics: (props: {
    data: FragmentData["nodes"][0];
    value: string;
    values: string[];
  }) => Record<string, unknown>;
}

export type ExpandedRowsResult = {
  rows: DataExplorerRow[];
  fragmentsToFetch: FragmentParams[];
};

export function getExpandedRows({
  data,
  expandedRows: { root, addRow, loadMoreRow, removeRow },
  getFragmentId,
  shouldRefetchFragment,
  getNodeValue,
  formatMetrics,
}: ExpandedRowsProps): ExpandedRowsResult {
  const rows: ExpandedRowsResult["rows"] = [];
  const fragmentsToFetch: ExpandedRowsResult["fragmentsToFetch"] = [];

  function getAllPages(
    row: ExpandedRow,
    values: string[],
    pageIndex: number,
    cursor?: string,
  ): void {
    const fragmentData = (() => {
      if (cursor && pageIndex > row.pageCount) return;
      return data[getFragmentId(values, cursor)];
    })();

    if (cursor && (!fragmentData || fragmentData.loading === true)) {
      rows.push({
        loadMore: () => loadMoreRow(values),
        isMoreLoading: fragmentData?.loading === true,
      });

      if (pageIndex <= row.pageCount && !(fragmentData?.loading === true)) {
        fragmentsToFetch.push({ values, cursor });
      }
      return;
    }

    if (!fragmentData) {
      fragmentsToFetch.push({ values, cursor });
      return;
    }

    if (fragmentData.loading && !fragmentData.nodes.length) {
      rows.push({ isLoading: true });
      return;
    }

    if (shouldRefetchFragment(fragmentData.nodes)) {
      fragmentsToFetch.push({ values, cursor });
    }

    fragmentData.nodes.forEach((node) => {
      const { value, maxDepth } = getNodeValue(node);
      const childValues = [...values, value];

      const expandedRow = row.children.find((x) => x.value === value);

      rows.push({
        expansionLevel: childValues.length,
        hasChildren: maxDepth > childValues.length,
        isExpanded: expandedRow !== undefined,
        setExpanded: (expanded: boolean) =>
          expanded ? addRow(childValues) : removeRow(childValues),
        ...formatMetrics({ data: node, value, values: childValues }),
      });

      if (expandedRow) {
        getAllPages(expandedRow, childValues, 1);
      }
    });

    const fragmentDataCursor =
      (fragmentData.pageInfo?.hasNextPage &&
        fragmentData.pageInfo?.endCursor) ||
      undefined;
    if (fragmentDataCursor) {
      getAllPages(row, values, pageIndex + 1, fragmentDataCursor);
    }
  }

  getAllPages(root, [], 1);

  return { rows, fragmentsToFetch };
}
