import {
  AccountMenuItem,
  AccountMenuItems,
  BatteryHighOutlined,
  ClockOutlined,
  CreditCardOutlined,
  LightningBoltOutlined,
  LocaleOutlined,
  LogoutOutlined,
  OfficeBuildingOutlined,
  PuzzleOutlined,
  TimeZoneContext,
  UserArrowRightOutlined,
  UserGroupOutlined,
  UserOutlined,
  getRawAccountId,
  supportedLanguages,
  useAccountSelectionSubMenu,
  useAdminAccountSearch,
  useBrand,
  useSession,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";
import { RoleCode } from "../../graphql";
import { Routes } from "../routing/routes";

import { insertIf } from "../insertIf";
import { useAccountsRoutes } from "../routing/accountsRoutes";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "20px",
  },
  logout: {
    color: theme.palette.red[500],
  },
}));

interface NavigationMenuItem extends AccountMenuItem {
  minimumRole?: RoleCode;
  availableForSharedLinkUser?: boolean;
}

type NavigationMenuItems = {
  name?: string;
  items: (NavigationMenuItem | undefined)[];
}[];

export function useNavigationMenuItems(): AccountMenuItems {
  const { t, i18n } = useTranslation("navigation");
  const classes = useStyles();
  const brand = useBrand();

  const { accountId } = useParams<{ accountId: string }>();

  const session = useSession();

  const accountsRoutes = useAccountsRoutes();

  const history = useHistory();
  const languageLabel = supportedLanguages.find(
    (lng) => lng.code === i18n.language,
  )?.name;

  const {
    timeZone: activeTimeZone,
    timeZones,
    setTimeZone,
  } = React.useContext(TimeZoneContext);

  // eslint-disable-next-line fp/no-mutating-methods
  const availableAccounts = [...session.allRelationships].sort((a, b) =>
    a.account.name.localeCompare(b.account.name),
  );
  const shouldShowAccountSwitcher =
    session.isDeepCrawlAdmin ||
    Boolean(
      availableAccounts.find(
        ({ account }) => getRawAccountId(account.id) !== accountId,
      ),
    );

  function handleAccountSwitch(id: string): void {
    if (id === accountId) return;
    history.push(
      Routes.Projects.getUrl({
        accountId: id,
      }),
    );
  }

  const accountsSubMenu = useAccountSelectionSubMenu({
    accounts: availableAccounts.map((x) => ({
      id: getRawAccountId(x.account.id),
      name: x.account.name,
    })),
    selectedAccountId: accountId,
    onAccountSelected: handleAccountSwitch,
    isDeepCrawlAdmin: session.isDeepCrawlAdmin,
    searchPlaceholder: t("accountSearch"),
    noResultMessage: t("noAccountFound"),
    formatValue: (id) => t("id", { accountId: id }),
    adminAccountSearch: useAdminAccountSearch(),
    dataAttributes: {
      "data-testid": "account-switcher-option",
    },
  });

  const languageSubMenu: AccountMenuItems = [
    {
      items: supportedLanguages.map((lng) => ({
        name: lng.name,
        checked: i18n.language === lng.code,
        onClick: () => {
          i18n.changeLanguage(lng.code);
        },
      })),
    },
  ];

  const menuItemsGroups: NavigationMenuItems = [
    {
      items: [
        shouldShowAccountSwitcher
          ? {
              name: t("switchAccount"),
              icon: <UserArrowRightOutlined className={classes.icon} />,
              minimumRole: RoleCode.Viewer,
              dataAttributes: { "data-testid": "account-switcher" },
              subMenu: accountsSubMenu,
            }
          : undefined,
      ],
    },
    {
      name: t("userSettings"),
      items: [
        ...insertIf(brand.featureAvailability.myProfilePage, {
          name: t("user"),
          icon: <UserOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-user" },
          link: accountsRoutes.User.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
        {
          name: t("language"),
          icon: <LocaleOutlined className={classes.icon} />,
          value: languageLabel,
          dataAttributes: { "data-pendo": "c-headernav-accounts-language" },
          minimumRole: RoleCode.Viewer,
          subMenu: languageSubMenu,
        },
        {
          name: t("timezone"),
          value: activeTimeZone.name,
          icon: <ClockOutlined className={classes.icon} />,
          subMenu: [
            {
              items: timeZones.map((timeZone) => ({
                name: timeZone.name,
                checked: timeZone.code === activeTimeZone.code,
                onClick: () => {
                  setTimeZone(timeZone.code);
                },
              })),
              height: 333,
              search: {
                placeholder: t("timezoneSearch"),
                noResultMessage: t("noTimezoneFound"),
              },
            },
          ],
          minimumRole: RoleCode.Viewer,
        },
        {
          name: t("connectedApps"),
          icon: <PuzzleOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "c-headernav-accounts-connectedapps",
          },
          link: accountsRoutes.ConnectedApps.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Editor,
        },
        ...insertIf(brand.featureAvailability.apiAccessPage, {
          name: t("apiAccess"),
          icon: <LightningBoltOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-api" },
          link: accountsRoutes.ApiAccess.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        }),
      ],
    },
    {
      name: t("accountSettings"),
      items: [
        {
          name: t("account"),
          icon: <OfficeBuildingOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-account" },
          link: accountsRoutes.Account.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Viewer,
        },
        ...insertIf(brand.featureAvailability.teamsPage, {
          name: t("team"),
          icon: <UserGroupOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-org" },
          link: accountsRoutes.Team.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        }),
        {
          name: t("subscription"),
          icon: <CreditCardOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-subscription" },
          link: accountsRoutes.Subscription.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
        {
          name: t("creditUsage"),
          icon: <BatteryHighOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-credit" },
          link: accountsRoutes.CreditUsage.getUrl({ accountId }),
          isLinkExternal: true,
          minimumRole: RoleCode.Admin,
        },
      ],
    },
    {
      items: [
        {
          name: t("logOut"),
          icon: (
            <LogoutOutlined className={clsx(classes.icon, classes.logout)} />
          ),
          dataAttributes: {
            "data-testid": "logout-link",
            "data-pendo": "c-headernav-accounts-logout",
          },
          onClick: () => {
            session.invalidateSession();
          },
          minimumRole: RoleCode.Viewer,
          availableForSharedLinkUser: false,
        },
      ],
    },
  ];

  function filterByRole(
    item: NavigationMenuItem | undefined,
  ): item is NavigationMenuItem {
    if (item === undefined) return false;
    return session.isUsingSharedLink
      ? (item.availableForSharedLinkUser ?? false)
      : session.hasSufficientRole(item.minimumRole);
  }

  return menuItemsGroups.reduce((result, group) => {
    const items = group.items.filter(filterByRole);
    return items.length ? [...result, { ...group, items }] : result;
  }, [] as AccountMenuItems);
}
