import { useContext } from "react";
import { ApolloCache, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";

import {
  AddGoogleSearchConsolePropertyMutation,
  DeleteGoogleSearchConsolePropertyMutation,
  GetProjectGoogleSearchConsolePropertiesDocument,
  GetProjectGoogleSearchConsolePropertiesQuery,
  useAddGoogleSearchConsolePropertyMutation,
  useDeleteGoogleSearchConsolePropertyMutation,
  useGetAccountMaxProjectGoogleSearchConsolePropertiesQuery,
  useGetProjectGoogleSearchConsolePropertiesQuery,
} from "../../../../graphql";
import { SourcesContext } from "../data/useSourcesFormValues";

export interface GoogleSearchConsolePropertyValues {
  id: string;
  connectionId: string;
  connectionName: string;
  siteUrl: string;
  enabled: boolean;
}

export interface GoogleSearchConsolePropertiesResult {
  enabledProperties: GoogleSearchConsolePropertyValues[];
  disabledProperties: GoogleSearchConsolePropertyValues[];
  maxProperties: number;
  loading: boolean;
  setStatus: (
    property: GoogleSearchConsolePropertyValues,
    enabled: boolean,
  ) => void;
  addAccount: () => void;
}

export function useGoogleSearchConsoleProperties(): GoogleSearchConsolePropertiesResult {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const { t } = useTranslation("crawlSettings");
  const context = useContext(SourcesContext);

  const {
    data: maxProjectGSCPropertiesData,
    loading: maxProjectGSCPropertiesLoading,
  } = useGetAccountMaxProjectGoogleSearchConsolePropertiesQuery({
    variables: {
      accountId,
    },
    fetchPolicy: "cache-first",
    onError: () => context.onError(t("message.apiErrorTitleInit")),
  });

  const { data, loading } = useGetProjectGoogleSearchConsolePropertiesQuery({
    variables: {
      projectId,
    },
    fetchPolicy: "cache-first",
    onError: () => context.onError(t("message.apiErrorTitleInit")),
  });

  const enabledProperties: GoogleSearchConsolePropertyValues[] =
    data?.getProject?.googleSearchConsoleProperties?.nodes?.map((item) => ({
      id: item.id,
      connectionId: item.googleConnection.id,
      connectionName: item.googleConnection.name,
      siteUrl: item.siteUrl,
      enabled: true,
    })) ?? [];
  const disabledProperties: GoogleSearchConsolePropertyValues[] =
    data?.me.googleConnections.nodes.flatMap(
      (connection) =>
        connection.searchConsoleSites
          ?.filter((p) => {
            const isEnabled = Boolean(
              enabledProperties.find(
                (site) =>
                  p.siteUrl === site.siteUrl &&
                  connection.name === site.connectionName,
              ),
            );
            return !isEnabled && p.permissionLevel !== "siteUnverifiedUser";
          })
          .map((p) => ({
            id: "",
            connectionId: connection.id,
            connectionName: connection.name,
            siteUrl: p.siteUrl ?? "",
            enabled: false,
          })) ?? [],
    ) ?? [];

  const [addProperty] = useAddGoogleSearchConsolePropertyMutation({
    onError: () => context.onError(t("message.apiErrorEnableGSCSite")),
  });

  const [removeProperty] = useDeleteGoogleSearchConsolePropertyMutation({
    onError: () => context.onError(t("message.apiErrorDisableGSCSite")),
  });

  const setStatus = (
    property: GoogleSearchConsolePropertyValues,
    enabled: boolean,
  ): void => {
    if (enabled) {
      addProperty({
        variables: {
          projectId,
          googleConnectionId: property.connectionId,
          siteUrl: property.siteUrl,
        },
        optimisticResponse: {
          createGoogleSearchConsoleProperty: {
            googleSearchConsoleProperty: {
              id: "",
              siteUrl: property.siteUrl,
              googleConnection: {
                id: property.connectionId,
                name: property.connectionName,
              },
              __typename: "GoogleSearchConsoleProperty",
            },
          },
        },
        update: (cache, { data }) => updateSiteEnabled(cache, data, projectId),
      });
    } else {
      removeProperty({
        variables: {
          googleSearchConsolePropertyId: property.id,
        },
        optimisticResponse: {
          deleteGoogleSearchConsoleProperty: {
            googleSearchConsoleProperty: {
              id: property.id,
              __typename: "GoogleSearchConsoleProperty",
            },
          },
        },
        update: (cache, { data }) => updateSiteDisabled(cache, data, projectId),
      });
    }
  };

  return {
    enabledProperties,
    disabledProperties,
    maxProperties:
      maxProjectGSCPropertiesData?.getAccount
        ?.maxGoogleSearchConsolePropertiesPerProject ?? 50,
    loading: loading || maxProjectGSCPropertiesLoading,
    setStatus,
    addAccount: context.addGoogleConnection,
  };
}

function updateSiteEnabled(
  cache: ApolloCache<AddGoogleSearchConsolePropertyMutation>,
  data: AddGoogleSearchConsolePropertyMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetProjectGoogleSearchConsolePropertiesQuery | null =
    cache.readQuery({
      query: GetProjectGoogleSearchConsolePropertiesDocument,
      variables: { projectId },
    });
  if (!cachedData?.getProject) return;

  const newProperty =
    data?.createGoogleSearchConsoleProperty?.googleSearchConsoleProperty;

  cache.writeQuery({
    query: GetProjectGoogleSearchConsolePropertiesDocument,
    variables: { projectId: projectId },
    data: {
      ...cachedData,
      getProject: {
        ...cachedData.getProject,
        googleSearchConsoleProperties: {
          nodes: [
            ...(cachedData.getProject.googleSearchConsoleProperties?.nodes ??
              []),
            {
              id: newProperty?.id,
              siteUrl: newProperty?.siteUrl,
              googleConnection: {
                id: newProperty?.googleConnection?.id,
                name: newProperty?.googleConnection?.name,
              },
            },
          ],
        },
      },
    },
  });
}

function updateSiteDisabled(
  cache: ApolloCache<DeleteGoogleSearchConsolePropertyMutation>,
  data: DeleteGoogleSearchConsolePropertyMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetProjectGoogleSearchConsolePropertiesQuery | null =
    cache.readQuery({
      query: GetProjectGoogleSearchConsolePropertiesDocument,
      variables: { projectId },
    });
  if (!cachedData?.getProject) return;

  const properties =
    cachedData.getProject.googleSearchConsoleProperties?.nodes ?? [];
  const index = properties.findIndex(
    (x) =>
      x.id ===
      data?.deleteGoogleSearchConsoleProperty.googleSearchConsoleProperty.id,
  );

  cache.writeQuery({
    query: GetProjectGoogleSearchConsolePropertiesDocument,
    variables: { projectId: projectId },
    data: {
      ...cachedData,
      getProject: {
        ...cachedData.getProject,
        googleSearchConsoleProperties: {
          nodes: [
            ...properties.slice(0, index),
            ...properties.slice(index + 1),
          ],
        },
      },
    },
  });
}
