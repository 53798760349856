const metricsToExcludeFromAll = ["rawHeader"];
const metricsToExcludeFromChanged = [
  "crawlDatetime",
  ...metricsToExcludeFromAll,
];

export function shouldShowInAllMetric(code: string): boolean {
  return !metricsToExcludeFromAll.includes(code);
}

export function shouldShowInChangedMetric(code: string): boolean {
  return !metricsToExcludeFromChanged.includes(code);
}
