var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Alert as MuiAlert, } from "@material-ui/lab";
import { withStyles } from "@material-ui/core";
import { CheckSquare, Info, Warning, WarningCircle } from "../../icons";
import clsx from "clsx";
function AlertInner(_a) {
    var { size = "large", classes = {} } = _a, alertProps = __rest(_a, ["size", "classes"]);
    const { root, rootSmall } = classes, alertClasses = __rest(classes, ["root", "rootSmall"]);
    const fontSize = size === "large" ? "1.5rem" : 20;
    return (_jsx(MuiAlert, Object.assign({ classes: Object.assign(Object.assign({}, alertClasses), { root: clsx(root, size === "small" ? rootSmall : "") }), iconMapping: {
            success: _jsx(CheckSquare, { style: { fontSize } }),
            error: _jsx(WarningCircle, { style: { fontSize } }),
            info: _jsx(Info, { style: { fontSize } }),
            warning: _jsx(Warning, { style: { fontSize } }),
        } }, alertProps)));
}
export const Alert = withStyles((theme) => {
    var _a;
    return (Object.assign({ root: {
            paddingLeft: 19,
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 42,
        }, rootSmall: {
            paddingLeft: 18,
            minHeight: 28,
            "& $message": {
                fontSize: theme.typography.pxToRem(12),
            },
        }, icon: {
            marginRight: 11,
            padding: 0,
        }, standardInfo: {
            backgroundColor: theme.palette.ultraviolet[50],
            borderColor: theme.palette.ultraviolet[300],
            borderWidth: 1,
            "& svg": {
                color: theme.palette.ultraviolet[400],
            },
        }, standardWarning: {
            backgroundColor: theme.palette.yellow[50],
            "& svg": {
                color: theme.palette.yellow[400],
            },
        }, standardSuccess: {
            backgroundColor: theme.palette.green[50],
            "& svg": {
                color: theme.palette.green[400],
            },
        }, standardError: {
            backgroundColor: theme.palette.red[50],
            "& svg": {
                color: theme.palette.red[400],
            },
        }, message: {
            color: theme.palette.navy[500],
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(13),
            lineHeight: theme.typography.pxToRem(15),
            padding: 0,
        } }, (_a = theme.overrides) === null || _a === void 0 ? void 0 : _a.Alert));
})(AlertInner);
