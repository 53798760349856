import {
  CustomMetricContainer,
  CustomMetricContainerProject,
} from "../../../../../graphql";
import { SaveHTMLAndScreenshotsSettings } from "../types";

type QueryData = {
  customMetricContainerProjects: {
    nodes: (Pick<CustomMetricContainerProject, "enabled"> & {
      customMetricContainer: Pick<CustomMetricContainer, "id">;
    })[];
  };
};

export function formatSaveHTMLAndScreenshotsSettingsFrom(
  data: QueryData | null | undefined,
  htmlContainerId: string | undefined,
  screenshotContainerId: string | undefined,
): SaveHTMLAndScreenshotsSettings {
  return {
    saveHTML: Boolean(
      data?.customMetricContainerProjects?.nodes?.find(
        (e) => e.customMetricContainer.id === htmlContainerId,
      )?.enabled,
    ),
    saveScreenshot: Boolean(
      data?.customMetricContainerProjects?.nodes?.find(
        (e) => e.customMetricContainer.id === screenshotContainerId,
      )?.enabled,
    ),
  };
}
