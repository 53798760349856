import { useContext } from "react";
import { useTranslation, ApolloCache, useSession } from "@lumar/shared";

import {
  DeleteLogzioQueryMutation,
  GetLogzioConnectionsForSourcesQuery,
  GetLogzioQueriesForSourcesDocument,
  GetLogzioQueriesForSourcesQuery,
  useAddLogzioConnectionMutation,
  useCreateLogzioQueryMutation,
  useDeleteLogzioQueryMutation,
  useGetLogzioConnectionsForSourcesQuery,
  useGetLogzioQueriesForSourcesQuery,
  useUpdateEnabledLogzioQueryMutation,
} from "../../../../../graphql";
import { assert } from "../../../../../_common/assert";
import { useGenericParams } from "../../../../../_common/routing/useGenericParams";
import { translationNamespace } from "../../../CrawlSettings";
import { SourcesContext } from "../../data/useSourcesFormValues";

export interface LogzioConnectionValues {
  id: string;
  label: string;
  token: string;
  user?: string;
}

export interface LogzioQueryValues {
  id: string;
  enabled: boolean;
}

export interface LogzioQueriesResult {
  connections: LogzioConnectionValues[];
  queries: LogzioQueryValues[];
  addConnection: (label: string, token: string) => Promise<boolean>;
  addQuery: () => Promise<void>;
  setQueryStatus: (id: string, status: boolean) => void;
  deleteQuery: (id: string) => void;
}

export function useLogzioQueries(): LogzioQueriesResult {
  const { projectId } = useGenericParams();
  assert(projectId);

  const { t } = useTranslation(translationNamespace);
  const context = useContext(SourcesContext);

  const { email } = useSession();

  const { data: connectionData } = useGetLogzioConnectionsForSourcesQuery({
    onError: () => context.onError(t("message.errorLogzioConnections")),
  });

  const { data: queriesData } = useGetLogzioQueriesForSourcesQuery({
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "cache-first",
    onError: () => context.onError(t("message.errorLogzioQueries")),
  });

  const [addConnection] = useAddLogzioConnectionMutation({
    onError: () => context.onError(t("message.errorAddLogzioAccount")),
  });

  const [addQuery] = useCreateLogzioQueryMutation({
    onError: () => context.onError(t("message.errorLogzioCreate")),
  });

  const [setQueryStatus] = useUpdateEnabledLogzioQueryMutation({
    onError: () => context.onError(t("message.errorLogzioUpdate")),
  });

  const [deleteQuery] = useDeleteLogzioQueryMutation({
    onError: () => context.onError(t("message.errorLogzioDelete")),
  });

  const connections = getConnections(connectionData, email);
  return {
    connections,
    queries: getQueries(queriesData),
    addConnection: async (label, token) => {
      try {
        await addConnection({
          variables: { label, token },
          refetchQueries: ["GetLogzioConnectionsForSources"],
          awaitRefetchQueries: true,
        });
      } catch {
        return false;
      }
      return true;
    },
    addQuery: async () => {
      await addQuery({
        variables: {
          logzioConnectionId: connections[0]?.id ?? null,
          projectId: projectId,
        },
        refetchQueries: ["GetLogzioQueriesForSources"],
        awaitRefetchQueries: true,
      });
    },
    setQueryStatus: async (id, status) => {
      await setQueryStatus({
        variables: {
          logzioProjectQueryId: id,
          enabled: status,
        },
        optimisticResponse: {
          updateLogzioProjectQuery: {
            logzioProjectQuery: {
              id: id,
              enabled: status,
              __typename: "LogzioProjectQuery",
            },
          },
        },
      });
    },
    deleteQuery: async (id) => {
      await deleteQuery({
        variables: {
          logzioProjectQueryId: id,
        },
        optimisticResponse: {
          deleteLogzioProjectQuery: {
            logzioProjectQuery: {
              id: id,
              __typename: "LogzioProjectQuery",
            },
          },
        },
        update: (cache, { data }) =>
          updateQueriesAfterDelete(cache, data, projectId),
      });
    },
  };
}

function getConnections(
  data: GetLogzioConnectionsForSourcesQuery | undefined,
  email?: string,
): LogzioConnectionValues[] {
  return (
    data?.me?.logzioConnections?.nodes?.map((connection) => ({
      id: connection.id,
      label: connection.label ?? "",
      token: connection.token,
      user: email,
    })) ?? []
  );
}

function getQueries(
  data: GetLogzioQueriesForSourcesQuery | undefined,
): LogzioQueryValues[] {
  return (
    data?.getProject?.logzioProjectQueries?.nodes.map((item) => ({
      id: item.id,
      enabled: item.enabled,
    })) ?? []
  );
}

function updateQueriesAfterDelete(
  cache: ApolloCache<DeleteLogzioQueryMutation>,
  data: DeleteLogzioQueryMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: GetLogzioQueriesForSourcesQuery | null = cache.readQuery({
    query: GetLogzioQueriesForSourcesDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject) return;

  const queries = cachedData.getProject.logzioProjectQueries.nodes;
  const index = queries.findIndex(
    (x) => x.id === data?.deleteLogzioProjectQuery.logzioProjectQuery.id,
  );

  cache.writeQuery({
    query: GetLogzioQueriesForSourcesDocument,
    variables: { projectId: projectId },
    data: {
      getProject: {
        ...cachedData.getProject,
        logzioProjectQueries: {
          nodes: [...queries.slice(0, index), ...queries.slice(index + 1)],
        },
      },
    },
  });
}
