/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getContentTypeBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    title: () => t("contentTypeBreakdown.title"),
    description: () => t("contentTypeBreakdown.description"),
    reportTemplateCodesOrder: [
      "javascript_files",
      "css_files",
      "all_images_2",
      "linked_non_html_files",
    ],
    reportStatFilter: (report) => {
      return [
        "javascript_files",
        "css_files",
        "all_images_2",
        "linked_non_html_files",
      ].includes(report.reportTemplateCode);
    },

    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
