import { useNumberFormatter } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function CrawledCell(props: GridRenderCellParams): JSX.Element {
  const formatNumber = useNumberFormatter();
  return (
    <div data-testid="crawled-value-cell">
      {formatNumber(props.value as number)}
    </div>
  );
}
