import { Project, UpdateProjectInput } from "../../../../../graphql";
import { AuthenticationSettings } from "../types";

// Note: For security reasons the API doesn't returns the password value, it only returns
// a flag to indicate it's state. Because of this on the UI a UUID is used to represent
// the password value, and it can be used to track changes in password value. - Csaba

const uuid = "745136d40f724662976db42cb4a5d84b";

type QueryData = Pick<Project, "testSiteUsername" | "hasTestSitePassword">;

export function formatAuthenticationSettingsFrom(
  data?: QueryData | null,
): AuthenticationSettings {
  return {
    userName: data?.testSiteUsername ?? "",
    password: data?.hasTestSitePassword ? uuid : "",
  };
}

export function formatAuthenticationSettingsTo(
  formValues: AuthenticationSettings,
): Pick<UpdateProjectInput, "testSiteUsername" | "testSitePassword"> {
  const shouldSetPassword = formValues.password !== uuid;
  return {
    testSiteUsername: formValues.userName,
    testSitePassword: shouldSetPassword ? formValues.password : undefined,
  };
}
