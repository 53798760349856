import { useTranslation } from "@lumar/shared";
import {
  Metrics,
  MetricsValuePresenterProps,
  ResourceDetailData,
} from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";
import {
  CanonicalUrlConflictPresenter,
  ChangedFromPresenter,
  FailedReasonPresenter,
  ImagePresenter,
  NumberWithThresholdPresenter,
  TextLimitPresenter,
} from "../../../metrics-value-presenter";
import { CrawlType } from "../../../../graphql";
import { camelCase } from "lodash";
import { ChangedMetricLabel } from "../../../metrics-groups/ChangedMetricLabel";
import { insertIf, insertManyIf } from "../../../../_common/insertIf";

export function SeoSummary({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useMetricsConfig(data.metrics, data.crawl);

  return <MetricsGroups metrics={data.metrics} config={config} />;
}

function useMetricsConfig(
  metrics: Metrics,
  crawl: ResourceDetailData["crawl"],
): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  const hasFailedReason = metrics["failedReason"]?.value !== undefined;
  const isRedirect = metrics["isRedirect"]?.value === true;
  const isRendererEnabled = crawl.settings.useRenderer;
  const isLogSummaryEnabled = crawl.crawlTypes.includes(CrawlType.LogSummary);
  const isAnalyticsEnabled = crawl.crawlTypes.includes(
    CrawlType.GoogleAnalytics,
  );
  const isSearchConsoleEnabled = crawl.crawlTypes.includes(
    CrawlType.GoogleSearchConsole,
  );
  const isBacklinksEnabled = crawl.crawlTypes.includes(CrawlType.Backlinks);
  const hasCustomExtraction = Boolean(crawl.settings.customExtractions?.length);

  const getMetricProps = (code: string): MetricsValuePresenterProps => ({
    code,
    metrics,
    value: metrics[code]?.value,
  });

  const config: MetricsGroupsConfig = [
    {
      name: t("groupCrawlSummary"),
      items: [
        { code: "url" },
        { code: "urlLength" },
        { code: "httpStatusCode" },
        { code: "contentSize" },
        ...insertIf(hasFailedReason, {
          code: "failedReason",
          value: <FailedReasonPresenter {...getMetricProps("failedReason")} />,
        }),
        { code: "headerContentType" },
        { code: "internal" },
        { code: "redirectsInCount" },
        { code: "isRedirect" },
        { code: "crawlDatetime" },
        { code: "level" },
      ],
    },
    ...insertIf(isRedirect, {
      name: t("groupRedirection"),
      items: [
        { code: "redirectChain" },
        { code: "redirectCount" },
        { code: "redirectedToUrl" },
        { code: "redirectedToStatusCode" },
        { code: "isRedirectLoop" },
        { code: "metaRedirect" },
        { code: "redirectExclusionReason" },
      ],
    }),
    {
      name: t("groupIndexation"),
      items: [
        { code: "indexable" },
        { code: "disallowedPage" },
        { code: "noindex" },
        { code: "metaNoindex" },
        { code: "headerNoindex" },
        { code: "isSelfCanonical" },
        { code: "canonicalizedPage" },
        { code: "canonicalUrl" },
        { code: "canonicalHeaderUrl" },
        { code: "canonicalHtmlUrl" },
        {
          code: "canonicalUrlIsConsistent",
          name: t("metricCanonicalUrlConflict"),
          value: (
            <CanonicalUrlConflictPresenter
              {...getMetricProps("metricCanonicalUrlConflict")}
            />
          ),
        },
        { code: "nofollowedPage" },
      ],
    },
    {
      name: t("groupPagination"),
      items: [
        { code: "page1" },
        { code: "paginatedPage" },
        { code: "relNextUrl" },
        { code: "relNextHtmlUrl" },
        { code: "relNextHeaderUrl" },
        { code: "relNextUrlIsConsistent" },
        { code: "relPrevUrl" },
        { code: "relPrevHtmlUrl" },
        { code: "relPrevHeaderUrl" },
        { code: "relPrevUrlIsConsistent" },
      ],
    },
    {
      name: t("groupDuplication"),
      items: [
        { code: "primaryPage" },
        { code: "primaryUrl" },
        { code: "duplicatePage" },
        { code: "duplicatePageCount" },
        { code: "duplicateTitle" },
        { code: "duplicateTitleCount" },
        { code: "duplicateTitlePrimaryUrl" },
        { code: "duplicateDescription" },
        { code: "duplicateDescriptionCount" },
        { code: "duplicateDescriptionPrimaryUrl" },
        { code: "duplicateBody" },
        { code: "duplicateBodyCount" },
        { code: "duplicateBodyPrimaryUrl" },
      ],
    },
    {
      name: t("groupSerpContent"),
      items: [
        { code: "wordCount" },
        { code: "htmlSize" },
        { code: "contentHtmlRatio" },
        {
          code: "pageTitle",
          value: (
            <TextLimitPresenter
              {...getMetricProps("pageTitle")}
              length={metrics["pageTitleLength"]?.value}
              minLength={crawl.settings.minTitleLength}
              width={metrics["pageTitleLengthPx"]?.value}
              maxWidth={crawl.settings.maxTitleWidth}
            />
          ),
        },
        {
          code: "description",
          value: (
            <TextLimitPresenter
              {...getMetricProps("description")}
              length={metrics["descriptionLength"]?.value}
              minLength={crawl.settings.minDescriptionLength}
              maxLength={crawl.settings.maxDescriptionLength ?? undefined}
              width={metrics["descriptionLengthPx"]?.value}
            />
          ),
        },
        { code: "h1Count" },
        { code: "h1Tag" },
        { code: "h1Length" },
        { code: "h2Tag" },
        { code: "h3Tag" },
        { code: "headerNosnippet" },
      ],
    },
    {
      name: t("groupMobile"),
      items: [
        { code: "responsive" },
        { code: "varyUserAgent" },
        { code: "separateDesktop" },
        { code: "desktopUrl" },
        { code: "separateMobile" },
        { code: "mobileRelAlternate" },
        { code: "mobileRelAlternateHeaderUrl" },
        { code: "mobileRelAlternateHtmlUrl" },
        { code: "mobileRelAlternateUrl" },
        { code: "mobileRelAmphtml" },
        { code: "mobileRelAmphtmlUrl" },
        { code: "mobileReciprocate" },
        { code: "mobileRelAlternateUrlIsConsistent" },
        { code: "amphtml" },
        { code: "amphtmlValid" },
        { code: "viewport" },
        { code: "mobileDesktopContentMismatch" },
        { code: "mobileDesktopLinksInDifference" },
        { code: "mobileDesktopLinksOutDifference" },
        { code: "mobileDesktopContentDifference" },
      ],
    },
    {
      name: t("groupStructuredData"),
      items: [
        { code: "hasStructuredData" },
        { code: "breadcrumbSchemaCount" },
        { code: "eventSchemaCount" },
        { code: "faqpageSchemaCount" },
        { code: "howtoSchemaCount" },
        { code: "newsarticleSchemaCount" },
        { code: "newsarticleSchemaValid" },
        { code: "newsarticleAuthorSchemaValue" },
        { code: "productSchemaCount" },
        { code: "productSchemaValid" },
        { code: "productRatingSchemaValue" },
        { code: "productAvailabilitySchemaValue" },
        { code: "productBrandSchemaValue" },
        { code: "productPriceSchemaValue" },
        { code: "productSkuSchemaValue" },
        { code: "qapageSchemaCount" },
        { code: "recipeSchemaCount" },
        { code: "reviewSchemaCount" },
        { code: "videoobjectSchemaCount" },
      ],
    },
    {
      name: t("groupSocialTags"),
      items: [
        { code: "ogType" },
        { code: "ogUrl" },
        { code: "ogTitle" },
        { code: "ogDescription" },
        { code: "ogSiteName" },
        {
          code: "ogImage",
          value: <ImagePresenter {...getMetricProps("ogImage")} fullWidth />,
        },
        { code: "ogLocale" },
        { code: "twitterCard" },
        { code: "validTwitterCard" },
        { code: "twitterTitle" },
        { code: "twitterDescription" },
        { code: "twitterDescriptionLength" },
        {
          code: "twitterImage",
          value: (
            <ImagePresenter {...getMetricProps("twitterImage")} fullWidth />
          ),
        },
        { code: "twitterSite" },
        { code: "fbAdmins" },
        { code: "fbAppId" },
      ],
    },
    {
      name: t("groupInternationalization"),
      items: [
        { code: "hreflangCombination" },
        { code: "hreflangOnHeader" },
        { code: "hreflangOnPage" },
        { code: "hreflangOnSitemap" },
        { code: "hreflangLinksOutCount" },
        { code: "hreflangUrlCount" },
      ],
    },
    {
      name: t("groupLinksOut"),
      items: [
        { code: "linksOutCount" },
        { code: "internalLinksCount" },
        { code: "externalLinksCount" },
        { code: "followedExternalLinksOutCount" },
        { code: "nofollowedPage" },
        { code: "metaNofollow" },
        { code: "headerNofollow" },
      ],
    },
    ...insertIf(isRendererEnabled, {
      name: t("groupPerformance"),
      items: [
        {
          code: "fetchTime",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("fetchTime")}
              maximumFractionDigits={2}
            />
          ),
        },
        {
          code: "performanceTtfb",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceTtfb")}
              maximumFractionDigits={1}
            />
          ),
        },
        {
          code: "performanceDcl",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceDcl")}
              maximumFractionDigits={1}
            />
          ),
        },
        {
          code: "performanceFcp",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceFcp")}
              maximumFractionDigits={1}
            />
          ),
        },
        {
          code: "performanceLcp",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceLcp")}
              maximumFractionDigits={1}
            />
          ),
        },
        {
          code: "performanceTti",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceTti")}
              maximumFractionDigits={1}
            />
          ),
        },
        {
          code: "performanceCls",
          value: (
            <NumberWithThresholdPresenter
              {...getMetricProps("performanceCls")}
              maximumFractionDigits={3}
            />
          ),
        },
      ],
    }),
    {
      name: t("groupRenderingIssues"),
      items: [
        { code: "bodyTagsInHeadCount" },
        { code: "headTagsInBodyCount" },
        ...insertManyIf(isRendererEnabled, [
          { code: "renderedCanonicalLinkMatch" },
          { code: "renderedLinkCountDifference" },
          { code: "renderedWordCountDifference" },
        ]),
      ],
    },
    ...insertIf(isLogSummaryEnabled, {
      name: t("groupLogFiles"),
      items: [
        { code: "logRequestsTotal" },
        { code: "logRequestsDesktop" },
        { code: "logRequestsMobile" },
      ],
    }),
    ...insertIf(isAnalyticsEnabled, {
      name: t("groupAnalytics"),
      items: [
        { code: "gaVisits" },
        { code: "gaVisitBounceRate" },
        { code: "gaAvgPageLoadTime" },
        { code: "gaAvgTimeOnPage" },
        { code: "gaPageviewsPerVisits" },
      ],
    }),
    ...insertIf(isSearchConsoleEnabled, {
      name: t("groupSearchConsole"),
      items: [
        { code: "searchConsoleTotalImpressions" },
        { code: "searchConsoleTotalClicks" },
        { code: "searchConsoleTotalCtr" },
        { code: "searchConsoleTotalPosition" },
        { code: "searchConsoleDesktopImpressions" },
        { code: "searchConsoleDesktopClicks" },
        { code: "searchConsoleDesktopCtr" },
        { code: "searchConsoleDesktopPosition" },
        { code: "searchConsoleMobileImpressions" },
        { code: "searchConsoleMobileClicks" },
        { code: "searchConsoleMobileCtr" },
        { code: "searchConsoleMobilePosition" },
        { code: "searchConsoleTabletImpressions" },
        { code: "searchConsoleTabletClicks" },
        { code: "searchConsoleTabletCtr" },
        { code: "searchConsoleTabletPosition" },
      ],
    }),
    ...insertIf(isBacklinksEnabled, {
      name: t("groupBacklinks"),
      items: [{ code: "backlinkCount" }, { code: "backlinkDomainCount" }],
    }),
    ...insertIf(hasCustomExtraction, {
      name: t("groupCustomExtraction"),
      items: [
        { code: "customExtractionMatch" },
        ...(crawl.settings.customExtractions || []).map((customExtraction) => ({
          code: camelCase(customExtraction.reportTemplateCode),
        })),
      ],
    }),
  ];

  const metricsList = Object.values(metrics);
  return config.map((config) => ({
    ...config,
    items: config.items?.reduce<NonNullable<MetricsGroupsConfig[0]["items"]>>(
      (result, item) => {
        const changedFrom = metricsList.find(
          (x) => x.data?.changedMetricCode === item.code,
        );

        return [
          ...result,
          item,
          ...(changedFrom && changedFrom.value
            ? [
                {
                  code: changedFrom.code,
                  name: <ChangedMetricLabel label={changedFrom?.name} />,
                  value:
                    changedFrom.code !== "httpStatusCodeChangedFrom" ? (
                      <ChangedFromPresenter
                        {...getMetricProps(changedFrom.code)}
                        grayedOut
                      />
                    ) : undefined,
                },
              ]
            : []),
        ];
      },
      [],
    ),
  }));
}
