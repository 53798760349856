import React, { useState } from "react";
import { InputBaseComponentProps, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  hiddenInput: {
    opacity: "0 !important",
  },
  valueDisplay: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    position: "absolute",
    width: "auto",
  },
});

function DisplayValueInput(
  { inputRef, ...props }: InputBaseComponentProps,
  getDisplayValue: (value: string) => string,
): JSX.Element {
  const classes = useStyles();

  const [focused, setFocused] = useState(false);

  const showText = !focused && !!props.value;

  return (
    <>
      {showText && (
        <Typography className={clsx(props.className, classes.valueDisplay)}>
          {getDisplayValue(props.value)}
        </Typography>
      )}
      <input
        {...props}
        ref={inputRef}
        className={clsx(props.className, showText ? classes.hiddenInput : "")}
        onFocus={(e) => {
          setFocused(true);
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          props.onBlur?.(e);
        }}
      />
    </>
  );
}

export function withDisplayValue(
  getDisplayValue: (value: string) => string,
): (props: InputBaseComponentProps) => JSX.Element {
  return (props: InputBaseComponentProps) =>
    DisplayValueInput(props, getDisplayValue);
}
