import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../../../../resource-detail/data/types";
import { RedirectChainPresenter } from "../../../../../resource-detail/metrics-value-presenter";
import { clsx } from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  rootInGridView: {
    flexDirection: "row",
  },
}));

export function RedirectChainInterpreter(
  props: MetricsValuePresenterProps & {
    isGridView?: boolean;
    containerWidth?: number;
  },
): JSX.Element {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootInGridView]: props.isGridView,
      })}
    >
      <RedirectChainPresenter behaviour="truncate" {...props} />
    </div>
  );
}
