import { Chip, ExternalLinkSolid, useTranslation } from "@lumar/shared";
import { makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { isObject } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { ReportTypeCode } from "../../../../../graphql";
import { MetricsValuePresenterProps } from "../../../../../resource-detail/data/types";
import {
  isUrlMetric,
  linkRegexp,
} from "../../../../../resource-detail/metrics-value-presenter/default-presenters/StringPresenter";
import { useResourceDetailUrls } from "../../../../../resource-detail/metrics-value-presenter/default-presenters/UrlPresenter";
import { GridContext } from "../../ReportGrid";
import { Truncate } from "./Truncate";

const useStyles = makeStyles((theme) => ({
  link: { color: theme.palette.blue[600], whiteSpace: "normal" },
  externalLink: {
    marginLeft: 4,
    position: "relative",
    color: theme.palette.blue[600],
  },
  icon: {
    fontSize: 14,
    verticalAlign: "sub",
  },
  tooltipWide: {
    minWidth: 600,
  },
  chip: {
    margin: theme.spacing(0.25, 1.25, 0.25, 0),
    height: "auto",
    "& > span": {
      whiteSpace: "break-spaces",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1.25),
    },
  },
}));

export const STRING_INTERPRETER_MAX_LENGTH = 250;

export function StringInterpreter(
  props: MetricsValuePresenterProps & {
    isGridView?: boolean;
    isCard?: boolean;
  },
): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  const isUrl = isUrlMetric(props);
  const isCustomMetric =
    props.code.startsWith("customMetric") && Array.isArray(props.value);

  if (isUrl) return <UrlInterpreter {...props} />;
  if (isCustomMetric) return <CustomMetricInterpreter {...props} />;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getValue(value: any): React.ReactNode {
    if (!value) return <>-</>;

    const formattedValues = ((): { value: string; tooltipValue?: string } => {
      if (isObject(value)) return { value: JSON.stringify(value, null, 2) };

      if (props.code === "issueDescription")
        return {
          value: value?.replaceAll("\n", " ■ ").replaceAll(/ +/g, " "),
          tooltipValue: value,
        };

      return { value };
    })();

    return (
      <Truncate
        value={formatStringValue(formattedValues.value, theme)}
        stringValue={formattedValues.value}
        tooltipValue={formattedValues.tooltipValue}
        isCard={props.isCard}
      />
    );
  }

  return (
    <span
      style={{
        whiteSpace: props.isGridView ? "nowrap" : "break-spaces",
        wordBreak: "break-word",
      }}
    >
      {Array.isArray(props.value)
        ? props.value.map((value, idx) => (
            <Chip
              key={idx}
              label={getValue(value)}
              color="cyan"
              className={classes.chip}
            />
          ))
        : getValue(props.value)}
    </span>
  );
}

function formatStringValue(value: string, theme: Theme): React.ReactNode {
  const links = [...value.matchAll(linkRegexp)];
  if (!links.length) return value;

  return (
    <>
      {value.substring(0, links[0].index)}
      {links.map((link, idx) => {
        const closeIndex = link[0].indexOf("]");
        return (
          <React.Fragment key={idx}>
            <a
              href={link[0].substring(closeIndex + 2, link[0].length - 1)}
              target="_blank"
              rel="noreferrer"
              style={{ color: theme.palette.blue[600] }}
            >
              {link[0].substring(1, closeIndex)}
            </a>
            {value.substring(
              (link.index || 0) + link[0].length,
              links[idx + 1]?.index,
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export function UrlInterpreter(
  props: MetricsValuePresenterProps,
): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation("report");
  const { compareToCrawlId, reportInput } = React.useContext(GridContext);

  const { resourceDetailUrl, externalUrl } = useResourceDetailUrls({
    ...props,
    crawlId:
      reportInput.reportTypeCode === ReportTypeCode.Missing
        ? compareToCrawlId
        : undefined,
  });

  if (typeof props.value !== "string") return <>-</>;

  const isTooLong = props.value.length > STRING_INTERPRETER_MAX_LENGTH;
  const value = isTooLong
    ? `${props.value.substring(0, STRING_INTERPRETER_MAX_LENGTH)}...`
    : props.value;

  return (
    <>
      <Tooltip
        title={isTooLong ? props.value : ""}
        classes={{
          tooltip: clsx({ [classes.tooltipWide]: props.value.length > 500 }),
        }}
      >
        {resourceDetailUrl ? (
          <Link to={resourceDetailUrl} className={classes.link}>
            {value}
          </Link>
        ) : (
          <>{value}</>
        )}
      </Tooltip>
      {externalUrl && (
        <Tooltip title={t("openNewTab")} arrow>
          <a
            href={externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.externalLink}
          >
            <ExternalLinkSolid className={classes.icon} />
          </a>
        </Tooltip>
      )}
    </>
  );
}

// TODO: Ad-hoc work to make it work for demo
// This needs to be redone for a proper implementation
function CustomMetricInterpreter(
  props: MetricsValuePresenterProps,
): JSX.Element {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      {Array.isArray(props.value) &&
        props.value.map((value, idx) => <Badge key={idx}>{value}</Badge>)}
    </div>
  );
}

export const Badge = (props: { children: React.ReactNode[] }): JSX.Element => {
  const theme = useTheme();

  return (
    <span
      style={{
        display: "inline-flex",
        alignItems: "center",
        borderRadius: 4,
        padding: "4px 8px",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        lineHeight: theme.typography.pxToRem(20),
        backgroundColor: theme.palette.cyan[100],
        color: theme.palette.cyan[700],
        whiteSpace: "pre-wrap",
      }}
    >
      {props.children}
    </span>
  );
};
