/* eslint-disable fp/no-mutating-methods */
import { BreadcrumbItem } from "@lumar/shared";
import {
  CrawlContextValue,
  CrawlReportCategoryListNode,
  useCrawlContext,
} from "../../crawl-overview/CrawlContext";
import { ReportCategory } from "../../graphql";
import { Routes } from "../../_common/routing/routes";

interface Props {
  reportTemplate?: {
    reportCategories: Array<
      Pick<ReportCategory, "code" | "parentCode" | "name">
    >;
    primaryReportCategoryCode?: string | null;
  };
  accountId: string;
  projectId: string;
  crawlId: string;
  segmentId: string | undefined;
  title: string;
}

interface ReportBreadcrumbs {
  loading: boolean;
  breadcrumbs: BreadcrumbItem[];
}

export function useReportBreadcrumbs({
  reportTemplate,
  title,
}: Props): ReportBreadcrumbs {
  const crawlContex = useCrawlContext();
  const { loading } = crawlContex;
  const breadcrumbs: BreadcrumbItem[] = createBreadcrumbs(
    crawlContex.data,
    reportTemplate?.primaryReportCategoryCode,
  );

  breadcrumbs.push({
    label: title,
  });

  return {
    loading,
    breadcrumbs,
  };
}

function createBreadcrumbs(
  crawlContextData?: NonNullable<CrawlContextValue["data"]>,
  primaryReportCategoryCode?: string | null,
): BreadcrumbItem[] {
  if (!crawlContextData || !primaryReportCategoryCode) {
    return [];
  }

  const primaryCategory = crawlContextData.crawlReportCategoriesList.find(
    (c) => c.code === primaryReportCategoryCode,
  );

  if (!primaryCategory) {
    return [];
  }

  const categoryLineage: CrawlReportCategoryListNode[] = [primaryCategory];

  // eslint-disable-next-line fp/no-loops
  while (categoryLineage[0]?.parentCode) {
    const parent = crawlContextData.crawlReportCategoriesList.find(
      (crawlReportCategory) =>
        crawlReportCategory.code === categoryLineage[0].parentCode,
    );
    if (parent) {
      // eslint-disable-next-line fp/no-mutating-methods
      categoryLineage.unshift(parent);
    } else {
      break;
    }
  }

  return categoryLineage.map((crawlReportCategory) => {
    return {
      label: crawlReportCategory.name,
      link: Routes.CrawlOverview.getUrl({
        accountId: crawlContextData.crawlProject.account.rawID,
        crawlId: crawlContextData.crawl.rawID,
        projectId: crawlContextData.crawlProject.rawID,
        category: crawlReportCategory.code,
        segmentId: crawlContextData.selectedCrawlSegment?.segment.id,
      }),
    };
  });
}
