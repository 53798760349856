import React from "react";
import {
  Button,
  Grid,
  Typography,
  Link,
  Tooltip,
  MenuItem,
  Divider,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { FastField, FieldArray, FieldProps } from "formik";
import clsx from "clsx";
import { AddCircle, Delete } from "@material-ui/icons";
import { Trans, useTranslation } from "react-i18next";
import { Alert, fieldToSelect, Select, ToggleIconButton } from "@lumar/shared";

import { UpgradeMessage } from "../../components/UpgradeMessage";
import { SettingsContext } from "../data/useContextValues";
import { CustomExtractionRule, FormValues } from "../data/types";
import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { SettingControlFactory } from "../useAdvancedControls";
import { CUSTOM_EXTRACTION_MAX } from "../data/useSettingsValidationSchema";

export const getCustomExtractionControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.customExtraction.title"),
  path: "customExtraction",
  // eslint-disable-next-line react/display-name
  control: () => <CustomExtraction />,
  testId: "crawl-settings-advanced-custom-extraction",
  activeValues: ["rules"],
  textForSearch: createTextForSearch([
    t("settings.customExtraction.title"),
    t("settings.customExtraction.description"),
    t("settings.customExtraction.example"),
    t("settings.customExtraction.notes"),
    t("settings.customExtraction.labelTooltip"),
    t("settings.customExtraction.labelPlaceholder"),
    t("settings.customExtraction.patternTooltip"),
    t("settings.customExtraction.patternPlaceholder"),
    t("settings.customExtraction.stripTooltip"),
    t("settings.customExtraction.stripPlaceholder"),
    t("settings.customExtraction.cleanHtmlTags"),
    t("settings.customExtraction.rublar"),
  ]),
  fieldNames: [
    ["rules", t("settings.customExtraction.title")],
    ["label", t("settings.customExtraction.labelPlaceholder")],
    ["pattern", t("settings.customExtraction.patternPlaceholder")],
  ],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  example: {
    whiteSpace: "pre-line",
    fontSize: "12px",
  },
  note: {
    backgroundColor: theme.palette.grey[700],
    color: "white",
    borderRadius: theme.shape.borderRadius,
    width: "fit-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  grid: {
    marginTop: theme.spacing(0),
    alignItems: "flex-start",
  },
  removeButton: {
    height: 49,
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

export const CustomExtraction = React.memo(CustomExtractionInner);

function CustomExtractionInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const contextValues = React.useContext(SettingsContext);

  const isPlanLight =
    contextValues.isPlanLight || contextValues.isPlanLightPlus;

  const createNewRule = (): CustomExtractionRule => ({
    label: "",
    pattern: "",
    matchNumber: 1,
    strip: "",
    cleanHtmlTags: false,
  });

  return (
    <>
      <Typography variant="caption">
        {t("settings.customExtraction.description")}
      </Typography>
      <code className={clsx(classes.example, classes.indent)}>
        {t("settings.customExtraction.example")}
      </code>
      <Alert severity="info" size="small" className={classes.indent}>
        {t("settings.customExtraction.notes")}
      </Alert>
      {isPlanLight ? (
        <UpgradeMessage upgradeType="Corporate" className={classes.indent} />
      ) : (
        <FieldArray
          name="extraction.customExtraction.rules"
          render={({ form, push, remove }) => {
            const values = (form.values as FormValues).extraction
              .customExtraction.rules;
            return (
              <div className={classes.indent}>
                {values.map((_: unknown, idx: number) => (
                  <Paper
                    key={idx}
                    style={{ padding: "8px", marginBottom: "8px" }}
                  >
                    <Grid container spacing={2} className={classes.grid}>
                      <Grid item xs={12} md={2}>
                        <FieldTooltip
                          title={t("settings.customExtraction.labelTooltip")}
                        >
                          <FastField
                            name={`extraction.customExtraction.rules[${idx}].label`}
                            component={TextFieldWithOnBlurUpdate}
                            hiddenLabel
                            variant="outlined"
                            placeholder={t(
                              "settings.customExtraction.labelPlaceholder",
                            )}
                            className={classes.input}
                            data-testid="extraction-rule-label"
                          />
                        </FieldTooltip>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FieldTooltip
                          title={t("settings.customExtraction.patternTooltip")}
                        >
                          <FastField
                            name={`extraction.customExtraction.rules[${idx}].pattern`}
                            component={TextFieldWithOnBlurUpdate}
                            hiddenLabel
                            variant="outlined"
                            placeholder={t(
                              "settings.customExtraction.patternPlaceholder",
                            )}
                            className={classes.input}
                            data-testid="extraction-rule-pattern"
                          />
                        </FieldTooltip>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FastField
                          name={`extraction.customExtraction.rules[${idx}].matchNumber`}
                        >
                          {(props: FieldProps<string>) => (
                            <Select
                              {...fieldToSelect(props)}
                              className={classes.input}
                              data-testid="extraction-rule-match"
                            >
                              <MenuItem value={1}>
                                {t("settings.customExtraction.firstMatch")}
                              </MenuItem>
                              <MenuItem value={5}>
                                {t("settings.customExtraction.first5Match")}
                              </MenuItem>
                              <MenuItem value={10}>
                                {t("settings.customExtraction.first10Match")}
                              </MenuItem>
                              <MenuItem value={20}>
                                {t("settings.customExtraction.first20Match")}
                              </MenuItem>
                            </Select>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FieldTooltip
                          title={t("settings.customExtraction.stripTooltip")}
                        >
                          <FastField
                            name={`extraction.customExtraction.rules[${idx}].strip`}
                            component={TextFieldWithOnBlurUpdate}
                            hiddenLabel
                            variant="outlined"
                            placeholder={t(
                              "settings.customExtraction.stripPlaceholder",
                            )}
                            className={classes.input}
                            data-testid="extraction-rule-filter"
                          />
                        </FieldTooltip>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Tooltip
                          title={t(
                            "settings.customExtraction.removeExtraction",
                          )}
                          placement="top"
                          arrow
                        >
                          <ToggleIconButton
                            size="large"
                            variant="outlined"
                            colorVariant="red"
                            onClick={() => remove(idx)}
                            disabled={form.isSubmitting}
                            data-testid="extraction-rule-remove"
                          >
                            <Delete />
                          </ToggleIconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          name={`extraction.customExtraction.rules[${idx}].cleanHtmlTags`}
                          component={CheckboxWithLabel}
                          type="checkbox"
                          Label={{
                            label: t("settings.customExtraction.cleanHtmlTags"),
                          }}
                          data-testid="extraction-rule-clean-tags"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
                <Button
                  onClick={() => push(createNewRule())}
                  disabled={
                    form.isSubmitting || values.length >= CUSTOM_EXTRACTION_MAX
                  }
                  startIcon={<AddCircle />}
                  variant="outlined"
                  size="small"
                  className={classes.indent}
                  data-testid="extraction-rule-add"
                >
                  {t("settings.customExtraction.newRule")}
                </Button>
              </div>
            );
          }}
        />
      )}
      <Divider className={classes.indent} />
      <Typography variant="caption" className={classes.indent}>
        <Trans
          ns="crawlSettings"
          i18nKey="settings.customExtraction.rublar"
          components={{
            rubularLink: (
              <Link
                href="http://rubular.com/"
                target="_blank"
                className={classes.link}
              />
            ),
          }}
        />
      </Typography>
    </>
  );
}

function FieldTooltip({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}): JSX.Element {
  return (
    <Tooltip title={title} placement="top">
      <div>{children}</div>
    </Tooltip>
  );
}
