import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export function useSourcesSchema(majesticMaxUrl: number): unknown {
  const { t } = useTranslation("crawlSettings");

  const lastNDaysMax = 10000;
  const maximumUrlsMax = 10000000;

  const required = t("validationError.required");
  const number = t("validationError.number");
  const integer = t("validationError.integer");
  const lessThan0 = t("validationError.lessThan", { min: 0 });
  const lessThan1 = t("validationError.lessThan", { min: 1 });
  const majesticsMaxUrlGreaterThan = t("validationError.greaterThan", {
    max: majesticMaxUrl,
  });
  const lastNDaysGreaterThan = t("validationError.greaterThan", {
    max: lastNDaysMax,
  });
  const maxmumUrlsGreaterThan = t("validationError.greaterThan", {
    max: maximumUrlsMax,
  });

  return Yup.object().shape({
    backlinks: Yup.object().shape({
      majestic: Yup.object()
        .shape({
          maxRows: Yup.number().when("enabled", {
            is: (enabled: boolean) => enabled,
            then: (schema) =>
              schema
                .typeError(number)
                .min(1, lessThan1)
                .max(majesticMaxUrl, majesticsMaxUrlGreaterThan)
                .required(required),
          }),
        })
        .default({ enabled: false }),
    }),
    googleSearchConsole: Yup.object().shape({
      minClicks: Yup.number().typeError(number).required(required),
      lastNDays: Yup.number()
        .typeError(number)
        .min(1, lessThan1)
        .max(lastNDaysMax, lastNDaysGreaterThan)
        .required(required),
    }),
    analytics: Yup.object().shape({
      googleAnalytics4: Yup.object().shape({
        lastNDays: Yup.number().when("propertyId", {
          is: (propertyId: string) => Boolean(propertyId),
          then: (schema) =>
            schema
              .typeError(number)
              .integer(integer)
              .min(1, lessThan1)
              .max(lastNDaysMax, lastNDaysGreaterThan)
              .required(required),
        }),
        minimumSessions: Yup.number().when("propertyId", {
          is: (propertyId: string) => Boolean(propertyId),
          then: (schema) =>
            schema
              .typeError(number)
              .integer(integer)
              .min(0, lessThan0)
              .required(required),
        }),
        minimumViews: Yup.number().when("propertyId", {
          is: (propertyId: string) => Boolean(propertyId),
          then: (schema) =>
            schema
              .typeError(number)
              .integer(integer)
              .required(required)
              .min(0, lessThan0),
        }),
      }),
      adobe: Yup.object().shape({
        numberOfDays: Yup.number()
          .typeError(number)
          .min(1, lessThan1)
          .max(lastNDaysMax, lastNDaysGreaterThan),
        maximumUrls: Yup.number()
          .typeError(number)
          .min(1, lessThan1)
          .max(maximumUrlsMax, maxmumUrlsGreaterThan),
      }),
    }),
  });
}
