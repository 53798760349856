import { SmallHealthScoreChart, useTranslation } from "@lumar/shared";
import {
  ChartCategoryHealthScoreProvider,
  useChartCategoryHealthScore,
} from "../../providers/ChartCategoryHealthScore";
import { CircularProgress } from "@material-ui/core";
import { ChartPanelErrorMessage } from "../../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";
import { ModuleCode } from "../../../../../graphql";

export function ChildCategoryHealthScoreChart(props: {
  reportCategoryCode: string;
}): JSX.Element {
  return (
    <ChartCategoryHealthScoreProvider
      reportCategoryCode={props.reportCategoryCode}
    >
      <ChildCategoryHealthScoreChartInner />
    </ChartCategoryHealthScoreProvider>
  );
}

function ChildCategoryHealthScoreChartInner(): JSX.Element {
  const { t } = useTranslation(["charts", "crawlOverview"]);

  const {
    loading,
    error,
    healthScore,
    healthScoreChangePercentage,
    trend,
    industry,
    category,
    moduleCode,
    link,
  } = useChartCategoryHealthScore();

  if (loading) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <ChartPanelErrorMessage>
          {t("charts:errors.queryError")}
        </ChartPanelErrorMessage>
      </div>
    );
  }

  return (
    <SmallHealthScoreChart
      id={category.code}
      getTitleText={(name) => category.abbreviation || name}
      unitDescription={t("crawlOverview:categoryHealthScoreUnit")}
      name={category.name}
      icon={moduleCode === ModuleCode.SiteSpeed ? "lighthouse" : undefined}
      healthScore={healthScore}
      healthScoreChangePercentage={healthScoreChangePercentage}
      healthScoreTrend={trend}
      industryBenchmark={industry?.healthScoreBenchmark}
      link={link}
    />
  );
}
