export function getCustomReportGUID(customReport: {
  crawlId: string;
  segmentId?: null | undefined | string;
  customReportTemplate: { id: string };
}): string {
  return `CustomReport_${customReport.crawlId}:${customReport.segmentId ?? null}:${customReport.customReportTemplate.id}`;
}

export function isCustomReportGUID(input: string): boolean {
  return input.startsWith("CustomReport_");
}

export function getCustomReportTemplateId(input: string): string {
  return input.split(":")[2];
}
