import { GridColDef } from "@mui/x-data-grid-pro";
import { useNumberMetricFormatter } from "../../resource-detail/metrics-value-presenter/default-presenters/NumberPresenter";
import { ReportsTableColumnCustomDefinition } from "./useReportsTableColumns";
import { ReportWithCustomValues } from "../dashboard/data-visualization/report-custom-data/types";

export function getCustomColumn(
  columnDefinition: ReportsTableColumnCustomDefinition,
  formatMetric: ReturnType<typeof useNumberMetricFormatter>,
): GridColDef {
  return {
    field: columnDefinition.field,
    headerName: columnDefinition.header,
    description: columnDefinition.description,
    align: columnDefinition.align || "right",
    hide: columnDefinition.hide,
    width: columnDefinition.header.length * 8 + 40,
    disableColumnMenu: true,
    valueGetter: ({ row }) => {
      const value = (row as ReportWithCustomValues).customValues[
        columnDefinition.field
      ]?.value;

      return typeof value === "number" &&
        columnDefinition.maxFractionDigits !== undefined
        ? Number(value.toFixed(columnDefinition.maxFractionDigits))
        : value;
    },
    renderCell: ({ row }) => {
      const report = row as ReportWithCustomValues;
      const { value, unit } = report.customValues[columnDefinition.field] ?? {};

      const formattedValue = ((): string => {
        if (typeof value === "number") {
          const formattedValue = formatMetric(value, unit, {
            maximumFractionDigits: columnDefinition.maxFractionDigits,
            minimumFractionDigits: columnDefinition.minFractionDigits,
          });
          return `${formattedValue.value} ${formattedValue.unit || ""}`;
        }

        return value ?? "-";
      })();

      return (
        <div
          style={{
            fontWeight: columnDefinition.bold ? 600 : 400,
            color:
              typeof columnDefinition.color === "string"
                ? columnDefinition.color
                : columnDefinition.color?.({ value, report }),
          }}
        >
          {formattedValue}
        </div>
      );
    },
  };
}
