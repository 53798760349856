/* eslint-disable fp/no-mutating-methods */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { CrawlType, ProjectUploadType } from "../../../../graphql";
import { ManualUploadsSettings } from "./ManualUploadsSettings";
import {
  EditFileProps,
  FileUpload,
  ManualUploadsContextValues,
  UploadTemplate,
  UploadType,
} from "./types";
import { useManualUploadFiles } from "./useManualUploadFiles";
import { useManualUploadMutations } from "./useManualUploadMutations";

export const ManualUploadsContext =
  React.createContext<ManualUploadsContextValues>({
    files: [],
    loading: false,
    uploadTypes: {},
    getFileCount: () => 0,
    uploadFiles: () => {},
    setFileStatus: () => {},
    deleteFile: () => {},
    editFile: () => {},
  });

export function ManualUploadsWrapper({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();

  const [editingFiles, setEditingFiles] = React.useState<EditFileProps[]>([]);
  const [uploadingFiles, setUploadingFiles] = React.useState<FileUpload[]>([]);

  const { files, uploadTypes, primaryDomain, loading } = useManualUploadFiles({
    projectId,
    uploadingFiles,
  });

  const { createFile, updateFile, setFileStatus, deleteFile } =
    useManualUploadMutations({ projectId });

  const editFile = React.useCallback(
    (file: EditFileProps): void => setEditingFiles((files) => [...files, file]),
    [setEditingFiles],
  );

  const addUploadingFiles = (files: FileUpload[]): void =>
    setUploadingFiles((value) => [...value, ...files]);

  const removeUploadingFile = (fileId: string): void =>
    setUploadingFiles((files) => files.filter((file) => file.id !== fileId));

  async function uploadFiles(
    crawlType: CrawlType,
    files: File[],
  ): Promise<void> {
    const fileUploads: [File, FileUpload][] = files.map((file) => [
      file,
      {
        id: uuid(),
        crawlType,
        fileName: formatFileName(file.name),
        enabled: true,
        status: "Uploading",
        fileLink: "#",
        totalRows: undefined,
        baseDomain: undefined,
        uploadType: undefined,
        isCustomizable: true,
        customTemplate: undefined,
      },
    ]);

    addUploadingFiles(fileUploads.map((x) => x[1]));

    await fileUploads.reduce(async (promise, [file, fileUpload]) => {
      await promise;

      const { uploadType, sample } = await detectUploadType(
        file,
        uploadTypes[crawlType]?.uploadTypes || [],
      );

      async function uploadFile(
        uploadType: ProjectUploadType,
        customTemplate?: Parameters<typeof createFile>[0]["customTemplate"],
        baseDomain?: string,
      ): Promise<void> {
        const { fileId, uploadLink } = await createFile({
          crawlType,
          fileName: fileUpload.fileName,
          baseDomain,
          uploadType,
          customTemplate,
        });
        removeUploadingFile(fileUpload.id);
        if (!fileId || !uploadLink) return;

        addUploadingFiles([{ ...fileUpload, id: fileId }]);
        try {
          await fetch(uploadLink, {
            method: "PUT",
            body: file,
          });
        } catch {
          enqueueSnackbar(
            <Snackbar
              variant="error"
              title={t("message.apiErrorTitleFileUpload")}
            />,
          );
        }
        removeUploadingFile(fileId);
      }

      if (uploadType) {
        await uploadFile(uploadType.code);
      } else {
        editFile({
          file: { ...fileUpload, uploadType: ProjectUploadType.Custom },
          sample,
          onSubmit: async (values, sample) => {
            await uploadFile(
              ProjectUploadType.Custom,
              {
                sample,
                headerRow: values.headerRow || 0,
                metrics: values.metrics,
              },
              values.baseDomain,
            );
            return true;
          },
          onClose: () => removeUploadingFile(fileUpload.id),
        });
      }
    }, Promise.resolve());
  }

  const editingFile = editingFiles[0];
  return (
    <ManualUploadsContext.Provider
      value={{
        files,
        loading,
        uploadTypes,
        getFileCount: (crawlType) =>
          files.filter((file) => file.crawlType === crawlType).length,
        uploadFiles,
        setFileStatus,
        deleteFile,
        editFile,
      }}
    >
      {Boolean(editingFile) && (
        <ManualUploadsSettings
          file={editingFile.file}
          sample={editingFile.sample}
          onSubmit={(values, sample) => {
            if (editingFile.onSubmit)
              return editingFile.onSubmit(values, sample);

            const isCustom = values.uploadType === ProjectUploadType.Custom;
            return updateFile({
              fileId: editingFile.file.id,
              baseDomain: values.baseDomain,
              uploadType: values.uploadType,
              customTemplate: isCustom
                ? {
                    sample,
                    headerRow: values.headerRow || 0,
                    metrics: values.metrics,
                  }
                : undefined,
            });
          }}
          primaryDomain={primaryDomain}
          onClose={() => {
            editingFile.onClose?.();
            setEditingFiles((files) => files.slice(1));
          }}
        />
      )}
      {children}
    </ManualUploadsContext.Provider>
  );
}

export function useManualUploadContext(crawlType: CrawlType): Omit<
  ManualUploadsContextValues,
  "uploadFiles" | "uploadTypes"
> & {
  uploadFiles: (files: File[]) => void;
  uploadTypes: UploadType[];
  metrics: UploadTemplate["metrics"];
} {
  const { files, uploadTypes, uploadFiles, ...context } =
    React.useContext(ManualUploadsContext);

  return {
    files: files.filter((file) => file.crawlType === crawlType),
    uploadTypes: uploadTypes[crawlType]?.uploadTypes || [],
    metrics: uploadTypes[crawlType]?.metrics || [],
    uploadFiles: (files) => uploadFiles(crawlType, files),
    ...context,
  };
}

function formatFileName(fileName: string): string {
  return fileName.replaceAll(
    /([^0-9a-zA-Z_\-.]|\.(?!(csv|gz|txt|xml)$))/g,
    "_",
  );
}

async function detectUploadType(
  file: File,
  uploadTypes: UploadType[],
): Promise<{ uploadType?: UploadType; sample?: string[][] }> {
  if (uploadTypes.length === 1 && !uploadTypes[0].template) {
    return { uploadType: uploadTypes[0] };
  }

  function removeByteOrderMarks(value: string): string {
    return value.replace(/^\uFEFF/, "").replace(/^\u00BB\u00BF/, "");
  }

  const fileContent = await file.text();
  const sample = Papa.parse(
    removeByteOrderMarks(fileContent).split("\n").slice(0, 50).join("\n"),
  ).data as string[][];

  return {
    uploadType: uploadTypes.find((uploadType) =>
      isUploadTypeMatch(sample, uploadType),
    ),
    sample,
  };
}

export function isUploadTypeMatch(
  sample: string[][],
  uploadType: UploadType,
): boolean {
  if (!uploadType.template) return false;

  function formatHeader(header: string | undefined): string | undefined {
    return header?.toLowerCase().replaceAll(/(^"|"$)/g, "");
  }

  const headers = sample[uploadType.template.headerRow ?? 0] || [];

  return uploadType.template.metrics.reduce((result, metric) => {
    if (
      typeof metric.index === "number" &&
      formatHeader(headers[metric.index]) !== formatHeader(metric.header)
    ) {
      return false;
    }

    return result;
  }, true);
}
