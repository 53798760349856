import React from "react";
import { RocketLaunch, useTranslation } from "@lumar/shared";

import { ProgressDashboardContainer } from "../_common/progress-dashboard-container/ProgressDashboardContainer";
import { ProgressDashboardKeyNumber } from "../_common/progress-dashboard-key-number/ProgressDashboardKeyNumber";
import { RunningCrawl } from "../../../useProjectPoller";

export function CrawlSpeedCard({
  runningCrawl,
}: {
  runningCrawl?: RunningCrawl;
}): JSX.Element {
  const { t } = useTranslation("crawlProgress");

  return (
    <ProgressDashboardContainer>
      <ProgressDashboardKeyNumber
        icon={RocketLaunch}
        label={t("progressDashboard.crawlSpeedCardTitle")}
        value={
          runningCrawl
            ? t("progressDashboard.crawlSpeedCardValue", {
                value: runningCrawl.crawlSpeed,
              })
            : "-"
        }
        infoText={t("progressDashboard.crawlSpeedCardTooltip")}
      />
    </ProgressDashboardContainer>
  );
}
