import { EmptyState, SmileyMeh, SmileySad } from "@lumar/shared";
import { CircularProgress, makeStyles, SvgIcon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overlay: {
    height: 300,
  },
  errorIcon: {
    color: theme.palette.red[400],
    fontSize: theme.typography.pxToRem(32),
  },
  warningIcon: {
    color: theme.palette.yellow[500],
    fontSize: theme.typography.pxToRem(32),
  },
  loading: {
    height: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function MetricTableOverlay({
  variant,
  icon,
  title,
  description,
  action,
}: {
  variant: "error" | "warning";
  icon?: typeof SvgIcon;
  title?: string;
  description?: string;
  action?: {
    title: string;
    callback: () => void;
  };
}): JSX.Element {
  const classes = useStyles();

  const Icon = (() => {
    if (icon) return icon;
    if (variant === "error") return SmileySad;
    return SmileyMeh;
  })();

  return (
    <EmptyState
      icon={
        <Icon
          className={
            variant === "error" ? classes.errorIcon : classes.warningIcon
          }
        />
      }
      title={title}
      description={description}
      className={classes.overlay}
      actions={
        action
          ? [{ type: "button", onClick: action.callback, title: action.title }]
          : undefined
      }
    />
  );
}

export function LoadingOverlay(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
}
