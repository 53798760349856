import React from "react";
import { Grid, Typography, FormControlLabel } from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Checkbox, updateIfPropsChanged } from "@lumar/shared";

import { TextFieldWithOnBlurUpdate } from "../../components/CustomFields";
import { SettingControlFactory } from "../useAdvancedControls";

export const getTestSiteDomainControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.testDomain.title"),
  path: "testSiteDomain",
  // eslint-disable-next-line react/display-name
  control: (formik) => (
    <TestSiteDomain
      testSiteDomain={formik.values.test.testSiteDomain.domain}
      hasTestSiteDomainError={Boolean(
        formik.errors.test?.testSiteDomain?.domain,
      )}
      setFormValues={formik.setFieldValue}
    />
  ),
  testId: "crawl-settings-advanced-testdomain",
  activeValues: ["enable"],
  openOnInitValues: ["enable"],
  textForSearch: createTextForSearch([
    t("settings.testDomain.title"),
    t("settings.testDomain.description"),
    t("settings.testDomain.domainNamePlaceholder"),
    t("settings.testDomain.checkbox"),
  ]),
  fieldNames: [["domain", t("settings.testDomain.domainField")]],
});

const useStyles = makeStyles((theme) => ({
  indent: {
    marginTop: theme.spacing(1),
  },
  input: {
    width: "100%",
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
}));

export const TestSiteDomain = React.memo(TestSiteDomainInner);

function TestSiteDomainInner({
  testSiteDomain,
  hasTestSiteDomainError,
  setFormValues,
}: {
  testSiteDomain: string;
  hasTestSiteDomainError: boolean;
  setFormValues: (field: string, value: boolean) => void;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const hasValidDomain = Boolean(testSiteDomain) && !hasTestSiteDomainError;

  const previousTestSiteDomain = React.useRef(testSiteDomain);
  const previousTestSiteDomainError = React.useRef(hasTestSiteDomainError);
  React.useEffect(() => {
    if (
      testSiteDomain !== previousTestSiteDomain.current ||
      hasTestSiteDomainError !== previousTestSiteDomainError.current
    ) {
      setFormValues(
        "test.testSiteDomain.enable",
        Boolean(testSiteDomain && !hasTestSiteDomainError),
      );

      previousTestSiteDomain.current = testSiteDomain;
      previousTestSiteDomainError.current = hasTestSiteDomainError;
    }
  }, [testSiteDomain, hasTestSiteDomainError, setFormValues]);

  return (
    <>
      <Typography variant="caption">
        {t("settings.testDomain.description")}
      </Typography>
      <Grid container spacing={2} className={classes.indent}>
        <Grid item xs={12} md={6}>
          <FastField
            name="test.testSiteDomain.domain"
            component={TextFieldWithOnBlurUpdate}
            hiddenLabel
            variant="outlined"
            placeholder={t("settings.testDomain.domainNamePlaceholder")}
            className={classes.input}
            inputProps={{
              autoComplete: "url",
              form: {
                autoComplete: "off",
              },
            }}
            data-testid="test-site-domain"
          />
        </Grid>
      </Grid>

      <FastField
        name="test.testSiteDomain.enable"
        diabled={hasValidDomain}
        shouldUpdate={updateIfPropsChanged("diabled")}
      >
        {(props: FastFieldProps<boolean>) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.field.value}
                onChange={() =>
                  props.form.setFieldValue(props.field.name, !props.field.value)
                }
                disabled={props.form.isSubmitting || !hasValidDomain}
                data-testid="use-test-site"
              />
            }
            label={t("settings.testDomain.checkbox")}
            className={classes.checkbox}
          />
        )}
      </FastField>
    </>
  );
}
