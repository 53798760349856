import { Chip, ChipColor, useTranslation } from "@lumar/shared";
import { MetricsValuePresenterProps } from "../../data/types";

export function StatusCodePresenter({
  value,
  metrics,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("report");
  const statusCode = Number(value ?? 0);

  const url = metrics["url"] ?? metrics["primaryUrl"]; // These metrics are not guaranteed.
  const isS3Upload = !!url?.value?.startsWith("s3://"); // s3 is used for sitemaps

  function getValue(): string | number {
    if (statusCode > 0) return statusCode;
    return t("failed");
  }

  if (isS3Upload) {
    return <Chip label={t("manualUpload")} color="grey" />;
  }

  return <Chip label={getValue()} color={getStatusCodeChipColor(statusCode)} />;
}

export const getStatusCodeChipColor = (statusCode: number): ChipColor => {
  if (statusCode > 199 && statusCode <= 299) return "green";
  if (statusCode > 299 && statusCode <= 399) return "cyan";
  if (statusCode > 399 && statusCode <= 499) return "yellow";
  return "red";
};

export function StatusCodeChangedFromPresenter({
  value,
}: MetricsValuePresenterProps): JSX.Element {
  const { t } = useTranslation("report");
  const statusCode = Number(value ?? 0);

  function getValue(): string | number {
    if (statusCode > 0) return statusCode;
    return t("failed");
  }

  if (value === null || value === undefined) return <>-</>;

  return <Chip label={<s>{getValue()}</s>} color="lightgrey" />;
}
