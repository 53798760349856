import {
  CustomMetricContainer,
  CustomMetricContainerProject,
  Project,
  UpdateProjectInput,
} from "../../../../../graphql";
import { ScriptRenderingSettings } from "../types";

type QueryData = Pick<
  Project,
  | "useRenderer"
  | "rendererBlockAds"
  | "rendererBlockAnalytics"
  | "rendererBlockCustom"
  | "rendererJsString"
  | "rendererJsUrls"
  | "flattenShadowDom"
  | "flattenIframes"
  | "renderTimeout"
  | "block3rdPartyCookies"
> & {
  customMetricContainerProjects: {
    nodes: Array<
      Pick<CustomMetricContainerProject, "customJsScripts" | "enabled"> & {
        customMetricContainer: Pick<CustomMetricContainer, "id">;
      }
    >;
  };
};

type AccessibilityMetricContainer = Pick<
  CustomMetricContainerProject,
  "customJsScripts" | "enabled"
> & {
  customMetricContainer: Pick<CustomMetricContainer, "id">;
};

export function getAccessibilityContainer(
  data: Pick<QueryData, "customMetricContainerProjects"> | undefined | null,
  accessibilityContainerId: string | undefined,
): AccessibilityMetricContainer | undefined {
  return data?.customMetricContainerProjects.nodes.find(
    (x) => x.customMetricContainer.id === accessibilityContainerId,
  );
}

export function formatScriptRenderingSettingsFrom(
  data: QueryData | undefined | null,
  accessibilityContainerId: string | undefined,
): ScriptRenderingSettings {
  const accessibilityContainer = getAccessibilityContainer(
    data,
    accessibilityContainerId,
  );

  return {
    enable: data?.useRenderer ?? false,
    blockAdScripts: data?.rendererBlockAds ?? false,
    blockAnalyticsScripts: data?.rendererBlockAnalytics ?? false,
    customRejections: data?.rendererBlockCustom?.join("\n") ?? "",
    customScript: data?.rendererJsString ?? "",
    customAccessibilityScript:
      accessibilityContainer?.customJsScripts?.[0] || "",
    externalResources: data?.rendererJsUrls ?? [],
    flattenShadowDom: data?.flattenShadowDom ?? false,
    flattenIframes: data?.flattenIframes ?? false,
    renderTimeout: data?.renderTimeout ?? 10,
    block3rdPartyCookies: data?.block3rdPartyCookies ?? false,
  };
}

export function formatScriptRenderingSettingsTo(
  formValues: ScriptRenderingSettings,
): Pick<
  UpdateProjectInput,
  | "useRenderer"
  | "rendererBlockAds"
  | "rendererBlockAnalytics"
  | "rendererBlockCustom"
  | "rendererJsString"
  | "rendererJsUrls"
  | "flattenShadowDom"
  | "flattenIframes"
  | "renderTimeout"
  | "block3rdPartyCookies"
> {
  return {
    useRenderer: formValues.enable,
    rendererBlockAds: formValues.blockAdScripts,
    rendererBlockAnalytics: formValues.blockAnalyticsScripts,
    rendererBlockCustom: formValues.customRejections
      .split(/\r\n|\r|\n/)
      .map((x) => x.trim())
      .filter((x) => x.length),
    rendererJsString: formValues.customScript,
    rendererJsUrls: formValues.externalResources,
    flattenShadowDom: formValues.flattenShadowDom,
    flattenIframes: formValues.flattenIframes,
    renderTimeout: formValues.renderTimeout,
    block3rdPartyCookies: formValues.block3rdPartyCookies,
  };
}
