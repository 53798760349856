import {
  SvgIconComponent,
  Typography,
  useNumberFormatter,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  chip: {
    background: theme.palette.secondary.light,
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
    borderRadius: "6px",
    marginRight: theme.spacing(2),
  },
  icon: {
    verticalAlign: "middle",
    marginBottom: 3,
    marginRight: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  chipText: {
    verticalAlign: "middle",
    display: "inline-block",
    color: theme.palette.grey[700],
    marginBottom: 3,
  },
}));

interface ChippedCountCellProps {
  params: ProjectsGridRenderCellParams;
  icon: SvgIconComponent;
  "data-testid"?: string;
}

export function ChippedCountCell(
  props: ChippedCountCellProps,
): JSX.Element | null {
  const { params } = props;
  const classes = useStyles();
  const paramsValue = params.value as string | null;
  const count = parseInt(paramsValue || "") || 0;
  const formatNumber = useNumberFormatter();
  const isValueZero = Number(params.value) === 0;

  if (isValueZero) {
    // Note: The non-breaking space is needed to prevent screen readers from
    // announcing the action twice. If the content is empty, the screen reader
    // will announce the link title, which is already set on the cell.
    return <>&nbsp;</>;
  }

  return (
    <div>
      <span className={classes.chip}>
        <Typography
          variant="subtitle3SemiBold"
          className={classes.chipText}
          data-testid={props["data-testid"]}
        >
          <props.icon className={classes.icon} fontSize="small" />
          {formatNumber(count)}
        </Typography>
      </span>
    </div>
  );
}
