import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
import { Typography } from "@lumar/shared";

import { CheckboxWithLabel } from "../../../../_common/forms/CheckboxWithLabel";
import { LabelInfo } from "../../../../_common/components/LabelInfo";
import { SettingControlFactory } from "../useAdvancedControls";

export const getLinkValidationControl: SettingControlFactory = ({
  t,
  createTextForSearch,
}) => ({
  title: t("settings.linkValidation.title"),
  path: "linkValidation",
  // eslint-disable-next-line react/display-name
  control: () => <LinkValidation />,
  testId: "crawl-settings-advanced-link-validation",
  textForSearch: createTextForSearch([
    t("settings.linkValidation.title"),
    t("settings.linkValidation.description"),
    t("settings.linkValidation.disallowedLinks"),
    t("settings.linkValidation.disallowedLinksLabelInfo"),
    t("settings.linkValidation.excludedLinks"),
    t("settings.linkValidation.excludedLinksLabelInfo"),
    t("settings.linkValidation.externalAnchorLinks"),
    t("settings.linkValidation.externalAnchorLinksLabelInfo"),
    t("settings.linkValidation.externalCanonicalLinks"),
    t("settings.linkValidation.externalCanonicalLinksLabelInfo"),
    t("settings.linkValidation.externalPaginationLinks"),
    t("settings.linkValidation.externalPaginationLinksLabelInfo"),
    t("settings.linkValidation.externalMobileAltLinks"),
    t("settings.linkValidation.externalMobileAltLinksLabelInfo"),
    t("settings.linkValidation.externalAmphtmlLinks"),
    t("settings.linkValidation.externalAmphtmlLabelInfo"),
    t("settings.linkValidation.externalHreflangLinks"),
    t("settings.linkValidation.externalHreflangLabelInfo"),
  ]),
});

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const LinkValidation = React.memo(LinkValidationInner);

function LinkValidationInner(): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption">
        {t("settings.linkValidation.description")}
      </Typography>
      <Grid container className={classes.grid}>
        <Grid xs={12} md={6} className={classes.column}>
          <FastField
            name="scope.linkValidation.disallowedLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.disallowedLinks")}
                  info={t("settings.linkValidation.disallowedLinksLabelInfo")}
                />
              ),
            }}
            data-testid="check-disallowed-links"
          />
          <FastField
            name="scope.linkValidation.excludedLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.excludedLinks")}
                  info={t("settings.linkValidation.excludedLinksLabelInfo")}
                />
              ),
            }}
            data-testid="check-excluded-links"
          />
          <FastField
            name="scope.linkValidation.externalAnchorLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalAnchorLinks")}
                  info={t(
                    "settings.linkValidation.externalAnchorLinksLabelInfo",
                  )}
                />
              ),
            }}
            data-testid="check-external-links"
          />
          <FastField
            name="scope.linkValidation.externalCanonicalLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalCanonicalLinks")}
                  info={t(
                    "settings.linkValidation.externalCanonicalLinksLabelInfo",
                  )}
                />
              ),
            }}
            data-testid="check-external-canonical-links"
          />
        </Grid>
        <Grid xs={12} md={6} className={classes.column}>
          <FastField
            name="scope.linkValidation.externalPaginationLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalPaginationLinks")}
                  info={t(
                    "settings.linkValidation.externalPaginationLinksLabelInfo",
                  )}
                />
              ),
            }}
            data-testid="check-external-pagination-links"
          />
          <FastField
            name="scope.linkValidation.externalMobileAltLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalMobileAltLinks")}
                  info={t(
                    "settings.linkValidation.externalMobileAltLinksLabelInfo",
                  )}
                />
              ),
            }}
            data-testid="check-external-mobile-alt-links"
          />
          <FastField
            name="scope.linkValidation.externalAmphtmlLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalAmphtmlLinks")}
                  info={t("settings.linkValidation.externalAmphtmlLabelInfo")}
                />
              ),
            }}
            data-testid="check-external-amphtml-links"
          />
          <FastField
            name="scope.linkValidation.externalHreflangLinks"
            component={CheckboxWithLabel}
            type="checkbox"
            Label={{
              label: (
                <LabelInfo
                  label={t("settings.linkValidation.externalHreflangLinks")}
                  info={t("settings.linkValidation.externalHreflangLabelInfo")}
                />
              ),
            }}
            data-testid="check-external-hreflang-links"
          />
        </Grid>
      </Grid>
    </>
  );
}
