import React from "react";
import {
  Alert,
  Chip,
  NestedNavigationMenu,
  SelectableButtonGroup,
  useTranslation,
} from "@lumar/shared";
import { useSiteSpeedDetailsSections } from "./useSiteSpeedDetailsSections";
import { ResourceDetailData } from "../../data/types";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useURLSearchParams } from "../../../_common/routing/useURLSearchParams";

export function SiteSpeedDetailsSidebar({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const params = useURLSearchParams();
  const { sections, active, activeParent, loading, error } =
    useSiteSpeedDetailsSections(data.metrics);
  const { t } = useTranslation("resourceDetail");
  const classes = useStyles();

  const [openedSections, setOpenedSections] = React.useState<
    Record<string, boolean>
  >({});

  function toggleSection(code: string): void {
    setOpenedSections((x) => ({
      ...x,
      [code]: !x[code],
    }));
  }

  React.useEffect(() => {
    if (
      activeParent?.expandable &&
      openedSections[activeParent.code] === undefined
    ) {
      toggleSection(activeParent.code);
    }
  }, [activeParent, openedSections]);

  function setType(type: string): void {
    params.set("type", type);
    params.navigate();
  }

  function setAuditFilter(auditFilter: string): void {
    params.set("auditFilter", auditFilter);
    params.navigate();
  }

  const showAuditFilters = Boolean(
    data.metrics["sitespeedAuditResults"]?.value,
  );
  const auditFilter = params.get("auditFilter") || "all";

  return (
    <>
      {showAuditFilters && (
        <SelectableButtonGroup
          size="small"
          value={auditFilter}
          onValueChange={setAuditFilter}
          colorVariant="muted"
          options={[
            {
              value: "all",
              label: t("siteSpeed.auditFilterAll"),
            },
            {
              value: "fcp",
              label: t("siteSpeed.auditFilterFcp"),
            },
            {
              value: "lcp",
              label: t("siteSpeed.auditFilterLcp"),
            },
            {
              value: "tbt",
              label: t("siteSpeed.auditFilterTbt"),
            },
            {
              value: "cls",
              label: t("siteSpeed.auditFilterCls"),
            },
          ]}
          className={classes.buttonGroup}
          classes={{
            optionSelected: classes.optionSelected,
          }}
          aria-label={t("siteSpeed.auditFilterGroup")}
        />
      )}
      <NestedNavigationMenu
        sections={[
          {
            id: "crawlUrl",
            items: sections.map((section) => ({
              id: section.code,
              name: section.name,
              icon: section.icon,
              ...(section.expandable
                ? {
                    onClick: () => toggleSection(section.code),
                  }
                : { onClick: () => setType(section.code) }),
              open: section.expandable
                ? Boolean(openedSections[section.code])
                : false,
              selected: section.code === active?.code,
              adornment: (() => {
                if (section.count === undefined) return undefined;

                function AuditCount(): JSX.Element {
                  return <Chip label={section.count} color="primary" />;
                }

                return AuditCount;
              })(),
              children: (section.items || []).map((item) => ({
                id: item.code,
                name: item.name,
                onClick: () => setType(item.code),
                selected: item.code === active?.code,
              })),
            })),
          },
        ]}
      />
      {loading && (
        <CircularProgress
          size="2rem"
          style={{ display: "block", margin: "auto", marginTop: "64px" }}
        />
      )}
      {error && (
        <Alert severity="error" style={{ paddingTop: 8, paddingBottom: 8 }}>
          {error}
        </Alert>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(1.375),
    width: "100%",
  },
  optionSelected: {
    color: theme.palette.ultraviolet[500],
    boxShadow: `0 0 0 1px ${theme.palette.ultraviolet[200]} inset`,
    "&:hover": {
      background: theme.palette.ultraviolet[200],
      color: theme.palette.ultraviolet[500],
    },
  },
}));
