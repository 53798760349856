import { ApolloError, Snackbar } from "@lumar/shared";
import React from "react";

export function ApolloErrorSnackbar({
  title,
  error,
}: {
  title: string;
  error: ApolloError;
}): JSX.Element {
  return (
    <Snackbar variant="error" title={title}>
      {error.graphQLErrors.length > 0 ? (
        error.graphQLErrors.map((error, idx) => (
          <React.Fragment key={idx}>
            {error.message}
            <br />
          </React.Fragment>
        ))
      ) : (
        <>{error.message}</>
      )}
    </Snackbar>
  );
}
