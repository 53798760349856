import {
  BlueDataGrid,
  useSession,
  useTranslation,
  useUrlServerPagination,
} from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { useParams } from "react-router";
import { useCompareSettingsResults } from "../../_common/crawl-settings-comparison/compareSettingsResults";
import {
  CrawlOrderField,
  CrawlStatus,
  OrderDirection,
  useCrawlsListTableQuery,
} from "../../graphql";
import { useCrawlsListColumns } from "./columns/columns";

interface RouteParams {
  accountId: string;
  projectId: string;
}

const useStyles = makeStyles(() => ({
  row: { cursor: "pointer" },
}));

export function CrawlsListTable(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("crawlsList");
  const { projectId } = useParams<RouteParams>();
  const { isUsingSharedLink } = useSession();

  const pageSize = 20;

  const { pageInfo, ...pagination } = useUrlServerPagination(
    pageSize,
    CrawlOrderField.CreatedAt,
    OrderDirection.Desc,
  );

  const { loading, data, error } = useCrawlsListTableQuery({
    variables: { projectId, ...pageInfo, isShared: isUsingSharedLink },
  });
  const compareSettingsResults = useCompareSettingsResults();

  const rows = useMemo(() => {
    const accessibilityContainerId =
      data?.accessibilityModule?.customMetricContainers?.find(
        (x) => x.name === "AccessibilityIssues",
      )?.id;

    return (
      data?.getProject?.crawls.edges.map((c) => {
        const compareResult =
          c.node.statusEnum !== CrawlStatus.Queued
            ? compareSettingsResults(
                c.node,
                accessibilityContainerId,
                data.getProject?.moduleCode,
              )
            : undefined;

        return { ...c.node, crawlChanges: compareResult?.diffs.length };
      }) ?? []
    );
  }, [data, compareSettingsResults]);

  const totalCount = data?.getProject?.crawls.totalCount;
  const isTestSuite = data?.getProject?.isTestSuite;

  return (
    <Box>
      <BlueDataGrid
        name="crawl-list"
        rows={rows}
        rowCount={totalCount}
        loading={loading}
        error={error}
        columns={useCrawlsListColumns(isTestSuite)}
        paginationMode="server"
        {...pagination}
        classes={{ root: classes.row }}
        pagination
        disableColumnReorder
        components={{ Toolbar: undefined }}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        sortingOrder={["desc", "asc", null]}
        rowHeight={65}
        autoRowHeight
        componentsProps={{
          noRowsOverlay: {
            title: t("noCrawlsTitle"),
            detail: t("noCrawlsDescription"),
          },
        }}
      />
    </Box>
  );
}
