import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { ChartPanelTitle } from "./ChartPanelTitle";
const useStyles = makeStyles((theme) => ({
    panel: {
        display: "flex",
        flexDirection: "column",
        height: "390px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
        background: theme.palette.background.paper,
        borderRadius: "8px",
        width: "100%",
    },
    chartItem: { padding: 15, height: "100%" },
}));
export function ChartContainer(props) {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.panel, "data-testid": props["data-testid"], children: [_jsx(ChartPanelTitle, { title: props.title, description: typeof props.description === "function" ? (_jsx(props.description, {})) : (props.description), subtitle: props.subtitle, children: props.header }), _jsx("div", { className: classes.chartItem, children: props.children })] }));
}
