interface UserOptionsWithUnit {
  unit: string;
}

export function hasUnitProperty(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: Record<string, any>,
): value is UserOptionsWithUnit {
  return value.hasOwnProperty("unit") && typeof value.unit === "string";
}
