import React from "react";
import { makeStyles } from "@material-ui/core";
import { ChartConfigItemSparkline } from "./ChartConfigItemSparkline";
import { assert } from "../../assert";
import { SparklineListItem } from "./SparklineListItem";
import { useGenericParams } from "../../routing/useGenericParams";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { ChartPanelTitle } from "../components/chart-components/ChartPanelTitle";
import { ChartWrapper } from "../components/chart-components/ChartWrapper";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartPanelContent } from "../components/chart-components/ChartPanelContent";
import { ChartPanelButton } from "../components/chart-components/ChartPanelButton";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { useSearchParam } from "../../routing/useSearchParam";
import {
  ChartConfigReportStatArray,
  ChartConfigReportStatArrayElement,
} from "../types/ChartConfigItemBase";
import { getIconForChart } from "../../../crawl-overview/dashboard/data-visualization/charts/getIconForChart";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
  table: {
    flexGrow: 1,
    width: "100%",
    tableLayout: "fixed",
    // NOTE: This is needed instead of cellspacing because of bootstrap style leak - Saul
    borderSpacing: 0,
    borderCollapse: "collapse",
  },
}));

function SparklineChart(props: SparklineListProps): JSX.Element {
  const classes = useStyles();
  const { reportStats } = useChartDataContext();
  const { accountId, projectId, crawlId } = useGenericParams();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  return (
    <table className={classes.table}>
      <tbody>
        {reportStats.map((report) => {
          const sign = props.sign(report as ChartConfigReportStatArrayElement);

          return (
            <SparklineListItem
              key={report.reportTemplateCode}
              reportTemplateCode={report.reportTemplateCode}
              reportTemplateName={report.reportTemplateName}
              sign={sign}
              count={props.count(
                report as ChartConfigReportStatArrayElement,
                reportStats as ChartConfigReportStatArray,
              )}
              showChangePercentage={Boolean(props.showChangePercentage)}
              change={
                props.change
                  ? props.change(report as ChartConfigReportStatArrayElement)
                  : undefined
              }
              trendValues={props.trendValues(
                report as ChartConfigReportStatArrayElement,
              )}
              colors={props.visualisationColors}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export type SparklineListProps = ChartConfigItemSparkline;

export function SparklineList(props: SparklineListProps): JSX.Element {
  const {
    noTrendsTemplate,
    noReportsTemplate,
    requiredSources,
    description,
    descriptionTitle,
    title,
  } = props;
  const { accountId, projectId, crawlId } = useGenericParams();
  const { crawl, reportStats, segmentName, totalUrls } = useChartDataContext();
  const { selectedCrawlSegment } = useCrawlContextData();
  const category = useSearchParam("category");

  const showNoTrendDataTemplate =
    Boolean(noTrendsTemplate) && !crawl?.comparedTo?.id;

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const moreLink = props.moreLink?.href({
    accountId,
    projectId,
    crawlId,
    segmentId: selectedCrawlSegment?.segment?.id,
    category: category,
  });

  const totalCount = reportStats.length;

  const moreLinkText = props.moreLink?.title(
    totalCount,
    reportStats as ChartConfigReportStatArray,
    totalUrls ?? 0,
  );

  return (
    <ChartWrapper
      data-testid={props.testAttributePrefix}
      data-pendo={props.pendoAttributePrefix}
    >
      <ChartPanelTitle
        title={title(reportStats as ChartConfigReportStatArray)}
        icon={getIconForChart(props.icon)}
        description={
          description
            ? description(
                { accountId, projectId, crawlId },
                reportStats as ChartConfigReportStatArray,
              )
            : undefined
        }
        descriptionTitle={descriptionTitle?.(
          reportStats as ChartConfigReportStatArray,
        )}
        segmentName={segmentName}
      >
        {
          <ChartPanelButton
            link={moreLink}
            label={moreLinkText}
            data-pendo={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-view-all`
                : undefined
            }
          />
        }
      </ChartPanelTitle>
      <ChartPanelContent requiredSources={requiredSources}>
        {showNoTrendDataTemplate ? (
          <ChartPanelMessageWrapper>
            {noTrendsTemplate}
          </ChartPanelMessageWrapper>
        ) : reportStats.length === 0 ? (
          <ChartPanelMessageWrapper>
            {noReportsTemplate}
          </ChartPanelMessageWrapper>
        ) : (
          <SparklineChart {...props} />
        )}
      </ChartPanelContent>
    </ChartWrapper>
  );
}
