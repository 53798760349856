import { NavLink } from "react-router-dom";
import { MetricsValuePresenterProps } from "../../data/types";
import { useResourceDetailUrls } from "./UrlPresenter";
import { makeStyles } from "@material-ui/core";

export function BoundingBoxPresenter({
  value,
  metrics,
}: MetricsValuePresenterProps): JSX.Element {
  const classes = useStyles();
  const { resourceDetailUrl } = useResourceDetailUrls({
    metrics,
    code: "issueDigest",
    value: "",
  });

  if (!value) {
    return <>-</>;
  }

  return (
    <>
      {resourceDetailUrl ? (
        <NavLink
          to={`${resourceDetailUrl}#screenshot`}
          className={classes.link}
        >
          {value}
        </NavLink>
      ) : (
        <>{value}</>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  link: { color: theme.palette.blue[600], whiteSpace: "normal" },
}));
