import { useTranslation } from "@lumar/shared";
import { insertIf } from "../../../../_common/insertIf";
import { Metrics, ResourceDetailData } from "../../../data/types";
import {
  MetricsGroups,
  MetricsGroupsConfig,
} from "../../../metrics-groups/MetricsGroups";

export function AssetResourceOverview({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const config = useMetricsConfig(data.metrics);

  return <MetricsGroups metrics={data.metrics} config={config} />;
}

function useMetricsConfig(metrics: Metrics): MetricsGroupsConfig {
  const { t } = useTranslation("resourceDetail");

  const hasFailedReason = metrics["failedReason"]?.value !== undefined;
  const isRedirect = metrics["isRedirect"]?.value === true;

  const result: MetricsGroupsConfig = [
    {
      name: t("groupAssetSummary"),
      items: [
        { code: "headerContentType" },
        { code: "htmlSize" },
        ...insertIf(hasFailedReason, { code: "failedReason" }),
        { code: "internal" },
        { code: "indexable" },
        { code: "redirectsInCount" },
        { code: "cssLinksInCount" },
        { code: "jsLinksInCount" },
        { code: "imgLinksInCount" },
        { code: "crawlDatetime" },
        { code: "disallowedPage" },
        { code: "noindex" },
        { code: "headerNoindex" },
      ],
    },
    ...insertIf(isRedirect, {
      name: t("groupRedirection"),
      items: [
        { code: "redirectChain" },
        { code: "redirectCount" },
        { code: "redirectedToUrl" },
        { code: "redirectedToStatusCode" },
        { code: "isRedirectLoop" },
      ],
    }),
  ];

  function filterMetricSupported(
    item: Required<MetricsGroupsConfig[0]>["items"][0],
  ): boolean {
    return Boolean(metrics[item.code]?.data);
  }

  return result
    .map((config) => ({
      ...config,
      items: config.items?.filter(filterMetricSupported),
    }))
    .filter((config) => config.items?.length);
}
