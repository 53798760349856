/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "../../../graphql";

export function getAccessibilityAriaBPChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    inputSource: "CrawlContext",
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityAriaBPChart.title"),
    description: () => t("accessibilityAriaBPChart.description"),
    reportTemplateCodesOrder: [
      "empty_table_header_issues",
      "aria_allowed_role_issues",
      "aria_dialog_name_issues",
      "aria_text_issues",
      "aria_treeitem_name_issues",
      "presentation_role_conflict_issues",
    ],
    reportStatFilter: (report) => {
      return [
        "empty_table_header_issues",
        "aria_allowed_role_issues",
        "aria_dialog_name_issues",
        "aria_text_issues",
        "aria_treeitem_name_issues",
        "presentation_role_conflict_issues",
      ].includes(report.reportTemplateCode);
    },
    reportStatsOrderBy: { field: "basic", direction: "desc" },
    serieName: (report) => report.reportTemplateName,
    count: (report) => {
      return report.basic ?? 0;
    },
  };
}
