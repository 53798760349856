import { ResourceDetailData } from "../../../data/types";
import { ChangedMetricsTable } from "../../../metrics-table/ChangedMetricsTable";
import { useExportName } from "../../useExportName";

export function SitemapDetailsChangedMetrics({
  data,
}: {
  data: ResourceDetailData;
}): JSX.Element {
  const getExportName = useExportName({
    crawlCreatedAt: data.crawl.createdAt,
    suffix: "changed_metrics",
  });

  return (
    <ChangedMetricsTable
      metrics={data.metrics}
      sourceTemplate={data.sourceTemplate}
      currentCrawlDate={data.crawl.createdAt}
      exportFileName={getExportName}
    />
  );
}
